// Importing React and mui Components
import React, { useState, useEffect } from "react";
import { Autocomplete, Box, Button, CircularProgress, Grid, IconButton, Paper, TextField, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import { AttachIcon, AddIcon, OpenEnrollmentIcon } from "@fluentui/react-icons-mdl2";
import AlertDialog from "../../../UIComponent/AlertDialog";
// Importing Custom Components
import DatePicker from "../../../UIComponent/DatePicker";
import ErroHandling from "../../../Services/ErrorHandling";
import {
    CancelIcon,
    DeleteIcon,
    TextDocumentIcon,
    LinkIcon,
} from "@fluentui/react-icons-mdl2";
import ApiService from "../../../Services/ApiService";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// import history from "../../../Routes/History";
import { useHistory } from "react-router-dom";

import SideDrawer from "../../../UIComponent/Drawer";
import { ScrollingDialog } from "../../../UIComponent";
import { grey, lightBlue } from "@mui/material/colors";
// import AdobeViewer from "./AdobeViewer";
import dayjs from "dayjs";
import id from "date-fns/esm/locale/id";
import AdobeViewer from "../JobManagement/AdobeViewer";
import useStyles from "../JobManagement/jobManagement.styles";
import CurrencyFormat from 'react-currency-format';
import CommonService from "../../../Services/CommonService";

// Import Fluent UI Icons

// Importing useStyles and Styles
// import useStyles from "./jobManagement.styles";
const darkGrey = grey[900];


const EditSideDeawer = (props) => {
    
const history = useHistory();
    const classes = useStyles();
    const [fileuploadLoading, setfileuploadLoading] = useState(false)
    const [loading, setloading] = useState(false)
    const [variableStatus, setVariableStatus] = useState([])
    const [selectedStatus, setselectedStatus] = useState(null)
    const [reportInfoData, serReportInfoData] = useState([])
    const [JobAssetFeedbackForm, setJobAssetFeedbackForm] = useState({
        variableId: props.state.formData.variableId,
        variableStatusId: null,
        variableFeedback: props.state.formData.variableFeedback ? props.state.formData.variableFeedback : null
    })
    const [VariableValuesForm, setVariableValuesForm] = useState('')
    const [newFile, setNewFile] = useState(null)
    const [selectedReport, setselectedReport] = useState([])
    const [adobeViewerData, setAdobeViewerData] = useState({ jobId: 0, fileName: '', pageNumber: 0, crudState: "" })
    const [openAdobeViewer, setOpenAdobeViewer] = useState(false)
    const [VariablevalueReportInfo, setVariablevalueReportInfo] = useState([])
    const [deleteReportLoading, setdeleteReportLoading] = useState({ status: false, index: 0 })
    // const [update]

    useEffect(() => {
        if (variableStatus.length < 1) {
            getRoleBasedStatus()
            getEditVariableValueDetails()
            getreortInfo()
        }
        if (VariablevalueReportInfo.length > 0 && reportInfoData.length > 0) {
            let rdData = reportInfoData
            VariablevalueReportInfo.forEach(vr => {
                reportInfoData.forEach(rd => {
                    if (vr.reportingId !== rd.assetReportId) {
                        rdData.push(vr)
                    }
                });
            });
            serReportInfoData(rdData)
        }
    }, [])

    useEffect(() => {
        if (props.state.mode === "variable" && VariableValuesForm && variableStatus) {
            setselectedStatus(variableStatus.filter(vs => vs.id === VariableValuesForm.variableValueStatusId)[0])
        }
    }, [VariableValuesForm, variableStatus])


    const getreortInfo = () => {
        axios.post(process.env.React_App_baseUrl + 'updatejobrecord/GetReportInfo', null, {
            headers: {
                jobAssetId: props.propsData,
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

            }
        }).then((res) => {
            if (res.status === 200) {
                serReportInfoData(res.data)
            }
        })

    }

    const getEditVariableValueDetails = () => {
        // console.log("getEditVariableValueDetails", props.state.formData.variablevalueid)
        if (props.state.mode === 'variable') {
            axios.get(process.env.React_App_baseUrl + 'QualityManagement/GetEditVariableValueDetails', {
                headers: {
                    VariableValueId: props.state.formData.variablevalueid,
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

                }
            }).then((res) => {
                if (res.status === 200) {
                    setVariablevalueReportInfo(res.data.valueReportInfo)
                    //  console.log(res.data.valueReportInfo)
                    setVariableValuesForm({ ...res.data })

                } else {
                    toasterError('Something went wrong')
                }
            }).catch((err) => {
                ErroHandling(err, function (res) {
                    toasterError(res)
                })
            })
        }
    }

    const getRoleBasedStatus = (ed) => {
        // console.log('Authorization: ',localStorage.getItem("token"));
        axios.get(process.env.React_App_baseUrl + 'QualityManagement/GetRoleBasedStatus', ed ? {
            headers: {
                isEdited: ed,
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

            }
        } : {
            headers: {
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

            }
        }).then((res) => {
            if (res.status === 200) {
                setVariableStatus(res.data)
                res.data.forEach((st, i) => {
                    if (st.id === props.state.formData.variableStatusId) {
                        setselectedStatus(res.data[i])
                    }
                })
            } else {
                toasterError('Something went wrong')
            }
        }).catch((err) => {
            ErroHandling(err, function (res) {
                toasterError(res)
            })
        })
    }

    const saveJobAssetFeedback = () => {
        setloading(true)
        let data = JobAssetFeedbackForm
        if (selectedStatus && selectedStatus.id) {
            data.variableStatusId = selectedStatus.id
            axios.post(process.env.React_App_baseUrl + 'QualityManagement/SaveVariableFeedback', JobAssetFeedbackForm, {
                headers: {
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                }
            })
                .then((res) => {
                    setloading(false)
                    if (res.status === 200) {
                        props.reload(true)
                        toasterSuccess("QC feedback for variable saved successfully")
                        props.setState({ ...props.state, right: false })
                    }
                }).catch((err) => {
                    setloading(false)
                    ErroHandling(err, function (res) {
                        toasterError(res)
                    })
                })
        } else {
            setloading(false)
            toasterError('Please fill all required fields')
        }
    }

    const formValidation = (data, mode) => {
        let result = []
        if (mode !== "array") {
            if (
                !data.value &&
                !data.minValue &&
                !data.minValue &&
                !data.variableValueStatusId
            ) {
                toasterError('Please fill all required fields')
                result.push(false)
            } else {
                if (!data.value && props.state.formData.valuationType !== 'Guidance') {
                    toasterError('Please fill all required fields')
                    result.push(false)
                }
                else if (!data.minValue && props.state.formData.valuationType === 'Guidance') {
                    toasterError('Please fill all required fields')
                    result.push(false)
                }
                else if (!data.minValue && props.state.formData.valuationType === 'Guidance') {
                    toasterError('Please fill all required fields')
                    result.push(false)
                }
                else if (!data.variableValueStatusId) {
                    toasterError('Please Select QC Status First')
                    result.push(false)
                }
                else {
                    result.push(true)
                }
            }
        } else {
            result = (data.map(vl => {
                if (vl.pageNumber && vl.reportName) {
                    return true
                } else {
                    toasterError('Please fill all required fields')
                    return false
                }
            }));

        }
        if (typeof result === 'object') {
            result = result.every(v => v === true);
            return result
        } else {
            return result
        }
    }

    const handleSave = () => {
        setloading(true)
        if (formValidation(VariableValuesForm.valueReportInfo, 'array') && formValidation(VariableValuesForm, 'single')) {
            var VariableValuesFormtemp = VariableValuesForm
            if (VariableValuesFormtemp.value) {
                VariableValuesFormtemp.value = parseFloat(VariableValuesFormtemp.value.toString().replaceAll(',', ''));
            }
            if (VariableValuesFormtemp.maxValue) {
                VariableValuesFormtemp.maxValue = parseFloat(VariableValuesFormtemp.maxValue.toString().replaceAll(',', ''));
            }
            if (VariableValuesFormtemp.minValue) {
                VariableValuesFormtemp.minValue = parseFloat(VariableValuesFormtemp.minValue.toString().replaceAll(',', ''));
            }
            axios.post(process.env.React_App_baseUrl + 'QualityManagement/UpdateVariableValues', VariableValuesFormtemp, {
                headers: {
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                }
            }).then((res) => {
                setloading(false)
                if (res.status === 200) {
                    props.reload(true)
                    toasterSuccess("Variable vaules saved successfully")
                    props.setState({ ...props.state, right: false })
                } else {
                    toasterError('Something went wrong')
                }
            }).catch((err) => {
                setloading(false)
                ErroHandling(err, function (res) {
                    toasterError(res)
                })
            })
        } else {
            setloading(false)
        }
    }
    const openDocumentForView = (file) => {

        let senData = {
            jobId: props.jobId,
            fileName: file.reportName + ".pdf",
            pageNumber: file.pageNumber,
            crudState: "Edit"
        }
        // history.push({ pathname: '/adobeView', state: senData })
        //OpenViewer(senData);
        localStorage.setItem("filePreview", JSON.stringify(senData));
       // CommonService.AddTokenFromSessionStorageToLocalStorage();
        window.open("#app/data-collections/file-preview", '_blank', 'noopener,noreferrer');
    }

    const OpenViewer = (data) => {
        setOpenAdobeViewer(true);
        setAdobeViewerData({ jobId: data.jobId, fileName: data.fileName, pageNumber: data.pageNumber, crudState: data.crudState });
    }

    const renderSelectReport = (value, mode) => {
        let oldData = VariableValuesForm
        oldData.valueReportInfo.push({
            "valueReportMappingId": 0,
            "reportingId": '',
            "reportName": '',
            "pageNumber": '',
        })
        setVariableValuesForm({ ...oldData })
    }
    const handleDeleteReportInfo = (i) => {

        if (VariablevalueReportInfo[i].valueReportMappingId == 0) {
            let data = VariablevalueReportInfo
            data.splice(i, 1)
            setVariablevalueReportInfo([...data])
            toasterSuccess('Report deleted successfully')
            return;
        }
        setdeleteReportLoading({ status: true, index: i })
        axios.delete(process.env.React_App_baseUrl + 'QualityManagement/DeleteVariableValueReport', {
            headers: {
                ReportMappingId: VariablevalueReportInfo[i].valueReportMappingId,
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

            }
        }).then((res) => {
            setdeleteReportLoading({ status: false, index: i })
            if (res.status === 200) {
                let data = VariablevalueReportInfo
                data.splice(i, 1)
                setVariablevalueReportInfo([...data])
                toasterSuccess('Report deleted successfully')
            } else {
                toasterError('Something went wrong')
            }
        }).catch((err) => {
            setdeleteReportLoading({ status: false, index: i })
            ErroHandling(err, function (res) {
                toasterError(res)
            })
        })
    }


    return (
        <Box sx={{ pt: 0, pb: 3, pr: 3, pl: 3 }}>
            <Box sx={{ pt: 2, mb: 2 }}>
                <Typography id="modal-modal-title" variant="h5">
                    Edit
                </Typography>
            </Box>
            <Grid container spacing={4} alignItems="center" justifyContent="center">
                {props.state.mode === 'action' ?
                    <>

                        <Grid item xs={12}>
                            <Autocomplete
                                disablePortal
                                key="QC Status"
                                id="combo-box-demo"
                                getOptionLabel={(option) => option.statusName}
                                options={variableStatus}
                                value={selectedStatus}
                                onChange={(e, value) => {
                                    if (value) {
                                        setselectedStatus(value)
                                    } else {
                                        setselectedStatus(null)
                                    }
                                }}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        key="QC Status"
                                        label="QC Status"
                                        variant="standard"
                                        required
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                fullWidth
                                label="QC Feedback"
                                InputProps={{
                                    shrink: true,
                                }}
                                placeholder="QC Feedback"
                                onChange={(event) => setJobAssetFeedbackForm({ ...JobAssetFeedbackForm, variableFeedback: event.target.value })}
                                defaultValue={props.state.formData.variableFeedback}
                                InputLabelProps={
                                    props.crudState === "Edit" ? { shrink: true } : null
                                }
                            />
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={12} >
                            <Box className={classes.addReport} sx={{ mb: 3 }}>

                                {(VariableValuesForm && VariableValuesForm.valueReportInfo ? VariableValuesForm.valueReportInfo : []).map((vara, i) => (
                                    <>
                                        <Box>
                                            <Grid container
                                                spacing={2}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item xs={12} sx={{ mt: 0, mb: -1 }}>
                                                    <Paper elevation={0} className={classes.recordTitleArea}>
                                                        <Box className={classes.recordTitle}>{i + 1}</Box>
                                                        <Box className={classes.recordLine}></Box>
                                                        <Box className={classes.recordAction}>
                                                            <Tooltip title="Delete" placement="top" arrow>
                                                                <IconButton color="error">
                                                                    {deleteReportLoading.status && deleteReportLoading.index === i ?
                                                                        <CircularProgress size={20} color="inherit" /> :
                                                                        <DeleteIcon onClick={() => handleDeleteReportInfo(i)} style={{ fontSize: "20px" }} />
                                                                    }
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box sx={{ flexGrow: 1 }}>
                                                        <Grid container spacing={1} justifyContent="center" alignItems="self-end">
                                                            <Grid item xs>
                                                                <Autocomplete
                                                                    disablePortal
                                                                    key="Select Report"
                                                                    id="combo-box-demo"
                                                                    getOptionLabel={(option) => option.reportName}
                                                                    options={reportInfoData}
                                                                    defaultValue={vara}
                                                                    // value={selectedStatus}
                                                                    onChange={(e, value) => {
                                                                        let oldReport = VariableValuesForm
                                                                        oldReport.valueReportInfo[i].reportingId = value && value.assetReportId ? value.assetReportId : null
                                                                        oldReport.valueReportInfo[i].reportName = value && value.reportName ? value.reportName : null
                                                                        setVariableValuesForm({ ...oldReport })
                                                                    }}
                                                                    fullWidth
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            key="Select Report"
                                                                            label="Select Report"
                                                                            variant="standard"
                                                                            required
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sx={{ mb: 3 }}>
                                                    <Box sx={{ flexGrow: 1 }}>
                                                        <Grid container spacing={2} justifyContent="self-start" alignItems="self-end">
                                                            <Grid item xs="auto">
                                                                <Button variant="contained" color="accent5" size="small"
                                                                    onClick={(event) =>
                                                                        openDocumentForView(vara)
                                                                    }
                                                                    startIcon={
                                                                        <OpenEnrollmentIcon
                                                                            style={{ fontSize: 16 }}
                                                                        />
                                                                    }
                                                                >
                                                                    Open Document
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs="auto">
                                                                <TextField
                                                                    required
                                                                    variant="standard"
                                                                    fullWidth
                                                                    label="Page no"
                                                                    className={classes.pageNumber}
                                                                    defaultValue={vara.pageNumber}
                                                                    // value={VariableValuesForm ? VariableValuesForm.page : ''}
                                                                    onChange={(e, value) => {
                                                                        let oldReport = VariableValuesForm
                                                                        oldReport.valueReportInfo[i].pageNumber = e.target.value
                                                                        setVariableValuesForm({ ...oldReport })
                                                                    }}
                                                                    InputProps={{ shrink: true, }}
                                                                    placeholder="Value"
                                                                    InputLabelProps={props.crudState === "Edit" ? { shrink: true } : null}
                                                                />
                                                            </Grid>

                                                        </Grid>
                                                    </Box>

                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </>
                                ))}

                                <Grid item xs={12} textAlign="right">
                                    <Button
                                        variant="contained"
                                        color="accent7"
                                        size="small"
                                        onClick={() => renderSelectReport(null, 'New')}
                                        startIcon={<AddIcon style={{ fontSize: 16 }} />}
                                    >
                                        Add Report
                                    </Button>
                                </Grid>

                            </Box>
                        </Grid>
                        {props.state.formData.valuationType !== 'Guidance' ?
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <div className='d-flex'>
                                    <Typography sx={{ color: "#898b8c", fontSize: "14px" }}>Value&nbsp;</Typography><Typography sx={{ color: "red" }}> *</Typography>
                                </div>
                                <CurrencyFormat
                                    style={{ outline: "none", width: "100%", border: "none", borderBottom: "solid 1px #dee0e3" }}
                                    id="standard-basic"
                                    value={VariableValuesForm ? VariableValuesForm.value : ''}
                                    placeholder="Value"
                                    onChange={(e, value) => {
                                        setVariableValuesForm({ ...VariableValuesForm, value: e.target.value })
                                    }}
                                    variant="standard"
                                    thousandSeparator={true}
                                />
                            </Grid>
                            :
                            <>
                                <Grid item xs={12}>
                                    <div className='d-flex'>
                                        <Typography sx={{ color: "#898b8c", fontSize: "14px" }}>Min Value&nbsp;</Typography><Typography sx={{ color: "red" }}> *</Typography>
                                    </div>
                                    <CurrencyFormat
                                        style={{ outline: "none", width: "100%", border: "none", borderBottom: "solid 1px #dee0e3" }}
                                        id="standard-basic"
                                        value={VariableValuesForm ? VariableValuesForm.minValue : ''}
                                        onChange={(e, value) => {
                                            setVariableValuesForm({ ...VariableValuesForm, minValue: e.target.value })
                                        }}
                                        placeholder="Min Value"
                                        variant="standard"
                                        thousandSeparator={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className='d-flex'>
                                        <Typography sx={{ color: "#898b8c", fontSize: "14px" }}>Max Value&nbsp;</Typography>
                                    </div>
                                    <CurrencyFormat
                                        style={{ outline: "none", width: "100%", border: "none", borderBottom: "solid 1px #dee0e3" }}
                                        id="standard-basic"
                                        value={VariableValuesForm ? VariableValuesForm.maxValue : ''}
                                        onChange={(e, value) => {
                                            setVariableValuesForm({ ...VariableValuesForm, maxValue: e.target.value })
                                        }}
                                        placeholder="Max Value"
                                        variant="standard"
                                        thousandSeparator={true}
                                    />
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                fullWidth
                                label="Comment"
                                value={VariableValuesForm ? VariableValuesForm.comments : ''}
                                onChange={(e, value) => {
                                    setVariableValuesForm({ ...VariableValuesForm, comments: e.target.value })
                                }}
                                InputProps={{
                                    shrink: true,
                                }}
                                placeholder="Comment"
                                InputLabelProps={
                                    { shrink: true }
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                disablePortal
                                key="QC Status"
                                id="combo-box-demo"
                                getOptionLabel={(option) => option.statusName}
                                options={variableStatus}
                                value={selectedStatus}
                                onChange={(e, value) => {
                                    setVariableValuesForm({ ...VariableValuesForm, variableValueStatusName: value.statusName, variableValueStatusId: value ? value.id : null })
                                    if (value) {
                                        setselectedStatus(value)
                                    } else {
                                        setselectedStatus(null)
                                    }
                                }}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        key="QC Status"
                                        label="QC Status"
                                        variant="standard"
                                        required
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={VariableValuesForm ? VariableValuesForm.variableValueFeedback : ''}
                                variant="standard"
                                fullWidth
                                label="QC Feedback"
                                InputProps={{
                                    shrink: true,
                                }}
                                placeholder="QC Feedback"
                                onChange={(e, event) => {
                                    setVariableValuesForm({ ...VariableValuesForm, variableValueFeedback: e.target.value })
                                }}
                                InputLabelProps={
                                    props.crudState === "Edit" ? { shrink: true } : null
                                }
                            />
                        </Grid>
                    </>
                }

                {props.crudState === "View" ? null : (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent="left">
                            <Grid item xs={'auto'}>
                                <Button variant="contained"
                                    onClick={props.state.mode === 'action' ? saveJobAssetFeedback : handleSave}
                                    className="rounded-border"
                                    color="accent5"
                                >
                                    {loading ? <CircularProgress size={20} color="inherit" /> : "Update"}
                                </Button>
                            </Grid>
                            <Grid item xs={'auto'}>
                                <Button
                                    variant="contained"
                                    className="rounded-border"
                                    color="accent3"
                                    onClick={() => props.setState({ ...props.state, right: false })}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <ScrollingDialog
                    open={openAdobeViewer}
                    setOpen={setOpenAdobeViewer}
                    // scroll={scroll}
                    // setScroll={setScroll}
                    dialogClass="darkTitle"
                    dialogTitle="File Preview"
                    dialogContent={
                        <AdobeViewer jobId={adobeViewerData.jobId}
                            fileName={adobeViewerData.fileName}
                            pageNumber={adobeViewerData.pageNumber}
                            crudState={adobeViewerData.crudState}>

                        </AdobeViewer>
                    }
                />
            </Grid>
        </Box>
    )
}

export default EditSideDeawer