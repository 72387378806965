// Default React, useState and Material Components
import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import SideDrawer from "../../../UIComponent/Drawer/index";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing Add & Edit Components
import AddVariableType from "./AddVariableType";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles
import useStyles from "../ManageData.styles";

// Creating Data Table for Top VariableType
function createData(id, variabletype, description) {
  return { id, variabletype, description };
}

// Assigning Columns and UI Props
const columns = [
  { id: "id", label: "ID", type: "Highlight" },
  { id: "variabletype", label: "VariableType", minWidth: 200, width: "25%", sorting: true, },
  { id: "description", label: "Description", minWidth: 400, width: "70%", sorting: true, truncate: 90, tooltipWithTruncate: true },
];

const TopVariableType = (props) => {
  
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [id, setId] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [crudState, setCrudState] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, SetSelected] = React.useState([]);
  const closeDeleteDialog = () => { setOpen(false); };
  const [state, setState] = useState({ right: false });
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAllSelected, setIsSelected] = useState(false);
  const [filterValue, setFilterValue] = React.useState("");
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: true });
  const toggleDrawer = (open, mode) => (event, item) => {
    setState({ ...state, right: open }); setCrudState(mode); setSelectedItem(item);
  };
  const [access,setAccess]= React.useState({allowEdit:false,allowDelete:false,allowView:false});
  const openDeleteDialog = (open, mode) => (event, item) => { setOpen(open); setSelectedItem(item.id); };
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  useEffect(() => { getData(); setReload(false); }, [reload,page, rowsPerPage,]);
  React.useEffect(()=>{
    GetMenuService(44,function(err,res){
      if(res){
        console.log(res.data)
        setAccess(res.data)
      }else{
        toasterError(err)
      }
    })
  },[])

  React.useEffect(() => {
    if (page === 0) { getData() }
    else { setPage(0) }
  }, [sorting])
    // Function for Handle KeyUp
    const handleKeyUp = (event) => {
      if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== 'BUTTON') {
        getData()
        setPage(0);
      }
    };

  // Declaration of React Hooks End Here

 // Function to get variable type data from the API
  const getVariableTypeData = async () => {
    // Create a new promise to handle the response
    const response = await new Promise((resolve, reject) => {
      // Resolve the promise with an axios GET request
      resolve(axios.get(
        `${process.env.React_App_baseUrl}managedata/GetVariableTypeDetails`,
        {
          // Set headers and params for the request
          headers: { searchText: filterValue ? encodeURIComponent(filterValue) : "", isAscending: false,'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
         },
          params: { pageNumber: page + 1, pagesize: rowsPerPage, },
        }
      ));
    });
    // Return the response
    return response;
  };

// Function to get data from an API
const getData = async () => {
  // Set loading state to true
  setLoading(true);
  try {
    // Get variable type data from API
    const { data } = await getVariableTypeData();
    // Set loading state to false
    setLoading(false);
    // Set total count of data
    setTotalCount(data.totalCount);
    // Map through the data and create new data
    const finalData = await data.data.map((el) =>
      createData(el.id, el.name, el.description)
    );
    // Set rows with the new data
    setRows(finalData);
  } catch (error) { 
    // Set rows to empty array if error occurs
    setRows([]); 
    return null;
   }
};

// This function handles the deletion of a variable type
  const handleDelete = async () => {
    try {
      // Make an axios delete request to the specified URL with the selected item's id and authorization token
      await axios
        .delete(`${process.env.React_App_baseUrl}managedata/DeleteVariableType`,
          { headers: { id: selectedItem,'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
         }, }
        )
       // If the request is successful, close the modal and display a success message 
        .then((res) => {
          setOpen(false);
          if (res.status === 200) {
            setReload(true);
            toasterSuccess("Variable Type deleted successfully");
          } else { toasterError("Something went wrong"); }
        })
        // If the request fails, close the modal and display an error message
        .catch((err) => { setOpen(false); ErroHandling(err, function (res) {
          toasterError(res)}) });
    } catch (error) { ErroHandling(error, function (res) {
      toasterError(res)}) }
  };

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Variable Type</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">Variable Type</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
            {(access.allowEdit)?<>
              <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: 16 }} />}>
                Add VariableType
              </Button>
              </>:null}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className={classes.searchByKeyword}
                      value={filterValue}
                      onKeyUp={handleKeyUp}
                      placeholder="Search by Keyword"
                      onChange={(e) => setFilterValue(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                        // endAdornment: (
                        //   <InputAdornment position="end">
                        //     <IconButton>
                        //       <ClearIcon
                        //         style={{ fontSize: 10 }}
                        //         onClick={() => {
                        //           setFilterValue("");
                        //           setReload(true);
                        //         }}
                        //       />
                        //     </IconButton>
                        //   </InputAdornment>
                        // ),
                       
                        }}
                      variant="standard"
                      />   
                        {filterValue ? (
                          <IconButton
                            aria-label="Add"
                            className={classes.serchByClear}
                            //style={{ position: "absolute", top: "5px", right: "0px" }}
                            onClick={() => {
                              setFilterValue("");
                              setReload(true);
                              //getSubRegionData();
                            }}
                          >
                            <ClearIcon style={{ fontSize: 10 }} />
                          </IconButton>
                        ) : ("")}

                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">
                      Total Records: <span className={classes.totalRecord}>{totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* VariableType Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={rows ? rows : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setReload(true);
                    setSorting(e);
                  }}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: openDeleteDialog(true),
                    }),
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Add & Edit Frequency Year Quarters Side Drawer */}
      <SideDrawer
        state={state}
        setState={setState}
        formComp={
          // Calling the AddVariableType
          <AddVariableType
            state={state}
            setState={setState}
            crudState={crudState}
            selectedItem={selectedItem}
            reload={reload}
            setReload={setReload}
          />
        }
      />

      {/* Alert Dialog - Confirmation for Delete */}
      
      <AlertDialog
          open={open}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Would you like to delete the selected item?`}
          action={
            <>
              <div className={classes.alertDialogbtn}>
                <Button onClick={closeDeleteDialog} autoFocus style={{marginRight:"10px"}}>No</Button>
                <Button onClick={handleDelete} color="secondary">Yes</Button>
              </div>
            </>
          }
        />
    </Box>
  );
};

export default TopVariableType;