// Default React, Router and Services Components
import React from "react";
import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import ApiService from "../../../Services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { toasterError } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
// Importing Custom Components
import MultiSelect from "../../../UIComponent/MultiSelect/MultiSelect";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { clearFilterReports, GetAssetData, GetAssetTypeData, setFilterReports, } from "../../../features/filter/filterSlice";

// Importing useStyles
import useStyles from "./SearchExport.styles";
import { setFiterValue, setTreeComm, } from "../../../features/filter/StateSlice";
import { callBasedOnLocation, callFromAssetType, callFromComm, callFromPriority, callSource } from "../../../Util/TreeAPI";
import Container from '../SourceManagement/TreeContainer';
import { red } from "@mui/material/colors";

const DataCollectionSearchExportFilter = (props) => {
    // Declaration of States and Component Variables Start Here
    const filterData = useSelector((store) => store.stateSlice.filterValue)
    const { filterReport, assetDropDownlist } = useSelector((store) => store.filter);
    const { treeComm } = useSelector((store) => store.stateSlice);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [jobCompanies, setJobCompanies] = React.useState([]);
    const [countryDropdownList, setCountryDropdownList] = React.useState([]);
    const [regionDropdownList, setRegionDropdownList] = React.useState([]);
    const [subRegionDropdownList, setSubRegionDropdownList] = React.useState([]);
    const [jobComms, setJobComms] = React.useState([]);
    const [dropDown, setDropDown] = React.useState({ reportingperoid: [], });
    const [disableFilters, setDisableFilters] = React.useState(false);
    const [jobSelection, setJobSelection] = React.useState([]);
    const [fromDate, setFromDate] = React.useState(props.fromDate);
    const [toDate, setToDate] = React.useState(props.toDate);
    const [keyToDate, setKeyToDate] = React.useState(false);
    const [keyFromoDate, setKeyFromDate] = React.useState(false);
    const [jobNames, setJobNames] = React.useState(null);
    const [filters, setFilters] = React.useState({
        JobName: '', company: [], asset: [], assetTypeIds: [], countryIds: [], regionIds: [], subRegionIds: [],
        commodityIds: [], statusIds: [], priorityIds: [], SearchText: ''
    });
    const [apiData, setApiData] = React.useState({
        companies: [],
        location: {},
        assetType: [],
        priority: [],
        commodity: [],
    });
    const [dependency, setDependency] = React.useState(null);
    const [selectedCompanies, setSelectedCompanies] = React.useState([]);

    // console.log(props.locationFilter, props.companyFilter, treeComm, "props.locationFilter, props.companyFilter, treeComm")
    // Declaration of States and Component Variables End Here
    // Function for Handle KeyUp
    const onChangeHandler = (key, value, reason) => {
        const pre = { ...filterReport };
        pre[key] = value;
        if (key === "commodity") {
            setDependency("commodity");

            setApiData({
                ...apiData,
                commodity: value,
            });
        } else if (key === "priority") {
            setDependency("priority");
            setApiData({
                ...apiData,
                priority: value,
            });
        } else if (key === "assetType") {
            setDependency("assetType");
            setApiData({
                ...apiData,
                assetType: value,
            });
        }
        dispatch(setFilterReports({ filterReport: pre }));
    };

    React.useEffect(() => {
        if (dependency === "company") {
            callSource(apiData)
                .then((res) => {
                    res.data.location.regions.forEach(el => {
                        for (let item of props.locationFilter) {
                            if (item.value === el.value) {
                                if (item.checked === true) {
                                    el.checked = true
                                }
                            }
                        }
                        el.children.forEach(it => {
                            for (let item of props.locationFilter) {
                                for (let items of item.children) {
                                    if (items.value === it.value) {
                                        if (items.checked === true) {
                                            it.checked = true
                                        }
                                    }
                                }
                            }
                            it.children.forEach(kt => {
                                for (let item of props.locationFilter) {
                                    for (let items of item.children) {
                                        for (let ite of items.children) {
                                            if (ite.value === kt.value) {
                                                if (ite.checked === true) {
                                                    kt.checked = true
                                                }
                                            }
                                        }
                                    }
                                }
                            })
                        })
                    })
                    props.setLocationFilter(res.data.location.regions);
                    props.setDataForFilter({
                        ...props.dataForFilter,
                        assetType: res.data.assetType,
                        commodity: res.data.commodity,
                        priority: res.data.priority,
                    });
                })
                .catch((err) => console.log(err));
        } else if (dependency === "location") {
            callBasedOnLocation(apiData)
                .then((res) => {
                    res.data.companies.forEach((el) => {
                        for (let i = 0; i < props.companyFilter.length; i++) {
                            if (props.companyFilter[i].value === el.value) {
                                if (props.companyFilter[i].checked === true) {
                                    el.checked = true;
                                }
                                el.children.forEach((item) => {
                                    for (
                                        let j = 0;
                                        j < props.companyFilter[i].children.length;
                                        j++
                                    ) {
                                        if (
                                            props.companyFilter[i].children[j].value === item.value
                                        ) {
                                            if (props.companyFilter[i].children[j].checked === true) {
                                                item.checked = true;
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    });

                    props.setCompanyFilter(res.data.companies);
                    props.setDataForFilter({
                        ...props.dataForFilter,
                        assetType: res.data.assetType,
                        commodity: res.data.commodity,
                        priority: res.data.priority,
                    });

                })
                .catch((err) => console.log(err));
        } else if (dependency === "assetType") {
            callFromAssetType(apiData)
                .then((res) => {
                    res.data.location.regions.forEach(el => {
                        for (let item of props.locationFilter) {
                            if (item.value === el.value) {
                                if (item.checked === true) {
                                    el.checked = true
                                }
                            }
                        }
                        el.children.forEach(it => {
                            for (let item of props.locationFilter) {
                                for (let items of item.children) {
                                    if (items.value === it.value) {
                                        if (items.checked === true) {
                                            it.checked = true
                                        }
                                    }
                                }
                            }
                            it.children.forEach(kt => {
                                for (let item of props.locationFilter) {
                                    for (let items of item.children) {
                                        for (let ite of items.children) {
                                            if (ite.value === kt.value) {
                                                if (ite.checked === true) {
                                                    kt.checked = true
                                                }
                                            }
                                        }
                                    }
                                }
                            })
                        })
                    })
                    res.data.companies.forEach((el) => {
                        for (let i = 0; i < props.companyFilter.length; i++) {
                            if (props.companyFilter[i].value === el.value) {
                                if (props.companyFilter[i].checked === true) {
                                    el.checked = true;
                                }
                                el.children.forEach((item) => {
                                    for (
                                        let j = 0;
                                        j < props.companyFilter[i].children.length;
                                        j++
                                    ) {
                                        if (
                                            props.companyFilter[i].children[j].value === item.value
                                        ) {
                                            if (props.companyFilter[i].children[j].checked === true) {
                                                item.checked = true;
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    });

                    props.setCompanyFilter(res.data.companies);

                    props.setLocationFilter(res.data.location.regions);
                    props.setDataForFilter({
                        ...props.dataForFilter,
                        commodity: res.data.commodity,
                        priority: res.data.priority,
                    });
                })
                .catch((err) => console.log(err));
        } else if (dependency === "priority") {
            callFromPriority(apiData)
                .then((res) => {
                    res.data.companies.forEach((el) => {
                        for (let i = 0; i < props.companyFilter.length; i++) {
                            if (props.companyFilter[i].value === el.value) {
                                if (props.companyFilter[i].checked === true) {
                                    el.checked = true;
                                }
                                el.children.forEach((item) => {
                                    for (
                                        let j = 0;
                                        j < props.companyFilter[i].children.length;
                                        j++
                                    ) {
                                        if (
                                            props.companyFilter[i].children[j].value === item.value
                                        ) {
                                            if (props.companyFilter[i].children[j].checked === true) {
                                                item.checked = true;
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    });
                    res.data.location.regions.forEach(el => {
                        for (let item of props.locationFilter) {
                            if (item.value === el.value) {
                                if (item.checked === true) {
                                    el.checked = true
                                }
                            }
                        }
                        el.children.forEach(it => {
                            for (let item of props.locationFilter) {
                                for (let items of item.children) {
                                    if (items.value === it.value) {
                                        if (items.checked === true) {
                                            it.checked = true
                                        }
                                    }
                                }
                            }
                            it.children.forEach(kt => {
                                for (let item of props.locationFilter) {
                                    for (let items of item.children) {
                                        for (let ite of items.children) {
                                            if (ite.value === kt.value) {
                                                if (ite.checked === true) {
                                                    kt.checked = true
                                                }
                                            }
                                        }
                                    }
                                }
                            })
                        })
                    })
                    props.setCompanyFilter(res.data.companies);

                    props.setLocationFilter(res.data.location.regions);
                    props.setDataForFilter({
                        ...props.dataForFilter,
                        assetType: res.data.assetType,
                        commodity: res.data.commodity,
                    });
                })
                .catch((err) => console.log(err));
        } else if (dependency === "commodity") {
            callFromComm(apiData)
                .then((res) => {
                    res.data.companies.forEach((el) => {
                        for (let i = 0; i < props.companyFilter.length; i++) {
                            if (props.companyFilter[i].value === el.value) {
                                if (props.companyFilter[i].checked === true) {
                                    el.checked = true;
                                }
                                el.children.forEach((item) => {
                                    for (
                                        let j = 0;
                                        j < props.companyFilter[i].children.length;
                                        j++
                                    ) {
                                        if (
                                            props.companyFilter[i].children[j].value === item.value
                                        ) {
                                            if (props.companyFilter[i].children[j].checked === true) {
                                                item.checked = true;
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    });
                    res.data.location.regions.forEach(el => {
                        for (let item of props.locationFilter) {
                            if (item.value === el.value) {
                                if (item.checked === true) {
                                    el.checked = true
                                }
                            }
                        }
                        el.children.forEach(it => {
                            for (let item of props.locationFilter) {
                                for (let items of item.children) {
                                    if (items.value === it.value) {
                                        if (items.checked === true) {
                                            it.checked = true
                                        }
                                    }
                                }
                            }
                            it.children.forEach(kt => {
                                for (let item of props.locationFilter) {
                                    for (let items of item.children) {
                                        for (let ite of items.children) {
                                            if (ite.value === kt.value) {
                                                if (ite.checked === true) {
                                                    kt.checked = true
                                                }
                                            }
                                        }
                                    }
                                }
                            })
                        })
                    })
                    props.setCompanyFilter(res.data.companies);
                    props.setLocationFilter(res.data.location.regions);
                    props.setDataForFilter({
                        ...props.dataForFilter,
                        assetType: res.data.assetType,
                        priority: res.data.priority,
                    });
                })
                .catch((err) => console.log(err));
        }
    }, [apiData]);



    /// <summary>
    /// Handler function for clearing a field.
    /// </summary>
    const clearFieldHandler = () => {
        // Check if props.setJobName is not null
        if (props.setJobName != null) {
            // Set the job name to an empty string
            props.setJobName('');
        }
        // Check if props.setFilterValues is not null
        if (props.setFilterValues != null) {
            // Set the filter values to an empty object
            props.setFilterValues({
                JobName: '', company: [], asset: [], assetTypeIds: [], countryIds: [], regionIds: [],
                subRegionIds: [], commodityIds: [], statusIds: [], priorityIds: [], SearchText: ''
            })
        }
        // Check if props.setReload is not null
        if (props.setReload != null) {
            // Toggle the reload state
            props.setReload(!props.reload);
        }
        // Set the selected job to null
        props.setSelectedJob(null)
        // Set the job names to null
        setJobNames(null);
        // Dispatch the action to clear the filter reports
        dispatch(clearFilterReports());
        // Enable the filters
        setDisableFilters(false);
        // Dispatch the action to set the filter values to an empty object
        dispatch(setFiterValue({
            JobName: '', company: [], asset: [], assetTypeIds: [], countryIds: [], regionIds: [],
            subRegionIds: [], commodityIds: [], statusIds: [], priorityIds: [], SearchText: ''
        }))
    };

    // Function to filter countries based on location filter
    const countryFilter = () => {
        let data = [] // Array to store filtered countries

        // Loop through each location filter
        for (let item of props.locationFilter) {
            // Loop through each child of the location filter
            for (let items of item.children) {
                // Loop through each child of the child of the location filter
                for (let ites of items.children) {
                    // Check if the child is checked
                    if (ites.checked === true) {
                        // Push the value of the checked child to the data array
                        data.push(ites.value)
                    }
                }
            }
        }

        return data // Return the filtered countries
    }
    // Function for Search Filter
    const searchFilter = () => {

        const formData = {
            JobName: props.JobName?.jobNoName,
            company: props.companyFilter
                .filter((el) => el.checked === true)
                .map((el) => el.value),
            asset: props.companyFilter
                .map((el) =>
                    el.children.filter((it) => it.checked === true).map((tk) => tk.value)
                )
                .filter((item) => item.length > 0)
                .flat(1),
            countryIds: countryFilter(),
            regionIds: props.locationFilter
                .filter((el) => el.checked === true)
                .map((el) => el.value),
            subRegionIds: props.locationFilter
                .map((el) =>
                    el.children.filter((it) => it.checked === true).map((tk) => tk.value)
                )
                .filter((item) => item.length > 0)
                .flat(1),
            CommodityIds: (filterReport.commodities || []).map((commo) => commo.commodityId),
            FromDate: fromDate, ToDate: toDate, Status: null,
            SearchText: ""
        };
        dispatch(setFiterValue(formData))
        props.setState({ ...props.state, right: false });
        props.setFilterValues(formData);
    };


    // Function for Getting Company Data
    const getAllCompanies = () => {
        ApiService.getAll("DropDown/GetCompanyDropDownFilter")
            .then((res) => {
                if (res.status === 200) { setJobCompanies(res.data); }
            })
            .catch((err) => {
                ErroHandling(err, function (res) {
                    toasterError(res)
                })
            });
    };

    // Function for Getting Commodity Data
    const getAllCommodities = () => {
        ApiService.getAll("DropDown/GetCommodityDropDownFilter")
            .then((res) => {
                if (res.status === 200) { setJobComms(res.data); }
            })
            .catch((err) => {
                ErroHandling(err, function (res) {
                    toasterError(res)
                })
            });
    };

    // Function for Getting Country Data
    const getAllCountries = () => {
        ApiService.getAll("DropDown/GetCountryDropDownFilter")
            .then((res) => {
                if (res.status === 200) { setCountryDropdownList(res.data); }
            })
            .catch((err) => {
                ErroHandling(err, function (res) {
                    toasterError(res)
                })
            });
    };

    // Function for Getting Region Data
    const getAllRegion = () => {
        ApiService.getAll("DropDown/GetRegionDropDownFilter")
            .then((res) => {
                if (res.status === 200) { setRegionDropdownList(res.data); }
                else { toasterError(`${res.data}`); }
            })
            .catch((err) => {
                ErroHandling(err, function (res) {
                    toasterError(res)
                })
            });
    };
    // Function for Getting Sub Region Data
    const getAllSubRegion = () => {
        ApiService.getAll("DropDown/GetSubRegionDropDownFilter")
            .then((res) => {
                if (res.status === 200) {
                    setSubRegionDropdownList(res.data);
                } else {
                    toasterError(`${res.data}`);
                }
            })
            .catch((err) => {
                ErroHandling(err, function (res) {
                    toasterError(res)
                })
            });
    };

    // Function for Getting Job Assets Data
    const GetAllJobAssets = () => {
        axios
            .get(process.env.React_App_baseUrl + "SearchAndExport/GetJobAssetDropDownForSearchAndExport", {
                headers: {
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                }
            })
            .then(function (response) {
                setJobSelection(response.data);
                if (props.JobName && props.JobName.jobId) {
                    let jobAsset = response.data.filter((e) => e.jobId === props.JobName.jobId)
                    setJobNames(jobAsset[0]);
                } else { setJobNames(null); }
            })
            .catch(function (error) {
                ErroHandling(error, function (res) {
                    toasterError(res)
                })
            });
    }
    const selectAJob = (e, value) => {
        props.setSelectedJob(value)
        setJobNames(!value ? null : value)
        props.setJobName(!value ? null : value);
        if (!value) { setDisableFilters(false); }
        else { setDisableFilters(true); }
    };

    // Declaration of React Hooks Start Here
    React.useEffect(() => {
        GetAllJobAssets();
        getAllCompanies();
        dispatch(GetAssetData());
        dispatch(GetAssetTypeData());
        getAllCommodities();
        getAllCountries();
        getAllRegion();
        getAllSubRegion();
    }, []);

    // Declaration of React Hooks End Here
    //console.log(props.dataForFilter, "props.setDataForFilter")
    // Function for Filters
    const onChangeComp = (currentNode, selectedNodes) => {

        setDependency("company");

        setSelectedCompanies(selectedNodes);

        props.companyFilter.forEach((el) => {
            if (currentNode.checked === true) {
                if (currentNode.value === el.value) {
                    el.checked = true;
                } else if (currentNode.parentValue === el.value) {
                    el.children.forEach((item) => {
                        if (currentNode.value === item.value) {
                            item.checked = true;
                        }
                    });
                }
            } else if (currentNode.checked === false) {
                if (currentNode.value === el.value) {
                    el.checked = false;
                } else if (currentNode.parentValue === el.value) {
                    el.children.forEach((item) => {
                        if (currentNode.value === item.value) {
                            item.checked = false;
                        }
                    });
                }
            }
        });

        const firstParent = props.companyFilter
            .map((el) => {
                let data = {
                    label: el.label,
                    value: el.value,
                    children: el.children.filter((item) => item["checked"] === true),
                };
                return data;
            })
            .filter((el) => el.children.length > 0);

        //console.log(firstParent, "firstParent");

        setApiData({
            ...apiData,
            companies:
                props.companyFilter.filter((el) => el["checked"] === true).length > 0
                    ? props.companyFilter.filter((el) => el["checked"] === true)
                    : firstParent,
        });
    };


    const onChangeLoc = (currentNode, selectedNodes) => {
        setDependency("location");


        if (selectedNodes.length === 0) {
            props.locationFilter.forEach((el) => {
                el["checked"] = false;
                el.children.forEach((it) => {
                    it["checked"] = false;
                    it.children.forEach((kt) => {
                        kt["checked"] = false;
                    });
                });
            });
        }

        if (currentNode.checked === true) {
            if (currentNode.grantParentValue) {
                props.locationFilter.forEach((el) => {
                    el.children.forEach((first) => {
                        first.children.forEach((second) => {
                            if (currentNode.value === second.value) {
                                second.checked = true;
                                filters.countryIds.push(currentNode.value)
                            }
                        });
                    });
                });
            } else if (currentNode.parentValue) {
                props.locationFilter.forEach((el) => {
                    el.children.forEach((first) => {
                        if (currentNode.value === first.value) {
                            first.checked = true;
                        }

                    });
                });
            } else if (currentNode.value) {
                props.locationFilter.forEach((el) => {
                    if (currentNode.value === el.value) {
                        //console.log(el, "currentNode");
                        el.checked = true;
                    }
                });
            }
            // currentNodes(currentNode, currentNode.value)
        } else if (currentNode.checked === false) {
            if (
                currentNode.value &&
                !currentNode.parentValue &&
                !currentNode.grantParentValue
            ) {
                props.locationFilter.forEach((el) => {
                    if (el.value === currentNode.value) {
                        // console.log(el, "currentNode false");
                        el.checked = false;
                    }
                });
            }
            if (
                currentNode.value &&
                currentNode.parentValue &&
                !currentNode.grantParentValue
            ) {
                props.locationFilter.forEach((el) => {
                    el.children.forEach((first) => {
                        if (currentNode.value === first.value) {
                            first.checked = false;
                        }
                    });
                });
            } else {
                props.locationFilter.forEach((el) => {
                    if (
                        el.children.map((it) => it.parentValue)[0] === currentNode.value
                    ) {
                        el.children.forEach((item) => {
                            if (item.parentValue === currentNode.value) {
                                item.checked = false;
                            }
                        });
                    }
                });
            }

            if (
                currentNode.value &&
                currentNode.parentValue &&
                currentNode.grantParentValue
            ) {
                props.locationFilter.forEach((el) => {
                    el.children.forEach((first) => {
                        first.children.forEach((second) => {
                            if (currentNode.value === second.value) {
                                second.checked = false;
                            }
                        });
                    });
                });
            }
        }

        const firstParent = props.locationFilter
            .map((el) => {
                let data = {
                    label: el.label,
                    value: el.value,
                    children: el.children.filter((item) => item["checked"] === true),
                };
                return data;
            })
            .filter((el) => el.children.length > 0);

        const grandParent = props.locationFilter
            .map((el) => {
                let data = {
                    label: el.label,
                    value: el.value,
                    children: el.children.map((item) => {
                        let items = {
                            label: item.label,
                            value: item.value,
                            children: item.children.filter(
                                (item) => item["checked"] === true
                            ),
                        };

                        return items;
                    })
                };

                return data;
            })

        // console.log(grandParent, "grandParent")
        const finalChecker = () => {
            let data = [];
            for (let i = 0; i < grandParent.length; i++) {
                for (let j = 0; j < grandParent[i].children.length; j++) {
                    if (grandParent[i].children[j].children.length > 0) {
                        data.push(grandParent[i]);
                    }
                }
            }
            return data;
        };
        setApiData({
            ...apiData,
            location: {
                regions:
                    props.locationFilter.filter((el) => el["checked"] === true).length > 0
                        ? props.locationFilter.filter((el) => el["checked"] === true)
                        : firstParent.length > 0
                            ? firstParent
                            : finalChecker(),
            },
        });


    };

    return (

        // Layout for Add and Edit
        <Box className={classes.popupArea}>

            {/* Title for Side Drawer */}
            <Box className={classes.popupHeading}>
                <Typography id="modal-modal-title" variant="h5">
                    Filter
                </Typography>
            </Box>

            {/* Add & Edit Currency Form */}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        value={props.selectedJob}
                        id="combo-box-demo"
                        options={jobSelection}
                        getOptionLabel={(option) => option.jobNoName}
                        onChange={selectAJob}
                        fullWidth
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Job ID - Job Name" variant="standard"
                                sx={{
                                    input: {
                                        "&::placeholder": {
                                            opacity: 0.75,
                                        },
                                        "&::after": {
                                            borderBottom: '2px solid rgba(0, 0, 0, 0.75) !important',
                                            opacity: 0.75,
                                        }

                                    },
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Container
                        data={props.companyFilter}
                        onChange={onChangeComp}
                        showPartiallySelected={true}
                        texts={{ placeholder: "Company" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Container
                        data={props.locationFilter}
                        onChange={onChangeLoc}
                        showPartiallySelected={true}
                        texts={{ placeholder: "Location" }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MultiSelect
                        onChange={onChangeHandler}
                        disabled={disableFilters}
                        value={filterReport.commodities}
                        items={props.dataForFilter.commodity ? props.dataForFilter.commodity : []}
                        label="Commodities"
                        selectAllLabel={{ commodityName: "Select all" }}
                        getOptionLabel={(option) => option.commodityName}
                        getOptionSelected={(option, anotherOption) =>
                            option.commodityName === anotherOption.commodityName
                        }
                        role="commodities"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="left">
                        <Grid item xs={'auto'}>
                            <Button variant="contained" className="dummy-btn" color="accent5" onClick={searchFilter}>
                                Filter
                            </Button>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Button variant="contained" className="dummy-btn reset-color" color="accent3" onClick={clearFieldHandler}>
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DataCollectionSearchExportFilter;