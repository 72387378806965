// Default React, Router and Services Components
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

// Importing Custom Components
import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import MultiSelect from "../../../../UIComponent/MultiSelect/MultiSelect";
import ApiService from "../../../../Services/ApiService";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
import {
  clearFilterJobRecords,
  clearFilterMeritQCReport,
  clearFilterQC,
  GetAssetData,
  setFilterJobRecords,
  setFilterMeritQCReports,
  setFilterQC,
} from "../../../../features/filter/filterSlice";
import {
  callBasedOnLocation,
  callFromAssetType,
  callFromComm,
  callFromPriority,
  callSource,
} from "../../../../Util/TreeAPI";

// Importing useStyles
import useStyles from "./MeritQCFilter.styles";
import "react-dropdown-tree-select/dist/styles.css";
import "../../../../../src/index.scss";
import "../../../../Resources/scss/layout.scss";
import {
  clearMeritQCFilter,
  setMeritQCFilter,
} from "../../../../features/filter/StateSlice";
import Container from "../../SourceManagement/TreeContainer";
import { FilterContext } from "../../../DataCollection/DashboardReports/Context/Context";
import DatePicker from "../../../../UIComponent/DatePicker";
import { Windows } from "react-bootstrap-icons";

const FilterForm = (props) => {
  const classes = useStyles();
  const { filterMeritQCReports, assetDropDownlist } = useSelector(
    (store) => store.filter
  );
  const {
    companyList,
    setCompanyList,
    locationQC,
    setLocationQC,
    commodsQC,
    setCommodsQC,
    setFilterValueContext,
  } = useContext(FilterContext);
  const dispatch = useDispatch();

  // Declaration of States and Component Variables Start Here
  const [allState, setAllState] = React.useState(props);
  const [status, setStatus] = React.useState([]);
  const [jobComms, setJobComms] = React.useState([]);
  const [prior, setPrior] = React.useState([]);
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(props.fromDate);
  const [toDate, setToDate] = React.useState(props.toDate);
  const [meritqcDate, setMeritQcDate] = React.useState(props.meritQcDate);
  const [keyToDate, setKeyToDate] = React.useState(false);
  const [keyFromoDate, setKeyFromDate] = React.useState(false);
  const [keymeritqcDate, setKeyMeritQcDate] = React.useState(false);
  const [filters, setFilters] = React.useState({
    company: [],
    asset: [],
    assetTypeIds: [],
    countryIds: [],
    regionIds: [],
    subRegionIds: [],
    commodityIds: [],
    statusIds: [],
    priorityIds: [],
  });
  const [apiData, setApiData] = React.useState({
    companies: [],
    location: {},
    assetType: [],
    priority: [],
    commodity: [],
  });

  const [dependency, setDependency] = React.useState(null);
  const [subCompDependency, setSubCompDependency] = React.useState(false);
  const [subLocDependency, setSubLocDependency] = React.useState(false);
  const [assignedto, setAssignedTo] = React.useState([]);
  const [meritqc, setMeritQc] = React.useState([]);
  // Declaration of States and Component Variables End Here

  const onChangeHandler = (key, value, reason) => {
    const pre = { ...filterMeritQCReports };
    pre[key] = value;
    /*   if (key === "commodity") {
      setDependency("commodity");

      setApiData({
        ...apiData,
        commodity: value,
      });
    } else if (key === "priority") {
      setDependency("priority");
      setApiData({
        ...apiData,
        priority: value,
      });
    } else if (key === "assetType") {
      setDependency("assetType");
      setApiData({
        ...apiData,
        assetType: value,
      });
    } */

    dispatch(setFilterMeritQCReports({ filterMeritQCReports: pre }));
  };

 // This function is used to clear all the filters and reset the filter values
const clearFieldHanlder = () => {
  setFilters({ // Setting all the filters to empty array
    filters,
    company: [],
    asset: [],
    assetTypeIds: [],
    countryIds: [],
    regionIds: [],
    subRegionIds: [],
    commodityIds: [],
    statusIds: [],
    priorityIds: [],
  });
  props.setReload(!props.reload); // Setting reload value to opposite of current value
  sessionStorage.removeItem("selectedFromDate"); // Removing selected from date from session storage
  sessionStorage.removeItem("selectedToDate"); // Removing selected to date from session storage
  sessionStorage.removeItem("selectedMeritQCDate") // Removing selected merit QC date from session storage
  setKeyFromDate((k) => !k); // Setting key from date to opposite of current value
  setKeyToDate((t) => !t); // Setting key to date to opposite of current value
  setKeyMeritQcDate((r) => !r); // Setting key merit QC date to opposite of current value
  props.resetTreeFilter(); // Resetting tree filter
  dispatch(clearFilterMeritQCReport()); // Dispatching clear filter merit QC report
  props.setFilterValue(""); // Setting filter value to empty string
  props.setFilterValues(""); // Setting filter values to empty string
  props.getFilterData(); // Getting filter data
  setCompanyList([]); // Setting company list to empty array
  setLocationQC([]); // Setting location QC to empty array
  setDependency(null); // Setting dependency to null
  setSubCompDependency(false); // Setting sub component dependency to false
  setSubLocDependency(false); // Setting sub location dependency to false
  setFilterValueContext(null); // Setting filter value context to null    
  window.location.reload(); // Reloading the window 
};


  //Function for fetch all status
  const getAllStatus = () => {
    ApiService.getAll("DropDown/GetStatusDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setStatus(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

// This function is used to get all commodities from the API
const getAllCommodities = () => {
  // Call ApiService to get all commodities
  ApiService.getAll("DropDown/GetCommodityDropDownFilter")
    .then((res) => {
      // If response status is 200, set job commodities
      if (res.status === 200) {
        setJobComms(res.data);
      } else {
        // Else call toasterError with response data
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Call ErrorHandling with error and a callback function
      ErroHandling(err, function (res) {
        // Call toasterError with response
        toasterError(res);
      });
    });
  }

// This function is used to get all assigned users from the API
const getAllAssignedUsers = () => {
  // Call the ApiService to get all assigned users
  ApiService.getAll("CompanyOwnershipReport/GetAssignedToDropdown")
    .then((res) => {
      // If the response status is 200, set the assigned users in the state
      if (res.status === 200) {
        setAssignedTo(res.data);
      } else {
        // If the response status is not 200, show an error message
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Handle any errors that occur during the API call
      ErroHandling(err, function (res) {
        toasterError(res);
      });
    });
};

// This function is used to get all MeritQC users from the API
const getAllMeritQcUsers = () => {
  // Call the ApiService to get all MeritQC users
  ApiService.getAll("CompanyOwnershipReport/GetMeritQCDropdown")
    .then((res) => {
      // If the response status is 200, set the MeritQC data
      if (res.status === 200) {
        setMeritQc(res.data);
      } else {
        // Else call the toasterError function with the response data
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Call the ErrorHandling function with the error and a callback function
      ErroHandling(err, function (res) {
        // Call the toasterError function with the response
        toasterError(res);
      });
  });
};

  //Function for fetch all Priority
  const getAllPriority = () => {
    ApiService.getAll("DropDown/GetPriorityDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setPrior(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

  // Declaration of React Hooks Start Here

  React.useEffect(() => {
    setAllState([...props.companyList]);
  }, [props]);
  React.useEffect(() => {
    // getAllStatus();
    // dispatch(GetAssetData());
    getAllCommodities();
    getAllAssignedUsers();
    getAllMeritQcUsers();
    // getAllPriority();
  }, []);

  React.useEffect(() => {
    if (dependency === "company") {
      callSource(apiData)
        .then((res) => {
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationQC) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationQC) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationQC) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          props.setLocationQC(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "location") {
      callBasedOnLocation(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyList.length; i++) {
              if (props.companyList[i].value === el.value) {
                if (props.companyList[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyList[i].children.length;
                    j++
                  ) {
                    if (props.companyList[i].children[j].value === item.value) {
                      if (props.companyList[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyList(res.data.companies);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
          setSubCompDependency(true);
        })
        .catch((err) => console.log(err));
    } else if (dependency === "assetType") {
      callFromAssetType(apiData)
        .then((res) => {
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationQC) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationQC) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationQC) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyList.length; i++) {
              if (props.companyList[i].value === el.value) {
                if (props.companyList[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyList[i].children.length;
                    j++
                  ) {
                    if (props.companyList[i].children[j].value === item.value) {
                      if (props.companyList[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyList(res.data.companies);
          props.setLocationQC(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "priority") {
      callFromPriority(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyList.length; i++) {
              if (props.companyList[i].value === el.value) {
                if (props.companyList[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyList[i].children.length;
                    j++
                  ) {
                    if (props.companyList[i].children[j].value === item.value) {
                      if (props.companyList[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationQC) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationQC) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationQC) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          props.setCompanyList(res.data.companies);

          props.setLocationQC(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "commodity") {
      callFromComm(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyList.length; i++) {
              if (props.companyList[i].value === el.value) {
                if (props.companyList[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyList[i].children.length;
                    j++
                  ) {
                    if (props.companyList[i].children[j].value === item.value) {
                      if (props.companyList[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationQC) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationQC) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationQC) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          props.setCompanyList(res.data.companies);
          props.setLocationQC(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [apiData]);

  // Declaration of React Hooks End Here

  //Function for company selection
  const onChangeComp = (currentNode, selectedNodes) => {
    setDependency("company");

    setSelectedCompanies(selectedNodes);

    props.companyList.forEach((el) => {
      if (currentNode.checked === true) {
        if (currentNode.value === el.value) {
          el.checked = true;
        } else if (currentNode.parentValue === el.value) {
          el.children.forEach((item) => {
            if (currentNode.value === item.value) {
              item.checked = true;
            }
          });
        }
      } else if (currentNode.checked === false) {
        if (currentNode.value === el.value) {
          el.checked = false;
        } else if (currentNode.parentValue === el.value) {
          el.children.forEach((item) => {
            if (currentNode.value === item.value) {
              item.checked = false;
            }
          });
        }
      }
    });

    const firstParent = props.companyList
      .map((el) => {
        let data = {
          label: el.label,
          value: el.value,
          children: el.children.filter((item) => item["checked"] === true),
        };
        return data;
      })
      .filter((el) => el.children.length > 0);

    setApiData({
      ...apiData,
      companies:
        props.companyList.filter((el) => el["checked"] === true).length > 0
          ? props.companyList.filter((el) => el["checked"] === true)
          : firstParent,
    });
  };

  //Function for location selection
  const onChangeLoc = (currentNode, selectedNodes) => {
    setDependency("location");
    if (selectedNodes.length === 0) {
      props.locationQC.forEach((el) => {
        el["checked"] = false;
        el.children.forEach((it) => {
          it["checked"] = false;
          it.children.forEach((kt) => {
            kt["checked"] = false;
          });
        });
      });
    }

    if (currentNode.checked === true) {
      if (currentNode.grantParentValue) {
        props.locationQC.forEach((el) => {
          el.children.forEach((first) => {
            first.children.forEach((second) => {
              if (currentNode.value === second.value) {
                second.checked = true;
                filters.countryIds.push(currentNode.value);
              }
            });
          });
        });
      } else if (currentNode.parentValue) {
        props.locationQC.forEach((el) => {
          el.children.forEach((first) => {
            if (currentNode.value === first.value) {
              first.checked = true;
            }
          });
        });
      } else if (currentNode.value) {
        props.locationQC.forEach((el) => {
          if (currentNode.value === el.value) {
            el.checked = true;
          }
        });
      }
    } else if (currentNode.checked === false) {
      if (
        currentNode.value &&
        !currentNode.parentValue &&
        !currentNode.grantParentValue
      ) {
        props.locationQC.forEach((el) => {
          if (el.value === currentNode.value) {
            el.checked = false;
          }
        });
      }
      if (
        currentNode.value &&
        currentNode.parentValue &&
        !currentNode.grantParentValue
      ) {
        props.locationQC.forEach((el) => {
          el.children.forEach((first) => {
            if (currentNode.value === first.value) {
              first.checked = false;
            }
          });
        });
      } else {
        props.locationQC.forEach((el) => {
          if (
            el.children.map((it) => it.parentValue)[0] === currentNode.value
          ) {
            el.children.forEach((item) => {
              if (item.parentValue === currentNode.value) {
                item.checked = false;
              }
            });
          }
        });
      }

      if (
        currentNode.value &&
        currentNode.parentValue &&
        currentNode.grantParentValue
      ) {
        props.locationQC.forEach((el) => {
          el.children.forEach((first) => {
            first.children.forEach((second) => {
              if (currentNode.value === second.value) {
                second.checked = false;
              }
            });
          });
        });
      }
    }

    const firstParent = props.locationQC
      .map((el) => {
        let data = {
          label: el.label,
          value: el.value,
          children: el.children.filter((item) => item["checked"] === true),
        };
        return data;
      })
      .filter((el) => el.children.length > 0);

    const grandParent = props.locationQC.map((el) => {
      let data = {
        label: el.label,
        value: el.value,
        children: el.children.map((item) => {
          let items = {
            label: item.label,
            value: item.value,
            children: item.children.filter((item) => item["checked"] === true),
          };

          return items;
        }),
      };

      return data;
    });

    const finalChecker = () => {
      let data = [];
      for (let i = 0; i < grandParent.length; i++) {
        for (let j = 0; j < grandParent[i].children.length; j++) {
          if (grandParent[i].children[j].children.length > 0) {
            data.push(grandParent[i]);
          }
        }
      }
      return data;
    };
    setApiData({
      ...apiData,
      location: {
        regions:
          props.locationQC.filter((el) => el["checked"] === true).length > 0
            ? props.locationQC.filter((el) => el["checked"] === true)
            : firstParent.length > 0
            ? firstParent
            : finalChecker(),
      },
    });
  };

  //Function for country selection
  const countryFilter = () => {
    let data = [];
    for (let item of props.locationQC) {
      for (let items of item.children) {
        for (let ites of items.children) {
          if (ites.checked === true) {
            data.push(ites.value);
          }
        }
      }
    }

    return data;
  };

  //Function for submit click
  const submitFilter = () => {
    const formData = {
      /* company: props.companyList
        .filter((el) => el.checked === true)
        .map((el) => el.value),
      asset: props.companyList
        .map((el) =>
          el.children.filter((it) => it.checked === true).map((tk) => tk.value)
        )
        .filter((item) => item.length > 0)
        .flat(1),
      countryIds: countryFilter(),
      regionIds: props.locationQC
        .filter((el) => el.checked === true)
        .map((el) => el.value),
      subRegionIds: props.locationQC
        .map((el) =>
          el.children.filter((it) => it.checked === true).map((tk) => tk.value)
        )
        .filter((item) => item.length > 0)
        .flat(1),
      assetTypeIds: filterMeritQCReports.assetType.map((el) => el.assetTypeId), */
      commodityIds: filterMeritQCReports.commodities.map(
        (el) => el.commodityId
      ),
      AssignedtoId: (filterMeritQCReports.assignedto || []).map(
        (e) => e.userId
      ),
      FromDate: fromDate|| localStorage.getItem("selectedFromDate"),
      ToDate: toDate ||localStorage.getItem("selectedToDate"),
      MeritQCId: (filterMeritQCReports.meritqcuser || []).map(
        (e) => e.userId
      ),
      MeritQcCompletionDate: meritqcDate ||localStorage.getItem("selectedMeritQCDate"),
      //statusIds: filterMeritQCReports.status.map((el) => el.id),
      //priorityIds: filterMeritQCReports.priority.map((el) => el.priorityId),
    };
    // this data will be send to the body api
    setFilterValueContext(formData);
    setCompanyList(props.companyList);
    setLocationQC(props.locationQC);
    props.setFilterValue(formData);
    dispatch(setMeritQCFilter(formData));
    props.setSubmitValues(!props.submitValues);
    props.setState({ ...props.state, right: false });
  };

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Add & Edit Currency Form */}
      <Grid container spacing={4}>
        {/* <Grid item xs={12}>
          <Container
            data={props.companyList}
            onChange={onChangeComp}
            showPartiallySelected={true}
            texts={{ placeholder: "Company" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Container
            data={props.locationQC}
            onChange={onChangeLoc}
            showPartiallySelected={true}
            texts={{ placeholder: "Location" }}
          />
        </Grid> */}
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterMeritQCReports.commodities}
            items={
              props.dataForFilter.commodity ? props.dataForFilter.commodity : []
            }
            label="Commodity"
            selectAllLabel={{ commodityName: "Select all" }}
            getOptionLabel={(option) => option.commodityName}
            getOptionSelected={(option, anotherOption) =>
              option.commodityName === anotherOption.commodityName
            }
            role="commodities"
          />
        </Grid>
        {console.log("Dashboard Reports", filterMeritQCReports)}
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterMeritQCReports.assignedto}
            items={assignedto}
            label="Assigned To"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            role="assignedto"
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            labelTitle="From Date"
            variant="standard"
            defaultDate={fromDate|| localStorage.getItem("selectedFromDate")}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date =
                selectedDate.getFullYear() +
                "-" +
                ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + selectedDate.getDate()).slice(-2);
              setFromDate(date);
              localStorage.setItem("selectedFromDate", date);
            }}
            past={false}
            key={keyFromoDate}
            clearable={true}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            labelTitle="To Date"
            variant="standard"
            past={false}
            minDates={fromDate || localStorage.getItem("selectedFromDate")}
            defaultDate={toDate || localStorage.getItem("selectedToDate")}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date =
                selectedDate.getFullYear() +
                "-" +
                ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + selectedDate.getDate()).slice(-2);
              setToDate(date);
             localStorage.setItem("selectedToDate", date);
            }}
            key={keyToDate}
            clearable={true}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterMeritQCReports.meritqcuser}
            items={meritqc}
            label="Merit QC"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            role="meritqcuser"
          />
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            labelTitle="Merit QC Completion Date"
            variant="standard"
            defaultDate={meritqcDate|| localStorage.getItem("selectedMeritQCDate")}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date =
                selectedDate.getFullYear() +
                "-" +
                ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + selectedDate.getDate()).slice(-2);
              setMeritQcDate(date);
              localStorage.setItem("selectedMeritQCDate", date);
            }}
            past={false}
            key={keymeritqcDate}
            clearable={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn"
                color="accent5"
                onClick={submitFilter}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn reset-color"
                color="accent3"
                onClick={clearFieldHanlder}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterForm;
