// Default React, useState and Material Components 
import React, { useState } from "react";
import { Box, Autocomplete, Button, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import * as Validations from '../../../common/validation'
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";
import "../../../Resources/scss/layout.scss";

const AddEditAssetVariableCount = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [allAssets, setAllAssets] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [defaultAssetValue, setDefaultAssetValue] = useState(null);
  const [defaultCompanyValue, setDefaultCompanyValue] = useState(null);
  const [companyIdforAsset, setCompanyIdForAsset] = React.useState(null);
  const [triggerAssetDropDown, setTriggerAssetDropDown] = React.useState(false);
  const [variableCountData, setVariableCountData] = useState({
    id: 0, companyId: 0, company: '', asset: '', variableCount: 0
  });
  // Declaration of States and Component Variables End Here

  // Get Companies list options
  React.useEffect(() => {
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCruCompanyDropdown", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then(function (response) {
        setCompanies(response.data);
      });
  }, []);

  React.useEffect(() => {
    if (props.crudState === "Edit") {
      setDefaultAssetValue(null);
      companies.forEach((rData, i) => {
        if (rData.crucompanyId === props.formData.companyId) {

          setDefaultCompanyValue(companies[i]);

          setVariableCountData({
            ...variableCountData,
            company:
              rData && rData.cruCompanyName ? rData.cruCompanyName : "",
            companyId: rData && rData.crucompanyId ? rData.crucompanyId : null,

          });

          setCompanyIdForAsset(props.formData.companyId);
          setTriggerAssetDropDown(!triggerAssetDropDown);
        }
      })
    }
    if (props.crudState === "Add") {

      setDefaultAssetValue({ ...null });
      setVariableCountData({
        ...variableCountData,
        asset: "",
        id: null
      });
    }

  }, [companies]);

  React.useEffect(() => {
    if (props.crudState === "Edit") {
      setDefaultAssetValue({ ...null });
      allAssets.forEach((rData, i) => {
        if (rData.assetID === props.formData.id) {
          setDefaultAssetValue(allAssets[i]);
          setVariableCountData({
            ...variableCountData,
            asset:
              rData && rData.assetName ? rData.assetName : "",
            id: rData && rData.assetID ? rData.assetID : null

          });
        }

      })
    }
    else if (props.crudState === "Add") {
      setVariableCountData({
        ...variableCountData,
        asset: "",
        id: null
      });
    }
  }, [allAssets]);

// This function is used to set the dropdown of assets based on the crucompanyId
  const assetDropDown = (crucompanyId) => {
    // Set all assets to an empty array
    setAllAssets([]);
    // Make a GET request to the specified URL with the given parameters
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCruAssetDropdown",
        {
          headers: {
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          },
          params: {
            CrucompanyId: crucompanyId
          }
        })
      .then(
        // If the request is successful, set the response data to all assets
        (response) => {
          setAllAssets(response.data)
          
        },
         // If the request fails, log the error
        (error) => {
          
        }
      );
  };

  React.useEffect(() => {
    assetDropDown(companyIdforAsset);
  }, [triggerAssetDropDown]);

  //Form Validation 
  const validation = (formData) => {
    if (formData.company === null || formData.company === 0 || formData.company === '') {
      toasterError("Please select company name");
      return false;
    }
    if (formData.asset === null || formData.asset === 0 || formData.asset === '') {
      toasterError("Please select asset name");
      return false;
    }
    if (formData.variableCount === '') {
      toasterError("Please enter variable name");
      return false;
    }
    if (!Validations.onlyNum(formData.variableCount)) {
      toasterError("Please enter a valid variable");
    }
    return true;
  }

  // This function is used to register the asset variable count
  const registerAssetVariableCount = () => {
    // Set loading to true
    setLoading(true);
    // Check if validation and onlyNum validations are successful
    if (validation(variableCountData) && Validations.onlyNum(variableCountData.variableCount)) {
      // Make a patch request to save the CRU Asset Variable
      axios.patch(process.env.React_App_baseUrl + "managedata/SaveCRUAssetVariable", variableCountData, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
        .then((res) => {
          // If response status is 200, show success message and hide side drawer
          if (res.status === 200) {
            toasterSuccess("Asset Variable count details added successfully!");
            setLoading(false);
            props.hideSideDrawer();
          }
        })
        .catch((err) => {
          // Handle errors
          ErroHandling(err, function (res) {
            toasterError(res)
          })
          setLoading(false);
        });
    } else {
      // Set loading to false
      setLoading(false);
    }
  };

// This function updates the asset variable count
const updateAssetVariableCount = () => {
  // Set loading to true
  setLoading(true);
  // Check if validation and onlyNum validations are successful
  if (validation(variableCountData) && Validations.onlyNum(variableCountData.variableCount)) {
    // Make a patch request with the given data
    axios.patch(process.env.React_App_baseUrl + "managedata/SaveCRUAssetVariable", variableCountData, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then((res) => {
        // If response is successful, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("Asset Variable count details updated successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // Handle errors
        ErroHandling(err, function (res) {
          toasterError(res)
        })
        setLoading(false);
      });

  } else {
    // Set loading to false if validation fails
    setLoading(false);
  }
};

  return (
    
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Asset - Variable Count
        </Typography>
      </Box>

      {/* Add & Edit Asset Variable Count Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={companies}
            value={defaultCompanyValue}
            getOptionLabel={(option) => option.cruCompanyName != undefined ? option.cruCompanyName : ""}
            onChange={(e, value) => {
              if (value != null) {
                setVariableCountData({
                  ...variableCountData,
                  company:
                    value && value.cruCompanyName ? value.cruCompanyName : "",
                  companyId: value && value.crucompanyId ? value.crucompanyId : null
                });
                if (value != null && value.crucompanyId != null) {
                  companies.forEach((rData, i) => {
                    if (rData.crucompanyId === value.crucompanyId) {
                      setDefaultCompanyValue(companies[i]);
                    }
                  });
                }
              }
              // on clear dropdown clear the following code executes
              else {
                setVariableCountData({
                  ...variableCountData,
                  company: "",
                  companyId: null,
                  asset: ''
                });
                setDefaultCompanyValue({ ...null });
              }
              setCompanyIdForAsset(value != null ? value.crucompanyId : 0);
              setTriggerAssetDropDown(!triggerAssetDropDown);
              setDefaultAssetValue({ ...null });
            }
            }
            fullWidth
            renderInput={(params) => (
              <TextField {...params} placeholder="Company" variant="standard"
                sx={{
                  input: {
                    "&::placeholder": {
                      opacity: 1,
                    },
                    "&::after": {
                      borderBottom: '2px solid rgba(0, 0, 0, 0.75) !important',
                      opacity: 0.75,
                    }
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            value={defaultAssetValue}
            id="combo-box-demo"
            options={allAssets}
            getOptionLabel={(option) => option.assetName != undefined ? option.assetName : ""}
            onChange={(e, value) => {
              if (value != null) {
                allAssets.forEach((rData, i) => {
                  if (rData.assetID === value.assetID) {
                    setDefaultAssetValue(allAssets[i]);
                  }
                })
                setVariableCountData({
                  ...variableCountData,
                  asset:
                    value && value.assetName ? value.assetName : "",
                  id: value && value.assetID ? value.assetID : null

                });
              }
              else {
                setVariableCountData({
                  ...variableCountData,
                  asset: "",
                  id: null

                });
              }
            }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} placeholder="Asset Name" variant="standard"
                sx={{
                  input: {
                    "&::placeholder": {
                      opacity: 1,
                    },
                    "&::after": {
                      borderBottom: '2px solid rgba(0, 0, 0, 0.75) !important',
                      opacity: 0.75,
                    }
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            required
            fullWidth
            id="standard-basic"
            name="variableCount"
            size="small"
            label="Variable Count"
            placeholder="Variable"
            variant="standard"
            inputProps={{ maxLength: 100 }}
            value={props.crudState === "Add" ? null : (props.formData || {}).variableCount}
            onChange={(e) => {
              props.setFormData({ ...props.formData, variableCount: e.target.value });
              setVariableCountData({ ...variableCountData, variableCount: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>
        {props.crudState === "View" ? null : (
          <Grid item xs={12} sx={{ mt: -1 }}>
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={'auto'}>
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Add" ? registerAssetVariableCount : updateAssetVariableCount}
                  isLoading={loading} />
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant="contained" color="accent3" onClick={() => props.setState({ ...props.state, right: false })}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AddEditAssetVariableCount;