// Default React, Router and Services Components
import React, { useState, useEffect } from 'react';
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import { Link } from 'react-router-dom';

// Importing Custom Components
import AdobeViewer from './AdobeViewer';
import BaseLayout from '../../../UIComponent/BaseLayout';
import EmptyState from "../../../UIComponent/EmptyState";

// Importing useStyles
import useStyles from "./jobManagement.styles";

// Import Fluent UI Icons
import { ChevronRightSmallIcon, FileOffIcon } from "@fluentui/react-icons-mdl2";
import CommonService from '../../../Services/CommonService';
import { LoaderWrapper } from '../../../UIComponent/Progress';

const OwnershipChangesFilePreview = (props) => {

    //CommonService.AddTokenFromLocalStorageToSessionStorage();
    // if(localStorage.getItem("filepreviewaction")!==null){
    //     localStorage.removeItem("filepreviewaction"); 
    // }

    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    const [adobeData, setAdobeData] =useState(JSON.parse(localStorage.getItem("filePreview")));
    const [isLoading, setLoading] = useState(true);
// removed the code not required.


    // Declaration of States and Component Variables End Here


    return (
        // Page Layout Start Here
        <Box>
            <Grid container maxWidth justifyContent="right" alignItems="center">
                {/* Breadcrumbs Menu Start Here */}
                <Grid item xs={12} className="page-title">
                    <h2>File Preview</h2>
                    <Breadcrumbs
                        separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/sourcemanagement" }}>Home</Link>
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/createjob" }}>Job Management</Link>
                        <Typography color="text.primary">File Preview</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* Breadcrumbs Menu End Here */}

                {/* Baselayout Start Here */}
                <Grid item xs={12}>
                    <BaseLayout>
                        <Box className={classes.addBorder}>
                            {/* Adobe Viewer - File Preview /> */}
                            <Grid item xs={12}>
                                <LoaderWrapper loading={isLoading}>
                                    {adobeData !== null ?
                                        (<AdobeViewer 
                                            fileName={adobeData.fileName}
                                            pageNumber={adobeData.pageNumber}
                                            crudState={adobeData.crudState}
                                            isLoading={(iscomplete) => {
                                                setLoading(iscomplete)
                                            }}
                                        >
                                        </AdobeViewer>) :
                                        <Box sx={{ mb: 3 }}>
                                            <EmptyState
                                                icon={<FileOffIcon style={{ color: "#db9400", fontSize: "40px" }} />}
                                                title="No File Found" />
                                        </Box>
                                    }
                                </LoaderWrapper>
                            </Grid>
                        </Box>
                    </BaseLayout>
                </Grid>
            </Grid>
        </Box>
    );
}

export default OwnershipChangesFilePreview;


