import axios from 'axios';
import { getSession, clearSession } from './Services/AppService';
// import history from './Routes/History';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';


const service = axios.create({
    baseURL: process.env.React_App_Baseurl,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          
    }
});

service.interceptors.request.use(config => {
    config.headers['x-access-token'] = getSession().token || '';
    config.headers['x-refresh-token'] = getSession().refreshToken || '';
    return config;
    // refference
    //https://lightrains.com/blogs/axios-intercepetors-react/#:~:text=Axios%20interceptors%20are%20the%20default,response%20that%20is%20being%20received.
}, error => {
    // console.log(error, '----request error----');
    return Promise.reject(error);
});

service.interceptors.response.use(response => {
   
    response.data = response.data || {};
    if ([null, undefined].includes(response.data.error)) {
        response.data = {
            'message': 'Something went wrong &&&.',
            ...response.data,
            'error': true,
        }
    }
    return response;
}, error => {
    const history = useHistory();
    // console.log(error, '----response error----');
    switch (error?.response?.status) {
        case 404: {
            clearSession();
            history.push('/login');
            break;
        }
        case 500: {
            clearSession();
            history.push('/login');
            toast.error("Something went wrong. Please contact the team.", {
                toastId: 'error',
            }).clearWaitingQueue();
            break;
        }
        default: {
            return Promise.reject(error);
        }
    }
});

export default service;