import { Badge } from '@mui/material'
import { withStyles, createStyles } from '@mui/styles'

const LegendBadge = withStyles((theme) => createStyles({
    root: {
        paddingLeft: 4,
        textIndent: 6,
        minHeight: 26,
        lineHeight: '26px'
    },
    badge: {
        top: '50%',
        left: 0,
        minWidth: 4,
        height: 26,
        transform: 'translate(0,-50%)'
    }
}))((props) => (
    <Badge
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        badgeContent={0}
        showZero={true}
        variant="dot"
        {...props}
        overlap="rectangular"
    >{props.primary}</Badge>
));

export { LegendBadge };