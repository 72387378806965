const CHART_COLORS = ['#333842', '#DB9400', '#5D626D','#EBFBFF', '#EAECEF'];
const DATE_FORMAT = "dd/MM/yyyy";
const TIME_FORMAT = "hh:mm a";
const TIME_MASK = "__:__ _M";
const DATE_TIME_FORMAT = "dd/MM/yyyy hh:mm a";
const MAX_TITLE_LENGTH = 55;
const MAX_DESC_LENGTH = 80;
const MAX_URL_LENGTH = 80;
const MAX_EMAIL_LENGTH = 30;
const MAX_ERROR_LENGTH = 50;
const MAX_KEYWORD_LENGTH = 40;

export {
    CHART_COLORS,
    DATE_FORMAT,
    TIME_FORMAT,
    TIME_MASK,
    DATE_TIME_FORMAT,
    MAX_TITLE_LENGTH,
    MAX_DESC_LENGTH,
    MAX_URL_LENGTH,
    MAX_EMAIL_LENGTH,
    MAX_ERROR_LENGTH,
    MAX_KEYWORD_LENGTH
}
