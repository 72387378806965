import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AlertDialog = (props) => {
    const { action, description, id, maxWidth, open, onClose, title } = props;
   
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth ? maxWidth : "xs"}
        aria-labelledby={id + "-title"}
        aria-describedby={id + "-description"}
        TransitionComponent={Transition}
        fullWidth
        keepMounted
      >
        <DialogTitle id={id + "-title"}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id={id + "-description"}>
            {description}
          </DialogContentText>
        </DialogContent>
        {action && <DialogActions>{action}</DialogActions>}
      </Dialog>
    </>
  );
};
export default AlertDialog;