// Default React, Router and Services Components
import React, { useState, useEffect } from "react";
import { Autocomplete, Box, Breadcrumbs, Button, Switch,Checkbox, CircularProgress, Grid, DialogActions, IconButton, TextField, Tooltip, Typography,Dialog,DialogTitle,DialogContent,TableContainer,Table,TableHead,TableRow,TableCell,TableBody,FormControlLabel } from "@mui/material";
import axios from "axios";
import XLSX from "sheetjs-style";
import { Link } from 'react-router-dom';
import * as FileSaver from "file-saver";
import { useHistory } from "react-router-dom";
import { grey, lightBlue } from "@mui/material/colors";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import AddReport from "./addReport";
import EditSideDeawer from "./EditSideDeawer";
import SideDrawer from "../../../UIComponent/Drawer";
import { ScrollingDialog } from "../../../UIComponent";
import AdobeViewer from "../JobManagement/AdobeViewer";
import BaseLayout from "../../../UIComponent/BaseLayout";
import HistoricData from "../JobManagement/HistoricData";
import AlertDialog from "../../../UIComponent/AlertDialog";
import DataTable from "../../../UIComponent/DataTable/index";
import MultiSelect from "../../../UIComponent/MultiSelectDropdown";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing useStyles and Styles
import useStyles from "./QualityManagement.styles";

// Import Fluent UI Icons
import EmptyState from "../../../UIComponent/EmptyState";

// Import Fluent UI Icons
import { ChevronLeftIcon, ChevronRightIcon, ChevronRightSmallIcon, ExportIcon, LinkIcon, TimeEntryIcon,FileOffIcon } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../Services/CommonService";
import './style.scss'
// Assigning the Grey and Blue Color Code
const darkGrey = grey[900];
const darkBlue = lightBlue[900];

function createData(
  lastUpdateYear, lastUpdateQuater, variableReport, unitReport, variableCru, unitCru, currency, q1, q2, q3, q4, cy, qcFeedback, qcStatus
) {
  return {
    lastUpdateYear, lastUpdateQuater, variableReport, unitReport, variableCru, unitCru, currency, q1, q2, q3, q4, cy, qcFeedback, qcStatus,
  };
}

const DataCollectionManageQualityDetails = (props) => {
  // Declaration of States and Component Variables Start Here
  const [historyData, setHistoryData] = useState();
  const [adobeViewerData, setAdobeViewerData] = useState({ jobId: 0, fileName: "", pageNumber: 0, crudState: "", });
  const [allTableRecord, setallTableRecord] = useState();
  const [assetType, setAssetType] = useState("all");
  const [currentTableDataIndex, setcurrentTableDataIndex] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [currentYeatData, setcurrentYeatData] = useState([])
  const [datum, setDatum] = useState([])
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [excelColumns, setExcelColumns] = useState([]);
  const [excelExportRows, setExcelExportRows] = useState([]);
  const [excelExportRowsWithComments, setexcelExportRowsWithComments] = useState([])
  const [allComment, setAllComent] = useState([])
  const [excelColorCode, setexcelColorCode] = useState([])
  const [filterState, setFilterState] = React.useState({ right: false });
  const [historicData, setHistoricData] = useState(false);
  const [isAssetSegment, setIsAssetSegment] = useState(false)
  const [HistoricDataList, setHistoricDataList] = useState([]);
  const [id, setId] = useState([]);
  const [isAllSelected, setIsSelected] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [jobAssetId, setJobAssetId] = useState();
  const [ManageQualityData, setManageQualityData] = useState("");
  const [open, setOpen] = useState(false);
  const [openAddReport, setopenAddReport] = useState(false);
  const [openAdobeViewer, setOpenAdobeViewer] = useState(false);
  const [openDeleteAlart, setOpenDeleteAlart] = useState(false);
  const [page, setPage] = React.useState(0);
  const [qcFormValue, setqcFormValue] = useState({ variableStatusId: null, variableFeedback: null, });
  const [qcStatus, setqcStatus] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [reload, setReload] = useState(false);
  const [Rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scroll, setScroll] = React.useState("paper");
  const [searchValue, setSearchValue] = useState();
  const [selected, SetSelected] = React.useState([]);
  const [SelectedYear, setSelectedYear] = useState([]);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: true });
  const [state, setState] = useState({ right: false });
  const [statusAndFeedBackLoading, setstatusAndFeedBackLoading] = useState(false);
  const [tempId, setTempId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [VariableDeleteItem, setVariableDeleteItem] = useState(null);
  const [YearOption, setYearOption] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const [accessEdit,setAccessEdit] = useState()
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false });
  const [scoringParameters, setScoringParameters] = useState();
	const [addOpenScore, setOpenScore] = useState(false);
  const [reloadScore, setReloadScore] = useState(true);
  const [mockScore, setmockScore] = useState([
    {
      jobAssetId: 0,
      total: 0,
      totalAchieved: 0,
      achievedPercentage: 0,
      parameterList: [
        {
          parameterId: 1,
          parameterName: "Boliden",
          parameterTypeId: 3,
          parameterType: "Boliden",
          isApplicable: true,
          total: 5,
          totalAchieved: 5
        },

        {
          parameterId: 2,
          parameterName: "Boliden2",
          parameterTypeId: 4,
          parameterType: "Boliden2",
          isApplicable: true,
          total: 10,
          totalAchieved: 10,

        },]
    }
  ]);
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    getHistoryData();
  }, [props]);

  React.useEffect(() => {
    var propsData = JSON.parse(sessionStorage.getItem('assetPhase1'))
    var role = sessionStorage.getItem("role")
    var b = !((role === "Merit QC") && (propsData.status === "Merit QC Passed" || propsData.status === "Merit QC Passed with Errors" || propsData.status === "CRU Review Passed" || propsData.status === "CRU Review Passed with Errors"))
    setAccessEdit(b)
    GetMenuService(11, function (err, res) {
      if (res) {
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    },[])

    if (historyData !== undefined && historyData !== null) {
      setTempId(historyData.state.jobAssetId);
      if (YearOption.length < 1) { GetReportingYear(); }
      if (qcStatus.length < 1) {
        getRoleBasedStatus();
        if (!ManageQualityData) { getAssetVariableDetails(historyData.state.jobAssetId); }
      }
      if (!currentTableDataIndex) {
        if (allTableRecord && allTableRecord.length > 0) {
          allTableRecord.forEach((al, i) => {
            if (al.jobAssetId === historyData.state.jobAssetId) {
              setcurrentTableDataIndex(i);
            }
          });
        }
      }
    }
  }, [])
  useEffect(() => {
    // if ManageQualityData is empty then call the api
    if (historyData !== undefined && historyData !== null) {
      setTempId(historyData.state.jobAssetId);
      if (YearOption.length < 1) { GetReportingYear(); }
      if (qcStatus.length < 1) {
        getRoleBasedStatus();
        if (!ManageQualityData) { getAssetVariableDetails(historyData.state.jobAssetId); }
      }
      if (!currentTableDataIndex) {
        if (allTableRecord && allTableRecord.length > 0) {
          allTableRecord.forEach((al, i) => {
            if (al.jobAssetId === historyData.state.jobAssetId) {
              setcurrentTableDataIndex(i);
            }
          });
        }
      }
    }


  }, [historyData]);
  useEffect(() => {
    if (reload) {
      setReload(false);
      if (sorting && sorting.sortBy) {
        if (SelectedYear.length < 1 && (sorting.sortBy !== "" || sorting.sortBy !== null)) {
          getAssetVariableDetails(jobAssetId ? jobAssetId : historyData.state.jobAssetId);
        } else {
          getVariableHistoryDetails(SelectedYear, historicData);
        }
      }
      if (SelectedYear.length < 1 && (sorting.sortBy === "" || sorting.sortBy === null)) {
        getAssetVariableDetails(jobAssetId ? jobAssetId : historyData.state.jobAssetId);
      } else if (sorting.sortBy === "" || sorting.sortBy === null) {
        getVariableHistoryDetails(SelectedYear, historicData);
      }
    }
  }, [reload, SelectedYear]);
  useEffect(() => {
    let yeOption = YearOption
    if (currentYear && YearOption.length > 0) {
      yeOption.forEach((ye, i) => {
        if (ye.year == currentYear) {
          yeOption.splice(i, 1)
          setcurrentYeatData(ye);
        }
      })
    }
  }, [currentYear, YearOption])
  // Declaration of React Hooks End Here
  const getHistoryData = () => {
    let temp = CommonService.GetStoredData(props, "historyData");
    if (temp) {
      setHistoryData(temp);
      if (temp.state.jobAssetId) {
        setJobAssetId(temp.state.jobAssetId);
      }
      if (temp.allRow) {
        setallTableRecord(temp.allRow);
      }
    }

  }
  // Assigning Columns and UI Props
  const [columns, setcolumns] = useState([
    { id: "id", label: "ID", type: "Highlight", border: true, align: "left" },
    { id: "lastUpdateYear", label: "LUY", minWidth: 80, sorting: false, border: true, freezingHeader: true },
    { id: "lastUpdateQuater", label: "LUQ", minWidth: 80, sorting: false, border: true },
    { id: "variableReport", label: "Variable (Report)", minWidth: 250, sorting: false, border: true },
    { id: "unitReport", label: "Unit (Report)", sorting: false, minWidth: 150, align: "left", border: true },
    { id: "variableCru", label: "Variable (CRU)", minWidth: 250, sorting: false, align: "left", border: true },
    { id: "unitCru", label: "Unit (CRU)", minWidth: 140, sorting: false, align: "left", border: true },
    { id: "currency", label: "Currency", minWidth: 130, sorting: false, align: "left", border: true },
    { id: "variableTypeName", label: "Variable Type", minWidth: 130, sorting: false, align: "left", border: true },
    {
      id: "q1", label: "Q1", minWidth: 200, sorting: false, align: "center", edit: true,
      comment: true, colorCode: "blue", attatch: true, qValue: true, border: true,
    },
    {
      id: "q2", label: "Q2", minWidth: 200, sorting: false, align: "center", edit: true,
      comment: true, colorCode: "blue", attatch: true, qValue: true, border: true,
    },
    {
      id: "q3", label: "Q3", minWidth: 200, sorting: false, align: "center", edit: true,
      comment: true, colorCode: "blue", attatch: true, qValue: true, border: true,
    },
    {
      id: "q4", label: "Q4", minWidth: 200, sorting: false, align: "center",
      edit: true, comment: true, colorCode: "blue", attatch: true, qValue: true, border: true,
    },
    { id: "cy", label: "Annual", minWidth: 200, sorting: false, align: "center", border: true },
    { id: "variableFeedback", label: "QC Feedback", minWidth: 200, maxWidth: 150, sorting: false, align: "left", border: true, truncate: 90, tooltipWithTruncate: true },
    { id: "variableStatusName", label: "QC Status", minWidth: 200, maxWidth: 150, sorting: false, align: "left", truncate: 90, tooltipWithTruncate: true },
  ]);

  // Function for Next and Previous Button
  const handleNextAndPrevious = (mode) => {
    setSelectedYear([])
    setCurrentYear(null)
    setcurrentYeatData([]);
    GetReportingYear();
    if (mode === "next") {
      getAssetVariableDetails(allTableRecord[currentTableDataIndex + 1].jobAssetId);
      setcurrentTableDataIndex(currentTableDataIndex + 1);
      setJobAssetId(allTableRecord[currentTableDataIndex + 1].jobAssetId);
    } else {
      getAssetVariableDetails(allTableRecord[currentTableDataIndex - 1].jobAssetId);
      setcurrentTableDataIndex(currentTableDataIndex - 1);
      setJobAssetId(allTableRecord[currentTableDataIndex - 1].jobAssetId);
    }
  };

  // Function for Sorting Column
  const changeSortColumnNameFormat = (fieldName) => {
    if (fieldName == null || fieldName == "") { return ""; }
    if (fieldName.toLocaleLowerCase() == "VariableReport".toLocaleLowerCase()) {
      fieldName = "VariableReport";
    }
    if (fieldName.toLocaleLowerCase() == "UnitReport".toLocaleLowerCase()) {
      fieldName = "UnitReport";
    }
    if (fieldName.toLocaleLowerCase() == "VariableCru".toLocaleLowerCase()) {
      fieldName = "VariableCru";
    }
    if (fieldName.toLocaleLowerCase() == "UnitCru".toLocaleLowerCase()) {
      fieldName = "UnitCru";
    }
    return fieldName;
  };

  // Function for Delete Variable Report 
  const deleteVariableReport = () => {
    setDeleteBtnLoadinng(true);
    axios.delete(process.env.React_App_baseUrl + "QualityManagement/DeleteVariable",
      {
        headers: {
          VariableId: VariableDeleteItem.variableId, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      }
    )
      .then((res) => {
        setOpenDeleteAlart(false);
        if (res.status === 200) {
          setReload(true);
          setDeleteBtnLoadinng(false);
          toasterSuccess("Variable deleted successfully");
        } else { toasterError("Something went wrong"); }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
        setOpenDeleteAlart(false);
        setDeleteBtnLoadinng(false);

      });
  };

  // Function for Getting Asset Variable Report 
  const getAssetVariableDetails = (id) => {
    setLoading(true);
    axios.post(process.env.React_App_baseUrl + "QualityManagement/GetAssetVariableDetails", null,
      {
        headers: {
          jobAssetId: id,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      }
    )
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          let cYear=[]
          if (res.data.variablesDetails.length > 0) {
            res.data.variablesDetails[0].reportingYear.forEach(item=>{if(!cYear.includes(item.publication.substring(0, 4))){cYear.push(item.publication.substring(0, 4))}})
            let YearCurrent = res.data.variablesDetails[0].reportingYear[0].publication.substring(0, 4)
            console.log(cYear);
            setCurrentYear(YearCurrent)
            if (SelectedYear.length > 0) {
              updateTableTitle(selected, res.data);
            } else {
              let yearTemp=[]
              cYear.forEach(ye=>{
                yearTemp.push({year:ye});
              })
              //updateTableTitle([{ year: cYear }], "default", res.data);
              updateTableTitle(yearTemp,  res.data);
            }
          } else {
            setDefaultCol()
          }
          setManageQualityData({ ...res.data });
          setIsAssetSegment(res.data.isSegment);
          sortHistoryTableData([...res.data.variablesDetails]);
          let excelData = [];
          let excelDataWithOutComment = []
          let allComentvalue = []
          let colorcodes = []
          if (res.data != null) {
            if (res.data.variablesDetails != null) {
              res.data.variablesDetails.forEach((element, index) => {
                let variableDetails = {
                  LastUpdateYear: element.lastUpdateYear,
                  LastUpdateQuater: element.lastUpdateQuater,
                  VariableReport: element.valuationType === "Guidance" ? element.valuationType + " - " + element.variableReport : element.variableReport,
                  UnitReport: element.unitReport,
                  VariableCRU: element.variableCru,
                  UnitCRU: element.unitCru,
                  Currency: element.currency,
                };
                let variableDetailsWithOutComments = {
                  LastUpdateYear: element.lastUpdateYear,
                  LastUpdateQuater: element.lastUpdateQuater,
                  Company: res.data.companyName,
                  Asset: res.data.asset,
                  Commodity: res.data.commodity,
                  Country: res.data.country,
                  Region: res.data.region,
                  "Sub Region": res.data.subRegion,
                  VariableReport: element.valuationType === "Guidance" ? element.valuationType + " - " + element.variableReport : element.variableReport,
                  UnitReport: element.unitReport,
                  VariableCRU: element.variableCru,
                  UnitCRU: element.unitCru,
                  Currency: element.currency,
                  VariableType: element.variableTypeName
                };
                if (element.reportingYear != null) {
                  element.reportingYear.forEach((ele, index) => {
                    variableDetails[ele.publication] = ele.value ? ele.value : (ele.minValue ? ele.minValue : "") + (ele.minValue && ele.maxValue ? " - " : '') + (ele.maxValue ? ele.maxValue : '');
                    variableDetails[ele.publication + "_comments"] =
                      ele.comments;
                    variableDetails[ele.publication + "_FileName"] =
                      ele.valueReportInfo
                        .map((el) => `${el.reportName}.pdf`)
                        .join(",");


                    variableDetailsWithOutComments[ele.publication] = ele.value ? ele.value : (ele.minValue ? ele.minValue : "") + (ele.minValue && ele.maxValue ? " - " : '') + (ele.maxValue ? ele.maxValue : '');
                    variableDetailsWithOutComments[ele.publication + "_FileName"] =
                      ele.valueReportInfo
                        .map((el) => `${el.reportName}.pdf`)
                        .join(",");
                    if (ele.value || ele.maxValue || ele.minValue) {
                      allComentvalue.push(ele.comments)
                      colorcodes.push(ele.isValueChanged
                        ? 'FF0800' : element.valuationType === "Guidance"
                          ? '0C2AE9' :
                          element.valuationType === 'Calculated'
                            ? '8908EE' : '0F011A')
                    }
                  });
                }
                variableDetails["QC Feedback"] = element.variableFeedback;
                variableDetails["QC Status"] = element.variableStatusName;
                variableDetailsWithOutComments["QC Feedback"] = element.variableFeedback;
                variableDetailsWithOutComments["QC Status"] = element.variableStatusName;
                excelData.push(variableDetails);
                excelDataWithOutComment.push(variableDetailsWithOutComments)
              });
              setExcelExportRows(excelDataWithOutComment);
              setexcelExportRowsWithComments(excelData)
              setexcelColorCode(colorcodes)
              setAllComent(allComentvalue)
            }
          }
        } else {
          toasterError("Something went wrong");
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
        setLoading(false);
      });
  };

  // Function for Getting Reporting Year
  const GetReportingYear = () => {
    // api for get year data for year dropdown
    axios
      .get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingYear", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then((res) => { setYearOption(res.data); })
      .catch((err) => ErroHandling(err, function (res) {
        toasterError(res)
      }));
  };

  // Function for Getting Variable History Details
  const getVariableHistoryDetails = (data, hismode, cols) => {
    // currentYeatData
    if (data != null) {
      setLoading(true);
      let allId = [];
      data.forEach((yid) => {
        if (yid.year == currentYeatData.year) {
          allId.push(currentYeatData.id);
        } else { allId.push(yid.id); }
      });
      allId = allId.toString();
      axios.get(process.env.React_App_baseUrl + "QualityManagement/GetVariableHistoryDetails",
        {
          headers: {
            jobAssetId: jobAssetId,
            yearId: allId,
            IsHistoricView: hismode ? hismode : historicData,
            sortColumn: changeSortColumnNameFormat(sorting.sortBy),
            isAscending: sorting.isAscending,
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

          },
        }
      )
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            setHistoricDataList(res.data);
            sortHistoryTableData(res.data);
            let excelData = [];
            if (res.data != null) {
              if (res.data.variablesDetails != null) {
                res.data.variablesDetails.forEach((element, index) => {
                  let variableDetails = {
                    LastUpdateYear: element.lastUpdateYear,
                    LastUpdateQuater: element.lastUpdateQuater,
                    VariableReport: element.variableReport,
                    UnitReport: element.unitReport,
                    VariableCRU: element.variableCru,
                    UnitCRU: element.unitCru,
                    Currency: element.currency,
                    VariableType: element.variableTypeName
                  };
                  if (element.reportingYear != null) {
                    element.reportingYear.forEach((ele, index) => {
                      switch (element.valuationType.toLowerCase()) {
                        case "guidance": {
                          if (ele.minValue != null && ele.maxValue != null)
                            variableDetails[ele.publication] = `${ele.minValue}-${ele.maxValue}`;
                          else if (ele.minValue != null)
                            variableDetails[ele.publication] = `${ele.minValue}`;
                          else if (ele.maxValue != null)
                            variableDetails[ele.publication] = `${ele.maxValue}`;
                          break;
                        }
                        case "actual": {
                          if (ele.value != null)
                            variableDetails[ele.publication] = ele.value;
                          else
                            variableDetails[ele.publication] = "";
                          break;
                        }
                        default: {
                          if (ele.value != null)
                            variableDetails[ele.publication] = ele.value;
                          else
                            variableDetails[ele.publication] = "";
                          break;
                        }
                      }
                      variableDetails[ele.publication + "_comments"] = ele.comments;
                      variableDetails[ele.publication + "_FileName"] = ele.valueReportInfo.map(el => `${el.reportName}.pdf`).join(',');
                    })
                  }
                  variableDetails["QC Feedback"] = element.variableFeedback;
                  variableDetails["QC Status"] = element.variableStatusName;
                  excelData.push(variableDetails);
                });
                setExcelExportRows(excelData);
              }
            }
          } else {
            toasterError("Something went wrong2 ");
          }
        })
        .catch((err) => {
          setLoading(false);
          ErroHandling(err, function (res) {
            toasterError(res)
          })
        });
    }
  };

  // Function for Open Document For View
  const openDocumentForView = (file) => {

    let senData = {
      jobId: ManageQualityData.jobId,
      fileName: file.reportName + ".pdf",
      pageNumber: file.pageNumber,
      crudState: "View",
    };
    localStorage.setItem("filePreview", JSON.stringify(senData));
    //CommonService.AddTokenFromSessionStorageToLocalStorage();
   // window.open("#app/filepreview", '_blank', 'noopener,noreferrer');
   window.open("#app/data-collections/file-preview", '_blank', 'noopener,noreferrer');

  };
  const OpenViewer = (data) => {
    setOpenAdobeViewer(true);
    setAdobeViewerData({
      jobId: data.jobId, fileName: data.fileName, pageNumber: data.pageNumber, crudState: data.crudState,
    });
  };

  // Function for Export To Excel
  const handleExportToExcel = () => {
    exportToCSV(
      excelExportRows,
      `QualityManagement_${new Date()
        .toLocaleDateString()
        .split("/")
        .join("-")}`,
      excelExportRowsWithComments
    );
  };

  const createAlphybet = (v, ws, dataWithOutComments) => {
    let commentData = {}
    let commentCount = 0
    var ordA = 'A'.charCodeAt(0);
    var ordZ = 'Z'.charCodeAt(0);
    var len = ordZ - ordA + 1;
    var s = "";
    //let n = Object.keys(v[0])?.length
    for (let index = 0; index < v.length; index++) {
      (Object.values(v[index]).map((value, ind) => {
        let commentIndex = (Object.keys(v[index]))[ind]
        if (ind > 13 && ind < (Object.keys(v[0]).length - 2) && value && value !== "" && !commentIndex.includes('_FileName')) {
          let n = ind
          while (n >= 0) {
            s = String.fromCharCode(n % len + ordA);
            n = Math.floor(n / len) - 1;
          }
          commentData[`${s + (index + 2)}`] = allComment[commentCount]
          ws[s + (index + 2)].s = {
            font: {
              color: { rgb: excelColorCode[commentCount] }
            },
          }
          commentCount = commentCount + 1
        }
      }))
    }
    Object.keys(ws).forEach(ww => {
      Object.keys(commentData).forEach((cmData) => {
        if (ww === cmData && commentData[cmData]) {
          ws[ww].c = [];
          ws[ww].c.hidden = true;
          ws[ww].c.push({ a: ws[ww].v, t: commentData[cmData], T: true });
        }
      })
    });
    return ws;
  }

  // Function for Export to CSV
  const exportToCSV = (apiData, fileName, dataWithOutComments) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    let wsData = createAlphybet(apiData, ws, dataWithOutComments)
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    excelColumns.forEach((exCol, i) => { if (exCol.includes('_Comments')) { excelColumns.splice(i, 1) } });
    XLSX.utils.sheet_add_aoa(wsData, [excelColumns], { origin: "A1" });
    const wb = { Sheets: { data: wsData }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // Function for Get Role Based Status
  const getRoleBasedStatus = (ed) => {
    var head;
    if (ed) {
      head = {
        isEdited: ed,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    } else {
      head = {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    }
    axios.get(process.env.React_App_baseUrl + "QualityManagement/GetRoleBasedStatus",
      { headers: head })
      .then((res) => {
        if (res.status === 200) { setqcStatus(res.data); }
        else { toasterError("Something went wrong"); }
      })
      .catch((err) => ErroHandling(err, function (res) {
        toasterError(res)
      }));
  };

  // Function for Save Job Asset Feedback
 // The function saveJobAssetFeedback is declared using an arrow function.
const saveJobAssetFeedback = () => {
  
  // We're setting the statusAndFeedBackLoading state to true, indicating that the feedback process has started
  setstatusAndFeedBackLoading(true);

  // Storing the job quality form values into data variable
  let data = qcFormValue;

  // Constructing the job asset feedback data object
  let FeedBackData = {
    jobAssetId: ManageQualityData.jobAssetId, // Taking job asset id from ManageQualityData object
    assetStatusId: data.variableStatusId, // Getting asset's current status id 
    assetFeedback: data.variableFeedback, // Getting asset's feedback from the form
  };

  // Submitting the constructed feedback data to the server using a POST request
  axios.post(process.env.React_App_baseUrl + "QualityManagement/SaveJobAssetFeedback", FeedBackData, {
    // Setting up headers for the request. For authorization, we're getting the token from local storage, if available.
    headers: { 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null }
  })
    .then((res) => { // This block is executed when the promise is settled as resolved

      // Getting the quality control form value status name and logging it on the console
      console.log("qcFormValue",qcFormValue.statusName)

      // Checking if the QC status is passed, failed, or CRU review failed, accordingly updating the session storage
      if(qcFormValue?.statusName === "Merit QC Passed"){
        setAccessEdit(false);
        var prop = JSON.parse(sessionStorage.getItem('assetPhase1'))
        prop.status = "Merit QC Passed"
        sessionStorage.setItem('assetPhase1',JSON.stringify(prop))
      }

      if(qcFormValue?.statusName === "Merit QC Failed"){
        setAccessEdit(false);
        var prop = JSON.parse(sessionStorage.getItem('assetPhase1'))
        prop.status = "Merit QC Failed"
        sessionStorage.setItem('assetPhase1',JSON.stringify(prop))
      }

      if(qcFormValue?.statusName === "CRU Review Failed"){
        setAccessEdit(false);
        var prop = JSON.parse(sessionStorage.getItem('assetPhase1'))
        prop.status = "CRU Review Failed"
        sessionStorage.setItem('assetPhase1',JSON.stringify(prop))
      }

      // Setting up the loading status to false indicating that feedback process has ended
      setstatusAndFeedBackLoading(false);

      // Checking the server response status. If it's 200 then show the success message else show the error message.
      if (res.status === 200) {
        setReload(true)
        toasterSuccess(res.data);
      }
      else { 
        toasterError("Something went wrong"); 
      }
    })
    .catch((err) => { // This block is executed when the promise is settled as rejected

        // Setting up the loading status to false and handling the error 
        setstatusAndFeedBackLoading(false);
        ErroHandling(err, function (res) { toasterError(res) })
    });
};

  // Function for Update Table Title
  const updateTableTitle = (year, mode, defaultData) => {
    if (currentYear) {
      let yearPresent = year.filter((ye) => ye.year == currentYear)
      if (yearPresent.length < 1) { year.unshift({ year: currentYear }); }
    }
    let col;
    let Excelcol;
    if (year.length > 0) {
      col = [
        { id: "id", label: "ID", type: "Highlight", border: true, },
        { id: "lastUpdateYear", label: "LUY22", minWidth: 80, sorting: false, border: true, },
        { id: "lastUpdateQuater", label: "LUQ", minWidth: 80, sorting: false, wrap: true, border: true, },
        { id: "variableReport", label: "Variable (Report)", minWidth: 250, sorting: true, border: true, freezeColumn:true,leftSide:0},
        { id: "unitReport", label: "Unit (Report)", sorting: true, minWidth: 140, align: "left", border: true,freezeColumn:true,leftSide:250},
        { id: "variableCru", label: "Variable (CRU)", minWidth: 250, sorting: true, align: "left", border: true,freezeColumn:true,leftSide:390 },
        { id: "unitCru", label: "Unit (CRU)", minWidth: 140, sorting: true, align: "left", border: true,freezeColumn:true,leftSide:640 },
        { id: "currency", label: "Currency", minWidth: 130, sorting: false, align: "left", border: true, },
        { id: "variableTypeName", label: "Variable Type", minWidth: 130, sorting: false, align: "left", border: true, },
        { id: "variableFeedback", label: "QC Feedback", minWidth: 200, sorting: false, align: "left", border: true, },
        { id: "variableStatusName", label: "QC Status", minWidth: 200, sorting: false, align: "left", border: true, },
      ];
      year.forEach((ye, i) => {
        col.splice(col.length - 2, 0, {
          id: ye.year + " - " + "Q1", label: ye.year + "-" + "Q1", minWidth: 120, sorting: false,
          align: "center", edit: true, comment: true, colorCode: "blue", attatch: true,
          qValue: true, border: true,
        },
          {
            id: ye.year + " - " + "Q2", label: ye.year + "-" + "Q2", minWidth: 120, sorting: false,
            align: "center", edit: true, comment: true, colorCode: "blue", attatch: true,
            qValue: true, border: true,
          },
          {
            id: ye.year + " - " + "Q3", label: ye.year + "-" + "Q3", minWidth: 120, sorting: false,
            align: "center", edit: true, comment: true, colorCode: "blue", attatch: true,
            qValue: true, border: true,
          },
          {
            id: ye.year + " - " + "Q4", label: ye.year + "-" + "Q4", minWidth: 120, sorting: false,
            align: "center", edit: true, comment: true, colorCode: "blue", attatch: true,
            qValue: true, border: true,
          },
          {
            id: ye.year + " - " + "Annual", label: ye.year + "-" + "Annual", minWidth: 120, sorting: false,
            align: "center", qValue: true, border: true,
          }
        );
      });

      Excelcol = [
        "LUY", "LYQ", 'Company', 'Asset', 'Commodity', 'Country',
        'Region', "Sub Region", "Variable (Report)", "Unit (Report)", "Variable (CRU)",
        "Unit (CRU)", "Currency"
        , "Variable Type"
        , "QC Feedback", "QC Status",
      ];
      year.forEach((ye, i) => {
        Excelcol.splice(
          Excelcol.length - 2, 0,
          ye.year + "-" + "Q1", ye.year + "-" + "Q1_Comments", ye.year + "-" + "Q1_FileName",
          ye.year + "-" + "Q2", ye.year + "-" + "Q2_Comments", ye.year + "-" + "Q2_FileName",
          ye.year + "-" + "Q3", ye.year + "-" + "Q3_Comments", ye.year + "-" + "Q3_FileName",
          ye.year + "-" + "Q4", ye.year + "-" + "Q4_Comments", ye.year + "-" + "Q4_FileName",
          ye.year + "-" + "Annual", ye.year + "-" + "Annual_Comments", ye.year + "-" + "Annual_FileName"
        );
      });
    } else {
      setDefaultCol();
    }
    setcolumns([...col]);
    setExcelColumns([...Excelcol]);
    if (mode === "default") { sortHistoryTableData(defaultData.variablesDetails); }
    else { }
  };

  // Assigning Columns and UI Props
  const setDefaultCol = () => {
    let col = [
      { id: "id", label: "ID", type: "Highlight" },
      { id: "lastUpdateYear", label: "LUY", minWidth: 80, sorting: false, },
      { id: "lastUpdateQuater", label: "LUQ", minWidth: 80, sorting: false, },
      { id: "variableReport", label: "Variable (Report)", minWidth: 250, sorting: false, },
      { id: "unitReport", label: "Unit (Report)", sorting: false, minWidth: 120, align: "left", },
      { id: "variableCru", label: "Variable (CRU)", minWidth: 250, sorting: false, align: "left", },
      { id: "unitCru", label: "Unit (CRU)", minWidth: 140, sorting: false, align: "left", },
      { id: "currency", label: "Currency", minWidth: 130, sorting: false, align: "left", },
      { id: "variableTypeName", label: "Variable Type", minWidth: 130, sorting: false, align: "left", },
      { id: "q1", label: "Q1", minWidth: 200, sorting: false, align: "center", colorCode: "blue", attatch: true, },
      { id: "q2", label: "Q2", minWidth: 200, sorting: false, align: "center", colorCode: "blue", attatch: true, },
      { id: "q3", label: "Q3", minWidth: 200, sorting: false, align: "center", colorCode: "blue", attatch: true, },
      { id: "q4", label: "Q4", minWidth: 200, sorting: false, align: "center", colorCode: "blue", attatch: true, },
      { id: "cy", label: "Annual", minWidth: 200, sorting: false, align: "center", colorCode: "blue", attatch: true, },
      { id: "variableFeedback", label: "QC Feedback", minWidth: 200, maxWidth: 150, sorting: false, align: "left", truncate: 90, tooltipWithTruncate: true },
      { id: "variableStatusName", label: "QC Status", minWidth: 200, maxWidth: 150, sorting: false, align: "left", truncate: 90, tooltipWithTruncate: true },
    ];
  };

  // Function for Sorting History Data
  const sortHistoryTableData = (res) => {
    if (res.length > 0) {
      let reqHeader = res[0].reportingYear.map((el) => el.publication);
      let bb = res.map((el) => {
        const publication = el.reportingYear.map((e) => {
          return {
            [e.publication]:
              el['valuationType'] === "Guidance" && e.maxValue || e.minValue
                ? (e.minValue ? e.minValue : "") + (e.minValue && e.maxValue ? " - " : '') + (e.maxValue ? e.maxValue : '')
                : e.value,
          };
        });
        return {
          ...el, ...Object.assign({}, ...publication),
        };
      });
      bb.forEach((b) => {
        console.log("Test", b)
        b.reportingYear.forEach((rp) => {
          reqHeader.forEach((req) => {
            if (b[req] !== null && rp.publication === req) {
              b["selectedReportingYear"] = rp;
              b["variablevalueid"] = rp.variableValueId;
              b["variableComment"] = rp.comments;
              b["isEdit"] = rp.isEdit;
              b["isValueChanged"] = rp.isValueChanged;
              b["id"] = b.variablevalueid;
              b['variableValueStatusName'] = rp.variableValueStatusName;
              if (b.valuationType === 'Guidance') {
                if (b.variableReport.toLowerCase().includes('Guidance'.toLowerCase())) {
                  b.variableReport = b.variableReport;
                } else {
                  b.variableReport = 'Guidance - ' + b.variableReport
                }

              } else {
                b.variableReport = b.variableReport;
              }

            }
          });
        });
      });
      setRows([...bb]);
    } else { setRows([]); }
  };
  const [menuPermission, setMenuPermission] = useState({ allowDelete: true, allowEdit: true, });

  // Function for Open Edit and Delete Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") {
      setVariableDeleteItem(item); setOpenDeleteAlart(true);
    } else {
      if (mode === "Edit") { }
      setFilterState({ ...state, right: true, formData: item, mode: "action" });
    }
  };
  const openVariableEdit = (item) => { setFilterState({ ...state, right: true, formData: item, mode: "variable" }); };
  const checkboxRequired = (e, item, row) => {
    let selectedRecord = [];
    e.forEach((el) => {
      Rows.forEach((row) => { if (row.id === el) { selectedRecord.push(row); } });
    });
    SetSelected(e);
    if (e.length === Rows.length) { setIsSelected(true) }
    else { setIsSelected(false) }
    setId(selectedRecord.map(el => el.reportingYear.filter(item => item.variableValueId > 0).map(k => {
      return { id: k.variableValueId, yearId: k.reportingYearId, periodId: k.reportingPeriodId }
    })))
  };

// This is a function that fetches single scoring details.
const getSingleScoringDetails = () => {

  // We use axios to send a GET request. 
  axios
    .get(
      // The URL of our request is created by concatenating the base URL with the rest of the endpoint.
      process.env.React_App_baseUrl + "QualityManagement/GetQCDrildownScoringParametersDC", 
      {
        headers: {
          // We set Authorization header. If token is present in local storage, it adds "Bearer" before the token.
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null,
          // jobAssetId is added as another header field
          jobAssetId: jobAssetId
        },
      }
    )
    // Upon successful completion of the GET request, we enter this .then block.
    .then((res) => {
      // Here, we update the ScoringParameters state with the data we received from our GET request.
      setScoringParameters(res.data);
      // OpenScore flag is set to true which could be used to display scoring parameters on UI.
      setOpenScore(true)
    })
     // If an error occurs during our GET request, we enter this .catch block.
    .catch((err) => ErroHandling(err, function (res) {
      // Display an error message using toasterError function.
      toasterError(res)
    }));
}

 // This function is responsible for updating comments in scoring parameters.
const handleUpdateComments = (id, e) => {

  // We create a temporary mock copy of our current scoringParameters state.
  const mockTemp = { ...scoringParameters };

  // We use map function to iterate over the parameterList array in our mock state.
  mockTemp.parameterList.map((items, index) => {
    // For each item in the array, we check if the item's parameterId matches the one we're looking for.
    if (items.parameterId === id) {
      // If it matches, we update this item's 'comments' field with the new value 'e'.
      return items.comments = e;
    }
  });

  // After iterating over the list and making necessary changes,
  // we update our original scoringParameters state with the updated state.
  setScoringParameters(mockTemp);
}

// This function is responsible for updating achieved values in scoring parameters.
const handleUpdateAchieved = (id, e) => {

  // We create a temporary mock copy of our current scoringParameters state.
  const mockTemp = { ...scoringParameters };

  // If the target element is checked,
  if (e.target.checked) {
    // Then we iterate over the parameterList array in our mock state.
    mockTemp.parameterList.map((items, index) => {
      // For each item in the array, we check if the item's parameterId matches the one we're looking for.
      if (items.parameterId === id) {
        // If it matches, we update this item's 'isAchieved' field to true and 'totalAchieved' to its total.
        items.isAchieved = true;
        items.totalAchieved = items.total;
      }
    });
  }
  else {
    // If the target element is not checked,
    // We iterate again over the parameterList to find the matching parameterId
    mockTemp.parameterList.map((items, index) => {
      if (items.parameterId === id) {
        // This time, we set 'isAchieved' to false and 'totalAchieved' to 0.
        items.isAchieved = false;
        items.totalAchieved = 0;
      }
    });
  }

  // Initialize a variable to hold the total of all totalAchieved values
  var total = 0

  // Loop through our mock list one more time to accumulate all 'totalAchieved'
  mockTemp.parameterList.map((items, index) => {
    total += items.totalAchieved;
  });

  // Store this total in 'totalAchieved' field of our mock object
  mockTemp.totalAchieved = total

  // Calculate the percentage of the total achieved and store it in 'achievedPercentage'
  var totalPercentage = (total / mockTemp.total) * 100;
  mockTemp.achievedPercentage = totalPercentage.toFixed(2);

  // After all the operations, update our original scoringParameters state with the mocked one.
  setScoringParameters(mockTemp);
}


 // This function is responsible for the submission of scoringParameters to an endpoint.
const handleSubmit = () => {

  // Here, we set the status and feedback loading flag to true to indicate that a process is ongoing.
  setstatusAndFeedBackLoading(true);

  // We make a POST request with axios to our desired endpoint.
  // The URL consists of a base URL stored in environment variables and 
  // the specific endpoint 'QualityManagement/SaveQCDrildownJobAssetScoringDC'.
  // Our payload is the `scoringParameters` state.
  // We also include an Authorization header with a token if it exists in local storage.
  axios.post(process.env.React_App_baseUrl + "QualityManagement/SaveQCDrildownJobAssetScoringDC", scoringParameters, {
    headers: { 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null }
  })
    .then((res) => {
      // If the axios call returns successfully,
      // We set the status and feedback loading flag to false.
      setstatusAndFeedBackLoading(false);

      // We check if the response status is 200, indicating a successful operation.
      if (res.status === 200) {

        // If so, we trigger a reload of the score,
        setReloadScore(true)

        // Display a success toast message,
        toasterSuccess("Score Added Successfully");

        // And toggle the open state 'setOpenScore' which perhaps controls a dialog or modal visibility.
        setOpenScore(!addOpenScore);
      }
      else {
        // If the status isn't 200, we show an error toast message.
        toasterError("Something went wrong");
      }
    })
    .catch((err) => {
      // If there was an error in making the axios call,
      // We again set the loading flag to false,
      setstatusAndFeedBackLoading(false);

      // And handle the error using a custom 'ErroHandling' function which displays an error toast with the error message.
      ErroHandling(err, function (res) { toasterError(res) })
    });
};


// This function is responsible for closing a dialog or modal related to scoring.
const handleCloseLegal = () => {
  // By setting 'setOpenScore' state to false, it likely hides a particular overlay such as a dialog or a modal window.
  setOpenScore(false);
};

  return (

    <Box>
      <Grid container maxWidth justifyContent="left" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={9} className="page-title">
          <h2>Quality Management Drill Down</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/quality-management" }}>Quality Management</Link>
            <Typography color="text.primary">Quality Management Drill Down</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={3}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              <Button variant="contained" color="accent2"
                startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
                onClick={() => { history.push("/app/data-collections/quality-management"); }}                  >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        <Grid item xs={12}>
          <BaseLayout>
            <Grid container alignItems="center" justifyContent="center" className={classes.jobShortDetails} sx={{ mb: 2 }}>
              {/* Job Short Details Start Here */}
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                    <div className={classes.shortDetails}>
                      Job ID:{" "}<strong><span>{ManageQualityData.jobNo ? ManageQualityData.jobNo : "-"}</span></strong>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <div className={classes.shortDetails}>
                      Job Type:{" "}<strong><span>{ManageQualityData.jobType ? ManageQualityData.jobType : "-"}</span></strong>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
                    <div className={classes.shortDetails}>
                      Job Name:{" "}<strong><span >{ManageQualityData.jobName ? ManageQualityData.jobName : "-"}</span></strong>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                    <div className={classes.shortDetails}>
                      Priority:&nbsp;
                      {ManageQualityData.priority === "High" ? (
                        <Typography className={classes.twoValues} color="error">
                          {" "}<span className={classes.boldText}>{ManageQualityData.priority}</span>
                        </Typography>) : undefined}{" "}
                      {ManageQualityData.priority === "Low" ? (
                        <Typography className={classes.twoValues} color="orange">
                          {" "}<span className={classes.boldText}>{ManageQualityData.priority}</span>
                        </Typography>) : undefined}{" "}
                      {ManageQualityData.priority === "Medium" ? (
                        <Typography className={classes.twoValues} color="blue">
                          {" "}<span className={classes.boldText}>{ManageQualityData.priority}</span>
                        </Typography>) : undefined}
                      {ManageQualityData.priority === "Critical" ? (
                        <Typography className={classes.twoValues} color="red">
                          {" "}<span className={classes.boldText}>{ManageQualityData.priority}</span>
                        </Typography>) : undefined}

                      {/* <strong><span>{ManageQualityData.priority ? ManageQualityData.priority : "-"};</span></strong> */}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <div className={classes.shortDetails}>
                      TAT:{" "}<strong><span>{ManageQualityData.tat ? ManageQualityData.tat : "-"}</span></strong>
                    </div>
                  </Grid>

                  {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={3} textAlign="left">
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <div className={classes.shortDetails}>
                          Priority:&nbsp;
                          {ManageQualityData.priority === "High" ? (
                            <Typography className={classes.twoValues} color="error">
                              {" "}{ManageQualityData.priority}
                            </Typography>) : undefined}{" "}
                          {ManageQualityData.priority === "Low" ? (
                            <Typography className={classes.twoValues} color="orange">
                              {" "}{ManageQualityData.priority}
                            </Typography>) : undefined}{" "}
                          {ManageQualityData.priority === "Medium" ? (
                            <Typography className={classes.twoValues} color="blue">
                              {" "}{ManageQualityData.priority}
                            </Typography>) : undefined}
                          {ManageQualityData.priority === "Critical" ? (
                            <Typography className={classes.twoValues} color="red">
                              {" "}
                              {ManageQualityData.priority}
                            </Typography>) : undefined}
                        </div>
                      </Grid>

                      <Grid item xs={6} textAlign="left">
                        <div className={classes.shortDetails}>
                          TAT:{" "}<span>{ManageQualityData.tat ? ManageQualityData.tat : "-"}</span>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={6} lg={7} xl={7} sx={{ pb: 1 }}>
                    {/* <Grid item xs={12} sx={{ pb: 1 }}> */}
                    <div className={classes.shortDetails}>
                      Job Instruction:{" "}<strong><span className={classes.boldText}>
                        {ManageQualityData.instructions ? ManageQualityData.instructions : "-"}
                      </span></strong>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent="left" alignItems="center" sx={{ pt: 1, pb: 3 }}>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>Company</Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.companyName ? ManageQualityData.companyName : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>Attributable %</Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.attibutablePercentage ? ManageQualityData.attibutablePercentage : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>Asset</Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.asset ? ManageQualityData.asset : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <div style={{ marginLeft: -10 }}>
                  <Typography variant="body1" color={darkGrey}>
                    <Checkbox disabled checked={isAssetSegment} />
                    {" "}
                    Asset is a segment
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>
                  Commodity
                </Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.commodity ?
                    <Tooltip title={ManageQualityData.commodity} placement="top" arrow>
                      <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "99%", display: "inline-block" }}>{ManageQualityData.commodity}</span>
                    </Tooltip> : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>
                  Country
                </Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.country ? ManageQualityData.country : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>
                  Region
                </Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.region ? ManageQualityData.region : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>
                  Sub-Region
                </Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.subRegion
                    ? ManageQualityData.subRegion
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  Segment Comments
                </Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.segmentComments ? ManageQualityData.segmentComments : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  Link to CAT
                </Typography>
                <Typography variant="body1" color={darkGrey} noWrap>
                  <LinkIcon style={{ fontSize: 22, marginRight: "5px" }} />{" "}
                  <Link color={darkBlue} onClick={() => {
                    if (ManageQualityData.linktoCAT) {
                      window.open(ManageQualityData.linktoCAT, "_blank");
                    }
                  }}>
                    {ManageQualityData.linktoCAT ? ManageQualityData.linktoCAT : "-"}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  Link to Asset Platform
                </Typography>
                <Typography variant="body1" color={darkGrey} noWrap>
                  <LinkIcon style={{ fontSize: 22, marginRight: "5px" }} />{" "}
                  <Link color={darkBlue} onClick={() => {
                    if (ManageQualityData.linktoAssetPlatform) {
                      window.open(ManageQualityData.linktoAssetPlatform, "_blank");
                    }
                  }}>
                    {ManageQualityData.linktoAssetPlatform ? ManageQualityData.linktoAssetPlatform : "-"}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  Link to EAT
                </Typography>
                <Typography variant="body1" color={darkGrey} noWrap>
                  <LinkIcon style={{ fontSize: 22, marginRight: "5px" }} />{" "}
                  <Link color={darkBlue}
                    onClick={() => {
                      if (ManageQualityData.linktoEAT) {
                        window.open(ManageQualityData.linktoEAT, "_blank");
                      }
                    }}>
                    {ManageQualityData.linktoEAT ? ManageQualityData.linktoEAT : "-"}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={qcStatus}
                  getOptionLabel={(option) => option.statusName}
                  onChange={(event, value) => {
                    setqcFormValue({ ...qcFormValue, variableStatusId: value.id,statusName:value.statusName });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="QC Status" defaultValue="Hello World" variant="standard" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={5}>
                <TextField fullWidth variant="standard" id="outlined-required" label="QC Feedback"
                  inputProps={{ maxLength: 2000 }}
                  onChange={(event, value) => {
                    setqcFormValue({ ...qcFormValue, variableFeedback: event.target.value, });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={8} lg={12} xl={4}>
                <Grid container spacing={{ xs: 1, sm: 2 }} alignItems="center" justifyContent="flex-start">
                  <Grid item>
                    {(access.allowEdit && accessEdit) ? <>
                      <Button variant="contained" color="accent5" onClick={saveJobAssetFeedback}>
                        {statusAndFeedBackLoading ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : ("save")}
                      </Button>
                    </> : null}
                  </Grid>
                  <Grid item>
                    {(access.allowEdit && accessEdit) ? <>
                      <Button variant="contained" color="accent3"
                        onClick={() => { history.push("/app/manage-quality"); }}>
                        Cancel
                      </Button>
                    </> : null}
                  </Grid>
                  <Grid item>
                      <Button variant="contained"
                        color="secondary"
                        onClick={() => { getSingleScoringDetails(); }}
                      >
                        Score
                      </Button>

                    </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={6} xl={4}>
                <MultiSelect
                  onChange={(value) => {
                    if (value.length > 0) {
                      updateTableTitle(value);
                      setSelectedYear(value);
                    } else {
                      setSelectedYear(value);
                    }
                    setReload(true)
                  }}
                  items={YearOption}
                  values={SelectedYear}
                  selectAllLabel={{ label: "Select all" }}
                  getOptionLabel={"year"}
                  placholder="Year"
                  dropLable={"year"}
                  role="year"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Button
                  className={classes.largeButtonAuto}
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => setOpen(!open)}
                  startIcon={<TimeEntryIcon style={{ fontSize: 16 }} />}>
                  Historic Data
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={2} xl={4} textAlign="right">
                <Grid container spacing={2} justifyContent="flex-end" alignItems="flex-end" >
                  <Grid item xs={12} sm={"auto"}>
                    <Tooltip title=" Replace Report's" placement="top" arrow
                      disabled={selected.length < 1 ? true : false}>
                      <IconButton color="secondary">
                        <TimeEntryIcon onClick={() => setopenAddReport(!openAddReport)} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={"auto"}>
                    <Tooltip title="Export To Excel" placement="top" arrow>
                      <IconButton color="secondary">
                        <ExportIcon onClick={handleExportToExcel} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container alignItems="center" justifyContent="center" className={classes.tableBorder}>
              {/* Assign Jobs Start Here */}
              <Grid item xs={12} className="overAllDetailsDataTable">

                <DataTable
                  setDatum={setDatum}
                  openVariableEdit={openVariableEdit}
                  isAllowEdit={accessEdit}
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={Rows ? Rows : []}
                  columns={columns ? columns : []}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  openDocumentForView={(fileName) => {
                    openDocumentForView(fileName);
                  }}
                  qualityManage
                  rowsPerPageVisible
                  isPagination={true}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  pagination={{ show: false }}
                  setSorting={(e) => {
                    setSorting(e)
                    setReload(true)
                  }}
                  hasChip={{
                    High: { color: "warning", label: "High" },
                    Critical: { color: "error", label: "Critical" },
                    Low: { color: "success", label: "Low" },
                    Medium: { color: "info", label: "Medium" },
                  }}
                  isActionButton={{
                    // ...(menuPermission.allowView && {
                    //   view: toggleDrawer(true, "View"),
                    // }),
                    ...((access.allowEdit && accessEdit) && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...((access.allowDelete && accessEdit) && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                  checkboxSelection={{
                    checkbox: checkboxRequired,
                  }}
                />
              </Grid>

              <Grid container spacing={{ xs: 1, sm: 2 }} sx={{ p: 2 }} alignItems="center" justifyContent="flex-end">
                <Grid item>
                  <Button variant="contained"
                    style={{ "background-color": currentTableDataIndex === 0 ? "#EBFBFF" : "#DB9400", }}
                    size="small"
                    disabled={currentTableDataIndex === 0 ? true : false}
                    onClick={(e) => { handleNextAndPrevious("pre"); }}
                    startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}>
                    Prev
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" style={{
                    "background-color":
                      allTableRecord && currentTableDataIndex === allTableRecord.length - 1
                        ? "#EBFBFF" : "#DB9400",
                  }}
                    size="small"
                    disabled={
                      allTableRecord && currentTableDataIndex === allTableRecord.length - 1
                        ? true : false
                    }
                    onClick={(e) => { handleNextAndPrevious("next"); }}
                    endIcon={<ChevronRightIcon style={{ fontSize: 15 }} />}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>

              {/* Filter Assign Jobs Start Here */}
              <SideDrawer
                formComp={
                  <EditSideDeawer
                    state={filterState}
                    setState={setFilterState}
                    jobId={ManageQualityData.jobId}
                    propsData={jobAssetId}
                    reload={() => {
                      setReload(true);
                    }}
                  />
                }
                setReload={() => { }}
                state={filterState}
                setState={setFilterState}
              />

              {/* Filter Assign Jobs End Here */}
            </Grid>
            {historyData !== undefined ?
              <ScrollingDialog
                open={open}
                setOpen={setOpen}
                scroll={scroll}
                setScroll={setScroll}
                dialogClass="darkTitle"
                dialogTitle="Historic Data"
                dialogContent={
                  <HistoricData
                    historyData={null}
                    historyColumns={null}
                    reportData={ManageQualityData}
                    variableReportId={
                      ManageQualityData
                        ? ManageQualityData.variablesDetails.variableReportId
                        : null
                    }
                    isOverallInfo={false}
                    jobAssetId={historyData.state.jobAssetId}
                    jobID={ManageQualityData ? ManageQualityData.jobId : null}
                  />
                }
              /> : ("")
            }
            <AlertDialog
              open={openDeleteAlart}
              title={"Confirmation"}
              maxWidth={"sm"}
              description={`Are you sure want to delete Report ${VariableDeleteItem?.variableReport} ?`}
              action={
                <>
                  <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                    <Button
                      onClick={() => {
                        setOpenDeleteAlart(false);
                        setVariableDeleteItem(null);
                      }}
                    >
                      No
                    </Button>
                    {deleteBtnLoadinng ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Button color="secondary" onClick={deleteVariableReport}>
                        Yes
                      </Button>
                    )}
                  </div>
                </>
              }
            />
            {openAddReport ? (
              <AddReport
                open={openAddReport}
                jobAssetId={jobAssetId}
                jobId={ManageQualityData.jobId}
                selectedRow={id}
                setopenAddReport={() => setopenAddReport(!openAddReport)}
                reload={() => {
                  setReload(true)
                }}
              />
            ) : null}
          </BaseLayout>

          <ScrollingDialog
            open={openAdobeViewer}
            setOpen={setOpenAdobeViewer}
            // scroll={scroll}
            // setScroll={setScroll}
            dialogClass="darkTitle"
            dialogTitle="File Preview"
            dialogContent={
              <AdobeViewer
                jobId={adobeViewerData.jobId}
                fileName={adobeViewerData.fileName}
                pageNumber={adobeViewerData.pageNumber}
                crudState={adobeViewerData.crudState}
              ></AdobeViewer>
            }
          />
        </Grid>
      </Grid>
 {/* Score popup screen Start Here */}
      {
        addOpenScore ? (
          <Dialog
            open={true}
            fullWidth
            // onClose={handleCloseLegal}
            maxWidth={"lg"}
          >
            <DialogTitle>

              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={"4"}>
                  Score
                </Grid>

              </Grid>
            </DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
              <Grid item xs={12}>
                <Box className={classes.setBorder}>
                  <TableContainer sx={{ maxHeight: "calc(100vh - 203px)" }}>
                    <Table
                      stickyHeader
                      // className={classes.table}
                      className={classes.historyTable}
                    >
                      <TableHead>
                        <TableRow hover>
                          <TableCell className={classes.tableHeader}
                            style={{ minWidth: "300px", whiteSpace: "nowrap", }} >
                            Parameter Name
                          </TableCell>
                          <TableCell className={classes.tableHeader && classes.cruTheadLeftSep}
                            style={{ minWidth: "200px", width: "25%", whiteSpace: "nowrap", }} >
                            Parameter Type
                          </TableCell>
                          <TableCell className={classes.tableHeader && classes.cruTheadLeftSep}
                            style={{ minWidth: "200px", width: "25%", whiteSpace: "nowrap", }} >
                            Comments
                          </TableCell>
                          <TableCell className={classes.tableHeader && classes.cruTheadLeftSep}
                            style={{ minWidth: "150px", width: "15%", whiteSpace: "nowrap", }} >
                            Achieved(No/Yes)
                          </TableCell>
                          <TableCell className={classes.tableHeader && classes.cruTheadLeftSep}
                            style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                            Total Score
                          </TableCell>
                          <TableCell className={classes.tableHeader && classes.cruTheadLeftSep}
                            style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                            Achieved
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {

                          scoringParameters.parameterList.length > 0 ?
                            scoringParameters.parameterList?.map((item, index) => (

                              <TableRow key={index} hover>
                                <TableCell className={classes.tableCell}>{item.parameterName}</TableCell>
                                <TableCell className={classes.tableCell && classes.tableCellLeftSep}>{item.parameterType}</TableCell>
                                {/* </Link> */}
                                <TableCell align="center" className={classes.tableCell && classes.tableCellLeftSep}>
                                  <FormControlLabel control={<TextField
                                    variant="standard"
                                    size="small"
                                    fullWidth
                                    label="Scoring Comments"
                                    placeholder="Comments"
                                    value={item.comments}
                                    onChange={(e) =>
                                      handleUpdateComments(item.parameterId, e.target.value)
                                    }
                                  />} label="" sx={{ m: 0, p: 0 }} />
                                </TableCell>


                                <TableCell align="center" className={classes.tableCell && classes.tableCellLeftSep}>
                                  <FormControlLabel control={<Switch color="secondary" checked={item.isAchieved} onChange={(e) => { handleUpdateAchieved(item.parameterId, e) }} />} label="" sx={{ m: 0, p: 0 }} />
                                </TableCell>

                                <TableCell className={classes.tableCell && classes.tableCellLeftSep}>{item.total}</TableCell>
                                <TableCell className={classes.tableCell && classes.tableCellLeftSep}>{item.totalAchieved}</TableCell>
                              </TableRow>
                            )) :
                            (<TableRow>
                              <TableCell className={classes.tableCell} align="center" colSpan={99}>
                                <EmptyState
                                  icon={
                                    <FileOffIcon style={{ color: "#db9400", fontSize: "40px" }} />
                                  }
                                  title="No Data Found"
                                />
                              </TableCell>
                            </TableRow>)}
                      </TableBody>

                      {/* <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={99}>
                          <EmptyState
                            icon={
                              <FileOffIcon
                                style={{ color: "#db9400", fontSize: "40px" }}
                              />
                            }
                            title="No Data Found"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody> */}
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </DialogContent>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              mt={2}
              sx={{ pr: 5 }}
            >
              <Grid item xs={"auto"}>
                <Typography variant="subtitle2">
                  Total Score:{" "}
                  <span className={classes.totalRecord}>
                    {" "}
                    <strong>{scoringParameters.total}</strong>
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={"auto"}>
                <Typography variant="subtitle2">
                  Total Achieved:{" "}
                  <span className={classes.totalRecord}>
                    {" "}
                    <strong>{scoringParameters.totalAchieved}</strong>
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={"auto"}>
                <Typography variant="subtitle2">
                  Achieved Quality (%):{" "}
                  <span className={classes.totalRecord}>
                    {" "}
                    <strong>{scoringParameters.achievedPercentage}{"%"}</strong>
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <DialogActions sx={{ mt: 1, mr: 2 }}>
              <Button onClick={handleSubmit}>Submit</Button>
              <Button onClick={handleCloseLegal}>Cancel</Button>
            </DialogActions>
          </Dialog>
        ) : null}
    </Box>

  );
};

export default DataCollectionManageQualityDetails;