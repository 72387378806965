import { Chip, FormControl, FormLabel, Grid, Paper, Typography } from "@mui/material";
import { createStyles, withStyles } from '@mui/styles'

const NcPaper = withStyles((theme) => createStyles({
    root: {
        display: "flex",
        flexWrap: "wrap",
        listStyle: "none",
        gap: theme.spacing(1),
        margin: 0,
        padding: 0
    }
}))((props) => (
    <Paper elevation={0} component="ul" {...props} />
));

const NcFormControl = withStyles((theme) => createStyles({
    root: {
        marginTop: theme.spacing(0),
    }
}))((props) => (
    <FormControl {...props} />
));

const NcFormLabel = withStyles((theme) => createStyles({
    root: {
        'legend&': {
            marginBottom: theme.spacing(0.5),
            color: theme.palette.text.secondary,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: 500
        }
    }
}))((props) => (
    <FormLabel {...props} />
));

const NcTypography = withStyles((theme) => createStyles({
    root: {
        whiteSpace: "pre-wrap",
        wordBreak: "break-all",
        wordWrap: "break-word"
    }
}))((props) => (
    <Typography {...props} />
));

const FormControlValue = ({ value }) => {
    switch (typeof value) {
        case "boolean":
            return <Typography>{value ? "Yes" : "No"}</Typography>
        case "object":
            return <NcPaper>{value.map((item, index) => { return <li key={index}><Chip variant="outlined" size="small" label={item} /></li> })}</NcPaper>
        default:
            return <NcTypography>{value}</NcTypography>
    }
}

const FormControlStatic = (props) => {
    return (
        <NcFormControl margin="normal" fullWidth>
            <Grid container spacing={1} alignItems="flex-end">
                {props.startAdornment &&
                    <Grid item xs={'auto'}>
                        {props.startAdornment}
                    </Grid>}
                <Grid item xs={true}>
                    <NcFormLabel component="legend">{props.label}</NcFormLabel>
                    <FormControlValue value={props.value} />
                </Grid>
            </Grid>
        </NcFormControl>
    )
}

export default FormControlStatic