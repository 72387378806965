import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Slide } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function FormDialog(props) {
  const handleClose = () => {
    props.setIsOpenAssign(false);
  };

  const handleCloseBatch = ()=>{
    props.setIsOpenBatchAssign(false);
  }

  return (
    <div>
    <Dialog
      open={props.isOpenAssign}
      onClose={handleClose}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      fullWidth
      keepMounted
    >
      <DialogTitle style={{ fontWeight: 600 }}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ flexGrow: 1}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {props.content}
            </Grid>
            <Grid item xs={12} textAlign="right">
              {props.action}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      {/* {props.action && <DialogActions sx={{ mr: 1, mb: 1 }}>{props.action}</DialogActions>} */}
    </Dialog>
      <Dialog
      open={props.isOpenBatchAssign}
      onClose={handleCloseBatch}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      fullWidth
      keepMounted
    >
      <DialogTitle style={{ fontWeight: 600 }}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ flexGrow: 1}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {props.content}
            </Grid>
            <Grid item xs={12} textAlign="right">
              {props.action}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      {/* {props.action && <DialogActions sx={{ mr: 1, mb: 1 }}>{props.action}</DialogActions>} */}
    </Dialog>
    </div>
  );
}

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
  { label: 'Monty Python and the Holy Grail', year: 1975 },
];