// Default React, useState and Material Components
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const ScoreParamsSideDrawer = ({
  // Declaration of States and Component Variables Start Here
  crudState,
  state,
  setState,
  selectedRow,
  setSelectedRow,
  reload,
  setReload,
}) => {
  const classes = useStyles();
  console.log(selectedRow, "selectedRow");
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(null);
  const [defaultTyoe, setDefaultType] = useState(null);
  const [typeValue, setTypeValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [defaultScoringLevelConfig, setDefaultScoringLevelConfig] = useState(0);
  const [name, setName] = useState(crudState === "Add" ? "" : selectedRow.name);
  const [type, setType] = useState(
    crudState === "Add" ? [] : selectedRow.parameterType
  );
  const [descrption, setDescription] = useState(
    crudState === "Add" ? "" : selectedRow.description
  );
  const [defaultScoringLevelConfigValue, setDefaultScoringLevelConfigValue] =
    useState(null);
  const [score, setScore] = useState(
    crudState === "Add" ? "" : selectedRow.score.toString()
  );
  const [scoringlevelConfig, setScoringlevelConfig] = useState(
    crudState === "Add" ? [] : selectedRow.parameterType
  );
  // Declaration of States and Component Variables End Here
  const [jobType, setJobType] = useState(
    crudState === "Add" ? [] : selectedRow.jobType
  );
  const [defaultJobType, setDefaultJobType] = useState(0);
  const [defaultJobTypeValue, setDefaultJobTypeValue] = useState(null);

  useEffect(() => {
    // setDefaultType(selectedRow.parameterType? selectedRow.parameterType : null)
    axios
      .get(
        `${process.env.React_App_baseUrl}managedata/GetParameterTypeDropdown`,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setType(res.data);
        for (let options of res.data) {
          if (selectedRow) {
            if (options.name === selectedRow.parameterType) {
              setValue(options);
              setTypeValue(options.id);
            }
          }
        }
      })
      .catch((err) => console.log(err));
    //load job type dropdown
    axios
      .get(
        `${process.env.React_App_baseUrl}job/GetJobTypeDropDown
        `,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setJobType(res.data);
        for (let options of res.data) {
          if (selectedRow) {
            if (options.name === selectedRow.jobType) {
              setDefaultJobTypeValue(options);
              setDefaultJobType(options.id);
            }
          }
        }
      })
      .catch((err) => console.log(err));
    // load scoring level configuration drop down on

    /*     axios
      .get(
        `${
          process.env.React_App_baseUrl
        }managedata/GetScoringLevelConfigurationDropdownWithJobType?jobTypeId=${1}`,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setScoringlevelConfig(res.data);
        for (let options of res.data) {
          if (selectedRow) {
            if (options.name === selectedRow.scoringLevelConfiguration) {
              setDefaultScoringLevelConfigValue(options);
              setDefaultScoringLevelConfig(options.id);
            }
          }
        }
      })
      .catch((err) => console.log(err)); */
  }, []);
  useEffect(() => {
    if (crudState !== "Add" && defaultJobTypeValue?.id !== undefined) {
      ScoringLevelConfigurationDropdown(defaultJobTypeValue?.id);
    }
  }, [crudState, defaultJobTypeValue?.id]);


  // This function is used to get the Scoring Level Configuration Dropdown with Job Type
const ScoringLevelConfigurationDropdown = (id) => {
  // Making an axios call to get the data from the server
  axios
    .get(
      `${process.env.React_App_baseUrl}managedata/GetScoringLevelConfigurationDropdownWithJobType?jobTypeId=${id}`,
      {
        headers: {
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      }
    )
    .then((res) => {
      // Setting the scoring level config
      setScoringlevelConfig(res.data);
      // Looping through the options and setting the default scoring level config value
      for (let options of res.data) {
        if (selectedRow) {
          if (options.name === selectedRow.scoringLevelConfiguration) {
            setDefaultScoringLevelConfigValue(options);
            setDefaultScoringLevelConfig(options.id);
          }
        }
      }
    })
    .catch((err) => console.log(err));
};

// This function is used to save the parameter

  const saveParameter = async () => {
     // Set loading to true
    setLoading(true);

     // Check if all required fields are filled
    if (
      name.length > 0 &&
      typeValue > 0 &&
      score.length > 0 &&
      defaultJobType > 0 &&
      defaultScoringLevelConfig > 0
    ) {
      // Check if score is greater than 100
      if (score > 100) {
        toasterError("Score should not be greater than 100!");
      } else {
      // Check if only numbers are allowed as input for scoring  
        if (!/^[0-9]+$/.test(score)) {
          toasterError("Only numbers are allowed as input for scoring!");
        } else {
          let data = {
            id: 0,
            name: name,
            description: descrption,
            parameterTypeId: typeValue,
            score: score,
            scoringLevelConfigurationId: defaultScoringLevelConfig,
          };
           // Try to post data to server
          try {
            await axios
              .post(
                `${process.env.React_App_baseUrl}managedata/SaveScoringParameter`,
                data,
                {
                  headers: {
                    Authorization: localStorage.getItem("token")
                      ? "Bearer " + localStorage.getItem("token")
                      : null,
                  },
                }
              )
              .then((res) => {
                // Check if response status is 200
                if (res.status === 200) {
                  setReload(!reload);
                  setState({ ...state, right: false });
                  setLoading(false);
                  toasterSuccess("Parameter added successfully!");
                } else {
                  toasterError("Something went wrong!");
                }
              });
          } catch (error) {
            toasterError(error.response.data);
          }
        }
      }
    } else {
      toasterError("Please check the required fields");
    }
// Set loading to false
    setLoading(false);
  };

  // This function updates the parameter with the given data
  const updateParameter = async () => {
   // Set loading to true 
    setLoading(true);
   // Check if all required fields are filled 
    if (
      name.length > 0 &&
      typeValue > 0 &&
      score.length > 0 &&
      defaultJobType > 0 &&
      defaultScoringLevelConfig > 0
    ) {
     // Check if score is greater than 100 
      if (score > 100) {
        toasterError("Score should not be greater than 100!");
      } else {
      // Check if only numbers are allowed as input for scoring  
        if (!/^[0-9]+$/.test(score)) {
          toasterError("Only numbers are allowed as input for scoring!");
        } else {
          // Create data object
          let data = {
            id: selectedRow.id,
            name: name,
            description: descrption,
            parameterTypeId: typeValue,
            score: score,
            scoringLevelConfigurationId: defaultScoringLevelConfig,
          };
          try {
           // Make a post request to update the parameter 
            const response = await axios.post(
              `${process.env.React_App_baseUrl}managedata/UpdateScoringParameter`,
              data,
              {
                headers: {
                  Authorization: localStorage.getItem("token")
                    ? "Bearer " + localStorage.getItem("token")
                    : null,
                },
              }
            );
            // Check if the request was successful
            if (response.status === 200) {
              setReload(!reload);
              setState({ ...state, right: false });
              setLoading(false);
              toasterSuccess("Parameter updated successfully!");
            } else {
              toasterError("Something went wrong!");
            }
          } catch (error) {
            toasterError(error.response.data);
          }
        }
      }
    } else {
      toasterError("Please check the required fields");
    }
// Set loading to false
    setLoading(false);
  };

  return (
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {crudState} Score Parameter
        </Typography>
      </Box>

      {/* Add & Edit Commodity Form */}
      {console.log("TESTSS", defaultJobTypeValue?.id)}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {selectedRow ? (
            <Autocomplete
              id="size-small-standard"
              options={jobType}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                // setTypeValue(value.id);
                //console.log("TEST", value.id);
                setDefaultJobType(value.id);
                setDefaultJobTypeValue(value);
                ScoringLevelConfigurationDropdown(
                  defaultJobTypeValue?.id || value.id
                );
              }}
              value={defaultJobTypeValue}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Job Type"
                  placeholder="Job Type"
                />
              )}
            />
          ) : (
            <Autocomplete
              id="size-small-standard"
              options={jobType}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                console.log("TEST", value.id);
                setDefaultJobType(value.id);
                setDefaultJobTypeValue(value);
                ScoringLevelConfigurationDropdown(value.id);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Job Type"
                  placeholder="Job Type"
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-basic"
            label="Name"
            fullWidth
            required
            variant="standard"
            inputProps={{ maxLength: 50 }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="standard-basic"
            label="Description"
            fullWidth
            variant="standard"
            multiline
            maxRows={4}
            value={descrption}
            inputProps={{ maxLength: 250 }}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          {selectedRow ? (
            <Autocomplete
              id="size-small-standard"
              options={type}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                setTypeValue(value.id);
                setValue(value);
              }}
              value={value}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Parameter Type"
                  placeholder="Parameter Type"
                />
              )}
            />
          ) : (
            <Autocomplete
              id="size-small-standard"
              options={type}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                setTypeValue(value.id);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Parameter Type"
                  placeholder="Parameter Type"
                />
              )}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {selectedRow ? (
            <Autocomplete
              id="size-small-standard"
              options={scoringlevelConfig}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                // setTypeValue(value.id);
                setDefaultScoringLevelConfig(value.id);
                setDefaultScoringLevelConfigValue(value);
              }}
              value={defaultScoringLevelConfigValue}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Scoring Level Configuration"
                  placeholder="Scoring Level Configuration"
                />
              )}
            />
          ) : (
            <Autocomplete
              id="size-small-standard"
              options={scoringlevelConfig}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                setDefaultScoringLevelConfig(value.id);
                setDefaultScoringLevelConfigValue(value);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Scoring Level Configuration"
                  placeholder="Scoring Level Configuration"
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-basic"
            label="Score"
            fullWidth
            required
            variant="standard"
            inputProps={{ maxLength: 50 }}
            value={score}
            onChange={(e) => {
              const newValue = e.target.value;

              // Check if the input is not just "0"
              if (
                newValue === "" ||
                (!isNaN(newValue) && parseFloat(newValue) > 0)
              ) {
                setScore(newValue);
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ mt: -1 }}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={"auto"}>
              {/* <Button variant="contained" color="accent5" onClick={crudState === "Add" ? saveData : updateData}>
                  {crudState === "Edit" ? "Update" : "Save"}
                </Button> */}
              <LoadingButton
                buttonName={crudState === "Add" ? "Save" : "Update"}
                clickHandler={
                  crudState === "Add" ? saveParameter : updateParameter
                }
                isLoading={loading}
              />
            </Grid>
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                color="accent3"
                onClick={() => setState({ ...state, right: false })}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ScoreParamsSideDrawer;
