// Default React, useState and Material Components
import React, { useState } from "react";
import { Box, Breadcrumbs, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';

// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import SideDrawer from "../../../UIComponent/Drawer";
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import AddEditFinancialYearQuarters from "./AddEditFinancialYearQuarters";
import { toasterSuccess, toasterError } from '../../../UIComponent/Toaster/index';

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const FinancialYearQuarters = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState([]);
  const [selected, SetSelected] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [allSubRegion, setAllStatus] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [state, setState] = useState({ right: false });
  const [openDelete, setOpenDelete] = useState(false);
  const [startSearch, setStartSearch] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [deleteFinancialYearQuarters, setDeleteFinancialYearQuarters] = useState(null);
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false });
  // Declaration of States and Component Variables End Here

  React.useEffect(() => {
    GetMenuService(18, function (err, res) {
      if (res) {
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])

  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) { searchUser('') }
    else { searchUser(searchValue) }
  }, [sorting]);

  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) { searchUser('') }
    else { searchUser(searchValue) }
  }, [page, reload, rowsPerPage])

  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") {
      setDeleteFinancialYearQuarters(item);
      setOpenDelete(true);
    } else {
      setFormData(item);
      setState({ ...state, right: open, formData: item });
      setCrudState(mode);
    }
  };

  // Assigning Colums and UI Props
  const columns = [
    { id: "countryName", label: "Country", minWidth: 150, width: '15%', sorting: true },
    { id: "financialYear", label: "Financial Year", align: "center", minWidth: 110, width: '20%' },
    { id: "q1", label: "Q1", align: "center", minWidth: 110, width: '15%' },
    { id: "q2", label: "Q2", align: "center", minWidth: 110, width: '15%' },
    { id: "q3", label: "Q3", align: "center", minWidth: 110, width: '15%' },
    { id: "q4", label: "Q4", align: "center", minWidth: 110, width: '25%' }
  ];

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { getSubRegionData(); }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      setTotalCount(0);
      setPage(0);
      setStartSearch(true)
      searchUser(event.target.value);
    }
  };

  // Function for Global Search
  const globalSearch = (value) => { setSearchValue(value); };

  // Function for Search Clear
  const changeSortColumnNameFormat = (fieldName) => {
    if (fieldName == null || fieldName == "") { return ""; }
    if (fieldName.toLocaleLowerCase() == "countryname") { fieldName = "CountryName"; }
    return fieldName;
  }
  const searchUser = (filterValue) => {
    setLoading(true);
    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
    ApiService.search("managedata/GetFinancialYearAndQuarterDetails?", {
      sortColumn: changeSortColumnNameFormat(sorting.sortBy),
      isAscending: sorting.isAscending,
      searchText: filterValue
    }, prt)
      .then((res) => {
        if (res.status === 200) {
          let searchResult = Array.isArray(res.data) ? res.data : res.data.data || [];
          setAllStatus(searchResult);
          setLoading(false);
          setTotalCount(res.data.totalCount);
        } else { toasterError(`${res.data}`); setLoading(false); }
      })
      .catch((err) => {
        setLoading(false); ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

  // Function for Get Financial Year Quarters Data
  const getSubRegionData = () => {
    setLoading(true);
    if (reload) { setReload(false); }

    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
    ApiService.search("managedata/GetFinancialYearAndQuarterDetails?",
      {
        sortColumn: changeSortColumnNameFormat(sorting.sortBy),
        isAscending: sorting.isAscending,
      }, prt)

      .then((res) => {
        if (res.status === 200) {
          let result = Array.isArray(res.data) ? res.data : res.data.data || [];
          setAllStatus(result);
          setLoading(false);
          setTotalCount(res.data.totalCount);

        } else { toasterError(`${res.data}`); setLoading(false); }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

  // Function for Delete Financial Year Quarters Data
  const deleteFinancialYearQuartersData = () => {
    setDeleteBtnLoadinng(true);
    let financialYearAndQuarterId = deleteFinancialYearQuarters.id;
    axios.post(
      process.env.React_App_baseUrl + "managedata/DeleteFinancialYearAndQuarter", null, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
      params: { financialYearAndQuarterId }
    }).then((res) => {
      if (res.status === 200) {
        toasterSuccess("Financial Year and Quarters deleted successfully");
        getSubRegionData();
        setDeleteBtnLoadinng(false);
        setOpenDelete(false);
      } else {
        toasterError("Somthing went wrong")
        setOpenDelete(false);
        setDeleteBtnLoadinng(false);
      }
    })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        }); setDeleteBtnLoadinng(false);
      });
  };

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Financial Year and Quarters</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">Financial Year and Quarters</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}

        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {(access.allowEdit) ? <>
                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: 16 }} />}>
                  Add Details
                </Button>
              </> : null
              }
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="serchByClear"
                        onClick={() => {
                          setSearchValue("");
                          getSubRegionData();
                        }}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Financial Year Quarters Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allSubRegion ? allSubRegion : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setReload(true)
                    setSorting(e)
                  }}
                  isActionButton={{
                    ...(access.allowView && {
                      view: toggleDrawer(true, "View"),
                    }),
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Add & Edit Financial Year Quarters Side Drawer */}
      <SideDrawer
        formComp={
          <AddEditFinancialYearQuarters
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            hideSideDrawer={() => {
              setState({ right: false });
              getSubRegionData();
            }}
          />
        }
        state={state}
        setState={setState}
      />

      {/* Alert Dialog - Confirmation for Delete */}
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to delete the Financial Year and Quarters details of country "${deleteFinancialYearQuarters?.countryName}" ?`}
          action={
            <Grid container alignItems="center" justifyContent="right" spacing={2} className={classes.alertDialogButton}>
              <Grid item xs={"auto"}>
                <Button onClick={() => setOpenDelete(false)}>No</Button>
              </Grid>
              <Grid item xs={"auto"}>
                <Button onClick={() => deleteFinancialYearQuartersData()} color="secondary">
                  {deleteBtnLoadinng ? (<CircularProgress size={25} />) : ("Yes")}
                </Button>
              </Grid>
            </Grid>
          }
        />
      ) : null}
    </Box>
  );
};

export default FinancialYearQuarters;