import React, { useEffect, useState } from "react";
import ErroHandling from "../../../Services/ErrorHandling";
// Default React, Router and Services Components
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Chip,
} from "@mui/material";
import {
  AddIcon,
  AttachIcon,
  CirclePlusIcon,
  DownloadDocumentIcon,
  FileOffIcon,
} from "@fluentui/react-icons-mdl2";
import CircularProgress from "@mui/material/CircularProgress";
import AlertDialog from "../../../UIComponent/AlertDialog";
// Importing Custom Components
import DatePicker from "../../../UIComponent/DatePicker";
import Dropzone from "../../../UIComponent/DragDropField";
import {
  CancelIcon,
  DeleteIcon,
  TextDocumentIcon,
  LinkIcon,
} from "@fluentui/react-icons-mdl2";
import ApiService from "../../../Services/ApiService";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// import history from "../../../Routes/History";
import { useHistory } from "react-router-dom";

import SideDrawer from "../../../UIComponent/Drawer";
import { ScrollingDialog } from "../../../UIComponent";
import AdobeViewer from "./AdobeViewer";
import dayjs from "dayjs";

// Import Fluent UI Icons

// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";
import "./index.scss";
import axios from "axios";
//ADD ON
// Accordion Components
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

// Import Material & Fluent UI Icons
import PlaceIcon from "@mui/icons-material/Place";
import RemoveIcon from "@mui/icons-material/Remove";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import { styled } from "@mui/material/styles";
import DataTable from "../../../UIComponent/DataTable";
import { setDate } from "date-fns";
import CustomDataTable from "../../../UIComponent/CustomDataTable/CustomDataTable";
import GetMenuService from "../../../Services/GetMenuService";
import AddSeeThroughOwnerInfo from "./AddSeeThroughOwnerInfo";
import CommonService from "../../../Services/CommonService";
import EditSeeThroughOwnerInfo from "./EditSeeThroughtOwnerInfo";
import { Value } from "sass";
import EmptyState from "../../../UIComponent/EmptyState";
import AddReportInfo from "./AddReportInfo";
/******************ADD ON START*********************** */

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1, 0, 1, 0),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
/************ADD ON END************* */
const OwnershipChangesOwnerInfo = (props) => {
  const history = useHistory();
  console.log(props);
  console.log(history);
  const classes = useStyles();
  const [data, setData] = useState({});
  const [legalid, setLegalId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [access, setAccess] = React.useState({
    allowEdit: false,
    allowDelete: false,
    allowView: false,
  });
  //USESTATE FOR DROPDOWN VALUES
  const [legalentitydropdown, setLegalEntityDropdown] = React.useState([]);
  const [ownerdropdown, setOwnerDropdown] = React.useState([]);
  const [popupDropdown, setPopupDropdown] = React.useState([]);
  const [popdata, setPopdata] = React.useState(null);
  const [previousPopdata, setPreviousPopdata] = useState(null);
  const [preselectedOwner, setPreselectedOwner] = React.useState(null);
  const [sourcefiledropdown, setSourceFileDropdown] = React.useState([]);
  const [stockdropdown, setStockExchangeDropdown] = useState([]);
  const [allYear, setAllYear] = useState([]);
  const [preselectedYear, setPreselectedYear] = React.useState(null);
  const [preselectedStock, setPreselectedStock] = React.useState(null);
  const [preselectedSource, setPreselectedSource] = React.useState([]);
  const [preselectedSourcePercentage, setPreselectedSourcePercentage] =
    React.useState([]);
  const [preselectedSourceStock, setPreselectedSourceStock] = React.useState(
    []
  );
  const [seeThrough, setSeeThrough] = React.useState();
  const [addCustomeStock, setAddCustomeStock] = useState(false);
  const [addLegalEntity, setAddLegalEntity] = useState(false);
  const [customeLegal, setCustomLegal] = React.useState({
    ownerGuid: "",
    correctOwnerName: "",
    legalEntityTypeId: 0,
  });
  const [seethroughdata, setSeeThroughData] = useState([]);
  const [customeStock, setCustomeStock] = useState({
    id: 0,
    symbol: "",
    name: "",
  });

  const [addCustomeOwner, setAddCustomeOwner] = React.useState(false);
  const [customeOwner, setCustomeOwner] = React.useState({
    ownerGuid: "",
    correctOwnerName: "",
    legalEntityTypeId: 0,
  });
  const [stateReport, setStateReport] = React.useState({ right: false });
  const [prefillReportName, setPrefillReportName] = React.useState(null);
  const [reload,setReload]=React.useState(false);
  const [showReportInfo, setShowReportInfo] = React.useState(false);
  const [crudStateReport, setCrudStateReport] = React.useState("");

  const [deleteData, setDeleteData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteSource, setOpenDeleteSource] = useState(false);
  const [openLegal, setOpenLegal] = useState(false);
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [removeIndex, setRemoveIndex] = useState(null);
  const [order, setOrder] = useState(null);
  const [reportInfoId, setReportInfoIdValue] = useState(null);
  const [BtnLoadinng, setBtnLoadinng] = useState(false);
  const [state, setState] = useState({ right: false });
  const [stateedit, setStateEdit] = useState({ right: false });
  const [crudState, setCrudState] = React.useState(false);
  const [crudStateEdit, setCrudStateEdit] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [openpopup, setOpenPopup] = React.useState(false);
  const [ownerComment, setOwnerComment] = React.useState("");
  const [percentageComment, setPercentageComment] = React.useState("");
  const [stockComment, setStockComment] = React.useState("");
  const [ownershipdate, setOwnershipDate] = React.useState("");
  const [ownershippercentage, setOwnershipPercentage] = React.useState("");
  const [prefillData, setPreFillData] = useState([]);

  var intialReportValue = {
    reportInfoId: 0,
    sourceFileId: 0,
    pageNo: 0,
    isDeleted: false,
    isEdited: false,
    reportType: 0,
  };

  const [reportInfoDataValue, setReportInfoDataValue] = useState([
    intialReportValue,
  ]);
  const [reportInfoOwnershipValue, setReportInfoOwnershipValue] = useState([
    intialReportValue,
  ]);
  const [reportInfoStockValue, setReportInfoStockValue] = useState([
    intialReportValue,
  ]);
  const [defaultSourcefile, setDefaultSourceFile] = React.useState(null);
  const [pgno, setPgNo] = React.useState(null);

  const [ownerfromdata, setOwnerFormData] = useState({});

  const finalData = {
    id: ownerfromdata.ownerInfoId ? ownerfromdata.ownerInfoId : 0,
    jobAssetId: ownerfromdata.jobAssetId,
    unsure: ownerfromdata.unsure ? ownerfromdata.unsure : false,
    legalEntityTypeId: ownerfromdata.legalEntityTypeId
      ? ownerfromdata.legalEntityTypeId
      : null,
    ownerGuid: ownerfromdata.ownerGuid,
    ownerName: ownerfromdata.ownerName ? ownerfromdata.ownerName : "",
    ownerComment: ownerfromdata.ownerComment,
    yearId: ownerfromdata.yearId ? ownerfromdata.yearId : 0,
    ownershipPercentage:  Number(ownerfromdata.ownershipPercentage),
    /* ownershipDate: ownerfromdata.ownershipDate
      ? ownerfromdata.ownershipDate
      : null, */
    ownershipComment: ownerfromdata.ownershipComment,
    stockExchangeId: ownerfromdata.stockExchangeId,
    stockExchangeComment: ownerfromdata.stockExchangeComment,
    ownerSourceFile: [
      ...(ownerfromdata.editOwnerReportDetails?.map((link) => ({
        ...link,
      })) || []),
      ...(ownerfromdata.editOwnershipReportDetails?.map((link) => ({
        ...link,
      })) || []),
      ...(ownerfromdata.editOwnerStockReportDetails?.map((link) => ({
        ...link,
      })) || []),
    ],
  };

  useEffect(() => {
    console.log("ownerfromdata.ownershipPercentage",ownerfromdata.ownershipPercentage)
    var a = {
      id: ownerfromdata.ownerInfoId ? ownerfromdata.ownerInfoId : 0,
      jobAssetId: props.jobAssetId,
      unsure: ownerfromdata.unsure ? ownerfromdata.unsure : false,
      legalEntityTypeId: ownerfromdata.legalEntityTypeId
        ? ownerfromdata.legalEntityTypeId
        : 0,
      ownerGuid: ownerfromdata.ownerGuid,
      ownerName: ownerfromdata.ownerName ? ownerfromdata.ownerName : "",
      ownerComment: ownerfromdata.ownerComment,
      yearId: ownerfromdata.yearId ? ownerfromdata.yearId : 0,
      ownershipPercentage: Number(ownerfromdata.ownershipPercentage),
      //ownershipDate: ownerfromdata.ownershipDate,
      ownershipComment: ownerfromdata.ownershipComment,
      stockExchangeId: ownerfromdata.stockExchangeId,
      stockExchangeComment: ownerfromdata.stockExchangeComment,
      ownerSourceFile: [
        ...(ownerfromdata.editOwnerReportDetails?.map((link) => ({
          ...link,
        })) || []),
        ...(ownerfromdata.editOwnershipReportDetails?.map((link) => ({
          ...link,
        })) || []),
        ...(ownerfromdata.editOwnerStockReportDetails?.map((link) => ({
          ...link,
        })) || []),
      ],
    };
    props.setFinalData({ ...props.finalData, saveOwnerInfo: a });
    props.setSaveOwnerInfo(a)
  }, [ownerfromdata]);

  React.useEffect(() => {
    GetMenuService(54, function (err, res) {
      if (res) {
        setAccess(res.data);
      } else {
        toasterError(err);
      }
    });
  }, []);

  const columns = [
    {
      id: "id",
      label: "id",
      type: "Highlight",
    },
    {
      id: "sequenceNumber",
      label: "Sl.No",
      minWidth: 90,
      width: "90",
      align: "left",
    },
    {
      id: "seeThroughOwnerName",
      label: "Equity Owner",
      minWidth: 250,
      width: "20%",
      align: "left",
    },

    {
      id: "seeThroughOwnershipPercentage",
      label: "Equity Owner %",
      // isLinkColumn: true,
      //comment: true,
      // isIcons: true,
      //colorCode: "blue",
      //attatch: true,
      //qValue: true,
      minWidth: 200,
      width: "20%",
    },
    {
      id: "year",
      label: "Equity Owner Date",
      minWidth: 150,
      width: "15%",
      align: "left",
    },
    {
      id: "stockExchangeName",
      label: "Stock Exchange",
      minWidth: 250,
      // width: "20%",
      align: "left",
    },
    /*     {
      id: "actions",
      label: "Action",
      minWidth: 110,
      width: "10%",
      align: "center",
    }, */
  ];
  const columnsLegalEntity = [
    {
      id: "commonVariation",
      label: "Common Variation",
      minWidth: 250,
      width: "20%",
      align: "left",
    },
    {
      id: "cruFormat",
      label: "CRU Format",
      minWidth: 250,
      width: "20%",
      align: "left",
    },
    {
      id: "country",
      label: "Country",
      minWidth: 250,
      width: "20%",
      align: "left",
    },
    {
      id: "actions",
      label: "Action",
      minWidth: 90,
      width: "10%",
      align: "center",
    },
  ];
  const getOwnerInfo = async () => {
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0
    ) {
      return;
    }
    await axios
      .post(
        `${process.env.React_App_baseUrl}ManageOwnership/GetOwnerInfoDetails`,
        null,
        {
          headers: {
            jobAssetId: props.jobAssetId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        //;
        setData(res.data);
        console.log("OWD", res.data);
        console.log("PRE", res.data?.ownerGuid);
        //setOwnerFormData(res.data);

        let ownerData = [];
        let ownershipData = [];
        let stockData = [];
        if (res.data.ownerInfoReportDetailsList !== null) {
          res.data.ownerInfoReportDetailsList.forEach((e) => {
            const report = {
              reportInfoId: e.reportInfoMappingId,
              sourceFileId: e.jobAssetReportInfoId,
              reportName: e.reportName,
              pageNo: e.pageNumber,
              isDeleted: false,
              isEdited: false,
              reportType: e.reportType,
              link:e.link,
            };

            if (e.reportType === 1) {
              ownerData.push(report);
            } else if (e.reportType === 2) {
              ownershipData.push(report);
            } else if (e.reportType === 3) {
              stockData.push(report);
              console.log("Reporte", report);
            }
          });
        }
        setOwnerFormData({
          ownerInfoId: res.data.ownerInfoId ? res.data.ownerInfoId : 0,
          jobAssetId: props.jobAssetId,
          unsure: res.data.unsure ? res.data.unsure : false,
          legalEntityTypeId: res.data.legalEntityTypeId
            ? res.data.legalEntityTypeId
            : null,
          ownerGuid: res.data.ownerGuid,
          ownerName: res.data.ownerName ? res.data.ownerName : "",
          ownerComment: res.data.ownerComment,
          yearId: res.data.yearId ? res.data.yearId : 0,
          ownershipPercentage: res.data.ownershipPercentage,
          /* ownershipDate: res.data.ownershipDate
            ? dayjs(res.data.ownershipDate, "DD-MM-YYYY").format(
                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              )
            : null, */

          ownershipComment: res.data.ownershipComment,
          stockExchangeId: res.data.stockExchangeId,
          stockExchangeComment: res.data.stockExchangeComment,
          editOwnerReportDetails: ownerData,
          editOwnershipReportDetails: ownershipData,
          editOwnerStockReportDetails: stockData,
        });

        // Find the preselected asset object based on matching asset IDs

        setOwnerComment(res.data?.ownerComment);
        setPercentageComment(res.data?.ownershipComment);
        setStockComment(res.data?.stockExchangeComment);
        setOwnershipPercentage(res.data?.ownershipPercentage);
        setOwnershipDate(res.data?.ownershipDate);
        console.log("Drop", ownerdropdown, res.data?.ownerGuid, stockdropdown);
        /* const preselected = ownerdropdown.find(
          (e) => e.guid === res.data?.ownerGuid
        ); */
        let preselected;
/*         if(preselectedOwner===null)
       { 
         preselected = ownerdropdown.find(
          (e) => e.guid === res.data?.ownerGuid
        )}else{
           preselected = ownerdropdown.find(
            (e) => e.guid === preselectedOwner.guid
          )
        } */
        preselected = ownerdropdown.find(
          (e) => e.guid === res.data?.ownerGuid
        )
        if (preselected) {
          if (preselected.guid !== "") {
            setPreselectedOwner(preselected);
          }
        }
        console.log("TESTU", res.data?.ownerGuid,preselected);
/*         if (customeOwner) {
          if (customeOwner.ownerGuid !== "") {
            setPreselectedOwner({
              guid: customeOwner.ownerGuid,
              companyName: customeOwner.correctOwnerName,
            });
            console.log("Chcek", customeOwner);
          }
        }
        if (customeLegal) {
          if (
            customeLegal.ownerGuid !== "" &&
            customeOwner.ownerGuid === customeLegal.ownerGuid
          ) {
            const preselectedlegal = ownerdropdown.find(
              (e) => e.guid === customeLegal.ownerGuid
            );
            setPreselectedOwner(preselectedlegal);
          }
        } */

        setSeeThrough(res.data?.ownerInfoId);
        console.log("OWID", res.data?.ownerInfoId);
        const preselectedstock = stockdropdown.find(
          (e) => e.id === res.data?.stockExchangeId
        );
        setPreselectedStock(preselectedstock);

        const preselectedyear = allYear.find(
          (e) => e.id === res.data?.yearId
        );
        setPreselectedYear(preselectedyear);
        
        if (customeStock.name !== "") {
          setPreselectedStock(customeStock);
        }
        //Source files
        const preselectedSourceFilesOwner = ownerData.map((item) => ({
          sourceFile: item,
          pageNo: item.pageNo || "",
        }));
        setReportInfoDataValue(preselectedSourceFilesOwner);

        const preselectedSourceFilesOwnership = ownershipData.map((item) => ({
          sourceFile: item,
          pageNo: item.pageNo || "",
        }));
        setReportInfoOwnershipValue(preselectedSourceFilesOwnership);

        const preselectedSourceFilesStock = stockData.map((item) => ({
          sourceFile: item,
          pageNo: item.pageNo || "",
        }));
        setReportInfoStockValue(preselectedSourceFilesStock);
      })

      .catch((err) => {
        {
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        }
      });
  };
  console.log("SEET", seeThrough);
  // Handling customeOwner and customeLegal
  const [legalOwnerStatus,SetLegalOwnerStatus]=React.useState(false)
  useEffect(() => {
    if(legalOwnerStatus){
          if (customeOwner) {
          if (customeOwner.ownerGuid !== "") {
            setPreselectedOwner({
              guid: customeOwner.ownerGuid,
              companyName: customeOwner.correctOwnerName,
            });
            console.log("Chcek", customeOwner);
          }
        }
        if (customeLegal) {
          if (
            customeLegal.ownerGuid !== "" &&
            customeOwner.ownerGuid === customeLegal.ownerGuid
          ) {
            const preselectedlegal = ownerdropdown.find(
              (e) => e.guid === customeLegal.ownerGuid
            );
            setPreselectedOwner(preselectedlegal);
          }
        }
      }
}, [customeOwner, customeLegal,legalOwnerStatus]);
  /*********API CALLS TO POPULATE DROPDOWN VALUES*********** */
  const GetOwnerDropdown = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetCorrectOwnerDropdown",
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setOwnerDropdown(res.data);
        console.log("Chcek", res.data);
        if (customeOwner) {
          if (customeOwner.ownerGuid !== "") {
            setPreselectedOwner({
              guid: customeOwner.ownerGuid,
              companyName: customeOwner.correctOwnerName,
            });
            console.log("Chcek", customeOwner);
          }
        }
        if (customeLegal) {
          if (
            customeLegal.ownerGuid !== "" &&
            customeOwner.ownerGuid === customeLegal.ownerGuid
          ) {
            const preselectedlegal = ownerdropdown.find(
              (e) => e.guid === customeLegal.ownerGuid
            );
            setPreselectedOwner(preselectedlegal);
          }
        }
        /*  if (popdata) {
          if (popdata.guid !== "") {
            console.log("Popu", popdata.guid);
            setPreselectedOwner(popdata);
          }
        } */
      });
  };

  const GetPopupCompanyDropdown = () => {
    axios
      .get(
        process.env.React_App_baseUrl +
          "DropDown/GetCompanyDropDownForCompanyStockExchange",
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setPopupDropdown(res.data);
        console.log(res.data);
      });
  };
  const GetLegalEntityGrid = () => {
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0
    ) {
      return;
    }
    axios
      .get(
        process.env.React_App_baseUrl +
          `ManageOwnership/GetLegalEntityTypes?jobAssetId=${props.jobAssetId}`,
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setLegalEntityDropdown(res.data);
        console.log(res.data);
      });
  };
  const GetSourceFileDropdown = () => {
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0
    ) {
      return;
    }
    axios
      .post(
        process.env.React_App_baseUrl +
          "ManageOwnership/GetSourceFileOwnerInfoDropdown",
        null,
        {
          headers: {
            jobAssetId: props.jobAssetId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setSourceFileDropdown(res.data);
        console.log(res.data);
      });
  };
  const GetStockExchangeDropdown = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "stock/GetStockExchangeDropDown",

        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setStockExchangeDropdown(res.data);
        console.log(res.data);
      });
  };
  const getAddReportYear = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "ManageOwnership/GetReportingYears",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setAllYear(res.data);
        } else {
          toasterError("Something went wrong");
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

  const getGridData = () => {
    if (
      data?.ownerInfoId === undefined ||
      data?.ownerInfoId === null ||
      data?.ownerInfoId === 0
    ) {
      return;
    }
    setLoading(true);
    axios
      .get(
        process.env.React_App_baseUrl + "ManageOwnership/GetSeeThroughInfoList",

        {
          headers: {
            ownerInfoId: data && data.ownerInfoId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        if (Array.isArray(res.data)) {
          // Check if res.data is an array
          setSeeThroughData(res.data);
          console.log("updates:", res.data);
        } else {
          console.log("Invalid data format:", res.data);
          // Handle the case when res.data is not an array
        }
      })

      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
        setLoading(false);
      });
  };

  React.useEffect(() => {

    setOwnerFormData({
      ...ownerfromdata,
      sourceFile: [],
    });
    GetOwnerDropdown();
    GetStockExchangeDropdown();
    //GetSourceFileDropdown();
    GetLegalEntityGrid();
    GetPopupCompanyDropdown();
    getAddReportYear()
  }, [props.jobAssetId]);

  React.useEffect(() => {
    GetSourceFileDropdown();
  }, [props.jobAssetId,props.reload]);
  React.useEffect(() => {
    getOwnerInfo();
  }, [props.jobAssetId, ownerdropdown, stockdropdown]);
  React.useEffect(() => {
    if (data) {
      getGridData();
    }
  }, [data]);
  React.useEffect(() => {
    if (props.ReportData) {
      GetSourceFileDropdown();
    }
  }, [props.ReportData,props.reload]);
  React.useEffect(() => {
    if (props.reload) {
      GetSourceFileDropdown();
    }
  }, [props.reload]);
  /**********SAVE OWNER INFO***********/
  const addFormData = async () => {
    setLoading(true);
    // Make the HTTP POST request
    axios
      .post(
        process.env.React_App_baseUrl + "ManageOwnership/SaveOwnerInfo",
        finalData,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then(function (response) {
        // Update state and display success message

        //props.setRefresh(!props.refresh);
        getOwnerInfo();
        //toasterSuccess("Update Added successfully");
        //setSuccessMessage("Update Added successfully");
        setLoading(false);
        props.hideSideDrawer();
        //setEditData(false);
      })
      .catch(function (error) {
        console.log(error);
        ErroHandling(error, function (res) {
          console.log(res);
          //toasterError(res);
        });
        //setLoading(false);
      });
  };

  const deleteRecord = () => {
    setDeleteBtnLoadinng(true);
    let data = deleteData;
    axios
      .delete(
        `${
          process.env.React_App_baseUrl
        }ManageOwnership/DeleteSeeThroughOwnerInfo?seeThroughOwnerInfoId=${deleteData}&isJointVentureSeeThroughOwnerInfo=${false}`,
        {
          headers: {
            //seeThroughOwnerInfoId: deleteData,
            //isJointVentureSeeThroughOwnerInfo: false,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setLoading(true);
        toasterSuccess("Equity Owner Deleted successfully!");
        getGridData();
        setDeleteBtnLoadinng(false);
        setOpenDelete(false);
      })
      .catch((err) =>
        ErroHandling(err, function (res) {
          toasterError(res);
        })
      );
  };
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") {
      setDeleteData(item.id);
      //setDeleteJobNumber(item.jobid);
      setOpenDelete(true);
    } else {
      //setSeeThrough(item.jobAssetJointVentureId);
      console.log("Heo", item);
      getGridData();
      addFormData();
      getOwnerInfo();
      setPreFillData(item);
      setState({ ...state, right: open, prefillData: item });
      setCrudState(mode);
    }
  };

  const toggleDrawerLegal = (open, mode) => (event, item, id) => {
    if (mode === "Legal") {
      console.log("MOD", id);
      setOpenLegal(true);
    }
  };
  const toggleDraweredit = (open, mode) => (event, item) => {
    console.log(item);
    getGridData();
    setPreFillData(item);
    setStateEdit({ ...stateedit, right: open, prefillData: item });
    setCrudStateEdit(mode);
  };

  const toggleDrawerReport = (open, mode) => (event, item) => {
    setStateReport({ ...stateReport, right: open });
    setShowReportInfo(true);
    setCrudStateReport(mode);
  };
  /**************API TO GET THE GRID DATA***************** */

  console.log(addCustomeStock);
  const handleClose = () => {
    setAddCustomeStock(!addCustomeStock);
  };
  const handleaddCustomeStock = () => {
    if (customeStock.name.length > 0 && customeStock.symbol.length > 0) {
      setLoading(true);
      ApiService.create("stock/SaveStockExchange", customeStock)
        .then((res) => {
          setLoading(false);
          GetStockExchangeDropdown();
          if (res.status === 200) {
            toasterSuccess("Stock Exchange added successfully ");
            setPreselectedStock(customeStock);
          } else {
            toasterError("Something went wrong");
          }
          setAddCustomeStock(!addCustomeStock);
        })
        .catch((err) => {
          setLoading(false);
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        });
    } else {
      toasterError("Please fill all the required fields");
    }
  };
  const [previousOperator, setPreviousOperator] = useState(null);
  const handleCloseOwner = () => {
    setPopdata(previousPopdata);
    setAddCustomeOwner(!addCustomeOwner);
    setPreselectedOwner(previousOperator);
  };
  const handleCloseLegal = () => {
    setAddLegalEntity(!addLegalEntity);
  };
  const handleAddCustomeOwner = () => {
    if (customeOwner.correctOwnerName.length > 0) {
      setLoading(true);
      ApiService.create("ManageOwnership/SaveCorrectOwnerName", customeOwner)
        .then((res) => {
          setLoading(false);
          GetOwnerDropdown();
          if (res.status === 200) {
            toasterSuccess("Owner added successfully ");
            SetLegalOwnerStatus(true)
            /* if (popdata) {
              if (popdata.ownerGuid !== "") {
                setPreselectedOwner(popdata);
              }
            } */
            console.log("Chcek", customeOwner);
          } else {
            toasterError("Something went wrong");
          }
          setAddCustomeOwner(!addCustomeOwner);
        })
        .catch((err) => {
          setLoading(false);
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        });
    } else {
      toasterError("Please fill the required fields");
    }
  };
  const handleAddCustomeLegalCheck = (id) => {
    setLoading(true);

    customeLegal.legalEntityTypeId = id;
    console.log("LEGALCH", customeLegal, id);

    ApiService.create("ManageOwnership/CheckCommonVariation", customeLegal)
      .then((res) => {
        setLoading(false);
        //GetOwnerDropdown();
        if (res.status === 200) {
          if (res.data === true) {
            handleAddCustomeLegal(id);
          } else {
            toggleDrawerLegal(true, "Legal")(id);
          }
        } else {
          toasterError("Something went wrong");
        }
        setAddLegalEntity(!addLegalEntity);
      })
      .catch((err) => {
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };
  const handleAddCustomeLegal = (id) => {
    setBtnLoadinng(true);
    customeLegal.legalEntityTypeId = id;
    //console.log("LEGAL", customeLegal);
    ApiService.create("ManageOwnership/SaveCorrectOwnerName", customeLegal)
      .then((res) => {
        setLoading(false);
        GetOwnerDropdown();
        if (res.status === 200) {
          toasterSuccess("Legal Entity Type Added Successfully ");
          SetLegalOwnerStatus(true)
          setBtnLoadinng(false);
        } else {
          toasterError("Something went wrong");
        }
        //setAddLegalEntity(!addLegalEntity);
      })
      .catch((err) => {
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

  const addReport = (reportType) => {
    if (reportType === 1) {
      setReportInfoDataValue((prevFiles) => {
        const updatedFileso = [...prevFiles, { sourceFile: null, pageNo: "" }];
        const updatedFormDataOwner = {
          ...ownerfromdata,
          editOwnerReportDetails: updatedFileso.map((file) => ({
            reportInfoId: 0,
            sourceFileId: file.sourceFile
              ? file.sourceFile.id || file.sourceFile?.sourceFileId
              : 0,
            reportName: file.sourceFile?.reportName,
            pageNo: file.pageNo,
            reportType: 1,
            isEdited: false,
            isDeleted: false,
          })),
        };
        setOwnerFormData((prevFormData) => ({
          ...prevFormData,
          editOwnerReportDetails: updatedFormDataOwner.editOwnerReportDetails,
          editOwnershipReportDetails:
            prevFormData.editOwnershipReportDetails?.filter(
              (file) => file.reportType === 2
            ),
          editOwnerStockReportDetails:
            prevFormData.editOwnerStockReportDetails?.filter(
              (file) => file.reportType === 3
            ),
        }));

        return updatedFileso;
      });
    } else if (reportType === 2) {
      setReportInfoOwnershipValue((prevFiles) => {
        const updatedFilesp = [...prevFiles, { sourceFile: null, pageNo: "" }];

        const updatedFormDataOwnership = {
          ...ownerfromdata,
          editOwnershipReportDetails: updatedFilesp.map((file) => ({
            reportInfoId: 0,
            sourceFileId: file.sourceFile
              ? file.sourceFile.id || file.sourceFile?.sourceFileId
              : 0,
            reportName: file.sourceFile
              ? file.sourceFile.reportName
              : file.reportName,
            pageNo: file.pageNo,
            reportType: 2,
            isEdited: false,
            isDeleted: false,
          })),
        };

        setOwnerFormData((prevFormData) => ({
          ...prevFormData,
          editOwnershipReportDetails:
            updatedFormDataOwnership.editOwnershipReportDetails,
          editOwnerReportDetails:
            prevFormData.editOwnerReportDetails?.filter(
              (file) => file.reportType !== 2
            ) ?? [],
          editOwnerStockReportDetails:
            prevFormData.editOwnerStockReportDetails?.filter(
              (file) => file.reportType !== 2
            ) ?? [],
        }));

        return updatedFilesp;
      });
    } else {
      setReportInfoStockValue((prevFiles) => {
        const updatedFiles = [...prevFiles, { sourceFile: null, pageNo: "" }];
        const updatedFormDataStock = {
          ...ownerfromdata,
          editOwnerStockReportDetails: updatedFiles.map((file) => ({
            reportInfoId: 0,
            sourceFileId: file.sourceFile
              ? file.sourceFile.id || file.sourceFile?.sourceFileId
              : 0,
            reportName: file.sourceFile
              ? file.sourceFile.reportName
              : file.reportName,
            pageNo: file.pageNo,
            reportType: 3,
            isEdited: false,
            isDeleted: false,
          })),
        };
        setOwnerFormData((prevFormData) => ({
          ...prevFormData,
          editOwnerStockReportDetails:
            updatedFormDataStock.editOwnerStockReportDetails,
          editOwnerReportDetails: prevFormData.editOwnerReportDetails.filter(
            (file) => file.reportType === 1
          ),
          editOwnershipReportDetails:
            prevFormData.editOwnershipReportDetails.filter(
              (file) => file.reportType === 2
            ),
        }));

        return updatedFiles;
      });
    }
  };
  console.log("UO", ownerfromdata, finalData);
  const removeReport = (index, reportType, mappingId) => {
    if (order === 1) {
      setReportInfoDataValue((prevFiles) => {
        const updatedFiles = prevFiles.map((file) => {
          if (
            file.sourceFile &&
            file.sourceFile.reportInfoId === reportInfoId
          ) {
            return {
              reportInfoId: file.sourceFile.reportInfoId,
              sourceFileId: file.sourceFile.sourceFileId,
              reportName: file.sourceFile.reportName,
              pageNo: file.sourceFile.pageNo,
              reportType: 1,
              isEdited: false,
              isDeleted: true,
            };
          }
          return {
            reportInfoId: file.sourceFile?.reportInfoId,
            sourceFileId: file.sourceFile?.sourceFileId,
            reportName: file.sourceFile?.reportName,
            pageNo: file.sourceFile?.pageNo,
            reportType: 1,
            isEdited: false,
            isDeleted: false,
          };
        });

        const updatedFormDataOwner = {
          ...ownerfromdata,
          editOwnerReportDetails: updatedFiles,
        };

        setOwnerFormData(updatedFormDataOwner);

        const updatedSourceFile = [...reportInfoDataValue];
        updatedSourceFile.splice(removeIndex, 1); // Remove the file from the UI
        setReportInfoDataValue(updatedSourceFile);

        return updatedFiles;
      });
    } else if (order === 2) {
      setReportInfoOwnershipValue((prevFiles) => {
        const updatedFiles = prevFiles.map((file) => {
          if (
            file.sourceFile &&
            file.sourceFile.reportInfoId === reportInfoId
          ) {
            return {
              reportInfoId: file.sourceFile.reportInfoId,
              sourceFileId: file.sourceFile.sourceFileId,
              reportName: file.sourceFile.reportName,
              pageNo: file.sourceFile.pageNo,
              reportType: 2,
              isEdited: false,
              isDeleted: true,
            };
          }
          return {
            reportInfoId: file.sourceFile?.reportInfoId,
            sourceFileId: file.sourceFile?.sourceFileId,
            reportName: file.sourceFile?.reportName,
            pageNo: file.sourceFile?.pageNo,
            reportType: 2,
            isEdited: false,
            isDeleted: false,
          };
        });

        const updatedFormDataOwnership = {
          ...ownerfromdata,
          editOwnershipReportDetails: updatedFiles,
        };

        setOwnerFormData(updatedFormDataOwnership);

        const updatedSourceFile = [...reportInfoOwnershipValue];
        updatedSourceFile.splice(removeIndex, 1); // Remove the file from the UI
        setReportInfoOwnershipValue(updatedSourceFile);

        return updatedFiles;
      });
    } else {
      setReportInfoStockValue((prevFiles) => {
        const updatedFiles = prevFiles.map((file) => {
          if (
            file.sourceFile &&
            file.sourceFile.reportInfoId === reportInfoId
          ) {
            return {
              reportInfoId: file.sourceFile.reportInfoId,
              sourceFileId: file.sourceFile.sourceFileId,
              reportName: file.sourceFile.reportName,
              pageNo: file.sourceFile.pageNo,
              reportType: 3,
              isEdited: false,
              isDeleted: true,
            };
          }
          return {
            reportInfoId: file.sourceFile?.reportInfoId,
            sourceFileId: file.sourceFile?.sourceFileId,
            reportName: file.sourceFile?.reportName,
            pageNo: file.sourceFile?.pageNo,
            reportType: 3,
            isEdited: false,
            isDeleted: false,
          };
        });

        const updatedFormDatastock = {
          ...ownerfromdata,
          editOwnerStockReportDetails: updatedFiles,
        };

        setOwnerFormData(updatedFormDatastock);

        const updatedSourceFile = [...reportInfoStockValue];
        updatedSourceFile.splice(removeIndex, 1); // Remove the file from the UI
        setReportInfoStockValue(updatedSourceFile);

        return updatedFiles;
      });
    }
  };

  useEffect(() => {
    const customcompany = popupDropdown.find((e) => e.guid === data?.ownerGuid);
    setPopdata(customcompany);
  }, [popupDropdown, data?.ownerGuid]);
  const RemoveData = (index, reportType, mappingId) => {
    setRemoveIndex(index);
    setOrder(reportType);
    setReportInfoIdValue(mappingId);
    setConfirmPopUp(true);
  };
  useEffect(() => {}, [removeIndex, order, reportInfoId]);
  React.useEffect(() => {
    if (reportInfoDataValue.length === 0) {
      setReportInfoDataValue([
        {
          reportInfoId: 0,
          sourceFileId: 0,
          pageNo: 0,
          isDeleted: false,
          isEdited: false,
          reportType: 0,
        },
      ]);
    }
    if (reportInfoOwnershipValue.length === 0) {
      setReportInfoOwnershipValue([
        {
          reportInfoId: 0,
          sourceFileId: 0,
          pageNo: 0,
          isDeleted: false,
          isEdited: false,
          reportType: 0,
        },
      ]);
    }
    if (reportInfoStockValue.length === 0) {
      setReportInfoStockValue([
        {
          reportInfoId: 0,
          sourceFileId: 0,
          pageNo: 0,
          isDeleted: false,
          isEdited: false,
          reportType: 0,
        },
      ]);
    }
  }, [
    reportInfoDataValue.length,
    reportInfoOwnershipValue.length,
    reportInfoStockValue.length,
  ]);
  /**********Open a file on cliking the icon********* */
/*   const openDocument = (rowData, pageNumber = 1) => {
    //var jobdetails = tempData?.state;
    
console.log("fname",pageNumber)
    let sendData = {
      jobId: props.jobId,
      fileName: rowData + ".pdf",
      pageNumber: pageNumber,
      crudState: "Edit",
    };
    CommonService.openFilePreview(sendData);
  }; */
  const openDocument = (file, pageNumber = 1) => {
    if (pageNumber === "") {
      pageNumber = 1;
    }
    //var jobdetails = tempData?.state;
    var reportName = file + ".pdf";
    var senData = {
      jobId: props.jobId,
      fileName: reportName,
      pageNumber: Number(pageNumber),
      crudState: "Edit",
    };
    localStorage.setItem("filePreview", JSON.stringify(senData));
   // CommonService.AddTokenFromSessionStorageToLocalStorage();
    window.open(
      "#app/ownership-changes/file-preview",
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <>
      <AccordionDetails>
        <Box className={classes.borderPadding}>
          <Grid
            container
            alignItems="flex-end"
            justifyContent="flex-start"
            spacing={3}
          >
            <Grid item xs={12}>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={3}
              >
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    spacing={3}
                  >
                    <Grid item xs={12}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          alignItems="self-end"
                        >
                          <Grid item xs>
                            <Autocomplete
                              disablePortal
                              options={ownerdropdown}
                              value={preselectedOwner}
                              disabled={!props?.dataPoints?.owner}
                              getOptionLabel={(option) => option.companyName}
                              onChange={(e, value) => {
                                if (value) {
                                  setPreviousOperator(preselectedOwner);
                                  console.log(value, "Data checker");
                                  if (value.companyName === "Others") {
                                    setPreviousPopdata(popdata);
                                    setAddCustomeOwner(!addCustomeOwner);
                                    setCustomeOwner({
                                      ownerGuid: preselectedOwner?.guid,
                                      correctOwnerName: "",
                                      legalEntityTypeId: 0,
                                    });
                                    setOwnerFormData({
                                      ...ownerfromdata,
                                      ownerName: value.companyName,
                                      ownerGuid: value.guid,
                                    });
                                  } else if (value.companyName !== "Others") {
                                    setOwnerFormData({
                                      ...ownerfromdata,
                                      ownerName: value.companyName,
                                      ownerGuid: value.guid,
                                    });
                                  }
                                  setPreselectedOwner(value);
                                }
                              }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  {...props}
                                  key={option.guid}
                                >
                                  {option.companyName}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Correct Owner Name"
                                  variant="standard"
                                  required
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs="auto">
                            <Tooltip
                              title="Apply Legal Entity"
                              placement="top"
                              arrow
                            >
                              <IconButton
                                color="accent7"
                                onClick={(e, value) => {
                                  if (
                                    preselectedOwner &&
                                    preselectedOwner.guid
                                  ) {
                                    setCustomLegal({
                                      ownerGuid: preselectedOwner.guid,
                                      correctOwnerName: "",
                                      legalEntityTypeId:
                                        customeLegal.legalEntityTypeId,
                                    });

                                    setAddLegalEntity(true);
                                  }
                                }}
                              >
                                <TaskAltIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Comments"
                        variant="standard"
                        value={ownerComment}
                        onChange={(e) => {
                          setOwnerFormData({
                            ...ownerfromdata,
                            ownerComment: e.target.value,
                          });
                          setOwnerComment(e.target.value);
                          //setFormData({ ...formData, comment: e.target.value });
                        }}
                        multiline
                        minRows={3}
                        maxRows={3}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    {reportInfoDataValue.map((el, index) => {
                      // console.log("ELL", prefillReportName);
                      return (
                        <>
                          <Grid item xs={12}>
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="self-end"
                              >
                                <Grid item xs>
                                  <Autocomplete
                                    disablePortal
                                    disableClearable
                                    id="combo-box-demo"
                                    options={sourcefiledropdown}
                                    fullWidth
                                    value={el.sourceFile || null}
                                    getOptionLabel={(option) =>
                                      option.reportName
                                    }
                                    onChange={(event, newValue) => {
                                      if (newValue?.reportName === "Others") {
                                        toggleDrawerReport(true, "Add")(
                                          event,
                                          newValue
                                        );
                                      } else {
                                        const updatedFiles = [
                                          ...reportInfoDataValue,
                                        ];
                                        updatedFiles[index] = {
                                          ...el,
                                          sourceFile: {
                                            ...el.sourceFile,
                                            ...newValue,
                                          },
                                        };
                                        console.log("GTTT", updatedFiles, el);
                                        setReportInfoDataValue(updatedFiles);
                                        const updatedFormData = {
                                          ...ownerfromdata,
                                          editOwnerReportDetails:
                                            updatedFiles.map((file) => ({
                                              reportInfoId: file.sourceFile
                                                .reportInfoId
                                                ? file.sourceFile?.reportInfoId
                                                : 0,
                                              sourceFileId: file.sourceFile
                                                ? file.sourceFile.id ||
                                                  file.sourceFile?.sourceFileId
                                                : 0,
                                              reportName:
                                                file.sourceFile?.reportName,
                                              pageNo: file?.pageNo,
                                              reportType: 1,
                                              isEdited: true,
                                              isDeleted: false,
                                            })),
                                        };
                                        setOwnerFormData(updatedFormData);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Source File"
                                        //required
                                        variant="standard"
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item xs="auto">
                                  <Tooltip
                                    title="Open Document"
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      color="accent6"
                                      aria-label="add to shopping cart"
                                      disabled={!el.sourceFile}
                                      onClick={() => {
                                        openDocument(
                                          el?.sourceFile?.reportName,
                                          el?.pageNo
                                        );
                                      }}
                                    >
                                      <TextDocumentIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid item xs="auto">
                                  <Tooltip
                                    title={el.sourceFile ? el.sourceFile.link : ""}
                                    placement="top"
                                    arrow
                                  >

                                    <IconButton color="accent4" disabled={!el.sourceFile}>
                                      <LinkIcon
                                        color="accent4"
                                        
                                        onClick={(event) => {
                                          window.open(`${el.sourceFile ? el.sourceFile.link : ""}`, "_blank");
                                        }}
                                      />
                                    </IconButton>

                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="self-start"
                                alignItems="self-end"
                              >
                                <Grid item xs="auto">
                                  <TextField
                                    id="standard-basic"
                                    type="number"
                                    label="Page No"
                                    value={el.pageNo || ""}
                                    minValue={1}
                                    onChange={(event) => {
                                      const updatedFiles = [
                                        ...reportInfoDataValue,
                                      ];
                                      updatedFiles[index] = {
                                        ...updatedFiles[index],
                                        pageNo: event.target.value,
                                      };
                                      setReportInfoDataValue(updatedFiles);
                                      const updatedFormData = {
                                        ...ownerfromdata,
                                        editOwnerReportDetails:
                                          updatedFiles.map((file) => ({
                                            reportInfoId: file?.sourceFile
                                              ?.reportInfoId
                                              ? file.sourceFile?.reportInfoId
                                              : 0,
                                            sourceFileId: file.sourceFile
                                              ? file.sourceFile.id ||
                                                file.sourceFile?.sourceFileId
                                              : 0,
                                            reportName:
                                              file.sourceFile?.reportName,
                                            pageNo: file?.pageNo,
                                            reportType: 1,
                                            isEdited: true,

                                            isDeleted: false,
                                          })),
                                      };
                                      setOwnerFormData(updatedFormData);
                                    }}
                                    sx={{ width: 100 }}
                                    fullWidth
                                    //required
                                    variant="standard"
                                  />
                                </Grid>

                                {props.access?<>
                                {reportInfoDataValue.length > 1 ? (
                                  <Grid item xs="auto">
                                    <Tooltip
                                      title="Delete "
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton color="error">
                                        <DeleteIcon
                                          style={{ fontSize: "20px" }}
                                          onClick={() => {
                                            // removeReport(
                                            //   index,
                                            //   1,
                                            //   el.sourceFile?.reportInfoId
                                            // );
                                            RemoveData(
                                              index,
                                              1,
                                              el.sourceFile?.reportInfoId
                                            );
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                ) : (
                                  <Grid item xs="auto">
                                    <Tooltip
                                      title="Delete"
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton color="error">
                                        <DeleteIcon
                                          style={{ fontSize: "20px" }}
                                          onClick={() => {
                                            RemoveData(
                                              index,
                                              1,
                                              el.sourceFile?.reportInfoId
                                            );
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                                {index === 0 && (
                                  <Grid item xs="auto">
                                    <Tooltip title="Add" placement="top" arrow>
                                      <IconButton
                                        color="secondary"
                                        style={{ fontSize: "20px" }}
                                        onClick={() => {
                                          addReport(1);
                                        }}
                                      >
                                        <CirclePlusIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}</>
                                :<></>}
                              </Grid>
                            </Box>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    spacing={3}
                  >
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        type="number"
                        id="standard-basic"
                        InputProps={{ shrink: true }}
                        label="Ownership Percentage(%)"
                        variant="standard"
                        disabled={!props?.dataPoints?.ownershipPercentage}
                        required
                        value={ownershippercentage}
                        onChange={(e) => {
                          if (e.target.value <= 100) {
                            setOwnershipPercentage(e.target.value);
                            setOwnerFormData({
                              ...ownerfromdata,
                              ownershipPercentage: e.target.value,
                            });
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {/* <TextField
                        fullWidth
                        id="standard-basic"
                        label="Ownership Date"
                        variant="standard"
                        value={ownershipdate}
                        onChange={(e) => {
                          setOwnershipDate(e.target.value);
                          setOwnerFormData({
                            ...ownerfromdata,
                            ownershipDate: e.target.value,
                          });
                        }}
                      /> */}

                      {/* <DatePicker
                        labelTitle="Ownership Date"
                        variant="standard"
                        disabled={!props?.dataPoints?.ownershipDate}
                        defaultDate={ownershipdate}
                        onChangeDate={(e) => {
                          let selectedDate = new Date(e);
                          let date =
                            selectedDate.getFullYear() +
                            "-" +
                            ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + selectedDate.getDate()).slice(-2);

                          setOwnerFormData({
                            ...ownerfromdata,
                            ownershipDate: dayjs(date, "YYYY-MM-DD").format(
                              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                            ),
                          });
                        }}
                        past={false}
                        //key={keyFromoDate}
                        clearable={true}
                      /> */}
         <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={allYear}
                              getOptionLabel={(option) => option.year}
                              value={preselectedYear}
                              onChange={(event, value) => {
                                if (value) {
                                  setOwnerFormData({
                                    ...ownerfromdata,
                                    yearId:
                                      value && value.id ? value.id : null,
                                  });
                                  setPreselectedYear(value);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Ownership Date"
                                  variant="standard"
                                  required
                                />
                              )}
                            />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Comments "
                        variant="standard"
                        value={percentageComment}
                        onChange={(e) => {
                          setOwnerFormData({
                            ...ownerfromdata,
                            ownershipComment: e.target.value,
                          });
                          setPercentageComment(e.target.value);
                        }}
                        multiline
                        minRows={3}
                        maxRows={3}
                        InputLabelProps={{ shrink: true }}

                      />
                    </Grid>

                    {reportInfoOwnershipValue.map((el, index) => {
                      return (
                        <>
                          <Grid item xs={12}>
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="self-end"
                              >
                                <Grid item xs>
                                  <Autocomplete
                                    disablePortal
                                    disableClearable
                                    id="combo-box-demo"
                                    options={sourcefiledropdown}
                                    fullWidth
                                    value={el.sourceFile || null}
                                    getOptionLabel={(option) =>
                                      option.reportName
                                    }
                                    onChange={(event, newValue) => {
                                      if (newValue?.reportName === "Others") {
                                        toggleDrawerReport(true, "Add")(
                                          event,
                                          newValue
                                        );
                                      } else {
                                        const updatedFiles = [
                                          ...reportInfoOwnershipValue,
                                        ];
                                        updatedFiles[index] = {
                                          ...el,
                                          sourceFile: {
                                            ...el.sourceFile,
                                            ...newValue,
                                          },
                                        };

                                        setReportInfoOwnershipValue(
                                          updatedFiles
                                        );

                                        const updatedFormData = {
                                          ...ownerfromdata,
                                          editOwnershipReportDetails:
                                            updatedFiles.map((file) => ({
                                              reportInfoId: file?.sourceFile
                                                ?.reportInfoId
                                                ? file.sourceFile?.reportInfoId
                                                : 0,
                                              sourceFileId: file.sourceFile
                                                ? file.sourceFile.id ||
                                                  file.sourceFile?.sourceFileId
                                                : 0,
                                              sourceFileId: file.sourceFile
                                                ? file.sourceFile.id ||
                                                  file.sourceFile?.sourceFileId
                                                : 0,
                                              reportName:
                                                file.sourceFile?.reportName,
                                              pageNo: file?.pageNo,
                                              reportType: 2,
                                              isEdited: true,
                                              isDeleted: false,
                                            })),
                                        };
                                        setOwnerFormData(updatedFormData);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Source File"
                                        //required
                                        variant="standard"
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item xs="auto">
                                  <Tooltip
                                    title="Open Document"
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      color="accent6"
                                      aria-label="add to shopping cart"
                                      disabled={!el.sourceFile}
                                      onClick={() => {
                                        openDocument(
                                          el?.sourceFile?.reportName,
                                          el?.pageNo
                                        );
                                      }}
                                    >
                                      <TextDocumentIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid item xs="auto">
                                  <Tooltip
                                    title={el.sourceFile ? el.sourceFile.link : ""}
                                    placement="top"
                                    arrow
                                  >

                                    <IconButton color="accent4" disabled={!el.sourceFile}>
                                      <LinkIcon
                                        color="accent4"
                                        onClick={(event) => {
                                          window.open(`${el.sourceFile ? el.sourceFile.link : ""}`, "_blank");
                                        }}
                                      />
                                    </IconButton>

                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="self-start"
                                alignItems="self-end"
                              >
                                <Grid item xs="auto">
                                  <TextField
                                    id="standard-basic"
                                    type="number"
                                    label="Page No"
                                    value={el.pageNo || ""}
                                    minValue={1}
                                    onChange={(event) => {
                                      const updatedFiles = [
                                        ...reportInfoOwnershipValue,
                                      ];
                                      updatedFiles[index] = {
                                        ...updatedFiles[index],
                                        pageNo: event.target.value,
                                      };
                                      setReportInfoOwnershipValue(updatedFiles);
                                      const updatedFormData = {
                                        ...ownerfromdata,
                                        editOwnershipReportDetails:
                                          updatedFiles.map((file) => ({
                                            reportInfoId: file?.sourceFile
                                              ?.reportInfoId
                                              ? file.sourceFile?.reportInfoId
                                              : 0,
                                            sourceFileId: file.sourceFile
                                              ? file.sourceFile.id ||
                                                file.sourceFile?.sourceFileId
                                              : 0,
                                            reportName:
                                              file.sourceFile?.reportName,
                                            pageNo: file?.pageNo,
                                            reportType: 2,
                                            isEdited: true,
                                            isDeleted: false,
                                          })),
                                      };
                                      setOwnerFormData(updatedFormData);
                                    }}
                                    sx={{ width: 100 }}
                                    fullWidth
                                    //required
                                    variant="standard"
                                  />
                                </Grid>
                                 {props.access?<>
                                {reportInfoOwnershipValue.length > 1 ? (
                                  <Grid item xs="auto">
                                    <Tooltip
                                      title="Delete"
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton color="error">
                                        <DeleteIcon
                                          style={{ fontSize: "20px" }}
                                          onClick={() => {
                                            // removeReport(
                                            //   index,
                                            //   2,
                                            //   el.sourceFile?.reportInfoId
                                            // );
                                            RemoveData(
                                              index,
                                              2,
                                              el.sourceFile?.reportInfoId
                                            );
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                ) : (
                                  <Grid item xs="auto">
                                    <Tooltip
                                      title="Delete"
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton color="error">
                                        <DeleteIcon
                                          style={{ fontSize: "20px" }}
                                          onClick={() => {
                                            RemoveData(
                                              index,
                                              2,
                                              el.sourceFile?.reportInfoId
                                            );
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                                {index === 0 && (
                                  <Grid item xs="auto">
                                    <Tooltip title="Add" placement="top" arrow>
                                      <IconButton
                                        color="secondary"
                                        style={{ fontSize: "20px" }}
                                        onClick={() => {
                                          addReport(2);
                                        }}
                                      >
                                        <CirclePlusIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}</>:<></>}
                              </Grid>
                            </Box>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    spacing={3}
                  >
                    <Grid item xs={12}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          alignItems="self-end"
                        >
                          <Grid item xs>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={stockdropdown}
                              getOptionLabel={(option) => option.name}
                              value={preselectedStock}
                              disabled={!props?.dataPoints?.stockExchange}
                              onChange={(event, value) => {
                                if (value) {
                                  setOwnerFormData({
                                    ...ownerfromdata,
                                    stockExchangeId:
                                      value && value.id ? value.id : null,
                                  });
                                  setPreselectedStock(value);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Stock Exchange with Largest market cap"
                                  variant="standard"
                                  required
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs="auto">
                            <Tooltip
                              title="Add Stock Exchange"
                              placement="top"
                              arrow
                            >
                              <IconButton
                                color="secondary"
                                onClick={() => {
                                  setCustomeStock({
                                    id: 0,
                                    symbol: "",
                                    name: "",
                                  });
                                  setAddCustomeStock(true);
                                }}
                              >
                                <CirclePlusIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Comments "
                        value={stockComment}
                        onChange={(e) => {
                          setStockComment(e.target.value);
                          setOwnerFormData({
                            ...ownerfromdata,
                            stockExchangeComment: e.target.value,
                          });
                        }}
                        variant="standard"
                        multiline
                        minRows={3}
                        InputLabelProps={{ shrink: true }}
                        maxRows={3}
                      />
                    </Grid>
                    {reportInfoStockValue.map((el, index) => {
                      return (
                        <>
                          <Grid item xs={12}>
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="self-end"
                              >
                                <Grid item xs>
                                  <Autocomplete
                                    disablePortal
                                    disableClearable
                                    id="combo-box-demo"
                                    options={sourcefiledropdown}
                                    fullWidth
                                    value={el.sourceFile || null}
                                    getOptionLabel={(option) =>
                                      option.reportName
                                    }
                                    onChange={(event, newValue) => {
                                      if (newValue?.reportName === "Others") {
                                        toggleDrawerReport(true, "Add")(
                                          event,
                                          newValue
                                        );
                                      } else {
                                        const updatedFiles = [
                                          ...reportInfoStockValue,
                                        ];
                                        updatedFiles[index] = {
                                          ...el,
                                          sourceFile: {
                                            ...el.sourceFile,
                                            ...newValue,
                                          },
                                        };

                                        setReportInfoStockValue(updatedFiles);
                                        const updatedFormData = {
                                          ...ownerfromdata,
                                          editOwnerStockReportDetails:
                                            updatedFiles.map((file) => ({
                                              reportInfoId: file.sourceFile
                                                .reportInfoId
                                                ? file.sourceFile?.reportInfoId
                                                : 0,
                                              sourceFileId: file.sourceFile
                                                ? file.sourceFile.id ||
                                                  file.sourceFile?.sourceFileId
                                                : 0,
                                              reportName:
                                                file.sourceFile?.reportName,
                                              pageNo: file?.pageNo,
                                              reportType: 3,
                                              isEdited: true,
                                              isDeleted: false,
                                            })),
                                        };
                                        setOwnerFormData(updatedFormData);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Source File"
                                        //required
                                        variant="standard"
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item xs="auto">
                                  <Tooltip
                                    title="Open Document"
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      color="accent6"
                                      aria-label="add to shopping cart"
                                      disabled={!el.sourceFile}
                                      onClick={() => {
                                        openDocument(
                                          el?.sourceFile?.reportName,
                                          el?.pageNo
                                        );
                                      }}
                                    >
                                      <TextDocumentIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid item xs="auto">
                                  <Tooltip
                                    title={el.sourceFile ? el.sourceFile.link : ""}
                                    placement="top"
                                    arrow
                                  >

                                    <IconButton color="accent4" disabled={!el.sourceFile}>
                                      <LinkIcon
                                        color="accent4"
                                        onClick={(event) => {
                                          window.open(`${el.sourceFile ? el.sourceFile.link : ""}`, "_blank");
                                        }}
                                      />
                                    </IconButton>

                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="self-start"
                                alignItems="self-end"
                              >
                                <Grid item xs="auto">
                                  <TextField
                                    id="standard-basic"
                                    type="number"
                                    label="Page No"
                                    value={el.pageNo || ""}
                                    minValue={1}
                                    onChange={(event) => {
                                      const updatedFiles = [
                                        ...reportInfoStockValue,
                                      ];
                                      updatedFiles[index] = {
                                        ...updatedFiles[index],
                                        pageNo: event.target.value,
                                      };
                                      setReportInfoStockValue(updatedFiles);
                                      const updatedFormData = {
                                        ...ownerfromdata,
                                        editOwnerStockReportDetails:
                                          updatedFiles.map((file) => ({
                                            reportInfoId: file?.sourceFile
                                              ?.reportInfoId
                                              ? file.sourceFile?.reportInfoId
                                              : 0,
                                            sourceFileId: file.sourceFile
                                              ? file.sourceFile.id ||
                                                file.sourceFile?.sourceFileId
                                              : 0,
                                            reportName:
                                              file.sourceFile?.reportName,
                                            pageNo: file.pageNo,
                                            reportType: 3,
                                            isEdited: true,
                                            isDeleted: false,
                                          })),
                                      };
                                      setOwnerFormData(updatedFormData);
                                    }}
                                    sx={{ width: 100 }}
                                    fullWidth
                                    //required
                                    variant="standard"
                                  />
                                </Grid>
                                {props.access?<>
                                {reportInfoStockValue.length > 1 ? (
                                  <Grid item xs="auto">
                                    <Tooltip
                                      title="Delete"
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton color="error">
                                        <DeleteIcon
                                          style={{ fontSize: "20px" }}
                                          onClick={() => {
                                            // removeReport(
                                            //   index,
                                            //   3,
                                            //   el.sourceFile?.reportInfoId
                                            // );
                                            RemoveData(
                                              index,
                                              3,
                                              el.sourceFile?.reportInfoId
                                            );
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                ) : (
                                  <Grid item xs="auto">
                                    <Tooltip
                                      title="Delete"
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton color="error">
                                        <DeleteIcon
                                          style={{ fontSize: "20px" }}
                                          onClick={() => {
                                            RemoveData(
                                              index,
                                              3,
                                              el.sourceFile?.reportInfoId
                                            );
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                                {index === 0 && (
                                  <Grid item xs="auto">
                                    <Tooltip title="Add" placement="top" arrow>
                                      <IconButton
                                        color="secondary"
                                        style={{ fontSize: "20px" }}
                                        onClick={() => {
                                          addReport(3);
                                        }}
                                      >
                                        <CirclePlusIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                                </>:<></>}
                              </Grid>
                            </Box>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Owner Info - See Through Owner Info Start Here */}
            <Grid item xs={12} sm={12} md={6} sx={{ mb: -2 }}>
              <h4 className={classes.subHeadingText2}>Equity Ownership Info</h4>
            </Grid>
             {props.access?<>
            <Grid item xs={12} sm={12} md={6}>
              <Grid
                item
                xs={12}
                container
                spacing={0}
                sx={{ mt: 1, mb: 0 }}
                justifyContent="flex-end"
                textAlign="right"
              >
                <Button
                  variant="contained"
                  color="accent7"
                  size="small"
                  startIcon={<AddIcon style={{ fontSize: 16 }} />}
                  onClick={toggleDrawer(true, "Add")} //addFormData(),
                >
                  Add Equity ownership
                </Button>
              </Grid>
            </Grid></>:<></>}
            <Grid item xs={12}>
              <Box className={classes.tableBorder}>
                <CustomDataTable
                  // className={classes.removeBorder}
                  // loading={isLoading}
                  rows={seethroughdata}
                  columns={columns}
                  openDocumentForView={(fileName) => {
                    openDocument(fileName?.reportName,fileName?.pageNo);
                  }}
                  // page={page}
                  // setPage={(data) => {
                  //   setPage(data);
                  //   setReload(true);
                  // }}
                  // rowsPerPage={rowsPerPage}
                  // setRowsPerPage={(data) => {
                  //   setRowsPerPage(data);
                  //   setReload(true);
                  // }}
                  // totalCount={totalCount}
                  // isAllSelected={isAllSelected}
                  // setIsSelected={setIsSelected}
                  // selected={selected}
                  // setSelected={SetSelected}
                  // sorting={sorting}
                  // setSorting={setSorting}
                  isActionButton={{
                    ...(props.access && {
                      edit: toggleDraweredit(true, "Edit"),
                    }),
                    ...(props.access && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                />
              </Box>
            </Grid>
            {/* Owner Info - See Through Owner Info End Here */}
          </Grid>
        </Box>
      </AccordionDetails>

      <SideDrawer
        formComp={
          <AddSeeThroughOwnerInfo
            state={state}
            setState={setState}
            jobAssetId={props.jobAssetId}
            jobId={props.jobId}
            jobAssetOwnerInfoId={seeThrough}
            crudState={crudState}
            setRefresh={setRefresh}
            refresh={refresh}
            companyGuid={data?.ownerGuid}
            hideSideDrawer={() => {
              GetSourceFileDropdown()
              setState({ right: false });
              getGridData();
              if(props.setReload){
                props.setReload(!props.reload)
            }
            }}
          />
        }
        state={state}
        setState={setState}
        crudState={crudState}
      />

      <SideDrawer
        formComp={
          <EditSeeThroughOwnerInfo
            state={stateedit}
            setState={setStateEdit}
            jobAssetId={props.jobAssetId}
            jobId={props.jobId}
            jobAssetOwnerInfoId={ownerfromdata.ownerInfoId}
            crudState={crudStateEdit}
            prefillData={prefillData ? prefillData : ""}
            setPreFillData={setPreFillData}
            companyGuid={data?.ownerGuid}
            setRefresh={setRefresh}
            //companyDropDown={companyDropDown}
            //assetTypeDropDown={assetTypeDropDown}
            //regionDropDown={regionDropDown}
            //commodityDropDown={commodityDropDown}
            //statusDropDown={statusDropDown}
            //setRefresh={setRefresh}
            //refresh={refresh}
            hideSideDrawer={() => {
              GetSourceFileDropdown()
              setStateEdit({ right: false });
              getGridData();
            }}
          />
        }
        state={stateedit}
        setState={setStateEdit}
        crudState={crudStateEdit}
      />
      <SideDrawer
        formComp={
          <AddReportInfo
            state={stateReport}
            setState={setStateReport}
            getSourceFile={() => {
              GetSourceFileDropdown();
            }}
            jobId={props.jobId}
            jobAssetId={props.jobAssetId}
            crudState={crudStateReport}
            getFileName={(value) => {
              setPrefillReportName({
                id: value.id,
                reportName: value.reportName,
              });
            }}
             
            hideSideDrawer={() => {
             
              setStateReport({ right: false });
              getGridData();
              GetSourceFileDropdown();
              if(props.setReload){
                props.setReload(!props.reload)
            }
            }}
          />
        }
        state={stateReport}
        setState={setStateReport}
      />
      {/* POPUP TO ADD NEW STOCK */}
      {addCustomeStock ? (
        <Dialog open={true} fullWidth onClose={handleClose}>
          <DialogTitle>Add New Stock Exchange</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              name="name"
              label="Name"
              type="email"
              fullWidth
              required
              variant="standard"
              inputProps={{ maxLength: 500 }}
              onChange={(event) => {
                let data = customeStock;
                data[event.target.name] = event.target.value;
                setCustomeStock(data);
              }}
            />
            <TextField
              margin="dense"
              id="symbol"
              name="symbol"
              label="Symbol"
              type="email"
              fullWidth
              required
              variant="standard"
              inputProps={{ maxLength: 100 }}
              onChange={(event) => {
                let data = customeStock;
                data[event.target.name] = event.target.value;
                setCustomeStock(data);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>

            <Button onClick={handleaddCustomeStock}>Add</Button>
          </DialogActions>
        </Dialog>
      ) : null}

      {/* POPUP TO ADD NEW OWNER */}

      {addCustomeOwner ? (
        <Dialog open={true} fullWidth onClose={handleCloseOwner}>
          <DialogTitle>Add New Owner</DialogTitle>
          <DialogContent>
            <Autocomplete
              disablePortal
              options={popupDropdown}
              value={popdata}
              getOptionLabel={(option) => option.companyName}
              onChange={(e, value) => {
                console.log("Popupvalue", value);
                setPopdata(value);
                if (value) {
                  let data = {
                    ownerGuid: value.guid || data.ownerGuid,
                    correctOwnerName: customeOwner.correctOwnerName,
                    legalEntityTypeId: 0,
                  };

                  setCustomeOwner(data);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Owner Name" variant="standard" />
              )}
            />
            <TextField
              margin="dense"
              id="correctOwnerName"
              name="correctOwnerName"
              label="Correct Owner Name"
              type="email"
              fullWidth
              required
              variant="standard"
              onChange={(event) => {
                let data = { ...customeOwner };
                data[event.target.name] = event.target.value;
                setCustomeOwner(data);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseOwner}>Cancel</Button>

            <Button onClick={handleAddCustomeOwner}>Add</Button>
          </DialogActions>
        </Dialog>
      ) : null}

      {/* POPUP TO ADD LEGAL ENTITY*/}
      {addLegalEntity ? (
        <Dialog
          open={true}
          fullWidth
          onClose={handleCloseLegal}
          maxWidth={"lg"}
        >
          <DialogTitle>Legal Entity Type</DialogTitle>
          <DialogContent sx={{ pb: 0 }}>
            <Grid item xs={12}>
              <Box className={classes.setBorder}>
                <TableContainer sx={{ maxHeight: "calc(100vh - 203px)" }}>
                  <Table
                    stickyHeader
                    // className={classes.table}
                    className={classes.historyTable}
                  >
                    <TableHead>
                      <TableRow hover>
                        {columnsLegalEntity.map((el) => {
                          return (
                            <TableCell
                              className={
                                classes.tableHeader && classes.cruTheadLeftSep
                              }
                              style={{
                                minWidth: "30px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {el.label}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    {legalentitydropdown.length > 0 ? (
                      <TableBody>
                        {legalentitydropdown.map((row) => (
                          <TableRow key={row.id} hover>
                            <TableCell
                              component="th"
                              scope="row"
                              className={
                                classes.tableCell && classes.tableCellLeftSep
                              }
                              style={{ minWidth: "200px" }}
                            >
                              {row?.commonVariation?.includes("^") ? (
                                <>
                                  {row?.commonVariation
                                    ?.split("^")
                                    ?.map((part, index) => {
                                      if (index % 2 === 0) {
                                        return (
                                          <Chip
                                            label={part}
                                            size="small"
                                            key={index}
                                          />
                                        );
                                      } else {
                                        return (
                                          <Chip
                                            label={`${part}`}
                                            size="small"
                                            key={index}
                                            style={{ marginLeft: 2 }}
                                          />
                                        );
                                      }
                                    })}
                                </>
                              ) : (
                                <Chip
                                  label={row.commonVariation}
                                  size="small"
                                />
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={
                                classes.tableCell && classes.tableCellLeftSep
                              }
                              style={{ minWidth: "150px" }}
                            >
                              {row.cruFormat}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={
                                classes.tableCell && classes.tableCellLeftSep
                              }
                              style={{ minWidth: "180px" }}
                            >
                              {row.country}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={
                                classes.tableCell && classes.tableCellLeftSep
                              }
                              style={{ minWidth: "30px" }}
                            >
                              <Tooltip title="Select" placement="top" arrow>
                                <IconButton aria-label="edit" color="accent7">
                                  <TaskAltIcon
                                    className={classes.iconSize}
                                    onClick={() => {
                                      setLegalId(row.id);
                                      console.log("LEGAL", legalid);
                                      handleAddCustomeLegalCheck(row.id);
                                      setAddLegalEntity(false);
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                              {/* <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => handleAddCustomeLegal(row.id)}
                              >
                                Select
                              </Button> */}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={99}>
                            <EmptyState
                              icon={
                                <FileOffIcon
                                  style={{ color: "#db9400", fontSize: "40px" }}
                                />
                              }
                              title="No Data Found"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ mt: 1, mr: 2 }}>
            <Button onClick={handleCloseLegal}>Cancel</Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {/* Alert Dialog - Confirmation for Delete */}
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          // ${deleteJobNumber}
          description={`Are you sure you want to delete  ?`} /* Id : ${deleteData} */
          action={
            <Grid container justifyContent="right">
              <Grid item xs={"auto"}>
                <Button
                  onClick={() => setOpenDelete(false)}
                  style={{ marginRight: "15px" }}
                >
                  No
                </Button>
              </Grid>
              <Grid item xs={"auto"}>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button onClick={deleteRecord} color="secondary">
                    Yes
                  </Button>
                )}
              </Grid>
            </Grid>
          }
        />
      ) : null}
      {confirmPopUp ? (
        <AlertDialog
          open={confirmPopUp}
          title={"Confirmation"}
          maxWidth={"sm"}
          // ${deleteJobNumber}
          description={`Are you sure you want to delete  ?`} /* Id : ${deleteData} */
          action={
            <Grid container justifyContent="right">
              <Grid item xs={"auto"}>
                <Button
                  onClick={() => setConfirmPopUp(false)}
                  style={{ marginRight: "15px" }}
                >
                  No
                </Button>
              </Grid>
              <Grid item xs={"auto"}>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button
                    onClick={() => {
                      setConfirmPopUp(false);
                      removeReport();
                    }}
                    color="secondary"
                  >
                    Yes
                  </Button>
                )}
              </Grid>
            </Grid>
          }
        />
      ) : null}

      {openLegal ? (
        <AlertDialog
          open={openLegal}
          title={"Confirmation"}
          maxWidth={"sm"}
          // ${deleteJobNumber}
          description={`Selected Common variation is not matched, Are you sure you want to proceed? `} /* Id : ${deleteData} */
          action={
            <Grid container justifyContent="right">
              <Grid item xs={"auto"}>
                <Button
                  onClick={() => setOpenLegal(false)}
                  style={{ marginRight: "15px" }}
                >
                  No
                </Button>
              </Grid>
              <Grid item xs={"auto"}>
                {BtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button
                    onClick={() => {
                      handleAddCustomeLegal(legalid);
                      setOpenLegal(false);
                    }}
                    color="secondary"
                  >
                    Yes
                  </Button>
                )}
              </Grid>
            </Grid>
          }
        />
      ) : null}
    </>
  );
};

export default OwnershipChangesOwnerInfo;
