import React from 'react';

import { Redirect, Route, Switch, Router } from 'react-router-dom';

import Main from '../Components/Shared/Main';

import { getSession } from '../Services/AppService';
import Login from '../Components/Shared/Login';
// import ChangePassword from "../Components/ChangePassword/changePassword";
import history from './History';
import AdobeViewer from '../Components/DataCollection/JobManagement/AdobeViewer';
import ChangePassword from '../Components/Shared/Login/changePassword';
import OpenFilePreview from '../Components/DataCollection/JobManagement/OpenFilePreview';
import ViewSDKClient from '../Components/DataCollection/JobManagement/ViewSDKClient';

const PrivateRoute = ({ component: Component, ...rest }) => {

    // console.log("token", localStorage.getItem('token'))
    let token = localStorage.getItem('token')
    if (token == null) {
        token = localStorage.getItem("token");
    }

    return (
        <Route {...rest} render={props => {
            return (token ?
                <Component {...props} />
                : <Redirect to="/login" />)
        }} />
    );
};

const PublicRoute = ({ component: Component, ...rest }) => {

    let token = localStorage.getItem('token')
    if (token == null) {
        token = localStorage.getItem("token");
    }
    return (
        <Route exact {...rest} render={props => (
            // replace "/app/dashboard" with landing page path.
            token ?
                <Redirect to={"/app"} />
                : <Component {...props} />
        )} />
    );
};

export default function Routing(props) {
    return (
            <Router history={history}>
                <Switch>
                    <PrivateRoute path="/app" component={Main} {...props} />
                    <PrivateRoute path="/app/data-collections/" component={Main} {...props} />
                    <PrivateRoute path="/app/ownership-changes/" component={Main} {...props} />
                    <PublicRoute path="/login" component={Login} />
                    <PublicRoute path="/changepassword" component={ChangePassword} />
                    <PublicRoute path="/OpenFilePreview" component={OpenFilePreview} />
                    <PublicRoute path="/ViewSDKClient" component={ViewSDKClient} />
                    <Redirect from="/" to="/login" />
                </Switch>
            </Router>
    )
}