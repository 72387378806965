import React from "react";
import { grey, lightBlue } from "@mui/material/colors";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Link,
  Typography,
  Paper,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@mui/material";
import BasicPopover from "../../../UIComponent/PopOver";
import axios from "axios";
import { useHistory } from "react-router-dom";

// Import Fluent UI Icons
import {
  AttachIcon,
  CommentIcon,
  FileOffIcon,
  LinkIcon,
  TextDocumentIcon,
} from "@fluentui/react-icons-mdl2";
import EmptyState from "../../../UIComponent/EmptyState";
import { useState } from "react";
import { ScrollingDialog } from "../../../UIComponent";
import AdobeViewer from "./AdobeViewer";

// Importing useStyles
import useStyles from "./jobManagement.styles";
import CommonService from "../../../Services/CommonService";

const darkGrey = grey[900];
const darkBlue = lightBlue[900];

function createData1(year, quarter, valuation, value, sources) {
  return {
    year,
    quarter,
    valuation,
    sources,
  };
}
function createData2(year, quarter, valuation) {
  return {
    year,
    quarter,
    valuation,
  };
}

function createData(
  reportVariable,
  reportUnit,
  cruVariable,
  cruUnit,
  currency
) {
  return {
    reportVariable,
    reportUnit,
    cruVariable,
    cruUnit,
    currency,
  };
}

const PreviewUpdateRecord = (props) => {
  const classes = useStyles();

  const column = [
    {
      id: "year",
      label: "Year",
      width: 70,
      minWidth: 70,
      sorting: false,
      align: "center",
    },
    {
      id: "quarter",
      label: "Quarter",
      width: 100,
      minWidth: 100,
      sorting: false,
      align: "center",
    },
    {
      id: "valuation",
      label: "Valuation",
      width: 100,
      minWidth: 100,
      sorting: false,
    },
    {
      id: "variableReport",
      label: "Variable (Report)",
      width: 150,
      minWidth: 150,
      sorting: false,
    },
    {
      id: "unitReport",
      label: "Unit (Report)",
      width: 100,
      minWidth: 100,
      sorting: false,
      align: "center",
    },
    {
      id: "variableCru",
      label: "Variable (CRU)",
      width: 150,
      minWidth: 150,
      sorting: false,
    },
    {
      id: "unitCru",
      label: "Unit (CRU)",
      width: 100,
      minWidth: 100,
      sorting: false,
      align: "center",
    },
    { id: "value", label: "Value", width: 100, minWidth: 100, sorting: false },
    {
      id: "currency",
      label: "Currency",
      width: 70,
      minWidth: 70,
      sorting: false,
      align: "center",
    },
    {
      id: "sources",
      label: "Sources",
      width: 70,
      minWidth: 70,
      sorting: false,
      align: "left",
    },
  ];

  const [initialDetails, setInitialDetails] = React.useState();
  const [tableData, setTableData] = useState([]);
  const [finalReport, setFinalReport] = useState([]);
  const [quarterlyDetails, setQuarterlyDetails] = React.useState([]);
  const [otherDetails, setOtherDetails] = React.useState([]);
  // const [column, setColumn] = React.useState([]);
  const [Comment, setComment] = React.useState({ message: "" });
  const [sOpen, setOpen] = React.useState(null);
  const history = useHistory();
  const [openAdobeViewer, setOpenAdobeViewer] = useState();
  const [adobeViewerData, setAdobeViewerData] = useState({
    jobId: 0,
    fileName: "",
    pageNumber: 0,
    crudState: "",
  });

  const open1 = Boolean(sOpen);
  const idss = open1 ? "simple-popover" : undefined;

  const getHistoryData = async () => {
    let { data } = await getVariableInfo();
    const arrangeDataForTable = async () => {
      let testArray = [];
      let Datum = data;
      for (let i = 0; i < Datum.length; i++) {
        let variables = Datum[i].reportingYear;
        if (variables.length > 0) {
          for (let k = 0; k < variables.length; k++) {
            let finalData = { ...variables[k], ...Datum[i] };

            testArray.push(finalData);
          }
        } else {
          testArray.push(Datum[i]);
        }
      }
      setInitialDetails(testArray);
    };
    await arrangeDataForTable();
    setInitialDetails(data);
  };

  React.useLayoutEffect(() => {
    getHistoryData();
  }, []);

  React.useEffect(() => {
    if (props.reportInfo && props.previewReportInfo) {
      let finalData = [];
      let checkDatas = [];
      props.reportInfo?.map((el) => finalData.push(el));
      props.previewReportInfo?.map((el) => finalData.push(el));

      const checkData = () => {
        for (let i = 0; i < finalData.length; i++) {
          if (finalData[i].assetReportId > 0) {
            checkDatas.push(finalData[i]);
          }
        }
      };
      checkData();
      setFinalReport(checkDatas);
    }
  }, []);


  const getVariableInfo = async () => {
    const response = await new Promise((resolve, reject) => {
      resolve(
        axios.get(
          `${process.env.React_App_baseUrl}updatejobrecord/GetVariableDetails`,
          {
            headers: {
              jobAssetId: props.jobAssetId,
              YearId: props.mainitem.reportingYearId,
              isAscending: true,
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
          }
        )
      );
    });

    return response;
  };


  // const openDocumentForView = (file, pageNumber = 1) => {
  //   let reportName = "";
  //   if(file.includes(".pdf")){
  //     reportName=file.split('.')[0];
  //   }else{
  //     reportName=file;
  //   }
  //   let senData = {
  //     jobId: props.jobID,
  //     fileName: reportName+".pdf",
  //     pageNumber: pageNumber,
  //     crudState: "View",
  //   };
  //   // history.push({ pathname: "/adobeView", state: senData });
  //   localStorage.setItem("filePreview", JSON.stringify(senData));
  //   CommonService.AddTokenFromSessionStorageToLocalStorage();
  //   window.open("#app/filepreview", "_blank", "noopener,noreferrer");
  // };

  // const OpenViewer = (data) => {
  //   setOpenAdobeViewer(true);
  //   setAdobeViewerData({
  //     jobId: data.jobId,
  //     fileName: data.fileName,
  //     pageNumber: data.pageNumber,
  //     crudState: data.crudState,
  //   });
  // };

  const openPopover = (event, mesg) => {
    setComment({ message: mesg });
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <Box>
      <Grid
        container
        spacing={3}
        justifyContent="left"
        alignItems="center"
        sx={{ p: 3 }}
      >
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.recordTitleArea}>
            <Box className={classes.recordTitle}>Asset Info</Box>
            <Box className={classes.recordLine}></Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
          <Typography variant="caption" display="block" gutterBottom>
            Company
          </Typography>
          <Typography variant="body1" color={darkGrey}>
            {props.previewAssetInfo.companyName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
          <Typography variant="caption" display="block" gutterBottom>
            Attributable %
          </Typography>
          <Typography variant="body1" color={darkGrey}>
            {props.attributeValue}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
          <Typography variant="caption" display="block" gutterBottom>
            Asset
          </Typography>
          <Typography variant="body1" color={darkGrey}>
            {props.defaultSelectedAsset.asset}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
          <Typography variant="body1" color={darkGrey}>
            <Checkbox
              checked={props.isAssetSegment}
              style={{ marginLeft: "-10px" }}
            />{" "}
            Asset is a segment
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
          <Typography variant="caption" display="block" gutterBottom>
            Commodity
          </Typography>
          <Typography variant="body1" color={darkGrey}>
            <Tooltip
              title={props.previewAssetInfo.commodity}
              placement="top"
              arrow
            >
              <span
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "99%",
                  display: "inline-block",
                }}
              >
                {props.previewAssetInfo.commodity}
              </span>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
          <Typography variant="caption" display="block" gutterBottom>
            Country
          </Typography>
          <Typography variant="body1" color={darkGrey}>
            {props.previewAssetInfo.country}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
          <Typography variant="caption" display="block" gutterBottom>
            Region
          </Typography>
          <Typography variant="body1" color={darkGrey}>
            {props.previewAssetInfo.region}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
          <Typography variant="caption" display="block" gutterBottom>
            Sub-Region
          </Typography>
          <Typography variant="body1" color={darkGrey}>
            {props.previewAssetInfo.subRegion}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <Typography variant="caption" display="block" gutterBottom>
            Segment Comments
          </Typography>
          <Typography variant="body1" color={darkGrey}>
            {props.segComments}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography variant="caption" display="block" gutterBottom>
            Link to CAT
          </Typography>
          <Typography variant="body1" color={darkGrey} noWrap>
            <LinkIcon style={{ fontSize: 22, marginRight: "5px" }} />{" "}
            <Link
              onClick={() => window.open(props.linkToCat, "_blank")}
              color={darkBlue}
            >
              {/* https://costanalysistool.crugroup.com/analyse/profile/50980 */}
              {props.linkToCat}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography variant="caption" display="block" gutterBottom>
            Link to Asset Platform
          </Typography>
          <Typography variant="body1" color={darkGrey} noWrap>
            <LinkIcon style={{ fontSize: 22, marginRight: "5px" }} />{" "}
            <Link
              onClick={() => window.open(props.linkToAssetPForm, "_blank")}
              color={darkBlue}
            >
              {/* https://costanalysistool.crugroup.com/analyse/profile/50980 */}
              {props.linkToAssetPForm}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography variant="caption" display="block" gutterBottom>
            Link to EAT
          </Typography>
          <Typography variant="body1" color={darkGrey} noWrap>
            <LinkIcon style={{ fontSize: 22, marginRight: "5px" }} />{" "}
            <Link
              onClick={() => window.open(props.linkToEat, "_blank")}
              color={darkBlue}
            >
              {/* https://costanalysistool.crugroup.com/analyse/profile/50980 */}
              {props.linkToEat}
            </Link>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        justifyContent="left"
        alignItems="center"
        sx={{ p: 3 }}
      >
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.recordTitleArea}>
            <Box className={classes.recordTitle}>Report Info</Box>
            <Box className={classes.recordLine}></Box>
          </Paper>
        </Grid>
        {finalReport &&
          finalReport?.map((el, key) => {
            return (
              <>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="left"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Year
                      </Typography>
                      <Typography variant="body1" color={darkGrey}>
                        {el.reportingYear && el.reportingYear.year
                          ? el.reportingYear.year
                          : "NA"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Reporting Period
                      </Typography>
                      <Typography variant="body1" color={darkGrey}>
                        {el.reportingPeriod && el.reportingPeriod.name
                          ? el.reportingPeriod.name
                          : "NA"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Report Publication Date
                      </Typography>
                      <Typography variant="body1" color={darkGrey}>
                        {el.publicationDate ? el.publicationDate : "NA"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Report Type
                  </Typography>
                  <Typography variant="body1" color={darkGrey}>
                    {el.reportingType && el.reportingType.reportingType
                      ? el.reportingType.reportingType
                      : "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Report Name
                  </Typography>
                  <Typography variant="body1" color={darkGrey} noWrap>
                    {el.reportName ? el.reportName : "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={6}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Link
                  </Typography>
                  <Typography variant="body1" color={darkGrey} noWrap>
                    <LinkIcon style={{ fontSize: 22, marginRight: "5px" }} />{" "}
                    <Link
                      onClick={() => window.open(el.Link, "_blank")}
                      color={darkBlue}
                    >
                      {el.Link
                        ? el.Link
                          ? el.Link
                          : "NA"
                        : el.link
                        ? el.link
                        : "NA"}
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={6}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Attachment(s):{" "}
                    {/* <span style={{ fontWeight: "bold" }}>{el.file.length}</span> */}
                  </Typography>
                  {el.file ? (
                    <Grid
                      container
                      spacing={3}
                      justifyContent="left"
                      alignItems="center"
                    >
                      <Grid item style={{ fontSize: 16 }} xs={12}>
                        <Typography variant="body1" color={darkGrey} noWrap>
                          <TextDocumentIcon
                            style={{ fontSize: 22, marginRight: "5px" }}
                          />{" "}
                          {el.file.DisplayName}
                          <Tooltip
                            title={el.file.DisplayName}
                            placement="top"
                            arrow
                          >
                            <IconButton color="secondary">
                              <AttachIcon
                                onClick={() =>
                                  props.openDocumentForView(el.file.DisplayName, 1)
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography variant="body1" color={darkGrey} noWrap>
                      {"NA"}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <hr style={{ margin: 0 }} />
                </Grid>
              </>
            );
          })}
      </Grid>

      <Grid
        container
        spacing={3}
        justifyContent="left"
        alignItems="center"
        sx={{ p: 3 }}
      >
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.recordTitleArea}>
            <Box className={classes.recordTitle}>Data Metrics</Box>
            <Box className={classes.recordLine}></Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.setBorder}>
            <TableContainer
              sx={{ maxHeight: 440 }}
              className={classes.setColor}
            >
              <Table stickyHeader aria-label="sticky" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.tableHeader}
                      style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                    >
                      Variable (Report)
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.cruTheadLeftSep}
                      style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                    >
                      Unit (Report)
                    </TableCell>

                    <TableCell
                      align="left"
                      className={classes.cruTheadLeftSep}
                      style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                    >
                      Variable (CRU)
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.cruTheadLeftSep}
                      style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                    >
                      Unit (CRU)
                    </TableCell>

                    <TableCell
                      align="center"
                      className={classes.cruTheadLeftSep}
                      style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                    >
                      Currency
                    </TableCell>

                    {props.companyHeader.map((el, ind) => (
                      <TableCell
                        align="center"
                        rowSpan={2}
                        style={{ minWidth: "150px" }}
                        className={classes.cruTheadLeftSep}
                        key={ind}
                      >
                        {el.publication}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {initialDetails?.map((el, ind) => (
                  <TableRow hover key={ind}>
                    <TableCell
                      align="left"
                      className={classes.tableCellLeftSep}
                      style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                    >
                      {el.variableReport}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCellLeftSep}
                      style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                    >
                      {el.unitReport}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCellLeftSep}
                      style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                    >
                      {el.variableCru}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCellLeftSep}
                      style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                    >
                      {el.unitCru}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCellLeftSep}
                      style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                    >
                      {el.currency}
                    </TableCell>
                    {el["reportingYear"].map((item) => (
                      <TableCell
                        align="right"
                        style={{ minWidth: "100px", whiteSpace: "nowrap" }}
                        key={ind}
                        className={classes.tableCellLeftSep}
                      > 
                        <span style={{color : item.variableValueStatusName === "CRU Review Failed" ? "red" :  el.valuationType === "Actual" ? 'black' : el.valuationType === "Guidance" ? "blue" : el.valuationType === "Calculated" ? "#A020F0" : el.variableValueStatusName === "CRU Review Failed" ?  'red' : 'black'}}>
                          {(item.minValue || item.maxValue) ? `${item.minValue? item.minValue?.toLocaleString() : ""} ${(item.minValue && item.maxValue) ? "~" : ""} ${item.maxValue ? item.maxValue?.toLocaleString() : ""}` : item.value ? item.value?.toLocaleString() : "-"}
                        </span>
                        <span>
                          {item.comments ? (
                            <Tooltip
                              title={item.commentss}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                aria-describedby={idss}
                                onClick={(event) =>
                                  openPopover(event, item.comments)
                                }
                              >
                                <CommentIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <span className={classes.overviewIcons}>-</span>
                          )}
                        </span>
                        <span>
                          {/* {item['valueReportInfo'].map(kt=>kt.reportName)} */}
                          {item["valueReportInfo"]?.length ? (
                            <>
                              {item["valueReportInfo"]?.map((kt) => (
                                <>
                                  <Tooltip
                                    title={kt.reportName}
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton>
                                      <AttachIcon
                                        onClick={() => {
                                          props.openDocumentForView(
                                            kt.reportName,
                                            kt.pageNumber
                                          );
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              ))}
                            </>
                          ) : (
                            <span className={classes.overviewIcons}>-</span>
                          )}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
            <BasicPopover
              sOpen={sOpen}
              open={open1}
              onClose={handleClose}
              idss={idss}
              description={Comment.message}
            />
          </Box>
        </Grid>
      </Grid>
      <ScrollingDialog
        open={openAdobeViewer}
        setOpen={setOpenAdobeViewer}
        dialogClass="darkTitle"
        dialogTitle="Preview"
        dialogContent={
          <AdobeViewer
            jobId={adobeViewerData.jobId}
            fileName={adobeViewerData.fileName}
            pageNumber={adobeViewerData.pageNumber}
            crudState={adobeViewerData.crudState}
          ></AdobeViewer>
        }
      />
    </Box>
  );
};

export default PreviewUpdateRecord;

const allCompany = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
];
const allPage = [
  { label: "1", year: 1994 },
  { label: "2", year: 1972 },
  { label: "3", year: 1974 },
  { label: "4", year: 2008 },
  { label: "5", year: 2008 },
];
const allPerPage = [
  { label: "1 Record", year: 1994 },
  { label: "2 Records", year: 1972 },
  { label: "3 Records", year: 1974 },
  { label: "4 Records", year: 2008 },
  { label: "5 Records", year: 2008 },
];
const allAsset = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
];
const allReportType = [
  { label: "Invester Report 1", year: 1994 },
  { label: "Invester Report 2", year: 1972 },
  { label: "Add Report/Others", year: 1974 },
];