// Default React, Router and Services Components
import React from "react";
import { Button, Box, Grid, Typography } from "@mui/material";
import ApiService from "../../../../Services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { toasterError } from "../../../../UIComponent/Toaster";
import DropdownTreeSelect from "react-dropdown-tree-select";
import ErroHandling from "../../../../Services/ErrorHandling";
// Importing Custom Components
import MultiSelect from "../../../../UIComponent/MultiSelect/MultiSelect";
import {
  clearFilterJobRecords,
  GetAssetData,
  setFilterJobRecords,
} from "../../../../features/filter/filterSlice";
import DatePicker from "../../../../UIComponent/DatePicker";
// Importing useStyles
import useStyles from "../../JobManagement/jobManagement.styles";
import "../../../../../src/index.scss";
import "../../../../Resources/scss/layout.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-dropdown-tree-select/dist/styles.css";
import TreeFilter from "../../../../Util/TreeHOC";
import {
  callBasedOnLocation,
  callFromAssetType,
  callFromComm,
  callFromPriority,
  callSource,
} from "../../../../Util/TreeAPI";

const AgingDrillFilter = (props) => {
  // Declaration of States and Component Variables Start Here
  const { filtersRecords, assetDropDownlist } = useSelector(
    (store) => store.filter
  );
  const dispatch = useDispatch();
  const [error, setError] = React.useState(false);
  const [status, setStatus] = React.useState([]);
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [jobComms, setJobComms] = React.useState([]);
  const [prior, setPrior] = React.useState([]);
  const [jobDate, setJobDate] = React.useState("");
  const [assignedto, setAssignedTo] = React.useState([]);
  const [assignedBy, setAssignedBy] = React.useState([]);
  const [filters, setFilters] = React.useState({
    company: [],
    asset: [],
    assetTypeIds: [],
    countryIds: [],
    regionIds: [],
    subRegionIds: [],
    commodityIds: [],
    statusIds: [],
    priorityIds: [],
  });
  const [loading, setLoading] = React.useState(false);
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  const [selectedLocations, setSelectedLocation] = React.useState([]);
  const classes = useStyles();
  // Declaration of States and Component Variables End Here
  const [apiData, setApiData] = React.useState({
    companies: [],
    location: {},
    assetType: [],
    priority: [],
    commodity: [],
  });
  const [dependency, setDependency] = React.useState(null);
  const [subCompDependency, setSubCompDependency] = React.useState(false);
  const [subLocDependency, setSubLocDependency] = React.useState(false);

  React.useEffect(() => {
    getAllStatus();
    dispatch(GetAssetData());
    getAllCommodities();
    getAllPriority();
    getAllAssignedUsers();
  }, []);

  // Function for Handle KeyUp
  // Function to handle changes in the filter records
  const onChangeHandler = (key, value, reason) => {
    // Create a copy of the filtersRecords object
    const pre = { ...filtersRecords };
    // Update the key with the new value
    pre[key] = value;
    // Check if the key is "commodity"
    if (key === "commodity") {
      // Set the dependency for commodity
      setDependency("commodity");
      // Update the apiData object
      setApiData({
        ...apiData,
        commodity: value,
      });
    } else if (key === "priority") {
      // Set the dependency for priority
      setDependency("priority");
      // Update the apiData object
      setApiData({
        ...apiData,
        priority: value,
      });
    } else if (key === "assetType") {
      // Set the dependency for assetType
      setDependency("assetType");
      // Update the apiData object
      setApiData({
        ...apiData,
        assetType: value,
      });
    }
    // Dispatch the updated filtersRecords object
    dispatch(setFilterJobRecords({ filtersRecords: pre }));
  };
  // This function is used to clear the filter fields
  const clearFieldHanlder = () => {
    // Reset the tree filter
    props.resetTreeFilter();
    // Dispatch an action to clear the filter job records
    dispatch(clearFilterJobRecords());
    // Set the filter data to empty string
    props.setFilterData("");
    // Toggle the reload state
    props.setReload(!props.reload);
    // Get the filter data
    props.getFilterData();
    // Set the dependency to null
    setDependency(null);
    // Set the sub component dependency to false
    setSubCompDependency(false);
    // Set the sub location dependency to false
    setSubLocDependency(false);
  };

  // Function for Getting All Status
  // Function to get all status from API
  const getAllStatus = () => {
    // Call ApiService to get all data from "DropDown/GetJobStatusFilterAsync"
    ApiService.getAll("DropDown/GetJobStatusFilterAsync")
      .then((res) => {
        // If response status is 200, set the status
        if (res.status === 200) {
          setStatus(res.data);
        } else {
          // Else call toasterError with response data
          toasterError(res.data);
        }
      })
      .catch((err) => {
        // If error response status is 400, call toasterError with error response data
        if(err.res.status===400){
          toasterError(err.res.data);
        }
         });
  };

// This function is used to get all assigned users from the API
const getAllAssignedUsers = () => {
  // Call the ApiService to get all assigned users
  ApiService.getAll("CompanyOwnershipReport/GetAssignedToDropdown")
    .then((res) => {
      // If the response status is 200, set the assigned users in the state
      if (res.status === 200) {
        setAssignedTo(res.data);
      } else {
        // If the response status is not 200, show an error message
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Handle any errors that occur during the API call
      ErroHandling(err, function (res) {
        toasterError(res);
      });
    });
};

  // Function for Getting All Commodity
  // This function is used to get all commodities from the API
  const getAllCommodities = () => {
    // Call the ApiService to get all commodities
    ApiService.getAll("DropDown/GetCommodityDropDownFilter")
      .then((res) => {
        // If the response status is 200, set the job commodities
        if (res.status === 200) {
          setJobComms(res.data);
        } else {
          // Else, call the toasterError function with the response data
          toasterError(res.data);
        }
      })
      .catch((err) => {
        // If the response status is 400, call the toasterError function with the response data
        if(err.res.status===400){
          toasterError(err.res.data);
        }
         });
  };

  // Function for Getting All Priority
  // Function to get all priority from API
  const getAllPriority = () => {
    // Call ApiService to get all data from "DropDown/GetPriorityDropDownFilter"
    ApiService.getAll("DropDown/GetPriorityDropDownFilter")
      .then((res) => {
        // If response status is 200, set the data to 'setPrior'
        if (res.status === 200) {
          setPrior(res.data);
        } else {
          // Else call 'toasterError' with response data
          toasterError(res.data);
        }
      })
      .catch((err) => {
        // If error response status is 400, call 'toasterError' with error response data
        if(err.res.status===400){
          toasterError(err.res.data);
        }
         });
  };
  React.useEffect(() => {
    if (dependency === "company") {
      callSource(apiData)
        .then((res) => {
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationFilter) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationFilter) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationFilter) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "location") {
      callBasedOnLocation(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
          setSubCompDependency(true);
        })
        .catch((err) => console.log(err));
    } else if (dependency === "assetType") {
      callFromAssetType(apiData)
        .then((res) => {
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationFilter) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationFilter) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationFilter) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "priority") {
      callFromPriority(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationFilter) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationFilter) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationFilter) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          props.setCompanyFilter(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "commodity") {
      callFromComm(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationFilter) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationFilter) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationFilter) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          props.setCompanyFilter(res.data.companies);
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [apiData]);
// This function is used to set the dependency of company
  const onChangeComp = (currentNode, selectedNodes) => {
    setDependency("company");
    // Set the selected companies
    setSelectedCompanies(selectedNodes);
    // Loop through the company filter props
    props.companyFilter.forEach((el) => {
      // If the current node is checked, set the value and children to true
      if (currentNode.checked === true) {
        if (currentNode.value === el.value) {
          el.checked = true;
        } else if (currentNode.parentValue === el.value) {
          el.children.forEach((item) => {
            if (currentNode.value === item.value) {
              item.checked = true;
            }
          });
        }
        // If the current node is unchecked, set the value and children to false
      } else if (currentNode.checked === false) {
        if (currentNode.value === el.value) {
          el.checked = false;
        } else if (currentNode.parentValue === el.value) {
          el.children.forEach((item) => {
            if (currentNode.value === item.value) {
              item.checked = false;
            }
          });
        }
      }
    });
// Map through the companyFilter props and filter out the elements with checked value as true
    const firstParent = props.companyFilter
      .map((el) => {
        let data = {
          label: el.label,// Store the label of the element
          value: el.value,// Store the value of the element
          children: el.children.filter((item) => item["checked"] === true),// Filter out the children with checked value as true
        };
        return data; // Return the data object
      })
      .filter((el) => el.children.length > 0); // Filter out the elements with no children

    //console.log(firstParent, "firstParent");

    setApiData({
      ...apiData, // Spread the apiData object
      companies:
        props.companyFilter.filter((el) => el["checked"] === true).length > 0 // Check if any elements have checked value as true
          ? props.companyFilter.filter((el) => el["checked"] === true) // If yes, filter out those elements
          : firstParent, // Else, use the firstParent array
    });
  };

// Function to submit filter data
  const submitFilter = () => {
    // Create an object with company and statusId as keys
    const formData = {
      company: props.companyFilter
      // Filter out elements with checked value false
        .filter((el) => el.checked === true)
        // Map the filtered elements to get their values
        .map((el) => el.value),
      tat: props.tatDate,
      AssignedToId: (filtersRecords.assignedto || []).map(
        (e) => e.userId
      ),
      statusId: filtersRecords.status.map((el) => el.id),
    };
    // Set the filter data
    props.setFilterData(formData)
    // Set the filter value
    props.setFilterValue(formData);
    // Update the state
    props.setState({ ...props.state, right: false });
    // Reload the page
    props.setReload(!props.reload)
  };

  console.log(props.companyFilter, "companyFilter")

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Add & Edit Frequency Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TreeFilter
            data={props.companyFilter}
            onChange={onChangeComp}
            showPartiallySelected={true}
            texts={{ placeholder: "Company" }}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filtersRecords.status}
            getOptionSelected={(option, anotherOption) =>
              option.statusName === anotherOption.statusName
            }
            items={status ? status : []}
            label="Status"
            selectAllLabel={{ statusName: "Select all" }}
            getOptionLabel={(option) => option.statusName}
            role="status"
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filtersRecords.assignedto}
            items={assignedto}
            label="Assigned To"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            role="assignedto"
          />
        </Grid>

        <Grid item xs={12}>
         
         <DatePicker
           labelTitle="TAT"
           placeHolder="DD-MM-YYYY"
           variant="standard"
           past={false}
           defaultDate={props.tatDate}
           onChange={onChangeHandler}
           onChangeDate={(e) => {
             let selectedDate = new Date(e);
             props.setTatDate(
               selectedDate.getFullYear() +
               "-" +
               (selectedDate.getMonth() + 1) +
               "-" +
               selectedDate.getDate()
             );
           }}
         />
       </Grid>    
        
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn"
                color="accent5"
                onClick={submitFilter}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn reset-color"
                color="accent3"
                onClick={clearFieldHanlder}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgingDrillFilter;