// Default React, useState and Material Components
import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddEditCruVariable = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [addCRUVariableDrawer, setAddCRUVariableDrawer] = useState(props.addCRUVariableDrawer);
  const [cruVariable, setCruVariable] = useState({
    id: 0, name: "", usualSource: "", description: "", active: true, createdOn: "2022-09-05T09:18:29.865Z",
  });
  // Declaration of States and Component Variables End Here

  React.useEffect(() => {
    if (props.crudState === "Edit" || props.crudState === "View") {
      let data = {
        id: props.formData.id,
        name: props.formData.name,
        usualSource: props.formData.usualSource,
        description: props.formData.description,
        active: props.formData.active,
        createdOn: props.formData.createdOn,
      };
      setCruVariable(data);
    }
  }, [props]);

  // Function for Validation
  const validation = (cruVariable) => {
    if (cruVariable.name.trim() == "" && cruVariable.usualSource.trim() == "" && cruVariable.description.trim() == "") {
      toasterError("Please enter required fields");
      return false;
    }
    if (cruVariable.name.trim() == "") {
      toasterError("Please enter CRU variable");
      return false;
    }
    if (cruVariable.name.length > 150) {
      toasterError("Please enter CRU variable less than 150 characters");
      return false;
    }
    if (cruVariable.usualSource.trim() == "") {
      toasterError("Please enter  usual source");
      return false;
    }
    if (cruVariable.usualSource.length > 100) {
      toasterError("Please enter usual source less than 100 characters");
      return false;
    }
    if (cruVariable.description.trim() == "") {
      toasterError("Please enter description");
      return false;
    }
    if (cruVariable.description.length > 250) {
      toasterError("Please enter description less than 250 characters");
      return false;
    }
    return true;
  }

 // Function to save CRU variable
const savecruvariable = () => {
  // Set loading to true
  setLoading(true);
  // Check if validation is successful
  if (validation(cruVariable)) {
    // Call API service to create CRU variable
    ApiService.create("cruvariable/CreateCRUVariable", cruVariable)
      .then((res) => {
        // If response status is 200, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("CRU variable added successfully!");
          if (addCRUVariableDrawer) {
            setLoading(false);
            if (props.hideSideDrawer != null) {
              props.hideSideDrawer();
            }
          } else {
            setLoading(false);
            if (props.closeAddCRUVariable != null) {
              props.closeAddCRUVariable();
            }
            if (props.hideSideDrawer != null) {
              props.hideSideDrawer();
            }
          }
        }
      })
      // Catch any errors and handle them
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        }
        )
        setLoading(false);
        if (addCRUVariableDrawer) {
          if (props.hideSideDrawer != null) {
            props.hideSideDrawer();
          }
        } else {
          setLoading(false);
          if (props.closeAddCRUVariable != null) {
            props.closeAddCRUVariable();
          }
        }
      });
  } else {
    // Set loading to false if validation fails
    setLoading(false);
  }
}

// This function updates the CRU variable
const updatecruvariable = () => {
  // Set loading to true while data is being updated
  setLoading(true);
  // Create a validData object with the current cruVariable
  let validData = cruVariable;
  // Add the id from props.formData to the validData object
  validData.id = props.formData.id;
  // If validation passes, make an API call to update the CRU variable
  if (validation(cruVariable)) {
    ApiService.create("cruvariable/UpdateCRUVariable", validData)
      .then((res) => {
        // If the response status is 200, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("CRU variable details updated successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // Handle errors and display error message
        ErroHandling(err, function (res) {
          toasterError(res)
        })
        setLoading(false);
      });
  } else { 
    // If validation fails, set loading to false
    setLoading(false); 
  }
}

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Variable(CRU)
        </Typography>
      </Box>

      {/* Add & Edit Top Producers Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            InputProps={{
              readOnly: props.crudState === "View" && true,
            }}
            required
            fullWidth
            id="standard-basic"
            name="name"
            size="small"
            label="CRU Variable"
            placeholder="CRU Variable"
            variant="standard"
            inputProps={{ maxLength: 150 }}
            value={props.crudState === "Add" ? null : props.formData.name}
            onChange={(e) => {
              props.setFormData({ ...props.formData, name: e.target.value });
              setCruVariable({ ...cruVariable, name: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            required
            fullWidth
            id="standard-basic"
            name="usualSource"
            size="small"
            label="Usual Source"
            placeholder="Usual Source"
            variant="standard"
            inputProps={{ maxLength: 100 }}
            value={props.crudState === "Add" ? null : props.formData.usualSource}
            onChange={(e) => {
              props.setFormData({ ...props.formData, usualSource: e.target.value });
              setCruVariable({ ...cruVariable, usualSource: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />

        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            required
            fullWidth
            multiline
            maxRows={4}
            id="standard-basic"
            name="description"
            size="small"
            label="Description"
            placeholder="Description"
            variant="standard"
            inputProps={{ maxLength: 250 }}
            value={props.crudState === "Add" ? null : props.formData.description}
            onChange={(e) => {
              props.setFormData({ ...props.formData, description: e.target.value });
              setCruVariable({ ...cruVariable, description: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>
        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={12} sm={'auto'}>
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Add" ? savecruvariable : updatecruvariable}
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <Button
                  variant="contained"
                  className="rounded-border"
                  color="accent3"
                  onClick={() => {
                    if (addCRUVariableDrawer) {
                      props.setState({ ...props.state, right: false })
                    } else {
                      setAddCRUVariableDrawer(true);
                      if (props.closeAddCRUVariable != null) { props.closeAddCRUVariable(); }
                    }
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddEditCruVariable;