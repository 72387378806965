// Default React, Router and Services Components
import React, { useState } from "react";
import {
    Breadcrumbs, Button, Box, Grid, TextField, Typography, FormControl, RadioGroup,
    FormControlLabel, Radio, TableCell, TableRow, TableBody, TableHead, TableContainer, Table, TableFooter
} from "@mui/material";
import { Link } from 'react-router-dom';
import axios from "axios";

// Importing Custom Components
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
// import history from "../../../Routes/History";
import { useHistory } from "react-router-dom";
// Importing useStyles
import useStyles from "./QualityManagement.styles";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from 'moment';
import dayjs from "dayjs";


// Import Fluent UI Icons
import { ChevronLeftIcon, ChevronRightSmallIcon } from "@fluentui/react-icons-mdl2";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { valid } from "overlayscrollbars";
import { textAlign } from "@mui/system";

const QualityManagementDrillDown = (props) => {
    const history = useHistory();
    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    const [getTotalRecordsForQCNum, setGetTotalRecordsForQCNum] = useState();
    const [assignToQc, setAssignToQc] = useState(true);
    const [samplePercentage, setSamplePercentage] = useState(50);
    const [qcSampled, setQcSampled] = useState();
    const [qcData, setQcdata] = useState({});
    const [assignInput, setAssignInput] = useState(true);
    const [assignToQcSection, setAssignToQcSection] = useState(false);
    const [customData, setCustomData] = useState({});
    const [openAlertPopup, setOpenAlertPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    //const [openTobeSampledCalAlertPopup, setOpenTobeSampledCalAlertPopup] = useState(false);
    const [beyondSamplingPercentageMsg, setBeyondSamplingPercentageMsg] = useState(false);
    const [showTobeSampledSumAnalystError, setShowTobeSampledSumAnalystError] = useState(false);
    const [calQcSamplingPercenatge, setCalQcSamplingPercenatge] = useState();
    const [validatedDate, setValidatedDate] = React.useState('');
    const [samplingDates, setSamplingDate] = useState();
    const [disableApply, setDisableApply] = useState(false);

    const GetTotalRecordsForQC = (date) => {
        setDisableApply(false);

        axios.get(process.env.React_App_baseUrl + 'OwnershipChangeQualityManagement/GetTotalRecordsForQC', {
            headers: {
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null,
                samplingDate: moment(date).format("YYYY-MM-DDTHH:mm:ss")
            }
        }).then((res) => {
            if (res.status === 200) {
                setGetTotalRecordsForQCNum(res.data);
                if (res.data == 0) {
                    setAssignToQcSection(false);
                }
            }
        }).catch((err) => {
            ErroHandling(err, function (res) {
                setDisableApply(true);
                toasterError(res)

            })
        })
    }

    const GetDistributeEquallyGrid = () => {
        axios.get(process.env.React_App_baseUrl + 'OwnershipChangeQualityManagement/GetDistributedEquallySamplingDetails',
            {
                headers: {
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null,
                    totalQCJobs: getTotalRecordsForQCNum,
                    samplingPercentage: samplePercentage,
                    samplingDate: moment(samplingDates).format("YYYY-MM-DDTHH:mm:ss")
                }
            },).then((res) => {
                if (res.status === 200) {
                    setQcdata(res.data);

                }
            }).catch((err) => {
                ErroHandling(err, function (res) {
                    toasterError(res)
                })
            })
    }

    const GetCustomDataGrid = () => {
        axios.get(process.env.React_App_baseUrl + 'OwnershipChangeQualityManagement/GetDistributedEquallySamplingDetails',
            {
                headers: {
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null,
                    totalQCJobs: getTotalRecordsForQCNum,
                    samplingPercentage: samplePercentage,
                    samplingDate: moment(samplingDates).format("YYYY-MM-DDTHH:mm:ss")
                },

            },).then((res) => {
                if (res === 0) {
                    toasterError("No Records  Available");
                    setDisableApply(true);
                }
                if (res.status === 200) {
                    setCustomData(res.data);
                }
            }).catch((err) => {
                ErroHandling(err, function (res) {
                    toasterError(res)
                })
            })
    }

    const recalculateClick = () => {
        if (showTobeSampledSumAnalystError === true) {
            toasterError("The sample count does not match the analyst's distribution count. Please recheck");
        } else {
            setOpenAlertPopup(true);
        }
    }

    const saveRecalculatedSamplingData = () => {
        const reCalculateCustomData = customData;

        axios.post(process.env.React_App_baseUrl + 'OwnershipChangeQualityManagement/GetRecalculatedSampling',
            reCalculateCustomData,
            {
                headers: {
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                },
            }).then((res) => {
                if (res.status === 200) {
                    toasterSuccess("Sampling deatails recalculated successfully");
                    setOpenAlertPopup(false);
                    setCustomData(res.data);
                }
            }).catch((err) => {
                ErroHandling(err, function (res) {
                    toasterError(res)
                })
            })

    }


    const saveDistributeEquallySampling = () => {
        setLoading(true);
        const qcSaveData = qcData;
        axios.post(process.env.React_App_baseUrl + 'OwnershipChangeQualityManagement/SaveQCSampling',
            qcSaveData,
            {
                headers: {
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null,
                    samplingDate: moment(samplingDates).format("YYYY-MM-DDTHH:mm:ss")
                },
            }).then((res) => {
                if (res.status === 200) {
                    toasterSuccess("QC sampling deatails save successfully");
                }
                setLoading(false);
                setAssignInput(true);
            }).catch((err) => {
                ErroHandling(err, function (res) {
                    toasterError(res)
                })
                setLoading(false);
                setAssignInput(false);
            })
    }


    // To Fill the grid
    // React.useEffect(() => { GetCustomDataGrid(); }, []);
    React.useEffect(() => { handleDateValidate(); }, []);



    const handleDateValidate = () => {
        const date = new Date();

        if (date.getDay() === 1) {
            const formattedDate = moment().subtract(3, 'days').format("MM-DD-YYYY");

            setValidatedDate(dayjs(formattedDate));
            GetTotalRecordsForQC(formattedDate);


        }
        else {
            const formattedDate = moment().subtract(1, 'days').format("MM-DD-YYYY");



            setValidatedDate(dayjs(formattedDate));

            GetTotalRecordsForQC(formattedDate);

        }

    }

    // Sample percenatge min max value validation
    const min = 0;
    const max = 100;

    const handleSamplePercentage = event => {
        const value = Math.max(min, Math.min(max, Number(event.target.value)));
        setSamplePercentage(value);

    };

    //calclulate QC Sampled 

    const calculateQcSampled = (e) => {
        e.preventDefault();
        const calculatedQcSampled = ((getTotalRecordsForQCNum * samplePercentage) / 100).toFixed()
        setQcSampled(calculatedQcSampled);
        setAssignToQcSection(true);
        GetDistributeEquallyGrid();
        GetCustomDataGrid();
    }

    const resetCalculateQcSampled = () => {
        setSamplePercentage('');
        setQcSampled('0');
    }

    const saveCustomAssignSampling = () => {
        setLoading(true);
        if (showTobeSampledSumAnalystError === true) {
            toasterError("The sample count does not match the analyst's distribution count. Please recheck");
            setLoading(false);
        } else {
            const qcSaveCusotmAssignData = { ...customData, "isDistributedEqually": false, "samplingPercentage": 0 };
            axios.post(process.env.React_App_baseUrl + 'OwnershipChangeQualityManagement/SaveQCSampling',
                qcSaveCusotmAssignData,
                {
                    headers: {
                        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null,
                        samplingDate: moment(samplingDates).format("YYYY-MM-DDTHH:mm:ss")
                    },
                }).then((res) => {
                    if (res.status === 200) {
                        toasterSuccess("Custom sampling assigned sucessfully");
                    }
                    setLoading(false);
                }).catch((err) => {
                    ErroHandling(err, function (res) {
                        toasterError(res)
                    })
                    setLoading(false);
                })
        }
    }
    //Tobesampled total calculation 
    const reCalculate = (skipUserTotal, calculateUserTotal) => {
        let sumTobeSampled = 0;
        let allUsers = [];
        let invalidAnalystTobeSampled = 0;
        customData?.getQCSamplingAnalystCODto.map((analyst) => {

            setAssignInput(false);
            if (analyst?.getMeritQCUsersCODto) {

                if (calculateUserTotal) {
                    allUsers = allUsers.concat(analyst.getMeritQCUsersCODto);
                }

                analyst.toBeSampled = !skipUserTotal ? 0 : analyst.toBeSampled;
                analyst.toBeSampledTemp = 0;
                analyst?.getMeritQCUsersCODto.map((user) => {
                    if (!skipUserTotal) {
                        analyst.toBeSampled += parseInt(user.toBeSampled || 0);
                    }
                    analyst.toBeSampledTemp += parseInt(user.toBeSampled || 0);
                });
                if (analyst.toBeSampled != analyst.toBeSampledTemp || analyst.toBeSampledTemp > analyst.submittedQC) {
                    invalidAnalystTobeSampled++;
                }
            }
            sumTobeSampled = sumTobeSampled + parseInt(analyst.toBeSampled || 0);
        });
        setShowTobeSampledSumAnalystError(false);
        if (invalidAnalystTobeSampled > 0) {
            setShowTobeSampledSumAnalystError(true);
        }
        if (!customData.totalCODto) {
            customData.totalCODto = {};
        }
        customData.totalCODto.totalToBeSampled = sumTobeSampled;

        if (calculateUserTotal) {
            customData.totalCODto.getMeritQCUsersCODto.map((t) => {
                t.toBeSampled = 0;
                allUsers.map((u) => {
                    if (u.meritQCUserId == t.meritQCUserId) {
                        t.toBeSampled += parseInt(u.toBeSampled || 0)
                    }
                })
            })
        }


        setCustomData({ ...customData });

        let customQcSamplingPercenatge = ((customData.totalCODto.totalToBeSampled * 100) / getTotalRecordsForQCNum).toFixed();

        setCalQcSamplingPercenatge(customQcSamplingPercenatge);

        if (customQcSamplingPercenatge > 50) {
            setBeyondSamplingPercentageMsg(true)
        } else {
            setBeyondSamplingPercentageMsg(false)
        }


    }

    //close alert popup
    const handleADClose = () => { setOpenAlertPopup(false); };
    // const handleTobeSampledCalAlertClose = () => {setOpenTobeSampledCalAlertPopup(false);};

    return (

        // Page Layout Start Here
        <Box>
            <Grid container maxWidth justifyContent="right" alignItems="center">

                {/* Breadcrumbs Menu Start Here */}
                <Grid item xs={12} sm={12} md={8} className="page-title">
                    <h2>QC Sampling</h2>
                    <Breadcrumbs
                        separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>
                            Home
                        </Link>
                        <Typography color="text.primary">Manage Company Ownership Changes</Typography>
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/quality-management" }}>Quality Management</Link>
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/quality-management-drill" }}>QC Sampling</Link>
                    </Breadcrumbs>
                </Grid>
                {/* Breadcrumbs Menu End Here */}

                {/* Main Buttons Start Here */}
                <Grid item xs={12} sm={12} md={4}>
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                        <Grid item xs={"auto"}>
                            <Button variant="contained" color="accent2"
                                startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
                                onClick={() => { history.push("/app/ownership-changes/quality-management"); }}
                            >
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Main Buttons End Here */}

                {/* Baselayout Start Here */}
                <Grid item xs={12}>
                    <BaseLayout>
                        <Grid container alignItems="center" justifyContent="center" className={classes.assignDetails}>
                            <Grid item xs={12} sm={2} md={2} mt={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Stack>
                                        <DesktopDatePicker
                                            label="Job Submission Date"
                                            inputFormat="DD-MM-YYYY"
                                            value={validatedDate}
                                            clearable={true}
                                            past={false}
                                            onChange={(e) => {
                                                let selectedDate = new Date(e);
                                                let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
                                                setSamplingDate(date);
                                                setValidatedDate(date);

                                                GetTotalRecordsForQC(date);

                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"

                                                />
                                            )}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} style={{ textAlign: "center" }}>
                                Total Jobs Available for QC
                                <div className={classes.assignValues}>{getTotalRecordsForQCNum}</div>
                            </Grid>

                            <Grid item xs={12} sm={5} md={5}>
                                <Box sx={{ flexGrow: 1 }} >
                                    <Grid container spacing={2} justifyContent="center" alignItems="self-end">
                                        <Grid item xs="auto">
                                            <TextField
                                                id="standard-basic"
                                                label="Set Sample %"
                                                required
                                                type="number"
                                                variant="standard"
                                                value={samplePercentage}
                                                onChange={handleSamplePercentage}
                                                disabled={!assignToQc}
                                                onKeyDown={(e) => {
                                                    if (e.key == ".") {
                                                        e.preventDefault();
                                                        return false;
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={9} lg={10} xl={5}>
                                            <Grid container spacing={2} justifyContent="left" sx={{ mt: 1 }}>
                                                <Grid item xs={'auto'}>
                                                    <Button variant="contained" className="rounded-border" color="accent5" onClick={calculateQcSampled} disabled={!assignToQc || samplePercentage == "" || disableApply || getTotalRecordsForQCNum == 0}>
                                                        Apply
                                                    </Button>
                                                    <Button variant="contained" className="rounded-border" color="accent3" sx={{ ml: 2 }} onClick={resetCalculateQcSampled} disabled={!assignToQc}>
                                                        Reset
                                                    </Button>

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={2} md={2} style={{ textAlign: "center" }} >
                                To be QC sampled
                                <div className={classes.assignValues}>{qcSampled}</div>
                            </Grid>

                        </Grid>
                        {assignToQcSection ?
                            <Box className={classes.addBorder}>
                                <Grid item xs={12} className={classes.tableTopPart}>
                                    <Grid container alignItems="center" justifyContent="left" spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            {assignToQc ?
                                                <h4 className={classes.headingText2}>Assigning to QC - {samplePercentage} %</h4>
                                                : <h4 className={classes.headingText2}>Assigning to QC - {calQcSamplingPercenatge} %</h4>}
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            {assignToQc ?
                                                <Grid container spacing={3} alignItems="center" justifyContent="right">
                                                    <Grid item xs="auto">
                                                        <Typography variant="subtitle2">To be QC sampled:&nbsp;
                                                            <span className={classes.totalRecord}>{qcData.totalRecordsToBeSampled}</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="subtitle2">Assigned Sampling:&nbsp;
                                                            <span className={classes.totalRecord}>{qcData.totalAssigned}</span>
                                                        </Typography>
                                                    </Grid>
                                                </Grid> :
                                                <Grid container spacing={3} alignItems="center" justifyContent="right">
                                                    <Grid item xs="auto">
                                                        <Typography variant="subtitle2">To be QC sampled:&nbsp;
                                                            <span className={classes.totalRecord}>{customData.totalRecordsToBeSampled}</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="subtitle2">Assigned Sampling:&nbsp;
                                                            <span className={classes.totalRecord}>{customData.totalAssigned}</span>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>}
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems="center" justifyContent="left" spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl sx={{ ml: 2 }}>
                                                <RadioGroup row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group">
                                                    <FormControlLabel value={"Distribute Equally"}
                                                        control={<Radio value={"Distribute Equally"} checked={assignToQc}
                                                            onChange={(e) => { setAssignToQc(true) }}
                                                        />}
                                                        label="Distribute Equally"
                                                    />
                                                    <FormControlLabel value={"Custom Assign"}
                                                        control={<Radio value={"Custom Assign"} checked={!assignToQc}
                                                            onChange={(e) => { setAssignToQc(false) }}
                                                        />}
                                                        label="Custom Assign"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Grid container spacing={3} alignItems="center" justifyContent="right">
                                                {assignToQc ? '' : (
                                                    <Grid item xs="auto">
                                                        <Button sx={{ mr: 2 }}
                                                            variant="contained"
                                                            color="accent7"
                                                            size="small"
                                                            disabled={assignInput}
                                                            onClick={() => {
                                                                recalculateClick()
                                                            }}
                                                        >
                                                            Recalculate
                                                        </Button>
                                                    </Grid>
                                                )}

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {assignToQc ?
                                    (
                                        <Grid item xs={12}>

                                            <Box>
                                                <TableContainer sx={{ maxHeight: 440 }}>
                                                    <Table stickyHeader aria-label="sticky table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className={classes.tableHeader} align="left"
                                                                    style={{ width: "200px", whiteSpace: "nowrap" }}>
                                                                    Analyst Name
                                                                </TableCell>
                                                                <TableCell className={classes.tableHeader} align="center"
                                                                    style={{ width: "120px", whiteSpace: "nowrap" }}>
                                                                    Submitted QC
                                                                </TableCell>
                                                                <TableCell className={classes.tableHeader} align="center"
                                                                    style={{ width: "120px", whiteSpace: "nowrap" }}>
                                                                    To be sampled
                                                                </TableCell>
                                                                {qcData?.totalCODto?.getMeritQCUsersCODto && qcData?.totalCODto?.getMeritQCUsersCODto.map((user, i) => {
                                                                    return (
                                                                        <TableCell className={classes.tableHeader} align="center"
                                                                            style={{ width: "180px", whiteSpace: "nowrap" }}>
                                                                            {user.meritQCUserName}
                                                                        </TableCell>)
                                                                })}


                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>

                                                            {qcData?.getQCSamplingAnalystCODto && qcData?.getQCSamplingAnalystCODto.map((analyst, i) => {
                                                                return (
                                                                    <TableRow align="left">
                                                                        <TableCell className={classes.tableCell}>
                                                                            {analyst.analystName}
                                                                        </TableCell>
                                                                        <TableCell align="center" className={classes.tableCell}>
                                                                            {analyst.submittedQC}
                                                                        </TableCell>
                                                                        <TableCell align="center" className={classes.tableCell}>
                                                                            {analyst.toBeSampled}
                                                                        </TableCell>
                                                                        {analyst?.getMeritQCUsersCODto && analyst?.getMeritQCUsersCODto.map((user, i) => {
                                                                            return (
                                                                                <TableCell align="center" className={classes.tableCell}
                                                                                    style={{ width: "180px", whiteSpace: "nowrap" }}>
                                                                                    {user.toBeSampled}
                                                                                </TableCell>)
                                                                        })}

                                                                    </TableRow>)
                                                            })}

                                                        </TableBody>
                                                        <TableFooter>
                                                            <TableRow>

                                                                <TableCell align="left" className={classes.tableFooterCell} style={{ fontWeight: "bold" }}>
                                                                    {qcData?.totalCODto?.total}
                                                                </TableCell>
                                                                <TableCell align="center" className={classes.tableFooterCell} style={{ fontWeight: "bold" }}>
                                                                    {qcData?.totalCODto?.totalSubmitted}
                                                                </TableCell>
                                                                <TableCell align="center" className={classes.tableFooterCell} style={{ fontWeight: "bold" }}>
                                                                    {qcData?.totalCODto?.totalToBeSampled}
                                                                </TableCell>
                                                                {qcData?.totalCODto?.getMeritQCUsersCODto && qcData?.totalCODto?.getMeritQCUsersCODto.map((user, i) => {
                                                                    return (
                                                                        <TableCell className={classes.tableFooterCell} align="center"
                                                                            style={{ width: "180px", whiteSpace: "nowrap" }}>
                                                                            {user.toBeSampled}
                                                                        </TableCell>)
                                                                })}
                                                            </TableRow>
                                                        </TableFooter>
                                                    </Table>
                                                </TableContainer>
                                                <Grid container xs={12} sm={12} mt={2} mb={2} justifyContent="right" pr={2}>
                                                    {/* <Button sx={{ mr: 2 }} variant="contained" 
                                                    color="accent5" onClick={()=>{
                                                        saveDistributeEquallySampling()
                                                    }}>
                                                Save
                                            </Button> */}
                                                    <LoadingButton
                                                        buttonName="Save"
                                                        clickHandler={saveDistributeEquallySampling}
                                                        isLoading={loading}
                                                    />
                                                </Grid>
                                            </Box>

                                        </Grid>
                                    )
                                    : (<Grid item xs={12}>
                                        <Box className={classes.setBorder}>
                                            <TableContainer sx={{ maxHeight: 440 }}>
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.tableHeader} align="left"
                                                                style={{ width: "200px", whiteSpace: "nowrap" }}>
                                                                Analyst Name
                                                            </TableCell>
                                                            <TableCell className={classes.tableHeader} align="center"
                                                                style={{ width: "120px", whiteSpace: "nowrap" }}>
                                                                Submitted QC
                                                            </TableCell>
                                                            <TableCell className={classes.tableHeader} align="center"
                                                                style={{ width: "120px", whiteSpace: "nowrap" }}>
                                                                To be sampled
                                                            </TableCell>
                                                            {customData?.totalCODto?.getMeritQCUsersCODto && customData?.totalCODto?.getMeritQCUsersCODto.map((user, i) => {
                                                                return (
                                                                    <TableCell className={classes.tableHeader} align="center"
                                                                        style={{ width: "180px", whiteSpace: "nowrap" }}>
                                                                        {user.meritQCUserName}
                                                                    </TableCell>)
                                                            })}

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {customData?.getQCSamplingAnalystCODto && customData?.getQCSamplingAnalystCODto.map((analyst, analystIndex) => {
                                                            return (
                                                                <TableRow align="left">
                                                                    <TableCell className={classes.tableCell}>
                                                                        {analyst.analystName}
                                                                    </TableCell>
                                                                    <TableCell align="center" className={classes.tableCell}>
                                                                        {analyst.submittedQC}
                                                                    </TableCell>
                                                                    <TableCell align="center" className={classes.tableCell}>

                                                                        <TextField variant="standard" fullWidth label="Allocate" type="number" style={{ width: "80px" }}
                                                                            value={analyst.toBeSampled}
                                                                            onChange={(e) => {
                                                                                var value = parseInt(e.target.value);
                                                                                let maxVal = parseInt(analyst.submittedQC || 0);
                                                                                if (value > maxVal) value = maxVal;
                                                                                if (value < 0) value = 0;

                                                                                customData.getQCSamplingAnalystCODto[analystIndex].toBeSampled = value;
                                                                                setCustomData({ ...customData });
                                                                                reCalculate(true)
                                                                            }
                                                                            }

                                                                        />
                                                                    </TableCell>
                                                                    {analyst?.getMeritQCUsersCODto && analyst?.getMeritQCUsersCODto.map((user, userIndex) => {
                                                                        return (
                                                                            <TableCell align="center" className={classes.tableCell}
                                                                                style={{ width: "180px", whiteSpace: "nowrap" }}>

                                                                                <TextField variant="standard" fullWidth label="Assign" type="number"
                                                                                    style={{ width: "80px" }}
                                                                                    value={user.toBeSampled}
                                                                                    disabled={assignInput}
                                                                                    onChange={(e) => {
                                                                                        //let custData = ({...customData});
                                                                                        var assignValue = parseInt(e.target.value);
                                                                                        let maxVal = parseInt(analyst.submittedQC || 0);
                                                                                        if (assignValue > maxVal) assignValue = maxVal;
                                                                                        if (assignValue < 0) assignValue = 0;
                                                                                        customData.getQCSamplingAnalystCODto[analystIndex].getMeritQCUsersCODto[userIndex].toBeSampled = assignValue;
                                                                                        setCustomData({ ...customData });
                                                                                        reCalculate(false, true)
                                                                                    }}
                                                                                />

                                                                            </TableCell>)
                                                                    })}

                                                                </TableRow>)
                                                        })}

                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>

                                                            <TableCell align="left" className={classes.tableFooterCell} style={{ fontWeight: "bold" }}>
                                                                {customData?.totalCODto?.total}
                                                            </TableCell>
                                                            <TableCell align="center" className={classes.tableFooterCell} style={{ fontWeight: "bold" }}>

                                                                {customData?.totalCODto?.totalSubmitted}
                                                            </TableCell>
                                                            <TableCell align="center" className={classes.tableFooterCell} style={{ fontWeight: "bold" }}>
                                                                <div style={{ width: "60px", whiteSpace: "nowrap" }}>
                                                                    {customData?.totalCODto?.totalToBeSampled}
                                                                </div>
                                                            </TableCell>
                                                            {customData?.totalCODto?.getMeritQCUsersCODto && customData?.totalCODto?.getMeritQCUsersCODto.map((user, i) => {
                                                                return (
                                                                    <TableCell className={classes.tableFooterCell} align="center" >
                                                                        <div style={{ width: "80px", whiteSpace: "nowrap", paddingLeft: "25%" }}>
                                                                            {user.toBeSampled}
                                                                        </div>

                                                                    </TableCell>)
                                                            })}

                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                            <Grid container xs={12} sm={12} mt={2} mb={2} justifyContent="right" pr={2}>
                                                {/* <Button sx={{ mr: 2 }} variant="contained" 
                                            color="accent5" onClick={()=>{
                                                saveCustomAssignSampling()
                                            }}>
                                                Save
                                            </Button> */}
                                                <LoadingButton
                                                    buttonName="Save"
                                                    clickHandler={saveCustomAssignSampling}
                                                    isLoading={loading}
                                                />
                                            </Grid>
                                        </Box>
                                    </Grid>)}
                            </Box>

                            : null}


                    </BaseLayout>
                </Grid>

            </Grid>

            <AlertDialog
                title={"Confirmation"}
                maxWidth={"sm"}
                id="alert-delete"
                open={openAlertPopup}
                onClose={handleADClose}
                description={
                    beyondSamplingPercentageMsg ? 'Sampling % is beyond 50, Do you want to Proceed?' :
                        'Sampling % is is below 50, Do you want to Proceed?'
                }
                action={
                    <Grid container spacing={2} justifyContent="right">
                        <Grid item xs={'auto'}>
                            <Button onClick={handleADClose}>No</Button>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Button color="secondary" onClick={() => { saveRecalculatedSamplingData() }}>
                                Yes
                            </Button>
                        </Grid>
                    </Grid>
                }
            />

            {/* <AlertDialog
        id="alert-delete"
        open={openTobeSampledCalAlertPopup}
        onClose={handleADClose}
        description="To be sampled allocate count is not matching with distrubter total count number"
        action={
          <Grid container spacing={2} justifyContent="right">
            <Grid item xs={'auto'}>
              <Button onClick={handleTobeSampledCalAlertClose}>No</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button color="secondary">
                Yes
              </Button>
            </Grid>
          </Grid>
        }
      /> */}

        </Box>
    );
};

export default QualityManagementDrillDown;
