// Default React, Router and Services Components
import React, { useState } from "react";
import axios from 'axios';
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError } from "../../../UIComponent/Toaster";
// Importing Custom Components
import SideDrawer from "../../../UIComponent/Drawer/index";
import CreatePublicationDetails from "./CreatePublicationDetails";

// Importing useStyles
import useStyles from "./ReportingCalendar.styles";

const CreatePublicationDetailsSideDrawer = (props) => {
    // Declaration of States and Component Variables Start Here
    const [crudState, setCrudState] = React.useState(false);
    const [state, setState] = React.useState({ right: false });
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sorting, setSorting] = useState({ orderBy: "", order: false });
    const [companyDeatils, setCompanyDeatis] = React.useState([])
    const [companyHeader, setCompanyHeader] = React.useState([])
    const [totalCount, setTotalCount] = React.useState(0)
    let [filterValue, setFilterValue] = React.useState({
        "Company": [], "YearId": [], "ReportingPeriodId": [], "Q1": null, "Q2": null, "Q3": null, "Q4": null,
        "Annual": null, "FromDate": "", "ToDate": "", "SearchText": ""
    });
    const [calenderID, setCalenderID] = useState(null);
    // Declaration of States and Component Variables End Here

    // Declaration of React Hooks Start Here
    React.useEffect(() => {
        if (props.crudModeForCreatePublication) { createItem(); }
        else { setState({ right: false }); props.setCrudModeForCreatePublication(false) }
    }, [props.crudModeForCreatePublication])
    // Declaration of React Hooks End Here

    // This function toggles the drawer open or closed
    const toggleDrawer = (open) => { 
        // Set the state of the right drawer to the value of the open parameter
        setState({ ...state, right: open }); 
        // Set the crud state to true
        setCrudState(true); 
    };
    
    // Function to create an item
    const createItem = () => { 
        // Set the calender ID to null
        setCalenderID(null); 
        // Toggle the drawer to true
        toggleDrawer(true); 
    }

    // This function is used to get the reporting calendar details
        const GetReportingCalendar = () => {
            // Declare a variable to store filter value
            var SendFilterValue;
            // Check if all the filter values are empty
            if (filterValue.Company.length === 0 && filterValue.YearId.length === 0 && filterValue.ReportingPeriodId.length === 0 && filterValue.Q1 === null && filterValue.Q2 === null && filterValue.Q3 === null && filterValue.Q4 === null && filterValue.Annual === null && filterValue.FromDate === "" && filterValue.ToDate === "" && filterValue.SearchText === "") {
                // Set the filter value to empty string
                SendFilterValue = ""
            } else {
                // Set the filter value to the given filter value
                SendFilterValue = filterValue
            }

            // Make an API call to get the reporting calendar details
            axios.get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingCalendar", {
                headers: {
                    // Set the sorting column
                    sortColumn: sorting.orderBy,
                    // Set the source filter
                    sourceFilter: encodeURIComponent(JSON.stringify(SendFilterValue)),
                    // Set the order of sorting
                    isAscending: sorting.order,
                    // Set the authorization token
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
            
                },
                params: {
                    // Set the page number
                    pageNumber: page + 1,
                    // Set the page size
                    pagesize: rowsPerPage,
                }
            }).then(function (response) {
                // Set the total count
                setTotalCount(response.data.totalCount);
                // Store the response data
                var responseData = response.data.data
            
                // Check if the response data is not empty
                if (responseData.length) {
                    // Store the header of the response data
                    let reqHeader = responseData[0].publicationDetails.map(el => el.publication)
                    
                    // Map the response data
                    let bb = responseData.map(el => {
                        const publication = el.publicationDetails.map(e => {
                            return { [e.publication]: e }
                        });
                        return {
                            ...el, ...Object.assign({}, ...publication)
                        };
                    });
                
                    // Set the company details
                    setCompanyDeatis([...bb]);
                    // Set the company header
                    setCompanyHeader(reqHeader)
                } else {
                    // Set the company details to empty array
                    setCompanyDeatis([])
                }
            })
                .catch(function (error) { ErroHandling(error, function (res) {
                    // Call the toaster error function
                    toasterError(res)}) });
        }
 

    return (
        <SideDrawer
            drawerWidth="md"
            formComp={
                <CreatePublicationDetails
                    state={state}
                    setState={setState}
                    calenderID={calenderID}
                    recallApi={GetReportingCalendar}
                    hideSideDrawer={() => {
                        props.setCrudModeForCreatePublication(false)
                        setState({ right: false });
                    }}
                    closeCreatePublicationDrawer={() => {
                        props.setCrudModeForCreatePublication(false)
                        setState({ right: false });
                    }}
                />
            }
            state={state}
            setState={setState}
        />
    );
}

export default CreatePublicationDetailsSideDrawer;