// Default React, useState and Material Components
import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddEditChangeType = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [changeType, setStockExchange] = useState({
    id: 0,
    changeType: "",
    description: "",
    isActive: true,
  });
  const [addStockExchangeDrawer, setAddStockExchangeDrawer] = useState(props.addStockExchangeDrawer);
  // Declaration of States and Component Variables End Here

  React.useEffect(() => {
    if (props.crudState === "Edit" || props.crudState === "View") {
      let data = {
        id: props.formData.id,
        changeType: props.formData.changeType,
        description: props.formData.description,
        active: props.formData.active,
        createdOn: props.formData.createdOn,
      };
      setStockExchange(data);
    }
  }, [props]);

  // Function for Validation
  const validation = (change) => {
    if (change.changeType.trim() == "") {
      toasterError("Please enter change type");
      return false;
    }
    if (change.changeType.length > 100) {
      toasterError("Please enter Change Type less than 100 characters");
      return false;
    }
    if (change.description.length > 500) {
      toasterError("Please enter Description less than 500 characters");
      return false;
    }
    return true;
  }

  // Function for Save Stock Exchange
  const saveChangeType = () => {
    setLoading(true);
    if (validation(changeType)) {
      ApiService.create("managedata/SaveChangeType", changeType)
        .then((res) => {
          if (res.status === 200) {
            toasterSuccess("Change Type added successfully!");
            if (addStockExchangeDrawer) {
              setLoading(false);
              if (props.hideSideDrawer != null) {
                props.hideSideDrawer();
              }
            } else {
              setLoading(false);
              if (props.closeStockExchange != null) {
                props.closeStockExchange();
              }
              if (props.hideSideDrawer != null) {
                props.hideSideDrawer();
              }
            }
          }
        })
        .catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res)
          }
          )
          setLoading(false);
          if (addStockExchangeDrawer) {
            if (props.hideSideDrawer != null) {
              props.hideSideDrawer();
            }
          } else {
            setLoading(false);
            if (props.closeStockExchange != null) {
              props.closeStockExchange();
            }
          }
        });
    } else {
      setLoading(false);
    }
  }

  // Function for Update Stock Exchange
  const updateChangeType = () => {
    setLoading(true);
    let validData = changeType;
    validData.id = props.formData.id;
    if (validation(changeType)) {
      ApiService.updatepatch("managedata/UpdateChangeType","", validData)
        .then((res) => {
          if (res.status === 200) {
            toasterSuccess("Change Type details updated successfully!");
            setLoading(false);
            props.hideSideDrawer();
          }
        })
        .catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res)
          })
          setLoading(false);
        });
    } else { setLoading(false); }
  }

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Change Type
        </Typography>
      </Box>

      {/* Add & Edit Top Producers Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            InputProps={{
              readOnly: props.crudState === "View" && true,
            }}
            required
            fullWidth
            multiline
            maxRows={4}
            id="standard-basic"
            name="changetype"
            size="small"
            label="Change Type"
            placeholder="Change Type"
            variant="standard"
            inputProps={{ maxLength: 100 }}
            value={props.crudState === "Add" ? null : props.formData.changeType}
            onChange={(e) => {
              props.setFormData({ ...props.formData, changeType: e.target.value });
              setStockExchange({ ...changeType, changeType: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            fullWidth
            multiline
            maxRows={4}
            id="standard-basic"
            name="description"
            size="small"
            label="Description"
            placeholder="Description"
            variant="standard"
            inputProps={{ maxLength: 500 }}
            value={props.crudState === "Add" ? null : props.formData.description}
            onChange={(e) => {
              props.setFormData({ ...props.formData, description: e.target.value });
              setStockExchange({ ...changeType, description: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>

        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={12} sm={'auto'}>
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Add" ? saveChangeType : updateChangeType}
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <Button
                  variant="contained"
                  className="rounded-border"
                  color="accent3"
                  onClick={() => {
                    if (addStockExchangeDrawer) {
                      props.setState({ ...props.state, right: false })
                    } else {
                      setAddStockExchangeDrawer(true);
                      if (props.closeStockExchange != null) { props.closeStockExchange(); }
                    }
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>

      </Grid>
    </Box>
  )
}

export default AddEditChangeType;