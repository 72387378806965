// Default React, Router and Services Components
import React, { useRef } from "react";
import { Autocomplete, Box, Button, Chip, Grid, IconButton, TextField } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

// Importing Custom Components
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster/index";
import ErroHandling from "../../../Services/ErrorHandling";
// Importing useStyles
import "../../../../src/index.scss"
import "../../../Resources/scss/layout.scss";
import "react-toastify/dist/ReactToastify.css";

// Import Material Icons
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Import Fluent UI Icons
import { ErrorBadgeIcon, CircleAdditionIcon } from "@fluentui/react-icons-mdl2";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SourceChange = (props) => {
  const [disableAsset, setDisableAsset] = React.useState(true);
  const [formData, setFormData] = React.useState({
    // Declaration of States and Component Variables Start Here
    editCompanyId: 0, editAssetId: 0, brandNewSource: true, isCRUCompany: false, companyId: 0, companyName: "",
    remarks: "", statusId: 1, isCruAsset: true, assetId: 0, assetName: null, assetTypeId: 0, regionId: 0,
    countryId: 0, subRegionId: 0, isJiraCreated: false, commoditiesID: [], referenceLinks: [], regionName: "",
    subRegionName: "", countryName: "", statusName: "Pending",
  });
  const [changeData, setChangeData] = React.useState({});
  const [newEntry, setNewEntry] = React.useState(false);
  const [isNewAsset, setIsNewAsset] = React.useState(false);
  const [companyId, setCompanyId] = React.useState(null);
  const [buttonStatus, setButtonStatus] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [subRegionDropDown, setSubRegionDropDown] = React.useState([]);
  const [sourceChangeComp, setSourceChangeComp] = React.useState([]);
  const [jiraforNewSource, setJiraforNewSource] = React.useState(false);
  const [jiraforchangedSource, setJiraforchangedSource] = React.useState(false);
  const [isCompanyChange, setIsCompanyChange] = React.useState(false);
  const [allCompanies, setAllCompanies] = React.useState([]);
  const [assetsBasedonCompany, setAssetsBasedOnCompany] = React.useState([]);
  const [currentAssetType, setCurrentAssetType] = React.useState("");
  const [currentRegion, setCurrentRegion] = React.useState("");
  const [currentSubRegion, setCurrentSubRegion] = React.useState("");
  const [currentCountry, setCurrentCountry] = React.useState("");
  const [currentCommodity, setCurrentCommodity] = React.useState([]);
  const [jiraticketState, setJiraTicketState] = React.useState({ id: 0, isCompanyChange: true, });
  const [editData, setEditData] = React.useState(true);
  const [editCompId, setEditCompId] = React.useState(null);
  const [editedAssetId, setEditedAssetId] = React.useState(null);
  const [testId, setTestId] = React.useState(null);
  const [assetsId, setAssetId] = React.useState(null);
  const [tempCompanyName, setTempCompanyName] = React.useState("");
  const [tempAssetName, setTempAssetName] = React.useState("");
  const [forCompany, setForCompany] = React.useState(true);
  const [companyIdForAsset, setCompanyIdForAssets] = React.useState(null);
  const [isCRUforAsset, setIsCruForAssets] = React.useState(false);
  const [companyNameforAssets, setCompanyNameForAssets] = React.useState("");
  const [sourceForAssets, setSourceForAssets] = React.useState(false);
  const [triggerAsset, setTriggerAsset] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    getAllCompanies();
    axios.get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
      headers: { isNewSource: false,'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
    })
      .then(function (response) { setSourceChangeComp(response.data); })
      .catch(function (error) { ErroHandling(error, function (res) {
        toasterError(res)}) });
    setFormData(newFormData);
    setChangeData(sourceChangeData);
    setChangeData({ ...changeData, referenceLinks: [""], });
  }, []);
  // Declaration of React Hooks End Here

  // Function for Getting Company Data
  const getCOmpaniesForEditing = (id) => {
    if (newEntry) {
      axios.get(`process.env.React_App_baseUrl +source/GetCompaniesDetail?CompanyId=${id}`,{
        headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
        .then((res) => {
          setEditCompId(res.data.editCompanyId);
          setEditedAssetId(res.data.editAssetId);
          setTestId(res.data.companyId);
          setAssetId(res.data.assetId);
        })
        .catch((err) => ErroHandling(err, function (res) {
          toasterError(res)}) );
    } 
    // else {
    //   axios.get(process.env.React_App_baseUrl + "source/GetAssetById", {
    //     headers: { AssetId: id, },
    //     'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        
    //   })
    //     .then((res) => {
    //       finalData.companyName = null;
    //       setEditCompId(res.data.editCompanyId);
    //       setEditedAssetId(res.data.editAssetId);
    //       setTestId(res.data.companyId);
    //       setAssetId(res.data.assetId);
    //     })
    //     .catch((err) => ErroHandling(err, function (res) {
    //       toasterError(res)}) );
    // }
  };

  // Function for Source change Submission
  const sendChangedData = () => {

    if (forCompany) {
      if (changedFinalData.remarks !== undefined && changedFinalData.companyName !== undefined &&
        changedFinalData.referencelink.length > 0
      ) {
        let validated = false;
        function validURL(str) {
          var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
          ); // fragment locator
          return !!pattern.test(str);
        }
        changedFinalData.referencelink.map((link) => {
          if (!validURL(link)) { validated = true; }
        });
        if (!validated) {
          axios.post(process.env.React_App_baseUrl + "ownershipsource/SaveCompanyOwnershipSource", changedFinalData,{
            headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
          })
            .then((res) => {
              props.setRefresh(!props.refresh);
              toasterSuccess("Company name changed successfully");
              // getCOmpaniesForEditing(res.data.id);
              setJiraTicketState({ ...jiraticketState, id: res.data.id, isCompanyChange: res.data.isCompanyChange, });
              setEditData(false);
              setJiraforchangedSource(true);
            })
            .catch((err) => { ErroHandling(err, function (res) {
              toasterError(res)})  });
        } else {
          toasterError("Please enter a valid link");
        }
      } else {
        toasterError("Please check the required fields for changing a company");
      }
    } else {
      if (changedFinalData.assetName !== undefined && changedFinalData.referencelink.length > 0 &&
        changedFinalData.remarks !== undefined
      ) {
        let validated = false;
        function validURL(str) {
          var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
          ); // fragment locator
          return !!pattern.test(str);
        }
        changedFinalData.referencelink.map((link) => {
          if (!validURL(link)) { validated = true; }
        });
        if (!validated) {
          axios.post(process.env.React_App_baseUrl + "ownershipsource/SaveCompanyOwnershipSource", changedFinalData,{
            headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
          })
            .then((res) => {
              props.setRefresh(!props.refresh);
              toasterSuccess("Asset name changed successfully");
              getCOmpaniesForEditing(res.data.id);
              setJiraTicketState({ ...jiraticketState, id: res.data.id, isCompanyChange: res.data.isCompanyChange, });
              setEditData(false);
              setJiraforchangedSource(true);
            })
            .catch((err) => {ErroHandling(err, function (res) {
              toasterError(res)}) });
        } else {
          toasterError("Please enter a valid link");
        }
      } else {
        toasterError("Please check the required fields to edit the asset");
      }
    }
  };

  //This function is used to edit the change form data
const editedChangeFormData = () => {
  //Assigning the values to changedFinalData object
  changedFinalData.editCompanyId = editCompId;
  changedFinalData.editAssetId = editedAssetId;
  changedFinalData.companyId = testId;
  changedFinalData.assetId = assetsId;
  //Making a post request to save the company ownership source
  axios.post(process.env.React_App_baseUrl + "ownershipsource/SaveCompanyOwnershipSource", changedFinalData,{
    headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
  })
    .then(function (response) {
      //Setting the refresh state to true
      props.setRefresh(!props.refresh);
      //Updating the jiraTicketState with response data
      setJiraTicketState({
        ...jiraticketState, id: response.data.id, isCompanyChange: response.data.isCompanyChange,
      });
      //Setting the jiraforNewSource to true
      setJiraforNewSource(true);
      //Setting the success message
      setSuccessMessage("Source Created success");
      //Setting the button status to true
      setButtonStatus(true);
    })
    .catch(function (error) { 
      //Handling the error and displaying the error message
      ErroHandling(error, function (res) {
      toasterError(res)}) 
      //Setting the button status to true
      setButtonStatus(true); 
    });
};
 

  ////initial Date for new source

  const newFormData = {
    editCompanyId: 0, editAssetId: 0, brandNewSource: true, isCRUCompany: false, companyId: 0, companyName: "",
    remarks: "", statusId: 1, isCruAsset: true, assetId: 0, assetName: null, assetTypeId: 0, regionId: 0, countryId: 0,
    subRegionId: 0, isJiraCreated: false, commoditiesID: [], referenceLinks: [""], regionName: "", subRegionName: "",
    countryName: "", statusName: "Pending",
  };

  //initial Data for Source Change
  const sourceChangeData = {
    editCompanyId: 0, editAssetId: 0, brandNewSource: true, isCRUCompany: false, companyId: 0, companyName: "",
    remarks: "", statusId: 1, isCruAsset: false, assetId: 0, assetName: "", assetTypeId: 0, regionId: 0,
    countryId: 0, subRegionId: 0, isJiraCreated: false, commoditiesID: null, referenceLinks: [""],
  };

  ////final data state new sourceAaaa

  const finalData = {
    editCompanyId: 0, editAssetId: 0, isNewSource: newFormData.brandNewSource, isCRUCompany: formData.isCRUCompany,
    companyId: formData.companyId, companyName: formData.companyName, remarks: formData.remarks,
    statusId: formData.statusId, isCRUAsset: formData.isCruAsset, assetId: formData.assetId, assetName: formData.assetName,
    assetTypeId: formData.assetTypeId, countryId: formData.countryId, regionId: formData.regionId,
    subRegionId: formData.subRegionId, isJiraCreated: formData.isJiraCreated, commoditiesId: formData.commoditiesID,
    referencelink: formData.referenceLinks,
  };

  ///final data stateb for change state

  const changedFinalData = {
    editCompanyId: companyIdForAsset,
    editAssetId: 0,
    isNewSource: changeData.brandNewSource,
    isCRUCompany: changeData.isCRUCompany,
    companyId: changeData.companyId,
    companyName: changeData.companyName,
    remarks: changeData.remarks,
    statusId: changeData.statusId,
    isCRUAsset: changeData.isCruAsset,
    assetId: changeData.assetId,
    assetName: changeData.assetName,
    assetTypeId: changeData.assetTypeId,
    countryId: changeData.countryId,
    regionId: changeData.regionId,
    subRegionId: changeData.subRegionId,
    isJiraCreated: changeData.isJiraCreated,
    commoditiesId: changeData.commoditiesID,
    referencelink: changeData.referenceLinks,
  };

  // Function for create Jira Ticket
  const createJiraTicket = () => {
    axios.post(process.env.React_App_baseUrl + "jira/CreateJiraTicket", {
      id: jiraticketState.id, isCompanyChange: jiraticketState.isCompanyChange,
    },{
      headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
    })
      .then((res) => { 
        toasterSuccess("Jira ticket created!");
        props.setState({ ...props.state, right: false }) },
        (err) => { toasterError(`${err.response.data}`) }
      );
  };

  // Function for Getting COmpanies
  const getAllCompanies = () => {
    axios.get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
      headers: { isNewSource: false,'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
     },
    })
      .then(function (response) { setAllCompanies(response.data); })
      .catch(function (error) {ErroHandling(error, function (res) {
        toasterError(res)})  });
  };

  //////get all assets for the listed  compnaies

  // Function for Getting Companies
  const getAllAssets = (companyId, isCruCompany, companyName, isNewSource) => {
    axios
      .get(process.env.React_App_baseUrl + "ownershipsource/GetAssetsCO?pageNumber=1&pageSize=1000", {
        headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null,        
          companyId: companyId, isCRUCompany: isCruCompany, companyName: companyName, isNewSource: isNewSource,
        },
      })
      .then(function (response) { setAssetsBasedOnCompany(response.data.data); setTriggerAsset(false) })
      .catch(function (error) {ErroHandling(error, function (res) {
        })  });
  };

  // Function for Getting Companies Details
  const handleAddLink = (e) => {
    const links = [...changeData?.referenceLinks];
    links.push("");
    let item = { ...changeData, referenceLinks: links };
    setChangeData(item);
  };

  const handleRemoveLink = (e, index) => {
    const links = [...changeData?.referenceLinks];
    links.splice(index, 1);
    let item = { ...changeData, referenceLinks: links };
    setChangeData(item);
  };

  const handleChange = (e, i) => {
    const links = [...changeData?.referenceLinks];
    links[i] = e.target.value;
    let item = { ...changeData, referenceLinks: links };
    setChangeData(item);
  };

  React.useEffect(() => {
    if(triggerAsset)
    getAllAssets(companyIdForAsset, isCRUforAsset, companyNameforAssets, sourceForAssets);
  }, [triggerAsset]);

  return (
    <div>
      <Autocomplete
        className="field-gap"
        id="size-small-standard size-small-standard"
        size="small"
        value={changeData.companyName}
        options={allCompanies}
        getOptionLabel={(option) => option.companyName}
        onChange={(e, value) => {
          setCompanyId(value.companyId);
          setChangeData({
            ...changeData,
            brandNewSource: false,
            companyId: value.companyId,
            isCRUCompany: value.isCRUCompany,
            assetId: 0,
            assetTypeId: 0,
            countryId: 0,
            regionId: 0,
            subRegionId: 0,
            commoditiesId: null,
            statusId: 1,
          });
          setCompanyIdForAssets(value.companyId);
          setIsCruForAssets(value.isCRUCompany);
          setCompanyNameForAssets(value.companyName);
          setSourceForAssets(value.isNewSource);
          setTriggerAsset(!triggerAsset);
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.companyName}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            required
            {...params}
            variant="standard"
            label="Company Name"
            placeholder="Company Name"
          />
        )}
      />
      <TextField
        required={disableAsset}
        disabled={tempAssetName.length > 0}
        fullWidth
        className="field-gap"
        size="small"
        id="size-small-standard size-small-standard"
        label="New Company Name"
        variant="standard"
        onChange={(e) => {
          let name = e.target.value;

          setChangeData({
            ...changeData,
            companyName: name,
          });
          setTempCompanyName(e.target.value);

          setNewEntry(true);
        }}
      />
      <Autocomplete
        freeSolo
        clearOnEscape
        disabled={tempCompanyName.length > 0}
        className="field-gap"
        size="small"
        id="size-small-standard size-small-standard"
        options={assetsBasedonCompany}
        getOptionLabel={(option) => option.asset}
        onChange={(e, value) => {
          setDisableAsset(false);
          setChangeData({
            ...changeData,
            assetId: value.assetId,
            assetTypeId: value.assetTypeId,
            commoditiesID: [2],
            companyName: null,
            countryId: value.countryId,
            regionId: value.regionId,
            subRegionId: value.subRegionId,
          });
          setCurrentAssetType(value.assetType);
          setCurrentRegion(value.region);
          setCurrentSubRegion(value.subRegion);
          setCurrentCountry(value.country);
          let tempComm = value.commodity.split(",");
          setCurrentCommodity(tempComm);
          setNewEntry(false);
          setForCompany(false);
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.asset}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Asset Name"
            placeholder="Asset Name"
          />
        )}
      />

      <TextField
        disabled={disableAsset === true}
        required
        className="field-gap"
        id="size-small-standard size-small-standard"
        label="New Asset Name"
        variant="standard"
        onChange={(e) => {
          let asset = e.target.value;
          setTempAssetName(e.target.value);
          setChangeData({
            ...changeData,
            assetName: asset,
          });
        }}
        fullWidth
      />

      <TextField
        disabled
        className="field-gap"
        id="size-small-standard size-small-standard"
        label="Asset Type"
        variant="standard"
        value={currentAssetType}
        fullWidth
      />

      <TextField
        disabled
        className="field-gap"
        id="size-small-standard size-small-standard"
        label="Region"
        variant="standard"
        value={currentRegion}
        fullWidth
      />

      <TextField
        disabled
        className="field-gap"
        id="size-small-standard size-small-standard"
        label="Sub Region"
        variant="standard"
        value={currentSubRegion}
        fullWidth
      />

      <TextField
        disabled
        className="field-gap"
        id="size-small-standard size-small-standard"
        label="Country"
        variant="standard"
        value={currentCountry}
        fullWidth
      />

      <Autocomplete
        readOnly
        multiple
        fullWidth
        size="small"
        className="field-gap"
        id="size-small-standard size-small-standard"
        variant="standard"
        options={currentCommodity}
        getOptionLabel={(option) => option}
        value={currentCommodity}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="standard"
              className="field-gap"
              id="size-small-standard size-small-standard"
              label={option}
              size="Commoditites"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            className="field-gap"
            id="size-small-standard size-small-standard"
            label="Commodities"
            placeholder="Commodities"
            disabled
          />
        )}
      />

      <TextField
        sx={{ background: "#eeeeee" }}
        className="field-gap"
        id="size-small-standard size-small-standard"
        label="Status"
        variant="standard"
        value={"Pending"}
        disabled
        fullWidth
      />

      {changeData?.referenceLinks &&
        changeData?.referenceLinks.map((linki, i) => {
          return (
            <>
              <div className="d-flex field-gap">
                <div className="flex-grow-1">
                  <TextField
                    required
                    fullWidth
                    id="size-small-standard size-small-standard"
                    label="Links"
                    variant="standard"
                    multiline
                    onChange={(e) => handleChange(e, i)}
                    defaultValue={linki}
                  />
                </div>
                <div>
                  {i === 0 && (
                    <IconButton
                      aria-label="Add"
                      style={{ marginTop: 10, marginLeft: 10 }}
                      onClick={(e) => {
                        handleAddLink(e);
                      }}
                    >
                      <CircleAdditionIcon />
                    </IconButton>
                  )}
                  {i > 0 && (
                    <IconButton
                      aria-label="Add"
                      style={{ marginTop: 10, marginLeft: 10 }}
                      onClick={(e) => {
                        handleRemoveLink(e, i);
                      }}
                    >
                      <ErrorBadgeIcon />
                    </IconButton>
                  )}
                </div>
              </div>
            </>
          );
        })}

      <TextField
        required
        fullWidth
        className="field-gap"
        size="small"
        id="size-small-standard size-small-standard"
        label="Remarks"
        variant="standard"
        value={changeData.remarks}
        onChange={(e) =>
          setChangeData({ ...changeData, remarks: e.target.value })
        }
      />

      <div style={{ width: "100%", marginTop: "15px" }}>
        <Box component="span" fullWidth sx={{ pt: 2, pb: 1 }}>
          <Grid container spacing={2} justifyContent="flex-start" alignItems="self-end">
            <Grid item xs="auto">
              <LoadingButton buttonName="Save" isLoading={loading}
                clickHandler={editData ? sendChangedData : editedChangeFormData}
                disabled={buttonStatus === true} />
            </Grid>
            <Grid item xs="auto">
              <Button variant="contained" style={{ marginRight: "15px" }}
                className="rounded-border" color="accent3"
                onClick={() => props.setState({ ...props.state, right: false })}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={jiraforchangedSource === false}
                variant="contained"
                className="rounded-border"
                color="secondary"
                onClick={createJiraTicket}
              >
                Create jira Ticket
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default SourceChange;