// Default React, useState and Material Components
import React, { useState } from "react";
import { Button, Box, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import { toast } from "react-toastify";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import * as Validations from '../../../common/validation';
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddEditFrequency = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [RegionData, setRegionData] = useState({ regionName: props.regionData?props.regionData.regionName:'', regionId: props.regionData ? props.regionData.regionId : null, IsActive: true,'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null});
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    if (props.crudState === "Edit" || props.crudState === "View") {
      let data = { regionId: props.formData.regionId, regionName: props.formData.regionName };
      setRegionData({...RegionData,data});
    }
  }, [props]);

  // Function for Validations
  const validation = (formData) => {
    if (formData.regionName.trim() === "") { toasterError("Please enter region"); return false; }
    if (!Validations.onlyTextAndChar(formData.regionName)) { toasterError("Please enter valid region name"); return false; }
    return true;
  }

// This function is used to register a region
const registerRegion = () => {
  // Set loading to true while the request is being processed
  setLoading(true);
  // Validate the RegionData
  if (validation(RegionData)) {
    // Make an axios post request to save the region data
    axios.post(process.env.React_App_baseUrl + "managedata/SaveRegion", null, { 
      headers: RegionData 
  })
      .then((res) => {
        // If the response status is 200, show success message and hide the side drawer
        if (res.status === 200) {
          toasterSuccess("Region details added successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => { 
        // Handle errors
        ErroHandling(err, function (res) {
        toasterError(res)}) });
  }
};
 

// This function updates the region data
const updateRegion = () => {
  // Set loading to true while updating
  setLoading(true);
  // Validate the RegionData
  if (validation(RegionData)) {
    // Make a post request to the server
    axios.post(process.env.React_App_baseUrl + "managedata/UpdateRegion", null, {
      headers: RegionData,   
    })
      .then((res) => {
        // If the response status is 200, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("Region details edited successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => { 
        // Handle errors
        ErroHandling(err, function (res) {
        toasterError(res)}) 
       });
  }
};
  

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Region
        </Typography>
      </Box>

      {/* Add & Edit Frequency Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            required
            fullWidth
            id="standard-basic"
            name="regionName"
            size="small"
            label="Region"
            placeholder="Region"
            variant="standard"
            inputProps={{ maxLength: 100 }}
            value={props.crudState === "Add" ? null : props.formData.regionName}
            onChange={(e) => {
              props.setFormData({ ...props.formData, regionName: e.target.value });
              setRegionData({ ...RegionData, regionName: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>

        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={'auto'}>
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Add" ? registerRegion : updateRegion}
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant="contained" color="accent3" onClick={() => props.setState({ ...props.state, right: false })}>Cancel</Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddEditFrequency;