// Default React, Router and Services Components
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

// Importing Custom Components
import { Button,Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import MultiSelect from "../../../UIComponent/MultiSelect/MultiSelect";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError } from "../../../UIComponent/Toaster";
import {
  clearFilterJobRecords,
  GetAssetData,
  setFilterJobRecords,
} from "../../../features/filter/filterSlice";
import {
  callBasedOnLocation,
  callFromAssetType,
  callFromComm,
  callFromPriority,
  callSource,
} from "./../../../Util/TreeAPI";
import Container from "./TreeContainer";

// Importing useStyles
import useStyles from "./OwnershipChangesManagement.styles";
import "react-dropdown-tree-select/dist/styles.css";
import "../../../../src/index.scss";
import "../../../Resources/scss/layout.scss";



const FilterForm = (props) => {
  const classes = useStyles();
  const { filtersRecords, assetDropDownlist } = useSelector(
    (store) => store.filter
  );

  const dispatch = useDispatch();

  // Declaration of States and Component Variables Start Here
  const [allState, setAllState] = React.useState(props);
  const [status, setStatus] = React.useState([]);
  const [jobComms, setJobComms] = React.useState([]);
  const [prior, setPrior] = React.useState([]);
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  const [filters, setFilters] = React.useState({
    company: [],
    asset: [],
    assetTypeIds: [],
    countryIds: [],
    regionIds: [],
    subRegionIds: [],
    commodityIds: [],
    statusIds: [],
    priorityIds: [],
  });
  const [apiData, setApiData] = React.useState({
    companies: [],
    location: {},
    assetType: [],
    priority: [],
    commodity: [],
  });

  const [dependency, setDependency] = React.useState(null);
  const [subCompDependency, setSubCompDependency] = React.useState(false);
  const [subLocDependency, setSubLocDependency] = React.useState(false);

  // Declaration of States and Component Variables End Here

  const onChangeHandler = (key, value, reason) => {
    const pre = { ...filtersRecords };
    pre[key] = value;
    if (key === "commodity") {
        setDependency("commodity");
      
      setApiData({
        ...apiData,
        commodity: value,
      });
    } else if (key === "priority") {
        setDependency("priority");
      setApiData({
        ...apiData,
        priority: value,
      });
    } else if (key === "assetType") {
        setDependency("assetType");
      setApiData({
        ...apiData,
        assetType: value,
      });
    }
    dispatch(setFilterJobRecords({ filtersRecords: pre }));
    console.log("FFF",value)

  };


  //Function for clear data while click on clear button 
  const clearFieldHanlder = () => {
    setFilters({
      filters,
      company: [],
      asset: [],
      assetTypeIds: [],
      countryIds: [],
      regionIds: [],
      subRegionIds: [],
      commodityIds: [],
      statusIds: [],
      priorityIds: [],
    });

    props.resetTreeFilter();
    dispatch(clearFilterJobRecords());
    props.setFilterValue("");
    props.setReload(!props.reload);
    props.getFilterData();

    setDependency(null);
    setSubCompDependency(false);
    setSubLocDependency(false);
  };

  //Function for fetch all status
  const getAllStatus = () => {
    ApiService.getAll("DropDown/GetStatusDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setStatus(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

  //Function for fetch all commodities
  const getAllCommodities = () => {
    ApiService.getAll("DropDown/GetCommodityDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setJobComms(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

  //Function for fetch all Priority
  const getAllPriority = () => {
    ApiService.getAll("DropDown/GetPriorityDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setPrior(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

  // Declaration of React Hooks Start Here

  React.useEffect(() => {
    setAllState([...props.companyFilter]);
  }, [props]);

  React.useEffect(() => {
    getAllStatus();
    dispatch(GetAssetData());
    getAllCommodities();
    getAllPriority();
  }, []);

  React.useEffect(() => {
    if (dependency === "company") {
      callSource(apiData)
        .then((res) => {
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationFilter){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationFilter){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationFilter){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "location") {
      callBasedOnLocation(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
          setSubCompDependency(true);
        })
        .catch((err) => console.log(err));
    } else if (dependency === "assetType") {
      callFromAssetType(apiData)
        .then((res) => {
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationFilter){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationFilter){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationFilter){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "priority") {
      callFromPriority(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationFilter){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationFilter){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationFilter){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          props.setCompanyFilter(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "commodity") {
      callFromComm(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationFilter){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationFilter){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationFilter){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          props.setCompanyFilter(res.data.companies);
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [apiData]);

  // Declaration of React Hooks End Here

   // onChangeComp is a function that takes two parameters, currentNode and selectedNodes
const onChangeComp = (currentNode, selectedNodes) => {
    
  // setDependency sets the dependency to "company"
  setDependency("company");
  
  // setSelectedCompanies sets the selected companies to the selectedNodes parameter
  setSelectedCompanies(selectedNodes);

  // loop through the companyFilter props
  props.companyFilter.forEach((el) => {
    // if the currentNode is checked, check the value of the currentNode and its parentValue
    if (currentNode.checked === true) {
      if (currentNode.value === el.value) {
        el.checked = true;
      } else if (currentNode.parentValue === el.value) {
        // loop through the children of the parentValue
        el.children.forEach((item) => {
          if (currentNode.value === item.value) {
            item.checked = true;
          }
        });
      }
    // if the currentNode is unchecked, uncheck the value of the currentNode and its parentValue
    } else if (currentNode.checked === false) {
      if (currentNode.value === el.value) {
        el.checked = false;
      } else if (currentNode.parentValue === el.value) {
        // loop through the children of the parentValue
        el.children.forEach((item) => {
          if (currentNode.value === item.value) {
            item.checked = false;
          }
        });
      }
    }
  });

  // map the companyFilter props and filter out the elements with no children
  const firstParent = props.companyFilter
    .map((el) => {
      let data = {
        label: el.label,
        value: el.value,
        children: el.children.filter((item) => item["checked"] === true),
      };
      return data;
    })
    .filter((el) => el.children.length > 0);
 
  // set the apiData to either the filtered companyFilter props or the firstParent
  setApiData({
    ...apiData,
    companies:
      props.companyFilter.filter((el) => el["checked"] === true).length > 0
        ? props.companyFilter.filter((el) => el["checked"] === true)
        : firstParent,
  });
};

  // This function is used to set the dependency of location
const onChangeLoc = (currentNode, selectedNodes) => {
  setDependency("location");
// If no nodes are selected, set all checkboxes to false
if (selectedNodes.length === 0) {
  props.locationFilter.forEach((el) => {
    el["checked"] = false;
    el.children.forEach((it) => {
      it["checked"] = false;
      it.children.forEach((kt) => {
        kt["checked"] = false;
      });
    });
  });
}

// If current node is checked, set the corresponding checkbox to true
if (currentNode.checked === true) {
  if (currentNode.grantParentValue) {
    props.locationFilter.forEach((el) => {
      el.children.forEach((first) => {
        first.children.forEach((second) => {
          if (currentNode.value === second.value) {
            second.checked = true;
            filters.countryIds.push(currentNode.value)
          }
        });
      });
    });
  } else if (currentNode.parentValue) {
    props.locationFilter.forEach((el) => {
      el.children.forEach((first) => {
        if (currentNode.value === first.value) {
          first.checked = true;
        }
        
      });
    });
  } else if (currentNode.value) {
    props.locationFilter.forEach((el) => {
      if (currentNode.value === el.value) {
        el.checked = true;
      }
    });
  }
  
} 
// If current node is unchecked, set the corresponding checkbox to false
else if (currentNode.checked === false) {
  if (
    currentNode.value &&
    !currentNode.parentValue &&
    !currentNode.grantParentValue
  ) {
    props.locationFilter.forEach((el) => {
      if (el.value === currentNode.value) {
        el.checked = false;
      }
    });
  }
  if (
    currentNode.value &&
    currentNode.parentValue &&
    !currentNode.grantParentValue
  ) {
    props.locationFilter.forEach((el) => {
      el.children.forEach((first) => {
        if (currentNode.value === first.value) {
          first.checked = false;
        }
      });
    });
  } else {
    props.locationFilter.forEach((el) => {
      if (
        el.children.map((it) => it.parentValue)[0] === currentNode.value
      ) {
        el.children.forEach((item) => {
          if (item.parentValue === currentNode.value) {
            item.checked = false;
          }
        });
      }
    });
  }

  if (
    currentNode.value &&
    currentNode.parentValue &&
    currentNode.grantParentValue
  ) {
    props.locationFilter.forEach((el) => {
      el.children.forEach((first) => {
        first.children.forEach((second) => {
          if (currentNode.value === second.value) {
            second.checked = false;
          }
        });
      });
    });
  }
}

// Get the first parent and grandparent values
const firstParent = props.locationFilter
  .map((el) => {
    let data = {
      label: el.label,
      value: el.value,
      children: el.children.filter((item) => item["checked"] === true),
    };
    return data;
  })
  .filter((el) => el.children.length > 0);

const grandParent = props.locationFilter
  .map((el) => {
    let data = {
      label: el.label,
      value: el.value,
      children: el.children.map((item) => {
        let items = {
          label: item.label,
          value: item.value,
          children: item.children.filter(
            (item) => item["checked"] === true
          ),
        };

        return items;
      })
    };

    return data;
  })

// Check if any grandparent has a child with more than 0 length
const finalChecker = () => {
  let data = [];
  for (let i = 0; i < grandParent.length; i++) {
    for (let j = 0; j < grandParent[i].children.length; j++) {
      if (grandParent[i].children[j].children.length > 0) {
        data.push(grandParent[i]);
      }
    }
  }
  return data;
};
// Set the apiData with the filtered locations
setApiData({
  ...apiData,
  location: {
    regions:
      props.locationFilter.filter((el) => el["checked"] === true).length > 0
        ? props.locationFilter.filter((el) => el["checked"] === true)
        : firstParent.length > 0
        ? firstParent
        : finalChecker(),
  },
});


};
 

 //Function for country selection
  const countryFilter = ()=>{
    let data = []
    for(let item of props.locationFilter){
      for(let items of item.children){
        for(let ites of items.children){
          if(ites.checked === true){
            data.push(ites.value)
          }
        }
      }
    }

    return data
  }

  //Function for submit click
  const submitFilter = () => {

    const formData = {
      company: props.companyFilter
        .filter((el) => el.checked === true)
        .map((el) => el.value),
      asset: props.companyFilter
        .map((el) =>
          el.children.filter((it) => it.checked === true).map((tk) => tk.value)
        )
        .filter((item) => item.length > 0)
        .flat(1),
      countryIds: countryFilter(),
      regionIds: props.locationFilter
        .filter((el) => el.checked === true)
        .map((el) => el.value),
      subRegionIds: props.locationFilter
        .map((el) =>
          el.children.filter((it) => it.checked === true).map((tk) => tk.value)
        )
        .filter((item) => item.length > 0)
        .flat(1),
      assetTypeIds: filtersRecords.assetType.map((el) => el.assetTypeId),
      commodityIds: filtersRecords.commodity.map((el) => el.commodityId),
      statusIds: filtersRecords.status.map((el) => el.id),
      priorityIds: filtersRecords.priority.map((el) => el.priorityId),
    };
    // this data will be send to the body api
  
    props.setFilterValue(formData);
    props.setState({ ...props.state, right: false });
  };
  

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Add & Edit Currency Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Container
            data={props.companyFilter}
            onChange={onChangeComp}
            showPartiallySelected={true}
            texts={{ placeholder: "Company" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Container
            data={props.locationFilter}
            onChange={onChangeLoc}
            showPartiallySelected={true}
            texts={{ placeholder: "Location" }}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filtersRecords.assetType}
            items={
              props.dataForFilter.assetType ? props.dataForFilter.assetType : []
            }
            label="Asset Type"
            selectAllLabel={{ assetTypeName: "Select all" }}
            getOptionLabel={(option) => option.assetTypeName}
            getOptionSelected={(option, anotherOption) =>
              option.assetTypeName === anotherOption.assetTypeName
            }
            role="assetType"
          />
        </Grid> */}
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filtersRecords.commodity}
            items={
              props.dataForFilter.commodity ? props.dataForFilter.commodity : []
            }
            label="Commodity"
            selectAllLabel={{ commodityName: "Select all" }}
            getOptionLabel={(option) => option.commodityName}
            getOptionSelected={(option, anotherOption) =>
              option.commodityName === anotherOption.commodityName
            }
            role="commodity"
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Grid item xs={12}>
            <MultiSelect
              onChange={onChangeHandler}
              value={filtersRecords.status}
              getOptionSelected={(option, anotherOption) =>
                option.statusName === anotherOption.statusName
              }
              items={status ? status : []}
              label="Status"
              selectAllLabel={{ statusName: "Select all" }}
              getOptionLabel={(option) => option.statusName}
              role="status"
            />
          </Grid>
        </Grid> */}
       <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filtersRecords.priority}
            getOptionSelected={(option, anotherOption) =>
              option.priorityName === anotherOption.priorityName
            }
            items={
              props.dataForFilter.priority ? props.dataForFilter.priority : []
            }
            label="Priority"
            selectAllLabel={{ priorityName: "Select all" }}
            getOptionLabel={(option) => option.priorityName}
            role="priority"
            
          />
        </Grid> 

        <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="left">
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn"
                color="accent5"
                onClick={submitFilter}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn reset-color"
                color="accent3"
                onClick={clearFieldHanlder}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterForm;
