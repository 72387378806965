// Default React, Router and Services Components
import React from "react";
import { Button, Box, Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../../../Services/ApiService";
import ErroHandling from "../../../../Services/ErrorHandling";

// Importing Custom Components
import MultiSelect from "../../../../UIComponent/MultiSelect/MultiSelect";
import DatePicker from "../../../../UIComponent/DatePicker";
import { toasterError } from "../../../../UIComponent/Toaster";
import Container from '../../SourceManagement/TreeContainer';
import { clearFilterJobRecords, clearQualityFilter, GetAssetData, setQualtyFilter } from "../../../../features/filter/filterSlice";
import { callBasedOnLocation, callFromAssetType, callFromComm, callFromPriority, callSource } from "../../../../Util/TreeAPI";

// Importing useStyles and Styles
import "../../../../../src/index.scss";
import "../../../../Resources/scss/layout.scss";
import useStyles from "./DataCollectionQCFailedReport.styles";
import "react-toastify/dist/ReactToastify.css";
import "react-dropdown-tree-select/dist/styles.css";

const QualityFilter = (props) => {
  // Declaration of States and Component Variables Start Here
  const { filterQuality, assetDropDownlist } = useSelector(
    (store) => store.filter
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [status, setStatus] = React.useState([]);
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [assignedto, setAssignedTo] = React.useState([]);
  const [meritqc, setMeritQc] = React.useState([]);
  const [dependency, setDependency] = React.useState(null);
  const [apiData, setApiData] = React.useState({
    companies: [], asset: [],tat:"", statusId:[],analystId:[],qcUserId:[]
  });
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);

  const onChangeHandler = (key, value, reason) => {
    const pre = { ...filterQuality };
    console.log(key)
    pre[key] = value;
    dispatch(setQualtyFilter({ filterQuality: pre }));
  };


// This function is used to clear the field values
const clearFieldHanlder = () => {
  // Reset the tree filter
  props.resetTreeFilter();
  // Dispatch the clear quality filter action
  dispatch(clearQualityFilter());
  // Set reset filter to true
  props.setResetFilter(true);
  // Get the filter data
  props.getFilterData();
  // Set the dependency to null
  setDependency(null);
};

  // This function is used to submit the filter values
const submitFilter = () => {
  // Create an object to store the filter values
  const formData = {
    searchText: "",
    statusId: filterQuality.status.map((stat) => stat.id),
    company: props.companyFilter
      .filter((el) => el.checked === true)
      .map((el) => el.value),
    asset: props.companyFilter
      .map((el) =>
        el.children.filter((it) => it.checked === true).map((tk) => tk.value)
      )
      .filter((item) => item.length > 0)
      .flat(1),
    tat: props.tatDate,
    analystId: (filterQuality.assignedto || []).map(
      (e) => e.userId
    ),
    qcUserId: (filterQuality.meritqcuser || []).map(
      (e) => e.userId
    ),
   
  };
  // Set the filter value and update the state
  props.setFilterValue(formData);
  props.setState({ ...props.state, right: false });
};

  // Function to get all status from API
const getAllStatus = () => {
  // Call the ApiService to get all data from QualityManagement/GetRoleBasedStatusFilterDropdown
  ApiService.getAll("QualityManagement/GetRoleBasedStatusFilterDropdown")
    .then((res) => {
      // If response is successful, set the status
      if (res.status === 200) {
        setStatus(res.data);
      } else {
        // If response is unsuccessful, call toasterError function
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // If there is an error, call ErroHandling function and pass toasterError as a callback
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
};

// Function to get all companies from the API
const getAllCompanies = () => {
  // Call ApiService to get all companies
  ApiService.getAll("DropDown/GetCompanyDropDownFilter")
    .then((res) => {
      // If response status is 200, set job companies
      if (res.status === 200) {
        setJobCompanies(res.data);
      } else {
        // Else call toasterError with response data
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Call ErrorHandling with error and a callback function
      ErroHandling(err, function (res) {
        // Call toasterError with response
        toasterError(res)
      })
    });
  }


// This function is used to get all assigned users from the API
const getAllAssignedUsers = () => {
  // Call the ApiService to get all assigned users
  ApiService.getAll("CompanyOwnershipReport/GetAssignedToDropdown")
    .then((res) => {
      // If the response status is 200, set the assigned users in the state
      if (res.status === 200) {
        setAssignedTo(res.data);
      } else {
        // If the response status is not 200, show an error message
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Handle any errors that occur during the API call
      ErroHandling(err, function (res) {
        toasterError(res);
      });
    });
};

// This function is used to get all MeritQC users from the API
const getAllMeritQcUsers = () => {
  // Call the ApiService to get all MeritQC users
  ApiService.getAll("CompanyOwnershipReport/GetMeritQCDropdown")
    .then((res) => {
      // If the response status is 200, set the MeritQC data
      if (res.status === 200) {
        setMeritQc(res.data);
      } else {
        // Else call the toasterError function with the response data
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Call the ErroHandling function with the error and a callback function
      ErroHandling(err, function (res) {
        // Call the toasterError function with the response
        toasterError(res);
      });
    });
};
// Declaration of React Hooks Start Here
  React.useEffect(() => {
    getAllStatus();
    getAllCompanies();
    dispatch(GetAssetData());
    getAllAssignedUsers();
    getAllMeritQcUsers();
  }, []);
// Declaration of React Hooks end  Here
// This function is used to set the dependency of company and selected companies
const onChangeComp = (currentNode, selectedNodes) => {
  // Set the dependency of company
  setDependency("company");
// Set the selected companies
setSelectedCompanies(selectedNodes);
// Loop through the company filter
props.companyFilter.forEach((el) => {
  // If the current node is checked
  if (currentNode.checked === true) {
    // Check if the current node value is equal to el value
    if (currentNode.value === el.value) {
      // Set the el.checked to true
      el.checked = true;
    } else if (currentNode.parentValue === el.value) {
      // Loop through the item in el.children
      el.children.forEach((item) => {
        // Check if the current node value is equal to item value
        if (currentNode.value === item.value) {
          // Set the item.checked to true
          item.checked = true;
        }
      });
    }
  } else if (currentNode.checked === false) {
    // Check if the current node value is equal to el value
    if (currentNode.value === el.value) {
      // Set the el.checked to false
      el.checked = false;
    } else if (currentNode.parentValue === el.value) {
      // Loop through the item in el.children
      el.children.forEach((item) => {
        // Check if the current node value is equal to item value
        if (currentNode.value === item.value) {
          // Set the item.checked to false
          item.checked = false;
        }
      });
    }
  }
});

// Get the first parent from the company filter
const firstParent = props.companyFilter
  .map((el) => {
    let data = {
      label: el.label,
      value: el.value,
      // Filter the children with checked value as true
      children: el.children.filter((item) => item["checked"] === true),
    };
    return data;
  })
  // Filter the elements with children length greater than 0
  .filter((el) => el.children.length > 0);
  // Set the api data
  setApiData({
    ...apiData,
    companies:
      // Check if the length of filtered elements is greater than 0
      props.companyFilter.filter((el) => el["checked"] === true).length > 0
        ? props.companyFilter.filter((el) => el["checked"] === true)
        : firstParent,
  });
};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Add & Edit Frequency Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <Container
            data={props.companyFilter}
            onChange={onChangeComp}
            showPartiallySelected={true}
            texts={{ placeholder: "Company" }}
          />
        </Grid>
    
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterQuality.assignedto}
            items={assignedto}
            label="Assigned To"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            role="assignedto"
          />
        </Grid>

        <Grid item xs={12}>
         
          <DatePicker
            labelTitle="TAT"
            placeHolder="DD-MM-YYYY"
            variant="standard"
            past={false}
            defaultDate={props.tatDate}
            onChange={onChangeHandler}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              props.setTatDate(
                selectedDate.getFullYear() +
                "-" +
                (selectedDate.getMonth() + 1) +
                "-" +
                selectedDate.getDate()
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterQuality.meritqcuser}
            items={meritqc}
            label="Merit QC"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            role="meritqcuser"
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterQuality.status}
            getOptionSelected={(option, anotherOption) =>
              option.statusName === anotherOption.statusName
            }
            items={status}
            label="Status"
            selectAllLabel={{ statusName: "Select all" }}
            getOptionLabel={(option) => option.statusName}
            role="status"
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn"
                color="accent5" onClick={submitFilter}>
                Filter
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn reset-color"
                color="accent3" onClick={clearFieldHanlder}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );
};

export default QualityFilter;