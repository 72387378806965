// Default React, Router and Services Components
import React, { useContext, useState } from "react";
import {
  Button,
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// import history from "../../../../Routes/History";
import { useHistory } from "react-router-dom";

import ApiService from "../../../../Services/ApiService";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
// Importing Custom Components
import DataTable from "../../../../UIComponent/DataTable";
import BaseLayout from "../../../../UIComponent/BaseLayout";
import ExportToExcel from "./Excelexport";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
// Importing useStyles
import useStyles from "./CRUReviewReport.styles";

// Import Fluent UI Icons
import {
  ChevronRightSmallIcon,
  ClearIcon,
  ChevronLeftIcon,
  SearchIcon,
  ExportIcon,
} from "@fluentui/react-icons-mdl2";
import { FilterContext } from "../../../DataCollection/DashboardReports/Context/Context";

const OwnershipChangesCRUReviewReportDrill = (props) => {
  const history = useHistory();
  // Declaration of States and Component Variables Start Here
  const { filterValueContext, dateRange, setDateRange, priorityIds } =
    useContext(FilterContext);
  const classes = useStyles();
  const [allRows, setAllRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [selected, SetSelected] = useState([]);
  const [isAllSelected, setIsSelected] = useState(false);
  const [state, setState] = React.useState({ right: false });
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [reload, setReload] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const date = new Date().toLocaleDateString();
  const date1 = date.split("/").join("-");
  const [tableData, setTableData] = useState([]);
  const [priorityId, setPriorityId] = useState("");
  const [reportType, setReportType] = useState("");
  const [priorId, setPriorId] = useState(null);
  const [isExcelDownload, setIsExcelDownload] = useState(false);
  // Declaration of States and Component Variables End Here
  const fileName = `CRUReviewStatusDrillDownReport${new Date()
    .toLocaleDateString()
    .split("/")
    .join("-")}`;
  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    getDataFromHistory();
  }, [history]);
  React.useEffect(() => {
    if (priorityId !== undefined && reportType != undefined) {
      GetCRUReviewDrillReport(searchValue, priorityId, reportType);
    }
    return () => {
      if (dateRange)
        if (Object.keys(dateRange).length > 0) {
          setDateRange({});
        }
    };
  }, [priorityId, reportType, reload]);
  React.useEffect(() => {
    GetCRUReviewDrillReport(searchValue, priorityId, reportType);
  }, [sorting, page, rowsPerPage]);
  // Declaration of React Hooks End Here
  console.log(props.location, "asasas");
  //columns to be displayed in grid
  const gridcolumns = [
    { id: "jobAssetId", label: "Id", type: "Highlight" },
    { id: "jobNo", label: "Job ID", minWidth: 90, width: 90, sorting: true },
    {
      id: "jobName",
      label: "Job Name",
      minWidth: 300,
      width: 300,
      sorting: true,
    },

    { id: "asset", label: "Asset", minWidth: 200, width: 200, sorting: true },
    {
      id: "commodities",
      label: "Commodity",
      minWidth: 250,
      width: 250,
      sorting: true,
      truncate: 50,
      tooltipWithTruncate: true,
    },
    {
      id: "company",
      label: "Owner",
      minWidth: 250,
      width: 250,
      sorting: true,
    },
    {
      id: "operator",
      label: "Operator",
      minWidth: 250,
      width: 250,
      sorting: true,
    },

    {
      id: "createdOn",
      label: "Job Created Date",
      minWidth: 120,
      width: 120,
      sorting: true,
      align: "center",
    },

    {
      id: "tat",
      label: "TAT",
      minWidth: 120,
      width: 120,
      sorting: true,
      align: "center",
    },
    {
      id: "remainingDays",
      label: "Remaining Days",
      minWidth: 110,
      width: 110,
      sorting: true,
      align: "center",
    },

    {
      id: "assignedTo",
      label: "Assigned To",
      minWidth: 200,
      width: 200,
      sorting: true,
    },
    {
      id: "jobCompletionDate",
      label: "Job Completion Date",
      minWidth: 140,
      width: 140,
      sorting: true,
      align: "center",
    },
    {
      id: "cruReview",
      label: "CRU Review",
      minWidth: 200,
      width: 200,
      sorting: true,
    },
    {
      id: "completedOn",
      label: "CRU QC Completion Date",
      minWidth: 150,
      width: 150,
      sorting: true,
      align: "center",
    },
    {
      id: "priority",
      label: "Priority",
      minWidth: 110,
      width: 110,
      sorting: true,
      type: "Chip",
      align: "center",
    },
    {
      id: "statusName",
      label: "Status",
      minWidth: 160,
      width: 160,
      sorting: true,
    },
    {
      id: "country",
      label: "Country",
      minWidth: 180,
      width: 180,
      sorting: true,
    },
    { id: "region", label: "Region", minWidth: 160, width: 160, sorting: true },
    {
      id: "subRegion",
      label: "Subregion",
      minWidth: 160,
      width: 160,
      sorting: true,
    },
  ];

  // search on enter press
  const handleKeyUp = (event) => {
    if (
      (event.keyCode === 8 || event.which === 8) &&
      event.target.nodeName !== "BUTTON"
    ) {
      if (searchValue.length === 0) {
      }
    }
    if (
      (event.keyCode === 13 || event.which === 13) &&
      event.target.nodeName !== "BUTTON"
    ) {
      searchReport(event.target.value);
    }
  };

  React.useEffect(() => {
    if (priorityIds === "High") {
      setPriorId(1);
    }
    if (priorityIds === "Medium") {
      setPriorId(3);
    }
    if (priorityIds === "Critical") {
      setPriorId(4);
    }
    if (priorityIds === "Low") {
      setPriorId(2);
    }
  }, [priorityIds]);

  console.log(priorId, "priorid");

  // Function for Sorting
  const changeSortColumnNameFormat = (fieldName) => {
    if (fieldName == null || fieldName == "") {
      return "";
    }
    if (fieldName.toLocaleLowerCase() == "jobNo".toLocaleLowerCase()) {
      fieldName = "JobNo";
    }
    if (fieldName.toLocaleLowerCase() == "jobName".toLocaleLowerCase()) {
      fieldName = "JobName";
    }
    if (fieldName.toLocaleLowerCase() == "company".toLocaleLowerCase()) {
      fieldName = "Company";
    }
    if (fieldName.toLocaleLowerCase() == "asset".toLocaleLowerCase()) {
      fieldName = "Asset";
    }
    if (fieldName.toLocaleLowerCase() == "NoOfVariable".toLocaleLowerCase()) {
      fieldName = "NoOfVariable";
    }
    if (fieldName.toLocaleLowerCase() == "commodities".toLocaleLowerCase()) {
      fieldName = "Commodities";
    }
    if (fieldName.toLocaleLowerCase() == "operator".toLocaleLowerCase()) {
      fieldName = "Operator";
    }
    if (fieldName.toLocaleLowerCase() == "country".toLocaleLowerCase()) {
      fieldName = "Country";
    }
    if (fieldName.toLocaleLowerCase() == "region".toLocaleLowerCase()) {
      fieldName = "Region";
    }
    if (fieldName.toLocaleLowerCase() == "subRegion".toLocaleLowerCase()) {
      fieldName = "SubRegion";
    }
    if (fieldName.toLocaleLowerCase() == "createdOn".toLocaleLowerCase()) {
      fieldName = "CreatedOn";
    }
    if (fieldName.toLocaleLowerCase() == "startDate".toLocaleLowerCase()) {
      fieldName = "StartDate";
    }
    if (fieldName.toLocaleLowerCase() == "TAT".toLocaleLowerCase()) {
      fieldName = "TAT";
    }
    if (fieldName.toLocaleLowerCase() == "RemainingDays".toLocaleLowerCase()) {
      fieldName = "RemainingDays";
    }
    if (fieldName.toLocaleLowerCase() == "commodities".toLocaleLowerCase()) {
      fieldName = "Commodities";
    }
    if (fieldName.toLocaleLowerCase() == "jobCompletionDate".toLocaleLowerCase()) {
      fieldName = "JobCompletionDate";
    }
    if (fieldName.toLocaleLowerCase() == "completedOn".toLocaleLowerCase()) {
      fieldName = "CompletedOn";
    }
    if (fieldName.toLocaleLowerCase() == "assignedTo".toLocaleLowerCase()) {
      fieldName = "AssignedTo";
    }
    if (fieldName.toLocaleLowerCase() == "cruReview".toLocaleLowerCase()) {
      fieldName = "CRUReview";
    }
    if (fieldName.toLocaleLowerCase() == "statusName".toLocaleLowerCase()) {
      fieldName = "StatusName";
    }
    if (fieldName.toLocaleLowerCase() == "priority".toLocaleLowerCase()) {
      fieldName = "Priority";
    }
    return fieldName;
  };

  // Function for Global Search
  const searchReport = (filterValue) => {
    setTotalCount(0);
    setPage(0);
    setLoading(true);
    setReload(!reload);
  };
  
  const globalSearch = (value) => {
    setSearchValue(value);
  };

// This function gets data from the history object
const getDataFromHistory = () => {
  // Get the search query from the location object of the history object
  let historyData = history.location?.search.replace("?", "").split("&");
  // Iterate through each element in the historyData array
  historyData.forEach((element) => {
    // Split the element into an array of items
    let items = element.split("=");
    // Check if the length of the items array is greater than 0
    if (items.length > 0) {
      // Check if the first item in the array is equal to 'Priority'
      if (items[0].toString() == "Priority") {
        // Get the priority name from the second item in the array and convert it to lowercase
        const priorityName = items[1].toLowerCase();
        // Switch statement to set the priorityId based on the priorityName
        switch (priorityName) {
          case "high":
            setPriorityId(1);
            break;
          case "critical":
            setPriorityId(4);
            break;
          case "low":
            setPriorityId(2);
            break;
          case "medium":
            setPriorityId(3);
            break;
          case "total":
            setPriorityId(5);
            break;
        }
      }
      // Check if the first item in the array is equal to 'ReportType'
      if (items[0].toString() == "ReportType") {
        // Set the report type using the second item in the array
        setReportType(items[1]);
      }
    }
  });
};


  // Function for Search Clear
  const searchClear = () => {
    setSearchValue("");
    setPage(0);
    setReload(!reload);
  };

  // Function for Getting CRU Review Data
  const GetCRUReviewDrillReport = (
    filterValue,
    priorityId,
    reportType,
    isExcelDownload = false
  ) => {
    setLoading(true);

    let searchvalue;
    if (priorityId != "" && reportType != "") {
      if (priorityId === 5) {
        searchvalue =
          '{"Company":[],"Asset":[],"FromDate":"","ToDate":"" ,"SearchText":"' +
          filterValue +
          '"}';
      } else {
        searchvalue =
          '{"Company":[],"Asset":[],"FromDate":"","ToDate":"","PriorityId":"' +
          priorityId +
          '" ,"SearchText":"' +
          filterValue +
          '"}';
      }

      let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
      if (isExcelDownload) {
        prt = [`pageNumber=${page + 1}`, `pageSize=${totalCount}`];
      }
      let url = "CompanyOwnershipReport/GetCRUReviewPassedWithNoErrorsDetailsCompanyOwnership?";
      if (reportType == "reviewPassedWithNoErrors") {
        url = "CompanyOwnershipReport/GetCRUReviewPassedWithNoErrorsDetailsCompanyOwnership?";
      } else if (reportType == "reviewPassedWithCRUReviewErrors") {
        url = "CompanyOwnershipReport/GetCRUReviewPassedWithErrorsDetailsCompanyOwnership?";
      } else if (reportType == "cruReviewFailed") {
        url = "CompanyOwnershipReport/GetCRUReviewFailedDetailsCompanyOwnership?";
      } else if (reportType == "total") {
        url = "CompanyOwnershipReport/GetCRUReviewPriorityDetailsCompanyOwnership?";
      }
      console.log("PASSE", {
        ...filterValueContext,
      });
      ApiService.search(
        url,
        {
          filter: encodeURIComponent(
            JSON.stringify({
              ...filterValueContext,
              searchText: searchValue,
              ...dateRange,
              ...{ PriorityId: priorId },
            })
          ),
          sortColumn: changeSortColumnNameFormat(sorting.sortBy),
          isAscending: sorting.isAscending,
        },
        prt
      )
        .then((res) => {
          if (res.status === 200) {
            setTotalCount(res.data.totalCount);
            let tempExcelData = [];

            console.log(tempExcelData);
            if (isExcelDownload) {
              res.data.data.forEach((e) => {
                tempExcelData.push({
                  "Job ID": e.jobNo,
                  "Job Name": e.jobName,
                  //Company: e.company,
                  Asset: e.asset,
                  Commodity: e.commodities,
                  Owner: e.company,
                  Operator: e.operator,
                  "Created Date": e.createdOn,
                  //"Job Start Date": e.startDate,
                  "Turn Around Time": e.tat,
                  "Remaining Days": e.remainingDays,
                  "Assigned To": e.assignedTo,
                  "Job Completion Date": e.jobCompletionDate,
                  "CRU Review": e.cruReview,
                  "CRU QC Completion Date": e.completedOn,
                  "Job Priority": e.priority,
                  status: e.statusName,
                  Country: e.country,
                  Region: e.region,
                  "Sub Region": e.subRegion,
                });
              });
              exportToCSV(tempExcelData, fileName);
            } else {
              setAllRows(res.data.data);

              // res.data.data.forEach(e => {
              //   tempExcelData.push({
              //     "Job ID": e.jobAssetId,
              //     "Job Name": e.jobName,
              //     "Company": e.company,
              //     "Asset": e.asset,
              //     "Variable Count": e.noOfVariable,
              //     "Commodity": e.commodities,
              //     "Created Date": e.createdOn,
              //     "Job Start Date": e.startDate,
              //     "Turn Around Time": e.tat,
              //     "Remaining Days": e.remainingDays,
              //     "Completed Time": e.completedOn,
              //     "Assigned To": e.assignedTo,
              //     "Merit QC": e.meritQC,
              //     "Job Priority": e.priority,
              //     "Country": e.country,
              //     "Region": e.region,
              //     "Sub Region": e.subRegion
              //   });
              // });
              // setTableData(tempExcelData);
            }

            setLoading(false);
          } else {
            toasterError(`${res.data}`);
            setLoading(false);
          }
        })
        .catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res);
          });
          setLoading(false);
        });
    }
  };
  const handleExportToExcel = () => {
    if (priorityId !== undefined && reportType != undefined) {
      GetCRUReviewDrillReport(searchValue, priorityId, reportType, true);
    }
  };

 // This function is used to export data from an API to a CSV file
const exportToCSV = (apiData, fileName) => {
  // Set the file type and extension
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  
  // Convert the API data to a worksheet
  const ws = XLSX.utils.json_to_sheet(apiData);
  
  // Define the columns for the worksheet
  const columns = [
    "Job ID",
    "Job Name",
    "Asset",
    "Commodity",
    "Owner",
    "Operator",
    "Job Created Date",
    "TAT",
    "Remaining Days",
    "Assigned To",
    "Job Completion Date",
    "CRU Review",
    "CRU QC Completion Date",
    "Priority",
    "Status",
    "Country",
    "Region",
    "Sub Region",
  ];
  
  // Add the columns to the worksheet
  XLSX.utils.sheet_add_aoa(ws, [columns], { origin: "A1" });
  
  // Create the workbook object
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  
  // Write the workbook object to an array buffer
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  
  // Create a blob from the array buffer
  const data = new Blob([excelBuffer], { type: fileType });
  
  // Save the blob as a file
  FileSaver.saveAs(data, fileName + fileExtension);
};
  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} lg={9} className="page-title">
          <h2>CRU Review Report Drill Down</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/data-collections/source-management" }}
            >
              Home
            </Link>
            <Typography color="text.primary">
              Manage Company Ownership Changes
            </Typography>
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/ownership-changes/status-report" }}
            >
              Dashboard and Reports
            </Link>
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/ownership-changes/cru-review-report" }}
            >
              CRU Review Report
            </Link>
            <Typography color="text.primary">
              CRU Review Report Drill Down
            </Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} lg={3}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                color="accent2"
                onClick={() => {
                  history.push("/app/ownership-changes/cru-review-report");
                }}
                startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  alignItems="flex-end"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      size="small"
                      fullWidth
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="serchByClear"
                        onClick={searchClear}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                      onClick={() => handleExportToExcel()}
                    >
                      Export To Excel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* CRU Review Report Table */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allRows ? allRows : []}
                  columns={gridcolumns}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={setSorting}
                  hasChip={{
                    High: { color: "warning", label: "High" },
                    Critical: { color: "error", label: "Critical" },
                    Low: { color: "success", label: "Low" },
                    Medium: { color: "info", label: "Medium" },
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OwnershipChangesCRUReviewReportDrill;
