import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

export default function BasicPopover({ sOpen, open, onClose, idss, description, commentDateTime }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const id = open1 ? "simple-popover" : undefined;
  return (
    <>
      <Popover
        id={idss}
        open={open}
        anchorEl={sOpen}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ pt: 2, pl: 2, pr: 2, pb: 1, maxWidth: '340px' }}>
        <Typography color="inherit" variant="body2" sx={{ pb: 1}}>
          {description}
        </Typography>

        <Typography variant="caption" style={{color: 'grey'}}>
          {commentDateTime}
        </Typography>
        </Box>
      </Popover>
    </>
  );
}