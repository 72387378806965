// Default React, Router and Services Components
import React from 'react';
import axios from 'axios';
import { useEffect } from "react";
import ViewSDKClient from './ViewSDKClient';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterSuccess, toasterError } from "../../../UIComponent/Toaster";
import QueryString from 'query-string';


const OpenFilePreview = (props) => {
    const history = useHistory();
    let location = useLocation();
    let { fileName, pageNumber, crudState,jobId } = { fileName: "", pageNumber: 1, crudState: "View",jobId:"" }
    // Declaration of States and Component Variables End Here
    //QueryString.parse(location.search.fileName)
    // Declaration of React Hooks Start Here
    useEffect(() => {
        var viewerConfig;
        if (crudState === "Edit") {
            viewerConfig = {
                showDownloadPDF: false,
                showPrintPDF: false,
                showAnnotationTools: true
            };
        } else {
            viewerConfig = {
                showDownloadPDF: false,
                showPrintPDF: false,
                showAnnotationTools: false
            };
        }

        const adobeKey = process.env.React_App_Adobe_Key;
        let queryValue = QueryString.parse(location.search);
        if (queryValue !== undefined && queryValue !== null) {
            fileName = queryValue.filename + ".pdf";
                // job id is not required for fetching file from blob.
            //jobId=queryValue.jobId
        }
        if (adobeKey !== undefined && adobeKey !== null && adobeKey !== '') {
            const viewSDKClient = new ViewSDKClient(adobeKey);
            viewSDKClient.ready().then(() => {
              // job id is not required for fetching file from blob.
                if (fileName !== undefined && fileName !== null && fileName !== "" ) {
                    axios.get(`${process.env.React_App_baseUrl}job/FetchBlobFile?fileName=${fileName}`, {
                        headers: {
                            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                        }
                    }).then((result) => {
                        var uint8Array = _base64ToArrayBuffer(result.data.file);
                        var promise = new Promise((resolve) => {
                            resolve(uint8Array)
                        });
                        let Senddata = { pageNumber: pageNumber, jobId: jobId, fileName: fileName, viewerConfig: viewerConfig, promise: promise, crudState: crudState }
                        viewSDKClient.previewFile("pdf-div", Senddata);
                        if (props?.isLoading) {
                            props?.isLoading(false);
                        }
                    }).catch((err) => {
                        {
                            if (props?.isLoading) {
                                props?.isLoading(false);
                            }
                            ErroHandling(err, function (res) {
                                toasterError(res)
                            })
                        }

                    })
                }else{
                    toasterError("file name is missing");
                }

            })
        } else {
            toasterError("pdf key is missing");
        }

    }, []);
    // Declaration of React Hooks End Here

    function _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) { bytes[i] = binary_string.charCodeAt(i); }
        return bytes.buffer;
    }
    return (<div id="pdf-div" className="full-window-div" style={{ height: 'calc(100vh - 150px)' }} />)
}

export default OpenFilePreview;

