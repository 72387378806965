// Default React, Router and Services Components
import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
// Importing Custom Components
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
import { Button,Select,Chip,  Grid } from "@mui/material";


import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
// import ChipInput from "material-ui-chip-input";
import axios from "axios";
import { ErrorBadgeIcon, CircleAdditionIcon } from "@fluentui/react-icons-mdl2";
import IconButton from "@mui/material/IconButton";
import CustomAutoComplete from "../../../UIComponent/CustomAutoComplete";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
// Importing useStyles
import useStyles from "./OwnershipChangesManagement.styles";
import "../../../Resources/scss/layout.scss";

const EditSource = (props) => {
  const classes = useStyles();
  // Declaration of States and Component Variables Start Here
  const [companyDetails, setCompanyDeatils] = useState(null);
  const [allCompanies, setAllCompanies] = React.useState([]);
  const [isJiraCreated,setIsJiraCreated]=useState(true);
  const [selectedCompany, setSelectedCompany] = React.useState({
    editCompanyId: 0,
    editAssetId: 0,
    isNewSource: true,
    isCRUCompany: true,
    companyId: 0,
    companyName: "string",
    remarks: "string",
    statusId: 0,
    isCRUAsset: true,
    assetId: 0,
    assetName: "string",
    assetTypeId: 0,
    countryId: 0,
    regionId: 0,
    subRegionId: 0,
    isJiraCreated: true,
    commoditiesId: [0],
    referencelink: ["string"],
  });
  const [loading, setLoading] = useState(false);
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    axios
      .get(process.env.React_App_baseUrl + "ownershipsource/GetCompanyOwnershipAssetById", {
        headers: {
          AssetId:props.assetId,
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
            
        },
        params: {
          CompanyId: props.companyId,
          
        },
      })
      .then(function (response) {
        if (!response.data.referencelink.length) {
          setIsJiraCreated(response.data.isJiraCreated)
          response.data.referencelink = [""];
          setCompanyDeatils({
            ...companyDetails,
            editCompanyId: response.data.editCompanyId,
            editAssetId: response.data.editAssetId,
            isNewSource: response.data.isNewSource,
            isCRUCompany: response.data.isCRUCompany,
            companyId: response.data.companyId,
            companyName: response.data.companyName,
            remarks: response.data.remarks,
            statusId: response.data.statusId,
            isCRUAsset: response.data.isCRUAsset,
            assetId: response.data.assetId,
            assetName: response.data.assetName,
            assetTypeId: response.data.assetTypeId,
            countryId: response.data.countryId,
            regionId: response.data.regionId,
            subRegionId: response.data.subRegionId,
            isJiraCreated: response.data.isJiraCreated,
            commoditiesId: response.data.commoditiesId ? response.data.commoditiesId : [0],
            referencelink: [""]
          });
          

        } else {
          setIsJiraCreated(response.data.isJiraCreated)
          setCompanyDeatils({
            ...companyDetails,
            editCompanyId: response.data.editCompanyId,
            editAssetId: response.data.editAssetId,
            isNewSource: response.data.isNewSource,
            isCRUCompany: response.data.isCRUCompany,
            companyId: response.data.companyId,
            companyName: response.data.companyName,
            remarks: response.data.remarks,
            statusId: response.data.statusId,
            isCRUAsset: response.data.isCRUAsset,
            assetId: response.data.assetId,
            assetName: response.data.assetName,
            assetTypeId: response.data.assetTypeId,
            countryId: response.data.countryId,
            regionId: response.data.regionId,
            subRegionId: response.data.subRegionId,
            isJiraCreated: response.data.isJiraCreated,
            commoditiesId: null,
            referencelink: response.data.referencelink
          });
          
        }
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });

    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
        headers: {
          isNewSource: false,
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      })
      .then(function (response) {
        setAllCompanies(response.data);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });
  }, []);
  // Declaration of React Hooks End Here

  //Method for add link
  const handleAddLink = (e) => {
    const links = [...companyDetails?.referencelink];
    links.push("");
    let item = { ...companyDetails, referencelink: links };
    setCompanyDeatils(item);
  };

  //Method for edit link
  const handleRemoveLink = (e, index) => {
    const links = [...companyDetails?.referencelink];
    links.splice(index, 1);
    let item = { ...companyDetails, referencelink: links };
    setCompanyDeatils(item);
  };

  //Method for change company details
  const handleChange = (e, i) => {
    const links = [...companyDetails?.referencelink];
    links[i] = e.target.value;
    let item = { ...companyDetails, referencelink: links };
    setCompanyDeatils(item);
  };

  //Method for edit source
  const editSource = () => {
    setLoading(true);
    setCompanyDeatils({
      companyDetails,
      isNewSource: false,
    });
    if (
      companyDetails.referencelink[companyDetails.referencelink.length - 1] ===
      ""
    ) {
      // console.log("URL Empty");
    } else {
      let validated = false;
      function validURL(str) {
        var pattern = new RegExp(
          "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
          "i"
        ); // fragment locator
        return !!pattern.test(str);
      }
      companyDetails.referencelink.map((link) => {
        if (!validURL(link)) {
          validated = true;
          setLoading(false);
        }
      });

      if (!validated) {
        axios
          .post(
            process.env.React_App_baseUrl + "ownershipsource/SaveCompanyOwnershipSource",
            companyDetails,
            {
              headers: {
                Authorization: localStorage.getItem("token")
                  ? "Bearer " + localStorage.getItem("token")
                  : null,
              },
            }
          )
          .then(function (response) {
            // console.log(response.data);
             toasterSuccess("Company edited successfully");
            props.setRefresh(!props.refresh);
            props.setState({ ...props.state, right: false });
            setLoading(false);
          })
          .catch(function (error) {
            ErroHandling(error, function (res) {
              toasterError(res);
            });
            setLoading(false);
          });
      } else {
        setLoading(false);
        toasterError("Please enter a valid link");
      }
    }
  };

  //Method for jira ticket created 
  const jira = () => {
    //toasterError("Jira ticket not created");
   // console.log(!companyDetails.isNewSource)
    axios
    .post(process.env.React_App_baseUrl + "jira/CreateJiraTicket", {
      id: companyDetails.editCompanyId,
      isCompanyChange: true, // default true is passed for iscompanychange so that it create jira ticket for company from backend
    },{
      headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
    .then(
      (res) => {
       // console.log(res);
        toasterSuccess(`Jira ticket created!`);
        setIsJiraCreated(true);
        props.setState({ ...props.state, right: false });
      },
      (err) => {
       // console.log(err);
       setIsJiraCreated(false);
       // setErrorMessage(err.response.data);
       // console.log(err.response.data);
        toasterError(`${err.response.data}`)
      }
    );
  };

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Edit Company
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                disabled={companyDetails?.isNewSource == false}
                checked={companyDetails?.isNewSource == true}
                value="newsource"
                control={<Radio />}
                label="New Source"
                id="newSource"
                color="accent2"
              />
              <FormControlLabel
                disabled={companyDetails?.isNewSource == true}
                checked={companyDetails?.isNewSource == false}
                value="sourcechange"
                control={<Radio />}
                label="Source Change"
                id="editSource"
                color="accent2"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sx={{ mt: -2 }}>
          {companyDetails?.isNewSource ? (
            <Autocomplete
              size="small"
              id="auto-complete1"
              autoComplete
              readOnly
              includeInputInList
              options={[{ name: "Other", id: 1 }]}
              value={{ name: "Other", id: 1 }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Company Name"
                  placeholder="Company Name"
                />
              )}
            />
          ) : (
            // <Autocomplete
            //
            //     size="small"
            //     disabled
            //     id="auto-complete"
            //     autoComplete
            //     includeInputInList
            //     options={allCompanies}
            //     value={selectedCompany}
            //     onChange = {(e,newValue)=>{
            //         console.log(newValue)
            //         setSelectedCompany(newValue)
            //     }}
            //     renderInput={(params) => (
            //         <TextField
            //             {...params}
            //             variant="standard"
            //             label="Company Name"
            //             placeholder="Company Name"
            //         />
            //     )} />
            companyDetails && (
              <CustomAutoComplete
                options={allCompanies}
                disabled={true}
                label="Company Name"
                optionLabelKey="companyName"
                optionValueKey="companyId"
                onChange={(value) => {
                  // console.log(value);
                }}
                defaultValueKey={["id", "isCRUCompany"]}
                defaultValue={[
                  companyDetails?.companyId,
                  companyDetails?.isCRUCompany,
                ]}
              />
            )
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            disabled={isJiraCreated}
            id="standard-basic"
            label="New Company Name"
            variant="standard"
            value={companyDetails?.companyName || ""}
            onChange={(e) =>
              setCompanyDeatils({
                ...companyDetails,
                companyName: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            disabled
            getOptionLabel={(option) => option.asset}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Asset Name"
                placeholder="Asset Name"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled
              id="standard-basic"
              label="Asset Type"
              variant="standard"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled
              id="standard-basic"
              label="Region"
              variant="standard"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled
              id="standard-basic"
              label="Sub Region"
              variant="standard"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled
              id="standard-basic"
              label="Country"
              variant="standard"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
        <FormControl fullWidth>
            <TextField
              disabled
              id="standard-basic"
              label="Commodities"
              variant="standard"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              id="standard-basic"
              label="Status"
              variant="standard"
              value={"Pending"}
              readOnly
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {companyDetails?.referencelink &&
            companyDetails?.referencelink.map((linki, i) => {
              return (
                <>
                  <div className="d-flex field-gap">
                    <div className="flex-grow-1">
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Link"
                        variant="standard"
                        multiline
                        onChange={(e) => handleChange(e, i)}
                        defaultValue={linki}
                      />
                    </div>
                    <div>
                      {i === 0 && (
                        <IconButton
                          aria-label="Add"
                          style={{ marginTop: 10, marginLeft: 10 }}
                          onClick={(e) => {
                            handleAddLink(e);
                          }}
                        >
                          <CircleAdditionIcon />
                        </IconButton>
                      )}
                      {i > 0 && (
                        <IconButton
                          aria-label="Add"
                          style={{ marginTop: 10, marginLeft: 10 }}
                          onClick={(e) => {
                            handleRemoveLink(e, i);
                          }}
                        >
                          <ErrorBadgeIcon />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              size="small"
              id="standard-basic"
              label="Remarks"
              variant="standard"
              value={companyDetails?.remarks || ""}
              onChange={(e) =>
                setCompanyDeatils({
                  ...companyDetails,
                  remarks: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box component="span" fullWidth sx={{ pt: 2, pb: 1 }}>
            {/* <Button
                            variant="contained"
                            style={{ marginRight: "15px" }}
                            className="rounded-border"
                            color="accent5"
                            onClick={editSource}
                        >
                            Save
                        </Button> */}
            <LoadingButton
              buttonName="Update"
              clickHandler={editSource}
              isLoading={loading}
            />
            <Button
              variant="contained"
              style={{ marginRight: "15px", marginLeft: "15px" }}
              className="rounded-border"
              onClick={() => props.setState({ ...props.state, right: false })}
              color="accent3"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="rounded-border"
              color="secondary"
              disabled={isJiraCreated}
              onClick={jira}
            >
              Create jira Ticket
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditSource;
