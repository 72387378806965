/* eslint-disable no-undef */
// Default React, Router and Services Components
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// Importing Custom Components
import CustomAutoComplete from "../../../UIComponent/CustomAutoComplete";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
import axios from "axios";
import CommonService from "../../../Services/CommonService";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import { LoaderWrapper } from "../../../UIComponent/Progress";
// Importing useStyles
import useStyles from "./jobManagement.styles";

// Import Fluent UI Icons
import { ClearIcon, DeleteIcon, SearchIcon } from "@fluentui/react-icons-mdl2";
import AlertDialog from "../../../UIComponent/AlertDialog";
import MultiSelect from "../../../UIComponent/MultiSelectDropdown";

const CreateMultipleJob = (props) => {
  
  const classes = useStyles();
  const [valueData, setValueData] = useState();

  const [jobType, setJobType] = useState([]);
  const [jobTypeId, setJobTypeId] = useState(0);
  const [defaultJobType, setDefaultJobType] = useState([]);
  const [priority, setPriority] = useState();
  const [searchValue, setSearchValue] = useState();
  const [loading, setLoading] = useState();
  const [uploadedExcelData, setUploadedExcelData] = useState([]);
  const [InitialExcelData, setInitialExcelData] = useState([]);
  const [longitude, setLongitude] = useState(false);
  const [latitude, setLatitude] = useState(false);
  const [owner, setOwner] = useState(false);
  const [operator, setOperator] = useState(false);
  const [isListedCompany, setIsListedCompany] = useState(false);
  const [ownershipDate, setOwnershipDate] = useState(false);
  const [stockExchange, setStockExchange] = useState(false);
  const [ownershipPercentage, setOwnershipPercentage] = useState(false);
  const [ownershipYear, setOwnershipYear] = useState(false);
  const [seeThroughOwners, setSeeThroughOwners] = useState(false);
  const [jointVentures, setJointVentures] = useState(false);
  const [updates, setUpdates] = useState(false);
  const [jobName, setJobName] = useState("");
  const [jobId, setJobId] = useState(0);
  const [jobInstruction, setJobInstruction] = useState("");
  const [openConfirmation, setConfirmation] = useState(false);
  const handleADClose = () => {
    setConfirmation(false);
  };
  const [deletedItem, setDeletedItem] = useState(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(false);
  const [isLoading, setIsLsoading] = useState(false);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [isSelectAll, setSelectAll] = useState(false);
  const [yearsForSave, setYearsForSave] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [selectedCompanyForCreateJob, setselectedCompanyForCreateJob] = useState([]);
  const [removedeleted,setRemoveDeleted]=useState([]);

// Define a function named 'globalSearch' with an argument 'value'.
const globalSearch = (value) => {
  // If the passed 'value' is truthy (i.e. it exists and is not null, undefined or empty), set 'searchValue' to 'value'. 
  // Otherwise, if 'value' is falsy (i.e., null, undefined, empty), set 'searchValue' to empty string "", essentially resetting or clearing the search.
  setSearchValue(value ? value : "");
};


  useEffect(() => {
    if (props?.selectedCompanyForCreateJob) {
      setselectedCompanyForCreateJob(props?.selectedCompanyForCreateJob);
      setUpdatedValue(!updatedValue);
    }
    getYear();
  }, [props?.open]);

  useEffect(() => {
    searchResult();
  }, [updatedValue]);

  useEffect(() => {
    GetJobType();
  }, []);

// Define a function named 'openAlertDialog' with an argument 'item'.
const openAlertDialog = (item) => {
  // Set the confirmation state as true, i.e., open the confirmation dialog.
  setConfirmation(true);
  // Set 'deletedItem' state to the passed 'item', identifying which item is selected for deletion.
  setDeletedItem(item);
};

  // Define a function named 'multipleDelete'.
const multipleDelete = () => {
  // Initialize a flag variable 'allowConfirmation' and set it as false.
  let allowConfirmation = false;
  // Iterate through each element in 'uploadedExcelData' array.
  uploadedExcelData.forEach((element) => {
    // If the current 'element' in the loop is selected, set 'allowConfirmation' true.
    if (element.selected) {
      allowConfirmation = true;
    }
  });
  // If the 'allowConfirmation' remains false (i.e., no elements were selected)
  if (!allowConfirmation) {
    // A toaster error message gets displayed asking to select at least one asset.
    toasterError("please select at least one asset.");
    // End the execution of code here itself.
    return;
  }
  // If any element was selected and 'allowConfirmation' became true,
  // the confirmation state is set to true.
  setConfirmation(true);
};


 // This is the GetJobType function, an arrow function.
const GetJobType = () => {
  // First it makes a GET request to get job type list from the server
  axios
    .get(process.env.React_App_baseUrl + "job/GetJobTypeDropDown", {
      // A bearer authorization token is added to the headers of the request
      headers: {
        Authorization: CommonService.getToken(),
      },
    })
    .then(function (response) {
      // Initialize an empty array named 'type'
      let type = [];
      // Iterates over each item in the response data.
      response.data.map((row) => {
        // Checks if job name is 'company changes'
        if (row.name.toLowerCase() === "company changes") {
          // Sets the relevant job id as 'Company Changes'
          setJobTypeId(row.id);
          // Sets the default job type as 'Company Changes' along with its id
          setDefaultJobType([row.id, "Company Changes"]);
        }
        // Each row of data with id and label(name) is pushed into the type array
        type.push({ id: row.id, label: row.name });
      });
      // The populated 'type' array is set as the job type
      setJobType(type);
    })
    // If there's any error during fetching data, this catch block will run
    .catch(function (error) {
      {
        // ErrorHandling function is triggered which takes the error object
        ErroHandling(error, function (res) {
          // Displays a toaster message with the error
          toasterError(res);
        });
      }
    });
};


  // Define the handleKeyUp function that handles keyboard events
const handleKeyUp = (event) => {
  
  // if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
    //   if (searchValue) {
    //   }

    // }

  // Check if the triggered keyboard event is 'Enter' key up (keyCode 13 or which 13)
  // and the event's target node is not a 'BUTTON'
  if (
    (event.keyCode === 13 || event.which === 13) && 
    event.target.nodeName !== "BUTTON"
  ) {
    
    // If conditions are met, call
    searchResult(event.target.value);
    }
  };

 // Define the DeleteData function
const DeleteData = () => {
  
  // Check if a deletedItem exists
  if (deletedItem) {
    
    // Check if deletedItem is an instance of Object
    if (deletedItem instanceof Object) {
      
      // If true, loop through each element in 'uploadedExcelData' array  
      uploadedExcelData.forEach((element) => {
        
        // If current element's Id matches that of deletedItem,
        // mark it as deleted by setting isDeleted property to true
        if (element.Id === deletedItem.Id) {
          element.isDeleted = true;
        }
      });
    }
    
  } else { 
    
    // In absence of a specific deletedItem,
    // search entire 'uploadedExcelData' array and mark elements as deleted if they are selected
    uploadedExcelData.forEach((element) => {
      if (element.selected) {
        element.isDeleted = true;
      }
    });

    // If all items were selected and thus marked as deleted, toggle the 'isAllSelected' state
    if (isAllSelected) {
      setIsAllSelected(!isAllSelected);
    }
  }

  // Display success message after deletion operation
  toasterSuccess("Deleted Successfully");
  
  // Update states based on updated 'uploadedExcelData' array after deletion operation
  setUploadedExcelData([...uploadedExcelData]);
  setInitialExcelData([...uploadedExcelData]);
  
  // Close the modal, if any
  handleADClose();
  
  // Add deleted items to the 'removedeleted' array
  setRemoveDeleted((removedeleted || []).concat(uploadedExcelData))
};

  // Define the save function
  const save = () => {
    // Check if jobName is defined and non-empty
    if (jobName === undefined || jobName === null || jobName === "") {
      toasterError("Job name can not be empty");
      return;
    }
    // Check if selectedYear exists and has a length more than zero
    if (selectedYear === null || selectedYear === undefined || selectedYear.length === 0) {
      toasterError("Please select the year.");
      return;
    }
    // Initialize 'bulkRowDetails' as an empty array
    let bulkRowDetails = [];
     // Check if uploadedExcelData is defined and non-null
    if (uploadedExcelData !== undefined && uploadedExcelData !== null) {
      // Loop through each data item in 'uploadedExcelData'
      uploadedExcelData.forEach((data) => {
       // Process only the items which aren't marked as deleted   
        if (!data.isDeleted) {
           /* ...Several data processing steps... */
          /* ...and pushing a new object with required keys into 'bulkRowDetails'... */
          if (data.lattitude === 0 || data.lattitude === "-") {
            data.lattitude = 0.0;
          }
          if (data.longitude === 0 || data.longitude === "-") {
            data.longitude = 0.0;
          }
          if (data.ownershipPercentage === 0) {
            data.ownershipPercentage = 0.0;
          }
          if (data.ownershipDate === "-") {
            data.ownershipDate = "";
          }
          if (data.operator === "-") {
            data.operator = "";
          }
          bulkRowDetails.push({
            asset: data.asset,
            assetId: data.assetId,
            company: data.company,
            companyId: data.companyId,
            country: data.country,
            region: data.region,
            subRegion: data.subRegion,
            commodities: data.commodities,
            lattitude: data.lattitude,
            longitude: data.longitude,
            ownershipPercentage: data.ownershipPercentage,
            ownershipYear: data.ownershipYear,
            operator: data.operator,
            isDeleted: data.isDeleted,
            isListedCompany: data.isListedCompany,
          });
        }
      });
    }
 // Create 'saveBulkUploadJobCODto' object with relevant information for saving the job
    let saveBulkUploadJobCODto = {
      jobId: jobId,
      jobName: jobName,
      jobInstructions: jobInstruction,
      jobType: jobTypeId,
      dataPoints: {
        lattitude: latitude,
        longitude: longitude,
        owner: owner,
        ownershipPercentage: ownershipPercentage,
        ownershipDate: ownershipYear,
        operator: operator,
        stockExchange: stockExchange,
        seeThroughOwners: seeThroughOwners,
        joinVentures: jointVentures,
        updates: updates,
            
      },
       /* ...Object properties...*/
      bulkUploadJobDetails: bulkRowDetails,
       /* ...More object properties... */
      jobYearsDto: yearsForSave
    };
    // Validate that 'bulkRowDetails' isn't empty
    if (bulkRowDetails !== undefined && bulkRowDetails !== null) {
      if (bulkRowDetails.length === 0) {
        toasterError("Asset list can not be empty");
        return;
      }
    }
  // Set loading state to true before API call
    setIsLsoading(true);
  // Make an axios post request to save the bulk upload job   
    axios
      .post(
        process.env.React_App_baseUrl +
        "OwnershipChangeBulkUploadJob/SaveBulkUploadJob_V2",
        saveBulkUploadJobCODto,
        {
          headers: {
            Authorization: CommonService.getToken(),
            isFromCreateJobs: true
          },
        }
      )
      .then(function (response) {

        toasterSuccess("Job saved successfully.");
       // If response status is 200,
        // make a GET request to map latest job asset data
        if (response.status === 200) {
          axios
            .get(
              process.env.React_App_baseUrl +
              "OwnershipChangeBulkUploadJob/MapLatestJobAssetData",
              {
                headers: {
                  Authorization: CommonService.getToken(),
                  jobAssetList: response.data.jobAssetList,
                },
              }
            )
            .then(() => { })
            .catch(() => { });
        }
   // Close the modal, if any, and set loading state to false
        handleADClose();
        if (props?.setClose) {
          props.setClose(false);
        }
        setIsLsoading(false);
      })
      // Handle errors that may occur during the axios post request
      .catch(function (error) {
        setIsLsoading(false);
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });
  };

  // Define a function 'searchResult'
  const searchResult = () => {
  // Start the loading state
    setLoader(true);

 // Check if the 'selectedCompanyForCreateJob' is undefined or null
 // If so, terminate the function to prevent further execution
    if (
      selectedCompanyForCreateJob === undefined &&
      selectedCompanyForCreateJob === null
    ) {
      return;
    }
    // Declare a variable 'filterValues' 
    var filterValues;
    // Check if a filter value is provided, if it is, merge it with the search value,
    // else use only the search value
    if (props.filterValue) {
      let s = props.searchValue ? { searchText: props.searchValue } : "";
      filterValues = { ...props.filterValue, ...s };
    } else {
      filterValues = props.searchValue ? { searchText: props.searchValue } : "";
    }
    // Make an API call using axios post method
    axios
      .post(
        // The URL for the request is constructed from an environment variable and a route string
        process.env.React_App_baseUrl +
        "CompanyOwnershipJob/CompanyOwnershipGetAssetDetailForCreateJob",
         // The payload of the request is the 'selectedCompanyForCreateJob'
        selectedCompanyForCreateJob,

        // The headers for the request includes information about:
        // 1. Source filter, which is derived from the previously defined 'filterValues',
        // 2. Select all property, taken from the component's props,
        // 3. Search text, which is the search term that was inputted by the user,
        // 4. Authorization token, which is generated by the `CommonService.getToken()` method.

        {
          headers: {
            sourceFilter: filterValues
              ? encodeURIComponent(JSON.stringify(filterValues))
              : "",
            selectAll: props.isAllSelected,
            searchText: searchValue ? encodeURIComponent(searchValue) : "",
            Authorization: CommonService.getToken(),
          },
        }
      )
      // Upon successfully receiving a response, 
      .then(function (response) {
        if (response.data) {
          // Set 'jobId' with the jobId received from the API response
          setJobId(response.data.jobId);
          // Create an empty array 'excelData'
          let excelData = [];
          // If data is received in the response
          if (response.data !== undefined && response.data !== null) {
            // If 'assetDetailsForCreateJobsCODto' is present and has length more 0, then proceed
            if (
              response.data.assetDetailsForCreateJobsCODto !== undefined &&
              response.data.assetDetailsForCreateJobsCODto !== null && response.data.assetDetailsForCreateJobsCODto.length > 0
            ) {
              // Map over each item in 'assetDetailsForCreateJobsCODto' to create a new set of data for 'excelData'
              excelData = response.data.assetDetailsForCreateJobsCODto.map(
                (data, index) => {
                  if (data.lattitude === undefined || data.lattitude === null) {
                    data.lattitude = "-";
                  }
                  if (data.longitude === undefined || data.longitude === null) {
                    data.longitude = "-";
                  }
                  if (
                    data.ownershipDate === undefined ||
                    data.ownershipDate === null
                  ) {
                    data.ownershipDate = "-";
                  }
                  if (
                    data.ownershipPercentage === undefined ||
                    data.ownershipPercentage === null
                  ) {
                    data.ownershipPercentage = 0;
                  }
                  if (data.operator === undefined || data.operator === null) {
                    data.operator = "-";
                  }
                   // Return a newly structured object
                  return {
                    Id: index,
                    companyId: data.companyId,
                    asset: data.asset,
                    assetId: data.assetId,
                    commodities: data.commodities,
                    country: data.country,
                    region: data.region,
                    subRegion: data.subRegion,
                    company: data.company,
                    operator: data.operator,
                    ownershipPercentage: data.ownershipPercentage,
                    ownershipYear: data.ownershipYear,
                    lattitude: data.lattitude,
                    longitude: data.longitude,
                    isDeleted: false,
                    selected: false,
                    isListedCompany: data.isListedCompany,
                  };
                }
              );
              // Update 'uploadedExcelData' with the newly created 'excelData'
              setUploadedExcelData(excelData);
            }
          }
        }
        // Stop the loading state
        setLoader(false);
      })
       // In case of any errors during the API request, handle it
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
        setLoader(false);
      });
  };

// Define a function named 'IndividualDelete'
// It takes an 'item' parameter that will be processed within the function
const IndividualDelete = (item) => {
  // Check if 'uploadedExcelData' is neither undefined nor null
  if (uploadedExcelData !== undefined && uploadedExcelData !== null) {
      // Loop through each element in 'uploadedExcelData'
      uploadedExcelData.forEach((element) => {
          // If the Id of the current 'element' matches with the Id of 'item'
          if (element.Id === item.Id) {
              // Toggle the 'selected' property of the current 'element'
              element.selected = !element.selected;
          }
      });

      // If 'item' is selected, check if every element in 'uploadedExcelData' is also selected
      // If not, set 'selected' to false
      const selected = item.isSelected
          ? uploadedExcelData.every((el) => el.isSelected)
          : false;
      
      // Update the state of 'isAllSelected' with the value of 'selected'
      setIsAllSelected(selected);

      // Update the states of 'uploadedExcelData' and 'initialExcelData' with the modified 'uploadedExcelData'
      setUploadedExcelData([...uploadedExcelData]);
      setInitialExcelData([...uploadedExcelData]);
  }
};



  // This is a function named 'SelectAllDelete'
// It takes an event parameter that triggers the function
const SelectAllDelete = (event) => {
  // We check if 'uploadedExcelData' exists
  if (
    uploadedExcelData !== undefined &&
    uploadedExcelData !== null
  ) {
    // Iterate through each element in 'uploadedExcelData'
    uploadedExcelData.forEach((element) => {

      // Check if the 'selected' property of the element doesn't match the negation of 'isAllSelected'
      if (element.selected !== !isAllSelected) {
        // If it doesn't match, we set 'selected' to be the negation of 'isAllSelected'
        element.selected = !isAllSelected;
      }
    });

    // We then update the states of 'uploadedExcelData'
    // and 'initialExcelData' with the modified 'uploadedExcelData'
    setUploadedExcelData([...uploadedExcelData]);
    setInitialExcelData([...uploadedExcelData]);

    // Lastly, we toggle the value of 'isAllSelected'
    setIsAllSelected(!isAllSelected);
 
     // setUploadedExcelData([...uploadedExcelData.map(element=>{
      //   element.selected=!isAllSelected;
      //   return element;
      // })])
      // setInitialExcelData(uploadedExcelData);
      //const selectItems = document.getElementsByClassName('SelectCheckBox');
     
  }
};

  // This is a JavaScript function named 'searchClear'
const searchClear = () => {
  // We reset the current search value to an empty string
  setSearchValue("");
  
  // We check if the selected company for creating a job exists.
  // If it does not exist, we exit the function early (return).
  if (
    selectedCompanyForCreateJob === undefined &&
    selectedCompanyForCreateJob === null
  ) {
    return;
  }
  
  var filterValues;

  // Check if a filterValue prop exists
  if (props.filterValue) {
    // If a searchValue prop also exists, we append it to the filterValue
    let s = props.searchValue ? { searchText: props.searchValue } : "";
    filterValues = { ...props.filterValue, ...s };
  } else {
    // Otherwise, we create a new filterValues variable from searchValue.
    filterValues = props.searchValue ? { searchText: props.searchValue } : "";
  }

  // Here we use Axios to send a POST request to an API endpoint.
  axios
    .post(
      process.env.React_App_baseUrl +
        "CompanyOwnershipJob/CompanyOwnershipGetAssetDetailForCreateJob",
      selectedCompanyForCreateJob,
      {
        headers: {
          searchText: "",
          sourceFilter: filterValues
            ? encodeURIComponent(JSON.stringify(filterValues))
            : "",
          selectAll: props.isAllSelected,
          Authorization: CommonService.getToken(),
        },
      }
    )
    .then(function (response) {
      // If the response data exists, we update job Id state with the jobId from the response.
      if (response.data) {
        setJobId(response.data.jobId);
        
        let excelData = [];
        
        if (response.data !== undefined && response.data !== null) {
          // Map through data and create an array of objects for each item
          if (
            response.data.assetDetailsForCreateJobsCODto !== undefined &&
            response.data.assetDetailsForCreateJobsCODto !== null
          ) {
            excelData = response.data.assetDetailsForCreateJobsCODto.map(
              (data, index) => {
                // Processing of the data items and substituting default values if not defined
                return {
                  Id: index,
                  companyId: data.companyId,
                  asset: data.asset,
                  assetId: data.assetId,
                  commodities: data.commodities,
                  country: data.country,
                  region: data.region,
                  subRegion: data.subRegion,
                  company: data.company,
                  operator: data.operator ?? "-",
                  ownershipPercentage: data.ownershipPercentage ?? 0,
                  ownershipYear: data.ownershipYear,
                  lattitude: data.lattitude ?? "-",
                  longitude: data.longitude ?? "-",
                  isDeleted: false,
                  selected: false,
                  isListedCompany: data.isListedCompany,
                  //ownershipDate: data.ownershipDate,
                };
              }
            );

            // Handling different cases - where removedeleted has length
            // or otherwise using different setUploadedExcelData sequences
            if(removedeleted.length>0){
              const isDeleted = removedeleted.map(item => item.isDeleted);
              //shouldUseRemovedData = isDeleted.some(value => value === true);
              //  if (shouldUseRemovedData) {
              //   setUploadedExcelData(removedeleted);
              // } 
              setUploadedExcelData(excelData.filter((i)=>{
                return !removedeleted.some(r => r.assetId === i.assetId)
              }));
            }else {
              setUploadedExcelData(excelData);
            }
            //setUploadedExcelData(excelData);
          }
        }
      }
    })
    .catch(function (error) {
      // If an error occurred while making the request, we handle it using the ErrorHandling function.
      ErrorHandling(error, function (res) {
        // And show the error message.
        toasterError(res);
      });
    });
};


  
 // This is a JavaScript function named 'getYear'
const getYear = () => {
  // Here we use Axios to send a GET request to an API endpoint.
  axios
    .get(
      // We are getting the base URL from the environment variable and appending the specific API route.
      process.env.React_App_baseUrl + "DropDown/GetYears",
      {
        headers: {
          // We are setting the Authorization header with a token obtained from CommonService's getToken function.
          Authorization: CommonService.getToken()
        },
      }
    )
    .then((res) => {
      // We are checking if the response status is 200, which means the request was successful.
      if (res.status === 200) {
        // If the status is 200, we set data from the response to the 'years' state.
        setYears(res.data);
      } else {
        // If the status is not 200, we show an error message.
        toasterError("Something went wrong");
      }
    })
    .catch((err) => {
      // If an error occurred while making the request, we handle it using the ErrorHandling function.
      ErrorHandling(err, function (res) {
        // And show the error message.
        toasterError(res);
      });
    });
};


  return (
    // Create Multiple Jobs
    <LoaderWrapper loading={isLoader}>
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={3} md={4} lg={1}>
            <TextField
              id="standard-basic"
              label="Job ID"
              variant="standard"
              fullWidth
              readOnly
              defaultValue={jobId}
              value={jobId}
              InputProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={9} lg={8}>
            <TextField
              id="standard-basic"
              label="Job Name"
              variant="standard"
              onChange={(e) => {
                setJobName(e.target.value);
              }}
              fullWidth
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <CustomAutoComplete
              options={jobType}
              fullWidth
              disabled={true}
              label="Job Type"
              optionLabelKey="label"
              optionValueKey="id"
              onChange={(value) => {
                setJobTypeId(value.id);
              }}
              defaultValueKey={["id", "label"]}
              defaultValue={defaultJobType}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <TextField
              fullWidth
              id="standard-multiline-static"
              label="Job Instructions"
              multiline
              rows={2}
              onChange={(e) => {
                setJobInstruction(e.target.value);
              }}
              variant="standard"
            />

          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>

            <MultiSelect
              items={years ? years : []}
              selectAllLabel={{ label: "Select all" }}
              onChange={(e, value) => {
                if (e !== undefined && e !== null) {
                  let tempYears = e.map(ele => {
                    return { YearId: ele.id, Year: ele.year }
                  })
                  setYearsForSave(tempYears);
                  setSelectedYear(e)
                }

              }}
              getOptionLabel={"year"}
              dropLable={"year"}
              role="year"
              label={"Year"}
              required={true}

            />
          </Grid>

          <Grid item xs={12} className={classes.removeCheckMargin}>
            <Grid container spacing={0}>
              <Grid xs="auto" sx={{ mr: 1 }}>
                <Typography color="text.primary" sx={{ mt: 1 }}>
                  <strong>Select Data Points</strong>
                  <FormControlLabel sx={{ ml: 1 }}
                    control={
                      <Checkbox
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setSelectAll(true);
                            setLongitude(true);
                            setLatitude(true);
                            setOwner(true);
                            setOperator(true);
                            setOwnershipPercentage(true);
                            setOwnershipYear(true);
                            setOwnershipDate(true);
                            setStockExchange(true);
                            setSeeThroughOwners(true);
                            setJointVentures(true);
                            setUpdates(true);
                          }
                          else {
                            setSelectAll(false);
                            setLongitude(false);
                            setLatitude(false);
                            setOwner(false);
                            setOperator(false);
                            setOwnershipPercentage(false);
                            setOwnershipYear(false)
                            setOwnershipDate(false);
                            setStockExchange(false);
                            setSeeThroughOwners(false);
                            setJointVentures(false);
                            setUpdates(false);
                          }
                        }}
                        checked={isSelectAll || (longitude && latitude && owner && operator && ownershipPercentage && ownershipDate && stockExchange && seeThroughOwners && jointVentures && updates)}
                      />
                    }
                    label="Select All"
                  />
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.removeCheckMargin}>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={1.5}
                  justifyContent="left"
                  alignItems="flex-start"
                >

                  <Grid item xs={12}>

                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={"auto"}>
                        <div className={classes.subHeading} style={{ minWidth: "100px" }}>Asset Info:</div>{" "}
                      </Grid>
                      <Grid item xs="auto">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setLongitude(true);
                                } else {
                                  setLongitude(false);
                                  setSelectAll(false);
                                }
                              }}
                              checked={longitude}
                            />
                          }
                          label="Longitude"
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setLatitude(true);
                                } else {
                                  setLatitude(false);
                                  setSelectAll(false);
                                }

                              }}
                              checked={latitude}
                            />
                          }
                          label="Latitude"
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setOperator(true);
                                } else {
                                  setOperator(false);
                                  setSelectAll(false);
                                }

                              }}
                              checked={operator}
                            />
                          }
                          label="Operator"
                        />
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.removeCheckMargin}>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={1.5}
                  justifyContent="left"
                  alignItems="flex-start"
                >

                  <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={"auto"}>
                        <div className={classes.subHeading} style={{ minWidth: "100px" }}>Owner Info:</div>{" "}
                      </Grid>

                      <Grid item xs="auto">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setOwner(true);
                                } else {
                                  setOwner(false);
                                  setSelectAll(false);
                                }

                              }}
                              checked={owner}
                            />
                          }
                          label="Owner"
                        />
                      </Grid>

                      <Grid item xs="auto">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setOwnershipPercentage(true);
                                } else {
                                  setOwnershipPercentage(false);
                                  setSelectAll(false);
                                }

                              }}
                              checked={ownershipPercentage}
                            />
                          }
                          label="Ownership %"
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setOwnershipDate(true);
                                  setOwnershipYear(true)
                                } else {
                                  setOwnershipDate(false);
                                  setOwnershipYear(false)
                                  setSelectAll(false);
                                }

                              }}
                              checked={ownershipDate}
                            />
                          }
                          label="Ownership Date"
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setStockExchange(true);
                                } else {
                                  setStockExchange(false);
                                  setSelectAll(false);
                                }

                              }}
                              checked={stockExchange}
                            />
                          }
                          label="Stock Exchange"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.removeCheckMargin}>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={1.5}
                  justifyContent="left"
                  alignItems="flex-start"
                >

                  <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={"auto"}>
                        <div className={classes.subHeading} style={{ minWidth: "100px" }}>Joint Venture:</div>{" "}
                      </Grid>

                      <Grid item xs="auto">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setJointVentures(true);
                                } else {
                                  setJointVentures(false);
                                  setSelectAll(false);
                                }

                              }}
                              checked={jointVentures}
                            />
                          }
                          label="Joint Ventures"
                        />
                      </Grid>

                      <Grid item xs="auto">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setSeeThroughOwners(true);
                                } else {
                                  setSeeThroughOwners(false);
                                  setSelectAll(false);
                                }

                              }}
                              checked={seeThroughOwners}
                            />
                          }
                          label="Equity Owners"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.removeCheckMargin}>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={1.5}
                  justifyContent="left"
                  alignItems="flex-start"
                >

                  <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={"auto"}>
                        <div className={classes.subHeading} style={{ minWidth: "100px" }}>Updates:</div>{" "}
                      </Grid>
                      <Grid item xs="auto">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setUpdates(true);
                                } else {
                                  setUpdates(false);
                                  setSelectAll(false);
                                }

                              }}
                              checked={updates}
                            />
                          }
                          label="Updates"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ minHeight: "calc(100vh - 580px)"}}>
            <Box className={classes.addBorder}>
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      size="small"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="searchByClear"
                        onClick={searchClear}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6} textAlign="right">
                    <Button
                      variant="contained"
                      color="accent5"
                      size="small"
                      startIcon={<DeleteIcon style={{ fontSize: 15 }} />}
                      onClick={multipleDelete}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} >
                <TableContainer
                  sx={{ minHeight: "110px", maxHeight: "480px" }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 60, width: 60 }}
                          align="left"
                        >
                          <Checkbox
                            checked={isAllSelected}
                            onChange={SelectAllDelete}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 200, width: 200 }}
                          align="left"
                        >
                          Asset
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 150, width: 150 }}
                          align="left"
                        >
                          Commodity
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 150, width: 150 }}
                          align="left"
                        >
                          Country
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 150, width: 150 }}
                          align="left"
                        >
                          Region
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 150, width: 150 }}
                          align="left"
                        >
                          Sub Region
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 150, width: 150 }}
                          align="center"
                        >
                          Operator
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 150, width: 150 }}
                          align="left"
                        >
                          Owner
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 100, width: 100 }}
                          align="center"
                        >
                          Ownership %
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 100, width: 100 }}
                          align="center"
                        >
                          Ownership Date
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 150, width: 150 }}
                          align="center"
                        >
                          Lattitude
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 150, width: 150 }}
                          align="center"
                        >
                          Longitude
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 100, width: 100 }}
                          align="center"
                        >
                          IsListedCompany
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ minWidth: 100, width: 100 }}
                          align="center"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {uploadedExcelData !== undefined &&
                        uploadedExcelData !== null
                        ? uploadedExcelData.map((rowData) => {
                          if (!rowData.isDeleted) {
                            return (
                              <TableRow
                                hover
                                className={classes.cruTr}
                                key={rowData.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  align="left"
                                  className={classes.tableCell}
                                >
                                  <Checkbox
                                    checked={rowData.selected}
                                    onChange={(event) => {
                                      IndividualDelete(rowData);
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={classes.tableCell}
                                >
                                  {rowData.asset}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={classes.tableCell}
                                >
                                  {rowData.commodities}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={classes.tableCell}
                                >
                                  {rowData.country}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={classes.tableCell}
                                >
                                  {rowData.region}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={classes.tableCell}
                                >
                                  {rowData.subRegion}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                >
                                  {rowData.operator}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={classes.tableCell}
                                >
                                  {rowData.company}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                >
                                  {rowData.ownershipPercentage}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                >
                                  {rowData.ownershipYear}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                >
                                  {rowData.lattitude == 0
                                    ? "-"
                                    : rowData.lattitude}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                >
                                  {rowData.longitude == 0
                                    ? "-"
                                    : rowData.longitude}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                >
                                  {rowData.isListedCompany == false
                                    ? "No"
                                    : "Yes"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                >
                                  {" "}
                                  <Tooltip
                                    title="Delete"
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      aria-label="Delete"
                                      sx={{ color: "red" }}
                                    >
                                      <DeleteIcon
                                        className={classes.iconSize}
                                        onClick={() =>
                                          openAlertDialog(rowData)
                                        }
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        })
                        : ""}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="right">
              <Grid item xs={"auto"}>
                <LoadingButton
                  buttonName="Save"
                  clickHandler={save}
                  isLoading={isLoading}
                  searchValue={searchValue}
                />
                {/* <Button variant="contained" color="accent5" onClick={save}>
                  Save
                </Button> */}
              </Grid>
              <Grid item xs={"auto"}>
                <Button
                  variant="contained"
                  color="accent3"
                  onClick={() => {
                    if (props?.setClose) {
                      props.setClose(false);
                    }
                    handleADClose();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AlertDialog
          title={"Confirmation"}
          maxWidth={"sm"}
          id="alert-delete"
          open={openConfirmation}
          onClose={handleADClose}
          description="The asset will be deleted from the job. Are you sure you want to delete?"
          action={
            <Grid container spacing={2} justifyContent="right">
              <Grid item xs={"auto"}>
                <Button onClick={handleADClose}>No</Button>
              </Grid>
              <Grid item xs={"auto"}>
                <Button onClick={DeleteData} color="secondary">
                  Yes
                </Button>
              </Grid>
            </Grid>
          }
        />
      </Box>
    </Box>
    </LoaderWrapper>
  );
};

export default CreateMultipleJob;
