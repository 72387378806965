import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  // Jira Ticket Page Styles
  breadCrumbsIcon: {
    fontSize: 10,
    marginLeft: 3
  },
  tableTopPart: {
    padding: theme.spacing(2)
  },
  totalRecord: {
    color: "#00519c",
  },
  container: {
    maxHeight: 640, // Table Max Height
  },
  colHighlight: {
    padding: theme.spacing(0, 0, 0, 1),
  },
  tableHeader: {
    padding: theme.spacing(0, 0.75) + '!important',
    whiteSpace: "normal",
    fontWeight: 'bold !important',
    height: "46px",
  },
  tableCell: {
    wordBreak: "break-all",
    wordWrap: "break-word",
    borderBottom: "1px solid #d7d7d7 !important",
    padding: theme.spacing(0, 0.75) + '!important',
    height: "46px",
  },
  tableLink: {
    cursor: 'pointer',
    color: '#01579b',
    padding: theme.spacing(1.5, 1.5),
    "&:hover": {

    },
  },
  tableBorder: {
    border: '1px solid #d7d7d7',
    borderRadius: 6,
    overflow: 'hidden',
  },
  tableTopPart: {
    padding: theme.spacing(2)
  },
  addBorder: {
    borderRadius: "6px",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  buttonNoWrap: {
    whiteSpace: "nowrap"
  },
  
  // Popup Styles
  popupArea: {
    padding: theme.spacing(3),
  },
  popupHeading: {
    padding: theme.spacing(0, 0, 3, 0),
  },

}));

export default useStyles;