/* eslint-disable no-undef */
import React, { useState } from "react";
import {
  Autocomplete,
  Box, Button, Checkbox, Chip, FormControlLabel, Grid, IconButton, InputAdornment, Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography
} from "@mui/material";
import axios from "axios";

import { toast } from "react-toastify";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import DatePicker from "../../../UIComponent/DatePicker";
import AlertDialog from "../../../UIComponent/AlertDialog";
import CustomAutoComplete from "../../../UIComponent/CustomAutoComplete";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
import { LoaderWrapper } from "../../../UIComponent/Progress";

// Importing useStyles and Styles
import "../../../../src/index.scss";
import "../../../Resources/scss/layout.scss";
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./jobManagement.styles";

// Import Fluent UI Icons
import { AcceptIcon, CancelIcon, ClearIcon, DeleteIcon, EditIcon, SearchIcon } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../Services/CommonService";
import MultiSelect from "../../../UIComponent/MultiSelect/MultiSelect";

const EditJobRecords = (props) => {
  // Declaration of States and Component Variables Start Here
  const [atat, setATat] = useState();
  const [userData, setUserData] = useState({ jobId: 0, jobName: "", jobInstructions: "", });
  let [tableRow, setTableRow] = useState();
  const [arrayData, stArrayData] = useState([]);
  let [jobtype, setJobType] = useState([{ id: 1, label: "Company Changes" }]);
  const [searchValue, setSearchValue] = React.useState("");
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [finalData, setFinalData] = useState();
  const [isEdited, setIsEdited] = useState(true);
  const [deletedRow, setDeletedRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [remainingDays, setRemainingDays] = React.useState("0");
  const [rawRowData, setRawRowData] = useState({
    jobId: props.editJobNumber, jobName: "", jobInstructions: "", jobAsset: [
      {
        jobAssetId: 0, priorityId: 0, startDate: "", tat: "", remainingDays: "", isEdited: false, isDeleted: false, companyId: 0,
        isCRUCompany: true, assetId: 0, isCRUAsset: true,
      },
    ], priorityName: "",
  });
  let [priorities, setpriotity] = useState();
  const [asJobAsset, setAsJobAsset] = useState({
    jobAssetId: 0, priorityId: 0, startDate: "", tat: "", remainingDays: "", isEdited: true, isDeleted: true, companyId: 0,
    isCRUCompany: true, assetId: 0, isCRUAsset: true,
  });
  const [formData, setFormData] = useState({
    jobId: props.editJobNumber, jobName: "", jobInstructions: "", jobAsset: [asJobAsset], checkJobStartValue: false
  });
  const [updateApi, setUpdateApi] = useState({
    jobId: "", jobNo: "", jobName: "", jobInstructions: "", tat: "", priorityId: "",
    priorityName: "", jobAsset: [{
      jobAssetId: 0, priorityId: 0, tat: "", assignedTo: "", assignedToId: 0, isEdited: false,
      isDeleted: false, companyId: 0, isCRUCompany: true, assetId: 0, isCRUAsset: true,
    },
    ],
    DataPointsCODto: {
      lattitude: false,
      longitude: false,
      owner: false,
      joinVentures: false,
      ownershipDate: false,
      operator: false,
      ownershipPercentage: false,
      seeThroughOwners: false,
      stockExchange: false,
      updates: false
    },
    JobYearsDto: []
  });
  const [assign, setAssign] = useState();
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [longitude, setLongitude] = useState(false);
  const [latitude, setLatitude] = useState(false);
  const [owner, setOwner] = useState(false);
  const [operator, setOperator] = useState(false);
  const [isListedCompany, setIsListedCompany] = useState(false);
  const [ownershipDate, setOwnershipDate] = useState(false);
  const [stockExchange, setStockExchange] = useState(false);
  const [ownershipPercentage, setOwnershipPercentage] = useState(false);
  const [seeThroughOwners, setSeeThroughOwners] = useState(false);
  const [jointVentures, setJointVentures] = useState(false);
  const [updates, setUpdates] = useState(false);
  const [selectAllDataPoints, setSelectAllDataPoints] = useState(false);
  const [isLoader, setLoader] = useState(false);
  // Declaration of States and Component Variables End Here

  function createPirorityData(label, id) { return { label, id }; }
  function createAssignData(name, userId) { return { name, userId }; }
  const globalSearch = (value) => { setSearchValue(value); };
  const handleADClose = () => { setOpenSaveConfirmation(false); };

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    getYear();
    axios.get(process.env.React_App_baseUrl + "DropDown/GetPriorityDropDownFilter", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        const rows = response.data.map((row) =>
          createPirorityData(row.priorityName, row.id)
        );
        setpriotity(rows);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
  }, []);

  React.useEffect(() => {
    axios.get(process.env.React_App_baseUrl + "DropDown/GetAssignedToFilterAsync", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        const rows = response.data.map((row) =>
          createAssignData(row.name, row.userId)
        );
        setAssign(rows);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
  }, []);

  React.useEffect(() => {
    getDetailsForEdit(props.jobIds, props.companyIds);
    setFormData({ ...formData, jobIds: props.editJobNumber, checkJobStartValue: false });

  }, []);
  // Declaration of React Hooks End Here

  // Define a function named 'submitForEdit' 
const submitForEdit = () => {

  // Set the loading state to true. This might be used for showing a spinner/loading icon on the UI while the processing is carried out.
  setLoading(true);

  // Check if the 'jobName' field in the 'updateApi' object is empty, if so show an error message and stop further execution.
  if (updateApi.jobName === "") {
    toasterError("Please check all the required fields");
    setLoading(false);
    return;
  }

  // Check if the 'JobYearsDto' field in 'updateApi' is undefined, null or empty. If it is, show an error message and stop further execution.
  if (updateApi.JobYearsDto === undefined || updateApi.JobYearsDto === null || updateApi.JobYearsDto.length === 0) {
    toasterError("Please check all the required fields");
    setLoading(false);
    return;
  }

  // Initialize the 'jobAsset' array in 'updateApi' to being empty.
  updateApi.jobAsset = []

  // Then set 'jobAsset' in 'updateApi' with contents of 'tableRow'.
  updateApi.jobAsset = tableRow;

  // If there are any deleted rows, append them to the 'jobAsset' array in 'updateApi'
  if (deletedRow.length) {
    updateApi.jobAsset = [...updateApi.jobAsset, ...deletedRow]
  }

  // Send a post request to the specified API path with 'updateApi' as the body. In headers pass 'Authorization' which can be either a bearer token or null
  axios.post(`${process.env.React_App_baseUrl}job/UpdateManageJobRecordsCompanyDetails`, updateApi, {
    headers: {
      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
  })
    .then((res) => {
      // If the response status is 200, show a success message, hide side drawer and set 'loading' to false after a delay of 4 seconds.
      if (res.status === 200) {
        toasterSuccess("Successfully Updated");
        props.hideSideDrawer();
        setTimeout(function () {
          setLoading(false);
        }, 4000)
      } else {
        // If the response status is other than 200, just set 'loading' to false.
        setLoading(false);
      }
    })
    // In case of any errors during the API call, set 'loading' to false and show an error toaster if error status is 400.
    .catch((err) => {
      setLoading(false);
      if (err.response.status === 400) {
        toasterError(err.response.data);
      }
    });
};

  
// Define a function named 'getDetailsForEdit' which accepts 'jobId' and 'companyId' as parameters.
const getDetailsForEdit = (jobId, companyId) => {

  // Set the loader to true. This might be used for showing a spinner/loading icon on UI.
  setLoader(true);

  // If jobId is 0, it's replaced with the jobId property of the companyId object.
  if (jobId === 0) {
    jobId = companyId.jobId;
  }

  // Define axios headers including jobId, companyId, whether or not it is a CRU company, and an Authorization token.
  const axiosConfig = {
    headers: {
      jobId: jobId, companyId: props.companyIds.companyID, isCRUCompany: props.companyIds.isCRUCompany,
      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    },
  };

  // Send a GET request to retrieve company job details.
  axios.get(process.env.React_App_baseUrl + "companyownershipjob/CompanyOwnershipGetJobCompanyAssetDetailForEdit", axiosConfig)
    .then((res) => {
      setLoader(false);  // Set loader to false indicating that loading has finished.
      setRawRowData(res.data);  // Set raw row data with the response from server.

      let ob = [];  // Initialize an empty array to push objects into later.

      // For each element in 'companyAssetDetailsDto', check if it's not in 'deletedRow'. If not, then push it to 'ob' array after formatting date fields.
      res?.data?.companyAssetDetailsDto?.forEach((el) => {
        if (!deletedRow?.some(x => x.assetId === el.assetId && x.companyId === el.companyId && x.isCRUAsset === el.isCRUAsset)) {
          ob.push({
            ...el, tat: el.tat.split("-").reverse().join("-"),
            startDate: el.startDate ? el.startDate.split("-").reverse().join("-") : null
          });
        }
      });
      setTableRow(ob);  // Set 'tableRow' state with the processed data in 'ob'.

      // If 'dataPointsCODto' exists in response, set various states with its respective values.
      if (res?.data?.dataPointsCODto) {
        setLongitude(res?.data?.dataPointsCODto?.longitude)
        setLatitude(res?.data?.dataPointsCODto?.lattitude)
        setOwner(res?.data?.dataPointsCODto?.owner)
        setOperator(res?.data?.dataPointsCODto?.operator)
        setOwnershipDate(res?.data?.dataPointsCODto?.ownershipDate)
        setStockExchange(res?.data?.dataPointsCODto?.stockExchange)
        setOwnershipPercentage(res?.data?.dataPointsCODto?.ownershipPercentage)
        setSeeThroughOwners(res?.data?.dataPointsCODto?.seeThroughOwners)
        setJointVentures(res?.data?.dataPointsCODto?.joinVentures)
        setUpdates(res?.data?.dataPointsCODto?.updates)

        // If all datapoint fields are not null, then set 'setSelectAllDataPoints' to true.
        if (res?.data?.dataPointsCODto?.longitude
          && res?.data?.dataPointsCODto?.lattitude
          && res?.data?.dataPointsCODto?.owner
          && res?.data?.dataPointsCODto?.operator
          && res?.data?.dataPointsCODto?.ownershipDate
          && res?.data?.dataPointsCODto?.stockExchange
          && res?.data?.dataPointsCODto?.ownershipPercentage
          && res?.data?.dataPointsCODto?.seeThroughOwners
          && res?.data?.dataPointsCODto?.joinVentures
          && res?.data?.dataPointsCODto?.updates) {
          setSelectAllDataPoints(true);
        }
      }

      // If 'jobYearsDto' exists in response, set 'selectedYear' state with its yearId and year values.
      if (res?.data?.jobYearsDto) {
        let tempSelectedYears = res.data.jobYearsDto.map(ele => {
          return { id: ele.yearId, year: ele.year }
        });
        setSelectedYear(tempSelectedYears);
      }
      
      // Set 'tableRow' state again(same as previous).
      setTableRow(ob);

      const data = res.data;  // Create a constant 'data' to shorten later codes.

      const jobAsset = [];  // Initialize an empty array to push objects into later.

      // For each item in 'companyAssetDetailsDto', check if it's not in 'deletedRow'. If not, then push it to 'jobAsset' array after formatting date fields.
      // Otherwise, mark it as deleted and still store in 'jobAsset'.
      data.companyAssetDetailsDto.map((item) => {
        if (!deletedRow?.some(x => x.assetId === el.assetId && x.companyId === el.companyId && x.isCRUAsset === el.isCRUAsset)) {
          jobAsset.push({
            ...updateApi.jobAsset[0],
            ...item,
            assignedTo: item.assignedTo,
            priorityId: item.priorityId,
            tat: item.tat.split("-").reverse().join("-"),
            startDate: item.startDate ? item.startDate.split("-").reverse().join("-") : null,
            priorityName: item.priorityName,
            jobAssetId: item.jobAssetId,
          })
        } else {
          jobAsset.push({
            ...updateApi.jobAsset[0],
            ...item,
            assignedTo: item.assignedTo,
            priorityId: item.priorityId,
            tat: item.tat.split("-").reverse().join("-"),
            startDate: item.startDate ? item.startDate.split("-").reverse().join("-") : null,
            priorityName: item.priorityName,
            jobAssetId: item.jobAssetId,
            isDeleted: true
          })
        }
      });

      // Set 'updateApi' state with the received 'data' and modified 'jobAsset'.
      setUpdateApi({
        ...updateApi,
        jobNo: data.jobNo,
        jobId: data.jobId,
        tat: data.tat,
        DataPointsCODto: {
          lattitude: data?.dataPointsCODto?.lattitude,
          longitude: data?.dataPointsCODto?.longitude,
          owner: data?.dataPointsCODto?.owner,
          joinVentures: data?.dataPointsCODto?.joinVentures,
          ownershipDate: data?.dataPointsCODto?.ownershipDate,
          operator: data?.dataPointsCODto?.operator,
          ownershipPercentage: data?.dataPointsCODto?.ownershipPercentage,
          seeThroughOwners: data?.dataPointsCODto?.seeThroughOwners,
          stockExchange: data?.dataPointsCODto?.stockExchange,
          updates: data?.dataPointsCODto?.updates
        },
        startDate: data.startDate ? data.startDate.split("-").reverse().join("-") : null,
        jobName: data.jobName,
        jobInstructions: data.jobInstructions,
        priorityId: data.priorityId,
        priorityName: data.priorityName,
        jobAsset,
        JobYearsDto: res?.data?.jobYearsDto
      });
    })
    .catch((err) => {
      ErroHandling(err, function (res) {  // Error handling with custom 'ErrorHandling' function.
        toasterError(res)
      })
      setLoader(true);  // Set loader to true indicating that there was an error and loading wasn't complete.
    });
};


  // Function for Search Clear
  const searchClear = () => { setSearchValue(""); searchUser(""); };

  // Function for Handle Data Change
  const handleDateChange = (e) => {
    let selectedDate = new Date(e);
    let date = selectedDate.getFullYear() + "-" + ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
      "-" + ("0" + selectedDate.getDate()).slice(-2);
    setFormData({ ...formData, tat: date, checkJobStartValue: false });
  };

  const classes = useStyles();
  var valueData = { jobId: "000" };

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { getDetailsForEdit(props.jobIds, props.companyIds); }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      searchUser(searchValue);
    }
  };
 
  // Function for Search User
  const searchUser = (filterValue) => {
    const axiosConfig = {
      headers: {
        jobId: props.compData.jobId, companyId: props.compData.companyId, searchText: filterValue ? encodeURIComponent(filterValue) : "", isCRUCompany: props.compData.isCRUCompany,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
    };
    axios.get(process.env.React_App_baseUrl + "companyownershipjob/CompanyOwnershipGetJobCompanyAssetDetailForEdit", axiosConfig)
      .then((res) => {
        setRawRowData(res.data);
        let ob = [];

        res?.data?.companyAssetDetailsDto?.forEach((el) => {
          if (!deletedRow?.some(x => x.assetId === el.assetId && x.companyId === el.companyId && x.isCRUAsset === el.isCRUAsset)) {
            ob.push({
              ...el, tat: el.tat.split("-").reverse().join("-"),
              startDate: el.startDate ? el.startDate.split("-").reverse().join("-") : null
            });
          }
        });
        setTableRow(ob);
        const data = res.data;
        const jobAsset = [];
        data.companyAssetDetailsDto.map((item) => {
          if (!deletedRow?.some(x => x.assetId === el.assetId && x.companyId === el.companyId && x.isCRUAsset === el.isCRUAsset)) {
            jobAsset.push({
              ...updateApi.jobAsset[0],
              ...item,
              assignedTo: item.assignedTo,
              priorityId: item.priorityId,
              tat: item.tat.split("-").reverse().join("-"),
              startDate: item.startDate ? item.startDate.split("-").reverse().join("-") : null,
              jobAssetId: item.jobAssetId,
              isDeleted: false
            })
          } else {
            jobAsset.push({
              ...updateApi.jobAsset[0],
              ...item,
              assignedTo: item.assignedTo,
              priorityId: item.priorityId,
              tat: item.tat.split("-").reverse().join("-"),
              startDate: item.startDate ? item.startDate.split("-").reverse().join("-") : null,
              jobAssetId: item.jobAssetId,
              isDeleted: true
            })
          }
        }
        );
        if (res?.data?.dataPointsCODto) {
          setLongitude(res?.data?.dataPointsCODto?.longitude)
          setLatitude(res?.data?.dataPointsCODto?.lattitude)
          setOwner(res?.data?.dataPointsCODto?.owner)
          setOperator(res?.data?.dataPointsCODto?.operator)
          setOwnershipDate(res?.data?.dataPointsCODto?.ownershipDate)
          setStockExchange(res?.data?.dataPointsCODto?.stockExchange)
          setOwnershipPercentage(res?.data?.dataPointsCODto?.ownershipPercentage)
          setSeeThroughOwners(res?.data?.dataPointsCODto?.seeThroughOwners)
          setJointVentures(res?.data?.dataPointsCODto?.joinVentures)
          setUpdates(res?.data?.dataPointsCODto?.updates)
          if (res?.data?.dataPointsCODto?.longitude
            && res?.data?.dataPointsCODto?.lattitude
            && res?.data?.dataPointsCODto?.owner
            && res?.data?.dataPointsCODto?.operator
            && res?.data?.dataPointsCODto?.ownershipDate
            && res?.data?.dataPointsCODto?.stockExchange
            && res?.data?.dataPointsCODto?.ownershipPercentage
            && res?.data?.dataPointsCODto?.seeThroughOwners
            && res?.data?.dataPointsCODto?.joinVentures
            && res?.data?.dataPointsCODto?.updates) {
            setSelectAllDataPoints(true);
          }
        }
        if (res.data.jobYearsDto) {
          let tempSelectedYears = res.data.jobYearsDto.map(ele => {
            return { id: ele.yearId, year: ele.year }
          });
          setSelectedYear(tempSelectedYears);
        }
        setUpdateApi({
          ...updateApi,
          jobId: data.jobId,
          jobNo: data.jobNo,
          jobName: data.jobName,
          jobInstructions: data.jobInstructions,
          tat: data.tat,
          startDate: data.startDate ? data.startDate.split("-").reverse().join("-") : null,
          priorityName: data.priorityName,
          jobAsset,
          DataPointsCODto: {
            lattitude: data?.dataPointsCODto?.lattitude,
            longitude: data?.dataPointsCODto?.longitude,
            owner: data?.dataPointsCODto?.owner,
            joinVentures: data?.dataPointsCODto?.joinVentures,
            ownershipDate: data?.dataPointsCODto?.ownershipDate,
            operator: data?.dataPointsCODto?.operator,
            ownershipPercentage: data?.dataPointsCODto?.ownershipPercentage,
            seeThroughOwners: data?.dataPointsCODto?.seeThroughOwners,
            stockExchange: data?.dataPointsCODto?.stockExchange,
            updates: data?.dataPointsCODto?.updates
          }
        });
      })
      .catch((err) => {
        { ErroHandling(err, function (res) { toasterError(res) }) }
      });
  };


// Define a function named 'CommonJobStartDate' accepting parameters 'e' (event object) and 'value'.
const CommonJobStartDate = (e, value) => {

  // If 'startDate' field exists in rawRowData, proceed with further operations.
  if (rawRowData.startDate) {

    // Update the formData state by setting checkJobStartValue to the passed value.
    setFormData({ ...formData, checkJobStartValue: value })

    // If value is truthy, perform the following set of operations.
    if (value) {

      let updatedRow = [];  // Initialize an empty array which will hold processed objects.

      // Create a Promise goes through each element in 'tableRow', 
      // perform API call to get number of days based on asset details, and process start date and other dates accordingly.
      let promise = Promise.all(tableRow.map(async (element) => {
        if (element.companyId > 0 && element.assetId > 0) {
          let assetDetails = {
            companyId: element.companyId,
            assetId: element.assetId,
            isCRUCompany: element.isCRUCompany,
            isCRUAsset: element.isCRUAsset
          }

          await AsyncGetNoOfDays(assetDetails).then((res) => {
            let startDate = rawRowData.startDate;
            let tempStartDate = new Date(rawRowData.startDate);
            
            // If start date is invalid, convert it into a valid date using 'GetDateInReverse' function.
            if (tempStartDate.toString().toLowerCase() === 'Invalid Date'.toLowerCase()) {
              tempStartDate = new Date(CommonService.GetDateInReverse(rawRowData.startDate))
              startDate = CommonService.GetDateInReverse(rawRowData.startDate);
            }
            
            let g = CommonService.addWorkingDays(tempStartDate, res.data)
            let newTatDate = CommonService.GetDateAsStringWithDash(g)
            let remainingDaysTemp = CommonService.getBusinessDatesCount(g)

            // Push processed start date, remainingDays, tat and isEdited flag into 'updatedRow' array.
            updatedRow.push({ ...element, startDate: startDate, remainingDays: remainingDaysTemp, tat: newTatDate, isEdited: true })

          })
        }
      }))

      // After the Promise is resolved, if there's any row updated, set those rows to tableRow.
      promise.then(() => {
        if (updatedRow.length > 0) {
          setTableRow(updatedRow)
        }
      }).catch((error) => {
        console.log(error);  // Log the error if any occurs during the process.
      })
    }

  } else {

    // If 'startDate' doesn't exist in rawRowData, show an error message saying "Please select Job Start Date".
    toasterError("Please select Job Start Date");
  }
}

// Define a function 'getYear' to fetch years from an API endpoint.
const getYear = () => {

  // Make an HTTP GET request to the API endpoint using Axios library.
  axios
    .get(
      process.env.React_App_baseUrl + "DropDown/GetYears",  // Combine base URL with endpoint path.
      {
        headers: { 
          // Set 'Authorization' HTTP header with the token received from 'CommonService.getToken()' function.
          Authorization: CommonService.getToken()
        },
      }
    )

    // Then, once the request is successful, proceed with the following operations.
    .then((res) => {

      // If HTTP status code is 200 (OK), update the 'years' state with the data received from the response.
      if (res.status === 200) {
        setYears(res.data);
      } else {
        // If HTTP status code is not 200, show an error toast message "Something went wrong".
        toasterError("Something went wrong");
      }

    })

    // Catch any errors that occur during the making of the HTTP request or in the subsequent then() block. 
    .catch((err) => {

      // Using an 'ErroHandling' function, handle the occurred error.
      // The err object contains information about the error.
      // On the completion of the 'ErroHandling' function, display the result in an error toast message.
      ErroHandling(err, function (res) {
        toasterError(res);
      });

    });
};


// Define an async function 'AsyncGetNoOfDays'.
const AsyncGetNoOfDays = async (finalAssetDetailObject) => {

  // This function will make an HTTP GET request to the API endpoint using Axios library and return a Promise.
  // The Promise will resolve with the response from the server or reject with an error.

  // The URL for the request is the combination of a base URL stored in an environment variable and the endpoint path.
  // Headers for the request are constructed based on input object 'finalAssetDetailObject' and a token stored in localStorage.
  return axios.get(
    `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
      headers: {

        // Set 'CompanyId' header using companyId property from finalAssetDetailObject
        CompanyId: finalAssetDetailObject.companyId,

        // Set 'AssetId' header using assetId property from finalAssetDetailObject
        AssetId: finalAssetDetailObject.assetId,

        // Set 'IsCRUCompany' header using isCRUCompany property from finalAssetDetailObject
        IsCRUCompany: finalAssetDetailObject.isCRUCompany,

        // Set 'IsCRUAsset' header using isCRUAsset property from finalAssetDetailObject
        IsCRUAsset: finalAssetDetailObject.isCRUAsset,

        // Set 'Authorization' header with the token retrieved from local storage.
        // If no token is present in local storage, it sets Authorization header to null.
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
};


  return (
    <>
    <LoaderWrapper loading={isLoader}>
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={3} lg={1}>
            <TextField
              id="standard-basic"
              label="Job ID"
              variant="standard"
              fullWidth
              readOnly
              defaultValue={updateApi.jobNo || " "}
              value={updateApi.jobNo || " "}
              InputProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={7}>
            <TextField
              InputProps={{ shrink: true, }}
              InputLabelProps={props.crudState === "Edit" ? { shrink: true } : null}
              id="standard-basic"
              label="Job Name"
              variant="standard"
              onChange={(e) => {
                setUpdateApi({ ...updateApi, jobName: e.target.value, });
              }}
              defaultValue={updateApi.jobName || " "}
              value={updateApi.jobName || " "}
              fullWidth
              required={true}
            />

          </Grid>
          <Grid item xs={12} md={3} lg={4}>
            <CustomAutoComplete
              options={jobtype}
              disabled={true}
              disableClearable={true}
              label="Job Type"
              optionLabelKey="label"
              optionValueKey="id"
              defaultValueKey={["id", "label"]}
              defaultValue={[1, "Company Changes"]}
            />
          </Grid>
          <Grid item xs={12} md={9} lg={6}>
            <TextField
              fullWidth
              id="standard-multiline-static"
              label="Job Instructions"
              multiline
              rows={2}
              value={updateApi.jobInstructions}
              onChange={(e) => {
                setUpdateApi({ ...updateApi, jobInstructions: e.target.value, });
              }}
              variant="standard"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3} sx={{ mt: 3 }}>
            <TextField
              id="standard-basic"
              label="Priority"
              variant="standard"
              fullWidth
              disabled
              value={updateApi?.priorityName}
              InputProps={{ shrink: true, }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3} sx={{ mt: 3 }}>

            <DatePicker
              labelTitle="TAT"
              variant="standard"
              disabled
              defaultDate={rawRowData.tat ? rawRowData.tat : null}
              onChangeDate={(e) => {
                let selectedDate = new Date(e);
                let date = selectedDate.getFullYear() + "-" + (selectedDate.getMonth() + 1) + "-" +
                  selectedDate.getDate();
                setUpdateApi({ ...updateApi, tat: date });
              }}
            />
          </Grid>


          <Grid item xs={12} md={6} lg={3} >

            <DatePicker
              labelTitle="Job Start Date"
              variant="standard"
              disabled={false}
              defaultDate={rawRowData.startDate ? rawRowData.startDate : null}
              onChangeDate={(e) => {
                if (e !== null && new Date(e).toDateString() !== 'Invalid Date') {
                  let selectedDate = new Date(e);
                  let date = CommonService.GetDateAsStringWithDash(selectedDate)
                  setUpdateApi({ ...updateApi, startDate: date })
                  setRawRowData({ ...rawRowData, startDate: date })
                  setFormData({ ...formData, checkJobStartValue: false })
                } else {
                  setUpdateApi({ ...updateApi, startDate: undefined })
                  setRawRowData({ ...rawRowData, startDate: undefined })
                  setFormData({ ...formData, checkJobStartValue: undefined })
                }

              }}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={4} xl={3} sx={{ mt: 2 }}>
            <FormControlLabel control={<Checkbox checked={formData.checkJobStartValue} onChange={(e, value) => CommonJobStartDate(e, value)} />} label="Apply Common Job Start Date" />
          </Grid>
          <Grid item xs={12} md={5} lg={4} xl={3}>
            <MultiSelect
              value={selectedYear}
              selectAllLabel={{ year: "Select all" }}
              items={years ? years : []}
              getOptionLabel={(option) => option.year.toString()}
              label="Year"
              onChange={(key, value, reason) => {
                if (value !== null && value !== undefined) {
                  setSelectedYear(value);
                  let tempYears = value.map(ele => {
                    return { YearId: ele.id, Year: ele.year }
                  })
                  setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, JobYearsDto: tempYears }));

                }
              }}
              role="year"
              key="year"
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid xs={12} sx={{ mr: 1 }}>
                <Typography color="text.primary" sx={{ mt: 1 }}>
                  <strong>Select Data Points</strong>
                  <FormControlLabel sx={{ ml: 1 }}
                    control={
                      <Checkbox
                        onChange={(event, value) => {
                          if (value !== null && value !== undefined) {
                            setLongitude(value)
                            setLatitude(value)
                            setOwner(value)
                            setOperator(value)
                            setOwnershipDate(value)
                            setStockExchange(value)
                            setOwnershipPercentage(value)
                            setSeeThroughOwners(value)
                            setJointVentures(value)
                            setUpdates(value)
                            setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, lattitude: value, longitude: value, owner: value, operator: value, ownershipDate: value, ownershipPercentage: value, stockExchange: value, seeThroughOwners: value, joinVentures: value, updates: value } }));
                          }
                          setSelectAllDataPoints(!selectAllDataPoints);

                        }}
                        checked={selectAllDataPoints}
                      />
                    }
                    label="Select All"
                  />
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={1.5}
                  justifyContent="left"
                  alignItems="flex-start"
                >

                  <Grid
                    item
                    xs={12}
                  >
                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={"auto"}>
                        <div className={classes.subHeading} style={{ minWidth: "100px" }}>Asset Info:</div>{" "}
                      </Grid>
                      <Grid item xs={"auto"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event, value) => {
                                if (value !== undefined && value !== null) {
                                  setLongitude(!longitude);
                                  setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, lattitude: value } }));
                                  if (value
                                    && latitude
                                    && owner
                                    && operator
                                    && ownershipDate
                                    && stockExchange
                                    && ownershipPercentage
                                    && seeThroughOwners
                                    && jointVentures
                                    && updates) {
                                    setSelectAllDataPoints(true);
                                  } else {
                                    setSelectAllDataPoints(false);
                                  }
                                }

                              }}
                              checked={longitude}
                            />
                          }
                          label="Longitude"
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event, value) => {

                                if (value !== undefined && value !== null) {
                                  setLatitude(!latitude);
                                  setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, longitude: value } }));
                                  if (longitude
                                    && value
                                    && owner
                                    && operator
                                    && ownershipDate
                                    && stockExchange
                                    && ownershipPercentage
                                    && seeThroughOwners
                                    && jointVentures
                                    && updates) {
                                    setSelectAllDataPoints(true);
                                  } else {
                                    setSelectAllDataPoints(false);
                                  }
                                }
                              }}
                              checked={latitude}
                            />
                          }
                          label="Latitude"
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event, value) => {
                                if (value !== undefined && value !== null) {
                                  setOperator(!operator);
                                  setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, operator: value } }));
                                  if (longitude
                                    && latitude
                                    && owner
                                    && value
                                    && ownershipDate
                                    && stockExchange
                                    && ownershipPercentage
                                    && seeThroughOwners
                                    && jointVentures
                                    && updates) {
                                    setSelectAllDataPoints(true);
                                  } else {
                                    setSelectAllDataPoints(false);
                                  }
                                }
                              }}
                              checked={operator}
                            />
                          }
                          label="Operator"
                        />
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>


              <Grid
                item
                xs={12}
              >
                <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                  <Grid item xs={"auto"}>
                    <div className={classes.subHeading} style={{ minWidth: "100px" }}>Owner Info:</div>{" "}
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {
                            if (value !== undefined && value !== null) {
                              setOwner(!owner);
                              setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, owner: value } }));
                              if (longitude
                                && latitude
                                && value
                                && operator
                                && ownershipDate
                                && stockExchange
                                && ownershipPercentage
                                && seeThroughOwners
                                && jointVentures
                                && updates) {
                                setSelectAllDataPoints(true);
                              } else {
                                setSelectAllDataPoints(false);
                              }
                            }
                          }}
                          checked={owner}
                        />
                      }
                      label="Owner"
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {

                            if (value !== undefined && value !== null) {
                              setOwnershipPercentage(!ownershipPercentage);
                              setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, ownershipPercentage: value } }));
                              if (longitude
                                && latitude
                                && owner
                                && operator
                                && ownershipDate
                                && stockExchange
                                && value
                                && seeThroughOwners
                                && jointVentures
                                && updates) {
                                setSelectAllDataPoints(true);
                              } else {
                                setSelectAllDataPoints(false);
                              }
                            }
                          }}
                          checked={ownershipPercentage}
                        />
                      }
                      label="Ownership %"
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {

                            if (value !== undefined && value !== null) {
                              setOwnershipDate(!ownershipDate);
                              setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, ownershipDate: value } }));
                              if (longitude
                                && latitude
                                && owner
                                && operator
                                && value
                                && stockExchange
                                && ownershipPercentage
                                && seeThroughOwners
                                && jointVentures
                                && updates) {
                                setSelectAllDataPoints(true);
                              } else {
                                setSelectAllDataPoints(false);
                              }
                            }

                          }}
                          checked={ownershipDate}
                        />
                      }
                      label="Ownership Date"
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {
                            if (value !== undefined && value !== null) {
                              setStockExchange(!stockExchange);
                              setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, stockExchange: value } }));
                              if (longitude
                                && latitude
                                && owner
                                && operator
                                && ownershipDate
                                && value
                                && ownershipPercentage
                                && seeThroughOwners
                                && jointVentures
                                && updates) {
                                setSelectAllDataPoints(true);
                              } else {
                                setSelectAllDataPoints(false);
                              }
                            }
                          }}
                          checked={stockExchange}
                        />
                      }
                      label="Stock Exchange"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                  <Grid item xs={"auto"}>
                    <div className={classes.subHeading} style={{ minWidth: "100px" }}>Joint Venture:</div>{" "}
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {
                            if (value !== undefined && value !== null) {
                              setJointVentures(!jointVentures);
                              setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, joinVentures: value } }));
                              if (longitude
                                && latitude
                                && owner
                                && operator
                                && ownershipDate
                                && stockExchange
                                && ownershipPercentage
                                && seeThroughOwners
                                && value
                                && updates) {
                                setSelectAllDataPoints(true);
                              } else {
                                setSelectAllDataPoints(false);
                              }
                            }
                          }}
                          checked={jointVentures}
                        />
                      }
                      label="Joint Ventures"
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {

                            if (value !== undefined && value !== null) {
                              setSeeThroughOwners(!seeThroughOwners);
                              setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, seeThroughOwners: value } }));
                              if (longitude
                                && latitude
                                && owner
                                && operator
                                && ownershipDate
                                && stockExchange
                                && ownershipPercentage
                                && value
                                && jointVentures
                                && updates) {
                                setSelectAllDataPoints(true);
                              } else {
                                setSelectAllDataPoints(false);
                              }
                            }
                          }}
                          checked={seeThroughOwners}
                        />
                      }
                      label="Equity Owners"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                  <Grid item xs={"auto"}>
                    <div className={classes.subHeading} style={{ minWidth: "100px" }}>Updates:</div>{" "}
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {
                            if (value !== undefined && value !== null) {
                              setUpdates(!updates);
                              setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, updates: value } }));
                              if (longitude
                                && latitude
                                && owner
                                && operator
                                && ownershipDate
                                && stockExchange
                                && ownershipPercentage
                                && seeThroughOwners
                                && jointVentures
                                && value) {
                                setSelectAllDataPoints(true);
                              } else {
                                setSelectAllDataPoints(false);
                              }
                            }
                          }}
                          checked={updates}
                        />
                      }
                      label="Updates"
                    />
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={0} className={classes.addBorder}>
              <div className="d-flex table-optons justify-content-between m-3">
                <div>
                  <TextField
                    id="input-with-icon-textfield"
                    className="serchByKeyword mt-1"
                    size="small"
                    value={searchValue}
                    placeholder="Search by Keyword"
                    onKeyUp={handleKeyUp}
                    onChange={(e) => globalSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                    }}
                    variant="standard"
                  />
                  {searchValue ? (
                    <IconButton aria-label="Add" className="searchByClear" onClick={searchClear}>
                      <ClearIcon style={{ fontSize: 10 }} />
                    </IconButton>
                  ) : ("")}
                </div>
              </div>

              <TableContainer component={Paper} elevation={0}
                sx={{ "& > *": { borderBottom: "unset" }, boxShadow: 0, maxHeight: "calc(100vh - 440px)" }}
                className={classes.cruTable}>
                <Table aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader} style={{ minWidth: 300 }}>
                        Owner
                      </TableCell>
                      <TableCell className={classes.tableHeader} style={{ minWidth: 200 }}>
                        Owner Percentage
                      </TableCell>
                      <TableCell className={classes.tableHeader} style={{ minWidth: 200 }}>
                        Operator Name
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ minWidth: 200 }}>
                        Asset
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ minWidth: 300 }}>
                        Commodity
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ minWidth: 180 }}>
                        Assigned To
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center" style={{ minWidth: 130 }}>
                        Job Start Date
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center" style={{ minWidth: 130 }}>
                        TAT
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center" style={{ minWidth: 130 }}>
                        Remaining Days
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center" style={{ minWidth: 130 }}>
                        Priority
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ minWidth: 180 }}>
                        Country
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ minWidth: 180 }}>
                        Region
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ minWidth: 180 }}>
                        Sub Region
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center" style={{ minWidth: 110 }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRow?.map((row) => (
                      <EditableTable
                        updateApi={updateApi}
                        isEdited={isEdited}
                        atat={atat}
                        id={props.idforEdit}
                        setATat={setATat}
                        asJobAsset={asJobAsset}
                        setAsJobAsset={setAsJobAsset}
                        row={row}
                        setTableRow={setTableRow}
                        tableRow={tableRow}
                        stArrayData={stArrayData}
                        priorities={priorities}
                        setUpdateApi={setUpdateApi}
                        setDeletedRow={setDeletedRow}
                        assign={assign}
                        deletedRow={deletedRow}
                      ></EditableTable>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="right">
              <Grid item xs={'auto'}>
                <Button variant="contained" className="rounded-border" color="accent3"
                  onClick={() => { props.setOpen(false); }}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={'auto'}>
                <LoadingButton buttonName="Update" clickHandler={submitForEdit} isLoading={loading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Alert Dialog - Confirmation for Delete */}
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openSaveConfirmation}
        onClose={handleADClose}
        description="Job already exists for the selected asset(s).Click here to view the existing job(s) or click ok to proceed"
        action={
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>Cancel</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button color="secondary"
                onClick={() => {
                  axios.post(process.env.React_App_baseUrl + "job/SaveJob", finalData, {
                    headers: {
                      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                    }
                  })
                    .then(function (response) {
                      handleADClose();
                      toasterSuccess("Job saved successfully");
                      props.setOpen(false);
                      props.setPageRefresh(true);
                    })
                    .catch(function (error) {
                      ErroHandling(error, function (res) {
                        toasterError(res)
                      })
                    });
                }}>
                OK
              </Button>
            </Grid>
          </Grid>
        }
      />
      </LoaderWrapper>
    </>
  );
};

export default EditJobRecords;

const EditableTable = ({
  updateApi, setUpdateApi, priorities, row, setTableRow, tableRow,
  stArrayData, setDeletedRow, deletedRow, assign,
}) => {
  let [edit, setEdit] = useState(false);
  let [value, setValue] = useState();
  const [deletedItem, setDeletedItem] = useState({});
  const classes = useStyles();
  const [openConfirmation, setConfirmation] = useState(false);
  const handleADClose = () => { setConfirmation(false); };
  const editData = () => { setEdit(!edit); };
  const openAlertDialog = (item) => { setConfirmation(true); setDeletedItem(item); };
  const CancelData = () => {

    //setJobIds(0);
    setValue(); setEdit(!edit);
  };
  const [reload, setReload] = useState(false);
  const saveData = () => {
    row.assignedTo = value?.assignedTo ? value?.assignedTo : row.assignedTo;
    row.assignedToId = value?.assignedToId
      ? value?.assignedToId
      : row.assignedToId;
    row.priorityId = value?.priorityId ? value?.priorityId : row.priorityId;
    row.tat = value?.tat ? value?.tat : row.tat;
    row.startDate = value?.startDate ? value?.startDate : row.startDate;
    row.priorityName = value?.priorityName
      ? value?.priorityName
      : row.priorityName;
    row.remainingDays = CommonService.getBusinessDatesCount(new Date(row.tat))
    row.isEdited = true;

    setEdit(!edit);
  };
  const priorityChange = (ab) => { setValue({ ...value, priorityName: ab.label, priorityId: ab.id }); };
  const assignChange = (ab) => {
    setValue({ ...value, assignedTo: ab.label, assignedToId: ab.id });
  };
  const totChange = (e) => { setValue({ ...value, tat: e }); setUpdateApi({ ...updateApi, tat: e }); };
  const startDateChange = (e) => {

    //    console.log(row)
    if (row.companyId > 0 && row.assetId > 0) {

      axios.get(
        `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
        headers: {
          CompanyId: row.companyId,
          AssetId: row.assetId,
          IsCRUCompany: row.isCRUCompany,
          IsCRUAsset: row.isCRUAsset,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null,
        }
      }).then((res) => {
        if (res.status === 200) {
          let tempStartDate = new Date(e);

          let g = CommonService.addWorkingDays(tempStartDate, res.data)
          let newTatDate = CommonService.GetDateAsStringWithDash(g)
          let remainingDaysTemp = CommonService.getBusinessDatesCount(g)
          row.tat = newTatDate;
          row.startDate = e;
          row.remainingDays = remainingDaysTemp;
          row.isEdited = true;
          setReload(!reload)
          // setEdit(!edit);

        }
      })
    }
  };
  const DeleteData = () => {
    let arr = [...tableRow];
    row.isDeleted = true;
    deletedRow.push(row);
    setDeletedRow(deletedRow);
    let index = arr.indexOf(row);
    arr.splice(index, 1);
    setTableRow(arr);
    handleADClose();
    toasterSuccess("Successfuly Delete");
  };
  const handleChange = (e, key, id) => {
    if (key === "isEdited") { setEdit(!edit); }
    const val = { ...updateApi };
    const role = val.jobAsset.find((r) => r.jobAssetId === id);
    role[key] = e;
    val.jobAsset.push(role);
    const item = [...new Set(val.jobAsset)];
    val.jobAsset = item;
    setUpdateApi({ ...val });
    setConfirmation(false);
  };

  const truncateString = (string, length) => {
    return (
      string.length > length ? string.substring(0, length) + "..." : string
    );
  }


  return (
    <>
      <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row" className={classes.tableCell}>{row.companyName}</TableCell>
        <TableCell align="center" className={classes.tableCell}>{row.ownershipPercentage}</TableCell>
        <TableCell align="center" className={classes.tableCell}>{row.operatorName}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{row.assetName}</TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <Tooltip title={row.commodity} placement="top" arrow>
            <div>
              {truncateString(`${row.commodity}`, 60)}
            </div>
          </Tooltip>
        </TableCell>
        {!edit ? (<TableCell align="left" className={classes.tableCell}>{row.assignedTo}</TableCell>) : (
          <TableCell align="left" className={`${classes.tableCell} ${classes.paddingRight}`}>
            <div className={classes.smallAlign}>
              <CustomAutoComplete
                options={assign}
                sx={{ width: 150 }}
                label="Analyst"
                optionLabelKey="name"
                optionValueKey="userId"
                onChange={(value) => { assignChange(value); }}
                defaultValueKey={["name", "userId"]}
                defaultValue={[row.assignedTo, row.assignedToId]}
              />
            </div>
          </TableCell>
        )}

        {!edit ? (<TableCell align="center" className={classes.tableCell}>
          {row.startDate ? row.startDate.split("-").reverse().join("-") : null}
        </TableCell>) : (
          <TableCell align="left" className={`${classes.tableCell} ${classes.paddingRight}`}>
            <div style={{ width: 140 }}>
              <DatePicker
                size="small"
                fullWidth
                labelTitle="Job Start Date"
                variant="standard"
                defaultDate={row.startDate}
                label="disabled"
                onChangeDate={(e) => {
                  if (e !== null && new Date(e).toDateString() !== 'Invalid Date') {
                    let selectedDate = new Date(e);
                    let date =
                      selectedDate.getFullYear() +
                      "-" +
                      ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                      "-" +
                      ("0" + selectedDate.getDate()).slice(-2);
                    startDateChange(date);
                  }

                }}
              />
            </div>
          </TableCell>
        )}
        {!edit ? (<TableCell align="center" className={classes.tableCell}>
          {row.tat.split("-").reverse().join("-")}
        </TableCell>) : (
          <TableCell align="left" className={`${classes.tableCell} ${classes.paddingRight}`}>
            <div style={{ width: 140 }}>
              <DatePicker
                size="small"
                fullWidth
                labelTitle="TAT"
                variant="standard"
                defaultDate={row.tat}
                label="disabled"
                onChangeDate={(e) => {

                  let selectedDate = new Date(e);
                  let date =
                    selectedDate.getFullYear() +
                    "-" +
                    ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + selectedDate.getDate()).slice(-2);
                  totChange(date);
                  row.remainingDays = CommonService.getBusinessDatesCount(new Date(date))
                }}
              />
            </div>
          </TableCell>
        )}
        <TableCell align="center" className={classes.tableCell}>
          {row.remainingDays}
        </TableCell>

        {!edit ? (
          <TableCell align="center" className={classes.tableCell}>
            {row.priorityName === "High" ? (
              <Chip label={row.priorityName} size="small" color="warning" className="statusChip" />
            ) : undefined}{" "}
            {row.priorityName === "Low" ? (
              <Chip label={row.priorityName} size="small" color="success" className="statusChip" />
            ) : undefined}{" "}
            {row.priorityName === "Medium" ? (
              <Chip label={row.priorityName} size="small" color="info" className="statusChip" />
            ) : undefined}
            {row.priorityName === "Critical" ? (
              <Chip label={row.priorityName} size="small" color="error" className="statusChip" />
            ) : undefined}
          </TableCell>) : (
          <TableCell align="left" className={`${classes.tableCell} ${classes.paddingRight}`}>
            <div className={classes.smallAlign}>
              <CustomAutoComplete
                size="small"
                options={priorities}
                sx={{ width: 300 }}
                label="Priority"
                optionLabelKey="label"
                disableClearable={true}
                optionValueKey="id"
                onChange={(value) => { priorityChange(value); }}
                defaultValueKey={["id", "label"]}
                defaultValue={[row.priorityId, row.priorityName]}
              />
            </div>
          </TableCell>
        )}
        <TableCell align="left" className={classes.tableCell}>{row.countryName}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{row.regionName}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{row.subRegionName}</TableCell>

        {!edit ? (
          <TableCell align="center" className={classes.tableCell}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton aria-label="edit" color="primary">
                <EditIcon className={classes.iconSize}
                  onClick={() => handleChange(true, "isEdited", row.jobAssetId)} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton aria-label="Delete" sx={{ color: "red" }}>
                <DeleteIcon className={classes.iconSize} onClick={() => openAlertDialog(row)} />
              </IconButton>
            </Tooltip>
          </TableCell>) : (
          <TableCell align="center" className={classes.tableCell}>
            <Tooltip title="Submit" placement="top" arrow>
              <IconButton aria-label="Submit">
                <AcceptIcon className={classes.iconSize} onClick={saveData} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel" placement="top" arrow>
              <IconButton aria-label="Cancel" color="secondary">
                <CancelIcon className={classes.iconSize} onClick={CancelData} />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>

      {/* Alert Dialog - Confirmation for Delete */}
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openConfirmation}
        onClose={handleADClose}
        description="The asset will be deleted from the job. Are you sure you want to delete?"
        action={
          <Grid container spacing={2} justifyContent="right">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>No</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button onClick={DeleteData} color="secondary">
                Yes
              </Button>
            </Grid>
          </Grid>
        }
      />

    </>
  );
};
