import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getJobID = createAsyncThunk(
  "filter/jobId",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl+"job/GetJobNo",{
          headers: {
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          }
        }
      );
      //console.log(data, "job id");
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const getJobTypeDropDown = createAsyncThunk(
  "filter/jobTypesDD",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl+"job/GetJobTypeDropDown",{
          headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
        }
      );
      //console.log(data, "job types");
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const getCompanyDropDown = createAsyncThunk(
  "filter/allcompanyDropDown",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl+"DropDown/GetCompanyDropDownFilter",{
          headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
        }
      );
      // console.log(data, "companyDropDOwn");
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const companiesForBrandnewJob = createAsyncThunk(
  "filter/allcompaniesforNewJob",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl+"DropDown/GetCompanyDropDown",  {headers: {
          isNewSource: false,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        
        },}
       );
     // console.log(data, "companyDropDOwn");
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const getAllCompanies = createAsyncThunk(
  "filter/companiesWithID",
  async (companyId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl+"DropDown/GetCompanyDropDown",{
          headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
        }
      );
     // console.log(data, "company based on ID");
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);



export const getcompaniesBasedOnID = createAsyncThunk(
  "filter/companiesWithID",
  async (companyId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
       `${process.env.React_App_baseUrl}source/GetCompaniesDetail?CompanyId=${companyId}`,{
        headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
       }
      );
     // console.log(data, "company based on ID");
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const getAssetsBasedOnId = createAsyncThunk(
  "filter/assetsBasedonId",
  async (assetId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl+"source/GetAssetById",
        {
          headers: {
            AssetId: assetId,
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        
          },
        }
      );
      //console.log(data, "assets based on ID");
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const getAllJobs = createAsyncThunk(
  "filter/allJobs",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl+"job/GetManageJobs?pageNumber=1&pageSize=1000",{
          headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
        }
      );
     // console.log(data, "All Jobs");
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);



const initialState = {
  generatedJobId: 0,
  jobTypeDropDOwn: [],
  allcompanyDropDown: [],
  allCompsFornewJOb : [],
  fetchCompanyName :"",
  companyId: [],
  createJobInitialData: [
    { companyId: 0, isCRUCompany: true, assetId: 0, isCRUAsset: true },
  ],
  assetsBasedonId: [],
  allJobList : []
 
};

const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    clearFilterData: (state) => {
      return {
        ...state,
        filters: initialState.filters,
        error: false,
      };
    },
    setFilterAction: (state, { payload }) => {
     // console.log(payload, "payload");
      state.filters = payload.filters;
    },
    getID: (state, { payload }) => {
      state.companyId.push(payload);
    },
  },
  extraReducers: {
    [getJobID.pending]: (state, { payload }) => {
      state.asyncStatus = "loading";
    },
    [getJobID.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.generatedJobId = payload;
    },
    [getJobID.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [getJobTypeDropDown.pending]: (state, { payload }) => {
      state.asyncStatus = "loading";
    },
    [getJobTypeDropDown.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.jobTypeDropDOwn = payload;
    },
    [getJobTypeDropDown.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [getCompanyDropDown.pending]: (state, { payload }) => {
      state.asyncStatus = "loading";
    },
    [getCompanyDropDown.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.allcompanyDropDown = payload;
    },
    [getCompanyDropDown.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [getcompaniesBasedOnID.pending]: (state, { payload }) => {
      state.asyncStatus = "loading";
    },
    [getcompaniesBasedOnID.fulfilled]: (state, { payload }) => {
      let { companyId, isCRUCompany, assetId, isCRUAsset, companyName} = payload;
      //console.log(payload)
      state.asyncStatus = "success";
      state.fetchCompanyName = companyName;
      //console.log(state.fetchCompanyName)
      state.createJobInitialData[0].companyId = companyId;
      state.createJobInitialData[0].isCRUCompany = isCRUCompany;
      state.createJobInitialData[0].assetId = assetId;
      state.createJobInitialData[0].isCRUAsset = isCRUAsset;
    },
    [getcompaniesBasedOnID.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [getAssetsBasedOnId.pending]: (state, { payload }) => {
      state.asyncStatus = "loading";
    },
    [getAssetsBasedOnId.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.assetsBasedonId = payload;
    },
    [getAssetsBasedOnId.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [companiesForBrandnewJob.pending]: (state, { payload }) => {
      state.asyncStatus = "loading";
    },
    [companiesForBrandnewJob.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.allCompsFornewJOb = payload;
    },
    [companiesForBrandnewJob.rejected]: (state) => {
      state.asyncStatus = "failed";
    }, 
    [getAllJobs.pending]: (state, { payload }) => {
      state.asyncStatus = "loading";
    },
    [getAllJobs.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.allJobList = payload;
    },
    [getAllJobs.rejected]: (state) => {
      state.asyncStatus = "failed";
    }, 

    // [getCompanyPriority.pending]: (state, { payload }) => {
    //   state.asyncStatus = "loading";
    // },
    // [getCompanyPriority.fulfilled]: (state, { payload }) => {
    //   state.asyncStatus = "success";
    //   state.priorityList = payload;
    // },
    // [getCompanyPriority.rejected]: (state) => {
    //   state.asyncStatus = "failed";
    // }, 

  },
});


// console.log(filterSlice);

export const { clearFilterData, setFilterAction } = jobSlice.actions;

export default jobSlice.reducer;
