import React from "react";
import { Button, Box, Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import MultiSelect from "../../../UIComponent/MultiSelect/MultiSelect";
import DatePicker from "../../../UIComponent/DatePicker";
import { toasterError } from "../../../UIComponent/Toaster";
import Container from '../SourceManagement/TreeContainer';
import { clearFilterJobRecords, clearQualityFilter, GetAssetData, setQualtyFilter } from "../../../features/filter/filterSlice";
import { callBasedOnLocation, callFromAssetType, callFromComm, callFromPriority, callSource } from "../../../Util/TreeAPI";

// Importing useStyles and Styles
import "../../../../src/index.scss";
import "../../../Resources/scss/layout.scss";
import useStyles from "../JobManagement/jobManagement.styles";
import "react-toastify/dist/ReactToastify.css";
import "react-dropdown-tree-select/dist/styles.css";

const QualityQCPassFilter = (props) => {
  // Declaration of States and Component Variables Start Here
  const { filterQuality, assetDropDownlist } = useSelector(
    (store) => store.filter
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [status, setStatus] = React.useState([]);
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [jobComms, setJobComms] = React.useState([]);
  const [prior, setPrior] = React.useState([]);
  const [jobDate, setJobDate] = React.useState("");
  const [assignedTo, setAssignedTo] = React.useState([]);
  const [assignedBy, setAssignedBy] = React.useState([]);
  const [filters, setFilters] = React.useState({
    company: [], asset: [], assetTypeIds: [], countryIds: [], regionIds: [],
    subRegionIds: [], commodityIds: [], statusIds: [], priorityIds: [],
  });
  const [completedby, setCompletedBy] = React.useState([]);
  const [dependency, setDependency] = React.useState(null);
  const [apiData, setApiData] = React.useState({
    companies: [], location: {}, assetType: [], priority: [], commodity: [],
  });
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  const [jobids, setJobIds] = React.useState([]);
  // This function handles the change of filter values
  const onChangeHandler = (key, value, reason) => {
    const pre = { ...filterQuality };
    console.log(key)
    pre[key] = value;
    if (key === "commodity") {
      setDependency("commodity");
    
    setApiData({
      ...apiData,
      commodity: value,
    });
  } else if (key === "priority") {
      setDependency("priority");
    setApiData({
      ...apiData,
      priority: value,
    });
  } else if (key === "assetType") {
      setDependency("assetType");
    setApiData({
      ...apiData,
      assetType: value,
    });
  }
    dispatch(setQualtyFilter({ filterQuality: pre }));
  };

  React.useEffect(() => {
    console.log(dependency,"dependency" )
    if (dependency === "company") {
      callSource(apiData)
        .then((res) => {
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationFilter){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationFilter){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationFilter){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "location") {
      callBasedOnLocation(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })  
        .catch((err) => console.log(err));
    } else if (dependency === "assetType") {
      callFromAssetType(apiData)
        .then((res) => {
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationFilter){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationFilter){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationFilter){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "priority") {
      callFromPriority(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationFilter){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationFilter){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationFilter){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          props.setCompanyFilter(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "commodity") {
      callFromComm(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationFilter){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationFilter){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationFilter){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          props.setCompanyFilter(res.data.companies);
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [apiData]);

// This function is used to clear the field values
  const clearFieldHanlder = () => {
    props.resetTreeFilter();
    dispatch(clearQualityFilter());
    props.setFilterValue("");
    props.setReload(!props.reload);
    props.getFilterData();

    setDependency(null);
  };

  // This function is used to submit the filter values
  const submitFilter = () => {
    // Create an object to store the filter values
    const formData = {
      JobIds: filterQuality.jobids?.map((e) => e.id), // Get job ids from filterQuality
      JobId: props?.JobIdCheck, // Get job id from props
      statusIds: filterQuality.status.map((stat) => stat.id), // Get status ids from filterQuality
      companyName: props.companyFilter // Get company name from props
        .filter((el) => el.checked === true) // Filter checked elements
        .map((el) => el.value), // Map the values
      asset: props.companyFilter // Get asset from props
        .map((el) =>
          el.children.filter((it) => it.checked === true).map((tk) => tk.value) // Filter checked elements and map the values
        )
        .filter((item) => item.length > 0) // Filter items with length greater than 0
        .flat(1), // Flatten the array
      //commodityIds: filterQuality.commodity.map((commo) => commo.commodityId),
      tat: props.tatDate, // Get tat date from props
      CompletionDate: props.jobCompletionDate, // Get job completion date from props
      CompletedBy: filterQuality.completedby?.map((e) => e.userId), // Get completed by from filterQuality
      priorityIds: filterQuality.priority.map((prio) => prio.priorityId), // Get priority ids from filterQuality
    };
    console.log(formData, "formData")
    // this data will be send to the body api
    props.setFilterValue(formData);
    props.setState({ ...props.state, right: false });
  };
// Function to get all job ids from the API
  const getAllJobIds = () => {
    ApiService.getAll(
      "OwnershipChangeQualityManagement/GetJobNoNameDropdownforQualityCO"
    )
      .then((res) => {
        if (res.status === 200) {
          setJobIds(res.data); // Set the job ids in the state
        } else {
          toasterError(res.data); // Show error message
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };
  // Function to get all completed users from the API
  const getAllCompletedUsers = () => {
    ApiService.getAll(
      "OwnershipChangeQualityManagement/GetCompletedByFilterAsync"
    )
      .then((res) => {
        if (res.status === 200) {
          setCompletedBy(res.data); // Set the completed users in the state
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };
  // Function to get all status from the QualityManagement/GetRoleBasedStatusFilterDropdown API
  const getAllStatus = () => {
    ApiService.getAll("QualityManagement/GetRoleBasedStatusFilterDropdown")
      .then((res) => {
        // If response is successful, set the status
        if (res.status === 200) {
          setStatus(res.data);
        } else {
          // If response is unsuccessful, call the toasterError function
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };
// Function to get all companies from the DropDown/GetCompanyDropDownFilter API
  const getAllCompanies = () => {
    ApiService.getAll("DropDown/GetCompanyDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setJobCompanies(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };
// Function to get all commodities from the API
  const getAllCommodities = () => {
    ApiService.getAll("DropDown/GetCommodityDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setJobComms(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };
// Function to get all priorities from the API
  const getAllPriority = () => {
    ApiService.getAll("DropDown/GetPriorityDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setPrior(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };


  React.useEffect(() => {
    getAllStatus();
    getAllCompletedUsers();
    getAllCompanies();
    dispatch(GetAssetData());
    getAllCommodities();
    getAllPriority();
    getAllJobIds();
  }, []);
// This function is used to set the company filter when a node is changed
  const onChangeComp = (currentNode, selectedNodes) => {
    
    setDependency("company");
  
  setSelectedCompanies(selectedNodes);
// Loop through the company filter and check/uncheck the nodes based on the current node
  props.companyFilter.forEach((el) => {
    if (currentNode.checked === true) {
      if (currentNode.value === el.value) {
        el.checked = true;
      } else if (currentNode.parentValue === el.value) {
        el.children.forEach((item) => {
          if (currentNode.value === item.value) {
            item.checked = true;
          }
        });
      }
    } else if (currentNode.checked === false) {
      if (currentNode.value === el.value) {
        el.checked = false;
      } else if (currentNode.parentValue === el.value) {
        el.children.forEach((item) => {
          if (currentNode.value === item.value) {
            item.checked = false;
          }
        });
      }
    }
  });

  const firstParent = props.companyFilter
    .map((el) => {
      let data = {
        label: el.label,
        value: el.value,
        children: el.children.filter((item) => item["checked"] === true),
      };
      return data;
    })
    .filter((el) => el.children.length > 0);
 

    setApiData({
      ...apiData,
      companies:
        props.companyFilter.filter((el) => el["checked"] === true).length > 0
          ? props.companyFilter.filter((el) => el["checked"] === true)
          : firstParent,
    });
};

//console.log(props.companyFilter, "companyFIlter")

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Add & Edit Frequency Form */}
      <Grid container spacing={4}>
      <Grid item xs={12}>
      <MultiSelect
            onChange={onChangeHandler}
            value={filterQuality.jobids}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            items={jobids}
            label="Job No"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            role="jobids"
          />
          </Grid>
        <Grid item xs={12}>
        <Container
            data={props.companyFilter}
            onChange={onChangeComp}
            showPartiallySelected={true}
            texts={{ placeholder: "Company" }}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterQuality.commodity}
            items={
              props.dataForFilter.commodity ? props.dataForFilter.commodity : []
            }
            label="Commodities"
            selectAllLabel={{ commodityName: "Select all" }}
            getOptionLabel={(option) => option.commodityName}
            getOptionSelected={(option, anotherOption) =>
              option.commodityName === anotherOption.commodityName
            }
            role="commodity"
          />
        </Grid> */}
        <Grid item xs={12}>
          {console.log(props.tatDate, "tatData")}
          <DatePicker
            labelTitle="TAT"
            placeHolder="DD-MM-YYYY"
            variant="standard"
            past={false}
            defaultDate={props.tatDate}
            onChange={onChangeHandler}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              props.setTatDate(
                selectedDate.getFullYear() +
                "-" +
                (selectedDate.getMonth() + 1) +
                "-" +
                selectedDate.getDate()
              );
            }}
          />
        </Grid>
{/*         <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterQuality.status}
            getOptionSelected={(option, anotherOption) =>
              option.statusName === anotherOption.statusName
            }
            items={status}
            label="Status"
            selectAllLabel={{ statusName: "Select all" }}
            getOptionLabel={(option) => option.statusName}
            role="status"
          />
        </Grid>

        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterQuality.priority}
            getOptionSelected={(option, anotherOption) =>
              option.priorityName === anotherOption.priorityName
            }
            items={
              props.dataForFilter.priority ? props.dataForFilter.priority : []
            }
            label="Priority"
            selectAllLabel={{ priorityName: "Select all" }}
            getOptionLabel={(option) => option.priorityName}
            role="priority"
          />
        </Grid> */}
        <Grid item xs={12}>
          {console.log(props.jobCompletionDate, "jobCompletionDate")}
          <DatePicker
            labelTitle="Job Completion Date"
            placeHolder="DD-MM-YYYY"
            variant="standard"
            past={false}
            defaultDate={props.jobCompletionDate}
            onChange={onChangeHandler}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              props.setJobCompletionDate(
                selectedDate.getFullYear() +
                  "-" +
                  (selectedDate.getMonth() + 1) +
                  "-" +
                  selectedDate.getDate()
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterQuality.completedby}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            items={completedby}
            label="Completed By"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            role="completedby"
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn" sx={{ mr: 2 }}
                color="accent5" onClick={submitFilter}>
                Filter
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn reset-color"
                color="accent3" onClick={clearFieldHanlder}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );
};

export default QualityQCPassFilter;
