// Default React, useState and Material Components
import React from "react";
import { Button, Box, Grid, TextField, Typography, Autocomplete, Tooltip, IconButton, Checkbox, FormControlLabel } from "@mui/material";

// Importing Custom Components
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Import Material & Fluent UI Icons
import AddIcon from '@mui/icons-material/Add';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { CirclePlusIcon, DeleteIcon, TextDocumentIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";

const AddOwnerInfo = (props) => {
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();

  // Top 100 films 
  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },
  ];
  // Declaration of States and Component Variables End Here

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Add Owner Info
        </Typography>
      </Box>

      {/* Add & Edit Commodity Form */}
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ mt: -1 }}>
          <Grid container alignItems="center" justifyContent="flex-start" spacing={0}>
            <Grid item xs={6} className={classes.removeCheckMargin}>
              <FormControlLabel control={<Checkbox />} label="Unsure" />
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Button variant="contained" color="accent7" size="small"
                startIcon={<AddIcon style={{ fontSize: 16 }} />}>
                Add report info
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: -2 }}>
          <Box className={classes.jobShortDetails}>
            <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={3}>
              <Grid item xs={12} sx={{ mt: -1 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={1} justifyContent="center" alignItems="self-end">
                    <Grid item xs>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={top100Films}
                        renderInput={(params) => <TextField {...params} label="Correct Owner Name" variant="standard" />}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Owner Name" placement="top" arrow>
                        <IconButton color="accent7">
                          <TaskAltIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="standard-basic" label="Comments " variant="standard"
                  multiline minRows={3} maxRows={3} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={1} justifyContent="center" alignItems="self-end">
                    <Grid item xs>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={top100Films}
                        renderInput={(params) => <TextField {...params} label="Report Name" variant="standard" />}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Open Document" placement="top" arrow>
                        <IconButton color="accent6" aria-label="add to shopping cart">
                          <TextDocumentIcon />
                        </IconButton>
                      </Tooltip>

                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Grid container spacing={1} justifyContent="self-start" alignItems="self-end">
                    <Grid item xs="auto">
                      <TextField id="standard-basic" label="Page No" fullWidth required variant="standard"
                        sx={{ width: 100 }}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Delete" placement="top" arrow>
                        <IconButton color="error">
                          <DeleteIcon style={{ fontSize: "20px" }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Add" placement="top" arrow >
                        <IconButton color="secondary" style={{ fontSize: "20px" }}>
                          <CirclePlusIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mt: -4 }}>
          <Box className={classes.jobShortDetails}>
            <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField fullWidth id="standard-basic" label="Ownership Percentage(%)" variant="standard" />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField fullWidth id="standard-basic" label="Ownership Date" variant="standard" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="standard-basic" label="Comments " variant="standard"
                  multiline minRows={3} maxRows={3} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={1} justifyContent="center" alignItems="self-end">
                    <Grid item xs>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={top100Films}
                        renderInput={(params) => <TextField {...params} label="Report Name" variant="standard" />}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Open Document" placement="top" arrow>
                        <IconButton color="accent6" aria-label="add to shopping cart">
                          <TextDocumentIcon />
                        </IconButton>
                      </Tooltip>

                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Grid container spacing={1} justifyContent="self-start" alignItems="self-end">
                    <Grid item xs="auto">
                      <TextField id="standard-basic" label="Page No" fullWidth required variant="standard"
                        sx={{ width: 100 }}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Delete" placement="top" arrow>
                        <IconButton color="error">
                          <DeleteIcon style={{ fontSize: "20px" }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Add" placement="top" arrow >
                        <IconButton color="secondary" style={{ fontSize: "20px" }}>
                          <CirclePlusIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mt: -4 }}>
          <Box className={classes.jobShortDetails}>
            <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={3}>
              <Grid item xs={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={1} justifyContent="center" alignItems="self-end">
                    <Grid item xs>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={top100Films}
                        renderInput={(params) => <TextField {...params} label="Stock Exchange with Largest market cap" variant="standard" />}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Add Stock Exchange" placement="top" arrow>
                        <IconButton color="secondary">
                          <CirclePlusIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="standard-basic" label="Comments " variant="standard"
                  multiline minRows={3} maxRows={3} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={1} justifyContent="center" alignItems="self-end">
                    <Grid item xs>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={top100Films}
                        renderInput={(params) => <TextField {...params} label="Report Name" variant="standard" />}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Open Document" placement="top" arrow>
                        <IconButton color="accent6" aria-label="add to shopping cart">
                          <TextDocumentIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Grid container spacing={1} justifyContent="self-start" alignItems="self-end">
                    <Grid item xs="auto">
                      <TextField id="standard-basic" label="Page No" fullWidth required variant="standard"
                        sx={{ width: 100 }}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Delete" placement="top" arrow>
                        <IconButton color="error">
                          <DeleteIcon style={{ fontSize: "20px" }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Add" placement="top" arrow >
                        <IconButton color="secondary" style={{ fontSize: "20px" }}>
                          <CirclePlusIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mt: -3 }}>
          {props.crudState === "View" ? null : (
            <Grid item xs={12} sx={{ mt: -1 }}>
              <Grid container spacing={2} justifyContent="left">
                <Grid item xs={'auto'}>
                  <LoadingButton
                    buttonName={"Save"}
                  />
                </Grid>
                <Grid item xs={'auto'}>
                  <Button variant="contained" color="accent3">
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddOwnerInfo;