// Importing React and mui Components
import React, { useState } from "react";
import {
    Autocomplete,
    Box,
    Button,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import IconButton from "@mui/material/IconButton";
import { DeleteIcon } from "@fluentui/react-icons-mdl2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// Importing Material and Fluentui Icons
import { AddIcon } from '@fluentui/react-icons-mdl2';
import axios from "axios";

import { makeStyles } from "@mui/styles";

const CreatePublicationDetails = (props) => {

    const useStyles = makeStyles((theme) => ({
        cruTable: {
            padding: 0,
            borderRadius: "6px",
            border: "1px solid #d7d7d7 !important",
        },
        cruThead: {
            padding: "0px 10px",
            background: "#f9f9f9",
            height: "46px",
            borderBottom: "1px solid #d7d7d7",
            fontWeight: "bold",
        },
        cruTd: {
            borderBottom: "1px solid #d7d7d7 !important",
            padding: "0px 10px !important",
            height: "46px",
        },
        "cruTd:hover": {
            background: "grey",
        },
        openStyle: {
            transform: "rotate(0deg)",
        },
        closeStyle: {
            transform: "rotate(45deg)",
            //color: 'red'
        },
        subTitle: {
            fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
            fontSize: '16px',
            fontWeight: '600',
            marginTop: '10px'
        },
    }));

    const classes = useStyles();
    const [dropDown, setDropDown] = useState({
        company: [],
        year: [],
        reportingperoid: []
    })

    let intialData = {
        "isVisible": true,
        "Publication": "",
        "ReportingPeriodId": "",
        "Date": "",
        "Comment": "",
        "reportingPeriodLabel": ""
    }
    const [reportingPeroidValue, setReportingPeroidValue] = useState([{ ...intialData }])
    const [sendData, setSendData] = useState({
        "CompanyId": "2",
        "IsCRUCompany": true,
        "Company": "",
        "Q1": false,
        "Q2": false,
        "Q3": false,
        "Q4": false,
        "Annual": false,
        "Year": "",
        "YearId": "",
        "PublicationDetails": []
    });

    const [reportingPeriodIdCpy, setReportingPeriodIdCpy] = useState([]);

    React.useEffect(() => {
        Promise.all([
            axios.get(
                process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
                headers: {
                    isNewSource: false,
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

                },
            }
            ),
            axios.get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingPeriod",{
                headers: {
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                }
            }),
            axios.get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingYear",{
                headers: {
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                }
            })
        ]).then(resp => {
            let years = [];
            let reportingperoid = [];
            let company = (resp[0].data || []).map((el, ind) => { return { label: el.companyName, value: el.companyId, isCRUCompany: el.isCRUCompany, isNewSource: el.isNewSource } });
            setDropDown(prev => { return { ...prev, company: company || [] } });
            if (resp[1]) {
                reportingperoid = resp[1].data.map((el) => { return { label: el.name, value: el.id } })
                setDropDown(prev => { return { ...prev, reportingperoid: reportingperoid || [] } })
            }
            if (resp[2]) {
                years = resp[2].data.map((el) => { return { label: el.year.toString(), value: el.id } });
                let currentYear = resp[2].data.find(ee => ee.isCurrentYear)?.id;
                setDropDown(prev => { return { ...prev, year: years || [] } });
                setTimeout(() => {
                    if (props.calenderID !== null) {
                        callingEditApi(currentYear, years, reportingperoid);
                    }
                }, 100);
            }
        }).catch((err) => {
            if(err.res.status===400){
              toasterError(err.res.data);
            }
             });
    }, []);

    const callingEditApi = (year, years, reportingperoid) => {
        // console.log(year, years, reportingperoid);
        axios.get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingCalendarDetails", {
            headers: {
                ReportingCalendarId: props.calenderID,
                yearId: year,
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                
            }
        })
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data || {};
                    setSendData(prev => {
                        return {
                            ...prev,
                            CompanyId: data.companyId || '',
                            IsCRUCompany: data.isCRUCompany || true,
                            Company: data.company || '',
                            "Year": years.find(el => el.value === year)?.label || '',
                            "YearId": year,
                            "Q1": data.q1 || false,
                            "Q2": data.q2 || false,
                            "Q3": data.q3 || false,
                            "Q4": data.q4 || false,
                            "Annual": data.annual || false,
                            "createdOn": data.createdOn || '',
                            "id": data.id
                        }
                    });
                    const pd = data.publicationDetails.map(el => {
                        let date = new Date(el.date);
                        date = date ?
                            date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate())).slice(-2) : '';
                        return {
                            "isVisible": true,
                            "Publication": el.publication,
                            "ReportingPeriodId": el.reportingPeriodId,
                            "Date": date,
                            "Comment": el.comment,
                            "reportingPeriodLabel": reportingperoid.find(e => e.value === el.reportingPeriodId)?.label || '',
                            "reportingPublicationId": el.reportingPublicationId,
                            isDeleted: false,
                            isEdited: false,
                            displayDate: el.displayDate
                        }
                    });
                    setReportingPeriodIdCpy(pd);
                    const pDItem = pd && pd.length ? pd : [{ ...intialData }];
                    setReportingPeroidValue(pDItem);
                }
            })
            .catch((err) => {
                if(err.res.status===400){
                  toasterError(err.res.data);
                }
                 });
    }

    const save = () => {
        const fReportingP = reportingPeroidValue.filter(e => e.isVisible);
        if (!!sendData.Company.length && !!sendData.Year.length && !!fReportingP &&
            fReportingP.every(el => el.ReportingPeriodId && el.Date && el.isVisible)) {
            let selectedDate = new Date();
            let reqData = {
                companyId: sendData.CompanyId,
                isCRUCompany: sendData.IsCRUCompany,
                company: sendData.Company,
                reportingYearId: sendData.YearId,
                createdOn: selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2),
                q1: sendData.Q1,
                q2: sendData.Q2,
                q3: sendData.Q3,
                q4: sendData.Q4,
                annual: sendData.Annual,
                publicationDetails: fReportingP.map(el => {
                    return {
                        publication: el.Publication,
                        reportingPeriodId: el.ReportingPeriodId,
                        displayDate: "",
                        date: el.Date,
                        comment: el.Comment,
                        isEdited: false,
                        isDeleted: false
                    }
                })
            }

            submittingApi(reqData, 'Added');
        } else {
            toast.warning("Please fill the required data.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: 'warning',
            });
        }
    }

    const update = () => {
        // console.log("update")
        let reqData = {
            id: sendData.id,
            companyId: sendData.CompanyId,
            isCRUCompany: sendData.IsCRUCompany,
            company: sendData.Company,
            reportingYearId: sendData.YearId,
            createdOn: sendData.createdOn,
            q1: sendData.Q1,
            q2: sendData.Q2,
            q3: sendData.Q3,
            q4: sendData.Q4,
            annual: sendData.Annual,
            publicationDetails: reportingPeroidValue.map((el, ind) => {
                let isEdit = false;
                const fRID = reportingPeriodIdCpy.find(e => e.reportingPublicationId === el.reportingPublicationId);
                // console.log(fRID,el)
                isEdit = fRID ? fRID.ReportingPeriodId !== el.ReportingPeriodId || fRID.Date !== el.Date || fRID.Comment !== el.Comment : false;
                return {
                    reportingPublicationId: el.reportingPublicationId,
                    publication: el.Publication,
                    reportingPeriodId: el.ReportingPeriodId,
                    displayDate: el.displayDate,
                    date: el.Date,
                    comment: el.Comment,
                    isEdited: true,
                    isDeleted: !el.isVisible
                }
            })
        }
        submittingApi(reqData, 'Updated');
    }


    const submittingApi = (reqData, message) => {
        axios.post(process.env.React_App_baseUrl + "reportingcalendar/SavePublication", reqData,{
            headers: {
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
            }
        })
            .then(function (response) {
                if (response.status === 200) {
                    if (props.calenderID === null) {
                        props.setState({ right: false });
                    }
                    props.recallApi();
                    toasterSuccess(`Successfully ${message}.`);
                }
            })
            .catch((err) => {
                
                if(err.res.status===400){
                  toasterError(err.res.data);
                }
                 });
    }

    const reportingPeroid = () => {
        // console.log("English")
        setReportingPeroidValue([...reportingPeroidValue, { ...intialData }])

    }

    const reportingPeroidRemove = (element) => {
        element.isVisible = false;
        setReportingPeroidValue([...reportingPeroidValue])
    }

    const reportingOnChange = (event, el) => {
        // console.log(event.value)
        el.ReportingPeriodId = event.value;
        el.reportingPeriodLabel = event.label
        setReportingPeroidValue([...reportingPeroidValue])
    }

    const dateOnChange = (e, el) => {
        el.Date = e.target.value
        setReportingPeroidValue([...reportingPeroidValue])
    }

    const commentChange = (e, el) => {
        el.Comment = e.target.value
        setReportingPeroidValue([...reportingPeroidValue])

    }

    const dynamicRendering = (options, index) => {
        const selectedrepIds = reportingPeroidValue.map(el => el.ReportingPeriodId);
        selectedrepIds.splice(index, 1);
        const nwOpt = [...options];
        return nwOpt.filter(el => !selectedrepIds.includes(el.value));
    }

    const yearOnchange = (value) => {
        setSendData(prev => {
            return {
                ...prev, Year: value.label, YearId: value.value
            }
        });
        callingEditApi(value.value, dropDown.year, dropDown.reportingperoid)
    }

    const close = () => {
        props.recallApi();
        props.setState({ right: false });
    }

    return (
        <Box sx={{ pt: 0, pb: 3, pr: 3, pl: 3 }}>
            <Box sx={{ pt: 2, mb: 3 }}>
                <Typography id="modal-modal-title" variant="h5">
                    {props.calenderID === null ? 'Create' : ''} Future Publication Details
                </Typography>
            </Box>
            <Grid container style={{ gap: "35px" }}>
                <Grid item xs={12}>
                    <Autocomplete
                        id="combo-box-demo"
                        fullWidth
                        disabled={props.calenderID !== null}
                        options={dropDown.company}
                        disableClearable
                        value={sendData.Company}
                        onChange={(e, value) => { setSendData({ ...sendData, Company: value.label, CompanyId: value.value, IsCRUCompany: value.isCRUCompany, isNewSource: value.isNewSource }) }}
                        renderInput={(params) => <TextField {...params} label="Company" variant="standard" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        id="combo-box-demo"
                        fullWidth
                        options={dropDown.year}
                        disableClearable
                        value={sendData.Year}
                        onChange={(e, value) => yearOnchange(value)}
                        renderInput={(params) => <TextField {...params} label="Year" variant="standard" />}
                    />
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        {/* <Box sx={{ fontSize: 'h6.fontSize', fontWeight: '500' }}>Reporting Period</Box> */}
                        <Typography className={classes.subTitle}>
                            Reporting Period
                        </Typography>
                    </Grid>
                    <Grid item xs={4} textAlign="right">
                        <Button variant="contained" color="accent3" size="small" onClick={reportingPeroid} startIcon={<AddIcon style={{ fontSize: "12px" }} />}>Add</Button>
                    </Grid>
                    {reportingPeroidValue.filter(e => e.isVisible).map((el, index) => {
                        return (
                            <Grid item xs={12}>
                                <Box sx={{ pt: 2, pr: 2, pl: 2, pb: 0, borderRadius: '6px', border: 1, borderColor: 'grey.400', }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                className="no-clear-icon"
                                                fullWidth
                                                disableClearable
                                                options={dynamicRendering(dropDown.reportingperoid, index)}
                                                value={el.reportingPeriodLabel}
                                                onChange={(event, ot) => reportingOnChange(ot, el)}
                                                renderInput={(params) => <TextField {...params} label="Reporting Period" variant="standard" />}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                id="date"
                                                label="To Date"
                                                value={el.Date}
                                                onChange={(event) => dateOnChange(event, el)}
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="d-flex field-gap">
                                                <div className="flex-grow-1">
                                                    <TextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        label="Comments"
                                                        variant="standard"
                                                        value={el.Comment}
                                                        onChange={(event) => commentChange(event, el)}
                                                        multiline
                                                        maxRows={4}
                                                    />
                                                </div>
                                                <div>
                                                    <IconButton
                                                        aria-label="Delete"
                                                        style={{ marginTop: 13, marginLeft: 10 }}
                                                        onClick={() => { reportingPeroidRemove(el) }}
                                                    >
                                                        <DeleteIcon style={{ fontSize: '20px', color: 'red' }} />
                                                    </IconButton>
                                                </div>
                                            </div>

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid item xs={12}>
                    {/* <Box sx={{ fontSize: 'h6.fontSize', fontWeight: '500', mb: 2 }}>Collection Frequency</Box> */}
                    <Typography className={classes.subTitle} style={{ marginTop: 0, marginBottom: "15px" }}>
                        Collection Frequency
                    </Typography>
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', mb: 2 }}>
                        <Grid item textAlign="center">
                            <FormControlLabel
                                value="Q1"
                                control={<Switch color="secondary" value={sendData.Q1} checked={sendData.Q1} onChange={(e) => { setSendData({ ...sendData, Q1: !sendData.Q1 }) }} />}
                                label="Q1"
                                labelPlacement="top"
                            />
                        </Grid>
                        <Grid item textAlign="center">
                            <FormControlLabel
                                value="Q2"
                                control={<Switch color="secondary" value={sendData.Q2} checked={sendData.Q2} onChange={(e) => { setSendData({ ...sendData, Q2: !sendData.Q2 }) }} />}
                                label="Q2"
                                labelPlacement="top"
                            />
                        </Grid>
                        <Grid item textAlign="center">
                            <FormControlLabel
                                value="Q3"
                                control={<Switch color="secondary" value={sendData.Q3} checked={sendData.Q3} onChange={(e) => { setSendData({ ...sendData, Q3: !sendData.Q3 }) }} />}
                                label="Q3"
                                labelPlacement="top"
                            />
                        </Grid>
                        <Grid item textAlign="center">

                            <FormControlLabel
                                value="Q4"
                                control={<Switch color="secondary" value={sendData.Q4} checked={sendData.Q4} onChange={(e) => { setSendData({ ...sendData, Q4: !sendData.Q4 }) }} />}
                                label="Q4"
                                labelPlacement="top"
                            />

                        </Grid>
                        <Grid item textAlign="center">
                            <FormControlLabel
                                value="Annual"
                                control={<Switch color="secondary" value={sendData.Annual} checked={sendData.Annual} onChange={(e) => { setSendData({ ...sendData, Annual: !sendData.Annual }) }} />}
                                label="Annual"
                                labelPlacement="top"
                            />
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    {
                        props.calenderID === null ? <Button variant="contained"
                            sx={{ mr: 2 }}
                            color="primary"
                            onClick={save}
                        >Save</Button> :
                            <Button variant="contained"
                                sx={{ mr: 2 }}
                                color="primary"
                                onClick={update}
                            >Update</Button>
                    }
                    <Button variant="contained" onClick={() => close()}
                        color="accent3"
                    >Cancel</Button>
                </Grid>

            </Grid>

        </Box >
    );
};

export default CreatePublicationDetails;