// Default React, useState and Material Component 
import React, { useState } from "react";
import { Box, Breadcrumbs, Button, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from './../../../Services/GetMenuService';

// Importing Custom Components
import SideDrawer from "../../../UIComponent/Drawer";
import DataTable from "../../../UIComponent/DataTable";
import { toasterError } from '../../../UIComponent/Toaster/index';

// Importing Add & Edit Components
import AddEditAssetVariableCount from "./AddEditAssetVariableCount";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles
import useStyles from "../ManageData.styles";
import BaseLayout from "../../../UIComponent/BaseLayout";

const AssetVariableCount = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState([]);
  const [selected, SetSelected] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [state, setState] = useState({ right: false });
  const [startSearch, setStartSearch] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [assetVariableCount, setAssetVariableCount] = useState([]);
  const [menuPermission, setMenuPermission] = useState({ allowEdit: true });
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(43, function (err, res) {
      if (res) {
        // console.log(res.data)
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])

// This function handles the keyup event
const handleKeyUp = (event) => {
  // If backspace is pressed and target is not a button
  if (
    (event.keyCode === 8 || event.which === 8) &&
    event.target.nodeName !== "BUTTON"
  ) {
    // If search value is empty, get asset variable count
    if (searchValue.length === 0) {
      getAssetVariableCount();
    }
  }
  // If enter is pressed and target is not a button
  if (
    (event.keyCode === 13 || event.which === 13) &&
    event.target.nodeName !== "BUTTON"
  ) {
    // Set total count to 0, page to 0 and start search to true
    setTotalCount(0);
    setPage(0);
    setStartSearch(true)
    // Search asset variable count with the value of the target
    searchAssetVariableCount(event.target.value);
    
  }
};

  // Function for Global Search
  const globalSearch = (value) => {
    setSearchValue(value);
  };


// This function changes the sort column name format
const changeSortColumnNameFormat = (fieldName) => {

  // If fieldName is null or empty, return an empty string
  if (fieldName == null || fieldName == "") {
    return "";
  }
  
  // If fieldName is 'company', set it to 'Company'
  if (fieldName.toLocaleLowerCase() == "company") {
    fieldName = "Company";
  }

  // If fieldName is 'asset', set it to 'Asset'
  if (fieldName.toLocaleLowerCase() == "asset") {
    fieldName = "Asset";
  }
  
  // Return the modified fieldName
  return fieldName;
}

  // This function is used to search for asset variables and set the count of the asset variables
  const searchAssetVariableCount = (filterValue) => {
    // Set loading to true while searching
    setLoading(true);
    // Create an array with pageNumber and pageSize
    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
     // Call ApiService to search for asset variables
    ApiService.search(
      "managedata/GetAllCRUAssetVariables?",
      {

        sortColumn: changeSortColumnNameFormat(sorting.sortBy),
        isAscending: sorting.isAscending,
        searchText: encodeURIComponent(filterValue)
      },
      prt
    )
      .then((res) => {
        // If response status is 200, set asset variable count and total count
        if (res.status === 200) {
          let searchResult = Array.isArray(res.data) ? res.data : res.data.data || [];
          setAssetVariableCount(searchResult);
          setLoading(false);
          setTotalCount(res.data.totalCount);
        } else {
         // Else show error message 
          toasterError(`${res.data}`)
          setLoading(false);
        }
      })
      .catch((err) => {
        // Catch any errors and handle them
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

// Function to get the count of asset variables
const getAssetVariableCount = () => {
  // Set loading to true
  setLoading(true);
  // If reload is true, set it to false
  if (reload) {
    setReload(false);
  }

  // Create an array with pageNumber and pageSize
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
  // Call ApiService to search for asset variables
  ApiService.search(
    "managedata/GetAllCRUAssetVariables?",
    {
      sortColumn: changeSortColumnNameFormat(sorting.sortBy),
      isAscending: sorting.isAscending,
    },
    prt
  )
    .then((res) => {
      // If response status is 200, set asset variable count and total count
      if (res.status === 200) {
        let result = Array.isArray(res.data) ? res.data : res.data.data || [];
        setAssetVariableCount(result);
        setLoading(false);
        setTotalCount(res.data.totalCount);

      } else {
        // Else, call toasterError function
        toasterError(`${res.data}`)
        setLoading(false);
      }
    })
    .catch((err) => {
      // Catch any errors and call ErroHandling function
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
};

  // Function for Search Clear
  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) {
      searchAssetVariableCount('')
    } else {
      searchAssetVariableCount(searchValue)
    }
  }, [sorting]);

  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) {
      searchAssetVariableCount('')
    } else {
      searchAssetVariableCount(searchValue)
    }
  }, [page, reload, rowsPerPage])

  //Toggle add edit drawer 
  const toggleDrawer = (open, mode) => (event, item) => {
    setFormData(item);
    setState({ ...state, right: open, formData: item });
    setCrudState(mode);
  };

  const columns = [
    { id: "company", label: "Company", minWidth: 325, width: "30%", sorting: true },
    { id: "asset", label: "Asset", minWidth: 250, sorting: true },
    { id: "variableCount", label: "Variable Count", minWidth: 150, width: "25%", sorting: false, align: "center" },
  ];

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="left" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Asset - Variable Count</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography>Asset - Variable Count</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {access.allowEdit && <Button
                onClick={toggleDrawer(true, "Add")}
                variant="contained"
                color="accent2"
                startIcon={<AddIcon style={{ fontSize: "16px" }} />}
              >
                Add Asset Variable Count
              </Button>}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className={classes.searchByKeyword}
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className={classes.serchByClear}
                        onClick={() => {
                          setSearchValue("");
                          getAssetVariableCount();
                        }}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">
                      Total Records: <span className={classes.totalRecord}>{totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Commodity Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={assetVariableCount ? assetVariableCount : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setReload(true)
                    setSorting(e)
                  }}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                  }}
                />
              </Grid>

              {/* Add & Edit Asset Variable Count Drawer */}
              <SideDrawer
                formComp={
                  <AddEditAssetVariableCount
                    state={state}
                    setState={setState}
                    crudState={crudState}
                    formData={formData}
                    setFormData={setFormData}
                    hideSideDrawer={() => {
                      setState({ right: false });
                      getAssetVariableCount();
                    }}
                  />
                }
                state={state}
                setState={setState}
              />
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetVariableCount;