// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import axios from "axios";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
// import history from "../../../Routes/History";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
// Importing Custom Components
import DataTable from "../../../../UIComponent/DataTable";
import BaseLayout from "../../../../UIComponent/BaseLayout";

// Importing useStyles
import useStyles from "./StatusReport.styles";

// Import Fluent UI Icons
import {
  ChevronLeftIcon,
  ChevronRightSmallIcon,
  ExportIcon,
} from "@fluentui/react-icons-mdl2";
import GetMenuService from "../../../../Services/GetMenuService";
import CommonService from "../../../../Services/CommonService";
import { useHistory } from "react-router-dom";

const DataCollectionStatusReportDrill = (props) => {
  const history = useHistory()
  // Declaration of States and Component Variables Start Here
  const [historyData, setHistoryData] = useState();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [priorityId, setPriorityId] = useState(null);
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isAllSelected, setIsSelected] = useState(false);
  const [reload, setReload] = useState(false);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: true });
  let [filterValue, setFilterValue] = React.useState("");
  const [exportToExcel, setExportToExcel] = React.useState(false);
  const [id, setId] = useState([]);
  const [selected, SetSelected] = React.useState([]);
  const date = new Date().toLocaleDateString();
  const date1 = date.split("/").join("-");
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetHistoryData();
  }, [])
  React.useEffect(() => {
    if ((currentStatus !== undefined && currentStatus !== null) && (priorityId !== undefined && priorityId !== null)) {
      consumeData();
    }
  }, [currentStatus, priorityId]);
  React.useEffect(() => {
    if ((currentStatus !== undefined && currentStatus !== null) && (priorityId !== undefined && priorityId !== null)) {
      consumeData();
    }
  }, [reload, page, rowsPerPage]);
  React.useEffect(() => {
    if ((currentStatus !== undefined && currentStatus !== null) && (priorityId !== undefined && priorityId !== null)) {
      consumeData();
    }
  }, [sorting]);
  // Declaration of React Hooks End Here

  // Creating Data Table for Manage Users
  function createData(jobNo, jobName, company, asset, variableCount, commodity, createdDate, assignedTo, assignedBy, startDate, tat, remainingDays, priority, statusName, country, region, subregion) {
    return { jobNo, jobName,company, asset, variableCount, commodity, createdDate, assignedTo, assignedBy, startDate, tat, remainingDays, priority, statusName, country, region, subregion };
  }
  function createUnassignedData(jobNo, jobName ,company, asset, variableCount, commodity, startDate, tat, remainingDays, priority, statusName, country, region, subregion) {
    return { jobNo, jobName,company, asset, variableCount, commodity, startDate, tat, remainingDays, priority, statusName, country, region, subregion };
  }

  const GetHistoryData = () => {
    
    let temp = CommonService.GetStoredData(props, "historyData");

    if (temp) {
      // setCurrentStatus(data.item);
      // setPriorityId(data.priorityId);
      setHistoryData(temp);
      if (temp.item) {
        setCurrentStatus(temp.item);
      }
      if (temp.priorityId !== undefined && temp.priorityId !== null) {
        setPriorityId(temp.state.priorityId);
      }
    }
  }
  // Assigning Columns and UI Props
  const columns = [
    { id: "jobNo", label: "Job No", minWidth: 100, width: 100, sorting: true },
    { id: "jobName", label: "Job Name", minWidth: 200, width: 100, sorting: true },
    { id: "company", label: "Company", minWidth: 250, width: 300, sorting: true, },
    { id: "asset", label: "Asset", minWidth: 200, width: 250, sorting: true },
    { id: "variableCount", label: "Variable count", minWidth: 90, width: 90, sorting: true, align: "center" },
    { id: "commodity", label: "Commodity", minWidth: 350, width: 400, sorting: true, truncate: 90, tooltipWithTruncate: true },
    { id: "createdDate", label: "Created Date", minWidth: 150, width: 150, sorting: true },
    { id: "assignedTo", label: "Assigned To", minWidth: 200, width: 200, sorting: true },
    { id: "assignedBy", label: "Assigned By", minWidth: 200, width: 200, sorting: true },
    { id: "startDate", label: "Job Start Date", sorting: true, minWidth: 130, width: 130, align: "center" },
    { id: "tat", label: "TAT", sorting: true, minWidth: 130, width: 130, align: "center" },
    { id: "remainingDays", label: "Remaining Days", sorting: true, minWidth: 130, width: 130, align: "center" },
    { id: "priority", label: "Priority", minWidth: 90, width: 90, type: "Chip", sorting: true, align: "center" },
    { id: "statusName", label: "Status Name", minWidth: 150, width: 150, sorting: true },
    { id: "country", label: "Country", minWidth: 150, width: 150, sorting: true },
    { id: "region", label: "Region", minWidth: 150, width: 150, sorting: true },
    { id: "subregion", label: "Subregion", minWidth: 150, width: 150, sorting: true }
  ];

  const unassignedcolumns = [
    { id: "jobNo", label: "Job No", minWidth: 100, width: 100, sorting: true },
    { id: "jobName", label: "Job Name", minWidth: 200, width: 100, sorting: true },
    { id: "company", label: "Company", minWidth: 250, width: 300, sorting: true, },
    { id: "asset", label: "Asset", minWidth: 200, width: 250, sorting: true },
    { id: "variableCount", label: "Variable count", minWidth: 90, width: 90, sorting: true, align: "center" },
    { id: "commodity", label: "Commodity", minWidth: 350, width: 400, sorting: true, truncate: 90, tooltipWithTruncate: true },
    { id: "startDate", label: "Job Start Date", sorting: true, minWidth: 130, width: 130, align: "center" },
    { id: "tat", label: "TAT", sorting: true, minWidth: 130, width: 130, align: "center" },
    { id: "remainingDays", label: "Remaining Days", sorting: true, minWidth: 130, width: 130, align: "center" },
    { id: "priority", label: "Priority", type: "Chip", minWidth: 90, width: 90, sorting: true, align: "center" },
    { id: "statusName", label: "Status Name", minWidth: 150, width: 150, sorting: true },
    { id: "country", label: "Country", minWidth: 150, width: 150, sorting: true },
    { id: "region", label: "Region", minWidth: 150, width: 150, sorting: true },
    { id: "subregion", label: "Sub region", minWidth: 150, width: 150, sorting: true },

  ];

  // Function for Get Current Status
  // Create a fileName variable with the current date in the format of "Job Status Drill Down YYYY-MM-DD"
  const fileName = `Job Status Drill Down ${new Date()
    .toLocaleDateString() // Get the current date in the format of MM/DD/YYYY
    .split("/") // Split the date into an array of strings using "/" as the separator
    .join("-")}`; // Join the array of strings using "-" as the separator

// This function is used to get data from the server    
    const getData = async () => {
      // Create a new promise to handle the response
    const response = await new Promise((resolve, reject) => {
      resolve(
        axios.get(
          `${process.env.React_App_baseUrl}statusreport/Get${currentStatus}StatusReport`,
          {
            headers: {
              priorityId: priorityId,
              // Encode the filter value as a string
              statusReportFilter: historyData.filter
                ? encodeURIComponent(JSON.stringify(historyData.filter))
                : "",
              isAscending: sorting.isAscending,
              // Change the sort column name format
              sortColumn: changeSortColumnNameFormat(sorting.sortBy),
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
            params: { pageNumber: page + 1, pagesize: rowsPerPage },
          }
        )
      );
    });
    console.log(response, "filterState.filterValue")
    return response;
  };
  // Function for Change Sort Column
  const changeSortColumnNameFormat = (fieldName) => {
    if (fieldName == null || fieldName == "") { return ""; }
    if (fieldName.toLocaleLowerCase() === "jobNo".toLocaleLowerCase()) { fieldName = "JobNo"; }
    if (fieldName.toLocaleLowerCase() === "jobName".toLocaleLowerCase()) { fieldName = "JobName"; }
    if (fieldName.toLocaleLowerCase() === "company".toLocaleLowerCase()) { fieldName = "Company"; }
    if (fieldName.toLocaleLowerCase() === "asset".toLocaleLowerCase()) { fieldName = "Asset"; }
    if (fieldName.toLocaleLowerCase() === "variablecount".toLocaleLowerCase()) { fieldName = "NoOfVariable"; }
    if (fieldName.toLocaleLowerCase() === "commodity".toLocaleLowerCase()) { fieldName = "Commodity"; }
    if (fieldName.toLocaleLowerCase() === "country".toLocaleLowerCase()) { fieldName = "Country"; }
    if (fieldName.toLocaleLowerCase() === "region".toLocaleLowerCase()) { fieldName = "Region"; }
    if (fieldName.toLocaleLowerCase() === "subRegion".toLocaleLowerCase()) { fieldName = "SubRegion"; }
    if (fieldName.toLocaleLowerCase() === "createdDate".toLocaleLowerCase()) { fieldName = "CreatedOn"; }
    if (fieldName.toLocaleLowerCase() === "TAT".toLocaleLowerCase()) { fieldName = "TAT"; }
    if (fieldName.toLocaleLowerCase() === "statusName".toLocaleLowerCase()) { fieldName = "StatusName"; }
    if (fieldName.toLocaleLowerCase() === "commodities".toLocaleLowerCase()) { fieldName = "Commodities"; }
    if (fieldName.toLocaleLowerCase() === "completedOn".toLocaleLowerCase()) { fieldName = "CompletedOn"; }
    if (fieldName.toLocaleLowerCase() === "assignedTo".toLocaleLowerCase()) { fieldName = "AssignedTo"; }
    if (fieldName.toLocaleLowerCase() === "assignedBy".toLocaleLowerCase()) { fieldName = "AssignedBy"; }
    if (fieldName.toLocaleLowerCase() === "meritQC".toLocaleLowerCase()) { fieldName = "MeritQC"; }
    if (fieldName.toLocaleLowerCase() === "startDate".toLocaleLowerCase()) { fieldName = "StartDate"; }
    if (fieldName.toLocaleLowerCase() === "remainingDays".toLocaleLowerCase()) { fieldName = "RemainingDays"; }
    if (fieldName.toLocaleLowerCase() === "priority".toLocaleLowerCase()) { fieldName = "Priority"; }
    return fieldName;
  };

  // Function for Export To Excel
  // Function to handle exporting data to an Excel file
  const handleExportToExcel = () => {
    // Set the number of rows per page to the total count
    setRowsPerPage(totalCount);
    // Set export to excel flag to true
    setExportToExcel(true);
    // Consume the data
    consumeData();
  };
  // This function is used to export data from an API to a CSV file
  const exportToCSV = (apiData, fileName) => {
    // Set the file type and extension
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    // Convert the API data to a worksheet
    const ws = XLSX.utils.json_to_sheet(apiData);
    // Set the columns for the worksheet
    const columns = currentStatus !== "Unassigend" ?
      ["jobNo", "jobName","company", "asset", "variableCount", "commodity", "createdDate", "assignedTo",
        "assignedBy", "startDate", "tat",
        "remainingDays", "priority", "statusName", "country", "region", "subregion"]
      : ["jobNo", "jobName","company", "asset", "variableCount", "commodity", "startDate", "tat",
        "remainingDays",
        "priority", "statusName", "country", "region", "subregion"];
    XLSX.utils.sheet_add_aoa(
      ws, [columns], { origin: "A1", }
    );
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
// This function is used to consume data from the API
  const consumeData = async () => {
    // Set loading to true while fetching data
    setLoading(true);
    // Fetch data from the API
    await getData()
      .then((res) => {
        // Destructure data from response
        let { data } = res;
        // console.log(res, "asasadasdasd");
        // Set total count of data
        setTotalCount(data.totalCount);
        // Map through the data and create new data based on current status
        const finalData = data.data.map((el, key) => {
          if (currentStatus !== "Unassigend") {
            return createData(
              el.jobNo && el.jobNo ? el.jobNo : key + 1,
              el.company,
              el.jobName,
              el.asset, el.noOfVariable, el.commodity,
              el.createdDate && el.createdDate ? el.createdDate : "NA",
              el.assignedTo && el.assignedTo ? el.assignedTo : "NA",
              el.assignedBy && el.assignedBy ? el.assignedBy : "NA",
              el.startDate ? el.startDate : "",
              el.tat,
              el.remainingDays ? el.remainingDays : "",

              el.priority, el.statusName, el.country, el.region, el.subRegion
            )
          }
          else {
            return createUnassignedData(
              el.jobNo && el.jobNo ? el.jobNo : key + 1,
              el.jobName,
              el.company,
              el.asset, el.noOfVariable, el.commodity,
              el.startDate ? el.startDate : "",
              el.tat,
              el.remainingDays ? el.remainingDays : "",
              el.priority, el.statusName, el.country, el.region, el.subRegion
            )
          }

        });

        // console.log(finalData)
        if (exportToExcel) { exportToCSV(finalData, fileName); }
        else { setRows(finalData); }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if(err.res.status===400){
          toasterError(err.res.data);
        }

         });
  };

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Status Report Drill Down</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/aging-report" }}>Dashboard and Reports</Link>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/status-report" }}>Status Report</Link>
            <Typography color="text.primary">
              Status Report Drill Down
            </Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={"auto"}>
              <Button
                onClick={() => {
                  history.push("/app/data-collections/status-report");
                }}
                variant="contained"
                color="accent2"
                startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search and Filter Options Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid item textAlign="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                    onClick={handleExportToExcel}
                  >
                    Export To Excel
                  </Button>
                </Grid>
              </Grid>
              {/* Search and Filter Options End Here */}

              {/* Status Report Drill Down Table */}
              {/* {console.log(rows, "Checkerrrr")} */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={rows ? rows : []}
                  columns={
                    currentStatus !== "Unassigend" ? columns : unassignedcolumns
                  }
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setReload(true);
                    setSorting(e);
                  }}
                  hasChip={{
                    High: { color: "warning", label: "High" },
                    Critical: { color: "error", label: "Critical" },
                    Low: { color: "success", label: "Low" },
                    Medium: { color: "info", label: "Medium" },
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataCollectionStatusReportDrill;