import { makeStyles } from "@mui/styles";
import { alpha } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({

    Dropzone: {
        // height: "80px",
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
        border: `1px dashed ${alpha(theme.palette.common.black, 0.23)}`,
        display: "flex",
        flexDirection: "column",
        margin: '0',
        padding: '0',
    },
    Highlight: {
        borderColor: theme.palette.primary.main,
        boxShadow: theme.shadows[2]
    },
    DropzoneInnerWrap: {
        padding: theme.spacing(2),
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        width: 1,
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
    },
    helperText: {
        fontSize: 12,
        marginTop: theme.spacing(2)
    },
    barColorPrimary: {
        background: "green",
    },
    barColorSecondary: {
        background: "green",
    }
}));

export default useStyles;
