// Default React, Router and Services Components
import React, { useContext, useState } from "react";
import { Box, Breadcrumbs, Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
// import history from "../../../../Routes/History";
import { useHistory } from "react-router-dom";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";

// Importing Custom Components
import BaseLayout from "../../../../UIComponent/BaseLayout";
import DataTable from "../../../../UIComponent/DataTable";
import DatePicker from "../../../../UIComponent/DatePicker";
import ExportToExcel from "../Excelexport";
import MeritQCFilter from "./MeritQCFilter";
import SideDrawer from "../../../../UIComponent/Drawer";

// Importing useStyles
import useStyles from "./MeritQCStatusReport.styles";

// Import Fluent UI Icons
import { ChevronRightSmallIcon } from "@fluentui/react-icons-mdl2";
import { useSelector } from 'react-redux';
import { FilterContext } from "../Context/Context";

const DataCollectionMeritQCStatusReport = (props) => {
  
const history = useHistory();
  // Declaration of States and Component Variables Start Here
  const filterState = useSelector(state=>state.stateSlice)
  const {companyList, setCompanyList, locationQC,
    setLocationQC,} = useContext(FilterContext)


  const [allRows, setAllRows] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [crudState, setCrudState] = React.useState(false);
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [editJobNumber, setEditJobNumber] = useState(null);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValue, setFilterValue] = useState(null);
  const [filterValues, setFilterValues] = useState();
  const [formData, setFormData] = useState([]);
  const [idforEdit, setIdForEdit] = useState(null);
  const [isAllSelected, setIsSelected] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [locationFilter, setLocationFilter] = useState([]);
  const [searchData, setSearchData] = useState({ FromDate: null, ToDate: null, });
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  const [open, setOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [scroll, setScroll] = React.useState("paper");
  const [searchValue, setSearchValue] = useState("");
  const [selected, SetSelected] = useState([]);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [state, setState] = React.useState({ right: false });
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [submitValues, setSubmitValues] = useState(false)
  const [value, setValue] = useState({ startDate: "", endDate: "", });
  const classes = useStyles();
  const date = new Date().toLocaleDateString();
  const date1 = date.split("/").join("-");
  // Declaration of States and Component Variables End Here

  // Assigning Columns and UI Props
  const gridcolumns = [
    { id: "priority", label: "Job Priority", minWidth: 200, width: '20%' },
    { id: "qcPassedWithNoErrors", type: "clickable", label: "QC Passed with no errors", minWidth: 140, width: '20%', sorting: true, align: "center" },
    { id: "qcPassedWithMeritQCErrors", type: "clickable", label: "QC Passed With Merit QC Errors", minWidth: 150, width: '20%', sorting: true, align: "center" },
    { id: "meritQCFailed", type: "clickable", label: "Merit QC Failed", minWidth: 110, width: '20%', sorting: true, align: "center" },
    { id: "total", type: "clickable", label: "Total", minWidth: 110, width: '20%', sorting: true, align: "center" },
  ];


  // search on enter press
  const handleKeyUp = (event) => {
    if (
      (event.keyCode === 8 || event.which === 8) &&
      event.target.nodeName !== "BUTTON"
    ) {
      if (searchValue.length === 0) {
      }
    }
    if (
      (event.keyCode === 13 || event.which === 13) &&
      event.target.nodeName !== "BUTTON"
    ) {
    }
  };

  // To Fill the grid
  React.useEffect(() => { GetMeritQCReport(); }, [filterValue, submitValues]);
  React.useEffect(() => { getFilterData(); }, [reload]);

  // For searching the grid
  const globalSearch = (value) => { setSearchValue(value); };

// This function is used to get the MeritQCReport
const GetMeritQCReport = async () => {
  // Set loading to true
  setLoading(true);
  // Create a new promise
  let response = await new Promise((resolve, reject) => {
    // Declare searchvalue variable
    let searchvalue;
    // Check if value is not undefined
    if (value != undefined) {
      // Check if FromDate and ToDate are not empty strings
      if (value.FromDate !== "" && value.ToDate !== "") {
        // Validate the value
        if (validation(value)) {
          // Assign values to searchvalue
          searchvalue = {
            "FromDate":value.startDate,
            "ToDate":value.endDate
          }
        }
      }
    }
    // Assign filterState.meritQCFilter and searchvalue to searchData
    let searchData = {...filterState.meritQCFilter, ...searchvalue}
    // Resolve the promise with axios get request
    resolve(
      axios.get(`${process.env.React_App_baseUrl}reports/GetMeritQCReport`, {
        headers: { filter:  encodeURIComponent(JSON.stringify(searchData)),
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
           },
      })
      
    );
  })
  ;
  // Set allRows and tableData with response data
  setAllRows(response.data);
  setTableData(response.data);
  // Set loading to false
  setLoading(false);
  // Return response
  return response;
}
  // Function for Search Clear
  const searchClear = () => {
    setSearchValue("");
    setPage(0);
    setReload(!reload);
  };

  const displayDrillReport = (event, row, column, index) => {
    // Declare a variable to store the priorityId
    var priorityId = 0;
    // Set priorityId to 4 if index is 0 (critical)
    if (index == 0) { priorityId = 4; }
    // Set priorityId to 1 if index is 1 (high)
    else if (index == 1) { priorityId = 1; }
    // Set priorityId to 3 if index is 2 (low)
    else if (index == 2) { priorityId = 3; }
    // Set priorityId to 2 if index is 3 (medium)
    else if (index == 3) { priorityId = 2; }
    // Set priorityId to 5 if index is 4 (total)
    else if (index === 4) { priorityId = 5; }
    // Push the pathname and state to history
    history.push({
      pathname: "/app/data-collections/merit-qc-drill",
      state: {
        type: column,
        priorityId: priorityId,
        filterData: {...filterState.meritQCFilter, ...value},
      },
    });
  };

  // Function for Validation
  const validation = (value) => {
    if (value.startDate >= value.endDate) {
      // toasterError("To Date Must Be Greater");
      return false;
    } return true;
  };

 // This function is used to get filter data from the API
const getFilterData = async () => {
  try {
    // Making a GET request to the API
    const response = await axios.get(
      `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`,{
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }
    );
    // If there is no data in the response, set the data for filter to an empty array
    if (response.data.length === 0) {
      setDataForFilter([]);
    } else {
      // Set the data for filter to the response data
      setDataForFilter(response.data);
      // Iterate through the companies and set the checked property of each item to false
      response.data.companies.forEach((item, index) => {
        item.checked = false;
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
        }
      });
      // Iterate through the regions and set the checked property of each item to false
      response.data.location.regions.forEach((item, index) => {
        item.checked = false;
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
          }
        }
      });
      // If the company list is empty, set it to the response data's companies
      if(companyList.length === 0){
        setCompanyList(response.data.companies)
      }
      // If the location QC is empty, set it to the response data's regions
      if(locationQC.length === 0){
        setLocationQC(response.data.location.regions)
      }
      
      // Set the company filter and location filter to the response data
      setCompanyFilter(response.data.companies);
      setLocationFilter(response.data.location.regions);
    }
  } catch (err)  { ErroHandling(err, function (res) {
    // Call the toasterError function with the response as parameter
    toasterError(res)})}
};
  const fetchData = async () => { const { data } = await GetMeritQCReport(); setTableData(data); };

  // This function is used to search data within a given date range
  const searchDataDateRange = function () {
    // Make an axios GET request to the specified URL with the given headers
    axios.get(`${process.env.React_App_baseUrl}reports/GetMeritQCReport`, {
      headers: { filter: value ? encodeURIComponent(JSON.stringify(value)) : null, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    },
    }).then((resp) => { 
      // Set all rows to the response data
      setAllRows(resp.data);
    });
  };

  // Function for Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    setState({ ...state, right: open });
  };
  const [assetTypeDD, setAssetTypeDD] = useState([]);


// This function resets the tree filter
const resetTreeFilter = () => {
  // Declare company and location filters
  let comFilter = companyFilter
  let loFilter = locationFilter
  
  // Loop through company filter and set checked to false
  comFilter.forEach((item, index) => {
    item.checked = false;
    // If there are children, loop through them and set checked to false
    if (item.children) {
      item.children.forEach((item, index) => { item.checked = false; });
    }
  });
  
  // Loop through location filter and set checked to false
  loFilter.forEach((item, index) => {
    item.checked = false;
    // If there are children, loop through them and set checked to false
    if (item.children) {
      item.children.forEach((item, index) => { item.checked = false; });
      // If there are grandchildren, loop through them and set checked to false
      if (item.children) {
        item.children.forEach((item, index) => { item.checked = false; });
      }
    }
  });
 
  // Set company and location filters with updated values
  setCompanyFilter([...comFilter])
  setLocationFilter([...loFilter])
 
};
  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Merit QC Report</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/aging-report" }}>Dashboard and Reports</Link>
            <Typography color="text.primary">Merit QC Report</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>

            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>

              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="flex-end" justifyContent="left" spacing={2}>
                  <Grid item xs={12} xl={8}>
                    <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-end">
                    <Grid item xs={"auto"}>
                        <DatePicker
                          labelTitle="From Date"
                          variant="standard"
                          past={false}
                          onChangeDate={(e) => {
                            let selectedDate = new Date(e);
                            let date = selectedDate.getFullYear() +
                              "-" + ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                              "-" + ("0" + selectedDate.getDate()).slice(-2);
                            setValue({ ...value, FromDate: date });
                          }}
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <DatePicker
                          labelTitle="To Date"
                          variant="standard"
                          past={false}
                          minDates={value.startDate}
                          onChangeDate={(e) => {
                            let selectedDate = new Date(e);
                            let date = selectedDate.getFullYear() +
                              "-" + ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                              "-" + ("0" + selectedDate.getDate()).slice(-2);
                            setValue({ ...value, ToDate: date });
                          }}
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <Button onClick={searchDataDateRange} variant="contained" color="secondary">
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} xl={4} textAlign="right">
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="flex-end">
                      <Grid item xs={"auto"}>
                        <Typography variant="subtitle2">
                          <ExportToExcel apiData={tableData} fileName={`MeritQCStatusReport ${date1}`} meritqc />
                        </Typography>
                      </Grid>
                      <Grid item xs={"auto"}>
                        <Button onClick={toggleDrawer(true)} variant="contained" color="secondary">
                          Filter
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Merit QC Report Table */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allRows ? allRows : []}
                  columns={gridcolumns}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={setSorting}
                  hasLink={displayDrillReport}
                  isPagination={false}
                  rowsPerPageVisible
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      <SideDrawer
        formComp={
          <MeritQCFilter
            dataForFilter={dataForFilter}
            filterFinalData={filterFinalData}
            companyFilter={companyFilter}
            locationFilter={locationFilter}
            setFilterValues={setFilterValues}
            setFilterValue={setFilterValue}
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            userId={null}
            setLocationFilter={setLocationFilter}
            hideSideDrawer={() => {
              setState({ right: false });
            }}
            reload={reload}
            setReload={setReload}
              setCompanyFilter={setCompanyFilter}
              assetTypeDD={assetTypeDD}
              setFilterFinalData={setFilterFinalData}
              resetTreeFilter={resetTreeFilter}
              setDataForFilter={setDataForFilter}
              getFilterData={getFilterData}
              setSubmitValues={setSubmitValues}
              submitValues={submitValues}
              companyList={companyList}
              setCompanyList={setCompanyList}
              locationQC={locationQC}
              setLocationQC={setLocationQC}
          />
        }
        state={state}
        setState={setState}
        reload={reload}
        setReload={setReload}
        setFilterValue={setFilterValue}
      />
    </Box>
  );
};

export default DataCollectionMeritQCStatusReport;