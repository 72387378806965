// Default React, useState and Material Components
import React from "react";
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Components
import BaseLayout from "../../../UIComponent/BaseLayout";
import EmptyState from "../../../UIComponent/EmptyState";

// Import Fluent UI Icons
import { ChevronRightSmallIcon, CodeEditIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const OwnerCorrectOwnerName = (props) => {
  const classes = useStyles();

  return (
    
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} className="page-title">
          <h2>Owner & Correct Owner</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">Owner & Correct Owner</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* BaseLayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box sx={{ pt: 11 }}>
              <center>
                <EmptyState
                  icon={
                    <CodeEditIcon
                      style={{ color: "#db9400", fontSize: "70px", marginBottom: "10px" }}
                    />
                  }
                  title="Under Construction!"
                />
              </center>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OwnerCorrectOwnerName;
