// Default React, useState and Material Components
import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  Breadcrumbs,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
// Importing Custom Services
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "./../../../Services/GetMenuService";

// Importing Custom Components
import SideDrawer from "../../../UIComponent/Drawer";
import DataTable from "../../../UIComponent/DataTable";
import { LoaderWrapper } from "../../../UIComponent/Progress";
import BaseLayout from "./../../../UIComponent/BaseLayout/index";
import {
  toasterError,
  toasterSuccess,
} from "./../../../UIComponent/Toaster/index";

// Importing Add & Edit Components
import ScoreParamsSideDrawer from "./AddEditScoreParameter";

// Import Fluent UI Icons
import {
  AddIcon,
  SearchIcon,
  ClearIcon,
  ChevronRightSmallIcon,
} from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import "../../../../src/index.scss";
import useStyles from "../ManageData.styles";

const columns = [
  { id: "id", label: "ID", type: "Highlight" },
  {
    id: "jobType",
    label: "Job Type",
    minWidth: 200,
    width: "25%",
    sorting: true,
  },
  { id: "name", label: "Name", minWidth: 200, width: "25%", sorting: true },
  {
    id: "description",
    label: "Description",
    minWidth: 300,
    width: "80%",
    sorting: true,
    truncate: 65,
    tooltipWithTruncate: true,
  },
  {
    id: "parameterType",
    label: "Parameter Type",
    minWidth: 200,
    width: "25%",
    sorting: true,
  },

  {
    id: "scoringLevelConfiguration",
    label: "Scoring Level",
    minWidth: 200,
    width: "25%",
    sorting: true,
  },
  {
    id: "score",
    label: "Score",
    minWidth: 100,
    width: "15%",
    sorting: true,
    align: "center",
  },
];

const ScoringParameter = () => {
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [searchOn, setSearchOn] = useState(true);
  const [crudState, setCrudState] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, SetSelected] = React.useState([]);
  const [state, setState] = useState({ right: false });
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAllSelected, setIsSelected] = useState(false);
  const [filterValue, setFilterValue] = React.useState("");
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [access, setAccess] = React.useState({
    allowEdit: false,
    allowDelete: false,
    allowView: false,
  });
  // Declaration of States and Component Variables End Here

  const changeSortColumnNameFormat = (fieldName) => {
    console.log(fieldName.toLocaleLowerCase());
    if (fieldName == null || fieldName === "") {
      return "";
    }
    if (fieldName.toLocaleLowerCase() === "name") {
      fieldName = "Name";
    }
    if (fieldName.toLocaleLowerCase() === "description") {
      fieldName = "Description";
    }
    if (fieldName.toLocaleLowerCase() === "score") {
      fieldName = "Score";
    }
    if (fieldName === "scoringLevelConfiguration") {
      fieldName = "ScoringLevelConfiguration";
    }
    if (fieldName === "parameterType") {
      fieldName = "ParameterType";
    }
    if (fieldName === "jobType") {
      fieldName = "JobType";
    }
    return fieldName;
  };
// This function is used to get table data from the API
  const getTableData = async () => {
  // Set loading to true while fetching data  
    setLoading(true);
  // Make a GET request to the API  
    const response = await axios.get(
      `${process.env.React_App_baseUrl}managedata/GetCompanyOwnershipScoringParameterDetails`,
      {
     // Set headers for the request    
        headers: {
          searchText: filterValue ? encodeURIComponent(filterValue) : "",
          sortColumn: changeSortColumnNameFormat(sorting.sortBy),
          isAscending: sorting.isAscending,
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
        // Set parameters for the request 
        params: { pageNumber: page + 1, pagesize: rowsPerPage },
      }
    );
    // Get the data from the response
    let { data } = await response;
    // Set the rows and total count with the fetched data
    setRows(data.data);
    setTotalCount(data.totalCount);
    // Set loading to false after fetching data
    setLoading(false);
  };

// This function handles the deletion of a scoring parameter
const handleDelete = async () => {
  try {
    // Make a post request to the specified URL with the selected item's id and authorization token
    await axios
      .post(
        `${process.env.React_App_baseUrl}managedata/DeleteScoringParameter`,
        null,
        {
          headers: {
            id: selectedItem,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      // If the request is successful, set open to false, reload the page, and display a success message
      .then((res) => {
        setOpen(false);
        if (res.status === 200) {
          setReload(!reload);
          toasterSuccess("Parameter Deleted Successfully");
        } else {
          toasterError("Something went wrong");
        }
      })
      // If the request fails, set open to false and display an error message
      .catch((err) => {
        setOpen(false);
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  } catch (error) {
    // If an error occurs, display an error message
    ErroHandling(error, function (res) {
      toasterError(res);
    });
  }
};

  // Declaration of React Hooks Start Here
  useEffect(() => {
    getTableData();
  }, [reload]);

  React.useEffect(() => {
    GetMenuService(45, function (err, res) {
      if (res) {
        console.log(res.data);
        setAccess(res.data);
      } else {
        toasterError(err);
      }
    });
  }, []);

  // This function is used to perform a global search
  const globalSearch = (e) => {
   // Set the page number to 0 
    setPage(0);
   // If the key pressed is "Enter"  
    if (e.key === "Enter") {
      setReload(!reload);
      setSearchOn(false);
    }
  };

// This function clears the search value and triggers a reload
  const clearSearch = () => {
    // Set reload to the opposite of its current value
    setReload(!reload);
    // Set filter value to an empty string
    setFilterValue("");
  };

  // This function toggles the drawer open or closed
  const toggleDrawer = (open, mode) => (event, item) => {
    setState({ ...state, right: open });
    setCrudState(mode);
    setSelectedRow(item);
  };

// Function to open delete dialog
const openDeleteDialog = (open, mode) => (event, item) => {
  // Set open state
  setOpen(open);
  // Set selected item id
  setSelectedItem(item.id);
};

// Function to close delete dialog
  const closeDeleteDialog = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Scoring Parameters</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/ownership-changes/source-management" }}
            >
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/commodity" }}
            >
              Manage Data
            </Link>
            <Typography color="text.primary">Scoring Parameters</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}

        <Grid item xs={12} sm={12} md={6}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={"auto"}>
              {access.allowEdit ? (
                <>
                  <Button
                    onClick={toggleDrawer(true, "Add")}
                    variant="contained"
                    color="accent2"
                    startIcon={<AddIcon style={{ fontSize: 16 }} />}
                  >
                    Add Scoring Parameter
                  </Button>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className={classes.searchByKeyword}
                      value={filterValue}
                      onKeyUp={globalSearch}
                      placeholder="Search by Keyword"
                      onChange={(e) => setFilterValue(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {filterValue ? (
                      <IconButton
                        aria-label="Add"
                        className="serchByClear"
                        onClick={clearSearch}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">
                      Total Records:{" "}
                      <span className={classes.totalRecord}>{totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* VariableType Table /> */}
              <Grid item xs={12}>
                <LoaderWrapper loading={isLoading}>
                  <DataTable
                    className={classes.removeBorder}
                    rows={rows ? rows : []}
                    columns={columns}
                    page={page}
                    setPage={(data) => {
                      setPage(data);
                      setReload(!reload);
                    }}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={(data) => {
                      setRowsPerPage(data);
                      setReload(!reload);
                    }}
                    totalCount={totalCount}
                    isAllSelected={isAllSelected}
                    setIsSelected={setIsSelected}
                    selected={selected}
                    setSelected={SetSelected}
                    sorting={sorting}
                    setSorting={(e) => {
                      console.log(e);
                      setReload(!reload);
                      setSorting(e);
                    }}
                    isActionButton={{
                      ...(access.allowEdit && {
                        edit: toggleDrawer(true, "Edit"),
                      }),
                      ...(access.allowDelete && {
                        delete: openDeleteDialog(true),
                      }),
                    }}
                  />
                </LoaderWrapper>
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Side Drawer  */}
      <SideDrawer
        state={state}
        setState={setState}
        formComp={
          <ScoreParamsSideDrawer
            state={state}
            setState={setState}
            crudState={crudState}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            reload={reload}
            setReload={setReload}
          />
        }
      />

      {/* Delete Dialog */}
      <Dialog
        open={open}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {" "}
            Would you like to delete the selected item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} autoFocus>
            No
          </Button>
          <Button onClick={handleDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ScoringParameter;
