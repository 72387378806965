const loaderReducer = (state = {
    appLoader: false
}, action) => {
    switch (action.type) {
        case 'showLoader':
            return {
                appLoader: true
            }
        case 'hideLoader':
            return {
                appLoader: false
            }
        default: return state;
    }
};
export default loaderReducer;
