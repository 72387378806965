/* eslint-disable no-undef */
// Default React, Router and Services Components
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  CircularProgress,
  Typography,
} from "@mui/material";

// Importing Custom Components
import CustomAutoComplete from "../../../UIComponent/CustomAutoComplete";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
import axios from "axios";
import CommonService from "../../../Services/CommonService";
import ErroHandling from "../../../Services/ErrorHandling";
import { ExportToExcel } from "../../../ExportToExcel";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing useStyles
import useStyles from "./jobManagement.styles";

// Import Fluent UI Icons
import { ClearIcon, DeleteIcon, SearchIcon } from "@fluentui/react-icons-mdl2";
import AlertDialog from "../../../UIComponent/AlertDialog";
import MultiSelect from "../../../UIComponent/MultiSelectDropdown";
import { LoaderWrapper } from "../../../UIComponent/Progress";

const BulkUploadViewer = (props) => {
  const classes = useStyles();
  const [valueData, setValueData] = useState();
  const [filename, setFilename] = useState("BulkUploadErrorLog");
  const [jobType, setJobType] = useState([]);
  const [jobTypeId, setJobTypeId] = useState(0);
  const [defaultJobType, setDefaultJobType] = useState([]);
  const [priority, setPriority] = useState();
  const [searchValue, setSearchValue] = useState();
  const [loading, setLoading] = useState();
  const [uploadedExcelData, setUploadedExcelData] = useState([]);
  const [uploadedDuplicateExcelData, setUploadedDuplicateExcelData] = useState(
    []
  );
  const [InitialExcelData, setInitialExcelData] = useState([]);
  const [longitude, setLongitude] = useState(false);
  const [latitude, setLatitude] = useState(false);
  const [owner, setOwner] = useState(false);
  const [operator, setOperator] = useState(false);
  const [isListedCompany, setIsListedCompany] = useState(false);
  const [ownershipDate, setOwnershipDate] = useState(false);
  const [stockExchange, setStockExchange] = useState(false);
  const [ownershipPercentage, setOwnershipPercentage] = useState(false);
  const [ownershipYear, setOwnershipYear] = useState(false);
  const [seeThroughOwners, setSeeThroughOwners] = useState(false);
  const [jointVentures, setJointVentures] = useState(false);
  const [updates, setUpdates] = useState(false);
  const [jobName, setJobName] = useState("");
  const [jobId, setJobId] = useState(0);
  const [jobInstruction, setJobInstruction] = useState("");
  const [openConfirmation, setConfirmation] = useState(false);
  const [isSelectAll, setSelectAll] = useState(false);
  const handleADClose = () => {
    setConfirmation(false);
  };
  const [deletedItem, setDeletedItem] = useState(null);
  const [multiDeleteItem, setMultiDeleteItem] = useState([]);
  const [selectedDeleteIndex, setSelectedDeleteIndex] = useState(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isLoading, setIsLsoading] = useState(false);
  const [errorExcel, setErrorExcel] = useState();
  const [errorDialog, setErrorDialog] = useState(false);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [yearsForSave, setYearsForSave] = useState([]);
  const globalSearch = (value) => {
    setSearchValue(value ? value : "");
  };
  const [isTableLoading, setIsTableLoading] = useState(false);

  function createErrorDataExcel(companyId, asset, assetId, company, error) {
    return { companyId, asset, assetId, company, error };
  }

  useEffect(() => {
    if (props?.uploadedExcelData?.bulkUploadJobDetails) {
      if (props?.uploadedExcelData?.bulkUploadJobDetails) {
        let excelData = props?.uploadedExcelData?.bulkUploadJobDetails.map(
          (data, index) => {
            return {
              Id: index,
              companyId: data.companyId,
              asset: data.asset,
              assetId: data.assetId,
              commodities: data.commodities,
              country: data.country,
              region: data.region,
              subRegion: data.subRegion,
              company: data.company,
              operator: data.operator,
              ownershipPercentage: data.ownershipPercentage,
              //ownershipDate: data.ownershipDate,
              ownershipYear: data.ownershipYear,
              lattitude: data.lattitude,
              longitude: data.longitude,
              isDeleted: false,
              selected: false,
              isListedCompany: data.isListedCompany,
            };
          }
        );
        setUploadedExcelData(excelData);
        setInitialExcelData(excelData);
        //setErrorDialog(true);
        // setInitialData(excelData);
      }
      if (props?.uploadedExcelData?.longitude) {
        setLongitude(props?.uploadedExcelData?.longitude);
      }

      if (props?.uploadedExcelData?.lattitude) {
        setLatitude(props?.uploadedExcelData?.lattitude);
      }
      if (props?.uploadedExcelData?.owner) {
        setOwner(props?.uploadedExcelData?.owner);
      }
      if (props?.uploadedExcelData?.ownershipDate) {
        setOwnershipDate(props?.uploadedExcelData?.ownershipDate);
      }
      if (props?.uploadedExcelData?.seeThroughOwners) {
        setSeeThroughOwners(props?.uploadedExcelData?.seeThroughOwners);
      }
      if (props?.uploadedExcelData?.joinVentures) {
        setJointVentures(props?.uploadedExcelData?.joinVentures);
      }
      if (props?.uploadedExcelData?.operator) {
        setOperator(props?.uploadedExcelData?.operator);
      }
      if (props?.uploadedExcelData?.isListedCompany) {
        setIsListedCompany(props?.uploadedExcelData?.isListedCompany);
      }
      if (props?.uploadedExcelData?.stockExchange) {
        setStockExchange(props?.uploadedExcelData?.stockExchange);
      }
      if (props?.uploadedExcelData?.updates) {
        setUpdates(props?.uploadedExcelData?.updates);
      }
      if (props?.uploadedExcelData?.jobName) {
        setJobName(props?.uploadedExcelData?.jobName);
      }
      if (props?.uploadedExcelData?.jobId) {
        setJobId(props?.uploadedExcelData?.jobId);
      }
      if (props?.uploadedExcelData?.jobInstruction) {
        setJobInstruction(props?.uploadedExcelData?.jobInstruction);
      }
    }
    if (props?.uploadedExcelData?.errorRecords) {
      let excelData = props?.uploadedExcelData?.errorRecords.map(
        (data, index) => {
          return {
            companyId: data.companyId,
            asset: data.asset,
            assetId: data.assetId,
            company: data.company,
            error: data.error,
          };
        }
      );
      setUploadedDuplicateExcelData(excelData);
      setErrorDialog(true);

      // setInitialData(excelData);
    }
  }, [props]);
  useEffect(() => {
    GetJobType();
    getYear();
  }, []);

  // useEffect(() => {
  //   const rowsDataExcel = errorData.map(row =>
  //     createErrorDataExcel(row.sourceType, row.sourceLink, row.topicName, row.sourceTypeError, row.sourceLinkFormatError, row.duplicates)
  //   );
  //   setErrorExcel(rowsDataExcel);

  // }, [errorData]);
  const handleErrorClose = () => {
    if (uploadedExcelData.length > 0) {
      setErrorDialog(false);
    } else {
      if (props?.setClose) {
        props.setClose(false);
      }
      handleADClose();
    }
  };
  const openAlertDialog = (item) => {
    setConfirmation(true);
    setDeletedItem(item);
  };
  const multipleDelete = () => {
    let allowConfirmation = false;
    uploadedExcelData.forEach((element) => {
      if (element.selected) {
        allowConfirmation = true;
      }
    });
    if (!allowConfirmation) {
      toasterError("please select at least one asset.");
      return;
    }
    setConfirmation(true);
  };

  const GetJobType = () => {
    axios
      .get(process.env.React_App_baseUrl + "job/GetJobTypeDropDown", {
        headers: {
          Authorization: CommonService.getToken(),
        },
      })
      .then(function (response) {
        let type = [];
        response.data.map((row) => {
          if (row.name.toLowerCase() === "company changes") {
            setJobTypeId(row.id);
            setDefaultJobType([row.id, "Company Changes"]);
          }

          type.push({ id: row.id, label: row.name });
        });
        setJobType(type);
      })
      .catch(function (error) {
        {
          ErroHandling(error, function (res) {
            toasterError(res);
          });
        }
      });
  };

  const handleKeyUp = (event) => {
    if (
      (event.keyCode === 8 || event.which === 8) &&
      event.target.nodeName !== "BUTTON"
    ) {
      if (searchValue) {
      }
    }
    if (
      (event.keyCode === 13 || event.which === 13) &&
      event.target.nodeName !== "BUTTON"
    ) {
      searchResult();
    }
  };

  const DeleteData = () => {
    if (deletedItem) {
      if (deletedItem instanceof Object) {
        uploadedExcelData.forEach((element) => {
          if (element.Id === deletedItem.Id) {
            element.isDeleted = true;
          }
        });
      }
    } else {
      uploadedExcelData.forEach((element) => {
        if (element.selected) {
          element.isDeleted = true;
        }
      });
      if (isAllSelected) {
        setIsAllSelected(!isAllSelected);
      }
    }
    toasterSuccess("Deleted Successfully");
    setUploadedExcelData([...uploadedExcelData]);
    setInitialExcelData([...uploadedExcelData]);
    handleADClose();
  };
  const save = () => {
    setLoading(true);
    if (jobName === undefined || jobName === null || jobName === "") {
      toasterError("Job name can not be empty");
      setLoading(false);
      return;
    }
    if (yearsForSave === undefined || yearsForSave === null || yearsForSave.length == 0) {
      toasterError("please select year");
      setLoading(false);
      return;
    }

    let bulkRowDetails = [];
    if (uploadedExcelData !== undefined && uploadedExcelData !== null) {
      uploadedExcelData.forEach((data) => {
        if (!data.isDeleted) {
          bulkRowDetails.push({
            asset: data.asset,
            assetId: data.assetId,
            company: data.company,
            companyId: data.companyId,
            country: data.country,
            region: data.region,
            subRegion: data.subRegion,
            commodities: data.commodities,
            lattitude: data.lattitude,
            longitude: data.longitude,
            ownershipPercentage: data.ownershipPercentage,
            //ownershipDate: data.ownershipDate,
            ownershipYear: data.ownershipYear,
            operator: data.operator,
            isDeleted: data.isDeleted,
            isListedCompany: data.isListedCompany,
          });
        }
      });
    }

    let saveBulkUploadJobCODto = {
      jobId: jobId,
      jobName: jobName,
      jobInstructions: jobInstruction,
      jobType: jobTypeId,
      dataPoints: {
        lattitude: latitude,
        longitude: longitude,
        owner: owner,
        ownershipPercentage: ownershipPercentage,
        //ownershipDate: ownershipDate,
        ownershipDate: ownershipYear,
        operator: operator,
        stockExchange: stockExchange,
        seeThroughOwners: seeThroughOwners,
        joinVentures: jointVentures,
        updates: updates,
        isListedCompany: isListedCompany
      },
      jobYearsDto: yearsForSave,
      bulkUploadJobDetails: bulkRowDetails,
    };
    if (bulkRowDetails !== undefined && bulkRowDetails !== null) {
      if (bulkRowDetails.length == 0) {
        setLoading(false);
        toasterError("Asset list can not be empty");
        return;
      }
    }

    //console.log(saveBulkUploadJobCODto)

    axios
      .post(
        process.env.React_App_baseUrl +
        "OwnershipChangeBulkUploadJob/SaveBulkUploadJob_V2",
        saveBulkUploadJobCODto,
        {
          headers: {
            Authorization: CommonService.getToken(),
            isFromCreateJobs: false
          },
        }
      )
      .then(function (response) {
        toasterSuccess("Job saved successfully.");

        if (response.status === 200) {
          axios
            .get(
              process.env.React_App_baseUrl +
              "OwnershipChangeBulkUploadJob/MapLatestJobAssetData",
              {
                headers: {
                  Authorization: CommonService.getToken(),
                  jobAssetList: response.data.jobAssetList,
                },
              }
            )
            .then(() => { })
            .catch(() => { });
        }

        handleADClose();
        if (props?.setClose) {
          props.setClose(false);
        }
        setLoading(false);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
        setLoading(false);
      });
  };

  const searchResult = (search) => {
    let bulkRowDetails = [];
    if (InitialExcelData !== undefined && InitialExcelData !== null) {
      InitialExcelData.forEach((data) => {
        if (!data.isDeleted) {
          bulkRowDetails.push({
            asset: data.asset,
            assetId: data.assetId,
            company: data.company,
            companyId: data.companyId,
            country: data.country,
            region: data.region,
            subRegion: data.subRegion,
            commodities: data.commodities,
            lattitude: data.lattitude,
            longitude: data.longitude,
            ownershipPercentage: data.ownershipPercentage,
            // ownershipDate: data.ownershipDate,
            ownershipYear: data.ownershipYear,
            operator: data.operator,
            isDeleted: data.isDeleted,
            isListedComapny: data.isListedCompany,
          });
        }
      });
    }
    // if (search === "") {
    //   setUploadedExcelData(InitialExcelData);
    //   return;
    // }

    axios
      .post(
        process.env.React_App_baseUrl +
        "OwnershipChangeBulkUploadJob/BulkUploadJobDetailsBySearch",
        bulkRowDetails,
        {
          headers: {
            searchText: searchValue ? encodeURIComponent(searchValue) : "",
            Authorization: CommonService.getToken(),
          },
        }
      )
      .then(function (response) {
        if (response.data) {
          let excelData = [];
          if (response.data !== undefined && response.data !== null) {
            excelData = response.data.map((data, index) => {
              return {
                Id: index,
                companyId: data.companyId,
                asset: data.asset,
                assetId: data.assetId,
                commodities: data.commodities,
                country: data.country,
                region: data.region,
                subRegion: data.subRegion,
                company: data.company,
                operator: data.operator,
                ownershipPercentage: data.ownershipPercentage,
                //ownershipDate: data.ownershipDate,
                ownershipYear: data.ownershipYear,
                lattitude: data.lattitude,
                longitude: data.longitude,
                isDeleted: false,
                selected: false,
                isListedCompany: data.isListedCompany,
              };
            });
            setUploadedExcelData(excelData);
          }
        }
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });
  };

  const IndividualDelete = (item) => {
    if (uploadedExcelData !== undefined && uploadedExcelData !== null) {
      uploadedExcelData.forEach((element) => {
        if (element.Id === item.Id) {
          element.selected = !element.selected;
        }
      });
      const selected = item.selected
        ? uploadedExcelData.every((el) => el.selected)
        : false;
      setIsAllSelected(selected);
      setUploadedExcelData([...uploadedExcelData]);
      setInitialExcelData([...uploadedExcelData]);
    }
  };
  const SelectAllDelete = (event) => {
    if (uploadedExcelData !== undefined && uploadedExcelData !== null) {
      uploadedExcelData.forEach((element) => {
        if (element.selected !== !isAllSelected) {
          element.selected = !isAllSelected;
        }
      });
      setUploadedExcelData([...uploadedExcelData]);
      setInitialExcelData([...uploadedExcelData]);
      setIsAllSelected(!isAllSelected);
      // setUploadedExcelData([...uploadedExcelData.map(element=>{
      //   element.selected=!isAllSelected;
      //   return element;
      // })])
      // setInitialExcelData(uploadedExcelData);
      //const selectItems = document.getElementsByClassName('SelectCheckBox');
    }
  };
  const getYear = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetYears",
        {
          headers: {
            Authorization: CommonService.getToken()
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setYears(res.data);
        } else {
          toasterError("Something went wrong");
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

  const searchClear = () => {
    setSearchValue("");
    let bulkRowDetails = [];
    if (InitialExcelData !== undefined && InitialExcelData !== null) {
      InitialExcelData.forEach((data) => {
        if (!data.isDeleted) {
          bulkRowDetails.push({
            asset: data.asset,
            assetId: data.assetId,
            company: data.company,
            companyId: data.companyId,
            country: data.country,
            region: data.region,
            subRegion: data.subRegion,
            commodities: data.commodities,
            lattitude: data.lattitude,
            longitude: data.longitude,
            ownershipPercentage: data.ownershipPercentage,
            //ownershipDate: data.ownershipDate,
            ownershipYear: data.ownershipYear,
            operator: data.operator,
            isDeleted: data.isDeleted,
            isListedCompany: data.isListedCompany,
          });
        }
      });
    }
    // if (search === "") {
    //   setUploadedExcelData(InitialExcelData);
    //   return;
    // }

    axios
      .post(
        process.env.React_App_baseUrl +
        "OwnershipChangeBulkUploadJob/BulkUploadJobDetailsBySearch",
        bulkRowDetails,
        {
          headers: {
            searchText: "",
            Authorization: CommonService.getToken(),
          },
        }
      )
      .then(function (response) {
        if (response.data) {
          let excelData = [];
          if (response.data !== undefined && response.data !== null) {
            excelData = response.data.map((data, index) => {
              return {
                Id: index,
                companyId: data.companyId,
                asset: data.asset,
                assetId: data.assetId,
                commodities: data.commodities,
                country: data.country,
                region: data.region,
                subRegion: data.subRegion,
                company: data.company,
                operator: data.operator,
                ownershipPercentage: data.ownershipPercentage,
                //ownershipDate: data.ownershipDate,
                ownershipYear: data.ownershipYear,
                lattitude: data.lattitude,
                longitude: data.longitude,
                isDeleted: false,
                selected: false,
                isListedCompany: data.isListedCompany,
              };
            });
            setUploadedExcelData(excelData);
          }
        }

      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });
  };
  if (errorDialog && uploadedDuplicateExcelData.length > 0) {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={6} textAlign="left">
              <h4 className={classes.headingText}>Bulk Upload Errors</h4>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={"auto"}>
                  <Button onClick={handleErrorClose}>Cancel</Button>
                </Grid>
                <Grid item xs={"auto"}>
                  <ExportToExcel
                    apiData={uploadedDuplicateExcelData}
                    fileName={filename}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {/* Render the table */}
              <Box className={classes.addBorder}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ minWidth: 100, width: 200 }}
                        align="left"
                      >
                        Owner Id
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ minWidth: 100, width: 200 }}
                        align="left"
                      >
                        Asset
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ minWidth: 100, width: 200 }}
                        align="left"
                      >
                        Asset Id
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ minWidth: 100, width: 200 }}
                        align="left"
                      >
                        Owner
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ minWidth: 100, width: 200 }}
                        align="left"
                      >
                        Error
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {uploadedDuplicateExcelData.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell align="left" className={classes.tableCell}>
                          {data.companyId}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {data.asset}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {data.assetId}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {data.company}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {data.error}
                        </TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  } else {
    return (
      // Create Multiple Jobs

      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={3} md={4} lg={1}>
              <TextField
                id="standard-basic"
                label="Job ID"
                variant="standard"
                fullWidth
                readOnly
                defaultValue={jobId}
                value={jobId}
                InputProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={9} lg={8}>
              <TextField
                id="standard-basic"
                label="Job Name"
                variant="standard"
                onChange={(e) => {
                  setJobName(e.target.value);
                }}
                fullWidth
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <CustomAutoComplete
                options={jobType}
                fullWidth
                disabled={true}
                label="Job Type"
                optionLabelKey="label"
                optionValueKey="id"
                onChange={(value) => {
                  setJobTypeId(value.id);
                }}
                defaultValueKey={["id", "label"]}
                defaultValue={defaultJobType}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <TextField
                fullWidth
                id="standard-multiline-static"
                label="Job Instructions"
                multiline
                rows={2}
                onChange={(e) => {
                  setJobInstruction(e.target.value);
                }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>

              <MultiSelect
                items={years ? years : []}
                selectAllLabel={{ label: "Select all" }}
                onChange={(e, value) => {
                  if (e !== undefined && e !== null) {
                    let tempYears = e.map(ele => {
                      return { YearId: ele.id, Year: ele.year }
                    })
                    setYearsForSave(tempYears);
                    setSelectedYear(e)
                  }
                }}
                getOptionLabel={"year"}
                dropLable={"year"}
                role="year"
                label="Year"
                required={true}
              />

            </Grid>





            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid xs="auto" sx={{ mr: 1 }}>
                  <Typography color="text.primary" sx={{ mt: 1 }}>
                    <strong>Select Data Points</strong>
                    <FormControlLabel sx={{ ml: 1 }}
                      control={
                        <Checkbox
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              setSelectAll(true);
                              setLongitude(true);
                              setLatitude(true);
                              setOwner(true);
                              setOperator(true);
                              setOwnershipPercentage(true);
                              setOwnershipYear(true);
                              setOwnershipDate(true);
                              setStockExchange(true);
                              setSeeThroughOwners(true);
                              setJointVentures(true);
                              setUpdates(true);
                            }
                            else {
                              setSelectAll(false);
                              setLongitude(false);
                              setLatitude(false);
                              setOwner(false);
                              setOperator(false);
                              setOwnershipPercentage(false);
                              setOwnershipYear(false);
                              setOwnershipDate(false);
                              setStockExchange(false);
                              setSeeThroughOwners(false);
                              setJointVentures(false);
                              setUpdates(false);
                            }
                          }}
                          checked={isSelectAll || (longitude && latitude && owner && operator && ownershipPercentage && ownershipDate && stockExchange && seeThroughOwners && jointVentures && updates)}
                        />
                      }
                      label="Select All"
                    />
                  </Typography>
                </Grid>

                <Grid item xs={12} className={classes.removeCheckMargin}>
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={1.5}
                    justifyContent="left"
                    alignItems="flex-start"
                  >
                    <Grid item xs={12}>
                      <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={"auto"}>
                          <div className={classes.subHeading} style={{ minWidth: "100px" }}>Asset Info:</div>{" "}
                        </Grid>


                        <Grid item xs="auto">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setLongitude(true);
                                  } else {
                                    setLongitude(false);
                                    setSelectAll(false);
                                  }
                                }}
                                checked={longitude}
                              />
                            }
                            label="Longitude"
                          />
                        </Grid>
                        <Grid item xs="auto">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setLatitude(true);
                                  } else {
                                    setLatitude(false);
                                    setSelectAll(false);
                                  }

                                }}
                                checked={latitude}
                              />
                            }
                            label="Latitude"
                          />
                        </Grid>

                        <Grid item xs="auto">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setOperator(true);
                                  } else {
                                    setOperator(false);
                                    setSelectAll(false);
                                  }

                                }}
                                checked={operator}
                              />
                            }
                            label="Operator"
                          />
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>


                <Grid item xs={12} className={classes.removeCheckMargin}>
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={1.5}
                    justifyContent="left"
                    alignItems="flex-start"
                  >

                    <Grid item xs={12}>
                      <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={"auto"}>
                          <div className={classes.subHeading} style={{ minWidth: "100px" }}>Owner Info:</div>{" "}
                        </Grid>

                        <Grid item xs="auto">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setOwner(true);
                                  } else {
                                    setOwner(false);
                                    setSelectAll(false);
                                  }

                                }}
                                checked={owner}
                              />
                            }
                            label="Owner"
                          />
                        </Grid>

                        <Grid item xs="auto">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setOwnershipPercentage(true);
                                  } else {
                                    setOwnershipPercentage(false);
                                    setSelectAll(false);
                                  }

                                }}
                                checked={ownershipPercentage}
                              />
                            }
                            label="Ownership %"
                          />
                        </Grid>

                        <Grid item xs="auto">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    //setOwnershipDate(true);
                                    setOwnershipYear(true);
                                  } else {
                                    //setOwnershipDate(false);
                                    setOwnershipYear(false);
                                    setSelectAll(false);
                                  }

                                }}
                                checked={ownershipDate}
                              />
                            }
                            label="Ownership Date"
                          />
                        </Grid>

                        <Grid item xs="auto">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setStockExchange(true);
                                  } else {
                                    setStockExchange(false);
                                    setSelectAll(false);
                                  }

                                }}
                                checked={stockExchange}
                              />
                            }
                            label="Stock Exchange"
                          />
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.removeCheckMargin}>
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={1.5}
                    justifyContent="left"
                    alignItems="flex-start"
                  >

                    <Grid item xs={12}>
                      <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={"auto"}>
                          <div className={classes.subHeading} style={{ minWidth: "100px" }}>Joint Venture:</div>{" "}
                        </Grid>

                        <Grid item xs="auto">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setJointVentures(true);
                                  } else {
                                    setJointVentures(false);
                                    setSelectAll(false);
                                  }

                                }}
                                checked={jointVentures}
                              />
                            }
                            label="Joint Ventures"
                          />
                        </Grid>

                        <Grid item xs="auto">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setSeeThroughOwners(true);
                                  } else {
                                    setSeeThroughOwners(false);
                                    setSelectAll(false);
                                  }

                                }}
                                checked={seeThroughOwners}
                              />
                            }
                            label="Equity Owners"
                          />
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>


                <Grid item xs={12} className={classes.removeCheckMargin}>
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={1.5}
                    justifyContent="left"
                    alignItems="flex-start"
                  >

                    <Grid item xs={12}>
                      <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={"auto"}>
                          <div className={classes.subHeading} style={{ minWidth: "100px" }}>Updates:</div>{" "}
                        </Grid>
                        <Grid item xs="auto">
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setUpdates(true);
                                  } else {
                                    setUpdates(false);
                                    setSelectAll(false);
                                  }

                                }}
                                checked={updates}
                              />
                            }
                            label="Updates"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.addBorder}>
                <Grid item xs={12} className={classes.tableTopPart}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="left"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="input-with-icon-textfield"
                        className="serchByKeyword mt-1"
                        size="small"
                        value={searchValue}
                        placeholder="Search by Keyword"
                        onKeyUp={handleKeyUp}
                        onChange={(e) => globalSearch(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                      />
                      {searchValue ? (
                        <IconButton
                          aria-label="Add"
                          className="searchByClear"
                          onClick={searchClear}
                        >
                          <ClearIcon style={{ fontSize: 10 }} />
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6} textAlign="right">
                      <Button
                        variant="contained"
                        color="accent5"
                        size="small"
                        startIcon={<DeleteIcon style={{ fontSize: 15 }} />}
                        onClick={multipleDelete}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer
                    sx={{ minHeight: 150, maxHeight: "calc(100vh - 480px)" }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 60, width: 60 }}
                            align="left"
                          >
                            <Checkbox
                              checked={isAllSelected}
                              onChange={SelectAllDelete}
                            />
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 200, width: 200 }}
                            align="left"
                          >
                            Asset
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 150, width: 150 }}
                            align="left"
                          >
                            Commodity
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 150, width: 150 }}
                            align="left"
                          >
                            Country
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 150, width: 150 }}
                            align="left"
                          >
                            Region
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 150, width: 150 }}
                            align="left"
                          >
                            Sub Region
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 150, width: 150 }}
                            align="center"
                          >
                            Operator
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 150, width: 150 }}
                            align="left"
                          >
                            Owner
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 100, width: 100 }}
                            align="center"
                          >
                            Ownership %
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 100, width: 100 }}
                            align="center"
                          >
                            Ownership Date
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 150, width: 150 }}
                            align="center"
                          >
                            Lattitude
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 150, width: 150 }}
                            align="center"
                          >
                            Longitude
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 100, width: 100 }}
                            align="center"
                          >
                            IsListedCompany
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 100, width: 100 }}
                            align="center"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {uploadedExcelData !== undefined &&
                          uploadedExcelData !== null
                          ? uploadedExcelData.map((rowData) => {
                            console.log("bulk", rowData)
                            if (!rowData.isDeleted) {
                              return (
                                <TableRow
                                  hover
                                  className={classes.cruTr}
                                  key={rowData.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    <Checkbox
                                      checked={rowData.selected}
                                      onChange={(event) => {
                                        IndividualDelete(rowData);
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {rowData.asset}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {rowData.commodities}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {rowData.country}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {rowData.region}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {rowData.subRegion}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    {rowData.operator}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.tableCell}
                                  >
                                    {rowData.company}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    {rowData.ownershipPercentage}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    {rowData.ownershipYear}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    {rowData.lattitude == 0
                                      ? "-"
                                      : rowData.lattitude}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    {rowData.longitude == 0
                                      ? "-"
                                      : rowData.longitude}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    {rowData.isListedCompany == false
                                      ? "No"
                                      : "Yes"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableCell}
                                  >
                                    {" "}
                                    <Tooltip
                                      title="Delete"
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton
                                        aria-label="Delete"
                                        sx={{ color: "red" }}
                                      >
                                        <DeleteIcon
                                          className={classes.iconSize}
                                          onClick={() =>
                                            openAlertDialog(rowData)
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          })
                          : ""}

                      </TableBody>
                    </Table>
                  </TableContainer>

                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="right">
                <Grid item xs={"auto"}>
                  <LoadingButton
                    buttonName="Save"
                    clickHandler={save}
                    isLoading={loading}
                    searchValue={searchValue}
                  />
                  {/* <Button variant="contained" color="accent5" onClick={save}>
                  Save
                </Button> */}
                </Grid>
                <Grid item xs={"auto"}>
                  <Button
                    variant="contained"
                    color="accent3"
                    onClick={() => {
                      if (props?.setClose) {
                        props.setClose(false);
                      }
                      handleADClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <AlertDialog
            title={"Confirmation"}
            maxWidth={"sm"}
            id="alert-delete"
            open={openConfirmation}
            onClose={handleADClose}
            description="The asset will be deleted from the job. Are you sure you want to delete?"
            action={
              <Grid container spacing={2} justifyContent="right">
                <Grid item xs={"auto"}>
                  <Button onClick={handleADClose}>No</Button>
                </Grid>
                <Grid item xs={"auto"}>
                  <Button onClick={DeleteData} color="secondary">
                    Yes
                  </Button>
                </Grid>
              </Grid>
            }
          />
        </Box>
      </Box>
    );
  }
};

export default BulkUploadViewer;
