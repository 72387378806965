import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { Typography } from "@mui/material";
import "./index.scss";

export default function SideDrawer(props) {
  // const [state, setState] = React.useState({
  //   right: false,
  // });

  // React.useEffect(() => {
  //   console.log(props.state);
  // }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if(props.setIsAssetSelected){
      props.setIsAssetSelected(false);
    }
    if(props.setPageRefresh){
      props.setPageRefresh(true);
    }
    if(props.setReload){
      props.setReload(!props.reload)
    }
    if(props.setFilterValue){
      props.setFilterValue("");
    }
    if(props.setState){
      props.setState({ ...props.state, right: open });
    }
    if(props.setState3){
      props.setState3({ ...props.state, right: open });    
    }
    if (!open) {   
      
      if(props.formComp.props.hideSideDrawer) {
props.formComp.props.hideSideDrawer()
      }
      
    }
  };

  let drawerWidth = 0;

  if (props.drawerWidth == "md") {
    drawerWidth = 580;
  } else {
    drawerWidth = 450;
  }

  const list = (anchor) => (
    <Box
      sx={{ width: drawerWidth }}
      role="presentation"
    // onClick={toggleDrawer(false)}
    // onKeyDown={toggleDrawer(false)}
    >
      {/* <Button
        onClick={() => {
          props.setState({ ...props.state, right: false });
        }}
      >
        Cancel
      </Button> */}
      {/* <Box sx={{ backgroundColor: 'orange', pt: 2, pb: 2, pr: 3, pl: 0, zIndex: 99, display: "flex", overflowY: 'hidden', top: '0', left: '0', position: "fixed" }}>

        <Typography id="modal-modal-title" variant="h5" >Create Source
        </Typography>

      </Box> */}
      <div className="popup-close">
        <IconButton
          color="primary"
          aria-label="Close"
          onClick={() => {
            props.setState({ ...props.state, right: false });
          //   if(props.setReload!=null && props.setReload!==undefined)
          //   {
          //     props.setReload(!props.reload)
          //   }
          //  if(props.setFilterValue!=null && props.setFilterValue!==undefined)
          //  {
          //   props.setFilterValue("")
          //  }
            if(props.setPageRefresh!=null && props.setPageRefresh!==undefined){
              props.setPageRefresh(true);
            }
            
            
            if (props.formComp?.props?.hideSideDrawer != null && props.formComp?.props?.hideSideDrawer !==undefined) {
              props.formComp?.props?.hideSideDrawer();
            }
            if(props.setState3!=null && props.setState3!==undefined){
              props.setState3({ ...props.state, right: false });
            }
            if(props.setIsAssetSelected!=null && props.setIsAssetSelected!==undefined){
              props.setIsAssetSelected(false);
            }
          

          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      {props.formComp}
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={props.state["right"]}
          onClose={toggleDrawer(false)}
        >
          {list("Create Source")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
