// Default React, Router and Services Components
import React, { useState } from 'react';
import { Redirect, Route, Switch , withRouter} from 'react-router-dom';    

// Importing Custom Components
import Footer from '../Footer';
import SideBar from '../Sidebar';
import Header from '../Header/Header';
import routes from '../../../Routes/Routes';

// Importing style file
import './index.scss';

const switchRoutes = (
    <Switch>
        {routes.map(({ link, component }, key) => {
            return (
                <Route
                    exact
                    path={'/app' + link}
                    component={component}
                    key={key}
                />
            );
        })}

        {/*
        1) Set the localstorage flag as true 
        2) if the the flag is true then navigate to file preview 
        3)Before navigation change the flag to false
        
        */}
        {/* {(localStorage.getItem("filepreviewaction") === "true")?

           ( <Redirect from="/filepreview" to="/app/filepreview" />        
        ):
        (<Redirect from="/" to="/app/sourcemanagement" />) */}
        <Redirect from="/filepreview" to="/app/data-collections/filepreview" /> 
        <Redirect from="/OwnershipFilePreview" to="/app/OwnershipChanges/filepreview" /> 
        <Redirect from="/" to="/app/data-collections/source-management" />
    {/* } */}
    </Switch>
);

const Main = (props) => {
    const { location } = props;
    const [open, setOpen] = useState(false);

    const sideBarToggle = (value) => {
        setOpen(value);
    }

    if (props.location.pathname === "/app/changepassword"){
        
        return (
            <>
                <section className="d-flex h-100">
                    <main className="mainContainer">
                        <div>
                            {switchRoutes}
                        </div>
                        
                    </main>
                </section>
            </>
        )
      }
    else{
        return (
            <>
                <section className="d-flex h-100">
                    <div className={
                        "sideBar " +
                        (open ? "sideBarContainerOpened" : "sideBarContainerClosed")
                    } >
                        <SideBar open={open} setOpen={setOpen} />
                    </div>
                    <main className={
                        "mainContainer " +
                        (open ? "mainContainerOpened" : "mainContainerClosed")
                    }>
                        <Header setOpen={setOpen} />
                        <div className="renderingContainer" onClick={() => sideBarToggle(false)}>
                            {switchRoutes}
                        </div>
                        <Footer />
                    </main>
                </section>
            </>
        )
      }

 
};

export default withRouter(Main);