
import React from 'react';
import { Box, CircularProgress, LinearProgress, Typography } from '@mui/material';
import { lighten, alpha } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import clsx from 'clsx';

const lphStyles = props => makeStyles(theme => ({
    root: {
        minHeight: 6,
        borderRadius: 3,
        backgroundColor: lighten(props.color, .62),
        '& .MuiLinearProgress-bar': {
            backgroundColor: props.color,
        }
    },
    small: {
        minHeight: 4,
        borderRadius: 2
    },
    large: {
        minHeight: 10,
        borderRadius: 5
    }
}));

const LinearProgressHex = (props) => {
    const { size, value } = props;
    const classes = lphStyles(props)();
    return (
        <LinearProgress variant="determinate"
            className={clsx(classes.root, {
                [classes.small]: size === 'small',
                [classes.large]: size === 'large'
            })}
            value={value}
        />
    )
}
const ncpStyles = props => makeStyles(theme => ({
    root: {
        minHeight: 6,
        backgroundColor: lighten(theme.palette[props.color ? props.color : 'primary']['main'], .62),
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette[props.color ? props.color : 'primary']['main'],
        }
    },
    small: {
        minHeight: 4,
    },
    large: {
        minHeight: 10,
    }
}));

const NcLinearProgress = (props) => {
    const { size, value } = props;
    const classes = ncpStyles(props)();
    return (
        <LinearProgress variant="determinate"
            className={clsx(classes.root, {
                [classes.small]: size === 'small',
                [classes.large]: size === 'large'
            })}
            value={value}
        />
    )
}

const ProgressBar = (props) => {
    const { color, hasLabel, hexColor, size, value } = props;

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%">
                {hexColor ?
                    <LinearProgressHex variant="determinate"
                        color={hexColor}
                        size={size}
                        value={value}
                    />
                    : <NcLinearProgress variant="determinate"
                        color={color}
                        value={value}
                    />
                }
            </Box>
            {hasLabel && <Box minWidth="4ch" ml={1}>
                <Typography variant="body2" color="textSecondary">
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
            }
        </Box>
    );
}

const lwStyles = makeStyles(theme => ({
    root: {
        position: "relative"
    },
    loader: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: alpha(theme.palette.common.white, .75)
    }
}));

const LoaderWrapper = ({ children, loading }) => {
    const classes = lwStyles();
    return (
        <Box className={classes.root}>
            {loading && <Box className={classes.loader}><CircularProgress style={{color:"#00519c"}}/></Box>}
            {children}
        </Box>
    )
}

export { ProgressBar, LoaderWrapper };