// Default React, Router and Services Components
import React, { useState } from "react";

// Importing Material Component 
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from '@mui/material/IconButton';
import { ErrorBadgeIcon, CircleAdditionIcon } from "@fluentui/react-icons-mdl2";
import { toast } from "react-toastify";
import axios from "axios";

// Importing Custom Component 
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import CustomAutoComplete from '../../../UIComponent/CustomAutoComplete'
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing Style files 
import "../../../Resources/scss/layout.scss";
import "react-toastify/dist/ReactToastify.css";


const EditAsset = (props) => {
    // Declaration of States and Component Variables Start Here
    const [assetDetails, setAssetDeatils] = useState()
    const [allCompanies, setAllCompanies] = React.useState([]);
    const [subRegionDropDown, setSubRegionDropDown] = React.useState([])
    const [countryRegion, setCountryDropDown] = React.useState([])
    const [commoditiesDropdown, setCommodityDropDown] = React.useState([])
    const [selectedCommodity, setselectedCommodity] = React.useState([]);
    const [allassets, setAllAssets] = React.useState()
    const [commodityValues, setCommodityValues] = React.useState([])
    const [isCommidityChange, setCommitityChange] = React.useState(false)
    const [loading, setLoading] = React.useState(false);
    const [isJiraCreated, setIsJiraCreated] = React.useState(true);
    // Declaration of States and Component Variables End Here

    // Declaration of React Hooks Start Here
    React.useEffect(() => {
        axios
        .get(process.env.React_App_baseUrl+"ownershipsource/GetCompanyOwnershipAssetById", {
                headers: {
                    AssetId: props.assetId,
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

                }
            })
            .then(function (response) {
                if (!response.data.referencelink.length) {
                    response.data.referencelink = [""]
                    setAssetDeatils(response.data)
                    setCommodityDropDown(props.commodityDropDown)
                    
                } else {
                    setAssetDeatils(response.data)
                    setCommodityDropDown(props.commodityDropDown)

                }
                setIsJiraCreated(response.data.isJiraCreated)
            })
            .catch(function (error) {
                ErroHandling(error, function (res) {
                    toasterError(res)
                })
            });
        axios
            .get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
                headers: {
                    isNewSource: false,
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

                },
            })
            .then(function (response) {
                setAllCompanies(response.data);
            })
            .catch(function (error) {
                ErroHandling(error, function (res) {
                    toasterError(res)
                })
            });



    }, [])

    React.useEffect(() => {
        if (commoditiesDropdown.length > 0 && assetDetails) {
            commoditiesDropdown.find((commodity) => {
                assetDetails.commoditiesId.map((id) => {
                    if (commodity.id === id) {
                        selectedCommodity.push(commodity)
                        // console.log("selectedCommodity", selectedCommodity)
                    }
                })
            })

        }

    }, [commoditiesDropdown])


    React.useEffect(() => {
        if (assetDetails) {
            axios
                .get(process.env.React_App_baseUrl + "DropDown/GetCountryDropDown", {
                    headers: {
                        regionId: assetDetails?.regionId,
                        subRegionId: assetDetails?.subRegionId,
                        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

                    },
                })
                .then(function (response) {
                    //   console.log(response.data);
                    setCountryDropDown(response.data);
                })
                .catch(function (error) {
                    ErroHandling(error, function (res) {
                        toasterError(res)
                    })
                });
            axios
                .post(process.env.React_App_baseUrl + "DropDown/GetAssetDropDown", {
                    "companyId": assetDetails?.companyId,
                    "isCRUCompany": assetDetails?.isCRUCompany,
                    "companyName": assetDetails?.companyName,
                    "isNewSource": assetDetails?.isNewSource
                }, {
                    headers: {
                        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                    }
                })
                .then(function (response) {
                    //  console.log(response.data);
                    setAllAssets(response.data);
                })
                .catch(function (error) {
                    ErroHandling(error, function (res) {
                        toasterError(res)
                    })
                });
        }
        if (assetDetails) {
            axios
                .get(process.env.React_App_baseUrl + "DropDown/GetSubRegionDropDown", {
                    headers: {
                        regionId: assetDetails?.regionId,
                        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

                    },
                })
                .then(function (response) {
                    setSubRegionDropDown(response.data);
                })
                .catch(function (error) {
                    ErroHandling(error, function (res) {
                        toasterError(res)
                    })
                });
        }
    }, [assetDetails?.subRegionId, assetDetails?.regionId])

    // Declaration of React Hooks End Here  


    //Method for edit Asset 
    const editSource = () => {
        setLoading(true);
        if (isCommidityChange) {
            assetDetails.commoditiesId = []
            commodityValues.map((com) => {
                assetDetails.commoditiesId.push(com.id)
            })
        }
        if (assetDetails.referencelink[assetDetails.referencelink.length - 1] === '') {
            toasterError("Please enter a link ");
            setLoading(false);
        } else {
            let validated = false
            function validURL(str) {
                var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
                return !!pattern.test(str);
            }
            assetDetails.referencelink.map((link) => {
                if (!validURL(link)) {
                    validated = true;
                    setLoading(true);
                }
            })

            if (!validated) {
                delete assetDetails.companyName;
                assetDetails.isNewSource = false;
                axios
                    .post(process.env.React_App_baseUrl + "ownershipsource/SaveCompanyOwnershipSource", assetDetails, {
                        headers: {
                            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                        }
                    })
                    .then(function (response) {
                        //  console.log(response.data);
                        toasterSuccess("Asset edited successfully");
                        props.setRefresh(!props.refresh)
                        props.setState({ ...props.state, right: false })
                        setLoading(false);
                    })
                    .catch(function (error) {
                        ErroHandling(error, function (res) {
                            toasterError(res)
                        });
                        setLoading(false);
                    });
            } else {
                setLoading(false);
                toasterError('Please enter a valid link');
            }
        }
        // props.setState({ ...props.state, right: false })
    }

    //Method for Add link    
    const handleAddLink = (e) => {
        const links = [...assetDetails?.referencelink];
        links.push("")
        let item = { ...assetDetails, referencelink: links }
        setAssetDeatils(item);
    }

    //Method for Remove link    
    const handleRemoveLink = (e, index) => {
        const links = [...assetDetails?.referencelink];
        links.splice(index, 1);
        let item = { ...assetDetails, referencelink: links }
        setAssetDeatils(item);
    }

    const handleChange = (e, i) => {
        const links = [...assetDetails?.referencelink];
        links[i] = e.target.value;
        let item = { ...assetDetails, referencelink: links }
        setAssetDeatils(item);
    }

    // This function is used to create a Jira ticket
    const jira = (() => {
    // Check if assetDetails is not undefined or null
    if(assetDetails!==undefined && assetDetails!==null)
    {
        // Make an axios post request with the given parameters
        axios
        .post(process.env.React_App_baseUrl + "jira/CreateJiraTicket", {
            id: assetDetails.editAssetId,
            isCompanyChange: false,
        }, {
            headers: {
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
            }
        })
        .then(
            // If the request is successful, display a success message and set isJiraCreated to true
            (res) => {
                toasterSuccess(`Jira ticket created!`)
                setIsJiraCreated(true);
                props.setState({ ...props.state, right: false })
            },
            // If the request fails, display an error message
            (err) => {
                toasterError(`${err.response.data}`)
            }
        );
    }else{
        // If assetDetails is undefined or null, display a success message
        toasterSuccess(`Jira ticket not created!`)
    }
    
})
    

    return (
        <Box sx={{ pt: 0, pb: 3, pr: 3, pl: 3 }}>
            <Box sx={{ pt: 2 }}>
                <Typography id="modal-modal-title" variant="h5">
                    Edit Asset
                </Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl sx={{ pt: 2 }}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel
                                disabled
                                checked={assetDetails?.isNewSource == true}
                                value="newsource"
                                control={<Radio />}
                                label="New Source"
                                id="newSource"
                                color="accent2"

                            />
                            <FormControlLabel
                                disabled
                                checked={assetDetails?.isNewSource == false}
                                value="sourcechange"
                                control={<Radio />}
                                label="Source Change"
                                id="editSource"
                                color="accent2"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <>
                    <Grid item xs={12}>
                        {assetDetails && <CustomAutoComplete
                            options={allCompanies}
                            disabled={true}
                            sx={{ width: 300 }}
                            label="Company Name"
                            optionLabelKey="companyName"
                            optionValueKey="companyId"
                            // defaultValueKey="id"
                            // defaultValue={companyDetails?.companyId}
                            onChange={((value) => { console.log(value) })}
                            defaultValueKey={["id", 'isCRUCompany']}
                            defaultValue={[assetDetails?.companyId, assetDetails?.isCRUCompany]}
                        />}
                    </Grid>
                    <Grid item xs={12}>
                        {!assetDetails?.isNewSource ? <TextField
                            fullWidth
                            className="field-gap"
                            size="small"
                            disabled
                            id="standard-basic"
                            label="New Company Name"
                            variant="standard"
                        /> : ''}
                    </Grid>
                    <Grid item xs={12}>
                        {assetDetails?.isNewSource ? <Autocomplete
                            className="field-gap"
                            size="small"
                            id="auto-complete1"
                            autoComplete
                            disabled
                            includeInputInList
                            options={[{ name: "Other", id: 1 }]}
                            value={{ name: "Other", id: 1 }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Asset Name"
                                    placeholder="Asset Name"
                                />
                            )}

                        /> : <CustomAutoComplete
                            options={allassets}
                            disabled={true}
                            sx={{ width: 300 }}
                            label="Assert Name"
                            optionLabelKey="assetName"
                            optionValueKey="assetId"
                            // defaultValueKey="id"
                            // defaultValue={companyDetails?.companyId}
                            onChange={((value) => { console.log(value) })}
                            defaultValueKey={["id", 'isCRUCompany']}
                            defaultValue={[assetDetails?.assetId, assetDetails?.isCRUCompany]}
                        />}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            className="field-gap"
                            size="small"
                            id="standard-basic"
                            label="New Asset Name"
                            inputProps={{ maxLength: 200 }}
                            variant="standard"
                            value={assetDetails?.assetName || ''}
                            onChange={(e) =>
                                setAssetDeatils({ ...assetDetails, assetName: e.target.value })
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {assetDetails && <CustomAutoComplete
                            options={props.assetTypeDropDown}
                            disabled={!assetDetails?.isNewSource}
                            sx={{ width: 300 }}
                            label="Asset Type"
                            optionLabelKey="assetType"
                            optionValueKey="id"
                            defaultValueKey="id"
                            defaultValue={assetDetails?.assetTypeId}
                            onChange={((value) => {
                                setAssetDeatils({ ...assetDetails, assetTypeId: value.id })
                            })}
                        // defaultValueKey={["id", 'isCRUCompany']}
                        // defaultValue={[assetDetails?.companyId, assetDetails?.isCRUCompany]}
                        />}
                    </Grid>


                    <Grid item xs={12}>
                        {assetDetails && <CustomAutoComplete
                            options={props.regionDropDown}
                            disabled={!assetDetails?.isNewSource}
                            sx={{ width: 300 }}
                            label="Region"
                            optionLabelKey="regionName"
                            optionValueKey="id"
                            defaultValueKey="id"
                            defaultValue={assetDetails?.regionId}
                            onChange={((value) => { setAssetDeatils({ ...assetDetails, regionId: value.id }) })}
                        // defaultValueKey={["id", 'isCRUCompany']}
                        // defaultValue={[assetDetails?.companyId, assetDetails?.isCRUCompany]}
                        />}
                    </Grid>

                    <Grid item xs={12}>
                        {subRegionDropDown && <CustomAutoComplete
                            options={subRegionDropDown}
                            disabled={!assetDetails?.isNewSource}
                            sx={{ width: 300 }}
                            label="Sub Region"
                            optionLabelKey="subRegionName"
                            optionValueKey="id"
                            defaultValueKey="id"
                            defaultValue={assetDetails?.subRegionId}
                            onChange={((value) => { setAssetDeatils({ ...assetDetails, subRegionId: value.id }) })}
                        // defaultValueKey={["id", 'isCRUCompany']}
                        // defaultValue={[assetDetails?.companyId, assetDetails?.isCRUCompany]}
                        />}
                    </Grid>

                    <Grid item xs={12}>
                        {countryRegion && <CustomAutoComplete
                            options={countryRegion}
                            disabled={!assetDetails?.isNewSource}
                            sx={{ width: 300 }}
                            label="Country"
                            optionLabelKey="countryName"
                            optionValueKey="id"
                            defaultValueKey="id"
                            defaultValue={assetDetails?.countryId}
                            onChange={((value) => { setAssetDeatils({ ...assetDetails, countryId: value.id }) })}
                        // defaultValueKey={["id", 'isCRUCompany']}
                        // defaultValue={[assetDetails?.companyId, assetDetails?.isCRUCompany]}
                        />}
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            disabled={!assetDetails?.isNewSource}
                            className="field-gap"
                            options={commoditiesDropdown}
                            getOptionLabel={(option) => option.commodityName}
                            defaultValue={selectedCommodity}
                            onChange={(e, value) => {
                                setCommitityChange(true)
                                setCommodityValues(value)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Commodity"
                                    placeholder="Commodity"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                sx={{ background: "#eeeeee" }}
                                className="field-gap"
                                id="standard-basic"
                                label="Status"
                                variant="standard"
                                value={"Pending"}
                                disabled
                                fullWidth
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {assetDetails?.referencelink && assetDetails?.referencelink.map((linki, i) => {
                            return (
                                <>
                                    <div className="d-flex field-gap">
                                        <div className="flex-grow-1">
                                            <TextField
                                                fullWidth
                                                id="standard-basic"
                                                label="Link"
                                                variant="standard"
                                                multiline
                                                onChange={(e) => handleChange(e, i)}
                                                defaultValue={linki}
                                            />
                                        </div>
                                        <div>
                                            {i === 0 && <IconButton aria-label="Add" style={{ marginTop: 10, marginLeft: 10 }} onClick={(e) => { handleAddLink(e) }}><CircleAdditionIcon /></IconButton>}
                                            {i > 0 && <IconButton aria-label="Add" style={{ marginTop: 10, marginLeft: 10 }} onClick={(e) => { handleRemoveLink(e, i) }}><ErrorBadgeIcon /></IconButton>}
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                className="field-gap"
                                size="small"
                                id="standard-basic"
                                label="Remarks"
                                variant="standard"
                                value={assetDetails?.remarks || ''}
                                onChange={(e) => setAssetDeatils({ ...assetDetails, remarks: e.target.value })}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Box component="span" fullWidth sx={{ pt: 2, pb: 1 }}>
                            <LoadingButton
                                buttonName="Save"
                                clickHandler={editSource}
                                isLoading={loading}

                            />
                            <Button
                                variant="contained"
                                style={{ marginRight: "15px", marginLeft: "15px" }}
                                className="rounded-border"
                                onClick={() => props.setState({ ...props.state, right: false })}
                                color="accent3"
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={isJiraCreated}
                                variant="contained"
                                className="rounded-border"
                                color="secondary"
                                onClick={jira}
                            >
                                Create jira Ticket
                            </Button>
                        </Box>
                    </Grid>


                </>
            </Grid>
        </Box>
    );
};

export default EditAsset;
