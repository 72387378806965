// Default React, Router and Services Components
import React, { useState } from "react";
import { Box, Breadcrumbs, Button, CircularProgress, Grid, Typography } from "@mui/material";
import XLSX from "sheetjs-style";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
import { useHistory } from "react-router-dom";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError } from "../../../UIComponent/Toaster";
// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import useStyles from "../CompanyOwnershipChanges.styles";
import BaseLayout from "../../../UIComponent/BaseLayout";
import { LoaderWrapper } from "../../../UIComponent/Progress";
// Import Fluent UI Icons
import { ChevronLeftIcon, ChevronRightSmallIcon, ExportIcon } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../Services/CommonService";


const OwnershipChangesViewData = (props) => {


    const [historyData, setHistoryData] = useState();

    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    const [allRows, setAllRows] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = React.useState(0);
    const [open, setOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [isExporting, setExporting] = useState(false);
    const [selected, SetSelected] = useState([]);
    const [idforEdit, setIdForEdit] = useState(null);
    const [editJobNumber, setEditJobNumber] = useState(null);
    const [isAllSelected, setIsSelected] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [state, setState] = React.useState({ right: false });
    const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
    const [crudState, setCrudState] = React.useState(false);
    const [formData, setFormData] = useState([]);
    const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
    const [reload, setReload] = useState(false);
    const [scroll, setScroll] = React.useState("paper");
    const date = new Date().toLocaleDateString();
    const [excelColumns, setExcelColumns] = useState([]);
    const date1 = date.split("/").join("-");
    const [cellArray, setCellArray] = useState([]);
    const [exportToExcel, setExportToExcel] = useState(false);
    const [columns, setColumns] = useState([]);
    const history = useHistory();

    // Declaration of States and Component Variables End Here


    // Declaration of React Hooks Start Here
    React.useEffect(() => {
        getDataFromHistory();
    }, [props])
    React.useEffect(() => {
        setColumns(gridcolumns); GetViewDataList(searchValue);
    }, [reload, page, rowsPerPage, historyData]);

    React.useEffect(() => {
        if (sorting.sortBy != '' && sorting.sortBy != null) { GetViewDataList(searchValue); }
    }, [sorting, historyData]);
    // Declaration of React Hooks End Here
    const getDataFromHistory = () => {
        let temp = CommonService.GetStoredData(props, "historyData");
        if (temp) {
            setHistoryData(temp);

        }

    }
    //columns to be displayed in grid
        const gridcolumns = [
            { id: "AssetName", label: "Asset", minWidth: 300, width: 300, sorting: true },
            { id: "CountryName", label: "Country ", minWidth: 300, width: 300, sorting: true },
            { id: "RegionName", label: "Region", minWidth: 300, width: 300, sorting: true,  },
            { id: "SubRegionName", label: "SubRegion", minWidth: 150, width: 180, sorting: true },
            { id: "Commodity", label: "Commodity", minWidth: 150, width: 180, sorting: true,truncate: 75, tooltipWithTruncate: true }, 
             { id: "OperatorName", label: "Operator", minWidth: 150, width: 180, sorting: true },
              { id: "Lattitude", label: "Lattitude", minWidth: 150, width: 180, sorting: true },
               { id: "Longitude", label: "Longitude", minWidth: 150, width: 180, sorting: true },
               { id: "OwnerName", label: "OwnerName", minWidth: 150, width: 180, sorting: true }
            ];
 

    // Function for Handle KeyUp
    const handleKeyUp = (event) => {
        if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
            if (searchValue.length === 0) { }
        }
        if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
            searchReport(event.target.value);
        }
    };



    // Function for Pagination after search
    const searchReport = (filterValue) => {
        setTotalCount(0);
        setPage(0);
        setLoading(true);
        setReload(!reload);
    };

    // Function for Getting Search And Export Data
    const globalSearch = (value) => { setSearchValue(value); };
    const GetViewDataList = (filterValue, exportExcel = false) => {
        if (historyData) {
            if (exportExcel) {
                setExporting(true);
                let ptr = []
                ApiService.search(`SearchAndExport/GetSearchAndExportExcelForOwnershipChanges?`,
                    {
                        filter: historyData.filter ? encodeURIComponent(JSON.stringify(historyData.filter)) : "",
                        jobAssetIds: historyData.state,
                        //jobAssetIds: 4418,
                        isAllSelected: historyData.isAllSelected,
                        //sortColumn: changeSortColumnNameFormat(sorting.sortBy),
                        sortColumn: sorting.sortBy,
                        isAscending: sorting.isAscending
                    }, ptr
                )
                    .then((result) => {
                        if (result.status === 200) {                            
                            let apiData = [];
                            let apiDatawoCoumns = [];
                            let fileName = `Search and Export ${date1}`;
                            let header=[];
                            if (result.data[0].result != null) {
                                //console.log("result",result);
                                apiData = JSON.parse(result.data[0].result);

                                if (apiData !== null && apiData !== undefined) {
                                    apiDatawoCoumns = JSON.parse(result.data[0].result);
                                    if (apiData.data !== null && apiData.data !== undefined && apiData.data.length > 0) {
                                        let colorcodes = [];
                                        if (apiData.data !== null && apiData.data !== undefined && apiData.data.length > 0) {
                                            let keys = Object.keys(apiData.data[0]);
                                            //console.log(keys);
                                            
                                            if (keys !== null && keys !== undefined) {
                                                keys.forEach(key => {
                                                    // console.log(key);
                                                    if (key.indexOf("UpdateOwnerName") !== -1) {
                                                        header.push('Owner Name')
                                                     }
                                                    else if (key.indexOf("UpdateAssetName") !== -1) {
                                                        header.push('Asset Name')    
                                                    }
                                                    else if (key.indexOf("ChangeType") !== -1) {
                                                        header.push('Change Type')                               
                                                    }
                                                    else {
                                                        header.push(key)
                                                    }
                                                    //headerName.push(key);
                
                                                    //}
                                                })
                                            }
                                        }
                                       
                                         exportToCSV(apiData.data, fileName, apiDatawoCoumns.data, colorcodes);
                                    } else {
                                        toasterError(`No data available`);
                                    }

                                } else {
                                    toasterError(`No data available`);
                                }
                            }
                        }
                        else {

                            toasterError(`Something went wrong!`);
                        }
                        setExporting(false);
                    })
                    .catch((err) => {
                        ErroHandling(err, function (res) {
                            toasterError(res)
                        })

                        setExporting(false);
                    }).finally(() => {
                        setExporting(false);
                    });
                return;
            }

            let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`]; //rowsPerPage
            setLoading(true);
            ApiService.search(`SearchAndExport/GetSearchAndExportViewDataForOwnershipChanges?`,
                {
                    filter: historyData.filter ? encodeURIComponent(JSON.stringify(historyData.filter)) : "",
                    jobAssetIds: historyData.state,
                    //jobAssetIds: 4418,
                    isAllSelected: historyData.isAllSelected,
                    //sortColumn: changeSortColumnNameFormat(sorting.sortBy),
                    sortColumn: sorting.sortBy,
                    isAscending: sorting.isAscending
                }, prt
            ).then((res) => {
                if (res.status === 200) {
                    if (res.data !== null) {                        
                        let apiResponse = JSON.parse(res?.data[0].result);
                        let headerName = [];
                         //col = gridcolumns.splice(15, gridcolumns.length);
                        if (apiResponse.data !== null && apiResponse.data !== undefined && apiResponse.data.length > 0) {
                            let keys = Object.keys(apiResponse.data[0]);
                            //console.log(keys);
                            if (keys !== null && keys !== undefined) {
                                keys.forEach(key => {
                                    // console.log(key);
                                    if (key.indexOf("UpdateOwnerName") !== -1) {
                                        gridcolumns.push({ 'id': key, 'label': `OwnerName`, 'minWidth': 100, 'sorting': false, align: "left", colorCode: 'blue', attatch: true });
                                    }
                                    else if ((key.indexOf("AssetName") !== -1) || (key.indexOf("CountryName") !== -1) ||(key.indexOf("RegionName") !== -1) ||(key.indexOf("SubRegionName") !== -1) ||(key.indexOf("OperatorName") !== -1) ||(key.indexOf("Lattitude") !== -1) ||(key.indexOf("Longitude") !== -1) ||(key.indexOf("OwnerName") !== -1) ||(key.indexOf("Commodity") !== -1) ) {
                                        if (key.indexOf("UpdateCommodityName") !== -1) {
                                            gridcolumns.push({ 'id': key, 'label': `Update Commodity`, 'minWidth': 100, 'sorting': false, align: "left", colorCode: 'blue', attatch: true });
                                        }
                                    }
                                    else if (key.indexOf("UpdateAssetName") !== -1) {
                                        gridcolumns.push({ 'id': key, 'label': `AssetName`, 'minWidth': 100, 'sorting': false, align: "left", colorCode: 'blue', attatch: true });                               
                                    }
                                    else if (key.indexOf("ChangeType") !== -1) {
                                        gridcolumns.push({ 'id': key, 'label': `ChangeType`, 'minWidth': 100, 'sorting': false, align: "left", colorCode: 'blue', attatch: true });                               
                                    }
                                    else {
                                        gridcolumns.push({ 'id': key, 'label': `${key}`, 'minWidth': 100, 'sorting': false, align: "left", colorCode: 'blue', attatch: true });
                                    }
                                    //headerName.push(key);

                                    //}
                                })
                            }

                            setColumns(gridcolumns);
                            setAllRows(apiResponse.data);
                            // setTotalCount(res.data.totalCount);
                            setTotalCount(res?.data[0].totalCount);

                        }


                    } else {
                        setColumns(gridcolumns);
                        setAllRows([]);
                    }
                    setLoading(false);
                }
                else {
                    toasterError(`${res.data}`);
                    setLoading(false);
                }
            })
                .catch((err) => {
                    ErroHandling(err, function (res) {
                        toasterError(res)
                    });
                    setLoading(false);
                });
        }

    };

    // Function for Search Clear
    const searchClear = () => {
        setSearchValue("");
        setPage(0);
        setReload(!reload);
    };

    // Function for Export
    const handleExportToExcel = () => { GetViewDataList(searchValue, true); }
    const exportToCSV = (apiData, fileName, datawoCommments, excelColorCode) => {
        //console.log("----->", apiData);
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        let onlyComments = [];
        datawoCommments.forEach(obj => {
            Object.keys(obj).forEach(key => {
                if (key.endsWith("_Comment")) {
                    onlyComments.push(obj[key]);
                }
                if (key.endsWith("_Comment") || key.endsWith("VariableId") || key.endsWith("_ValuationType") || key.endsWith("ValuationTypeId") || key.endsWith("JobAssetId")) {
                    delete obj[key];
                }
            })
        })
        //console.log("onlycomments---->", onlyComments);
        const ws = XLSX.utils.json_to_sheet(datawoCommments);
        let wsData = createAlphybet(datawoCommments, ws, onlyComments, excelColorCode)
        //console.log("full", wsData);
        // // const ws = XLSX.utils.json_to_sheet(apiData);
        excelColumns.forEach((exCol, i) => {
            if (exCol.includes('_Comment')) {
                excelColumns.splice(i, 1)
            }
        });
        //console.log(wsData);
        XLSX.utils.sheet_add_aoa(wsData, [excelColumns], { origin: "A1", });
        const wb = { Sheets: { data: wsData }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const alphabetLocation = (n) => {
        var ordA = 'A'.charCodeAt(0);
        var ordZ = 'Z'.charCodeAt(0);
        var len = ordZ - ordA + 1;

        var s = "";
        while (n >= 0) {
            s = String.fromCharCode(n % len + ordA) + s;
            n = Math.floor(n / len) - 1;
        }
        return s;
    }

    const createAlphybet = (v, ws, allComments, excelColorCode) => {

        var ordA = 'A'.charCodeAt(0);
        var ordZ = 'Z'.charCodeAt(0);
        var len = ordZ - ordA + 1;
        var s = "";
        let commentData = {}
        let commentCount = 0;
        let n = Object.keys(v[0]).length
        for (let index = 0; index < v.length; index++) {
            (Object.values(v[index]).map((value, ind) => {
                let n = ind
                while (n >= 0) {
                    s = String.fromCharCode(n % len + ordA);
                    n = Math.floor(n / len) - 1;
                }

                let commentIndex = (Object.keys(v[index]))[ind];

                if (ind > 13 && value && value != '' && !commentIndex.includes('_ReportName')) {
                    // console.log(`${alphabetLocation(ind)}`,index+2);          
                    //commentData[s+(index+2)] = allComments[commentCount];
                    commentData[alphabetLocation(ind) + (index + 2)] = allComments[commentCount];
                    // console.log("index",index+1)
                    // console.log("commentCount",commentCount)
                    // console.log("excelcolorcode",excelColorCode[commentCount] )
                    ws[`${alphabetLocation(ind)}${index + 2}`].s = {
                        font: {
                            color: { rgb: excelColorCode[commentCount] }
                        },
                    }
                    commentCount = commentCount + 1
                }
            }))
        }
        //console.log(commentData)
        Object.keys(ws).forEach(ww => {
            Object.keys(commentData).forEach((cmData) => {

                if (ww === cmData && commentData[cmData] && commentData[cmData].indexOf("-") !== 0) {
                    ws[ww].c = [];
                    ws[ww].c.hidden = true;
                    if (ws) {
                        if (ws[ww]) {
                            if (ws[ww].t === "s" && ws[ww].v !== "-") {
                                ws[ww].t = "n"
                            }
                        }
                    }

                    ws[ww].c.push({ a: ws[ww].v, t: commentData[cmData], T: true });
                }

            })
        });
        //console.log("ws===>",ws);
        return ws;
    }

    return (
        // Page Layout Start Here
        <Box>
            <Grid container maxWidth justifyContent="right" alignItems="center">

                {/* Breadcrumbs Menu Start Here */}
                <Grid item xs={12} sm={12} md={6} className="page-title">
                    <h2>View Data</h2>
                    <Breadcrumbs
                        separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/sourcemanagement" }}>Home</Link>
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/search&export" }}>Search & Export</Link>
                        <Typography color="text.primary">View Data</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* Breadcrumbs Menu End Here */}

                {/* Main Buttons Start Here */}
                <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                        <Grid item xs={"auto"}>
                            <Button variant="contained" color="accent2" startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
                                onClick={() => { history.push("/app/ownership-changes/search-export"); }}
                            >
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Main Buttons End Here */}

                {/* Baselayout Start Here */}
                <Grid item xs={12}>
                    <BaseLayout>
                        <Box className={classes.addBorder}>
                            {/* Search Option and Total Count Start Here */}
                            <Grid item xs={12} className={classes.tableTopPart}>
                                <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                                    <Grid item xs={"auto"}>
                                        {isExporting ?
                                            <Button variant="contained" color="secondary" disabled style={{ width: 190 }}>
                                                <CircularProgress size={25} />
                                            </Button> :
                                            <Button variant="contained" color="secondary" style={{ width: 190 }}
                                                startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                                                onClick={handleExportToExcel}>
                                                Export To Excel
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Search Option and Total Count End Here */}

                            {/* View Data Table */}
                            <Grid item xs={12}>
                                <LoaderWrapper loading={isLoading}>
                                    <DataTable
                                        className={classes.removeBorder}
                                        rows={allRows ? allRows : []}
                                        columns={columns}
                                        page={page}
                                        setPage={setPage}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        totalCount={totalCount}
                                        isAllSelected={isAllSelected}
                                        setIsSelected={setIsSelected}
                                        selected={selected}
                                        setSelected={SetSelected}
                                        sorting={sorting}
                                        setSorting={setSorting}
                                        hasChip={{
                                            High: { color: "warning", label: "High" },
                                            Critical: { color: "error", label: "Critical" },
                                            Low: { color: "success", label: "Low" },
                                            Medium: { color: "info", label: "Medium" },
                                        }}
                                        cellArray={cellArray}
                                        searchAndControl
                                    />
                                </LoaderWrapper>
                            </Grid>
                        </Box>
                    </BaseLayout>
                </Grid>
            </Grid>
        </Box >
    );
};

export default OwnershipChangesViewData;