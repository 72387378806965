// Default React, Router and Services Components
import React, { useEffect, useState } from "react";
import { Breadcrumbs, Button, Box, CircularProgress, Grid, Typography, IconButton, TextField } from "@mui/material";
import axios from "axios";
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from "react-router-dom";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// Importing Custom Components
import FilterForm from "./FilterForm";
import SideDrawer from "../../../UIComponent/Drawer";
import SearchIcon from "@mui/icons-material/Search";
import { ScrollingDialog } from "../../../UIComponent";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import EditMultipleJobForAssignJob from "./EditMultipleJobForAssignJob";
import { LoaderWrapper } from "../../../UIComponent/Progress";
// Importing useStyles
import "./index.scss";
import useStyles from "./jobManagement.styles";

// Import Material Icons
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import InputAdornment from "@mui/material/InputAdornment";

// Import Fluent UI Icons
import { ChevronRightSmallIcon, ClearIcon } from "@fluentui/react-icons-mdl2";
import GetMenuService from '../../../Services/GetMenuService';
import ManageJobsCustomTable from "../../../UIComponent/ManageJobsCustomTable/ManageJobsCustomTable";

// Creating Data Table for Job Lists
function createData(
  id, jobNo, jobName, createdOn, createdBy, noOfCompanies, noOfRecords, status, tat, priorityName) {
  return {
    id, jobNo, jobName, createdOn, createdBy, noOfCompanies, noOfRecords, status, tat, priorityName,
  };
}

// Assigning Columns and UI Props
const columns = [
  { id: "id", label: "ID", type: "Highlight" },
  { id: "jobNo", label: "Job ID", sorting: true, minWidth: 85, width: 85 },
  { id: "jobName", label: "Job Name", sorting: true, minWidth: 300, },
  { id: "createdOn", label: "Created Date", sorting: true, minWidth: 150, width: "10%", },
  { id: "createdBy", label: "Created By", sorting: true, minWidth: 180, width: "10%", },
  // { id: "noOfCompanies", label: "No. of Companies", minWidth: 120, width: 120, sorting: true, align: "center", },
  { id: "noOfRecords", label: "No. of Records", minWidth: 110, width: "10%", sorting: true, align: "center", },
  //{ id: "status", label: "Status", minWidth: 150, width: "10%", sorting: true, },
  { id: "tat", label: "TAT", minWidth: 110, width: "10%", sorting: true, align: "center", },
  { id: "priorityName", label: "Priority", type: "Chip", minWidth: 110, width: "10%", sorting: true, align: "center" },
];

const OwnershipChangesJobList = () => {
  // Declaration of States and Component Variables Start Here
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = useState({ right: false });
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [filterState, setFilterState] = React.useState({ right: false });
  const [reload, setReload] = useState(false);
  const [selected, SetSelected] = React.useState([]);
  const [checkBoxValue, setCheckBoxValue] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rawRowData, setRawRowData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteUserData, setDeleteUserData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [deleteJobNumber, setDeleteJobNumber] = useState(null);
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  const [idforEdit, setIdForEdit] = useState(null);
  const [editJobNumber, setEditJobNumber] = useState(null);
  const [id, setId] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isAllSelected, setIsSelected] = useState(false);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [viewDataButtonDisable, setviewDataButtonDisable] = useState(true);
  let [filterValue, setFilterValue] = React.useState();
  const [searchValue, setSearchValue] = React.useState();
  const [edit, setEdit] = useState({});
  const [crudState, setCrudState] = React.useState(false);
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValues, setFilterValues] = useState();
  const [companyFilter, setCompanyFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [jobLocations, setJobLocations] = React.useState([]);
  const [tatDate, setTatDate] = React.useState(null);
  const [assetTypes, setAssetTypes] = React.useState(dataForFilter.assetType ? dataForFilter.assetType : [])
  const [commodityList, setCommodityList] = React.useState(dataForFilter.commodityList ? dataForFilter.commodity : [])
  const [priorityList, setPriorityList] = React.useState(dataForFilter.priority ? dataForFilter.priority : [])
  const classes = useStyles();
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })

// Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(55, function (err, res) {
      if (res) {
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])
  React.useEffect(() => {
    if (selected) {
      AddCheckBoxValue(selected);
    } else {
      RemoveAllCheckboxValue();
    }
  }, [selected]);

// Declaration of React Hooks end Here

  // `AddCheckBoxValue` is a function that accepts a parameter 'value'.
const AddCheckBoxValue = (value) => {
  // Initialising an empty array tempStoredValue
  let tempStoredValue = [];
  // Assigning the global checkbox value to tempStoredValue.
  tempStoredValue = checkBoxValue;
  if (tempStoredValue && value) {
    // If value is an instance of Array,
    // it means multiple checkbox values are being processed.
      if (value instanceof Array) {
          // Loop over each element in array 'value'
          value.forEach((element) => {
              // Find the index of current element in tempStoredValue array
              let index = tempStoredValue.indexOf(element);
              // If the element is not found in the array (index will be -1), push it into our array.
              if (index === -1) {
                  tempStoredValue.push(element);
              }
          });
      } 
      // If the value is of type number, it means only one checkbox value is being processed. 
      else if (typeof value === "number") {
          // Find the index of 'value' in tempStoredValue array
          let index = tempStoredValue.indexOf(value);
          // If the value is not found in the array (index will be -1), push it into the array.
          if (index === -1) {
              tempStoredValue.push(value);
          }
      }
  }
  // Set the new checkbox value state with updated 'tempStoredValue'
  setCheckBoxValue(tempStoredValue);
};


// `RemoveAllCheckboxValue` is a function that doesn't accept any parameters.
const RemoveAllCheckboxValue = () => {
  // It updates the state of checkBoxValue to be an empty array.
  // Essentially, it removes all checked values from checkBoxValue.
  setCheckBoxValue([]);
};


 // `getJobData` is a function that doesn't accept any parameters.
const getJobData = () => {
  // Set the loading state to true, indicating data fetching process has started
  setLoading(true);

  // If a filter value exists, add the search value if it exists. If no filter and search values exist, set an empty string
  if (filterValue) {
    let s = searchValue ? { searchText: searchValue } : "";
    filterValue = { ...filterValue, ...s };
  } else { 
    filterValue = searchValue ? { searchText: searchValue } : ""; 
  }

  // If the reload property is true, reset it to false.
  if (reload) {
    setReload(false);
  }

  // Clear out raw row data ahead of new fetch
  setRawRowData([]);

  // Make HTTP GET request to fetch job data from API.
  axios
    .get(process.env.React_App_baseUrl + "companyownershipjob/CompanyOwnershipGetManageJobs", {
      headers: {
        // Send various options as headers
        sortColumn: changeSortColumnNameFormat(sorting.sortBy),
        sourceFilter: filterValue ? encodeURIComponent(JSON.stringify(filterValue)) : "",
        isAscending: sorting.isAscending,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
      params: {
        // Send page number and number of rows per page in API request
        pageNumber: page + 1,
        pagesize: rowsPerPage,
      },
    })
    .then(function (response) {

      // If response contains data, process the data
      if (response.data.data) {
        // Store total count received from response
        setTotalCount(response.data.totalCount);
        
        // Create row data from response and store it
        let rowsData = []
        rowsData = response.data.data.map((row) =>
          createData(
            row.id, row.jobNo, row.jobName, row.createdOn, row.createdBy, row.noOfCompanies,
            row.noOfRecords, row.status, row.tat, row.priorityName,
          )
        );
        setRawRowData(rowsData);

        // Set the loading state to false, indicating data fetching process has completed
        setLoading(false);
        
        // If all rows are selected, push each id into 'selected' array if not already present
        let Id = response.data.data.map((element) => {
          return element.id;
        });
        if (isAllSelected) {
          let tempSelected = selected;
          Id.forEach((element) => {
            let index = selected.indexOf(element);
            if (index === -1) {
              selected.push(element);
            }
          });
        }
      }
    })
    .catch(function (error) {
      // Handle any errors occurred during HTTP GET request
      ErroHandling(error, function (res) {
        toasterError(res)
      })
    });
};


 // `handleKeyUp` is a function that accepts an event object.
const handleKeyUp = (event) => {
  // Check if the key pressed was 'Enter' (Keycode 13), and also ensure that the event target was not a button.
  if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
    // If conditions are met, reset the page count to 0.
    setPage(0);

    // Call `getFilterData` to retrieve filtered data.
    getFilterData()

    // Call `getJobData` to retrieve job data.
    getJobData();
  }
};


  // Function for Global Search
  const globalSearch = (value) => { setSearchValue(value); };

 // `searchClear` is a function to clear the search input, reset the page count, toggle the reload state, and re-fetch the job data.
const searchClear = () => {
  // Set the search value to an empty string, effectively clearing any existing input.
  setSearchValue("");

  // Reset the page count to 0.
  setPage(0);

  // Toggle the reload state. This could be used to force re-render or re-fetch data in other parts of the application.
  setReload(!reload);

  // Call `getJobData` to fetch the updated job data.
  getJobData();
};


  
  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    if (rawRowData.length < 1 || reload) { getJobData(); }
    var location = window.location.pathname;
  }, [filterValue]);


  React.useEffect(() => {
    if (state) {
      getJobData()
    }
  }, [filterValue, reload]);

  React.useEffect(() => {
    getJobData();
  }, [sorting, page, rowsPerPage, reload]);


// Declaration of React Hooks end Here
  // `resetTreeFilter` is a function that resets all filters.
const resetTreeFilter = () => {

  // Iterate over every item in the `companyFilter` array.
  companyFilter.forEach((item, index) => {
    // Reset the 'checked' property of each filter item to false, meaning it's not selected.
    item.checked = false

    // If the filter item has a 'children' property (sub-filters), do the same for those.
    if (item.children) {
      item.children.forEach((item, index) => { 
        item.checked = false 
      })
    }
  })

  // Do the same for the `locationFilter` array.
  locationFilter.forEach((item, index) => {
    item.checked = false;

    // If a filter item has first level children, reset their 'checked' property to false.
    if(item.children) {
      item.children.forEach((item, index) => { 
        item.checked = false 
      })
      
      // If a first level child filter item itself has children, also reset their 'checked' property to false.
      if (item.children) {
        item.children.forEach((item, index) => { 
          item.checked = false 
        })
      }
    }
  })
}

 // const checkboxRequired = (e, item) => { SetSelected(e); setId(e); };

  // Function for Open Delete Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") {
      setDeleteUserData(item.id);
      setDeleteJobNumber(item.jobNo);
      setOpenDelete(true);
    } else { }
  };
  const toggleDrawerFilter = (open, mode, e) => (event, item) => {
    setFilterState({ ...filterState, right: open });
  };

  // Function for Open Multi Job
  const openMultiJobPage = (open, mode, e) => (event, item) => {
    setCrudState(mode);
    setIdForEdit(item.id);
    setEditJobNumber(item.jobNo);
    setOpen(true);
    setScroll("paper");
  };

  
  // `deleteUser` is a function that deletes a user.
const deleteUser = () => {
  // Set the delete button loading state to true, indicating a process is ongoing.
  setDeleteBtnLoadinng(true);
  
  // Save the user data to be deleted in a variable
  let data = deleteUserData;
  
  // Make an HTTP DELETE request using axios to the specified URL.
  axios.delete(`${process.env.React_App_baseUrl}companyownershipjob/CompanyOwnershipDeleteJob`, {
    headers: {
      // Attach jobId and Authorization token to the request header.
      jobId: data,
      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    },
  })
    .then((res) => {
      // On successful completion of the request, display a success message,
      toasterSuccess("Job deleted successfully!");
      
      // Refresh the job data.
      getJobData();
      
      // Reset the loading state of the delete button, close the delete dialog and reload the component.
      setDeleteBtnLoadinng(false);
      setOpenDelete(false);
      setReload(true);
      setDeleteBtnLoadinng(false);
    })
    .catch((err) => {
      // If an error occurs during the request, handle the error,
      ErroHandling(err, function (res) {
        // display an error message,
        toasterError(res);
        
        // And reset the loading state of the delete button.
        setDeleteBtnLoadinng(false);
      });
    });
};


  
// `getFilterData` is an asynchronous function that fetches data for filter fields.
const getFilterData = async () => {
  try {
    // Make an HTTP GET request using axios to the specified URL, await waits until the Promise resolves.
    const response = await axios.get(
      `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`, {
      headers: {
        // Attach stored Authorization token to the request header.
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    });
  
    // If there's no data returned from the API call
    if (response.data.length === 0) {
      // Set an empty array for filters
      setDataForFilter([]);
    } else {
      // If there's data returned, set it to state variable
      setDataForFilter(response.data);
      
      // Loop through each company entry.
      // Check each item and its children (if they exist), and mark them as not checked. 
      response.data.companies.forEach((item, index) => {
        item.checked = false;
        if (item.children) {
          item.children.forEach((item, index) => { item.checked = false; });
        }
      });

      //Loop through each location region entry.
      // Check each item, its children, and the children's children (if they exist), and mark them as not checked. 
      response.data.location.regions.forEach((item, index) => {
        item.checked = false;
        if (item.children) {
          item.children.forEach((item, index) => { item.checked = false })
          if (item.children) {
            item.children.forEach((item, index) => { item.checked = false })
          }
        }
      });

      // Set the company and location filters to their respective data from the response.
      setCompanyFilter(response.data.companies);
      setLocationFilter(response.data.location.regions);
    }
  } catch (error) {
    // If there's an error in the process, handle it and display an error message.
    ErroHandling(error, function (res) {
      toasterError(res)
    })
  }
};

  useEffect(() => {
    getFilterData()
  }, [reload])

  // Function for Sorting
  const changeSortColumnNameFormat = (fieldName) => {

    if (fieldName == null || fieldName == "") { return ""; }
    if (fieldName.toLocaleLowerCase() == "jobNo".toLocaleLowerCase()) { fieldName = "JobNo"; }
    if (fieldName.toLocaleLowerCase() == "jobName".toLocaleLowerCase()) { fieldName = "JobName"; }
    if (fieldName.toLocaleLowerCase() == "createdOn".toLocaleLowerCase()) { fieldName = "CreatedOn"; }
    if (fieldName.toLocaleLowerCase() == "createdBy".toLocaleLowerCase()) { fieldName = "CreatedBy"; }
    if (fieldName.toLocaleLowerCase() == "noOfRecords".toLocaleLowerCase()) { fieldName = "NoOfRecords"; }
    if (fieldName.toLocaleLowerCase() == "Status".toLocaleLowerCase()) { fieldName = "Status"; }
    if (fieldName.toLocaleLowerCase() == "tat".toLocaleLowerCase()) { fieldName = "TAT"; }
    if (fieldName.toLocaleLowerCase() == "priorityName".toLocaleLowerCase()) { fieldName = "PriorityName"; }

    return fieldName;
  }

  // `AddRecords` is a function that validates selection and redirects to job creation page.
const AddRecords = () => {
  // Checks whether 'selected' is undefined, has no elements, or has more than one element.
  if (selected === undefined || selected.length < 1 || selected.length > 1) {
    // If any of the conditions are met, it displays an error message using toasterError function.
    toasterError("please select one job to add record.")
    return; // Terminates the function early.
  }
  
  // 'history.push' is used for routing to another route ('/app/ownership-changes/create-job') with state data.
  // The state allows us to pass data to the new route.
  history.push({ pathname: "/app/ownership-changes/create-job", state: selected[0] });
}

// Define a higher-order function 'redirectToRecords'. It accepts three parameters: 'open', 'mode', and 'e'.
const redirectToRecords = (open, mode, e) => 

  // This higher-order function returns another function that takes two parameters: 'event' and 'item'.
  (event, item) => {
  
    // Inside this returned function, we use the 'history.push' method for navigation.
    history.push({
      // The 'pathname' is set to the string representing the manage job records page.
      pathname: "/app/ownership-changes/manage-job-records",
      
      // The 'state' is an object which has a single property 'jobAssetId'. It's value is set to the 'id' property of the 'item 'object. 
      // This allows us to pass data between routes.
      state: { jobAssetId: item.id }, 

      // The previous location or route is assigned to 'prevPath'. This can be used to go back to the previous route.
      prevPath: location,
    });
};


  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Manage Jobs</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Ownership Changes</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/create-job" }}>Job Management</Link>
            <Typography color="text.primary">Manage Jobs</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}


        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              <Button onClick={() => { history.push("/app/ownership-changes/create-job"); }}
                variant="contained" color="accent2" startIcon={<ArrowBackIosIcon />}>
                Back
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              {access.allowEdit && <Button onClick={() => { AddRecords() }}
                variant="contained" color="secondary" startIcon={<AddIcon />}>
                Add Records
              </Button>}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      // label="TextField"
                      className="serchByKeyword mt-1"
                      size="small"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"><SearchIcon /></InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : ("")}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Button onClick={toggleDrawerFilter(true)}
                      variant="contained" color="secondary" startIcon={<FilterAltOutlinedIcon />}>
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              <Grid item xs={12}>
                <LoaderWrapper loading={isLoading}>
                  <ManageJobsCustomTable
                    className={classes.removeBorder}
                    rows={rawRowData ? rawRowData : []}
                    columns={columns}
                    page={page}
                    setPage={(data) => {
                      setPage(data);
                      setReload(true);
                    }}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={(data) => {
                      setRowsPerPage(data);
                      setReload(true);
                    }}
                    totalCount={totalCount}
                    isAllSelected={isAllSelected}
                    setIsSelected={setIsSelected}
                    selected={selected}
                    setSelected={SetSelected}
                    sorting={sorting}
                    setSorting={setSorting}
                    isActionButton={{
                      ...(access.allowView && {
                        view: redirectToRecords(),
                      }),
                      ...(access.allowEdit && {
                        edit: openMultiJobPage(true, "Edit"),
                      }),
                      ...(access.allowDelete && {
                        delete: toggleDrawer(true, "Delete"),
                      }),
                    }}
                    setviewDataButtonDisable={setviewDataButtonDisable}
                    selectableRows
                    hasChip={{
                      High: { color: "warning", label: "High" },
                      Critical: { color: "error", label: "Critical" },
                      Low: { color: "success", label: "Low" },
                      Medium: { color: "info", label: "Medium" },
                    }}
                    /* checkboxSelection={{
                      checkbox: checkboxRequired,
                    }}  */
                  />
                </LoaderWrapper>
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>

        {/* Open Filter Panel */}
        <SideDrawer
          formComp={
            <FilterForm
              dataForFilter={dataForFilter}
              setDataForFilter={setDataForFilter}
              filterFinalData={filterFinalData}
              companyFilter={companyFilter}
              locationFilter={locationFilter}
              setFilterValues={setFilterValues}
              state={filterState}
              setState={setFilterState}
              setFilterValue={setFilterValue}
              filterValue={filterValue}
              reload={reload}
              setReload={setReload}
              resetTreeFilter={resetTreeFilter}
              setJobCompanies={setJobCompanies}
              setJobLocations={setJobLocations}
              setAssetTypes={setAssetTypes}
              setCommodityList={setCommodityList}
              setPriorityList={setPriorityList}
              getFilterData={getFilterData}
              setCompanyFilter={setCompanyFilter}
              setLocationFilter={setLocationFilter}
              tatDate={tatDate}
              setTatDate={setTatDate}
            />
          }
          state={filterState}
          setState={setFilterState}
          setFilterValue={setFilterValue}
        />

        {/* Open Edit Multiple Job Panel */}
        <ScrollingDialog
          open={open}
          setOpen={setOpen}
          scroll={scroll}
          setScroll={setScroll}
          dialogClass="darkTitle"
          dialogTitle={crudState === "Edit" ? "Edit Jobs" : "View Jobs"}
          dialogContent={
            <EditMultipleJobForAssignJob
              open={open}
              crudState={crudState}
              state={state}
              idforEdit={idforEdit}
              editJobNumber={editJobNumber}
              setState={setState}
              setOpen={setOpen}
              reload={reload}
              setReload={setReload}
              hideSideDrawer={() => {
                setOpen(false);
                setState({ right: false });
                getJobData();
              }}
            />
          }
        />

        {/* Alert Dialog - Confirmation for Delete */}
        {openDelete ? (
          <AlertDialog
            open={openDelete}
            title={"Confirmation"}
            maxWidth={"sm"}
            description={`Are you sure want to delete the Job Id : ${deleteJobNumber}?`}
            action={
              <Grid container justifyContent="right">
                <Grid item xs={'auto'}>
                  <Button onClick={() => setOpenDelete(false)} style={{ marginRight: "15px" }}>No</Button>
                </Grid>
                <Grid item xs={'auto'}>
                  {deleteBtnLoadinng ? (<CircularProgress />) :
                    (<Button onClick={deleteUser} color="secondary">Yes</Button>)}
                </Grid>
              </Grid>
            }
          />
        ) : null}
      </Grid>
    </Box>
  );
};

export default OwnershipChangesJobList;
