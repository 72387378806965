// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Box, Breadcrumbs, Grid, Paper, Typography, Table, TableBody, TableCell,
  TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
} from "@mui/material";
import { Link } from 'react-router-dom';
import ApiService from "../../../../Services/ApiService";
import { toast } from "react-toastify";


// Importing Custom Components
import { FileOffIcon } from "@fluentui/react-icons-mdl2";
import { LoaderWrapper } from "../../../../UIComponent/Progress";
import BaseLayout from "../../../../UIComponent/BaseLayout";
import EmptyState from "../../../../UIComponent/EmptyState";
import ExportToExcel from "../Excelexport";
// import history from "../../../Routes/History";

import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../../UIComponent/Toaster";
// Importing useStyles
import useStyles from "./AgingReport.styles";

// Import Fluent UI Icons
import { ChevronRightSmallIcon } from "@fluentui/react-icons-mdl2";
import GetMenuService from '../../../../Services/GetMenuService';
import { useHistory } from "react-router-dom";

const DataCollectionAgingReport = (props) => {
  const history = useHistory()
  // Declaration of States and Component Variables Start Here
  const [allUsers, setAllUsers] = useState([]);
  const [exportReportData, setExportReportData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [reportData, setRecordData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState();
  const [showDelete, setDelete] = useState(false);
  const [sorting, setSorting] = useState({ sortBy: "PriorityOrder", isAscending: false });
  const classes = useStyles();
  const date = new Date().toLocaleDateString();
  const date1 = date.split("/").join("-");
  // Declaration of States and Component Variables End Here

  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })


  React.useEffect(() => {
    GetMenuService(33, function (err, res) {
      if (res) {
        //console.log(res.data)
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])

  // Declaration of React Hooks Start Here
  // This useEffect hook will call the searchAgingReportData function when the reload variable changes 
  React.useEffect(() => { searchAgingReportData(); }, [reload]);
  // Declaration of React Hooks End Here

  // Function for Search Clear
  // This function is used to search for aging report data based on the filter value provided
  const searchAgingReportData = (filterValue) => {
    // Set loading to true while the data is being fetched
    setLoading(true);
    // Call the ApiService to get all the reports
    ApiService.getAll("reports/GetAgingSummaryReport",)
      .then((res) => {
        // If the response status is 200, set the record data and export report data
        if (res.status === 200) {
          setRecordData(res.data);
          let exportExcelData = [];
          // Check if the response data is not null
          if (res.data != null) {
            // Loop through the response data and push the elements into the exportExcelData array
            res.data.forEach(element => {
              exportExcelData.push({
                "Job Priority": element.priorityName,
                "1 - 10 Days": element.lessThanTen,
                "11 - 20 Days": element.lessThanTwenty,
                "21 - 30 Days": element.lessThanThirty,
                "> 30 Days": element.greaterThanThirty,
                "Total": element.total
              })
            });
            // Set the export report data
            setExportReportData(exportExcelData);
          }
          // Set loading to false after the data has been fetched
          setLoading(false);
        } else {
          // If the response status is not 200, call the toasterError function with the response data
          toasterError(`${res.data}`);
          setLoading(false);
        }
      })
      .catch((err) => {
        // Set loading to false if an error occurs
        setLoading(false);
        // If the response status is 400, call the toasterError function with the response data
        if(err.res.status===400){
          toasterError(err.res.data);
        }
         });
  };


  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Aging Report</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/aging-report" }}>Dashboard and Reports</Link>
            <Typography color="text.primary">Aging Report</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>

            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>

              {/* Search and Filter Options Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid item textAlign="right">
                  <Typography variant="subtitle2" >
                    <ExportToExcel apiData={exportReportData} fileName={`Aging Report ${date1}`} />
                  </Typography>
                </Grid>
              </Grid>
              {/* Search and Filter Options End Here */}

              {/* Aging Report Table */}
              <Grid item xs={12}>
                <LoaderWrapper loading={isLoading}>
                  <TableContainer component={Paper} elevation={0} className="table-container">
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableHeader} style={{ minWidth: 150, width: '15%' }}>
                            Job Priority
                          </TableCell>
                          <TableCell className={classes.tableHeader} style={{ minWidth: 110, textAlign: "center", width: '17%' }}>
                            1 - 10 Days
                          </TableCell>
                          <TableCell className={classes.tableHeader} style={{ minWidth: 110, textAlign: "center", width: '17%' }}>
                            11 - 20 Days
                          </TableCell>
                          <TableCell className={classes.tableHeader} style={{ minWidth: 110, textAlign: "center", width: '17%' }}>
                            21 - 30 Days
                          </TableCell>
                          <TableCell className={classes.tableHeader} style={{ minWidth: 110, textAlign: "center", width: '17%' }}>
                            {"> 30 Days"}
                          </TableCell>
                          <TableCell className={classes.tableHeader} style={{ minWidth: 110, textAlign: "center", width: '17%' }}>
                            Total
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          reportData.length > 0 ?
                            reportData.map((item, index) => (

                              <TableRow key={index} hover>
                                <TableCell className={classes.tableCell}>{item.priorityName}</TableCell>
                                <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>
                                  {(index != (reportData.length - 1)) ?
                                    <span
                                      onClick={() =>
                                        access.allowView && history.push({
                                          pathname: "/app/data-collections/aging-report-drill",
                                          state: {
                                            PriorityId: item.priorityId,
                                            tat: 1
                                          }
                                        })
                                      }
                                      className={classes.tableLink}
                                    >
                                      {item["lessThanTen"]}
                                    </span> :
                                    <span
                                      onClick={() =>
                                        access.allowView && history.push({
                                          pathname: "/app/data-collections/aging-report-drill",
                                          state: {
                                            PriorityId: 0,
                                            tat: 1
                                          }
                                        })
                                      }
                                      className={classes.tableLink}
                                    >
                                      {item["lessThanTen"]}
                                    </span>
                                  }
                                </TableCell>
                                {/* </Link> */}
                                <TableCell className={classes.tableCell}
                                  style={{ textAlign: "center" }}
                                >
                                  {(index != (reportData.length - 1)) ?
                                    <span
                                      onClick={() =>
                                        access.allowView && history.push({
                                          pathname: "/app/data-collections/aging-report-drill",
                                          state: {
                                            PriorityId: item.priorityId,
                                            tat: 2
                                          }
                                        })
                                      }
                                      className={classes.tableLink}
                                    >
                                      {item["lessThanTwenty"]}
                                    </span> : <span
                                      onClick={() =>
                                        access.allowView && history.push({
                                          pathname: "/app/data-collections/aging-report-drill",
                                          state: {
                                            PriorityId: 0,
                                            tat: 2
                                          }
                                        })
                                      }
                                      className={classes.tableLink}
                                    >
                                      {item["lessThanTwenty"]}
                                    </span>
                                  }
                                </TableCell>
                                <TableCell className={classes.tableCell}
                                  style={{ textAlign: "center" }}
                                >
                                  {(index != (reportData.length - 1)) ?
                                    <span
                                      onClick={() =>
                                        access.allowView && history.push({
                                          pathname: "/app/data-collections/aging-report-drill",
                                          state: {
                                            PriorityId: item.priorityId,
                                            tat: 3
                                          }
                                        })
                                      }
                                      className={classes.tableLink}
                                    >
                                      {item["lessThanThirty"]}
                                    </span> : <span
                                      onClick={() =>
                                        access.allowView && history.push({
                                          pathname: "/app/data-collections/aging-report-drill",
                                          state: {
                                            PriorityId: 0,
                                            tat: 3
                                          }
                                        })
                                      }
                                      className={classes.tableLink}
                                    >
                                      {item["lessThanThirty"]}
                                    </span>
                                  }
                                </TableCell>
                                <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>
                                  {(index != (reportData.length - 1)) ?
                                    <span
                                      onClick={() =>
                                        access.allowView && history.push({
                                          pathname: "/app/data-collections/aging-report-drill",
                                          state: {
                                            PriorityId: item.priorityId,
                                            tat: 4
                                          }
                                        })
                                      }
                                      className={classes.tableLink}
                                    >
                                      {item["greaterThanThirty"]}
                                    </span> : <span
                                      onClick={() =>
                                        access.allowView && history.push({
                                          pathname: "/app/data-collections/aging-report-drill",
                                          state: {
                                            PriorityId: 0,
                                            tat: 4
                                          }
                                        })
                                      }
                                      className={classes.tableLink}
                                    >
                                      {item["greaterThanThirty"]}
                                    </span>
                                  }
                                </TableCell>
                                <TableCell className={classes.tableCell} style={{ textAlign: "center" }} >
                                  {(index != (reportData.length - 1)) ?
                                    <span
                                      onClick={() =>
                                        access.allowView && history.push({
                                          pathname: "/app/data-collections/aging-report-drill",
                                          state: {
                                            PriorityId: item.priorityId,
                                            tat: 0
                                          }
                                        })
                                      }
                                      className={classes.tableLink}
                                    >
                                      {item["total"]}
                                    </span> : <span
                                      onClick={() =>
                                        access.allowView && history.push({
                                          pathname: "/app/data-collections/aging-report-drill",
                                          state: {
                                            PriorityId: 0,
                                            tat: 0
                                          }
                                        })
                                      }
                                      className={classes.tableLink}
                                    >
                                      {item["total"]}
                                    </span>
                                  }

                                </TableCell>
                              </TableRow>
                            )) :
                            (<TableRow>
                              <TableCell className={classes.tableCell} align="center" colSpan={99}>
                                <EmptyState
                                  icon={
                                    <FileOffIcon style={{ color: "#db9400", fontSize: "40px" }} />
                                  }
                                  title="No Data Found"
                                />
                              </TableCell>
                            </TableRow>)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </LoaderWrapper>
              </Grid>

            </Box>
          </BaseLayout>
        </Grid>
        {/* Baselayout End Here */}

      </Grid>
    </Box>
  );
};

export default DataCollectionAgingReport;