// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Autocomplete, Box, Breadcrumbs, Button, Checkbox, Chip, Collapse, Grid, IconButton,
  InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead,
  TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Typography
} from "@mui/material";
import axios from "axios";
import { Link } from 'react-router-dom';
import { visuallyHidden } from "@mui/utils";
import { useHistory } from "react-router-dom";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import CreateJobPage from "./CreateJob";
import EditJobRecords from "./EditJobRecords";
import FilterJobRecords from "./FilterJobRecords";
import { ScrollingDialog } from "../../../UIComponent";
import TooltipIcon from "../../../UIComponent/Tooltip";
import EmptyState from "../../../UIComponent/EmptyState";
import FormDialog from "../../../UIComponent/FormDialog";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import SideDrawer from "../../../../src/UIComponent/Drawer/index";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import { LoaderWrapper } from "../../../UIComponent/Progress";

// Importing useStyles
import "./index.scss";
import useStyles from "./jobManagement.styles";

// Import material Icons
import SearchIcon from "@mui/icons-material/Search";

// Import Fluent UI Icons
import {
  AddIcon, ChevronLeftIcon, ChevronRightSmallIcon, ClearIcon, DeleteIcon, EditIcon, FileOffIcon,
  FilterIcon, ReminderGroupIcon, RemoveIcon, ViewIcon
} from "@fluentui/react-icons-mdl2";
import { async } from "rxjs";
import { toast } from "react-toastify";
import CommonService from "../../../Services/CommonService";


// Creating Data Table for Create Data
function createData(
  jobNo, company, companyID, isCRUCompany, priority, assignedTo,
  assignedBy, createdOn, status, isopen, isSelected, children, isIndetSelected
) {
  return {
    jobNo, company, companyID, isCRUCompany, priority, assignedTo, status,
    assignedBy, createdOn, status, isopen, isSelected, children, isIndetSelected,
  };
}

// Creating Data Table for Create Sub Data
function createSubData(
  jobAssetId, asset, noOfVariable, country, commodity, assignedTo, assignedBy, status,
  startDate, tat, remainingDays, priority, createdOn, isSelected) {
  return {
    jobAssetId, asset, noOfVariable, country, commodity, assignedTo, assignedBy, status,
    startDate, tat, remainingDays, priority, createdOn, isSelected,
  };
}

function Row(props) {
  console.log("props", props)
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const history = useHistory();

  const [isAllSelected, setIsSelected] = useState(props.isAllSelected);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [jobAssetRecord, setJobAssetRecord] = useState([]);
  const [deleteRecord, setDeleteRecord] = useState([
    { companyId: 1, isCRUCompany: true, jobAssetId: [0], },]);
  const [assetId, setAssetId] = React.useState(0)
  const {
    open, setOpenDialog, row, dialog, setDialog, setScroll, setOpen, companyIds, jobIds,
    jobDetails, individualCheckOnChange, setJobDetails, setjobAssetIdForDelete,
    setisCRUCompforDelete, setCompanyIdforDelete, isCruComp, setDirectDelete,
    setCompData, jId, disData, displayJobId, access
  } = props;
  //console.log("rowprops",row)
  const truncateString = (string, length) => {
    return (
      string.length > length ? string.substring(0, length) + "..." : string
    );

  }
  const [jobId, setJobId] = useState(0);
  // Declaration of States and Component Variables End Here

   const handleClickOpen = (scrollType) => () => {
    setCompData({ ...row, instructions: row.jobInstruction, priority: row.priorityName, companyID: row.companyId, company: row.companyName });
    setOpen(true);
    setScroll(scrollType);

  };

  const openDeleteDialog = (item) => {
    setjobAssetIdForDelete(item.jobAssetId);
    setisCRUCompforDelete(item.isCRUCompany);
    setCompanyIdforDelete(item.companyId);
    setOpenDialog(true);
  };


  return (
    <React.Fragment>
      <TableRow hover sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell key={"checkboxIdOne"} padding="checkbox" className={classes.tableCell}>
          <Checkbox
            checked={row.isSelected}
            //  indeterminate={row.isIndetSelected}
            onChange={(event) => individualCheckOnChange(row, "outer")}
            inputProps={{ "aria-label": "Items" }}
          />
        </TableCell>
        <TableCell style={{ paddingLeft: 0 }} className={classes.tableCell}>
          <IconButton aria-label="expand row" size="small"
            onClick={() => {
              let company = jobDetails.map((cmd) => {
                if (row.companyId === cmd.companyId) { cmd.isopen = !cmd.isopen; }
                else { cmd.isopen = false; }
                return cmd;
              });
              setJobDetails(company);
              if (!row.children.length) {
                var filterData;
                if (props.filterValue) {
                  let s = props.searchValue
                    ? { searchText: props.searchValue } : "";
                  filterData = { ...props.filterValue, ...s };
                } else {
                  filterData = props.searchValue
                    ? { searchText: props.searchValue } : "";
                }
              }
            }}>
            {/* {row.isopen ? <RemoveIcon /> : <AddIcon />} */}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className={classes.tableCell}>{row.jobNo}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{row.companyName}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{row.assetName}</TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <div className="pr-4">{row.statusName}</div>
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {row.assignedTo === '' ? '-' :
            <div className="pr-4">{row.assignedTo}</div>
          }
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          {row.startDate === '' ? '-' :
            <div className="pr-4">{row.startDate}</div>
          }
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          <div className="pr-4">{row.tat}</div>
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          <div className="pr-4">{row.remainingDays}</div>
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {row.assignedBy === '' ? '-' :
            <div className="pr-4">{row.assignedBy}</div>
          }
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          <div className="pr-4">{" "}
            {row.priorityName === "High" ? (
              <Chip label={row.priorityName} size="small" color="warning" className="statusChip" />
            ) : undefined}{" "}
            {row.priorityName === "Low" ? (
              <Chip label={row.priorityName} size="small" color="success" className="statusChip" />
            ) : undefined}{" "}
            {row.priorityName === "Medium" ? (
              <Chip label={row.priorityName} size="small" color="info" className="statusChip" />
            ) : undefined}
            {row.priorityName === "Critical" ? (
              <Chip label={row.priorityName} size="small" color="error" className="statusChip" />
            ) : undefined}
          </div>
        </TableCell>
        <TableCell align="center" className={classes.tableCell}><div className="pr-4">{row.variableCount}</div></TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {row.commodity === '' ? '-' :
            <Tooltip title={row.commodity} placement="top" arrow>
              <div>
                {truncateString(`${row.commodity}`, 80)}
              </div>
            </Tooltip>
          }
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {row.country === '' ? '-' :
            <div>
              {row.country}
            </div>
          }
        </TableCell>
        <TableCell className={classes.tableCell} align="center">
          <span>
            <TooltipIcon title="View" placement="top" color="primary">
              <ViewIcon style={{ fontSize: "20px", color: "#DB9400" }}
                onClick={() => {
                  history.push({
                    pathname: "/app/data-collections/update-record",
                    state: {
                      ...row, jobIds: row.jobId, ...{ ...disData, jobId: row.jobId, jobInstr: row.jobInstruction, jobName: row.jobName, jobType: row.jobType, priority: row.priorityName, tat: row.tat }, assetId: row.assetId, companyID: row.companyId, displayJobId: row.jobId, role: props.role
                      , defaultAsset: { jobAssetId: row.jobAssetId }
                    },
                  });
                  // console.log(history.state);
                }}
              />
            </TooltipIcon>
          </span>
          {(access.allowEdit === true) ? <span><TooltipIcon title="Edit" placement="top">
            <EditIcon style={{ fontSize: "20px", color: "#333842" }} onClick={handleClickOpen()} />
          </TooltipIcon>
          </span> : null}
          {(access.allowDelete === true) ? <span>
            <TooltipIcon title="Delete" placement="top" color="error">
              <DeleteIcon style={{ fontSize: "20px", color: "red" }}
                onClick={(e) => { openDeleteDialog(row); setDirectDelete(true); }}
              />
            </TooltipIcon>
          </span> : null}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const DataCollectionManageJobRecords = (props) => {
  // Declaration of States and Component Variables Start Here
  const history = useHistory();
  const classes = useStyles();
  const [historyData, setHistoryData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = React.useState({ right: false, });
  const [state2, setState2] = React.useState({ right: false, });
  const [directDelete, setDirectDelete] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [disableAssign, setDisableAssign] = useState(true);
  const [isAssetSelected, setIsAssetSelected] = React.useState(false);
  const [assetIndex, setAssetIndex] = React.useState(0);
  const [companyValue, setCompanyValue] = React.useState(0);
  const [newJob, setNewJob] = React.useState(true);
  const [companyDropDown, setCompanyDropDown] = React.useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [companyDropDownTrue, setCompanyDropDownTrue] = React.useState([]);
  const [companyDropDownFalse, setCompanyDropDownFalse] = React.useState([]);
  const [assetTypeDropDown, setAssetTypeDropDown] = React.useState([]);
  const [regionDropDown, setRegionDropDown] = React.useState([]);
  const [commodityDropDown, setCommodityDropDown] = React.useState([]);
  const [statusDropDown, setStatusDropDown] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [company, setCompany] = React.useState([]);
  let [filterValue, setFilterValue] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [crudState, setCrudState] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [companyId, setCompanyId] = React.useState();
  const [assetId, setAssetId] = React.useState();
  const [sorting, setSorting] = useState({ orderBy: "", order: false });
  const [isAllSelected, setIsSelected] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedValue, setSelectedValue] = useState();
  const [argumentValue, setArgumentValue] = useState({});
  const [createJobData, setCreateJobData] = useState({});
  const [pageRefresh, setPageRefresh] = useState(false);
  const [rowValue, setRowValue] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [level, setLevel] = useState("");
  const [assetValue, setAssetValue] = useState(0);
  const [disableAsset, setDisableAsset] = useState(false);
  const [dialog, setDialog] = useState(true);
  const [compData, setCompData] = useState();
  const [jobAssetType, setJobAssetType] = useState("");
  const [displayJobId, setDisplayJobId] = useState(0);
  const [displayJobName, setDisplayJobName] = useState("");
  const [displayDataType, setDisplayDataType] = useState("");
  const [displayPriority, setDisplayPriority] = useState("");
  const [displayTat, setDisplaytat] = useState("");
  const [displayJobInst, setDisplayJobInst] = useState("");
  const [companyIds, setCompanyIds] = useState();
  const [jId, setJId] = useState(null);
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValues, setFilterValues] = useState();
  const [companyFilter, setCompanyFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [manageJobRecords, setManageJobRecords] = useState({});
  const [jobDetails, setJobDetails] = useState([]);
  const [isOpenAssign, setIsOpenAssign] = useState(false);
  const [close, setClose] = useState(false);
  const [assignedToList, setAssignedToList] = useState([]);
  const [assignedToId, setAssignedToId] = useState(null);
  const [jobSelection, setJobSelection] = useState([]);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [companyIdforDelete, setCompanyIdforDelete] = useState(null);
  const [isCRUCompforDelete, setisCRUCompforDelete] = useState(false);
  const [jobAssetIdForDelete, setjobAssetIdForDelete] = useState([]);
  const [assignJobToID, setAssignJobToID] = useState(null);
  const [pageReload, setPageReload] = useState(false);
  const [isCruComp, setIsCRUComp] = useState(false);
  const [reload, setReload] = useState(false);
  const [tableRefresh, setTableRefresh] = useState(false)


  const [disData, setDisData] = useState({
    jobId: 0, jobName: "", jobType: "", priority: "", tat: "", jobInstr: "",
  });
  const [jobCompanies, setJobCompanies] = React.useState([])
  const [jobLocations, setJobLocations] = React.useState([])
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
  const [role, setRole] = React.useState();
  const [tatDate, setTatDate] = React.useState(null)

  let temp = CommonService.GetStoredData(props, "historyData");
  const [jobIds, setJobIds] = useState(temp ? temp.state.jobAssetId:0);
  // Function for Handle Request Sort
  const handleRequestSort = (event, property) => {
    const isAscending = sorting.orderBy === property && sorting.order;
    setSorting({ orderBy: property, order: isAscending ? false : true });
  };

  // Function for Reset Tree Filter
  const resetTreeFilter = () => {
    companyFilter.forEach((item, index) => {
      item.checked = false
      if (item.children) {
        item.children.forEach((item, index) => { item.checked = false })
      }
    })
    locationFilter.forEach((item, index) => {
      item.checked = false;
      if (item.children) {
        item.children.forEach((item, index) => { item.checked = false })
        if (item.children) {
          item.children.forEach((item, index) => { item.checked = false })
        }
      }
    });
    setTatDate(null)
  }

  React.useEffect(() => {
    var a = localStorage.getItem('role')
    setRole(a)
  }, [])


  // Function for Getting Filter Data
  const getFilterData = async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      });
      if (response.data.length === 0) {
        setDataForFilter([]);
      } else {
        setDataForFilter(response.data);
        response.data.companies.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
          }
        });
        response.data.location.regions.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false
            })
            if (item.children) {
              item.children.forEach((item, index) => {
                item.checked = false
              })
            }
          }

        });
        setCompanyFilter(response.data.companies);
        setLocationFilter(response.data.location.regions);
      }
    } catch (error) { ErroHandling(error, function (res) { toasterError(res) }) }
  };

  // Declaration of React Hooks Start Here
  React.useEffect(() => {

    GetMenuService(9, function (err, res) {
      if (res) {
        setAccess(res.data)
        if (jobAssetType !== undefined && jobAssetType.toLocaleLowerCase() === 'completed'.toLocaleLowerCase()) {
          setAccess({ ...access, allowEdit: false, allowDelete: false });
        }
      }
      else { toasterError(err) }
    })



  }, [])
  React.useEffect(() => { getFilterData(); }, [reload, jId])
  React.useEffect(() => {

    if (pageRefresh) {
      setPage(0);
      // setRowsPerPage(10);
      setSorting({ orderBy: "", order: false });
      setSearchValue("");
      setPageRefresh(false);
      setIsAssetSelected(false);
    } else {
      setIsAssetSelected(false);
    }
  }, [filterValue, pageRefresh === true]);
  // Declaration of React Hooks End Here

  React.useEffect(() => {

    axios.get(process.env.React_App_baseUrl + "DropDown/GetAssignedToFilterAsync", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then((res) => { setAssignedToList(res.data); });
  }, []);


  React.useEffect(() => {

    if (filterValue)
      getTableDataFilter(filterValue)
  }, [filterValue, tableRefresh])

  // Declaration of React Hooks Start Here
  React.useEffect(() => {

    setPage(0)
    setJId(historyData?.state);
    axios.get(process.env.React_App_baseUrl + "job/GetJobNoNameDropdown", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        setJobSelection(response.data);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) { toasterError(res) })
      });
    getTableData();
  }, [jobIds, pageReload, directDelete]);
  // Declaration of React Hooks End Here
  React.useEffect(() => {

    getTableData();

  }, [page, rowsPerPage, sorting, reload])

  React.useEffect(() => {
    GetDataFromTempStorage();
  }, [props])


  const GetDataFromTempStorage = () => {
    let temp = CommonService.GetStoredData(props, "historyData");
    if (temp) {
      setHistoryData(temp);
      if (temp.state.jobAssetId) {
        setJobIds(temp.state.jobAssetId);
      }
      if (temp.state.assetType) {
        setJobAssetType(temp.state.assetType)
      }
    }
  }



  // Function for Handle Open Close
  const handleOpenClose = () => {
    var a = checkBoxValidation('');
    if (a.length) { setIsOpenAssign(!isOpenAssign); }
    else { toasterError("Please make a selection!"); }
  };


  const removeCheckBox = () => {
    (jobDetails || []).forEach((el) => {
      if (el.isSelected) {
        el.isSelected = false
      }
    });

  }

  // Function for Validation
  const checkBoxValidation = (status) => {

    //console.log(jobDetails, "jobDetails")

    let reqObj = [];
    (jobDetails || []).forEach((el) => {
      if (el.isSelected) {
        console.log(el.statusName)
        if (status === 'assign') {
          if (el.statusName.toLowerCase() === "Yet To Start".toLowerCase() || el.statusName.toLowerCase() === "Merit Data Collection".toLowerCase() || el.statusName.toLowerCase() === "Merit QC failed".toLowerCase() || el.statusName.toLowerCase() === "CRU Review failed".toLowerCase()) {
            reqObj.push({
              isCRUCompany: el.isCRUCompany,
              companyId: el.companyId,
              jobAssetId: [el.jobAssetId],
            });
          } else {
            reqObj = []
            return;
          }
        } else {
          reqObj.push({
            isCRUCompany: el.isCRUCompany,
            companyId: el.companyId,
            jobAssetId: [el.jobAssetId],
          });
        }
      }
    });
    return reqObj;
  };




  // Function for Handle Delete
  const handleDelete = () => {

    setOpenDialog(false);
    let finalData = checkBoxValidation('');
    let otherFinalData = [
      { companyId: companyIdforDelete, isCRUCompany: isCRUCompforDelete, jobAssetId: [jobAssetIdForDelete] },
    ];
    axios.delete(process.env.React_App_baseUrl + "job/DeleteJobRecords", {
      headers: {
        //JobId: jobIds, 
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
      data: directDelete ? otherFinalData : finalData,
    })
      .then((res) => {
        if (res.status === 200) {
          toasterSuccess("Deleted Successfully!");
          setDirectDelete(false);
          setPageReload(true);
          setOpenDialog(false);
          if (historyData?.location?.state) {
            historyData.location.state = 0;
          }

          getTableData();
          setIsSelected(false);
        } else {
          toasterError("Something went wrong");
          setOpenDialog(false);
        }
      })
      .catch((err) => { ErroHandling(err, function (res) { toasterError(res) }) });

    removeCheckBox()
  };



  const getTableData = () => {
    if (filterValue) {
      let s = searchValue ? { searchText: searchValue } : "";
      filterValue = { ...filterValue, ...s };
    } else {
      filterValue = searchValue ? { searchText: searchValue } : "";
    }

    if (reload) { setReload(false); }
    setIsLoading(true)
    axios.get(process.env.React_App_baseUrl + "job/GetManageJobRecords",
      {
        headers: {
          jobId: jobIds,
          sortColumn: sorting.orderBy,
          sourceFilter: filterValue ? encodeURIComponent(JSON.stringify(filterValue)) : "",
          isAscending: sorting.order,
          Authorization: localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
        params: { pageNumber: page + 1, pageSize: rowsPerPage }
      }
    ).then((res) => {
      // console.log(res.data.data, "Checkerrrr")
      setIsLoading(false);
      setManageJobRecords(res.data.data);
      setJobDetails(res.data.data.map((el) => { el.isSelected = false; return el }));
      setTotalCount(res.data.totalCount)
      // res.data.jobDetails.data.map((item) => setCompanyIds(item.companyID));
    })
      .catch((err) => {
        setIsLoading(false); setJobDetails([]);
        if (historyData?.location?.state) {
          historyData.location.state = 0;
        }
      });
  };

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { // getFilterData()
        getTableData();
      }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      setTotalCount(0);
      setPage(0);
      // getFilterData()
      getTableData();
    }

  };

  const getTableDataFilter = (filterData) => {
    if (reload) { setReload(false); }
    setIsLoading(true);
    axios.get(process.env.React_App_baseUrl + "job/GetManageJobRecords",
      {
        headers: {
          jobId: jobIds,
          sortColumn: sorting.orderBy,
          sourceFilter: filterData ? encodeURIComponent(JSON.stringify(filterData)) : "",
          isAscending: sorting.order,
          Authorization: localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
        params: { pageNumber: 1, pageSize: rowsPerPage }
      }
    ).then((res) => {
      setIsLoading(false);
      setManageJobRecords(res.data.data);

      setJobDetails(res.data.data.map((el) => { el.isSelected = false; return el }));
      setTotalCount(res.data.totalCount)
      //res.data.jobDetails.data.map((item) => setCompanyIds(item.companyID));
    })
      .catch((err) => {
        setIsLoading(false); setJobDetails([]);
        if (historyData?.location?.state) {
          historyData.location.state = 0;
        }
      });
  };

  // Function for Global Search
  const globalSearch = (value) => {

    // setPage(0);
    // setSorting({ orderBy: "", order: false });
    setSearchValue(value ? value : "");
  };
  const searchClear = () => {
    setPage(0);
    setSorting({ orderBy: "", order: false });
    setSearchValue("");
  };

  // Function for Open Filter
  const toggleDrawer2 = (open) => (event) => {
    setState2({ ...state2, right: open });
    setShowFilter(true);
  };

  const handleChangePage = (event, newPage) => { setPage(newPage); };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // console.log(jobDetails, "jobDetails")

  const overAllCheckOnCHange = (event) => {
    setDisableAssign(!disableAssign);
    jobDetails.forEach((el) => {
      el.isSelected = !isAllSelected;
      //return el;
    });

    setJobDetails([...jobDetails])
    setIsSelected(!isAllSelected);
  };

  const individualCheckOnChange = (row, level, index) => {
    setjobAssetIdForDelete([]);
    setDisableAssign(!disableAssign);
    setLevel(level);
    row.isSelected = !row.isSelected;

    setJobDetails([...jobDetails]);
  };

  const selectAJob = (e, value) => {

    if (value != null && value != "undefined") {
      setJobIds(value.id);
      setJId(value.id)
    } else {
      setJobIds(0);
      setJId(0)
    }
    if (historyData?.location?.state) {
      historyData.location.state = 1;
    }

  };

  // Function for Assign Job
  const assignJob = () => {

    let finalData = checkBoxValidation('assign');
    console.log("finalData", finalData)
    if (finalData.length) {
      axios.post(`${process.env.React_App_baseUrl}job/AssignJobRecords`, finalData, {
        headers: {
          //jobId: jobIds, 
          AssignId: assignJobToID,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      })
        .then((res) => {
          setIsOpenAssign(false);
          setPageReload(true);
          getTableData();
          toasterSuccess("Job assigned to a new user");
          setIsSelected(false);
        })
        .catch((err) => {
          { ErroHandling(err, function (res) { toasterError(res) }) }
          setOpenDialog(false);
        });
      setIsOpenAssign(false);
    } else {
      toasterError("Cannot assign job as data collection is already completed")
      setIsOpenAssign(false);
    }

  };

  const handleClose = () => { setIsOpenAssign(false); };

  return (
    // Page Layout Start Here
    <Box>
      {/* {console.log("allowaccess",access)}
      {console.log("jobAssetType",jobAssetType)} */}

      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Manage Job Records</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/create-jobs" }}>Job Management</Link>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/manage-jobs" }}>Manage Jobs</Link>
            <Typography color="text.primary">Manage Job Records</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              <Button variant="contained" color="accent2" startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
                onClick={() => { history.push("/app/data-collections/manage-jobs"); }}>
                Back
              </Button>
            </Grid>
            {(access.allowEdit) ? <>
              <Grid item xs={"auto"}>
                <Button onClick={() => {
                  let jobIdList = [];
                  (jobDetails || []).forEach((el) => {
                    if (el.isSelected) {
                      jobIdList.push(el.jobId);
                    }
                  });
                  if (jobIdList.length > 1) {
                    toasterError("Please select only one job");
                    return;
                  }
                  if (jobIdList.length < 1) {
                    toasterError("Please select one job to add record");
                    return;
                  }
                  history.push({ pathname: "/app/data-collections/create-jobs", state: jobIdList });
                }}
                  variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: 14 }} />}>
                  Add Records
                </Button>
              </Grid>
            </> : null}
            {/* {(access.allowEdit) ? <>
              <Grid item xs={"auto"}>
                <Button variant="contained" color="secondary" startIcon={<ReminderGroupIcon style={{ fontSize: 16 }} />}>
                  Batch Assign
                </Button>
              </Grid>
            </> : null
            } */}
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Grid container alignItems="left" justifyContent="center" className={classes.tableBorder}>
              {/* Search and Filter Options Start Here */}
              <Grid container alignItems="left" justifyContent="center" spacing={{ xs: 1, sm: 2 }}
                className={classes.tableTopPart}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                  <TextField
                    id="input-with-icon-textfield"
                    className="serchByKeyword mt-1"
                    size="small"
                    fullWidth
                    value={searchValue}
                    placeholder="Search by Keyword"
                    onKeyUp={handleKeyUp}
                    onChange={(e) => globalSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"><SearchIcon /></InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  {searchValue ? (
                    <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                      <ClearIcon style={{ fontSize: 10 }} />
                    </IconButton>
                  ) : ("")}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <Autocomplete
                    //disablePortal
                    //disableClearable={false}
                    id="combo-box-demo"
                    options={jobSelection}
                    getOptionLabel={(option) => option.name}
                    onChange={selectAJob}
                    fullWidth
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>{option.name}</li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Job ID - Job Name" variant="standard" />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={5}>
                  <Grid item xs={12} textAlign="right">
                    <Grid container spacing={{ xs: 1, sm: 2 }} alignItems="center" justifyContent="flex-end">
                      {(access.allowEdit) ? <><Grid item>
                        <Button onClick={handleOpenClose} variant="contained" color="accent1"
                          startIcon={<ReminderGroupIcon style={{ fontSize: 16 }} color="primary" />}>
                          Assign
                        </Button>
                        {/* {console.log(jobIds, "checkers")} */}
                      </Grid>
                      </> : null
                      }
                      {(access.allowDelete === true) ?
                        <>
                          <Grid item>
                            <Button onClick={() => {
                              var a = checkBoxValidation('');
                              if (a.length) { setOpenDialog(true); }
                              else { toasterError("Please select any option"); }
                            }}
                              variant="contained" color="accent5"
                              startIcon={<DeleteIcon style={{ fontSize: 16 }} color="primary" />}>
                              Delete
                            </Button>
                          </Grid>
                        </> : null
                      }
                      {(access.allowView) ? <>
                        <Grid item>
                          <Button onClick={toggleDrawer2(true)} variant="contained" color="secondary"
                            startIcon={<FilterIcon style={{ fontSize: 16 }} color="primary" />}>
                            Filter
                          </Button>
                        </Grid>
                      </> : null
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {isOpenAssign && (
                  <FormDialog
                    isOpenAssign={isOpenAssign}
                    onClose={handleClose}
                    setIsOpenAssign={setIsOpenAssign}
                    title="Assign Job Records"
                    content={
                      <>
                        <Box sx={{ height: 60 }}>
                          <Autocomplete
                            //disablePortal
                            id="combo-box-demo"
                            options={assignedToList}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => {

                              if (historyData?.setJobIds !== undefined) {
                                historyData?.setJobIds(value.jobId)
                              }

                              setAssignJobToID(value.userId);
                            }}
                            fullWidth
                            renderInput={(params) => (
                              <TextField {...params} label="Analyst" variant="standard" />
                            )}
                          />
                          <br />
                          Note: Please select atleast one analyst and assign
                        </Box>
                      </>
                    }
                    action={
                      <Grid container spacing={2} justifyContent="right" style={{ marginTop: "15px" }}>
                        <Grid item xs={'auto'}>
                          <Button onClick={handleClose}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs={'auto'}>
                          <Button onClick={assignJob} color="secondary">Assign</Button>
                        </Grid>
                      </Grid>
                    }
                  />
                )}

                <LoaderWrapper loading={isLoading}>
                  <TableContainer className={classes.tableVerticalScroll}>
                    {/* sx={{ minHeight: 150, maxHeight: "calc(100vh - 480px)" }}> */}
                    <Table stickyHeader aria-label="sticky">
                      <TableHead>
                        <TableRow hover>
                          <TableCell key={"checkboxIdThree"} style={{ width: 50 }} className={classes.tableCell}>
                            <Checkbox checked={isAllSelected} onChange={overAllCheckOnCHange}
                              inputProps={{ "aria-label": "Select All" }}
                            />
                          </TableCell>
                          <TableCell className={classes.tableCell} />
                          <TableCell className={classes.tableCell} style={{ minWidth: 90 }} align="left"
                            sortDirection={
                              sorting.orderBy === "JobNo" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel
                              active={sorting.orderBy === "JobNo"}
                              direction={
                                sorting.orderBy === "JobNo" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "JobNo")}>
                              Job ID
                              {sorting.orderBy === "JobNo" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ minWidth: 250 }} align="left"
                            sortDirection={
                              sorting.orderBy === "CompanyName" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel
                              active={sorting.orderBy === "CompanyName"}
                              direction={
                                sorting.orderBy === "CompanyName" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "CompanyName")}>
                              Company
                              {sorting.orderBy === "CompanyName" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ minWidth: 250 }} align="left"
                            sortDirection={
                              sorting.orderBy === "AssetName" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel
                              active={sorting.orderBy === "AssetName"}
                              direction={
                                sorting.orderBy === "AssetName" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "AssetName")}>
                              Asset
                              {sorting.orderBy === "AssetName" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ minWidth: 180 }} align="left"
                            sortDirection={
                              sorting.orderBy === "StatusName" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel
                              active={sorting.orderBy === "StatusName"}
                              direction={
                                sorting.orderBy === "StatusName" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "StatusName")}>
                              Status
                              {sorting.orderBy === "StatusName" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ minWidth: 200 }} align="left"
                            sortDirection={
                              sorting.orderBy === "AssignedTo" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel
                              active={sorting.orderBy === "AssignedTo"}
                              direction={
                                sorting.orderBy === "AssignedTo" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "AssignedTo")}>
                              Assigned To
                              {sorting.orderBy === "AssignedTo" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ minWidth: 103 }} align="center"
                            sortDirection={
                              sorting.orderBy === "StartDate" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel
                              active={sorting.orderBy === "StartDate"}
                              direction={
                                sorting.orderBy === "StartDate" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "StartDate")}>
                              Start Date
                              {sorting.orderBy === "StartDate" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ minWidth: 103 }} align="center"
                            sortDirection={
                              sorting.orderBy === "TAT" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel
                              active={sorting.orderBy === "TAT"}
                              direction={
                                sorting.orderBy === "TAT" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "TAT")}>
                              TAT
                              {sorting.orderBy === "TAT" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ minWidth: 90 }} align="center"
                            sortDirection={
                              sorting.orderBy === "RemainingDays" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel
                              active={sorting.orderBy === "RemainingDays"}
                              direction={
                                sorting.orderBy === "RemainingDays" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "RemainingDays")}>
                              Remaining Days
                              {sorting.orderBy === "RemainingDays" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ minWidth: 200 }} align="left"
                            sortDirection={
                              sorting.orderBy === "AssignedBy" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel
                              active={sorting.orderBy === "AssignedBy"}
                              direction={
                                sorting.orderBy === "AssignedBy" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "AssignedBy")}>
                              Assigned By
                              {sorting.orderBy === "AssignedBy" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ minWidth: 150 }} align="center"
                            sortDirection={
                              sorting.orderBy === "PriorityName" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel active={sorting.orderBy === "PriorityName"}
                              direction={
                                sorting.orderBy === "PriorityName" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "PriorityName")}>
                              Priority
                              {sorting.orderBy === "PriorityName" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                         

                          <TableCell className={classes.tableCell} style={{ minWidth: 100 }} align="center"
                            sortDirection={
                              sorting.orderBy === "VariableCount" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel
                              active={sorting.orderBy === "VariableCount"}
                              direction={
                                sorting.orderBy === "VariableCount" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "VariableCount")}>
                              Variable Count
                              {sorting.orderBy === "VariableCount" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ minWidth: 300 }} align="left"
                            sortDirection={
                              sorting.orderBy === "Commodity" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel
                              active={sorting.orderBy === "Commodity"}
                              direction={
                                sorting.orderBy === "Commodity" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "Commodity")}>
                              Commodity
                              {sorting.orderBy === "Commodity" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ minWidth: 200 }} align="left"
                            sortDirection={
                              sorting.orderBy === "Country" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel
                              active={sorting.orderBy === "Country"}
                              direction={
                                sorting.orderBy === "Country" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "Country")}>
                              Country
                              {sorting.orderBy === "Country" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{ minWidth: 130 }} align="center">
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jobDetails.length > 0 ? (
                          jobDetails.map((row, index) => (

                            <Row
                              access={access}
                              compData={compData}
                              setCompData={setCompData}
                              setJobDetails={setJobDetails}
                              jobDetails={jobDetails}
                              companyIds={row.companyIds}
                              jobIds={jobIds}
                              setJobIds={setJobIds}
                              setOpen={setOpen}
                              setScroll={setScroll}
                              dialog={dialog}
                              setDialog={setDialog}
                              key={row?.companyId + "-" + index}
                              row={row}
                              setState={setState}
                              setCrudState={setCrudState}
                              filterValue={filterValue}
                              searchValue={searchValue}
                              setCompanyId={setCompanyId}
                              setAssetId={setAssetId}
                              isAllSelected={isAllSelected}
                              individualCheckOnChange={individualCheckOnChange}
                              setOpenDialog={setOpenDialog}
                              setCompanyIdforDelete={setCompanyIdforDelete}
                              setisCRUCompforDelete={setisCRUCompforDelete}
                              setjobAssetIdForDelete={setjobAssetIdForDelete}
                              isCruComp={isCruComp}
                              setDirectDelete={setDirectDelete}
                              jId={jId}
                              disData={disData}
                              role={role}
                              setRole={setRole}
                              displayJobId={displayJobId}
                            />
                          ))
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan={99}>
                              <EmptyState icon={<FileOffIcon style={{ color: "#db9400", fontSize: "40px" }} />}
                                title="No Data Found"
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </LoaderWrapper>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 25, 50, 100]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </BaseLayout>
        </Grid>

        {/* Open Create Job Panel */}
        <SideDrawer
          formComp={
            <CreateJobPage
              assetValue={assetValue}
              setPageRefresh={setPageRefresh}
              setLevel={setLevel}
              createJobData={createJobData}
              state={state}
              setState={setState}
              companyDropDown={companyDropDown}
              assetTypeDropDown={assetTypeDropDown}
              regionDropDown={regionDropDown}
              disableAsset={disableAsset}
              setDisableAsset={setDisableAsset}
              commodityDropDown={commodityDropDown}
              statusDropDown={statusDropDown}
              isAssetSelected={isAssetSelected}
              setIsAssetSelected={setIsAssetSelected}
              assetIndex={assetIndex}
              setAssetIndex={setAssetIndex}
              companyValue={companyValue}
              newJob={newJob}
              setNewJob={setNewJob}
            />
          }
          state={state}
          setState={setState}
          crudState={crudState}
          setPageRefresh={setPageRefresh}
          setLevel={setLevel}
          setIsAssetSelected={setIsAssetSelected}
        />

        {/* Open Filter Job Records Panel */}
        <SideDrawer
          formComp={
            <FilterJobRecords
              dataForFilter={dataForFilter}
              filterFinalData={filterFinalData}
              companyFilter={companyFilter}
              locationFilter={locationFilter}
              setFilterValue={setFilterValue}
              state={state2}
              setState={setState2}
              resetTreeFilter={resetTreeFilter}
              setJobCompanies={setJobCompanies}
              setJobLocations={setJobLocations}
              setLocationFilter={setLocationFilter}
              setCompanyFilter={setCompanyFilter}
              setTatDate={setTatDate}
              tatDate={tatDate}
              reload={reload}
              setReload={setReload}
            />
          }
          state={state2}
          setState={setState2}
          crudState={showFilter}
          setReload={setReload}
        />

        {/* Open Edit Jobs Panel */}
        <ScrollingDialog
          open={open}
          setOpen={setOpen}
          scroll={scroll}
          setScroll={setScroll}
          dialogClass="darkTitle"
          dialogTitle="Edit Jobs"
          dialogContent={
            <EditJobRecords
              state={state}
              selectedValue={selectedValue}
              setState={setState}
              setOpen={setOpen}
              setPageRefresh={setPageRefresh}
              jobIds={jobIds}
              companyIds={compData}
              hideSideDrawer={() => {
                getTableData();
              }}
              compData={compData}
            />
          } />

        {/* Alert Dialog - Confirmation for Delete */}
        <AlertDialog
          open={openDialog}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure want to delete the Job record?`}
          action={
            <Grid container justifyContent="right">
              <Grid item xs={'auto'}>
                <Button onClick={() => setOpenDialog(false)}>No</Button>
              </Grid>
              <Grid item xs={'auto'}>
                <Button onClick={handleDelete} color="secondary">Yes</Button>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Box>
  );
};

export default DataCollectionManageJobRecords;