// Default React, Router and Services Components
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import { toasterError } from "../../../UIComponent/Toaster";
import { LoaderWrapper } from "../../../UIComponent/Progress";

// Importing useStyles
import useStyles from "./dataTable.styles";

const DuplicateJobData = (props) => {
    // Declaration of States and Component Variables Start Here
    const [allJobs, setAllJobs] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [selected, SetSelected] = useState([]);
    const [isAllSelected, setIsSelected] = useState(false);
    const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
    const [reload, setReload] = useState(false);
    const classes = useStyles();
    const [companies, setCompanies] = useState();
    const [companyName, setCompanyName] = React.useState("");
    const [assets, setAssets] = useState();
    const [createManual, setCreateManual] = React.useState(true);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedCompnies, setSelectedCompanies] = useState([]);
    // Declaration of States and Component Variables End Here

    // Assigning Columns and UI Props
    const columns = [
        { id: "jobNo", label: "Job Id", minWidth: 90, width: 90, sorting: true, },
        { id: "jobName", label: "Job Name", minWidth: 300, width: 300, sorting: true, },
        { id: "createdOn", label: "Created Date", sorting: true, minWidth: 130, width: 130, align: "center" },
        { id: "jobInstruction", label: "Job Instruction", minWidth: 250, width: 250, sorting: true, },
        { id: "companyName", label: "Company", sorting: true, minWidth: 300, width: 300, },
        { id: "assetName", label: "Asset", sorting: true, minWidth: 200, width: 200, },
        { id: "assignedTo", label: "Assigned To", sorting: true, minWidth: 180, width: 180, },
        { id: "startDate", label: "Job Start Date", sorting: true, width: 120, minWidth: 120, align: "center"  },
        { id: "tat", label: "TAT", sorting: true, width: 120, minWidth: 120, align: "center"  },
        { id: "remainingDays", label: "Remaining Days", sorting: true, width: 120, minWidth: 120, align: "center"  },
        { id: "commodityName", label: "Commodity", minWidth: 350, width: 350, sorting: true, truncate: 90, tooltipWithTruncate: true },
        { id: "countryName", label: "Country", minWidth: 180, width: 180, sorting: true, },
        { id: "statusName", label: "Status", sorting: true, minWidth: 130, width: 130, align: "center" }
    ];

    // Function for Sorting
    const changeSortColumnNameFormat = (fieldName) => {
        if (fieldName == null || fieldName == "") {
            return "";
        }
        if (fieldName.toLocaleLowerCase() == "JobNo".toLocaleLowerCase()) {
            fieldName = "JobNo";
            // console.log(fieldName);
        }
        if (fieldName.toLocaleLowerCase() == "JobName".toLocaleLowerCase()) {
            fieldName = "JobName";
            // console.log(fieldName);
        }
        if (fieldName.toLocaleLowerCase() == "createdOn".toLocaleLowerCase()) {
            fieldName = "CreatedOn";
        }
        if (fieldName.toLocaleLowerCase() == "jobInstruction".toLocaleLowerCase()) {
            fieldName = "JobInstruction";
        }
        if (fieldName.toLocaleLowerCase() == "assignedTo".toLocaleLowerCase()) {
            fieldName = "AssignedTo";
        }
        if (fieldName.toLocaleLowerCase() == "tat".toLocaleLowerCase()) {
            fieldName = "Tat";
        }
        if (fieldName.toLocaleLowerCase() == "remainingDays".toLocaleLowerCase()) {
            fieldName = "RemainingDays";
        }
        if (fieldName.toLocaleLowerCase() == "startDate".toLocaleLowerCase()) {
            fieldName = "StartDate";
        }
        if (fieldName.toLocaleLowerCase() == "assetName".toLocaleLowerCase()) {
            fieldName = "AssetName";
        }
        if (fieldName.toLocaleLowerCase() == "commodityName".toLocaleLowerCase()) {
            fieldName = "CommodityName";
        }
        if (fieldName.toLocaleLowerCase() == "companyName".toLocaleLowerCase()) {
            fieldName = "CompanyName";
        }
        if (fieldName.toLocaleLowerCase() == "countryName".toLocaleLowerCase()) {
            fieldName = "CountryName";
        }
        if (fieldName.toLocaleLowerCase() == "statusName".toLocaleLowerCase()) {
            fieldName = "StatusName";
        }
        return fieldName;
    }

    // Function for Search Duplicate Jobs
    const searchDuplicateJobs = () => {
        setLoading(true);
        axios.post(`${process.env.React_App_baseUrl}job/CheckJobDuplication?pageNumber=${page}&pageSize=${rowsPerPage}`,
            selectedCompnies
            , {
                headers: {
                    startDate: startDate ? `${startDate}` : '',
                    endDate: endDate ? `${endDate}` : '',
                    manualJobCreation: createManual,
                    sortColumn: changeSortColumnNameFormat(sorting.sortBy),
                    isAscending: sorting.isAscending,
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                }
            })
            .then((res) => {
                setAllJobs(res.data.data);
                setTotalCount(res.data.totalCount);
                setLoading(false);
            })
            .catch((err) => {
                ErroHandling(err, function (res) {
                    toasterError(res)
                })
                setLoading(false);
            });
    }

    // Declaration of React Hooks Start Here
    useEffect(() => { setCreateManual(props.createManual); }, [props]);
    useEffect(() => {
        if (createManual) {
            let array = props.selectedCompnies?.map((element) => {
                return {
                    companyId: element.companyId,
                    isCRUCompany: element.isCRUCompany,
                    assetId: element.assetId,
                    isCRUAsset: element.isCRUAsset,
                }
            });
            setSelectedCompanies(array);
        } else {
            setStartDate(props.startDate);
            setEndDate(props.endDate);

            let array = props.selectedCompnies?.map((element) => {
                return {
                    companyId: element.companyId,
                    isCRUCompany: element.isCRUCompany,
                    assetId: 0,
                    isCRUAsset: false,
                }
            });
            setSelectedCompanies(array);
        }
    }, [createManual, props])

    useEffect(() => {
        if (!createManual && startDate !== undefined && startDate !== "" && endDate !== undefined && endDate !== "" && selectedCompnies.length >= 0) {
            searchDuplicateJobs();
        }
        if (createManual && selectedCompnies.length > 0) {
            searchDuplicateJobs();
        }
    }, [sorting, page, rowsPerPage, selectedCompnies, startDate, endDate])
    // Declaration of React Hooks End Here

    return (
        // Duplicate Job Data
        <Box>
            <LoaderWrapper loading={isLoading}>
                <DataTable
                    className={classes.removeBorder}
                    rows={allJobs}
                    columns={columns}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    totalCount={totalCount}
                    isAllSelected={isAllSelected}
                    setIsSelected={setIsSelected}
                    selected={selected}
                    setSelected={SetSelected}
                    sorting={sorting}
                    setSorting={setSorting}
                />
            </LoaderWrapper>
        </Box>
    );

}


export default DuplicateJobData;