// Default React, Router and Services Components
import React, { useContext } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import ApiService from "../../../../Services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFilterCRUQCReport,
  clearFilterDataJob,
  clearFilterReports,
  GetAssetData,
  GetAssetTypeData,
  setFilterActionJob,
  setFilterCRUQCReports,
  setFilterReports,
} from "../../../../features/filter/filterSlice";
import DropdownTreeSelect from "react-dropdown-tree-select";
import Container from "../../SourceManagement/TreeContainer";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
import {
  callBasedOnLocation,
  callFromAssetType,
  callFromComm,
  callFromPriority,
  callSource,
} from "../../../../Util/TreeAPI";

// Importing Custom Components
import MultiSelect from "../../../../UIComponent/MultiSelect/MultiSelect";
import DatePicker from "../../../../UIComponent/DatePicker";

// Importing useStyles
import "react-dropdown-tree-select/dist/styles.css";
import "../../../../Resources/scss/layout.scss";
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./CRUReviewReport.styles";
import { FilterContext } from "../../../DataCollection/DashboardReports/Context/Context";

const FilterForm = (props) => {
  // Declaration of States and Component Variables Start Here
  const { filterCRUReviewReports, assetDropDownlist } = useSelector(
    (store) => store.filter
  );

  const {
    companyListCRU,
    setCompanyListCRU,
    locationCRU,
    setLocationCRU,
    setFilterValueContext,
  } = useContext(FilterContext);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [countryDropdownList, setCountryDropdownList] = React.useState([]);
  const [regionDropdownList, setRegionDropdownList] = React.useState([]);
  const [subRegionDropdownList, setSubRegionDropdownList] = React.useState([]);
  const [jobComms, setJobComms] = React.useState([]);
  const [dropDown, setDropDown] = React.useState({ reportingperoid: [] });
  const [values, setValues] = React.useState({ reporting: [] });
  const [fromDate, setFromDate] = React.useState(props.fromDate);
  const [toDate, setToDate] = React.useState(props.toDate);
  const [crureviewDate, setCruReviewDate] = React.useState(props.cruReviewDate);
  const [keyToDate, setKeyToDate] = React.useState(false);
  const [keyFromoDate, setKeyFromDate] = React.useState(false);
  const [keycrureviewDate, setKeyCruReviewDate] = React.useState(false);
  const [assignedto, setAssignedTo] = React.useState([]);
  const [cruqc, setCruQc] = React.useState([]);
  const [filters, setFilters] = React.useState({
    company: [],
    asset: [],
    assetTypeIds: [],
    countryIds: [],
    regionIds: [],
    subRegionIds: [],
    commodityIds: [],
    statusIds: [],
    priorityIds: [],
  });
  const [dependency, setDependency] = React.useState(null);
  const [apiData, setApiData] = React.useState({
    companies: [],
    location: {},
    assetType: [],
    priority: [],
    commodity: [],
  });
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  // Declaration of States and Component Variables End Here

  // Function for Handle KeyUp
  const onChangeHandler = (key, value, reason) => {
    /* if (key === "commodity") {
      setDependency("commodity");
      setApiData({ ...apiData, commodity: value });
    } else if (key === "priority") {
      setDependency("priority");
      setApiData({ ...apiData, priority: value });
    } else if ("assetType") {
      setDependency(key === "assetType");
      setApiData({ ...apiData, assetType: value });
    } */

    const pre = { ...filterCRUReviewReports };
    pre[key] = value;

    dispatch(setFilterCRUQCReports({ filterCRUReviewReports: pre }));
  };

// This function is used to clear the fields in CRU QC Report
const clearFieldHanlder = () => {
    // Set reload value to opposite of current value
    props.setReload(!props.reload);
    // Remove selectedCRUFromDate from localStorage
    localStorage.removeItem("selectedCRUFromDate");
    // Remove selectedCRUToDate from localStorage
    localStorage.removeItem("selectedCRUToDate");
    // Remove selectedCRUQCDate from localStorage
    localStorage.removeItem("selectedCRUQCDate")
    // Set keyFromDate to opposite of current value
    setKeyFromDate((k) => !k);
    // Set keyToDate to opposite of current value
    setKeyToDate((t) => !t);
    // Set keyCruReviewDate to opposite of current value
    setKeyCruReviewDate((r) => !r);
    // Clear filterCRUQCReport
    dispatch(clearFilterCRUQCReport());
    // Set values to empty array
    setValues({ reporting: [] });
    // Set filterValue to empty string
    props.setFilterValue("");
    // Set companyListCRU to empty array
    setCompanyListCRU([]);
    // Set locationCRU to empty array
    setLocationCRU([]);
    // Set filterValueContext to null
    setFilterValueContext(null);
    setFromDate(null);
    setToDate(null);
    setCruReviewDate(null);


  };
  // Function for Country Filter
  const countryFilter = () => {
    let data = [];
    for (let item of props.locationCRU) {
      for (let items of item.children) {
        for (let ites of items.children) {
          if (ites.checked === true) {
            data.push(ites.value);
          }
        }
      }
    }
    return data;
  };

  // This function is used to set the filter values for CRU Review Reports
const searchFilter = () => {
  // Create an object to store the form data
  const formData = {

    /*  company: props.companyListCRU
        .filter((el) => el.checked === true)
        .map((el) => el.value),
      asset: props.companyListCRU
        .map((el) =>
          el.children.filter((it) => it.checked === true).map((tk) => tk.value)
        )
        .filter((item) => item.length > 0)
        .flat(1),
      countryIds: countryFilter(),
      regionIds: props.locationCRU
        .filter((el) => el.checked === true)
        .map((el) => el.value),
      subRegionIds: props.locationCRU
        .map((el) =>
          el.children.filter((it) => it.checked === true).map((tk) => tk.value)
        )
        .filter((item) => item.length > 0)
        .flat(1), */
    
    // Map over the commodities array and return the commodityId
    CommodityIds: (filterCRUReviewReports.commodities || []).map(
      (commo) => commo.commodityId
    ),
    // Map over the assignedto array and return the userId
    AssignedtoId: (filterCRUReviewReports.assignedto || []).map(
      (e) => e.userId
    ),
    // Set the fromDate value or get it from localStorage
    FromDate: fromDate||localStorage.getItem("selectedCRUFromDate"),
    // Set the toDate value or get it from localStorage
    ToDate: toDate || localStorage.getItem("selectedCRUToDate"),
    // Map over the cruqcuser array and return the userId
    MeritQCId: (filterCRUReviewReports.cruqcuser || []).map(
      (e) => e.userId
    ),
    // Set the crureviewDate value or get it from localStorage
    MeritQcCompletionDate: crureviewDate ||localStorage.getItem("selectedCRUQCDate"),
    //Status: null,
  };
  // Call the setFilterValueContext function with the formData as argument
  setFilterValueContext(formData);
  // Update the state of props
  props.setState({ ...props.state, right: false });
  // Call the setFilterValue function with the formData as argument
  props.setFilterValue(formData);
  // Call the setCompanyListCRU function with the companyListCRU as argument
  setCompanyListCRU(props.companyListCRU);
  // Call the setLocationCRU function with the locationCRU as argument
  setLocationCRU(props.locationCRU);
};
 

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    if (dependency === "company") {
      callSource(apiData)
        .then((res) => {
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationCRU) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationCRU) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationCRU) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "location") {
      callBasedOnLocation(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyListCRU.length; i++) {
              if (props.companyListCRU[i].value === el.value) {
                if (props.companyListCRU[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyListCRU[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyListCRU[i].children[j].value === item.value
                    ) {
                      if (
                        props.companyListCRU[i].children[j].checked === true
                      ) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
          // setSubCompDependency(true);
        })
        .catch((err) => console.log(err));
    } else if (dependency === "assetType") {
      callFromAssetType(apiData)
        .then((res) => {
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationCRU) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationCRU) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationCRU) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyListCRU.length; i++) {
              if (props.companyListCRU[i].value === el.value) {
                if (props.companyListCRU[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyListCRU[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyListCRU[i].children[j].value === item.value
                    ) {
                      if (
                        props.companyListCRU[i].children[j].checked === true
                      ) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "priority") {
      callFromPriority(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyListCRU.length; i++) {
              if (props.companyListCRU[i].value === el.value) {
                if (props.companyListCRU[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyListCRU[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyListCRU[i].children[j].value === item.value
                    ) {
                      if (
                        props.companyListCRU[i].children[j].checked === true
                      ) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationCRU) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationCRU) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationCRU) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          props.setCompanyFilter(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "commodity") {
      callFromComm(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyListCRU.length; i++) {
              if (props.companyListCRU[i].value === el.value) {
                if (props.companyListCRU[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyListCRU[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyListCRU[i].children[j].value === item.value
                    ) {
                      if (
                        props.companyListCRU[i].children[j].checked === true
                      ) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationCRU) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationCRU) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationCRU) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          props.setCompanyFilter(res.data.companies);
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [apiData]);
  // Declaration of React Hooks End Here

  // Function for Getting All Companies
  const getAllCompanies = () => {
    ApiService.getAll("DropDown/GetCompanyDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setJobCompanies(res.data);
        } else {
          toasterError(`${res.data}`);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

// This function is used to get all commodities from the API
const getAllCommodities = () => {
  // Call ApiService to get all commodities
  ApiService.getAll("DropDown/GetCommodityDropDownFilter")
    .then((res) => {
      // If response status is 200, set job commodities
      if (res.status === 200) {
        setJobComms(res.data);
      } else {
        // Else show error message
        toasterError(`${res.data}`);
      }
    })
    .catch((err) => {
      // Handle errors
      ErroHandling(err, function (res) {
        toasterError(res);
      });
    });
};

// This function is used to get all assigned users from the API
const getAllAssignedUsers = () => {
  // Call the ApiService to get all assigned users
  ApiService.getAll("DropDown/GetAssignedToFilterAsync")
    .then((res) => {
      // If the response status is 200, set the assigned users in the state
      if (res.status === 200) {
        setAssignedTo(res.data);
      } else {
        // If the response status is not 200, call the toasterError function with the response data
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // If there is an error, call the ErroHandling function with the error and a callback function
      ErroHandling(err, function (res) {
        // Call the toasterError function with the response data
        toasterError(res);
      });
    });
  }

// Function to get all CRU review users
const getAllCruReviewUsers = () => {
    // Call API service to get all data from CompanyOwnershipReport/GetCRUReviewDropdown
    ApiService.getAll("CompanyOwnershipReport/GetCRUReviewDropdown")
      .then((res) => {
        // If response status is 200, set CruQc with response data
        if (res.status === 200) {
          setCruQc(res.data);
        } else {
          // Else call toasterError function with response data
          toasterError(res.data);
        }
      })
      .catch((err) => {
        // Call ErroHandling function with error and callback function
        ErroHandling(err, function (res) {
          // Call toasterError function with response
          toasterError(res);
        });
      });
    }

 // This function is used to get all countries from the API
  const getAllCountries = () => {
    // Call the ApiService to get all countries
    ApiService.getAll("DropDown/GetCountryDropDownFilter")
      .then((res) => {
        // If the response status is 200, set the country dropdown list
        if (res.status === 200) {
          setCountryDropdownList(res.data);
        } else {
          // Else, call the toasterError function with the response data
          toasterError(`${res.data}`);
        }
      })
      .catch((err) => {
        // Call the ErroHandling function with the error and a callback function
        ErroHandling(err, function (res) {
          // Call the toasterError function with the response
          toasterError(res);
        });
      });
    }
 
  // Function for Getting All Region
  const getAllRegion = () => {
    ApiService.getAll("DropDown/GetRegionDropDownFilter")
      .then((res) => {
        // If the response status is 200, set the Region dropdown list
        if (res.status === 200) {
          setRegionDropdownList(res.data);
        } else {
           // Else, call the toasterError function with the response data
          toasterError(`${res.data}`);
        }
      })
      .catch((err) => {
        // Call the ErroHandling function with the error and a callback function
        ErroHandling(err, function (res) {
            // Call the toasterError function with the response
          toasterError(res);
        });
      });
  };

  // Function for Getting All Sub Region
  const getAllSubRegion = () => {
    ApiService.getAll("DropDown/GetSubRegionDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setSubRegionDropdownList(res.data);
        } else {
          toasterError(`${res.data}`);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    getAllCommodities();
    getAllAssignedUsers();
    getAllCruReviewUsers();
    // getAllCountries();
    // getAllSubRegion();
    // getAllRegion();
  }, []);
  // Declaration of React Hooks End Here

  // This function is used to handle the change in company selection
const onChangeComp = (currentNode, selectedNodes) => {
  // Set the dependency for company
  setDependency("company");
  // Set the selected companies
  setSelectedCompanies(selectedNodes);
  // Iterate through the company list
  props.companyListCRU.forEach((el) => {
    // If the current node is checked
    if (currentNode.checked === true) {
      // Check if the current node value matches with the element value
      if (currentNode.value === el.value) {
        // Set the checked property of the element to true
        el.checked = true;
      } else if (currentNode.parentValue === el.value) {
        // Iterate through the children of the element
        el.children.forEach((item) => {
          // Check if the current node value matches with the item value
          if (currentNode.value === item.value) {
            // Set the checked property of the item to true
            item.checked = true;
          }
        });
      }
    } else if (currentNode.checked === false) {
      // Check if the current node value matches with the element value
      if (currentNode.value === el.value) {
        // Set the checked property of the element to false
        el.checked = false;
      } else if (currentNode.parentValue === el.value) {
        // Iterate through the children of the element
        el.children.forEach((item) => {
          // Check if the current node value matches with the item value
          if (currentNode.value === item.value) {
            // Set the checked property of the item to false
            item.checked = false;
          }
        });
      }
    }
  });

  // Get the first parent from the company list
  const firstParent = props.companyListCRU
    .map((el) => {
      let data = {
        label: el.label,
        value: el.value,
        // Filter the children based on the checked property
        children: el.children.filter((item) => item["checked"] === true),
      };
      return data;
    })
    // Filter the elements based on the length of the children
    .filter((el) => el.children.length > 0);
  // Set the api data
  setApiData({
    ...apiData,
    companies:
      // Check if the length of the filtered elements is greater than 0
      props.companyListCRU.filter((el) => el["checked"] === true).length > 0
        ? props.companyListCRU.filter((el) => el["checked"] === true)
        : firstParent,
  });
};

// This function is used to set the dependency of location
const onChangeLoc = (currentNode, selectedNodes) => {
  setDependency("location");
  
  // If no nodes are selected, set all checked values to false
  if (selectedNodes.length === 0) {
    props.locationCRU.forEach((el) => {
      el["checked"] = false;
      el.children.forEach((it) => {
        it["checked"] = false;
        it.children.forEach((kt) => {
          kt["checked"] = false;
        });
      });
    });
  }

  // If current node is checked, set the corresponding value to true
  if (currentNode.checked === true) {
    if (currentNode.grantParentValue) {
      props.locationCRU.forEach((el) => {
        el.children.forEach((first) => {
          first.children.forEach((second) => {
            if (currentNode.value === second.value) {
              second.checked = true;
              filters.countryIds.push(currentNode.value);
            }
          });
        });
      });
    } else if (currentNode.parentValue) {
      props.locationCRU.forEach((el) => {
        el.children.forEach((first) => {
          if (currentNode.value === first.value) {
            first.checked = true;
          }
        });
      });
    } else if (currentNode.value) {
      props.locationCRU.forEach((el) => {
        if (currentNode.value === el.value) {
          el.checked = true;
        }
      });
    }
  } 
  // If current node is unchecked, set the corresponding value to false
  else if (currentNode.checked === false) {
    if (
      currentNode.value &&
      !currentNode.parentValue &&
      !currentNode.grantParentValue
    ) {
      props.locationCRU.forEach((el) => {
        if (el.value === currentNode.value) {
          el.checked = false;
        }
      });
    }
    if (
      currentNode.value &&
      currentNode.parentValue &&
      !currentNode.grantParentValue
    ) {
      props.locationCRU.forEach((el) => {
        el.children.forEach((first) => {
          if (currentNode.value === first.value) {
            first.checked = false;
          }
        });
      });
    } else {
      props.locationCRU.forEach((el) => {
        if (
          el.children.map((it) => it.parentValue)[0] === currentNode.value
        ) {
          el.children.forEach((item) => {
            if (item.parentValue === currentNode.value) {
              item.checked = false;
            }
          });
        }
      });
    }

    if (
      currentNode.value &&
      currentNode.parentValue &&
      currentNode.grantParentValue
    ) {
      props.locationCRU.forEach((el) => {
        el.children.forEach((first) => {
          first.children.forEach((second) => {
            if (currentNode.value === second.value) {
              second.checked = false;
            }
          });
        });
      });
    }
  }

  // Set the data for apiData
  const firstParent = props.locationCRU
    .map((el) => {
      let data = {
        label: el.label,
        value: el.value,
        children: el.children.filter((item) => item["checked"] === true),
      };
      return data;
    })
    .filter((el) => el.children.length > 0);

  const grandParent = props.locationCRU.map((el) => {
    let data = {
      label: el.label,
      value: el.value,
      children: el.children.map((item) => {
        let items = {
          label: item.label,
          value: item.value,
          children: item.children.filter((item) => item["checked"] === true),
        };

        return items;
      }),
    };

    return data;
  });

  // Function to check if any grandparent has children
  const finalChecker = () => {
    let data = [];
    for (let i = 0; i < grandParent.length; i++) {
      for (let j = 0; j < grandParent[i].children.length; j++) {
        if (grandParent[i].children[j].children.length > 0) {
          data.push(grandParent[i]);
        }
      }
    }
    return data;
  };
  setApiData({
    ...apiData,
    location: {
      regions:
        props.locationCRU.filter((el) => el["checked"] === true).length > 0
          ? props.locationCRU.filter((el) => el["checked"] === true)
          : firstParent.length > 0
          ? firstParent
          : finalChecker(),
    },
  });
};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Filter Form */}
      <Grid container spacing={4}>
        {/*  <Grid item xs={12}>
          <Container
            data={props.companyListCRU}
            onChange={onChangeComp}
            showPartiallySelected={true}
            texts={{ placeholder: "Company" }}
          />
        </Grid> */}

        {/*  <Grid item xs={12}>
          <Container
            data={props.locationCRU}
            onChange={onChangeLoc}
            showPartiallySelected={true}
            texts={{ placeholder: "Location" }}
          />
        </Grid> */}

        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterCRUReviewReports.commodities}
            items={
              props.dataForFilter.commodity ? props.dataForFilter.commodity : []
            }
            label="Commodities"
            selectAllLabel={{ commodityName: "Select all" }}
            getOptionLabel={(option) => option.commodityName}
            getOptionSelected={(option, anotherOption) =>
              option.commodityName === anotherOption.commodityName
            }
            role="commodities"
            key="commodities"
          />
        </Grid>

        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterCRUReviewReports.assignedto}
            items={assignedto}
            label="Assigned To"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            role="assignedto"
            key="assignedto"
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            labelTitle="From Date"
            variant="standard"
            defaultDate={fromDate|| localStorage.getItem("selectedCRUFromDate")}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date =
                selectedDate.getFullYear() +
                "-" +
                ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + selectedDate.getDate()).slice(-2);
              setFromDate(date);
              localStorage.setItem("selectedCRUFromDate", date);
            }}
            past={false}
            key={keyFromoDate}
            clearable={true}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            labelTitle="To Date"
            variant="standard"
            past={false}
            minDates={fromDate || localStorage.getItem("selectedCRUFromDate")}
            defaultDate={toDate || localStorage.getItem("selectedCRUToDate")}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date =
                selectedDate.getFullYear() +
                "-" +
                ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + selectedDate.getDate()).slice(-2);
              setToDate(date);
              localStorage.setItem("selectedCRUToDate", date);
            }}
            key={keyToDate}
            clearable={true}
          />
        </Grid>
        {console.log("Dashboard Reports", filterCRUReviewReports)}
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterCRUReviewReports.cruqcuser}
            items={cruqc}
            label="CRU Review"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            role="cruqcuser"
            key="cruqcuser"
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            labelTitle="CRU QC Completion Date"
            variant="standard"
            defaultDate={crureviewDate|| localStorage.getItem("selectedCRUQCDate")}           
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date =
                selectedDate.getFullYear() +
                "-" +
                ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + selectedDate.getDate()).slice(-2);
              setCruReviewDate(date);
              localStorage.setItem("selectedCRUQCDate", date);
            }}
            past={false}
            key={keycrureviewDate}
            clearable={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn"
                color="accent5"
                onClick={searchFilter}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn reset-color"
                color="accent3"
                onClick={clearFieldHanlder}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterForm;
