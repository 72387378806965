// Default React, Router and Services Components
import React, { useState, useEffect } from "react";
import {
  Box, Button, Collapse, FormControlLabel, Grid, IconButton, Paper, Switch, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, TextField, Tooltip, Typography
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
// Importing Custom Components
import AlertDialog from "../../../UIComponent/AlertDialog";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles
import useStyles from "./ManageRoles.styles";

// Import Fluent UI Icons
import { EditIcon, DeleteIcon, CircleAdditionIcon } from "@fluentui/react-icons-mdl2";

const AddNewRights = (
  { editList, list, mode, getUpdatedList, handleChangeIcon, editGetUpdatedList, }
) => {
  const initialState = {
    allowDelete: false, allowEdit: false, allowView: false, id: 0,
    name: "", roleConfigurationId: 0,
  };
  const [dataRight, setDataRight] = useState({
    allowDelete: false, allowEdit: false, allowView: false, id: 0,
    name: "", roleConfigurationId: 0,
  });

// This function handles changes to the dataRight object
const handleChange = (e, key) => {
  // Create a copy of the dataRight object
  const val = { ...dataRight };
  // Update the value of the specified key with the new value
  val[key] = e;
  // Set the updated dataRight object
  setDataRight(val);
};

// This function handles the addition of 1
const handleAdd1 = () => {
  // Call the handleAdd() function
  handleAdd();
  // Check if the name length is greater than 0
  if (dataRight.name.length > 0) { 
      // Call the handleChangeIcon() function
      handleChangeIcon(); 
  }
};
// This function handles the addition of data to either the editList or list array depending on the mode
const handleAdd = () => {
  // If the mode is set to Edit and the name field has a value, add the dataRight object to the editList array
  if (mode === "Edit" && dataRight.name.length > 0) {
    const editNewList = editList.concat({ ...dataRight });
    //console.log(editNewList, "editNewList");
    editGetUpdatedList(editNewList);
  } 
  // Else if the mode is set to Add and the name field has a value, add the dataRight object to the list array
  else if (mode === "Add" && dataRight.name.length > 0) {
    const newList = list.concat({ ...dataRight });
    //console.log(newList, "newList");
    getUpdatedList(newList);
  }

  // Reset the dataRight object to its initial state
  setDataRight(initialState);
  // If the name field has a value, display a success message
  if (dataRight.name.length > 0) {
    toasterSuccess("Access right added successfully");
  } 
  // Else display an error message
  else {
    toasterError("Please enter the required fields ");
  }
};
  const classes = useStyles();

  // This function handles the resetting of data to its initial state
const handleReset = () => { 
  // Set the data to its initial state
  setDataRight(initialState); 
};
  return (
    // Add Access Rights Form
    <Paper className={classes.addAccessRights} elevation={0}>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <TextField required value={dataRight.name} id="standard-basic"
            label="Access Right Name" variant="standard" fullWidth autoFocus
            onChange={(e) => handleChange(e.target.value, "name")}
          />
        </Grid>

        <Grid item xs={7}>
          <FormControlLabel className={classes.switchControls} label="View" labelPlacement="top"
            control={
              <Switch color="secondary" checked={dataRight.allowView}
                onChange={(e) => handleChange(e.target.checked, "allowView")} />
            }
          />
          <FormControlLabel className={classes.switchControls} label="Edit" labelPlacement="top"
            control={
              <Switch color="secondary" checked={dataRight.allowEdit}
                onChange={(e) => handleChange(e.target.checked, "allowEdit")} />
            }
          />
          <FormControlLabel className={classes.switchControls} label="Delete" labelPlacement="top"
            control={
              <Switch color="secondary" checked={dataRight.allowDelete}
                onChange={(e) => handleChange(e.target.checked, "allowDelete")} />
            }
          />
        </Grid>
        <Grid item xs={5} sx={{ mt: 1 }}>
          <Grid container spacing={2} justifyContent="right">
            <Grid item xs={'auto'}>
              <Button variant="contained" onClick={handleAdd1}>Add</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button variant="contained" color="accent3" onClick={handleReset}>Reset</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const AddRole = (props) => {
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const mode = props.crudState;
  const [checked, setChecked] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [roles, setRoles] = useState([]);
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [finalData, setFinalData] = React.useState({});
  const [openConfirmation, setConfirmation] = useState(false);
  const [apiAccessRight, setApiAccessRight] = useState({});
  const [show, setShow] = useState(-1);
  const [tableName, setTableName] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const [isDeleted, setIsDeleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    roleId: 0, roleName: "", roleDescription: "", active: true, isDefault: true,
    accessRightsDto: apiAccessRight.accessRightsDto, isEdited: true, isDeleted: true,
  });
  const [editView, setEditView] = useState({});
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  useEffect(() => {
    if (Object.values(props.formData).length > 0) { setEditView(props.formData); }
  }, [props.formData]);

// This useEffect hook is used to make an API call to get the list of access rights
useEffect(() => {
  // Making an API call using axios and passing in the baseUrl from the environment variables and the Authorization token from sessionStorage
  axios.get(process.env.React_App_baseUrl + "account/GetAccessRightsList", {
    headers: {
      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
  })
  // If the API call is successful, set the apiAccessRight state with the response data
  .then((res) => {
    setApiAccessRight(res.data);
  });
}, []);
  // Declaration of React Hooks End Here


  // Function for Access Rights
// Function to handle the click open event
const handleClickOpen = (item) => {
  // Set open delete to true
  setOpenDelete(true);
  // Set deleted item to the item passed in
  setDeletedItem(item);
  // If the item id is greater than 0, call the isDeletedApi function with the item id
  if (item.id > 0) { isDeletedApi(item.id); }
  // Otherwise, set isDeleted to true
  else { setIsDeleted(true); }
};
  // This function sets the isDeleted state to false
const handleClose = () => { 
  setIsDeleted(false); 
};
// This function checks if the access right is associated with other pages
const isDeletedApi = (id) => {
  // Make a GET request to the API endpoint
  axios.get(process.env.React_App_baseUrl + `account/IsAccessRightUsed?accessId=${id}`, {
    headers: {
      // Set the Authorization header with the token from sessionStorage
      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
  })
    .then((res) => {
      // If the response data is true, show an error message
      if (res.data) {
        toasterError("Access right can't be deleted. It is associated with other pages");
      }
      // Set the isDeleted state to the opposite of the response data
      setIsDeleted(!res.data);
    });
};

// This function is used to update the role details
const onUpdateApi = () => {
  // Set loading to true while making API call
  setLoading(true);
  // Get the roleName from editView
  const { roleName } = editView;
  // Check if roleName is greater than 0
  if (roleName.length > 0) {
    // Make a post request to UpdateRole endpoint with Authorization token
    axios.post(process.env.React_App_baseUrl + "account/UpdateRole", { ...editView, }, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then((res) => {
        // Set the response data to editView
        setEditView(res.data);
        // If response status is 200, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("Role details updated successfully!");
          props.hideSideDrawer();
          setLoading(false);
        }
      })
      .catch((err) => {
        // Handle errors
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  } else {
    // Show error message if required fields are not entered
    toasterError("Please enter the required fields");
  }
};

// This function is used to save the data to the API
const onSaveApi = (data) => {
  // Set loading to true while the data is being saved
  setLoading(true);
  // Get the roleName from the data
  const { roleName } = data;
  // Check if the roleName has a length greater than 0
  if (roleName.length > 0) {
    // Make an axios post request to the API with the data and authorization token
    axios.post(process.env.React_App_baseUrl + "account/CreateRole", { ...data }, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then((res) => {
        // If the response status is 200, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("Role details added successfully!");
          props.hideSideDrawer();
          setLoading(false);
        }
      })
      .catch((err) => {
        // Call error handling function to handle errors
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  } else {
    // Show error message if required fields are not entered
    toasterError("Please enter the required fields");
  }
};

// Function to handle change of icon
const handleChangeIcon = () => { 
  setChecked((prev) => !prev); 
  setIsActive((current) => !current); 
};

// Function to handle closing of alert dialog
const handleADClose = () => { 
  setOpenSaveConfirmation(false); 
};

// Function to open alert dialog
const openAlertDialog = () => { 
  setConfirmation(true); 
};
// Function to update the list
const getUpdatedList = (list) => {
  // Create a copy of the apiAccessRight object
  let prevState = { ...apiAccessRight };
  // Update the accessRightsDto property with the new list
  prevState.accessRightsDto = list;
  // Set the updated object as the new apiAccessRight
  setApiAccessRight(prevState);
};
// Function to update the edit view
const editGetUpdatedList = (editList) => {
  // Create a copy of the editView object
  let prevState = { ...editView };
  // Update the accessRightsDto property with the new list
  prevState.accessRightsDto = editList;
  // Set the updated object as the new editView
  setEditView(prevState);
};
// Function to handle changes in userData
const handleChange1 = (e, key) => {
  // Create a copy of the userData object
  const val = { ...userData };
  // Update the specified key with the new value
  val[key] = e;
  // Set the updated object as the new userData
  setUserData(val);
};

// This function handles changes to the apiAccessRight object
const handleChange2 = (e, key, id) => {
  // If the key is "isEdited", set the show state to the given id
  if (key === "isEdited") { setShow(id); }
  // If the key is "isDeleted", display a success message
  if (key === "isDeleted") { toasterSuccess("Access right deleted successfully"); }
  // Create a copy of the apiAccessRight object
  const val = { ...apiAccessRight };
  // Find the role with the given id
  const role = val.accessRightsDto.find((r) => r.id === id);
  // Set the value of the given key to the given e
  role[key] = e;
  // Push the updated role to the accessRightsDto array
  val.accessRightsDto.push(role);
  // Create a new Set from the accessRightsDto array and assign it back to the array
  const item = [...new Set(val.accessRightsDto)];
  val.accessRightsDto = item;
  // Update the apiAccessRight state with the updated object
  setApiAccessRight({ ...val });
  // Set the isDeleted state to false
  setIsDeleted(false);
};
// This function handles the change of the access rights
const handleChange3 = (e, key, id) => {
  // If the key is "isEdited", set the show state to the given id
  if (key === "isEdited") { setShow(id); }
  // If the key is "isDeleted", display a success message
  if (key === "isDeleted") { toasterSuccess("Access right deleted successfully"); }
  // Create a copy of the editView state
  const val = { ...editView };
  // Find the role with the given id
  const role = val.accessRightsDto.find((r) => r.id === id);
  // Set the value of the key to the given e
  role[key] = e;
  // Push the role to the accessRightsDto array
  val.accessRightsDto.push(role);
  // Create a new Set from the accessRightsDto array
  const item = [...new Set(val.accessRightsDto)];
  // Set the accessRightsDto array to the new Set
  val.accessRightsDto = item;
  // Update the editView state
  setEditView({ ...val });
  // Set the isDeleted state to false
  setIsDeleted(false);
};

// Function to handle saving of user data
const handleSave = () => {
  // Create a copy of the userData object
  const data = { ...userData };
  // Add access rights to the data object
  data.accessRightsDto = apiAccessRight.accessRightsDto;
  // Set the userData to the new data object
  setUserData(data);
  // Call the onSaveApi function with the new data object
  onSaveApi(data);
};

  const handUpdate = () => { const data = { ...editView }; };
  const onChangeHandler = (e, id) => { setTableName(e.target.value); };
  const handleDelete = (id, index) => {
    const value = { ...apiAccessRight };
    const arr = value.accessRightsDto.filter((r) => r.id !== id);
    value.accessRightsDto = arr;
    setApiAccessRight(value);
  };

  const handleDelete1 = (id, index) => {
    const value = { ...editView };
    const arr = value.accessRightsDto.filter((r) => r.id !== id);
    value.accessRightsDto = arr;
    setEditView(value);
  };

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Role
        </Typography>
      </Box>

      {/* Add & Edit Currency Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField required variant="standard" fullWidth label="User Role"
            defaultValue={props.crudState === "Edit" ? editView.roleName : null}
            InputProps={{ shrink: true, }}
            value={props.crudState === "Add" ? null : editView.roleName}
            onChange={(e) => {
              if (props.crudState === "Edit") {
                setEditView({ ...editView, roleName: e.target.value, });
                return;
              }
              setUserData({ ...userData, roleName: e.target.value });
            }}
            placeholder="User Role"
            InputLabelProps={
              props.crudState === "Edit" ? { shrink: true } : null
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField variant="standard" fullWidth label="Description" placeholder="Description"
            multiline
            value={props.crudState === "Add" ? null : editView.roleDescription}
            maxRows={4}
            onChange={(e) => {
              if (props.crudState === "Edit") {
                setEditView({ ...editView, roleDescription: e.target.value, });
                return;
              }
              setUserData({ ...userData, roleDescription: e.target.value });
            }}
            InputLabelProps={props.crudState === "Edit" ? { shrink: true } : null}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={10}>
              <Typography className={classes.subTitle}>Access Rights</Typography>
            </Grid>
            <Grid item xs={2} textAlign="right">
              <Tooltip title={checked ? "Close" : "Add New"} placement="top" arrow>
                <IconButton aria-label="edit" color="primary" onClick={handleChangeIcon}>
                  <CircleAdditionIcon style={{ fontSize: 24 }} aria-label="Add"
                    className={checked ? classes.closeStyle : ""} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Collapse in={checked}>
                  <AddNewRights
                    handleChangeIcon={handleChangeIcon}
                    mode={mode}
                    list={apiAccessRight.accessRightsDto}
                    editList={editView.accessRightsDto}
                    getUpdatedList={getUpdatedList}
                    editGetUpdatedList={editGetUpdatedList}
                  />
                </Collapse>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper} className={classes.addBorder} elevation={0} sx={{ minWidth: 375, maxHeight: "calc(100vh - 375px)", mt: 1 }}>
                <Table aria-label="simple table" stickyHeader >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader} width="30%"></TableCell>
                      <TableCell align="center" width="15%" className={classes.tableHeader}>View</TableCell>
                      <TableCell align="center" width="15%" className={classes.tableHeader}>Edit</TableCell>
                      <TableCell align="center" width="15%" className={classes.tableHeader}>Delete</TableCell>
                      <TableCell align="center" width="30%" className={classes.tableHeader} style={{ minWidth: 110 }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.crudState === "Edit" ? (
                      <>
                        {Object.keys(editView).length > 0 &&
                          editView.accessRightsDto.map(
                            (item, index) =>
                              !item.isDeleted && (
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0, }, }} hover>
                                  <TableCell component="th" scope="row" className={classes.tableCell}>
                                    {item.id !== show ? (
                                      <Typography variant="subtitle2" className={classes.subTitle2}>
                                        {item.name}
                                      </Typography>) :
                                      (<TextField variant="standard" value={item.name}
                                        onChange={(e) => handleChange3(e.target.value, "name", item.id)}>
                                      </TextField>
                                      )}
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    <Switch color="secondary" checked={item.allowView}
                                      onChange={(e) => handleChange3(e.target.checked, "allowView", item.id)}
                                    />
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    <Switch color="secondary" checked={item.allowEdit}
                                      onChange={(e) => handleChange3(e.target.checked, "allowEdit", item.id)}
                                    />
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    <Switch color="secondary" checked={item.allowDelete}
                                      onChange={(e) => handleChange3(e.target.checked, "allowDelete", item.id)}
                                    />
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    <Grid container spacing={1} justifyContent="center">
                                      <Grid item xs={'auto'}>
                                        <Tooltip title="Edit" placement="top" arrow>
                                          <IconButton aria-label="edit" color="primary">
                                            <EditIcon style={{ fontSize: "20px" }}
                                              onClick={() => handleChange3(true, "isEdited", item.id)}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                      <Grid item xs={'auto'}>
                                        <Tooltip title="Delete" placement="top" arrow>
                                          <IconButton aria-label="Delete" color="secondary">
                                            <DeleteIcon onClick={() => handleClickOpen(item)}
                                              style={{ fontSize: "20px", color: "red" }} />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              )
                          )}
                        {isDeleted ? (
                          <AlertDialog
                            open={isDeleted}
                            title={"Confirmation"}
                            maxWidth={"sm"}
                            description={`Are you sure you want to delete ${deletedItem.name}`}
                            action={
                              <Grid container alignItems="center" justifyContent="right" spacing={2} className={classes.alertDialogbtn}>
                                <Grid item xs={'auto'}>
                                  <Button onClick={handleClose}>No</Button>
                                </Grid>
                                <Grid item xs={'auto'}>
                                  <Button onClick={() => handleChange3(true, "isDeleted", deletedItem.id)} color="secondary">
                                    Yes
                                  </Button>
                                </Grid>
                              </Grid>
                            }
                          />
                        ) : null}
                      </>
                    ) : (
                      <>
                        {Object.keys(apiAccessRight).length > 0 &&
                          apiAccessRight.accessRightsDto.map(
                            (item, index) =>
                              !item.isDeleted && (
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0, }, }}>
                                  <TableCell component="th" scope="row" className={classes.tableCell}>
                                    {item.id !== show ? (<Typography variant="subtitle2">{item.name}</Typography>) : (
                                      <TextField variant="standard" value={item.name}
                                        onChange={(e) => handleChange2(e.target.value, "name", item.id)}>
                                      </TextField>
                                    )}
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    <Switch color="secondary" checked={item.allowView}
                                      onChange={(e) => handleChange2(e.target.checked, "allowView", item.id)} />
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    <Switch color="secondary" checked={item.allowEdit}
                                      onChange={(e) => handleChange2(e.target.checked, "allowEdit", item.id)} />
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    <Switch color="secondary" checked={item.allowDelete}
                                      onChange={(e) => handleChange2(e.target.checked, "allowDelete", item.id)} />
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell}>
                                    <Tooltip title="Edit" placement="top" arrow>
                                      <IconButton aria-label="edit" color="primary">
                                        <EditIcon style={{ fontSize: "20px" }}
                                          onClick={() => handleChange2(true, "isEdited", item.id)} />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete" placement="top" arrow>
                                      <IconButton aria-label="Delete" color="secondary">
                                        <DeleteIcon onClick={() => handleClickOpen(item)}
                                          style={{ fontSize: "20px", color: "red" }} />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              )
                          )}
                        {isDeleted ? (
                          <AlertDialog
                            open={isDeleted}
                            title={"Confirmation"}
                            maxWidth={"sm"}
                            description={`Are you sure you want to delete ${deletedItem.name}`}
                            action={
                              <Grid container alignItems="center" justifyContent="right" spacing={2} className={classes.alertDialogbtn}>
                                <Grid item xs={"auto"}>
                                  <Button onClick={handleClose}>No</Button>
                                </Grid>
                                <Grid item xs={"auto"}>
                                  <Button onClick={() => handleChange2(true, "isDeleted", deletedItem.id)}
                                    color="secondary">Yes
                                  </Button>
                                </Grid>
                              </Grid>
                            }
                          />
                        ) : null}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left" sx={{ mt: 1 }}>
              <Grid item xs={'auto'}>
                {/* <Button variant="contained" className="rounded-border" color="accent5"
                  onClick={props.crudState === "Edit" ? onUpdateApi : handleSave}>
                  {props.crudState === "Edit" ? "Update" : "Save"}
                </Button> */}
                <LoadingButton
                  buttonName={props.crudState === "Edit" ? "Update" : "Save"}
                  clickHandler={props.crudState === "Edit" ? onUpdateApi : handleSave}
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant="contained" className="rounded-border" color="accent3"
                  onClick={() => props.setState({ ...props.state, right: false })}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>

      </Grid>
      {/* Alert Dialog - Confirmation for Delete */}
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openSaveConfirmation}
        onClose={handleADClose}
        description="There are existing job for your selection,would like to add?"
        action={
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>No</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button color="secondary">Yes</Button>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default AddRole;
