// Default React, Router and Services Components
import React, { useContext, useState } from "react";
import { Box, Breadcrumbs, Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
// Importing Custom Components
import BaseLayout from "../../../../UIComponent/BaseLayout";

// Importing useStyles
import useStyles from "../../CompanyOwnershipChanges.styles";

// Import Fluent UI Icons
import {
  ChevronRightSmallIcon,
  CodeEditIcon,
} from "@fluentui/react-icons-mdl2";
import DatePicker from "../../../../UIComponent/DatePicker";
import { useSelector } from "react-redux";
import { FilterContext } from "../../../DataCollection/DashboardReports/Context/Context";
import axios from "axios";
// import history from "../../../../Routes/History";
import { useHistory } from "react-router-dom";

import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
import ExportToExcel from "../Excelexport";
import DataTable from "../../../../UIComponent/DataTable";
import SideDrawer from "../../../../UIComponent/Drawer";
import MeritQCFilter from "../MeritQCReport/MeritQCFilter";
const OwnershipChangesMeritQCReport = (props) => {
  const history = useHistory();
  const filterState = useSelector((state) => state.stateSlice);
  const { companyList, setCompanyList, locationQC, setLocationQC } =
    useContext(FilterContext);

  const [allRows, setAllRows] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [crudState, setCrudState] = React.useState(false);
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [editJobNumber, setEditJobNumber] = useState(null);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValue, setFilterValue] = useState(null);
  const [filterValues, setFilterValues] = useState();
  const [formData, setFormData] = useState([]);
  const [idforEdit, setIdForEdit] = useState(null);
  const [isAllSelected, setIsSelected] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [locationFilter, setLocationFilter] = useState([]);
  const [searchData, setSearchData] = useState({
    FromDate: null,
    ToDate: null,
  });
  const [menuPermission, setMenuPermission] = useState({
    allowView: true,
    allowEdit: true,
    allowDelete: true,
  });
  const [open, setOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [scroll, setScroll] = React.useState("paper");
  const [searchValue, setSearchValue] = useState("");
  const [selected, SetSelected] = useState([]);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [state, setState] = React.useState({ right: false });
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [submitValues, setSubmitValues] = useState(false);
  const [value, setValue] = useState({ startDate: "", endDate: "" });
  const classes = useStyles();
  const date = new Date().toLocaleDateString();
  const date1 = date.split("/").join("-");

  const gridcolumns = [
    { id: "priority", label: "Job Priority", minWidth: 200, width: "20%" },
    {
      id: "qcPassedWithNoErrors",
      type: "clickable",
      label: "QC Passed with no errors",
      minWidth: 140,
      width: "20%",
      //sorting: true,
      align: "center",
    },
    {
      id: "qcPassedWithMeritQCErrors",
      type: "clickable",
      label: "QC Passed With Merit QC Errors",
      minWidth: 150,
      width: "20%",
      //sorting: true,
      align: "center",
    },
    {
      id: "meritQCFailed",
      type: "clickable",
      label: "Merit QC Failed",
      minWidth: 110,
      width: "20%",
      //sorting: true,
      align: "center",
    },
    {
      id: "total",
      type: "clickable",
      label: "Total",
      minWidth: 110,
      width: "20%",
      //sorting: true,
      align: "center",
    },
  ];

  // search on enter press
  const handleKeyUp = (event) => {
    if (
      (event.keyCode === 8 || event.which === 8) &&
      event.target.nodeName !== "BUTTON"
    ) {
      if (searchValue.length === 0) {
      }
    }
    if (
      (event.keyCode === 13 || event.which === 13) &&
      event.target.nodeName !== "BUTTON"
    ) {
    }
  };

  // To Fill the grid
  React.useEffect(() => {
    GetMeritQCReport();
  }, [filterValue, submitValues]);
  React.useEffect(() => {
    getFilterData();
  }, [reload]);

  // For searching the grid
  const globalSearch = (value) => {
    setSearchValue(value);
  };

 
  // This function is used to get the MeritQC report
const GetMeritQCReport = async () => {
  // Set loading to true while data is being fetched
  setLoading(true);
  let response = await new Promise((resolve, reject) => {
    // Initialize searchvalue variable
    let searchvalue;
    // Check if value is not undefined
    if (value != undefined) {
      // Check if both FromDate and ToDate are not empty strings
      if (value.FromDate !== "" && value.ToDate !== "") {
        // Validate the value
        if (validation(value)) {
          // Assign values to searchvalue
          searchvalue = {
            FromDate: value.startDate,
            ToDate: value.endDate,
          };
        }
      }
    }

    // Create searchData object by merging filterState.meritQCFilter and searchvalue
    let searchData = { ...filterState.meritQCFilter, ...searchvalue };
    console.log("HSE", searchData);
    // Resolve the promise with an axios GET request
    resolve(
      axios.get(
        `${process.env.React_App_baseUrl}CompanyOwnershipReport/GetMeritQCReportCompanyOwnership`,
        {
          headers: {
            // Encode the searchData object as a query string
            filter: searchData
              ? encodeURIComponent(JSON.stringify(searchData))
              : null,
            // Set Authorization header with token from sessionStorage
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
    );
  });
  // Set allRows and tableData with response data
  setAllRows(response.data);
  setTableData(response.data);
  // Set loading to false after data has been fetched
  setLoading(false);
  return response;
}


  const GetMeritQCReportClear = async () => {
    setLoading(true);
    let response = await new Promise((resolve, reject) => {
      let searchvalue;
      if (value != undefined) {
        if (value.FromDate !== "" && value.ToDate !== "") {
          if (validation(value)) {
            searchvalue = {
              FromDate: value.startDate,
              ToDate: value.endDate,
            };
          }
        }
      }

      let searchData = { ...filterState.meritQCFilter, ...searchvalue };
      resolve(
        axios.get(
          `${process.env.React_App_baseUrl}CompanyOwnershipReport/GetMeritQCReportCompanyOwnership`,
          {
            headers: {
              filter: encodeURIComponent(JSON.stringify(null)),
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
          }
        )
      );
    });
    setAllRows(response.data);
    setTableData(response.data);
    setLoading(false);
    return response;
  };
  // Function for Search Clear
  const searchClear = () => {
    setSearchValue("");
    setPage(0);
    setReload(!reload);
  };

  const displayDrillReport = (event, row, column, index) => {
    var priorityId = 0;
    //critical
    if (index == 0) {
      priorityId = 4;
    }
    //high
    else if (index == 1) {
      priorityId = 1;
    }
    // low
    else if (index == 2) {
      priorityId = 3;
    }
    // medium
    else if (index == 3) {
      priorityId = 2;
    }
    //total
    else if (index === 4) {
      priorityId = 5;
    }
    history.push({
      pathname: "/app/ownership-changes/merit-qc-report-drill",
      state: {
        type: column,
        priorityId: priorityId,
        filterData: { ...filterState.meritQCFilter, ...value },
      },
    });
  };

  // Function for Validation
  const validation = (value) => {
    if (value.startDate >= value.endDate) {
      // toasterError("To Date Must Be Greater");
      return false;
    }
    return true;
  };

 // This function is used to get filter data from the API
const getFilterData = async () => {
  try {
    // Making a GET request to the API
    const response = await axios.get(
      `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`,
      {
        headers: {
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      }
    );
    // If there is no data in the response
    if (response.data.length === 0) {
      setDataForFilter([]);
    } else {
      // Setting the data for filter
      setDataForFilter(response.data);
      // Looping through the companies and setting checked to false
      response.data.companies.forEach((item, index) => {
        item.checked = false;
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
        }
      });
      // Looping through the locations and setting checked to false
      response.data.location.regions.forEach((item, index) => {
        item.checked = false;
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
          }
        }
      });
      // Setting the company list and location QC
      if (companyList.length === 0) {
        setCompanyList(response.data.companies);
      }
      if (locationQC.length === 0) {
        setLocationQC(response.data.location.regions);
      }

      // Setting the company filter and location filter
      setCompanyFilter(response.data.companies);
      setLocationFilter(response.data.location.regions);
    }
  } catch (err) {
    // Handling errors
    ErroHandling(err, function (res) {
      toasterError(res);
  });
}
};
  const fetchData = async () => {
    const { data } = await GetMeritQCReport();
    setTableData(data);
  };

  // Function for Getting Source Filter Data
  const searchDataDateRange = function () {
    axios
      .get(`${process.env.React_App_baseUrl}reports/GetMeritQCReport`, {
        headers: {
          filter: value ? encodeURIComponent(JSON.stringify(value)) : null,
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      })
      .then((resp) => {
        //setTableData(resp.data);
        setAllRows(resp.data);
      });
  };

  // Function for Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    setState({ ...state, right: open });
  };
  const [assetTypeDD, setAssetTypeDD] = useState([]);

// This function resets the tree filter
const resetTreeFilter = () => {
  // Declare company and location filters
  let comFilter = companyFilter;
  let loFilter = locationFilter;
  
  // Iterate through company filter and set checked to false
  comFilter.forEach((item, index) => {
    item.checked = false;
    if (item.children) {
      item.children.forEach((item, index) => {
        item.checked = false;
      });
    }
  });
  
  // Iterate through location filter and set checked to false
  loFilter.forEach((item, index) => {
    item.checked = false;
    if (item.children) {
      item.children.forEach((item, index) => {
        item.checked = false;
      });
      if (item.children) {
        item.children.forEach((item, index) => {
          item.checked = false;
        });
      }
    }
  });
 
  // Set company and location filters
  setCompanyFilter([...comFilter]);
  setLocationFilter([...loFilter]);
 
};

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} className="page-title">
          <h2>Merit QC Report</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/ownership-changes/source-management" }}
            >
              Home
            </Link>
            <Typography>Manage Company Ownership Changes</Typography>
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/ownership-changes/status-report" }}
            >
              Dashboard and Reports
            </Link>
            <Typography color="text.primary">Merit QC Report</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* BaseLayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            {/*  <Box sx={{ pt: 11 }}>
              <center>
                <EmptyState
                  icon={
                    <CodeEditIcon
                      style={{ color: "#db9400", fontSize: "70px", marginBottom: "10px" }}
                    />
                  }
                  title="Under Construction!"
                />
              </center>
            </Box> */}
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  alignItems="flex-end"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={12} xl={8}>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="flex-start"
                      alignItems="flex-end"
                    >
                      {/* <Grid item xs={"auto"}>
                        <DatePicker
                          labelTitle="From Date"
                          variant="standard"
                          past={false}
                          onChangeDate={(e) => {
                            let selectedDate = new Date(e);
                            let date =
                              selectedDate.getFullYear() +
                              "-" +
                              ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + selectedDate.getDate()).slice(-2);
                            setValue({ ...value, FromDate: date });
                          }}
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <DatePicker
                          labelTitle="To Date"
                          variant="standard"
                          past={false}
                          minDates={value.startDate}
                          onChangeDate={(e) => {
                            let selectedDate = new Date(e);
                            let date =
                              selectedDate.getFullYear() +
                              "-" +
                              ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + selectedDate.getDate()).slice(-2);
                            setValue({ ...value, ToDate: date });
                          }}
                        />
                      </Grid> */}
                      {/*  <Grid item xs={"auto"}>
                        <Button
                          onClick={searchDataDateRange}
                          variant="contained"
                          color="secondary"
                        >
                          Search
                        </Button>
                      </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} xl={4} textAlign="right">
                    <Grid
                      container
                      spacing={2}
                      justifyContent="flex-end"
                      alignItems="flex-end"
                    >
                      <Grid item xs={"auto"}>
                        <Typography variant="subtitle2">
                          <ExportToExcel
                            apiData={tableData}
                            fileName={`MeritQCStatusReport ${date1}`}
                            meritqc
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={"auto"}>
                        <Button
                          onClick={toggleDrawer(true)}
                          variant="contained"
                          color="secondary"
                        >
                          Filter
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Merit QC Report Table */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allRows ? allRows : []}
                  columns={gridcolumns}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={setSorting}
                  hasLink={displayDrillReport}
                  isPagination={false}
                  rowsPerPageVisible
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
      <SideDrawer
        formComp={
          <MeritQCFilter
            dataForFilter={dataForFilter}
            filterFinalData={filterFinalData}
            companyFilter={companyFilter}
            locationFilter={locationFilter}
            setFilterValues={setFilterValues}
            setFilterValue={setFilterValue}
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            userId={null}
            setLocationFilter={setLocationFilter}
            clear={filterState.meritQCFilter}
            hideSideDrawer={() => {
              setState({ right: false });
            }}
            reload={reload}
            setReload={setReload}
            setCompanyFilter={setCompanyFilter}
            assetTypeDD={assetTypeDD}
            setFilterFinalData={setFilterFinalData}
            resetTreeFilter={resetTreeFilter}
            setDataForFilter={setDataForFilter}
            getFilterData={() => {
              GetMeritQCReport();
            }}
            setSubmitValues={setSubmitValues}
            submitValues={submitValues}
            companyList={companyList}
            setCompanyList={setCompanyList}
            locationQC={locationQC}
            setLocationQC={setLocationQC}
          />
        }
        state={state}
        setState={setState}
        reload={reload}
        setReload={setReload}
        setFilterValue={setFilterValue}
      />
    </Box>
  );
};

export default OwnershipChangesMeritQCReport;
