// Default React, Router and Services Components
import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import axios from "axios";
import { Link } from 'react-router-dom';
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import { toasterError } from "../../../UIComponent/Toaster";
import BaseLayout from "../../../UIComponent/BaseLayout";
import DatePicker from "../../../UIComponent/DatePicker";
import DuplicateJobData from "./DuplicateJobsData";
import MultiSelect from "../../../UIComponent/MultiSelectDropdown";

// Importing useStyles
import useStyles from "./jobManagement.styles";

// Import Fluent UI Icons
import { ChevronRightSmallIcon } from "@fluentui/react-icons-mdl2";

const DataCollectionDuplicateJob = (props) => {
    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    const [value, setValue] = useState({ startDate: '', endDate: '', });
    const [companies, setCompanies] = useState([]);
    const [selectedCompnies, setSelectedCompanies] = useState([]);
    const [createManual, setCreateManual] = React.useState(false);
    const onChangeHandlerCompany = (key, value, reason) => { setSelectedCompanies(key); }
    // Declaration of States and Component Variables End Here

    // Declaration of React Hooks Start Here
    useEffect(() => { GetCompanies(); }, []);
    // Declaration of React Hooks End Here

    // Function for Getting Companies
    const GetCompanies = () => {
        axios.get(
            process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
            headers: {
                isNewSource: false,
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
            },
        }
        ).then(function (response) {
            setCompanies(response.data);
        })
            .catch(function (error) {
                {
                    ErroHandling(error, function (res) { toasterError(res) })
                }
            });
    }

    return (
        // Page Layout Start Here
        <Box>
            <Grid container maxWidth justifyContent="right" alignItems="center">

                {/* Breadcrumbs Menu Start Here */}
                <Grid item xs={12} sm={12} md={6} className="page-title">
                    <h2>Duplicate Jobs</h2>
                    <Breadcrumbs
                        separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
                        <Typography color="text.primary">Manage Company Data Collections</Typography>
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/create-jobs" }}>Job Management</Link>
                        <Typography color="text.primary">Duplicate Jobs</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* Breadcrumbs Menu End Here */}

                {/* Main Buttons Start Here */}
                <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                        <Grid item xs={"auto"}>

                        </Grid>
                    </Grid>
                </Grid>
                {/* Main Buttons End Here */}

                <Grid item xs={12}>
                    <BaseLayout>
                        <Box className={classes.addBorder}>
                            <Grid item xs={12} className={classes.tableTopPart}>
                                <Grid container alignItems="self-end" justifyContent="left" spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={5}>
                                        <MultiSelect
                                            onChange={(key, value, reason) => onChangeHandlerCompany(key, value, reason)}
                                            value="companyId"
                                            items={companies}
                                            label="Company"
                                            selectAllLabel={{ label: "Select all" }}
                                            getOptionLabel={(option) => option}
                                            dropLable="companyName"
                                            role="companyName"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={7} md={7} lg={7} xl={4}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Grid container spacing={1} justifyContent="self-start" alignItems="self-end">
                                                <Grid item xs={6} md={6}>
                                                    <DatePicker
                                                        labelTitle="From"
                                                        variant="standard"
                                                        past={false}
                                                        onChangeDate={(e) => {
                                                            let selectedDate = new Date(e);
                                                            let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
                                                            setValue({ ...value, startDate: date })
                                                        }
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <DatePicker
                                                        labelTitle="To"
                                                        variant="standard"
                                                        past={false}
                                                        minDates={value.startDate}
                                                        onChangeDate={(e) => {
                                                            let selectedDate = new Date(e);
                                                            let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
                                                            setValue({ ...value, endDate: date })
                                                        }
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <DuplicateJobData
                                createManual={createManual}
                                startDate={value?.startDate?.split('-').reverse().join('-')}
                                endDate={value?.endDate?.split('-').reverse().join('-')}
                                selectedCompnies={selectedCompnies}
                            />
                        </Box>
                    </BaseLayout>
                </Grid>
            </Grid>
        </Box>
    );
}

export default DataCollectionDuplicateJob;