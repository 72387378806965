// Default React, Router and Services Components
import React, { useState } from "react";
import {
    AppBar, Avatar, Badge, Box, Container, Divider, Grid, IconButton, ListItemIcon,
    ListItemText, Menu, MenuItem, Toolbar, Tooltip, Typography
} from '@mui/material';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearSession, getSession } from "../../../Services/SessionService";

// Importing Custom Components
import MyProfile from "./MyProfile";
import Notifications from "./Notifications";
import SideDrawer from "../../../UIComponent/Drawer";

// Import Fluent UI Icons
import { ChevronDownMedIcon, ContactIcon, GlobalNavButtonIcon, PermissionsIcon, RingerIcon, SignOutIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import './Header.scss';
import useStyles from "./Header.styles";

const Header = (props) => {
    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    const useHistoy = useHistory();
    const [lang, setLang] = useState("en");
    const [state, setState] = React.useState({ right: false });
    const { t, i18n } = useTranslation();
    const languages = [{ key: "en", name: "English" }, { key: "es", name: "Spanish" }];
    const handleSideBar = () => { props.setOpen(open => !open); }
    const changeLanguage = (e) => { setLang(e); i18n.changeLanguage(e); }
    const logout = () => { clearSession(); useHistoy.push('/login'); }
    const langMenu = (
        <Menu open={false}>
            {                
                languages.map(el =>
                    <MenuItem key={el.key} onClick={() => changeLanguage(el.key)} className={
                        lang === el.key ? "menuSelected" : ""
                    }>{el.name}
                    </MenuItem>
                )
            }
        </Menu>
    );
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleOpenNavMenu = (event) => { setAnchorElNav(event.currentTarget); };
    const handleOpenUserMenu = (event) => { setAnchorElUser(event.currentTarget); };
    const handleCloseNavMenu = () => { setAnchorElNav(null); };
    const handleCloseUserMenu = () => { setAnchorElUser(null); };
    const [myProfileState, setMyProfileState] = React.useState({ right: false });
    const [notificationState, setNotificationState] = React.useState({ right: false });
    const toggleDrawerMyProfile = (open, mode, e) => (event, item) => {
        setMyProfileState({ ...myProfileState, right: open });
        setAnchorElUser(false);
    };
    const toggleDrawerNotification = (open, mode, e) => (event, item) => {
        setNotificationState({ ...notificationState, right: open });
        setAnchorElUser(false);
    };
    let name = localStorage.getItem("username") ? localStorage.getItem("username") : null
    let mail = localStorage.getItem("email") ? localStorage.getItem("email") : null
    let lName = localStorage.getItem("lastName") ? localStorage.getItem("lastName") : null
    if(localStorage.getItem("username")){
        name = localStorage.getItem("username");
    }
    if(localStorage.getItem("email")){
        mail = localStorage.getItem("email");
    }
    if(localStorage.getItem("lastName")){
        lName = localStorage.getItem("lastName");
    }


    
    // Declaration of States and Component Variables End Here

    return <>
        <div className="main-header">
            <AppBar position="static" sx={{ boxShadow: 0 }} elevation={0}>
                <Container maxWidth="xxl">
                    <Toolbar disableGutters>
                        <Tooltip title="Main Menu" placement="right" >
                            <IconButton sx={{ p: 0 }} onClick={() => handleSideBar()} color="inherit" alt="toggle">
                                <GlobalNavButtonIcon className={classes.globalNavIcon} />
                            </IconButton>
                        </Tooltip>
                        <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}></Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <Grid item xs={12} sm={12} md={12}
                                sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", }}>
                                <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                                    {/* <Grid item>
                                        <IconButton aria-label="notification" onClick={toggleDrawerNotification(true)}>
                                            <Badge color="secondary" variant="dot">
                                                <RingerIcon style={{ color: '#ffffff', fontSize: "24px", marginRight: '-6px' }} />
                                            </Badge>
                                        </IconButton>
                                    </Grid> */}

                                    <Grid item>
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, textAlign: 'left' }}>
                                            {name != null ?
                                           ( <><Avatar alt={name} variant="rounded" className={classes.avatarIcon}>{name[0] + lName[0]}</Avatar>
                                         
                                            <div className={classes.profileArea}>
                                                <Typography className={classes.profileName} noWrap={true}>
                                                    {name + " " + lName}
                                                </Typography>
                                                <Typography variant="caption" className={classes.profileEmail} display="block" gutterBottom noWrap={true}>
                                                    {mail}
                                                </Typography>
                                            </div>
                                            <ChevronDownMedIcon className={classes.arrowIcon} />
                                            </>)
                                                : ""}
                                        </IconButton>

                                        <Menu
                                            className={classes.menuFont}
                                            sx={{ mt: '45px' }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                                            keepMounted
                                            transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                        >
                                            {/* <MenuItem onClick={toggleDrawerMyProfile(true)}>
                                                <ListItemIcon>
                                                    <ContactIcon />
                                                </ListItemIcon>
                                                <ListItemText>My Profile</ListItemText>
                                            </MenuItem>

                                            <MenuItem>
                                                <ListItemIcon>
                                                    <PermissionsIcon />
                                                </ListItemIcon>
                                                <ListItemText>Change Password</ListItemText>
                                            </MenuItem>

                                            <MenuItem onClick={toggleDrawerNotification(true)}>
                                                <ListItemIcon>
                                                    <RingerIcon />
                                                </ListItemIcon>
                                                <ListItemText>Notifications</ListItemText>
                                            </MenuItem>

                                            <Divider /> */}

                                            <MenuItem onClick={() => logout()}>
                                                <ListItemIcon>
                                                    <SignOutIcon />
                                                    {/* style={{ fontSize: "20px" }} */}
                                                </ListItemIcon>
                                                <ListItemText>Sign out</ListItemText>
                                            </MenuItem>
                                        </Menu>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            {/* {My Profile Drawer Start Here */}
            <SideDrawer formComp={<MyProfile />} state={myProfileState} setState={setMyProfileState} />
            {/* {My Profile Drawer End Here */}

            {/* {Notification Drawer Start Here */}
            <SideDrawer formComp={<Notifications />} state={notificationState} setState={setNotificationState} />
            {/* {Notification Drawer End Here */}

        </div>
    </>
}

export default Header;