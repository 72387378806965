import React from 'react'
import { useHistory } from 'react-router-dom';
import { compareDesc } from 'date-fns'

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
};

const useSession = () => {
    const history = useHistory();
    const getSession = () => {
        const ncSession = JSON.parse(localStorage.getItem('ncSession')),
            sessionObj = {
                token: ncSession?.token,
                userName: ncSession?.firstName && ncSession?.lastName ? ncSession?.firstName + ' ' + ncSession?.lastName : null,
                userInitial: ncSession?.firstName && ncSession?.lastName ? ncSession?.firstName?.charAt(0) + ncSession?.lastName?.charAt(0) : null,
                auth: ncSession?.auth ? true : false
            };
        return sessionObj;
    };

    const [ncSession, setSession] = React.useState(getSession());

    const saveSession = (params) => {
        localStorage.setItem('ncSession', JSON.stringify(params));
        setSession({
            token: params.token,
            userName: params.firstName && params.lastName ? params.firstName + ' ' + params.lastName : null,
            userInitial: params.firstName && params.lastName ? params.firstName.charAt(0) + params.lastName.charAt(0) : null,
            auth: params.auth ? true : false
        });
    };

    const clearSession = () => {
        localStorage.removeItem('ncSession');
        setSession({})
        history.push('/login')
    }

    return {
        setSession: saveSession,
        clearSession,
        ncSession
    }
}

const truncateString = (string, length) => {
    return (
        string.length > length ? string.substring(0, length) + "..." : string
    );
}

const isExpired = (expiryDate) => {
    const today = new Date();
    return (
        compareDesc(today, new Date(expiryDate)) > 0
    )
}

const isQuillEmpty = (quill) => {
    if ((quill.getContents()['ops'] || []).length !== 1) { return false }
    return quill.getText().trim().length === 0
}

const getArrDiff = function (a, b) {
    return b.find(function (i) { return a.indexOf(i) < 0; });
};

export {
    descendingComparator,
    getComparator,
    stableSort,
    useSession,
    truncateString,
    isExpired,
    isQuillEmpty,
    getArrDiff
}