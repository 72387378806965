import axios from "axios";

const callSource = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.React_App_baseUrl}DropDown/GetSourceFilterAsyncWithId`,data
      
    );

    return response;
  } catch (err) {
    //console.log(err);
  }
};

const callBasedOnLocation = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.React_App_baseUrl}DropDown/GetLocationFilterAsyncWithId`,
      data
    );

    return response;
  } catch (err) {
   // console.log(err);
  }
};

const callFromPriority = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.React_App_baseUrl}DropDown/GetPriorityFilterAsyncWithId`,
     data
    );

    return response;
  } catch (err) {
   // console.log(err);
  }
};

const callFromComm = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.React_App_baseUrl}DropDown/GetCommodityFilterAsyncWithId`,
     data
    );
    return response;
  } catch (err) {
    //console.log(err);
  }
};


const callFromAssetType = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.React_App_baseUrl}DropDown/GetAssetTypeFilterAsyncWithId`,
     data
    );
    return response;
  } catch (err) {
    //console.log(err);
  }
};



export {callSource, callBasedOnLocation, callFromPriority, callFromComm, callFromAssetType}

