import React, { useState } from "react";
import { Box, Breadcrumbs, Button, CircularProgress, Grid, IconButton, InputAdornment, Link, Paper, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// Importing Custom Components
import AddRole from "./AddRole";
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import SideDrawer from "../../../../src/UIComponent/Drawer/index";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles
import useStyles from "./ManageRoles.styles";
import GetMenuService from './../../../Services/GetMenuService';

const ManageRoles = (props) => {
  // Declaration of States and Component Variables Start Here
  const [allUsers, setAllUsers] = useState([]);
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [rows, setRows] = useState([]);
  const [showDelete, setDelete] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [id, setId] = useState([]);
  const [selected, SetSelected] = useState([]);
  const [isAllSelected, setIsSelected] = useState(false);
  const [state, setState] = React.useState({ right: false });
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  const [crudMode, setCrudMode] = useState("add");
  const [crudState, setCrudState] = React.useState(false);
  const [crudModal, setCrudModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [search, setSearch] = useState("");
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState(null);
  const [tableRow, setTableRow] = useState();
  const [formData, setFormData] = useState([]);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [edit, setEdit] = useState({});
  const [roleId, setRoleId] = useState();
  const [reload, setReload] = useState(false);
  // Declaration of States and Component Variables End Here

  const [access,setAccess]= React.useState({allowEdit:false,allowDelete:false,allowView:false})


  React.useEffect(()=>{
    // Call GetMenuService with parameter 1 and a callback function 
    GetMenuService(1,function(err,res){
      if(res){
        // Log the response data to the console
        //console.log(res.data)
        // Set the access using the response data
        setAccess(res.data)
      }else{
        // Show an error message in the toaster
        toasterError(err)
      }
    })
  },[])

// This function handles the keyup event
const handleKeyUp = (event) => {
  // If backspace is pressed and the target element is not a button
  if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
    // If searchValue is empty, get user data
    if (searchValue.length === 0) { getUserData(); }
  }
  // If enter is pressed and the target element is not a button
  if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
    // Search for the user with the value of the target element
    searchUser(event.target.value);
  }
};

// This function sets the total count, page and search value to 0 and the given value respectively
const globalSearch = (value) => { 
  setTotalCount(0);
  setPage(0);   
  setSearchValue(value); 
};
// This function is used to search for a user based on the filter value provided
const searchUser = (filterValue) => {
  // Set loading to true while the API call is being made
  setLoading(true);
  // Create an array of parameters to be passed in the API call
  let prt = [`pageNumber=${page+1}`, `pageSize=${rowsPerPage}`];
  // Make an API call to the GetRoleDetails endpoint with the filter value, sorting criteria and parameters
  ApiService.search("account/GetRoleDetails?", { searchText: filterValue,
    sortFilter:{SortColumn:sorting.sortBy.charAt(0).toUpperCase() + sorting.sortBy.slice(1),isAscending:sorting.isAscending}
  }, prt)
    .then((res) => {
      // If the response status is 200, set the allUsers state to the data returned from the API call and set loading to false
      if (res.status === 200) {
        setAllUsers(res.data.data);
        setLoading(false);
        setTotalCount(res.data.totalCount);
      } else {
        // If the response status is not 200, display an error message and set loading to false
        toasterError(`${res.data}`);
        setLoading(false);
      }
    })
    .catch((err) => {
      // If there is an error, set loading to false and handle the error
      setLoading(false);
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
};

  const handleSearch = (event) => { setSearchValue(event.target.value); };

// This function toggles the drawer open or closed
const toggleDrawer = (open, mode) => (event, item) => {
  // If mode is set to delete, set the delete user data and open the delete window
  if (mode === "Delete") { setDeleteUserData(item); setOpenDelete(true); }
  else {
    // If mode is set to edit, call the edit button API with the item id
    if (mode === "Edit") { editButtonApi(item.id); }
    // Set the form data to edit and update the state
    setFormData(edit);
    setState({ ...state, right: open, formData: edit });
    // Set the CRUD state to the given mode
    setCrudState(mode);
  }
};
// This function makes an API call to get the role configuration details
const editButtonApi = (roleId) => {
  // Make a GET request using axios
  axios.get(process.env.React_App_baseUrl + "account/GetRoleConfigurationDetails", {
    // Set the headers for the request
    headers: {
      roleId: roleId, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    },
  })
    // On successful response, set the data in the state
    .then((res) => { setEdit(res.data); });
};

// Function to get user data from the API
const getUserData = () => {
  // Set loading to true
  setLoading(true);
  // Reset reload if it is true
  if (reload) { setReload(false); }
  // Create parameters for pagination and filter implementation
  let prt = [`pageNumber=${page+1}`, `pageSize=${rowsPerPage}`];
  // Call the API service to search for user data
  ApiService.search("account/GetRoleDetails?", { 
    sortColumn:sorting.sortBy.charAt(0).toUpperCase() + sorting.sortBy.slice(1),
    isAscending:sorting.isAscending
  }, prt)
    .then((res) => {
      // If response status is 200, set all users and total count
      if (res.status === 200) {
        setAllUsers(res.data.data);
        setTotalCount(res.data.totalCount);
        setLoading(false);
      } else {
        // Else, display error message
        toasterError(`${res.data}`);
        setLoading(false);
      }
    })
    .catch((err) => {
      // Handle errors
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
};

// Function to delete a user
const deleteUser = () => {
  // Set loading state of delete button to true
  setDeleteBtnLoadinng(true);
  // Get data for deleting user
  let data = deleteUserData;
  // Set active status of user to false
  data.active = false;
  // Make a post request to delete the user
  axios.post(process.env.React_App_baseUrl + "account/DeleteRole", data, {
    headers: {
      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
  })
    .then((res) => {
      // If request is successful
      if (res.status === 200) {
        // Show success message
        toasterSuccess("Role Deleted successfully!");
        // Get updated user data
        getUserData();
        // Set loading state of delete button to false
        setDeleteBtnLoadinng(false);
        // Close delete modal
        setOpenDelete(false);
      } else {
        // Show error message
        toasterError("Somthing Went Wrong");
        // Close delete modal
        setOpenDelete(false);
        // Set loading state of delete button to false
        setDeleteBtnLoadinng(false);
      }
    })
    .catch((err) => {
      // Handle errors
      ErroHandling(err, function (res) {
        // Show error message
        toasterError(res)
      })
    });
};

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
   // if (allUsers.length < 1 || reload) { 
      getUserData(); 
    //}
  }, [page, reload,sorting,rowsPerPage]);
  // Declaration of React Hooks End Here


  // Function for Search Clear
  const searchClear = () => {
    setSearchValue("");
    axios.post(process.env.React_App_baseUrl + "job/GetCompanyAssetDetail", props.selectedValue, {
      headers: {
        searchText: "",
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
    })
      .then(function (response) {
        setFormData({ ...formData, checkValue: false });
        setTableRow(response.data);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
  };

// Declare an array of objects containing column information
const columns = [
  // First column has id "id", label "Status" and type "Highlight"
  { id: "id", label: "Status", type: "Highlight" },
  // Second column has id "roleName", label "Role Name", minWidth 180, width 25%, sorting true
  { id: "roleName", label: "Role Name", minWidth: 180, width: "25%", sorting: true, },
  // Third column has id "roleDesc", label "Description", minWidth 250, width 75%, sorting true
  { id: "roleDesc", label: "Description", minWidth: 250, width: "75%", sorting: true },
];

  const checkboxRequired = (e, item) => {
    SetSelected(e);
    setId(e);

    if (e.length > 0) { setDelete(true); }
    else { setDelete(false); }
  };

  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Manage Roles</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Typography color="text.primary">Home</Typography>
            <Typography color="text.primary">Manage Users/Roles</Typography>
            <Typography color="text.primary">Manage Roles</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {access.allowEdit && <Button variant="contained" color="accent2" onClick={toggleDrawer(true, "Add")}
                startIcon={<AddIcon style={{ fontSize: 16 }} color="primary" />}>
                Add Role
              </Button>}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}


        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>

              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="searchByClear"
                        onClick={() => {
                          setSearchValue("");
                          getUserData();
                        }}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : ("")}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">Total Records:&nbsp;
                      <span className={classes.totalRecord}>{totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Manage Role Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allUsers ? allUsers : []}
                  columns={columns}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={setSorting}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Add Role Side Drawer */}
      <SideDrawer
        drawerWidth="md"
        formComp={
          // Calling Add Role
          <AddRole
            state={state}
            setState={setState}
            crudState={crudState}
            formData={edit}
            setFormData={setEdit}
            userId={null}
            hideSideDrawer={() => {
              setState({ right: false });
              getUserData();
            }}
          />
        }
        state={state}
        setState={setState}
      />
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to delete "${deleteUserData?.roleName}" ?`}
          action={
            <Grid container alignItems="center" justifyContent="right" spacing={2} className={classes.alertDialogbtn}>
              <Grid item xs={"auto"}>
                <Button onClick={() => setOpenDelete(false)}>No</Button>
              </Grid>
              <Grid item xs={"auto"}>
                {deleteBtnLoadinng ? (
                  <CircularProgress />) : (<Button onClick={() => deleteUser()} color="secondary">Yes</Button>)}
              </Grid>
            </Grid>
          }
        />
      ) : null}
    </Box>
  );
};

export default ManageRoles;
