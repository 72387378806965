import axios from "axios";

const baseUrl = process.env.React_App_baseUrl;
const search = async (url, headerparam, param) => {
  let payload;
  param.forEach((el) => {
    payload = payload ? payload + "&" + el : el;
  });
  return axios.get(baseUrl + url + payload, {
    headers: {
      ...headerparam,
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : null,
    },
  });
};
const getAll = async (url) => {
  return axios.get(baseUrl + url, {
    headers: {
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : null,
    },
  });
};

const get = (url, id) => {
  return axios.get(baseUrl + url + id, {
    headers: {
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : null,
    },
  });
};

const create = (url, data) => {
  return axios.post(baseUrl + url, data, {
    headers: {
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : null,
    },
  });
};

const update = (url, id, data) => {
  return axios.put(baseUrl + url + id, data, {
    headers: {
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : null,
    },
  });
};
const updatepatch = (url, id, data) => {
  return axios.patch(baseUrl + url + id, data, {
    headers: {
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : null,
    },
  });
};

const remove = (url, id) => {
  return axios.delete(baseUrl + url + id, {
    headers: {
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : null,
    },
  });
};

const removeAll = (url) => {
  return axios.delete(baseUrl + url, {
    headers: {
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : null,
    },
  });
};

const findByTitle = (url, title) => {
  return axios.get(baseUrl + url + title, {
    headers: {
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : null,
    },
  });
};

const TutorialService = {
  getAll,
  get,
  create,
  update,
  updatepatch,
  remove,
  removeAll,
  findByTitle,
  search,
};

export default TutorialService;
