// Default React, useState and Material Components
import React, { useState } from "react";
import { Button, Box, Breadcrumbs, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import SideDrawer from "../../../../src/UIComponent/Drawer/index";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing Add & Edit Components
import AddEditVariableReport from "./AddVariableReport";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon, } from "@fluentui/react-icons-mdl2";

// Importing useStyles
import useStyles from "../ManageData.styles";

const VariableReport = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [id, setId] = useState([]);
  const [rows, setRows] = useState([]);
  const [edit, setEdit] = useState({});
  const [roleId, setRoleId] = useState();
  const [page, setPage] = React.useState(0);
  const [tableRow, setTableRow] = useState();
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState([]);
  const [selected, SetSelected] = useState([]);
  const [showDelete, setDelete] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [crudMode, setCrudMode] = useState("add");
  const [crudModal, setCrudModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [prevFormValue, setPrevFormValue] = useState(null);
  const [deleteUserData, setDeleteUserData] = useState(null);
  const [state, setState] = React.useState({ right: false });
  const [allVairableReport, setAllVairableReport] = useState([]);
  const [dataTableDisable, setDataTableDisable] = useState(true);
  const [mappedCRUVariable, setMappedCRUVariable] = useState(true);
  const [variableReportState, setVariableReportState] = useState();
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [variableReportPage, setVariableReportPage] = useState(true);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [deleteVariableReportData, setDeleteVariableReportData] = useState(null);
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false });
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(30, function (err, res) {
      if (res) {
        // console.log(res.data)
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])
  React.useEffect(() => { GetAllVariableReport(searchValue); }, [reload]);
  React.useEffect(() => { GetAllVariableReport(searchValue); }, [sorting, page, rowsPerPage]);
  // Declaration of React Hooks End Here

  // Assigning Columns and UI Props
  const Mappedcolumns = [
    { id: "id", label: "Id", type: "Highlight" },
    { id: "name", label: "Variable", minWidth: 200, sorting: true },
    { id: "cruVariableName", label: "CRU Variable", minWidth: 275, width: '25%', sorting: true },
    { id: "createdBy", label: "Created By", minWidth: 250, width: '20%', sorting: true },
    { id: "createdDate", label: "Created On", minWidth: 150, width: '20%', sorting: true },
  ];
  const UmMappedColumns = [
    { id: "id", label: "Id", type: "Highlight" },
    { id: "name", label: "Variable", minWidth: 200, sorting: true },
    { id: "statusName", label: "Jira Ticket Status", minWidth: 275, width: '25%', sorting: true },
    { id: "createdBy", label: "Created By", minWidth: 250, width: '20%', sorting: true },
    { id: "createdDate", label: "Created On", minWidth: 150, width: '20%', sorting: true },
  ]

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      searchVariableReport(event.target.value);
    }
  };

  // Function for Sorting
  const changeSortColumnNameFormat = (fieldName) => {
    if (fieldName == null || fieldName == "") { return ""; }
    if (fieldName.toLocaleLowerCase() == "name".toLocaleLowerCase()) { fieldName = "Name"; }
    if (fieldName.toLocaleLowerCase() == "cruVariableName".toLocaleLowerCase()) { fieldName = "CRUVariableName"; }
    if (fieldName.toLocaleLowerCase() == "statusName".toLocaleLowerCase()) { fieldName = "StatusName"; }
    if (fieldName.toLocaleLowerCase() == "createdon".toLocaleLowerCase()) { fieldName = "CreatedOn"; }
    if (fieldName.toLocaleLowerCase() == "modifiedon".toLocaleLowerCase()) { fieldName = "ModifiedOn"; }
    return fieldName;
  }

  const globalSearch = (value) => { setSearchValue(value); };
  const searchVariableReport = (filterValue) => {
    setTotalCount(0);
    setPage(0);
    setLoading(true);
    setReload(!reload);
  };

  const handleSearch = (event) => { setSearchValue(event.target.value); };

  // Function for Add, Edit and Delete
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") {
      setDeleteVariableReportData(item);
      setOpenDelete(true);
    } else {
      if (mode === "Edit") {
        setFormData(item);
        setState({ ...state, right: open, formData: item });
        setCrudState(mode);
      }
      if (mode === "Add") {
        setState({ ...state, right: open, formData: item });
        setCrudState(mode);
      }
    }
  };

  const MappedCRUVariable = () => {
    setMappedCRUVariable(true);
    setSearchValue("");
    setPage(0);
    setSorting({ sortBy: "", isAscending: false });
    setReload(!reload);
  }

  const UnMapperVariableReport = () => {
    setMappedCRUVariable(false);
    setSearchValue("");
    setPage(0);
    setSorting({ sortBy: "", isAscending: false });
    setReload(!reload);
  }

 // Function to delete a variable report
const deleteVariableReport = () => {
  // Set loading state of delete button to true
  setDeleteBtnLoadinng(true);
  // Check if deleteVariableReportData is null or Id is 0
  if (deleteVariableReportData == null || deleteVariableReportData.Id == 0) {
    // Show error message
    toasterError("Somthing Went Wrong");
    // Set loading state of delete button to false
    setDeleteBtnLoadinng(false);
    // Return from the function
    return;
  }

  // Make an API call to delete the variable report
  axios.post(process.env.React_App_baseUrl + `variablereport/DeleteVariableReport?Id=${deleteVariableReportData.id}`, null, {
    headers: {
      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
  })
    .then((res) => {
      // If API call is successful
      if (res.status === 200) {
        // Show success message
        toasterSuccess("Variable Report deleted successfully!");
        // Set loading state of delete button to false
        setDeleteBtnLoadinng(false);
        // Close delete modal
        setOpenDelete(false);
        // Reload the page
        setReload(!reload);
      } else {
        // Show error message
        toasterError("Somthing Went Wrong");
        // Close delete modal
        setOpenDelete(false);
        // Set loading state of delete button to false
        setDeleteBtnLoadinng(false);
        // Reload the page
        setReload(!reload);
      }
    })
    .catch((err) => {
      // Close delete modal
      setOpenDelete(false);
      // Set loading state of delete button to false
      setDeleteBtnLoadinng(false);
      // Reload the page
      setReload(!reload);
      // Handle errors
      ErroHandling(err, function (res) {
        // Show error message
        toasterError(res)
      })
    });
};


  // Function for Search Clear
  const searchClear = () => {
    setSearchValue("");
    setPage(0);
    setReload(!reload);
  };

// This function is used to get all variable report details
  const GetAllVariableReport = (filterValue) => {
    // Set loading to true
    setLoading(true);
   // Create an array of parameters 
    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`, `mappedVariableReport=${mappedCRUVariable}`];
    // Call the ApiService search method
    ApiService.search(`variablereport/GetAllVariableReportDetails?`,
      {
        // Pass the filter value as a search text
        searchText: encodeURIComponent(filterValue),
        // Change the sort column name format
        sortColumn: changeSortColumnNameFormat(sorting.sortBy),
        // Set the sorting order
        isAscending: sorting.isAscending
      }, prt
    )
      .then((res) => {
       // If response status is 200, set the data and total count 
        if (res.status === 200) {
          setAllVairableReport(res.data.data);
          setTotalCount(res.data.totalCount);
          setLoading(false);
        } else {
         // Else show error message 
          toasterError(`${res.data}`);
          setLoading(false);
        }
      })
      .catch((err) => {
        // Set loading to false
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }

  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Variable (Report)</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">Variable (Report)</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {(access.allowEdit) ? <>
                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: "16px" }} />}>
                  Add Variable
                </Button>
              </> : null}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        <Grid item xs={12}>
          <BaseLayout>

            {/* Variable Buttons Start Here */}
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={'auto'}>
                  <Button fullWidth className={classes.largeButton2} color="secondary" size="small"
                    variant={mappedCRUVariable ? "contained" : "outlined"}
                    onClick={() => MappedCRUVariable()}>
                    Mapped CRU Variables
                  </Button>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                  <Button fullWidth className={classes.largeButton2} variant={mappedCRUVariable ? "outlined" : "contained"}
                    color="secondary"
                    size="small"
                    onClick={() => UnMapperVariableReport()}>
                    Missing CRU Variables
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* Variable Buttons Start Here */}

            <Box className={classes.addBorder}>
              <Grid container alignItems="center" justifyContent="left">
                {/* Search and Filter Options Start Here */}
                <Grid item xs={12} className={classes.tableTopPart}>
                  <Grid container alignItems="center" justifyContent="left" spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="input-with-icon-textfield"
                        className={classes.searchByKeyword}
                        size="small"
                        fullWidth
                        value={searchValue}
                        placeholder="Search by Keyword"
                        onKeyUp={handleKeyUp}
                        onChange={(e) => globalSearch(e.target.value)}
                        InputProps={{
                          startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                        }}
                        variant="standard"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility"><SearchIcon /></IconButton>
                          </InputAdornment>
                        }
                      />
                      {searchValue ? (
                        <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                          <ClearIcon style={{ fontSize: 10 }} />
                        </IconButton>
                      ) : ("")}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid container alignItems="center" justifyContent="right" spacing={2}>
                        <Grid item textAlign="right">
                          <Typography variant="subtitle2">
                            Total Variables:{" "}
                            <span className={classes.totalRecord}> {totalCount}</span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Variable Report */}
                <Grid item xs={12}>
                  <DataTable
                    className={classes.removeBorder}
                    loading={isLoading}
                    rows={allVairableReport ? allVairableReport : []}
                    columns={mappedCRUVariable ? Mappedcolumns : UmMappedColumns}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    variableReport={true}
                    totalCount={totalCount}
                    isAllSelected={isAllSelected}
                    setIsSelected={setIsSelected}
                    selected={selected}
                    setSelected={SetSelected}
                    sorting={sorting}
                    setSorting={setSorting}
                    isActionButton={{
                      ...(access.allowEdit && { edit: toggleDrawer(true, "Edit"), }),
                      ...(access.allowDelete && { delete: toggleDrawer(true, "Delete"), }),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </BaseLayout>

        </Grid>
      </Grid>

      {/* Add, Edit, Delete Drawer */}
      <SideDrawer
        formComp={
          <AddEditVariableReport
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            userId={null}
            hideSideDrawer={() => { setState({ right: false }); setReload(!reload); }}
            mappedCRUVariable={mappedCRUVariable}
            updateMappedCRUVariable={(e) => {
              setMappedCRUVariable(e);
              setPage(0);
              setSearchValue("");
              if (crudState.toLowerCase() == "Edit".toLowerCase()) {
                setSorting({ sortBy: "ModifiedOn", isAscending: false })
              } else {
                setSorting({ sortBy: "CreatedOn", isAscending: false })
              }
            }}
            prevFormValue={prevFormValue}
            openAddCRUVariableDrawer={(e) => {
              setPrevFormValue(e);
              setVariableReportState(crudState);
              setCrudState("Add");
            }}
            closeVariableReportDrawer={(e) => {
              setState({ right: false });
              setReload(!reload);
              setPrevFormValue(null);
            }}
          />
        }
        state={state}
        setState={setState}
        setPageRefresh={() => { setReload(!reload); }}
      />

      {/* Single/Muliple Delete Confirmation Dialog Start Here */}
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to delete "${deleteVariableReportData?.name}" ?`}
          action={
            <Grid container alignItems="center" justifyContent="right" spacing={2} className={classes.alertDialogButton}>
              <Grid item xs={"auto"}>
                <Button onClick={() => setOpenDelete(false)} style={{ marginRight: "15px" }}>No</Button>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button onClick={() => deleteVariableReport()} color="secondary">
                    Yes
                  </Button>
                )}
              </Grid>
            </Grid>
          } />) : null}
      {/* Single/Muliple Delete Confirmation Dialog End Here */}

    </Box >
  );
};

export default VariableReport;