
// Default React, Router and Services Components
import React, { useState } from "react";
import { Box, Breadcrumbs, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import axios from "axios";
//import history from "../../../Routes/History";
import { Link } from 'react-router-dom';
import EmptyState from "../../../../UIComponent/EmptyState/index";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
// Importing Custom Components
import BaseLayout from "../../../../UIComponent/BaseLayout";
import DatePicker from "../../../../UIComponent/DatePicker";
import ExportToExcel from "./Excelexport";
import FilterForm from "./FilterForm";
import SideDrawer from "../../../../UIComponent/Drawer";
import { useHistory } from "react-router-dom";

// Importing useStyles
import useStyles from "./JiraTicketStatusReport.styles";

// Import Fluent UI Icons
import { FileOffIcon, ChevronRightSmallIcon } from "@fluentui/react-icons-mdl2";
import GetMenuService from '../../../../Services/GetMenuService';

const DataCollectionJiraTicketStatusReport = (props) => {
  // Declaration of States and Component Variables Start Here
  const [state, setState] = useState({ right: false });
  const [crudState, setCrudState] = React.useState(false);
  const [sorting, setSorting] = useState({ orderBy: "", order: false });
  const [formData, setFormData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const date = new Date().toLocaleDateString();
  const date1 = date.split("/").join("-");
  const [filterValue, setFilterValue] = useState({});
  const [keyFromDate, setKeyFromDate] = useState(false);
  const [keyToDate, setKeyToDate] = useState(false);
  const classes = useStyles();
  const [searchData, setSerachData] = useState({ FromDate: null, ToDate: null, });
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValues, setFilterValues] = useState();
  const [companyFilter, setCompanyFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [reload, setReload] = useState(false);
  // Declaration of States and Component Variables End Here
  const [access,setAccess]= React.useState({allowEdit:false,allowDelete:false,allowView:false})
  const history = useHistory();

React.useEffect(()=>{
    GetMenuService(36,function(err,res){
      if(res){
        //console.log(res.data)
        setAccess(res.data)
      }else{
        toasterError(err)
      }
    })
  },[])
  // Declaration of React Hooks Start Here
  React.useEffect(() => { fetchData(); }, [filterValue]);
  React.useEffect(() => { getFilterData(); }, [reload]);
  // Declaration of React Hooks End Here

// This function is used to get data from the Jira API
const getData = async () => {
  // Create a new promise to handle the response
  let response = await new Promise((resolve, reject) => {
    // Resolve the promise with an axios GET request
    resolve(axios.get(`${process.env.React_App_baseUrl}reports/GetJiraTicketStatus`,
      { 
        // Set the headers for the request
        headers: { 
          JiraFilter: filterValue ? JSON.stringify(filterValue) : null,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null 
        },
       })
    );
  });
  // Return the response
  return response;
};
// This function is used to fetch data from the getData() function
const fetchData = async () => {
  // Get the data from the getData() function
  const { data } = await getData();
  // Set the table data with the fetched data
  setTableData(data);
};

  // Function for Getting Source Filter Dropdown List
  const getFilterData = async () => {
    try {
      // Making a GET request to the API
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`,{headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }}
      );
      // If no data is returned, set the data for filter to an empty array
      if (response.data.length === 0) { setDataForFilter([]); }
      else {
      // Set the data for filter to the response data  
        setDataForFilter(response.data);
      // Iterate through the companies and set checked to false  
        response.data.companies.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
          }
        });
      // Iterate through the regions and set checked to false  
        response.data.location.regions.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
            if (item.children) {
              item.children.forEach((item, index) => {
                item.checked = false;
              });
            }
          }
        });
         // Set the company filter and location filter
        setCompanyFilter(response.data.companies);
        setLocationFilter(response.data.location.regions);
      }
    } catch (err) {
      // If the status code is 400, call the toasterError function with the error response data
      if(err.response.status===400){ toasterError(err.response.data); }
     }
  };

// This function is used to get the Jira ticket status from the API
const searchDataDateRange = function () {
  // Make an axios GET request to the specified URL with the given headers
  axios.get(`${process.env.React_App_baseUrl}reports/GetJiraTicketStatus`, {
    headers: { 
      // Set the JiraFilter header to the value of the searchData variable, or null if it is not set
      JiraFilter: searchData ? JSON.stringify(searchData) : null,
      // Set the Authorization header to the token stored in sessionStorage, or null if it is not set
      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null 
    },
    
  })
    // Set the table data to the response data from the API call
    .then((resp) => { setTableData(resp.data); });
};
  const clearDataDateRange = function () {
    setKeyFromDate((k) => !k);
    setKeyToDate((t) => !t);
    axios.get(`${process.env.React_App_baseUrl}reports/GetJiraTicketStatus`, {
      headers: { JiraFilter: null,'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null },
      
    })
      .then((resp) => { setTableData(resp.data); });
  };

  // Function for Open Delete and Edit Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    setState({ ...state, right: open });
  };

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Jira Tickets</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/aging-report" }}>Dashboard and Reports</Link>
            <Typography color="text.primary">Jira Tickets</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>

            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>

              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="flex-end" justifyContent="left" spacing={2}>
                  <Grid item xs={12} lg={9}>
                    <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-end">
                      <Grid item xs={"auto"}>
                        <DatePicker
                          labelTitle="From Date"
                          variant="standard"
                          defaultDate={null}
                          onChangeDate={(e) => {
                            let selectedDate = new Date(e);
                            let date =
                              selectedDate.getFullYear() +
                              "-" +
                              ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + selectedDate.getDate()).slice(-2);
                            setSerachData({ ...searchData, FromDate: date });
                          }}
                          past={false}
                          clearable={true}
                          key={keyFromDate}
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <DatePicker
                          labelTitle="To Date"
                          variant="standard"
                          past={false}
                          minDates={searchData.FromDate}
                          defaultDate={null}
                          onChangeDate={(e) => {
                            let selectedDate = new Date(e);
                            let date =
                              selectedDate.getFullYear() +
                              "-" +
                              ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + selectedDate.getDate()).slice(-2);
                            // setToDateVal(date)
                            setSerachData({ ...searchData, ToDate: date });
                          }}
                          key={keyToDate}
                          clearable={true}
                        /></Grid>
                      <Grid item xs={"auto"} textAlign="right">
                        <Button onClick={searchDataDateRange} variant="contained" color="secondary">
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={3} textAlign="right">
                    <Typography variant="subtitle2" className={classes.buttonNoWrap}>
                    {access.allowView && <ExportToExcel apiData={tableData} fileName={`Jira Tickets Report ${date1}`} status />}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Jira Ticket Table */}
              <Grid item xs={12}>
                <TableContainer component={Paper} elevation={0} className="table-container">
                  <Table aria-label="collapsible table" className="jiraStatusTable">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 250, width: "20%" }}>Ticket Type</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 90, width: "20%" }} align="center">Active</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 90, width: "20%" }} align="center">Inactive</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 90, width: "20%" }} align="center">Pending</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 90, width: "20%" }} align="center">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.length > 0 ? (
                        tableData?.map((item, index) => (
                          <TableRow key={index} hover>
                            <TableCell className={classes.tableCell}>
                            {(index!==tableData.length)?
                              item.ticketType : ("")}
                              </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                            {(index!==tableData.length)?
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView &&  history.push({
                                    pathname: `/app/data-collections/jira-ticket-status-report-drill`,
                                    state:{ TicketType:item.ticketType,
                                    TicketStatus:'Active',
                                    searchData:searchData}
                                  }
                                    
                                  )
                                }
                              >
                                {item.active}
                              </span>:
                              ("")}
                            </TableCell>
                            {/* </Link> */}
                            <TableCell className={classes.tableCell} align="center">
                            {(index!==tableData.length)?
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView &&  history.push({
                                    pathname:'/app/data-collections/jira-ticket-status-report-drill',
                                    state:{TicketType:item.ticketType,
                                    TicketStatus:'Inactive',
                                    searchData:searchData}
                                  }                                  
                                  )
                                }
                              >
                                {item.inactive}
                              </span> : ("")}
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                            {(index!==tableData.length)?
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView &&   history.push({
                                    pathname:'/app/data-collections/jira-ticket-status-report-drill',
                                    state:{TicketType:item.ticketType,
                                    TicketStatus:'Pending',
                                    searchData:searchData}
                                  })
                                }
                              >
                                {item.pending}
                              </span> : ("")}
                            </TableCell>
                            <TableCell className={classes.tableCell} style={{ textAlign: "center", cursor: "", }}>
                              {(index!==tableData.length)?
                            <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView &&   history.push({
                                    pathname:'/app/data-collections/jira-ticket-status-report-drill',
                                    state:{TicketType:item.ticketType,
                                    TicketStatus:'Total',
                                    searchData:searchData}
                                   })
                                }
                              >
                              {item.total}
                              </span> : ("")
                          }
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell className={classes.tableCell} align="center" colSpan={99}>
                            <EmptyState
                              icon={<FileOffIcon style={{ color: "#db9400", fontSize: "40px", }} />}
                              title="No Data Found"
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

              </Grid>
            </Box>
          </BaseLayout>
        </Grid>

      </Grid>

      {/* Filter Side Drawer */}
      <SideDrawer
        formComp={
          <FilterForm
            dataForFilter={dataForFilter}
            filterFinalData={filterFinalData}
            companyFilter={companyFilter}
            locationFilter={locationFilter}
            setFilterValues={setFilterValues}
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            userId={null}
            setFilterValue={setFilterValue}
            hideSideDrawer={() => {
              setState({ right: false });
              // getUserData();
            }}
            reload={reload}
            setReload={setReload}
          />
        }
        state={state}
        setState={setState}
        reload={reload}
        setReload={setReload}
        setFilterValue={setFilterValue}
      />
    </Box>
  );
};

export default DataCollectionJiraTicketStatusReport;