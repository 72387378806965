// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Paper,
  Chip,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import AddRecords from "./AddRecords";
import { useDispatch } from "react-redux";
import { visuallyHidden } from "@mui/utils";
import { useHistory } from "react-router-dom";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError } from "../../../UIComponent/Toaster";
// Importing Custom Components
// import "./index.scss";
import FilterJob from "./FilterJob";
import BulkUpload from "./BulkUpload";
import CreateJobPage from "./CreateJob";
import BulkUploadViewer from "./BulkUploadViewer";
import { ScrollingDialog } from "../../../UIComponent";
import BaseLayout from "../../../UIComponent/BaseLayout";
import CreateMultipleJobPage from "./CreateMultipleJob";
import SideDrawer from "../../../UIComponent/Drawer/index";
import { LoaderWrapper } from "../../../UIComponent/Progress";

// Importing useStyles
import useStyles from "./jobManagement.styles";

// Import Material Icons
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from "@mui/icons-material/Group";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";
import InputAdornment from "@mui/material/InputAdornment";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

// Import Fluent UI Icons
import { ChevronRightSmallIcon, ClearIcon } from "@fluentui/react-icons-mdl2";

// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import { getcompaniesBasedOnID } from "../../features/JobManagement/jobSlice";
// import { set } from "date-fns";
import GetMenuService from "../../../Services/GetMenuService";
import FilterJobRecords from "./CreateJobFilters";
//import FilterJobRecords from "./FilterJobRecords";

function createData(
  company,
  commodity,
  noOfAssets,
  priority,
  status,
  companyId,
  isCRUCompany,
  isJiraCreated,
  isopen,
  isSelected,
  children,
  isIndetSelected
) {
  return {
    company,
    commodity,
    noOfAssets,
    priority,
    status,
    companyId,
    isCRUCompany,
    isJiraCreated,
    isopen,
    isSelected,
    children,
    isIndetSelected,
  };
}

function createSubData(
  asset,
  assetType,
  variableCount,
  country,
  region,
  subRegion,
  commodity,
  status,
  isCRUAsset,
  isJiraTicketCreated,
  assetId,
  companyId,
  isCRUCompany,
  isSelected
) {
  return {
    asset,
    assetType,
    variableCount,
    country,
    region,
    subRegion,
    commodity,
    status,
    isCRUAsset,
    isJiraTicketCreated,
    assetId,
    companyId,
    isCRUCompany,
    isSelected,
  };
}

const truncateString = (string, length) => {
  return string.length > length ? string.substring(0, length) + "..." : string;
};

function Row(props) {
  const { row } = props;

  const classes = useStyles();
  if (
    props.PreserveState !== null &&
    props.PreserveState !== undefined &&
    props.PreserveState.length > 0
  ) {
    let tempRows = props.PreserveState;
    let tempRow = tempRows.filter((e) => {
      return e.company == row.company;
    });
    if (tempRow != null && tempRow.length > 0) {
      row.isSelected = tempRow[0].isSelected;
    }
  }
  // console.log(row, "children");

  return (
    <React.Fragment>
      <TableRow hover sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          key={"checkboxIdOne"}
          padding="checkbox"
          style={{ height: 46 }}
          className={classes.TableCell}
        >
          <Checkbox
            checked={row.isSelected}
            onChange={(event) => props.individualCheckOnChange(row, "outer")}
            inputProps={{ "aria-label": "Items" }}
          />
        </TableCell>

        <TableCell
          style={{ paddingLeft: 0, height: 46 }}
          className={classes.TableCell}
        ></TableCell>
        <TableCell component="th" scope="row" className={classes.TableCell}>
          {row.asset}
        </TableCell>
        <TableCell align="left" className={classes.TableCell}>
          {row.commodity === null ? (
            "-"
          ) : (
            <Tooltip title={row.commodity} placement="top" arrow>
              <div>{truncateString(`${row.commodity}`, 90)}</div>
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="left" className={classes.TableCell}>
          {row.country}
        </TableCell>
        <TableCell align="left" className={classes.TableCell}>
          <div className="pr-4">{row.region}</div>
        </TableCell>
        <TableCell align="left" className={classes.TableCell}>
          <div className="pr-4">{row.subRegion}</div>
        </TableCell>
        <TableCell align="left" className={classes.TableCell}>
          <div className="pr-4">{row.operator}</div>
        </TableCell>
        <TableCell align="left" className={classes.TableCell}>
          <div className="pr-4">{row.company}</div>
        </TableCell>
        <TableCell align="center" className={classes.TableCell}>
          <div className="pr-4">{row.ownershipPercentage}</div>
        </TableCell>
        <TableCell align="center" className={classes.TableCell}>
          <div className="pr-4">{row.isListedCompanyGrid}</div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const OwnershipChangesCreateJob = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({ right: false });
  const [state2, setState2] = React.useState({ right: false });
  const [state3, setState3] = React.useState({ right: false });
  const [bulkState, setBulkState] = React.useState({ right: false });
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const [openBulkUploadDrawer, setOpenBulkUploadDrawer] = React.useState(false);
  const [stateBulkUploadDrawer, setStateBulkUploadDrawer] =
    React.useState(false);

  const [openBulkUpload, setOpenBulkUpload] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = () => () => {
    history.push("/app/ownership-changes/manage-job-records");
  };
  const [isAssetSelected, setIsAssetSelected] = React.useState(false);
  const [assetIndex, setAssetIndex] = React.useState(0);
  const [companyValue, setCompanyValue] = React.useState(0);
  const [newJob, setNewJob] = React.useState(true);
  const [companyDropDown, setCompanyDropDown] = React.useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [companyDropDownTrue, setCompanyDropDownTrue] = React.useState([]);
  const [companyDropDownFalse, setCompanyDropDownFalse] = React.useState([]);
  const [assetTypeDropDown, setAssetTypeDropDown] = React.useState([]);
  const [regionDropDown, setRegionDropDown] = React.useState([]);
  const [commodityDropDown, setCommodityDropDown] = React.useState([]);
  const [statusDropDown, setStatusDropDown] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [company, setCompany] = React.useState([]);
  let [filterValue, setFilterValue] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [crudState, setCrudState] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [showBulk, setShowBulk] = React.useState(false);
  const [companyId, setCompanyId] = React.useState();
  const [assetId, setAssetId] = React.useState();
  const [sorting, setSorting] = useState({ orderBy: "", order: false });
  const [isAllSelected, setIsSelected] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedValue, setSelectedValue] = useState([]);
  const [argumentValue, setArgumentValue] = useState({});
  const [createJobData, setCreateJobData] = useState({});
  const [pageRefresh, setPageRefresh] = useState(false);
  const [rowValue, setRowValue] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [level, setLevel] = useState("");
  const [assetValue, setAssetValue] = useState(0);
  const [disableAsset, setDisableAsset] = useState(false);
  const handleRequestSort = (event, property) => {
    const isAscending = sorting.orderBy === property && sorting.order;
    setSorting({ orderBy: property, order: isAscending ? false : true });
  };
  const [openRecords, setOpenRecords] = React.useState(false);
  const [scrollRecords, setScrollRecords] = React.useState("paper");
  const [crudStateRecords, setCrudStateRecords] = React.useState(false);
  const [jobIdForAddingRecords, setJobIdForAddingRecords] = React.useState(
    props.location.state
  );
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValues, setFilterValues] = useState();
  const [assetTypeDD, setAssetTypeDD] = useState([]);
  const [reload, setReload] = useState(false);
  const [preserveState, setPreserveState] = useState([]);
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [jobLocations, setJobLocations] = React.useState([]);
  const [assetTypes, setAssetTypes] = React.useState(
    dataForFilter.assetType ? dataForFilter.assetType : []
  );
  const [commodityList, setCommodityList] = React.useState(
    dataForFilter.commodityList ? dataForFilter.commodity : []
  );
  const [priorityList, setPriorityList] = React.useState(
    dataForFilter.priority ? dataForFilter.priority : []
  );
  const [access, setAccess] = React.useState({
    allowEdit: false,
    allowDelete: false,
    allowView: false,
  });
  const [isLoading, setIsLsoading] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [selectedCompanyForCreateJob, setSelectedCompanyForCreateJob] =
    useState([]);

  const [uploadedExcelData, setUploadedExcelData] = useState([]);
  //const [openBulkUpload,setOpenBulkUpload]=useState(false);
  //select all
  const [allassetIds, setAllAssetIds] = React.useState();
  const [partialSelect, setPartialSelect] = React.useState(false);
  const [deselectedValue, setDeselectedValue] = React.useState([]);

  React.useEffect(() => {
    GetMenuService(51, function (err, res) {
      if (res) {
        // console.log(res.data)
        setAccess(res.data);
      } else {
        toasterError(err);
      }
    });
  }, []);

  // console.log(jobIdForAddingRecords, "jobIdForAddingRecords")

  React.useEffect(() => {
    if (pageRefresh) {
      setPage(0);
      setRowsPerPage(10);
      setSorting({ orderBy: "", order: false });
      setSearchValue("");
      setPageRefresh(false);
      setIsAssetSelected(false);
    } else {
      getCompany();
      setIsAssetSelected(false);
    }
  }, [filterValue, sorting, page, rowsPerPage, pageRefresh === true]);

  React.useEffect(() => {
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
        headers: {
          isNewSource: false,
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      })
      .then(function (response) {
        setCompanyDropDownFalse(response.data);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });

    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
        headers: {
          isNewSource: true,
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      })
      .then(function (response) {
        setCompanyDropDownTrue(response.data);
        setAllCompany(companyDropDownFalse.concat(response.data));
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });

    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetCompanyDropDownFilter",
        {
          headers: {
            isNewSource: false,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then(function (response) {
        setCompanyDropDown(response.data);
      })
      .catch(function (error) { });

    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetCompanyDropDownFilter",
        {
          headers: {
            isNewSource: true,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then(function (response) {
        setCompanyDropDown(response.data);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });

    ////asset type DropDown API
    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetAssetTypeDropDownFilter",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then(function (response) {
        setAssetTypeDropDown(response.data);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });

    /// Region Dropdown API calling

    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetRegionDropDownFilter", {
        headers: {
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      })
      .then(function (response) {
        setRegionDropDown(response.data);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });

    /////Commodity Drop DOwn API
    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetCommodityDropDownFilter",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then(function (response) {
        setCommodityDropDown(response.data);
      })
      .catch(function (error) { });

    //////////status dropdown API
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetStatusDropDownFilter", {
        headers: {
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      })
      .then(function (response) {
        setStatusDropDown(response.data);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });
    return () => {
      setCompany([]);
    };
  }, []);

  const getCompany = () => {
    setIsLsoading(true);

    if (filterValue) {
      let s = searchValue ? { searchText: searchValue } : "";
      filterValue = { ...filterValue, ...s };
    } else {
      filterValue = searchValue ? { searchText: searchValue } : "";
    }
    axios
      .get(
        process.env.React_App_baseUrl +
        "CompanyOwnershipJob/GetCompanyOwnershipAssetsForCreateJobs",
        {
          headers: {
            sortColumn: sorting.orderBy,
            sourceFilter: filterValue
              ? encodeURIComponent(JSON.stringify(filterValue))
              : "",
            isAscending: sorting.order,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
          params: { pageNumber: page + 1, pagesize: rowsPerPage },
        }
      )
      .then(function (response) {
        setCompany([]);
        //setIsSelected(false);
        const allAssetGuids = response.data.assetGuids.map(item => item.assetGuid);
        setAllAssetIds(response.data.assetGuids)
        //console.log("all", allAssetGuids)
        setTotalCount(response.data.companyOwnershipAssetForCreateJobs.totalCount);
        const rowsData = [];
        //console.log("kkk", response)
        response.data.companyOwnershipAssetForCreateJobs.data.forEach((row) => {
          rowsData.push({
            isCRUAsset: row.isCRUAsset,
            assetId: row.assetId,
            asset: row.asset,
            companyId: row.companyId,
            company: row.company,
            commodity: row.commodity,
            country: row.country,
            region: row.region,
            subRegion: row.subRegion,
            operator: row.operator,
            ownershipPercentage: row.ownershipPercentage,
            isListedCompanyGrid: row.isListedCompanyGrid,
            //isSelected: false,
          });
        });
        const repItem = response.data.companyOwnershipAssetForCreateJobs.data.map((el) => {
          if (deselectedValue.length || selectedValue.length) {
            if (deselectedValue.length) {
              let isDeselected = false;
              for (const e of deselectedValue) {
                if (e.assetGuid === el.assetGuid && e.isCRUAsset === el.isCRUAsset) {
                  isDeselected = true;
                  break;
                }
              }
              el.isSelected = !isDeselected;
            }
            if (selectedValue.length) {
              let isSelected = false;
              for (const e of selectedValue) {
                if (e.assetGuid === el.assetGuid && e.isCRUAsset === el.isCRUAsset) {
                  isSelected = true;
                  break;
                }
              }
              el.isSelected = isSelected;
            }
          } else {
            el.isSelected = isAllSelected;
          }
        
          return el;
        });
        
        setCompany([...repItem]);
        setIsLsoading(false);
      })
      .catch(function (error) {
        setIsLsoading(false);
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });
  };

  const handleKeyUp = (event) => {
    if (
      (event.keyCode === 13 || event.which === 13) &&
      event.target.nodeName !== "BUTTON"
    ) {
      getCompany();
    }
  };

  const globalSearch = (value) => {
    setPage(0);
    setSearchValue(value ? value : "");
  };

  const searchClear = () => {
    setIsLsoading(true);
    setSearchValue("");
    axios
      .get(
        process.env.React_App_baseUrl +
        "CompanyOwnershipJob/GetCompanyOwnershipAssetsForCreateJobs",
        {
          headers: {
            sourceFilter: filterValue
              ? encodeURIComponent(JSON.stringify(filterValue))
              : "",
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
          params: { pageNumber: page + 1, pagesize: rowsPerPage },
        }
      )
      .then(function (response) {
        //setIsSelected(false);
        setCompany([]);
        setTotalCount(response.data.companyOwnershipAssetForCreateJobs.totalCount);
        const rowsData = [];

        response.data.companyOwnershipAssetForCreateJobs.data.forEach((row) => {
          rowsData.push({
            isCRUAsset: row.isCRUAsset,
            assetId: row.assetId,
            asset: row.asset,
            companyId: row.companyId,
            company: row.company,
            commodity: row.commodity,
            country: row.country,
            region: row.region,
            subRegion: row.subRegion,
            operator: row.operator,
            ownershipPercentage: row.ownershipPercentage,
            isListedCompanyGrid: row.isListedCompanyGrid,
            isSelected: false,
          });
        });

        setCompany([...rowsData]);
        setIsLsoading(false);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          setIsLsoading(false);
          toasterError(res);
        });
      });
  };

  /////Filter side drawer
  const toggleDrawer2 = (open) => (event) => {
    setState2({ ...state2, right: open });
    setShowFilter(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log(+event.target.value, "setRowsPerPage");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function createSendData(companyId, isCRUCompany, assetId, isCRUAsset) {
    return { companyId, isCRUCompany, assetId, isCRUAsset };
  }

  const mapToCompanyId = (id, isCRUCompany) => {
    if (isCRUCompany) {
      for (let i = 0; i < companyDropDownTrue.length; i++) {
        if (companyDropDownTrue[i].companyId === id) {
          setCreateJobData(companyDropDownTrue[i]);
        }
      }
    } else if (!isCRUCompany) {
      for (let i = 0; i < companyDropDownFalse.length; i++) {
        if (companyDropDownFalse[i].companyId === id) {
          setCreateJobData(companyDropDownFalse[i]);
        }
      }
    }
  };

  /*   const overAllCheckOnCHange = (event) => {
      // console.log(event);
      let cmpData = company.map((el) => {
        el.isSelected = !isAllSelected;
        return el;
      });
      setCompany(cmpData);
      setIsSelected(!isAllSelected);
      setPreserveState(cmpData);
    }; */
  const overAllCheckOnCHange = (event) => {
     company.forEach((el) => {
      el.isSelected = !isAllSelected;
      //return el;
    });
    if (!isAllSelected) {
      setSelectedValue(allassetIds)
    } else {
      setSelectedValue([])
    }
    setCompany([...company]);
    setIsSelected(!isAllSelected); 

  };
  //console.log("selectedValue", selectedValue)
  const createJob = () => {
    // setState({ ...state, right: true });
    //setNewJob(false);
    //event.stopPropagation();

    //console.log(company);
    let selectedCompany = selectedValue;
    /*  company.forEach((element) => {
       console.log("eement",element)
       if (element.isSelected) {
         selectedCompany.push({
           isCRUAsset: element.isCRUAsset,
           assetGuid:element.assetGuid,
           assetId: element.assetId,
           asset: element.asset,
           companyId: element.companyId,
           company: element.company,
         });
       }
     }); */

    if (selectedCompany.length == 0) {
      toasterError("Please select any asset");
      return;
    }
    setSelectedCompanyForCreateJob(selectedCompany);
    setOpenCreate(true);
    setCrudState(true);
  };

  const bulkUpload = () => {
    setOpen(true);
    setScroll("paper");
  };

  const bulkUploadDrawer = () => {
    toggleDrawer3(true);
  };

  //try
/*   const individualCheckOnChange = (row, level, index) => {
     setLevel(level);
 debugger
    if (row.isSelected) {
      setIsSelected(false);
      let seldValue = selectedValue
      const index = seldValue.find((e) => e.assetGuid === row.assetGuid && e.isCRUAsset === row.isCRUAsset);
      if (index !== undefined && index !== null) {
        seldValue.splice(index, 1);
        setSelectedValue(seldValue)
        console.log("checkerr", seldValue.length, allassetIds.length)
      }

      let deSelectedItem = deselectedValue
      if ((deSelectedItem.find((e) => e.assetGuid === row.assetGuid && e.isCRUAsset === row.isCRUAsset))) {
        deSelectedItem.push({ assetGuid: row.assetGuid, isCRUAsset: row.isCRUAsset })
        setDeselectedValue(deSelectedItem)
        console.log("checkerr", deSelectedItem)

      }
    } else {
      let dseldValue = deselectedValue
      const index = dseldValue.find((e) => e.assetGuid === row.assetGuid && e.isCRUAsset === row.isCRUAsset)
      if (index !== undefined && index !== null) {
        dseldValue.splice(index, 1);
        setDeselectedValue(dseldValue)
      }
      let selectedItem = selectedValue
      if ((selectedItem.find((e) => e.assetGuid === row.assetGuid && e.isCRUAsset === row.isCRUAsset))) {
        selectedItem.push({ assetGuid: row.assetGuid, isCRUAsset: row.isCRUAsset })
         if (selectedItem.length === allassetIds.length) {
          setIsSelected(true);
        } else {
          setIsSelected(false);
        } 
        setSelectedValue(selectedItem)
      }
    }
    row.isSelected = !row.isSelected;
    setCompany([...company]); 
  }; */

  //try2
  const individualCheckOnChange = (row, level, index) => {
    setLevel(level);
    if (row.isSelected) {
      setIsSelected(false);
      // Create a new array to avoid mutating state directly
      const updatedSelectedValue = selectedValue.filter(
        (e) => e.assetGuid !== row.assetGuid || e.isCRUAsset !== row.isCRUAsset
      );
      setSelectedValue(updatedSelectedValue);
      // Check if the row is already in deselectedValue
      if (!deselectedValue.some(
        (e) => e.assetGuid === row.assetGuid && e.isCRUAsset === row.isCRUAsset
      )) {
        const updatedDeselectedValue = [...deselectedValue, {
          assetGuid: row.assetGuid,
          isCRUAsset: row.isCRUAsset
        }];
        setDeselectedValue(updatedDeselectedValue);
      }
    } else {
      const updatedDeselectedValue = deselectedValue.filter(
        (e) => e.assetGuid !== row.assetGuid || e.isCRUAsset !== row.isCRUAsset
      );

      setDeselectedValue(updatedDeselectedValue);


      const updatedSelectedValue = selectedValue.filter(
        (e) => e.assetGuid !== row.assetGuid || e.isCRUAsset !== row.isCRUAsset
      );

      updatedSelectedValue.push({
        assetGuid: row.assetGuid,
        isCRUAsset: row.isCRUAsset
      });

      setSelectedValue(updatedSelectedValue);

      // Check if all assets are selected
      setIsSelected(updatedSelectedValue.length === allassetIds.length);
    }

    // Update row isSelected property
    row.isSelected = !row.isSelected;
    setCompany([...company]);
  };
  
  

  /*   const individualCheckOnChange = (row, level, index) => {
    // if (level === "outer") {
    //   setLevel(level);
    //   row.isSelected = !row.isSelected;
    //   // row.isIndetSelected = false;
    // }
    company.forEach((element) => {
      if (
        element.assetId === row.assetId &&
        element.companyId === row.companyId &&
        element.isCRUAsset === row.isCRUAsset
      ) {
        element.isSelected = !element.isSelected;
      }
    });
    setCompany([...company]);
    const selected = row.isSelected
      ? company.every((el) => el.isSelected)
      : false;
    setIsSelected(selected);
    // let lastState = preserveState;
    // let duplicateRow = lastState.filter((e) => { return e.company === row.company && e.isSelected !== row.isSelected })
    // if (duplicateRow !== null) {
    //   let index = lastState.indexOf(duplicateRow);
    //   lastState.splice(index, 1);
    //   lastState.push(row)
    // }
    // setPreserveState(lastState)
    // console.log(selected)
  }; */

  const openAddRecords = (open, mode, e) => (event, item) => {
    setOpenRecords(true);
    setScrollRecords("paper");
  };

  React.useEffect(() => {
    getFilterData();
    getCompany();
    getAssetTypeDropDownForFilter();
  }, [reload]);

  const getFilterData = async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      if (response.data.length === 0) {
        setDataForFilter([]);
      } else {
        setDataForFilter(response.data);
        response.data.companies.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
          }
        });
        response.data.location.regions.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
            if (item.children) {
              item.children.forEach((item, index) => {
                item.checked = false;
              });
            }
          }
        });
        setCompanyFilter(response.data.companies);
        setLocationFilter(response.data.location.regions);
      }
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res);
      });
    }
  };

  const getAssetTypeDropDownForFilter = async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetAssetTypeDropDownFilter`,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      if (response.data.length === 0) {
        setAssetTypeDD([]);
      } else {
        setAssetTypeDD(response.data);
      }
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res);
      });
    }
  };

  const resetTreeFilter = () => {
    companyFilter.forEach((item, index) => {
      item.checked = false;
      if (item.children) {
        item.children.forEach((item, index) => {
          item.checked = false;
        });
      }
    });
    locationFilter.forEach((item, index) => {
      item.checked = false;
      if (item.children) {
        item.children.forEach((item, index) => {
          item.checked = false;
        });
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
        }
      }
    });
  };

  const toggleDrawer3 = (open, mode, e) => (event, item) => {
    //console.log("toggleDrawer 3")
    setState3({ ...state3, right: open });
    setShowBulk(true);
  };
  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={5} className="page-title">
          <h2>Create Jobs</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/ownership-changes/source-management" }}
            >
              Home
            </Link>
            <Typography color="text.primary">
              Manage Company Ownership Changes
            </Typography>
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/ownership-changes/create-job" }}
            >
              Job Management
            </Link>
            <Typography color="text.primary">Create Jobs</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={7}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={"auto"}>
              {props.location.state && (
                <Button
                  onClick={openAddRecords()}
                  variant="contained"
                  color="accent2"
                  startIcon={<AddIcon />}
                >
                  Add Records
                </Button>
              )}
            </Grid>
            <Grid item xs={"auto"}>
              {/* {props.location.state || access.allowEdit && <Button */}
              {access.allowEdit && (
                <Button
                  onClick={createJob}
                  variant="contained"
                  color="accent2"
                  startIcon={<AddIcon />}
                >
                  Create Job
                </Button>
              )}
            </Grid>
            <Grid item xs={"auto"}>
              {access.allowView && (
                <Button
                  onClick={handleClickOpen()}
                  variant="contained"
                  color="secondary"
                  startIcon={<GroupIcon />}
                >
                  View Job Records
                </Button>
              )}
            </Grid>
            {/* <Grid item xs={"auto"}>
              <Button
                onClick={bulkUploadDrawer}
                variant="contained" color="secondary"
                startIcon={<CloudUploadOutlinedIcon />}
              >
                Bulk Upload Drawer
              </Button>  */}
            <Grid item xs={"auto"}>
              <Button
                // onClick={bulkUploadDrawer}
                onClick={toggleDrawer3(true)}
                variant="contained"
                color="secondary"
                startIcon={<CloudUploadOutlinedIcon />}
              >
                Bulk Upload
              </Button>
              {/* </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      size="small"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="searchByClear"
                        onClick={searchClear}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Button
                      onClick={toggleDrawer2(true)}
                      variant="contained"
                      color="secondary"
                      startIcon={<FilterAltOutlinedIcon />}
                    >
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}
              <LoaderWrapper loading={isLoading}>
                <TableContainer
                  component={Paper}
                  elevation={0}
                  className={classes.container}
                >
                  <Table aria-label="collapsible table" stickyHeader>
                    <TableHead>
                      <TableRow hover>
                        <TableCell
                          key={"checkboxIdThree"}
                          padding="checkbox"
                          style={{ width: "2%" }}
                        >
                          <Checkbox
                            checked={isAllSelected}
                            onChange={overAllCheckOnCHange}
                            inputProps={{ "aria-label": "Select All" }}
                            indeterminate={((selectedValue.length) > 0 && (selectedValue.length !== totalCount))}

                          />
                        </TableCell>
                        <TableCell
                          style={{ width: "2%" }}
                          className={classes.tableHeader}
                        />
                        <TableCell
                          style={{ minWidth: "200px", width: "25%" }}
                          className={classes.tableHeader}
                          sortDirection={
                            sorting.orderBy === "Asset"
                              ? sorting.order
                                ? "asc"
                                : "desc"
                              : false
                          }
                        >
                          <TableSortLabel
                            active={sorting.orderBy === "Asset"}
                            direction={
                              sorting.orderBy === "Asset"
                                ? sorting.order
                                  ? "asc"
                                  : "desc"
                                : "asc"
                            }
                            onClick={(e) => handleRequestSort(e, "Asset")}
                          >
                            Asset
                            {sorting.orderBy === "Asset" ? (
                              <Box component="span" sx={visuallyHidden}>
                                {sorting.order === false
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableHeader}
                          style={{ minWidth: "250px" }}
                        >
                          Commodity
                        </TableCell>
                        <TableCell
                          style={{ minWidth: "180px", width: "13%" }}
                          align="left"
                          className={classes.tableHeader}
                          sortDirection={
                            sorting.orderBy === "Country"
                              ? sorting.order
                                ? "asc"
                                : "desc"
                              : false
                          }
                        >
                          <TableSortLabel
                            active={sorting.orderBy === "Country"}
                            direction={
                              sorting.orderBy === "Country"
                                ? sorting.order
                                  ? "asc"
                                  : "desc"
                                : "asc"
                            }
                            onClick={(e) => handleRequestSort(e, "Country")}
                          >
                            Country
                            {sorting.orderBy === "Country" ? (
                              <Box component="span" sx={visuallyHidden}>
                                {sorting.order === false
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: "180px", width: "13%" }}
                          align="left"
                          className={classes.tableHeader}
                          sortDirection={
                            sorting.orderBy === "Region"
                              ? sorting.order
                                ? "asc"
                                : "desc"
                              : false
                          }
                        >
                          <TableSortLabel
                            active={sorting.orderBy === "Region"}
                            direction={
                              sorting.orderBy === "Region"
                                ? sorting.order
                                  ? "asc"
                                  : "desc"
                                : "asc"
                            }
                            onClick={(e) => handleRequestSort(e, "Region")}
                          >
                            Region
                            {sorting.orderBy === "Region" ? (
                              <Box component="span" sx={visuallyHidden}>
                                {sorting.order === false
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: "180px", width: "15%" }}
                          align="left"
                          className={classes.tableHeader}
                          sortDirection={
                            sorting.orderBy === "SubRegion"
                              ? sorting.order
                                ? "asc"
                                : "desc"
                              : false
                          }
                        >
                          <TableSortLabel
                            active={sorting.orderBy === "SubRegion"}
                            direction={
                              sorting.orderBy === "SubRegion"
                                ? sorting.order
                                  ? "asc"
                                  : "desc"
                                : "asc"
                            }
                            onClick={(e) => handleRequestSort(e, "SubRegion")}
                          >
                            Sub Region
                            {sorting.orderBy === "SubRegion" ? (
                              <Box component="span" sx={visuallyHidden}>
                                {sorting.order === false
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: "250px", width: "15%" }}
                          align="left"
                          className={classes.tableHeader}
                          sortDirection={
                            sorting.orderBy === "Operator"
                              ? sorting.order
                                ? "asc"
                                : "desc"
                              : false
                          }
                        >
                          <TableSortLabel
                            active={sorting.orderBy === "Operator"}
                            direction={
                              sorting.orderBy === "Operator"
                                ? sorting.order
                                  ? "asc"
                                  : "desc"
                                : "asc"
                            }
                            onClick={(e) => handleRequestSort(e, "Operator")}
                          >
                            Operator
                            {sorting.orderBy === "Operator" ? (
                              <Box component="span" sx={visuallyHidden}>
                                {sorting.order === false
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: "300px", width: "15%" }}
                          align="left"
                          className={classes.tableHeader}
                          sortDirection={
                            sorting.orderBy === "Company"
                              ? sorting.order
                                ? "asc"
                                : "desc"
                              : false
                          }
                        >
                          <TableSortLabel
                            active={sorting.orderBy === "Company"}
                            direction={
                              sorting.orderBy === "Company"
                                ? sorting.order
                                  ? "asc"
                                  : "desc"
                                : "asc"
                            }
                            onClick={(e) => handleRequestSort(e, "Company")}
                          >
                            Owner
                            {sorting.orderBy === "Company" ? (
                              <Box component="span" sx={visuallyHidden}>
                                {sorting.order === false
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: "110px", width: "15%" }}
                          align="center"
                          className={classes.tableHeader}
                          sortDirection={
                            sorting.orderBy === "OwnershipPercentage"
                              ? sorting.order
                                ? "asc"
                                : "desc"
                              : false
                          }
                        >
                          <TableSortLabel
                            active={sorting.orderBy === "OwnershipPercentage"}
                            direction={
                              sorting.orderBy === "OwnershipPercentage"
                                ? sorting.order
                                  ? "asc"
                                  : "desc"
                                : "asc"
                            }
                            onClick={(e) =>
                              handleRequestSort(e, "OwnershipPercentage")
                            }
                          >
                            Ownership %
                            {sorting.orderBy === "OwnershipPercentage" ? (
                              <Box component="span" sx={visuallyHidden}>
                                {sorting.order === false
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: "110px", width: "15%" }}
                          align="center"
                          className={classes.tableHeader}
                          sortDirection={
                            sorting.orderBy === "IsListedCompany"
                              ? sorting.order
                                ? "asc"
                                : "desc"
                              : false
                          }
                        >
                          <TableSortLabel
                            active={sorting.orderBy === "IsListedCompany"}
                            direction={
                              sorting.orderBy === "IsListedCompany"
                                ? sorting.order
                                  ? "asc"
                                  : "desc"
                                : "asc"
                            }
                            onClick={(e) =>
                              handleRequestSort(e, "IsListedCompany")
                            }
                          >
                            Listed Company
                            {sorting.orderBy === "IsListedCompany" ? (
                              <Box component="span" sx={visuallyHidden}>
                                {sorting.order === false
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {company.map((row, index) => (
                        <Row
                          key={row?.companyId + "-" + index}
                          row={row}
                          setState={setState}
                          setCrudState={setCrudState}
                          filterValue={filterValue}
                          searchValue={searchValue}
                          setCompanyId={setCompanyId}
                          setAssetId={setAssetId}
                          isAllSelected={isAllSelected}
                          individualCheckOnChange={individualCheckOnChange}
                          company={company}
                          setCompany={setCompany}
                          PreserveState={preserveState}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </LoaderWrapper>
              <TablePagination
                rowsPerPageOptions={[10, 15, 25, 50, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </BaseLayout>
        </Grid>

        <ScrollingDialog
          open={openCreate}
          setOpen={(value) => {
            setOpenCreate(value);
            setPageRefresh(!pageRefresh);
          }}
          scroll={scroll}
          setScroll={setScroll}
          dialogClass="darkTitle"
          dialogTitle="Create Jobs"
          dialogContent={
            <CreateMultipleJobPage
              state={state}
              selectedCompanyForCreateJob={selectedCompanyForCreateJob}
              isAllSelected={isAllSelected}
              filterValue={filterValue}
              searchValue={searchValue}
              setState={setState}
              setOpen={setOpen}
              setPageRefresh={setPageRefresh}
              setClose={(value) => {
                setOpenCreate(value);
                setPageRefresh(!pageRefresh);
              }}
            />
          }
        />

        <ScrollingDialog
          open={openBulkUpload}
          setOpen={(value) => {
            setOpenBulkUpload(value);
          }}
          scroll={scroll}
          setScroll={setScroll}
          dialogClass="darkTitle"
          dialogTitle="Bulk Upload"
          dialogContent={
            <BulkUploadViewer
              state={state}
              selectedValue={selectedValue}
              setState={setState}
              setOpenBulkUpload={(value) => {
                setOpenBulkUpload(value);
              }}
              setPageRefresh={setPageRefresh}
              uploadedExcelData={uploadedExcelData}
              setClose={(value) => {
                setOpenBulkUpload(value);
                setPageRefresh(!pageRefresh);
              }}
            />
          }
        />

        <SideDrawer
          formComp={
            <BulkUpload
              bulkUploadData={(data) => {
                if (data !== undefined && data !== null) {
                  setUploadedExcelData(data);
                  setState3({ ...state3, right: false });
                  setShowBulk(false);
                  setOpenBulkUpload(true);
                  setScroll("paper");
                }
              }}
            />
          }
          state={state3}
          setState={setState3}
          crudState={showBulk}
        />

        {/* <SideDrawer
          formComp={
            <CreateJobPage
              assetValue={assetValue}
              setPageRefresh={setPageRefresh}
              setLevel={setLevel}
              createJobData={createJobData}
              companyData={company.filter(item => item.isSelected || item.isIndetSelected)}
              state={state}
              setState={setState}
              companyDropDown={companyDropDown}
              assetTypeDropDown={assetTypeDropDown}
              regionDropDown={regionDropDown}
              disableAsset={disableAsset}
              setDisableAsset={setDisableAsset}
              // subRegionDropDown={subRegionDropDown}
              // countryDropDown={countryDropDown}
              commodityDropDown={commodityDropDown}
              statusDropDown={statusDropDown}
              isAssetSelected={isAssetSelected}
              setIsAssetSelected={setIsAssetSelected}
              assetIndex={assetIndex}
              setAssetIndex={setAssetIndex}
              companyValue={companyValue}
              newJob={newJob}
              setNewJob={setNewJob}
            />
          }
          state={state}
          setState={setState}
          crudState={crudState}
          setPageRefresh={setPageRefresh}
          setLevel={setLevel}
          setIsAssetSelected={setIsAssetSelected}
        /> */}

        <SideDrawer
          formComp={
            <FilterJobRecords
              dataForFilter={dataForFilter}
              filterFinalData={filterFinalData}
              companyFilter={companyFilter}
              locationFilter={locationFilter}
              setFilterValues={setFilterValues}
              assetTypeDD={assetTypeDD}
              setFilterFinalData={setFilterFinalData}
              setFilterValue={setFilterValue}
              state={state2}
              setState={setState2}
              reload={reload}
              setReload={setReload}
              resetTreeFilter={resetTreeFilter}
              setJobCompanies={setJobCompanies}
              setJobLocations={setJobLocations}
              setLocationFilter={setLocationFilter}
              setCompanyFilter={setCompanyFilter}
              setCommodityList={setCommodityList}
              setPriorityList={setPriorityList}
              setDataForFilter={setDataForFilter}
              getFilterData={getFilterData}
            />
          }
          state={state2}
          setState={setState2}
          crudState={showFilter}
          reload={reload}
          setReload={setReload}
          setFilterValue={setFilterValue}
        />

        <ScrollingDialog
          open={openRecords}
          setOpen={setOpenRecords}
          scroll={scrollRecords}
          setScroll={setScrollRecords}
          dialogClass="darkTitle"
          dialogTitle={"Add Records"}
          dialogContent={
            <AddRecords
              jobid={jobIdForAddingRecords}
              companyData={company}
              isAllSelected={isAllSelected}
              filterValue={filterValue}
              searchValue={searchValue}
              setOpenRecords={setOpenRecords}
            />
          }
        ></ScrollingDialog>
      </Grid>
    </Box>
  );
};

export default OwnershipChangesCreateJob;
