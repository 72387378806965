
import { makeStyles } from "@mui/styles";

const inputSize = 40;
const useStyles = makeStyles(theme => ({
    root: {
        alignContent: 'flex-start'
    },
    sizeMd: {
        minHeight: `${ inputSize * 2 }px`
    },
    sizeLg: {
        minHeight: `${ inputSize * 4 }px`
    }
    /* container: {
        padding:"10px",
        border:"1px solid #c0c0c0", 
        borderBottomColor:"#c0c0c0",
        borderRadius:"5px",
        minHeight:"111px",
        marginTop:"8px",
        marginBottom:"32px",
        alignItems: "start",
    }, 
    isRow:{
        padding:"10px",
        border:"1px solid #c0c0c0", 
        borderBottomColor:"#c0c0c0",
        borderRadius:"5px",
        marginTop:"8px",
        marginBottom:"32px",
    } */
}));

export default useStyles;