// Default React, Router and Services Components
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

// Importing Custom Components
import { Button,Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import MultiSelect from "../../../../UIComponent/MultiSelect/MultiSelect";
import ApiService from "../../../../Services/ApiService";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
import {
  clearFilterJobRecords,
  clearFilterQC,
  GetAssetData,
  setFilterJobRecords,
  setFilterQC,
} from "../../../../features/filter/filterSlice";
import {
  callBasedOnLocation,
  callFromAssetType,
  callFromComm,
  callFromPriority,
  callSource,
} from "../../../../Util/TreeAPI";

// Importing useStyles
import useStyles from "./MeritQCFilter.styles";import "react-dropdown-tree-select/dist/styles.css";
import "../../../../../src/index.scss";
import "../../../../Resources/scss/layout.scss";
import { clearMeritQCFilter, setMeritQCFilter } from "../../../../features/filter/StateSlice";
import Container from '../../SourceManagement/TreeContainer';
import { FilterContext } from "../Context/Context";



const FilterForm = (props) => {
  const classes = useStyles();
  const { filterQC, assetDropDownlist } = useSelector(
    (store) => store.filter
  );
  const {companyList, setCompanyList, locationQC,
    setLocationQC, commodsQC, setCommodsQC} = useContext(FilterContext)
  const dispatch = useDispatch();

  // Declaration of States and Component Variables Start Here
  const [allState, setAllState] = React.useState(props);
  const [status, setStatus] = React.useState([]);
  const [jobComms, setJobComms] = React.useState([]);
  const [prior, setPrior] = React.useState([]);
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  const [filters, setFilters] = React.useState({
    company: [],
    asset: [],
    assetTypeIds: [],
    countryIds: [],
    regionIds: [],
    subRegionIds: [],
    commodityIds: [],
    statusIds: [],
    priorityIds: [],
  });
  const [apiData, setApiData] = React.useState({
    companies: [],
    location: {},
    assetType: [],
    priority: [],
    commodity: [],
  });

  const [dependency, setDependency] = React.useState(null);
  const [subCompDependency, setSubCompDependency] = React.useState(false);
  const [subLocDependency, setSubLocDependency] = React.useState(false);

  // Declaration of States and Component Variables End Here

  const onChangeHandler = (key, value, reason) => {
    const pre = { ...filterQC };
    pre[key] = value;
    if (key === "commodity") {
        setDependency("commodity");
      
      setApiData({
        ...apiData,
        commodity: value,
      });
    } else if (key === "priority") {
        setDependency("priority");
      setApiData({
        ...apiData,
        priority: value,
      });
    } else if (key === "assetType") {
        setDependency("assetType");
      setApiData({
        ...apiData,
        assetType: value,
      });
    }
    dispatch(setFilterQC({ filterQC: pre }));
  };


  //Function for clear data while click on clear button 
  const clearFieldHanlder = () => {
    setFilters({
      filters,
      company: [],
      asset: [],
      assetTypeIds: [],
      countryIds: [],
      regionIds: [],
      subRegionIds: [],
      commodityIds: [],
      statusIds: [],
      priorityIds: [],
    });
    setApiData(null)
    props.resetTreeFilter();
    dispatch(clearFilterQC());
    dispatch(clearMeritQCFilter());
    props.setFilterValue("");
    props.setReload(!props.reload);
    props.getFilterData();
    setCompanyList([])
    setLocationQC([])
    setDependency(null);
    setSubCompDependency(false);
    setSubLocDependency(false);
  };

  //Function for fetch all status
  const getAllStatus = () => {
    ApiService.getAll("DropDown/GetStatusDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setStatus(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        if(err.res.status===400){
          toasterError(err.res.data);
        }

         });
  };

  //Function for fetch all commodities
  const getAllCommodities = () => {
    ApiService.getAll("DropDown/GetCommodityDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setJobComms(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        if(err.res.status===400){
          toasterError(err.res.data);
        }

         });
  };

  //Function for fetch all Priority
  const getAllPriority = () => {
    ApiService.getAll("DropDown/GetPriorityDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setPrior(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        if(err.res.status===400){
          toasterError(err.res.data);
        }

         });
  };

  // Declaration of React Hooks Start Here

  React.useEffect(() => {
    setAllState([...props.companyList]);
  }, [props]);

  React.useEffect(() => {
    getAllStatus();
    dispatch(GetAssetData());
    getAllCommodities();
    getAllPriority();
  }, []);

  React.useEffect(() => {
    if (dependency === "company") {
      callSource(apiData)
        .then((res) => {
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationQC){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationQC){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationQC){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          props.setLocationQC(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "location") {
      callBasedOnLocation(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyList.length; i++) {
              if (props.companyList[i].value === el.value) {
                if (props.companyList[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyList[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyList[i].children[j].value === item.value
                    ) {
                      if (props.companyList[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyList(res.data.companies);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
          setSubCompDependency(true);
        })
        .catch((err) => console.log(err));
    } else if (dependency === "assetType") {
      callFromAssetType(apiData)
        .then((res) => {
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationQC){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationQC){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationQC){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyList.length; i++) {
              if (props.companyList[i].value === el.value) {
                if (props.companyList[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyList[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyList[i].children[j].value === item.value
                    ) {
                      if (props.companyList[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyList(res.data.companies);
          props.setLocationQC(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "priority") {
      callFromPriority(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyList.length; i++) {
              if (props.companyList[i].value === el.value) {
                if (props.companyList[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyList[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyList[i].children[j].value === item.value
                    ) {
                      if (props.companyList[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationQC){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationQC){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationQC){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          props.setCompanyList(res.data.companies);

          props.setLocationQC(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "commodity") {
      callFromComm(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyList.length; i++) {
              if (props.companyList[i].value === el.value) {
                if (props.companyList[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyList[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyList[i].children[j].value === item.value
                    ) {
                      if (props.companyList[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationQC){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationQC){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationQC){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          props.setCompanyList(res.data.companies);
          props.setLocationQC(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [apiData]);

  // Declaration of React Hooks End Here

  // onChangeComp is a function that sets the dependency to "company" and sets the selected companies
const onChangeComp = (currentNode, selectedNodes) => {
    
      // Set the dependency to "company"
      setDependency("company");
    
    // Set the selected companies
    setSelectedCompanies(selectedNodes);

    // Loop through the company list and check/uncheck the current node
    props.companyList.forEach((el) => {
      if (currentNode.checked === true) {
        if (currentNode.value === el.value) {
          el.checked = true;
        } else if (currentNode.parentValue === el.value) {
          el.children.forEach((item) => {
            if (currentNode.value === item.value) {
              item.checked = true;
            }
          });
        }
      } else if (currentNode.checked === false) {
        if (currentNode.value === el.value) {
          el.checked = false;
        } else if (currentNode.parentValue === el.value) {
          el.children.forEach((item) => {
            if (currentNode.value === item.value) {
              item.checked = false;
            }
          });
        }
      }
    });

    // Get the first parent from the company list
    const firstParent = props.companyList
      .map((el) => {
        let data = {
          label: el.label,
          value: el.value,
          children: el.children.filter((item) => item["checked"] === true),
        };
        return data;
      })
      .filter((el) => el.children.length > 0);
   

    // Set the API data with the filtered companies
    setApiData({
      ...apiData,
      companies:
        props.companyList.filter((el) => el["checked"] === true).length > 0
          ? props.companyList.filter((el) => el["checked"] === true)
          : firstParent,
    });
  };
 

// This function is used to set the dependency of location
const onChangeLoc = (currentNode, selectedNodes) => {
  setDependency("location");
// If no nodes are selected, set all checked values to false
if (selectedNodes.length === 0) {
  props.locationQC.forEach((el) => {
    el["checked"] = false;
    el.children.forEach((it) => {
      it["checked"] = false;
      it.children.forEach((kt) => {
        kt["checked"] = false;
      });
    });
  });
}

// If current node is checked, set the corresponding value to true
if (currentNode.checked === true) {
  if (currentNode.grantParentValue) {
    props.locationQC.forEach((el) => {
      el.children.forEach((first) => {
        first.children.forEach((second) => {
          if (currentNode.value === second.value) {
            second.checked = true;
            filters.countryIds.push(currentNode.value)
          }
        });
      });
    });
  } else if (currentNode.parentValue) {
    props.locationQC.forEach((el) => {
      el.children.forEach((first) => {
        if (currentNode.value === first.value) {
          first.checked = true;
        }
        
      });
    });
  } else if (currentNode.value) {
    props.locationQC.forEach((el) => {
      if (currentNode.value === el.value) {
        el.checked = true;
      }
    });
  }
  
} 
// If current node is unchecked, set the corresponding value to false
else if (currentNode.checked === false) {
  if (
    currentNode.value &&
    !currentNode.parentValue &&
    !currentNode.grantParentValue
  ) {
    props.locationQC.forEach((el) => {
      if (el.value === currentNode.value) {
        el.checked = false;
      }
    });
  }
  if (
    currentNode.value &&
    currentNode.parentValue &&
    !currentNode.grantParentValue
  ) {
    props.locationQC.forEach((el) => {
      el.children.forEach((first) => {
        if (currentNode.value === first.value) {
          first.checked = false;
        }
      });
    });
  } else {
    props.locationQC.forEach((el) => {
      if (
        el.children.map((it) => it.parentValue)[0] === currentNode.value
      ) {
        el.children.forEach((item) => {
          if (item.parentValue === currentNode.value) {
            item.checked = false;
          }
        });
      }
    });
  }

  if (
    currentNode.value &&
    currentNode.parentValue &&
    currentNode.grantParentValue
  ) {
    props.locationQC.forEach((el) => {
      el.children.forEach((first) => {
        first.children.forEach((second) => {
          if (currentNode.value === second.value) {
            second.checked = false;
          }
        });
      });
    });
  }
}

// Set the apiData for location
const firstParent = props.locationQC
  .map((el) => {
    let data = {
      label: el.label,
      value: el.value,
      children: el.children.filter((item) => item["checked"] === true),
    };
    return data;
  })
  .filter((el) => el.children.length > 0);

const grandParent = props.locationQC
  .map((el) => {
    let data = {
      label: el.label,
      value: el.value,
      children: el.children.map((item) => {
        let items = {
          label: item.label,
          value: item.value,
          children: item.children.filter(
            (item) => item["checked"] === true
          ),
        };

        return items;
      })
    };

    return data;
  })

// Check if any grandParent has children with length greater than 0
const finalChecker = () => {
  let data = [];
  for (let i = 0; i < grandParent.length; i++) {
    for (let j = 0; j < grandParent[i].children.length; j++) {
      if (grandParent[i].children[j].children.length > 0) {
        data.push(grandParent[i]);
      }
    }
  }
  return data;
};
setApiData({
  ...apiData,
  location: {
    regions:
      props.locationQC.filter((el) => el["checked"] === true).length > 0
        ? props.locationQC.filter((el) => el["checked"] === true)
        : firstParent.length > 0
        ? firstParent
        : finalChecker(),
  },
});


};


//Function for country selection
//This function filters the locationQC props and returns an array of values
const countryFilter = ()=>{
  //Declare empty array to store filtered data
  let data = []
  //Loop through each item in the locationQC props
  for(let item of props.locationQC){
    //Loop through each child of the item
    for(let items of item.children){
      //Loop through each grandchild of the item
      for(let ites of items.children){
        //Check if the grandchild is checked
        if(ites.checked === true){
          //Push the value of the grandchild into the data array
          data.push(ites.value)
        }
      }
    }
  }

  //Return the filtered data array
  return data
}

  //Function for submit click
  const submitFilter = () => {
    const formData = {
      company: props.companyList
        .filter((el) => el.checked === true)
        .map((el) => el.value),
      asset: props.companyList
        .map((el) =>
          el.children.filter((it) => it.checked === true).map((tk) => tk.value)
        )
        .filter((item) => item.length > 0)
        .flat(1),
      countryIds: countryFilter(),
      regionIds: props.locationQC
        .filter((el) => el.checked === true)
        .map((el) => el.value),
      subRegionIds: props.locationQC
        .map((el) =>
          el.children.filter((it) => it.checked === true).map((tk) => tk.value)
        )
        .filter((item) => item.length > 0)
        .flat(1),
      assetTypeIds: filterQC.assetType.map((el) => el.assetTypeId),
      commodityIds: filterQC.commodity.map((el) => el.commodityId),
      statusIds: filterQC.status.map((el) => el.id),
      priorityIds: filterQC.priority.map((el) => el.priorityId),
    };
    // this data will be send to the body api
    setCompanyList(props.companyList)
    setLocationQC(props.locationQC)
    props.setFilterValue(formData);
    dispatch(setMeritQCFilter(formData))
    props.setSubmitValues(!props.submitValues)
    props.setState({ ...props.state, right: false });

  };

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Add & Edit Currency Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Container
            data={props.companyList}
            onChange={onChangeComp}
            showPartiallySelected={true}
            texts={{ placeholder: "Company" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Container
            data={props.locationQC}
            onChange={onChangeLoc}
            showPartiallySelected={true}
            texts={{ placeholder: "Location" }}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterQC.commodity}
            items={
              props.dataForFilter.commodity ? props.dataForFilter.commodity : []
            }
            label="Commodity"
            selectAllLabel={{ commodityName: "Select all" }}
            getOptionLabel={(option) => option.commodityName}
            getOptionSelected={(option, anotherOption) =>
              option.commodityName === anotherOption.commodityName
            }
            role="commodity"
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn"
                color="accent5"
                onClick={submitFilter}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn reset-color"
                color="accent3"
                onClick={clearFieldHanlder}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterForm;