import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
//import { createBrowserHistory } from 'history';
import {createHashHistory} from 'history';
const createHistory = createHashHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const AppInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: createHistory },
    },
  },
});

if(AppInsights.config.instrumentationKey){
  AppInsights.loadAppInsights();
}

export { reactPlugin, AppInsights };