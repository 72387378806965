// Default React, useState and Material Components
import React, { useState } from "react";
import { Button, Box, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import AutocompleteText from "../../../UIComponent/Autocomplete";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddTopProducers = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [ranks, SetRanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);
  const [defaultRankValue, setRankDefaultValue] = useState(null);
  const [defaultCompanyValue, setCompanyDefaultValue] = useState(null);
  const [ProducerData, setProducerData] = useState({
    id: 0, rank: 0, source: "", cruCompanyID: null, cruCompanyName: "", commodityID: null, commodityName: "",
  });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    SetRanks([{ "rank": 1 }, { "rank": 2 }, { "rank": 3 }, { "rank": 4 }, { "rank": 5 }, { "rank": 6 },
    { "rank": 7 }, { "rank": 8 }, { "rank": 9 }, { "rank": 10 },]);
  }, []);
  React.useEffect(() => {
    ApiService.getAll("DropDown/GetCommodityDropDownFilter")
      .then((res) => { setCommodities(res.data); })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }, []);
  React.useEffect(() => {
    ApiService.getAll(`DropDown/GetCRUCompaniesFilter`)
      .then((res) => { setCompanies(res.data); })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }, []);
  // Declaration of React Hooks End Here

  React.useEffect(() => {
    if (props.crudState === "Edit" || props.crudState === "View") {
      let data = {
        id: props.formData.id,
        rank: props.formData.rank,
        source: props.formData.source,
        cruCompanyID: props.formData.cruCompanyID,
        companyName: props.formData.cruCompanyName,
        commodityID: props.formData.commodityID,
        commodity: props.formData.commodityName,
      };
      setProducerData(data);
      companies.forEach((rData, i) => {
        if (rData.id === props.formData.cruCompanyID) { setCompanyDefaultValue(companies[i]); }
      });
      commodities.forEach((rData, i) => {
        if (rData.id === props.formData.commodityID) { setDefaultValue(commodities[i]); }
      });
      ranks.forEach((rData, i) => {
        if (rData.rank === props.formData.rank) { setRankDefaultValue(ranks[i]); }
      });
    }
  }, [companies, commodities, ranks, props]);

  // Function for Register Producer
  const registerProducer = () => {
    setLoading(true);
    if (validation(ProducerData)) {
      ApiService.create("topproducercompanies/CreateTopProducerCompany", ProducerData)
        .then((res) => {
          if (res.status === 200) {

            toasterSuccess("Top Producers details added successfully!");
            setLoading(false);
            props.hideSideDrawer();
          }
        })
        .catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res)
          });
          setLoading(false);
        });

    }
  };

  // Function for Validation
  const validation = (formData) => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    if ((formData.rank === 0 || formData.rank === null || formData.rank === '') && (formData.commodityID === null || formData.commodityID === 0 || formData.commodityID === '')
      && (formData.cruCompanyID === null || formData.cruCompanyID === 0 || formData.cruCompanyID === '') && (formData.source.trim() === "")
    ) { toasterError("Please select all required fields"); return false; }
    if (formData.rank === null || formData.rank === 0 || formData.rank === '') {
      toasterError("Please select rank number"); return false;
    }
    if (formData.commodityID === null || formData.commodityID === 0 || formData.commodityID === '') {
      toasterError("Please select commodity name"); return false;
    }
    if (formData.cruCompanyID === null || formData.cruCompanyID === 0 || formData.cruCompanyID === '') {
      toasterError("Please select company name"); return false;
    }
    if (!regex.test(formData.source)) { toasterError("Please enter valid URL"); return false; }
    if (formData.source.trim() === "") { toasterError("Please check the URL entered"); return false; }
    return true;
  }

  // Function for Update Top Producer
  const updateProducer = () => {
    setLoading(true);
    if (validation(ProducerData)) {
      ApiService.create("topproducercompanies/UpdateTopProducerCompany", ProducerData)
        .then((res) => {
          if (res.status === 200) {
            toasterSuccess("Top Producers details edited successfully!");
            setLoading(false);
            props.hideSideDrawer();
          }
        })
        .catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res)
          });
          setLoading(false);
        });
    }
  };

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Top Producer
        </Typography>
      </Box>

      {/* Add & Edit Top Producers Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AutocompleteText
            option={ranks}
            dropShow={"rank"}
            labelName={"Rank"}
            isRequired={true}
            feildName={"rank"}
            defaultValue={defaultRankValue}
            readOnly={props.crudState === "View" ? true : false}
            onChangeRole={(value) => {
              setRankDefaultValue({
                ...defaultValue, rank: value && value.rank ? value.rank : ''
              });
              setProducerData({
                ...ProducerData, rank: value && value.rank ? value.rank : ''
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteText
            option={commodities}
            dropShow={"commodityName"}
            labelName={"Commodity"}
            isRequired={true}
            feildName={"commodity"}
            defaultValue={defaultValue}
            readOnly={props.crudState === "View" ? true : false}
            onChangeRole={(value) => {
              setDefaultValue({
                ...defaultValue, commodityName: value && value.commodityName ? value.commodityName : "",
                commodityID: value && value.id ? value.id : null,
              });
              setProducerData({
                ...ProducerData, commodityName: value && value.commodityName ? value.commodityName : "",
                commodityID: value && value.id ? value.id : null,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteText
            option={companies}
            dropShow={"companyName"}
            labelName={"Company"}
            feildName={"companyName"}
            isRequired={true}
            defaultValue={defaultCompanyValue}
            readOnly={props.crudState === "View" ? true : false}
            onChangeRole={(value) => {
              setCompanyDefaultValue({
                ...defaultValue, cruCompanyName: value && value.companyName ? value.companyName : "",
                companyName: value && value.companyName ? value.companyName : "",
                cruCompanyID: value && value.id ? value.id : null,
              });
              setProducerData({
                ...ProducerData, cruCompanyName: value && value.companyName ? value.companyName : "",
                companyName: value && value.companyName ? value.companyName : "",
                cruCompanyID: value && value.id ? value.id : null,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {props.crudState === "Add" ? (
            <TextField InputProps={{ readOnly: props.crudState === "View" && true, }}
              required
              fullWidth
              id="standard-basic"
              name="source"
              size="small"
              label="Data Source"
              placeholder="Data Source"
              variant="standard"
              inputProps={{ maxLength: 100 }}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  source: e.target.value,
                });
                setProducerData({ ...ProducerData, source: e.target.value });
              }}
              value={null}
              InputLabelProps={{ style: { color: "black" } }}
            />
          ) : (
            <TextField
              InputProps={{ readOnly: props.crudState === "View" && true, }}
              required
              fullWidth
              id="standard-basic"
              name="source"
              size="small"
              label="Data Source"
              placeholder="Data Source"
              variant="standard"
              inputProps={{ maxLength: 100 }}
              onChange={(e) => {
                props.setFormData({ ...props.formData, source: e.target.value, });
                setProducerData({ ...ProducerData, source: e.target.value });
              }}
              value={props.formData.source ? props.formData.source : null}
              InputLabelProps={{ style: { color: "black" } }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={'auto'}>
                {/* {props.crudState === "Add" && (
                  <Button type="submit" variant="contained" className="rounded-border"
                    color="accent5" onClick={registerProducer}>
                    Save
                  </Button>
                )}
                {props.crudState === "Edit" && (
                  <Button type="submit" variant="contained" className="rounded-border"
                    color="accent5" onClick={updateProducer}>
                    Update
                  </Button>
                )} */}
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Add" ? registerProducer : updateProducer}
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant="contained" className="rounded-border" color="accent3"
                  onClick={() => props.setState({ ...props.state, right: false })}>
                  Cancel
                </Button></Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddTopProducers;