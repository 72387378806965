// Default React Components
import React from 'react'
import { Paper } from '@mui/material'

// Importing useStyles
import useStyles from "./baseLayout.styles";

const BaseLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <Paper elevation={1} className={classes.pageContent}>
      {children}
    </Paper>
  )
}

export default BaseLayout