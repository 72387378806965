import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

const REACT_BEARER_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjI5IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiTWVyaXRBZG1pbiIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiJzYW5kaHlhLnNyaW5pdmFzYW5AbWVyaXRncm91cC5jby51ayIsIk1hbmFnZSBDb250ZW50IjpbIlZpZXciLCJFZGl0IiwiRGVsZXRlIl0sIkV4cG9ydCBDb250ZW50IjoiVmlldyIsIk1hbmFnZSBVc2VycyI6IlZpZXciLCJNYW5hZ2UgU3ViIFRlYW1zIjoiVmlldyIsIk1hbmFnZSBTb3VyY2UgTGlua3MiOiJWaWV3IiwiVG9waWMgQ29uZmlndXJhdGlvbiI6IlZpZXciLCJEYXNoYm9hcmQiOiJWaWV3IiwiUmVwb3J0cyI6IlZpZXciLCJSb2xlIENvbmZpZ3VyYXRpb24iOiJWaWV3IiwiTWFuYWdlIE1lbnUiOiJWaWV3IiwiTWFuYWdlIFJvbGUiOiJWaWV3IiwiZXhwIjoxNjYyNzg4MDA0LCJpc3MiOiJodHRwOi8vQ1JVQVBJLmNvbSIsImF1ZCI6Imh0dHA6Ly9DUlVBUEkuY29tIn0.sym5XwE1BWTbT2GR13ihU2t52GaLGu6Hm-L2v2-NxFI";

  export const getAllAroles = createAsyncThunk(
    "source/allRolesAvailable",
    async (arg, { rejectWithValue }) => {
      try {
        const { data } = await axios.get(
          `${process.env.React_App_baseUrl}account/GetAllRoles`,{
            headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
          }
        );
        console.log(data, "job id");
        return data;
      } catch (error) {
        rejectWithValue(error.response.data);
      }
    }
  );

// export const getAllAroles = createAsyncThunk(
//   "source/allRolesAvailable",
//   async (arg, { rejectWithValue }) => {
//     try {
//       const { data } = await axios.get(
//         console.log('base url',process.env.React_App_baseUrl)
//         `http://10.101.53.53:9097/api/account/GetAllRoles`,
//       );
//       console.log(data, "get all user role ");
//       return data;
//     } catch (error) {
//       rejectWithValue(error.response.data);
//     }
//   }
// );

export const getAllUsers = createAsyncThunk(
  "source/allUsersData",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/account/GetUserDetails?pageNumber=1&pageSize=1000`,{
          headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
        });
     // console.log(data.data)
        return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  allRoles: [],
  allUsers: [],
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    testFunc: (state) => {
      return state;
    },
  },
  extraReducers: {
    ///getAll roles
    [getAllAroles.pending]: (state, { payload }) => {
      state.asyncStatus = "loading";
    },
    [getAllAroles.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.allRoles = payload;
    },
    [getAllAroles.rejected]: (state) => {
      state.asyncStatus = "failed";
    },

    ////all users
    [getAllUsers.pending]: (state, { payload }) => {
      state.asyncStatus = "loading";
    },
    [getAllUsers.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.allUsers = payload;
    },
    [getAllUsers.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
  },
});

export const { testFunc } = userSlice.actions;

export default userSlice.reducer;
