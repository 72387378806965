import { configureStore } from "@reduxjs/toolkit";
import filterReducer from "./features/filter/filterSlice";
import jobsReducer from "./features/JobManagement/jobSlice";
import userReducer from './features/UserManagement/UserSlice'
import stateReducer from './features/filter/StateSlice'

export const store = configureStore({
  reducer: {
    filter: filterReducer,
    jobs : jobsReducer,
    users : userReducer,
    stateSlice : stateReducer
  },
});
