// Default React, Router and Services Components
import React, { useContext, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Components
import BaseLayout from "../../../../UIComponent/BaseLayout";
import EmptyState from "../../../../UIComponent/EmptyState";
import { LoaderWrapper } from '../../../../UIComponent/Progress';
// Importing useStyles
import useStyles from "../StatusReport/StatusReport.styles";

// Import Fluent UI Icons
import {
  ChevronRightSmallIcon,
  CodeEditIcon,
  FileOffIcon,
} from "@fluentui/react-icons-mdl2";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GetMenuService from "../../../../Services/GetMenuService";
import { toasterError } from "../../../../UIComponent/Toaster";
import axios from "axios";
import ErroHandling from "../../../../Services/ErrorHandling";
import { clearFilterReports } from "../../../../features/filter/filterSlice";
import ExportToExcel from "../Excelexport";
import SideDrawer from "../../../../UIComponent/Drawer";
import FilterForm from "../FilterForm";

import { FilterContext } from "../Context/Context";

const OwnershipChangesStatusReport = (props) => {
  const classes = useStyles();

  const history = useHistory();
  // Declaration of States and Component Variables Start Here
  const { companyList, setCompanyList } = useContext(FilterContext);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [crudState, setCrudState] = React.useState(false);
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const { filterJobRecords} = useSelector(
    (store) => store.filter
  );

  const [excelData, setExcelData] = useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValue, setFilterValue] = useState(null);
  const [filterValues, setFilterValues] = useState("");
  const [formData, setFormData] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [reload, setReload] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null)
const [jobName, setJobName] = useState('');
  const [sorting, setSorting] = useState({ orderBy: "", order: false });
  const [state, setState] = useState({ right: false });
  const [tableData, setTableData] = useState([]);
  const [tatDate, setTatDate] = React.useState(null);
  const { filterReport, assetDropDownlist } = useSelector(
    (store) => store.filter
  );
  const [isLoading, setLoading] = useState(true);
  const date = new Date().toLocaleDateString();
  const date1 = date.split("/").join("-");
  
  // Declaration of States and Component Variables End Here
  const [access, setAccess] = React.useState({
    allowEdit: false,
    allowDelete: false,
    allowView: false,
  });

  React.useEffect(() => {
    GetMenuService(34, function (err, res) {
      if (res) {
        setAccess(res.data);
      } else {
        toasterError(err);
      }
    });
  }, []);
  const filterState = useSelector((state) => state.stateSlice);
  console.log(filterState);
  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    fetchData();
  }, [filterValue, sorting, filterState, reload]);
  React.useEffect(() => {
    getFilterData();
    return () => {
      clearValues();
      setReload(!reload);
    };
  }, [reload]);
  // Declaration of React Hooks End Here

  // Creating Data Table for Status Report
  function createData(
    priorityName,
    unassigned,
    assigned,
    open,
    meritDataCollection,
    qualityControl,
    CRUReview,
    completed,
    total
  ) {
    return {
      priorityName,
      unassigned,
      assigned,
      open,
      meritDataCollection,
      qualityControl,
      CRUReview,
      completed,
      total,
    };
  }

  // This function is used to get data from the server using axios
const getData = async () => {
  // Set loading to true
  setLoading(true);
  // Create a new promise and resolve it with an axios request
  let response = await new Promise((resolve, reject) => {
    resolve(
      axios.get(
        `${process.env.React_App_baseUrl}statusreport/GetStatusReport_OwnershipChanges`,
        {
          headers: {
            // Encode filterValue if it exists
            statusReportFilter: filterValue
              ? encodeURIComponent(JSON.stringify(filterValue))
              : null,
            // Get token from sessionStorage if it exists
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        },
      )
    );
  });
  // Return the response
  return response;
};
 

  // Function for fetch Data
  const fetchData = async () => {

    const { data } = await getData();
    const rows = data.map((el) =>
      createData(
        el.priority,
        el.unassigned,
        el.assigned,
        el.open,
        el.meritDataCollection,
        el.meritQualityControl,
        el.cruReview,
        el.completed,
        el.total
      )
    );
    setExcelData(rows);
    setTableData(data);
    setLoading(false);
  };

  // This function is used to get filter data from the API
const getFilterData = async () => {
  try {
    // Making a GET request to the API
    const response = await axios.get(
      `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`,
      {
        headers: {
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      }
    );
    // If there is no data in the response
    if (response.data.length === 0) {
      setDataForFilter([]);
    } else {
      // Setting the data for filter
      setDataForFilter(response.data);
      console.log(response.data);
      // Looping through the companies and setting checked to false
      response.data.companies.forEach((item, index) => {
        item.checked = false;
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
        }
      });
      // Looping through the locations and setting checked to false
      response.data.location.regions.forEach((item, index) => {
        item.checked = false;
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
          }
        }
      });
      // If company list is empty then set the company list
      if (companyList.length === 0) {
        setCompanyList(response.data.companies);
      }
      // Setting the company filter and location filter
      setCompanyFilter(response.data.companies);
      setLocationFilter(response.data.location.regions);
    }
  } catch (error) {
    // Handling errors
    ErroHandling(error, function (res) {
      toasterError(res);
    });
  }
};


  // Function for Global Search
  const dispatch = useDispatch();
  const clearValues = () => {
    setFilterValue(null);
    setReload(!reload);
    dispatch(clearFilterReports());
  };

  // This function resets the tree filter
const resetTreeFilter = () => {
  // Loop through each item in companyFilter and set checked to false
  companyFilter.forEach((item, index) => {
    item.checked = false;
    // If item has children, loop through them and set checked to false
    if (item.children) {
      item.children.forEach((item, index) => {
        item.checked = false;
      });
    }
  });
  // Loop through each item in locationFilter and set checked to false
  locationFilter.forEach((item, index) => {
    item.checked = false;
    // If item has children, loop through them and set checked to false
    if (item.children) {
      item.children.forEach((item, index) => {
        item.checked = false;
      });
      // If item's children have children, loop through them and set checked to false
      if (item.children) {
        item.children.forEach((item, index) => {
          item.checked = false;
        });
      }
    }
  });
  // Set TatDate to null
  setTatDate(null);
};

  // Function for Open Delete and Edit Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    setState({ ...state, right: open });
  };

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} className="page-title">
          <h2>Status Report</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>
              Home
            </Link>
            <Typography>
              Manage Company Ownership Changes
            </Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/status-report" }}>
              Dashboard and Reports
            </Link>
            <Typography>
              Status Report
            </Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* BaseLayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            {/*  <Box sx={{ pt: 11 }}>
              <center>
                <EmptyState
                  icon={
                    <CodeEditIcon
                      style={{ color: "#db9400", fontSize: "70px", marginBottom: "10px" }}
                    />
                  }
                  title="Under Construction!"
                />
              </center>
            </Box> */}
            <Box className={classes.addBorder}>
              {/* Search and Filter Options Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  spacing={{ xs: 1, sm: 2 }}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Typography variant="subtitle2">
                      <ExportToExcel
                        apiData={excelData}
                        fileName={`Status Report ${date1}`}
                        status
                      />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={toggleDrawer(true)}
                      variant="contained"
                      color="secondary"
                    >
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search and Filter Options End Here */}

              <Grid item xs={12}>
              <LoaderWrapper loading={isLoading}>
                <TableContainer
                  component={Paper}
                  elevation={0}
                  className="table-container"
                >
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader} style={{minWidth:"9%"}}>
                          Job Priority
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center",minWidth:"9%" }}
                        >
                          Unassigned
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center",minWidth:"9%" }}
                        >
                          Assigned
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center",minWidth:"9%" }}
                        >
                          Open
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center",minWidth:"9%" }}
                        >
                          Merit Data Collection
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center",minWidth:"9%" }}
                        >
                          Merit Quality Control
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center",minWidth:"9%" }}
                        >
                          CRU Review
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center",minWidth:"9%" }}
                        >
                          Completed
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center",minWidth:"9%" }}
                        >
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.length > 0 ? (
                        tableData?.map((item, index) => (
                          <TableRow key={index} hover>
                            <TableCell
                              className={classes.tableCell}
                              style={{ minWidth: 120, width: "9%" }}
                            >
                              {item.priority}
                            </TableCell>

                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "9%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "Unassigend",
                                      filter: filterState.filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.unassigned}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "Unassigend",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.unassigned}
                                </span>
                              )}
                            </TableCell>
                            {/* </Link> */}
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "9%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "Assigned",
                                      filter: filterState.filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.assigned}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "Assigned",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.assigned}
                                </span>
                              )}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "9%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "Open",
                                      filter: filterState.filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.open}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "Open",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.open}
                                </span>
                              )}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "9%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "DataCollection",
                                      filter: filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.meritDataCollection}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "DataCollection",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.meritDataCollection}
                                </span>
                              )}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "9%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "QualityControl",
                                      filter: filterState.filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.meritQualityControl}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "QualityControl",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.meritQualityControl}
                                </span>
                              )}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "9%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "CRUReview",
                                      filter: filterState.filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.cruReview}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "CRUReview",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.cruReview}
                                </span>
                              )}
                            </TableCell>

                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "9%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "Completed",
                                      filter: filterState.filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.completed}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "Completed",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.completed}
                                </span>
                              )}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "9%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "TotalPriority",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.total}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/ownership-changes/status-report-drill",
                                      state: item,
                                      item: "TotalPriority",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.total}
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            className={classes.tableCell}
                            style={{ textAlign: "center" }}
                            colSpan={99}
                          >
                            <EmptyState
                              icon={
                                <FileOffIcon
                                  style={{
                                    color: "#db9400",
                                    fontSize: "40px",
                                  }}
                                />
                              }
                              title="No Data Found"
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                </LoaderWrapper>
                <SideDrawer
                  formComp={
                    <FilterForm
                      dataForFilter={dataForFilter}
                      filterFinalData={filterFinalData}
                      companyFilter={companyFilter}
                      locationFilter={locationFilter}
                      setFilterValues={setFilterValues}
                      state={state}
                      setState={setState}
                      JobName={jobName}
                      setJobName={setJobName}
                      crudState={crudState}
                      formData={formData}
                      setFormData={setFormData}
                      setSelectedJob={setSelectedJob}
                      selectedJob={selectedJob}
                      userId={null}
                      //frm job records

                      setTatDate={setTatDate}
                      tatDate={tatDate}
                      setFilterValue={setFilterValue}
                      hideSideDrawer={() => {
                        setState({ right: false });
                        // getUserData();
                      }}
                      filterValue={filterReport}
                      setReload={setReload}
                      reload={reload}
                      resetTreeFilter={resetTreeFilter}
                      setDataForFilter={setDataForFilter}
                      setLocationFilter={setLocationFilter}
                      setCompanyFilter={setCompanyFilter}
                      companyList={companyList}
                      setCompanyList={setCompanyList}
                    />
                  }
                  state={state}
                  setState={setState}
                  reload={reload}
                  setReload={setReload}
                  setFilterValue={setFilterValue}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OwnershipChangesStatusReport;
