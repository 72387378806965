// Default React, Router and Services Components
import React from "react";
import XLSX from "sheetjs-style";
import { Button } from "@mui/material";
import * as FileSaver from "file-saver";

// Import Fluent UI Icons
import { ExportIcon } from "@fluentui/react-icons-mdl2";

const ExportToExcel = ({ apiData, fileName, drill, status, statusdrill, meritqc, meritqcdrill, searchexport, viewdata }) => {
  // Declaration of States and Component Variables Start Here
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  // Declaration of States and Component Variables End Here

  // Function for Export to CSV
  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    if (drill) {
      XLSX.utils.sheet_add_aoa(
        ws,
        [["Job Id", "Job Name", "Created Date", "Created By", "No of Compnay",
          "No Of Records", "Status", "TAT",
        ],],
        { origin: "A1", }
      );
    }
    if (status) {
      XLSX.utils.sheet_add_aoa(
        ws,
        [["Job Priority", "Unassigned", "Assigned", "Open", "Merit Data Collection",
          "Merit Quality Control", "CRU Review", "Completed", "Total",
        ],],
        { origin: "A1", }
      );
    }
    if (statusdrill) {
      XLSX.utils.sheet_add_aoa(
        ws,
        [["Job No", "Asset Name", "Country", "Commodities", "Region"]],
        { origin: "A1", }
      );
    }
    if (meritqc) {
      XLSX.utils.sheet_add_aoa(
        ws,
        [["Job Priority", "QC Passed with no errors", "QC Passed with Merit QC errors", "Merit QC Failed",],],
        { origin: "A1", }
      );
    }
    if (meritqcdrill) {
      XLSX.utils.sheet_add_aoa(
        ws,
        [["Job ID", "Job Name", "Company", "Asset", "Country", "Commodity", "Region", "Sub Region", "Created Date",
          "Turn Around Time", "Completed Time", "Assigned To", "Merit QC", "Job Priority",],],
        { origin: "A1", }
      );
    }
    if (searchexport) {
      XLSX.utils.sheet_add_aoa(
        ws,
        [["Job Asset Id", "Job ID", "Job Name", "Company", "Asset", "Region", "Commodity",
          "Country", "Region", "Sub Region", "Created On",
        ],
        ],
        { origin: "A1", }
      );
    }
    if (viewdata) {
      XLSX.utils.sheet_add_aoa(
        ws,
        [["Last Updated", "Frequency", "Link 1", "Report Name", "Commodity", "Mine", "Country", "SubRegion",
          "Company", "Unit Report", "CRU Unit", "Variable Report", "Currency", "Q1", "Q2", "Q3", "Q4",],],
        { origin: "A1", }
      );
    }
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    // Export to Excel
    <Button variant="contained" color="secondary" startIcon={<ExportIcon style={{ fontSize: 14 }} />}
      onClick={(e) => exportToCSV(apiData, fileName)}>
      Export To Excel
    </Button>
  );
};

export default ExportToExcel;