import React from "react";

import { createContext } from "react";

export const FilterContext = createContext();

export const OwnershipFilterProvider = ({ children }) => {
  console.log(children);
  const [companyList, setCompanyList] = React.useState([]);
  const [owner, setOwner] = React.useState([]);
  const [asset, setAsset] = React.useState([]);
  const [commods, setCommods] = React.useState([]);
  const [assignedTo, setAssignedTo] = React.useState([]);
  const [status, setStatus] = React.useState([]);

  const [frequencey, setFrequency] = React.useState([]);

  const [companyListQC, setCompanyListQC] = React.useState([]);
  const [locationQC, setLocationQC] = React.useState([]);
  const [commodsQC, setCommodsQC] = React.useState([]);

  const [companyListCRU, setCompanyListCRU] = React.useState([]);
  const [locationCRU, setLocationCRU] = React.useState([]);
  // const [commodsCRU, setCommodsCRU] = React.useState([]);

  const [filterValueContext, setFilterValueContext] = React.useState(null);

  const [dateRange, setDateRange] = React.useState(null);

  const [dateRangeJira, setDateRangeJira] = React.useState(null);

  const [priorityIds, setPriorityId] = React.useState(null);
  const [jiraStatus, setJiraStatus] = React.useState(null);
  const [jiraType, setJiraType] = React.useState(null);

  React.useEffect(() => {
    console.log(companyListCRU, "companyList");
  }, [companyListCRU]);

  return (
    <FilterContext.Provider
      value={{
        companyList,
        setCompanyList,
        owner,
        setOwner,
        asset,
        setAsset,
        commods,
        setCommods,
        assignedTo,
        setAssignedTo,
        status,
        setStatus,
        frequencey,
        setFrequency,
        companyListQC,
        setCompanyListQC,
        locationQC,
        setLocationQC,
        commodsQC,
        setCommodsQC,
        companyListCRU,
        setCompanyListCRU,
        locationCRU,
        setLocationCRU,
        setFilterValueContext,
        filterValueContext,
        dateRange,
        setDateRange,
        dateRangeJira,
        setDateRangeJira,
        priorityIds,
        setPriorityId,
        jiraStatus,
        setJiraStatus,
        jiraType,
        setJiraType,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
