// Default React, Router and Services Components
import React, { useState } from "react";
import { Breadcrumbs, Button, Box, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography, } from "@mui/material";
// import history from '../../../Routes/History';
import { useHistory } from "react-router-dom";

import { Link } from 'react-router-dom';
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError } from "../../../UIComponent/Toaster";
// Importing Custom Components
import AlertDialog from "../../../UIComponent/AlertDialog";
import BaseLayout from "../../../UIComponent/BaseLayout";
import DataTable from "../../../UIComponent/DataTable/index";
import FilterAssignJobs from "./FilterAssignJobs";
import SideDrawer from "../../../UIComponent/Drawer";
import { ScrollingDialog } from "../../../UIComponent";
import EditMultipleJobForAssignJob from "./EditMultipleJobForAssignJob";
import { LoaderWrapper } from "../../../UIComponent/Progress";

// Import Fluent UI Icons
import { ChevronRightSmallIcon, ClearIcon, FilterIcon, ZoomIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";
import "./index.scss";
import axios from "axios";
import GetMenuService from '../../../Services/GetMenuService';

// Creating Data Table for Assign Job
function createData(
  id, JobNo, JobName, CreatedDate, Completed, TotalRecords, UnassignedRecords, Status, StartDate, TAT, RemainingDays, CompletedDate, PriorityName) {
  return {
    id, JobNo, JobName, CreatedDate, Completed, TotalRecords, UnassignedRecords, Status, StartDate, TAT, RemainingDays, CompletedDate, PriorityName
  };
}

// Assigning Columns and UI Props
const columns = [
  { id: "id", label: "ID", type: "Highlight" },
  { id: "JobNo", label: "Job ID", minWidth: 90, width: 90, sorting: true, },
  { id: "JobName", label: "Job Name", minWidth: 350, width: "35%", sorting: true },
  { id: "CreatedDate", label: "Created Date", minWidth: 120, width: "13%", sorting: true, align: "center", },
  { id: "Completed", label: "Completion %", sorting: true, minWidth: 120, width: "13%", align: "center", },
  { id: "TotalRecords", label: "Total Records", minWidth: 120, width: "13%", sorting: true, align: "center", },
  { id: "UnassignedRecords", label: "Unassigned Records", minWidth: 120, width: "13%", sorting: true, align: "center", },
  { id: "PriorityName", label: "Priority", type: "Chip", minWidth: 120, width: "15%", sorting: true, align: "center", },
];
const columnsForCompleted = [
  { id: "id", label: "ID", type: "Highlight" },
  { id: "JobNo", label: "Job ID", minWidth: 90, width: 90, sorting: true, },
  { id: "JobName", label: "Job Name", sorting: true, minWidth: 300, width: "30%", },
  { id: "CreatedDate", label: "Created Date", minWidth: 130, width: "10%", sorting: true, align: "center", },
  { id: "TotalRecords", label: "Total Records", minWidth: 130, width: "10%", sorting: true, align: "center", },
  { id: "StartDate", label: "Job Start Date", minWidth: 120, width: "10%", sorting: true, align: "center", },
  { id: "TAT", label: "TAT", minWidth: 110, width: "10%", sorting: true, align: "center", },
  // { id: "RemainingDays", label: "Remaining Days", minWidth: 110, width: 120, sorting: true, align: "center", },
  { id: "CompletedDate", label: "Completed Date", minWidth: 120, width: "10%", sorting: true, align: "center", },
  { id: "PriorityName", label: "Priority", type: "Chip", minWidth: 120, width: "10%", sorting: true, align: "center", },
];

const DataCollectionAssignJobs = () => {
  const history = useHistory();
  // Declaration of States and Component Variables Start Here
  const [filterState, setFilterState] = React.useState({ right: false });
  const [reload, setReload] = useState(false);
  const [selected, SetSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [state, setState] = useState({ right: false });
  const [crudState, setCrudState] = React.useState("Edit");
  const [idforEdit, setIdForEdit] = useState(null);
  const [editJobNumber, setEditJobNumber] = useState(null);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isAllSelected, setIsSelected] = useState(false);
  const [assetType, setAssetType] = useState('all')
  const [rawRowData, setRawRowData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  let [filterValue, setFilterValue] = React.useState(
    {
      JobNo: [], JobName: [], JobType: [], AssignedById: [], AssignedToId: [], CreatedOn: "",
      Tat: "", PriorityIds: [], SearchText: "", CompletedDate: ""
    });
  const [searchValue, setSearchValue] = React.useState();
  const [values, setValues] = useState({});
  const classes = useStyles();
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, });
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
  // Declaration of States and Component Variables End Here

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== 'BUTTON') {
      getJobs()
      setPage(0);
    }
  };

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(8, function (err, res) {
      if (res) {
        setAccess(res.data)
        if (assetType.toLocaleLowerCase() === 'completed'.toLocaleLowerCase()) {
          setAccess({ ...access, allowEdit: false });
        }
      }
      else { toasterError(err) }
    })
  }, [])

  React.useEffect(() => { getJobs() }, [assetType, page, rowsPerPage, refresh])

  React.useEffect(() => {
    if (page === 0) { getJobs() }
    else { setPage(0) }
  }, [sorting])

  // Declaration of React Hooks End Here

  // Function for Global Search
  const globalSearch = (value) => {
    setFilterValue({ ...filterValue, SearchText: value })
    setSearchValue(value);
  };
  const filterSubmit = () => { setRefresh(!refresh) }

  // Function for Search Clear
  const searchClear = () => {
    setFilterValue({ ...filterValue, SearchText: "" })
    setSearchValue("");
    var SendFilterValue;
    delete filterValue.SearchText
    if (filterValue.JobNo.length === 0 && filterValue.JobName.length === 0 && filterValue.AssignedById.length === 0 && filterValue.AssignedToId.length === 0 && filterValue.CreatedOn === "" && filterValue.CompletedDate === "" && filterValue.Tat === "" && filterValue.PriorityIds.length === 0) {
      SendFilterValue = ""
    } else {
      SendFilterValue = filterValue
    }
    axios.get(process.env.React_App_baseUrl + "assignjobs/GetAssignJobs", {
      headers: {
        AssignType: assetType,
        sortColumn: sorting.sortBy,
        sourceFilter: encodeURIComponent(JSON.stringify(SendFilterValue)),
        isAscending: sorting.isAscending,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

      },
      params: { pageNumber: page + 1, pagesize: rowsPerPage, }
    })
      .then(function (response) {
        setTotalCount(response.data.totalCount)
        let rowsData = [];
        setRawRowData([])
        rowsData = response.data.data.map((row) =>
          createData(
            row.id, row.jobNo, row.jobName, row.createdDate, row.completed + ' %', row.totalRecords,
            row.unassignedRecords, row.status, row.startDate, row.tat, row.remainingDays, row.completedDate, row.priorityName,)
          
        );
        setRawRowData(rowsData)
      }) .catch((err) => {
        
        if(err.res.status===400){
          toasterError(err.res.data);
        }
         });
  };

  const getJobs = () => {
    setLoading(true)
    var SendFilterValue;
    if (filterValue.JobNo.length === 0 && filterValue.JobName.length === 0 && filterValue.AssignedById.length === 0 && filterValue.AssignedToId.length === 0 && filterValue.CreatedOn === "" && filterValue.CompletedDate === "" && filterValue.Tat === "" && filterValue.PriorityIds.length === 0 && filterValue.SearchText === "") {
      SendFilterValue = ""
    } else {
      SendFilterValue = filterValue
    }
    axios.get(process.env.React_App_baseUrl + "assignjobs/GetAssignJobs", {
      headers: {
        AssignType: assetType,
        sortColumn: sorting.sortBy,
        sourceFilter: encodeURIComponent(JSON.stringify(SendFilterValue)),
        isAscending: sorting.isAscending,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
      params: { pageNumber: page + 1, pagesize: rowsPerPage, }
    })
      .then(function (response) {
        setLoading(false)
        setTotalCount(response.data.totalCount)
        let rowsData = []
        setRawRowData([])
        rowsData = response.data.data.map((row) =>
          createData(
            row.id, row.jobNo, row.jobName, row.createdDate, row.completed + ' %', row.totalRecords,
            row.unassignedRecords, row.status, row.startDate, row.tat, row.remainingDays, row.completedDate, row.priorityName,)
        );
        setRawRowData(rowsData)
      })
      .catch((err) => {
        setLoading(false);
        if(err.res.status===400){
          toasterError(err.res.data);
        }
         });
  }

  const toggleDrawerFilter = (open, mode, e) => (event, item) => {
    setFilterState({ ...filterState, right: open });
  };

  const changeAssetType = (data) => {

    setAssetType(data)
    setPage(0)
    if (data.toLocaleLowerCase() === 'completed'.toLocaleLowerCase()) {
      setAccess({ ...access, allowEdit: false });
    } else {
      GetMenuService(8, function (err, res) {
        if (res) { setAccess(res.data) }
        else { toasterError(err) }
      })
    }
  }
  const toggleDrawer = (open, mode) => (event, item) => {

    if (mode === "View") {
      history.push({
        pathname: "/app/data-collections/manage-job-records",
        state: { jobAssetId: item.id, assetType: assetType },
        //  assetType:assetType,
        prevPath: window.location.pathname,
      });
    } else {
      setIdForEdit(item.id);
      setEditJobNumber(item.JobNo);
      setOpen(true);
      setScroll("paper");
    }
  };

  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Jobs Status</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/create-jobs" }}>Job Management</Link>
            <Typography color="text.primary">Jobs Status</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>

            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        <Grid item xs={12}>
          <BaseLayout>

            {/* Jobs Buttons Start Here */}
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 3 }}>
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={'auto'}>
                  <Button fullWidth className={classes.largeButton} color="secondary" size="small"
                    variant={assetType === 'all' ? "contained" : "outlined"}
                    onClick={() => changeAssetType('all')}>
                    All Jobs
                  </Button>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                  <Button fullWidth className={classes.largeButton} color="secondary" size="small"
                    variant={assetType === 'assigned' ? "contained" : "outlined"}
                    onClick={() => changeAssetType('assigned')}>
                    Assigned Jobs
                  </Button>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                  <Button fullWidth className={classes.largeButton} color="secondary" size="small"
                    variant={assetType === 'unassigned' ? "contained" : "outlined"}
                    onClick={() => changeAssetType('unassigned')}>
                    Unassigned Jobs
                  </Button>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                  <Button fullWidth className={classes.largeButton} color="secondary" size="small"
                    variant={assetType === 'completed' ? "contained" : "outlined"}
                    onClick={() => changeAssetType('completed')}>
                    Completed Jobs
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* Jobs Buttons Start Here */}

            <Grid container alignItems="center" justifyContent="center" className={classes.tableBorder}>
              {/* Search and Filter Options Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      size="small"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"><ZoomIcon /></InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : ("")}
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                      <Grid item>
                        <Button onClick={toggleDrawerFilter(true)} variant="contained" color="secondary"
                          startIcon={<FilterIcon style={{ fontSize: 16 }} />}>
                          Filter
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
              {/* Search and Filter Options End Here */}

              {/* Assign Jobs Start Here */}
              <Grid item xs={12}>
                <LoaderWrapper loading={isLoading}>
                  <DataTable
                    className={classes.removeBorder}
                    loading={isLoading}
                    rows={rawRowData ? rawRowData : []}
                    columns={assetType === 'completed' ? columnsForCompleted : columns}
                    page={page}
                    setPage={(data) => {
                      setPage(data);
                      setReload(true);
                    }}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={(data) => {
                      setRowsPerPage(data);
                      setReload(true);
                    }}
                    totalCount={totalCount}
                    isAllSelected={isAllSelected}
                    setIsSelected={setIsSelected}
                    selected={selected}
                    setSelected={SetSelected}
                    sorting={sorting}
                    setSorting={setSorting}
                    hasChip={{
                      High: { color: "warning", label: "High" },
                      Critical: { color: "error", label: "Critical" },
                      Low: { color: "success", label: "Low" },
                      Medium: { color: "info", label: "Medium" },
                    }}
                    isActionButton={{
                      ...(access.allowView && {
                        view: toggleDrawer(true, "View"),
                      }),
                      ...(access.allowEdit && {
                        edit: toggleDrawer(true, "Edit"),
                      })
                    }}
                  />
                </LoaderWrapper>
              </Grid>

              {/* Filter Assign Jobs Start Here */}
              <SideDrawer
                formComp={
                  <FilterAssignJobs
                    state={filterState}
                    setState={setFilterState}
                    filterSubmit={filterSubmit}
                    setFilterValue={setFilterValue}
                    filterValue={filterValue}
                    assetType={assetType}
                    reload={reload}
                    values={values}
                    setValues={setValues}
                    setReload={setReload}
                  />
                }
                state={filterState}
                setState={setFilterState}
              />
              {/* Filter Assign Jobs End Here */}

            </Grid>

            <ScrollingDialog
              open={open}
              setOpen={setOpen}
              scroll={scroll}
              setScroll={setScroll}
              dialogClass="darkTitle"
              dialogTitle="Edit Job"
              dialogContent={
                <EditMultipleJobForAssignJob
                  open={open}
                  crudState={crudState}
                  state={state}
                  idforEdit={idforEdit}
                  editJobNumber={editJobNumber}
                  setState={setState}
                  setOpen={setOpen}
                  hideSideDrawer={() => {
                    setOpen(false);
                    setState({ right: false });
                    getJobs();
                  }}
                />
              }
            />
          </BaseLayout>
        </Grid>

        {
          openDelete ? (
            <AlertDialog
              open={openDelete}
              title={"Confirmation"}
              maxWidth={"sm"}
              description={`Are you sure want to delete the Job No`}
              action={
                <>
                  <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                    <Button onClick={() => setOpenDelete(false)}>No</Button>
                    {deleteBtnLoadinng ? (
                      <CircularProgress />
                    ) : (<Button color="secondary">Yes</Button>)}
                  </div>
                </>
              }
            />
          ) : null
        }
      </Grid >
    </Box>
  );
};

export default DataCollectionAssignJobs;