// 01 - Manage User/Roles
import Users from "../Components/ManageUsersRoles/BlockUserAccess";
import ManageUsers from "../Components/ManageUsersRoles/UserManagement/index";
import ManageRoles from "../Components/ManageUsersRoles/ManageRoles/ManageRoles";

// 02-1 - Manage Company Data Collections - Source Management
import DataCollectionSourceManagement from "../Components/DataCollection/SourceManagement/DataCollectionSourceManagement";

// 02-2 - Manage Company Data Collections - Job Management
import DataCollectionJobList from "../Components/DataCollection/JobManagement/DataCollectionJobList";
import DataCollectionCreateJob from "../Components/DataCollection/JobManagement/DataCollectionCreateJob";
import DataCollectionAssignJobs from "../Components/DataCollection/JobManagement/DataCollectionAssignJobs";
import DataCollectionManageJobRecords from "../Components/DataCollection/JobManagement/DataCollectionManageJobRecords";
import DataCollectionUpdateRecord from "../Components/DataCollection/JobManagement/DataCollectionUpdateRecord";
import DataCollectionFilePreview from "../Components/DataCollection/JobManagement/DataCollectionFilePreview";
import DataCollectionDuplicateJob from "../Components/DataCollection/JobManagement/DataCollectionDuplicateJob";

// 02-3 - Manage Company Data Collections - Quality Management
import DataCollectionQualityList from "../Components/DataCollection/QualityManagement/DataCollectionQualityList";
import DataCollectionQualityManagementDrillDown from "../Components/DataCollection/QualityManagement/QualityManagementDrillDown";
import DataCollectionManageQualityDetails from "../Components/DataCollection/QualityManagement/DataCollectionManageQualityDetails";

// 02-4 - Manage Company Data Collections - Reporting Calendar
import DataCollectionReportingCalendar from "../Components/DataCollection/ReportingCalendar/DataCollectionReportingCalendar";

// 02-5 - Manage Company Data Collections - Search Export
import DataCollectionSearchExport from "../Components/DataCollection/SearchExport/DataCollectionSearchExport";
import DataCollectionSearchExportFilter from "../Components/DataCollection/SearchExport/DataCollectionSearchExportFilter";
import DataCollectionViewData from "../Components/DataCollection/SearchExport/DataCollectionViewData";

// 02-6 - Manage Company Data Collections - Dashboard and Reports
import DataCollectionAgingReport from "../Components/DataCollection/DashboardReports/AgingReport/DataCollectionAgingReport";
import DataCollectionAgingReportDrill from "../Components/DataCollection/DashboardReports/AgingReport/DataCollectionAgingReportDrill";
import DataCollectionStatusReport from "../Components/DataCollection/DashboardReports/StatusReport/DataCollectionStatusReport";
import DataCollectionStatusReportDrill from "../Components/DataCollection/DashboardReports/StatusReport/DataCollectionStatusReportDrill";
import DataCollectionJobsInQueue from "../Components/DataCollection/DashboardReports/Jobs/DataCollectionJobsInQueue";
import DataCollectionJobDrillDown from "../Components/DataCollection/DashboardReports/Jobs/DataCollectionJobDrillDown";
import DataCollectionJiraTicketStatusReport from "../Components/DataCollection/DashboardReports/JiraTicketStatusReport/DataCollectionJiraTicketStatusReport";
import DataCollectionJiraTicketStatusReportDrill from "../Components/DataCollection/DashboardReports/JiraTicketStatusReport/DataCollectionJiraTicketStatusReportDrill";
import DataCollectionWeeklyStatusReport from "../Components/DataCollection/DashboardReports/WeeklyStatusReport/DataCollectionWeeklyStatusReport";
import DataCollectionMeritQCStatusReport from "../Components/DataCollection/DashboardReports/MeritQCReport/DataCollectionMeritQCStatusReport";
import DataCollectionMeritQCDrill from "../Components/DataCollection/DashboardReports/MeritQCReport/DataCollectionMeritQCDrill";
import DataCollectionCRUReviewReport from "../Components/DataCollection/DashboardReports/CRUReviewReport/DataCollectionCRUReviewReport";
import DataCollectionCRUReviewReportDrill from "../Components/DataCollection/DashboardReports/CRUReviewReport/DataCollectionCRUReviewReportDrill";
import DataCollectionQCFailedReport from "../Components/DataCollection/DashboardReports/QCFailedReport/DataCollectionQCFailedReport";
// Phase1 QC pass Screen CR
import QualityManagementQcPass from "../Components/DataCollection/QualityManagement/QualityManagementQcPass";

// 03-1 - Manage Company Ownership Changes - Source Management
import OwnershipChangesSourceManagement from "../Components/OwnershipChanges/SourceManagement/OwnershipChangesSourceManagement";

// 03-2 - Manage Company Ownership Changes - Job Management
import OwnershipChangesJobList from "../Components/OwnershipChanges/JobManagement/OwnershipChangesJobList";
import OwnershipChangesCreateJob from "../Components/OwnershipChanges/JobManagement/OwnershipChangesCreateJob";
import OwnershipChangesAssignJobs from "../Components/OwnershipChanges/JobManagement/OwnershipChangesAssignJobs";
import OwnershipChangesManageJobRecords from "../Components/OwnershipChanges/JobManagement/OwnershipChangesManageJobRecords";
import OwnershipChangesUpdateRecord from "../Components/OwnershipChanges/JobManagement/OwnershipChangesUpdateRecord";
import OwnershipChangesFilePreview from "../Components/OwnershipChanges/JobManagement/OwnershipChangesFilePreview";
import OwnershipChangesDuplicateJobs from "../Components/OwnershipChanges/JobManagement/OwnershipChangesDuplicateJob";

// 03-3 - Manage Company Ownership Changes - Quality Management
import OwnershipChangesQualityList from "../Components/OwnershipChanges/QualityManagement/OwnershipChangesQualityList";
import OwnershipChangesManageQualityDetails from "../Components/OwnershipChanges/QualityManagement/OwnershipChangesManageQualityDetails";
import QualityManagementDrillDown from "../Components/OwnershipChanges/QualityManagement/QualityManagementDrillDown";

// 03-4 - Manage Company Ownership Changes - Search & Export
import OwnershipChangesSearchExport from "../Components/OwnershipChanges/SearchExport/OwnershipChangesSearchExport";
import OwnershipChangesSearchExportFilter from "../Components/OwnershipChanges/SearchExport/OwnershipChangesSearchExportFilter";
import OwnershipChangesViewData from "../Components/OwnershipChanges/SearchExport/OwnershipChangesViewData";

// 03-5 - Manage Company Ownership Changes - Dashboard and Reports
import OwnershipChangesStatusReport from "../Components/OwnershipChanges/DashboardReports/StatusReport/OwnershipChangesStatusReport";
import OwnershipChangesStatusReportDrill from "../Components/OwnershipChanges/DashboardReports/StatusReport/OwnershipChangesStatusReportDrill";
import OwnershipChangesWeeklyStatusReport from "../Components/OwnershipChanges/DashboardReports/WeeklyStatusReport/OwnershipChangesWeeklyStatusReport";
import OwnershipChangesMeritQCReport from "../Components/OwnershipChanges/DashboardReports/MeritQCReport/OwnershipChangesMeritQCReport";
import OwnershipChangesScoringReport from "../Components/OwnershipChanges/DashboardReports/ScoringReport/ScoringReport";
import OwnershipChangesCRUReviewReport from "../Components/OwnershipChanges/DashboardReports/CRUReviewReport/OwnershipChangesCRUReviewReport";

// 04-1 - Manage Data - Commodity
import Commodity from "../Components/ManageData/Commodity/Commodity";
import CommoditySideBar from "../Components/ManageData/Commodity/AddEditCommodity";

// 04-2 - Manage Data - Country
import Country from "../Components/ManageData/Country/Country";

// 04-3 - Manage Data - Currency
import Currency from "../Components/ManageData/Currency/Currency";

// 04-4 - Manage Data - Frequency
import Frequency from "../Components/ManageData/Frequency/Frequency";

// 04-5 - Manage Data - Region
import Region from "../Components/ManageData/Region/Region";

// 04-6 - Manage Data - Sub Region
import SubRegion from "../Components/ManageData/SubRegion/SubRegion";

// 04-7 - Manage Data - Priority
import TopPriority from "../Components/ManageData/Priority/Priority";

// 04-8 - Manage Data - Status
import Status from "../Components/ManageData/Status/Status";

// 04-9 - Manage Data - Unit(CRU)
import UnitCru from "../Components/ManageData/UnitCRU/UnitCRU";

// 04-10 - Manage Data - Unit(Report)
import UnitReport from "../Components/ManageData/UnitReport/UnitReport";

// 04-11 - Manage Data - Variable(CRU)
import ManageCruVariables from "../Components/ManageData/CruVariable/CruVariable";

// 04-12 - Manage Data - Variable(Report)
import VariableReport from "../Components/ManageData/VariableReport/VariableReport";

// 04-13 - Manage Data - Asset Variable(Count)
import AssetVariableCount from "../Components/ManageData/AssetVariableCount/AssetVariableCount";

// 04-14 - Manage Data - TAT Configuration
import TatConfiguration from "../Components/ManageData/TatConfiguration/TatConfiguration";

// 04-15 - Manage Data - Variable Type
import TopVariableType from "../Components/ManageData/VariableType/VariableType";

// 04-16 - Manage Data - Scoring Parameter
import ScoringParameter from "../Components/ManageData/ScoringParameter/ScoringParameter";

// 04-17 - Manage Data - Legal Entity Types
import LegalEntityTypes from "../Components/ManageData/LegalEntityTypes/LegalEntityTypes";

// 04-18 - Manage Data - Stock Exchanges
import StockExchanges from "../Components/ManageData/StockExchanges/StockExchanges";

// 04-19 - Manage Data - Change Types
import ChangeTypes from "../Components/ManageData/ChangeTypes/ChangeTypes";

// 04-20 - Manage Data - Company and Stock Exchanges
import CompanyStockExchange from "../Components/ManageData/CompanyStockExchange/CompanyStockExchange";

// Others
import TopProducers from "../Components/ManageData/TopProducers/TopProducers";
import FinancialYearQuarters from "../Components/ManageData/FinancialYearQuarters/FinancialYearQuarters";

// 06 - Shared & Common Components
import ChangePassword from "../Components/Shared/Login/changePassword";
import OwnerCorrectOwnerName from "../Components/ManageData/OwnerCorrectOwnerName/OwnerCorrectOwnerName";
import OwnershipChangesMeritQCDrill from "../Components/OwnershipChanges/DashboardReports/MeritQCReport/OwnershipChangesMeritQCDrill";
import OwnershipChangesCRUReviewReportDrill from "../Components/OwnershipChanges/DashboardReports/CRUReviewReport/OwnershipChangesCRUReviewReportDrill";
import QualityManagementQualityCheck from "../Components/OwnershipChanges/QualityManagement/QualityManagementQualityCheck";
import OpenFilePreview from "../Components/DataCollection/JobManagement/OpenFilePreview";
import DataCollectionQCFailedReportDrilldown from "../Components/DataCollection/DashboardReports/QCFailedReport/DataCollectionQCFailedReportDrilldown";


// import { ManageUsers, SourceMangement } from "../Components/Shared/DevelopingComponents/developingComponents";

// Others
// import { DataTableCollapsible } from "../UIComponent";
// import Commodity from "../Components/ManageData/Commodity";
// import Country from "../Components/ManageData/Country";
// import CommoditySideBar from "../Components/ManageData/CommoditySideBar";
// import QualityManagement from "../Components/QualityManagement/QualityManagement";
// import ManageQuality from "../Components/QualityManagement/ManageQuality";

const routes = [
  // 01 - Manage User/Roles
  {
    link: "/",
    component: Users,
  },
  {
    link: "/manage-users",
    component: ManageUsers,
  },
  {
    link: "/manage-roles",
    component: ManageRoles,
  },

  // 02-1 - Manage Company Data Collections
  {
    link: "/data-collections/source-management",
    component: DataCollectionSourceManagement,
  },
  {
    link: "/data-collections/create-jobs",
    component: DataCollectionCreateJob,
  },
  {
    link: "/data-collections/manage-jobs",
    component: DataCollectionJobList,
  },
  {
    link: "/data-collections/assign-jobs",
    component: DataCollectionAssignJobs,
  },
  {
    link: "/data-collections/manage-job-records",
    component: DataCollectionManageJobRecords,
  },
  {
    link: "/data-collections/update-record",
    component: DataCollectionUpdateRecord,
  },
  {
    link: "/data-collections/file-preview",
    component: DataCollectionFilePreview,
  },
  {
    link: "/data-collections/duplicate-jobs",
    component: DataCollectionDuplicateJob,
  },
  {
    link: "/data-collections/quality-management",
    component: DataCollectionQualityList,
  },
  {
    link: "/data-collections/quality-management-drill",
    component: DataCollectionQualityManagementDrillDown,
  },
  {
    link: "/data-collections/manage-quality-details",
    component: DataCollectionManageQualityDetails,
  },
  {
    link: "/data-collection/quality-management-qualitycheck",
    component: QualityManagementQcPass,
  },
  {
    link: "/data-collections/reporting-calendar",
    component: DataCollectionReportingCalendar,
  },
  {
    link: "/data-collections/search-export",
    component: DataCollectionSearchExport,
  },
  {
    link: "/data-collections/search-export-filter",
    component: DataCollectionSearchExportFilter,
  },
  {
    link: "/data-collections/view-data",
    component: DataCollectionViewData,
  },

  // 02-6 - Manage Company Data Collections - Dashboard and Reports
  {
    link: "/data-collections/aging-report",
    component: DataCollectionAgingReport,
  },
  {
    link: "/data-collections/aging-report-drill",
    component: DataCollectionAgingReportDrill,
  },
  {
    link: "/data-collections/status-report",
    component: DataCollectionStatusReport,
  },
  {
    link: "/data-collections/status-report-drill",
    component: DataCollectionStatusReportDrill,
  },
  {
    link: "/data-collections/jobs-in-queue",
    component: DataCollectionJobsInQueue,
  },
  {
    link: "/data-collections/jobs-drill-down",
    component: DataCollectionJobDrillDown,
  },
  {
    link: "/data-collections/jira-tickets",
    component: DataCollectionJiraTicketStatusReport,
  },
  {
    link: "/data-collections/jira-ticket-status-report-drill",
    component: DataCollectionJiraTicketStatusReportDrill,
  },
  {
    link: "/data-collections/weekly-status-report",
    component: DataCollectionWeeklyStatusReport,
  },
  {
    link: "/data-collections/merit-qc-report",
    component: DataCollectionMeritQCStatusReport,
  },
  {
    link: "/data-collections/merit-qc-drill",
    component: DataCollectionMeritQCDrill,
  },
  {
    link: "/data-collections/cru-review-report",
    component: DataCollectionCRUReviewReport,
  },
  {
    link: "/data-collections/cru-review-report-drill",
    component: DataCollectionCRUReviewReportDrill,
  },
  {
    link: "/data-collections/qc-failed-report",
    component: DataCollectionQCFailedReport,
  },
  {
    link: "/data-collections/qc-failed-report-drill",
    component: DataCollectionQCFailedReportDrilldown,
  },

  // 03-1 - Manage Company Ownership Changes
  {
    link: "/ownership-changes/source-management",
    component: OwnershipChangesSourceManagement,
  },

  // 03-2 - Manage Company Ownership Changes - Job Management
  {
    link: "/ownership-changes/job-list",
    component: OwnershipChangesJobList,
  },
  {
    link: "/ownership-changes/create-job",
    component: OwnershipChangesCreateJob,
  },
  {
    link: "/ownership-changes/manage-jobs",
    component: OwnershipChangesJobList,
  },
  {
    link: "/ownership-changes/assign-jobs",
    component: OwnershipChangesAssignJobs,
  },
  {
    link: "/ownership-changes/manage-job-records",
    component: OwnershipChangesManageJobRecords,
  },
  {
    link: "/ownership-changes/update-record",
    component: OwnershipChangesUpdateRecord,
  },
  {
    link: "/ownership-changes/file-preview",
    component: OwnershipChangesFilePreview,
  },
  {
    link: "/ownership-changes/duplicate-jobs",
    component: OwnershipChangesDuplicateJobs,
  },

  // 03-3 - Manage Company Ownership Changes - Quality Management
  {
    link: "/ownership-changes/quality-management",
    component: OwnershipChangesQualityList,
  },
  {
    link: "/ownership-changes/manage-quality-details",
    component: OwnershipChangesManageQualityDetails,
  },
  {
    link: "/ownership-changes/quality-management-drill",
    component: QualityManagementDrillDown,
  },
  {
    link: "/ownership-changes/quality-management-qualitycheck",
    component: QualityManagementQualityCheck,
  },
  // 03-4 - Manage Company Ownership Changes - Search & Export
  {
    link: "/ownership-changes/search-export",
    component: OwnershipChangesSearchExport,
  },
  {
    link: "/ownership-changes/search-export-filter",
    component: OwnershipChangesSearchExportFilter,
  },
  {
    link: "/ownership-changes/view-data",
    component: OwnershipChangesViewData,
  },

  // 03-5 - Manage Company Ownership Changes - Dashboard and Reports
  {
    link: "/ownership-changes/status-report",
    component: OwnershipChangesStatusReport,
  },
  {
    link: "/ownership-changes/status-report-drill",
    component: OwnershipChangesStatusReportDrill,
  },
  {
    link: "/ownership-changes/weekly-status-report",
    component: OwnershipChangesWeeklyStatusReport,
  },
  {
    link: "/ownership-changes/merit-qc-report",
    component: OwnershipChangesMeritQCReport,
  },
  {
    link: "/ownership-changes/scoring-report",
    component: OwnershipChangesScoringReport,
  },
  {
    link: "/ownership-changes/merit-qc-report-drill",
    component: OwnershipChangesMeritQCDrill,
  },
  {
    link: "/ownership-changes/cru-review-report",
    component: OwnershipChangesCRUReviewReport,
  },
  {
    link: "/ownership-changes/cru-review-report-drill",
    component: OwnershipChangesCRUReviewReportDrill,
  },

  // 04 - Manage Data
  {
    link: "/commodity",
    component: Commodity,
  },
  {
    link: "/commodity-sideBar",
    component: CommoditySideBar,
  },
  {
    link: "/country",
    component: Country,
  },
  {
    link: "/currency",
    component: Currency,
  },
  {
    link: "/Frequency",
    component: Frequency,
  },
  {
    link: "/region",
    component: Region,
  },
  {
    link: "/sub-region",
    component: SubRegion,
  },
  {
    link: "/priority",
    component: TopPriority,
  },
  {
    link: "/status",
    component: Status,
  },
  {
    link: "/unit-cru",
    component: UnitCru,
  },
  {
    link: "/unit-report",
    component: UnitReport,
  },
  {
    link: "/variable-cru",
    component: ManageCruVariables,
  },
  {
    link: "/variable-report",
    component: VariableReport,
  },
  {
    link: "/asset-variable-count",
    component: AssetVariableCount,
  },
  {
    link: "/tat-configuration",
    component: TatConfiguration,
  },
  {
    link: "/variable-type",
    component: TopVariableType,
  },
  {
    link: "/scoring-parameters",
    component: ScoringParameter,
  },
  {
    link: "/legal-entity-types",
    component: LegalEntityTypes,
  },
  {
    link: "/stock-exchanges",
    component: StockExchanges,
  },
  {
    link: "/change-types",
    component: ChangeTypes,
  },
  {
    link: "/company-stock-exchange",
    component: CompanyStockExchange,
  },
  {
    link: "/owner-correct-ownerName",
    component: OwnerCorrectOwnerName,
  },

  // Common
  {
    link: "/changepassword",
    component: ChangePassword,
  },

  // Others
  {
    link: "/topproducers",
    component: TopProducers,
  },
  {
    link: "/financialyearandquarters",
    component: FinancialYearQuarters,
  },
  {
    link: "/manage-quality",
    component: DataCollectionQualityList,
  },
  {
    link: "/OpenFilePreview",
    component: OpenFilePreview,
  },
];
export default routes;
