import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  // Quality Management Page Styles
  breadCrumbsIcon: {
    fontSize: 10,
    marginLeft: 3
  },
  totalRecord: {
    color: "#00519c",
  },
  container: {
    maxHeight: 640 // Table Max Height
  },
  tableBorder: {
    border: '1px solid #d7d7d7',
    borderRadius: 6,
    overflow: 'hidden',
    // margin: theme.spacing(2, 0, 0, 0)
  },
  tableTopPart: {
    padding: theme.spacing(2)
  },
  colHighlight: {
    padding: theme.spacing(0, 0, 0, 1) + '!important',
  },
  tableHeader: {
    padding: theme.spacing(0, 0.75) + '!important',
    lineHeight: "1 !important",
    whiteSpace: "normal",
    fontWeight: 'bold !important',
    height: "46px",
  },
  tableCell: {
    wordBreak: "break-all",
    wordWrap: "break-word",
    borderBottom: "1px solid #d7d7d7 !important",
    padding: theme.spacing(0, 0.75) + '!important',
    height: "46px",
  },
  tableFooterCell: {
    wordBreak: "break-all",
    wordWrap: "break-word",
    borderBottom: "1px solid #d7d7d7 !important",
    padding: theme.spacing(0, 0.75) + '!important',
    height: "46px",
    fontWeight: 700 + '!important',
    color: '#000 !important',
    fontSize: '13px !important'
  },
  tableActionCell: {
    padding: theme.spacing(0, 0.75) + '!important',
  },
  cellNoWrap: {
    whiteSpace: "nowrap"
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  helperText: {
    display: 'block',
    whiteSpace: "nowrap",
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  addBorder: {
    borderRadius: '6px',
    border: "1px solid rgba(224, 224, 224, 1) !important",
  },
  shortDetails: {
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
  },
  boldText: {
    fontWeight: 700,
    fontFamily: "Roboto, sans-serif",
  },
  twoValues: {
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    display: "inline-block",
  },
  largeButton: {
    fontWeight: 'bold',
    minWidth: '230px !important',

    [theme.breakpoints.up("sm")]: {
      minWidth: '210px !important',
      width: '210px !important',
    },
    [theme.breakpoints.up("md")]: {
      minWidth: '190px !important',
      width: '190px !important',
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: '180px !important',
      width: '180px !important',
    },
  },
  iconSize: {
    fontSize: "20px",
    width: "1em",
    height: "1em",
  },
  smallAlign: {
    margin: "0 !important",
    "& .MuiAutocomplete-root": {
      margin: "0 !important",
    },
  },
  jobShortDetails: {
    border: '1px solid #d7d7d7',
    borderRadius: 6,
    overflow: 'hidden',
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0, 2, 0),
  },
  largeButtonAuto: {
    minWidth: 230,

    [theme.breakpoints.up("sm")]: {
      minWidth: 'auto',
      width: 'auto',
    },
  },
  posRelative: {
    borderRadius: '6px',
    border: "1px solid rgba(224, 224, 224, 1)",
    position: 'relative',
  },
  recordArea: {
    position: 'absolute',
    display: 'inline-block',
    top: '-15px',
    left: '10px',
    background: "#ffffff",
    padding: theme.spacing(0, 1),
    color: '#1E88E5',
  },
  iconArea: {
    position: 'absolute',
    top: '-19px',
    right: '10px',
    background: "#ffffff",
    padding: theme.spacing(0, 1),
  },
  recordTitleArea: {
    position: 'relative',
    zIndex: '0',
    background: '#ffffff',
    height: '25px',
  },
  recordLine: {
    height: '2px',
    width: '100%',
    background: '#00519c',
    position: 'absolute',
    top: '10px',
    left: '0px',
    zIndex: '90'
  },
  recordTitle: {
    position: 'absolute',
    top: '-9px',
    left: '0px',
    background: "#ffffff",
    padding: theme.spacing(1, 1, 1, 0),
    zIndex: '99',
    fontWeight: 'bold',
    color: '#1E88E5',
    fontFamily: "Roboto, sans-serif",
  },
  recordAction: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    background: "#ffffff",
    padding: theme.spacing(0),
    zIndex: '99'
  },
  headingText: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
    // fontWeight: 'bold !important',
    fontSize: '22px',
    color: '#00519c',
    fontWeight: '600',
  },
  overviewIcons: {
    width: 40,
    display: "inline-block",
    textAlign: "center",
  },
  overviewValues: {
    minWidth: 40,
    maxWidth: 130,
    display: "inline-block",
    margin: theme.spacing(0, 0, 0, 1),
    textAlign: 'right'
  },
  cruTheadLeftSep: {
    padding: theme.spacing(0, 0.75) + '!important',
    whiteSpace: "normal",
    fontWeight: 'bold !important',
    height: "46px",
    borderBottom: "1px solid #d7d7d7",
    borderLeft: "1px solid #d7d7d7 !important",
  },
  cruTheadRightSep: {
    padding: theme.spacing(0, 0.75) + '!important',
    whiteSpace: "normal",
    fontWeight: 'bold !important',
    height: "46px",
    borderBottom: "1px solid #d7d7d7",
    borderRight: "1px solid #d7d7d7 !important",
  },
  tableCellRightSep: {
    padding: theme.spacing(0, 0.75) + '!important',
    height: "46px",
    borderBottom: "1px solid #d7d7d7",
    borderRight: "1px solid #d7d7d7 !important",
  },
  tableCellLeftSep: {
    padding: theme.spacing(0, 0.75) + '!important',
    height: "46px",
    borderBottom: "1px solid #d7d7d7",
    borderLeft: "1px solid #d7d7d7 !important",
  },
  headingText: {
    color: "#000",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 10,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
  },
  popupArea: {
    padding: theme.spacing(3),
  },
  popupHeading: {
    padding: theme.spacing(0, 0, 3, 0),
  },

  headingText2: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '22px',
    color: '#00519c',
    fontWeight: '600',
  },
  assignDetails: {
    border: '1px solid #d7d7d7',
    borderRadius: 6,
    overflow: 'hidden',
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0, 2, 0),
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
  },
  assignDetails2: {
    border: '1px solid #d7d7d7',
    borderRadius: 6,
    overflow: 'hidden',
    padding: theme.spacing(0, 2, 2, 2),
    margin: theme.spacing(0, 0, 2, 0),
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
  },
  assignValues: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "20px",
    fontWeight: "bold",
    color: '#00519c',
    padding: theme.spacing(0.75, 0, 0, 0),
  },
  setBorder: {
    borderRadius: "6px",
    border: "1px solid rgba(224, 224, 224, 1)",
    overflow: "auto",
  },
  stickyFooter: {
    bottom: 0,
    position: "fixed",
    backgroundColor: "#ffffff"
  }
}));

export default useStyles;
