// Default React, useState and Material Components 
import React, { useState } from "react";
import { Box, Breadcrumbs, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import SideDrawer from "../../../UIComponent/Drawer/index";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing Add & Edit Components
import CommoditySideBar from "./AddEditCommodity";

// Importing useStyles
import useStyles from "../ManageData.styles";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

const Commodity = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [edit, setEdit] = useState({});
  const [page, setPage] = React.useState(0);
  const [selected, SetSelected] = useState([]);
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [state, setState] = React.useState({ right: false });
  const [deleteUserData, setDeleteUserData] = useState(null);
  const globalSearch = (value) => { setSearchValue(value); };
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false });
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  // Declaration of States and Component Variables End Here

  // React.useEffect(() => {
  //   getUserData(searchValue);
  // }, [reload]);

  React.useEffect(() => {
    GetMenuService(14, function (err, res) {
      if (res) {
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])

  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) { searchUser('') }
    else { searchUser(searchValue) }
  }, [sorting]);

  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) { searchUser('') }
    else { searchUser(searchValue) }
  }, [page, reload, rowsPerPage])
  // Declaration of React Hooks End Here

  // Assigning Columns and UI Props
  const columns = [
    { id: "id", label: "Status", type: "Highlight" },
    { id: "commoditySymbol", label: "Commodity Symbol", minWidth: 200, width: "25%", sorting: false },
    { id: "name", label: "Commodity Name", minWidth: 400, width: "70%", sorting: false, truncate: 90, tooltipWithTruncate: true },
  ];

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { getUserData(); }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      setTotalCount(0);
      setPage(0);
      searchUser(event.target.value);
    }
  };

  // Function for Get Commodity Details
  const getUserData = () => {
    // Set loading to true
    setLoading(true);
    if (reload) { setReload(false); }
     // Call API service to get all user data
    ApiService.getAll(`managedata/GetCommodityDetails?pageNumber=${page + 1}&pageSize=${rowsPerPage}`)
      .then((res) => {
        // If response status is 200, set all users and total count
        if (res.status === 200) {
          setAllUsers(res.data.data);
          setTotalCount(res.data.totalCount);
          setLoading(false);
        } else {
          // Else show error message
          toasterError(`${res.data}`);
          setLoading(false);
        }
      })
      .catch((err) => {
        // Handle errors
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

  // Function for Delete Commodity
  const deleteUser = () => {
    // Set loading state of delete button to true
    setDeleteBtnLoadinng(true);
    // Get data of the user to be deleted
    let data = deleteUserData;
    // Set active status of user to false
    data.active = false;
    // Make a post request to delete user
    axios
      .post(process.env.React_App_baseUrl + "managedata/DeleteCommodity", null, {
        headers: {
          id: data.id,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then((res) => {
        // If response is successful
        if (res.status === 200) {
          // Show success message
          toasterSuccess("Commodity Deleted successfully!");
          // Get updated user data
          getUserData();
          // Set loading state of delete button to false
          setDeleteBtnLoadinng(false);
          // Close delete modal
          setOpenDelete(false);
        } else {
          // Show error message
          toasterError("Somthing Went Wrong");
          // Close delete modal
          setOpenDelete(false);
          // Set loading state of delete button to false
          setDeleteBtnLoadinng(false);
        }
      })
      .catch((err) => {
        // Handle errors
        ErroHandling(err, function (res) {
          // Show error message
          toasterError(res)
        })

        // Set loading state of delete button to false
        setDeleteBtnLoadinng(false);
      })
  };
  // Function for Search Commodity
  const searchUser = (filterValue) => {
    // Set loading to true
    setLoading(true);
    // Create an array of parameters
    let prt = [`pageNumber=${page+1}`, `pageSize=${rowsPerPage}`];
    // Call ApiService to search for the user
    ApiService.search("managedata/GetCommodityDetails?", { searchText: encodeURIComponent(filterValue), }, prt)
      .then((res) => {
        // If response is successful, set all users and total count
        if (res.status === 200) {
          setAllUsers(res.data.data);
          setLoading(false);
          setTotalCount(res.data.totalCount);
        } else {
          // Else, display error message
          toasterError(`${res.data}`);
          setLoading(false);
        }
      })
      .catch((err) => {
        // Catch any errors and handle them
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

// This function toggles the drawer open or closed
const toggleDrawer = (open, mode) => (event, item) => {
  // If mode is set to delete, set the delete user data and open the delete window
  if (mode === "Delete") {
    setDeleteUserData(item);
    setOpenDelete(true);
  } else {
    // If mode is set to edit, set the form data
    if (mode === "Edit") {
      setFormData(item);
    }
    // Set the state with the right open, formData in edit mode and crudState
    setState({ ...state, right: open, formData: edit });
    setCrudState(mode);
  }
};


  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="left" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Commodity</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography>Commodity</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}

        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {(access.allowEdit) ? <>
                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: 16 }} />}>
                  Add Commodity
                </Button>
              </> : null}
            </Grid>
          </Grid>
        </Grid>

        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.searchByKeyword}
                      id="input-with-icon-textfield"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="serchByClear"
                        onClick={() => {
                          setSearchValue("");
                          getUserData();
                        }}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">
                      Total Records: <span className={classes.totalRecord}>{totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Commodity Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allUsers ? allUsers : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={setSorting}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Add & Edit Commodity Side Drawer */}
      <SideDrawer
        formComp={
          // Calling Add & Edit Commodity
          <CommoditySideBar
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setEdit}
            userId={null}
            hideSideDrawer={() => {
              setState({ right: false });
              getUserData();
            }}
          />
        }
        state={state}
        setState={setState}
      />

      {/* Alert Dialog - Confirmation for Delete */}
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to delete commodity "${deleteUserData?.name}" ?`}
          action={
            <Grid container alignItems="center" justifyContent="right" spacing={2} className={classes.alertDialogButton}>
              <Grid item xs={"auto"}>
                <Button onClick={() => { setOpenDelete(false); setDeleteBtnLoadinng(false); }}>No</Button>
              </Grid>
              <Grid item xs={"auto"}>
                <Button onClick={deleteUser} color="secondary">
                  {deleteBtnLoadinng ? (<CircularProgress size={25} />) : ("Yes")}
                </Button>
              </Grid>
            </Grid>
          }
        />
      ) : null}
    </Box>
  );
};

export default Commodity;