import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';

export default function ScrollingDialog(props) {
   
    const handleClose = () => {
        props.setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (props.open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props.open]);

    return (
        <div>
            {/* <Button onClick={handleClickOpen('paper')} variant="contained" color="accent2">Multiple</Button> */}
            {/* <Button onClick={handleClickOpen('body')}>scroll=body</Button> */}
            <Dialog
                open={props.open}
                onClose={handleClose}
                scroll={props.scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullScreen
                sx={{ width: "calc(100% - 30px)", m: '15px' }}
            >
                <DialogTitle id="scroll-dialog-title" className={props.dialogClass} sx={{ pt: '12px', pb: '10px' }}>
                    <IconButton aria-label="delete" onClick={handleClose}>
                        <CloseIcon style={{ color: "white", fontSize: 26 }} />
                    </IconButton>
                    {props.dialogTitle}
                </DialogTitle>
                {/* <LinearProgress color="secondary" />               */}

                <DialogContent dividers={props.scroll === 'paper'}>
               
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                        sx={{pt:1}}
                    >
                        <div>
                            {props.dialogContent}
                        </div>

                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose} variant="contained" >Save</Button>
                    <Button onClick={handleClose} variant="contained" color="accent3">Cancel</Button>
                </DialogActions> */}

            </Dialog>
        </div>
    );
}