import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    globalNavIcon: {
        fontSize: 24,
        color: "#ffffff",
    },
    avatarIcon: {
        background: "#ebfbff !important",
        fontSize: "14px !important",
        color: "#000 !important",
        fontWeight: "bold !important",
        marginRight: "10px",
        textTransform:"uppercase"

    },
    profileArea: {
        width: 140,
        [theme.breakpoints.up("sm")]: {
            width: 160,
        },
    },
    profileName: {
        fontSize: "14px",
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        color: "#ffffff",
        fontWeight: 400,
        lineHeight: "18px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: 140,
        [theme.breakpoints.up("sm")]: {
            width: 160,
        },
        textTransform:"capitalize"
    },
    profileEmail: {
        fontSize: "12px",
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        overflow: "hidden",
        color: "#ffffff",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: 140,
        [theme.breakpoints.up("sm")]: {
            width: 160,
        },
    },
    menuFont: {
        '& .MuiListItemIcon-root': {
            minWidth: '28px'
        },
        '& .MuiTypography-root': {
            fontSize: "14px",
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        }
    },
    arrowIcon: {
        color: "#ffffff",
        fontSize: "12px",
    },
    // Popup Styles
    popupArea: {
        padding: theme.spacing(3),
    },
    popupHeading: {
        padding: theme.spacing(0, 0, 3, 0),
    },
    notiArea: {
        border: '1px solid #d7d7d7',
        borderRadius: 6,
        padding: theme.spacing(2, 2, 2, 2),
    },
    notiBorder: {
        border: '1px solid #d7d7d7',
        borderRadius: 6,
    },
    notiHeader: {
        fontSize: 14,
    },
    notiMessage: {
        fontSize: 12,
        padding: theme.spacing(0.5, 0, 0, 0),
    },
    subHeding: {
        fontSize: "14px !important",
        lineHeight: "30px !important",
        fontWeight: "bold !important",
    },
    subHedingIcon: {
        fontSize: "22px !important",
        margin: theme.spacing(0, 1, 0, 0,) + "!important",
    },
    hintText: {
        fontSize: "12px !important",
        lineHeight: "24px !important",
    },
    closeStyle: {
        transform: "rotate(45deg)",
    },
}));

export default useStyles;