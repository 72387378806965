import React, { useEffect } from "react";
import { connect } from "react-redux";
// import history from "./routes/history";
import { ToastContainer } from "react-toastify";
import Routing from "./Routes/Routing";
import "./App.scss";
import { Loader } from "./Shared/Loader/Loader";
import { GetMenuAuthenticateService } from "./Services/AppService";
import { HashRouter, } from "react-router-dom";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import "@fontsource/outfit"; // Defaults to weight 400
import "@fontsource/outfit/400.css"; // Specify weight

const App = (props) => {
  // useEffect(() => {
  //   onLoad();
  // }, []);

  // const onLoad = async () => {
  //   const pathName = window.location.pathname;
  //   if (pathName !== "/login") {
  //     try {
  //       // api need to get user details.
  //       const resp = await GetMenuAuthenticateService();
  //       const find = resp.find((el) => el.route === pathName);
  //       console.log(find);
  //       //api need to call to get menu permission  and store in localstorage. GetAccessBymenuIdService
  //       // replace "/app/dashboard" with landing page path.
  //       // history.push(find ? find.route : '/app/dashboard');
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  // };

  return (
    <>
      {props.loading ? <Loader /> : null}
      <div className="appInitializedComponent">
        <ToastContainer autoClose={2000} limit={1} theme="colored" />        
          <Routing />
      </div>
    </>
  );
};

export default App;
