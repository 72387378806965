// Default React, useState and Material Components
import React, { useState } from "react";
import { Box, Breadcrumbs, Button, CircularProgress, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import SideDrawer from "../../../UIComponent/Drawer";
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { toasterSuccess, toasterError } from '../../../UIComponent/Toaster/index';

// Importing Add & Edit Components
import AddEditRegion from "./AddEditRegion";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const Region = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState([]);
  const [selected, SetSelected] = useState([]);
  const [allRegion, setAllRegion] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState();
  const [regionData, setRegionData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [state, setState] = useState({ right: false });
  const [startSearch, setStartSearch] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [deleteFrequencyData, setDeleteFrequencyData] = useState(null);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [menuPermission, setMenuPermission] = useState({ allowEdit: true, allowDelete: true, });
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(21, function (err, res) {
      if (res) {
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])
  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) { searchUser('') }
    else { searchUser(searchValue) }
  }, [sorting]);
  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) { searchUser('') }
    else { searchUser(searchValue) }
  }, [page, reload, rowsPerPage])

// This function toggles the drawer open or closed
const toggleDrawer = (open, mode) => (event, item) => {
  // Set region data to the item passed in
  setRegionData(item)
  // If mode is "Delete", set delete frequency data and open delete
  if (mode === "Delete") { setDeleteFrequencyData(item); setOpenDelete(true); }
  // Otherwise, set form data, state, and crud state
  else {
    setFormData(item);
    setState({ ...state, right: open, formData: item });
    setCrudState(mode);
  }
};
 

  // Assigning Columns and UI Props
  const columns = [{ id: "regionName", label: "Region", sorting: true }];

// This function handles the keyup event
const handleKeyUp = (event) => {
  // If backspace is pressed and target is not a button
  if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
    // If search value is empty, get status data
    if (searchValue.length === 0) { getStatusData(); }
  }
  // If enter is pressed and target is not a button
  if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
    // Reset total count, page and start search
    setTotalCount(0);
    setPage(0);
    setStartSearch(true)
    // Search user with the value of the target
    searchUser(event.target.value);
  }
};

  // Function for get Region Data
// Function to get status data
const getStatusData = () => {
  // Set loading to true
  setLoading(true);
  
  // If reload is true, set reload to false
  if (reload) { setReload(false); }
  
  // Create an array of parameters
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
  
  // Call ApiService search method with sorting and parameters
  ApiService.search("managedata/GetRegionDetails?", { sortColumn: changeSortColumnNameFormat(sorting.sortBy), isAscending: sorting.isAscending, },
    prt
  )
    .then((res) => {
      // If response status is 200, set all region, loading and total count
      if (res.status === 200) {
        let result = Array.isArray(res.data) ? res.data : res.data.data || [];
        setAllRegion(result);
        setLoading(false);
        setTotalCount(res.data.totalCount);
      } else { 
          // Else call toasterError function
          toasterError(`${res.data}`); 
          setLoading(false); 
      }
    })
    .catch((err) => {
      // Call ErroHandling function
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
};

  // Function to delete a region
const deleteRegion = () => {
  // Set loading state of delete button to true
  setDeleteBtnLoadinng(true);
  // Get data for deleting frequency
  let data = deleteFrequencyData;
  // Make an API call to check if the region is mapped with subregion
  axios.get(process.env.React_App_baseUrl + "managedata/IsRegionMapped", {
    headers: {
      id: data.regionId, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
  }
  ).then((res) => {
    // If the region is not mapped with subregion
    if (res.data == false) {
      // Make an API call to delete the region
      axios.post(process.env.React_App_baseUrl + "managedata/DeleteRegion", null, {
        headers: {
          id: data.regionId, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }).then((res) => {
        // If the API call is successful
        if (res.status === 200) {
          // Show success message
          toasterSuccess("Region deleted successfully")
          // Get status data
          getStatusData();
          // Set loading state of delete button to false
          setDeleteBtnLoadinng(false);
          // Close delete modal
          setOpenDelete(false);
        } else {
          // Show error message
          toasterError("Somthing went wrong")
          // Close delete modal
          setOpenDelete(false);
          // Set loading state of delete button to false
          setDeleteBtnLoadinng(false);
        }
      });
    } else {
      // Show error message
      toasterError('Region can not be deleted as its mapped with subregion.');
      // Close delete modal
      setOpenDelete(false);
      // Set loading state of delete button to false
      setDeleteBtnLoadinng(false);
    }
  }
  )
    .catch(err => {
      // Handle errors
      ErroHandling(err, function (res) {
        toasterError(res)
      })
        ; 
      // Close delete modal
      setOpenDelete(false); 
      // Set loading state of delete button to false
      setDeleteBtnLoadinng(false);
    });
};

  // Function for Global Search
  const globalSearch = (value) => { setSearchValue(value); };

// This function changes the format of a field name to a specific format
const changeSortColumnNameFormat = (fieldName) => {
  // If the fieldName is null or empty, return an empty string
  if (fieldName == null || fieldName == "") { return ""; }
  // If the fieldName is equal to 'regionname' in any case, set it to 'RegionName'
  if (fieldName.toLocaleLowerCase() == "regionname") { fieldName = "RegionName"; }
  // Return the formatted fieldName
  return fieldName;
}

// Function to search user based on filter value
const searchUser = (filterValue) => {
  // Set loading to true
  setLoading(true);
  // Create an array of parameters
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
  // Call ApiService to search for the user
  ApiService.search("managedata/GetRegionDetails?", {
    // Set sorting column and order
    sortColumn: changeSortColumnNameFormat(sorting.sortBy),
    isAscending: sorting.isAscending,
    // Set search text
    searchText: filterValue
  }, prt)
    .then((res) => {
      // If response status is 200, set search result, loading and total count
      if (res.status === 200) {
        let searchResult = Array.isArray(res.data) ? res.data : res.data.data || [];
        setAllRegion(searchResult);
        setLoading(false);
        setTotalCount(res.data.totalCount);
      } else { 
          // Else show error message
          toasterError(`${res.data}`); 
          setLoading(false); 
      }
    })
    .catch((err) => {
      // Handle errors
      setLoading(false); ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
};

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Region</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">Region</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {(access.allowEdit) ? <>
                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: 16 }} />}>
                  Add Region
                </Button>
              </> : null}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className={classes.searchByKeyword}
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className={classes.serchByClear}
                        onClick={() => {
                          setSearchValue("");
                          getStatusData();
                        }}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">
                      Total Records: <span className={classes.totalRecord}>{totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Region Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allRegion ? allRegion : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setReload(true)
                    setSorting(e)
                  }}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Add & Edit Commodity Side Drawer */}
      <SideDrawer
        formComp={
          <AddEditRegion
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            // userId={null}
            hideSideDrawer={() => {
              setState({ right: false });
              getStatusData();
            }}
            regionData={regionData}
          />
        }
        state={state}
        setState={setState}
      />

      {/* Alert Dialog - Confirmation for Delete */}
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to delete the region "${deleteFrequencyData?.regionName}" ?`}
          action={
            <Grid container alignItems="center" justifyContent="right" spacing={2} className={classes.alertDialogButton}>
              <Grid item xs={"auto"}>
                <Button onClick={() => setOpenDelete(false)}>No</Button>
              </Grid>
              <Grid item xs={"auto"}>
                <Button onClick={() => deleteRegion()} color="secondary">
                  {deleteBtnLoadinng ? (<CircularProgress size={25} />) : ("Yes")}
                </Button>
              </Grid>
            </Grid>
          }
        />
      ) : null}
    </Box>
  );
};

export default Region;