// Default React, Router and Services Components
import React from 'react'
import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";

// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";

const AddVariableReport = () => {
    const classes = useStyles();
    return (
        // Layout for Profile
        <Box className={classes.popupArea}>
            {/* Title for Side Drawer */}
            <Box className={classes.popupHeading}>
                <Typography id="modal-modal-title" variant="h5">
                    Add Report Variable (Report)
                </Typography>
            </Box>

            {/* Add & Edit Currency Form */}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TextField id="standard-basic"
                        label="Report Variable"
                        fullWidth
                        variant="standard" />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete options={top100Films()} fullWidth
                        renderInput={(params) => <TextField {...params} label="Movie" variant="standard" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="left">
                        <Grid item xs={12} sm={'auto'}>
                            <Button variant="contained" color="accent5">save</Button>
                        </Grid>
                        <Grid item xs={12} sm={'auto'}>
                            <Button variant="contained" color="accent3">cancel</Button>
                        </Grid>
                        <Grid item xs={12} sm={'auto'}>
                            <Button variant="contained" color="secondary">
                                create jira ticket
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Box>
    )
}

export default AddVariableReport;

const top100Films = () => [
    { label: 'CRU Variable', year: 1994 },
    { label: 'Unit Variable', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
];