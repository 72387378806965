import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // Reporting Calendar Styles
  breadCrumbsIcon: {
    fontSize: 10,
    marginLeft: 3
  },
  tableTopPart: {
    padding: theme.spacing(2)
  },
  totalRecord: {
    color: "#00519c",
  },
  container: {
    maxHeight: 640, // Table Max Height
  },
  colHighlight: {
    padding: theme.spacing(0, 0, 0, 1),
  },
  tableHeader: {
    padding: theme.spacing(0.5, 0.75),
    whiteSpace: "normal"
  },
  tableCell: {
    wordBreak: "break-all",
    wordWrap: "break-word",
    borderBottom: "1px solid #d7d7d7 !important",
    padding: theme.spacing(0.5, 0.75),
    height: "46px"
  },
  tableActionCell: {
    padding: theme.spacing(0.5, 0.75),
  },
  cellNoWrap: {
    whiteSpace: "nowrap",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  helperText: {
    display: "block",
    whiteSpace: "nowrap",
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  addBorder: {
    borderRadius: "6px",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  alertDialogbtn: {
    margin: "0% 5% 2% 0%",
  },
  rightDrowerbtn: {
    margin: "0% 5% 2% 0%",
    display: 'inline-flex'
  },
  alertDialogButton: {
    padding: theme.spacing(0, 2, 1.5, 0),
  },
  overviewIcons: {
    width: 40,
    display: "inline-block",
    textAlign: "center",
  },
  // Add & Edit User Popup Styles
  popupArea: {
    padding: theme.spacing(3),
  },
  popupHeading: {
    padding: theme.spacing(0, 0, 3, 0),
  },

  cruTable: {
    padding: 0,
    borderRadius: "6px",
    border: "0px solid #d7d7d7 !important",
  },
  cruThead: {
    padding: "0px 10px",
    background: "#f9f9f9",
    height: "46px",
    borderBottom: "1px solid #d7d7d7",
    fontWeight: "bold !important",
  },
  cruTd: {
    borderBottom: "1px solid #d7d7d7 !important",
    padding: "0px 10px !important",
    height: "46px",
  },
  "cruTd:hover": {
    background: "grey",
  },
  openStyle: {
    transform: "rotate(0deg)",
  },
  closeStyle: {
    transform: "rotate(45deg)",
  },
  subTitle: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    fontSize: '16px',
    fontWeight: '600',
    marginTop: '10px'
  },
  iconSize: {
    fontSize: "20px",
    width: '1em',
    height: '1em',
  },
  smallAlign: {
    margin: "0 !important",
    '& .MuiAutocomplete-root': {
      margin: "0 !important",
    },
  },
  tableBox: {
    borderRadius: "6px",
    border: "1px solid #d7d7d7",
  },
  searchIcon: {
    transform: "rotateY(180deg)",
    marginTop: "-2px",
    marginLeft: "5px",
  },
  commentStatus: {
    display: "block",
    marginTop: "5px",
    marginBottom: "0",
    color: "#999999",
  },
  cruTheadDoubleSep: {
    padding: "0px 6px !important",
    background: "#f9f9f9",
    height: "30px",
    borderBottom: "1px solid #d7d7d7",
    borderLeft: "1px solid #d7d7d7 !important",
    fontWeight: "bold !important",
  },
  cruTheadLeftSep: {
    padding: "0px 6px",
    background: "#f9f9f9",
    height: "30px",
    borderBottom: "1px solid #d7d7d7",
    borderLeft: "1px solid #d7d7d7 !important",
    fontWeight: "bold !important",
  },
  cruTheadRightSep: {
    padding: "0px 8px",
    background: "#f9f9f9",
    height: "30px",
    borderBottom: "1px solid #d7d7d7",
    borderRight: "1px solid #d7d7d7 !important",
    fontWeight: "bold !important",
  },
  cruTheadSep: {
    padding: "0px 6px",
    background: "#f9f9f9",
    height: "30px",
    borderBottom: "1px solid #d7d7d7",
    borderLeft: "1px solid #d7d7d7 !important",
    fontWeight: "bold !important",
  },
  cruTdSep: {
    borderBottom: "1px solid #d7d7d7 !important",
    borderLeft: "1px solid #d7d7d7 !important",
    padding: "0px 6px !important",
    height: "46px"
  },
}));

export default useStyles;