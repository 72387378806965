// Default React, Router and Services Components
import React, { useState } from "react";
import { Autocomplete, Box, Button, FormControlLabel, Grid, Switch, TextField, Typography, } from '@mui/material';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createFilterOptions } from "@mui/material/Autocomplete";
import axios from "axios";
import DatePicker from '../../../UIComponent/DatePicker'
import { makeStyles } from "@mui/styles";
// Importing Material and Fluentui Icons
import IconButton from "@mui/material/IconButton";
import { AddIcon, DeleteIcon } from '@fluentui/react-icons-mdl2';
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import dayjs from "dayjs";

// Importing useStyles
import useStyles from "./ReportingCalendar.styles";

const CreatePublicationDetails = (props) => {
    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    const [dropDown, setDropDown] = useState({ company: [], year: [], reportingperoid: [] })
    let intialData = {
        "isVisible": true, "Publication": "", "ReportingPeriodId": "", "Date": "",
        "Comment": "", "reportingPeriodLabel": ""
    }
    const [reportingPeroidValue, setReportingPeroidValue] = useState([{ ...intialData }])
    const [sendData, setSendData] = useState({
        "CompanyId": "2", "IsCRUCompany": '', "Company": "", "Q1": false, "Q2": false,
        "Q3": false, "Q4": false, "Annual": false, "Year": "", "YearId": "",
        "PublicationDetails": []
    });
    const [reportingPeriodIdCpy, setReportingPeriodIdCpy] = useState([]);
    const [intialValidation ] = useState({
        ReportingPeriod:false,
        PublicationDate:false
    })
    const [reportingperoidValidation, setreportingperoidValidation] = useState([intialValidation])
    const [loading, setLoading] = useState(false);
    const [calID, setcalId]= useState('')
    // Declaration of React Hooks Start Here
    React.useEffect(() => {
        Promise.all([
            axios.get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
                headers: { isNewSource: false,'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
             },
            }),
            axios.get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingPeriod",{
                headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
            }),
            axios.get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingYear",{
                headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
            })
        ]).then(resp => {
            let years = [];
            let reportingperoid = [];
            let company = (resp[0].data || []).map((el, ind) => { return { label: el.companyName, value: el.companyId, isCRUCompany: el.isCRUCompany, isNewSource: el.isNewSource } });
            setDropDown(prev => { return { ...prev, company: company || [] } });
            if (resp[1]) {
                reportingperoid = resp[1].data.map((el) => { return { label: el.name, value: el.id } })
                setDropDown(prev => { return { ...prev, reportingperoid: reportingperoid || [] } })
            }
            if (resp[2]) {
                years = resp[2].data.map((el) => { return { label: el.year.toString(), value: el.id } });
                // let currentYear = resp[2].data.find(ee => ee.isCurrentYear)?.id;
                let curYear = new Date().getFullYear()
                let currentYear = resp[2].data.find(ee => ee.year === curYear).id
                setDropDown(prev => { return { ...prev, year: years || [] } });
                setTimeout(() => {
                    if (props.calenderID !== null) { callingEditApi(currentYear, years, reportingperoid); }
                }, 100);
            }
        }).catch(function (error) { ErroHandling(error, function (res) {
            toasterError(res)}) });
    }, []);
    // Declaration of React Hooks End Here

    //This function is used to check if a company exists in the reporting calendar
        const CheckCompanyExitsReportingCalendar=(data)=>{
            //Making an axios get request to the specified url with the given headers
            axios.get(process.env.React_App_baseUrl + "reportingcalendar/CheckCompanyExitsReportingCalendar",{
                headers:{
                    CompanyId:data.value, //Company Id of the company
                    isCruCompany:data.isCRUCompany, //Boolean value to check if it is a CRU company
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
            
                }
            }).then((res)=>{
                //Setting the calId to the response data
                setcalId(res.data)
            }).catch(function (error) {ErroHandling(error, function (res) {
                //Calling the toasterError function with the response as parameter
                toasterError(res)})  });
        }


        // This function is used to call the edit API for a reporting calendar
        const callingEditApi = (year, years, reportingperoid) => {
            // Making an axios get request to the specified URL with the given headers
            axios.get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingCalendarDetails", {
                headers: { ReportingCalendarId: props.calenderID?props.calenderID:calID, yearId: year,'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
            }
            })
                .then(function (response) {
                    // Checking if the response status is 200
                    if (response.status === 200) {
                        const data = response.data || {};
                        // Setting the sendData state with the response data
                        setSendData(prev => {
                            return {
                                ...prev,
                                CompanyId: data.companyId || '',
                                IsCRUCompany: data.isCRUCompany ,
                                Company: data.company || '',
                                "Year": years.find(el => el.value === year)?.label || '',
                                "YearId": year,
                                "Q1": data.q1 || false,
                                "Q2": data.q2 || false,
                                "Q3": data.q3 || false,
                                "Q4": data.q4 || false,
                                "Annual": data.annual || false,
                                "createdOn": data.createdOn || null,
                                "id": data.id
                            }
                        });
                        // Mapping the publication details from the response data
                        const pd = data.publicationDetails.map(el => {
                            let date = new Date(el.date);
                            date = date ?
                                date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + (date.getDate())).slice(-2) : '';
                            return {
                                "isVisible": true,
                                "Publication": el.publication,
                                "ReportingPeriodId": el.reportingPeriodId,
                                "Date": date,
                                "Comment": el.comment,
                                "reportingPeriodLabel": reportingperoid.find(e => e.value === el.reportingPeriodId)?.label || '',
                                "reportingPublicationId": el.reportingPublicationId,
                                isDeleted: false,
                                isEdited: false,
                                displayDate: el.displayDate
                            }
                        });
                        // Setting the reportingPeriodIdCpy state with the mapped publication details
                        setReportingPeriodIdCpy(pd);
                        const pDItem = pd && pd.length ? pd : [{ ...intialData }];
                        // Setting the reportingPeroidValue state with the publication details
                        setReportingPeroidValue(pDItem);
                        let val= []
                        // Checking if the publication details length is greater than 0
                        if(data.publicationDetails.length> 0){
                            // Looping through the publication details and setting the validation state
                            data.publicationDetails.forEach(da => {
                                val.push({
                                    ReportingPeriod: true,
                                    PublicationDate: true
                                })
                            });
                            setreportingperoidValidation(val)
                        }else {
                            // Setting the validation state with the intialValidation
                            setreportingperoidValidation([intialValidation])
                        }
                    }
                })
                // Handling errors
                .catch(function (error) {ErroHandling(error, function (res) {
            toasterError(res)})  });
        }


        // This function checks the publication date and reporting period of a given data set
const checkPublication =()=>{
    // Filters out elements with isVisible property set to true
    const fReportingPs = reportingPeroidValue.filter(e => e.isVisible);
    // Stores the data to be sent
    const fReportingP = sendData;
    // Stores the validation data
    const val = reportingperoidValidation
    let status
    // Checks if all elements in the validation data have PublicationDate and ReportingPeriod properties
    let result=  val.every(el => el.PublicationDate && el.ReportingPeriod )
    // If the validation data has elements and all elements have PublicationDate and ReportingPeriod properties
    if(result && val.length > 0){
        // Iterates through each element in the filtered data
        fReportingPs.forEach(el => {
                // If the element has Date and ReportingPeriodId properties
                if(el.Date && el.ReportingPeriodId){
                    // Sets status to true
                    status = true
                }
                // If the element does not have Date property
                if(!el.Date){
                    // Sets status to false and displays warning message
                    status = false
                    toast.warning("Please fill the publication date.", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        toastId: 'warning',
                    });
                }
                // If the element does not have ReportingPeriodId property
                if(!el.ReportingPeriodId){
                    // Sets status to false and displays warning message
                    status = false
                    toast.warning("Please fill the reporting period.", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        toastId: 'warning',
                    });
                }
        });
    }else {
        // If the validation data does not have any elements or all elements do not have PublicationDate and ReportingPeriod properties, sets status to true
        status = true
    }
 // Returns the status
 return status
}

   // This function is used to save the data
    const save = () => {
        // Check if all elements in reportingperoidValidation have PublicationDate and ReportingPeriod
        let result=  reportingperoidValidation.every(el => el.PublicationDate && el.ReportingPeriod )
        const fReportingP = reportingPeroidValue;
        // Check if Company, Year and fReportingP are not empty and checkPublication() returns true
        if (!!sendData.Company.length && !!sendData.Year.length && !!fReportingP && checkPublication() ) {
            // Get current date
            let selectedDate = new Date();
            // Create request data object
            let reqData = {
                companyId: sendData.CompanyId,
                isCRUCompany: sendData.IsCRUCompany,
                company: sendData.Company,
                reportingYearId: sendData.YearId,
                createdOn: selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2),
                q1: sendData.Q1,
                q2: sendData.Q2,
                q3: sendData.Q3,
                q4: sendData.Q4,
                annual: sendData.Annual,
                // Map over fReportingP and create an array of objects with publication, reportingPeriodId, displayDate, date, comment, isEdited, isDeleted and reportingPublicationId
                publicationDetails: result? fReportingP.map(el => {
                    return {
                        publication: el.Publication,
                        reportingPeriodId: el.ReportingPeriodId,
                        displayDate: "",
                        date: el.Date,
                        comment: el.Comment,
                        isEdited: el.reportingPublicationId?true:false,
                        isDeleted: !el.isVisible,
                        reportingPublicationId: el.reportingPublicationId?el.reportingPublicationId:0,
                    }
                }):[]
            }
            // Call submittingApi function with reqData and 'Added' as parameters
            submittingApi(reqData, 'Added');
            
        } else {
            // Show warning message if required data is not filled
            toast.warning("Please fill the required data.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: 'warning',
            }); 
        }
    }
   

    // Function to update the reporting period value
const update = () => {
    // Iterate through the reportingPeriodValue array and remove any elements that don't have a Date or ReportingPeriodId
    reportingPeroidValue.forEach((el, i) => {
            if(!el.Date && !el.ReportingPeriodId){
                reportingPeroidValue.splice(i, 1)
            }
    });
  // Check if publication is valid
  if(checkPublication()){
      // Create request data object
      let reqData = {
          id: sendData.id,
          companyId: sendData.CompanyId,
          isCRUCompany: sendData.IsCRUCompany,
          company: sendData.Company,
          reportingYearId: sendData.YearId,
          createdOn: sendData.createdOn,
          q1: sendData.Q1,
          q2: sendData.Q2,
          q3: sendData.Q3,
          q4: sendData.Q4,
          annual: sendData.Annual,
          // Map through the reportingPeriodValue array and create an object with the relevant data
          publicationDetails: reportingPeroidValue.map((el, ind) => {
              let isEdit = false;
              // Find the reportingPublicationId in the reportingPeriodIdCpy array
              const fRID = reportingPeriodIdCpy.find(e => e.reportingPublicationId === el.reportingPublicationId);
              // Check if the ReportingPeriodId, Date, or Comment has been edited
              isEdit = fRID ? fRID.ReportingPeriodId !== el.ReportingPeriodId || fRID.Date !== el.Date || fRID.Comment !== el.Comment : false;
              return {
                  reportingPublicationId: el.reportingPublicationId ? el.reportingPublicationId: 0 ,
                  publication: el.Publication,
                  reportingPeriodId: el.ReportingPeriodId,
                  displayDate: el.displayDate,
                  date: el.Date,
                  comment: el.Comment,
                  isEdited:el.reportingPublicationId?true:false,
                  isDeleted: !el.isVisible
              }
          })
        }
        // Submit the API request with the reqData object
        submittingApi(reqData, 'Updated');
       
  }
}
   

   // This function is used to submit API request
const submittingApi = (reqData, message) => {
    // Set loading to true
    setLoading(true);
    // Make a GET request to check if the company exists in the reporting calendar
    axios.get(process.env.React_App_baseUrl + "reportingcalendar/CheckCompanyExitsReportingCalendar",{
        headers:{
            CompanyId:sendData.CompanyId,
            isCruCompany:sendData.IsCRUCompany,
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    
        }
    })
    // If the response status is 200, make a POST request to save the publication
    .then((res)=>{
        if(res.status === 200){
                reqData.id =res.data
                axios.post(process.env.React_App_baseUrl + "reportingcalendar/SavePublication", reqData,{
                    headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
                })
                    // If the response status is 200, set right to false, recall API and show success message
                    .then(function (response) {
                        if (response.status === 200) {
                            props.setState({ right: false });
                            props.recallApi();
                            toasterSuccess(`Successfully ${message}.`);
                            setLoading(false);
                        }
                    })
                    // If there is an error, handle it and show error message
                    .catch(function (error) {
                        ErroHandling(error, function (res) {
                            toasterError(res)}) ;
                            setLoading(false);
                    });
        }else{
            setLoading(false);
        }
    })
}
   

// Function to set the reporting period value
const reportingPeroid = () => {
    // Spread operator to add new data to existing array
    setReportingPeroidValue([...reportingPeroidValue, { ...intialData }])
    // Spread operator to add new validation data to existing array
    setreportingperoidValidation([...reportingperoidValidation, {...intialValidation}])
}

// Function to remove a reporting period
const reportingPeroidRemove = (element, index) => {
    // Get the current value of the reporting period
    let rpValue = reportingPeroidValue
    // Get the current validation of the reporting period
    let valid= reportingperoidValidation
    // Remove the element from the validation array
    valid.splice(index, 1)
    // Update the validation array
    setreportingperoidValidation([...valid])
    // Set the visibility of the element to false
    element.isVisible = false;
    // If the reporting publication ID is not present
    if(!rpValue[index].reportingPublicationId){
       // Remove the element from the reporting period value array
       rpValue.splice(index, 1) 
    }
    // Update the reporting period value array
    setReportingPeroidValue([...reportingPeroidValue])
}

// This function is used to update the reporting period and publication date validation
const reportingOnChange = (event, el, i) => {
    // Create a copy of the reporting period validation array
    let validationRP = reportingperoidValidation
    // Set the reporting period and publication date to true
    validationRP[i].ReportingPeriod = true
    validationRP[i].PublicationDate= true
    // Update the reporting period validation array
    setreportingperoidValidation([...validationRP])
    // Set the reporting period id and label
    el.ReportingPeriodId = event.value;
    el.reportingPeriodLabel = event.label
    // Update the reporting period value array
    setReportingPeroidValue([...reportingPeroidValue]) 
}

// Function to update the date when it is changed
const dateOnChange = (e, el , i) => {
    // Set the Date value to the element
    el.Date = e
    // Update the reportingPeroidValue array
    setReportingPeroidValue([...reportingPeroidValue])
    // Get a copy of the reportingperoidValidation array
    let validationRP = [...reportingperoidValidation]
    // Loop through the validationRP array
    validationRP.forEach((rpv, index) => {
        // Check if the index matches the given index
        if(index === i){
            // Set the ReportingPeriod and PublicationDate to true
            rpv.ReportingPeriod = true
            rpv.PublicationDate= true
        }
    });
    // Update the reportingperoidValidation array
    setreportingperoidValidation([...validationRP])
}
// This function updates the comment value when an event is triggered
const commentChange = (e, el) => {
    // Set the comment value to the target value of the event
    el.Comment = e.target.value
    // Update the reportingPeroidValue array with the new comment value
    setReportingPeroidValue([...reportingPeroidValue])
}

// This function filters out the options from the given array based on the reporting period ids
const dynamicRendering = (options, index) => {
    // Create an array of all the reporting period ids
    const selectedrepIds = reportingPeroidValue.map(el => el.ReportingPeriodId);
    // Remove the element at the given index
    selectedrepIds.splice(index, 1);
    // Create a new array with the given options
    const nwOpt = [...options];
    // Return the filtered array
    return nwOpt.filter(el => !selectedrepIds.includes(el.value));
}

// This function is used to update the year value when it is changed
const yearOnchange = (value) => {
    // Update the sendData with the new year value
    setSendData(prev => {
        return { ...prev, Year: value.label, YearId: value.value }
    }); 
        // If calID is present, call the edit API with the new year value
        if(calID){
            callingEditApi(value.value, dropDown.year, dropDown.reportingperoid)
        }
}

// Function to close the drawer
const close = () => {
    // Call recallApi function
    props.recallApi();
    // Set right state to false
    props.setState({ right: false });
    // Check if closeCreatePublicationDrawer is not null
    if (props.closeCreatePublicationDrawer != null) {
        // Call closeCreatePublicationDrawer function
        props.closeCreatePublicationDrawer();
    }
}

// Create a filterOptions object with the given parameters
const filterOptions = createFilterOptions({
    // Match from the start of the string
    matchFrom: 'start',
    // Stringify the option by returning its label
    stringify: (option) => option.label,
});

    return (

        // Layout for Add and Edit
        <Box className={classes.popupArea}>

            {/* Title for Side Drawer */}
            <Box className={classes.popupHeading}>
                <Typography id="modal-modal-title" variant="h5">
                    {props.calenderID === null ? 'Create' : 'Edit'} Publication Details
                </Typography>
            </Box>

            {/* Filter Form */}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Autocomplete
                        id="combo-box-demo"
                        fullWidth
                        disabled={props.calenderID !== null}
                        options={dropDown.company}
                        disableClearable
                        filterOptions={filterOptions}
                        value={sendData.Company}
                        onChange={(e, value) => { 
                            setSendData({ ...sendData, Company: value.label, CompanyId: value.value, IsCRUCompany: value.isCRUCompany, isNewSource: value.isNewSource }) 
                            if(!props.calenderID){
                                CheckCompanyExitsReportingCalendar(value)
                            }
                        }}
                        renderInput={(params) => <TextField {...params} label="Company" variant="standard" required />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        id="combo-box-demo"
                        disabled ={(sendData.CompanyId && sendData.Company) ? false:true}
                        fullWidth
                        options={dropDown.year}
                        disableClearable
                        value={sendData.Year}
                        onChange={(e, value) => yearOnchange(value)}
                        renderInput={(params) => <TextField {...params} label="Year"  variant="standard" required />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="left">
                        <Grid item xs={8}>
                            <Typography className={classes.subTitle}>
                                Reporting Period
                            </Typography>
                        </Grid>

                        <Grid item xs={4} textAlign="right">
                            <Button variant="contained" color="accent3" size="small" onClick={reportingPeroid} 
                            startIcon={<AddIcon style={{ fontSize: "12px" }} />}>Add</Button>
                        </Grid>
                    </Grid>
                    {reportingPeroidValue.filter(e => e.isVisible).map((el, index) => {
                        return (
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Box sx={{ pt: 2, pr: 2, pl: 2, pb: 0, borderRadius: '6px', border: 1, borderColor: 'grey.400', }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                className="no-clear-icon"
                                                fullWidth
                                                disableClearable
                                                options={dynamicRendering(dropDown.reportingperoid, index)}
                                                value={el.reportingPeriodLabel}
                                                onChange={(event, ot) => reportingOnChange(ot, el, index)}
                                                renderInput={(params) => <TextField {...params} label="Reporting Period" variant="standard" required={reportingperoidValidation && reportingperoidValidation[index] &&reportingperoidValidation[index].ReportingPeriod? reportingperoidValidation[index].ReportingPeriod:false} />}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DatePicker
                                                labelTitle="Publication Date"
                                                variant="standard"
                                                past={true}
                                                minDates={dayjs(new Date().setDate(new Date().getDate() + 1)).format('YYYY/MM/DD')}
                                                required={reportingperoidValidation && reportingperoidValidation[index] && reportingperoidValidation[index].PublicationDate ? reportingperoidValidation[index].PublicationDate:false}
                                                defaultDate={el.Date}
                                                onChangeDate={(e) => {
                                                    let date;
                                                    if(e){
                                                        let selectedDate = new Date(e);
                                                         date = selectedDate.getFullYear() +
                                                        "-" + ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                                                        "-" + ("0" + selectedDate.getDate()).slice(-2);
                                                        //console.log('date: ', date);
                                                    }else {
                                                        date= ''
                                                    }
                                                        dateOnChange(date, el, index)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="d-flex field-gap">
                                                <div className="flex-grow-1">
                                                    <TextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        label="Comments"
                                                        variant="standard"
                                                        value={el.Comment}
                                                        onChange={(event) => commentChange(event, el)}
                                                        multiline
                                                        maxRows={4}
                                                    />
                                                </div>
                                                <div>
                                                    <IconButton
                                                        aria-label="Delete"
                                                        style={{ marginTop: 13, marginLeft: 10 }}
                                                        onClick={() => { reportingPeroidRemove(el, index) }}
                                                    >
                                                        <DeleteIcon style={{ fontSize: '20px', color: 'red' }} />
                                                    </IconButton>
                                                </div>
                                            </div>

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.subTitle} style={{ marginTop: 0, marginBottom: "15px" }}>
                        Collection Frequency
                    </Typography>
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', mb: -1 }}>
                        <Grid item textAlign="center">
                            <FormControlLabel
                                value="Q1"
                                control={<Switch color="secondary" value={sendData.Q1} checked={sendData.Q1}
                                    onChange={(e) => { setSendData({ ...sendData, Q1: !sendData.Q1 }) }} />}
                                label="Q1"
                                labelPlacement="top"
                            />
                        </Grid>
                        <Grid item textAlign="center">
                            <FormControlLabel
                                value="Q2"
                                control={<Switch color="secondary" value={sendData.Q2} checked={sendData.Q2}
                                    onChange={(e) => { setSendData({ ...sendData, Q2: !sendData.Q2 }) }} />}
                                label="Q2"
                                labelPlacement="top"
                            />
                        </Grid>
                        <Grid item textAlign="center">
                            <FormControlLabel
                                value="Q3"
                                control={<Switch color="secondary" value={sendData.Q3} checked={sendData.Q3}
                                    onChange={(e) => { setSendData({ ...sendData, Q3: !sendData.Q3 }) }} />}
                                label="Q3"
                                labelPlacement="top"
                            />
                        </Grid>
                        <Grid item textAlign="center">

                            <FormControlLabel
                                value="Q4"
                                control={<Switch color="secondary" value={sendData.Q4} checked={sendData.Q4}
                                    onChange={(e) => { setSendData({ ...sendData, Q4: !sendData.Q4 }) }} />}
                                label="Q4"
                                labelPlacement="top"
                            />

                        </Grid>
                        <Grid item textAlign="center">
                            <FormControlLabel
                                value="Annual"
                                control={<Switch color="secondary" value={sendData.Annual} checked={sendData.Annual}
                                    onChange={(e) => { setSendData({ ...sendData, Annual: !sendData.Annual }) }} />}
                                label="Annual"
                                labelPlacement="top"
                            />
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="left">
                        <Grid item xs={'auto'}>
                            {/* {props.calenderID === null ?
                                <Button variant="contained" color="primary" onClick={save}>Save</Button> :
                                <Button variant="contained" color="primary" onClick={update}>Update</Button>
                            } */}
                            <LoadingButton
                            buttonName={props.calenderID === null ? "Save" : "Update"}
                            clickHandler={props.calenderID === null ? save : update}
                            isLoading ={loading} />
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Button variant="contained" onClick={() => close()} color="accent3">Cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

        </Box >
    );
};

export default CreatePublicationDetails;