// Default React, Router and Services Components
import React, { useState } from "react";
import {
    Button,
    Box,
    Breadcrumbs,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    Backdrop,
    LinearProgress,
} from "@mui/material";
import axios from "axios";
import XLSX from "sheetjs-style";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
import { Link } from "react-router-dom";
//import history from '../../Routes/History';
import { useHistory } from "react-router-dom";
import ApiService from "../../../Services/ApiService";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";
// Importing Custom Components
import BaseLayout from "../../../UIComponent/BaseLayout";
import CircularProgress from "@mui/material/CircularProgress";
import DataTable from "../../../UIComponent/DataTable";
import SearchExportFilter from "./DataCollectionSearchExportFilter";
import SideDrawer from "../../../UIComponent/Drawer";

// Importing useStyles
import useStyles from "./SearchExport.styles";

// Import Fluent UI Icons
import {
    ChevronRightSmallIcon,
    ClearIcon,
    SearchIcon,
    ExportIcon,
} from "@fluentui/react-icons-mdl2";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    setFiterValue,
    setTreeComm,
    setTreeCompany,
    setTreeLocation,
} from "../../../features/filter/StateSlice";
import { indexOf } from "lodash";
import CustomDataCollectionSearchAndExportDataTable from "../../../UIComponent/CustomDataTable/CustomDataCollectionSearchAndExportDataTable";
import pLimit from "p-limit";
const DataCollectionSearchExport = (props) => {
    const filterData = useSelector((store) => store.stateSlice.filterValue);
    const dispatch = useDispatch();
    const { treeLocation, treeCompany, treeComm } = useSelector(
        (store) => store.stateSlice
    );

    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    const [allRows, setAllRows] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = React.useState(0);
    const [open, setOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [isExporting, setExporting] = useState(false);
    const [selected, SetSelected] = useState([]);
    const [idforEdit, setIdForEdit] = useState(null);
    const [editJobNumber, setEditJobNumber] = useState(null);
    const [isAllSelected, setIsSelected] = useState(false);
    const [ischecked, setIsChecked] = useState([]);
    const [state, setState] = React.useState({ right: false });
    const [menuPermission, setMenuPermission] = useState({
        allowView: true,
        allowEdit: true,
        allowDelete: true,
    });
    const [crudState, setCrudState] = React.useState(false);
    const [formData, setFormData] = useState([]);
    const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
    const [reload, setReload] = useState(false);
    const [scroll, setScroll] = React.useState("paper");
    const [filterValue, setFilterValue] = useState(null);
    const [tableData, setTableData] = useState([]);
    const date = new Date().toLocaleDateString();
    const date1 = date.split("/").join("-");
    const [value, setValue] = useState({ startDate: "", endDate: "" });
    const [viewDataButtonDisable, setviewDataButtonDisable] = useState(true);
    const [exportToExcel, setExportToExcel] = useState(false);
    const [dataForFilter, setDataForFilter] = React.useState([]);
    const [jobName, setJobName] = useState("");
    const [filterFinalData, setFilterFinalData] = useState();
    const [companyFilter, setCompanyFilter] = useState([]);
    const [locationFilter, setLocationFilter] = useState([]);
    const [excelColumns, setExcelColumns] = useState([]);
    const [allComment, setAllComent] = useState([]);
    const [filterValues, setFilterValues] = useState({
        JobName: "",
        Company: [],
        Asset: [],
        CountryIds: [],
        RegionIds: [],
        SubRegionIds: [],
        CommodityIds: [],
        FromDate: null,
        ToDate: null,
        Status: null,
        SearchText: null,
    });
    const [selectedJob, setSelectedJob] = useState(null);
    const [access, setAccess] = React.useState({
        allowEdit: false,
        allowDelete: false,
        allowView: false,
    });
    const history = useHistory();
    const [allJobAssetIds, setAllJobAssetIds] = React.useState([]);
    const [deselectedValue, setDeselectedValue] = React.useState([]);
    const [selectedValue, setSelectedValue] = React.useState([]);
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    // const handleClose = () => {
    //   setBackdropOpen(false);
    // };
    // const handleOpen = () => {
    //   setBackdropOpen(true);
    // };
    // Declaration of States and Component Variables End Here

    // Assigning Columns and UI Props
    // Define an array of objects called gridcolumns
    const gridcolumns = [
        // Define the first object in the array
        {
            id: "jobAssetId", // Set the id property to "jobAssetId"
            label: "jobAssetId", // Set the label property to "jobAssetId"
            type: "Highlight" // Set the type property to "Highlight"
        },
        // Define the second object in the array
        {
            id: "jobNoName", // Set the id property to "jobNoName"
            label: "Job Name", // Set the label property to "Job Name"
            minWidth: 350, // Set the minWidth property to 350
            width: 350, // Set the width property to 350
            sorting: true, // Set the sorting property to true
        },
        // Define the third object in the array
        {
            id: "companyName", // Set the id property to "companyName"
            label: "Company", // Set the label property to "Company"
            minWidth: 300, // Set the minWidth property to 300
            width: 300, // Set the width property to 300
            sorting: true, // Set the sorting property to true
        },
        // Define the fourth object in the array
        {
            id: "assetName", // Set the id property to "assetName"
            label: "Asset", // Set the label property to "Asset"
            minWidth: 200, // Set the minWidth property to 200
            width: 200, // Set the width property to 200
            sorting: true, // Set the sorting property to true
        },
        // Define the fifth object in the array
        {
            id: "commodity", // Set the id property to "commodity"
            label: "Commodity", // Set the label property to "Commodity"
            minWidth: 300, // Set the minWidth property to 300
            width: 300, // Set the width property to 300
            sorting: true, // Set the sorting property to true
            truncate: 90, // Set the truncate property to 90
            tooltipWithTruncate: true, // Set the tooltipWithTruncate property to true
        },
        // Define the sixth object in the array
        {
            id: "countryName", // Set the id property to "countryName"
            label: "Country", // Set the label property to "Country"
            minWidth: 180, // Set the minWidth property to 180
            width: 180, // Set the width property to 180
            sorting: true, // Set the sorting property to true
        },
        // Define the seventh object in the array
        {
            id: "regionName", // Set the id property to "regionName"
            label: "Region", // Set the label property to "Region"
            minWidth: 180, // Set the minWidth property to 180
            width: 180, // Set the width property to 180
            sorting: true, // Set the sorting property to true
        },
        // Define the eighth object in the array
        {
            id: "subRegionName", // Set the id property to "subRegionName"
            label: "Subregion", // Set the label property to "Subregion"
            minWidth: 180, // Set the minWidth property to 180
            width: 180, // Set the width property to 180
            sorting: true, // Set the sorting property to true
        },
    ];


    // Function to handle key up event
    /// <summary>
    /// Function that handles the key up event.
    /// </summary>
    /// <param name="event">The key up event object.</param>
    const handleKeyUp = (event) => {
        // Check if the key code is 8 (backspace) or the key code is 8 (backspace) and the target element is not a button
        if (
            (event.keyCode === 8 || event.which === 8) &&
            event.target.nodeName !== "BUTTON"
        ) {
            // Check if the search value is empty
            if (searchValue.length === 0) {
                // Do something if the search value is empty
            }
        }
        // Check if the key code is 13 (enter) or the key code is 13 (enter) and the target element is not a button
        if (
            (event.keyCode === 13 || event.which === 13) &&
            event.target.nodeName !== "BUTTON"
        ) {
            // Dispatch an action to set the filter value with the search value
            dispatch(setFiterValue({ searchText: searchValue }));
            // Call the GetSearchExportReport function
            GetSearchExportReport();
        }
    };

    // Declaration of React Hooks Start Here
    // This code is using the useEffect hook from React to perform a side effect when the component mounts or updates.
    React.useEffect(() => {
        // The GetMenuService function is called with the argument 32 and a callback function.
        GetMenuService(32, function (err, res) {
            // If a response is received from the GetMenuService function,
            if (res) {
                // The response data is logged to the console (commented out).
                //console.log(res.data)
                // The setAccess function is called with the response data to update the state.
                setAccess(res.data);
            } else {
                // If an error occurs, the toasterError function is called with the error message.
                toasterError(err);
            }
        });
    }, []);

    // This code is using the useEffect hook from React
    React.useEffect(() => {
        // This function call is getting filter data
        getFilterData();
    }, [reload]);
    // The useEffect hook is triggered whenever the value of 'reload' changes

    // This code is using the useEffect hook from React
    React.useEffect(() => {
        // This function is called when the component mounts or when the dependencies change
        GetSearchExportReport();
    }, [filterData, reload]); // The dependencies for this effect are filterData and reload

    // This code is using the useEffect hook from React to execute the GetSearchExportReport function
    // whenever the sorting, page, or rowsPerPage variables change.
    React.useEffect(() => {
        GetSearchExportReport();
    }, [sorting, page, rowsPerPage]);
    // Declaration of React Hooks End Here

    // For searching the grid
    /// <summary>
    /// Sets the search value for global search.
    /// </summary>
    /// <param name="value">The value to set as the search value.</param>
    // Function to update the search value globally
    const globalSearch = (value) => {
        setSearchValue(value); // Set the search value to the provided value
    }

        /// <summary>
        /// Changes the format of the sort column name based on the given field name.
        /// </summary>
        /// <param name="fieldName">The field name to be formatted.</param>
        /// <returns>The formatted field name.</returns>
        const changeSortColumnNameFormat = (fieldName) => {
            // If the fieldName is null or empty, return an empty string
            if (fieldName == null || fieldName == "") {
                return "";
            }
            // If the fieldName is "jobNo" (case insensitive), change it to "JobNo"
            if (fieldName.toLocaleLowerCase() == "jobNo".toLocaleLowerCase()) {
                fieldName = "JobNo";
            }
            // If the fieldName is "jobNoName" (case insensitive), change it to "JobNoName"
            if (fieldName.toLocaleLowerCase() == "jobNoName".toLocaleLowerCase()) {
                fieldName = "JobNoName";
            }
            // If the fieldName is "companyName" (case insensitive), change it to "CompanyName"
            if (fieldName.toLocaleLowerCase() == "companyName".toLocaleLowerCase()) {
                fieldName = "CompanyName";
            }
            // If the fieldName is "assetName" (case insensitive), change it to "AssetName"
            if (fieldName.toLocaleLowerCase() == "assetName".toLocaleLowerCase()) {
                fieldName = "AssetName";
            }
            // If the fieldName is "commodity" (case insensitive), change it to "Commodity"
            if (fieldName.toLocaleLowerCase() == "commodity".toLocaleLowerCase()) {
                fieldName = "Commodity";
            }
            // If the fieldName is "countryName" (case insensitive), change it to "CountryName"
            if (fieldName.toLocaleLowerCase() == "countryName".toLocaleLowerCase()) {
                fieldName = "CountryName";
            }
            // If the fieldName is "regionName" (case insensitive), change it to "RegionName"
            if (fieldName.toLocaleLowerCase() == "regionName".toLocaleLowerCase()) {
                fieldName = "RegionName";
            }
            // If the fieldName is "subRegionName" (case insensitive), change it to "SubRegionName"
            if (fieldName.toLocaleLowerCase() == "subRegionName".toLocaleLowerCase()) {
                fieldName = "SubRegionName";
            }
            // Return the modified fieldName
            return fieldName;
        };


        // This function is used to get the export report of a given filter value 
        /// <summary>
        /// Retrieves a search export report based on the provided filter value.
        /// </summary>
        /// <param name="filterValue">The value used to filter the search export report.</param>
        /// <returns>The search export report.</returns>
        const GetSearchExportReport = (filterValue) => {
            // Set loading status to true on start of the function
            setLoading(true);
            // Check if there exists a search text in the filterValues object i.e. filterValue 
            if (filterValues.SearchText) {
                // Add SearchText property with the given search value in the filterValues object 
                Object.defineProperties(filterValues, {
                    SearchText: {
                        value: searchValue,
                    },
                });
                // Uncomment this line to add SearchText property with the given search value in the filterValues object 
                // filterValues.SearchText = searchValue;
            }
            // Initialize an array prt which will hold pageNumber and pageSize values
            let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
            // Call the ApiService search passing the url, filter data and sorting parameters  
            ApiService.search(
                `SearchAndExport/GetSearchAndExportList?`,
                {
                    filter: filterData
                        ? encodeURIComponent(JSON.stringify(filterData))
                        : "",
                    sortColumn: changeSortColumnNameFormat(sorting.sortBy),
                    isAscending: sorting.isAscending,
                },
                prt
            )
                // Handle the promise response 
                .then((res) => {
                    // If api response is successful
                    if (res.status === 200) {
                        // Uncomment below lines to assign api response data to variables
                        // setAllRows(res.data.data);
                        // setTotalCount(res.data.totalCount);
                        // setTableData(res.data.data);
                        // Set loading status to false on successful api response 
                        // setLoading(false);

                        // Check if page property is equal to 0, if yes then assign listJobAssetIds from the api response data to variable
                        if (page === 0) {
                            // Assign listJobAssetIds to variable  
                            setAllJobAssetIds(res.data.listJobAssetId);
                        }
                        // Assign listSearchAndExportOwnershipChangesDto from the api response data to variables 
                        setAllRows(res.data.listSearchAndExportOwnershipChangesDto.data);
                        setTotalCount(
                            res.data.listSearchAndExportOwnershipChangesDto.totalCount
                        );
                        // Set loading status to false on successful api response
                        setLoading(false);
                    } else {
                        // Set loading status to false on unsuccessful api response
                        setLoading(false);
                    }
                })
                // Catch any errors while handling the promise response.
                .catch((err) => {
                    // Call ErroHandling function to handle the error
                    ErroHandling(err, function (res) {
                        // Show toaster error notification with the passed error message in the argument
                        toasterError(res);
                    });
                    // Set loading status to false on unsuccessful promise response
                    setLoading(false);
                });
        };

        // Function for Getting View Data

        /// <summary>
        /// Retrieves a list of view data based on the provided filter value.
        /// </summary>
        /// <param name="filterValue">The value used to filter the view data.</param>
        /// <param name="exportExcel">Indicates whether the view data should be exported to Excel.</param>
        /// <returns>A list of view data.</returns>
        const GetViewDataList = (filterValue, exportExcel = false) => {
            let promises = []; // Array to store promises
            let limit = pLimit(2); // Set default limit to 2
            if (process.env.React_App_P_limit) {
                limit = pLimit(Number(process.env.React_App_P_limit)); // Set limit to the value specified in the environment variable
            }
            const wb = XLSX.utils.book_new(); // Create a new Excel workbook
            let fileName = `Search and Export ${date1} `; // Set the filename for the exported Excel file
            if (selected.length === 0) {
                toasterError("Please select any Job"); // Show an error message if no job is selected
                return;
            }
            let jobassets = [];
            setBackdropOpen(true); // Show a backdrop indicating that the export process is running
            // jobassets = selected;
            let ptr = [];
            //let Headers = { jobAssetIds: jobassets }
            let lotSize = 50; // Set default lot size to 50
            if (process.env.React_App_SearchAndExport_Max_Count) {
                lotSize = Number(process.env.React_App_SearchAndExport_Max_Count); // Set lot size to the value specified in the environment variable
            }
            if (selected.length > lotSize) {
                let sheetindex = 1; // Index for the sheet in the Excel workbook
                let index = 0; // Starting index for slicing the selected job assets
                let lastIndex = 0; // Ending index for slicing the selected job assets
                while (index <= selected.length) {
                    if (index + lotSize <= selected.length) {
                        lastIndex = index + lotSize; // Set the ending index to the current index plus the lot size
                    } else {
                        lastIndex = selected.length; // Set the ending index to the length of the selected job assets
                    }
                    let jobAssetId = selected.slice(index, lastIndex); // Slice the selected job assets based on the current index and ending index
                    promises.push(
                        limit(() =>
                            ExportExcel(jobAssetId).then((result) => {
                                if (result !== undefined && result !== null) {
                                    XLSX.utils.book_append_sheet(wb, result, `data${sheetindex++} `); // Append the result to the Excel workbook as a new sheet
                                    console.log(result);
                                }
                            })
                        )
                    );
                    Promise.all(promises)
                        .then(() => {
                            if (limit.pendingCount === 0 && limit.activeCount === 0) {
                                XLSX.writeFile(wb, `${fileName}.xlsx`); // Write the Excel workbook to a file with the specified filename
                                setBackdropOpen(false); // Hide the backdrop indicating that the export process is running
                            }
                        })
                        .catch((err) => {
                            Promise.reject(err);
                            setBackdropOpen(false); // Hide the backdrop indicating that the export process is running
                        });
                    index = index + lotSize; // Increment the index by the lot size
                }
            } else {
                promises.push(
                    ExportExcel(selected).then((result) => {
                        if (result !== undefined && result !== null) {
                            XLSX.utils.book_append_sheet(wb, result, `data`); // Append the result to the Excel workbook as a new sheet
                            console.log(result);
                        }
                    })
                );
                Promise.all(promises)
                    .then(() => {
                        XLSX.writeFile(wb, `${fileName}.xlsx`); // Write the Excel workbook to a file with the specified filename
                        setBackdropOpen(false); // Hide the backdrop indicating that the export process is running
                    })
                    .catch((err) => Promise.reject(err), setBackdropOpen(false));
            }
        };

        /// <summary>
        /// This function handles the export of data to an Excel file.
        /// </summary>
        const handleExportToExcel = () => {
            // GetViewDataList(searchValue, true);
            ExportExcel(selected);
        };



        /// <summary>
        /// This function exports the given job assets to an Excel file.
        /// </summary>
        /// <param name="jobassets">The job assets to be exported.</param>
        const ExportExcel = (jobassets) => {
            // Make a GET request to the server to get the Excel file
            axios.get(process.env.React_App_baseUrl + "SearchAndExport/GetSearchAndExportForExcel", {
                headers: {
                    jobAssetIds: jobassets,
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                },
                responseType: "blob",
            })
                .then((result) => {
                    // Check the content type of the response
                    if (result.headers["content-type"] === "text/plain; charset=utf-8") {
                        // If the content type is text/plain, display a success message
                        toasterSuccess("Search and export result will be sent via email");
                    } else {
                        // If the content type is not text/plain, create a download link for the Excel file
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const link = document.createElement('a');
                        link.style = "display: none";
                        link.href = url;

                        // Get the file name from the response headers
                        let fileName = `SearchAndExport.xlsx`;
                        let disposition = result.headers['content-disposition'];
                        if (disposition && disposition.indexOf('attachment') !== -1) {
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(disposition);
                            if (matches != null && matches[1]) {
                                fileName = matches[1].replace(/['"]/g, '');
                            }
                        }

                        // Set the download attribute and append the link to the document body
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                    }

                    // Set the exporting flag to false
                    setExporting(false);
                })
                .catch((err) => {
                    // Handle any errors that occur during the request
                    ErroHandling(err, function (res) {
                        toasterError(res);
                        setExporting(false);
                    });
                })
                .finally(() => {
                    // Set the exporting flag to false
                    setExporting(false);
                });
        };




        /// <summary>
        /// Exports the given API data to a CSV file with the specified file name.
        /// Optionally excludes comments from the data and applies an Excel color code.
        /// </summary>
        /// <param name="apiData">The API data to export</param>
        /// <param name="fileName">The name of the CSV file</param>
        /// <param name="datawoCommments">Flag indicating whether to exclude comments from the data</param>
        /// <param name="excelColorCode">The Excel color code to apply</param>
        const exportToCSV = (apiData, fileName, datawoCommments, excelColorCode) => {
            // Define the file type and extension
            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";

            // Create an array to store only the comments
            let onlyComments = [];

            // Loop through each object in the data without comments
            datawoCommments.forEach((obj) => {
                // Loop through each key in the object
                Object.keys(obj).forEach((key) => {
                    // Check if the key ends with "_Comment"
                    if (key.endsWith("_Comment")) {
                        // Push the comment value to the onlyComments array
                        onlyComments.push(obj[key]);
                    }

                    // Check if the key ends with specific strings and delete the key-value pair
                    if (
                        key.endsWith("_Comment") ||
                        key.endsWith("VariableId") ||
                        key.endsWith("_ValuationType") ||
                        key.endsWith("ValuationTypeId") ||
                        key.endsWith("JobAssetId") ||
                        key.endsWith("JobId")
                    ) {
                        delete obj[key];
                    }
                });
            });


            const ws = XLSX.utils.json_to_sheet(datawoCommments);
            let wsData = createAlphybet(
                datawoCommments,
                ws,
                onlyComments,
                excelColorCode
            );
            excelColumns.forEach((exCol, i) => {
                if (exCol.includes("_Comment")) {
                    excelColumns.splice(i, 1);
                }
            });
            XLSX.utils.sheet_add_aoa(wsData, [excelColumns], { origin: "A1" });
            // console.log(wsData);
            return wsData;
        };


        /// <summary>
        /// Returns the location of a letter in the alphabet based on its position in the English alphabet.
        /// </summary>
        /// <param name="n">The position of the letter in the English alphabet.</param>
        /// <returns>The location of the letter in the alphabet.</returns>
        const alphabetLocation = (n) => {
            // Get the ASCII code of 'A' and 'Z'
            var ordA = "A".charCodeAt(0);
            var ordZ = "Z".charCodeAt(0);
            // Calculate the length of the alphabet
            var len = ordZ - ordA + 1;

            // Initialize an empty string to store the alphabet location
            var s = "";
            // Loop until the number is greater than or equal to 0
            while (n >= 0) {
                // Calculate the remainder of the number divided by the length of the alphabet
                // Add the ASCII code of 'A' to the remainder to get the corresponding alphabet
                // Convert the ASCII code to its corresponding character and concatenate it to the string
                s = String.fromCharCode((n % len) + ordA) + s;
                // Update the number by dividing it by the length of the alphabet and subtracting 1
                n = Math.floor(n / len) - 1;
            }
            // Return the alphabet location
            return s;
        };



        /// <summary>
        /// Creates an alphabet based on the given parameters.
        /// </summary>
        /// <param name="v">The number of vowels in the alphabet.</param>
        /// <param name="ws">The number of consonants in the alphabet.</param>
        /// <param name="allComments">A flag indicating whether to include comments in the alphabet.</param>
        /// <param name="excelColorCode">The color code to use for formatting in Excel.</param>
        /// <returns>The generated alphabet.</returns>
        const createAlphybet = (v, ws, allComments, excelColorCode) => {
            var ordA = "A".charCodeAt(0); // Get the ASCII code of 'A'
            var ordZ = "Z".charCodeAt(0); // Get the ASCII code of 'Z'
            var len = ordZ - ordA + 1; // Calculate the length of the alphabet
            var s = ""; // Variable to store the alphabet
            let commentData = {}; // Object to store comment data
            let commentCount = 0; // Variable to keep track of comment count
            let n = Object.keys(v[0]).length; // Get the number of keys in the first object of v array

            // Loop through each object in v array
            for (let index = 0; index < v.length; index++) {
                // Loop through each value in the current object
                Object.values(v[index]).map((value, ind) => {
                    let n = ind; // Assign the current index to n
                    while (n >= 0) {
                        s = String.fromCharCode((n % len) + ordA); // Calculate the alphabet based on the current index
                        n = Math.floor(n / len) - 1; // Update n to the next index
                    }

                    let commentIndex = Object.keys(v[index])[ind]; // Get the key of the current value

                    // Check if the conditions are met to add a comment
                    if (
                        ind > 13 &&
                        value &&
                        value != "" &&
                        !commentIndex.includes("_ReportName")
                    ) {
                        commentData[alphabetLocation(ind) + (index + 2)] =
                            allComments[commentCount]; // Add the comment to the commentData object
                        ws[`${alphabetLocation(ind)}${index + 2} `].s = {
                            font: {
                                color: { rgb: excelColorCode[commentCount] }, // Set the font color of the cell
                            },
                        };
                        commentCount = commentCount + 1; // Increment the comment count
                    }
                });
            }

            // Loop through each key in ws object
            Object.keys(ws).forEach((ww) => {
                // Loop through each key in commentData object
                Object.keys(commentData).forEach((cmData) => {
                    // Check if the current key in ws matches the key in commentData and the commentData value is not empty
                    if (
                        ww === cmData &&
                        commentData[cmData] &&
                        commentData[cmData].indexOf("-") !== 0
                    ) {
                        ws[ww].c = [];
                        ws[ww].c.hidden = true; // Hide the comment
                        if (ws) {
                            if (ws[ww]) {
                                if (ws[ww].t === "s" && ws[ww].v !== "-" && !isNaN(ws[ww].v)) {
                                    ws[ww].t = "n"; // Change the cell type to number if it meets the conditions
                                }
                            }
                        }
                        ws[ww].c.push({ a: ws[ww].v, t: commentData[cmData], T: true }); // Add the comment to the cell
                        //if(ws[ww].indexOf("http://")!==-1){

                        //}
                    } else if (ws[ww] !== undefined && ws[ww] !== null) {
                        // Check if the current key in ws is not undefined or null and the value contains "http://"
                        if (
                            ws[ww].v !== undefined &&
                            ws[ww].v !== null &&
                            ws[ww].v.indexOf("http://") !== -1
                        ) {
                            // Check if the value contains only one "http://"
                            if (ws[ww].v.split("http://").length <= 2) {
                                let link = ws[ww].v;
                                ws[ww].l = { Target: link }; // Add the link to the cell
                            }
                        }
                    }
                });
            });

            return ws; // Return the modified ws object
        };




        /// <summary>
        /// Clears the search input field.
        /// </summary>
        const searchClear = () => {
            // Clear the search value
            setSearchValue("");
            // Reset the page to 0
            setPage(0);
            // Reload the page by toggling the reload state
            setReload(!reload);
            // Dispatch an action to set the filter values to their initial state
            dispatch(
                setFiterValue({
                    JobName: "",
                    company: [],
                    asset: [],
                    assetTypeIds: [],
                    countryIds: [],
                    regionIds: [],
                    subRegionIds: [],
                    commodityIds: [],
                    statusIds: [],
                    priorityIds: [],
                    SearchText: "",
                })
            );
        };

        // Function for View icon click toggleDrawer is called

        /// <summary>
        /// Toggles the drawer open or closed based on the provided parameters.
        /// </summary>
        /// <param name="open">A boolean indicating whether the drawer should be open or closed.</param>
        /// <param name="mode">The mode of the drawer.</param>
        /// <param name="option">The option for the drawer.</param>
        /// <returns>A function that handles the toggle event and item.</returns>
        const toggleDrawer = (open, mode, option) => (event, item) => {
            // console.log("item checker", item);
            // Check if the mode is "View"
            if (mode === "View") {
                // Push a new location to the history object
                history.push({
                    pathname: "/app/data-collections/view-data",
                    state:
                        // Check if item is defined and item.jobAssetId is not null
                        item !== undefined && item?.jobAssetId !== null
                            ? item.jobAssetId
                            : selected,
                });
            }
            // Check if the mode is "Filter"
            else if (mode === "Filter") {
                // Update the state with the new value of "right"
                setState({ ...state, right: open });
            }
            //// Check if the mode is "Filter"
            //else if (mode === "Filter") {
            //    // Update the state with the new value of "right"
            //    setState({ ...state, right: open });
            //}
        };


        // Function for Getting Filter Dropdown List
        // Define an asynchronous function called getFilterData
        /// <summary>
        /// This function is an asynchronous function that retrieves filter data.
        /// </summary>
        const getFilterData = async () => {
            try {
                // Send a GET request to the specified URL with headers
                const response = await axios.get(
                    `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`,
                    {
                        headers: {
                            // Set the Authorization header with the token from localStorage
                            Authorization: localStorage.getItem("token")
                                ? "Bearer " + localStorage.getItem("token")
                                : null,
                        },
                    }
                );
                // Check if the response data is empty
                if (response.data.length === 0) {
                    // Set the data for filter to an empty array
                    setDataForFilter([]);
                } else {
                    // Set the data for filter to the response data
                    setDataForFilter(response.data);
                    // Iterate over the companies in the response data
                    response.data.companies.forEach((item, index) => {
                        // Set the checked property of each company to false
                        item.checked = false;
                        // Check if the company has children
                        if (item.children) {
                            // Iterate over the children of the company
                            item.children.forEach((item, index) => {
                                // Set the checked property of each child to false
                                item.checked = false;
                            });
                        }
                    });
                    // Iterate over the regions in the response data
                    response.data.location.regions.forEach((item, index) => {
                        // Set the checked property of each region to false
                        item.checked = false;
                        // Check if the region has children
                        if (item.children) {
                            // Iterate over the children of the region
                            item.children.forEach((item, index) => {
                                // Set the checked property of each child to false
                                item.checked = false;
                            });
                            // Check if the child has children
                            if (item.children) {
                                // Iterate over the children of the child
                                item.children.forEach((item, index) => {
                                    // Set the checked property of each child to false
                                    item.checked = false;
                                });
                            }
                        }
                    });
                    // Set the company filter to the companies in the response data
                    setCompanyFilter(response.data.companies);
                    // Set the location filter to the regions in the response data
                    setLocationFilter(response.data.location.regions);
                }
            } catch (error) { }
        };



        /// <summary>
        /// Resets the tree filter.
        /// </summary>
        const resetTreeFilter = () => {
            // Reset company filter
            companyFilter.forEach((item, index) => {
                item.checked = false;
                // Reset children of company filter
                if (item.children) {
                    item.children.forEach((item, index) => {
                        item.checked = false;
                    });
                }
            });
            // Reset location filter
            locationFilter.forEach((item, index) => {
                item.checked = false;
                // Reset children of location filter
                if (item.children) {
                    item.children.forEach((item, index) => {
                        item.checked = false;
                    });
                    // Reset grandchildren of location filter
                    if (item.children) {
                        item.children.forEach((item, index) => {
                            item.checked = false;
                        });
                    }
                }
            });
        };

        return (
            // Page Layout Start Here
            <Box>
                <Grid container maxWidth justifyContent="right" alignItems="center">
                    {/* Breadcrumbs Menu Start Here */}
                    <Grid item xs={12} sm={12} md={8} className="page-title">
                        <h2>Search & Export</h2>
                        <Breadcrumbs
                            separator={
                                <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
                            }
                            aria-label="breadcrumb"
                            className="custom-breadcrumb"
                        >
                            <Link
                                underline="hover"
                                color="inherit"
                                to={{ pathname: "/app/data-collections/source-management" }}
                            >
                                Home
                            </Link>
                            <Typography color="text.primary">
                                Manage Company Data Collections
                            </Typography>
                            <Typography color="text.primary">Search & Export</Typography>
                        </Breadcrumbs>
                    </Grid>
                    {/* Breadcrumbs Menu End Here */}

                    <Grid item xs={12} sm={12} md={4}>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item xs={"auto"}>
                                <Box>
                                    {/* <Button
                  onClick={handleOpen}
                  variant="contained"
                  color="secondary"
                >
                  Export backdrop
                </Button> */}
                                    <Backdrop
                                        sx={{
                                            color: "#000000",
                                            zIndex: (theme) => theme.zIndex.drawer + 1,
                                        }}
                                        open={backdropOpen}
                                        // onClick={handleClose}
                                        style={{
                                            backdropFilter: "blur(2px)",
                                            background: "rgba(0, 0, 0, 0.75)",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                background: "#ffffff",
                                                borderRadius: 2,
                                                p: 4,
                                                pt: 3.5,
                                            }}
                                        >
                                            <Box sx={{ width: "100%" }}>
                                                <Typography variant="h6" gutterBottom>
                                                    Exporting...
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: "100%" }}>
                                                <LinearProgress color="accent6" />
                                            </Box>
                                            <Box sx={{ pt: 2 }}>
                                                <Typography
                                                    variant="body2"
                                                    gutterBottom
                                                    sx={{ maxWidth: 340 }}
                                                >
                                                    Exporting may take a while. If you want to continue your
                                                    task, please open a new tab and proceed.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Backdrop>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Baselayout Start Here */}
                    <Grid item xs={12}>
                        <BaseLayout>
                            <Box className={classes.addBorder}>
                                {/* Search Option and Total Count Start Here */}
                                <Grid item xs={12} className={classes.tableTopPart}>
                                    <Grid
                                        container
                                        alignItems="flex-end"
                                        justifyContent="left"
                                        spacing={2}
                                    >
                                        <Grid item xs={12} md={12} lg={5}>
                                            <TextField
                                                id="input-with-icon-textfield"
                                                className="serchByKeyword mt-1"
                                                size="small"
                                                fullWidth
                                                value={searchValue}
                                                placeholder="Search by Keyword"
                                                onKeyUp={handleKeyUp}
                                                onChange={(e) => {
                                                    globalSearch(e.target.value);
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton aria-label="toggle password visibility">
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {searchValue ? (
                                                <IconButton
                                                    aria-label="Add"
                                                    className="serchByClear"
                                                    onClick={searchClear}
                                                >
                                                    <ClearIcon style={{ fontSize: 10 }} />
                                                </IconButton>
                                            ) : (
                                                ""
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={7}>
                                            <Grid
                                                container
                                                spacing={2}
                                                justifyContent="flex-end"
                                                alignItems="flex-end"
                                            >
                                                <Grid item xs={"auto"}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        disabled={viewDataButtonDisable}
                                                        onClick={toggleDrawer(true, "View", SetSelected)}
                                                    >
                                                        View Data
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={"auto"}>

                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        style={{ width: 190 }}
                                                        disabled={viewDataButtonDisable}
                                                        startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                                                        onClick={handleExportToExcel}
                                                    >
                                                        Export To Excel
                                                    </Button>


                                                    {/* {isExporting ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            disabled
                            style={{ width: 190 }}
                          >
                            <CircularProgress size={25} />
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ width: 190 }}
                            disabled={viewDataButtonDisable}
                            startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                            onClick={handleExportToExcel}
                          >
                            Export To Excel
                          </Button>
                        )} */}
                                                </Grid>
                                                <Grid item xs={"auto"}>
                                                    {access.allowView ? (
                                                        <>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={toggleDrawer(true, "Filter")}
                                                            >
                                                                Filter
                                                            </Button>
                                                        </>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* Search Option and Total Count End Here */}

                                {/* Search & Export Table */}
                                <Grid item xs={12}>
                                    <CustomDataCollectionSearchAndExportDataTable
                                        className={classes.removeBorder}
                                        loading={isLoading}
                                        rows={allRows ? allRows : []}
                                        columns={gridcolumns}
                                        page={page}
                                        setPage={setPage}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        totalCount={totalCount}
                                        isAllSelected={isAllSelected}
                                        setIsSelected={setIsSelected}
                                        allJobAssetIds={allJobAssetIds}
                                        setAllJobAssetIds={setAllJobAssetIds}
                                        selected={selected}
                                        setSelected={SetSelected}
                                        sorting={sorting}
                                        setSorting={setSorting}
                                        setviewDataButtonDisable={setviewDataButtonDisable}
                                        selectableRows
                                        isActionButton={{
                                            ...(menuPermission.allowView && {
                                                view: toggleDrawer(true, "View"),
                                            }),
                                        }}
                                    />
                                </Grid>
                            </Box>
                        </BaseLayout>
                    </Grid>
                </Grid>
                {/* Search Export Filter Side Drawer */}
                <SideDrawer
                    formComp={
                        // Calling Search Export Filter
                        <SearchExportFilter
                            dataForFilter={dataForFilter}
                            filterFinalData={filterFinalData}
                            companyFilter={companyFilter}
                            locationFilter={locationFilter}
                            setFilterValues={setFilterValues}
                            JobName={jobName}
                            setJobName={setJobName}
                            state={state}
                            setState={setState}
                            crudState={crudState}
                            formData={formData}
                            setFormData={setFormData}
                            userId={null}
                            setFilterValue={setFilterValue}
                            hideSideDrawer={() => {
                                setState({ right: false });
                                // getUserData();
                            }}
                            reload={reload}
                            setReload={setReload}
                            resetTreeFilter={resetTreeFilter}
                            selectedJob={selectedJob}
                            setSelectedJob={setSelectedJob}
                            setLocationFilter={setLocationFilter}
                            setCompanyFilter={setCompanyFilter}
                            setDataForFilter={setDataForFilter}
                        />
                    }
                    state={state}
                    setState={setState}
                    reload={reload}
                    setReload={setReload}
                    setFilterValue={setFilterValue}
                />
            </Box>
        );
    };

    export default DataCollectionSearchExport;
