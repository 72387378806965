// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,  
  TextField,
  Typography,
} from "@mui/material";
import XLSX from "sheetjs-style";
import axios from "axios";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
import { Link } from 'react-router-dom';
// import history from "../../../Routes/History";
import ApiService from "../../../../Services/ApiService";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../../UIComponent/Toaster";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

// Importing Custom Components
import BaseLayout from "../../../../UIComponent/BaseLayout";
import DataTable from "../../../../UIComponent/DataTable";

// Importing useStyles
import useStyles from "./AgingReport.styles";

// Import Fluent UI Icons
import {
  ChevronLeftIcon,
  ChevronRightSmallIcon,
  ClearIcon,
  ExportIcon,
  SearchIcon,
} from "@fluentui/react-icons-mdl2";
import SideDrawer from "../../../../UIComponent/Drawer";
import AgingDrillFilter from "./AgingDrillFilter";
import CommonService from "../../../../Services/CommonService";
import { useHistory } from "react-router-dom";

const DataCollectionAgingReportDrill = (props) => {
  // Declaration of States and Component Variables Start Here
  const history = useHistory();
  const [historyData, setHistoryData] = useState();
  const [allJobs, setAllJobs] = useState([]);
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = React.useState({ right: false, });
  const [showFilter, setShowFilter] = React.useState(false);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [selected, SetSelected] = useState([]);
  const [isAllSelected, setIsSelected] = useState(false);
  const [priorityId, setPriorityId] = useState();
  const [tat, setTat] = useState();
  const [reload, setReload] = useState(false);
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValues, setFilterValues] = useState();
  const [assetTypeDD, setAssetTypeDD] = useState([]);
  const [jobCompanies, setJobCompanies] = React.useState([])
  const [jobLocations, setJobLocations] = React.useState([])
  let [filterValue, setFilterValue] = React.useState("");
  const [assetTypes, setAssetTypes] = React.useState(dataForFilter.assetType ? dataForFilter.assetType : [])
  const [commodityList, setCommodityList] = React.useState(dataForFilter.commodityList ? dataForFilter.commodity : [])
  const [priorityList, setPriorityList] = React.useState(dataForFilter.priority ? dataForFilter.priority : [])
  const [filterData, setFilterData] = React.useState(null);
  const [tatDate, setTatDate] = React.useState(null)
  const fileName = `Aging Drill Down ${new Date()
    .toLocaleDateString() // Get the current date as a string
    .split("/") // Split the string by "/"
    .join("-")}`; // Join the split strings with
  // Declaration of States and Component Variables End Here
  React.useEffect(() => {
    // Get history data and filter data
    GetHistoryData();
    getFilterData();
    console.log("priorityId", priorityId);
    console.log("tat", tat);
  }, [])
  React.useEffect(() => {
    // Search jobs details when priorityId and tat are defined
    if (priorityId !== undefined && tat !== undefined) {
      searchJobsDetails(searchValue);
    }
  }, [priorityId, tat, reload]);

  React.useEffect(() => {
    // Search jobs details when sorting is changed
    if (priorityId !== undefined && tat !== undefined) {
      searchJobsDetails(searchValue);
    }
  }, [sorting]);

  React.useEffect(() => {
    // Search jobs details when page or rowsPerPage is changed
    if (priorityId !== undefined && tat !== undefined) {
      searchJobsDetails(searchValue);
    }
  }, [page, rowsPerPage]);


// Function to get history data from CommonService
  const GetHistoryData = () => {
    // Get stored data from CommonService
    let temp = CommonService.GetStoredData(props, "historyData");
    // If data is present, set the history data, priority id and tat
    if (temp) {
      setHistoryData(temp);
      setPriorityId(temp.state.PriorityId);
      setTat(temp.state.tat);
    }
  }


  const date = new Date().toLocaleDateString();
  const date1 = date.split("/").join("-");
// This function changes the format of the column name for sorting
  const changeSortColumnNameFormat = (fieldName) => {
    // Check if fieldName is null or empty
    if (fieldName == null || fieldName == "") {
      return "";
    }
    // Check if fieldName and convert to proper format
    if (fieldName.toLocaleLowerCase() == "JobNo".toLocaleLowerCase()) {
      fieldName = "JobNo";
    }
    if (fieldName.toLocaleLowerCase() == "JobName".toLocaleLowerCase()) {
      fieldName = "JobName";
    }
    if (fieldName.toLocaleLowerCase() == "companyName".toLocaleLowerCase()) {
      fieldName = "CompanyName";
    }
    if (fieldName.toLocaleLowerCase() == "assetName".toLocaleLowerCase()) {
      fieldName = "AssetName";
    }
    if (fieldName.toLocaleLowerCase() == "createdOn".toLocaleLowerCase()) {
      fieldName = "CreatedOn";
    }
    if (fieldName.toLocaleLowerCase() == "createdBy".toLocaleLowerCase()) {
      fieldName = "CreatedBy";
    }
    if (fieldName.toLocaleLowerCase() == "assignedOn".toLocaleLowerCase()) {
      fieldName = "AssignedOn";
    }
    if (fieldName.toLocaleLowerCase() == "assignedTo".toLocaleLowerCase()) {
      fieldName = "AssignedTo";
    }
    if (fieldName.toLocaleLowerCase() == "statusName".toLocaleLowerCase()) {
      fieldName = "StatusName";
    }
    if (fieldName.toLocaleLowerCase() == "tat".toLocaleLowerCase()) {
      fieldName = "TAT";
    }
    if (fieldName.toLocaleLowerCase() == "StartDate".toLocaleLowerCase()) {
      fieldName = "StartDate";
    }
    if (fieldName.toLocaleLowerCase() == "noofvariable".toLocaleLowerCase()) {
      fieldName = "NoOfVariable";
    }
    if (fieldName.toLocaleLowerCase() == "remainingDays".toLocaleLowerCase()) {
      fieldName = "RemainingDays";
    }
    return fieldName;
  };

  // Function for Handle KeyUp
  // This function handles the keyup event
  const handleKeyUp = (event) => {
    // Check if the key pressed is backspace or delete
    if (
      (event.keyCode === 8 || event.which === 8) &&
      event.target.nodeName !== "BUTTON"
    ) {
    }
    // Check if the key pressed is enter
    if (
      (event.keyCode === 13 || event.which === 13) &&
      event.target.nodeName !== "BUTTON"
    ) {
      // Reset total count and page number
      setTotalCount(0);
      setPage(0);
      // Call searchJobsDetails function with the value of the target element
      searchJobsDetails(event.target.value);
    }
  };

  const getDataFromHistory = () => {
    let historyData = history.location?.search.replace("?", "").split("&");
    historyData.forEach((element) => {
      let items = element.split("=");
      if (items.length > 0) {
        if (items[0].toString().toLowerCase() == "PriorityId".toLowerCase()) {
          setPriorityId(items[1]);
        }
        if (items[0].toString().toLowerCase() == "tat".toLowerCase()) {
          setTat(items[1]);
        }
      }
    });
  };

  // Function for Global Search
  const globalSearch = (value) => {
    setSearchValue(value);
  };

  // Function for Search Clear
  // This function is used to search for jobs details based on the filter value and exportExcel flag
  const searchJobsDetails = (filterValue, exportExcel = false) => {
    // Check if priorityId and tat are defined
    if (priorityId !== undefined && tat !== undefined) {
      setLoading(true);
      let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
      // If exportExcel flag is true, set pageNumber and pageSize to 1 and totalCount respectively
      if (exportExcel) {
        prt = [`pageNumber=1`, `pageSize=${totalCount}`];
      }
      // Call ApiService.search() with the given parameters
      ApiService.search(
        "reports/GetAgingDrillDownReport?",
        {
          searchText: encodeURIComponent(filterValue),
          sortColumn: changeSortColumnNameFormat(sorting.sortBy),
          isAscending: sorting.isAscending,
          priorityId: priorityId,
          tat: tat,
          AgingFilter: filterData ? encodeURIComponent(JSON.stringify(filterData)) : ""
        },
        prt
      )
        .then((res) => {
          // If response status is 200, set allJobs and totalCount accordingly
          if (res.status === 200) {
            if (exportExcel) {
              let apiData = [];
              res.data.data.forEach((element) => {
                apiData.push({
                  "Job Id": element.jobNo,
                  "Job Name": element.jobName,
                  "Company Name": element.companyName,
                  "Asset Name": element.assetName,
                  "Variable Count": element.noOfVariable,
                  "Created On": element.createdOn,
                  "Created By": element.createdBy,
                  "Assigned On": element.assignedOn,
                  "Assigned To": element.assignedTo,
                  "Status": element.statusName,
                  "Job Start Date": element.startDate,
                  "TAT": element.tat,
                  "Remaining Days": element.remainingDays
                });
              });
              // Call exportToCSV() with apiData and fileName as parameters
              exportToCSV(apiData, fileName);
            } else {
              setAllJobs([]);
              setAllJobs(res.data.data);
              setTotalCount(res.data.totalCount);
            }
          } else {
            toasterError(`${res.data}`);
          }
        })
        .catch((err) => {
          if(err.response.status===400){
            toasterError(err.response.data);
          }
           }).finally(() => {
          setLoading(false);
        });
    }
  };
  // This function resets the tree filter
  const resetTreeFilter = () => {
    // Iterate through each item in companyFilter
    companyFilter.forEach((item, index) => {
      // Set checked to false for each item
      item.checked = false
      // If item has children, iterate through them
      if (item.children) {
        item.children.forEach((item, index) => {
          // Set checked to false for each child item
          item.checked = false
        })
      }
    });
    //Reset Tat Date
    setTatDate(null);
  }
  /////Filter side drawer
  // This function toggles the drawer open or closed
  const toggleDrawer2 = (open) => (event) => {
    // Set the state of the right drawer to open or closed
    setState({ ...state, right: open });
    // Set the showFilter state to true
    setShowFilter(true);
  };
// This function is used to get filter data from the API
  const getFilterData = async () => {
    try {
      // Making a GET request to the API
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }
      );
      // If no data is returned, set the data for filter to an empty array
      if (response.data.length === 0) {
        setDataForFilter([]);
      } else {
        // Set the data for filter to the response data
        setDataForFilter(response.data);
        // Iterate through the companies and set the checked property to false
        response.data.companies.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => { item.checked = false; });
          }
        });
        // Iterate through the regions and set the checked property to false
        response.data.location.regions.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => { item.checked = false })
            if (item.children) {
              item.children.forEach((item, index) => { item.checked = false })
            }
          }

        });
        console.log(response.data)
        // Set the company filter and location filter to the respective response data
        setCompanyFilter(response.data.companies);
        setLocationFilter(response.data.location.regions);
      }
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res)
      })
    }
  };

  // Function for Export to Excel
  // This function is used to export data from an API to a CSV file
  const exportToCSV = (apiData, fileName) => {
    // Set the file type and extension
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    // Convert the API data to a worksheet
    const ws = XLSX.utils.json_to_sheet(apiData);
    // Add column headers to the worksheet
    XLSX.utils.sheet_add_aoa(
      ws,
      [["Job Id", "Job Name", "Company Name", "Asset Name", "Variable Count", "Created On",
        "Created By", "Assigned On", "Assigned To", "Status", "Job Start Date", "TAT",
        "Remaining Days"
      ],],
      { origin: "A1", }
    );
    // Create a workbook object with the worksheet
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    // Write the workbook object to an array buffer
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // Create a blob from the array buffer
    const data = new Blob([excelBuffer], { type: fileType });
    // Save the blob as a file
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // Function for Search Clear
  // This function clears the search value, sets the page to 0 and reloads the page
  const searchClear = () => {
    // Set the search value to an empty string
    setSearchValue("");
    // Set the page to 0
    setPage(0);
    // Toggle the reload state
    setReload(!reload);
  };

  // Assigning Columns and UI Props
  // This code defines an array of objects containing information about columns
  const columns = [
    { id: "jobNo", label: "Job Id", minWidth: 90, width: 90, sorting: true },
    { id: "jobName", label: "Job Name", minWidth: 350, width: 350, sorting: true },
    { id: "companyName", label: "Company Name", sorting: true, minWidth: 250, width: 250 },
    { id: "assetName", label: "Asset Name", sorting: true, minWidth: 200, width: 200 },
    { id: "noOfVariable", label: "Variable Count", sorting: true, minWidth: 90, width: 90, align: "center" },
    { id: "createdOn", label: "Created Date", sorting: true, minWidth: 130, width: 130, align: "center" },
    { id: "createdBy", label: "Created By", sorting: true, minWidth: 170, width: 170 },
    { id: "assignedOn", label: "Assigned On", sorting: true, minWidth: 130, width: 130, align: "center" },
    { id: "assignedTo", label: "Assigned To", sorting: true, minWidth: 170, width: 170 },
    { id: "statusName", label: "Status", sorting: true, minWidth: 160, width: 200, align: "center" },
    { id: "startDate", label: "Job Start Date", sorting: true, width: 120, minWidth: 120, align: "center" },
    { id: "tat", label: "TAT", sorting: true, width: 120, minWidth: 120, align: "center" },
    { id: "remainingDays", label: "Remaining Days", sorting: true, width: 120, minWidth: 120, align: "center" }
  ];
// This function handles the export of job details to an Excel file
  const handleExportToExcel = () => {
    // Call searchJobsDetails with the searchValue and true as parameters
    searchJobsDetails(searchValue, true);
  };

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Aging Report Drill Down</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/aging-report" }}>Dashboard and Reports</Link>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/aging-report" }}>Aging Report</Link>
            <Typography color="text.primary">Aging Report Drill Down</Typography>            
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                color="accent2"
                startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
                onClick={() => {
                  history.push("/app/data-collections/aging-report");
                }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search and Filter Options Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className={classes.serchByKeyword}
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className={classes.serchByClear}
                        onClick={searchClear}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="right"
                      spacing={2}
                    >
                      <Grid item textAlign="right">
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                          onClick={handleExportToExcel}
                        >
                          Export To Excel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={toggleDrawer2(true)}
                          variant="contained"
                          color="secondary"
                          startIcon={<FilterAltOutlinedIcon />}
                        >
                          Filter
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search and Filter Options End Here */}

              {/* Aging Report Table */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allJobs ? allJobs : []}
                  columns={columns}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={setSorting}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
      <SideDrawer
        formComp={
          <AgingDrillFilter
            dataForFilter={dataForFilter}
            filterFinalData={filterFinalData}
            companyFilter={companyFilter}
            setFilterValues={setFilterValues}
            assetTypeDD={assetTypeDD}
            setFilterFinalData={setFilterFinalData}
            setFilterValue={setFilterValue}
            state={state}
            setState={setState}
            reload={reload}
            setReload={setReload}
            resetTreeFilter={resetTreeFilter}
            setJobCompanies={setJobCompanies}
            setCompanyFilter={setCompanyFilter}
            setPriorityList={setPriorityList}
            setDataForFilter={setDataForFilter}
            getFilterData={getFilterData}
            setFilterData={setFilterData}
            setTatDate={setTatDate}
            tatDate={tatDate}
          />
        }
        state={state}
        setState={setState} />
    </Box>
  );
};

export default DataCollectionAgingReportDrill;