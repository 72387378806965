import { clearSession } from "./SessionService";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const getDayDifference = (date1) => {
  let date2 = new Date();
  let diffDays = 0;
  if (
    date1 === null ||
    date1 === undefined ||
    date1 === "" ||
    date2 === null ||
    date2 === undefined ||
    date2 === ""
  ) {
    return diffDays;
  }
  // if (date2 > date1) {
  //   const diffTime =Math.ceil(date1 - date2);
  //   diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //   return diffDays;
  // }
  const diffTime = Math.ceil(date1 - date2);
  diffDays = Number(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
  if (isNaN(diffDays)) {
    diffDays = 0;
  }
  return diffDays;
};

const GetDateAsStringWithDash = (selectedDate) => {
  return (
    selectedDate.getFullYear() +
    "-" +
    ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + selectedDate.getDate()).slice(-2)
  );
};
const GetDateInReverse = (strDate) => {
  if (strDate === "" || strDate === null || strDate === undefined) {
    return "";
  }
  return strDate.split("-").reverse().join("-");
};

const GetStoredData = (props, storageKey) => {
  let tempData = null;
  if (props?.location?.state !== undefined && props?.location?.state !== null) {
    localStorage.setItem(storageKey, JSON.stringify(props.location));
    if (props.location.state) {
      tempData = props.location;
    }
  } else if (
    localStorage.getItem(storageKey) !== undefined &&
    localStorage.getItem(storageKey) !== null
  ) {
    let data = JSON.parse(localStorage.getItem(storageKey));
    if (data) {
      tempData = data;
    }
  }

  return tempData;
};

const GetPropsStoredData = (props, storageKey) => {
  let tempData = null;
  if (props !== undefined && props !== null) {
    localStorage.setItem(storageKey, JSON.stringify(props));
    if (props) {
      tempData = props;
    }
  } else if (
    localStorage.getItem(storageKey) !== undefined &&
    localStorage.getItem(storageKey) !== null
  ) {
    let data = JSON.parse(localStorage.getItem(storageKey));
    if (data) {
      tempData = data;
    }
  }
  return tempData;
};

const getBusinessDatesCount = (endDate) => {
  let count = 0;
  let startDate = new Date();
  const curDate = new Date(startDate.getTime());

 // if (startDate.getDate() === endDate.getDate() && startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()){
 if(getDateWithZeroTime(startDate).toString()===getDateWithZeroTime(endDate).toString()){  
 count = 0;
    return count;
  } 
  else if (getDateWithZeroTime(curDate) < getDateWithZeroTime(endDate)){
    while (getDateWithZeroTime(curDate) < getDateWithZeroTime(endDate)) {
      const dayOfWeek = curDate.getDay();
      if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
      curDate.setDate(curDate.getDate()+1);
  }
  }
  else{
    while (getDateWithZeroTime(curDate) > getDateWithZeroTime(endDate)) {
      const dayOfWeek = curDate.getDay();
      if(dayOfWeek !== 0 && dayOfWeek !== 6) count--;
      curDate.setDate(curDate.getDate() - 1);
  }
 // count = count + 1;
  }
  
  //alert(count);
  return count;
}

const getDateWithZeroTime = (dateParam) => {
  if (dateParam instanceof Date) {
    return new Date(
      dateParam.getFullYear(),
      dateParam.getMonth(),
      dateParam.getDate()
    );
  }
  return null;
};

const addWorkingDays=(startDate, days)=>{
  if(isNaN(days)) {
      console.log("Value provided for \"days\" was not a number");
      return
  }
  if(!(startDate instanceof Date)) {
      console.log("Value provided for \"startDate\" was not a Date object");
      return
  }
  if(days>0){
    days=days-1;
  }
  if(days<0){
    days=days+1;
  }
  // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
  var dow = startDate.getDay();
  var daysToAdd = parseInt(days);
  // If the current day is Sunday add one day
  if (dow == 0)
      daysToAdd++;
  // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
  if (dow + daysToAdd >= 6) {
      //Subtract days in current working week from work days
      var remainingWorkDays = daysToAdd - (5 - dow);
      //Add current working week's weekend
      daysToAdd += 2;
      if (remainingWorkDays > 5) {
          //Add two days for each working week by calculating how many weeks are included
          daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
          //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
          if (remainingWorkDays % 5 == 0)
              daysToAdd -= 2;
      }
  }
  startDate.setDate(startDate.getDate() + daysToAdd);
  console.log(startDate);
  return startDate;
}

const AddTokenFromLocalStorageToSessionStorage = () => {
  if (localStorage.getItem("token")) {
    localStorage.setItem("token", localStorage.getItem("token"));
    localStorage.removeItem("token");
  }
  if (localStorage.getItem("username")) {
    localStorage.setItem("username", localStorage.getItem("username"));
    localStorage.removeItem("username");
  }
  if (localStorage.getItem("email")) {
    localStorage.setItem("email", localStorage.getItem("email"));
    localStorage.removeItem("email");
  }
  if (localStorage.getItem("lastName")) {
    localStorage.setItem("lastName", localStorage.getItem("lastName"));
    localStorage.removeItem("lastName");
  }
  if (localStorage.getItem("role")) {
    localStorage.setItem("role", localStorage.getItem("role"));
    localStorage.removeItem("role");
  }
};

const AddTokenFromSessionStorageToLocalStorage = () => {
  if (localStorage.getItem("token")) {
    localStorage.setItem("token", localStorage.getItem("token"));
  }
  if (localStorage.getItem("username")) {
    localStorage.setItem("username", localStorage.getItem("username"));
  }
  if (localStorage.getItem("email")) {
    localStorage.setItem("email", localStorage.getItem("email"));
  }
  if (localStorage.getItem("lastName")) {
    localStorage.setItem("lastName", localStorage.getItem("lastName"));
  }
  if (localStorage.getItem("role")) {
    localStorage.setItem("role", localStorage.getItem("role"));
  }
};

const removeToken = () => {
  clearSession();
  window.open("login", "_self");
};
const getToken = () => {
  return localStorage.getItem("token")
    ? `Bearer  ${localStorage.getItem("token")}`
    : null;
};

const ExcelExportFunc = (
  fileName,
  headers = [],
  dataRow = [],
  SheetName = "data"
) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  if (fileName === undefined || fileName === undefined) {
    fileName = `Download_${new Date()
      .toLocaleDateString()
      .split("/")
      .join("-")}`;
  }
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(dataRow);
  XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });

  const wb = { Sheets: { data: ws }, SheetNames: [SheetName] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
const openFilePreview = (sendData) => {
  localStorage.setItem("filePreview", JSON.stringify(sendData));
  //CommonService.AddTokenFromSessionStorageToLocalStorage();
  window.open(
    "#app/ownership-changes/file-preview",
    "_blank",
    "noopener,noreferrer"
  );
};

const CommonService = {
  getDayDifference,
  GetDateAsStringWithDash,
  GetDateInReverse,
  GetStoredData,
  GetPropsStoredData,
  getBusinessDatesCount,
  addWorkingDays,
  AddTokenFromLocalStorageToSessionStorage,
  AddTokenFromSessionStorageToLocalStorage,
  removeToken,
  getToken,
  openFilePreview,
  ExcelExportFunc,
};

export default CommonService;
