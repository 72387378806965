// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
  Paper,
  FormControl,
  RadioGroup,
  Radio,
  Tooltip,
  Chip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TableContainer,
} from "@mui/material";
import { Link } from 'react-router-dom';
import ErroHandling from "../../../Services/ErrorHandling";
import { useHistory } from "react-router-dom";
import MultiSelect from "../../../UIComponent/MultiSelectDropdown";
import dayjs from "dayjs";
// Importing Custom Components
import AlertDialog from "../../../UIComponent/AlertDialog";
import BaseLayout from "../../../UIComponent/BaseLayout";
import SideDrawer from "../../../UIComponent/Drawer";
import { ScrollingDialog } from "../../../UIComponent";
import CreatePublicationDetails from "./CreatePublicationDetails";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import VariableReportSideDrawer from "../../ManageData/VariableReport/VariableReportSideDrawer";
import AddUnitReportSideDrawer from "../../ManageData/UnitReport/AddUnitReportSideDrawer";
// Import Fluent UI Icons
import {
  AddIcon,
  ChevronRightSmallIcon,
  CirclePlusIcon,
  ComplianceAuditIcon,
  DeleteIcon,
  InfoIcon,
  LinkIcon,
  TimeEntryIcon,
  OpenEnrollmentIcon,
  CommentIcon,
  AttachIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CopyIcon,
  EntryViewIcon,
  EditIcon,
  DependencyAddIcon,
} from "@fluentui/react-icons-mdl2";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";
import "./index.scss";
import axios from "axios";
import PreviewUpdateRecord from "./PreviewUpdateRecord";
import HistoricData from "./HistoricData";
import AddVariableReport from "./AddVariableReport";
import ReportInfo from "./reportInfo";
import { useEffect } from "react";
import DataMetrics from "./DataMetrics";
import BasicPopover from "../../../UIComponent/PopOver";
import AdobeViewer from "./AdobeViewer";
import EditDataMetrics from "./EditDataMetrics";
import FormDialog from "../../../UIComponent/FormDialog";
import CreatePublicationDetailsSideDrawer from "../ReportingCalendar/CreatePublicationDetailsSideDrawer";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
import CurrencyFormat from 'react-currency-format';
import CommonService from "../../../Services/CommonService";
import { LoaderWrapper } from "../../../UIComponent/Progress";



const UpdateRecord = (props) => {
  const history = useHistory();
  // Declaration of States and Component Variables Start Here

  const [page, setPage] = React.useState(1);
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [openPreview2, setOpenPreview2] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [state, setState] = useState({ right: false });
  const [confirmation, setConfirmation] = useState(false);
  const [confirmation1, setConfirmation1] = useState(false);
  const [confirmation2, setConfirmation2] = useState(false);
  const [confirmation3, setConfirmation3] = useState(false);
  const [variableReport, setVariableReport] = useState(false);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [assetType, setAssetType] = useState("all");
  const [refresh, setRefresh] = useState(false);
  const [variableRefresh, setVariableRefresh] = useState(false)
  const [cruRefresh, setCruRefresh] = useState(false)
  const [assetInfo, setAssetInfo] = useState(null);
  const [assetDetail, setAssetDetail] = useState(null);
  const [isReportUpload, setIsReportUpload] = useState(true)
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [reportDeleteRefresh, setreportDeleteRefresh] = useState(false);
  const [companyName, setCompanyName] = useState("");

  const [editDataMaterics, setEditDataMaterics] = useState({ report: "", pageNumber: "", valuationId: "", comments: "", variableValueId: null })
  const [selectedAssetInfo, setSelectedAssetInfo] = useState({
    attributable: null,
    assetIsAsegment: null,
    asset: null,
    segmentComments: null,
    linkToCAT: null,
    linkToAssetPlatform: null,
    linkToEAT: null,
  });
  const [reportInfo, setReportInfo] = useState([]);
  const [reportFound, setReportFound] = useState(null)
  var intialValueOfDatametrics = {
    "assetVariableId": 0,
    "jobAssetId": 0,
    "frequencyId": 0,
    "frequency": "",
    "lastYearId": null,
    "lastYear": "",
    "lastQuarterId": null,
    "lastQuarter": "",
    "currencyId": 0,
    "currency": "",
    "variableReportId": 0,
    "variableReport": "",
    "unitReport": "",
    "unitReportId": 0,
    "reportingYearId": null,
    "reportingYear": "",
    "valuationTypeId": 2,
    "valuationType": "Actual",
    "variableTypeName": "",
    "cruVariable": "",
    "cruUnit": "",
    "value": "",
    "minValue": "",
    "maxValue": "",
    "comments": "",
    "reportingPeriodId": null,
    "reportingPeriod": "",
    "variableValues": [
      {
        "variableValueId": 0,
        "assetVariableId": 0,
        "reportingPeriodId": 0,
        "reportingYearId": 0,
        "valueReportInfo": [
          {
            "valueReportMappingId": 0,
            "reportingId": 0,
            "reportName": "string",
            "pageNumber": 0
          }
        ]
      }
    ]
  }
  const [mainitem, setMainItem] = useState(intialValueOfDatametrics)
  const [assetVariableInfo, setAssetVariableInfo] = useState([])
  const [assetVariablecount, setAssetVariablecount] = useState(0)
  const [reportInfoError, setReportInfoError] = useState([
    {
      reportingYear: false,
      reportingPeriod: false,
      publicationDate: false,
      reportingType: false,
      reportName: false,
      link: false,
      FilePath: false,
      file: false,
    },
  ]);
  var intialReportValue = { reportName: "", pageNumber: "", valueReportMappingId: 0, }
  const [reportInfoDataValue, setReportInfoDataValue] = useState([intialReportValue])
  const [reportInfoDataValueIntial, setReportInfoDataValueIntial] = useState([])
  const [assetInfoError, setAssetInfoError] = useState(false);
  const [defaultSelectedAsset, setDefaultSelectedAsset] = useState(null)
  let [filterValue, setFilterValue] = React.useState({
    JobNo: [],
    JobName: [],
    JobType: [],
    AssignedById: [],
    AssignedToId: [],
    CreatedOn: "",
    Tat: "",
    PriorityIds: [],
    SearchText: "",
    CompletedDate: "",
  });
  const classes = useStyles();
  const [reportData, setReportData] = useState();
  const [historyData, setHistoryData] = useState();
  const [historyColumns, setHistoryColumns] = useState();
  const [dropDown, setDropDown] = useState({
    frequency: [],
    year: [],
    reportingPeroid: [],
    currency: [],
    variable: [],
    unit: [],
    valuation: [],
  });
  const [isOverallInfo, setIsOverAllInfo] = useState()
  const [jobAssetId, setJobAssetId] = useState(null)
  const [refreshReportInfo, setRefreshReportInfo] = useState(false)
  const [companyDeatils, setCompanyDeatis] = React.useState([])
  const [companyHeader, setCompanyHeader] = React.useState([])
  const [datametricsAllChecked, setDatametricsAllChecked] = React.useState(false)
  const [datametricsCheckedValue, setDatametricsCheckedValue] = React.useState([])
  const [swapValues, setSwapValues] = React.useState({ reportingPeriodId: null, reportingPeriod: "" })
  const [swapHead, setSwapHead] = React.useState()
  const [auditVariableId, setAuditVariableId] = React.useState([])
  const [defaultVariableValue, setDefaultVariableValue] = React.useState()
  const [defaultVaribaleCRUValue, setDefaultVaribaleCRUValue] = useState()
  const [defaultUnitValue, setDefaultUnitValue] = React.useState()
  const [editAccess, setEditAccess] = useState(false);

  // Declaration of States and Component Variables End Here

  //data for preview page
  const [previewAssetInfo, setPreviewAssetInfo] = useState({
    companyName: '',
    attributable: "Value",
    selectedAsset: "",
    isAssetSegment: true,
    commodity: "",
    country: "",
    region: "",
    subRegion: "",
    segmentComments: "",
    linkToCat: "",
    linkToAssetPlatform: "",
    linkToEat: "",
  });
  ///end of data for preview page


  // Declaration of States and Component Variables Start Here
  const [segComments, setSegComments] = useState("");
  const [linkToCat, setLinktoCat] = useState("");
  const [linkToAssetPForm, setlinkToAssetPform] = useState("");
  const [linkToEat, setLinktoEat] = useState("");
  const [currency, setCurrency] = useState("");
  const [variableReportId, setVariableReportId] = useState(null);
  const [selectedYear, setSelectedYear] = useState([]);
  const [Comment, setComment] = React.useState({ message: "" });
  const [sOpen, setSOpen] = React.useState(null);
  const [isAssetSegment, setIsAssetSegment] = useState(false)
  const [liveData, setLiveData] = useState([])
  const [previewReportInfo, setPreviewReportInfo] = useState(null)
  const [pageIndex, setPageIndex] = useState(null)
  const [crudModeForVariable, setCrudModeForVariable] = useState(false)
  const [crudModeForUnit, setCrudModeForUnit] = useState(false)
  const [crudModeForCreatePublication, setCrudModeForCreatePublication] = useState(false)
  const open1 = Boolean(sOpen);
  const idss = open1 ? "simple-popover" : undefined;
  const [fileuploadLoading, setFileuploadLoading] = useState(false)
  const [assetVariableId, setAssetVariableId] = useState(null)
  const [deleteVariableId, setDeleteVariableId] = useState(null)
  const [openAdobeViewer, setOpenAdobeViewer] = useState()
  const [adobeViewerData, setAdobeViewerData] = useState({ jobId: 0, fileName: '', pageNumber: 0, crudState: "" })
  const [reportInfoDetails, setReportInfoDetails] = useState([])
  const [reportsValue, setReportsValue] = useState([])
  const [elData, setEldata] = useState()
  const [loading, setLoading] = useState(false);
  const [editState, setEditState] = useState({ right: false });
  const [isOpenSwap, setIsOpenSwap] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isHistory, setIsHistory] = useState();
  const [tempData, setTempData] = useState();
  // Declaration of States and Component Variables End Here
  React.useEffect(() => {
    GetDataFromTempStorage();

  }, [props.location])

  React.useEffect(() => {
    if (tempData) {

      setPreviewAssetInfo({
        ...previewAssetInfo,
        companyName: tempData?.state?.companyName,
      });
      GetAssetInfo();

    }

  }, [tempData])




  React.useEffect(() => {
    //console.log(tempData)
    getDataMetricsDefaultValue()
  }, [defaultSelectedAsset])

  React.useEffect(() => {
    if (reportData || defaultSelectedAsset || refreshReportInfo) {
      setRefreshReportInfo(false)
      getReportInfo(reportData ? reportData : defaultSelectedAsset);
    }
  }, [reportData, defaultSelectedAsset, refreshReportInfo]);



  useEffect(() => {
    getUnitReportDropDown()
  }, [cruRefresh])

  useEffect(() => {
    if (assetInfo) {
      if (tempData?.state?.defaultAsset) {
        let ind
        assetInfo.assetList.forEach((e, i) => {
          if (e.jobAssetId === tempData?.state?.defaultAsset?.jobAssetId) {
            ind = i
          }
        })
        setautoSelectedAssetDropdown(ind)
      } else {
        setautoSelectedAssetDropdown(0)
      }
    }
  }, [assetInfo])


  React.useEffect(() => {
    let reportData = reportInfo
    let previewData = []
    if (reportData.length > 0)
      reportData.forEach(rd => {
        if (!rd.assetReportId) {
          previewData.push({
            "reportingYear": rd.reportingYear && rd.reportingYear.id ? rd.reportingYear.id : null,
            "reportingPeriod": rd.reportingPeriod && rd.reportingPeriod.id ? rd.reportingPeriod.id : null,
            "publicationDate": "2022-10-21T13:31:02.496Z",
            "reportingType": rd.reportingType && rd.reportingType.id ? rd.reportingType.id : null,
            "reportName": rd.reportName,
            "link": rd.link,
            "FilePath": rd.FilePath,
            "file": rd.file,
            "companyName": rd.companyName
          })
        }
      });
    setPreviewReportInfo(previewData)

  }, [reportInfo])

  React.useEffect(() => {
    if (tempData) {
      GetAssetInfo();
    }

  }, [assetType, refresh,]);


  React.useEffect(() => {
    GetDropDownValue();
    getVariableReportDropDown()
    var propsData = tempData?.state
    var b = !((propsData?.role === "Merit Analyst") && (propsData.statusName === "Merit Quality Control" || propsData.statusName === "Merit QC Passed" || propsData.statusName === "Merit QC Passed with Errors" || propsData.statusName === "CRU Review Passed" || propsData.statusName === "CRU Review Passed with Errors"))
    setEditAccess(b)
  }, []);

  React.useEffect(() => {
    GetVariableDeatils()
  }, [mainitem.reportingYearId, defaultSelectedAsset?.jobAssetId])

  React.useEffect(() => {
    if (defaultVariableValue) {
      axios
        .get(
          process.env.React_App_baseUrl + "DropDown/GetVariableReportsDropDown", {
          headers: {
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          }
        }
        )
        .then((res) => {
          let variable = (res.data || []).map((el) => {
            if (el.name === defaultVariableValue) {
              setMainItem({ ...mainitem, variableReportId: el.id, variableReport: el.name, variableTypeName: el.variableTypeName, cruVariable: el.cruVariableName })
              setDefaultVariableValue()
              setDefaultVaribaleCRUValue()
            }
            return {
              label: el.name,
              value: el.id,
              variableTypeName: el.variableTypeName,
              cruVariableName: el.cruVariableName,
            };
          });
          setDropDown((prev) => {
            return { ...prev, variable: variable || [] };
          });
        })
        .catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res)
          })
        });

    }
  }, [defaultVariableValue])

  React.useEffect(() => {
    if (defaultUnitValue) {
      axios
        .get(process.env.React_App_baseUrl + "DropDown/GetUnitReportsDropDown", {
          headers: {
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          }
        }).then((res) => {
          let unit = (res.data || []).map((el) => {
            if (el.name === defaultUnitValue) {
              setMainItem({ ...mainitem, unitReportId: el.id, unitReport: el.name, isManual: el.isManual, cruUnit: el.cruUnitName })
              setDefaultUnitValue()
            }
            return { label: el.name, value: el.id, cruUnitName: el.cruUnitName, isManual: el.isManual };
          });
          setDropDown((prev) => {
            return { ...prev, unit: unit || [] };
          });
        })
        .catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res)
          })
        });

    }
  }, [defaultUnitValue])

  useEffect(() => {
    getHistoryData()
  }, [sorting])


  //Function for toggle side window 
  const toggleDrawer = (open, el, head) => (event, item) => {
    setEldata(el)
    setEditDataMaterics({
      valuationId: el.valuationTypeId, valuation: el.valuationType,
      comments: el[head.publication]?.comments, minValue: el[head.publication]?.minValue,
      maxValue: el[head.publication]?.maxValue, value: el[head.publication]?.value,
      variableValueId: el[head.publication].variableValueId,
      reportingYearId: el[head.publication].reportingYearId,
      reportingPeriodId: el[head.publication].reportingPeriodId,
      lastUpdateYear: el.lastUpdateYear,
      lastUpdateYearId: el.lastUpdateYearId,
      lastUpdateQuater: el.lastUpdateQuater,
      lastUpdateQuaterId: el.lastUpdateQuaterId
    })
    if (el[head.publication]?.valueReportInfo.length) {
      setReportInfoDetails(el[head.publication]?.valueReportInfo)
    } else {
      var a = [];
      reportInfoDetails.map((item) => {
        a.push({ reportName: item.reportName, pageNumber: item.pageNumber, reportingId: item.reportingId })
      }
      )
      setReportInfoDetails(a)

    }
    setEditState({ ...editState, right: open });
  };

  const GetDataFromTempStorage = () => {
    let temp = CommonService.GetStoredData(props, "jobManagement");
    setTempData(temp);
    setCompanyName(temp?.state?.companyName);

    setPreviewAssetInfo({
      companyName: temp?.state?.companyName,
      attributable: "Value",
      selectedAsset: "",
      isAssetSegment: true,
      commodity: "",
      country: "",
      region: "",
      subRegion: "",
      segmentComments: "",
      linkToCat: "",
      linkToAssetPlatform: "",
      linkToEat: "",
    });
  }









  const swapValuesFun = (head) => {
    if (datametricsCheckedValue.length) {
      setSwapHead(head)
      setIsOpenSwap(!isOpenSwap);
    } else {
      setSwapHead()
      toasterError("Please select atleast one variable")
    }
  };

  const swapApiCall = (copy) => {
    let swapValue = []
    datametricsCheckedValue.map((item, index) => {
      item.reportingYear.map((subitem, subindex) => {
        if ((subitem.publication === swapHead) && (subitem.variableValueId > 0)) {
          let data = {
            "variableValueId": subitem.variableValueId,
            "toReportingYearId": subitem.reportingYearId,
            "toReportingPeriodId": swapValues.reportingPeriodId,
            "isCopy": copy
          }
          swapValue.push(data)
        }
      })
    })

    cancelPopUp()
    if (swapValue.length) {
      axios.post(process.env.React_App_baseUrl + "updatejobrecord/SwapVariableData", swapValue, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }).then((res) => {
        if (swapValue[0].isCopy) {
          toasterSuccess("Copied Successfuly");
        } else {
          toasterSuccess("Swaped Successfuly");
        }
        getHistoryData()
      }).catch((err) => ErroHandling(err, function (res) {
        toasterError(res)
      }))
    } else {
      if (swapValue[0].isCopy) {
        toasterError("No value to Copy")
      } else {
        toasterError("No value to Swap")

      }
    }
  }

  const samationValues = (yearId) => {
    if (datametricsCheckedValue.length) {
      let samatonValueData = datametricsCheckedValue.map((item, index) => {
        return {
          variableId: item.variableId,
          valuationTypeId: item.valuationTypeId
        }
      })
      axios.post(process.env.React_App_baseUrl + "updatejobrecord/UpdateAnnualValue", samatonValueData, {
        headers: {
          YearId: yearId,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

        },
      }).then((res) => {
        toasterSuccess("Calculated Successfuly");
        getHistoryData()
      }).catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      })
    } else {
      toasterError("Please select atleast one variable")
    }
  }



  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  const validateReportInfoData = (data) => {
    let status
    status = data.map((da, i) => {
      if (!da.reportingYear && !da.reportingPeriod && !da.reportingType && !da.reportName
        && !da.link && !da.file && !da.publicationDate) {
        toasterError(`Please fill required fields in Report-${i + 1}`)
        status = false
      } else if (!da.reportingYear) {
        toasterError(`Please fill reportingYear fields in Report-${i + 1}`)
        status = false
      } else if (!da.reportingPeriod) {
        toasterError(`Please fill reportingPeriod fields in Report-${i + 1}`)
        status = false
      } else if (!da.reportingType) {
        toasterError(`Please fill reportingType fields in Report-${i + 1}`)
        status = false
      } else if (!da.link) {
        toasterError(`Please fill link fields in Report-${i + 1}`)
        status = false
      } else if (da.link && !validURL(da.link)) {
        status = validURL(da.link)
        toasterError(`Please enter a valid URL in Report-${i + 1}`)
      } else if (da.file.length < 1) {
        toasterError(`Please upload a report file in Report-${i + 1}`)
        status = false
      } else if (!da.publicationDate) {
        toasterError(`Please fill publication Date in Report-${i + 1}`)
        status = false
      } else {
        status = true
      }
      return status
    });
    let st = status.find(function (element) {
      return !element;
    });
    if (!st && st !== undefined) {
      status = false
    } else {
      status = true
    }
    return status
  }

  const handleRequestSort = (event, property) => {
    setSorting({ sortBy: property, isAscending: !sorting.isAscending })
  };

  const formatPublicationDate = (date) => {
    let formatedDate
    var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
    if (!!date?.match(reg)) {
      let newdate = date.split("-").reverse().join("-");
      formatedDate = dayjs(newdate).format('YYYY-MM-DDTHH:mm:ss')
    } else {
      formatedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss')
    }
    return formatedDate
  }

  const saveDraft = (mesg) => {
      /**
       * Checks if the message equals "Variable added successfully"
       */
      if (mesg === "Variable added successfully") {
       
        
            // Declare a flag indicating if mandatory fields are empty, initially set to true
     var isMandatoryFieldEmpty = true;

     // Check if 'variableReportId', 'unitReportId' and either 'value' or 'minValue' in the mainitem object are not defined or null.
     // If any of them is not defined or null, then set isMandatoryFieldEmpty flag to false
     if (!(mainitem?.variableReportId && mainitem?.unitReportId && (mainitem?.value || mainitem.minValue))) {
         isMandatoryFieldEmpty = false;
     }
    
     // This block of code will run only if reportInfoDataValue is not null and its length more than 0 (i.e., it's not an empty array)
     if(reportInfoDataValue != null && reportInfoDataValue.length){
          // Loop over each item in the reportInfoDataValue array
          for(var i=0; i<reportInfoDataValue.length; i++){   
              // If 'reportName' or 'pageNumber' is not defined or null, set isMandatoryFieldEmpty flag to false       
              if(!reportInfoDataValue[i].reportName || !reportInfoDataValue[i].pageNumber){
                  isMandatoryFieldEmpty  =false;
                  break;  // Break the loop as we found an item without mandatory fields
              }
          }
     }

     // If any mandatory field is empty (i.e., isMandatoryFieldEmpty flag is false), show an error message,
     // stop loading indicator and return from the function
     if(!isMandatoryFieldEmpty){          
         toasterError("Please fill all mandatory");
         setLoading(false);
         return;
     }


      }
    var minValueParse = parseFloat(mainitem.minValue.toString().replaceAll(',', ''));
    var maxValueParse = parseFloat(mainitem.maxValue.toString().replaceAll(',', ''));
    var valueParse = parseFloat(mainitem.value.toString().replaceAll(',', ''));
    var variableData;
    if ((mainitem?.variableReportId && mainitem?.unitReportId && (mainitem?.value || mainitem.minValue))) {
      if (mainitem?.frequencyId && mainitem?.variableReportId && mainitem?.unitReportId) {
        if (mainitem.valuationTypeId && mainitem.reportingPeriodId && mainitem.reportingYearId) {
          if (mainitem.valuationTypeId === 4 && mainitem.minValue) {
            variableData = [{
              "variableValueId": mainitem.variableValueId ? mainitem.variableValueId : 0,
              "assetVariableId": mainitem?.assetVariableId ? mainitem?.assetVariableId : 0,
              "valuationTypeId": mainitem.valuationTypeId,
              "reportingPeriodId": mainitem.reportingPeriodId,
              "reportingYearId": mainitem.reportingYearId,
              "value": null,
              "minValue": minValueParse,
              "maxValue": maxValueParse ? maxValueParse : null,
              "comments": mainitem.comments,
              "valueReportInfo": reportInfoDataValue
            }]
            SaveDraftFinal(variableData, mesg)
          } else if (mainitem.valuationTypeId != 4 && mainitem.value) {
            variableData = [{
              "variableValueId": mainitem.variableValueId ? mainitem.variableValueId : 0,
              "assetVariableId": mainitem?.assetVariableId ? mainitem?.assetVariableId : 0,
              "valuationTypeId": mainitem.valuationTypeId,
              "reportingPeriodId": mainitem.reportingPeriodId,
              "reportingYearId": mainitem.reportingYearId,
              "value": valueParse,
              "minValue": null,
              "maxValue": null,
              "comments": mainitem.comments,
              "valueReportInfo": reportInfoDataValue
            }]
            SaveDraftFinal(variableData, mesg)

          } else {
            toasterError("Please fill all mandatory")
            setLoading(false)
          }
        } else {
          toasterError("Please fill all mandatory")
          setLoading(false)
        }
      } else {
        toasterError("Please fill all mandatory")
        setLoading(false)
      }
    } else {
      // console.log("Without")
      saveDraftWithoutDataMetrics(mesg)
    }

  }

  const saveDraftWithoutDataMetrics = (mesg) => {
    var jobdetails = tempData?.state
    var reportInfoData = reportInfo.map((el) => {
      return {
        "assetReportId": el.assetReportId ? el.assetReportId : 0,
        "jobAssetId": defaultSelectedAsset.jobAssetId,
        "reportingYearId": el.reportingYear?.id,
        "reportingPeriodId": el.reportingPeriod?.id,
        "publicationDate": formatPublicationDate(el.publicationDate),
        "reportingTypeId": el.reportingType?.id,
        "reportName": el.reportName,
        "originalReportName": el.originalReportName ? el.originalReportName : '',
        "link": el.link,
      }
    })



    var sendData = {
      "jobID": jobdetails.jobIds,
      "companyId": jobdetails.companyID,
      "isCRUCompany": jobdetails.isCRUCompany,
      "assetId": jobdetails.assetId,
      "isCRUAsset": defaultSelectedAsset.isCruasset,
      "jobAssetId": defaultSelectedAsset.jobAssetId,
      "assetInfoDetails": {
        "attibutablePercentage": Number(selectedAssetInfo.attributable) ? Number(selectedAssetInfo.attributable) : 0,
        "isSegment": isAssetSegment,
        "segmentComments": segComments,
        "linktoCAT": linkToCat || null,
        "linktoEAT": linkToEat || null,
        "linktoAssetPlatform": linkToAssetPForm || null
      },
      "reportInfoDetails": reportInfoData,
      "assetVariableInfo": []
    }
    if (validateReportInfoData(reportInfo)) {
      axios.post(process.env.React_App_baseUrl + "updatejobrecord/SaveDraft", sendData, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }).then((res) => {
        setLoading(false)
        setRefreshReportInfo(true)
        if (mesg !== "Variable added successfully") {
          toasterSuccess(mesg);
        } else {
          toasterError("Please fill all the required fields");
        }
        getHistoryData()
        getLastVariableAfterSave()
        setreportDeleteRefresh(true)
      }).catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
        setLoading(false)
      })
    } else {
      toasterError("Please fill all the required fields");
      setLoading(false)
    }
  }

  const SaveDraftFinal = (variableData, mesg) => {
    var minValueParse = parseFloat(mainitem.minValue.toString().replaceAll(',', ''));
    var maxValueParse = parseFloat(mainitem.maxValue.toString().replaceAll(',', ''));
    var valueParse = parseFloat(mainitem.value.toString().replaceAll(',', ''));
    var jobdetails = tempData?.state
    var reportInfoData = reportInfo.map((el) => {
      return {
        "assetReportId": el.assetReportId ? el.assetReportId : 0,
        "jobAssetId": defaultSelectedAsset.jobAssetId,
        "reportingYearId": el.reportingYear?.id,
        "reportingPeriodId": el.reportingPeriod?.id,
        "publicationDate": formatPublicationDate(el.publicationDate),
        "reportingTypeId": el.reportingType?.id,
        "reportName": el.reportName,
        "originalReportName": el.originalReportName ? el.originalReportName : '',
        "link": el.link,
      }
    })

    var sendData = {
      "jobID": jobdetails.jobIds,
      "companyId": jobdetails.companyID,
      "isCRUCompany": jobdetails.isCRUCompany,
      "assetId": jobdetails.assetId,
      "isCRUAsset": defaultSelectedAsset.isCruasset,
      "jobAssetId": defaultSelectedAsset.jobAssetId,
      "assetInfoDetails": {
        "attibutablePercentage": Number(selectedAssetInfo.attributable) ? Number(selectedAssetInfo.attributable) : 0,
        "isSegment": isAssetSegment,
        "segmentComments": segComments,
        "linktoCAT": linkToCat || null,
        "linktoEAT": linkToEat || null,
        "linktoAssetPlatform": linkToAssetPForm || null
      },
      "reportInfoDetails": reportInfoData,
      "assetVariableInfo": [
        {
          "assetVariableId": mainitem.assetVariableId ? mainitem.assetVariableId : 0,
          "jobAssetId": defaultSelectedAsset.jobAssetId,
          "frequencyId": mainitem.frequencyId,
          "lastYearId": mainitem.lastYearId || null,
          "lastQuarterId": mainitem.lastQuarterId || null,
          "currencyId": mainitem.currencyId || null,
          "variableReportId": mainitem.variableReportId,
          "unitReportId": mainitem.unitReportId,
          "variableValues": variableData
        }
      ]
    }

    if (minValueParse && maxValueParse) {
      if (maxValueParse > minValueParse) {
        axios.get(process.env.React_App_baseUrl + "updatejobrecord/GetAssetVariableId", {
          headers: {
            jobAssetId: defaultSelectedAsset.jobAssetId,
            VariableReportId: mainitem.variableReportId,
            UnitReportId: mainitem.unitReportId,
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

          }
        }).then((res) => {
          sendData.assetVariableInfo[0].assetVariableId = res.data
          sendData.assetVariableInfo[0].variableValues[0].assetVariableId = res.data
          if (validateReportInfoData(reportInfo)) {
            axios.post(process.env.React_App_baseUrl + "updatejobrecord/SaveDraft", sendData, {
              headers: {
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
              }
            }).then((res) => {
              setLoading(false)
              setRefreshReportInfo(true)
              if (mesg) {
                toasterSuccess(mesg);
              } else {
                toasterSuccess("Draft Saved Successfuly");
              }
              getHistoryData()
              getLastVariableAfterSave()
            }).catch((err) => {
              ErroHandling(err, function (res) {
                toasterError(res)
              })
              setLoading(false)
            })
          } else {
            toasterError("Please fill all the required fields");
            setLoading(false)

          }
        }).catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res)
          })
          setLoading(false)
        })
      } else {
        toasterError("Please enter min value is lesser than max value")
        setLoading(false)
      }
    } else {
      axios.get(process.env.React_App_baseUrl + "updatejobrecord/GetAssetVariableId", {
        headers: {
          jobAssetId: defaultSelectedAsset.jobAssetId,
          VariableReportId: mainitem.variableReportId,
          UnitReportId: mainitem.unitReportId,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

        }
      }).then((res) => {
        sendData.assetVariableInfo[0].assetVariableId = res.data
        sendData.assetVariableInfo[0].variableValues[0].assetVariableId = res.data
        if (validateReportInfoData(reportInfo)) {
          axios.post(process.env.React_App_baseUrl + "updatejobrecord/SaveDraft", sendData, {
            headers: {
              'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
            }
          }).then((res) => {
            setLoading(false)
            setRefreshReportInfo(true)
            if (mesg) {
              toasterSuccess(mesg);
            } else {
              toasterSuccess("Draft Saved Successfuly");
            }
            //setMainItem({ ...mainitem, variableReport: "", variableReportId: null, unitReport: "", unitReportId: null, currency: "", currencyId: null, value: "", minValue: "", maxValue: "", comments: "", cruUnit: "", cruVariable: "" })
            getHistoryData()
            getLastVariableAfterSave()
          }).catch((err) => {
            ErroHandling(err, function (res) {
              toasterError(res)
            })
            setLoading(false)
          })
        } else {
          toasterError("Please fill all the required fields");
          setLoading(false)
        }
      }).catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
        setLoading(false)
      })
    }
  }

  //function for save job record 
  const saveSubmit = () => {
    var minValueParse = parseFloat(mainitem.minValue.toString().replaceAll(',', ''));
    var maxValueParse = parseFloat(mainitem.maxValue.toString().replaceAll(',', ''));
    var valueParse = parseFloat(mainitem.value.toString().replaceAll(',', ''));

    var variableData;
    var jobdetails = tempData?.state
    if (mainitem?.frequencyId && mainitem?.variableReportId && mainitem?.unitReportId) {
      if (mainitem.valuationTypeId && mainitem.reportingPeriodId && mainitem.reportingYearId) {
        if (mainitem.valuationTypeId === 4 && mainitem.minValue) {
          if (maxValueParse && minValueParse) {
            if (maxValueParse > minValueParse) {
              variableData = [{
                "variableValueId": mainitem.variableValueId ? mainitem.variableValueId : 0,
                "assetVariableId": mainitem?.assetVariableId ? mainitem?.assetVariableId : 0,
                "valuationTypeId": mainitem.valuationTypeId,
                "reportingPeriodId": mainitem.reportingPeriodId,
                "reportingYearId": mainitem.reportingYearId,
                "value": null,
                "minValue": minValueParse,
                "maxValue": maxValueParse ? maxValueParse : null,
                "comments": mainitem.comments,
                "valueReportInfo": reportInfoDataValue
              }]
            } else {
              setConfirmation2(true)
            }
          } else {
            variableData = [{
              "variableValueId": mainitem.variableValueId ? mainitem.variableValueId : 0,
              "assetVariableId": mainitem?.assetVariableId ? mainitem?.assetVariableId : 0,
              "valuationTypeId": mainitem.valuationTypeId,
              "reportingPeriodId": mainitem.reportingPeriodId,
              "reportingYearId": mainitem.reportingYearId,
              "value": null,
              "minValue": minValueParse,
              "maxValue": maxValueParse ? maxValueParse : null,
              "comments": mainitem.comments,
              "valueReportInfo": reportInfoDataValue
            }]
          }

        } else if (mainitem.valuationTypeId != 4 && mainitem.value) {
          variableData = [{
            "variableValueId": mainitem.variableValueId ? mainitem.variableValueId : 0,
            "assetVariableId": mainitem?.assetVariableId ? mainitem?.assetVariableId : 0,
            "valuationTypeId": mainitem.valuationTypeId,
            "reportingPeriodId": mainitem.reportingPeriodId,
            "reportingYearId": mainitem.reportingYearId,
            "value": valueParse,
            "minValue": null,
            "maxValue": null,
            "comments": mainitem.comments,
            "valueReportInfo": reportInfoDataValue
          }]
        } else {
          setConfirmation2(true)
        }
      } else {
        setConfirmation2(true)
      }
      var reportInfoData = reportInfo.map((el) => {
        return {
          "assetReportId": el.assetReportId ? el.assetReportId : 0,
          "jobAssetId": defaultSelectedAsset.jobAssetId,
          "reportingYearId": el.reportingYear?.id,
          "reportingPeriodId": el.reportingPeriod?.id,
          "publicationDate": formatPublicationDate(el.publicationDate),
          "reportingTypeId": el.reportingType?.id,
          "reportName": el.reportName,
          "originalReportName": el.originalReportName ? el.originalReportName : '',
          "link": el.link,
        }
      })

      var sendData = {
        "jobID": jobdetails.jobIds,
        "companyId": jobdetails.companyID,
        "isCRUCompany": jobdetails.isCRUCompany,
        "assetId": jobdetails.assetId,
        "isCRUAsset": defaultSelectedAsset.isCruasset,
        "jobAssetId": defaultSelectedAsset.jobAssetId,
        "assetInfoDetails": {
          "attibutablePercentage": Number(selectedAssetInfo.attributable) ? Number(selectedAssetInfo.attributable) : 0,
          "isSegment": isAssetSegment,
          "segmentComments": segComments,
          "linktoCAT": linkToCat || null,
          "linktoEAT": linkToEat || null,
          "linktoAssetPlatform": linkToAssetPForm
        },
        "reportInfoDetails": reportInfoData,
        "assetVariableInfo": [
          {
            "assetVariableId": mainitem.assetVariableId ? mainitem.assetVariableId : 0,
            "jobAssetId": defaultSelectedAsset.jobAssetId,
            "frequencyId": mainitem.frequencyId,
            "lastYearId": mainitem.lastYearId || null,
            "lastQuarterId": mainitem.lastQuarterId || null,
            "currencyId": mainitem.currencyId || null,
            "variableReportId": mainitem.variableReportId,
            "unitReportId": mainitem.unitReportId,
            "variableValues": variableData
          }
        ]
      }

      if (mainitem.variableReportId && mainitem.unitReportId) {
        axios.get(process.env.React_App_baseUrl + "updatejobrecord/GetAssetVariableId", {
          headers: {
            jobAssetId: defaultSelectedAsset.jobAssetId,
            VariableReportId: mainitem.variableReportId,
            UnitReportId: mainitem.unitReportId,
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

          }
        }).then((res) => {
          sendData.assetVariableInfo[0].assetVariableId = res.data
          sendData.assetVariableInfo[0].variableValues[0].assetVariableId = res.data
          // if (validateReportInfoData(reportInfo) && validateAssetInfoData()) {
          if (validateReportInfoData(reportInfo)) {
            axios.post(process.env.React_App_baseUrl + "updatejobrecord/SaveDraft", sendData, {
              headers: {
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
              }
            }).then((res) => {
              setLoading(false)
              setRefreshReportInfo(true)
              setMainItem({ ...mainitem, variableReport: "", variableReportId: null, unitReport: "", unitReportId: null, currency: "", currencyId: null, value: "", minValue: "", maxValue: "", comments: "", cruUnit: "", variableTypeName: "", cruVariable: "" })
              setConfirmation1(true)
              getHistoryData()
            }).catch((err) => {
              ErroHandling(err, function (res) {
                toasterError(res)
              })
            })
          } else {
            setConfirmation2(true)
          }
        }).catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res)
          })
        })
      } else {
        /**
            * Calling a function that processes and saves draft message without data metrics
        */
        saveDraftWithoutDataMetrics("Drafted Successfully")
        setConfirmation2(true)
      }
    } else {
       /**
            * Calling a function that processes and saves draft message without data metrics
        */
      saveDraftWithoutDataMetrics("Drafted Successfully")
      setConfirmation2(true)
    }
  }

  //API call for submit job record
  const finalSubmit = () => {
    axios.get(process.env.React_App_baseUrl + 'updatejobrecord/SubmitJob', {
      headers: {
        jobAssetId: defaultSelectedAsset?.jobAssetId,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

      }
    }).then((res) => {
      toasterSuccess("Submited Successfully")
      history.push('/app/data-collections/manage-job-records')
    }).catch((err) =>
      ErroHandling(err, function (res) {
        toasterError(res)
      }))


  }

  //API call for report info
  const getReportInfo = (data) => {
    setReportInfo([])
    GetUpdateJobAssetDetails(data.jobAssetId);
    axios
      .post(
        process.env.React_App_baseUrl + "updatejobrecord/GetReportInfo",
        null,
        {
          headers: {
            jobAssetId: data.jobAssetId,
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

          },
        }
      )
      .then(function (response) {
        let dataofReport = [];
        if (response.data.length > 0) {
          setReportFound(true)
        } else {
          setReportFound(false)
        }
        response.data.forEach((res) => {
          dataofReport.push(res);
          dataofReport = dataofReport.reduce((unique, o) => {
            if (!unique.some(obj => obj.assetReportId === o.assetReportId)) {
              unique.push(o);
            }
            return unique;
          }, []);
        });
        dataofReport.forEach((el, i) => {
          if (!el.assetReportId) {
            dataofReport.splice(i, 1);
          }
        });
        setReportInfo([...dataofReport]);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
  };

  //API call for Asset info
  const GetAssetInfo = () => {
    var SendFilterValue;
    if (
      filterValue.JobNo.length === 0 &&
      filterValue.JobName.length === 0 &&
      filterValue.AssignedById.length === 0 &&
      filterValue.AssignedToId.length === 0 &&
      filterValue.CreatedOn === "" &&
      filterValue.CompletedDate === "" &&
      filterValue.Tat === "" &&
      filterValue.PriorityIds.length === 0 &&
      filterValue.SearchText === ""
    ) {
      SendFilterValue = "";
    } else {
      SendFilterValue = filterValue;
    }
    if (tempData) {
      axios
        .post(
          process.env.React_App_baseUrl + "updatejobrecord/GetAssetInfo",
          null,
          {
            headers: {
              jobId: tempData?.state?.jobIds,
              companyId: tempData?.state?.companyID,
              isCruCompany: tempData?.state?.isCRUCompany,
              'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

            },
          }
        )
        .then(function (response) {
          setAssetInfo(response.data);
          setPageIndex(response.data.assetList.length - 1)
          setPreviewAssetInfo({
            ...previewAssetInfo,
            companyName: response.data.companyName,
          });
        })
        .catch(function (error) {
          ErroHandling(error, function (res) {
            toasterError(res)
          })
        });

    }
  };

  //Method for check All functionality 
  const handleChangeCheckBoxAll = (event) => {
    const allCheckboxValue =
      datametricsCheckedValue.length === companyDeatils.length
        ? []
        : companyDeatils
    setDatametricsCheckedValue(allCheckboxValue);
    setDatametricsAllChecked(!datametricsAllChecked)
  };

  //Method for single check functionality 
  const handleChangeCheckBoxSingle = (event, option) => {
    let selectedOld = [...datametricsCheckedValue];
    if (selectedOld.length === companyDeatils.length) {
      setDatametricsAllChecked(false);
    }
    if (selectedOld.length === 0 && companyDeatils.length > 1) {
      selectedOld.push(option);
    } else if (selectedOld.includes(option)) {
      selectedOld.splice(selectedOld.indexOf(option), 1);
    } else {
      selectedOld.push(option);
      if (selectedOld.length === companyDeatils.length) {
        setDatametricsAllChecked(true);
      }
    }
    setDatametricsCheckedValue(selectedOld);
  };

  const ArrowUpAndDown = (data, position, ind) => {
    if ((position === "UP" && ind !== 0) || (position === "DOWN" && ind + 1 !== companyDeatils.length)) {
      setIsLoading(true)
      axios.put(process.env.React_App_baseUrl + 'updatejobrecord/UpdateOrderIdForJobAssetVariables', {
        "jobAssetId": defaultSelectedAsset.jobAssetId,
        "recordToMoveOrderId": data.orderId,
        "newPositionOrderId": (position === "UP") ? companyDeatils[ind - 1].orderId : companyDeatils[ind + 1].orderId
      }, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }).then((res) => {
        if (res.status === 200) {
          toasterSuccess("Moved Successfully")
          GetVariableDeatils()
        }
      }).catch((err) => {
        setLoading(false)
      })
    }
  }



  //API call for Asset Details
  const getAssetDetails = (id) => {

    axios.post(process.env.React_App_baseUrl + 'updatejobrecord/GetAssetDetails', null, {
      headers: {
        jobAssetId: id, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    }).then((res) => {
      if (res.status === 200) {
        setSelectedAssetInfo({
          attributable: res.data.attibutablePercentage ? res.data.attibutablePercentage : "",
          assetIsAsegment: res.data.isSegment,
          segmentComments: res.data.segmentComments,
          linkToCAT: res.data.linktoCAT,
          linkToAssetPlatform: res.data.linktoAssetPlatform,
          linkToEAT: res.data.linktoEAT,
        });
        let lik = res.data.linktoEAT
        setIsAssetSegment(res.data.isSegment !== null ? res.data.isSegment : '')
        setSegComments(res.data.segmentComments !== null ? res.data.segmentComments : '')
      } else {
        //Internal server handler is globally writting so removing duplicate error handler
        // toasterError("Internal Server Error")
      }
    }).catch((error) => {
      ErroHandling(error, function (res) {
        toasterError(res)
      })
    })
    setJobAssetId(id)
  }

  //Method for save draft data
  const saveDraftUpdate = (variableData) => {

    var jobdetails = tempData?.state
    var reportInfoData = reportInfo.map((el) => {
      return {
        "assetReportId": el.assetReportId ? el.assetReportId : 0,
        "jobAssetId": defaultSelectedAsset.jobAssetId,
        "reportingYearId": el.reportingYear?.id,
        "reportingPeriodId": el.reportingPeriod?.id,
        "publicationDate": formatPublicationDate(el.publicationDate),
        "reportingTypeId": el.reportingType?.id,
        "reportName": el.reportName,
        "originalReportName": el.originalReportName ? el.originalReportName : '',
        "link": el.link,
      }
    })

    var sendData = {
      "jobID": jobdetails.jobIds,
      "companyId": jobdetails.companyID,
      "isCRUCompany": jobdetails.isCRUCompany,
      "assetId": jobdetails.assetId,
      "isCRUAsset": defaultSelectedAsset.isCruasset,
      "jobAssetId": defaultSelectedAsset.jobAssetId,
      "assetInfoDetails": {
        "attibutablePercentage": Number(selectedAssetInfo.attributable) ? Number(selectedAssetInfo.attributable) : 0,
        "isSegment": isAssetSegment,
        "segmentComments": segComments,
        "linktoCAT": linkToCat || null,
        "linktoEAT": linkToEat || null,
        "linktoAssetPlatform": linkToAssetPForm
      },
      "reportInfoDetails": reportInfoData,
      "assetVariableInfo": [
        {
          "assetVariableId": elData.variableId,
          "jobAssetId": defaultSelectedAsset.jobAssetId,
          "frequencyId": elData.frequencyId,
          "lastYearId": elData.lastUpdateYearId || null,
          "lastQuarterId": elData.lastUpdateQuaterId || null,
          "currencyId": elData.currenyId || null,
          "variableReportId": elData.variableReportId,
          "unitReportId": elData.unitReportId,
          "variableValues": variableData
        }
      ]
    }
    if (validateReportInfoData(reportInfo)) {
      axios.post(process.env.React_App_baseUrl + "updatejobrecord/SaveDraft", sendData, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }).then((res) => {
        toasterSuccess("Updated  Successfuly");
        setLoading(false)
        getHistoryData()
      }).catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      })
    } else {
      toasterError("Please fill all the required fields");
    }
  }

  const updateFromSave = () => {
    let block = false
    var variableData;
    elData.lastUpdateYear = editDataMaterics.lastUpdateYear;
    elData.lastUpdateYearId = editDataMaterics.lastUpdateYearId;
    elData.lastUpdateQuater = editDataMaterics.lastUpdateQuater;
    elData.lastUpdateQuaterId = editDataMaterics.lastUpdateQuaterId;
    setEldata(elData)

    let variableValueIds = [editDataMaterics.variableValueId]
    if (isReportUpload) {
      reportsValue.map((item) => {
        if (!((item.reportingId >= 0) && item.pageNumber)) {
          block = true
        }
      })
    }
    var minValueParse = editDataMaterics?.minValue ? parseFloat(editDataMaterics.minValue.toString().replaceAll(',', '')) : 0;
    var maxValueParse = editDataMaterics.maxValue ? parseFloat(editDataMaterics.maxValue.toString().replaceAll(',', '')) : 0;


    console.log("++++", Number(editDataMaterics.minValue), editDataMaterics.minValue)
    if (editDataMaterics.valuationId === 4) {
      if (minValueParse === 0) {
        deleteVariableValueFun(elData.variableId, variableValueIds)
      } else if (minValueParse) {
        if (minValueParse && maxValueParse) {
          if (minValueParse < maxValueParse) {
            if (!block) {
              variableData = [{
                "variableValueId": editDataMaterics?.variableValueId,
                "assetVariableId": elData?.variableId,
                "valuationTypeId": editDataMaterics.valuationId,
                "reportingPeriodId": editDataMaterics.reportingPeriodId,
                "reportingYearId": editDataMaterics.reportingYearId,
                "value": null,
                "minValue": minValueParse,
                "maxValue": maxValueParse ? maxValueParse : null,
                "comments": editDataMaterics.comments,
                "valueReportInfo": isReportUpload ? reportsValue : []
              }]
              saveDraftUpdate(variableData)
            } else {
              toasterError("Please fill all mandatory fields")
            }
          } else {
            toasterError("Please enter min value is lesser than max value")
          }
        } else {
          if (!block) {
            variableData = [{
              "variableValueId": editDataMaterics?.variableValueId,
              "assetVariableId": elData?.variableId,
              "valuationTypeId": editDataMaterics.valuationId,
              "reportingPeriodId": editDataMaterics.reportingPeriodId,
              "reportingYearId": editDataMaterics.reportingYearId,
              "value": null,
              "minValue": minValueParse,
              "maxValue": maxValueParse ? maxValueParse : null,
              "comments": editDataMaterics.comments,
              "valueReportInfo": isReportUpload ? reportsValue : []
            }]

            saveDraftUpdate(variableData)
          } else {
            toasterError("Please fill all mandatory fields")
          }
        }

      } else {
        toasterError("Please fill all mandatory fields")
      }
    } else {
      if (editDataMaterics.value) {
        console.log("Number", Number(editDataMaterics.value), editDataMaterics.value)
        const num = editDataMaterics.value ? parseFloat(editDataMaterics.value.toString().replaceAll(',', '')) : 0;
        setEditDataMaterics({ ...editDataMaterics, value: num })
        if (num === 0) {
          deleteVariableValueFun(elData.variableId, variableValueIds)
        } else if (Number(num)) {
          if (!block) {
            variableData = [{
              "variableValueId": editDataMaterics?.variableValueId,
              "assetVariableId": elData?.variableId,
              "valuationTypeId": editDataMaterics.valuationId,
              "reportingPeriodId": editDataMaterics.reportingPeriodId,
              "reportingYearId": editDataMaterics.reportingYearId,
              "value": num,
              "minValue": null,
              "maxValue": null,
              "comments": editDataMaterics.comments,
              "valueReportInfo": isReportUpload ? reportsValue : []

            }]
            console.log("111", variableData)
            saveDraftUpdate(variableData)
          } else {
            toasterError("Please fill all mandatory fields")
          }
        } else {
          toasterError("Please fill all mandatory fields")
        }
      } else {
        toasterError("Please fill all mandatory fields")

      }
    }


  }

  const getVariableReportDropDown = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetVariableReportsDropDown", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }
      )
      .then((res) => {
        let variable = (res.data || []).map((el) => {
          // console.log("drop",res.data)

          return {
            label: el.name,
            value: el.id,
            variableTypeName: el.variableTypeName,
            cruVariableName: el.cruVariableName,
          };
        });
        setDropDown((prev) => {
          return { ...prev, variable: variable || [] };
        });
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }

  const getUnitReportDropDown = () => {

    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetUnitReportsDropDown", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then((res) => {
        let unit = (res.data || []).map((el) => {
          return { label: el.name, value: el.id, cruUnitName: el.cruUnitName };
        });
        setDropDown((prev) => {
          return { ...prev, unit: unit || [] };
        });
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }

  // Declaration of React Hooks Start Here

  // Declaration of React Hooks End Here

  const setautoSelectedAssetDropdown = (index) => {
    setDefaultSelectedAsset(assetInfo.assetList[index])
    getAssetDetails(assetInfo.assetList[index].jobAssetId)
    getAssetStatus(assetInfo.assetList[index].jobAssetId)
  }

  const getAssetStatus = (id) => {
    axios
      .get(process.env.React_App_baseUrl + "job/GetJobAssetStatus", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null,
          'jobAssetId': id
        }
      })
      .then((res) => {
        var propsData = tempData?.state
        var b = !((propsData?.role === "Merit Analyst") && (res.data === "Merit Quality Control" || res.data === "Merit QC Passed" || res.data === "Merit QC Passed with Errors" || res.data === "CRU Review Passed" || res.data === "CRU Review Passed with Errors"))
        setEditAccess(b)

      }).catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      })
  }

  const GetDropDownValue = () => {
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetFrequencyDropDown", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then((res) => {
        let frequency = (res.data || []).map((el) => {
          return { label: el.name, value: el.id };
        });
        setDropDown((prev) => {
          return { ...prev, frequency: frequency || [] };
        });
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
    axios
      .get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingYear", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then((res) => {
        let year = (res.data || []).map((el) => {
          return { label: el.year, value: el.id };
        });
        setDropDown((prev) => {
          return { ...prev, year: year || [] };
        });
      })
      .catch((err) =>
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      );
    axios
      .get(
        process.env.React_App_baseUrl + "reportingcalendar/GetReportingPeriod", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }
      )
      .then((res) => {
        let reportingPeroid = (res.data || []).map((el) => {
          return { label: el.name, value: el.id };
        });
        setDropDown((prev) => {
          return { ...prev, reportingPeroid: reportingPeroid || [] };
        });
      })
      .catch((err) =>
        ErroHandling(err, function (res) {
          toasterError(res)
        }));
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCurrenciesDropDown", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then((res) => {
        let currency = (res.data || []).map((el) => {
          return { label: el.name, value: el.id };
        });
        setDropDown((prev) => {
          return { ...prev, currency: currency || [] };
        });
      })
      .catch((err) =>
        ErroHandling(err, function (res) {
          toasterError(res)
        }));
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetValuationTypeDropDown", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then((res) => {
        let valuation = (res.data || []).map((el) => {
          return { label: el.name, value: el.id };
        });
        setDropDown((prev) => {
          return { ...prev, valuation: valuation || [] };
        });
      })
      .catch((err) =>
        ErroHandling(err, function (res) {
          toasterError(res)
        }));
  };

  const GetUpdateJobAssetDetails = (id) => {
    axios
      .post(
        process.env.React_App_baseUrl + "DropDown/GetUpdateJobAssetDetails",
        null,
        {
          headers: {
            jobAssetId: id,
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

          },
        }
      )
      .then(function (response) {
        setAssetDetail(response.data);
        setPreviewAssetInfo({
          ...previewAssetInfo,
          commodity: response.data.commodities,
          country: response.data.country,
          region: response.data.region,
          subRegion: response.data.subRegion,
        });
        setlinkToAssetPform(response.data.linktoAssetPlatform)
        setLinktoCat(response.data.linktoCAT)
        setLinktoEat(response.data.linktoEAT)
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
  };

  // Declaration of React Hooks Start Here


  // Declaration of React Hooks End Here


  const futurePublicationRefresh = () => {
  }

  const openPreviewDialog = () => {
    setOpenPreview(true);
    setScroll("paper");
  };

  const openPreviewDialog2 = () => {
    if (datametricsCheckedValue.length) {
      let variableIds = datametricsCheckedValue.map((item) => item.variableId)
      setAuditVariableId(variableIds)
      setOpenPreview2(true);
      setScroll("paper");
    } else {
      toasterError("Please select atleast one variable")
    }
  };

  const toggleDrawerHistoric2 = () => {
    setOpen(true);
    setScroll("paper");
    setIsOverAllInfo(false)
  };

  const uploadFile = (data) => {
    setFileuploadLoading(true)
    let fileName
    if (data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      fileName = data.reportName + ".xlsx";
    }
    if (data.type === 'application/vnd.ms-powerpoint') {
      fileName = data.reportName + ".ppt"
    }
    if (data.type === 'application/pdf') {
      fileName = data.DisplayName
    }
    if (data.type === 'application/msword') {
      fileName = data.reportName + ".doc";
    }
    if (data.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      fileName = data.reportName + ".docx";
    }
    const myNewFile = new File([data], `${fileName}`, {
      type: data.type,
    });
    var formdata = new FormData();

    formdata.append("file", myNewFile);
    axios
      .post(
        process.env.React_App_baseUrl +
        `job/UploadFile`,
        formdata, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }
      )
      .then(function (response) {
        if (response.status === 200) {
          saveDraft("Report info updated successfully")
          toasterSuccess('File uploaded  successfully')
        }
        setReportInfoDataValue(reportInfoDataValueIntial)
        setFileuploadLoading(false)
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
        setFileuploadLoading(false)
      });
  };

  const getDataMetricsDefaultValue = () => {
    if (defaultSelectedAsset?.jobAssetId) {
      axios.get(process.env.React_App_baseUrl + 'updatejobrecord/GetLastVariableDetails', {
        headers: {
          jobAssetId: defaultSelectedAsset?.jobAssetId,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

        }
      }).then((res) => {
        let responseData = res.data;
        setReportInfoDataValue(responseData?.reportDetails.length ? responseData?.reportDetails : [intialReportValue])
        setReportInfoDataValueIntial(responseData?.reportDetails.length ? responseData?.reportDetails : [intialReportValue])
        setMainItem({ ...mainitem, frequencyId: responseData.frequencyId, frequency: responseData.frequencyName, lastQuarterId: responseData.lastUpdateQuaterId, lastQuarter: responseData.lastUpdateQuater, lastYearId: responseData.lastUpdateYearId ? responseData.lastUpdateYearId : null, lastYear: responseData.lastUpdateYear, reportingYearId: responseData.reportingYearId, reportingYear: responseData.reportingYear ? responseData.reportingYear : null, valuationType: responseData.valuationType ? responseData.valuationType : "Actual", valuationTypeId: responseData.valuationTypeId ? responseData.valuationTypeId : 2, reportingPeriodId: responseData.reportingPeriodId, reportingPeriod: responseData.reportingPeriod })
      }).catch((err) => {
        ErroHandling(err, function (res) {
          // toasterError(res)
        })

      })
    }

  }

  const deleteVariableFun = () => {
    if (confirmation) {
      axios.delete(process.env.React_App_baseUrl + 'updatejobrecord/DeleteVariable', {
        headers: {
          VariableId: deleteVariableId,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

        }
      }).then((res) => {
        toasterSuccess("Deleted Successfully")
        getHistoryData()
      }).catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      })
    }
  }

  const deleteVariableValueFun = (variableId, valuesId) => {
    axios.delete(process.env.React_App_baseUrl + 'updatejobrecord/DeleteVariableValues', {
      headers: {
        VariableId: variableId,
        ValuesId: valuesId,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

      }
    }).then((res) => {
      toasterSuccess("Updated Successfully")
      getHistoryData()
      setEditState({ ...editState, right: false });
    }).catch((err) => {
      setConfirmation(false)
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    })
  }

  const copyData = () => {
    if (selectedYear.length === 1) {
      axios.get(process.env.React_App_baseUrl + 'updatejobrecord/CopyVariableData', {
        headers: {
          jobAssetId: defaultSelectedAsset?.jobAssetId,
          yearId: selectedYear[0],
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

        }
      }).then((res) => {
        toasterSuccess("Copied Successfully")
      }).catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      })
    } else {
      toasterError("Please select any one year")
    }
  }

  const viewData = () => {
    if (selectedYear.length) {
      getHistoryData()
    } else {
      toasterError("Please select year")
    }
  }

  // Declaration of React Hooks Start Here
  // Declaration of React Hooks End Here

  const GetVariableDeatils = () => {
    if (defaultSelectedAsset?.jobAssetId) {
      setIsLoading(true)
      axios.get(process.env.React_App_baseUrl + 'updatejobrecord/GetVariableDetails', {
        headers: {
          sortColumn: sorting.sortBy,
          isAscending: sorting.isAscending,
          jobAssetId: defaultSelectedAsset?.jobAssetId,
          YearId: mainitem.reportingYearId ? mainitem.reportingYearId : 0,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

        }
      }).then((res) => {
        setIsLoading(false)
        setDatametricsCheckedValue([])
        setDatametricsAllChecked(false)
        setIsHistory(false)
        var responseData = res.data
        if (responseData.length) {
          let reqHeader = responseData[0].reportingYear.map(el => { return { publication: el.publication, reportingPeriodId: el.reportingPeriodId, reportingYearId: el.reportingYearId, reportingYear: el.reportingYear, reportingPeriod: el.reportingPeriod } })
          let bb = responseData.map((el, index) => {
            const publication = el.reportingYear.map(e => {
              return {
                [e.publication]: e,
              }
            });
            return {
              ...el,
              ...Object.assign({}, ...publication),
              indexId: index + 1
            };
          });
          setCompanyDeatis([...bb]);
          setCompanyHeader(reqHeader)
        } else {
          setCompanyDeatis([])
        }
      })
    } else {
      //console.log("Job Id not present")
    }
  }

  const getLastVariableAfterSave = () => {
    if (defaultSelectedAsset?.jobAssetId) {
      axios.get(process.env.React_App_baseUrl + 'updatejobrecord/GetLastVariableDetails', {
        headers: {
          jobAssetId: defaultSelectedAsset?.jobAssetId,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

        }
      }).then((res) => {
        let responseData = res.data;
        if (res.data) {
          setReportInfoDataValue(responseData?.reportDetails.length ? responseData?.reportDetails : [intialReportValue])
          setReportInfoDataValueIntial(responseData?.reportDetails.length ? responseData?.reportDetails : [intialReportValue])
          setMainItem({ ...mainitem, variableReport: "", variableReportId: null, unitReport: "", unitReportId: null, currency: "", currencyId: null, value: "", minValue: "", maxValue: "", comments: "", cruUnit: "", variableTypeName: "", cruVariable: "", frequencyId: responseData.frequencyId, frequency: responseData.frequencyName, lastQuarterId: responseData.lastUpdateQuaterId, lastQuarter: responseData.lastUpdateQuater, lastYearId: responseData.lastUpdateYearId ? responseData.lastUpdateYearId : null, lastYear: responseData.lastUpdateYear, reportingYearId: responseData.reportingYearId, reportingYear: responseData.reportingYear ? responseData.reportingYear : null, valuationType: responseData.valuationType ? responseData.valuationType : "Actual", valuationTypeId: responseData.valuationTypeId ? responseData.valuationTypeId : 2, reportingPeriodId: responseData.reportingPeriodId, reportingPeriod: responseData.reportingPeriod })
        }
      }).catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      })
    }
  }

  const getHistoryData = () => {
    if (selectedYear.length) {
      axios.get(process.env.React_App_baseUrl + 'updatejobrecord/GetVariableHistoryDetails', {
        headers: {
          sortColumn: sorting.sortBy,
          isAscending: sorting.isAscending,
          jobAssetId: defaultSelectedAsset?.jobAssetId,
          yearId: selectedYear.length ? selectedYear : [mainitem.lastYearId],
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

        }
      }).then((res) => {
        setDatametricsCheckedValue([])
        setDatametricsAllChecked(false)
        setIsHistory(true)
        var responseData = res.data
        if (responseData.length) {
          let reqHeader = responseData[0].reportingYear.map(el => { console.log(el); return { publication: el.publication, reportingPeriodId: el.reportingPeriodId, reportingYearId: el.reportingYearId, reportingYear: el.reportingYear, reportingPeriod: el.reportingPeriod, isValueChanged: el.isValueChanged } })
          let bb = responseData.map((el, index) => {
            const publication = el.reportingYear.map(e => {
              return {
                [e.publication]: e,
              }
            });
            return {
              ...el,
              ...Object.assign({}, ...publication),
              indexId: index + 1
            };
          });
          setCompanyDeatis([...bb]);
          setCompanyHeader(reqHeader)
        } else {
          setCompanyDeatis([])
        }
      })
    } else {
      GetVariableDeatils()
    }

  }

  const addReport = () => {
    let data = [...reportInfoDataValue]
    data.push(intialReportValue)
    setReportInfoDataValue(data)
  }

  const removeReport = (index) => {
    let data = [...reportInfoDataValue];
    data.splice(index, 1);
    setReportInfoDataValue(data);
  }

  const openDocument = (file, pageNumber = 1) => {
    if (pageNumber === "") {
      pageNumber = 1;
    }
    var jobdetails = tempData?.state
    var reportName = file + ".pdf"
    var senData = { jobId: jobdetails.jobIds, fileName: reportName, pageNumber: Number(pageNumber), crudState: "Edit" }
    localStorage.setItem("filePreview", JSON.stringify(senData));
    //CommonService.AddTokenFromlocalStorageToLocalStorage();
    window.open("#app/data-collections/file-preview", '_blank', 'noopener,noreferrer');
  }

  const cancelPopUp = () => {
    setSwapValues({ reportingPeriodId: null, reportingPeriod: "" })
    setIsOpenSwap(false);

  }

  const openDocumentForView = (file, pageNumber) => {
    if (file !== null && file !== undefined) {
      let jobdetails = tempData?.state
      let reportName = file.endsWith(".pdf") ? file : file + ".pdf";
      let senData = { jobId: jobdetails.jobIds, fileName: reportName, pageNumber: pageNumber, crudState: "View" }
      localStorage.setItem("filePreview", JSON.stringify(senData));
     // CommonService.AddTokenFromSessionStorageToLocalStorage();
      window.open("#app/data-collections/file-preview", '_blank', 'noopener,noreferrer');
    }

  }

  const openPopover = (event, mesg) => {
    setComment({ message: mesg });
    setSOpen(event.currentTarget);
  };

  //Method for close popup
  const handleClose = () => {
    setSOpen(null);
  };

  const openVariableReportFun = () => {
    setCrudModeForVariable(true)
  }

  const openUnitReportFun = () => {
    setCrudModeForUnit(true)
  }

  //Method for duplicate variable check 
  const VariableCheck = (item) => {
    axios.get(process.env.React_App_baseUrl + 'updatejobrecord/CheckDuplicationVarialbeReport', {
      headers: {
        jobAssetId: defaultSelectedAsset?.jobAssetId,
        VariableReportId: mainitem.variableReportId,
        ValuationTypeId: mainitem.valuationTypeId,
        AssetVariableId: mainitem.assetVariableId ? mainitem.assetVariableId : 0,
        UnitReportId: item.value,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

      }
    }).then((res) => {
      if (res.data) {
        toasterError("Unit already exists")
        setMainItem({
          ...mainitem,
          cruUnit: "",
          unitReport: "",
          unitReportId: null,
          isManual: item?.isManual
        });
      } else {
        setMainItem({
          ...mainitem,
          cruUnit: item?.cruUnitName ? item?.cruUnitName : "",
          unitReport: item.label,
          unitReportId: item.value,
          isManual: item.isManual
        });
      }
    }).catch((err) => {
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    })
  }

  const cancelSubmit = () => {
    history.push('/app/data-collections/manage-job-records')
  }

  return (
    <Box>
      {crudModeForVariable ? <VariableReportSideDrawer crudModeForVariable={crudModeForVariable} setCrudModeForVariable={setCrudModeForVariable} setDefaultVariableValue={setDefaultVariableValue} setDefaultVaribaleCRUValue={setDefaultVaribaleCRUValue} updateVariableRefresh={() => {
        setVariableRefresh(!variableRefresh);
      }} /> : ''}
      {crudModeForUnit ? <AddUnitReportSideDrawer crudModeForUnit={crudModeForUnit} setCrudModeForUnit={setCrudModeForUnit} cruRefresh={cruRefresh} setDefaultUnitValue={setDefaultUnitValue} updateUnitReportRefresh={() => {
        setCruRefresh(!cruRefresh);
      }} /> : ''}
      {crudModeForCreatePublication ? <CreatePublicationDetailsSideDrawer crudModeForCreatePublication={crudModeForCreatePublication} setCrudModeForCreatePublication={setCrudModeForCreatePublication} /> : ''}

      <Grid container maxWidth>
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Update Records</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon
                style={{ fontSize: "10px", marginLeft: "3px" }}
              />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/create-jobs" }}>Job Management</Link>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/manage-jobs" }}>Manage Jobs</Link>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/manage-job-records" }}>Manage Job Records</Link>
            <Typography color="text.primary">Update Records</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Grid container spacing={{ xs: 1, sm: 2 }} alignItems="center" justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" color={"secondary"}
                onClick={(e) => {
                  if (pageIndex < assetInfo.assetList.length) {
                    setPageIndex(pageIndex + 1)
                    setautoSelectedAssetDropdown((assetInfo.assetList.length - 1) - (pageIndex + 1))
                    setPage(1)
                  }
                }}
                disabled={assetInfo && pageIndex === assetInfo.assetList.length - 1 ? true : false}
                style={{ 'background-color': assetInfo && pageIndex === assetInfo.assetList.length - 1 ? '#EBFBFF' : '#DB9400' }}
                startIcon={
                  <ChevronLeftIcon style={{ fontSize: 15 }} />
                }>
                Prev
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color={"secondary"}
                onClick={(e) => {
                  if (pageIndex !== 0) {
                    setPageIndex(pageIndex - 1)
                    setautoSelectedAssetDropdown((assetInfo.assetList.length - 1) - (pageIndex - 1))
                    setPage(1)
                  }
                }}
                style={{ 'background-color': pageIndex === 0 ? '#EBFBFF' : '#DB9400' }}
                endIcon={
                  <ChevronRightIcon style={{ fontSize: 15 }} />
                }
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BaseLayout>
            <ScrollingDialog
              open={open}
              setOpen={setOpen}
              scroll={scroll}
              setScroll={setScroll}
              dialogClass="darkTitle"
              dialogTitle="Historic Data"
              dialogContent={
                <HistoricData
                  historyData={historyData}
                  historyColumns={historyColumns}
                  reportData={reportData}
                  variableReportId={variableReportId}
                  isOverallInfo={isOverallInfo}
                  jobAssetId={jobAssetId}
                  jobID={tempData?.state?.jobIds}
                />
              }
            />
            <ScrollingDialog
              open={openPreview}
              setOpen={setOpenPreview}
              scroll={scroll}
              setScroll={setScroll}
              dialogClass="darkTitle"
              dialogTitle="Preview"
              dialogContent={
                <PreviewUpdateRecord
                  previewAssetInfo={previewAssetInfo}
                  jobAssetId={defaultSelectedAsset?.jobAssetId}
                  reportInfo={reportInfo}
                  assetVariableInfo={assetVariableInfo}
                  segComments={segComments}
                  linkToCat={linkToCat}
                  openDocumentForView={openDocumentForView}
                  linkToAssetPForm={linkToAssetPForm}
                  linkToEat={linkToEat}
                  mainitem={mainitem}
                  jobID={tempData?.state?.jobIds}
                  isAssetSegment={isAssetSegment}
                  defaultSelectedAsset={defaultSelectedAsset}
                  assetVariablecount={assetVariablecount}
                  previewReportInfo={previewReportInfo}
                  attributeValue={selectedAssetInfo.attributable}
                  companyHeader={companyHeader}
                />
              }
            />
            <ScrollingDialog
              open={openPreview2}
              setOpen={setOpenPreview2}
              scroll={scroll}
              setScroll={setScroll}
              dialogClass="darkTitle"
              dialogTitle="Data Metrics"
              dialogContent={
                <DataMetrics
                  variableReportId={variableReportId}
                  assetVariableId={assetVariableId}
                  auditVariableId={auditVariableId}
                  assetVariableInfo={assetVariableInfo}
                />
              }
            />

            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.jobShortDetails}
              sx={{ mb: 2 }}
            >
              {/* Job Short Details Start Here */}
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                    <div className={classes.shortDetails}>
                      Job ID:{" "}
                      <span className={classes.boldText}>
                        {tempData?.state?.jobNo}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <div className={classes.shortDetails}>
                      Job Type:{" "}
                      <span className={classes.boldText}>
                        {tempData?.state?.jobType}
                      </span>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
                    <div className={classes.shortDetails}>
                      Job Name:{" "}
                      <span className={classes.boldText}>
                        {tempData?.state?.jobName}
                      </span>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                    <div className={classes.shortDetails}>
                      Priority:&nbsp;
                      {tempData?.state?.priorityName === "High" ? (
                        <Typography className={classes.twoValues} color="error">
                          {" "}<span className={classes.boldText}>{tempData?.state?.priorityName}</span>
                        </Typography>) : undefined}{" "}
                      {tempData?.state?.priorityName === "Low" ? (
                        <Typography className={classes.twoValues} color="orange">
                          {" "}<span className={classes.boldText}>{tempData?.state?.priorityName}</span>
                        </Typography>) : undefined}{" "}
                      {tempData?.state?.priorityName === "Medium" ? (
                        <Typography className={classes.twoValues} color="blue">
                          {" "}<span className={classes.boldText}>{tempData?.state?.priorityName}</span>
                        </Typography>) : undefined}
                      {tempData?.state?.priorityName === "Critical" ? (
                        <Typography className={classes.twoValues} color="red">
                          {" "}<span className={classes.boldText}>{tempData?.state?.priorityName}</span>
                        </Typography>) : undefined}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <div className={classes.shortDetails}>TAT: <span className={classes.boldText}>{tempData?.state?.tat}</span></div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={7} xl={7}>
                    <div className={classes.shortDetails}>
                      Job Instruction:{" "}
                      <span className={classes.boldText}>
                        {tempData?.state?.jobInstructions}
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* Job Short Details End Here */}
            </Grid>

            {/* Asset Info Details */}
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={12} md={3} lg={4} xl={4} sx={{ mb: -2 }}>
                <h4 className={classes.headingText}>Asset Info</h4>
              </Grid>

              <Grid item xs={12} sm={12} md={9} lg={8} xl={8}>
                <Grid container spacing={2} justifyContent="right">
                  <Grid item xs={12} sm={"auto"}>
                    <Button
                      fullWidth
                      className={classes.largeButtonAuto}
                      variant="contained"
                      color="accent6"
                      size="small"
                      startIcon={
                        <ComplianceAuditIcon style={{ fontSize: 16 }} />
                      }
                      onClick={openPreviewDialog}
                    >
                      Preview
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={"auto"}>
                    <Button
                      fullWidth
                      className={classes.largeButtonAuto}
                      variant="contained"
                      color="accent7"
                      size="small"
                      startIcon={<InfoIcon style={{ fontSize: 16 }} />}
                      onClick={() => {
                        setCrudModeForCreatePublication(true);
                      }}
                    >
                      Future Publication Info
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={"auto"}>
                    <Button
                      fullWidth
                      className={classes.largeButtonAuto}
                      variant="contained"
                      color="secondary"
                      size="small"
                      startIcon={<TimeEntryIcon style={{ fontSize: 16 }} />}
                      onClick={toggleDrawerHistoric2}
                    >
                      Historic Data
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* Asset Info Details Start Here */}
              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  className={classes.posRelative}
                  sx={{ p: 2, pb: 3 }}
                >
                  <Grid container spacing={2} justifyContent="left" alignItems="center">
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                      <TextField
                        id="standard-basic"
                        label="Company"
                        disabled
                        value={companyName}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={2}>
                      <TextField
                        id="standard-basic"
                        label="Attributable %"
                        value={selectedAssetInfo.attributable}
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        variant="standard"
                        onChange={(e, value) => {
                          setSelectedAssetInfo({
                            ...selectedAssetInfo,
                            attributable: e.target.value
                          })
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                      <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        options={
                          assetInfo && assetInfo.assetList ? assetInfo.assetList : []
                        }
                        value={defaultSelectedAsset}
                        getOptionLabel={(option) => option.asset}
                        onChange={(e, value) => {
                          getAssetDetails(value.jobAssetId)
                          getAssetStatus(value.jobAssetId)
                          setDefaultSelectedAsset(value)
                          setPreviewAssetInfo({
                            ...previewAssetInfo,
                            selectedAsset: value.asset,
                          });
                          setReportData(value);
                          setSelectedAssetInfo(value);
                        }}

                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Asset"
                            variant="standard"
                            error={assetInfoError}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                      <FormGroup>
                        <FormControlLabel
                          style={{ marginTop: "20px", marginBottom: "0" }}
                          control={
                            <Checkbox
                              onChange={(e) => {
                                setIsAssetSegment(e.target.checked)
                              }}
                              checked={isAssetSegment}
                            />
                          }
                          label="Asset is a segment"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                      <Typography variant="caption" display="block" gutterBottom>
                        Commodity
                      </Typography>
                      <Typography variant="body1">
                        {assetDetail && assetDetail.commodities
                          ?
                          <Tooltip title={assetDetail.commodities} placement="top" arrow>
                            <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "99%", display: "inline-block" }}>{assetDetail.commodities}</span>
                          </Tooltip>
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={2}>
                      <Typography variant="caption" display="block" gutterBottom>
                        Country
                      </Typography>
                      <Typography variant="body1">
                        {assetDetail && assetDetail.country
                          ? assetDetail.country
                          : "-"}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                      <Typography variant="caption" display="block" gutterBottom>
                        Region
                      </Typography>
                      <Typography variant="body1">
                        {assetDetail && assetDetail.region ? assetDetail.region : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                      <Typography variant="caption" display="block" gutterBottom>
                        Sub-Region
                      </Typography>
                      <Typography variant="body1">
                        {assetDetail && assetDetail.subRegion
                          ? assetDetail.subRegion
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <TextField
                        id="standard-basic"
                        label="Segment Comments"
                        multiline
                        fullWidth
                        variant="standard"
                        value={segComments}
                        onChange={(e) => setSegComments(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          alignItems="self-end"
                        >
                          <Grid item xs>
                            <TextField
                              id="standard-basic"
                              label="Link to CAT"
                              disabled
                              fullWidth
                              value={linkToCat}
                              variant="standard"
                              onChange={(e) => setLinktoCat(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs="auto">
                            <Tooltip title="Open Link" placement="top" arrow>
                              <IconButton color="secondary">
                                <LinkIcon
                                  onClick={() => { if (linkToCat) window.open(linkToCat, "_blank") }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          alignItems="self-end"
                        >
                          <Grid item xs>
                            <TextField
                              id="standard-basic"
                              label="Link to Asset Platform"
                              fullWidth
                              disabled
                              value={linkToAssetPForm}
                              variant="standard"
                              onChange={(e) => setlinkToAssetPform(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs="auto">
                            <Tooltip title="Open Link" placement="top" arrow>
                              <IconButton color="secondary">
                                <LinkIcon
                                  onClick={() => {
                                    if (linkToAssetPForm)
                                      window.open(linkToAssetPForm, "_blank")
                                  }
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="top"
                          alignItems="self-end"
                        >
                          <Grid item xs>
                            <TextField
                              id="standard-basic"
                              label="Link to EAT"
                              value={linkToEat}
                              fullWidth
                              disabled
                              variant="standard"
                              onChange={(e) => setLinktoEat(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs="auto">
                            <Tooltip title="Open Link" placement="top" arrow>
                              <IconButton color="secondary">
                                <LinkIcon
                                  onClick={() => {
                                    if (linkToEat)
                                      window.open(linkToEat, "_blank")
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Asset Info Details End Here */}

              {/* Report Info Details Start Here */}

              <ReportInfo
                reportInfoData={reportInfo}
                setReportInfoDataValue={setReportInfoDataValue}
                reportInfoDataValueIntial={reportInfoDataValueIntial}
                companyData={tempData?.state}
                reportInfoError={reportInfoError}
                reportFound={reportFound}
                fileuploadLoading={fileuploadLoading}
                editAccess={editAccess}
                GetVariableDeatils={GetVariableDeatils}
                uploadFiles={(e) => {
                  uploadFile(e);
                }}
                updateCall={() => {
                  saveDraft("Report Info added successfully")
                }}
                reportDeleteRefreshvalue={reportDeleteRefresh}
                setreportDeleteRefresh={() => {
                  setreportDeleteRefresh(!reportDeleteRefresh)
                }}
                setReportInfoError={(e) => {
                  setReportInfoError(e);
                }}
                setReportInfo={(e) => {
                  setReportInfo(e);
                }}
                setRefreshReportInfo={(e) => {
                  setRefreshReportInfo(e)
                }
                }
              />

              {/* Report Info Details End Here */}

              {/* Data Metrics Details Start Here */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid
                  container
                  spacing={3}
                  justifyContent="left"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    sx={{ mt: 0, mb: -2 }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      lg={3}
                      justifyContent="left"
                      alignItems="center"
                    >
                      <h4 className={classes.headingText}>Data Metrics</h4>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper
                      elevation={0}
                      className={classes.posRelative}
                      sx={{ p: 2, pb: 3 }}
                    >
                      <Box className={classes.setBorder2}>
                        <Grid
                          container
                          spacing={3}
                          justifyContent="left"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <Typography className={classes.subHeadingText}>
                              Variable
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={dropDown.frequency}
                              value={mainitem?.frequency}
                              fullWidth
                              onChange={(e, item) => {
                                if (item) {
                                  setMainItem({
                                    ...mainitem,
                                    frequency: item.label,
                                    frequencyId: item.value,
                                  });
                                } else {
                                  setMainItem({
                                    ...mainitem,
                                    frequency: "",
                                    frequencyId: 0,
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Frequency"
                                  required
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={dropDown.year}
                              value={mainitem?.lastYear}
                              fullWidth
                              onChange={(e, item) => {
                                if (item) {
                                  setMainItem({
                                    ...mainitem,
                                    lastYear: item.label,
                                    lastYearId: item.value,
                                  });
                                } else {
                                  setMainItem({
                                    ...mainitem,
                                    lastYear: "",
                                    lastYearId: null,
                                  });
                                }

                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Last Update Year"
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={dropDown.reportingPeroid}
                              value={mainitem?.lastQuarter}
                              fullWidth
                              onChange={(e, item) => {
                                if (item) {
                                  setMainItem({
                                    ...mainitem,
                                    lastQuarter: item.label,
                                    lastQuarterId: item.value,
                                  });
                                } else {
                                  setMainItem({
                                    ...mainitem,
                                    lastQuarter: "",
                                    lastQuarterId: null,
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Last Update Quarter"
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={dropDown.valuation}
                              value={mainitem?.valuationType}
                              fullWidth
                              onChange={(e, item) => {
                                setMainItem({
                                  ...mainitem,
                                  valuationType: item.label,
                                  valuationTypeId: item.value,
                                  variableReport: "",
                                  variableReportId: null
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  label="Valuation"
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={dropDown.year}
                              value={mainitem?.reportingYear}
                              fullWidth
                              onChange={(e, item) => {
                                if (item) {
                                  setMainItem({
                                    ...mainitem,
                                    reportingYear: item.label,
                                    reportingYearId: item.value,
                                  });
                                }
                                else {
                                  setMainItem({
                                    ...mainitem,
                                    reportingYear: "",
                                    reportingYearId: null,
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  label="Report Year"
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={dropDown.reportingPeroid}
                              value={mainitem?.reportingPeriod}
                              fullWidth
                              onChange={(e, item) => {
                                if (item) {
                                  setMainItem({
                                    ...mainitem,
                                    reportingPeriod: item.label,
                                    reportingPeriodId: item.value,
                                  });
                                }
                                else {
                                  setMainItem({
                                    ...mainitem,
                                    reportingPeriod: "",
                                    reportingPeriodId: null,
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  label="ReportingPeroid"
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="self-end"
                              >
                                <Grid item xs>
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={dropDown.variable}
                                    value={mainitem?.variableReport}
                                    fullWidth
                                    onChange={(el, item) => {
                                      if (item) {
                                        setMainItem({
                                          ...mainitem,
                                          variableTypeName: item.variableTypeName ? item.variableTypeName : "",
                                          cruVariable: item.cruVariableName ? item.cruVariableName : "",
                                          variableReport: item.label,
                                          variableReportId: item.value,
                                          isManual: item.isManual
                                        });
                                      } else {
                                        setMainItem({
                                          ...mainitem,
                                          cruVariable: "",
                                          variableTypeName: "",
                                          variableReport: "",
                                          variableReportId: null,
                                          isManual: item?.isManual
                                        });
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Variable (Report)"
                                        variant="standard"
                                        required
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs="auto">
                                  <Tooltip
                                    title="Add Variable (Report)"
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      color="secondary"
                                      onClick={() => { openVariableReportFun() }}
                                    >
                                      <CirclePlusIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="self-end"
                              >
                                <Grid item xs>
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={dropDown.unit}
                                    disabled={mainitem.variableReport ? false : true}
                                    value={mainitem?.unitReport}
                                    style={{ color: mainitem.isManual ? "white" : "orange" }}
                                    fullWidth
                                    onChange={(el, item) => {
                                      if (item) {
                                        VariableCheck(item)
                                      } else {
                                        setMainItem({
                                          ...mainitem,
                                          cruUnit: "",
                                          unitReport: "",
                                          unitReportId: null,
                                          isManual: item?.isManual
                                        });
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Unit (Report)"
                                        variant="standard"
                                        required
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs="auto">
                                  <Tooltip
                                    title="Add Unit (Report)"
                                    placement="top"
                                    required
                                    arrow
                                  >
                                    <IconButton
                                      color="secondary"
                                      onClick={() => { openUnitReportFun() }}
                                    >
                                      <CirclePlusIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={dropDown.currency}
                              value={mainitem?.currency}
                              fullWidth
                              onChange={(e, item) => {
                                if (item) {
                                  setMainItem({
                                    ...mainitem,
                                    currency: item.label,
                                    currencyId: item.value,
                                  });
                                }
                                else {
                                  setMainItem({
                                    ...mainitem,
                                    currency: "",
                                    currencyId: null,
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Currency"
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>

                          {mainitem.valuationTypeId !== 4 ?
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                              <div className='d-flex'>
                                <Typography sx={{ color: "#898b8c", fontSize: "14px" }}>Value&nbsp;</Typography><Typography sx={{ color: "red" }}> *</Typography>
                              </div>
                              <CurrencyFormat
                                style={{ outline: "none", width: "100%", border: "none", borderBottom: "solid 1px #dee0e3" }}
                                id="standard-basic"
                                value={mainitem?.value}
                                placeholder="Value"
                                onChange={(e) => {
                                  setMainItem({
                                    ...mainitem,
                                    value: e.target.value,
                                    maxValue: "",
                                    minValue: ""
                                  });
                                }}
                                variant="standard"
                                thousandSeparator={true}
                              />
                            </Grid> : <>
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={2}>
                                <div className='d-flex'>
                                  <Typography sx={{ color: "#898b8c", fontSize: "14px" }}>Min Value&nbsp;</Typography><Typography sx={{ color: "red" }}> *</Typography>
                                </div>
                                <CurrencyFormat
                                  style={{ outline: "none", width: "100%", border: "none", borderBottom: "solid 1px #dee0e3" }}
                                  id="standard-basic"
                                  value={mainitem?.minValue}
                                  placeholder="Min Value"
                                  onChange={(e) => {
                                    setMainItem({
                                      ...mainitem,
                                      minValue: e.target.value,
                                      value: ""
                                    });
                                  }}
                                  variant="standard"
                                  thousandSeparator={true}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={2}>

                                <div className='d-flex'>
                                  <Typography sx={{ color: "#898b8c", fontSize: "14px" }}>Max Value&nbsp;</Typography>
                                </div>
                                <CurrencyFormat
                                  style={{ outline: "none", width: "100%", border: "none", borderBottom: "solid 1px #dee0e3" }}
                                  id="standard-basic"
                                  value={mainitem?.maxValue}

                                  placeholder="Max Value"
                                  onChange={(e) => {
                                    setMainItem({
                                      ...mainitem,
                                      maxValue: e.target.value,
                                      value: ""
                                    });
                                  }}
                                  variant="standard"
                                  thousandSeparator={true}
                                />
                              </Grid>
                            </>}
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="self-end"
                              >
                                <Grid item xs>
                                  <TextField
                                    id="standard-multiline-static"
                                    label="Variable (CRU)"
                                    value={mainitem.cruVariable ? mainitem.cruVariable : ""}
                                    disabled
                                    variant="standard"
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="self-end"
                              >
                                <Grid item xs>
                                  <TextField
                                    id="standard-multiline-static"
                                    label="Unit (CRU)"
                                    value={mainitem.cruUnit ? mainitem.cruUnit : ""}
                                    disabled
                                    variant="standard"
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="self-end"
                              >
                                <Grid item xs>
                                  <TextField
                                    id="standard-multiline-static"
                                    label="Variable (Type)"
                                    value={mainitem.variableTypeName ? mainitem.variableTypeName : ""}
                                    disabled
                                    variant="standard"
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                            <TextField
                              id="standard-multiline-static"
                              label="Comment"
                              multiline
                              maxRows={2}
                              value={mainitem.comments}
                              onChange={(e) => {
                                setMainItem({
                                  ...mainitem,
                                  comments: e.target.value,
                                });
                              }}
                              variant="standard"
                              fullWidth
                            />
                          </Grid>



                          <Grid item xs={12} sx={{ mt: 0, mb: -1 }}
                          >
                            <Paper elevation={0} className={classes.recordTitleArea}>
                              <Box className={classes.recordTitle}>Reports</Box>
                              <Box className={classes.recordLine}></Box>
                            </Paper>
                          </Grid>
                          {reportInfoDataValue.map((el, index) => {
                            return (
                              <>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
                                  <Box sx={{ flexGrow: 1 }}>
                                    <Grid
                                      container
                                      spacing={1}
                                      justifyContent="center"
                                      alignItems="self-end"
                                    >
                                      <Grid item xs>
                                        <Autocomplete
                                          disablePortal
                                          id="combo-box-demo"
                                          options={reportInfo}
                                          fullWidth
                                          value={{ reportName: el.reportName }}
                                          getOptionLabel={(option) => option.reportName}
                                          onChange={(e, value) => {
                                            let recivedData = reportInfoDataValue
                                            recivedData[index].reportName = value ? value.reportName : "";
                                            recivedData[index].reportingId = value ? value.assetReportId : 0
                                            setReportInfoDataValue([...recivedData]);
                                          }}
                                          renderInput={(params) => (
                                            <TextField {...params} label="Report Name" required variant="standard" />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs="auto">
                                        <Button
                                          variant="contained"
                                          color="accent5"
                                          size="small"
                                          onClick={() => {
                                            openDocument(el.reportName, el?.pageNumber)

                                          }}
                                          startIcon={
                                            <OpenEnrollmentIcon
                                              style={{ fontSize: 16 }}
                                            />
                                          }
                                        >
                                          Open Document
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                                  <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={1} justifyContent="center" alignItems="self-end" >
                                      <Grid item xs>
                                        <TextField
                                          id="standard-basic"
                                          label="Page No"
                                          value={el?.pageNumber}
                                          minValue={1}
                                          onChange={(e) => {
                                            if (e.target.value === '' || e.target.value > 0) {
                                              let recivedData = reportInfoDataValue
                                              recivedData[index].pageNumber = e.target.value === '' ? null : Number(e.target.value)
                                              setReportInfoDataValue([...recivedData]);
                                            }

                                          }}
                                          fullWidth
                                          required
                                          variant="standard"
                                        />
                                      </Grid>
                                      {reportInfoDataValue.length > 1 ?
                                        <Grid item xs="auto">
                                          <Tooltip title="Delete All" placement="top" arrow>
                                            <IconButton color="error">
                                              <DeleteIcon
                                                style={{ fontSize: "20px" }} onClick={() => {
                                                  removeReport(index)
                                                }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Grid> : ''}
                                    </Grid>
                                  </Box>
                                </Grid>
                              </>
                            )
                          })}
                          {editAccess ?
                            <Grid item xs={12} md={12} lg={12} xl={4} textAlign="right" >
                              <Button
                                variant="contained"
                                color="accent7"
                                size="small"
                                onClick={() => { addReport() }}
                                startIcon={<AddIcon style={{ fontSize: 16 }} />}
                              >
                                Add Report
                              </Button>
                            </Grid> : null}
                        </Grid>
                      </Box>
                      <Box>
                        <Grid
                          container
                          spacing={3}
                          justifyContent="left"
                          alignItems="center"
                        >

                          {editAccess ?
                            <Grid item xs={12} textAlign="right" >
                              <LoadingButton
                                buttonName="Add Variable"
                                clickHandler={() => { setLoading(true); saveDraft("Variable added successfully") }}
                                isLoading={loading}
                              />
                            </Grid> : null}
                          <Grid item xs={12} sx={{ mt: 1, mb: -3 }}
                          >
                            <Paper elevation={0} className={classes.recordTitleArea}>
                              <Box className={classes.recordTitle}>Overall Details</Box>
                              <Box className={classes.recordLine}></Box>
                              <Box className={classes.recordAction}>
                              </Box>
                            </Paper>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                            <MultiSelect
                              items={dropDown.year}
                              selectAllLabel={{ label: "Select all" }}
                              onChange={(value) => {
                                if (value.length) {
                                  setSelectedYear(value.map((el) => el.value));
                                } else {
                                  setSelectedYear([])
                                  GetVariableDeatils()
                                }
                              }}
                              getOptionLabel={"label"}
                              dropLable={"label"}
                              role="year"
                              label="Year"
                            />

                          </Grid>
                          <Grid item xs={12} sm={12} md={9} lg={5} xl={4}>
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="flex-start"
                                alignItems="self-end"
                              >
                                {editAccess ?
                                  <Grid item sm="auto">
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      onClick={() => { copyData() }}
                                      startIcon={
                                        <CopyIcon
                                          style={{ fontSize: 16 }}
                                        />
                                      }
                                    >
                                      Copy Data
                                    </Button>
                                  </Grid> : null}
                                <Grid item sm="auto">
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={() => { viewData() }}
                                    startIcon={
                                      <EntryViewIcon
                                        style={{ fontSize: 16 }}
                                      />
                                    }
                                  >
                                    View Data
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>

                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={1} xl={4} spacing={{ xs: 0, xl: 1 }} textAlign="right">
                            <Tooltip title="Audit History" placement="top" arrow>
                              <IconButton color="accent6">
                                <TimeEntryIcon
                                  onClick={openPreviewDialog2}
                                  style={{ fontSize: "20px" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12}>
                            <Box className={classes.setBorder}>
                              <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell padding="checkbox" className={classes.tableHeader + ' ' + classes.stickyCheckboxTop}
                                        style={{ width: "40px", whiteSpace: "nowrap" }}>
                                        <Checkbox
                                          color="primary"
                                          checked={datametricsAllChecked}
                                          onChange={(event) => {
                                            handleChangeCheckBoxAll(event)
                                          }}

                                        />
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={classes.cruTheadLeftSep + ' ' + classes.stickyYearTop}
                                        style={{ minWidth: "35px", whiteSpace: "nowrap" }}
                                        sortDirection={(sorting.sortBy === "LastUpdateYear") ? sorting.isAscending : false}
                                      >
                                        {/* <TableSortLabel
                                          active={(sorting.sortBy === "LastUpdateYear")}
                                          direction={(sorting.sortBy === "LastUpdateYear") ? sorting.isAscending ? "asc" : "desc" : "asc"}
                                          onClick={e => {
                                            handleRequestSort(e, "LastUpdateYear")
                                          }}
                                        > */}
                                        Order
                                        {/* </TableSortLabel> */}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.cruTheadLeftSep + ' ' + classes.stickyYearTop}
                                        style={{ minWidth: "69px", whiteSpace: "nowrap" }}
                                        sortDirection={(sorting.sortBy === "LastUpdateYear") ? sorting.isAscending : false}
                                      >
                                        <TableSortLabel
                                          active={(sorting.sortBy === "LastUpdateYear")}
                                          direction={(sorting.sortBy === "LastUpdateYear") ? sorting.isAscending ? "asc" : "desc" : "asc"}
                                          onClick={e => {
                                            handleRequestSort(e, "LastUpdateYear")
                                          }}
                                        >
                                          LUY
                                        </TableSortLabel>
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.cruTheadLeftSep + ' ' + classes.stickyLUQTop}
                                        style={{ minWidth: "69px", whiteSpace: "nowrap" }}
                                        sortDirection={(sorting.sortBy === "LastUpdateQuater") ? sorting.isAscending : false}
                                      >
                                        <TableSortLabel
                                          active={(sorting.sortBy === "LastUpdateQuater")}
                                          direction={(sorting.sortBy === "LastUpdateQuater") ? sorting.isAscending ? "asc" : "desc" : "asc"}
                                          onClick={e => {
                                            handleRequestSort(e, "LastUpdateQuater")
                                          }}
                                        >
                                          LUQ
                                        </TableSortLabel>
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.cruTheadLeftSep + ' ' + classes.stickyVRTop}
                                        style={{ minWidth: "156px", width: "150px", whiteSpace: "nowrap" }}
                                        sortDirection={(sorting.sortBy === "variableReport") ? sorting.isAscending : false}
                                      >
                                        <TableSortLabel
                                          active={(sorting.sortBy === "VariableReport")}
                                          direction={(sorting.sortBy === "VariableReport") ? sorting.isAscending ? "asc" : "desc" : "asc"}
                                          onClick={e => {
                                            handleRequestSort(e, "VariableReport")
                                          }}
                                        >
                                          Variable (Report)
                                        </TableSortLabel>
                                      </TableCell>

                                      <TableCell
                                        align="left"
                                        className={classes.cruTheadLeftSep + ' ' + classes.stickyURTop}
                                        style={{ minWidth: "132px", whiteSpace: "nowrap" }}
                                        sortDirection={(sorting.sortBy === "UnitReport") ? sorting.isAscending : false}
                                      >
                                        <TableSortLabel
                                          active={(sorting.sortBy === "UnitReport")}
                                          direction={(sorting.sortBy === "UnitReport") ? sorting.isAscending ? "asc" : "desc" : "asc"}
                                          onClick={e => {
                                            handleRequestSort(e, "UnitReport")
                                          }}
                                        >
                                          Unit (Report)
                                        </TableSortLabel>
                                      </TableCell>

                                      <TableCell
                                        align="left"
                                        className={classes.cruTheadLeftSep + ' ' + classes.stickyVCTop}
                                        style={{ minWidth: "139px", whiteSpace: "nowrap" }}
                                        sortDirection={(sorting.sortBy === "VariableCru") ? sorting.isAscending : false}
                                      >
                                        <TableSortLabel
                                          active={(sorting.sortBy === "VariableCru")}
                                          direction={(sorting.sortBy === "VariableCru") ? sorting.isAscending ? "asc" : "desc" : "asc"}
                                          onClick={e => {
                                            handleRequestSort(e, "VariableCru")
                                          }}
                                        >
                                          Variable (CRU)
                                        </TableSortLabel>
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.cruTheadLeftSep + ' ' + classes.stickyUCTop}
                                        style={{ minWidth: "113px", whiteSpace: "nowrap" }}
                                        sortDirection={(sorting.sortBy === "UnitCru") ? sorting.isAscending : false}
                                      >
                                        <TableSortLabel
                                          active={(sorting.sortBy === "UnitCru")}
                                          direction={(sorting.sortBy === "UnitCru") ? sorting.isAscending ? "asc" : "desc" : "asc"}
                                          onClick={e => {
                                            handleRequestSort(e, "UnitCru")
                                          }}
                                        >
                                          Unit (CRU)
                                        </TableSortLabel>
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className={classes.cruTheadLeftSep}
                                        style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                                        sortDirection={(sorting.sortBy === "Currency") ? sorting.isAscending : false}
                                      >
                                        <TableSortLabel
                                          active={(sorting.sortBy === "Currency")}
                                          direction={(sorting.sortBy === "Currency") ? sorting.isAscending ? "asc" : "desc" : "asc"}
                                          onClick={e => {
                                            handleRequestSort(e, "Currency")
                                          }}
                                        >
                                          Currency
                                        </TableSortLabel>
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.cruTheadLeftSep}
                                        style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                                        sortDirection={(sorting.sortBy === "variableTypeName") ? sorting.isAscending : false}
                                      >
                                        <TableSortLabel
                                          active={(sorting.sortBy === "VariableTypeName")}
                                          direction={(sorting.sortBy === "VariableTypeName") ? sorting.isAscending ? "asc" : "desc" : "asc"}
                                          onClick={e => {
                                            handleRequestSort(e, "VariableTypeName")
                                          }}
                                        >
                                          Variable (Type)
                                          {/* variableTypeName */}
                                        </TableSortLabel>
                                      </TableCell>
                                      {companyHeader.map((el, ind) => <TableCell align="center" rowSpan={2} style={{ minWidth: '150px' }} className={classes.cruTheadLeftSep} key={ind}>{el.publication}
                                        {((el.reportingYearId === mainitem.reportingYearId) && (el.reportingPeriod !== "Annual")) ? <Tooltip title="Swap Values" placement="top" arrow>
                                          <IconButton size="small" onClick={() => { swapValuesFun(el.publication) }}>
                                            <DependencyAddIcon />
                                          </IconButton>
                                        </Tooltip> : ((el.reportingYearId === mainitem.reportingYearId) && (el.reportingPeriod === "Annual")) ? <Tooltip title="Add Values" placement="top" arrow>
                                          <IconButton size="small" onClick={() => { samationValues(el.reportingYearId) }}>
                                            <AddIcon />
                                          </IconButton>
                                        </Tooltip> : ''}
                                      </TableCell>)}
                                      <TableCell align="center" rowSpan={2} style={{ minWidth: '75px' }} className={classes.tableHeader}>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {companyDeatils?.map((el, ind) => <TableRow hover key={ind}>
                                      <TableCell padding="checkbox" className={classes.tableHeader + ' ' + classes.stickyCheckbox}
                                        style={{ width: "40px", whiteSpace: "nowrap" }}>
                                        <Checkbox
                                          color="primary"
                                          checked={datametricsCheckedValue.indexOf(el) > -1}
                                          onChange={(e) => {
                                            handleChangeCheckBoxSingle(e, el)
                                          }
                                          }
                                        />
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tableCellLeftSep + ' ' + classes.stickyYear}
                                        style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                                      >
                                        <Tooltip title="Move Up" placement="top">
                                          <IconButton aria-label="Move Up" onClick={(e) => {
                                            ArrowUpAndDown(el, "UP", ind)
                                          }}>
                                            <ArrowDropUpIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Move Down" placement="top">
                                          <IconButton aria-label="Move Down" onClick={(e) => {
                                            ArrowUpAndDown(el, "DOWN", ind)
                                          }}>
                                            <ArrowDropDownIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tableCellLeftSep + ' ' + classes.stickyYear}
                                        style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                                      >
                                        {el.lastUpdateYear}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tableCellLeftSep + ' ' + classes.stickyLUQ}
                                        style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                                      >
                                        {el.lastUpdateQuater}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tableCellLeftSep + ' ' + classes.stickyVR}
                                        style={{ minWidth: "156px", maxWidth: "150px", color: el.isManualVariable ? "orange" : "black" }}
                                      >
                                        {el.valuationTypeId === 4 ? <>Guidance-{el.variableReport}</> : <>{el.variableReport}</>}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tableCellLeftSep + ' ' + classes.stickyUR}
                                        style={{ minWidth: "132px", whiteSpace: "nowrap", color: el.isManualUnit ? "orange" : "black" }}
                                      >
                                        {el.unitReport}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tableCellLeftSep + ' ' + classes.stickyVC}
                                        style={{ minWidth: "139px", maxWidth: "160px" }}
                                      >
                                        {el.variableCru}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tableCellLeftSep + ' ' + classes.stickyUC}
                                        style={{ minWidth: "113px", whiteSpace: "nowrap" }}
                                      >
                                        {el.unitCru}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tableCellLeftSep}
                                        style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                                      >
                                        {el.currency}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.tableCellLeftSep}
                                        style={{ minWidth: "60px", whiteSpace: "nowrap" }}
                                      >
                                        {el.variableTypeName}
                                      </TableCell>
                                      {
                                        companyHeader.map((head, index) =>
                                          <TableCell align="right" style={{ minWidth: "100px", whiteSpace: "nowrap" }} key={index} className={classes.tableCellLeftSep}>
                                            <span className={classes.overviewIcons}>
                                              {el.valuationTypeId === 4 ? <span className={(el[head.publication]?.isValueChanged || el[head.publication]?.variableValueStatusName === "Merit QC Failed") || (el[head.publication]?.variableValueStatusName === "CRU Review Failed") ? classes.color2 : classes.color4}>{el[head.publication]?.maxValue ?
                                                <>{el[head.publication]?.minValue?.toLocaleString() || "-"} ~ {el[head.publication]?.maxValue?.toLocaleString() || "-"}</> : <>{el[head.publication]?.minValue?.toLocaleString() || "-"}</>} </span> : <>{el.valuationTypeId === 3 ? <span className={el[head.publication]?.isValueChanged || (el[head.publication]?.variableValueStatusName === "Merit QC Failed") || (el[head.publication]?.variableValueStatusName === "CRU Review Failed") ? classes.color2 : classes.color3}>{el[head.publication]?.value?.toLocaleString() || "-"}</span> : <>{(el[head.publication]?.isValueChanged) || (el[head.publication]?.variableValueStatusName === "Merit QC Failed") || (el[head.publication]?.variableValueStatusName === "CRU Review Failed") ? <span className={classes.color2}>{el[head.publication]?.value?.toLocaleString() || "-"}</span> : <>{el[head.publication]?.value?.toLocaleString() || "-"}</>}</>}</>}
                                            </span>
                                            {el[head.publication]?.comments ?
                                              <Tooltip title={el[head.publication].comments} placement="top" arrow>
                                                <IconButton aria-describedby={idss} onClick={(event) => openPopover(event, el[head.publication]?.comments)}>
                                                  <CommentIcon />
                                                </IconButton>
                                              </Tooltip> : <span className={classes.overviewIcons}>-</span>}
                                            {el[head.publication]?.valueReportInfo.length ? <>{el[head.publication]?.valueReportInfo.map((item) => <>
                                              <Tooltip title={item.reportName} placement="top" arrow>
                                                <IconButton>
                                                  <AttachIcon onClick={() => { openDocumentForView(item.reportName, item.pageNumber) }} />
                                                </IconButton></Tooltip></>)}</> : <span className={classes.overviewIcons}>-</span>}
                                            {(editAccess) ? <>{((el[head.publication]?.variableValueId > 0) && (el[head.publication]?.isEdit === false) || (isHistory && el[head.publication]?.variableValueId === 0)) ? <>-</> :
                                              <Tooltip title="Edit" placement="top" arrow>
                                                <IconButton>
                                                  <EditIcon onClick={toggleDrawer(true, el, head)} />
                                                </IconButton>
                                              </Tooltip>}
                                            </> : <span className={classes.overviewIcons}>-</span>}
                                          </TableCell>)
                                      }
                                      < TableCell align="center" className={classes.tableCell} >
                                        {
                                          editAccess ?
                                            <Tooltip title="Delete" placement="top" arrow>
                                              < IconButton color="error" >
                                                <DeleteIcon style={{ fontSize: '20px' }} onClick={() => { setDeleteVariableId(el.variableId); setConfirmation(true) }} />
                                              </IconButton>
                                            </Tooltip> : null}
                                      </TableCell>
                                    </TableRow>)}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      {/* Data metric table goes here */}
                      <br />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              {/* Data Metrics Details End Here */}
              <SideDrawer
                drawerWidth="md"
                formComp={
                  <EditDataMetrics editDataMaterics={editDataMaterics} setEditDataMaterics={setEditDataMaterics} reportInfo={reportInfo} reportInfoDetails={reportInfoDetails} setReportInfoDetails={setReportInfoDetails} openDocument={openDocument} reportsValue={reportsValue} setReportsValue={setReportsValue} setEditState={setEditState} updateFromSave={updateFromSave} getHistoryData={getHistoryData} isReportUpload={isReportUpload} setIsReportUpload={setIsReportUpload} dropDown={dropDown} />
                }
                state={editState}
                setState={setEditState}
              />
              {
                isOpenSwap && (
                  <FormDialog
                    isOpenAssign={isOpenSwap}
                    onClose={handleClose}
                    setIsOpenAssign={setIsOpenSwap}
                    title="Swap Values"
                    content={
                      <>
                        <Box sx={{ height: 130 }}>
                          <Grid container spacing={{ xs: 1, sm: 2 }} alignItems="flex-start" justifyContent="flex-start">
                            <Grid item xs={12} sm={12}>
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={dropDown.reportingPeroid}
                                value={swapValues?.reportingPeriod}
                                fullWidth
                                onChange={(e, item) => {
                                  setSwapValues({
                                    reportingPeriod: item.label,
                                    reportingPeriodId: item.value,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Reporting Period (Swap To)"
                                    variant="standard"
                                  />
                                )}
                              />
                            </Grid>
                            <br />
                          </Grid>
                        </Box>
                      </>
                    }
                    action={
                      <>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="flex-end"
                          alignItems="flex-end"
                          sx={{ mb: 2 }}
                        >
                          <Grid item xs="auto">
                            <Button color="accent1" onClick={() => { swapApiCall(false) }} variant="contained">
                              Move Value
                            </Button>
                          </Grid>
                          <Grid item xs="auto">
                            <Button color="accent1" onClick={() => { swapApiCall(true) }} variant="contained">
                              Copy Value
                            </Button>
                          </Grid>
                          <Grid item xs="auto">
                            <Button color="accent3" variant="contained" onClick={cancelPopUp}>Cancel</Button >
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                )
              }
              <Grid item xs={12} spacing={2}>
                <Grid container spacing={2} justifyContent="left">
                  {editAccess ?
                    <Grid item xs={12} sm={"auto"}>
                      <Button
                        fullWidth
                        className={classes.largeButtonAuto}
                        variant="contained"
                        color="accent5"
                        onClick={() => { saveSubmit() }}
                      >
                        Submit
                      </Button>
                    </Grid> : null}

                  {editAccess ? <Grid item xs={12} sm={"auto"}>
                    <Button
                      fullWidth
                      className={classes.largeButtonAuto}
                      variant="contained"
                      color="secondary"
                      onClick={() => { saveDraft("Drafted Successfully") }}
                    >
                      save draft
                    </Button>
                  </Grid> : null}
                  <Grid item xs={12} sm={"auto"}>
                    <Button
                      fullWidth
                      className={classes.largeButtonAuto}
                      variant="contained"
                      color="accent6"
                      onClick={openPreviewDialog}
                    >
                      preview
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={"auto"}>
                    <Button
                      fullWidth
                      className={classes.largeButtonAuto}
                      variant="contained"
                      color="accent3"
                      onClick={() => { setConfirmation3(true) }}
                    >
                      cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid >
          </BaseLayout >
        </Grid >

        <SideDrawer
          formComp={<AddVariableReport />}
          state={variableReport}
          setState={setVariableReport}
        />
        <SideDrawer
          drawerWidth="md"
          formComp={
            <CreatePublicationDetails
              state={state}
              setState={setState}
              calenderID={null}
              recallApi={futurePublicationRefresh}
            />
          }
          state={state}
          setState={setState}
        />

        <BasicPopover
          sOpen={sOpen}
          open={open1}
          onClose={handleClose}
          idss={idss}
          description={Comment.message}
        />

        <AlertDialog
          open={confirmation}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to delete?`}
          action={
            <>
              <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                <Button onClick={() => setConfirmation(false)}>No</Button>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button color="secondary" onClick={() => {
                    setConfirmation(false)
                    deleteVariableFun()
                  }}>
                    Yes
                  </Button>
                )}
              </div>
            </>
          }
        />

        <AlertDialog
          open={confirmation1}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to submit? Data cannot be edited once submitted `}
          action={
            <>
              <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                <Button onClick={() => setConfirmation1(false)}>No</Button>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button color="secondary" onClick={finalSubmit}>
                    Yes
                  </Button>
                )}
              </div>
            </>}
        />
        <AlertDialog
          open={confirmation2}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to submit? Data cannot be edited once submitted `}
          action={
            <>
              <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                <Button onClick={() => setConfirmation2(false)}>No</Button>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button color="secondary" onClick={finalSubmit}>
                    Yes
                  </Button>
                )}
              </div>
            </>}
        />
        <AlertDialog
          open={confirmation3}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to Cancel, This will cause any unsaved data to be lost`}
          action={
            <>
              <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                <Button onClick={() => setConfirmation3(false)}>No</Button>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button color="secondary" onClick={cancelSubmit}>
                    Yes
                  </Button>
                )}
              </div>
            </>}
        />

        <ScrollingDialog
          open={openAdobeViewer}
          setOpen={setOpenAdobeViewer}
          dialogClass="darkTitle"
          dialogTitle="File Preview"
          dialogContent={
            <AdobeViewer jobId={adobeViewerData.jobId}
              fileName={adobeViewerData.fileName}
              pageNumber={adobeViewerData.pageNumber}
              crudState={adobeViewerData.crudState}>
            </AdobeViewer>
          }
        />
      </Grid >
    </Box >
  );
};

export default UpdateRecord;

const ValueOfDataMatrix = (props) => {
  let {
    subItem,
    subindex,
    dropDown,
    classes,
    reportInfo,
    setConfirmation,
    assetVariableInfo,
    setAssetVariableInfo,
    setRemoveValueData,
    jobdetails
  } = props;

  const [valueItem, setValueItem] = useState(subItem);
  const [openAdobeViewer, setOpenAdobeViewer] = useState()
  const [adobeViewerData, setAdobeViewerData] = useState({ jobId: 0, fileName: '', pageNumber: 0, crudState: "" })

  React.useEffect(() => {
    let recivedData = assetVariableInfo
    recivedData[subindex] = valueItem
    setAssetVariableInfo([...recivedData]);
  }, [valueItem]);

  const openDocument = (file, pageNumber) => {
    var reportName = file + ".pdf"
    var senData = { jobId: jobdetails.jobIds, fileName: reportName, pageNumber: Number(pageNumber), crudState: "View" }
    localStorage.setItem("filePreview", JSON.stringify(senData));
    //CommonService.AddTokenFromSessionStorageToLocalStorage();
    window.open("#app/data-collections/file-preview", '_blank', 'noopener,noreferrer');
  }

  const onValuationChange = (e, el, subInd) => {
    var isValidatFailed = false
    var value = Number(e.target.value)
    if (value && valueItem.reportingYearId && valueItem.reportingPeriodId && assetVariableInfo.length) {
      assetVariableInfo.forEach((el, ind) => {
        if (subInd !== ind) {
          if (el.valuationTypeId === value && el.reportingYearId === valueItem.reportingYearId && el.reportingPeriodId === valueItem.reportingPeriodId) {
            toasterError("Selected valuation type already exists for the same year,frequency and quarter")
            isValidatFailed = true
          }
        }
        if (assetVariableInfo.length === ind + 1) {
          if (!isValidatFailed) {
            if (value === 4) {
              setValueItem({ ...subItem, minValue: null, valuationTypeId: value, valuationType: el.label })
            } else {
              setValueItem({ ...subItem, valuationTypeId: value, valuationType: el.label })
            }
          }
        }
      });
    } else {
      if (value === 4) {
        setValueItem({ ...subItem, minValue: null, valuationTypeId: value, valuationType: el.label })
      } else {
        setValueItem({ ...subItem, valuationTypeId: value, valuationType: el.label })
      }
    }
  }

  const onYearChange = (item, subInd) => {
    var isValidatFailed = false
    var value = item.value
    if (valueItem.valuationTypeId && value && valueItem.reportingPeriodId && assetVariableInfo.length) {
      assetVariableInfo.forEach((el, ind) => {
        if (subInd !== ind) {
          if (el.valuationTypeId === valueItem.valuationTypeId && el.reportingYearId === value && el.reportingPeriodId === valueItem.reportingPeriodId) {
            toasterError("Selected valuation type already exists for the same year,frequency and quarter")
            isValidatFailed = true
          }
        }
        if (assetVariableInfo.length === ind + 1) {
          if (!isValidatFailed) {
            setValueItem({
              ...valueItem,
              reportingYear: item.label,
              reportingYearId: item.value,
            });
          }
        }
      });
    } else {
      setValueItem({
        ...valueItem,
        reportingYear: item.label,
        reportingYearId: item.value,
      });
    }
  }

  const reportingChange = (item, subInd) => {
    var isValidatFailed = false
    var value = item.value
    if (valueItem.valuationTypeId && valueItem.reportingYearId && value && assetVariableInfo.length) {
      assetVariableInfo.forEach((el, ind) => {
        if (subInd !== ind) {
          if (el.valuationTypeId === valueItem.valuationTypeId && el.reportingYearId === valueItem.reportingYearId && el.reportingPeriodId === value) {
            toasterError("Selected valuation type already exists for the same year,frequency and quarter")
            isValidatFailed = true
          }
        }
        if (assetVariableInfo.length === ind + 1) {
          if (!isValidatFailed) {
            setValueItem({
              ...subItem,
              reportingPeriod: item.label,
              reportingPeriodId: item.value,
            });
          }
        }
      });
    } else {
      setValueItem({
        ...subItem,
        reportingPeriod: item.label,
        reportingPeriodId: item.value,
      });
    }
  }

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1, mb: -2 }}>
        <Paper elevation={0} className={classes.recordTitleArea}>
          <Box className={classes.recordTitle}>Value {subindex + 1}</Box>
          <Box className={classes.recordLine}></Box>
          <Box className={classes.recordAction}>
            <Tooltip
              title="Delete Value"
              placement="top"
              arrow
            >
              <IconButton color="error">
                <DeleteIcon
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    let data = { subindex: subindex, id: subItem?.variableValueId }
                    setRemoveValueData(data)
                    setConfirmation(true)
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
        <FormControl>
          {/* <FormLabel id="demo-row-radio-buttons-group-label">Frequency</FormLabel> */}
          <Typography variant="caption" display="block">
            Valuation
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {dropDown.valuation?.map((el) => {
              return (
                <FormControlLabel
                  value={el.label}
                  control={<Radio value={el.value} checked={subItem.valuationTypeId ? subItem.valuationTypeId === el.value : false}
                    onChange={(e) => { onValuationChange(e, el, subindex) }}
                  />}
                  label={el.label}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={dropDown.year}
          value={subItem.reportingYear}
          fullWidth
          onChange={(e, item) => {
            onYearChange(item, subindex)
          }}
          renderInput={(params) => (
            <TextField {...params} label="Year" required variant="standard" />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={dropDown.reportingPeroid}
          value={subItem.reportingPeriod}
          fullWidth
          onChange={(e, item) => { reportingChange(item, subindex) }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Reporting Period"
              variant="standard"
              required
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
        <Grid
          container
          spacing={3}
          justifyContent="left"
          alignItems="center"
        >
          {(subItem.valuationTypeId === 4) ? <>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                id="standard-basic"
                label="Min Value"
                value={subItem.minValue}
                type="number"
                onChange={(e) => { setValueItem({ ...subItem, minValue: e.target.value, value: null }) }}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                id="standard-basic"
                label="Max Value"
                value={subItem.maxValue}
                type="number"
                onChange={(e) => { setValueItem({ ...subItem, maxValue: e.target.value, value: null }) }}
                fullWidth
                variant="standard"
              />
            </Grid>
          </> :
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="standard-basic"
                label="Value"
                type="number"
                value={subItem.value}
                onChange={(e) => { setValueItem({ ...subItem, value: e.target.value, minValue: null, maxValue: null }) }}
                fullWidth
                required
                variant="standard"
              />
            </Grid>
          }
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="self-end"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={reportInfo}
                fullWidth
                value={{ reportName: subItem.reportName }}
                getOptionLabel={(option) => option.reportName}
                onChange={(e, value) => { setValueItem({ ...subItem, reportName: value.reportName }) }}
                renderInput={(params) => (
                  <TextField {...params} label="Report Name" required variant="standard" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Button
                variant="contained"
                color="accent5"
                size="small"
                onClick={() => {
                  openDocument(valueItem.reportName)
                }}
                startIcon={
                  <OpenEnrollmentIcon
                    style={{ fontSize: 16 }}
                  />
                }
              >
                Open Document
              </Button>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6} xl={4}>

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1} justifyContent="center" alignItems="self-end">
                  <Grid item xs>
                    <TextField
                      id="standard-basic"
                      label="Page No"
                      type="number"
                      value={subItem.pageNumber}
                      onChange={(e) => {
                        setValueItem({
                          ...subItem,
                          pageNumber: e.target.value,
                        });
                      }}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <Tooltip title="File Preview" placement="top" arrow>
                      <IconButton color="secondary">
                        <AttachIcon onClick={() => {
                          if (subItem.pageNumber > 0) {
                            openDocument(subItem.reportName, subItem.pageNumber)
                          }
                        }
                        } />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={8} lg={12} xl={6}>
        <TextField
          id="standard-basic"
          label="Comments"
          value={subItem.comments}
          onChange={(e) => {
            setValueItem({ ...subItem, comments: e.target.value });
          }}
          fullWidth
          variant="standard"
        />
      </Grid>

      <ScrollingDialog
        open={openAdobeViewer}
        setOpen={setOpenAdobeViewer}
        dialogClass="darkTitle"
        dialogTitle="File Preview"
        dialogContent={
          <AdobeViewer jobId={adobeViewerData.jobId}
            fileName={adobeViewerData.fileName}
            pageNumber={adobeViewerData.pageNumber}
            crudState={adobeViewerData.crudState}>

          </AdobeViewer>
        }
      />

    </>
  );
};



