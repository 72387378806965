// Default React, Router and Services Components
import React from "react";
import { Button } from "@mui/material";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";

// Import Fluent UI Icons
import { ExportIcon } from "@fluentui/react-icons-mdl2";

// Function for Export to CSV
const ExportToExcel = ({ apiData, fileName }) => {
  const exportToCSV = (apiData, fileName) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    XLSX.utils.sheet_add_aoa(
      ws,
      [["Ticket Type", "Active", "inactive", "Pending", "Total"],],
      { origin: "A1", }
    );
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    // Export to Excel
    <Button variant="contained" color="secondary" startIcon={<ExportIcon style={{ fontSize: 14 }} />}
      onClick={(e) => exportToCSV(apiData, fileName)}>
      Export To Excel
    </Button>
  );
};

export default ExportToExcel;