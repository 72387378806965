// Default React, useState and Material Components
import React, { useState } from "react";
import { Button, Box, Breadcrumbs, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography, } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import SideDrawer from "../../../../src/UIComponent/Drawer/index";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing Add & Edit Components
import AddUnitReport from "./AddEditUnitReport";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon, } from "@fluentui/react-icons-mdl2";

// Importing useStyles
import useStyles from "../ManageData.styles";

const UnitReport = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [id, setId] = useState([]);
  const [rows, setRows] = useState([]);
  const [edit, setEdit] = useState({});
  const [page, setPage] = React.useState(0);
  const [tableRow, setTableRow] = useState();
  const [reload, setReload] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [selected, SetSelected] = useState([]);
  const [formData, setFormData] = useState([]);
  const [showDelete, setDelete] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [crudMode, setCrudMode] = useState("add");
  const [crudModal, setCrudModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [UnitReportState, setUnitReportState] = useState();
  const [prevFormValue, setPrevFormValue] = useState(null);
  const [state, setState] = React.useState({ right: false });
  const [deleteUserData, setDeleteUserData] = useState(null);
  const [mappedCRUVariable, setMappedCRUVariable] = useState(true);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [addUnitReportDrawer, setAddUnitReportDrawer] = useState(true);
  const [deleteUnitReportData, setDeleteUnitReportData] = useState(null);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false });
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(28, function (err, res) {
      if (res) {
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])
  React.useEffect(() => { GetAllUnitReport(searchValue); }, [reload]);
  React.useEffect(() => { GetAllUnitReport(searchValue); }, [sorting, page, rowsPerPage]);
  // Declaration of React Hooks End Here

  // Assigning Columns and UI Props
  const Mappedcolumns = [
    { id: "id", label: "Id", type: "Highlight" },
    { id: "name", label: "Unit", minWidth: 150, width: '25%', sorting: true },
    { id: "cruUnitName", label: "CRU Unit", minWidth: 200, width: '25%', sorting: true },
    { id: "createdBy", label: "Created By", minWidth: 250, width: '25%', sorting: true },
    { id: "createdDate", label: "Created On", minWidth: 150, width: '25%', sorting: true },
  ];
  const UmMappedColumns = [
    { id: "id", label: "Id", type: "Highlight" },
    { id: "name", label: "Unit", minWidth: 150, width: '25%', sorting: true },
    { id: "status", label: "JiraTicketStatus", minWidth: 200, width: '25%', sorting: true },
    { id: "createdBy", label: "Created By", minWidth: 250, width: '25%', sorting: true },
    { id: "createdDate", label: "Created On", minWidth: 150, width: '25%', sorting: true },
  ]

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      searchReport(event.target.value);
    }
  };

  // Function for Sorting
  const changeSortColumnNameFormat = (fieldName) => {
    if (fieldName == null || fieldName == "") { return ""; }
    if (fieldName.toLocaleLowerCase() == "name".toLocaleLowerCase()) {
      fieldName = "Name";
    }
    if (fieldName.toLocaleLowerCase() == "cruunitname".toLocaleLowerCase()) {
      fieldName = "CRUUnitName";
    }
    if (fieldName.toLocaleLowerCase() == "status".toLocaleLowerCase()) {
      fieldName = "Status";
    }
    if (fieldName.toLocaleLowerCase() == "createdDate".toLocaleLowerCase()) {
      fieldName = "CreatedOn";
    }
    if (fieldName.toLocaleLowerCase() == "createdby".toLocaleLowerCase()) {
      fieldName = "CreatedBy";
    }
    return fieldName;
  }

  React.useEffect(() => {
    GetAllUnitReport(searchValue);
  }, [reload]);

  React.useEffect(() => {
    GetAllUnitReport(searchValue);
  }, [sorting, page, rowsPerPage]);

  const globalSearch = (value) => {
    setSearchValue(value);
  };

  const searchReport = (filterValue) => {
    setTotalCount(0);
    setPage(0);
    setLoading(true);
    setReload(!reload);
  };
  const handleSearch = (event) => { setSearchValue(event.target.value); };

  // Function for Save/Update Side Drawer
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") {
      setDeleteUnitReportData(item);
      setOpenDelete(true);
    } else {
      setAddUnitReportDrawer(true);
      setPrevFormValue(null);
      if (mode === "Edit") {
        setFormData(item);
        setState({ ...state, right: open, formData: item });
        setCrudState(mode);
      }
      if (mode === "Add") {
        setState({ ...state, right: open, formData: item });
        setCrudState(mode);
      }
    }
  };
  const MappedCRUVariable = () => {
    setMappedCRUVariable(true);
    setSearchValue("");
    setPage(0);
    setSorting({ sortBy: "", isAscending: false });
    setReload(!reload);
  }
// Function to reset the mapped CRU variable, search value, page number, sorting and reload
const UnMapperVariableReport = () => {
  // Set mapped CRU variable to false
  setMappedCRUVariable(false);
  // Reset search value to empty string
  setSearchValue("");
  // Reset page number to 0
  setPage(0);
  // Reset sorting to default values
  setSorting({ sortBy: "", isAscending: false });
  // Toggle reload value
  setReload(!reload);
}

// Function to delete a unit report
const deleteUnitReport = () => {
  // Set loading state of delete button to true
  setDeleteBtnLoadinng(true);
  
  // Check if deleteUnitReportData is null or has an Id of 0
  if (deleteUnitReportData == null || deleteUnitReportData.Id == 0) {
    // If so, display error message
    toasterError("Somthing Went Wrong");
    // Set loading state of delete button to false
    setDeleteBtnLoadinng(false);
    // Return out of the function
    return;
  }
  // Make a post request to the specified URL with the given data and headers
  axios.post(process.env.React_App_baseUrl + `managedata/DeleteUnitReport?unitId=${deleteUnitReportData.id}`, null, {
    headers: {
      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
  })
    .then((res) => {
      // If the response status is 200, display success message
      if (res.status === 200) {
        toasterSuccess("Unit Report deleted successfully!");
        // Set loading state of delete button to false
        setDeleteBtnLoadinng(false);
        // Set openDelete to false
        setOpenDelete(false);
        // Set reload to the opposite of its current value
        setReload(!reload);
      } else {
        // If not, display error message
        toasterError("Somthing Went Wrong");
        // Set openDelete to false
        setOpenDelete(false);
        // Set loading state of delete button to false
        setDeleteBtnLoadinng(false);
        // Set reload to the opposite of its current value
        setReload(!reload);
      }
    })
    .catch((err) => {
      // Set openDelete to false
      setOpenDelete(false);
      // Set loading state of delete button to false
      setDeleteBtnLoadinng(false);
      // Set reload to the opposite of its current value
      setReload(!reload);
      // Call ErroHandling function with the given error and a callback function
      ErroHandling(err, function (res) {
        // Display the given error message
        toasterError(res)
      })
    });
};

  // Function for Search Clear
  const searchClear = () => {
    setSearchValue("");
    setPage(0);
    setReload(!reload);
  };

// This function is used to get all unit reports based on the filter value
  const GetAllUnitReport = (filterValue) => {
    // Set loading to true while fetching data from API
    setLoading(true);
    // Create an array of parameters for API call
    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`, `mappedVariableReport=${mappedCRUVariable}`];
    // Call API service to search for unit reports
    ApiService.search(`managedata/GetAllUnitReports?`, {
      searchText: encodeURIComponent(filterValue),
      sortColumn: changeSortColumnNameFormat(sorting.sortBy),
      isAscending: sorting.isAscending
    }, prt)
      .then((res) => {
    // If response status is 200, set all users and total count    
        if (res.status === 200) {
          setAllUsers(res.data.data);
          setTotalCount(res.data.totalCount);
          setLoading(false);
        } else {
     // Else show error message     
          toasterError(`${res.data}`);
          setLoading(false);
        }
      })
      .catch((err) => {
     // Catch any errors and handle them   
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }

  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Unit (Report)</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">Unit (Report)</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Top Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", }}>
          <Grid container spacing={{ xs: 1, sm: 2 }} alignItems="center" justifyContent="flex-end">
            <Grid item>
              {(access.allowEdit) ? <>
                <Button variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: 16 }} color="primary" />}
                  onClick={toggleDrawer(true, "Add")}>
                  Add Unit (Report)
                </Button></> : null
              }
            </Grid>
          </Grid>
        </Grid>
        {/* Top Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            {/* Units Buttons Start Here */}
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={'auto'}>
                  <Button fullWidth className={classes.largeButton} color="secondary" size="small"
                    variant={mappedCRUVariable ? "contained" : "outlined"}
                    onClick={() => MappedCRUVariable()}
                  >
                    Mapped Units
                  </Button>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                  <Button fullWidth className={classes.largeButton} variant={mappedCRUVariable ? "outlined" : "contained"}
                    color="secondary"
                    size="small"
                    onClick={() => UnMapperVariableReport()}
                  >
                    Missing Units
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* Units Buttons Start Here */}

            <Box className={classes.addBorder}>
              <Grid container alignItems="center" justifyContent="left">
                {/* Search and Filter Options Start Here */}
                <Grid item xs={12} className={classes.tableTopPart}>
                  <Grid container alignItems="center" justifyContent="left" spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="input-with-icon-textfield"
                        className={classes.searchByKeyword}
                        size="small"
                        fullWidth
                        value={searchValue}
                        placeholder="Search by Keyword"
                        onKeyUp={handleKeyUp}
                        onChange={(e) => globalSearch(e.target.value)}
                        InputProps={{
                          startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                        }}
                        variant="standard"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility"><SearchIcon /></IconButton>
                          </InputAdornment>
                        }
                      />
                      {searchValue ? (
                        <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                          <ClearIcon style={{ fontSize: 10 }} />
                        </IconButton>
                      ) : ("")}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container alignItems="center" justifyContent="right" spacing={2}>
                        <Grid item textAlign="right">
                          <Typography variant="subtitle2">
                            Total Units:{" "}
                            <span className={classes.totalRecord}> {totalCount}</span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DataTable
                    className={classes.removeBorder}
                    loading={isLoading}
                    rows={allUsers ? allUsers : []}
                    columns={mappedCRUVariable ? Mappedcolumns : UmMappedColumns}
                    page={page}
                    unitReport={true}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    totalCount={totalCount}
                    isAllSelected={isAllSelected}
                    setIsSelected={setIsSelected}
                    selected={selected}
                    setSelected={SetSelected}
                    sorting={sorting}
                    setSorting={setSorting}
                    isActionButton={{
                      ...(access.allowEdit && { edit: toggleDrawer(true, "Edit"), }),
                      ...(access.allowDelete && { delete: toggleDrawer(true, "Delete"), }),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Add, Edit, Delete Side Drawer */}
      <SideDrawer
        formComp={
          <AddUnitReport
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            userId={null}
            hideSideDrawer={() => { setState({ right: false }); setReload(!reload); }}
            mappedCRUVariable={mappedCRUVariable}
            addUnitReportDrawer={addUnitReportDrawer}
            setPrevFormValue={setPrevFormValue}
            prevFormValue={prevFormValue}
            openAddCRUUnitDrawer={(e) => {
              setPrevFormValue(e);
              setUnitReportState(crudState);
              setCrudState("Add");
              setAddUnitReportDrawer(false);
            }}
            closeUnitReportDrawer={() => {
              setState({ right: false });
              setReload(!reload);
              setPrevFormValue(null);
            }}
            closeVariableReportDrawer={() => {
              setState({ right: false });
            }}
            updateUnitReport={() => {
              setReload(!reload);
              setPage(0);
              setSearchValue("");
              if (crudState.toLowerCase() == "Edit".toLowerCase()) {
                setSorting({ sortBy: "ModifiedOn", isAscending: false })
              } else {
                setSorting({ sortBy: "CreatedOn", isAscending: false })
              }
            }}
          />
        }
        state={state}
        setState={setState}
        setPageRefresh={() => {
          setReload(!reload);
        }}
      />

      {/* Single and Multiple Delete Confirmation Dialog Start Here */}
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to delete "${deleteUnitReportData?.name}" ?`}
          action={
            <>
              <div className={classes.alertDialogbtn}>
                <Button onClick={() => setOpenDelete(false)} style={{ marginRight: "15px" }}>No</Button>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button onClick={() => deleteUnitReport()} color="secondary">
                    Yes
                  </Button>
                )}
              </div>
            </>
          }
        />
      ) : null
      }
      {/* Single and Multiple Delete Confirmation Dialog End Here */}
    </Box >
  );
};

export default UnitReport;