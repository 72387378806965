// Default React, Router and Services Components
import { React, useState } from "react";
import { Button, Box, Grid, Typography } from "@mui/material";
import { CircularProgress } from '@mui/material';
// Importing Custom Components
import Dropzone from "../../../UIComponent/DragDropField";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";
import CommonService from "../../../Services/CommonService";
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

const BulkUpload = (props) => {
  const classes = useStyles();
  const [isFileCleared, setIsFileCleared] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
 
  // `uploadFileFunc` is a function which does not take any parameters.
const uploadFileFunc = () => {
  // Set loading state to true, usually used to show a spinner/loading symbol in UI.
  setLoading(true);
  let fileName

  // Checking if fileData is null or undefined. 
  // If so, trigger error toast and stop execution by 'return'.
  if (fileData === undefined || fileData === null) {
    toasterError("file not found");
    setLoading(false);
    return;
  }

  // Assigning fileData to variable data.
  var data = fileData;

  // Checking the type of file. If it's not excel sheet type, trigger an error toast and stop execution.
  if (data.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    toasterError("Please select excel sheet.");
    setLoading(false);
    return;
  }

  // If the file is an Excel sheet, assign file name for further processing.
  if (data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    fileName = data.name;
  }

  // Create a new File object using given data, and given file name and data type.
  const myNewFile = new File([data], `${fileName}`, {
    type: data.type,
  });

  // Creating new FormData instance to hold form field data that will be sent over HTTP request.
  var formdata = new FormData();

  // Appending our file to the 'file' key within form data.
  formdata.append("file", myNewFile);

  // Making POST request with axios to upload the file.
  // The URL is built from environment variable and static string.
  // Also includes a token in the headers for authentication.
  axios
    .post(
      process.env.React_App_baseUrl +
      `OwnershipChangeBulkUploadJob/BulkUploadJobDetails`,
      formdata, {
      headers: {
        'Authorization': CommonService.getToken()

      }
    }
    )
    .then(function (response) {
      // If request is successful -
      if (response.status === 200) {

        // Call bulkUploadData function from props, if it exists and passing the response data.  
        if(props?.bulkUploadData!==undefined && props?.bulkUploadData!==null)
        {
          props?.bulkUploadData(response.data);
        }

        // Trigger success toast and stop loading state.
        toasterSuccess('File uploaded  successfully.');
        setLoading(false);

      }
    }).catch(function (error) { 
        
      // If there's an error -    
      ErroHandling(error, function (res) {
        // Stop loading state and throw error toast message.
        setLoading(false);
        toasterError(res)
      })

    });
}


// `downloadTemplate` is a function which does not take any parameters.
const downloadTemplate = () => {
  // Creating a constant `fileName`, and assigning it a string value
  // The string contains the current date in 'MM-DD-YYYY' format concatenated with "BulkUploadTemplate".
  const fileName = `BulkUploadTemplate ${new Date().toLocaleDateString().split("/").join("-")}`;

  // An array `headers` with list of strings that represent headers for an Excel file.
  const headers = ["Owner","Owner Id", "Asset","Asset Id", "Commodity", "Country",
   "Region", "Sub Region", "Lattitude", "Longitude", "Ownership Percentage", 
   "Ownership Year", "Operator","IsListedCompany"]

  // Calling the `ExcelExportFunc` function from `CommonService`, 
  // which likely exports or downloads the template as an Excel file,
  // using provided `fileName` and `headers`.
  CommonService.ExcelExportFunc(fileName, headers);
}


  // `onFilesAdded` is a function that takes two parameters: an event `e` and a `fileUpload`.
const onFilesAdded = (e, fileUpload) => {
  // Check if `fileUpload` is defined and not null.
  if (fileUpload !== undefined && fileUpload !== null) {
      // If `fileUpload` is an array and its length is more than 0, then proceed.
      if (fileUpload.length > 0) {
          // Call the `setFileData` function with the first file in the `fileUpload` array.
          setFileData(fileUpload[0]);
      }
  }

}


// `reset` is a function which doesn't take any parameters.
const reset = () => {
  // It calls the `setFileData` function with null as its argument. 
  setFileData(null)
}


  return (
    // Layout for Filter
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Bulk Upload
        </Typography>
      </Box>

      {/* Filter Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Dropzone
            margin="normal"
            content={"CSV File"}
            helperText="Max. of 100 rows allowed"
            showSelectedFile={false}
            isFileCleared={isFileCleared}
            onFilesAdded={(e, fileUpload) => {
              // console.log("Dropzone")
              onFilesAdded(e, fileUpload)

            }
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              {/* <Button variant="contained" color="secondary"
              disabled={fileData===null}
                onClick={uploadFileFunc}
              >
                Upload

              </Button> */}

              <Button
                type="submit"
                disabled={fileData===null}
                variant="contained"
                onClick={uploadFileFunc}
                color="secondary"
                style={{
                    color: loading ? "transparent" : "",
                    position: "relative", height: "100%", width: "auto"
                }}
            >
                Upload
                {loading ? (
                    <div style={{ position: "absolute", top: "calc(50% - 11px)", right: "38%" }} >
                        <CircularProgress size={20} />
                    </div>
                ) : null}
            </Button>

              {/* <LoadingButton
                    variant="contained" 
                    color="secondary"
                    buttonName="Upload"
                    disabled={fileData===null}
                    clickHandler={uploadFileFunc}
                    isLoading={loading}
                  /> */}
            </Grid>
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn reset-color" color="accent3"
                onClick={reset}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" className={classes.bulkTemplate}>
            <DescriptionOutlinedIcon /> <a onClick={downloadTemplate}>Click here</a> to download the bulk upload template
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BulkUpload;