// Default React, useState and Material Components
import React, { useState, useEffect } from "react";

// Importing Custom Components
import SideDrawer from "../../../../src/UIComponent/Drawer/index";

// Importing Add & Edit Components
import AddEditVariableReport from "./AddVariableReport";

const VariableReportSideDrawer = (props) => {

    // Declaration of States and Component Variables Start Here
    const [reload, setReload] = useState(false);
    const [formData, setFormData] = useState([]);
    const [crudState, setCrudState] = React.useState(false);
    const [prevFormValue, setPrevFormValue] = useState(null);
    const [state, setState] = React.useState({ right: false });
    const [variableReportState, setVariableReportState] = useState();
    const [mappedCRUVariable, setMappedCRUVariable] = useState(props.mappedCRUVariable);
    // Declaration of States and Component Variables End Here

    // Declaration of React Hooks Start Here
    useEffect(() => {
        if (props.crudModeForVariable)
        // if(props.open)
        {
            toggleDrawer(true, "Add")
        } else {
            setState({ right: false });
            props.setCrudModeForVariable(false)
        }
    }, [props.crudModeForVariable])
    //},[props.open])
    // Declaration of React Hooks End Here

    //Method for toggle side drawer 
    const toggleDrawer = (open, mode) => {
        if (mode === "Add") {
            let item = {};
            setState({ ...state, right: open, formData: item });
            setCrudState(mode);
        }
    };

    return (
        <SideDrawer
            formComp={
                <AddEditVariableReport
                    state={state}
                    setState={setState}
                    crudState={crudState}
                    formData={formData}
                    setFormData={setFormData}
                    userId={null}
                    hideSideDrawer={() => {
                        props.setCrudModeForVariable(false)
                        setState({ right: false });
                    }}
                    mappedCRUVariable={mappedCRUVariable}
                    setPrevFormValue={setPrevFormValue}
                    prevFormValue={prevFormValue}
                    setDefaultVariableValue={props.setDefaultVariableValue}
                    setDefaultVaribaleCRUValue={props.setDefaultVaribaleCRUValue}
                    openAddCRUVariableDrawer={(e) => {
                    }}
                    closeVariableReportDrawer={() => {
                        props.setCrudModeForVariable(false)
                        setState({ right: false });
                    }}
                    updateMappedCRUVariable={(e) => {
                        if (props.updateVariableRefresh != null) {
                            props.updateVariableRefresh();
                        }
                    }}
                />
            }
            state={state}
            setState={setState}
            setCrudModeForVariable={props.setCrudModeForVariable}
            setPageRefresh={() => {
                setReload(!reload);
            }}
        />
    );
}

export default VariableReportSideDrawer;