import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const allCompanies = [
  { companyName: "The Shawshank Redemption", isRPC: false, companyId: 1 },
  { companyName: "The Godfather", isRPC: false, companyId: 2 },
  { companyName: "The Godfath2er", isRPC: true, companyId: 2 },
];

function CustomAutoComplete(props) {
  //console.log(props)
  const {
    label = "",
    placeholder = "",
    options = [],
    onChange = () => {},
    optionValueKey = "id",
    optionLabelKey = "label",
    defaultValueKey = "id",
    defaultValue = "",
    disabled =""
  } = props;

  const [value, setValue] = React.useState(null);
  const [values, setValues] = React.useState([]);

  React.useEffect(() => {
    if (values.length > 0 && !value) {
      let currentOption = null;
      if (!defaultValue) {
        currentOption = values[0];
      } else {
        currentOption = values.find((option) =>
          typeof defaultValueKey === "string"
            ? option[defaultValueKey] === defaultValue
            : defaultValueKey
                .map((key, idx) => option[key] === defaultValue[idx])
                .every((e) => e)
        );
      }
      if (currentOption) setValue(currentOption);
    }
  }, [values, value]);

  React.useEffect(() => {
    if (options.length > 0)
      setValues(
        options.map((option) => {
          return {
            ...option,
            id: option[optionValueKey],
            label: option[optionLabelKey],
          };
        })
      );
  }, [options]);

  return (
    <Autocomplete
      id="auto-complete"
      autoComplete
      disabled = {props.disabled === true}
      disableClearable={props.disableClearable}
      includeInputInList
      options={values}
      value={value}
      onChange={(e, newValue) => {
        setValue(newValue);
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={label || placeholder}
        />
      )}
      // {...restProps}
    />
  );
}
export default CustomAutoComplete

// export default function ComboBox() {
//   return (
//     <CustomAutoComplete
//       disablePortal
//       id="combo-box-demo"
//       options={allCompanies}
//       sx={{ width: 300 }}
//       // label="companyName"
//       // optionLabelKey="companyName"
//       // optionValueKey="companyId"
//       // defaultValueKey="id"
//       // defaultValue={2}
//       // defaultValueKey={["id", 'isRPC']}
//       // defaultValue={[2, true]}
//     />
//   );
// }