// Default React, Router and Services Components
import React from "react";
import { Button, Box, Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import MultiSelect from "../../../UIComponent/MultiSelect/MultiSelect";
import DatePicker from "../../../UIComponent/DatePicker";
import { toasterError } from "../../../UIComponent/Toaster";
import Container from "../SourceManagement/TreeContainer";
import {
  clearFilterJobRecords,
  clearQualityCheckFilter,
  clearQualityFilter,
  GetAssetData,
  setQualtyCheckFilter,
  setQualtyFilter,
} from "../../../features/filter/filterSlice";
import {
  callBasedOnLocation,
  callFromAssetType,
  callFromComm,
  callFromPriority,
  callSource,
} from "../../../Util/TreeAPI";

// Importing useStyles and Styles
import "../../../../src/index.scss";
import "../../../Resources/scss/layout.scss";
import useStyles from "./QualityManagement.styles";
import "react-toastify/dist/ReactToastify.css";
import "react-dropdown-tree-select/dist/styles.css";
import { Value } from "sass";

const QualityCheckFilter = (props) => {
  // Declaration of States and Component Variables Start Here
  const { filterQualityCheck, assetDropDownlist } = useSelector(
    (store) => store.filter
  );

  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [status, setStatus] = React.useState([]);
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [jobComms, setJobComms] = React.useState([]);
  const [prior, setPrior] = React.useState([]);
  const [jobids, setJobIds] = React.useState([]);
  const [asset, setAsset] = React.useState([]);
  const [completedby, setCompletedBy] = React.useState([]);
  const [jobDate, setJobDate] = React.useState("");
  const [assignedTo, setAssignedTo] = React.useState([]);
  const [assignedBy, setAssignedBy] = React.useState([]);

  const [dependency, setDependency] = React.useState(null);
  const [apiData, setApiData] = React.useState({
    JobIds: [],
    asset: [],
    completedBy: [],
  });
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  console.log("Quality", filterQualityCheck);
  const onChangeHandler = (key, value, reason) => {
    console.log("CHECKONCLICK");
    const pre = { ...filterQualityCheck };
    console.log("KEY", key, value);
    pre[key] = value;
    dispatch(setQualtyCheckFilter({ filterQualityCheck: pre }));
    console.log("KEYY", { filterQualityCheck: pre });
  };

  const clearFieldHanlder = () => {
    props.resetTreeFilter();
    dispatch(clearQualityCheckFilter());
    props.setFilterValue("");
    props.setReload(!props.reload);
    props.getFilterData();

    setDependency(null);
  };

  const submitFilter = () => {
    console.log("PROP", props?.JobIdCheck);

    const formData = {
      JobIds: filterQualityCheck.jobids?.map((e) => e.id),
      JobId: props?.JobIdCheck,
      OwnerName: props.companyFilter
        .filter((el) => el.checked === true)
        .map((el) => el.value),
      AssetName: props.companyFilter
        .map((el) =>
          el.children.filter((it) => it.checked === true).map((tk) => tk.value)
        )
        .filter((item) => item.length > 0)
        .flat(1),
      //AssetName: filterQualityCheck.asset?.map((e) => e.assetName),
      tat: props.tatDate,
      CompletionDate: props.jobCompletionDate,
      CompletedBy: filterQualityCheck.completedby?.map((e) => e.userId),
    };
    console.log(formData, "formData");
    // this data will be send to the body api
    props.setFilterValue(formData);
    props.setState({ ...props.state, right: false });
  };
  const getAllJobIds = () => {
    ApiService.getAll(
      "OwnershipChangeQualityManagement/GetJobNoNameDropdownforQualityCO"
    )
      .then((res) => {
        if (res.status === 200) {
          setJobIds(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };
  const getAllAssets = () => {
    ApiService.getAll("DropDown/GetAssetforQualityDropDown", null)
      .then((res) => {
        if (res.status === 200) {
          setAsset(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };
  const getAllCompletedUsers = () => {
    ApiService.getAll(
      "OwnershipChangeQualityManagement/GetCompletedByFilterAsync"
    )
      .then((res) => {
        if (res.status === 200) {
          setCompletedBy(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

  React.useEffect(() => {
    getAllJobIds();
    //dispatch(GetAssetData());
    getAllCompletedUsers();
    getAllAssets();
  }, []);

  const onChangeComp = (currentNode, selectedNodes) => {
    setDependency("company");

    setSelectedCompanies(selectedNodes);

    props.companyFilter.forEach((el) => {
      if (currentNode.checked === true) {
        if (currentNode.value === el.value) {
          el.checked = true;
        } else if (currentNode.parentValue === el.value) {
          el.children.forEach((item) => {
            if (currentNode.value === item.value) {
              item.checked = true;
            }
          });
        }
      } else if (currentNode.checked === false) {
        if (currentNode.value === el.value) {
          el.checked = false;
        } else if (currentNode.parentValue === el.value) {
          el.children.forEach((item) => {
            if (currentNode.value === item.value) {
              item.checked = false;
            }
          });
        }
      }
    });

    const firstParent = props.companyFilter
      .map((el) => {
        let data = {
          label: el.label,
          value: el.value,
          children: el.children.filter((item) => item["checked"] === true),
        };
        return data;
      })
      .filter((el) => el.children.length > 0);

    setApiData({
      ...apiData,
      companies:
        props.companyFilter.filter((el) => el["checked"] === true).length > 0
          ? props.companyFilter.filter((el) => el["checked"] === true)
          : firstParent,
    });
  };

  //console.log(props.companyFilter, "companyFIlter")

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Add & Edit Frequency Form */}
      {console.log("JBD", filterQualityCheck)}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterQualityCheck.jobids}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            items={jobids}
            label="Job No"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            role="jobids"
          />
        </Grid>
        {/* <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterQualityCheck.asset}
            getOptionSelected={(option, anotherOption) =>
              option.assetName === anotherOption.assetName
            }
            items={asset}
            label="Asset"
            selectAllLabel={{ assetName: "Select all" }}
            getOptionLabel={(option) => option.assetName}
            role="asset"
          />
        </Grid> */}
        <Grid item xs={12}>
          <Container
            data={props.companyFilter}
            onChange={onChangeComp}
            showPartiallySelected={true}
            texts={{ placeholder: "Company" }}
          />
        </Grid>
        <Grid item xs={12}>
          {console.log(props.tatDate, "tatData")}
          <DatePicker
            labelTitle="TAT"
            placeHolder="DD-MM-YYYY"
            variant="standard"
            past={false}
            defaultDate={props.tatDate}
            onChange={onChangeHandler}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              props.setTatDate(
                selectedDate.getFullYear() +
                  "-" +
                  (selectedDate.getMonth() + 1) +
                  "-" +
                  selectedDate.getDate()
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {console.log(props.jobCompletionDate, "jobCompletionDate")}
          <DatePicker
            labelTitle="Job Completion Date"
            placeHolder="DD-MM-YYYY"
            variant="standard"
            past={false}
            defaultDate={props.jobCompletionDate}
            onChange={onChangeHandler}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              props.setJobCompletionDate(
                selectedDate.getFullYear() +
                  "-" +
                  (selectedDate.getMonth() + 1) +
                  "-" +
                  selectedDate.getDate()
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterQualityCheck.completedby}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            items={completedby}
            label="Completed By"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            role="completedby"
          />
        </Grid>

        <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="left">
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn"
                color="accent5"
                onClick={submitFilter}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn reset-color"
                color="accent3"
                onClick={clearFieldHanlder}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QualityCheckFilter;
