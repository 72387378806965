// Default React, Router and Services Components
import React, { useContext, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
// Importing Custom Components
import BaseLayout from "../../../../UIComponent/BaseLayout";
import EmptyState from "../../../../UIComponent/EmptyState";
import { Link } from "react-router-dom";
// Importing useStyles
import useStyles from "./CRUReviewReport.styles";

// Import Fluent UI Icons
import {
  ChevronRightSmallIcon,
  CodeEditIcon,
  FileOffIcon,
} from "@fluentui/react-icons-mdl2";
import { FilterContext } from "../../../DataCollection/DashboardReports/Context/Context";
import axios from "axios";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
import DatePicker from "../../../../UIComponent/DatePicker";
import ExportToExcel from "./Excelexport";
import { LoaderWrapper } from "../../../../UIComponent/Progress";
import FilterForm from "./FilterForm";
import SideDrawer from "../../../../UIComponent/Drawer";
// import history from "../../../../Routes/History";
import { useHistory } from "react-router-dom";

const OwnershipChangesCRUReviewReport = (props) => {
  const history = useHistory();
  const {
    companyListCRU,
    setCompanyListCRU,
    locationCRU,
    setLocationCRU,
    filterValueContext,
    dateRange,
    setDateRange,
    priorityId,
    setPriorityId,
  } = useContext(FilterContext);
  const [state, setState] = useState({ right: false });
  const [crudState, setCrudState] = React.useState(false);
  const [sorting, setSorting] = useState({ orderBy: "", order: false });
  const [formData, setFormData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const date = new Date().toLocaleDateString();
  const date1 = date.split("/").join("-");
  const [filterValue, setFilterValue] = useState({});
  const [keyFromDate, setKeyFromDate] = useState(false);
  const [keyToDate, setKeyToDate] = useState(false);
  const classes = useStyles();
  const [searchData, setSerachData] = useState({
    FromDate: null,
    ToDate: null,
  });
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValues, setFilterValues] = useState();
  const [companyFilter, setCompanyFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLsoading] = useState(false);

  // Declaration of States and Component Variables End Here

  React.useEffect(() => {
    fetchData();
  }, [filterValue]);
  React.useEffect(() => {
    getFilterData();
  }, [reload]);


// This function is used to get CRU data  Review from the server
const getData = async () => {
  // Create a new promise and wait for it to resolve
  let response = await new Promise((resolve, reject) => {
    // Resolve the promise with an axios GET request
    resolve(
      axios.get(
        `${process.env.React_App_baseUrl}CompanyOwnershipReport/GetCRUReviewReportCompanyOwnership`,
        {
          // Set headers for the request
          headers: {
            filter: filterValueContext
              ? encodeURIComponent(JSON.stringify(filterValueContext))
              : null,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
    );
  });
  // Return the response
  return response;
};

  const fetchData = async () => {
    setIsLsoading(true);
    try {
      const { data } = await getData();
      setTableData(data);
      setIsLsoading(false);
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res);
      });
      setIsLsoading(false);
    }
  };

  // This function is used to get filter data from the API
const getFilterData = async () => {
  try {
    // Making a GET request to the API
    const response = await axios.get(
      `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`,
      {
        headers: {
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      }
    );
    // Checking if the response data length is 0
    if (response.data.length === 0) {
      setDataForFilter([]);
    } else {
      // Setting the data for filter
      setDataForFilter(response.data);
      // Looping through companies and setting checked to false
      response.data.companies.forEach((item, index) => {
        item.checked = false;
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
        }
      });
      // Looping through location and setting checked to false
      response.data.location.regions.forEach((item, index) => {
        item.checked = false;
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
          }
        }
      });
      // Checking if company list CRU length is 0
      if (companyListCRU.length === 0) {
        setCompanyListCRU(response.data.companies);
      }
      // Checking if location CRU length is 0
      if (locationCRU.length === 0) {
        setLocationCRU(response.data.location.regions);
      }
      // Setting company filter and location filter
      setCompanyFilter(response.data.companies);
      setLocationFilter(response.data.location.regions);
    }
  } catch (error) {
    // Calling error handling function
    ErroHandling(error, function (res) {
      toasterError(res);
    });
  }
};
 

// This function is used to get the CRU Review Report data from the server
const searchDataDateRange = function () {
  // Make an axios request to the server with the filter and authorization headers
  axios
    .get(`${process.env.React_App_baseUrl}reports/GetCRUReviewReport`, {
      headers: {
        filter: searchData
          ? encodeURIComponent(JSON.stringify(searchData))
          : null,
        Authorization: localStorage.getItem("token")
          ? "Bearer " + localStorage.getItem("token")
          : null,
      },
    })
    // Set the table data and date range based on the response from the server
    .then((resp) => {
      setTableData(resp.data);
      setDateRange(searchData);
    });
};
  // const clearDataDateRange = function () {
  //   setKeyFromDate((k) => !k);
  //   setKeyToDate((t) => !t);
  //   axios.get(`${process.env.React_App_baseUrl}reports/GetCRUReviewReport`, {
  //     headers: { filter: null,
  //         'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
  //        },
  //   }).then((resp) => { setTableData(resp.data);
  //     setDateRange({})
  //   });
  // };

  // Function for Filter Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    setState({ ...state, right: open });
  };

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} className="page-title">
          <h2>CRU Review Report</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/ownership-changes/source-management" }}
            >
              Home
            </Link>
            <Typography underline="hover" color="inherit" href="/">
              Manage Company Ownership Changes
            </Typography>
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/ownership-changes/status-report" }}
            >
              Dashboard and Reports
            </Link>
            <Typography color="text.primary">CRU Review Report</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* BaseLayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            {/* <Box sx={{ pt: 11 }}>
              <center>
                <EmptyState
                  icon={
                    <CodeEditIcon
                      style={{ color: "#db9400", fontSize: "70px", marginBottom: "10px" }}
                    />
                  }
                  title="Under Construction!"
                />
              </center>
            </Box> */}
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  alignItems="flex-end"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={12} xl={8}>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="flex-start"
                      alignItems="flex-end"
                    >
                      {/*  <Grid item xs={"auto"}>
                        <DatePicker
                          labelTitle="From Date"
                          variant="standard"
                          defaultDate={null}
                          onChangeDate={(e) => {
                            let selectedDate = new Date(e);
                            let date =
                              selectedDate.getFullYear() +
                              "-" +
                              ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + selectedDate.getDate()).slice(-2);
                            setSerachData({ ...searchData, FromDate: date });
                          }}
                          past={false}
                          clearable={true}
                          key={keyFromDate}
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <DatePicker
                          labelTitle="To Date"
                          variant="standard"
                          past={false}
                          minDates={searchData.FromDate}
                          defaultDate={null}
                          onChangeDate={(e) => {
                            let selectedDate = new Date(e);
                            let date =
                              selectedDate.getFullYear() +
                              "-" +
                              ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + selectedDate.getDate()).slice(-2);
                            setSerachData({ ...searchData, ToDate: date });
                          }}
                          key={keyToDate}
                          clearable={true}
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={searchDataDateRange}
                        >
                          Search
                        </Button>
                      </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} xl={4} textAlign="right">
                    <Grid
                      container
                      spacing={2}
                      justifyContent="flex-end"
                      alignItems="flex-end"
                    >
                      <Grid item xs={"auto"}>
                        <Typography variant="subtitle2">
                          <ExportToExcel
                            apiData={tableData}
                            fileName={`CRUReviewStatusReport ${date1}`}
                            cruReviewReport
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={"auto"}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={toggleDrawer(true)}
                        >
                          Filter
                        </Button>
                      </Grid>
                      {/* <Grid item xs={"auto"}>
          <Button variant="contained" color="accent1" onClick={clearDataDateRange}>
            Clear
          </Button>
        </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* CRU Review Report Table */}
              <Grid item xs={12}>
                <LoaderWrapper loading={isLoading}>
                  <TableContainer
                    component={Paper}
                    elevation={0}
                    className="table-container"
                  >
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className={classes.tableHeader}
                            style={{ minWidth: 150, width: "15%" }}
                          >
                            Job Priority
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            align="center"
                            style={{ minWidth: 200, width: "21%" }}
                          >
                            Passed with no errors
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            align="center"
                            style={{ minWidth: 200, width: "21%" }}
                          >
                            Passed with CRU review errors
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            align="center"
                            style={{ minWidth: 200, width: "21%" }}
                          >
                            CRU Review failed
                          </TableCell>
                          <TableCell
                            className={classes.tableHeader}
                            align="center"
                            style={{ minWidth: 200, width: "21%" }}
                          >
                            Total
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData.length > 0 ? (
                          tableData?.map((item, index) => (
                            <TableRow key={index} hover>
                              <TableCell className={classes.tableCell}>
                                {item.priority}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="center"
                              >
                                <span
                                  className={classes.tableLink}
                                  onClick={() => {
                                    history.push(
                                      `/app/ownership-changes/cru-review-report-drill?Priority=${item.priority}&ReportType=reviewPassedWithNoErrors`
                                    );
                                    setPriorityId(item.priority);
                                  }}
                                >
                                  {item.reviewPassedWithNoErrors}
                                </span>
                              </TableCell>
                              {/* </Link> */}
                              <TableCell
                                className={classes.tableCell}
                                align="center"
                              >
                                <span
                                  className={classes.tableLink}
                                  onClick={() => {
                                    setPriorityId(item.priority);
                                    history.push(
                                      `/app/ownership-changes/cru-review-report-drill?Priority=${item.priority}&ReportType=reviewPassedWithCRUReviewErrors`
                                    );
                                  }}
                                >
                                  {item.reviewPassedWithCRUReviewErrors}
                                </span>
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="center"
                              >
                                <span
                                  className={classes.tableLink}
                                  onClick={() => {
                                    history.push(
                                      `/app/ownership-changes/cru-review-report-drill?Priority=${item.priority}&ReportType=cruReviewFailed`
                                    );
                                    setPriorityId(item.priority);
                                  }}
                                >
                                  {item.cruReviewFailed}
                                </span>
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="center"
                              >
                                <span
                                  className={classes.tableLink}
                                  onClick={() => {
                                    history.push(
                                      `/app/ownership-changes/cru-review-report-drill?Priority=${"total"}&ReportType=total`
                                    );
                                    setPriorityId(item.priority);
                                  }}
                                >
                                  {item.total}
                                </span>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              className={classes.tableCell}
                              align="center"
                              colSpan={99}
                            >
                              <EmptyState
                                icon={
                                  <FileOffIcon
                                    style={{
                                      color: "#db9400",
                                      fontSize: "40px",
                                    }}
                                  />
                                }
                                title="No Data Found"
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </LoaderWrapper>
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
      {/* Filter Side Drawer */}
      <SideDrawer
        formComp={
          // Filter Form
          <FilterForm
            dataForFilter={dataForFilter}
            filterFinalData={filterFinalData}
            companyFilter={companyFilter}
            locationFilter={locationFilter}
            setFilterValues={setFilterValues}
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            userId={null}
            setFilterValue={setFilterValue}
            setLocationFilter={setLocationFilter}
            hideSideDrawer={() => {
              setState({ right: false });
            }}
            reload={reload}
            setReload={setReload}
            companyListCRU={companyListCRU}
            locationCRU={locationCRU}
            setCompanyListCRU={setCompanyListCRU}
            setLocationCRU={setLocationCRU}
          />
        }
        state={state}
        setState={setState}
        reload={reload}
        setReload={setReload}
        setFilterValue={setFilterValue}
      />
    </Box>
  );
};

export default OwnershipChangesCRUReviewReport;
