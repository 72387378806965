// Default React, useState and Material Components
import * as React from "react";
import { useState } from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  CircularProgress
} from "@mui/material";
import { Link } from "react-router-dom";
// Importing Custom Components
import AddReportInfo from "./AddReportInfo";
import AddOwnerInfo from "./AddOwnerInfo";
import AddJointVenture from "./AddJointVenture";
import SideDrawer from "../../../UIComponent/Drawer";
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AddSeeThroughOwnerInfo from "./AddSeeThroughOwnerInfo";
import OwnershipDetailsReportInfo from "./OwnershipDetailsReportInfo";
// Accordion Components
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

// Import Material & Fluent UI Icons
import AddIcon from "@mui/icons-material/Add";
import PlaceIcon from "@mui/icons-material/Place";
import RemoveIcon from "@mui/icons-material/Remove";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  ChevronRightSmallIcon,
  ChevronLeftIcon
} from "@fluentui/react-icons-mdl2";


// Importing useStyles and Styles
import { styled } from "@mui/material/styles";
import useStyles from "./jobManagement.styles";

import CommonService from "../../../Services/CommonService";
import OwnershipChangesJobInfo from "./OwnershipChangesJobinfo";
import OwnershipChangesAssetInfo from "./OwnershipChangesAssetinfo";
import OwnershipChangesUpdates from "./OwnershipChangesUpdates";
import AddUpdateInfo from "./AddUpdateInfo";
import OwnershipChangesOwnerInfo from "./OwnershipChangesOwnerInfo";
import OwnershipJoinVenture from "./OwnershipJoinVenture";
import AddJointVentureInfo from "./AddJoinVenture";
import { useHistory } from "react-router-dom";

import axios from "axios";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import AlertDialog from "../../../UIComponent/AlertDialog";
// import history from "../../../Routes/History";
import ErroHandling from "../../../Services/ErrorHandling";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<AddIcon sx={{ fontSize: "0rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === 'dark'
  //     ? 'rgba(255, 255, 255, .05)'
  //     : 'rgba(0, 0, 0, .03)',
  padding: theme.spacing(0),
  flexDirection: "row-reverse",
  "& .MuiButtonBase-root-MuiAccordionSummary-root": {
    padding: theme.spacing(0),
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(45deg)",
  },

  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1, 0, 1, 0),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const OwnerCorrectOwnerName = (props) => {
  const history = useHistory();
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [state, setState] = useState({ right: false });
  const [State2, setState2] = useState({ right: false });
  const [State3, setState3] = useState({ right: false });
  const [State4, setState4] = useState({ right: false });
  const [State5, setState5] = useState({ right: false });
  const [finalData, setFinalData] = useState({ isCRUAsset: true });
  const [showReportInfo, setShowReportInfo] = React.useState(false);
  const [showJointVenture, setShowJointVenture] = React.useState(false);
  const [showAddOwnerInfo, setShowAddOwnerInfo] = React.useState(false);
  const [showAddUpdateInfo, setShowAddUpdateInfo] = React.useState(false);
  const [ownerPercent, setOwnerPercent] = React.useState(0);
  const [openConfirmfileDeleteAlart, setopenConfirmfileDeleteAlart] =
    useState(false);
  const [showSeeThroughOwnerInfo, setShowSeeThroughOwnerInfo] =
    React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [updatesExpanded, setUpdatesExpanded] = React.useState(true);
  const [ownerInfoExpanded, setOwnerInfoExpanded] = React.useState(true);
  const [assetInfoExpanded, setAssetInfoExpanded] = React.useState(true);
  const [crudState, setCrudState] = React.useState(false);
  const [ReportData, setReportData] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeUpdate = (panel) => (event, isExpanded) => {
    setUpdatesExpanded(isExpanded ? panel : false);
  };

  const handleOwnerInfoChange = (panel) => (event, isExpanded) => {
    setOwnerInfoExpanded(isExpanded ? panel : false);
  };

  const handleChangeAssetInfo = (panel) => (event, isExpanded) => {
    setAssetInfoExpanded(isExpanded ? panel : false);
  };
  
  const [preselectedAsset, setPreselectedAsset] = React.useState(null);
  const [preselectedOwner, setPreselectedOwner] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const [prefillData, setPreFillData] = useState([]);
  const [assetdropdown, setAssetDropdown] = useState([]);
  //Setting States for the Top Columns
  const [tempData, setTempData] = useState();
  const [jobAssetId, setjobAssetId] = useState(0);
  const [isQualityPage, setIsQualityPage] = useState();
  const [jobId, setjobId] = useState(0);
  const [access, setAccess] = useState(true)
  const [dataPoints, setDataPoints] = useState();
  const [componentMount, setComponentMount] = useState(false);
  const [bindUnloadFunc, setBindUnloadFunc] = useState(true);
  const [reload, setReload] = useState(false)
  const [saveOwnerInfo, setSaveOwnerInfo] = useState()
  //console.log(tempData);
  const cleanup = (event) => {
    if (event?.preventDefault) {
      event?.preventDefault();
    }
    if (jobAssetId !== undefined && jobAssetId !== null && jobAssetId > 0) {
      axios.get(process.env.React_App_baseUrl + "ManageOwnership/GetEndDateEntryForJobAsset", {
        headers: {
          'Authorization': CommonService.getToken(),
          'jobAssetId': jobAssetId
        }
      }).catch((err) => {
        ErroHandling(err, function (res) {
        });
      });

      console.log(tempData);

    }
    console.log('unmount call');
    //return event.returnValue="";  
  }
  React.useEffect(() => {
    GetDataFromTempStorage();
    console.log("get", props);
  }, []);

  React.useEffect(() => {
    if (jobAssetId !== undefined && jobAssetId !== null && jobAssetId > 0) {
      if (!componentMount) {
        setComponentMount(true);
        window.addEventListener('beforeunload', cleanup);
        window.addEventListener('unload', cleanup);

        axios.get(process.env.React_App_baseUrl + "ManageOwnership/GetStartDateEntryForJobAsset", {
          headers: {
            'Authorization': CommonService.getToken(),
            'jobAssetId': jobAssetId
          }
        }).catch((err) => {
          ErroHandling(err, function (res) {
          });
        });
        // console.log("mount call -",componentMount)
      }
    }
    return () => {
      cleanup();
      window.removeEventListener('beforeunload', cleanup);
      window.removeEventListener('unload', cleanup);
    }
  }, [jobAssetId])



  const GetDataFromTempStorage = () => {
    let temp = CommonService.GetStoredData(props, "ownershipjobManagement");
    setIsQualityPage(temp.state?.isQualityPage)
    setTempData(temp);
    if (temp?.state?.jobAssetId) {
      setjobAssetId(temp.state.jobAssetId);
    }
  };
  React.useEffect(() => {
    var role = localStorage.getItem('role')
    if (tempData !== undefined && tempData !== null) {
      console.log("tempData.state.statusName", tempData?.state?.statusName)
      if (tempData.state.jobId) {
        setjobId(tempData.state.jobId);
        console.log("job", tempData.state.jobId);
      }

      if (tempData.state.jobAssetId) {
        setjobAssetId(tempData.state.jobAssetId);
        //  setFinalData({ ...finalData, jobAssetId: tempData.state.jobAssetId });
      }
      if (tempData.state.statusName) {
        var b = !((role === "Merit Analyst") && (tempData.state.statusName === "Merit Quality Control" || tempData.state.statusName === "Merit QC Passed" || tempData.state.statusName === "Merit QC Passed with Errors" || tempData.state.statusName === "CRU Review Passed" || tempData.state.statusName === "CRU Review Passed with Errors"))
        setAccess(b)
      }
    }
  }, [tempData]);

  // Assigning Columns and UI Props
  const columns = [
    { id: "id", label: "Status", type: "Highlight" },
    { id: "slNo", label: "Sl.No", minWidth: 90, width: "8%", align: "center" },
    { id: "year", label: "Year", minWidth: 100, width: "8%" },
    { id: "reportType", label: "Report Type", minWidth: 250, width: "25%" },
    { id: "reportName", label: "Report Name", minWidth: 250, width: "29%" },
    {
      id: "linksAndAttachments",
      label: "Links and Attachments",
      minWidth: 200,
      width: "20%",
    },
    {
      id: "actions",
      label: "Action",
      minWidth: 110,
      width: "10%",
      align: "center",
    },
  ];

  // Top 100 films
  const top100Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: "Pulp Fiction", year: 1994 },
  ];

  // Add Report Info Drawer
  const toggleDrawer = (open, mode) => (event) => {
    setState({ ...state, right: open });
    setShowReportInfo(true);

    setCrudState(mode);
  };

  // Add See Through Owner Info Drawer
  const toggleDrawer2 = (open) => (event) => {
    setState2({ ...state, right: open });
    setShowSeeThroughOwnerInfo(true);
  };

  // Add Joint Venture Drawer
  const toggleDrawer3 = (open, mode) => (event) => {
    setState3({ ...state, right: open });
    setShowJointVenture(true);
    setCrudState(mode);
  };
  // Add Owner Info Drawer
  const toggleDrawer4 = (open) => (event) => {
    setState4({ ...state, right: open });
    setShowAddOwnerInfo(true);
  };
  // Add Update Info Drawer
  const toggleDrawer5 = (open, mode) => (event, item) => {
    console.log("PRBLM", mode, item);
    //setShowAddUpdateInfo(true);
    //getAssetInfo();
    setPreFillData(item);
    setState5({ ...State5, right: open, prefillData: item });
    setCrudState(mode);
  };
  // Declaration of States and Component Variables EndF Here
  const getAssetInfo = async () => {
    // setIsOpenAssign(false);
    if (jobAssetId === undefined || jobAssetId === null || jobAssetId === 0) {
      return;
    }
    axios
      .post(
        `${process.env.React_App_baseUrl}ManageOwnership/GetJobAssetInfo`,
        null,
        {
          headers: {
            jobAssetId: jobAssetId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        // Find the preselected asset object based on matching asset IDs
        const preselected = assetdropdown.find(
          (e) => e.assetGUID === res.data.assetGUID
        );
        // Set the preselectedAsset state
        setPreselectedAsset(preselected);
        setPreselectedOwner({owner : res.data.owner, ownerGuid : res.data.ownerGUID, commodityIds : res.data.commodityIds});
        console.log("ASSETD", preselected, res.data.assetGUID);
      })
      .catch((err) => {
        {
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        }
        // setOpenDialog(false);
      });
  };
  React.useEffect(() => {
    if (jobId === undefined || jobId === null || jobId === 0) {
      return;
    }
    axios
      .post(
        process.env.React_App_baseUrl + "ManageOwnership/GetJobAssetIds",
        null,
        {
          headers: {
            jobId: jobId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setAssetDropdown(res.data);
        console.log("", res.data);
      });
  }, [jobId]);

  React.useEffect(() => {
    if (assetdropdown.length > 0) {
      // Check if assetdropdown is not empty
      getAssetInfo();
    }
  }, [assetdropdown]);

  React.useEffect(() => {
    getJobInfo()
  }, [jobAssetId])

  const getJobInfo = async () => {
    if (jobAssetId === undefined || jobAssetId === null || jobAssetId === 0) {
      return;
    }
    await axios
      .post(
        `${process.env.React_App_baseUrl}ManageOwnership/GetJobDetails`,
        null,
        {
          headers: {
            jobAssetId: jobAssetId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setDataPoints(res?.data?.dataPoints)
      })
    // setIsOpenAssign(false);
  };

  const submit = () => {
    console.log("setFinalData",finalData, saveOwnerInfo)
    if(finalData?.operatorId){
    var sendData = {
      "jobAssetId": finalData?.jobAssetId,
      "isCRUAsset": finalData?.isCRUAsset,
      "assetId": finalData?.assetId,
      "operatorId": finalData?.operatorId,
      "latitude": finalData?.latitude,
      "longitude": finalData?.longitude,
      "operatorComment":finalData?.operatorComment,
      "operatorSourceFile":finalData?.editOwnerReportDetails,
      "saveOwnerInfo": saveOwnerInfo
    }
    console.log("Sumbit",sendData)
      axios
        .post(
          process.env.React_App_baseUrl + "ManageOwnership/SubmitOrSaveDraft",
          sendData,
          {
            headers: {
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
              jobAssetId: jobAssetId,
              isSubmitted: true,
              isQualityPage:isQualityPage
            },
          }
        )
        .then(() => {
          toasterSuccess("Job Submitted Successfully");

          axios
            .post(
              process.env.React_App_baseUrl +
              "ManageOwnership/AddAuditLogAfterSubmit",
              finalData,
              {
                headers: {
                  Authorization: CommonService.getToken(),
                  jobAssetId: jobAssetId,
                },
              }
            )
            .then(() => { })
            .catch(() => { });
          history.push("/app/ownership-changes/manage-job-records");
        })
        .catch((err) => {
          toasterError("Something went wrong");
        });
      }else{
        toasterError("Please select operator");

      }
    
  };

  const submitManageOwnershipDetails = () =>{
    var ownerpercent = Number(
      ownerPercent + saveOwnerInfo.ownershipPercentage
    );
    if (ownerpercent <= 100) {
      setopenConfirmfileDeleteAlart(true);
    }else{
      toasterError("Total Ownership Percentage is not more than 100. please check");
    }

  }

  const cancel = () => {
    history.push("/app/ownership-changes/manage-job-records");
  };
  const saveDraft = () => {
    if(finalData?.operatorId){
    setLoading(true);
    console.log("finalData", finalData)
    var sendData = {
      "jobAssetId": finalData?.jobAssetId,
      "isCRUAsset": finalData?.isCRUAsset,
      "assetId": finalData?.assetId,
      "operatorId": finalData?.operatorId,
      "latitude": finalData?.latitude,
      "longitude": finalData?.longitude,
      "operatorComment":finalData?.operatorComment,
      "operatorSourceFile":finalData?.editOwnerReportDetails,
      "saveOwnerInfo": saveOwnerInfo
    }
    axios
      .post(
        process.env.React_App_baseUrl + "ManageOwnership/SubmitOrSaveDraft",
        sendData,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
            jobAssetId: jobAssetId,
            isSubmitted: false,
            isQualityPage :isQualityPage
          },
        }
      )
      .then(() => {
        toasterSuccess("Job Saved Successfully");
        setLoading(false);
      })
      .catch((err) => {
        toasterError("Something went wrong");
        setLoading(false);
      });
    }else{
      toasterError("Please select operator");
    }
  };


  return (
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={9} className="page-title">
          <h2>Manage Ownership Details</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/ownership-changes/source-management" }}
            >
              Home
            </Link>
            <Typography>Manage Company Ownership Changes</Typography>
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/ownership-changes/create-job" }}
            >
              Job Management
            </Link>
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/ownership-changes/manage-job-records" }}
            >
              Assign Jobs
            </Link>
            <Typography color="text.primary">
              Manage Ownership Details
            </Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={3}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
            <Button
                variant="contained"
                color="accent2"
                startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
                onClick={() => {
                  history.push("/app/ownership-changes/manage-job-records");
                }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* BaseLayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box>
              <OwnershipChangesJobInfo
                tempData={tempData}
                jobId={jobId}
                jobAssetId={jobAssetId}
              />
              {/* Job Short Details End Here */}

              {/* Accordion Start Here */}
              <Box>
                {/* Asset Info Details Start Here*/}
                <Accordion
                  assetInfoExpanded={assetInfoExpanded === "panel1"}
                  onChange={handleChangeAssetInfo("panel1")}
                >
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={6}>
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <h4 className={classes.accHeadingText}>
                          {assetInfoExpanded === "panel1" ? (
                            <RemoveIcon
                              sx={{
                                fontSize: "1.75rem",
                                color: "#606060",
                                marginRight: 0.75,
                                marginTop: -0.5,
                              }}
                            />
                          ) : (
                            <AddIcon
                              sx={{
                                fontSize: "1.75rem",
                                color: "#606060",
                                marginRight: 0.75,
                                marginTop: -0.5,
                              }}
                            />
                          )}
                          Asset Info
                        </h4>
                      </AccordionSummary>
                    </Grid>
                  </Grid>
                  <OwnershipChangesAssetInfo
                    dataPoints={dataPoints}
                    tempData={tempData}
                    jobId={jobId}
                    access={access}
                    jobAssetId={jobAssetId}
                    setjobAssetId={setjobAssetId}
                    preselectedAsset={preselectedAsset}
                    preselectedOwner={preselectedOwner}
                    setPreselectedAsset={setPreselectedAsset}
                    setPreselectedOwner={setPreselectedOwner}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    reload={reload}
                    setReload={setReload}
                  />
                </Accordion>
                {/* Asset Info Details End Here*/}

                {/* Report Info Details Start Here*/}
                <OwnershipDetailsReportInfo
                  tempData={tempData}
                  jobId={jobId}
                  jobAssetId={jobAssetId}
                  access={access}
                  //reload={true}
                  getFileInfo={() => {
                    setReportData(!ReportData);
                    setReload(!reload)
                  }}
                />
                {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12} sm={12} md={6}>
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <h4 className={classes.accHeadingText}>
                          {expanded === "panel2" ? (
                            <RemoveIcon
                              sx={{
                                fontSize: "1.75rem",
                                color: "#606060",
                                marginRight: 0.75,
                                marginTop: -0.5,
                              }}
                            />
                          ) : (
                            <AddIcon
                              sx={{
                                fontSize: "1.75rem",
                                color: "#606060",
                                marginRight: 0.75,
                                marginTop: -0.5,
                              }}
                            />
                          )}
                          Report Info
                        </h4>
                      </AccordionSummary>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid
                        item
                        xs={12}
                        container
                        spacing={2}
                        justifyContent="flex-end"
                        alignItems="center"
                        textAlign="right"
                      >
                        <Grid item xs={"auto"}>
                          <Button
                            variant="contained"
                            color="accent7"
                            size="small"
                            startIcon={<AddIcon style={{ fontSize: 16 }} />}
                            onClick={toggleDrawer(true, "Add")}
                          >
                            Add report info
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <AccordionDetails>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="flex-start"
                      spacing={3}
                      sx={{ mb: 0 }}
                    >
                      <Grid item xs={12}>
                        <Box className={classes.tableBorder}>
                          <DataTable
                            // className={classes.removeBorder}
                            // loading={isLoading}
                            rows={[]}
                            columns={columns}
                            // page={page}
                            // setPage={(data) => {
                            //   setPage(data);
                            //   setReload(true);
                            // }}
                            // rowsPerPage={rowsPerPage}
                            // setRowsPerPage={(data) => {
                            //   setRowsPerPage(data);
                            //   setReload(true);
                            // }}
                            // totalCount={totalCount}
                            // isAllSelected={isAllSelected}
                            // setIsSelected={setIsSelected}
                            // selected={selected}
                            // setSelected={SetSelected}
                            // sorting={sorting}
                            // setSorting={setSorting}
                            // isActionButton={{
                            //   ...(access.allowEdit && {
                            //     edit: toggleDrawer(true, "Edit"),
                            //   }),
                            //   ...(access.allowDelete && {
                            //     delete: toggleDrawer(true, "Delete"),
                            //   }),
                            // }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {/* Report Info Details End Here*/}

                {/* Owner Info Details Start Here*/}
                <Accordion
                  ownerInfoExpanded={ownerInfoExpanded === "panel3"}
                  disabled={!(dataPoints?.owner || dataPoints?.ownershipPercentage || dataPoints?.ownershipDate)}
                  onChange={handleOwnerInfoChange("panel3")}
                >
                  <Grid container spacing={0} alignItems="center">
                    <Grid container spacing={0} alignItems="center">
                      <Grid item xs={12} sm={12} md={6}>
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <h4 className={classes.accHeadingText}>
                            {ownerInfoExpanded === "panel3" ? (
                              <RemoveIcon
                                sx={{
                                  fontSize: "1.75rem",
                                  color: "#606060",
                                  marginRight: 0.75,
                                  marginTop: -0.5,
                                }}
                              />
                            ) : (
                              <AddIcon
                                sx={{
                                  fontSize: "1.75rem",
                                  color: "#606060",
                                  marginRight: 0.75,
                                  marginTop: -0.5,
                                }}
                              />
                            )}
                            Owner Info
                          </h4>
                        </AccordionSummary>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Grid
                          item
                          xs={12}
                          container
                          spacing={2}
                          justifyContent="flex-end"
                          alignItems="center"
                          textAlign="right"
                        >
                          {/* <Grid item xs={"auto"}>
                            <Button
                              variant="contained"
                              color="accent7"
                              size="small"
                              startIcon={<AddIcon style={{ fontSize: 16 }} />}
                              onClick={toggleDrawer4(true, "Add")}
                            >
                              Add Owner info
                            </Button>
                          </Grid> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <OwnershipChangesOwnerInfo
                    dataPoints={dataPoints}
                    tempData={tempData}
                    jobId={jobId}
                    jobAssetId={jobAssetId}
                    finalData={finalData}
                    setFinalData={setFinalData}
                    setSaveOwnerInfo={setSaveOwnerInfo}
                    ReportData={ReportData}
                    access={access}
                    reload={reload}
                    setReload={setReload}
                  />
                </Accordion>
                {/* Owner Info Details Start Here*/}

                {/* Joint Venture Details Start Here*/}
                <Accordion
                  disabled={!(dataPoints?.joinVentures)}
                 // expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <Grid container spacing={0} alignItems="center">
                    <Grid container spacing={0} alignItems="center">
                      <Grid item xs={12} sm={12} md={6}>
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <h4 className={classes.accHeadingText}>
                            {expanded === "panel4" ? (
                              <RemoveIcon
                                sx={{
                                  fontSize: "1.75rem",
                                  color: "#606060",
                                  marginRight: 0.75,
                                  marginTop: -0.5,
                                }}
                              />
                            ) : (
                              <AddIcon
                                sx={{
                                  fontSize: "1.75rem",
                                  color: "#606060",
                                  marginRight: 0.75,
                                  marginTop: -0.5,
                                }}
                              />
                            )}
                            Joint Venture
                          </h4>
                        </AccordionSummary>
                      </Grid>
                      {access ? <>
                        <Grid item xs={12} sm={12} md={6}>
                          <Grid
                            item
                            xs={12}
                            container
                            spacing={2}
                            justifyContent="flex-end"
                            alignItems="center"
                            textAlign="right"
                          >
                            <Grid item xs={"auto"}>
                              <Button
                                variant="contained"
                                disabled={!(dataPoints?.joinVentures)}
                                color="accent7"
                                size="small"
                                startIcon={<AddIcon style={{ fontSize: 16 }} />}
                                onClick={toggleDrawer3(true, "Add")}
                              >
                                Add joint venture
                              </Button>
                            </Grid>
                            {/* <Grid item xs={"auto"}>
                            <Button
                              variant="contained"
                              color="accent7"
                              size="small"
                              startIcon={<AddIcon style={{ fontSize: 16 }} />}
                              onClick={toggleDrawer(true, "Add")}
                            >
                              Add Report Info
                            </Button>
                          </Grid> */}
                          </Grid>
                        </Grid></> : <></>}
                    </Grid>
                  </Grid>
                  <AccordionDetails>
                    <OwnershipJoinVenture
                      classes={classes}
                      toggleDrawer2={toggleDrawer2}
                      dataPoints={dataPoints}
                      jobAssetId={jobAssetId}
                      jobId={jobId}
                      access={access}
                      setState3={setState3}
                      State3={State3}
                      showSeeThroughOwnerInfo={showSeeThroughOwnerInfo}
                      crudState={crudState}
                      setShowSeeThroughOwnerInfo={setShowSeeThroughOwnerInfo}
                      setOwnerPercent={setOwnerPercent}
                      ownerPercent={ownerPercent}
                      //reload={true}
                      reload={reload}
                      setReload={setReload}
                    />
                  </AccordionDetails>
                </Accordion>
                {/* Joint Venture Details Start Here*/}
              </Box>
              {/* Accordion End Here */}

              {/* Updates Details Start Here*/}
              <Accordion
               updatesExpanded={updatesExpanded === "panel5"}
                onChange={handleChangeUpdate("panel5")}
                disabled={!(dataPoints?.updates)}
              >
                <Grid container spacing={0} alignItems="center">
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12} sm={12} md={6}>
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <h4 className={classes.accHeadingText}>
                          {updatesExpanded === "panel5" ? (
                            <RemoveIcon
                              sx={{
                                fontSize: "1.75rem",
                                color: "#606060",
                                marginRight: 0.75,
                                marginTop: -0.5,
                              }}
                            />
                          ) : (
                            <AddIcon
                              sx={{
                                fontSize: "1.75rem",
                                color: "#606060",
                                marginRight: 0.75,
                                marginTop: -0.5,
                              }}
                            />
                          )}
                          Updates
                        </h4>
                      </AccordionSummary>
                    </Grid>
                    {access ? <>
                      <Grid item xs={12} sm={12} md={6}>
                        <Grid
                          item
                          xs={12}
                          container
                          spacing={2}
                          justifyContent="flex-end"
                          alignItems="center"
                          textAlign="right"
                        >
                          <Grid item xs={"auto"}>
                            <Button
                              variant="contained"
                              color="accent7"
                              disabled={!(dataPoints?.updates)}
                              size="small"
                              startIcon={<AddIcon style={{ fontSize: 16 }} />}
                              onClick={toggleDrawer5(true, "Add")}
                            >
                              Add Update
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid></> : <></>}
                  </Grid>
                </Grid>
                <OwnershipChangesUpdates
                  tempData={tempData}
                  jobId={jobId}
                  jobAssetId={jobAssetId}
                  toggleDrawer5={toggleDrawer5}
                  access={access}
                /* crudState={crudState}
                setState5={setState5}
                State5={State5}
               
                prefillData={prefillData ? prefillData : ""}
                setPreFillData={setPreFillData}
                preselectedAsset={preselectedAsset}
                setPreselectedAsset={setPreselectedAsset} */
                />
              </Accordion>
              {/* Report Info Details End Here*/}

              {/* Buttons Start Here*/}
              {access ? <>
                <Grid item xs={12} mt={1}>
                  <Grid container spacing={2} justifyContent="left">
                    <Grid item xs={12} sm={"auto"}>
                      <Button
                        fullWidth
                        className={classes.largeButtonAuto}
                        variant="contained"
                        color="accent5"
                        onClick={() => {
                          submitManageOwnershipDetails()
                          //setopenConfirmfileDeleteAlart(true);
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={"auto"}>
                      <Button
                        fullWidth
                        className={classes.largeButtonAuto}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          saveDraft(false);
                        }}
                        style={{
                          color: loading ? "transparent" : "",
                          position: "relative", height: "100%", width: "auto"
                        }}
                      >
                        save draft
                        {loading ? (
                          <div style={{ position: "absolute", top: "calc(50% - 11px)", right: "38%" }} >
                            <CircularProgress size={20} />
                          </div>
                        ) : null}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={"auto"}>
                      <Button
                        fullWidth
                        className={classes.largeButtonAuto}
                        variant="contained"
                        color="accent3"
                        onClick={() => {
                          cancel();
                        }}
                      >
                        cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid></> : <></>}
              {/* Buttons End Here*/}
            </Box>
          </BaseLayout>
        </Grid>

        {/* Add & Edit Report Info Drawer */}

        {/* Add & Edit See Through Owner Info Drawer */}

        <SideDrawer
          formComp={
            <AddSeeThroughOwnerInfo
              state={State2}
              setState={setState2}
            // crudState={crudState}
            // formData={formData}
            // setFormData={setFormData}
            // userId={null}
            // hideSideDrawer={() => {
            //   setState({ right: false });
            //   getSubRegionData();
            // }}
            // subRegionData={subRegionData}
            />
          }
          state={State2}
          setState={setState2}
          crudState={showSeeThroughOwnerInfo}
        />
        {console.log("ASSETD", props, preselectedAsset)}
        <SideDrawer
          formComp={
            // Calling Add User
            <AddUpdateInfo
              state={State5}
              setState={setState5}
              crudState={crudState}
              prefillData={prefillData ? prefillData : ""}
              setPreFillData={setPreFillData}
              preselectedAsset={preselectedAsset}
              preselectedOwner={preselectedOwner}
              setPreselectedAsset={setPreselectedAsset}
              setPreselectedOwner={setPreselectedOwner}
              jobAssetId={jobAssetId}
              setRefresh={setRefresh}
              jobId={jobId}
              /* userId={null} */
              hideSideDrawer={() => {
                setState5({ right: false });
                if (props?.getGridData) {
                  props?.getGridData();
                }
                setReload(!reload)
              }}
            />
          }
          state={State5}
          setState={setState5}
        //crudState={props.crudState}
        />
        {/* Add & Edit Joint Venture Info Drawer */}

        {/* <SideDrawer
        formComp={
          <AddJointVentureInfo
            state={State3}
            setState={setState3}
            jobAssetId={jobAssetId}
            jobId={jobId}
            crudState={crudState}
            setRefresh={setRefresh}
            refresh={refresh}
            companyGuid={"GERDFTEWW"}
            hideSideDrawer={() => {
              setState3({ right: false });
            }}
          />
        }
        state={State3}
        setState={setState3}
        crudState={showSeeThroughOwnerInfo}
      /> */}

        {/* Add & Edit Add Owner Info Drawer */}
        <SideDrawer
          formComp={
            <AddOwnerInfo
              state={State4}
              setState={setState4}
            // crudState={crudState}
            // formData={formData}
            // setFormData={setFormData}
            // userId={null}
            // hideSideDrawer={() => {
            //   setState({ right: false });
            //   getSubRegionData();
            // }}
            // subRegionData={subRegionData}
            />
          }
          state={State4}
          setState={setState4}
          crudState={showAddOwnerInfo}
        />

        {/* Add & Edit Add Owner Info Drawer */}
        {/*  <SideDrawer
          formComp={
            <AddUpdateInfo
              state={State5}
              setState={setState5}
            // crudState={crudState}
            // formData={formData}
            // setFormData={setFormData}
            // userId={null}
            // hideSideDrawer={() => {
            //   setState({ right: false });
            //   getSubRegionData();
            // }}
            // subRegionData={subRegionData}
            />
          }
          state={State5}
          setState={setState5}
          crudState={showAddUpdateInfo}
        /> */}
        <AlertDialog
          open={openConfirmfileDeleteAlart}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={
            "Are you sure you want to submit? Data cannot be edited once submitted"
          }
          action={
            <>
              <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                <Button onClick={() => setopenConfirmfileDeleteAlart(false)}>
                  No
                </Button>
                <Button
                  color="secondary"
                  onClick={(e) => {
                    submit();
                    setopenConfirmfileDeleteAlart(false);
                  }}
                >
                  Yes
                </Button>
              </div>
            </>
          }
        />
      </Grid>
    </Box>
  );
};

export default OwnerCorrectOwnerName;
