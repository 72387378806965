import { makeStyles } from "@mui/styles";
import { Hidden } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  // Status Page Styles
  breadCrumbsIcon: {
    fontSize: 10,
    marginLeft: 3
  },
  tableTopPart: {
    padding: theme.spacing(2)
  },
  addBorder: {
    borderRadius: 6,
    border: "1px solid rgba(224, 224, 224, 1) !important",
  },
  container: {
    maxHeight: 640, // Table Max Height
  },
  colHighlight: {
    padding: theme.spacing(0, 0, 0, 1),
  },
  tableHeader: {
    padding: theme.spacing(0.5, 0.75) + '!important',
    whiteSpace: "normal",
    height: "46px"
  },
  tableCell: {
    wordBreak: "break-all",
    wordWrap: "break-word",
    borderBottom: "1px solid #d7d7d7 !important",
    padding: theme.spacing(0.5, 0.75) + '!important',
    height: "46px",
    wordBreak: "normal",
  },
  tableActionCell: {
    padding: theme.spacing(0.5, 0.75) + '!important',
  },
  cellNoWrap: {
    whiteSpace: "nowrap",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  helperText: {
    display: "block",
    whiteSpace: "nowrap",
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  totalRecord: {
    color: "#00519c",
  },
  rightDrowerbtn: {
    margin: "0% 5% 2% 0%",
    display: 'inline-flex'
  },
  alertDialogbtn: {
    padding: theme.spacing(0, 2, 1.5, 0),
  },
  fullWidth: {
    width: 'calc(100vw - 330px) !important',
    // overflowX: "scroll",
  },
  loaderWrapper: {
    minHeight: 250
  },
  // Add & Edit User Popup Styles
  popupArea: {
    padding: theme.spacing(3),
  },
  popupHeading: {
    padding: theme.spacing(0, 0, 3, 0),
  },
}));

export default useStyles;
