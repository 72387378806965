// Default React, Router and Services Components
import React from "react";
import { Box, Breadcrumbs, Grid, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import axios from "axios";
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import EmptyState from "../../../../UIComponent/EmptyState";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
// Importing Custom Components
import BaseLayout from "../../../../UIComponent/BaseLayout";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
// Importing useStyles
import useStyles from "./Jobs.styles";

// Import Fluent UI Icons
import { ChevronRightSmallIcon, ExportIcon, FileOffIcon } from "@fluentui/react-icons-mdl2";
import GetMenuService from '../../../../Services/GetMenuService';



// Assigning Columns and UI Props
const monthHeaders = [
  { id: 1, month: "Jan" }, { id: 2, month: "Feb" }, { id: 3, month: "Mar" },
  { id: 4, month: "Apr" }, { id: 5, month: "May" }, { id: 6, month: "Jun" },
  { id: 7, month: "Jul" }, { id: 8, month: "Aug" }, { id: 9, month: "Sep" },
  { id: 10, month: "Oct" }, { id: 11, month: "Nov" }, { id: 12, month: "Dec" },
  { id: 13, month: "Total" },
];

const DataCollectionJobsInQueue = () => {
  // Declaration of States and Component Variables Start Here
  let classes = useStyles();
  const history = useHistory();
  const [tableData, setTableData] = useState();

  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })

// Call GetMenuService with parameter 35 and a callback function
  React.useEffect(() => {
    GetMenuService(35, function (err, res) {
      if (res) {
        // console.log(res.data)
        // Set the access using the response data
        setAccess(res.data)
      } else {
        // Show an error message in the toaster
        toasterError(err)
      }
    })
  }, [])
// Function to get jobs in queue from the API
  const getJobsinQueue = async () => {
    const response = await axios.get(`${process.env.React_App_baseUrl}reports/GetJobInQueue`, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    });
    return response;
  };
// Function to consume data from the API
  const consumeData = async () => {
    try {
      const { data } = await getJobsinQueue();
      setTableData(data);
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res)
      })
    }
  };

  useEffect(() => { consumeData(); }, []);
  // Function to get jobs in queue
  const handleExportToExcel = async () => {
    try {
      // Get jobs in queue
      const { data } = await getJobsinQueue();
      let excelData = [];
      // Iterate over the data and calculate total
      data.forEach((obj, index) => {
        let total = obj.january + obj.february + obj.march + obj.april + obj.may + obj.june + obj.july + obj.august + obj.september + obj.october + obj.november + obj.december;
        let tempData = {
          "Job Priority": obj.jobPriority,
          "Jan": obj.january,
          "Feb": obj.february,
          "Mar": obj.march,
          "Apr": obj.april,
          "May": obj.may,
          "Jun": obj.june,
          "Jul": obj.july,
          "Aug": obj.august,
          "Sep": obj.september,
          "Oct": obj.october,
          "Nov": obj.november,
          "Dec": obj.december,
          "Total": total
        }
        excelData.push(tempData)
      })
      // Set file name with current date
      const fileName = `Jobs in Queue ${new Date()
        .toLocaleDateString()
        .split("/")
        .join("-")}`;
        // Call exportToCSV function to export data to excel
      exportToCSV(excelData, fileName)
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res)
      })
    }
  }
  // Function for Export to Excel
  const exportToCSV = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    XLSX.utils.sheet_add_aoa(
      ws,
      [["Job Priority", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total"
      ],],
      { origin: "A1", }
    );
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Jobs in Queue</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/aging-report" }}>Dashboard and Reports</Link>
            <Typography color="text.primary">Jobs in Queue</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>

            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid item textAlign="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                    onClick={handleExportToExcel}

                  >
                    Export To Excel
                  </Button>
                </Grid>
              </Grid>
              {/* Jobs in Queue Table Start Here */}
              <Grid item xs={12}>

                <TableContainer component={Paper} elevation={0} className="table-container">
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 110, width: 180 }}>Job Priority</TableCell>
                        {monthHeaders && monthHeaders?.map((el) => (
                          <>
                            <TableCell align="center" key={el.id} className={classes.tableHeader} style={{ minWidth: 70, width: "7.5%" }}>
                              {el.month}
                            </TableCell>
                          </>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData ? (
                        tableData?.map((item, index) => (
                          <TableRow key={index} hover>
                            <TableCell className={classes.tableCell}>{item.jobPriority}</TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView && history.push({
                                    pathname: "/app/data-collections/jobs-drill-down",
                                    state: item.jobPriority,
                                    month: 1,
                                  })
                                }
                              >
                                {item.january}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView && history.push({
                                    pathname: "/app/data-collections/jobs-drill-down",
                                    state: item.jobPriority,
                                    month: 2,
                                  })
                                }
                              >
                                {item.february}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              <span
                                onClick={() =>
                                  access.allowView && history.push({
                                    pathname: "/app/data-collections/jobs-drill-down",
                                    state: item.jobPriority,
                                    month: 3,
                                  })
                                }
                                className={classes.tableLink}
                              >
                                {item.march}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView && history.push({
                                    pathname: "/app/data-collections/jobs-drill-down",
                                    state: item.jobPriority,
                                    month: 4,
                                  })
                                }
                              >
                                {item.april}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView && history.push({
                                    pathname: "/app/data-collections/jobs-drill-down",
                                    state: item.jobPriority,
                                    month: 5,
                                  })
                                }
                              >
                                {item.may}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView && history.push({
                                    pathname: "/app/data-collections/jobs-drill-down",
                                    state: item.jobPriority,
                                    month: 6,
                                  })
                                }
                              >
                                {item.june}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView && history.push({
                                    pathname: "/app/data-collections/jobs-drill-down",
                                    state: item.jobPriority,
                                    month: 7,
                                  })
                                }
                              >
                                {item.july}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView && history.push({
                                    pathname: "/app/data-collections/jobs-drill-down",
                                    state: item.jobPriority,
                                    month: 8,
                                  })
                                }
                              >
                                {item.august}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView && history.push({
                                    pathname: "/app/data-collections/jobs-drill-down",
                                    state: item.jobPriority,
                                    month: 9,
                                  })
                                }
                              >
                                {item.september}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView && history.push({
                                    pathname: "/app/data-collections/jobs-drill-down",
                                    state: item.jobPriority,
                                    month: 10,
                                  })
                                }
                              >
                                {item.october}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView && history.push({
                                    pathname: "/app/data-collections/jobs-drill-down",
                                    state: item.jobPriority,
                                    month: 11,
                                  })
                                }
                              >
                                {item.november}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView && history.push({
                                    pathname: "/app/data-collections/jobs-drill-down",
                                    state: item.jobPriority,
                                    month: 12,
                                  })
                                }
                              >
                                {item.december}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCellTotal} align="center" style={{ minWidth: 80 }}>
                              <span className={classes.tableLink}
                                onClick={() =>
                                  access.allowView && history.push({
                                    pathname: "/app/data-collections/jobs-drill-down",
                                    state: item.jobPriority,
                                    month: 0,
                                  })
                                }
                              >
                                {item.january +
                                  item.february +
                                  item.march +
                                  item.april +
                                  item.may +
                                  item.june +
                                  item.july +
                                  item.august +
                                  item.september +
                                  item.october +
                                  item.november +
                                  item.december}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={99} className={classes.tableCell}>
                            <EmptyState
                              icon={<FileOffIcon style={{ color: "#db9400", fontSize: "40px", }} />}
                              title="No Data Found"
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* Jobs Table End Here */}
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataCollectionJobsInQueue;