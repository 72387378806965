// Default React, useState and Material Components
import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import * as Validations from '../../../common/validation';
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddEditStatus = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [ProducerData, setProducerData] = useState({ id: 0, statusName: '', statusDesc: '', IsActive: true });
  // Declaration of States and Component Variables End Here

  React.useEffect(() => {
    if (props.crudState === "Edit" || props.crudState === "View") {
      let data = { id: props.formData.id, statusName: props.formData.statusName, statusDesc: props.formData.statusDesc };
      setProducerData(data);
    }
  }, [props]);

// This function validates the form data
const validation = (formData) => {
  // Check if statusName is empty
  if ((formData.statusName.trim() === "")) { 
      toasterError("Please enter status"); 
      return false; 
  }
  // Check if statusName contains only text and characters
  if (!Validations.onlyTextAndChar(formData.statusName)) {
    toasterError("Please enter valid status name"); 
    return false;
  }
  // Check if statusDesc is empty
  if (formData.statusDesc.trim() === "") { 
      toasterError("Please enter description"); 
      return false; 
  }
  // Return true if all conditions are met
  return true;
}

// This function is used to register the status of a producer
const registerStatus = () => {

  // Set loading to true while data is being processed
  setLoading(true);
  
  // Validate the ProducerData
  if (validation(ProducerData)) {
    // Call ApiService to save the status
    ApiService.create("managedata/SaveStatus", ProducerData)
      .then((res) => {
        // If response is successful, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("Status details added successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // Handle errors
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }
};

// This function updates the status of a ProducerData
const updateStatus = () => {
  // Set loading to true while data is being processed
  setLoading(true);
  // Validate the ProducerData
  if (validation(ProducerData)) {
    // Make an axios patch request to update the status
    axios.patch(process.env.React_App_baseUrl + "managedata/UpdateStatus", ProducerData, {
      headers: {
        // Get the token from sessionStorage
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then((res) => {
        // If the response status is 200, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("Status details edited successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // Handle errors
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }
};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Status
        </Typography>
      </Box>

      {/* Add & Edit Commodity Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            required
            fullWidth
            id="standard-basic"
            name="status"
            label="Status"
            placeholder="Status"
            variant="standard"
            inputProps={{ maxLength: 100 }}
            value={props.crudState === "Add" ? null : props.formData.statusName}
            onChange={(e) => {
              props.setFormData({ ...props.formData, statusName: e.target.value });
              setProducerData({ ...ProducerData, statusName: e.target.value });
            }} InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            required
            fullWidth
            multiline
            maxRows={4}
            id="standard-basic"
            name="description"
            label="Description"
            placeholder="Description"
            variant="standard"
            inputProps={{ maxLength: 250 }}
            value={props.crudState === "Add" ? null : props.formData.statusDesc}
            onChange={(e) => {
              props.setFormData({ ...props.formData, statusDesc: e.target.value });
              setProducerData({ ...ProducerData, statusDesc: e.target.value });
            }} InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>

        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={'auto'}>
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Add" ? registerStatus : updateStatus}
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant="contained" color="accent3" onClick={() => props.setState({ ...props.state, right: false })}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddEditStatus;