// Default React, Router and Services Components
import React, { useState } from "react";
import axios from "axios";
import { logo } from "../../../Resources/index";
import { useHistory } from "react-router-dom";
import { Button, Grid, TextField } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
import ErroHandling from "../../../Services/ErrorHandling"

// Importing useStyles
import "./index.scss";
import { ChevronLeftIcon } from "@fluentui/react-icons-mdl2";

const data = { email: "", password: "" }
const Login = () => {
    // Declaration of States and Component Variables Start Here
    const history = useHistory();
    const [visible, setVisible] = useState(true);
    const [logData, setLogData] = useState(data);
    const [sentData, setSentData] = useState({ email: '' });
    const [loading, setLoading] = useState(false);

    // Declaration of States and Component Variables End Here

    // Function for Validation
    const handleChange = (e) => {
       // console.log('current Pathname ', window.location.href);
        setLoading(true);
   
        var path = e.view.location.origin + '/#/changepassword'
        const forgetPass = { emailId: sentData.email, path: path }
        if (forgetPass.emailId) {
            axios.post(process.env.React_App_baseUrl + 'account/ResetPassword', forgetPass, {
                headers: {
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                }
            })
                .then(function (response) {
                  //  console.log(response.data)
                    setLoading(false);
                    toasterSuccess("Link Sent to your email,Please check")
                })
                .catch(function (error) {
                    ErroHandling(error, function (res) {
                        toasterError(res)
                        setLoading(false);
                    })
                });
        }
        else {
            toasterError("Please Enter Email Id")
            setLoading(false);
        }
    }

    // Function for Login
    const onSubmit = () => {
        setLoading(true);
        const loginData = { email: logData.email, password: logData.password }
        {
            if (loginData.email && loginData.password) {
                axios.post(process.env.React_App_baseUrl + "account/login", loginData, {
                    headers: {
                        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                    }

                })
                    .then(function (response) {
                        // console.log(response.data.email)
                        // console.log(response.data.lastName)
                        const token = response.data.token
                        const mail = response.data.email
                        const name = response.data.firstName
                        const lName = response.data.lastName
                        const roled = response.data.roleName
                        
                        localStorage.setItem('token', token)
                        localStorage.setItem("username", name)
                        localStorage.setItem("email", mail)
                        localStorage.setItem("lastName", lName)
                        localStorage.setItem("role",roled)

                        if (response.data.isFirstTimeLogin) {
                            history.push({pathname: 'app/changepassword', state:{pathname:'/changepassword'}})
                            setLoading(false);
                        }
                        else {
                            if(roled==='CRU Analyst')
                            {

                                history.push('/app/data-collections/search-export')
                                setLoading(false);
                            }
                            else if(roled==='CRU Admin')
                            {
                           
                                history.push('/app/data-collections/manage-job-records')
                                setLoading(false);
                            }
                             else if(roled==='Merit Admin')
                            {
                                history.push('/app/data-collections/assign-jobs')
                                setLoading(false);
                            }
                             else if(roled==='Merit Analyst')
                            {
                                history.push('/app/data-collections/manage-job-records')
                                setLoading(false);
                            }
                             else if(roled==='Merit QC')
                            {
                                history.push('/app/data-collections/quality-management')
                                setLoading(false);

                            }
                            else{

                            history.push('app/data-collections/source-management')
                            setLoading(false);
                            }
                        }
                    })
                    .catch((error => {
                        ErroHandling(error, function (res) {
                            toasterError(res)
                            setLoading(false);
                        })

                    }));
            }
            else if (!loginData.email) {
                toasterError("Please enter valid email")
                setLoading(false);
            }
            else if (!loginData.password) {
                toasterError("Please enter valid password")
                setLoading(false);
            }
        }
    };


    const handleReset = () => { setLogData(data) }
    const handleReset1 = () => { setSentData({ email: '' }) }
    const handleForget = () => { setVisible(false) }
    const handleBack = () => { setVisible(true) }

    return <Formik
        initialValues={{ email: '', password: '', showPassword: false }}
        onSubmit={values => onSubmit(values)}>
        {({ values, isValid, dirty, handleSubmit }) => (
            <Form noValidate autoComplete="new-password">
                <div className="loginPage">
                    <div className="d-flex align-items-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="loginArea col-12 col-sm-9 col-md-7 col-lg-5">
                                    <div className="loginContainer py-4 px-4">
                                        <Grid container spacing={2} alignItems="center" justifyContent="center">

                                            {/* Login Start Here */}

                                            <Grid item xs={12}>
                                                <div className="logoContainer d-flex justify-content-center">
                                                    <img className="image" src={logo} width="65" height="auto" alt="Welcome to CRU - Primary Data Collection Platform" title="Welcome to CRU - Primary Data Collection Platform" />
                                                </div>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <div className="welcomeMessage">
                                                    <div className="title text-center">Welcome to CRU - Primary Data Collection Platform</div>
                                                </div>
                                            </Grid>
                                            {visible ? <>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        label="Email ID"
                                                        type="email"
                                                        name="email"
                                                        value={logData?.email}
                                                        onChange={(e) => setLogData({ ...logData, email: e.target.value })}
                                                        placeholder="Please enter your email ID"
                                                        variant="standard"
                                                        required
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        label="Password"
                                                        placeholder="Please enter your password"
                                                        name="password"
                                                        variant="standard"
                                                        value={logData?.password}
                                                        onChange={(e) => setLogData({ ...logData, password: e.target.value })}
                                                        type={values.showPassword ? "text" : "password"}
                                                        required
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <div className="form-check show-password">
                                                        <Field name="showPassword" className="form-check-input" type="checkbox" id="showPassword" />
                                                        <label className="form-check-label">Show Password</label>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={6} textAlign="right">
                                                    {/* <Checkbox name="rememberMe" onChange={handleChange}>Remember Me</Checkbox> */}
                                                    <div onClick={handleForget} className="forgotPassword">Forgot Password?</div>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <div className="loginSubmit">
                                                        <LoadingButton
                                                            buttonName={"Login"}
                                                            clickHandler={handleSubmit}
                                                            isLoading={loading} />
                                                        <Button variant="contained" color="accent3" style={{ minWidth: '100px', marginLeft: "15px" }} onClick={handleReset}>Reset</Button>
                                                    </div>
                                                </Grid>
                                            </> :
                                                <>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2} alignItems="center" justifyContent="left">
                                                            <Grid item xs={8}>
                                                                <div variant="h6" className="subheading">
                                                                    Forgot Password
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={4} textAlign="right">
                                                                <Button variant="contained" color="accent2" startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
                                                                    onClick={handleBack}>Back</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            size="small"
                                                            label="Email ID"
                                                            type="email"
                                                            name="email"
                                                            fullWidth
                                                            placeholder="Please enter your email ID"
                                                            variant="standard"
                                                            value={sentData.email}
                                                            onChange={(e) => setSentData({ ...sentData, email: e.target.value })}
                                                            required
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sx={{ mt: 1 }}>
                                                        <Grid container spacing={2} justifyContent="left">
                                                            <Grid item xs={'auto'}>
                                                                {/* <Button variant="contained" onClick={handleChange} style={{ minWidth: '100px' }}>Submit</Button> */}
                                                                <LoadingButton
                                                                    buttonName={"Submit"}
                                                                    clickHandler={handleChange}
                                                                    isLoading={loading} />
                                                            </Grid>
                                                            <Grid item xs={'auto'}>
                                                                <Button variant="contained" color="accent3" style={{ minWidth: '100px' }} onClick={handleReset1}>Reset</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                            {/* Login End Here */}

                                            <Grid item xs={12}>
                                                <div className="copyright-text text-center mt-3">
                                                    © 2023 <a href="https://www.crugroup.com/" target={"_blank"}>CRU International Ltd</a>. All rights reserved.
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        )}
    </Formik >
};

export default Login;