import React, { useState, useRef } from 'react';
import useStyles from "./dragDrop.style";
import { BulkUploadIcon } from "@fluentui/react-icons-mdl2";
import { Box, Chip, FormControl, LinearProgress, Typography } from '@mui/material';

const Dropzone = (props) => {
  const classes = useStyles();

  const [filesSelected, setFiles] = useState([]);
  const [hightlight, setHightlight] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const fileInputRef = useRef();
  
  // React.useEffect (()=>{
  //  console.log("Dropxone useffect")
  // },[])
  const openFileDialog = () => {
    if (isLoading) return;

    fileInputRef.current.click();
    setLoading(true);
    window.addEventListener("mousemove", handleProgess);
  }

  const handleProgess = () => {
    if (filesSelected.length > 0) {
      setLoading(false);
    } else {
      setLoading(false);
    }
    window.removeEventListener("mousemove", handleProgess);
  }

  const onFilesAdded = (evt) => {
    const files = evt.target.files;
    setFiles(files);
    if (props.onFilesAdded) {
      const array = fileListToArray(files);
      props.onFilesAdded(evt, array);
    }
    setLoading(false);
    window.removeEventListener("mousemove", handleProgess);
  }

  const onDragOver = (event) => {
    event.preventDefault();
    setHightlight(true);
  }

  const onDragLeave = (event) => {
    event.preventDefault();
    setHightlight(false);
  }

  const onDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    setFiles(files);
    if (props.onFilesAdded) {
      const array = fileListToArray(files);
      props.onFilesAdded(event, array);
    }
    setHightlight(false);
  }

  const fileListToArray = (list) => {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  const handleDelete = (event) => {
    setFiles([]);
    if (props.onFilesAdded) {
      props.onFilesAdded(event, []);
    }
  };

  const truncateFileName = (text) => {
    if (text.length <= 20) return text;
    var string,
      filename = '',
      splitArr = text.split('.'),
      extension = '.' + splitArr[splitArr.length - 1];
    for (var i = 0; i < splitArr.length - 1; i++) {
      filename += splitArr[i];
    }
    string = filename.substring(0, 7) + '...' + filename.substring(filename.length - 7, filename.length) + extension;
    return string;
  }

  return (
    <>
      <FormControl
        fullWidth
        // margin={props.margin}
      >
        <div
          className={`${classes.Dropzone} ${hightlight ? classes.Highlight : ''}`}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onClick={openFileDialog}
          style={{ cursor: props.disabled ? 'default' : 'pointer' }}
        >
          <Box className={classes.DropzoneInnerWrap}>
            <input
              value ={''}
              ref={fileInputRef}
              className={classes.visuallyHidden}
              type="file"
              accept="
               .pdf, 
               .PDF,
               .xls, 
               .xlsx,
               .doc,
               .docx,
               .ppt"
              multiple
              onChange={(event) => onFilesAdded(event)}
            />

            <BulkUploadIcon style={{ fontSize: 30 }} />
            <Typography variant="body1" component="p" align='center' style={{ fontsize: '11px', marginBottom: 0, marginTop: '10px' }}>{"Drag and Drop or browse files to upload attachments"}</Typography>

          </Box>
          {/* {isLoading && <LinearProgress />} */}
        </div>

        {filesSelected.length > 0 && props.showSelectedFile &&
          <Box mt={2}>
            <Chip variant="outlined" label={`${truncateFileName(filesSelected[0].name)}`} onDelete={e => handleDelete(e)} />
          </Box>
        }
      </FormControl>
    </>
  );
}

export default Dropzone;
