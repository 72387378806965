import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export default function DatePicker({
  onChangeDate,
  past,
  defaultDate,
  labelTitle,
  placeHolder,
  minDates,
  disabled,
  required,
  clearable
}) {
  const [value, setValue] = React.useState(defaultDate ? defaultDate : null);
  const handleChange = (newValue) => {
    setValue(newValue);
    onChangeDate(newValue);
  };

  useEffect(() => {
    if (defaultDate) {
      let myArray = (defaultDate.toString()).split("-");
      if (myArray[2] > 2000) {
        let newDate = myArray[2] + "-" + myArray[1] + "-" + myArray[0];
        setValue(newDate);
      } else if (myArray[0] > 2000) {
        setValue(defaultDate);
      }
    } else {
      setValue(null);
    }
  }, [defaultDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DesktopDatePicker
          disabled={disabled}
          minDate={minDates}
          label={labelTitle}
          inputFormat="DD-MM-YYYY"
          disablePast={past}
          value={value}
          clearable={clearable}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={placeHolder}
              required ={required}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
