// Default React, useState and Material Components
import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import SideDrawer from "../../../UIComponent/Drawer/index";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing Custom Components
import CountrySideBar from './AddEditCountry';

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const Country = () => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [edit, setEdit] = useState({});
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [allCountry, setAllCountry] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const globalSearch = (value) => { setSearchValue(value); };
  const [state, setState] = React.useState({ right: false });
  const [deleteCountyData, setDeleteCountyData] = useState(null);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
  const [menuPermission, setMenuPermission] = useState({ allowDelete: true, allowEdit: true, allowView: true });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(15, function (err, res) {
      if (res) {
        // console.log(res.data)
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, []);
  

  // useEffect(() => {
  //   if (!allCountry || allCountry.length < 1 || reload && !searchValue) { GetCountryDetails() }
  //   if (reload && searchValue) { searchCountry() }
  // }, [reload,page,rowsPerPage])
  // Declaration of React Hooks End Here

  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) { searchCountry('') }
    else { searchCountry(searchValue) }
  }, [page, reload, rowsPerPage])

  // Assigning Colums and UI Props
  const columns = [
    { id: "countryName", label: "Country", minWidth: 180, width: "33%", sorting: false, },
    { id: "subRegionName", label: "Subregion", minWidth: 180, width: "33%", sorting: false, },
    { id: "regionName", label: "Region", minWidth: 180, width: "33%", sorting: false, },
  ];

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
     // If backspace is pressed and target element is not a button
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      // If search value is empty, call GetCountryDetails()
      if (searchValue.length === 0) { GetCountryDetails(); }
    }
    // If enter is pressed and target element is not a button
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      // Set total count to 0 and page to 0
      setTotalCount(0);
      setPage(0);
      // Call searchCountry() with the value of the target element
      searchCountry(event.target.value);
    }
  };
  

// Function to get country details from API
const GetCountryDetails = () => {
  // Set loading state to true
  setLoading(true)
  // Make an API call to get all country details
  ApiService.getAll(`managedata/GetCountryDetails?pageNumber=${page}&pageSize=${rowsPerPage}`).then((res) => {
     // If the response status is 200, set the data and total count in state and set loading state to false
     if (res.status === 200) {
      setAllCountry(res.data.data)
      setTotalCount(res.data.totalCount)
      setLoading(false)
    } else {
      // If the response status is not 200, show error message and set loading state to false
      toasterError(`${res.data}`);
      setLoading(false)
    }

  }).catch((err) => {
    // Set loading state to false
    setLoading(false);
    // Handle errors
    ErroHandling(err, function (res) {
      toasterError(res)
    })
  });
}

// This function is used to delete a country record
const deleteCountry = () => {
  // Set loading state of delete button to true
    setDeleteBtnLoadinng(true)
    // Make an axios post request to delete the country record
    axios.post(process.env.React_App_baseUrl + `managedata/DeleteCountry`, null, {
      headers: {
        id: deleteCountyData.countryId,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }

    }).then((res) => {
      // If the response status is 200, show success message and get the updated country details
      if (res.status === 200) {
        toasterSuccess('Record Deleted Successfully');
        GetCountryDetails()
        setDeleteBtnLoadinng(false)
        setOpenDelete(false);
      } else {
         // Else show error message
        toasterError('Something went wrong');
        setDeleteBtnLoadinng(false)
      }
    }).catch((err) => {
       // Handle errors
      ErroHandling(err, function (res) {
        toasterError(res)
      })
      setDeleteBtnLoadinng(false)
    })
  }

// This function toggles the drawer open or closed
const toggleDrawer = (open, mode) => (event, item) => {
  // If mode is set to "Delete", set delete county data and open delete
  if (mode === "Delete") {
    setDeleteCountyData(item);
    setOpenDelete(true);
  } else {
    // If mode is set to "Edit", set form data
    if (mode === "Edit") {
      setFormData(item);
    }
    // Set state with right open, formData and crudState
    setState({ ...state, right: open, formData: item });
    setCrudState(mode);
  }
};

// Function to search for a country
const searchCountry = () => {
  // Set loading to true
  setLoading(true);
  // Create an array of parameters
  let prt = [`pageNumber=${page}`, `pageSize=${rowsPerPage}`];
  // Call the ApiService to search for a country
  ApiService.search(`managedata/GetCountryDetails?`, { searchText: searchValue, },
    prt).then((res) => {
      // If the response is successful, set the data and total count
      if (res.status === 200) { setAllCountry(res.data.data); setTotalCount(res.data.totalCount); setLoading(false); } 
      // Else, display an error message
      else { toasterError(`${res.data}`); setLoading(false) }
    }).catch((err) => {
      // Set loading to false and handle errors
      setLoading(false); ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
}

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Country</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography>Country</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}

        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {(access.allowEdit) ? <>
                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: 16 }} />}>
                  Add Country
                </Button>
              </> : null
              }
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.searchByKeyword}
                      id="input-with-icon-textfield"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="serchByClear"
                        onClick={() => {
                          setSearchValue("");
                          GetCountryDetails();
                        }}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">Total Records: <span className={classes.totalRecord}>{totalCount}</span></Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Country Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={loading}
                  rows={allCountry ? allCountry : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  /* setPage={(data) => {
                    if (page === 0 && data === 1) {
                      setPage(2)
                    }
                    else if (page === 2 && data === 1) {
                      setPage(0)
                    }
                    else {
                      setPage(data);
                    }
                    setReload(true);
                  }} */
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>

        {/* Add & Edit Country Side Drawer */}
        <SideDrawer
          formComp={
            // Calling Add & Edit Country
            <CountrySideBar
              state={state}
              setState={setState}
              crudState={crudState}
              formData={formData}
              setFormData={setEdit}
              userId={null}
              hideSideDrawer={() => {
                setState({ right: false });
                GetCountryDetails();
              }}
            />
          }
          state={state}
          setState={setState}
        />
      </Grid>

      {/* Alert Dialog - Confirmation for Delete */}
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to delete country "${deleteCountyData?.countryName}" ?`}
          action={
            <Grid container alignItems="center" justifyContent="right" spacing={2} className={classes.alertDialogbtn}>
              <Grid item xs={"auto"}>
                <Button onClick={() => setOpenDelete(false)}>No</Button>
              </Grid>
              <Grid item xs={"auto"}>
                <Button onClick={deleteCountry} color="secondary">
                  {deleteBtnLoadinng ? (<CircularProgress size={25} />) : ("Yes")}
                </Button>
              </Grid>
            </Grid>
          }
        />
      ) : null}
    </Box>
  );
};

export default Country;