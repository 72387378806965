
export const  emailValid =(mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
        return (true)
      }else {
        return false
      }
}

export const onlyChar =(char)=>{
    if ( /^[a-zA-Z]+$/.test(char)){
        return (true)
      }else {
        return false
      }
}

export const onlyNum =(char)=>{
  if ( /^[0-9]+$/.test(char)){
      return (true)
    }else {
      return false
    }
}

export const onlyTextAndChar =(char)=>{
  if ( /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-,\ /]*$/i.test(char)){
      return (true)
    }else {
      return false
    }
}

export const linkValidation =(char)=>{
  if ( /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi.test(char)){
      return (true)
    }else {
      return false
    }
}

export const linkValidationWithHttps =(char)=>{
  if ( /^(http:\/\/|https:\/\/)?(www\.)?([a-zA-Z0-9]+(-*[a-zA-Z0-9])*\.)+[a-z]{2,}(\/\S*)?/gi.test(char)){
      return (true)
    }else {
      return false
    }
}



