// Default React, useState and Material Components
import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  Breadcrumbs,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
// Importing Custom Services
import axios from "axios";
import GetMenuService from "./../../../../Services/GetMenuService";
// Importing Custom Components
import DataTable from "../../../../UIComponent/DataTable";
import { LoaderWrapper } from "../../../../UIComponent/Progress";
import BaseLayout from "../../../../UIComponent/BaseLayout/index";
import {
  toasterError,
  toasterSuccess,
} from "../../../../UIComponent/Toaster/index";
import { useHistory } from "react-router-dom";
// Importing Add & Edit Components
// import ScoreParamsSideDrawer from "./AddEditScoreParameter";

// Import Fluent UI Icons
import {
  ChevronLeftIcon,
  SearchIcon,
  ClearIcon,
  ChevronRightSmallIcon,
} from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import "../../../../../src/index.scss";
import useStyles from "../../../ManageData/ManageData.styles";
import CommonService from "../../../../Services/CommonService";

const columns = [
  { id: "id", label: "ID", type: "Highlight" },
  {
    id: "jobNo",
    label: "Job Id",
    minWidth: 80,
    width: 80,
    sorting: true,
  },
  { id: "jobName", label: "Job Name", minWidth: 200, width: "25%", sorting: true },
  {
    id: "company",
    label: "Company",
    minWidth: 200,
    width: 200,
    sorting: true,
    truncate: 65,
    tooltipWithTruncate: true,
  },
  {
    id: "asset",
    label: "Asset",
    minWidth: 120,
    width: 120,
    sorting: true,
  },

  {
    id: "startDate",
    label: "Job Start Date",
    minWidth: 100,
    width: 100,
    sorting: true,
    align: "center",
  },
  {
    id: "tat",
    label: "Tat",
    minWidth: 150,
    width: 150,
    sorting: true,
    align: "center",
  },
  {
    id: "remainingDays",
    label: "Remaining Days",
    minWidth: 100,
    width: "15%",
    sorting: true,
    align: "center",
  },
  {
    id: "meritAnalystName",
    label: "Merit Analyst Name",
    minWidth: 150,
    width: 150,
    sorting: true,
    align: "left",
  },
  {
    id: "completedOn",
    label: "Completed On",
    minWidth: 100,
    width: 100,
    sorting: true,
    align: "center",
  },
  {
    id: "meritQCCompletionDate",
    label: "Merit QC Completion Date",
    minWidth: 120,
    width: 120,
    sorting: true,
    align: "center",
  },
  {
    id: "meritQCName",
    label: "Merit QC Name",
    minWidth: 150,
    width: 150,
    sorting: true,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 150,
    width: 150,
    sorting: true,
    align: "center",
  }
];

const DataCollectionQCFailedReportDrilldown = (props) => {
  const history = useHistory()
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [historyData, setHistoryData] = useState();
  const [open, setOpen] = React.useState(false);
  const [searchOn, setSearchOn] = useState(true);
  const [crudState, setCrudState] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, SetSelected] = React.useState([]);
  const [state, setState] = useState({ right: false });
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAllSelected, setIsSelected] = useState(false);
  const [filterValue, setFilterValue] = React.useState("");
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [access, setAccess] = React.useState({
    allowEdit: false,
    allowDelete: false,
    allowView: false,
  });
  // Declaration of States and Component Variables End Here

  const changeSortColumnNameFormat = (fieldName) => {
  
    if (fieldName == null || fieldName === "") {
      return "";
    }
    if (fieldName.toLocaleLowerCase() === "jobno") {
      fieldName = "JobNo";
    }
    if (fieldName.toLocaleLowerCase() === "jobname") {
      fieldName = "JobName";
    }
    if (fieldName.toLocaleLowerCase() === "company") {
      fieldName = "Company";
    }
    if (fieldName.toLocaleLowerCase() === "asset") {
      fieldName = "Asset";
    }
    if (fieldName.toLocaleLowerCase() === "startdate") {
      fieldName = "StartDate";
    }
    if (fieldName.toLocaleLowerCase() === "tat") {
      fieldName = "TAT";
    }
    if (fieldName.toLocaleLowerCase() === "remainingdays") {
        fieldName = "RemainingDays";
      }
      if (fieldName.toLocaleLowerCase() === "meritanalystname") {
        fieldName = "MeritAnalystName";
      }
      if (fieldName.toLocaleLowerCase() === "completedon") {
        fieldName = "AssetCompletedTime";
      }
      if (fieldName.toLocaleLowerCase() === "meritqccompletiondate") {
        fieldName = "QCCompletionDate";
      }
      if (fieldName.toLocaleLowerCase() === "meritqcname") {
        fieldName = "MeritQCName";
      }
      if (fieldName.toLocaleLowerCase() === "status") {
        fieldName = "Status";
      }
    return fieldName;
  };

  // Function to get table data from API
const getTableData = async () => {
    // Set loading state to true
    setLoading(true);
    let temp = CommonService.GetStoredData(props, "historyData");
    // Make a GET request to the API
    const response = await axios.get(
      `${process.env.React_App_baseUrl}reports/GetQCReportDrilldown`,
      {
        headers: {
          // Set searchText header
          searchText: filterValue ? encodeURIComponent(filterValue) : "",
          // Set sortColumn header
          sortColumn: changeSortColumnNameFormat(sorting.sortBy),
          // Set isAscending header
          isAscending: sorting.isAscending,
          // Set jobAssetId header
          jobAssetId: temp.state.jobAssetId,
          // Set Authorization header
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
        // Set pageNumber and pagesize params
        params: { pageNumber: page + 1, pagesize: rowsPerPage },
      }
    );

    // Get data from response
    let { data } = await response;

    // Log data
    console.log(data, "daaaaa");

    // Set rows with data
    setRows(data.data);

    // Set total count with data
    setTotalCount(data.totalCount);

    // Set loading state to false
    setLoading(false);
  };
  // useEffect is a hook that allows us to perform side effects in function components
useEffect(() => {
    // getTableData is a function that fetches data from an API
    getTableData();
  }, [reload]);
  // reload is a boolean variable that triggers the useEffect hook when it changes its value
  // Declaration of React Hooks Start Here
  // This useEffect hook is used to call the GetMenuService function with parameters 45 and a callback function
React.useEffect(() => {
    // Call GetMenuService with parameter 45 and a callback function
    GetMenuService(45, function (err, res) {
      // If response is successful, log the data and set access
      if (res) {
        console.log(res.data);
        setAccess(res.data);
      } else {
        // Else, display an error message using toasterError
        toasterError(err);
      }
    });
  }, []);
// This function is used to perform a global search
const globalSearch = (e) => {
    // Set the page number to 0
    setPage(0);
    // If the key pressed is "Enter"
    if (e.key === "Enter") {
      // Set reload to true
      setReload(!reload);
      // Set searchOn to false
      setSearchOn(false);
    }
  };
  const clearSearch = () => {
    setReload(!reload);
    setFilterValue("");
  };

  return (
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Merit QC Report Failed Drilldown</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/ownership-changes/source-management" }}
            >
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/commodity" }}
            >
              Manage Company Data Collections
            </Link>
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/commodity" }}
            >
              Dashboard and Reports
            </Link>
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/commodity" }}
            >
              Merit QC Failed Report
            </Link>
            <Typography color="text.primary">Merit QC Failed Report Drilldown
</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}

        <Grid item xs={12} sm={12} md={6}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={"auto"}>
              <Button
                onClick={() => {
                  history.push("/app/data-collections/qc-failed-report");
                }}
                variant="contained"
                color="accent2"
                startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className={classes.searchByKeyword}
                      value={filterValue}
                      onKeyUp={globalSearch}
                      placeholder="Search by Keyword"
                      onChange={(e) => setFilterValue(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {filterValue ? (
                      <IconButton
                        aria-label="Add"
                        className="serchByClear"
                        onClick={clearSearch}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">
                      Total Records:{" "}
                      <span className={classes.totalRecord}>{totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* VariableType Table /> */}
              <Grid item xs={12}>
                <LoaderWrapper loading={isLoading}>
                  <DataTable
                    className={classes.removeBorder}
                    rows={rows ? rows : []}
                    columns={columns}
                    page={page}
                    setPage={(data) => {
                      setPage(data);
                      setReload(!reload);
                    }}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={(data) => {
                      setRowsPerPage(data);
                      setReload(!reload);
                    }}
                    totalCount={totalCount}
                    isAllSelected={isAllSelected}
                    setIsSelected={setIsSelected}
                    selected={selected}
                    setSelected={SetSelected}
                    sorting={sorting}
                    setSorting={(e) => {
                      console.log(e);
                      setReload(!reload);
                      setSorting(e);
                    }}
                    // isActionButton={{
                    //   ...(access.allowEdit && {
                    //     edit: toggleDrawer(true, "Edit"),
                    //   }),
                    //   ...(access.allowDelete && {
                    //     delete: openDeleteDialog(true),
                    //   }),
                    // }}
                  />
                </LoaderWrapper>
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Side Drawer  */}
      {/* <SideDrawer
        state={state}
        setState={setState}
        formComp={
          <ScoreParamsSideDrawer
            state={state}
            setState={setState}
            crudState={crudState}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            reload={reload}
            setReload={setReload}
          />
        }
      /> */}

      {/* Delete Dialog */}
      {/* <Dialog
        open={open}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {" "}
            Would you like to delete the selected item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} autoFocus>
            No
          </Button>
          <Button onClick={handleDelete}>Yes</Button>
        </DialogActions>
      </Dialog> */}
    </Box>
  );
};

export default DataCollectionQCFailedReportDrilldown;
