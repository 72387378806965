// Default React, Router and Services Components
import React, { useState } from "react";
import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import axios from "axios";
import AdobeViewer from "./AdobeViewer";
import EmptyState from "../../../UIComponent/EmptyState";
import { useDispatch, useSelector } from "react-redux";
import { toasterError } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
// Importing Custom Components
import { ScrollingDialog } from "../../../UIComponent";
import BasicPopover from "../../../UIComponent/PopOver";
import MultiSelect from "../../../UIComponent/MultiSelectDropdown";
import { setYearsForHistoryData } from "../../../features/filter/filterSlice";

// Importing useStyles
// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";

// Import Material & Fluent UI Icons
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AttachIcon, CommentIcon, FileOffIcon } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../Services/CommonService";

const columnOne = [
  { id: "variable", label: "Variable", width: 70, minWidth: 70, sorting: false, align: "center", },
  { id: "unitQuarter", label: "Unit (Report)", width: 100, minWidth: 100, sorting: false, align: "center", },
  { id: "variableCru", label: "Variable (CRU)", width: 100, minWidth: 100, sorting: false, },
  { id: "unit", label: "Unit (CRU)", width: 150, minWidth: 150, sorting: false, },
  { id: "currency", label: "Currency", width: 100, minWidth: 100, sorting: false, align: "center", },
  { id: "variableTypeName", label: "Variable Type", width: 100, minWidth: 100, sorting: false, align: "center", },
];

const HistoricData = (props) => {
  // Declaration of States and Component Variables Start Here
  const { yearsForHistoryData } = useSelector((store) => store.filter);
  const [firstSet, setFirstSet] = useState([]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [column, setColumn] = React.useState([]);
  const [Comment, setComment] = React.useState({ message: "" });
  const [sOpen, setOpen] = React.useState(null);
  const [yearDropDown, setYearDropDown] = React.useState([]);
  const [openAdobeViewer, setOpenAdobeViewer] = React.useState();
  const [adobeViewerData, setAdobeViewerData] = React.useState({ jobId: 0, fileName: "", pageNumber: 0, crudState: "", });
  const [selectedYear, setSelectedYear] = React.useState(null)
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const open1 = Boolean(sOpen);
  const idss = open1 ? "simple-popover" : undefined;
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => { getYearForData(); }, []);
  React.useEffect(() => { getHistoricData(); }, [yearsForHistoryData, selectedYear]);
  // Declaration of React Hooks End Here

  const getHistoricData = async () => {
    let yearsId = []
    if (selectedYear) { yearsId = selectedYear.map(el => el.id) }
    yearsId.push(1)
    try {
      let res = await axios.get(`${process.env.React_App_baseUrl}updatejobrecord/GetVariableHistoryDetails`,
        { headers: { jobAssetId: props.jobAssetId, yearId: yearsId,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
       },
       }
      );
      if(res.data!==null && res.data.length){
        let responseData = await res.data;
        if (responseData) {
          let reqHeader = await responseData?.map((el) => el.reportingYear);
          let firstBatch = responseData.map((el) => {
            let data = {
              variableReport: el.variableReport,
              unitReport: el.unitReport,
              variableCru: el.variableCru,
              unitCru: el.unitCru,
              currency: el.currency,
              variableType:el.variableTypeName,
              values: el.reportingYear,
              type: el.valuationType,
            };
            return data;
          });
          setFirstSet(firstBatch);
          let secondColumn = await reqHeader[0].map((item) => {
            return {
              id: item.publication,
              label: item.publication,
              width: 100,
              minWidth: 100,
              sorting: false,
              align: "center",
            };
          });
          setColumn(columnOne.concat(secondColumn));
        }
      }      
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res)})
      return null;
    }
  };

  // Function for Getting Year Data
  const getYearForData = async () => {
    try {
      let res = await axios.get(`${process.env.React_App_baseUrl}reportingcalendar/GetReportingYear`,{
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
      });
      let response = await res.data;
      setYearDropDown(response);
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res)})
      return null;
    }
  };

  // Function for on Change Handler
  const onChangeHandler = (key, value, reason) => {
    const pre = { ...yearsForHistoryData };
    pre[key] = value;
    dispatch(setYearsForHistoryData({ yearsForHistoryData: pre }));
  };

  const openPopover = (event, mesg) => { setComment({ message: mesg }); setOpen(event.currentTarget); };
  const handleClose = () => { setOpen(null); };

  // Function for Open Adobe Viewer
  const openDocumentForView = (file, pageNumber) => {
    let reportName = `${file}.pdf`;
    let senData = { jobId: props.jobID, fileName: reportName, pageNumber: pageNumber[0], crudState: "View", };
    localStorage.setItem("filePreview", JSON.stringify(senData));
    //CommonService.AddTokenFromSessionStorageToLocalStorage();
   // window.open("#app/filepreview", "_blank", "noopener,noreferrer");
   window.open("#app/data-collections/file-preview", '_blank', 'noopener,noreferrer');

  };

  return (

    <Box>
      <Grid container spacing={3} justifyContent="left" alignItems="center" sx={{ p: 2 }} >
        <Grid item xs={12} md={9} lg={4}>
          <MultiSelect
            items={yearDropDown ? yearDropDown : []}
            selectAllLabel={{ label: "Select all" }}
            onChange={(e, value) => {
              setSelectedYear(e)
            }}
            getOptionLabel={"year"}
            dropLable={"year"}
            role="year"
            label="Year"
          />
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.setBorder}>
          <TableContainer sx={{ maxHeight: "calc(100vh - 255px)" }}>
          <Table stickyHeader aria-label="sticky table"
            // className={classes.table}
            className={classes.historyTable}
            >
              <TableHead>
                <TableRow>
                  {column.map((el) => {
                    return (
                      (el.label==="Variable") ? 
                      (<TableCell className={classes.tableHeader && classes.hcruTheadLeftSep +' '+ classes.historicVariableTop} style={{ minWidth: "200px", maxWidth:"200px" }}>
                      {el.label}
                    </TableCell>):(el.label==="Unit (Report)")?
                      (<TableCell className={classes.tableHeader && classes.hcruTheadLeftSep +' '+ classes.historicURTop} style={{ minWidth: "150px", maxWidth:"150px" }}>
                      {el.label}
                    </TableCell>):(el.label==="Variable (CRU)")?
                      (<TableCell className={classes.tableHeader && classes.hcruTheadLeftSep +' '+ classes.historicVCTop} style={{ minWidth: "150px", maxWidth:"150px" }}>
                      {el.label}
                    </TableCell>):(el.label==="Unit (CRU)")?
                      (<TableCell className={classes.tableHeader && classes.hcruTheadLeftSep +' '+ classes.historicUCTop} style={{ minWidth: "100px", maxWidth:"100px" }}>
                      {el.label}
                    </TableCell>):
                    ( <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} style={{ minWidth: "120px", maxWidth:"120px" }}>
                      {el.label}
                    </TableCell>
                    )
                  )
                  })}
                </TableRow>
              </TableHead>
              {firstSet.length > 0 ? (
                <TableBody>
                  {console.log(firstSet,"firstSet")}
                  {firstSet.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row" className={classes.tableCell && classes.htableCellLeftSep +' '+ classes.historicVariable} style={{  minWidth: "200px", maxWidth:"200px"  }}>
                        {row.variableReport}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell && classes.htableCellLeftSep +' '+ classes.historicUR} style={{  minWidth: "150px", maxWidth:"150px"  }}>
                        {row.unitReport}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell && classes.htableCellLeftSep +' '+ classes.historicVC} style={{  minWidth: "150px", maxWidth:"150px"  }}>
                        {row.variableCru}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell && classes.htableCellLeftSep +' '+ classes.historicUC} style={{  minWidth: "100px", maxWidth:"100px"  }}>
                        {row.unitCru}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell && classes.tableCellLeftSep} style={{  minWidth: "120px", maxWidth:"120px"  }}>
                        {row.currency}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell && classes.tableCellLeftSep} style={{  minWidth: "120px", maxWidth:"120px"  }}>
                        {row.variableType}
                      </TableCell>
                      {row.values.map((item) => (
                        <TableCell align="right" className={classes.tableCell && classes.tableCellLeftSep}
                          style={{ minWidth: "100px", whiteSpace: "nowrap" }}>
                          <span className={classes.overviewIcons} style={{color : row.type === "Actual" ? 'black' : row.type === "Guidance" ? "blue" : row.type === "Calculated" ? "#A020F0" :'black'}}>
                            {row.type === "Guidance"
                              ? `${item.minValue ? item.minValue?.toLocaleString() : ""}${item.minValue === null || item.maxValue === null ? "" : "~"}${item.maxValue ? item.maxValue?.toLocaleString() : ""}`
                              : item.value ? item.value.toLocaleString() : <span className={classes.overviewIcons}>-</span>}
                          </span>

                          <span className={classes.overviewIcons}>
                            {item.comments ? (
                              <Tooltip title={item.comments} placement="top" arrow>
                                <IconButton>
                                  <CommentIcon
                                    onClick={(event) =>
                                      openPopover(event, item.comments)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : "-"}
                          </span>
                          {console.log(item.valueReportInfo, "item.valueReportInfo")}
                          <span className={classes.overviewIcons}>
                            {item.valueReportInfo.length > 0 ? (
                              <Tooltip title={item.valueReportInfo.map((it) => it.reportName)} placement="top" arrow>
                                <IconButton>
                                  <AttachIcon
                                    onClick={() => openDocumentForView(item.valueReportInfo.map((it) => it.reportName), item.valueReportInfo.map((it) => it.pageNumber))
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : "-"}
                          </span>

                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={99}>
                      <EmptyState
                        icon={<FileOffIcon style={{ color: "#db9400", fontSize: "40px" }} />}
                        title="No Data Found"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
</TableContainer>

            <BasicPopover
              sOpen={sOpen}
              open={open1}
              onClose={handleClose}
              idss={idss}
              description={Comment.message}
            />

            <ScrollingDialog
              open={openAdobeViewer}
              setOpen={setOpenAdobeViewer}
              dialogClass="darkTitle"
              dialogTitle="Preview"
              dialogContent={
                <AdobeViewer
                  jobId={adobeViewerData.jobId}
                  fileName={adobeViewerData.fileName}
                  pageNumber={adobeViewerData.pageNumber}
                  crudState={adobeViewerData.crudState}
                ></AdobeViewer>
              }
            />

          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HistoricData;