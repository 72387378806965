// Default React, Router and Services Components
import React, { useContext, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Link } from 'react-router-dom';
// import history from "../../../Routes/History";
import { useDispatch, useSelector } from "react-redux";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
// Importing Custom Components
import { clearFilterReports } from "../../../../features/filter/filterSlice";
import EmptyState from "../../../../UIComponent/EmptyState/index";
import ExportToExcel from "../Excelexport";
import FilterForm from "../FilterForm";
import SideDrawer from "../../../../UIComponent/Drawer";

// Importing useStyles
import useStyles from "../StatusReport/StatusReport.styles";

// Import Fluent UI Icons
import { ChevronRightSmallIcon, FileOffIcon } from "@fluentui/react-icons-mdl2";
import BaseLayout from "../../../../UIComponent/BaseLayout";
import GetMenuService from "../../../../Services/GetMenuService";
import { FilterContext } from "../Context/Context";
import { useHistory } from "react-router-dom";

const DataCollectionStatusReport = (props) => {
  const history = useHistory();
  // Declaration of States and Component Variables Start Here
  const { companyList, setCompanyList } = useContext(FilterContext);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [crudState, setCrudState] = React.useState(false);
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [excelData, setExcelData] = useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValue, setFilterValue] = useState(null);
  const [filterValues, setFilterValues] = useState();
  const [formData, setFormData] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [reload, setReload] = useState(false);
  const [sorting, setSorting] = useState({ orderBy: "", order: false });
  const [state, setState] = useState({ right: false });
  const [tableData, setTableData] = useState([]);
  const { filterReport, assetDropDownlist } = useSelector(
    (store) => store.filter
  );
  const classes = useStyles();
  const date = new Date().toLocaleDateString();
  const date1 = date.split("/").join("-");
  // Declaration of States and Component Variables End Here
  const [access, setAccess] = React.useState({
    allowEdit: false,
    allowDelete: false,
    allowView: false,
  });

  React.useEffect(() => {
    GetMenuService(34, function (err, res) {
      if (res) {
        // console.log(res.data)
        setAccess(res.data);
      } else {
        toasterError(err);
      }
    });
  }, []);
  const filterState = useSelector((state) => state.stateSlice);

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    // console.log(filterState, "filterStatefilterState")
    // console.log(filterState.filteValue, "filterStatefilterState")
    fetchData();
  }, [filterValue, sorting, filterState, reload]);
  React.useEffect(() => {
    getFilterData();
    return () => {
      clearValues();
      setReload(!reload);
    };
  }, [reload]);
  // Declaration of React Hooks End Here

  // Creating Data Table for Status Report
  // This function creates an object with the given parameters
  function createData(
    priorityName,
    unassigned,
    assigned,
    open,
    meritDataCollection,
    qualityControl,
    CRUReview,
    completed,
    total
  ) {
    return {
      priorityName,
      unassigned,
      assigned,
      open,
      meritDataCollection,
      qualityControl,
      CRUReview,
      completed,
      total,
    };
  }

  // Function for Get Status Report
  // This function is used to get data from the server
  const getData = async () => {
    // Create a new promise and wait for it to resolve
    let response = await new Promise((resolve, reject) => {
      // Resolve the promise with an axios GET request
      resolve(
        axios.get(
          `${process.env.React_App_baseUrl}statusreport/GetStatusReport`,
          {
            headers: {
              // Set the status report filter if one is present
              statusReportFilter: filterState
                ? encodeURIComponent(JSON.stringify(filterState.filterValue))
                : null,
                // Set the authorization header if a token is present
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
          }
        )
      );
    });
    return response;
  };

  // Function for fetch Data
  // This function is used to fetch data from the API and set it in the excelData and tableData
  const fetchData = async () => {
    // Get data from the API
    const { data } = await getData();
    // Map the data to createData function
    const rows = data.map((el) =>
      createData(
        el.priority,
        el.unassigned,
        el.assigned,
        el.open,
        el.meritDataCollection,
        el.meritQualityControl,
        el.cruReview,
        el.completed,
        el.total
      )
    );
    // Set the mapped data to excelData
    setExcelData(rows);
    // Set the original data to tableData
    setTableData(data);
  };

  // Function for Get User Data
  // This function is used to get filter data from the API
  const getFilterData = async () => {
    try {
      // Making a GET request to the API with authorization token
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      //If there is no data in the response, set the data for filter to an empty array
      if (response.data.length === 0) {
        setDataForFilter([]);
      } else {
        // Set the data for filter to the response data
        setDataForFilter(response.data);
        // Iterate through the companies and set checked to false
        response.data.companies.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
          }
        });
        // Iterate through the regions and set checked to false
        response.data.location.regions.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
            if (item.children) {
              item.children.forEach((item, index) => {
                item.checked = false;
              });
            }
          }
        });
        // If the company list is empty, set it to the response data
        if (companyList.length === 0) {
          setCompanyList(response.data.companies);
        }
        // Set the company filter and location filter to the response data
        setCompanyFilter(response.data.companies);
        setLocationFilter(response.data.location.regions);
      }
    } catch (error) {
      // Call the error handling function
      ErroHandling(error, function (res) {
        toasterError(res);
      });
    }
  };

  // Function for Global Search
  const dispatch = useDispatch();
  // Function to clear filter values
  const clearValues = () => {
    // Set filter value to null
    setFilterValue(null);
    // Toggle reload state
    setReload(!reload);
    // Dispatch action to clear filter reports
    dispatch(clearFilterReports());
  };
  // Function to reset tree filter
  const resetTreeFilter = () => {
    // Loop through company filter and set checked to false
    companyFilter.forEach((item, index) => {
      item.checked = false;
      // Check if item has children
      if (item.children) {
        // Loop through children and set checked to false
        item.children.forEach((item, index) => {
          item.checked = false;
        });
      }
    });
    // Loop through location filter and set checked to false
    locationFilter.forEach((item, index) => {
      item.checked = false;
      // Check if item has children
      if (item.children) {
        // Loop through children and set checked to false
        item.children.forEach((item, index) => {
          item.checked = false;
        });
        // Check if item has grandchildren
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
        }
      }
    });
  };

  // Function for Open Delete and Edit Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    setState({ ...state, right: open });
  };

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Status Report</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/aging-report" }}>Dashboard and Reports</Link>
            <Typography color="text.primary">Status Report</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={"auto"}></Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search and Filter Options Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  spacing={{ xs: 1, sm: 2 }}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Typography variant="subtitle2">
                      <ExportToExcel
                        apiData={excelData}
                        fileName={`Status Report ${date1}`}
                        status
                      />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={toggleDrawer(true)}
                      variant="contained"
                      color="secondary"
                    >
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search and Filter Options End Here */}

              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  elevation={0}
                  className="table-container"
                >
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                          Job Priority
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center" }}
                        >
                          Unassigned
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center" }}
                        >
                          Assigned
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center" }}
                        >
                          Open
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center" }}
                        >
                          Merit Data Collection
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center" }}
                        >
                          Merit Quality Control
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center" }}
                        >
                          CRU Review
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center" }}
                        >
                          Completed
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          style={{ textAlign: "center" }}
                        >
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.length > 0 ? (
                        tableData?.map((item, index) => (
                          <TableRow key={index} hover>
                            <TableCell
                              className={classes.tableCell}
                              style={{ minWidth: 120, width: "15%" }}
                            >
                              {item.priority}
                            </TableCell>

                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "Unassigend",
                                      filter: filterState.filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.unassigned}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "Unassigend",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.unassigned}
                                </span>
                              )}
                            </TableCell>
                            {/* </Link> */}
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "Assigned",
                                      filter: filterState.filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.assigned}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "Assigned",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.assigned}
                                </span>
                              )}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "Open",
                                      filter: filterState.filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.open}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "Open",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.open}
                                </span>
                              )}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "11%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "DataCollection",
                                      filter: filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.meritDataCollection}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "DataCollection",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.meritDataCollection}
                                </span>
                              )}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "11%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "QualityControl",
                                      filter: filterState.filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.meritQualityControl}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "QualityControl",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.meritQualityControl}
                                </span>
                              )}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "CRUReview",
                                      filter: filterState.filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.cruReview}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "CRUReview",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.cruReview}
                                </span>
                              )}
                            </TableCell>

                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "Completed",
                                      filter: filterState.filterValue,
                                      priorityId: item.priorityId,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.completed}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "Completed",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.completed}
                                </span>
                              )}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              style={{
                                minWidth: 110,
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              {index != tableData.length - 1 ? (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "TotalPriority",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.total}
                                </span>
                              ) : (
                                <span
                                  onClick={() =>
                                    access.allowView &&
                                    history.push({
                                      pathname:
                                        "/app/data-collections/status-report-drill",
                                      state: item,
                                      item: "TotalPriority",
                                      filter: filterState.filterValue,
                                      priorityId: 0,
                                    })
                                  }
                                  className={classes.tableLink}
                                >
                                  {item.total}
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            className={classes.tableCell}
                            style={{ textAlign: "center" }}
                            colSpan={99}
                          >
                            <EmptyState
                              icon={
                                <FileOffIcon
                                  style={{
                                    color: "#db9400",
                                    fontSize: "40px",
                                  }}
                                />
                              }
                              title="No Data Found"
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <SideDrawer
                  formComp={
                    <FilterForm
                      dataForFilter={dataForFilter}
                      filterFinalData={filterFinalData}
                      companyFilter={companyFilter}
                      locationFilter={locationFilter}
                      setFilterValues={setFilterValues}
                      state={state}
                      setState={setState}
                      crudState={crudState}
                      formData={formData}
                      setFormData={setFormData}
                      userId={null}
                      setFilterValue={setFilterValue}
                      hideSideDrawer={() => {
                        setState({ right: false });
                        // getUserData();
                      }}
                      filterValue={filterReport}
                      setReload={setReload}
                      reload={reload}
                      resetTreeFilter={resetTreeFilter}
                      setDataForFilter={setDataForFilter}
                      setLocationFilter={setLocationFilter}
                      setCompanyFilter={setCompanyFilter}
                      companyList={companyList}
                      setCompanyList={setCompanyList}
                    />
                  }
                  state={state}
                  setState={setState}
                  reload={reload}
                  setReload={setReload}
                  setFilterValue={setFilterValue}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataCollectionStatusReport;
