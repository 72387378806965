// Default React, useState and Material Components
import React, { useState, useEffect } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles
import useStyles from "../ManageData.styles";

const AddUnit = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const mode = props.crudState;
  const [show, setShow] = useState(-1);
  const [roles, setRoles] = useState([]);
  const [editView, setEditView] = useState({});
  const [loading, setLoading] = useState(false);
  const [tableName, setTableName] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const [checked, setChecked] = React.useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [apiAccessRight, setApiAccessRight] = useState({});
  const [openConfirmation, setConfirmation] = useState(false);
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [userData, setUserData] = useState({ id: 0, unit: "", description: "", });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  useEffect(() => {
    if (Object.values(props.formData).length > 0) { setEditView(props.formData); }
  }, [props.formData]);
  // Declaration of React Hooks End Here

  // Function for Handle KeyUp
  const handleClickOpen = (item) => {
    setOpenDelete(true);
    setDeletedItem(item);
    if (item.id > 0) { }
    else { setIsDeleted(true); }
  };
  const handleClose = () => { setIsDeleted(false); };

  const handleChangeIcon = () => {
    setChecked((prev) => !prev);
    setIsActive((current) => !current);
  };
  const handleADClose = () => { setOpenSaveConfirmation(false); };

  const handleChange1 = (e, key) => {
    const val = { ...userData };
    val[key] = e;
    setUserData(val);
  };

  const handleChange2 = (e, key, id) => {
    if (key === "isEdited") { setShow(id); }
    const val = { ...apiAccessRight };
    const role = val.accessRightsDto.find((r) => r.id === id);
    role[key] = e;
    val.accessRightsDto.push(role);
    const item = [...new Set(val.accessRightsDto)];
    val.accessRightsDto = item;
    setApiAccessRight({ ...val });
    setIsDeleted(false);
  };

  const handleChange3 = (e, key, id) => {
    if (key === "isEdited") { setShow(id); }
    const val = { ...editView };
    const role = val.accessRightsDto.find((r) => r.id === id);
    role[key] = e;
    val.accessRightsDto.push(role);
    const item = [...new Set(val.accessRightsDto)];
    val.accessRightsDto = item;
    setEditView({ ...val });
    setIsDeleted(false);
  };

// This function handles the save action
const handleSave = () => {
  // Create a copy of userData
  const data = { ...userData };
  // Set unit and description from userData
  data.unit = userData.unit;
  data.description = userData.description;
  // Update userData with new data
  setUserData(data);
  // Call onSaveApi with new data
  onSaveApi(data);
};
  const handUpdate = () => {
    const data = { ...editView };
  };

  const onChangeHandler = (e, id) => {
    setTableName(e.target.value);
  };

  const handleDelete = (id, index) => {
    const value = { ...apiAccessRight };
    const arr = value.accessRightsDto.filter((r) => r.id !== id);
    value.accessRightsDto = arr;
    setApiAccessRight(value);
  };

  const handleDelete1 = (id, index) => {
    const value = { ...editView };
    const arr = value.accessRightsDto.filter((r) => r.id !== id);
    value.accessRightsDto = arr;
    setEditView(value);
  };

// This function is used to update the CRU unit
const onUpdateApi = () => {
  // Get the unit from editView
  const { unit } = editView;
  // Check if the unit length is greater than 0
  if (unit.length > 0) {
    // Set loading to true
    setLoading(true);
    // Make a patch request to the API with the editView data and authorization token
    axios.patch(process.env.React_App_baseUrl + "managedata/UpdateCRUUnit", { ...editView, },{
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
    })
      .then((res) => {
        // Set the editView data to the response data
        setEditView(res.data);
        // If the response status is 200, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("CRU unit updated successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => { 
        // Set loading to false
        setLoading(false); 
        // Call error handling function
        ErroHandling(err, function (res) {
          toasterError(res)})
        });
  } else { 
    // Show error message if required fields are not entered
    toasterError("Please enter the required fields");
  setLoading(false);
}
};

// This function is used to save data to the API
const onSaveApi = (data) => {
  // Check if the required fields are not empty
  if (data.unit !== '' && data.description !== '') {
    setLoading(true);
    // Call ApiService to create a new record
    ApiService.create("manageData/SaveCRUUnit", data)
      .then((res) => {
        // If the response status is 200, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("CRU unit added successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // If there is an error, set loading to false and call ErroHandling function
        setLoading(false);
        ErroHandling(err, function (res) {
        toasterError(res)})
      });
  } else {
    // If the required fields are empty, set loading to false and show error message
    setLoading(false);
    toasterError("Please enter the required fields");
   }
};
  const openAlertDialog = () => { setConfirmation(true); };

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} CRU Unit
        </Typography>
      </Box>

      {/* Add & Edit Top Producers Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField id="standard-basic"
            label="Unit"
            fullWidth
            required
            variant="standard"
            inputProps={{ maxLength: 50 }}
            value={props.crudState === "Edit" ? props.formData.unit : null}
            onChange={(e) => {
              props.setFormData({ ...props.formData, unit: e.target.value });
              setUserData({ ...userData, unit: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField id="standard-basic"
            label="Description"
            multiline
            maxRows={4}
            fullWidth
            required
            variant="standard"
            inputProps={{ maxLength: 250 }}
            value={props.crudState === "Edit" ? props.formData.description : null}
            onChange={(e) => {
              props.setFormData({ ...props.formData, description: e.target.value });
              setUserData({ ...userData, description: e.target.value });
            }} />
        </Grid>
        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={12} sm={'auto'}>
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Add" ? handleSave : onUpdateApi}
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <Button variant="contained" color="accent3"
                  onClick={() => props.setState({ ...props.state, right: false })}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddUnit;