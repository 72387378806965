// Default React, Router and Services Components
import React from "react";
import { Box, Breadcrumbs, Grid, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
// Importing Custom Components
import BaseLayout from "../../../../UIComponent/BaseLayout";
import DataTable from "../../../../UIComponent/DataTable";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
// Importing useStyles
import useStyles from "./Jobs.styles";

// Import Fluent UI Icons
import { ChevronLeftIcon, ChevronRightSmallIcon, ExportIcon } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../../Services/CommonService";



const DataCollectionJobDrillDown = (props) => {
  // Declaration of States and Component Variables Start Here
  const [historyData, setHistoryData] = useState();
  let classes = useStyles();
  const history = useHistory()
  const [groupName, setGroupName] = useState("");
  const [month, setMonth] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [selected, SetSelected] = React.useState([]);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: true });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  useEffect(() => {
    if (groupName !== undefined && groupName !== "" && month !== undefined && month !== "") {
      consumeData();
    }


  }, [groupName, month, page, reload, pageSize, sorting]);
  // Declaration of React Hooks End Here
  useEffect(() => {
    getDataFromHistory();
  }, []);
  // Creating Data Table for Job Drill Down
  function createData(company, asset, noOfVariable, startDate, TAT, remainingDays, priority, country, region, subRegion) {
    return { company, asset, startDate, TAT, remainingDays, priority, country, region, subRegion };
  }

  // Assigning Columns and UI Props
  const columns = [
    { id: "company", label: "Company", minWidth: 250, width: 250, sorting: true },
    { id: "asset", label: "Asset", minWidth: 250, width: 250, sorting: true },
    { id: "noOfVariable", label: "Variable Count", minWidth: 130, width: 130, sorting: true, align: "center" },
    { id: "startDate", label: "Job Start Date", minWidth: 130, width: 130, sorting: true, align: "center" },
    { id: "TAT", label: "TAT", minWidth: 130, width: 130, sorting: true, align: "center" },
    { id: "remainingDays", label: "Remaining Days", minWidth: 150, width: 150, sorting: true, align: "center" },
    { id: "priority", label: "Priority", minWidth: 90, width: 90, type: "Chip", sorting: true, align: "center" },
    { id: "country", label: "Country", minWidth: 180, width: 180, sorting: true, },
    { id: "region", label: "Region", minWidth: 180, width: 180, sorting: true, },
    { id: "subRegion", label: "Subregion", minWidth: 180, width: 180, sorting: true, },

  ];
  // This function is used to export the data to excel
  const handleExportToExcel = async () => {
    try {
      // Get the drill down data for excel
      const data = await getDrillDownDataForExcel();
      let excelData = [];
      // Iterate over the data and push it into excelData array
      data.data.data.forEach((obj, index) => {
        let tempData = {
          "Company": obj.companyName,
          "Asset": obj.assetName,
          "Variable Count": obj.noOfVariable,
          "Job Start Date": obj.startDate,
          "TAT": obj.tat,
          "Remaining Days": obj.remainingDays,
          "Priority": obj.priorityName,
          "Country": obj.country,
          "Region": obj.region,
          "SubRegion": obj.subRegion,
        }
        excelData.push(tempData);
      })
      // Create a file name with current date
      const fileName = `Jobs in Queue Drill Down Report ${new Date()
        .toLocaleDateString()
        .split("/")
        .join("-")}`;
        // Export the data to CSV
      exportToCSV(excelData, fileName)
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res)
      })
    }
  }
  // Function for Export to Excel
  const exportToCSV = (apiData, fileName) => {
    // Set the file type and extension
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    // Convert the API data to a worksheet
    const ws = XLSX.utils.json_to_sheet(apiData);
    // Add column headers to the worksheet
    XLSX.utils.sheet_add_aoa(
      ws,
      [["Company", "Asset", "Variable Count", "Job Start Date", "TAT", "Remaining Days", "Priority", "Country", "Region", "SubRegion"
      ],],
      { origin: "A1", }
    );
    // Create a workbook object with the worksheet
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    // Write the workbook to a buffer
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // Create a blob from the buffer
    const data = new Blob([excelBuffer], { type: fileType });
    // Save the blob as a file
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // Function for Getting Drill Down Data
  const getDrillDownData = async () => {
    // Initialize sort variable
    let sort = "";
    // Check if sorting.sortBy is not empty, undefined or null
    if (sorting.sortBy !== "" && sorting.sortBy !== undefined && sorting.sortBy !== null) {
      // Set sort variable to the upper case of the first letter of sorting.sortBy and the rest of the string
      sort = sorting.sortBy[0].toUpperCase() + sorting.sortBy.slice(1)
    }
    // Make a GET request to the specified URL with headers and params
    const response = await axios.get(
      `${process.env.React_App_baseUrl}reports/GetJobInQueueDrillDown`, {
      headers: {
        // Set sortColumn header to the changed format of sorting.sortBy
        sortColumn: changeSortColumnNameFormat(sorting.sortBy),
        // Set isAscending header to sorting.isAscending
        isAscending: sorting.isAscending,
        // Set Authorization header to the token stored in sessionStorage
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
      params: {
        // Set groupName param to groupName
        groupName: groupName,
        // Set month param to month
        month: month,

        pageNumber: page + 1,
        pageSize: pageSize,

      },

    }

    );
    return response;
  };

  const getDrillDownDataForExcel = async () => {
    //Declare sort variable
    let sort = "";
    //Check if sorting.sortBy is not empty, undefined or null
    if (sorting.sortBy !== "" && sorting.sortBy !== undefined && sorting.sortBy !== null) {
      //Convert the first letter of sorting.sortBy to uppercase and concatenate it with the rest of the string
      sort = sorting.sortBy[0].toUpperCase() + sorting.sortBy.slice(1)
    }
    //Make a GET request to the specified URL
    const response = await axios.get(
      `${process.env.React_App_baseUrl}reports/GetJobInQueueDrillDown`, {
      headers: {
        //Set headers for the request
        sortColumn: changeSortColumnNameFormat(sorting.sortBy),
        isAscending: sorting.isAscending,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
      //Set parameters for the request
      params: {
        groupName: groupName,
        month: month,

        pageNumber: 1,
        pageSize: tableCount,

      },

    }

    );
    return response;
  };
// Function to get data from history
  const getDataFromHistory = () => {
    // Get stored data from CommonService
    let temp = CommonService.GetStoredData(props, "historyData");
    if (temp) {
      // Set history data
      setHistoryData(temp);
      if (temp.state) {
        // Set history data state
        setHistoryData(temp.state);
      }
      if (temp.state !== undefined) {
        // Set group name
        setGroupName(temp.state);
      }
      if (temp.month !== undefined) {
        // Set month
        setMonth(temp.month);
      }
    }

  }

  // Function for Change Sort Column
  const changeSortColumnNameFormat = (fieldName) => {
    if (fieldName == null || fieldName == "") { return ""; }
    if (fieldName.toLocaleLowerCase() === "company".toLocaleLowerCase()) { fieldName = "CompanyName"; }
    if (fieldName.toLocaleLowerCase() === "asset".toLocaleLowerCase()) { fieldName = "AssetName"; }
    if (fieldName.toLocaleLowerCase() === "noOfVariable".toLocaleLowerCase()) { fieldName = "NoOfVariable"; }
    if (fieldName.toLocaleLowerCase() === "country".toLocaleLowerCase()) { fieldName = "Country"; }
    if (fieldName.toLocaleLowerCase() === "region".toLocaleLowerCase()) { fieldName = "Region"; }
    if (fieldName.toLocaleLowerCase() === "subRegion".toLocaleLowerCase()) { fieldName = "SubRegion"; }
    if (fieldName.toLocaleLowerCase() === "TAT".toLocaleLowerCase()) { fieldName = "TAT"; }
    if (fieldName.toLocaleLowerCase() === "startDate".toLocaleLowerCase()) { fieldName = "StartDate"; }
    if (fieldName.toLocaleLowerCase() === "remainingDays".toLocaleLowerCase()) { fieldName = "RemainingDays"; }
    if (fieldName.toLocaleLowerCase() === "priority".toLocaleLowerCase()) { fieldName = "PriorityName"; }
    return fieldName;
  };
// This function is used to consume data from the API
  const consumeData = async () => {
    try {
      // Get drill down data from the API
      const data = await getDrillDownData();
      // Set the total count of the table
      setTableCount(data.data.totalCount);
      // Map through the data and create a new array with the required data
      const finalData = data.data.data.map((el, key) =>
        createData(
          el.companyName,
          el.assetName,
          el.noOfVariable,
          el.startDate,
          el.tat,
          el.remainingDays,
          el.priorityName,
          el.country,
          el.region,
          el.subRegion
        )
      );
      // Set the table data
      setTableData(finalData);
    } catch (error) {
      ErroHandling(error, function (res) {
        // Show error in the toaster
        toasterError(res)
      })
    }
  };

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Jobs in Queue Drill Down</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/aging-report" }}>Dashboard and Reports</Link>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/jobs-in-queue" }}>Jobs in Queue</Link>
            <Typography color="text.primary">Jobs in Queue Drill Down</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              <Button
                onClick={() => {
                  history.push("/app/data-collections/jobs-in-queue");
                }}
                variant="contained"
                color="accent2"
                startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid item textAlign="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                    onClick={handleExportToExcel}

                  >
                    Export To Excel
                  </Button>
                </Grid>
              </Grid>
              {/* Status Report Drill Down Table */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={tableData ? tableData : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={pageSize}
                  setRowsPerPage={(data) => {
                    setPageSize(data);
                    setReload(true);
                  }}
                  totalCount={tableCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={setSorting}
                  // setSorting={(e) => {
                  //   setReload(true);
                  //   setSorting(e);
                  // }}
                  hasChip={{
                    High: { color: "warning", label: "High" },
                    Critical: { color: "error", label: "Critical" },
                    Low: { color: "success", label: "Low" },
                    Medium: { color: "info", label: "Medium" },
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataCollectionJobDrillDown;