// Default React, useState and Material Components
import React, { useState, useEffect } from "react";

// Importing Custom Services
import AddUnitReport from "./AddEditUnitReport";
import SideDrawer from "../../../UIComponent/Drawer/index";

const AddUnitReportSideDrawer = (props) => {

    // Declaration of States and Component Variables Start Here
    const [reload, setReload] = useState(false);
    const [formData, setFormData] = useState([]);
    const [crudState, setCrudState] = React.useState(false);
    const [prevFormValue, setPrevFormValue] = useState(null);
    const [state, setState] = React.useState({ right: false });
    const [mappedCRUVariable, setMappedCRUVariable] = useState(props.mappedCRUVariable);
    // Declaration of States and Component Variables End Here

    // Declaration of React Hooks Start Here
    useEffect(() => {
        // if(props.open)
        if (props.crudModeForUnit) {
            toggleDrawer(true, "Add")
        } else {
            setState({ right: false });
            props.setCrudModeForUnit(false)
        }

    }, [props.crudModeForUnit]);
    // Declaration of React Hooks End Here

    //Function for toogle add edit drawer
    const toggleDrawer = (open, mode) => {
        if (mode === "Add") {
            let item = {};
            setState({ ...state, right: open, formData: item });
            setCrudState(mode);
        }
    };

    return (
        <SideDrawer
            formComp={
                <AddUnitReport
                    state={state}
                    setState={setState}
                    crudState={crudState}
                    formData={formData}
                    setFormData={setFormData}
                    setDefaultUnitValue={props.setDefaultUnitValue}
                    userId={null}
                    hideSideDrawer={() => {
                        props.setCrudModeForUnit(false)
                        setState({ right: false });
                        setReload(!reload);
                    }}
                    mappedCRUVariable={mappedCRUVariable}

                    setPrevFormValue={setPrevFormValue}
                    prevFormValue={prevFormValue}
                    addUnitReportDrawer={(e) => {
                    }}
                    closeVariableReportDrawer={() => {
                        props.setCrudModeForUnit(false)
                        setState({ right: false });
                    }}
                    updateUnitReport={() => {
                        if (props.updateUnitReportRefresh != null) {
                            props.updateUnitReportRefresh();
                        }
                    }}
                />
            }
            state={state}
            setState={setState}
            setPageRefresh={() => {
                setReload(!reload);
            }}
        />
    );
}

export default AddUnitReportSideDrawer;