/* eslint-disable no-undef */
// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Box, Button, Checkbox, Chip, FormControlLabel, Grid, IconButton, InputAdornment, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip
} from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import EmptyState from "../../../UIComponent/EmptyState";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import { upload } from "@testing-library/user-event/dist/upload";

// Importing Custom Components
import DatePicker from "../../../UIComponent/DatePicker";
import AlertDialog from "../../../UIComponent/AlertDialog";
import CustomAutoComplete from "../../../UIComponent/CustomAutoComplete";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import "../../../../src/index.scss";
import "../../../Resources/scss/layout.scss";
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./jobManagement.styles";

// Import Fluent UI Icons
import { AcceptIcon, CancelIcon, ClearIcon, DeleteIcon, EditIcon, SearchIcon } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../Services/CommonService";

const EditMultipleJobForAssignJob = (props) => {
  const [atat, setATat] = useState();
  const [userData, setUserData] = useState({ jobId: 0, jobName: "", jobInstructions: "", });
  let [tableRow, setTableRow] = useState();
  const [arrayData, stArrayData] = useState([]);
  let [jobtype, setJobType] = useState([{ id: 1, label: "Company Data" }]);
  const [searchValue, setSearchValue] = React.useState("");
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [finalData, setFinalData] = useState();
  const [isEdited, setIsEdited] = useState(true);
  const [deltedRow, setDeletedRow] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [remainingDays, setRemainingDays] = React.useState("0");
  const [rawRowData, setRawRowData] = useState({
    jobId: props.editJobNumber, jobName: "", jobInstructions: "", jobAsset: [
      {
        jobAssetId: 0, priorityId: 0, tat: "", isEdited: false, isDeleted: false,
        companyId: 0, isCRUCompany: true, assetId: 0, isCRUAsset: true,
      },
    ],
    priorityName: ""
  });
  let [priorities, setpriotity] = useState();
  const [asJobAsset, setAsJobAsset] = useState({
    jobAssetId: 0, priorityId: 0, tat: "", isEdited: true, isDeleted: true,
    companyId: 0, isCRUCompany: true, assetId: 0, isCRUAsset: true,
  });
  const [formData, setFormData] = useState({
    jobId: props.editJobNumber, jobName: "shhhh", jobInstructions: "", jobAsset: [asJobAsset], checkJobStartValue: false,
    startDate: ""
  });
  const [updateApi, setUpdateApi] = useState({
    jobId: props.idforEdit, jobName: "", jobInstructions: "", jobAsset: [
      {
        jobAssetId: 0, priorityId: 0, tat: "", isEdited: false, isDeleted: false,
        companyId: 0, isCRUCompany: true, assetId: 0, isCRUAsset: true,
      },
    ],
  });
  function createPirorityData(label, id) { return { label, id }; }
  const globalSearch = (value) => { setSearchValue(value); };

  const handleADClose = () => { setOpenSaveConfirmation(false); };

  React.useEffect(() => {
    axios.get(process.env.React_App_baseUrl + "DropDown/GetPriorityDropDownFilter", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        const rows = response.data.map((row) =>
          createPirorityData(row.priorityName, row.id)
        );
        setpriotity(rows);
      })
      .catch(function (error) { ErroHandling(error, function (res) { toasterError(res) }) });
  }, []);

  React.useEffect(() => {
    getDetailsForEdit(props.idforEdit);
    setFormData({ ...formData, jobId: props.editJobNumber });
  }, []);

  const submitForEdit = () => {
    setLoading(true);
    if (updateApi.jobName === '') {
      toasterError("Please check all the required fields");
      setLoading(false);
      return;
    }

    updateApi.jobAsset = tableRow
    if (deltedRow.length) {
      updateApi.jobAsset = [...updateApi.jobAsset, ...deltedRow]
    }
    axios.post(`${process.env.React_App_baseUrl}job/UpdateJob`, updateApi, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then((res) => {
        toasterSuccess("Job details updated successfully");
        setTimeout(function () {
          setLoading(false);
        }, 4000)
        getDetailsForEdit(props.idforEdit)
        if(props.getData){
          props.getData()
        }
      })
      .catch((err) => { setLoading(false); });
  };
  const getDetailsForEdit = (jobid) => {
    axios.get(
      `${process.env.React_App_baseUrl}job/GetJobCompanyAssetDetailForEdit`,
      {
        headers: {
          jobId: jobid,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      }
    )
      .then((res) => {
        setRawRowData(res.data);
        let ob = res.data.companyAssetDetailsDto.map((el) => {
          return {
            ...el, tat: el.tat.split("-").reverse().join("-"),
            startDate: el.startDate ? el.startDate.split("-").reverse().join("-") : null
          };
        });
        setTableRow(ob);
        const data = res.data;
        const jobAsset = [];
        data.companyAssetDetailsDto.map((item) =>
          jobAsset.push({
            ...updateApi.jobAsset[0],
            ...item,
            priorityId: item.priorityId,
            tat: item.tat.split("-").reverse().join("-"),
            startDate: item.startDate ? item.startDate.split("-").reverse().join("-") : null,
            jobAssetId: item.jobAssetId,
          })
        );
        setUpdateApi({
          ...updateApi,
          jobName: data.jobName,
          jobInstructions: data.jobInstructions,
          jobAsset,
        });
      })
      .catch((err) => { { ErroHandling(err, function (res) { toasterError(res) }) } });
  };

  const searchClear = () => { setSearchValue(""); searchUser("") };

  const handleDateChange = (e) => {
    let selectedDate = new Date(e);
    let date = selectedDate.getFullYear() +
      "-" + ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
      "-" + ("0" + selectedDate.getDate()).slice(-2);
    setFormData({ ...formData, tat: date, checkJobStartValue: false });
  };
  const classes = useStyles();
  var valueData = { jobId: "000" };
  const handleKeyUp = (event) => {

    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { searchUser(); }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      searchUser(searchValue);
    }
  };
  const searchUser = (filterValue) => {
    axios.get(
      `${process.env.React_App_baseUrl}job/GetJobCompanyAssetDetailForEdit`,
      {
        headers: {
          searchText: encodeURIComponent(filterValue),
          jobId: props.idforEdit,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      }
    )
      .then((res) => {
        setRawRowData(res.data);
        let ob = res.data.companyAssetDetailsDto.map((el) => {
          return {
            ...el, tat: el.tat.split("-").reverse().join("-"),
            startDate: el.startDate ? el.startDate.split("-").reverse().join("-") : null
          };
        });
        setTableRow(ob);
        const data = res.data;
        const jobAsset = [];
        data.companyAssetDetailsDto.map((item) =>
          jobAsset.push({
            ...updateApi.jobAsset[0],
            ...item,
            priorityId: item.priorityId,
            tat: item.tat.split("-").reverse().join("-"),
            startDate: item.startDate ? item.startDate.split("-").reverse().join("-") : null,
            jobAssetId: item.jobAssetId,
          })
        );
        setUpdateApi({
          ...updateApi,
          jobName: data.jobName,
          jobInstructions: data.jobInstructions,
          jobAsset,
        });
      })
      .catch((err) => { { ErroHandling(err, function (res) { toasterError(res) }) } });
  };


  const CommonJobStartDate = (e, value) => {

    if (rawRowData.startDate) {
      setFormData({ ...formData, checkJobStartValue: value })
      if (value) {
        let updatedRow = [];
        let promise = Promise.all(tableRow.map(async (element) => {
          if (element.companyId > 0 && element.assetId > 0) {
            let assetDetails = {
              companyId: element.companyId,
              assetId: element.assetId,
              isCRUCompany: element.isCRUCompany,
              isCRUAsset: element.isCRUAsset
            }
            await AsyncGetNoOfDays(assetDetails).then((res) => {
              let startDate = rawRowData.startDate;
              // Check if the date format is "dd-mm-yyyy"
              if (/^\d{2}-\d{2}-\d{4}$/.test(startDate)) {
                // If the format is "dd-mm-yyyy", convert it to "yyyy-mm-dd"
                let parts = startDate.split("-");
                startDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
              }
              let tempStartDate = new Date(startDate);
              //let tempStartDate = new Date(rawRowData.startDate);
              if (tempStartDate.toString().toLowerCase() === 'Invalid Date'.toLowerCase()) {
                tempStartDate = new Date(CommonService.GetDateInReverse(rawRowData.startDate))
                startDate = CommonService.GetDateInReverse(rawRowData.startDate);
              }
              //tempStartDate.setDate(tempStartDate.getDate() + res.data)
              let g = CommonService.addWorkingDays(tempStartDate, res.data)
              let newTatDate = CommonService.GetDateAsStringWithDash(g)
              let remainingDaysTemp = CommonService.getBusinessDatesCount(g)
              updatedRow.push({ ...element, startDate: startDate, remainingDays: remainingDaysTemp, tat: newTatDate, isEdited: true })
            })
          }
        }))
        promise.then(() => {
          if (updatedRow.length > 0) {
            setTableRow(updatedRow)
            setRawRowData({ ...rawRowData, isEdited: true })
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    }
    else {
      toasterError("Please select Job Start Date");
    }
  }

  const AsyncGetNoOfDays = async (finalAssetDetailObject) => {
    return axios.get(
      `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
      headers: {
        CompanyId: finalAssetDetailObject.companyId,
        AssetId: finalAssetDetailObject.assetId,
        IsCRUCompany: finalAssetDetailObject.isCRUCompany,
        IsCRUAsset: finalAssetDetailObject.isCRUAsset,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })

  }

  return (
    // Create Multiple Jobs
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={3} lg={2} xl={1}>
            <TextField
              id="standard-basic"
              label="Job ID"
              variant="standard"
              fullWidth
              readOnly
              value={props.editJobNumber}
              InputProps={{ shrink: true, }}
            />
          </Grid>
          <Grid item xs={12} md={9} lg={7} xl={8}>
            <TextField
              InputProps={{ shrink: true, }}
              InputLabelProps={
                props.crudState === "Edit" ? { shrink: true } : null
              }
              id="standard-basic"
              label="Job Name"
              variant="standard"
              onChange={(e) => {
                setUpdateApi({ ...updateApi, jobName: e.target.value, });
              }}
              value={updateApi.jobName || " "}
              fullWidth
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CustomAutoComplete
              options={jobtype}
              sx={{ width: 300 }}
              disabled={true}
              label="Job Type"
              optionLabelKey="label"
              optionValueKey="id"
              defaultValueKey={["id", "label"]}
              defaultValue={[1, "Company Data"]}
            />
          </Grid>
          <Grid item xs={12} md={9} lg={6}>
            <TextField
              fullWidth
              id="standard-multiline-static"
              label="Job Instructions"
              multiline
              rows={2}
              value={updateApi.jobInstructions}
              onChange={(e) => {
                setUpdateApi({ ...updateApi, jobInstructions: e.target.value, });
              }}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ mt: 3 }}>
            <TextField
              id="standard-basic"
              label="Priority"
              variant="standard"
              fullWidth
              disabled
              value={rawRowData?.priorityName}
              InputProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ mt: 3 }}>
            <DatePicker
              labelTitle="TAT"
              variant="standard"
              disabled
              defaultDate={rawRowData.tat ? rawRowData.tat : null}
              onChangeDate={(e) => {
                let selectedDate = new Date(e);
                let date = selectedDate.getFullYear() +
                  "-" + (selectedDate.getMonth() + 1) +
                  "-" + selectedDate.getDate();
                setValue({ ...value, tat: date, });
                rawRowData.remainingDays = CommonService.getBusinessDatesCount(new Date(date));
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} >

            <DatePicker
              labelTitle="Job Start Date"
              variant="standard"
              disabled={false}
              defaultDate={rawRowData.startDate ? rawRowData.startDate : null}
              onChangeDate={(e) => {
                if (e !== null && new Date(e).toDateString() !== 'Invalid Date') {
                  let selectedDate = new Date(e);
                  let date = CommonService.GetDateAsStringWithDash(selectedDate)
                  setRawRowData({ ...rawRowData, startDate: date })
                  setFormData({ ...formData, checkJobStartValue: false })
                } else {
                  setRawRowData({ ...rawRowData, startDate: undefined })
                  setFormData({ ...formData, checkJobStartValue: false })
                }

              }}
            />
          </Grid>


          <Grid item xs={12} md={5} lg={4} xl={3} >
            <FormControlLabel style={{ marginTop: "10px" }} control={<Checkbox checked={formData.checkJobStartValue} onChange={(e, value) => CommonJobStartDate(e, value)} />} label="Apply Common Job Start Date" />
          </Grid>



          <Grid item xs={12}>
            <Paper elevation={0} className="table-box"
              sx={{ borderRadius: "6px", boxShadow: 0, border: "1px solid #d7d7d7", }}>
              <div className="d-flex table-optons justify-content-between m-3">
                <div>
                  <TextField
                    id="input-with-icon-textfield"
                    className="serchByKeyword mt-1"
                    size="small"
                    value={searchValue}
                    placeholder="Search by Keyword"
                    onKeyUp={handleKeyUp}
                    onChange={(e) => globalSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  {searchValue ? (
                    <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                      <ClearIcon style={{ fontSize: 10 }} />
                    </IconButton>
                  ) : ("")}
                </div>
              </div>

              <TableContainer component={Paper} elevation={0} className={classes.cruTable}
                sx={{ "& > *": { borderBottom: "unset" }, boxShadow: 0 }}>
                <Table aria-label="simple table" >
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${classes.cruTheadFirst} ${classes.tableHeader}`} style={{ minWidth: 225 }}>
                        Company
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="left" style={{ minWidth: 200 }}>
                        Asset
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="left" style={{ minWidth: 120 }}>
                        Variable Count
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="left" style={{ minWidth: 200 }}>
                        Commodity
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="center" style={{ minWidth: 102 }}>
                        Job Start Date
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="center" style={{ minWidth: 130 }}>
                        TAT
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="center" style={{ minWidth: 116 }}>
                        Remaining Days
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="center" style={{ minWidth: 150 }}>
                        Priority
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="left" style={{ minWidth: 150 }}>
                        Country
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="left" style={{ minWidth: 150 }}>
                        Region
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="left" style={{ minWidth: 150 }}>
                        Sub Region
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="center" style={{ minWidth: 120 }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRow?.map((row) => (
                      <EditableTable
                        updateApi={updateApi}
                        isEdited={isEdited}
                        atat={atat}
                        id={props.idforEdit}
                        setATat={setATat}
                        asJobAsset={asJobAsset}
                        setAsJobAsset={setAsJobAsset}
                        row={row}
                        setTableRow={setTableRow}
                        tableRow={tableRow}
                        stArrayData={stArrayData}
                        priorities={priorities}
                        setUpdateApi={setUpdateApi}
                        setDeletedRow={setDeletedRow}
                        deltedRow={deltedRow}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="right">
              <Grid item xs={'auto'}>
                <Button variant="contained" className="rounded-border" color="accent3"
                  onClick={() => { props.setOpen(false); }}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={'auto'}>
                <LoadingButton
                  buttonName={"Update"}
                  clickHandler={submitForEdit}
                  isLoading={loading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Alert Dialog - Confirmation for Delete */}
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openSaveConfirmation}
        onClose={handleADClose}
        description="Job already exists for the selected asset(s).Click here to view the existing job(s) or click ok to proceed"
        action={
          <Grid container spacing={2} justifyContent="right">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>Cancel</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button color="secondary"
                onClick={() => {
                  axios.post(
                    process.env.React_App_baseUrl + "job/SaveJob",
                    finalData, {
                    headers: {
                      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                    }
                  })
                    .then(function (response) {
                      handleADClose();
                      toasterSuccess("Job saved successfully");
                      props.setOpen(false);
                      props.setPageRefresh(true);
                    })
                    .catch(function (error) { ErroHandling(error, function (res) { toasterError(res) }) });
                }}>
                OK
              </Button>
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default EditMultipleJobForAssignJob;
const EditableTable = ({
  updateApi, setUpdateApi, priorities, row, setTableRow, tableRow, stArrayData, setDeletedRow, deltedRow
}) => {
  // Declaration of States and Component Variables Start Here
  let [edit, setEdit] = useState(false);
  let [value, setValue] = useState();
  //let [jobStartDate, setJobStartDate]=useState()
  const [deletedItem, setDeletedItem] = useState({});
  const classes = useStyles();
  const [openConfirmation, setConfirmation] = useState(false);
  const handleADClose = () => { setConfirmation(false); };
  const editData = () => { setEdit(!edit); };
  const openAlertDialog = (item) => { setConfirmation(true); setDeletedItem(item); };
  const CancelData = () => { setValue(); setEdit(!edit); };
  const [reload, setReload] = useState(false);
  const saveData = () => {

    row.priorityId = value?.priorityId ? value.priorityId : row.priorityId;
    row.tat = value?.tat ? value.tat : row.tat;
    row.startDate = value?.startDate ? value.startDate : row.startDate;
    row.priorityName = value?.priorityName
      ? value.priorityName
      : row.priorityName;
    row.remainingDays = CommonService.getBusinessDatesCount(new Date(row.tat))
    row.isEdited = true
    setEdit(!edit);
  };

  // const checkRowValidation = (row) => {
  //   if (row.tat === null || row.tat === undefined || row.tat === "") {
  //     toasterError("TAT date can not be empty.")
  //     return false;
  //   }
  //   if (row.startDate === null || row.startDate === undefined || row.startDate === "") {
  //     toasterError("Job start date can not be empty.")
  //     return false;
  //   }
  //   if (new Date(row.tat) < new Date(row.startDate)) {
  //     toasterError("Job start date can not be greater than TAT date.")
  //     return false;
  //   }
  //   if (row.priorityId == 0 || row.priorityId === null || row.priorityId === undefined) {
  //     toasterError("priority can not be empty.")
  //     return false;
  //   }
  //   return true;
  // }


  const priorityChange = (ab) => { setValue({ ...value, priorityName: ab.label, priorityId: ab.id }); };
  const totChange = (e) => { setValue({ ...value, tat: e }); };
  const startDatechange = (e) => {
    //setValue({ ...value, startDate: e });
    console.log("row", row);
    if (row.companyId > 0 && row.assetId > 0) {

      axios.get(
        `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
        headers: {
          CompanyId: row.companyId,
          AssetId: row.assetId,
          IsCRUCompany: row.isCRUCompany,
          IsCRUAsset: row.isCRUAsset,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }).then((res) => {
        if (res.status === 200) {
          let tempStartDate = new Date(e);

          //tempStartDate.setDate(tempStartDate.getDate() + res.data);
          let g = CommonService.addWorkingDays(tempStartDate, res.data)
          let newTatDate = CommonService.GetDateAsStringWithDash(g);
          let remainingDaysTemp = CommonService.getBusinessDatesCount(g);
          row.tat = newTatDate;
          row.startDate = e;
          row.remainingDays = remainingDaysTemp;
          setReload(!reload)
          // setEdit(!edit);

        }
      })
    }
  }
  // Declaration of States and Component Variables End Here

  // Function for Delete Data
  const DeleteData = () => {
    let arr = [...tableRow];
    row.isDeleted = true
    deltedRow.push(row)
    setDeletedRow(deltedRow)
    let index = arr.indexOf(row);
    arr.splice(index, 1);
    setTableRow(arr);
    handleADClose();
    toasterSuccess("successfully Delete");
  };

  // Function for Handle Change
  const handleChange = (e, key, id) => {
    if (key === "isEdited") { setEdit(!edit); }
    const val = { ...updateApi };
    const role = val.jobAsset.find((r) => r.jobAssetId === id);
    role[key] = e;
    val.jobAsset.push(role);
    const item = [...new Set(val.jobAsset)];
    val.jobAsset = item;
    setUpdateApi({ ...val });
    setConfirmation(false);
  };

  const truncateString = (string, length) => {
    return (
      string.length > length ? string.substring(0, length) + "..." : string
    );
  }

  return (
    <>
      <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }} className={classes.cruTr}>
        <TableCell component="th" scope="row" className={classes.cruTdFirst}>
          {row.companyName}
        </TableCell>
        <TableCell align="left" className={classes.cruTd}>
          {row.assetName}
        </TableCell>
        <TableCell align="left" className={classes.cruTd}>
          {row.noOfVariable}
        </TableCell>
        <TableCell align="left" className={classes.cruTd}>

          <Tooltip title={row.commodity} placement="top" arrow>
            <div>
              {truncateString(`${row.commodity}`, 90)}
            </div>
          </Tooltip>
        </TableCell>
        {!edit ? (
          <TableCell align="center" className={classes.cruTd}>

            {row.startDate ? row.startDate.split("-").reverse().join("-") : null}

          </TableCell>
        ) : (
          <TableCell align="left" className={classes.cruTd}>
            <div style={{ width: 140 }}>
              <DatePicker
                size="small"
                fullWidth
                labelTitle="Job Start Date"
                variant="standard"
                defaultDate={row.startDate}
                label="disabled"
                onChangeDate={(e) => {
                  if (e !== null && new Date(e).toDateString() !== 'Invalid Date') {
                    let selectedDate = new Date(e);
                    let date = selectedDate.getFullYear() +
                      "-" + ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                      "-" + ("0" + selectedDate.getDate()).slice(-2);
                    startDatechange(date);

                  }
                }}
              />
            </div>
          </TableCell>
        )}
        {!edit ? (
          <TableCell align="center" className={classes.cruTd}>
            {row.tat.split("-").reverse().join("-")}
          </TableCell>
        ) : (
          <TableCell align="left" className={classes.cruTd}>
            <div style={{ width: 140 }}>
              <DatePicker
                size="small"
                fullWidth
                labelTitle="TAT"
                variant="standard"
                defaultDate={row.tat}
                label="disabled"
                onChangeDate={(e) => {

                  let selectedDate = new Date(e);
                  let date = selectedDate.getFullYear() +
                    "-" + ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                    "-" + ("0" + selectedDate.getDate()).slice(-2);
                  totChange(date);
                  row.remainingDays = CommonService.getBusinessDatesCount(new Date(date))
                }}
              />
            </div>
          </TableCell>
        )}
        <TableCell align="center" className={classes.cruTd}>
          {row.remainingDays}
        </TableCell>
        {!edit ? (
          <TableCell align="center" className={classes.cruTd}>
            {row.priorityName === "High" ? (
              <Chip
                label={row.priorityName}
                size="small"
                color="warning"
                className="statusChip"
              />
            ) : undefined}{" "}
            {row.priorityName === "Low" ? (
              <Chip
                label={row.priorityName}
                size="small"
                color="success"
                className="statusChip"
              />
            ) : undefined}{" "}
            {row.priorityName === "Medium" ? (
              <Chip
                label={row.priorityName}
                size="small"
                color="info"
                className="statusChip"
              />
            ) : undefined}
            {row.priorityName === "Critical" ? (
              <Chip
                label={row.priorityName}
                size="small"
                color="error"
                className="statusChip"
              />
            ) : undefined}
          </TableCell>
        ) : (
          <TableCell align="left" className={classes.cruTd}>
            <div className={classes.smallAlign}>
              <CustomAutoComplete
                size="small"
                options={priorities}
                sx={{ width: 300 }}
                label="Priority"
                disableClearable={true}
                optionLabelKey="label"
                optionValueKey="id"
                onChange={(value) => {
                  priorityChange(value);
                }}
                defaultValueKey={["id", "label"]}
                defaultValue={[row.priorityId, row.priorityName]}
              />
            </div>
          </TableCell>
        )}
        <TableCell align="left" className={classes.cruTd}>
          {row.countryName}
        </TableCell>
        <TableCell align="left" className={classes.cruTd}>
          {row.regionName}
        </TableCell>
        <TableCell align="left" className={classes.cruTd}>
          {row.subRegionName}
        </TableCell>

        {!edit ? (
          <TableCell align="center" className={classes.cruTd}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton aria-label="edit" color="primary">
                <EditIcon className={classes.iconSize}
                  onClick={() => handleChange(true, "isEdited", row.jobAssetId)} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton aria-label="Delete" sx={{ color: "red" }}>
                <DeleteIcon className={classes.iconSize}
                  onClick={() => openAlertDialog(row)} />
              </IconButton>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell align="center" className={classes.cruTd}>
            <Tooltip title="Submit" placement="top" arrow>
              <IconButton aria-label="Submit">
                <AcceptIcon className={classes.iconSize} onClick={saveData} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel" placement="top" arrow>
              <IconButton aria-label="Cancel" color="secondary">
                <CancelIcon className={classes.iconSize} onClick={CancelData} />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>

      {/* Alert Dialog - Confirmation for Delete */}
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openConfirmation}
        onClose={handleADClose}
        description="The asset will be deleted from the job. Are you sure you want to delete?"
        action={
          <Grid container spacing={2} justifyContent="right">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>No</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button onClick={DeleteData} color="secondary">Yes</Button>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};