// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Box, Breadcrumbs, Button, Checkbox, Collapse, Grid, IconButton, Table, TableBody, TableCell,
  TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography, Paper, Chip, Tooltip
} from "@mui/material";
import axios from "axios";
import AddRecords from "./AddRecords";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { visuallyHidden } from "@mui/utils";
import { useHistory } from "react-router-dom";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError } from "../../../UIComponent/Toaster";
// Importing Custom Components
// import "./index.scss";
import FilterJob from "./FilterJob";
import CreateJobPage from "./CreateJob";
import { ScrollingDialog } from "../../../UIComponent";
import BaseLayout from "../../../UIComponent/BaseLayout";
import CreateMultipleJobPage from "./CreateMultipleJob";
import SideDrawer from "../../../UIComponent/Drawer/index";
import { LoaderWrapper } from "../../../UIComponent/Progress";

// Importing useStyles
import useStyles from "./jobManagement.styles";

// Import Material Icons
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from "@mui/icons-material/Group";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";
import InputAdornment from "@mui/material/InputAdornment";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

// Import Fluent UI Icons
import { ChevronRightSmallIcon, ClearIcon } from "@fluentui/react-icons-mdl2";

// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import { getcompaniesBasedOnID } from "../../features/JobManagement/jobSlice";
// import { set } from "date-fns";
import GetMenuService from '../../../Services/GetMenuService';
import CommonService from "../../../Services/CommonService";

function createData(
  company, commodity, noOfAssets, priority, status, companyId, isCRUCompany,
  isJiraCreated, isopen, isSelected, children, isIndetSelected
) {
  return {
    company, commodity, noOfAssets, priority, status, companyId, isCRUCompany,
    isJiraCreated, isopen, isSelected, children, isIndetSelected,
  };
}

function createSubData(
  asset, assetType, variableCount, country, region, subRegion, commodity, status, isCRUAsset,
  isJiraTicketCreated, assetId, companyId, isCRUCompany, isSelected
) {
  return {
    asset, assetType, variableCount, country, region, subRegion, commodity, status, isCRUAsset,
    isJiraTicketCreated, assetId, companyId, isCRUCompany, isSelected,
  };
}

const truncateString = (string, length) => {
  return (
    string.length > length ? string.substring(0, length) + "..." : string
  );
}



function Row(props) {
  const { row } = props;
  const classes = useStyles();
  if (props.PreserveState !== null && props.PreserveState !== undefined && props.PreserveState.length > 0) {
    let tempRows = props.PreserveState;
    let tempRow = tempRows.filter((e) => { return e.company == row.company })
    if (tempRow != null && tempRow.length > 0) { row.isSelected = tempRow[0].isSelected; }
  }
  // console.log(row, "children");

  return (
    <React.Fragment>
      <TableRow hover sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell key={"checkboxIdOne"} padding="checkbox" style={{ height: 46 }} className={classes.TableCell}>
          <Checkbox
            checked={row.isSelected}
            indeterminate={row.isIndetSelected}
            onChange={(event) => props.individualCheckOnChange(row, "outer")}
            inputProps={{ "aria-label": "Items" }}
          />
        </TableCell>

        <TableCell style={{ paddingLeft: 0, height: 46 }} className={classes.TableCell}>
          <IconButton aria-label="expand row" size="small"
            onClick={() => {
              let company = props.company.map((cmd) => {
                if (row.companyId === cmd.companyId) {
                  cmd.isopen = !cmd.isopen;
                }
                else { cmd.isopen = false; }
                return cmd;
              });
              props.setCompany(company);
              if (row.noOfAssets && !row.children.length) {
                var filterData;
                if (props.filterValue) {
                  let s = props.searchValue
                    ? { searchText: props.searchValue }
                    : "";
                  filterData = { ...props.filterValue, ...s };
                } else {
                  filterData = props.searchValue
                    ? { searchText: props.searchValue } : "";
                }
                axios
                  .get(process.env.React_App_baseUrl + "source/GetAssets", {
                    headers: {
                      CompanyId: row.companyId,
                      IsCRUCompany: row.isCRUCompany,
                      sourceFilter: filterData ? encodeURIComponent(JSON.stringify(filterData)) : "",
                      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

                    },
                    params: { pageNumber: 1, pagesize: 1000, },
                  })
                  .then(function (response) {
                    let mRow = row;
                    const rowsData = response.data.data.map((row) =>

                      createSubData(
                        row.asset, row.assetType, row.noOfVariable, row.country, row.region, row.subRegion, row.commodity,
                        row.status, row.isCRUAsset, row.isJiraTicketCreated, row.assetId, row.companyId,
                        row.isCruCompany, mRow.isSelected || false)
                    );
                    row.children = [...rowsData];
                    props.setCompany([...company]);
                  })
                  .catch(function (error) {
                    ErroHandling(error, function (res) {
                      toasterError(res)
                    })
                  });
              }
            }}>

            {row.isopen ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className={classes.TableCell}>{row.company}</TableCell>
        <TableCell align="left" className={classes.TableCell}>
          {row.commodity === null ? '-' :
            <Tooltip title={row.commodity} placement="top" arrow>
              <div>
                {truncateString(`${row.commodity}`, 90)}
              </div>
            </Tooltip>
          }

        </TableCell>
        <TableCell align="center" className={classes.TableCell}><div className="pr-4">{row.noOfAssets}</div></TableCell>
        <TableCell align="center" className={classes.TableCell}>
          <div className="pr-4">
            {" "}
            {row.priority === "High" ? (
              <Chip label={row.priority} size="small" color="warning" className="statusChip" />
            ) : undefined}{" "}
            {row.priority === "Low" ? (
              <Chip label={row.priority} size="small" color="success" className="statusChip" />
            ) : undefined}{" "}
            {row.priority === "Medium" ? (
              <Chip label={row.priority} size="small" color="info" className="statusChip" />
            ) : undefined}
            {row.priority === "Critical" ? (
              <Chip label={row.priority} size="small" color="error" className="statusChip" />
            ) : undefined}
          </div>
        </TableCell>
        <TableCell align="center" className={classes.TableCell}><div className="pr-4">{row.status}</div></TableCell>
      </TableRow>
      <TableRow className="checkPlusMinus-table">        
        <TableCell style={{ padding: 0, borderBottomColor: "#ebebeb", background: "#f9f9f9" }} colSpan={7}>
          <Collapse in={row.isopen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '10px 10px 10px 60px' }}>
              <div className={classes.fullWidth2}>
                <TableContainer sx={{ maxHeight: 350, minWidth: 905 }}>
                  <Table size="small" aria-label="purchases" stickyHeader>
                    <TableHead>
                      <TableRow hover>
                        <TableCell className={classes.TableHead} style={{ width: "50px", height: 46 }} align="left"></TableCell>
                        <TableCell className={classes.TableHead} style={{ minWidth: 200, width: "20%" }} align="left">Asset</TableCell>
                        <TableCell className={classes.TableHead} style={{ minWidth: 200, width: "20%" }} align="left">Asset Type</TableCell>
                        <TableCell className={classes.TableHead} style={{ minWidth: 200, width: "20%" }} align="left">Variable Count</TableCell>
                        <TableCell className={classes.TableHead} style={{ minWidth: 150, width: "10%" }} align="left">Country</TableCell>
                        <TableCell className={classes.TableHead} style={{ minWidth: 150, width: "10%" }} align="left">Region</TableCell>
                        <TableCell className={classes.TableHead} style={{ minWidth: 150, width: "10%" }} align="left">Sub Region</TableCell>
                        <TableCell className={classes.TableHead} style={{ minWidth: 350, width: "20%" }} align="left">Commodity</TableCell>
                        <TableCell className={classes.TableHead} style={{ minWidth: 150, width: "10%" }} align="center">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.children.map((historyRow, index) => (
                        <TableRow hover key={historyRow.asset + "_" + index}>
                          <TableCell key={"checkboxIdTwo"} padding="checkbox" className={classes.TableCell}>
                            <Checkbox checked={historyRow.isSelected}
                              onChange={(event) => props.individualCheckOnChange(row, "inner", index)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" className="normal-font">
                            {historyRow.asset}
                          </TableCell>
                          <TableCell className={classes.TableCell} align="left" style={{ height: 46 }}>{historyRow.assetType}</TableCell>
                          <TableCell className={classes.TableCell} align="left" style={{ height: 46 }}>{historyRow.variableCount ? historyRow.variableCount : 0}</TableCell>
                          <TableCell className={classes.TableCell}>{historyRow.country}</TableCell>
                          <TableCell className={classes.TableCell} align="left">{historyRow.region}</TableCell>
                          <TableCell className={classes.TableCell} align="left">{historyRow.subRegion}</TableCell>
                          <TableCell className={classes.TableCell} align="left">
                            <Tooltip title={historyRow.commodity} placement="top" arrow>
                              <div>
                                {truncateString(`${historyRow.commodity}`, 90)}
                              </div>
                            </Tooltip>
                          </TableCell>
                          <TableCell className={classes.TableCell} align="center">{historyRow.status}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const DataCollectionCreateJob = (props) => {
  const [historyData, setHistoryData] = useState();
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({ right: false, });
  const [state2, setState2] = React.useState({ right: false, });
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = () => () => { history.push("/app/data-collections/manage-job-records"); };
  const [isAssetSelected, setIsAssetSelected] = React.useState(false);
  const [assetIndex, setAssetIndex] = React.useState(0);
  const [companyValue, setCompanyValue] = React.useState(0);
  const [newJob, setNewJob] = React.useState(true);
  const [companyDropDown, setCompanyDropDown] = React.useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [companyDropDownTrue, setCompanyDropDownTrue] = React.useState([]);
  const [companyDropDownFalse, setCompanyDropDownFalse] = React.useState([]);
  const [assetTypeDropDown, setAssetTypeDropDown] = React.useState([]);
  const [regionDropDown, setRegionDropDown] = React.useState([]);
  const [commodityDropDown, setCommodityDropDown] = React.useState([]);
  const [statusDropDown, setStatusDropDown] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [company, setCompany] = React.useState([]);
  let [filterValue, setFilterValue] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [crudState, setCrudState] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [companyId, setCompanyId] = React.useState();
  const [assetId, setAssetId] = React.useState();
  const [sorting, setSorting] = useState({ orderBy: "", order: false });
  const [isAllSelected, setIsSelected] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedValue, setSelectedValue] = useState();
  const [argumentValue, setArgumentValue] = useState({});
  const [createJobData, setCreateJobData] = useState({});
  const [pageRefresh, setPageRefresh] = useState(false);
  const [rowValue, setRowValue] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [level, setLevel] = useState("");
  const [assetValue, setAssetValue] = useState(0);
  const [disableAsset, setDisableAsset] = useState(false);
  const handleRequestSort = (event, property) => {
    const isAscending = sorting.orderBy === property && sorting.order;
    setSorting({ orderBy: property, order: isAscending ? false : true });
  };
  const [openRecords, setOpenRecords] = React.useState(false);
  const [scrollRecords, setScrollRecords] = React.useState("paper");
  const [crudStateRecords, setCrudStateRecords] = React.useState(false);
  const [jobIdForAddingRecords, setJobIdForAddingRecords] = React.useState(null)
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValues, setFilterValues] = useState();
  const [assetTypeDD, setAssetTypeDD] = useState([]);
  const [reload, setReload] = useState(false)
  const [preserveState, setPreserveState] = useState([])
  const [jobCompanies, setJobCompanies] = React.useState([])
  const [jobLocations, setJobLocations] = React.useState([])
  const [assetTypes, setAssetTypes] = React.useState(dataForFilter.assetType ? dataForFilter.assetType : [])
  const [commodityList, setCommodityList] = React.useState(dataForFilter.commodityList ? dataForFilter.commodity : [])
  const [priorityList, setPriorityList] = React.useState(dataForFilter.priority ? dataForFilter.priority : [])
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
  const [isLoading, setIsLsoading] = useState(false);

  React.useEffect(() => {
    GetHistory()
    GetMenuService(6, function (err, res) {
      if (res) {
        // console.log(res.data)
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])

  // console.log(jobIdForAddingRecords, "jobIdForAddingRecords")

  React.useEffect(() => {

    if (pageRefresh) {
      setPage(0);
      setRowsPerPage(10);
      setSorting({ orderBy: "", order: false });
      setSearchValue("");
      setPageRefresh(false);
      setIsAssetSelected(false);
    } else {
      getCompany();
      setIsAssetSelected(false);
    }
  }, [filterValue, sorting, page, rowsPerPage, pageRefresh === true]);

  React.useEffect(() => {
    axios.get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
      headers: {
        isNewSource: false,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
    })
      .then(function (response) {
        setCompanyDropDownFalse(response.data);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });

    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
        headers: {
          isNewSource: true,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      })
      .then(function (response) {
        setCompanyDropDownTrue(response.data);
        setAllCompany(companyDropDownFalse.concat(response.data));
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });

    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDownFilter",
        {
          headers: {
            isNewSource: false,
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          },
        })
      .then(function (response) {
        setCompanyDropDown(response.data);
      })
      .catch(function (error) { });

    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDownFilter",
        {
          headers: {
            isNewSource: true,
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          },
        })
      .then(function (response) {
        setCompanyDropDown(response.data);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });

    ////asset type DropDown API
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetAssetTypeDropDownFilter", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then(function (response) { setAssetTypeDropDown(response.data); })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });

    /// Region Dropdown API calling

    axios.get(process.env.React_App_baseUrl + "DropDown/GetRegionDropDownFilter", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) { setRegionDropDown(response.data); })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });

    /////Commodity Drop DOwn API
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCommodityDropDownFilter", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then(function (response) { setCommodityDropDown(response.data); })
      .catch(function (error) { });

    //////////status dropdown API
    axios.get(process.env.React_App_baseUrl + "DropDown/GetStatusDropDownFilter", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) { setStatusDropDown(response.data); })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
    return () => { setCompany([]); };
  }, []);

  const GetHistory = () => {
    let temp = CommonService.GetStoredData(props, "historyData");
    if (temp) {
      setHistoryData(temp);
      setJobIdForAddingRecords(temp.state);
    }
  }

  const getCompany = () => {

    setIsLsoading(true)
    if (filterValue) {
      let s = searchValue ? { searchText: searchValue } : "";
      filterValue = { ...filterValue, ...s };
    } else {
      filterValue = searchValue ? { searchText: searchValue } : "";
    }
    axios.get(process.env.React_App_baseUrl + "source/GetCompanies", {
      headers: {
        sortColumn: sorting.orderBy,
        sourceFilter: filterValue ? encodeURIComponent(JSON.stringify(filterValue)) : "",
        isAscending: sorting.order,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

      },
      params: { pageNumber: page + 1, pagesize: rowsPerPage, },
    })
      .then(function (response) {
        setIsSelected(false);
        setTotalCount(response.data.totalCount);
        const rowsData = response.data.data.map((row) =>
          createData(
            row.company,
            row.commodity,
            row.noOfAssets,
            row.priority,
            row.status,
            row.companyId,
            row.isCRUCompany,
            row.isJiraCreated,
            false,
            false,
            [],
            false
          )
        );
        setCompany(rowsData);
        setIsLsoading(false)
      })
      .catch(function (error) {
        setIsLsoading(false)
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
  };

  const handleKeyUp = (event) => {
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      getCompany();
    }
  };

  const globalSearch = (value) => {
    setPage(0);
    setSearchValue(value ? value : "");
  };

  const searchClear = () => {
    setSearchValue("");
    axios.get(process.env.React_App_baseUrl + "source/GetCompanies", {
      headers: {
        sourceFilter: filterValue ? encodeURIComponent(JSON.stringify(filterValue)) : "",
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
      params: { pageNumber: page + 1, pagesize: rowsPerPage, },
    })
      .then(function (response) {
        setTotalCount(response.data.totalCount);
        const rowsData = response.data.data.map((row) =>
          createData(
            row.company,
            row.commodity,
            row.noOfAssets,
            row.priority,
            row.status,
            row.companyId,
            row.isCRUCompany,
            row.isJiraCreated,
            false,
            false,
            [],
            false
          )
        );
        setCompany(rowsData);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
  };

  /////Filter side drawer
  const toggleDrawer2 = (open) => (event) => {
    setState2({ ...state2, right: open });
    setShowFilter(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log(+event.target.value, "setRowsPerPage");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function createSendData(companyId, isCRUCompany, assetId, isCRUAsset) {
    return { companyId, isCRUCompany, assetId, isCRUAsset };
  }

  const mapToCompanyId = (id, isCRUCompany) => {
    if (isCRUCompany) {
      for (let i = 0; i < companyDropDownTrue.length; i++) {
        if (companyDropDownTrue[i].companyId === id) {
          setCreateJobData(companyDropDownTrue[i]);
        }
      }
    } else if (!isCRUCompany) {
      for (let i = 0; i < companyDropDownFalse.length; i++) {
        if (companyDropDownFalse[i].companyId === id) {
          setCreateJobData(companyDropDownFalse[i]);
        }
      }
    }
  };

  const overAllCheckOnCHange = (event) => {
    // console.log(event);
    let cmpData = company.map((el) => {
      el.isSelected = !isAllSelected;
      el.isIndetSelected = false;
      if (el.noOfAssets && el.children && el.children.length) {
        el.children = el.children.map((ch) => {
          ch.isSelected = !isAllSelected;
          return ch;
        });
      }
      return el;
    });
    setCompany(cmpData);
    setIsSelected(!isAllSelected)
    setPreserveState(cmpData)

  };

  const createJob = () => {
    let reqObj = [];
    (company || []).forEach((el) => {
      let obj = {};
      if (el.isSelected) {
        reqObj.push({
          companyId: el.companyId,
          isCRUCompany: el.isCRUCompany,
          assetId: 0,
          isCRUAsset: el.isCRUCompany,
          noOfAssets: el.noOfAssets
        });
      } else if (!el.isSelected && el.isIndetSelected && el.children.length) {
        const data = el.children
          .filter((ch) => ch.isSelected)
          .map((ch) => {
            return {
              companyId: el.companyId,
              isCRUCompany: el.isCRUCompany,
              assetId: ch.assetId,
              isCRUAsset: ch.isCRUAsset,
            };
          });
        reqObj = [...reqObj, ...data];
      }
    });
    setSelectedValue(reqObj);
    // console.log("reqObj",reqObj)
    if (reqObj.length === 1 && (reqObj[0].noOfAssets === 1 || reqObj[0].noOfAssets === 0)) {
      // console.log("111")
      setAssetValue(reqObj[0].assetId);
      setState({ ...state, right: true });
      setCrudState(true);
      setNewJob(false);
      mapToCompanyId(reqObj[0].companyId, reqObj[0].isCRUCompany);
      if (reqObj[0].assetId > 0) {
        setIsAssetSelected(true);
        disableAsset(false);
      }
      else { setIsAssetSelected(false); }
    } else if (reqObj.length > 1 || (reqObj.length === 1 && reqObj[0].noOfAssets > 1)) {
      // console.log("222")
      setOpen(true);
      setScroll("paper");
      setNewJob(false);
    } else {
      // console.log("333")
      setState({ ...state, right: true });
      setNewJob(true);
      setDisableAsset(true);
    }
  };

  const individualCheckOnChange = (row, level, index) => {
    // console.log("individualcheckOnChange", row, level, index)
    if (level === "outer") {
      setLevel(level);
      row.isSelected = !row.isSelected;
      row.isIndetSelected = false;
      if (row.noOfAssets && row.children && row.children.length) {
        row.children = row.children.map((ch) => {
          ch.isSelected = row.isSelected;
          return ch;
        });
      }
    } else {
      setLevel(level);
      setAssetIndex(index);
      row.children[index].isSelected = !row.children[index].isSelected;
      const every = row.children.every((el) => el.isSelected);
      const some = row.children.some((el) => el.isSelected);
      row.isSelected = every ? true : false;
      row.isIndetSelected = some && !every ? true : false;
    }
    setCompany([...company]);
    const selected = row.isSelected ? company.every((el) => el.isSelected) : false;
    setIsSelected(selected);
    let lastState = preserveState;
    let duplicateRow = lastState.filter((e) => { return e.company === row.company && e.isSelected !== row.isSelected })
    if (duplicateRow !== null) {
      let index = lastState.indexOf(duplicateRow);
      lastState.splice(index, 1);
      lastState.push(row)
    }
    setPreserveState(lastState)
    // console.log(selected)
  };

  const openAddRecords = (open, mode, e) => (event, item) => {
    setOpenRecords(true);
    setScrollRecords("paper");
  };

  React.useEffect(() => {
    getFilterData();
    getCompany();
    getAssetTypeDropDownForFilter()
  }, [reload])


  const getFilterData = async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }
      );
      if (response.data.length === 0) {
        setDataForFilter([]);
      } else {
        setDataForFilter(response.data);
        response.data.companies.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => { item.checked = false; });
          }
        });
        response.data.location.regions.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => { item.checked = false })
            if (item.children) {
              item.children.forEach((item, index) => { item.checked = false })
            }
          }

        });
        setCompanyFilter(response.data.companies);
        setLocationFilter(response.data.location.regions);
      }
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res)
      })
    }
  };

  const getAssetTypeDropDownForFilter = async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetAssetTypeDropDownFilter`, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }
      );
      if (response.data.length === 0) {
        setAssetTypeDD([]);
      } else {
        setAssetTypeDD(response.data);
      }
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res)
      })
    }
  };

  const resetTreeFilter = () => {
    companyFilter.forEach((item, index) => {
      item.checked = false
      if (item.children) {
        item.children.forEach((item, index) => {
          item.checked = false
        })
      }
    })
    locationFilter.forEach((item, index) => {
      item.checked = false;
      if (item.children) {
        item.children.forEach((item, index) => {
          item.checked = false
        })
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false
          })
        }
      }
    });
  }

  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Create Jobs</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/create-jobs" }}>Job Management</Link>
            <Typography color="text.primary">Create Jobs</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {historyData?.state && <Button
                onClick={openAddRecords()}
                variant="contained" color="accent2" startIcon={<AddIcon />}>
                Add Records
              </Button>}
            </Grid>
            <Grid item xs={"auto"}>
              {(historyData?.state || access.allowEdit) && <Button
                onClick={createJob}
                variant="contained" color="accent2" startIcon={<AddIcon />}>
                Create Job
              </Button>}
            </Grid>
            <Grid item xs={"auto"}>
              {access.allowView && <Button
                onClick={handleClickOpen()}
                variant="contained" color="secondary" startIcon={<GroupIcon />}>
                View Job Records
              </Button>}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      size="small"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : ("")}

                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Button onClick={toggleDrawer2(true)} variant="contained" color="secondary"
                      startIcon={<FilterAltOutlinedIcon />}>
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}
              <LoaderWrapper loading={isLoading}>
                <TableContainer component={Paper} elevation={0} className={classes.container}>
                  <Table aria-label="collapsible table" stickyHeader>
                    <TableHead>
                      <TableRow hover>
                        <TableCell key={"checkboxIdThree"} padding="checkbox" style={{ width: "2%" }}>
                          <Checkbox checked={isAllSelected}
                            onChange={overAllCheckOnCHange}
                            inputProps={{ "aria-label": "Select All" }}
                          />
                        </TableCell>
                        <TableCell style={{ width: "2%" }} className={classes.tableHeader} />
                        <TableCell style={{ width: "25%" }} className={classes.tableHeader}
                          sortDirection={
                            sorting.orderBy === "Company" ? sorting.order ? "asc" : "desc" : false
                          }>
                          <TableSortLabel
                            active={sorting.orderBy === "Company"}
                            direction={
                              sorting.orderBy === "Company" ? sorting.order ? "asc" : "desc" : "asc"}
                            onClick={(e) => handleRequestSort(e, "Company")}>
                            Company
                            {sorting.orderBy === "Company" ? (
                              <Box component="span" sx={visuallyHidden}>
                                {sorting.order === false ? "sorted descending" : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="left" className={classes.tableHeader}>Commodity</TableCell>
                        <TableCell style={{ width: "13%" }} align="center" className={classes.tableHeader}
                          sortDirection={sorting.orderBy === "NoOfAssets" ? sorting.order ? "asc" : "desc" : false}>
                          <TableSortLabel
                            active={sorting.orderBy === "NoOfAssets"} className={classes.tableHeader}
                            direction={
                              sorting.orderBy === "NoOfAssets" ? sorting.order ? "asc" : "desc" : "asc"}
                            onClick={(e) => handleRequestSort(e, "NoOfAssets")}>
                            No. of Assets
                            {sorting.orderBy === "NoOfAssets" ? (
                              <Box component="span" sx={visuallyHidden}>
                                {sorting.order === false ? "sorted descending" : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ width: "13%" }} align="center" className={classes.tableHeader}
                          sortDirection={sorting.orderBy === "Priority" ? sorting.order ? "asc" : "desc" : false}>
                          <TableSortLabel active={sorting.orderBy === "Priority"}
                            direction={
                              sorting.orderBy === "Priority" ? sorting.order ? "asc" : "desc" : "asc"}
                            onClick={(e) => handleRequestSort(e, "Priority")}
                          >
                            Priority
                            {sorting.orderBy === "Priority" ? (
                              <Box component="span" sx={visuallyHidden}>
                                {sorting.order === false ? "sorted descending" : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell style={{ width: "15%" }} align="center" className={classes.tableHeader}
                          sortDirection={sorting.orderBy === "Status" ? sorting.order ? "asc" : "desc" : false}>
                          <TableSortLabel
                            active={sorting.orderBy === "Status"}
                            direction={sorting.orderBy === "Status" ? sorting.order ? "asc" : "desc" : "asc"}
                            onClick={(e) => handleRequestSort(e, "Status")}>
                            Status
                            {sorting.orderBy === "Status" ? (
                              <Box component="span" sx={visuallyHidden}>
                                {sorting.order === false ? "sorted descending" : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>

                      {company.map((row, index) => (

                        <Row
                          key={row?.companyId + "-" + index}
                          row={row}
                          setState={setState}
                          setCrudState={setCrudState}
                          filterValue={filterValue}
                          searchValue={searchValue}
                          setCompanyId={setCompanyId}
                          setAssetId={setAssetId}
                          isAllSelected={isAllSelected}
                          individualCheckOnChange={individualCheckOnChange}
                          company={company}
                          setCompany={setCompany}
                          PreserveState={preserveState}
                        />

                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </LoaderWrapper>
              <TablePagination
                rowsPerPageOptions={[10, 15, 25, 50, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </BaseLayout>
        </Grid>

        <ScrollingDialog
          open={open}
          setOpen={setOpen}
          scroll={scroll}
          setScroll={setScroll}
          dialogClass="darkTitle"
          dialogTitle="Create Jobs"
          dialogContent={
            <CreateMultipleJobPage
              state={state}
              selectedValue={selectedValue}
              setState={setState}
              setOpen={setOpen}
              setPageRefresh={setPageRefresh}
            />
          } />

        <SideDrawer
          formComp={
            <CreateJobPage
              assetValue={assetValue}
              setPageRefresh={setPageRefresh}
              setLevel={setLevel}
              createJobData={createJobData}
              companyData={company.filter(item => item.isSelected || item.isIndetSelected)}
              state={state}
              setState={setState}
              companyDropDown={companyDropDown}
              assetTypeDropDown={assetTypeDropDown}
              regionDropDown={regionDropDown}
              disableAsset={disableAsset}
              setDisableAsset={setDisableAsset}
              // subRegionDropDown={subRegionDropDown}
              // countryDropDown={countryDropDown}
              commodityDropDown={commodityDropDown}
              statusDropDown={statusDropDown}
              isAssetSelected={isAssetSelected}
              setIsAssetSelected={setIsAssetSelected}
              assetIndex={assetIndex}
              setAssetIndex={setAssetIndex}
              companyValue={companyValue}
              newJob={newJob}
              setNewJob={setNewJob}
            />
          }
          state={state}
          setState={setState}
          crudState={crudState}
          setPageRefresh={setPageRefresh}
          setLevel={setLevel}
          setIsAssetSelected={setIsAssetSelected}
        />

        <SideDrawer
          formComp={
            <FilterJob
              dataForFilter={dataForFilter}
              filterFinalData={filterFinalData}
              companyFilter={companyFilter}
              locationFilter={locationFilter}
              setFilterValues={setFilterValues}
              assetTypeDD={assetTypeDD}
              setFilterFinalData={setFilterFinalData}
              setFilterValue={setFilterValue}
              state={state2}
              setState={setState2}
              reload={reload}
              setReload={setReload}
              resetTreeFilter={resetTreeFilter}
              setJobCompanies={setJobCompanies}
              setJobLocations={setJobLocations}
              setLocationFilter={setLocationFilter}
              setCompanyFilter={setCompanyFilter}
              setCommodityList={setCommodityList}
              setPriorityList={setPriorityList}
              setDataForFilter={setDataForFilter}
              getFilterData={getFilterData}
            />
          }
          state={state2}
          setState={setState2}
          crudState={showFilter}
          reload={reload}
          setReload={setReload}
          setFilterValue={setFilterValue}
        />

        <ScrollingDialog
          open={openRecords}
          setOpen={setOpenRecords}
          scroll={scrollRecords}
          setScroll={setScrollRecords}
          dialogClass="darkTitle"
          dialogTitle={"Add Records"}
          dialogContent={
            <AddRecords jobid={jobIdForAddingRecords} companyData={company} setOpenRecords={setOpenRecords} />
          }
        ></ScrollingDialog>
      </Grid>
    </Box>
  );
};

export default DataCollectionCreateJob;