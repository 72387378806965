// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  InputAdornment,
    TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
//import history from "../../Routes/History";
import { useHistory } from "react-router-dom";
import ApiService from "../../../../Services/ApiService";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
import GetMenuService from "../../../../Services/GetMenuService";
// Importing Custom Components
import DataTable from "../../../../UIComponent/DataTable";
import BaseLayout from "../../../../UIComponent/BaseLayout";
import { LoaderWrapper } from "../../../../UIComponent/Progress";
// Importing useStylesQC
import useStyles from "./DataCollectionQCFailedReport.styles";

// Import Fluent UI Icons
import {
  ChevronRightSmallIcon,
  ClearIcon,
  SearchIcon,
} from "@fluentui/react-icons-mdl2";
import { Button } from "@mui/material";
import SideDrawer from "../../../../UIComponent/Drawer/index";
import QualityFilter from "./FilterForm";
import { FilterIcon } from "@fluentui/react-icons-mdl2";

const DataCollectionQCFailedReport = (props) => {

  const getDefaultFilter = () =>{
    return {"searchText":"", "company":[], "asset":[], "tat":"", "statusId":[],"analystId":[],"qcUserId":[]};
}

  // Declaration of States and Component Variables Start Here
 const classes = useStyles();
  const [allRows, setAllRows] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [selected, SetSelected] = useState([]);
  const [isAllSelected, setIsSelected] = useState(false);
  const [state, setState] = React.useState({ right: false });
  const [crudState, setCrudState] = React.useState(false);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [reload, setReload] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [access, setAccess] = React.useState({ allowView: false });
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValues, setFilterValues] = React.useState(getDefaultFilter());
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [resetFilter, setResetFilter] = React.useState(false);
  const [tatDate, setTatDate] = React.useState(null)
  const history = useHistory();

  // Declaration of States and Component Variables end Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    if(resetFilter){
    setFilterValues(getDefaultFilter());
    setResetFilter(false);
    }else{
      GetReportData();
    }
  }, [reload, resetFilter]);

  React.useEffect(() => {
    if (filterValues) {
      GetReportData();
    }
  }, [filterValues]);

  React.useEffect(() => {
    GetMenuService(11, function (err, res) {
      if (res) {
        setAccess(res.data);
      } else {
        toasterError(err);
      }
    });
  }, []);

  React.useEffect(() => {
    GetReportData();
  }, [sorting, page, rowsPerPage]);

  React.useEffect(() => {
    getFilterData();
  }, [reload]);

  // Declaration of React Hooks End Here

// This function resets the tree filter
const resetTreeFilter = () => {
  // Loop through each item in companyFilter and set checked to false 
  companyFilter.forEach((item, index) => {
    item.checked = false;
    // If item has children, loop through them and set checked to false
    if (item.children) {
      item.children.forEach((item, index) => {
        item.checked = false;
      });
    }
  });

  // Set TatDate to null
  setTatDate(null)
};

 // This function is used to get filter data from the API
const getFilterData = async () => {
  try {
    // Making a GET request to the API with the base URL and the endpoint
    const response = await axios.get(
      `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`,
      {
        headers: {
          // Setting the authorization header with the token stored in sessionStorage
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      }
    );
    // If the response data length is 0, set the data for filter to an empty array
    if (response.data.length === 0) {
      setDataForFilter([]);
    } else {
      // Else set the data for filter to the response data
      setDataForFilter(response.data);
      // Iterate through the companies array and set checked to false
      response.data.companies.forEach((item, index) => {
        item.checked = false;
        // If there are children, iterate through them and set checked to false
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
        }
      });
      // Set the company filter to the response data's companies array
      setCompanyFilter(response.data.companies);
     
    }
  } catch (error) {
    // Call the error handling function with the error and a callback function
    ErroHandling(error, function (res) {
      // Call the toasterError function with the response
      toasterError(res);
    });
  }
}

  // Assigning Columns and UI Props
  const columns = [
    { id: "jobAssetId", label: "Id", type: "Highlight" },
    { id: "jobNo", label: "Job ID", width: 90, minWidth: 90, sorting: true },
    {
      id: "jobName",
      label: "Job Name",
      minWidth: 300,
      width: 320,
      sorting: true,
    },
    {
      id: "company",
      label: "Company",
      minWidth: 250,
      width: 280,
      sorting: true,
    },
    { id: "asset", label: "Asset", minWidth: 200, width: 250, sorting: true },
    { id: "startDate", label: "Job Start Date", minWidth: 120, width: 120, sorting: true, align: "center" },
    { id: "tat", label: "TAT", minWidth: 120, width: 120, sorting: true, align: "center" },
    { id: "remainingDays", label: "Remaining Days", minWidth: 120, width: 120, sorting: true, align: "center" },
    { id: "status", label: "Status", minWidth: 180, width: 200, sorting: true },
    // {
    //   id: "priority",
    //   type: "Chip",
    //   label: "Job Priority",
    //   minWidth: 100,
    //   width: 120,
    //   sorting: true,
    //   align: "center"
    // },
    {
      id: "meritAnalystName",
      label: "Merit Analyst name",
      minWidth: 150,
      width: 150,
      sorting: true,
      align: "left",
    },
    {
      id: "assetCompletedTime",
      label: "Asset Completion Date",
      minWidth: 110,
      width: 110,
      sorting: true,
      align: "center"
    },
    {
      id: "qcCompletionDate",
      label: "Merit QC Completion Date",
      minWidth: 120,
      width: 120,
      sorting: true,
      align: "center",
    },
    {
      id: "meritQCName",
      label: "Merit QC Name",
      minWidth: 150,
      width: 150,
      sorting: true,
      align: "left",
    },

    // {
    //   id: "commodity",
    //   label: "Commodity",
    //   minWidth: 300,
    //   width: 300,
    //   sorting: true,
    //   truncate: 60,
    //   tooltipWithTruncate: true
    // },
    // {
    //   id: "country",
    //   label: "Country",
    //   minWidth: 150,
    //   width: 180,
    //   sorting: true,
    // },
    // { id: "region", label: "Region", minWidth: 150, width: 180, sorting: true },

  ];

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if (
      (event.keyCode === 8 || event.which === 8) &&
      event.target.nodeName !== "BUTTON"
    ) {
      if (searchValue.length === 0) {
      }
    }
    if (
      (event.keyCode === 13 || event.which === 13) &&
      event.target.nodeName !== "BUTTON"
    ) {
      searchReport(event.target.value);
    }
  };

  // Function for Sorting
  const changeSortColumnNameFormat = (fieldName) => {
    if (fieldName == null || fieldName == "") {
      return "";
    }
    if (fieldName.toLocaleLowerCase() == "jobNo".toLocaleLowerCase()) {
      fieldName = "JobNo";
    }
    if (fieldName.toLocaleLowerCase() == "jobname".toLocaleLowerCase()) {
      fieldName = "JobName";
    }
    if (fieldName.toLocaleLowerCase() == "company".toLocaleLowerCase()) {
      fieldName = "Company";
    }
    if (fieldName.toLocaleLowerCase() == "asset".toLocaleLowerCase()) {
      fieldName = "Asset";
    }
    if (fieldName.toLocaleLowerCase() == "meritQCName".toLocaleLowerCase()) {
      fieldName = "MeritQCName";
    }
    if (fieldName.toLocaleLowerCase() == "tat".toLocaleLowerCase()) {
      fieldName = "TAT";
    }
    if (fieldName.toLocaleLowerCase() == "status".toLocaleLowerCase()) {
      fieldName = "Status";
    }
    // if (fieldName.toLocaleLowerCase() == "priority".toLocaleLowerCase()) {
    //   fieldName = "Priority";
    // }
    if (fieldName.toLocaleLowerCase() == "qcCompletionDate".toLocaleLowerCase()) {
      fieldName = "QCCompletionDate";
    }
    if (fieldName.toLocaleLowerCase() == "meritAnalystName".toLocaleLowerCase()) {
      fieldName = "MeritAnalystName";
    }
    if (fieldName.toLocaleLowerCase() == "remainingDays".toLocaleLowerCase()) {
      fieldName = "RemainingDays";
    }
    if (fieldName.toLocaleLowerCase() == "startDate".toLocaleLowerCase()) {
      fieldName = "StartDate";
    }
    if (fieldName.toLocaleLowerCase() == "assetCompletedTime".toLocaleLowerCase()) {
      fieldName = "AssetCompletedTime";
    }
    
    return fieldName;
  };

  // Function for Pagination after search
  const searchReport = () => {
    setTotalCount(0);
    setPage(0);
    setLoading(true);
    setReload(!reload);
  };

  // Function for Searching the grid
  const globalSearch = (value) => {
    setSearchValue(value);
  };

// GetReportData is a function that fetches data from the API
const GetReportData = () => {
  // Set loading to true while fetching data
  setLoading(true);
  // Set searchText value in filterValues object
  filterValues.searchText = searchValue || "";
  // Create an array of parameters for the API call
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
  // Make an API call with the given parameters
  ApiService.search(
    `reports/GetQCReport?`,
    {
      filter: JSON.stringify(filterValues),
      sortColumn: changeSortColumnNameFormat(sorting.sortBy),
      isAscending: sorting.isAscending,
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : null,
    },
    prt
  )
    .then((res) => {
      // If response status is 200, set allRows and totalCount values
      if (res.status === 200) {
        console.log(res.data.data, "resssssss")
        setAllRows(res.data.data);
        setTotalCount(res.data.totalCount);
        setLoading(false);
      } else {
        // Else show error message
        toasterError(`${res.data}`);
        setLoading(false);
      }
    })
    .catch((err) => {
      // Handle errors
      ErroHandling(err, function (res) {
        toasterError(res);
      });
      setLoading(false);
    });

};
 

  // Function for Search Clear
  const searchClear = () => {
    setSearchValue("");
    setPage(0);
    setReload(!reload);
  };

  // This function toggles the filter open or closed
  const toggleFilter = (open) => (event) => {
    // Set the state of right to open
    setState({ ...state, right: open });
    // Set the crud state to true
    setCrudState(true);
  };

  // Function for Open View and Edit Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "View") {
      history.push({
        pathname: "/app/data-collections/qc-failed-report-drill",
        state: {
          jobAssetId: item.jobAssetId 
        },
        allRow: allRows,
      });
    } else {
      setOpen(true);
      setScroll("paper");
   }
  };

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={12} className="page-title">
          <h2>Merit QC Failed Report</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
           <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
           <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/aging-report" }}>Dashboard and Reports</Link>
            <Typography color="text.primary">Merit QC Failed Report</Typography>
          </Breadcrumbs>
        </Grid>
      
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search and Filter Options Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6} lg={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      size="small"
                      fullWidth
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="serchByClear"
                        onClick={searchClear}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                
                  <Grid item xs={12}  sm={6} lg={6}>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={"auto"}>
                        <Button
                          onClick={toggleFilter(true)}
                          variant="contained"
                          color="secondary"
                          startIcon={<FilterIcon style={{ fontSize: 16 }} />}
                        >
                          Filter
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <LoaderWrapper loading={isLoading}>
                  <DataTable
                    className={classes.removeBorder}
                    rows={allRows ? allRows : []}
                    columns={columns}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    totalCount={totalCount}
                    isAllSelected={isAllSelected}
                    setIsSelected={setIsSelected}
                    selected={selected}
                    setSelected={SetSelected}
                    sorting={sorting}
                    setSorting={setSorting}
                    isActionButton={{
                      ...(access.allowView && {
                        view: toggleDrawer(true, "View"),
                      }),
                    }}
                  />
                </LoaderWrapper>
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
      <SideDrawer
        formComp={
          <QualityFilter
            dataForFilter={dataForFilter}
            filterFinalData={filterFinalData}
            companyFilter={companyFilter}
            setFilterValue={setFilterValues}
            state={state}
            setState={setState}
            resetTreeFilter={resetTreeFilter}
            setJobCompanies={setJobCompanies}
            setCompanyFilter={setCompanyFilter}
            setResetFilter={setResetFilter}
            setDataForFilter={setDataForFilter}
            getFilterData={getFilterData}
            reload={reload}
            setReload={setReload}
            setTatDate={setTatDate}
            tatDate={tatDate}
          />
        }
        state={state}
        setState={setState}
        crudState={crudState}
      />
    </Box>
  );
};

export default DataCollectionQCFailedReport;