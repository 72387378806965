// Default React, Router and Services Components
import React, { useState } from "react";
import { Box, Breadcrumbs, Button, CircularProgress, Grid, Link, Typography, Backdrop, LinearProgress } from "@mui/material";
import XLSX from "sheetjs-style";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
//import history from '../../Routes/History';
import { useHistory } from "react-router-dom";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import useStyles from "./SearchExport.styles";
import BaseLayout from "../../../UIComponent/BaseLayout";
import { LoaderWrapper } from "../../../UIComponent/Progress";
// Import Fluent UI Icons
import { ChevronLeftIcon, ChevronRightSmallIcon, ExportIcon } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../Services/CommonService";
import pLimit from "p-limit";
import axios from "axios";

const DataCollectionViewData = (props) => {


    const [historyData, setHistoryData] = useState();

    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    const [allRows, setAllRows] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = React.useState(0);
    const [open, setOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [isExporting, setExporting] = useState(false);
    const [selected, SetSelected] = useState([]);
    const [idforEdit, setIdForEdit] = useState(null);
    const [editJobNumber, setEditJobNumber] = useState(null);
    const [isAllSelected, setIsSelected] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [state, setState] = React.useState({ right: false });
    const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
    const [crudState, setCrudState] = React.useState(false);
    const [formData, setFormData] = useState([]);
    const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
    const [reload, setReload] = useState(false);
    const [scroll, setScroll] = React.useState("paper");
    const date = new Date().toLocaleDateString();
    const [excelColumns, setExcelColumns] = useState([]);
    const date1 = date.split("/").join("-");
    const [cellArray, setCellArray] = useState([]);
    const [exportToExcel, setExportToExcel] = useState(false);
    const [columns, setColumns] = useState([]);
    const history = useHistory();
    const [backdropOpen, setBackdropOpen] = React.useState(false);

    // This code is using the useEffect hook from React
    React.useEffect(() => {
        // This function is called getDataFromHistory and it is being executed when the component mounts or when the props change
        getDataFromHistory();
    }, [props]);


    /// <summary>
    /// This is a hook in React that allows you to perform side effects in functional components. It is similar to the componentDidMount and componentDidUpdate lifecycle methods in class components. The useEffect hook takes a callback function as its first argument, which will be executed after the component has rendered. The callback function can return a cleanup function, which will be executed before the component is unmounted or before the next render if any of the dependencies specified in the second argument have changed.
    /// </summary>
    React.useEffect(() => {
        // Update the columns state with the gridcolumns value
        setColumns(gridcolumns);
        // Call the GetViewDataList function with the searchValue parameter
        GetViewDataList(searchValue);
    }, [reload, page, rowsPerPage, historyData]);


    // This code is using the useEffect hook from React to perform a side effect
    /// <summary>
    /// This is a hook in React that is used to perform side effects in functional components. It is similar to componentDidMount, componentDidUpdate, and componentWillUnmount lifecycle methods in class components. The useEffect hook takes a callback function as its first argument, which will be executed after the component has rendered. It can also take a second argument, which is an array of dependencies. If any of the dependencies change, the callback function will be re-executed. This hook is commonly used for fetching data, subscribing to events, or performing other side effects in React components.
    /// </summary>
    React.useEffect(() => {
        // Check if the sorting sortBy property is not empty or null
        if (sorting.sortBy != '' && sorting.sortBy != null) {
            // Call the GetViewDataList function with the searchValue parameter
            GetViewDataList(searchValue);
        }
    }, [sorting, historyData]);


    /// <summary>
    /// This function retrieves data from a history source.
    /// </summary>
    const getDataFromHistory = () => {
        // Get stored data from props using CommonService
        let temp = CommonService.GetStoredData(props, "historyData");
        // If data is available
        if (temp) {
            // Set historyData state with the retrieved data
            setHistoryData(temp);
        }
    }

    //columns to be displayed in grid
    const gridcolumns = [

        // { id: "jobAssetId", label: "Id", type: "Highlight" },

        { id: "LastUpdated", label: "Last Updated", minWidth: 150, width: 200, sorting: true },

        { id: "Frequency", label: "Frequency", minWidth: 110, width: 150, sorting: true },

        { id: "Company", label: "Company", minWidth: 300, width: 300, sorting: true },
        { id: "Asset", label: "Asset", minWidth: 300, width: 300, sorting: true },
        { id: "Commodity", label: "Commodity", minWidth: 350, width: 350, sorting: true, truncate: 90, tooltipWithTruncate: true },
        { id: "Country", label: "Country", minWidth: 150, sorting: true },
        { id: "Region", label: "Region", minWidth: 150, width: 200, sorting: true },
        { id: "SubRegion", label: "Subregion", minWidth: 150, width: 200, sorting: true },

        { id: "VariableReport", label: "Variable (Report)", minWidth: 250, width: 300, sorting: true },
        { id: "VariableType", label: "Variable Type", minWidth: 250, width: 300, sorting: true },
        { id: "UnitReport", label: "Unit (Report)", minWidth: 135, width: 150, sorting: true },

        { id: "VariableCRU", label: "Variable (CRU)", minWidth: 160, width: 200, sorting: true },

        { id: "UnitCRU", label: "Unit (CRU)", minWidth: 110, width: 150, sorting: true },

        { id: "Currency", label: "Currency", minWidth: 100, width: 150, sorting: true },



    ];


    /// <summary>
    /// Function that handles the key up event.
    /// </summary>
    /// <param name="event">The key up event object.</param>
    const handleKeyUp = (event) => {
        // Check if the key code is 8 (backspace) or the key code is 8 (backspace) and the target element is not a button
        if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
            // Check if the searchValue length is 0
            if (searchValue.length === 0) {
                // Do nothing
            }
        }
        // Check if the key code is 13 (enter) or the key code is 13 (enter) and the target element is not a button
        if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
            // Call the searchReport function with the value of the target element
            searchReport(event.target.value);
        }
    };




    /// <summary>
    /// This function takes a filter value as input and performs a search report based on that filter value.
    /// </summary>
    /// <param name="filterValue">The value to filter the search report.</param>
    const searchReport = (filterValue) => {
        // Reset the total count of reports
        setTotalCount(0);
        // Reset the current page to the first page
        setPage(0);
        // Set the loading state to true to indicate that the search is in progress
        setLoading(true);
        // Trigger a reload of the report data by changing the reload state
        setReload(!reload);
    };

    // Function for Getting Search And Export Data
    // This function updates the search value in the global state
    const globalSearch = (value) => {
        setSearchValue(value);
    };


    const GetViewDataList = (filterValue, exportExcel = false) => {
        if (historyData) {
            // if (exportExcel) {
            //    // ExcelExport(filterValue);

            // }

            let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`]; //rowsPerPage
            setLoading(true);
            ApiService.search(`SearchAndExport/GetSearchAndExportViewData?`,
                {
                    jobAssetIds: historyData.state,
                    sortColumn: sorting.sortBy,
                    isAscending: sorting.isAscending
                }, prt
            ).then((res) => {
                if (res.status === 200) {
                    if (res.data != null) {

                        let apiResponse = JSON.parse(res?.data[0].result);
                        let headerName = [];
                        if (apiResponse.data !== null && apiResponse.data !== undefined && apiResponse.data.length > 0) {

                            let keys = Object.keys(apiResponse.data[0]);
                            if (keys !== null && keys !== undefined) {
                                keys.forEach(key => {
                                    if ((key.indexOf("LastUpdated") !== -1) || (key.indexOf("Frequency") !== -1) || (key.indexOf("Link1") !== -1) || (key.indexOf("Commodity") !== -1) || (key.indexOf("Asset") !== -1) || (key.indexOf("Country") !== -1) || (key.indexOf("Region") !== -1) || (key.indexOf("SubRegion") !== -1) || (key.indexOf("Company") !== -1) || (key.indexOf("VariableReport") !== -1) || (key.indexOf("VariableType") !== -1) || (key.indexOf("UnitReport") !== -1) || (key.indexOf("VariableCRU") !== -1) || (key.indexOf("UnitCRU") !== -1) || (key.indexOf("Currency") !== -1) || (key.indexOf("_ValuationType") !== -1)) {
                                    }
                                    else if (key.indexOf("_Comment") !== -1) {
                                        gridcolumns.push({ 'id': `${key}`, 'label': `${key}`, 'minWidth': 250, 'sorting': false, align: "left", colorCode: 'blue', attatch: true })
                                    } else if (key.indexOf("_ReportName") !== -1) {
                                        gridcolumns.push({ 'id': `${key}`, 'label': `${key}`, 'minWidth': 500, 'sorting': false, align: "left", colorCode: 'blue', attatch: true })
                                    }
                                    else {
                                        gridcolumns.push({ 'id': `${key}`, 'label': `${key}`, 'minWidth': 210, 'sorting': false, align: "left", colorCode: 'blue', attatch: true });
                                    }


                                })
                            }

                            setColumns(gridcolumns);
                            setAllRows(apiResponse.data);
                            // setTotalCount(res.data.totalCount);
                            setTotalCount(res?.data[0].totalCount);
                        }
                        setLoading(false);
                    } else {
                        setColumns(gridcolumns);
                        setAllRows([]);
                        setLoading(false);
                    }
                }


            }).catch((err) => {
                ErroHandling(err, function (res) {
                    toasterError(res)
                });
                setLoading(false);
            });
        }

    };


    /// <summary>
    /// Clears the search input field.
    /// </summary>
    const searchClear = () => {
        setSearchValue(""); // Set the search value to an empty string
        setPage(0); // Set the page number to 0
        setReload(!reload); // Toggle the reload state to trigger a reload
    };


    /// <summary>
    /// Function to handle the export action.
    /// </summary>
    const handleExport = () => {
        if (historyData.state) {
            setBackdropOpen(true); // Open backdrop
            let promises = []; // Array to store promises
            let limit = pLimit(2); // Set default limit to 2
            if (process.env.React_App_P_limit) {
                limit = pLimit(Number(process.env.React_App_P_limit)); // Set limit to the value specified in the environment variable
            }
            const wb = XLSX.utils.book_new(); // Create a new workbook
            let fileName = `Search and Export ${date1} `; // Set the filename
            if (historyData.state.length === 0) {
                toasterError('Please select any Job'); // Show error message if no job is selected
                return;
            }
            let jobassets = [];
            jobassets = historyData.state; // Set jobassets to the selected jobs
            let ptr = []
            let lotSize = 100; // Set default lot size to 100
            lotSize = Number(process.env.React_App_SearchAndExport_Max_Count); // Set lot size to the value specified in the environment variable
            if (jobassets.length > lotSize) {
                let sheetindex = 1; // Initialize sheet index
                let index = 0; // Initialize index
                let lastIndex = 0; // Initialize last index
                while (index <= jobassets.length) {
                    if (index + lotSize <= jobassets.length) {
                        lastIndex = index + lotSize; // Set last index to index + lot size if it is within the range of jobassets length
                    } else {
                        lastIndex = jobassets.length; // Set last index to jobassets length if it exceeds the range
                    }
                    let jobAssetId = jobassets.slice(index, lastIndex); // Get a slice of jobassets based on the current index and last index
                    promises.push(
                        limit(() =>
                            ExportExcel(jobAssetId).then((result) => {
                                if (result !== undefined && result !== null) {
                                    XLSX.utils.book_append_sheet(wb, result, `data${sheetindex++} `); // Append the result to the workbook with a sheet name of "data" followed by the sheet index
                                    //console.log(result);
                                }
                            })
                        )
                    );
                    Promise.all(promises)
                        .then(() => {
                            if (limit.pendingCount === 0 && limit.activeCount === 0) {
                                XLSX.writeFile(wb, `${fileName}.xlsx`); // Write the workbook to a file with the specified filename
                                setBackdropOpen(false); // Close backdrop
                            }
                        })
                        .catch((err) => {
                            Promise.reject(err);
                            setBackdropOpen(false); // Close backdrop
                        });

                    index = index + lotSize; // Increment index by lot size
                }
            } else {
                promises.push(
                    ExportExcel(jobassets).then((result) => {
                        if (result !== undefined && result !== null) {
                            XLSX.utils.book_append_sheet(wb, result, `data`); // Append the result to the workbook with a sheet name of "data"
                            //console.log(result);
                        }
                    })
                );
                Promise.all(promises)
                    .then(() => {
                        if (wb) {
                            if (wb.SheetNames.length !== 0) {
                                XLSX.writeFile(wb, `${fileName}.xlsx`); // Write the workbook to a file with the specified filename
                                setBackdropOpen(false); // Close backdrop
                            }
                        }
                    })
                    .catch((err) => Promise.reject(err), setBackdropOpen(false)); // Close backdrop
            }
        }
    }


    // Function for Export
    // const handleExportToExcel = () => { GetViewDataList(searchValue, true); }
    /// <summary>
    /// This function is responsible for handling the export to Excel functionality.
    /// It calls the ExportExcel function with the state of the history data.
    /// </summary>
    /// <returns>
    /// No return value.
    /// </returns>
    const handleExportToExcel = () => {
        //    handleExport(); 
        ExportExcel(historyData.state);
    }
    /// <summary>
    /// Exports the given API data to a CSV file with the specified file name. 
    /// The data can be exported with or without comments, and can be formatted with an Excel color code.
    /// </summary>
    /// <param name="apiData">The API data to be exported</param>
    /// <param name="fileName">The name of the CSV file</param>
    /// <param name="datawoCommments">Flag indicating whether to export the data without comments</param>
    /// <param name="excelColorCode">The Excel color code to format the exported data</param>
    const exportToCSV = (apiData, fileName, datawoCommments, excelColorCode) => {
        //console.log("----->", apiData);
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        let onlyComments = [];
        datawoCommments.forEach(obj => {
            Object.keys(obj).forEach(key => {
                if (key.endsWith("_Comment")) {
                    onlyComments.push(obj[key]);
                }
                if (key.endsWith("_Comment") || key.endsWith("VariableId") || key.endsWith("_ValuationType") || key.endsWith("ValuationTypeId") || key.endsWith("JobAssetId") || key.endsWith("JobId")) {
                    delete obj[key];
                }
            })
        })
        //console.log("onlycomments---->", onlyComments);
        const ws = XLSX.utils.json_to_sheet(datawoCommments);
        let wsData = createAlphybet(datawoCommments, ws, onlyComments, excelColorCode)
        //console.log("full", wsData);
        // // const ws = XLSX.utils.json_to_sheet(apiData);
        excelColumns.forEach((exCol, i) => {
            if (exCol.includes('_Comment')) {
                excelColumns.splice(i, 1)
            }
        });
        //console.log(wsData);
        XLSX.utils.sheet_add_aoa(wsData, [excelColumns], { origin: "A1", });
        // console.log(wsData);
        return wsData;
        // const wb = { Sheets: { data: wsData }, SheetNames: ["data"] };
        // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        // const data = new Blob([excelBuffer], { type: fileType });
        // FileSaver.saveAs(data, fileName + fileExtension);
    };


    // Function to export data to Excel
    /// <summary>
    /// Exports the specified job asset IDs to an Excel file.
    /// </summary>
    /// <param name="jobAssetIds">The IDs of the job assets to export.</param>
    /// <returns>void</returns>
    const ExportExcel = (jobAssetIds) => {
        // Set exporting flag to true
        setExporting(true);

        // Make API call to get data for Excel export
        axios.get(process.env.React_App_baseUrl + "SearchAndExport/GetSearchAndExportForExcel", {
            headers: {
                jobAssetIds: jobAssetIds,
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
            },
            responseType: "blob",
        })
            .then((result) => {
                // Check if the response is a text/plain type, indicating that the export result will be sent via email
                if (result.headers["content-type"] === "text/plain; charset=utf-8") {
                    toasterSuccess("Search and export result will be sent via email");
                } else {
                    // Create a download link for the Excel file
                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    const link = document.createElement('a');
                    link.style = "display: none";
                    link.href = url;

                    // Get the file name from the response headers
                    let fileName = `SearchAndExport.xlsx`;
                    let disposition = result.headers['content-disposition'];
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            fileName = matches[1].replace(/['"]/g, '');
                        }
                    }

                    // Set the download attribute and append the link to the document body
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                }
                // Set exporting flag to false
                setExporting(false);
            })
            .catch((err) => {
                // Handle error and display error message
                ErroHandling(err, function (res) {
                    toasterError(res)
                })

                // Set exporting flag to false
                setExporting(false);
            })
            .finally(() => {
                // Set exporting flag to false
                setExporting(false);
            });
    }


    // Function to convert a number to its corresponding alphabet location
    /// <summary>
    /// Returns the location of a letter in the alphabet based on its position in the English alphabet.
    /// </summary>
    /// <param name="n">The position of the letter in the English alphabet.</param>
    /// <returns>The location of the letter in the alphabet.</returns>
    const alphabetLocation = (n) => {
        // Get the ASCII code of 'A' and 'Z'
        var ordA = 'A'.charCodeAt(0);
        var ordZ = 'Z'.charCodeAt(0);
        // Calculate the number of alphabets
        var len = ordZ - ordA + 1;

        // Initialize an empty string to store the result
        var s = "";
        // Loop until the number is greater than or equal to 0
        while (n >= 0) {
            // Get the remainder of n divided by len and convert it to corresponding alphabet
            s = String.fromCharCode(n % len + ordA) + s;
            // Update n by dividing it by len and subtracting 1
            n = Math.floor(n / len) - 1;
        }
        // Return the result
        return s;
    }


    // Function to create alphabet characters for column names
    /// <summary>
    /// Creates an alphabet based on the given parameters.
    /// </summary>
    /// <param name="v">The number of vowels in the alphabet.</param>
    /// <param name="ws">The number of consonants in the alphabet.</param>
    /// <param name="allComments">A flag indicating whether to include comments in the alphabet.</param>
    /// <param name="excelColorCode">The color code to use for formatting in Excel.</param>
    /// <returns>The generated alphabet.</returns>
    const createAlphybet = (v, ws, allComments, excelColorCode) => {

        var ordA = 'A'.charCodeAt(0);
        var ordZ = 'Z'.charCodeAt(0);
        var len = ordZ - ordA + 1;
        var s = "";
        let commentData = {}
        let commentCount = 0;
        let n = Object.keys(v[0]).length

        // Loop through each row in the data
        for (let index = 0; index < v.length; index++) {
            (Object.values(v[index]).map((value, ind) => {
                let n = ind

                // Convert the column index to alphabet characters
                while (n >= 0) {
                    s = String.fromCharCode(n % len + ordA);
                    n = Math.floor(n / len) - 1;
                }

                let commentIndex = (Object.keys(v[index]))[ind];

                // Check if the value is not empty and the column index is greater than 13
                if (ind > 13 && value && value != '' && !commentIndex.includes('_ReportName')) {
                    // Store the comment data with the corresponding cell location
                    commentData[alphabetLocation(ind) + (index + 2)] = allComments[commentCount];

                    // Set the font color of the cell
                    ws[`${alphabetLocation(ind)}${index + 2} `].s = {
                        font: {
                            color: { rgb: excelColorCode[commentCount] }
                        },
                    }

                    commentCount = commentCount + 1
                }
            }))
        }

        // Loop through each cell in the worksheet
        Object.keys(ws).forEach(ww => {
            Object.keys(commentData).forEach((cmData) => {
                // Check if the cell location matches with the comment data
                if (ww === cmData && commentData[cmData] && commentData[cmData].indexOf("-") !== 0) {
                    ws[ww].c = [];
                    ws[ww].c.hidden = true;

                    // Check if the cell value is a string and can be converted to a number
                    if (ws) {
                        if (ws[ww]) {
                            if (ws[ww].t === "s" && ws[ww].v !== "-" && !isNaN(ws[ww].v)) {
                                ws[ww].t = "n"
                            }
                        }
                    }

                    // Add the comment to the cell
                    ws[ww].c.push({ a: ws[ww].v, t: commentData[cmData], T: true });
                } else if (ws[ww] !== undefined && ws[ww] !== null) {
                    // Check if the cell value is a hyperlink
                    if (ws[ww].v !== undefined && ws[ww].v !== null && ws[ww].v.indexOf("http://") !== -1) {
                        if (ws[ww].v.split("http://").length <= 2) {
                            let link = ws[ww].v
                            ws[ww].l = { Target: link };
                        }
                    }
                }
            })
        });

        return ws;
    }

    return (


        // Page Layout Start Here
        <Box>
            <Grid container maxWidth justifyContent="right" alignItems="center">

                {/* Breadcrumbs Menu Start Here */}
                <Grid item xs={12} sm={12} md={6} className="page-title">
                    <h2>View Data</h2>
                    <Breadcrumbs
                        separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
                        <Typography color="text.primary">Manage Company Data Collections</Typography>
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/search-export" }}>Search & Export</Link>
                        <Typography color="text.primary">View Data</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* Breadcrumbs Menu End Here */}

                {/* Main Buttons Start Here */}
                <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                        <Grid item xs={"auto"}>
                            <Button variant="contained" color="accent2" startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
                                onClick={() => { history.push("/app/data-collections/search-export"); }}
                            >
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Main Buttons End Here */}

                {/* Breadcrumbs Menu End Here */}

                <Grid item xs={12} sm={12} md={4}>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={"auto"}>
                            <Box>
                                {/* <Button
                  onClick={handleOpen}
                  variant="contained"
                  color="secondary"
                >
                  Export backdrop
                </Button> */}
                                <Backdrop
                                    sx={{
                                        color: "#000000",
                                        zIndex: (theme) => theme.zIndex.drawer + 1,
                                    }}
                                    open={backdropOpen}
                                    // onClick={handleClose}
                                    style={{
                                        backdropFilter: "blur(2px)",
                                        background: "rgba(0, 0, 0, 0.75)",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            background: "#ffffff",
                                            borderRadius: 2,
                                            p: 4,
                                            pt: 3.5,
                                        }}
                                    >
                                        <Box sx={{ width: "100%" }}>
                                            <Typography variant="h6" gutterBottom>
                                                Exporting...
                                            </Typography>
                                        </Box>
                                        <Box sx={{ width: "100%" }}>
                                            <LinearProgress color="accent6" />
                                        </Box>
                                        <Box sx={{ pt: 2 }}>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                sx={{ maxWidth: 340 }}
                                            >
                                                Exporting may take a while. If you want to continue your
                                                task, please open a new tab and proceed.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Backdrop>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Baselayout Start Here */}
                <Grid item xs={12}>
                    <BaseLayout>
                        <Box className={classes.addBorder}>
                            {/* Search Option and Total Count Start Here */}
                            <Grid item xs={12} className={classes.tableTopPart}>
                                <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                                    <Grid item xs={"auto"}>
                                        <Button variant="contained" color="secondary" style={{ width: 190 }}
                                            startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                                            onClick={handleExportToExcel}>
                                            Export To Excel
                                        </Button>

                                        {/* 
{isExporting ?
                                            <Button variant="contained" color="secondary" disabled style={{ width: 190 }}>
                                                <CircularProgress size={25} />
                                            </Button> :
                                            <Button variant="contained" color="secondary" style={{ width: 190 }}
                                                startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                                                onClick={handleExportToExcel}>
                                                Export To Excel
                                            </Button>
                                        } */}

                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Search Option and Total Count End Here */}

                            {/* View Data Table */}
                            <Grid item xs={12}>
                                <LoaderWrapper loading={isLoading}>
                                    <DataTable
                                        className={classes.removeBorder}
                                        rows={allRows ? allRows : []}
                                        columns={columns}
                                        page={page}
                                        setPage={setPage}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        totalCount={totalCount}
                                        isAllSelected={isAllSelected}
                                        setIsSelected={setIsSelected}
                                        selected={selected}
                                        setSelected={SetSelected}
                                        sorting={sorting}
                                        setSorting={setSorting}
                                        hasChip={{
                                            High: { color: "warning", label: "High" },
                                            Critical: { color: "error", label: "Critical" },
                                            Low: { color: "success", label: "Low" },
                                            Medium: { color: "info", label: "Medium" },
                                        }}
                                        cellArray={cellArray}
                                        searchAndControl
                                    />
                                </LoaderWrapper>
                            </Grid>
                        </Box>
                    </BaseLayout>
                </Grid>
            </Grid>
        </Box >
    );
};

export default DataCollectionViewData;