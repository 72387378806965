// Default React, Router and Services Components
import React, { useState } from "react";
import { Grid, Button, Box, Typography } from "@mui/material";
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import DatePicker from "../../../UIComponent/DatePicker";
import { toasterError } from "../../../UIComponent/Toaster";
import MultiSelect from "../../../UIComponent/MultiSelectDropdown";

// Importing useStyles
import useStyles from "./jobManagement.styles";

const FormJson = (label, value) => {
  return { label, value }
}

// Function for Filter Assign Jobs
const FilterAssignJobs = (props) => {
  const classes = useStyles();
  const [dropDown, setDropDown] = useState(
    {
      JobNo: [], JobName: [], JobType: [], AssignedById: [], AssignedToId: [],
      CreatedOn: "", Tat: "", PriorityIds: [], CompletedDate: ""
    })

  const [values, setValues] = useState({
    JobNo: [], JobName: [], JobType: [], AssignedById: [], AssignedToId: [],
    CreatedOn: "", Tat: "", PriorityIds: [], CompletedDate: ""
  })

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    setValues(prev => {
      return {
        ...prev,
        JobNo: props.values?.JobNo || [],
        JobName: props.values?.JobName || [],
        JobType: props.values?.JobType || [],
        AssignedById: props.values?.AssignedById || [],
        AssignedToId: props.values?.AssignedToId || [],
        CreatedOn: props.values?.CreatedOn || "",
        CompletedDate: props.values?.CompletedDate || "",
        Tat: props.values?.Tat || "",
        PriorityIds: props.values?.PriorityIds || [],
      }
    })
    axios.get(process.env.React_App_baseUrl + "job/GetJobNoDropdown", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        var item = response.data.map((el) => FormJson(el, el))
        setDropDown(prev => { return { ...prev, JobNo: item || [] } });
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
    axios.get(process.env.React_App_baseUrl + "job/GetJobNameDropdown", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        var item = response.data.map((el) => FormJson(el, el))
        setDropDown(prev => { return { ...prev, JobName: item || [] } });
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
    axios.get(process.env.React_App_baseUrl + "job/GetJobTypeDropDown", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        var item = response.data.map((el) => FormJson(el.name, el.id))
        setDropDown(prev => { return { ...prev, JobType: item || [] } });
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
    axios.get(process.env.React_App_baseUrl + "DropDown/GetAssignedByFilterAsync", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        var item = response.data.map((el) => FormJson(el.name, el.userId))
        setDropDown(prev => { return { ...prev, AssignedById: item || [] } });
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
    axios.get(process.env.React_App_baseUrl + "DropDown/GetAssignedToFilterAsync", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        var item = response.data.map((el) => FormJson(el.name, el.userId))
        setDropDown(prev => { return { ...prev, AssignedToId: item || [] } });
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
    axios.get(process.env.React_App_baseUrl + "DropDown/GetPriorityDropDownFilter", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        var item = response.data.map((el) => FormJson(el.priorityName, el.id))
        setDropDown(prev => { return { ...prev, PriorityIds: item || [] } });
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
  }, [])
  // Declaration of React Hooks End Here

  // Function for Filter
  const filter = () => {
    props.setValues(values)
    props.setFilterValue({
      JobNo: values.JobNo.map((el) => el.value), JobName: values.JobName.map((el) => el.value),
      AssignedById: values.AssignedById.map((el) => el.value), AssignedToId: values.AssignedToId.map((el) => el.value),
      CreatedOn: values.CreatedOn, Tat: values.Tat, CompletedDate: values.CompletedDate,
      PriorityIds: values.PriorityIds.map((el) => el.value)
    })
    props.filterSubmit()
    props.setState({ right: false })
  }

  // Function for Reset Value
  const reset = () => {
    setValues({
      JobNo: [], JobName: [], JobType: [], AssignedById: [], AssignedToId: [],
      CreatedOn: "", Tat: "", PriorityIds: [], CompletedDate: ""
    });

    props.setValues({
      JobNo: [], JobName: [], JobType: [], AssignedById: [], AssignedToId: [],
      CreatedOn: "", Tat: "", PriorityIds: [],
    });
    props.setFilterValue({
      JobNo: [], JobName: [], JobType: [], AssignedById: [], AssignedToId: [],
      CreatedOn: "", Tat: "", PriorityIds: [], CompletedDate: ""
    })
    props.filterSubmit()
  }
  const onChangeHandlerJobName = (item) => {
    setValues({ ...values, JobName: item });
  }
  const onChangeHandlerJobNo = (item) => {
    setValues({ ...values, JobNo: item });
  }
  const onChangeHandlerAssignedById = (item) => {
    setValues({ ...values, AssignedById: item });
  }
  const onChangeHandlerAssignedToId = (item) => {
    setValues({ ...values, AssignedToId: item });
  }
  const onChangeHandlerPriorityIds = (item) => {
    setValues({ ...values, PriorityIds: item });
  }

  return (
    // Layout for Filter
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Filter Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MultiSelect
            items={dropDown.JobNo}
            selectAllLabel={{ label: "Select all" }}
            values={values.JobNo}
            onChange={(key) => onChangeHandlerJobNo(key)}
            getOptionLabel={'label'}
            dropLable={'label'}
            role="JobId"
            label="Job ID"
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            items={dropDown.JobName}
            values={values.JobName}
            onChange={(key) => onChangeHandlerJobName(key)}
            selectAllLabel={{ label: "Select all" }}
            getOptionLabel={'label'}
            dropLable={'label'}
            role="JobName"
            label="Job Name"
          />
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            labelTitle="Created Date"
            variant="standard"
            defaultDate={values.CreatedOn}
            onChangeDate={(e) => {
              let selectedDate
              if (e) {
                selectedDate = new Date(e);
                selectedDate = selectedDate.getFullYear() + "-" + (selectedDate.getMonth() + 1) + "-" + selectedDate.getDate()
              } else {
                selectedDate = ''
              }
              setValues({ ...values, CreatedOn: selectedDate });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            items={dropDown.AssignedById}
            values={values.AssignedById}
            onChange={(key) => onChangeHandlerAssignedById(key)}
            selectAllLabel={{ label: "Select all" }}
            getOptionLabel={'label'}
            dropLable={'label'}
            role="AssignedById"
            label="Assigned By"
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            items={dropDown.AssignedToId}
            values={values.AssignedToId}
            selectAllLabel={{ label: "Select all" }}
            onChange={(key) => onChangeHandlerAssignedToId(key)}
            getOptionLabel={'label'}
            dropLable={'label'}
            role="AssignedToId"
            label="Assigned to"
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            labelTitle="TAT"
            variant="standard"
            defaultDate={values.Tat}
            onChangeDate={(e) => {
              let selectedDate
              if (e) {
                selectedDate = new Date(e);
                selectedDate = selectedDate.getFullYear() + "-" + (selectedDate.getMonth() + 1) + "-" + selectedDate.getDate()
              } else {
                selectedDate = ''
              }
              setValues({ ...values, Tat: selectedDate });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            labelTitle="Completed Date"
            variant="standard"
            defaultDate={values.CompletedDate}
            onChangeDate={(e) => {
              let selectedDate
              if (e) {
                selectedDate = new Date(e);
                selectedDate = selectedDate.getFullYear() + "-" + (selectedDate.getMonth() + 1) + "-" + selectedDate.getDate()
              } else {
                selectedDate = ''
              }
              setValues({ ...values, CompletedDate: selectedDate });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            items={dropDown.PriorityIds}
            values={values.PriorityIds}
            selectAllLabel={{ label: "Select all" }}
            onChange={(key) => onChangeHandlerPriorityIds(key)}
            getOptionLabel={'label'}
            dropLable={'label'}
            role="PriorityIds"
            label="Priority"
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn" color="accent3" onClick={filter}>
                Filter
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn reset-color" color="accent3" onClick={reset}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterAssignJobs;