import React from "react";
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import {
  DeleteIcon,
  EditIcon,
  FileOffIcon,
  ViewIcon,
  CommentIcon,
  AttachIcon,
  AddIcon,
  LinkIcon,
} from "@fluentui/react-icons-mdl2";
import { htmlToText } from "html-to-text";

import { LegendBadge } from "./../Badge";
import { ProgressBar, LoaderWrapper } from "./../Progress";
import TooltipIcon from "../Tooltip";
import EmptyState from "../EmptyState";
import Tooltip from "@mui/material/Tooltip";

import { truncateString } from "./../shared/utility";
import { CHART_COLORS } from "./../shared/constants";

import useStyles from "../DataTable/dataTable.styles";

const CustomDataTable = (props) => {
  //console.log("props", props);
  const classes = useStyles();
  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(+event.target.value);
    props.setPage(0);
  };

  const handleRequestSort = (event, property) => {
    props.setSorting({
      sortBy: property,
      isAscending: !props.sorting.isAscending,
    });
  };

  const handleChangeNew = (event) => {
    const allCheckboxValue =
      props.selected.length === props.rows.length
        ? []
        : props.rows.map((option) => option.id);
    props.setSelected(allCheckboxValue);
    props.checkboxSelection.checkbox(allCheckboxValue);
    `props`.setIsSelected(!props.isAllSelected);
  };

  const handleChangeItem = (event, option) => {
    let selectedOld = [...props.selected];
    if (selectedOld.length === props.rows.length) {
      props.setIsSelected(false);
    }
    if (selectedOld.length === 0 && props.rows.length > 1) {
      selectedOld.push(option);
    } else if (selectedOld.includes(option)) {
      selectedOld.splice(selectedOld.indexOf(option), 1);
    } else {
      selectedOld.push(option);
      if (selectedOld.length === props.rows.length) {
        props.setIsSelected(true);
      }
    }
    props.setSelected(selectedOld);
    props.checkboxSelection.checkbox(selectedOld);
  };

  const isFlagEnabled = (items, isColumn, hasRowSpan) => {
    if (props.hasHighlight) {
      if (isColumn) {
        return (
          <TableCell
            key={"statusId"}
            className={classes.colHighlight}
            {...(hasRowSpan ? { rowSpan: 2 } : {})}
          >
            <div className={classes.visuallyHidden}>{"Highlight"}</div>
          </TableCell>
        );
      } else {
        return (
          <TableCell key={"statusId"} className={classes.colHighlight}>
            <LegendBadge color={props.hasHighlight[items.status].color} />
            <div className={classes.visuallyHidden}>
              {props.hasHighlight[items.status].primary}
            </div>
          </TableCell>
        );
      }
    }
  };

  const handleCheckBoxHeader = (event, option) => {
    const allCheckboxValue =
      props.selected.length === props.rows.length
        ? []
        : props.rows.map((option) => option.jobAssetId);
    props.setSelected(allCheckboxValue);
    props.setIsSelected(!props.isAllSelected);
    props.setviewDataButtonDisable(allCheckboxValue.length <= 0 ? true : false);
  };
  const handleCheckBox = (event, option) => {
    let selectedOld = [...props.selected];
    if (selectedOld.length === props.rows.length) {
      props.setIsSelected(false);
    }
    if (selectedOld.length === 0 && props.rows.length > 1) {
      selectedOld.push(option);
    } else if (selectedOld.includes(option)) {
      selectedOld.splice(selectedOld.indexOf(option), 1);
    } else {
      selectedOld.push(option);
      if (selectedOld.length === props.rows.length) {
        props.setIsSelected(true);
      }
    }

    props.setviewDataButtonDisable(selectedOld.length <= 0 ? true : false);
    props.setSelected(selectedOld);
  };

  const isSelectableCheckbox = (items, isColumn, hasRowSpan) => {
    if (props.selectableRows) {
      if (isColumn) {
        return (
          <TableCell
            className={classes.tableHeader}
            key={"checkboxId"}
            padding="checkbox"
            {...(hasRowSpan ? { rowSpan: 2 } : {})}
          >
            <Checkbox
              checked={props.isAllSelected}
              onChange={handleCheckBoxHeader}
              inputProps={{ "aria-label": "Select All" }}
            />
          </TableCell>
        );
      } else {
        return (
          <TableCell
            key={"checkboxId"}
            padding="checkbox"
            className={classes.tableHeader}
          >
            <Checkbox
              checked={props.selected.indexOf(items.jobAssetId) > -1}
              onChange={(event) => handleCheckBox(event, items.jobAssetId)}
              inputProps={{ "aria-label": "Items" }}
            />
          </TableCell>
        );
      }
    }
  };
  const isCheckboxEnabled = (items, isColumn, hasRowSpan) => {
    if (props.checkboxSelection) {
      if (isColumn) {
        return (
          <TableCell
            className={classes.tableHeader}
            key={"checkboxId"}
            padding="checkbox"
            {...(hasRowSpan ? { rowSpan: 2 } : {})}
          >
            <Checkbox
              checked={props.isAllSelected}
              onChange={handleChangeNew}
              inputProps={{ "aria-label": "Select All" }}
            />
          </TableCell>
        );
      } else {
        return (
          <TableCell
            key={"checkboxId"}
            padding="checkbox"
            className={classes.tableHeader}
          >
            <Checkbox
              checked={props.selected.indexOf(items.id) > -1}
              onChange={(event) => handleChangeItem(event, items.id)}
              inputProps={{ "aria-label": "Items" }}
            />
          </TableCell>
        );
      }
    }
  };
  const isActionButtonAvailable = (items, isColumn, index, hasRowSpan) => {
    if (props.isActionButton) {
      if (isColumn) {
        return (
          <TableCell
            style={{ width: "140px", minWidth: "75px", maxWidth: "140px" }}
            key={"actionId"}
            className={clsx(classes.tableHeader, classes.cellNoWrap)}
            align="center"
            {...(hasRowSpan ? { rowSpan: 2 } : {})}
          >
            {"Actions"}
          </TableCell>
        );
      } else {
        return (
          <TableCell
            key={items.id}
            align="center"
            className={clsx(classes.tableActionCell, classes.cellNoWrap)}
          >
            {props.isActionButton.view ? (
              <TooltipIcon
                title="View"
                placement="top"
                color="primary"
                onClick={(e) => props.isActionButton.view(e, items, index)}
              >
                <ViewIcon style={{ fontSize: "20px", color: "#DB9400" }} />
              </TooltipIcon>
            ) : null}
            {props.isActionButton.edit &&
            (props.isEditActionVisibile
              ? props.isEditActionVisibile(items)
              : true) ? (
              <TooltipIcon
                title="Edit"
                placement="top"
                onClick={(e) =>
                  props.isActionButton.edit(e, items, index, props.page)
                }
              >
                <EditIcon style={{ fontSize: "20px", color: "#333842" }} />
              </TooltipIcon>
            ) : null}
            {props.isActionButton.delete ? (
              <TooltipIcon
                title="Delete"
                placement="top"
                color="error"
                onClick={(e) => props.isActionButton.delete(e, items, index)}
              >
                <DeleteIcon style={{ fontSize: "20px", color: "red" }} />
              </TooltipIcon>
            ) : null}
          </TableCell>
        );
      }
    }
  };

  const generateColumn = (column, hasRowSpan) => {
    if (column.type === "Highlight") return;
    if (column.sorting) {
      return (
        <TableCell
          key={column.id}
          // className={clsx(classes.tableHeader, classes.cellNoWrap)}
          className={
            column.border == true
              ? clsx(classes.tableHeader, classes.cruTheadRightSep)
              : clsx(classes.tableHeader)
          }
          align={column.align}
          style={{ minWidth: column.minWidth, width: column.width }}
          sortDirection={
            column.sorting && props.sorting.sortBy === column.id
              ? props.sorting.isAscending
              : false
          }
          {...(hasRowSpan ? { rowSpan: 2 } : {})}
        >
          <TableSortLabel
            active={column.sorting && props.sorting.sortBy === column.id}
            direction={
              column.sorting && props.sorting.sortBy === column.id
                ? props.sorting.isAscending
                  ? "asc"
                  : "desc"
                : "asc"
            }
            onClick={(e) => {
              handleRequestSort(e, column.id);
            }}
          >
            {column.label}
            {column.sorting && props.sorting.sortBy === column.id ? (
              <span className={classes.visuallyHidden}>
                {props.sorting.isAscending ? "asc" : "desc"}
              </span>
            ) : null}
          </TableSortLabel>
          {column.helperText && column.helperText.length > 0 ? (
            <span className={classes.helperText}>{column.helperText}</span>
          ) : (
            ""
          )}
        </TableCell>
      );
    } else {
      return (
        <TableCell
          key={column.id}
          className={
            column.border == true
              ? clsx(classes.tableHeader, classes.cruTheadRightSep)
              : clsx(classes.tableHeader)
          }
          align={column.align}
          style={{ minWidth: column.minWidth, width: column.width }}
          sortDirection={
            column.sorting && props.sorting.sortBy === column.id
              ? props.sorting.isAscending
              : false
          }
          {...(hasRowSpan ? { rowSpan: 2 } : {})}
        >
          {column.label}
        </TableCell>
      );
    }
  };
  const getCellContent = (column, row, value, index) => {
    if (
      typeof value === "number" &&
      (column.format || column.type === "Progress")
    ) {
      if (column.type === "Progress") {
        return (
          <Box style={{ maxWidth: "400px" }}>
            <ProgressBar
              hasLabel
              value={value}
              hexColor={CHART_COLORS[index % CHART_COLORS.length]}
            />
          </Box>
        );
      } else {
        return column.format(value);
      }
    } else if (
      typeof value === "string" &&
      (column.format || column.truncate || column.type === "Chip")
    ) {
      if (column.label && column.type === "Date") {
        return column.format(value);
      } else if (column.type === "Chip") {
        if (!value.length) return false;
        return (
          <div className="cruChip">
            <Chip
              size="small"
              color={props.hasChip[value].color}
              label={props.hasChip[value].label}
            />
          </div>
        );
      } else if (column.truncate && typeof column.truncate === "number") {
        if (column.richText) {
          return truncateString(
            htmlToText(value, {
              tags: {
                img: { format: "skip" },
                a: { options: { ignoreHref: true } },
                h2: { options: { uppercase: false } },
              },
            }),
            column.truncate
          );
        } else {
          if (column.tooltipWithTruncate) {
            return (
              <Tooltip title={value} placement="top" arrow>
                <span>{truncateString(value, column.truncate)}</span>
              </Tooltip>
            );
          } else {
            return truncateString(value, column.truncate);
          }
        }
      }
    } else if (column.type === "Switch") {
      return (
        <Switch
          checked={row[column.id] ? true : false}
          name={column.id}
          onChange={(event) => props.handleToggle(event, row.id, column.id)}
        />
      );
    } else if (column.type == "clickable") {
      return (
        <Typography
          name={column.id}
          className={classes.linkText}
          onClick={(event) => props.hasLink(event, value, column.id, index)}
        >
          {value}
        </Typography>
      );
    } else if (column.type == "hyperlink") {
      if (value != "" && value != undefined) {
        let cellArray = value.split(",");
        if (cellArray.length > 0) {
          const output = cellArray.map((el, i) => (
            <div>
              <a href={el.split(" : ")[1]} target="_blank">
                {el}
              </a>
            </div>
          ));
          return output;
        }
      }
    } else if (value && typeof value === "object" && column.type !== "Nested") {
      if (value != "" && value != undefined) {
        return value.join(", ");
      } else {
        return value;
      }
    } else if (
      props.qualityManage &&
      value &&
      typeof value === "number" &&
      column.type !== "Nested"
    ) {
      return (
        <div className={classes.cellNoWrap}>
          <span
            // style={{
            //   color: row.isValueChanged && row.selectedReportingYear.value === value
            //     ? 'red' : row.valuationType === "Guidance" && row.selectedReportingYear.minValue + "-" + row.selectedReportingYear.maxValue === value
            //       ? '#00519c' :
            //       row.valuationType === 'Calculated' && row.selectedReportingYear.value === value
            //         ? '#8F00FF' : 'black'
            // }}
            gutterBottom
          >
            <span className={classes.overviewValues}>{value}</span>
          </span>
        </div>
      );
    } else {
      if (props.qualityManage && value === 0) {
        return "-";
      } else {
        return value;
      }
    }
  };

  const colSpanCount = props.columns.reduce((result, { colSpan }) => {
    result[colSpan] = ++result[colSpan] || 1;
    return result;
  }, {});

  let rendered = [];

  const showAttatchment = (column, value, index, row) => {
    //console.log("col", column, "row", row, props);
    if (props.rows[0].reportingYear) {
      let reports = [];
      let comt = "";
      let isEdit;
      let repq;
      props.rows.forEach((row) => {
        // row[reqHeader]
        row.reportingYear.forEach((rep) => {
          if (rep.publication === column.id) {
            if (rep.value && rep.value === value) {
              reports = rep.valueReportInfo;
              comt = rep.comments;
              isEdit = rep.isEdit;
              repq = rep;
            } else if (
              rep.maxValue &&
              rep.minValue &&
              value === rep.minValue + " - " + rep.maxValue
            ) {
              reports = rep.valueReportInfo;
              comt = rep.comments;
              isEdit = rep.isEdit;
              repq = rep;
            } else if (
              rep.maxValue &&
              !rep.minValue &&
              value === rep.maxValue
            ) {
              reports = rep.valueReportInfo;
              comt = rep.comments;
              isEdit = rep.isEdit;
              repq = rep;
            } else if (rep.minValue && !rep.maxValue && value == rep.minValue) {
              reports = rep.valueReportInfo;
              comt = rep.comments;
              isEdit = rep.isEdit;
              repq = rep;
            }
          }
        });
      });

      return (
        <>
          {column.qValue ? (
            <div align="right">
              <div className={classes.cellNoWrap}>
                <span className={classes.overviewValues}>
                  {value ? (
                    <>{value}</>
                  ) : (
                    <span className={classes.overviewIcons}>-</span>
                  )}
                </span>
                {comt ? (
                  <span className={classes.overviewIcons}>
                    <Tooltip title={comt}>
                      <IconButton>
                        <CommentIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                ) : (
                  <span className={classes.overviewIcons}>-</span>
                )}
                {reports.length > 0 ? (
                  reports.map((rp) => (
                    <span className={classes.overviewIcons}>
                      <Tooltip title={rp.reportName} placement="top" arrow>
                        <IconButton>
                          <AttachIcon
                            onClick={(event) => {
                              props.openDocumentForView(rp);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </span>
                  ))
                ) : (
                  <span className={classes.overviewIcons}>-</span>
                )}
                {isEdit && props.isAllowEdit ? (
                  <span className={classes.overviewIcons}>
                    <TooltipIcon
                      title="Edit"
                      placement="top"
                      key={column.variableValueId}
                      onClick={(e) => {
                        row.reportingYear.forEach((el) => {
                          if (el.publication == column.id) {
                            row.variablevalueid = el.variableValueId;
                          }
                        });
                        props.openVariableEdit(row);
                      }}
                    >
                      <EditIcon
                        style={{ fontSize: "20px", color: "#333842" }}
                      />
                    </TooltipIcon>
                  </span>
                ) : (
                  <span className={classes.overviewIcons}>-</span>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      );
    }

    if (
      row.ownerUpdateReportInfoList &&
      row.ownerUpdateReportInfoList.length > 0
    ) {
      return (
        <div style={{ display: "flex" }}>
          {row.ownerUpdateReportInfoList.map((item, index) => {
            console.log(item);
            const icons = [];

            if (item.link) {
              // Render LinkIcon for link
              icons.push(
                <span className={classes.overviewIcons}>
                  <Tooltip
                    title={item.link}
                    placement="top"
                    arrow
                    key={`${index}-link`}
                  >
                    <IconButton color="accent4">
                      <LinkIcon
                        color="accent4"
                        onClick={(event) => {
                          console.log("NAVI", item.link);
                          window.open(`${item.link}`, "_blank");
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              );
            }
            if (item.reportName) {
              // Render AttachIcon for attachment
              icons.push(
                <Tooltip
                  title={item.reportName}
                  placement="top"
                  arrow
                  key={`${index}-attach`}
                >
                  <IconButton>
                    <AttachIcon
                      onClick={(event) => {
                        props.openDocumentForView(item);
                      }}
                    />
                  </IconButton>
                </Tooltip>
              );
            }

            return icons.length > 0 ? icons : null; // Render null if there are no recognized items
          })}

          {row.comment && (
            <Tooltip title={row.comment} placement="top" arrow>
              <IconButton>
                <CommentIcon
                  onClick={(event) => {
                    // Perform action for comments
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      );
    } else if (row.comment) {
      return (
        <Tooltip title={row.comment} placement="top" arrow>
          <IconButton>
            <CommentIcon
              onClick={(event) => {
                // Perform action for comments
              }}
            />
          </IconButton>
        </Tooltip>
      );
    }
    //SEE THROUGHT OWNERS

    if (
      row.seeThroughOwnerSourceFileList &&
      row.seeThroughOwnerSourceFileList.length > 0
    ) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.seeThroughOwnershipPercentage && (
            <div>{row.seeThroughOwnershipPercentage}%</div>
          )}
          {row.seeThroughOwnerSourceFileList.map((item, index) => {
            console.log(item);
            const icons = [];

            if (item.link) {
              // Render LinkIcon for link
              icons.push(
                <span className={classes.overviewIcons}>
                  <Tooltip
                    title={item.link}
                    placement="top"
                    arrow
                    key={`${index}-link`}
                  >
                    <IconButton color="accent4">
                      <LinkIcon
                        color="accent4"
                        onClick={(event) => {
                          window.open(`${item.link}`, "_blank");
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              );
            }
            if (item.reportName) {
              // Render AttachIcon for attachment
              icons.push(
                <Tooltip
                  title={item.reportName}
                  placement="top"
                  arrow
                  key={`${index}-attach`}
                >
                  <IconButton>
                    <AttachIcon
                      onClick={(event) => {
                        props.openDocumentForView(item);
                      }}
                    />
                  </IconButton>
                </Tooltip>
              );
            }

            return icons.length > 0 ? icons : null; // Render null if there are no recognized items
          })}

          {row.seeThroughOwnershipComment && (
            <Tooltip
              title={row.seeThroughOwnershipComment}
              placement="top"
              arrow
            >
              <IconButton>
                <CommentIcon
                  onClick={(event) => {
                    // Perform action for comments
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      );
    } else if (row.seeThroughOwnershipComment) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.seeThroughOwnershipPercentage && (
            <div>{row.seeThroughOwnershipPercentage}%</div>
          )}
          <Tooltip title={row.seeThroughOwnershipComment} placement="top" arrow>
            <IconButton>
              <CommentIcon
                onClick={(event) => {
                  // Perform action for comments
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      );
    }

    if (
      row.joinVentureCompnayToBeIncludedFileList &&
      column.id === "companyToBeIncluded" &&
      row.joinVentureCompnayToBeIncludedFileList.length > 0
    ) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.companyToBeIncluded && <div>{row.companyToBeIncluded}</div>}
          {row.joinVentureCompnayToBeIncludedFileList.map((item, index) => {
            console.log(item);
            const icons = [];
            if (item.link) {
              icons.push(
                <span className={classes.overviewIcons}>
                  <Tooltip
                    title={item.link}
                    placement="top"
                    arrow
                    key={`${index}-link`}
                  >
                    <IconButton color="accent4">
                      <LinkIcon
                        color="accent4"
                        onClick={(event) => {
                          window.open(`${item.link}`, "_blank");
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              );
            }
            if (item.reportName) {
              // Render AttachIcon for attachment
              icons.push(
                <Tooltip
                  title={item.reportName}
                  placement="top"
                  arrow
                  key={`${index}-attach`}
                >
                  <IconButton>
                    <AttachIcon
                      onClick={(event) => {
                        props.openDocumentForView(item);
                      }}
                    />
                  </IconButton>
                </Tooltip>
              );
            }

            return icons.length > 0 ? icons : null; // Render null if there are no recognized items
          })}

          {row.companyToBeIncludedComment && (
            <Tooltip
              title={row.companyToBeIncludedComment}
              placement="top"
              arrow
            >
              <IconButton>
                <CommentIcon
                  onClick={(event) => {
                    // Perform action for comments
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      );
    } else if (
      row.companyToBeIncludedComment &&
      column.id === "companyToBeIncluded"
    ) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.companyToBeIncluded && <div>{row.companyToBeIncluded}</div>}
          <Tooltip title={row.companyToBeIncludedComment} placement="top" arrow>
            <IconButton>
              <CommentIcon
                onClick={(event) => {
                  // Perform action for comments
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      );
    } else if (column.id === "companyToBeIncluded") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.companyToBeIncluded && <div>{row.companyToBeIncluded}</div>}
        </div>
      );
    }

    if (
      row.ownershipFileList &&
      column.id === "ownershipFileList" &&
      row.ownershipFileList.length > 0
    ) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.ownerShipperPer && <div>{row.ownerShipperPer}%</div>}
          {row.ownershipFileList.map((item, index) => {
            console.log(item);
            const icons = [];
            if (item.link) {
              icons.push(
                <span className={classes.overviewIcons}>
                  <Tooltip
                    title={item.link}
                    placement="top"
                    arrow
                    key={`${index}-link`}
                  >
                    <IconButton color="accent4">
                      <LinkIcon
                        color="accent4"
                        onClick={(event) => {
                          window.open(`${item.link}`, "_blank");
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              );
            }
            if (item.reportName) {
              // Render AttachIcon for attachment
              icons.push(
                <Tooltip
                  title={item.reportName}
                  placement="top"
                  arrow
                  key={`${index}-attach`}
                >
                  <IconButton>
                    <AttachIcon
                      onClick={(event) => {
                        props.openDocumentForView(item);
                      }}
                    />
                  </IconButton>
                </Tooltip>
              );
            }

            return icons.length > 0 ? icons : null; // Render null if there are no recognized items
          })}

          {row.ownerShipperPerComment && (
            <Tooltip title={row.ownerShipperPerComment} placement="top" arrow>
              <IconButton>
                <CommentIcon
                  onClick={(event) => {
                    // Perform action for comments
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      );
    } else if (
      row.ownerShipperPerComment &&
      column.id === "ownershipFileList"
    ) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.ownerShipperPer && <div>{row.ownerShipperPer}</div>}
          <Tooltip title={row.ownerShipperPerComment} placement="top" arrow>
            <IconButton>
              <CommentIcon
                onClick={(event) => {
                  // Perform action for comments
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      );
    } else if (column.id === "ownershipFileList") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.ownerShipperPer && <div>{row.ownerShipperPer}%</div>}
        </div>
      );
    }

    if (
      row.stockExchangeFileList &&
      column.id === "stockExchange" &&
      row.stockExchangeFileList.length > 0
    ) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.stockExchange && <div>{row.stockExchange}</div>}
          {row.stockExchangeFileList.map((item, index) => {
            console.log(item);
            const icons = [];
            if (item.link) {
              icons.push(
                <span className={classes.overviewIcons}>
                  <Tooltip
                    title={item.link}
                    placement="top"
                    arrow
                    key={`${index}-link`}
                  >
                    <IconButton color="accent4">
                      <LinkIcon
                        color="accent4"
                        onClick={(event) => {
                          window.open(`${item.link}`, "_blank");
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              );
            }
            if (item.reportName) {
              // Render AttachIcon for attachment
              icons.push(
                <Tooltip
                  title={item.reportName}
                  placement="top"
                  arrow
                  key={`${index}-attach`}
                >
                  <IconButton>
                    <AttachIcon
                      onClick={(event) => {
                        props.openDocumentForView(item);
                      }}
                    />
                  </IconButton>
                </Tooltip>
              );
            }

            return icons.length > 0 ? icons : null; // Render null if there are no recognized items
          })}

          {row.stockExchangeComment && (
            <Tooltip title={row.stockExchangeComment} placement="top" arrow>
              <IconButton>
                <CommentIcon
                  onClick={(event) => {
                    // Perform action for comments
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      );
    } else if (row.stockExchangeComment && column.id === "stockExchange") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.stockExchange && <div>{row.stockExchange}</div>}
          <Tooltip title={row.stockExchangeComment} placement="top" arrow>
            <IconButton>
              <CommentIcon
                onClick={(event) => {
                  // Perform action for comments
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      );
    } else if (column.id === "stockExchange") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.stockExchange && <div>{row.stockExchange}</div>}
        </div>
      );
    }

    if (
      row.SeeThroughOwnerPerFileList &&
      column.id === "SeeThroughOwnerPer" &&
      row.SeeThroughOwnerPerFileList.length > 0
    ) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.SeeThroughOwnerPer && <div>{row.SeeThroughOwnerPer}%</div>}
          {row.SeeThroughOwnerPerFileList.map((item, index) => {
            console.log(item);
            const icons = [];
            if (item.link) {
              icons.push(
                <span className={classes.overviewIcons}>
                  <Tooltip
                    title={item.link}
                    placement="top"
                    arrow
                    key={`${index}-link`}
                  >
                    <IconButton color="accent4">
                      <LinkIcon
                        color="accent4"
                        onClick={(event) => {
                          window.open(`${item.link}`, "_blank");
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              );
            }
            if (item.reportName) {
              // Render AttachIcon for attachment
              icons.push(
                <Tooltip
                  title={item.reportName}
                  placement="top"
                  arrow
                  key={`${index}-attach`}
                >
                  <IconButton>
                    <AttachIcon
                      onClick={(event) => {
                        props.openDocumentForView(item);
                      }}
                    />
                  </IconButton>
                </Tooltip>
              );
            }

            return icons.length > 0 ? icons : null; // Render null if there are no recognized items
          })}

          {row.SeeThroughOwnerPerComment && (
            <Tooltip
              title={row.SeeThroughOwnerPerComment}
              placement="top"
              arrow
            >
              <IconButton>
                <CommentIcon
                  onClick={(event) => {
                    // Perform action for comments
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      );
    } else if (
      row.SeeThroughOwnerPerComment &&
      column.id === "SeeThroughOwnerPer"
    ) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.SeeThroughOwnerPer && <div>{row.SeeThroughOwnerPer}%</div>}
          <Tooltip title={row.SeeThroughOwnerPerComment} placement="top" arrow>
            <IconButton>
              <CommentIcon
                onClick={(event) => {
                  // Perform action for comments
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      );
    } else if (column.id === "SeeThroughOwnerPer") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.SeeThroughOwnerPer && <div>{row.SeeThroughOwnerPer}%</div>}
        </div>
      );
    }

    if (
      row.SeeThroughOwnerFileList &&
      column.id === "SeeThroughOwner" &&
      row.SeeThroughOwnerFileList.length > 0
    ) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.SeeThroughOwnerPer && <div>{row.SeeThroughOwnerPer}%</div>}
          {row.SeeThroughOwnerFileList.map((item, index) => {
            console.log(item);
            const icons = [];
            if (item.link) {
              icons.push(
                <span className={classes.overviewIcons}>
                  <Tooltip
                    title={item.link}
                    placement="top"
                    arrow
                    key={`${index}-link`}
                  >
                    <IconButton color="accent4">
                      <LinkIcon
                        color="accent4"
                        onClick={(event) => {
                          window.open(`${item.link}`, "_blank");
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              );
            }
            if (item.reportName) {
              // Render AttachIcon for attachment
              icons.push(
                <Tooltip
                  title={item.reportName}
                  placement="top"
                  arrow
                  key={`${index}-attach`}
                >
                  <IconButton>
                    <AttachIcon
                      onClick={(event) => {
                        props.openDocumentForView(item);
                      }}
                    />
                  </IconButton>
                </Tooltip>
              );
            }

            return icons.length > 0 ? icons : null; // Render null if there are no recognized items
          })}

          {row.SeeThroughOwnerComment && (
            <Tooltip title={row.SeeThroughOwnerComment} placement="top" arrow>
              <IconButton>
                <CommentIcon
                  onClick={(event) => {
                    // Perform action for comments
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      );
    } else if (row.SeeThroughOwnerComment && column.id === "SeeThroughOwner") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.SeeThroughOwnerPer && <div>{row.SeeThroughOwnerPer}%</div>}
          <Tooltip title={row.SeeThroughOwnerComment} placement="top" arrow>
            <IconButton>
              <CommentIcon
                onClick={(event) => {
                  // Perform action for comments
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      );
    } else if (column.id === "SeeThroughOwner") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.SeeThroughOwner && <div>{row.SeeThroughOwner}%</div>}
        </div>
      );
    }
  };

  const getCollerCode = (column, value, index, row) => {
    var colortype = "black";
    if (props.variableReport || props.unitReport) {
      if (column.id === "name") {
        if (row.isManual) {
          return {
            color: "#db9400",
          };
        }
      }
    }
    if (props.qualityManage) {
      if (column.id === "unitReport") {
        if (row.isManualUnit) {
          return {
            color: "#db9400",
          };
        } else {
          return {
            color: "black",
          };
        }
      }
      if (column.id === "variableReport") {
        if (row.isManualVariable) {
          return {
            color: "#db9400",
          };
        } else {
          return {
            color: "black",
          };
        }
      }

      row.reportingYear.forEach((rep) => {
        if (rep.publication === column.id) {
          // if (rep.value && rep.value === value) {
          if (rep.isValueChanged) {
            colortype = "red";
          } else if (row.valuationType === "Guidance") {
            colortype = "#00519c";
          } else if (row.valuationType === "Calculated") {
            colortype = "#8F00FF";
          }
          // }
          // else {
          //   if (typeof value === 'string') {
          //     if (rep.isValueChanged) {
          //       colortype = 'red'
          //     }

          //     if (row.valuationType === "Guidance") {

          //       colortype = '#00519c'

          //     }

          //   }

          // }
        }
      });
    }
    if (props.searchAndControl) {
      let col = "";
      let colortype = "black";
      if (column != null && column.label) {
        col = column.label;
        if (
          col.indexOf("-annual") !== -1 ||
          col.indexOf("-Q1") !== -1 ||
          col.indexOf("-Q2") !== -1 ||
          col.indexOf("-Q3") !== -1 ||
          col.indexOf("-Q4") !== -1 ||
          col.indexOf("-Annual") !== -1
        ) {
          if (
            row[`${col}_ValuationType`] !== undefined &&
            row[`${col}_ValuationType`] !== null
          ) {
            if (
              row[`${col}_ValuationType`].toLowerCase() ===
              "Actual".toLowerCase()
            ) {
              colortype = "black";
            }
            if (
              row[`${col}_ValuationType`].toLowerCase() ===
              "Guidance".toLowerCase()
            ) {
              colortype = "#00519c";
            }
            if (
              row[`${col}_ValuationType`].toLowerCase() ===
              "Calculated".toLowerCase()
            ) {
              colortype = "#8F00FF";
            }
          }
        }
      }
      return {
        color: colortype,
      };
    }

    return {
      color: colortype,
    };
  };

  const getLinkColumnContent = (column, row) => {
    var linkUrl = column.linkUrl;
    var linkText = column.linkText;
    if (column.valuePlaceholders) {
      var arrPlaceholders = column.valuePlaceholders.split(",");

      for (var i = 0; i < arrPlaceholders.length; i++) {
        var linkValue = row[arrPlaceholders[i]];
        linkUrl = linkValue
          ? linkUrl.replace("{" + arrPlaceholders[i] + "}", linkValue)
          : "";
        linkText = linkText.replace("{" + arrPlaceholders[i] + "}", linkValue);
      }
    }

    return linkUrl ? (
      <a href={linkUrl} target="_blank" className={classes.linkText}>
        {" "}
        {linkText}{" "}
      </a>
    ) : (
      "-"
    );
  };

  return (
    <Paper elevation={0}>
      <LoaderWrapper loading={props.loading}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              {props.columns.some((item) => item.hasOwnProperty("colSpan")) ? (
                <>
                  <TableRow>
                    {isFlagEnabled({}, true, true)}
                    {isCheckboxEnabled({}, true, true)}
                    {isSelectableCheckbox({}, true, true)}
                    {props.columns.map((column, index) => {
                      if (typeof column.colSpan === "undefined") {
                        return generateColumn(column, true);
                      } else {
                        if (!rendered.includes(column.colSpan)) {
                          rendered.push(column.colSpan);
                          return (
                            <TableCell
                              align="center"
                              key={index}
                              colSpan={colSpanCount[column.colSpan]}
                            >
                              {column.colSpan}
                            </TableCell>
                          );
                        } else return;
                      }
                    })}
                    {isActionButtonAvailable({}, true, -1, true)}
                  </TableRow>
                  <TableRow>
                    {props.columns
                      .filter((column) => typeof column.colSpan !== "undefined")
                      .map((column) => generateColumn(column))}
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  {isFlagEnabled({}, true)}
                  {isCheckboxEnabled({}, true)}
                  {isSelectableCheckbox({}, true)}
                  {props.columns.map((column) => generateColumn(column))}
                  {isActionButtonAvailable({}, true, -1)}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {props.rows.length > 0 ? (
                props.rows.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {isFlagEnabled(row, false)}

                      {isCheckboxEnabled(row, false)}
                      {isSelectableCheckbox(row, false)}

                      {props.columns
                        .filter((column) => column.type !== "Highlight")
                        .map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className={
                                column.border == true
                                  ? clsx(
                                      classes.tableCell,
                                      classes.tableCellRightSep
                                    )
                                  : clsx(classes.tableCell) &&
                                    column.sorting == true
                                  ? clsx(classes.tableCellSorting)
                                  : clsx(classes.tableCell)
                              }
                              style={getCollerCode(column, value, index, row)}
                            >
                              {column.isLinkColumn ? (
                                getLinkColumnContent(column, row)
                              ) : column.qValue ? (
                                <>
                                  {showAttatchment(column, value, index, row)}
                                </>
                              ) : (
                                <>{getCellContent(column, row, value, index)}</>
                              )}

                              {column.isIcons ? (
                                <>
                                  {/*  {//console.log("IconList:", props.IconList)} */}
                                  {/*   {props.IconList &&
                                    props.IconList.map((itemList, rowIndex) => {
                                      const icons =
                                        itemList.length > 0 &&
                                        itemList.map((item, itemIndex) => {
                                          const linkIcon = item.link && (
                                            <Tooltip
                                              title={item.link}
                                              placement="top"
                                              arrow
                                              key={`${rowIndex}-${itemIndex}-link`}
                                            >
                                              <IconButton>
                                                <LinkIcon
                                                  onClick={(event) => {
                                                    // Perform action for opening the link
                                                  }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          );

                                          const attachIcon =
                                            item.reportName && (
                                              <Tooltip
                                                title={item.reportName}
                                                placement="top"
                                                arrow
                                                key={`${rowIndex}-${itemIndex}-attach`}
                                              >
                                                <IconButton>
                                                  <AttachIcon
                                                    onClick={(event) => {
                                                      props.openDocumentForView(
                                                        item.reportName
                                                      );
                                                    }}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            );

                                          return [linkIcon, attachIcon];
                                        });

                                      return (
                                        <React.Fragment key={rowIndex}>
                                          {icons}
                                        </React.Fragment>
                                      );
                                    })} */}

                                  {/*    {props.MessageBox[index] === "" ? (
                                    <>-</>
                                  ) : (
                                    <Tooltip
                                      title={props.MessageBox[index]} // Display the comment for the current row
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton>
                                        <CommentIcon
                                          onClick={(event) => {
                                            // Perform action for comments
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )} */}
                                </>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          );
                        })}

                      {isActionButtonAvailable(row, false, index)}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={99}>
                    <EmptyState
                      icon={
                        <FileOffIcon
                          style={{ color: "#db9400", fontSize: "40px" }}
                        />
                      }
                      title="No Data Found"
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/*      {!props.rowsPerPageVisible ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={props.totalCount}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null} */}
      </LoaderWrapper>
    </Paper>
  );
};

export default CustomDataTable;
