// Default React, Router and Services Components
import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Box, Collapse, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, Tooltip, Typography } from '@mui/material';
import { lighten, styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import menu from "./Menu";
import { logo } from "../../../Resources/index";
import { clearSession } from "../../../Services/SessionService";


// Import Material Icons
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import { ExpandLess, ExpandMore, Logout } from '@mui/icons-material';

const Icons = (e) => {
    if (e === "users") {
        return <BadgeOutlinedIcon className="svgIcon" />
    }
    else if (e === "area-chart") {
        return <AddchartOutlinedIcon className="svgIcon" />
    }
    else if (e === "ownership") {
        return <PollOutlinedIcon className="svgIcon" />
    }
    else if (e === "data") {
        return <DatasetOutlinedIcon className="svgIcon" />
    }
}

const ExpandIcon = ({ open, sideBarOpen }) => {

    return (
        sideBarOpen ?
            open ? <ExpandLess sx={{ color: (theme) => theme.palette.grey[500], ml: (theme) => theme.spacing(1) }} />
                : <ExpandMore sx={{ color: (theme) => theme.palette.grey[500], ml: (theme) => theme.spacing(1) }} />
            : ""
    )
}

const ParentLIButton = styled(({ sideBarOpen, open, ...rest }) => (
    <ListItemButton {...rest} />
))(({ open, sideBarOpen, theme }) => ({
    borderRadius: theme.shape.borderRadius,
    padding: !sideBarOpen ? theme.spacing(1) : theme.spacing(1),
    flexDirection: !sideBarOpen ? "column" : "row",
    '&:hover': {
        '& > .MuiListItemIcon-root': {
            color: theme.palette.secondary.main,
        }
    },
    '& > .MuiListItemIcon-root': {
        minWidth: theme.spacing(4),
        color: open && sideBarOpen ? theme.palette.secondary.main : theme.palette.text.secondary,
        fontSize: 20,
        justifyContent: !sideBarOpen ? "center" : "flex-start"
    },
    '& > .MuiListItemText-root': {
        textAlign: !sideBarOpen ? "center" : "initial",
        '& > .MuiTypography-root': {
            fontSize: !sideBarOpen ? 12 : 14,
            fontWeight: 600,
            color:"#000",
            lineHeight: 1.4,
        },
    },
}));

const NestedLIButton = styled(({ sideBarOpen, ...rest }) => (
    <ListItemButton {...rest} />
))(({ theme, sideBarOpen }) => ({
    padding: theme.spacing(1, 1, 1, 4),
    borderRadius: theme.shape.borderRadius,
    '&:before': {
        content: '""',
        display: "block",
        height: '100%',
        width: '1px',
        position: "absolute",
        left: theme.spacing(1.5),
        background: theme.palette.grey[400],
    },
    '& > .MuiListItemText-root': {
        '& > .MuiTypography-root': {
            fontSize: 14,
            fontWeight: 600
        }
    }
}));

const LI = styled((props) => (
    <ListItem {...props} />
))(({ theme }) => ({
    display: "block",
    padding: theme.spacing(0, .5),
}));

const LISubHeader = styled((props) => (
    <ListSubheader {...props} />
))(({ theme }) => ({
    padding: theme.spacing(0.5, 2, 0.5, 4),
    '&:before': {
        content: '""',
        display: 'block',
        height: '50%',
        width: '1px',
        position: 'absolute',
        top: '50%',
        left: theme.spacing(1.5),
        background: theme.palette.grey[400],
    },
    '&:after': {
        content: '""',
        display: 'block',
        height: theme.spacing(1.5),
        width: theme.spacing(1.5),
        position: 'absolute',
        top: '50%',
        left: theme.spacing(1.5),
        transform: 'translate(-50%,-50%)',
        border: `2px solid ${theme.palette.grey[400]}`,
        borderRadius: theme.spacing(.5),
        background: theme.palette.background.default,
    },
    '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
        fontSize: 13,
        fontWeight: 500,
        textTransform: "uppercase",
    }
}));

const LIButtonNode = styled((props) => (
    <ListItemButton {...props} />
))(({ theme }) => ({
    padding: theme.spacing(1, 2, 1, 4),
    borderRadius: theme.shape.borderRadius,
    '&:before': {
        content: '""',
        display: "block",
        height: '100%',
        width: '1px',
        position: "absolute",
        left: theme.spacing(1.5),
        background: theme.palette.grey[400],
    },
    '&.Mui-selected': {
        color: theme.palette.secondary.main,
        background: lighten(theme.palette.secondary.light, 0.75),
        '&:hover': {
            color: theme.palette.text.primary,
            background: lighten(theme.palette.secondary.light, 0.25)
        },
        '&:before': {
            background: theme.palette.secondary.main,
        },
    },
    '& > .MuiListItemText-root': {
        '& > .MuiTypography-root': {
            fontSize: 12,
            fontWeight: 500,
        }
    }
}));

const ToggleCollapseButton = ({ icon = null, id, isTop, onClick, open, primary, sideBarOpen }) => {
    if (isTop) {
        return (
            <ParentLIButton sideBarOpen={sideBarOpen} open={open} id={id} onClick={onClick}>
                {icon && <ListItemIcon>{Icons(icon)}</ListItemIcon>}
                <ListItemText primary={primary} />
                <ExpandIcon open={open} sideBarOpen={sideBarOpen} />
            </ParentLIButton>
        )
    } else {
        return (
            <NestedLIButton sideBarOpen={sideBarOpen} id={id} onClick={onClick}>
                <ListItemText primary={primary} />
                <ExpandIcon open={open} sideBarOpen={sideBarOpen} />
            </NestedLIButton>
        )
    }
}

const MenuItem = ({ id, primary, menu }) => {
    const history = useHistory(),
        location = useLocation(),
        LANDING_PAGE = "/app/dashboard";

    const handleRoute = (object) => {
        localStorage.setItem('menu', JSON.stringify(object));
        if (localStorage.getItem("historyData")) {
            localStorage.removeItem("historyData");
        }
        history.push(object.route);
    }

    const isSelected = (currentPath) => {
        return currentPath === location.pathname || (currentPath === LANDING_PAGE && location.pathname === "/app");
    }

    return (
        <LIButtonNode id={id} selected={isSelected(menu.route)} onClick={() => handleRoute(menu)}>
            <ListItemText primary={primary} />
        </LIButtonNode>
    )
}


const MenuItemHeader = ({ primary, sideBarOpen }) => {
    return (
        <>
            <LISubHeader component="div">
                <ListItemText primary={primary} />
            </LISubHeader>
        </>
    )
}

const CollapsibleMenuList = ({ isTop = false, menu, sideBarOpen, uniqueId }) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ToggleCollapseButton icon={menu.icon} id={`panel_${uniqueId}`} open={open} onClick={handleClick} primary={t(menu.name)} sideBarOpen={sideBarOpen} isTop={isTop} />
            <Collapse in={open && sideBarOpen} timeout="auto" unmountOnExit>
                <Box sx={{ pl: isTop ? 1 : 2.5, py: 1 }}>
                    {menu.children.map((item, index) => {
                        if (item.children && !!item.children.length) {
                            return <CollapsibleMenuList
                                key={'noCollapse_' + uniqueId + '_' + index}
                                uniqueId={uniqueId + '_' + index}
                                menu={item}
                                sideBarOpen={sideBarOpen}
                            />
                        } else {
                            return <MenuItem
                                key={'noCollapse_' + uniqueId + '_' + index}
                                id={'noCollapse_' + uniqueId + '_' + index}
                                primary={t(item.name)}
                                menu={item}
                            />
                        }
                    })}
                </Box>
            </Collapse>
        </>
    )
}

const NestedMenuList = ({ isTop = false, menu, sideBarOpen, uniqueId }) => {
    const { t } = useTranslation();

    return (
        <>
            <MenuItemHeader id={`panel_${uniqueId}`} primary={t(menu.name)} sideBarOpen={sideBarOpen} />
            {menu.children.map((item, index) => {
                if (item.children && !!item.children.length) {
                    return <NestedMenuList
                        key={'noCollapse_' + uniqueId + '_' + index}
                        uniqueId={uniqueId + '_' + index}
                        menu={item}
                        sideBarOpen={sideBarOpen}
                    />
                } else {
                    return <MenuItem
                        key={'noCollapse_' + uniqueId + '_' + index}
                        id={'noCollapse_' + uniqueId + '_' + index}
                        primary={t(item.name)}
                        menu={item}
                    />
                }
            })}
        </>
    )
}

const SideNav = ({ list, pIndex = 0, sideBarOpen }) => {
    return list.map((item, index) => {
        const uniqueId = (index + 1) + '' + pIndex;

        return <LI key={'panel_' + uniqueId}>
            <CollapsibleMenuList
                isTop={true}
                menu={item}
                sideBarOpen={sideBarOpen}
                uniqueId={uniqueId} />
        </LI>
    });
}

const SideBar = (props) => {
    const menuList = menu;

    const history = useHistory();
    const expandSidebar = () => { props.setOpen(true) }
    const logout = () => {
        clearSession();
        history.push('/login');
    }

    return (
        <>
            <Box component={Paper} square elevation={3} display="flex" flexDirection="column" maxHeight="100%" sx={{ overflow: "hidden" }}>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} p={1.5} gap={1}>
                    <img className="" src={logo} width="63" alt="logo" />
                    {props.open && <Typography sx={{ fontSize: 12, fontWeight: 700, textTransform: "uppercase", height: "38px", overflow:"hidden" }}>Primary Data Collection Platform</Typography>}
                </Box>
                <Divider sx={{ mx: 2 }} />
                <Box minHeight={"1px"} flexGrow={1}>
                    <OverlayScrollbarsComponent style={{ height: "100%" }} element="nav" onClick={expandSidebar}>
                        <List>
                            <SideNav list={menuList} sideBarOpen={props.open} />
                        </List>
                    </OverlayScrollbarsComponent>
                </Box>
                <Divider sx={{ mx: 2 }} />
                <Box display={"flex"} justifyContent={"center"} sx={{ pt: (theme) => theme.spacing(1) }}>
                    {props.open ?
                        <ListItemButton sx={{ justifyContent: "center" }} onClick={() => logout()}>
                            <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}><Logout /></ListItemIcon>
                            <ListItemText sx={{ flex: "0 1 auto" }} primary="Sign out" />
                        </ListItemButton> :
                        <Tooltip arrow placement="right" title="Sign out">
                            <IconButton aria-label="Sign out">
                                <Logout />
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
            </Box>
        </>
    )
}

export default SideBar;