// Default React, Router and Services Components
import React, { useState } from "react";
import { Button, Box, Breadcrumbs, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Link } from 'react-router-dom';
import XLSX from "sheetjs-style";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
import { useHistory } from "react-router-dom";
import ApiService from "../../../Services/ApiService";
import { toasterError } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";
// Importing Custom Components
import BaseLayout from "../../../UIComponent/BaseLayout";
import CircularProgress from "@mui/material/CircularProgress";
import DataTable from "../../../UIComponent/DataTable";
import SearchExportFilter from "./OwnershipChangesSearchExportFilter";
import SideDrawer from "../../../UIComponent/Drawer";
import CustomSearchAndExportDataTable from '../../../UIComponent/CustomSearchAndExportDataTable'
// Importing useStyles
import useStyles from "../CompanyOwnershipChanges.styles";

// Import Fluent UI Icons
import { ChevronRightSmallIcon, ClearIcon, SearchIcon, ExportIcon } from "@fluentui/react-icons-mdl2";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setFiterValue, setTreeComm, setTreeCompany, setTreeLocation } from "../../../features/filter/StateSlice";
import { indexOf } from "lodash";

const OwnershipChangesSearchExport = (props) => {
  const filterData = useSelector((store) => store.stateSlice.filterValue)
  const dispatch = useDispatch()
  const { treeLocation, treeCompany, treeComm } = useSelector((store) => store.stateSlice);
  const [checkBoxValue,setCheckBoxValue]=useState([]);
const [tempJobAssetIdForCheckBox,setTempJobAssetForCheckBox]= useState([]);
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [allRows, setAllRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [selected, SetSelected] = useState([]);
  const [idforEdit, setIdForEdit] = useState(null);
  const [editJobNumber, setEditJobNumber] = useState(null);
  const [isAllSelected, setIsSelected] = useState(false);
  const [ischecked, setIsChecked] = useState([]);
  const [state, setState] = React.useState({ right: false });
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  const [crudState, setCrudState] = React.useState(false);
  const [formData, setFormData] = useState([]);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [reload, setReload] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [filterValue, setFilterValue] = useState(null);
  const [tableData, setTableData] = useState([]);
  const date = new Date().toLocaleDateString();
  const date1 = date.split("/").join("-");
  const [value, setValue] = useState({ startDate: '', endDate: '', });
  const [viewDataButtonDisable, setviewDataButtonDisable] = useState(true);
  const [exportToExcel, setExportToExcel] = useState(false);
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [jobName, setJobName] = useState('');
  const [filterFinalData, setFilterFinalData] = useState();
  const [companyFilter, setCompanyFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [excelColumns, setExcelColumns] = useState([]);
  const [allComment, setAllComent] = useState([])

  const [filterValues, setFilterValues] = useState({
    JobName: '', Company: [], Asset: [], CountryIds: [], RegionIds: [], SubRegionIds: [],
    CommodityIds: [], FromDate: null, ToDate: null, Status: null, SearchText: ''
  });

  const [allJobAssetIds, setAllJobAssetIds] = React.useState([]);
  const [deselectedValue, setDeselectedValue] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState([]);


  const [selectedJob, setSelectedJob] = useState(null)
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
  const history = useHistory();
  // Declaration of States and Component Variables End Here
React.useEffect(()=>{
if(selected){
  AddCheckBoxValue(selected);
}else{
  RemoveAllCheckboxValue();
}

},[selected])
  // Assigning Columns and UI Props
  const gridcolumns = [
    { id: "jobNoName", label: "Job Name", minWidth: 300, width: 350, sorting: true },
    { id: "assetName", label: "Asset ", minWidth: 250, width: 250, sorting: true },
    { id: "commodity", label: "Commodity", minWidth: 200, width: 200, sorting: true, truncate: 75, tooltipWithTruncate: true },
    { id: "operatorName", label: "Operator", minWidth: 250, width: 250, sorting: true },
    { id: "ownerName", label: "Owner", minWidth: 250, width: 250, sorting: true },  ];

  // search on enter press
  const handleKeyUp = (event) => {
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      setPage(0)
      dispatch(setFiterValue({ searchText: searchValue }))
      setAllJobAssetIds([]);
      setIsSelected(false);
      GetSearchExportReport();

    }
  };

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(32, function (err, res) {
      if (res) {
        //console.log(res.data)
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])
  React.useEffect(() => {  getFilterData(); }, [reload]);
  React.useEffect(() => { 
    setPage(0); 
    setAllJobAssetIds([]);
    setIsSelected(false);
     GetSearchExportReport();
     }, [filterData,reload]);
  React.useEffect(() => {  GetSearchExportReport(); }, [sorting, page]);
  React.useEffect(() => { 
    ResetCheckBox(); 
    GetSearchExportReport(); 
  }, [rowsPerPage]);
  // Declaration of React Hooks End Here

  // For searching the grid
  const globalSearch = (value) => { 
    setSearchValue(value); 
  };
const ResetCheckBox=()=>{
  SetSelected([]);
  setIsSelected(false);
}
  

  // Function for Export Report
  const GetSearchExportReport = (filterValue) => {
   // debugger;
    setLoading(true);
    if(filterValues.SearchText){
      Object.defineProperties(filterValues,{SearchText:{
        value:searchValue
      }
      })
    } 

    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
    ApiService.search(`SearchAndExport/GetSearchAndExportListForOwnershipChanges?`,
      {
        filter: filterData? encodeURIComponent(JSON.stringify(filterData)) : "",
        sortColumn: sorting.sortBy.slice(0,1).toUpperCase()+sorting.sortBy.slice(1,sorting.sortBy.length),
        isAscending: sorting.isAscending
      }, prt
    )
      .then((res) => {
        if (res.status === 200) {
          if(page==0){
            setAllJobAssetIds(res.data.listJobAssetId)
          }
          setAllRows(res.data.listSearchAndExportOwnershipChangesDto.data);
          setTotalCount(res.data.listSearchAndExportOwnershipChangesDto.totalCount);
         // setTableData(res.data.listSearchAndExportOwnershipChangesDto.totalCount);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
        setLoading(false);
      });
  };

  // Function for Getting View Data
  const GetViewDataList = (filterValue, exportExcel = false) => {

    if (selected.length === 0) {
      toasterError('Please select any Job');
      return;
    }
    let jobassets = [];
    setExporting(true);
    jobassets = selected;
    let ptr = []
    let Headers = { jobAssetIds: jobassets }
    ApiService.search(`SearchAndExport/GetSearchAndExportExcelForOwnershipChanges?`,
    {
      filter: filterData ? encodeURIComponent(JSON.stringify(filterData)) : "",
      jobAssetIds: jobassets,
      sortColumn: sorting.sortBy,
      isAscending: sorting.isAscending
  }, ptr
    )
      .then((result) => {
        if (result.status === 200) {                            
          let apiData = [];
          let apiDatawoCoumns = [];
          let fileName = `Search and Export ${date1}`;
          let header=[];
          if (result.data[0].result != null) {
            
              //console.log("result",result);
              apiData = JSON.parse(result.data[0].result);

              if (apiData !== null && apiData !== undefined) {
                  apiDatawoCoumns = JSON.parse(result.data[0].result);
                  if (apiData.data !== null && apiData.data !== undefined && apiData.data.length > 0) {
                      let colorcodes = [];
                      if (apiData.data !== null && apiData.data !== undefined && apiData.data.length > 0) {
                          let keys = Object.keys(apiData.data[0]);
                          //console.log(keys);
                          
                          if (keys !== null && keys !== undefined) {
                              keys.forEach(key => {

                                  // console.log(key);
                                  if (key.indexOf("UpdateOwnerName") !== -1) {
                                      header.push('Owner Name')
                                   }
                                  else if (key.indexOf("UpdateAssetName") !== -1) {
                                      header.push('Asset Name')    
                                  }
                                  else if (key.indexOf("ChangeType") !== -1) {
                                      header.push('Change Type')                               
                                  }
                                  else if (key.indexOf('_')!==-1 && key.indexOf("_Comment") === -1){
                                    header.push(key.substring(0, key.indexOf('_')));
                                  }
                                  else if (key.indexOf("_Comment") !== -1){
                                    //header.push(key.substring(0, key.indexOf('_')));
                                  }
                                  else {
                                    header.push(key)
                                }                               
                              })
                              
                          }
                          
                              setExcelColumns(header);
                      }
                      exportToCSV(apiData.data, fileName, apiDatawoCoumns.data, colorcodes);
                  } else {
                      toasterError(`No data available`);
                  }

              } else {
                  toasterError(`No data available`);
              }
          }
      }
        setExporting(false);
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
    setExporting(false);
  };
  const handleExportToExcel = () => { GetViewDataList(searchValue, true); }

  // Function for Export to CSV
  const exportToCSV = (apiData, fileName, datawoCommments, excelColorCode) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let onlyComments = [];
    datawoCommments.forEach(obj => {
      Object.keys(obj).forEach(key => {
        if (key.endsWith("_Comment")) { onlyComments.push(obj[key]); }
        if (key.endsWith("_Comment") || key.endsWith("JobAssetId")) {
          delete obj[key];
      }
      })
    })
    const ws = XLSX.utils.json_to_sheet(datawoCommments);
   // console.log("ws",ws);
    let wsData = createAlphybet(datawoCommments, ws, onlyComments, excelColorCode)
    excelColumns.forEach((exCol, i) => {
        if (exCol.includes('_Comment')) {
        excelColumns.splice(i, 1)
      }
    });
    XLSX.utils.sheet_add_aoa(wsData, [excelColumns], { origin: "A1", });
    const wb = { Sheets: { data: wsData }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const alphabetLocation = (n) => {
    var ordA = 'A'.charCodeAt(0);
    var ordZ = 'Z'.charCodeAt(0);
    var len = ordZ - ordA + 1;

    var s = "";
    while (n >= 0) {
      s = String.fromCharCode(n % len + ordA) + s;
      n = Math.floor(n / len) - 1;
    }
    return s;
  }

  const createAlphybet = (v, ws, allComments, excelColorCode) => {
    var ordA = 'A'.charCodeAt(0);
    var ordZ = 'Z'.charCodeAt(0);
    var len = ordZ - ordA + 1;
    let header=[];
    var s = "";
    let commentData = {}
    let commentCount = 0;
    let n = Object.keys(v[0]).length
    for (let index = 0; index < v.length; index++) {
      (Object.values(v[index]).map((value, ind) => {
        let n = ind
        
        
        while (n >= 0) {
          s = String.fromCharCode(n % len + ordA);
          n = Math.floor(n / len) - 1;
        }

        let commentIndex = (Object.keys(v[index]))[ind];

        if ((ind === 7 || ind===11 || ind===15) && value && value != '' && !commentIndex.includes('_ReportName')) {
        // if (ind > 8 && value && value != '' && !commentIndex.includes('_ReportName')) {
          commentData[alphabetLocation(ind) + (index + 2)] = allComments[commentCount];
          ws[`${alphabetLocation(ind)}${index +2}`].s = {
            font: {
              color: { rgb: excelColorCode[commentCount] }
            },
          }
          commentCount = commentCount + 1
        }
      }))
    }
    Object.keys(ws).forEach(ww => {
      Object.keys(commentData).forEach((cmData) => {
        if (ww === cmData && commentData[cmData] && commentData[cmData].indexOf("-") !== 0) {
          ws[ww].c = [];
          ws[ww].c.hidden = true;
          if(ws){
            if(ws[ww]){
              if(ws[ww].t==="s" && ws[ww].v!=="-" &&  !isNaN(ws[ww].v)){
                ws[ww].t="n"
              }
            }            
          }
          //console.log('ws',ws[ww].v)
          ws[ww].c.push({ a: ws[ww].v, t: commentData[cmData], T: true });
        }
      })
    });
    return ws;
  }

  // Function for Search Clear
  const searchClear = () => {
    setSearchValue("");
    setPage(0);
    SetSelected([]);
    setIsSelected(false);
    setReload(!reload);
    dispatch(setFiterValue({
      JobName: '', company: [], asset: [], assetTypeIds: [], countryIds: [], regionIds: [],
      subRegionIds: [], commodityIds: [], statusIds: [], priorityIds: [], SearchText: ''
    }))
  };

  // Function for View icon click toggleDrawer is called
  const toggleDrawer = (open, mode, option) => (event, item) => {
    //console.log("item checker", item);
   
    if (mode === "View") {
      history.push({
        pathname: '/app/ownership-changes/view-data',
        state: item !== undefined && item?.jobAssetId !== null ? item.jobAssetId : selected,
       // isAllSelected:isAllSelected,
       //state:  selected,
        filter: filterData? filterData : "",
      },
      )
    }
    else if (mode === "Filter") {
      setState({ ...state, right: open });
    }
    else if (mode === "Filter") { setState({ ...state, right: open }); }
  };

  // Function for Getting Filter Dropdown List
  const getFilterData = async () => {
    
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`, {
        headers: {
          'Authorization': sessionStorage.getItem("token") ? "Bearer " + sessionStorage.getItem("token") : null
        }
      }
      );
      if (response.data.length === 0) {
        setDataForFilter([]);
      } else {
        setDataForFilter(response.data)
        response.data.companies.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => { item.checked = false; });
          }
        });
        response.data.location.regions.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => { item.checked = false; });
            if (item.children) {
              item.children.forEach((item, index) => { item.checked = false; });
            }
          }
        });
        setCompanyFilter(response.data.companies);
        setLocationFilter(response.data.location.regions);
      }
    } catch (error) {

    }
  };
  const resetTreeFilter = () => {
    companyFilter.forEach((item, index) => {
      item.checked = false
      if (item.children) {
        item.children.forEach((item, index) => { item.checked = false })
      }
    })
    locationFilter.forEach((item, index) => {
      item.checked = false;
      if (item.children) {
        item.children.forEach((item, index) => { item.checked = false })
        if (item.children) {
          item.children.forEach((item, index) => { item.checked = false })
        }
      }
    });
  }

  const AddCheckBoxValue=(value)=>{
    let tempStoredValue=[];
    tempStoredValue= checkBoxValue;
    if(tempStoredValue && value){
      if(value instanceof Array)
      {
      value.forEach((element)=>{
        let index= tempStoredValue.indexOf(element);
        if(index===-1){      
        tempStoredValue.push(element);
        }
      });
    }
    else if(typeof(value)==='number'){
      let index= tempStoredValue.indexOf(value);
        if(index===-1){      
        tempStoredValue.push(value);
        }
    }
    }
    setCheckBoxValue(tempStoredValue);
  }

const RemoveCheckBoxValue=(value)=>{
  let tempStoredValue=[];
  tempStoredValue= checkBoxValue;
if(tempStoredValue && value){
 let index= tempStoredValue.indexOf(value);
 if(index!==-1){
  tempStoredValue.splice(index, 1);
 }
}
setCheckBoxValue(tempStoredValue);
}

const RemoveAllCheckboxValue=()=>{
  setCheckBoxValue([]);
}
  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} className="page-title">
          <h2>Search & Export</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Ownership Changes</Typography>
            <Typography color="text.primary">Search & Export</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>

              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="flex-end" justifyContent="left" spacing={2}>
                  <Grid item xs={12} md={12} lg={5}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      size="small"
                      fullWidth
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => {
                        globalSearch(e.target.value)
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"><SearchIcon /></InputAdornment>
                        ),
                      }}
                      variant="standard"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility"><SearchIcon /></IconButton>
                        </InputAdornment>
                      }
                    />
                    {searchValue ? (
                      <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : ("")}
                  </Grid>
                  <Grid item xs={12} md={12} lg={7}>
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="flex-end">
                      <Grid item xs={"auto"}>
                        <Button variant="contained" color="secondary" disabled={viewDataButtonDisable}
                          onClick={toggleDrawer(true, "View", SetSelected)}
                        >
                          View Data
                        </Button>
                      </Grid>
                      <Grid item xs={"auto"}>
                        {/* {isExporting ? (<CircularProgress />) :
                          <Button variant="contained" color="secondary" startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                            disabled={viewDataButtonDisable} onClick={handleExportToExcel}>
                            Export To Excel
                          </Button>
                        } */}

                        {isExporting ?
                          <Button variant="contained" color="secondary" disabled style={{ width: 190 }}>
                            <CircularProgress size={25} />
                          </Button> :
                          <Button variant="contained" color="secondary" style={{ width: 190 }}
                            disabled={viewDataButtonDisable}
                            startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                            onClick={handleExportToExcel}>
                            Export To Excel
                          </Button>
                        }

                      </Grid>
                      <Grid item xs={"auto"}>
                        {(access.allowView) ? <>
                          <Button variant="contained" color="secondary"
                            onClick={toggleDrawer(true, "Filter")}>
                            Filter
                          </Button>
                        </> : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Search & Export Table */}
              <Grid item xs={12}>
                <CustomSearchAndExportDataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allRows ? allRows : []}
                  columns={gridcolumns}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  allJobAssetIds={allJobAssetIds}
                  setAllJobAssetIds={setAllJobAssetIds}
                  sorting={sorting}
                  setSorting={setSorting}
                  setviewDataButtonDisable={setviewDataButtonDisable}
                  selectableRows
                  isActionButton={{
                    ...(menuPermission.allowView && {
                      view: toggleDrawer(true, "View"),
                    }),
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
      {/* Search Export Filter Side Drawer */}
      <SideDrawer
        formComp={
          // Calling Search Export Filter
          <SearchExportFilter
            dataForFilter={dataForFilter}
            filterFinalData={filterFinalData}
            companyFilter={companyFilter}
            locationFilter={locationFilter}
            setFilterValues={setFilterValues}
            JobName={jobName}
            setJobName={setJobName}
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            userId={null}
            setFilterValue={setFilterValue}
            hideSideDrawer={() => {
              setState({ right: false });
              // getUserData();
            }}
            reload={reload}
            setReload={setReload}
            resetTreeFilter={resetTreeFilter}
            selectedJob={selectedJob}
            setSelectedJob={setSelectedJob}
            setLocationFilter={setLocationFilter}
            setCompanyFilter={setCompanyFilter}
            setDataForFilter={setDataForFilter}
          />
        }
        state={state}
        setState={setState}
        reload={reload}
        setReload={setReload}
        setFilterValue={setFilterValue}
      />
    </Box >
  );
};

export default OwnershipChangesSearchExport;