/* eslint-disable no-undef */
// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Grid, Link, TextField, Typography
} from "@mui/material";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// Importing Custom Components
import { lightBlue } from "@mui/material/colors";
import DatePicker from "../../../UIComponent/DatePicker";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

import {
  getJobID, getJobTypeDropDown, getCompanyDropDown, companiesForBrandnewJob
} from "../../../features/JobManagement/jobSlice";

// import "../../Resources/scss/layout.scss";
// import "react-toastify/dist/ReactToastify.css";
// import "../../../src/index.scss";
// import DuplicateJob from "./DuplicateJob";

// Importing Custom Components
import { ScrollingDialog } from "../../../UIComponent";
import DuplicateJobData from "./DuplicateJobsData";

// Importing useStyles
import useStyles from "./jobManagement.styles";

// Import Material Icons
import ClearIcon from "@mui/icons-material/Clear";
import CommonService from "../../../Services/CommonService";
import { strUndefined } from "@microsoft/applicationinsights-core-js";

const CreateJob = (props) => {
 
  return (
    // Layout for Create Job
    // <Box className={classes.popupArea}>

    //   {/* Title for Side Drawer */}
    //   <Box className={classes.popupHeading}>
    //     <Typography id="modal-modal-title" variant="h5">
    //       Create Jobs
    //     </Typography>
    //   </Box>
{/*       Create Form
 <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            readOnly={true}
            size="small"
            fullWidth
            label="Job ID"
            placeholder="Job ID"
            // value={}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            variant="standard"
            size="small"
            fullWidth
            label="Job Name"
            placeholder="JobName"
            // onChange={(e) =>
            //   setFinalData({ ...finalData, jobName: e.target.value })
            // }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            variant="standard"
            readOnly
            size="small"
            fullWidth
            label="Job Type"
            placeholder="Job Type"
            // value={jobType}
          />
        </Grid>
        <Grid item xs={12}>
          {props.newJob ? (
            <Autocomplete
              clearIcon={<ClearIcon fontSize="small" onClick={clearFunction} />}
              size="small"
              id="size-small-standard size-small-standard"
              options={allCompsFornewJOb}
              // onChange={(e, value) => {
                
              // }}
              getOptionLabel={(option) => option.companyName}
              // renderOption={(props, option) => {
              //   return (

              //   );
              // }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Company"
                  placeholder="Company Name"
                  required={true}

                />
              )}
            />
          ) : (
            <TextField
            required={true}
              variant="standard"
              readOnly={true}
              size="small"
              fullWidth
              label="Company Name"
              placeholder="Company Name"
              value={comp}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {props.isAssetSelected ? (
            <TextField
              variant="standard"
              readOnly={true}
              size="small"
              fullWidth
              label="Asset"
              placeholder="Asset"
              value={currentAsset}
            />
          ) : (
            <Autocomplete
              size="small"
              id="size-small-standard size-small-standard"
              clearIcon={<ClearIcon fontSize="small" onClick={clearFunction} />}
              options={currentCompany}
              getOptionLabel={(option) => option.asset}
              onChange={(e, value) => {
                if (value) {
                  setFinalAssetDetailObject({
                    ...finalAssetDetailObject,
                    assetId: value.assetId,
                    isCRUAsset: value.isCRUAsset,
                    companyId: value.companyId,
                  });
                  let tempInitialData = [
                    {
                      companyId: finalAssetDetailObject.companyId,
                      isCRUCompany: finalAssetDetailObject.isCRUCompany,
                      assetId: value.assetId,
                      isCRUAsset: value.isCRUAsset,
                    },
                  ];
                  setTriggerPriority(tempInitialData);
                  setTempAsset(value.asset);
                  setCurrentAsset(value.asset);
                  setCurrentAssetID(value.assetId);
                  setCurrentAssetType(value.assetType);
                  setCountryName(value.country);
                  setRegionName(value.region);
                  setSubregion(value.subRegion);
                  setCurrentCommodity([value.commodity]);
                }
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.asset}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={tempAsset}
                  variant="standard"
                  label="Asset"
                  placeholder="Asset"
                  required={true}

                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            required={true}
            variant="standard"
            readOnly={true}
            size="small"
            fullWidth
            label="Asset Type"
            placeholder="Asset Type"
            value={currentassetType}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            readOnly={true}
            size="small"
            fullWidth
            label="Country"
            placeholder="Country"
            value={countryName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            readOnly={true}
            size="small"
            fullWidth
            label="Region"
            placeholder="Region"
            value={regionName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            readOnly={true}
            size="small"
            fullWidth
            label="Sub Region"
            placeholder="Sub Region"
            value={currentSubregion}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            readOnly
            multiple
            freeSolo
            fullWidth
            size="small"
            id="size-small-standard size-small-standard"
            variant="standard"
            options={currentCommodity}
            getOptionLabel={(option) => option}
            value={currentCommodity}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="standard"
                  id="size-small-standard size-small-standard"
                  label={option}
                  size="Commoditites"
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                id="size-small-standard size-small-standard"
                label="Commodities"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            labelTitle="Job Start Date"
            variant="standard"
            past={true}
             disabled={enableStartDate}
            // defaultDate={currentPriority.startDate ? currentPriority.startDate : finalAssetDetailObject.startDate}
            defaultDate={currentPriority.startDate ? currentPriority.startDate : null}
            onChangeDate={(e) => {
              
               let selectedDate = new Date(e);
               GetNoOfDays(selectedDate);
              let tempStartDate=CommonService.GetDateAsStringWithDash(selectedDate)
              setFinalAssetDetailObject({
                  ...finalAssetDetailObject,
                  startDate: tempStartDate
                });

            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            labelTitle="TAT"
            variant="standard"
            past={true}
            required={true}
            defaultDate={currentPriority.priorityDate ? currentPriority.priorityDate : null}
            onChangeDate={(e) => {

              let selectedDate = new Date(e);
              // console.log("selectedDate", selectedDate)
              //     console.log("currentPriority.priorityDate", currentPriority.priorityDate);
              setFinalAssetDetailObject({
                ...finalAssetDetailObject,
                tat: selectedDate,
              });

              setRemainingDays(CommonService.getDayDifference(selectedDate))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="standard"
            readOnly={true}
            size="small"
            fullWidth
            disabled={true}
            label="Remaining Days"
            placeholder="Remaining Days"
            value={remainingDays}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            id="size-small-standard size-small-standard"
            options={priority}
            value={tempPriority}
            getOptionLabel={(option) => option.priorityName}
            onChange={(e, value) => {
              setFinalAssetDetailObject({
                ...finalAssetDetailObject,
                priorityId: value.id,
              });
              setTempPriority(value)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Priority"
                placeholder="Priority"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            size="small"
            fullWidth
            multiline
            maxRows={4}
            label="Job Instructions"
            placeholder="Job Instructions"
            onChange={(e) =>
              setFinalData({ ...finalData, instructions: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              {/* <Button variant="contained" className="rounded-border"
                color="accent5" onClick={submitForm}>
                Save
              </Button> 
              <LoadingButton
                buttonName="Save"
                clickHandler={submitForm}
                isLoading={loading} />
            </Grid>
            <Grid item xs={'auto'}>
              <Button variant="contained" className="rounded-border" color="accent3"
                onClick={() => {
                  props.setPageRefresh(true);
                  setCurrentCompany([]);
                  props.setState({ ...props.state, right: false });
                  setCurrentAsset("");
                  setCurrentAssetType("");
                  setCountryName("");
                  setRegionName("");
                  setSubregion("");
                  setCurrentCommodity([]);
                }}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Dialog for Confirmation  */}
  //     <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title"
  //       aria-describedby="alert-dialog-description">
  //       <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
  //       <DialogContent>
  //         <DialogContentText id="alert-dialog-description">
  //           Job already exists for the selected asset(s) or company(s). click <Link underline="hover" onClick={showDuplicate} color={darkBlue}>
  //             here</Link> to view the duplicate jobs.
  //         </DialogContentText>
  //       </DialogContent>
  //       <DialogActions>
  //         <Grid container spacing={2} justifyContent="right">
  //           <Grid item xs={'auto'}>
  //             <Button value={false} onClick={() => {
  //               setOpen(false);
  //               props.setState({ ...props.state, right: false });
  //               props.setPageRefresh(true);
  //             }}>No
  //             </Button>
  //           </Grid>
  //           <Grid item xs={'auto'}>
  //             <Button value={true} onClick={handleClose} autoFocus>
  //               Yes
  //             </Button>
  //           </Grid>
  //         </Grid>
  //       </DialogActions>
  //     </Dialog>


  //     <ScrollingDialog
  //       open={openDuplicateDialog}
  //       setOpen={setOpenDuplicateDialog}
  //       scroll={scroll}
  //       setScroll={setScroll}
  //       dialogClass="darkTitle"
  //       dialogTitle="Duplicate Jobs"
  //       dialogContent={
  //         <Box className={classes.addBorder}>
  //           <DuplicateJobData
  //             duplicateJobs={duplicateJobs}
  //             companyAssets={companyAssets}
  //             totalDuplicateJobs={totalDuplicateJobs}
  //             createManual={createManual}
  //             selectedCompnies={companyAssets}
  //             startDate={undefined}
  //             endDate={undefined}
  //           />
  //         </Box>
  //       }
  //     />
  //   </Box>
   ); 
};

export default CreateJob;
