// Default React, useState and Material Components
import React, { useState, useEffect } from "react";
import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const CountrySideBar = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [editView, setEditView] = useState({});
  const [loading, setLoading] = useState(false);
  const [allRegion, setAllRegion] = useState([]);
  const [allsubRegion, setAllsubRegion] = useState([]);
  const [countryData, setCountryData] = useState({ countryName: null, regionId: null, subRegionId: null, });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  useEffect(() => {
    if (Object.values(props.formData).length > 0) { setEditView(props.formData); }
  }, [props.formData]);
  useEffect(() => {
    if (allRegion.length < 1) { getRegionDropDownFilter() }
  }, [allRegion])
  // Declaration of React Hooks End Here

 // This function is used to get the region dropdown filter
const getRegionDropDownFilter = () => {
  // Making an axios call to get the data from the server
  axios.get(process.env.React_App_baseUrl + 'DropDown/GetRegionDropDownFilter', {
    headers: {
      // Setting the authorization header with the token stored in sessionStorage
      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
  }).then((res) => {
    if (res.status === 200) {
      // Setting the allRegion state with the response data
      setAllRegion(res.data)
      // If the crudState is Edit, then loop through the response data and get the subregion dropdown
      if (props.crudState === 'Edit') {
        res.data.forEach(rs => {
          if (rs.id === props.formData.regionId) {
            getSubRegionDropDown(rs.id, rs)
          }
        });
      }
    } else {
      // Showing error message if something went wrong
      toasterError('Something went wrong');
    }
  }).catch((err) => {
    // Handling the error and showing the error message
    ErroHandling(err, function (res) {
      toasterError(res)
    })
  })
}

  // This function is used to get the subregion dropdown based on the regionId
const getSubRegionDropDown = (id, regionData) => {
  // Making an axios call to the API endpoint with the regionId and token in the header
  axios.get(process.env.React_App_baseUrl + 'DropDown/GetSubRegionDropDown', {
    headers: {
      regionId: id, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
  })
  // If the response status is 200, set the allsubRegion data and check if the crudState is Edit and regionData is present
  .then((res) => {
    if (res.status === 200) {
      setAllsubRegion(res.data)
      if (props.crudState === 'Edit' && regionData && regionData.id || regionData !== 'dropdown') {
        // Find the subReg from the response data
        let subReg = res.data.find(function (rs) {
          return rs.id === props.formData.subRegionId;
        });
        // If subReg is found, set the countryData with regionData and subReg
        if (subReg) {
          setCountryData({ ...countryData, regionId: regionData, subRegionId: subReg, })
        } else {
          setCountryData({ ...countryData, regionId: regionData, subRegionId: null, })
        }
      }
    } else { toasterError('Something went wrong') }
  })
  // Catch any errors and handle them
  .catch((err) => {
    ErroHandling(err, function (res) {
      toasterError(res)
    })
  })
}

// handleSave function is used to save the country data
const handleSave = () => {
  // set loading to true while saving the data
    setLoading(true)
    // validate the form before saving the data
    if (validateForm(countryData)) {
   // make a post request to save the data   
      axios.post(process.env.React_App_baseUrl + 'managedata/SaveCountry', null, {
        headers: {
           // set authorization token
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          , countryName: countryData.countryName, regionId: countryData.regionId.id, subRegionId: countryData.subRegionId.id
        }
      }).then((res) => {
        // check if the response status is 200
        if (res.status === 200) {
         // display success message 
          toasterSuccess('Country created successfully');
          props.hideSideDrawer()
          setLoading(false)
        } else {
          // display error message
          toasterError('Something went wrong');
          setLoading(false)
        }
      }).catch((err) => {
         // handle errors
        ErroHandling(err, function (res) {
          toasterError(res)
        });
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  };

  // Function for Update Country
  const onUpdateApi = () => {
     // Object to validate data
    let validateData = {
      countryName: editView.countryName,
      regionId: countryData.regionId,
      subRegionId: countryData.subRegionId,
    }
    // Set loading to true
    setLoading(true)
    if (validateForm(validateData)) {
      // Make a post request to update the country
      axios.post(process.env.React_App_baseUrl + 'managedata/UpdateCountry', null, {
        headers: {
          countryId: editView.countryId,
          countryName: editView.countryName,
          regionId: countryData.regionId.id,
          subRegionId: countryData.subRegionId.id,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

        }
      }).then((res) => {
        // If response status is 200, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess('Country updated successfully')
          props.hideSideDrawer()
          setLoading(false)
        } else {
          // Else show error message
          toasterError('Something went wrong')
          setLoading(false)
        }
      }).catch((err) => {
        // Handle errors
        ErroHandling(err, function (res) {
          toasterError(res)
        });
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }

  // Function for Validations
  const validateForm = (data) => {
    if (!data.countryName && !data.regionId && !data.subRegionId) {
      toasterError('Please fill all the fields');
      return false
    } else if (!data.countryName) {
      toasterError('Please fill the country name');
      return false
    } else if (!data.regionId) {
      toasterError('Please fill the region ');
      return false
    } else if (!data.subRegionId) {
      toasterError('Please fill the sub-region ');
      return false
    } else {
      return true
    }
  }

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Country
        </Typography>
      </Box>

      {/* Add & Edit Country Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            required
            variant="standard"
            defaultValue={props.crudState === "Edit" ? editView.countryName : null}
            fullWidth
            label="Country"
            InputProps={{ shrink: true, }}
            value={props.crudState === "Add" ? null : editView.countryName}
            onChange={(e) => {
              if (props.crudState === "Edit") {
                setEditView({
                  ...editView,
                  countryName: e.target.value,
                });
                return;
              }
              setCountryData({ ...countryData, countryName: e.target.value });
            }}
            placeholder="Country"
            InputLabelProps={props.crudState === "Edit" ? { shrink: true } : null}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            key="Region"
            id="combo-box-demo"
            getOptionLabel={(option) => option.regionName}
            options={allRegion}
            value={countryData.regionId}
            onChange={(e, value) => {
              if (value) {
                getSubRegionDropDown(value.id, 'dropdown')
                setCountryData({ ...countryData, regionId: value, subRegionId: null });
              } else {
                setAllsubRegion([])
                setCountryData({ ...countryData, regionId: null, subRegionId: null });
              }
            }}
            fullWidth
            renderInput={(params) => (<TextField {...params} key="Region" label="Region" variant="standard" required />)}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            key="Sub-Region"
            id="combo-box-demo"
            getOptionLabel={(option) => option.subRegionName}
            options={allsubRegion}
            value={countryData.subRegionId}
            onChange={(e, value) => {
              if (value) {
                setCountryData({ ...countryData, subRegionId: value });
              } else {
                setCountryData({ ...countryData, subRegionId: null });
              }
            }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} key="Sub-Region" label="Sub-Region" variant="standard" required />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={'auto'}>
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Edit" ? onUpdateApi : handleSave}
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant="contained" color="accent3" onClick={() => props.setState({ ...props.state, right: false })}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CountrySideBar;