// Default React, useState and Material Components
import React, { useState } from "react";
import { Button, Box, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddEditStockExchange = (props) => {
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [stockExchange, setStockExchange] = useState({
    id: 0, name: "", symbol: "", active: true,
    createdOn: "2022-09-05T09:18:29.865Z",
  });
  const [addStockExchangeDrawer, setAddStockExchangeDrawer] = useState(props.addStockExchangeDrawer);
  // Declaration of States and Component Variables End Here

  React.useEffect(() => {
    if (props.crudState === "Edit" || props.crudState === "View") {
      let data = {
        id: props.formData.id,
        name: props.formData.name,
        symbol: props.formData.symbol,
        active: props.formData.active,
        createdOn: props.formData.createdOn,
      };
      setStockExchange(data);
    }
  }, [props]);

  // Function for Validation
  const validation = (stockExchange) => {
    if (stockExchange.name.trim() == "" && stockExchange.symbol.trim() == "") {
      toasterError("Please enter required fields");
      return false;
    }
    if (stockExchange.name.trim() == "") {
      toasterError("Please enter Stock Exhange Name");
      return false;
    }
    if (stockExchange.name.length > 100) {
      toasterError("Please enter Stock Exchange less than 150 characters");
      return false;
    }
    if (stockExchange.symbol.trim() == "") {
      toasterError("Please enter Stock Exchange Code");
      return false;
    }
    if (stockExchange.symbol.length > 100) {
      toasterError("Please enter Stock Exchange Code less than 100 characters");
      return false;
    }
    return true;
  }

 // Function to save stock exchange details
const saveStockExchange = () => {
  // Set loading to true
  setLoading(true);
  // Validate the stock exchange data
  if (validation(stockExchange)) {
    // Call API service to save stock exchange data
    ApiService.create("stock/SaveStockExchange", stockExchange)
      .then((res) => {
        // If response status is 200, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("Stock Exchange details added successfully!");
          if (addStockExchangeDrawer) {
            setLoading(false);
            if (props.hideSideDrawer != null) {
              props.hideSideDrawer();
            }
          } else {
            setLoading(false);
            if (props.closeStockExchange != null) {
              props.closeStockExchange();
            }
            if (props.hideSideDrawer != null) {
              props.hideSideDrawer();
            }
          }
        }
      })
      // Catch any errors and handle them
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        }
        )
        setLoading(false);
        if (addStockExchangeDrawer) {
          if (props.hideSideDrawer != null) {
            props.hideSideDrawer();
          }
        } else {
          setLoading(false);
          if (props.closeStockExchange != null) {
            props.closeStockExchange();
          }
        }
      });
  } else {
    // Set loading to false if validation fails
    setLoading(false);
  }
}

 // This function updates the stock exchange details
const updateStockExchange = () => {
  // Set loading to true while data is being fetched
  setLoading(true);
  // Create a validData variable and assign it the value of stockExchange
  let validData = stockExchange;
  // Assign the id from props.formData to validData
  validData.id = props.formData.id;
  // If validation passes, make an API call to updatepatch
  if (validation(stockExchange)) {
    ApiService.updatepatch("stock/UpdateStockExchange","",validData)
      .then((res) => {
        // If the response status is 200, display success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("Stock Exchange details updated successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // If there is an error, handle it and display an error message
        ErroHandling(err, function (res) {
          toasterError(res)
        })
        setLoading(false);
      });
  } else { 
    // If validation fails, set loading to false
    setLoading(false); 
  }
}


  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Stock Exchange
        </Typography>
      </Box>

      {/* Add & Edit Top Producers Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            required
            fullWidth
            multiline
            maxRows={4}
            id="standard-basic"
            name="symbol"
            size="small"
            label="Stock Exchange Code"
            placeholder="Stock Exchange Code"
            variant="standard"
            inputProps={{ maxLength: 100 }}
            value={props.crudState === "Add" ? null : props.formData.symbol}
            onChange={(e) => {
              props.setFormData({ ...props.formData, symbol: e.target.value });
              setStockExchange({ ...stockExchange, symbol: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{
              readOnly: props.crudState === "View" && true,
            }}
            required
            fullWidth
            multiline
            maxRows={4}
            id="standard-basic"
            name="name"
            size="small"
            label="Stock Exchange Name"
            placeholder="Stock Exchange Name"
            variant="standard"
            inputProps={{ maxLength: 150 }}
            value={props.crudState === "Add" ? null : props.formData.name}
            onChange={(e) => {
              props.setFormData({ ...props.formData, name: e.target.value });
              setStockExchange({ ...stockExchange, name: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>

        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={12} sm={'auto'}>
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Add" ? saveStockExchange : updateStockExchange}
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <Button
                  variant="contained"
                  className="rounded-border"
                  color="accent3"
                  onClick={() => {
                    if (addStockExchangeDrawer) {
                      props.setState({ ...props.state, right: false })
                    } else {
                      setAddStockExchangeDrawer(true);
                      if (props.closeStockExchange != null) { props.closeStockExchange(); }
                    }
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddEditStockExchange;