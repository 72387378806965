/* eslint-disable no-undef */
// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Autocomplete, Box, Button, Checkbox, Chip, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, Link, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, CircularProgress
} from "@mui/material";
import axios from 'axios';
import { lightBlue } from "@mui/material/colors";
import { toast } from "react-toastify";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// Importing Custom Components
import CustomAutoComplete from '../../../UIComponent/CustomAutoComplete'
import AlertDialog from "../../../UIComponent/AlertDialog"
import DatePicker from "../../../UIComponent/DatePicker"
import { ScrollingDialog } from "../../../UIComponent";
import DuplicateJobData from "./DuplicateJobsData";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles
import useStyles from "./jobManagement.styles";

// Import Fluent UI Icons
import { AcceptIcon, CancelIcon, ClearIcon, DeleteIcon, EditIcon, SearchIcon } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../Services/CommonService";

const CreateMultipleJob = (props) => {
  // Declaration of States and Component Variables Start Here
  let [formData, setFormData] = useState({ "jobtypeId": 1, "checkValue": false, checkJobStartValue: false })
  let [priority, setpriotity] = useState([])
  let [tableRow, setTableRow] = useState()
  let [jobtype, setJobType] = useState([])
  const [searchValue, setSearchValue] = React.useState()
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [finalData, setFinalData] = useState()
  const [duplicateJobs, setDuplicateJobs] = useState([]);
  const [totalDuplicateJobs, setTotalDuplicateJobs] = useState(0);
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
  const [companyAssets, setCompanyAssets] = useState([]);
  const [scroll, setScroll] = useState("paper");
  const [createManual, setCreateManual] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const darkBlue = lightBlue[600];
  const openAlertSaveDialog = () => { setOpenSaveConfirmation(true); }
  const handleADClose = () => { setOpenSaveConfirmation(false); }
  const classes = useStyles();
  const [btnLoadinng, setBtnLoadinng] = useState(false);

  var valueData = { jobId: "000" }
  // Declaration of States and Component Variables End Here

  function createPirorityData(label, id) { return { label, id }; }

  const handleKeyUp = (event) => {
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== 'BUTTON') {
      getAssetDetails()
    }
  }

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    getAssetDetails()
    axios.get(process.env.React_App_baseUrl + "job/GetJobNo", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        // console.log(response.data);
        setFormData({ ...formData, jobId: response.data })
      })
      .catch((err) => {
        if (err.res.status === 400) {
          toasterError(err.res.data);

        }

      });
    axios.get(process.env.React_App_baseUrl + "job/GetJobTypeDropDown", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        const rows = response.data.map(row =>
          createPirorityData(row.name, row.id)
        )
        setJobType(rows)
        // console.log(response.data);
      })
      .catch((err) => {
        if (err.response.status === 400) {

          toasterError(err.response.data);

        }

      });
    axios.get(process.env.React_App_baseUrl + "DropDown/GetPriorityDropDownFilter", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        // console.log(response.data);
        const rows = response.data.map(row =>
          createPirorityData(row.priorityName, row.id)
        )
        setpriotity(rows)
      })
      .catch((err) => {

        if (err.response.status === 400) {

          toasterError(err.response.data);

        }

      });
  }, [])
  // Declaration of React Hooks End Here

  // Function for Search Data
  const globalSearch = (value) => { setSearchValue(value) }
  const searchClear = () => {
    setSearchValue('')
    axios.post(process.env.React_App_baseUrl + "job/GetCompanyAssetDetail", props.selectedValue, {
      headers: {
        searchText: '', 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },

    })
      .then(function (response) {
        setFormData({ ...formData, checkValue: false, checkJobStartValue: false })
        setTableRow(response.data)
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toasterError(err.response.data);
        }

      });
  }

  // Function for Getting Asset Data
  const getAssetDetails = () => {
    axios.post(process.env.React_App_baseUrl + "job/GetCompanyAssetDetail", props.selectedValue, {
      headers: {
        searchText: searchValue ? encodeURIComponent(searchValue) : '',
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },


    })
      .then(function (response) {
        let ob = response.data.map(el => {
          return { ...el, tat: el.tat.split("-").reverse().join("-") }
        })
        setTableRow(ob)
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toasterError(err.response.data);

        }

      });
  }

  // Function for Validation
  const CommonTat = (e, value) => {
    if (formData.tat && formData.priorityId) {

      let remainingDaysTemp = CommonService.getBusinessDatesCount(new Date(formData.tat))
      setFormData({ ...formData, checkValue: value, checkJobStartValue: false })
      if (value) {
        const addData = tableRow.map(object => {
          return { ...object, remainingDays: remainingDaysTemp, tat: formData.tat, priorityName: formData.priorityName, priorityId: formData.priorityId };
        });
        setTableRow(addData)
      }
    } else {
      toasterError("Please select TAT and Priority");
    }
  }

  const CommonJobStartDate = (e, value) => {
    if (formData.startDate) {

      //let remainingDaysTemp=CommonService.getDayDifference(new Date(formData.tat))

      if (value) {
        setFormData({ ...formData, checkJobStartValue: value })
        let updatedRow = [];
        let promise = Promise.all(tableRow.map(async (element) => {
          if (element.companyId > 0 && element.assetId > 0) {
            let assetDetails = {
              companyId: element.companyId,
              assetId: element.assetId,
              isCRUCompany: element.isCRUCompany,
              isCRUAsset: element.isCRUAsset
            }
            await AsyncGetNoOfDays(assetDetails).then((res) => {
              let tempStartDate = new Date(formData.startDate);
              //tempStartDate.setDate(tempStartDate.getDate() + res.data)
              let g = CommonService.addWorkingDays(tempStartDate, res.data)
              let newTatDate = CommonService.GetDateAsStringWithDash(g)
              let remainingDays = CommonService.getBusinessDatesCount(g)
              updatedRow.push({ ...element, startDate: formData.startDate, remainingDays: remainingDays, tat: newTatDate })
            })
          }
        }))
        promise.then(() => {
          // console.log("test",updatedRow)
          if (updatedRow.length > 0) {
            setTableRow(updatedRow)
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    } else {
      toasterError("Please select Job Start Date");
    }
  }

  // Function for Save Job
  const saveJob = (sendData) => {
    setLoading(true)
    axios.post(process.env.React_App_baseUrl + "job/SaveJob", sendData, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        props.setOpen(false);
        toasterSuccess("Job saved successfully");
        props.setPageRefresh(true);
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false);
        if (err.res.status === 400) {
          toasterError(err.res.data);
          setLoading(false);
        }
      });
  }
  const showDuplicate = (e) => {
    e.stopPropagation();
    setOpenDuplicateDialog(true);
  }

  const save = () => {
    setLoading(true);
    if (formData.jobtypeId && formData.jobname) {
      let validation = false
      var validationloop = new Promise((resolve, reject) => {
        tableRow.forEach((value, index, array) => {
          if (!(value.tat && value.priorityId)) {
            validation = true
          }
          if (index === array.length - 1) resolve();
        });
        reject();
      });
      validationloop.then(() => {
        var sendData;
        if (!validation) {
          var a = []
          let companyAssetsTemp = []
          tableRow.forEach((el) => {
            a.push({ tat: el.tat, startDate: el.startDate, priorityId: el.priorityId, companyId: el.companyId, isCRUcompany: el.isCRUCompany, assetId: el.assetId, isCRUAsset: el.isCRUAsset })
            companyAssetsTemp.push({ companyId: el.companyId, isCRUcompany: el.isCRUCompany, assetId: el.assetId, isCRUAsset: el.isCRUAsset })
          })
          setCompanyAssets(companyAssetsTemp)
          sendData = {
            "jobName": formData.jobname,
            "jobTypeId": formData.jobtypeId,
            "instructions": formData.remarks,
            "companyAssetDetails": a
          }
          axios.post(process.env.React_App_baseUrl + "job/CheckJobDuplication?pageNumber=0&pageSize=10", companyAssetsTemp, {
            headers: {
              startDate: '', endDate: '', manualJobCreation: createManual,
              'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
            }


          })
            .then(function (response) {
              if (response.data.data.length > 0) {
                setDuplicateJobs(response.data.data);
                setTotalDuplicateJobs(response.data.totalCount);
                openAlertSaveDialog()
                setFinalData(sendData)
                setLoading(false);
              }
              else {
                saveJob(sendData)
                setLoading(false);
              }
            })
            .catch((err) => {
              setLoading(false);
              if (err.res.status === 400) {
                toasterError(err.res.data);
              }
            });
        } else {
          toasterError("Please enter all required fields");
          setLoading(false);
        }
      }, () => {
        toasterError("Please enter all required fields");
      });

    } else {
      toasterError("Please enter all required fields")
      setLoading(false);
    }
  }

  const handleDateChange = (e) => {
    let selectedDate = new Date(e);
    let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
    setFormData({ ...formData, tat: date, checkValue: false, checkJobStartValue: false })
  }
  const handleJobStartDateChange = (e) => {
    if (e !== null && new Date(e).toDateString() !== 'Invalid Date') {
      let selectedDate = new Date(e);
      let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
      setFormData({ ...formData, startDate: date, checkValue: false, checkJobStartValue: false })
      // GetNoOfDays(selectedDate);
    } else {
      setFormData({ ...formData, startDate: undefined, checkValue: false, checkJobStartValue: false })
    }

  }
  const truncateString = (string, length) => {
    return (string.length > length ? string.substring(0, length) + "..." : string);
  }
  const GetNoOfDays = (finalAssetDetailObject, startDate) => {

    if (finalAssetDetailObject.companyId > 0 && finalAssetDetailObject.assetId > 0) {
      axios.get(
        `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
        headers: {
          CompanyId: finalAssetDetailObject.companyId,
          AssetId: finalAssetDetailObject.assetId,
          IsCRUCompany: finalAssetDetailObject.isCRUCompany,
          IsCRUAsset: finalAssetDetailObject.isCRUAsset,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }).then((res) => {
        if (res.status === 200) {

          let tempStartDate = new Date(startDate);
          //tempStartDate.setDate(tempStartDate.getDate() + res.data)
          let g = CommonService.addWorkingDays(tempStartDate, res.data)
          let newTatDate = CommonService.GetDateAsStringWithDash(g)
          console.log(newTatDate)
          setCurrentPriority({
            ...currentPriority,
            priorityDate: newTatDate,
          });
          setFinalAssetDetailObject({
            ...finalAssetDetailObject,
            tat: newTatDate
          });
          setRemainingDays(CommonService.getBusinessDatesCount(g))
        } else { toasterError(`${res.data}`); setLoading(false); }
      })
        .catch((err) => {
          if (err.res.status === 400) {
            toasterError(err.res.data);
          }
        });
    }
  }
  const AsyncGetNoOfDays = async (finalAssetDetailObject) => {
    return axios.get(
      `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
      headers: {
        CompanyId: finalAssetDetailObject.companyId,
        AssetId: finalAssetDetailObject.assetId,
        IsCRUCompany: finalAssetDetailObject.isCRUCompany,
        IsCRUAsset: finalAssetDetailObject.isCRUAsset,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })

  }


  return (
    // Create Multiple Jobs
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={3} md={4} lg={1}>
            <TextField id="standard-basic"
              label="Job ID"
              variant="standard"
              fullWidth
              readOnly
              defaultValue={valueData.jobId}
              value={formData?.jobId}
              InputProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={9} lg={8}>
            <TextField id="standard-basic" label="Job Name" variant="standard" onChange={(e) => {
              setFormData({ ...formData, jobname: e.target.value })
            }} fullWidth required={true} />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <CustomAutoComplete
              options={jobtype}
              fullWidth
              disabled={true}
              label="Job Type"
              optionLabelKey="label"
              optionValueKey="id"
              onChange={((value) => { setFormData({ ...formData, jobtypeId: value.id }) })}
              defaultValueKey={["id", 'label']}
              defaultValue={[1, "Company Data"]}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={12} xl={4}>
            <TextField
              fullWidth
              id="standard-multiline-static"
              label="Job Instructions"
              multiline
              rows={2}
              onChange={(e) => { setFormData({ ...formData, remarks: e.target.value }) }}
              variant="standard" />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={4} xl={3} sx={{ mt: 3 }}>
            <DatePicker
              labelTitle="Job Start Date(DD-MM-YYYY)"
              variant="standard"
              past={true}
              defaultDate={null}
              onChangeDate={(e) => { handleJobStartDateChange(e) }} />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={4} xl={3} sx={{ mt: 3 }}>
            <DatePicker
              labelTitle="TAT(DD-MM-YYYY)"
              variant="standard"
              past={true}
              defaultDate={null}
              onChangeDate={(e) => { handleDateChange(e) }} />
          </Grid>
          <Grid item xs={12} sm={2} md={3} lg={4} xl={2} sx={{ mt: 3 }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"

              options={priority}
              onChange={(e, value) => {
                if (value) {
                  setFormData({ ...formData, priorityName: value.label, priorityId: value.id, checkValue: false, checkJobStartValue: false })
                } else {
                  setFormData({ ...formData, priorityName: "", priorityId: 0, checkValue: false, checkJobStartValue: false })
                }

              }}
              renderInput={(params) => <TextField {...params} label="Priority" variant="standard" />}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={4} xl={3} >
            <FormControlLabel control={<Checkbox checked={formData.checkJobStartValue} onChange={(e, value) => CommonJobStartDate(e, value)} />} label="Apply Common Job Start Date" />
          </Grid>
          <Grid item xs={12} md={5} lg={4} xl={3} >
            <FormControlLabel control={<Checkbox checked={formData.checkValue} onChange={(e, value) => CommonTat(e, value)} />} label="Apply Common TAT and Priority" />
          </Grid>

          <Grid item xs={12}>
            <Box className={classes.addBorder}>
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      size="small"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"><SearchIcon /></InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}><ClearIcon style={{ fontSize: 10 }} /></IconButton> : ''}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TableContainer sx={{ minHeight: 150, maxHeight: "calc(100vh - 480px)" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 300, width: "25%" }}>Company</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 200, width: 200 }} align="left">Asset</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 200, width: 200 }} align="center">Variable Count</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 350, width: 350 }} align="left">Commodity</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 150, width: 150 }} align="center">Job Start Date</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 150, width: 150 }} align="center">TAT</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 150, width: 150 }} align="center">Remaining Days</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 150, width: 150 }} align="center">Priority</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 150, width: 150 }} align="left">Country</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 150, width: 150 }} align="left">Region</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 150, width: 150 }} align="left">Sub Region</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 100, width: 100 }} align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableRow?.map((row) => (
                        <EditableTable row={row} setTableRow={setTableRow} tableRow={tableRow} priority={priority} ></EditableTable>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>

            <Grid container spacing={2} justifyContent="right">

              <Grid item xs={'auto'}>
                <Button variant="contained" color="accent3"
                  onClick={() => { props.setOpen(false); props.setPageRefresh(true) }}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={'auto'}>
                <LoadingButton
                  buttonName="Save"
                  clickHandler={save}
                  isLoading={loading}
                  searchValue={searchValue}
                />
                {/* <Button variant="contained" color="accent5" onClick={save}>
                  Save
                </Button> */}
              </Grid>
            </Grid>
          </Grid>

        </Grid>

      </Box >

      {/* Alert Dialog - Checking Duplicate */}
      <Dialog
        open={openSaveConfirmation}
        onClose={handleADClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Job already exists for the selected asset(s) or company(s). click <Link underline="hover" onClick={showDuplicate} color={darkBlue}>
              here</Link> to view the duplicate jobs.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button value={false} onClick={handleADClose}>
            No
          </Button>
          <Button onClick={() => {
            setBtnLoadinng(true);
            axios.post(process.env.React_App_baseUrl + "job/SaveJob", finalData, {
              headers: {
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
              }
            })
              .then(function (response) {
                props.setOpen(false);
                handleADClose()
                toasterSuccess("Job saved successfully");
                props.setPageRefresh(true);
                setBtnLoadinng(false);
              })
              .catch((err) => {
                setBtnLoadinng(false);
                if (err.res.status === 400) {
                  toasterError(err.res.data);
                }
              });

          }} autoFocus value={true}>
            {btnLoadinng ? (<CircularProgress size={25} />) : ("Yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <ScrollingDialog
        open={openDuplicateDialog}
        setOpen={setOpenDuplicateDialog}
        scroll={scroll}
        setScroll={setScroll}
        dialogClass="darkTitle"
        dialogTitle="Duplicate Jobs"
        dialogContent={
          <Box className={classes.addBorder}>
            <DuplicateJobData
              duplicateJobs={duplicateJobs}
              companyAssets={companyAssets}
              totalDuplicateJobs={totalDuplicateJobs}
              createManual={createManual}
              selectedCompnies={companyAssets}
              startDate={undefined}
              endDate={undefined}
            />
          </Box>
        }
      />
    </Box >

  );
};

export default CreateMultipleJob;


const EditableTable = (props) => {
  // Declaration of States and Component Variables Start Here
  let [edit, setEdit] = useState(false);
  let [value, setValue] = useState();
  const classes = useStyles()
  const [openConfirmation, setConfirmation] = useState(false);
  const handleADClose = () => { setConfirmation(false); }
  const editData = () => { setEdit(!edit) }
  const openAlertDialog = () => { setConfirmation(true); }
  const DeleteData = () => {
    let arr = [...props.tableRow];
    let index = arr.indexOf(props.row)
    arr.splice(index, 1)
    props.setTableRow(arr)
    handleADClose()
  }
  const CancelData = () => { setValue(); setEdit(!edit) }
  const [reload, setReload] = useState(false);
  const saveData = () => {

    props.row.priorityName = value?.priorityName ? value.priorityName : props.row.priorityName
    props.row.priorityId = value?.priorityId ? value.priorityId : props.row.priorityId
    props.row.tat = value?.tat ? value.tat : props.row.tat
    props.row.startDate = value?.startDate ? value.startDate : props.row.startDate
    setEdit(!edit)
  }

  const totChange = (e) => { setValue({ ...value, tat: e }) }
  const startDateChange = (e) => {

    // setValue({ ...value, startDate: e }) 
    if (props.row.companyId > 0 && props.row.assetId > 0) {

      axios.get(
        `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
        headers: {
          CompanyId: props.row.companyId,
          AssetId: props.row.assetId,
          IsCRUCompany: props.row.isCRUCompany,
          IsCRUAsset: props.row.isCRUAsset,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }).then((res) => {
        if (res.status === 200) {
          let tempStartDate = new Date(e);

          //tempStartDate.setDate(tempStartDate.getDate() + res.data);
          let g = CommonService.addWorkingDays(tempStartDate, res.data)
          let newTatDate = CommonService.GetDateAsStringWithDash(g);
          let remainingDaysTemp = CommonService.getBusinessDatesCount(g);
          props.row.tat = newTatDate;
          props.row.startDate = e;
          props.row.remainingDays = remainingDaysTemp;
          setReload(!reload)
          // setEdit(!edit);

        }
      })
    }

  }
  // const changeRemainingDays=(e)=>{ setValue({...value,remainingDays:e})}

  const priorityChange = (ab) => {
    setValue({ ...value, priorityName: ab.label, priorityId: ab.id })
  }
  const truncateString = (string, length) => {
    return (
      string.length > length ? string.substring(0, length) + "..." : string
    );
  }
  return (
    <>
      {/* Create Multiple Job Table /> */}

      <TableRow hover key={props.row.companyName}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className={classes.tableCell}>
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {props.row.companyName}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>{props.row.assetName}</TableCell>
        <TableCell align="center" className={classes.tableCell}>{props.row.noOfVariable ? props.row.noOfVariable : 0}</TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <Tooltip title={props.row.commodity} placement="top" arrow>
            <div>
              {truncateString(`${props.row.commodity}`, 90)}
            </div>
          </Tooltip>
        </TableCell>
        {!edit ? <TableCell align="center" className={classes.tableCell}>
          {props.row.startDate ? props.row.startDate.split("-").reverse().join("-") : null}</TableCell> : <TableCell align="left" className={classes.tableCell}>
          <DatePicker labelTitle="Job Start Date" variant="standard" past={true} defaultDate={props.row.startDate}
            onChangeDate={(e) => {
              if (e !== null && new Date(e).toDateString() !== 'Invalid Date') {
                let selectedDate = new Date(e);
                let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
                startDateChange(date)
              }
              //props.row.remainingDays = CommonService.getDayDifference(new Date(props.row.tat))
            }}
          />
        </TableCell>}
        {!edit ? <TableCell align="center" className={classes.tableCell}>
          {props.row.tat.split("-").reverse().join("-")}</TableCell> : <TableCell align="left" className={`${classes.tableCell} ${classes.paddingRight}`}>
          <DatePicker labelTitle="TAT" variant="standard" past={true} defaultDate={props.row.tat}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
              totChange(date)
              props.row.remainingDays = CommonService.getBusinessDatesCount(new Date(date))
            }}
          />
        </TableCell>}
        <TableCell align="center" className={classes.tableCell}>{props.row.remainingDays}</TableCell>
        {!edit ? <TableCell align="center" className={classes.tableCell}>
          {props.row.priorityName == "High" ? (
            <Chip label={props.row.priorityName} size="small" color="warning" className="statusChip" />
          ) : undefined}{" "}
          {props.row.priorityName == "Low" ? (
            <Chip label={props.row.priorityName} size="small" color="success" className="statusChip" />
          ) : undefined}{" "}
          {props.row.priorityName == "Medium" ? (
            <Chip label={props.row.priorityName} size="small" color="info" className="statusChip" />
          ) : undefined}
          {props.row.priorityName == "Critical" ? (
            <Chip label={props.row.priorityName} size="small" color="error" className="statusChip" />
          ) : undefined}
        </TableCell> : <TableCell align="center" className={`${classes.tableCell} ${classes.paddingRight}`}>
          <div className={classes.smallAlign}>
            <CustomAutoComplete
              options={props.priority}
              sx={{ width: 300 }}
              label="Priority"
              disableClearable={true}
              optionLabelKey="label"
              optionValueKey="id"
              onChange={((value) => {
                priorityChange(value)
              })}
              defaultValueKey={["id", 'label']}
              defaultValue={[props.row.priorityId, props.row.priorityName]}
            />
          </div>
        </TableCell>}
        <TableCell align="left" className={classes.tableCell}>{props.row.countryName}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{props.row.regionName}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{props.row.subRegionName}</TableCell>
        {!edit ? <TableCell align="center" className={classes.tableCell}>
          <Tooltip title="Edit"
            placement="top" arrow >
            <IconButton aria-label="edit" color="primary">
              <EditIcon className={classes.iconSize} onClick={editData} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete"
            placement="top" arrow >
            <IconButton aria-label="Delete" sx={{ color: "red" }}>
              <DeleteIcon className={classes.iconSize} onClick={openAlertDialog} />
            </IconButton>
          </Tooltip>
        </TableCell> :
          <TableCell align="left" className={classes.tableCell}>
            <Tooltip title="Submit"
              placement="top" arrow >
              <IconButton aria-label="Submit">
                <AcceptIcon className={classes.iconSize} onClick={saveData} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel"
              placement="top" arrow >
              <IconButton aria-label="Cancel" color="secondary">
                <CancelIcon className={classes.iconSize} onClick={CancelData} />
              </IconButton>
            </Tooltip>
          </TableCell>}
      </TableRow>

      {/* Alert Dialog - Confirmation for Delete */}
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openConfirmation}
        onClose={handleADClose}
        description="The asset will be deleted from the job. Are you sure you want to delete?"
        action={
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>No</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button onClick={DeleteData} color="secondary">Yes</Button>
            </Grid>
          </Grid>
        }
      />
    </>

  )
}
