import PropTypes from "prop-types";
import { Card, CardActions, CardHeader } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { OpenFolderHorizontalIcon } from "@fluentui/react-icons-mdl2";

const NcCardHeader = withStyles((theme) => createStyles({
    root: {
        flexDirection: "column"
    },
    avatar: {
        marginRight: 0,
        marginBottom: theme.spacing(0),
        fontSize: "5rem",
        lineHeight: 1,
        color: theme.palette.secondary.light
    },
    content: {
        textAlign: "center"
    },
    title: {
        fontSize: "1.25rem",
        fontWeight: 500,
        marginBottom: theme.spacing(1)
    }
}))((props) => (
    <CardHeader
        avatar={props.avatar ? props.avatar : <OpenFolderHorizontalIcon />}
        {...props}
    />
));

const NcCardHeaderLg = withStyles((theme) => createStyles({
    root: {
        flexDirection: "column"
    },
    avatar: {
        marginRight: 0,
        marginBottom: theme.spacing(2),
        fontSize: "5rem",
        lineHeight: 1,
        color: theme.palette.secondary.light,
        [theme.breakpoints.up('sm')]: {
            fontSize: "10rem",
        }
    },
    content: {
        textAlign: "center"
    },
    title: {
        fontSize: "1.25rem",
        fontWeight: 500,
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            fontSize: "3rem",
        }
    }
}))((props) => (
    <CardHeader
        avatar={props.avatar ? props.avatar : <OpenFolderHorizontalIcon />}
        {...props}
    />
));

const NcCardActions = withStyles((theme) => createStyles({
    root: {
        flexDirection: "column"
    }
}))((props) => (
    <CardActions {...props} />
));

const NcCard = withStyles((theme) => createStyles({
    root: {
        backgroundColor: "transparent"
    }
}))((props) => (
    <Card {...props} />
));

const getCardHeader = (props) => {
    const { icon, title, description, size } = props;
    switch (size) {
        case "large": return <NcCardHeaderLg avatar={icon} title={title} subheader={description} />
        default: return <NcCardHeader avatar={icon} title={title} subheader={description} />
    }
}

const EmptyState = (props) => {
    return (
        <>
            <NcCard elevation={0}>
                {getCardHeader(props)}
                {props.action && <NcCardActions>{props.action}</NcCardActions>}
            </NcCard>
        </>
    )
}

EmptyState.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    action: PropTypes.node,
    size: PropTypes.string
}
export default EmptyState;