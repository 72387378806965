// Default React, Router and Services Components
import React, { useContext } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import ApiService from "../../../../Services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFilterDataJob, clearFilterReports, GetAssetData, GetAssetTypeData,
  setFilterActionJob, setFilterReports,
} from "../../../../features/filter/filterSlice";
import DropdownTreeSelect from "react-dropdown-tree-select";
import Container from "../../SourceManagement/TreeContainer";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
import { callBasedOnLocation, callFromAssetType, callFromComm, callFromPriority, callSource } from "../../../../Util/TreeAPI";

// Importing Custom Components
import MultiSelect from "../../../../UIComponent/MultiSelect/MultiSelect";
import DatePicker from "../../../../UIComponent/DatePicker";

// Importing useStyles
import "react-dropdown-tree-select/dist/styles.css";
import "../../../../Resources/scss/layout.scss";
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./CRUReviewReport.styles";
import { FilterContext } from "../Context/Context";

const FilterForm = (props) => {
  // Declaration of States and Component Variables Start Here
  const { filterReport, assetDropDownlist } = useSelector((store) => store.filter);
  const {companyListCRU, setCompanyListCRU,
    locationCRU, setLocationCRU, setFilterValueContext} = useContext(FilterContext)
  const dispatch = useDispatch();
  const classes = useStyles();
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [countryDropdownList, setCountryDropdownList] = React.useState([]);
  const [regionDropdownList, setRegionDropdownList] = React.useState([]);
  const [subRegionDropdownList, setSubRegionDropdownList] = React.useState([]);
  const [jobComms, setJobComms] = React.useState([]);
  const [dropDown, setDropDown] = React.useState({ reportingperoid: [], });
  const [values, setValues] = React.useState({ reporting: [], });
  const [fromDate, setFromDate] = React.useState(props.fromDate);
  const [toDate, setToDate] = React.useState(props.toDate);
  const [keyToDate, setKeyToDate] = React.useState(false);
  const [keyFromoDate, setKeyFromDate] = React.useState(false);
  const [filters, setFilters] = React.useState({
    company: [], asset: [], assetTypeIds: [], countryIds: [], regionIds: [],
    subRegionIds: [], commodityIds: [], statusIds: [], priorityIds: [],
  });
  const [dependency, setDependency] = React.useState(null);
  const [apiData, setApiData] = React.useState({
    companies: [], location: {}, assetType: [], priority: [], commodity: [],
  });
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  // Declaration of States and Component Variables End Here

  // Function for Handle KeyUp
  const onChangeHandler = (key, value, reason) => {
    if (key === "commodity") {
      setDependency("commodity");
      setApiData({ ...apiData, commodity: value, });
    } else if (key === "priority") {
      setDependency("priority");
      setApiData({ ...apiData, priority: value, });
    } else if ("assetType") {
      setDependency(key === "assetType");
      setApiData({ ...apiData, assetType: value, });
    }
    const pre = { ...filterReport };
    pre[key] = value;

    dispatch(setFilterReports({ filterReport: pre }));
  };
  const clearFieldHanlder = () => {
    props.setReload(!props.reload);
    setKeyFromDate((k) => !k);
    setKeyToDate((t) => !t);
    dispatch(clearFilterReports());
    setValues({ reporting: [], });
    props.setFilterValue("");
    setCompanyListCRU([])
    setLocationCRU([])
    setFilterValueContext(null)
  };

  // Function for Country Filter
  const countryFilter = () => {
    let data = []
    for (let item of props.locationCRU) {
      for (let items of item.children) {
        for (let ites of items.children) {
          if (ites.checked === true) {
            data.push(ites.value)
          }
        }
      }
    }
    return data
  }

  // This function is used to set the filter values for the search
  const searchFilter = () => {
   // Create an object to store the form data 
    const formData = {
      company: props.companyListCRU
   // Filter out elements with checked value false   
        .filter((el) => el.checked === true)
  // Map the filtered elements to get their values
        .map((el) => el.value),
      asset: props.companyListCRU
  // Map the elements to get their children    
        .map((el) => el.children.filter((it) => it.checked === true).map((tk) => tk.value))
  // Filter out elements with length less than 0       
        .filter((item) => item.length > 0)
  // Flatten the array
        .flat(1),
      countryIds: countryFilter(),
      regionIds: props.locationCRU
   // Filter out elements with checked value false   
        .filter((el) => el.checked === true)
  // Map the filtered elements to get their values      
        .map((el) => el.value),
      subRegionIds: props.locationCRU
  // Map the elements to get their children    
        .map((el) => el.children.filter((it) => it.checked === true).map((tk) => tk.value))
   // Filter out elements with length less than 0     
        .filter((item) => item.length > 0)
        // Flatten the array     
        .flat(1),
      CommodityIds: (filterReport.commodities || []).map((commo) => commo.commodityId),
      FromDate: fromDate,
      ToDate: toDate,
      Status: null,
    };
    // Set the filter value context
    setFilterValueContext(formData)
    // Set the state of the component
    props.setState({ ...props.state, right: false });
    // Set the filter value
    props.setFilterValue(formData);
    // Set the company list CRU
    setCompanyListCRU(props.companyListCRU)
    // Set the location CRU
    setLocationCRU(props.locationCRU)
  };

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    if (dependency === "company") {
      callSource(apiData)
        .then((res) => {
          res.data.location.regions.forEach(el => {
            for (let item of props.locationCRU) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true
                }
              }
            }
            el.children.forEach(it => {
              for (let item of props.locationCRU) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true
                    }
                  }
                }
              }
              it.children.forEach(kt => {
                for (let item of props.locationCRU) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true
                        }
                      }
                    }
                  }
                }
              })
            })
          })
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "location") {
      callBasedOnLocation(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyListCRU.length; i++) {
              if (props.companyListCRU[i].value === el.value) {
                if (props.companyListCRU[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyListCRU[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyListCRU[i].children[j].value === item.value
                    ) {
                      if (props.companyListCRU[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
          // setSubCompDependency(true);
        })
        .catch((err) => console.log(err));
    } else if (dependency === "assetType") {
      callFromAssetType(apiData)
        .then((res) => {
          res.data.location.regions.forEach(el => {
            for (let item of props.locationCRU) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true
                }
              }
            }
            el.children.forEach(it => {
              for (let item of props.locationCRU) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true
                    }
                  }
                }
              }
              it.children.forEach(kt => {
                for (let item of props.locationCRU) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true
                        }
                      }
                    }
                  }
                }
              })
            })
          })
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyListCRU.length; i++) {
              if (props.companyListCRU[i].value === el.value) {
                if (props.companyListCRU[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyListCRU[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyListCRU[i].children[j].value === item.value
                    ) {
                      if (props.companyListCRU[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "priority") {
      callFromPriority(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyListCRU.length; i++) {
              if (props.companyListCRU[i].value === el.value) {
                if (props.companyListCRU[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyListCRU[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyListCRU[i].children[j].value === item.value
                    ) {
                      if (props.companyListCRU[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach(el => {
            for (let item of props.locationCRU) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true
                }
              }
            }
            el.children.forEach(it => {
              for (let item of props.locationCRU) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true
                    }
                  }
                }
              }
              it.children.forEach(kt => {
                for (let item of props.locationCRU) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true
                        }
                      }
                    }
                  }
                }
              })
            })
          })
          props.setCompanyFilter(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "commodity") {
      callFromComm(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyListCRU.length; i++) {
              if (props.companyListCRU[i].value === el.value) {
                if (props.companyListCRU[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyListCRU[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyListCRU[i].children[j].value === item.value
                    ) {
                      if (props.companyListCRU[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach(el => {
            for (let item of props.locationCRU) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true
                }
              }
            }
            el.children.forEach(it => {
              for (let item of props.locationCRU) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true
                    }
                  }
                }
              }
              it.children.forEach(kt => {
                for (let item of props.locationCRU) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true
                        }
                      }
                    }
                  }
                }
              })
            })
          })
          props.setCompanyFilter(res.data.companies);
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [apiData]);
  // Declaration of React Hooks End Here

  // Function for Getting All Companies
  const getAllCompanies = () => {
    ApiService.getAll("DropDown/GetCompanyDropDownFilter")
      .then((res) => {
        if (res.status === 200) { setJobCompanies(res.data); }
        else {
          toasterError(`${res.data}`);
        }
      })
      .catch((err) => {
        
        if(err.res.status===400){
          toasterError(err.res.data);
        }
         });
  };

// Function to get all commodities from the API
const getAllCommodities = () => {
  // Call ApiService to get all commodities
  ApiService.getAll("DropDown/GetCommodityDropDownFilter")
    .then((res) => {
      // If response status is 200, set job commodities
      if (res.status === 200) { setJobComms(res.data); }
      else {
        // Else, display error message
        toasterError(`${res.data}`);
      }
    })
    .catch((err) => {
      // If response status is 400, display error message
      if(err.res.status===400){
        toasterError(err.res.data);
      }
       });
};

// This function is used to get all countries from the API
const getAllCountries = () => {
  // Call the ApiService to get all countries
  ApiService.getAll("DropDown/GetCountryDropDownFilter")
    .then((res) => {
      // If the response status is 200, set the country dropdown list
      if (res.status === 200) {
        setCountryDropdownList(res.data);
      } else {
        // Else, display an error message
        toasterError(`${res.data}`);
      }
    })
    .catch((err) => {
      // If the response status is 400, display an error message
      if(err.res.status===400){
        toasterError(err.res.data);
      }
       });
};

// This function is used to get all the regions from the API
const getAllRegion = () => {
  // Call the ApiService to get all the regions
  ApiService.getAll("DropDown/GetRegionDropDownFilter")
    .then((res) => {
      // If the response status is 200, set the region dropdown list
      if (res.status === 200) { setRegionDropdownList(res.data); }
      else {
        // If the response status is not 200, display an error message
        toasterError(`${res.data}`);
      }
    })
    .catch((err) => {
      // If the response status is 400, display an error message
      if(err.res.status===400){
        toasterError(err.res.data);
      }
       });
};

// This function is used to get all the subregions from the API
const getAllSubRegion = () => {
  // Call the ApiService to get all the subregions
  ApiService.getAll("DropDown/GetSubRegionDropDownFilter")
    .then((res) => {
      // If the response status is 200, set the subregion dropdown list
      if (res.status === 200) {
        setSubRegionDropdownList(res.data);
      } else {
        // Else, display an error message
        toasterError(`${res.data}`);
      }
    })
    .catch((err) => {
      // If the response status is 400, display an error message
      if(err.res.status===400){
        toasterError(err.res.data);
      }
       });
};

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    dispatch(GetAssetData());
    dispatch(GetAssetTypeData());
    getAllCommodities();
    getAllCountries();
    getAllSubRegion();
    getAllRegion();
  }, []);
  // Declaration of React Hooks End Here

 // This function is used to set the dependency of company and selected companies
// It also sets the apiData with the checked companies
const onChangeComp = (currentNode, selectedNodes) => {
  // Set the dependency of company
  setDependency("company");
  // Set the selected companies
  setSelectedCompanies(selectedNodes);
  // Loop through the companyListCRU
  props.companyListCRU.forEach((el) => {
    // If the current node is checked
    if (currentNode.checked === true) {
      // Check if the current node value matches the element value
      if (currentNode.value === el.value) {
        // Set the element checked to true
        el.checked = true;
      } else if (currentNode.parentValue === el.value) {
        // Loop through the children of the element
        el.children.forEach((item) => {
          // Check if the current node value matches the item value
          if (currentNode.value === item.value) {
            // Set the item checked to true
            item.checked = true;
          }
        });
      }
    } else if (currentNode.checked === false) {
      // Check if the current node value matches the element value
      if (currentNode.value === el.value) {
        // Set the element checked to false
        el.checked = false;
      } else if (currentNode.parentValue === el.value) {
        // Loop through the children of the element
        el.children.forEach((item) => {
          // Check if the current node value matches the item value
          if (currentNode.value === item.value) {
            // Set the item checked to false
            item.checked = false;
          }
        });
      }
    }
  });

  // Get the first parent from the companyListCRU
  const firstParent = props.companyListCRU
    .map((el) => {
      let data = {
        label: el.label,
        value: el.value,
        // Filter the children that are checked
        children: el.children.filter((item) => item["checked"] === true),
      };
      return data;
    })
    // Filter the elements that have more than 0 children
    .filter((el) => el.children.length > 0);
  // Set the apiData with the checked companies
  setApiData({
    ...apiData,
    companies:
      // Check if there are any companies checked
      props.companyListCRU.filter((el) => el["checked"] === true).length > 0
        // If yes, set the companies to the checked ones
        ? props.companyListCRU.filter((el) => el["checked"] === true)
        // Else, set the companies to the first parent
        : firstParent,
  });
};

  // This function is used to set the dependency of location
const onChangeLoc = (currentNode, selectedNodes) => {
  // Set the dependency of location
  setDependency("location");
  // If no nodes are selected, set all checked values to false
  if (selectedNodes.length === 0) {
    props.locationCRU.forEach((el) => {
      el["checked"] = false;
      el.children.forEach((it) => {
        it["checked"] = false;
        it.children.forEach((kt) => {
          kt["checked"] = false;
        });
      });
    });
  }

  // If current node is checked, set the corresponding value to true
  if (currentNode.checked === true) {
    if (currentNode.grantParentValue) {
      props.locationCRU.forEach((el) => {
        el.children.forEach((first) => {
          first.children.forEach((second) => {
            if (currentNode.value === second.value) {
              second.checked = true;
              filters.countryIds.push(currentNode.value)
            }
          });
        });
      });
    } else if (currentNode.parentValue) {
      props.locationCRU.forEach((el) => {
        el.children.forEach((first) => {
          if (currentNode.value === first.value) {
            first.checked = true;
          }

        });
      });
    } else if (currentNode.value) {
      props.locationCRU.forEach((el) => {
        if (currentNode.value === el.value) {
          el.checked = true;
        }
      });
    }
  } 
  // If current node is unchecked, set the corresponding value to false
  else if (currentNode.checked === false) {
    if (currentNode.value && !currentNode.parentValue && !currentNode.grantParentValue) {
      props.locationCRU.forEach((el) => {
        if (el.value === currentNode.value) {
          el.checked = false;
        }
      });
    }
    if (currentNode.value && currentNode.parentValue && !currentNode.grantParentValue) {
      props.locationCRU.forEach((el) => {
        el.children.forEach((first) => {
          if (currentNode.value === first.value) {
            first.checked = false;
          }
        });
      });
    } else {
      props.locationCRU.forEach((el) => {
        if (el.children.map((it) => it.parentValue)[0] === currentNode.value) {
          el.children.forEach((item) => {
            if (item.parentValue === currentNode.value) {
              item.checked = false;
            }
          });
        }
      });
    }

    if (currentNode.value && currentNode.parentValue && currentNode.grantParentValue) {
      props.locationCRU.forEach((el) => {
        el.children.forEach((first) => {
          first.children.forEach((second) => {
            if (currentNode.value === second.value) {
              second.checked = false;
            }
          });
        });
      });
    }
  }

  // Get the first parent
  const firstParent = props.locationCRU
    .map((el) => {
      let data = {
        label: el.label,
        value: el.value,
        children: el.children.filter((item) => item["checked"] === true),
      };
      return data;
    })
    .filter((el) => el.children.length > 0);

  // Get the grand parent
  const grandParent = props.locationCRU
    .map((el) => {
      let data = {
        label: el.label,
        value: el.value,
        children: el.children.map((item) => {
          let items = {
            label: item.label,
            value: item.value,
            children: item.children.filter(
              (item) => item["checked"] === true
            ),
          };

          return items;
        })
      };

      return data;
    })

  // Checker function to get the final data
  const finalChecker = () => {
    let data = [];
    for (let i = 0; i < grandParent.length; i++) {
      for (let j = 0; j < grandParent[i].children.length; j++) {
        if (grandParent[i].children[j].children.length > 0) {
          data.push(grandParent[i]);
        }
      }
    }
    return data;
  };
  // Set the apiData with the final data
  setApiData({
    ...apiData,
    location: {
      regions:
        props.locationCRU.filter((el) => el["checked"] === true).length > 0
          ? props.locationCRU.filter((el) => el["checked"] === true)
          : firstParent.length > 0
            ? firstParent
            : finalChecker(),
    },
  });


};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Filter Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Container
            data={props.companyListCRU}
            onChange={onChangeComp}
            showPartiallySelected={true}
            texts={{ placeholder: "Company" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Container
            data={props.locationCRU}
            onChange={onChangeLoc}
            showPartiallySelected={true}
            texts={{ placeholder: "Location" }}
          />
        </Grid>

        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterReport.commodities}
            items={props.dataForFilter.commodity ? props.dataForFilter.commodity : []}
            label="Commodities"
            selectAllLabel={{ commodityName: "Select all" }}
            getOptionLabel={(option) => option.commodityName}
            getOptionSelected={(option, anotherOption) =>
              option.commodityName === anotherOption.commodityName
            }
            role="commodities"
            key="commodities"
          />
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            labelTitle="From Date"
            variant="standard"
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date =
                selectedDate.getFullYear() +
                "-" +
                ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + selectedDate.getDate()).slice(-2);
              setFromDate(date);
            }}
            past={false}
            key={keyFromoDate}
            clearable={true}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            labelTitle="To Date"
            variant="standard"
            past={false}
            minDates={fromDate}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date =
                selectedDate.getFullYear() +
                "-" +
                ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + selectedDate.getDate()).slice(-2);
              setToDate(date);
            }}
            key={keyToDate}
            clearable={true}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn" color="accent5"
                onClick={searchFilter}>
                Filter
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn reset-color" color="accent3"
                onClick={clearFieldHanlder}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Box>
  );
};

export default FilterForm;