// Default React, useState and Material Components
import React, { useState } from "react";
import { Button, Box, Breadcrumbs, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import SideDrawer from "../../../UIComponent/Drawer/index";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing Add & Edit Components
import AddUnit from "./AddUnitCRU";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon, } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const UnitCru = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [id, setId] = useState([]);
  const [rows, setRows] = useState([]);
  const [edit, setEdit] = useState({});
  const [roleId, setRoleId] = useState();
  const [page, setPage] = React.useState(0);
  const [tableRow, setTableRow] = useState();
  const [reload, setReload] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [formData, setFormData] = useState([]);
  const [selected, SetSelected] = useState([]);
  const [crudMode, setCrudMode] = useState("add");
  const [isLoading, setLoading] = useState(false);
  const [showDelete, setDelete] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [crudModal, setCrudModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [defaultHiddenColumnNames] = useState(['createdOn']);
  const [state, setState] = React.useState({ right: false });
  const [deleteUserData, setDeleteUserData] = useState(null);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(27, function (err, res) {
      if (res) {
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])

  React.useEffect(() => {
    searchUser(searchValue);
  }, [reload]);

  React.useEffect(() => {
    searchUser(searchValue);
  }, [sorting, page, rowsPerPage]);


  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) {
      getUserData();
    }
    if (searchValue && reload) { searchUser(searchValue); }
}, [page, reload, rowsPerPage])


	
React.useEffect(() => {
  if (searchValue == "" || searchValue == null || searchValue == undefined) {
    getUserData();
  }
  if (searchValue) { searchUser(searchValue); }
}, [sorting])

  // Declaration of React Hooks End Here

  // Assigning Columns and UI Props
  const columns = [
    { id: "id", label: "Status", type: "Highlight" },
    { id: "unit", label: "Unit", minWidth: 200, width: '20%', sorting: true },
    { id: "description", label: "Description", minWidth: 400, width: '80%', sorting: true, truncate: 90, tooltipWithTruncate: true },
  ];


// This function changes the format of a given field name
const changeSortColumnNameFormat = (fieldName) => {
  // If fieldName is null or empty, return an empty string
  if (fieldName == null || fieldName == "") { return ""; }
  // If fieldName is "unit", change it to "Unit"
  if (fieldName.toLocaleLowerCase() == "unit") { fieldName = "Unit"; }
  // If fieldName is "description", change it to "Description"
  if (fieldName.toLocaleLowerCase() == "description") { fieldName = "Description"; }
  // Return the modified fieldName
  return fieldName;
}

// This function handles the keyup event
const handleKeyUp = (event) => {
  // If backspace is pressed and target element is not a button
  if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
    // If search value is empty, get user data
    if (searchValue.length === 0) { getUserData(); }
  }
  // If enter is pressed and target element is not a button
  if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
    // Reset total count and page number
    setTotalCount(0);
    setPage(0);
    // Search for user with given value
    searchUser(event.target.value);
  }
};

  // Function for Global Search
  const globalSearch = (value) => { setSearchValue(value); };

// Function to search for users based on filter value
const searchUser = (filterValue) => {
  // Set loading to true
  setLoading(true);
  // Create an array of parameters
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
  // Call ApiService to search for users
  ApiService.search("managedata/GetCRUunitDetails?", {
    // Encode the search text
    searchText: encodeURIComponent(filterValue),
    // Change the sort column name format
    sortColumn: changeSortColumnNameFormat(sorting.sortBy),
    // Set isAscending to sorting.isAscending
    isAscending: sorting.isAscending
  }, prt)
    .then((res) => {
      // If response status is 200, set all users and total count
      if (res.status === 200) {
        setAllUsers(res.data.data);
        setLoading(false);
        setTotalCount(res.data.totalCount);
      } else {
        // Else, call toasterError with response data
        toasterError(`${res.data}`);
        setLoading(false);
      }
    })
    .catch((err) => {
      // Set loading to false
      setLoading(false);
      // Call ErroHandling with error and a callback function
      ErroHandling(err, function (res) {
        // Call toasterError with response
        toasterError(res);
      })
    });
};

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

// This function toggles the drawer open or closed
const toggleDrawer = (open, mode) => (event, item) => {
  // If mode is set to delete, set the delete user data and open the delete window
  if (mode === "Delete") { setDeleteUserData(item); setOpenDelete(true); }
  else {
    // If mode is set to edit, call the edit button API with the item id
    if (mode === "Edit") { editButtonApi(item.id); }
    // Set the form data to edit and update the state
    setFormData(edit);
    setState({ ...state, right: open, formData: edit });
    // Set the CRUD state to the given mode
    setCrudState(mode);
  }
};

// This function is used to make an API call to get the data for a given unit ID
const editButtonApi = (id) => {
  // Make an axios GET request to the specified URL with the given unit ID
  axios
    .get(process.env.React_App_baseUrl + "managedata/GetCRUUnit?unitID=" + id,
      {
        headers: {
          // Set the unit ID and authorization token in the header
          unitid: id, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      }
    )
    .then((res) => {
      // Set the response data to the edit state
      setEdit(res.data);
    });
};

// This function is used to get user data from the API
  const getUserData = () => {
    // Set loading to true
    setLoading(true);
    // Create an array of parameters
    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
    // Call the API service to search for user data
    ApiService.search("managedata/GetCRUunitDetails?", {
      sortColumn: changeSortColumnNameFormat(sorting.sortBy),
      isAscending: sorting.isAscending
    }, prt)
      .then((res) => {
    // If the response status is 200, set the user data and total count    
        if (res.status === 200) {
          setAllUsers(res.data.data);
          setTotalCount(res.data.totalCount);
          setLoading(false);
        } else {
    // Else, display an error message      
          toasterError(`${res.data}`);
          setLoading(false);
        }
      })
      .catch((err) => {
    // Catch any errors and set loading to false    
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

// Function to delete a user
const deleteUser = () => {
  // Set loading state of delete button to true
  setDeleteBtnLoadinng(true);
  // Get data of the user to be deleted
  let data = deleteUserData;
  // Set active status of user to false
  data.active = false;
  // Create an object with id of the user to be deleted
  const unitId = { id: data.id };
  // Make a post request to delete the user
  axios.post(process.env.React_App_baseUrl + "managedata/DeleteCRUUnit?unitId=" + data.id, null, {
    headers: {
      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
  })
    .then((res) => {
      // If request is successful
      if (res.status === 200) {
        // Show success message
        toasterSuccess("CRU unit deleted successfully!");
        // Get updated user data
        getUserData();
        // Set loading state of delete button to false
        setDeleteBtnLoadinng(false);
        // Close delete modal
        setOpenDelete(false);
      } else {
        // Show error message
        toasterError("Somthing Went Wrong");
        // Close delete modal
        setOpenDelete(false);
        // Set loading state of delete button to false
        setDeleteBtnLoadinng(false);
      }

    })
    .catch((err) => {
      // Handle errors
      ErroHandling(err, function (res) {
        // Show error message
        toasterError(res)
      })
    });
  // Set loading state of delete button to false
  setDeleteBtnLoadinng(false);
};

  // Function for Search Clear
  const searchClear = () => { setSearchValue(""); getUserData(); };
  const checkboxRequired = (e, item) => {
    SetSelected(e);
    setId(e);
    if (e.length > 0) { setDelete(true); }
    else { setDelete(false); }
  };

  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Unit (CRU)</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">Unit (CRU)</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {(access.allowEdit) ? <>
                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: "16px" }} />}>
                  Add Unit
                </Button>
              </> : null}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className={classes.searchByKeyword}
                      size="small"
                      fullWidth
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {searchValue ? (
                      <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">
                      Total Records: <span className={classes.totalRecord}>{totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Unit CRU Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allUsers ? allUsers : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setReload(true)
                    setSorting(e)
                  }}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Add, Edit, Delete Drawer */}
      <SideDrawer
        formComp={
          <AddUnit
            state={state}
            setState={setState}
            crudState={crudState}
            formData={edit}
            setFormData={setEdit}
            userId={null}
            hideSideDrawer={() => {
              setState({ right: false });
              getUserData();
            }}
          />
        }
        state={state}
        setState={setState}
      />

      {/* Single and Multiple Delete Confirmation Dialog Start Here */}
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to delete "${deleteUserData?.unit}" ?`}
          action={
            <>
              <div className={classes.alertDialogbtn}>
                <Button onClick={() => { setOpenDelete(false); setDeleteBtnLoadinng(false); }}>No</Button>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button onClick={() => deleteUser()} color="secondary">
                    Yes
                  </Button>
                )}
              </div>
            </>
          }
        />
      ) : null
      }
      {/* Single and Multiple Delete Confirmation Dialog End Here */}
    </Box >
  );
};

export default UnitCru;