/* eslint-disable no-undef */
import React, { useState } from "react";
import {
  Box, Button, Checkbox, Chip, FormControlLabel, Grid, IconButton, InputAdornment, Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip
} from "@mui/material";
import axios from "axios";

import { toast } from "react-toastify";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import DatePicker from "../../../UIComponent/DatePicker";
import AlertDialog from "../../../UIComponent/AlertDialog";
import CustomAutoComplete from "../../../UIComponent/CustomAutoComplete";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import "../../../../src/index.scss";
import "../../../Resources/scss/layout.scss";
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./jobManagement.styles";

// Import Fluent UI Icons
import { AcceptIcon, CancelIcon, ClearIcon, DeleteIcon, EditIcon, SearchIcon } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../Services/CommonService";

const EditJobRecords = (props) => {
  // Declaration of States and Component Variables Start Here
  const [atat, setATat] = useState();
  const [userData, setUserData] = useState({ jobId: 0, jobName: "", jobInstructions: "", });
  let [tableRow, setTableRow] = useState();
  const [arrayData, stArrayData] = useState([]);
  let [jobtype, setJobType] = useState([{ id: 1, label: "Company Data" }]);
  const [searchValue, setSearchValue] = React.useState("");
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [finalData, setFinalData] = useState();
  const [isEdited, setIsEdited] = useState(true);
  const [deltedRow, setDeletedRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [remainingDays, setRemainingDays] = React.useState("0");
  const [rawRowData, setRawRowData] = useState({
    jobId: props.editJobNumber, jobName: "", jobInstructions: "", jobAsset: [
      {
        jobAssetId: 0, priorityId: 0, startDate: "", tat: "", remainingDays: "", isEdited: false, isDeleted: false, companyId: 0,
        isCRUCompany: true, assetId: 0, isCRUAsset: true,
      },
    ], priorityName: "",
  });
  let [priorities, setpriotity] = useState();
  const [asJobAsset, setAsJobAsset] = useState({
    jobAssetId: 0, priorityId: 0, startDate: "", tat: "", remainingDays: "", isEdited: true, isDeleted: true, companyId: 0,
    isCRUCompany: true, assetId: 0, isCRUAsset: true,
  });
  const [formData, setFormData] = useState({
    jobId: props.editJobNumber, jobName: "", jobInstructions: "", jobAsset: [asJobAsset], checkJobStartValue: false
  });
  const [updateApi, setUpdateApi] = useState({
    jobId: "", jobNo: "", jobName: "", jobInstructions: "", tat: "", priorityId: "",
    priorityName: "", jobAsset: [{
      jobAssetId: 0, priorityId: 0, tat: "", assignedTo: "", assignedToId: 0, isEdited: false,
      isDeleted: false, companyId: 0, isCRUCompany: true, assetId: 0, isCRUAsset: true,
    },
    ],
  });
  const [assign, setAssign] = useState();
  // Declaration of States and Component Variables End Here

  function createPirorityData(label, id) { return { label, id }; }
  function createAssignData(name, userId) { return { name, userId }; }
  const globalSearch = (value) => { setSearchValue(value); };
  const handleADClose = () => { setOpenSaveConfirmation(false); };

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    axios.get(process.env.React_App_baseUrl + "DropDown/GetPriorityDropDownFilter", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        const rows = response.data.map((row) =>
          createPirorityData(row.priorityName, row.id)
        );
        setpriotity(rows);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
  }, []);

  React.useEffect(() => {
    axios.get(process.env.React_App_baseUrl + "DropDown/GetAssignedToFilterAsync", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        const rows = response.data.map((row) =>
          createAssignData(row.name, row.userId)
        );
        setAssign(rows);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
  }, []);

  React.useEffect(() => {
    getDetailsForEdit(props.jobIds, props.companyIds);
    setFormData({ ...formData, jobIds: props.editJobNumber, checkJobStartValue: false });
  }, []);
  // Declaration of React Hooks End Here

  // Function for Edit
  const submitForEdit = () => {
    setLoading(true);
    if (updateApi.jobName === "") {
      toasterError("Please check all the required fields");
      setLoading(false);
      return;
    }
    updateApi.jobAsset = []
    updateApi.jobAsset = tableRow;
    axios.post(`${process.env.React_App_baseUrl}job/UpdateManageJobRecordsCompanyDetails`, updateApi, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then((res) => {
        if (res.status === 200) {
          toasterSuccess("Successfuly Updated");
          props.hideSideDrawer();
          setTimeout(function () {
            setLoading(false);
          }, 4000)
        } else {
          setLoading(false);
        }
      })
      .err((err) => { setLoading(false); });
  };
  const getDetailsForEdit = (jobId, companyId) => {

    if (jobId === 0) {
      jobId = companyId.jobId;
    }
    const axiosConfig = {
      headers: {
        jobId: jobId, companyId: props.companyIds.companyID, isCRUCompany: props.companyIds.isCRUCompany,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
    };
    axios.post(process.env.React_App_baseUrl + "job/GetManageJobRecordsCompanyDetails", "", axiosConfig)
      .then((res) => {
        setRawRowData(res.data);
        let ob = res.data.companyAssetDetailsDto.map((el) => {
          return {
            ...el, tat: el.tat.split("-").reverse().join("-"),
            startDate: el.startDate ? el.startDate.split("-").reverse().join("-") : null
          };
        });
        setTableRow(ob);
        const data = res.data;
        const jobAsset = [];
        data.companyAssetDetailsDto.map((item) =>
          jobAsset.push({
            ...updateApi.jobAsset[0],
            ...item,
            assignedTo: item.assignedTo,
            priorityId: item.priorityId,
            tat: item.tat.split("-").reverse().join("-"),
            startDate: item.startDate ? item.startDate.split("-").reverse().join("-") : null,
            priorityName: item.priorityName,
            jobAssetId: item.jobAssetId,
          })
        );
        setUpdateApi({
          ...updateApi,
          jobNo: data.jobNo,
          jobId: data.jobId,
          tat: data.tat,
          startDate: data.startDate ? data.startDate.split("-").reverse().join("-") : null,
          jobName: data.jobName,
          jobInstructions: data.jobInstructions,
          priorityId: data.priorityId,
          priorityName: data.priorityName,
          jobAsset,
        });
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

  // Function for Search Clear
  const searchClear = () => { setSearchValue(""); searchUser(""); };

  // Function for Handle Data Change
  const handleDateChange = (e) => {
    let selectedDate = new Date(e);
    let date = selectedDate.getFullYear() + "-" + ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
      "-" + ("0" + selectedDate.getDate()).slice(-2);
    setFormData({ ...formData, tat: date, checkJobStartValue: false });
  };

  const classes = useStyles();
  var valueData = { jobId: "000" };

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { getDetailsForEdit(props.jobIds, props.companyIds); }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      searchUser(searchValue);
    }
  };
  //console.log(props.compData, "props.compData")
  // Function for Search User
  const searchUser = (filterValue) => {
    const axiosConfig = {
      headers: {
        jobId: props.compData.jobId, companyId: props.compData.companyId, searchText: filterValue ? encodeURIComponent(filterValue) : "", isCRUCompany: props.compData.isCRUCompany,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
    };
    axios.post(process.env.React_App_baseUrl + "job/GetManageJobRecordsCompanyDetails", "", axiosConfig)
      .then((res) => {
        setRawRowData(res.data);
        let ob = res.data.companyAssetDetailsDto.map((el) => {
          return {
            ...el, tat: el.tat.split("-").reverse().join("-"),
            startDate: el.startDate ? el.startDate.split("-").reverse().join("-") : null
          };
        });
        setTableRow(ob);
        const data = res.data;
        const jobAsset = [];
        data.companyAssetDetailsDto.map((item) =>
          jobAsset.push({
            ...updateApi.jobAsset[0],
            ...item,
            assignedTo: item.assignedTo,
            priorityId: item.priorityId,
            tat: item.tat.split("-").reverse().join("-"),
            startDate: item.startDate ? item.startDate.split("-").reverse().join("-") : null,
            jobAssetId: item.jobAssetId,
          })
        );
        setUpdateApi({
          ...updateApi,
          jobId: data.jobId,
          jobNo: data.jobNo,
          jobName: data.jobName,
          jobInstructions: data.jobInstructions,
          tat: data.tat,
          startDate: data.startDate ? data.startDate.split("-").reverse().join("-") : null,
          priorityName: data.priorityName,
          jobAsset,
        });
      })
      .catch((err) => {
        { ErroHandling(err, function (res) { toasterError(res) }) }
      });
  };


  const CommonJobStartDate = (e, value) => {
    if (rawRowData.startDate) {
      setFormData({ ...formData, checkJobStartValue: value })
      if (value) {

        let updatedRow = [];
        let promise = Promise.all(tableRow.map(async (element) => {
          if (element.companyId > 0 && element.assetId > 0) {
            let assetDetails = {
              companyId: element.companyId,
              assetId: element.assetId,
              isCRUCompany: element.isCRUCompany,
              isCRUAsset: element.isCRUAsset
            }
            await AsyncGetNoOfDays(assetDetails).then((res) => {
              let startDate = rawRowData.startDate;
              // Check if the date format is "dd-mm-yyyy"
              if (/^\d{2}-\d{2}-\d{4}$/.test(startDate)) {
                // If the format is "dd-mm-yyyy", convert it to "yyyy-mm-dd"
                let parts = startDate.split("-");
                startDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
              }
              let tempStartDate = new Date(startDate);
              //let tempStartDate = new Date(rawRowData.startDate);
              if (tempStartDate.toString().toLowerCase() === 'Invalid Date'.toLowerCase()) {
                tempStartDate = new Date(CommonService.GetDateInReverse(rawRowData.startDate))
                startDate = CommonService.GetDateInReverse(rawRowData.startDate);
              }

              //tempStartDate.setDate(tempStartDate.getDate() + res.data)
              let g = CommonService.addWorkingDays(tempStartDate, res.data)
              let newTatDate = CommonService.GetDateAsStringWithDash(g)
              let remainingDaysTemp = CommonService.getBusinessDatesCount(g)
              updatedRow.push({ ...element, startDate: startDate, remainingDays: remainingDaysTemp, tat: newTatDate, isEdited: true })

            })
          }
        }))
        promise.then(() => {
          if (updatedRow.length > 0) {
            setTableRow(updatedRow)
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    } else {
      toasterError("Please select Job Start Date");
    }
  }



  const AsyncGetNoOfDays = async (finalAssetDetailObject) => {
    return axios.get(
      `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
      headers: {
        CompanyId: finalAssetDetailObject.companyId,
        AssetId: finalAssetDetailObject.assetId,
        IsCRUCompany: finalAssetDetailObject.isCRUCompany,
        IsCRUAsset: finalAssetDetailObject.isCRUAsset,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })

  }

  return (
    <>

      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={3} lg={1}>
            <TextField
              id="standard-basic"
              label="Job ID"
              variant="standard"
              fullWidth
              readOnly
              defaultValue={updateApi.jobNo || " "}
              value={updateApi.jobNo || " "}
              InputProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={7}>
            <TextField
              InputProps={{ shrink: true, }}
              InputLabelProps={props.crudState === "Edit" ? { shrink: true } : null}
              id="standard-basic"
              label="Job Name"
              variant="standard"
              onChange={(e) => {
                setUpdateApi({ ...updateApi, jobName: e.target.value, });
              }}
              defaultValue={updateApi.jobName || " "}
              value={updateApi.jobName || " "}
              fullWidth
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={4}>
            <CustomAutoComplete
              options={jobtype}
              disabled={true}
              disableClearable={true}
              label="Job Type"
              optionLabelKey="label"
              optionValueKey="id"
              defaultValueKey={["id", "label"]}
              defaultValue={[1, "Company Data"]}
            />
          </Grid>
          <Grid item xs={12} md={9} lg={6}>
            <TextField
              fullWidth
              id="standard-multiline-static"
              label="Job Instructions"
              multiline
              rows={2}
              value={updateApi.jobInstructions}
              onChange={(e) => {
                setUpdateApi({ ...updateApi, jobInstructions: e.target.value, });
              }}
              variant="standard"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3} sx={{ mt: 3 }}>
            <TextField
              id="standard-basic"
              label="Priority"
              variant="standard"
              fullWidth
              disabled
              value={updateApi?.priorityName}
              InputProps={{ shrink: true, }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3} sx={{ mt: 3 }}>

            <DatePicker
              labelTitle="TAT"
              variant="standard"
              disabled
              defaultDate={rawRowData.tat ? rawRowData.tat : null}
              onChangeDate={(e) => {
                let selectedDate = new Date(e);
                let date = selectedDate.getFullYear() + "-" + (selectedDate.getMonth() + 1) + "-" +
                  selectedDate.getDate();
                setUpdateApi({ ...updateApi, tat: date });
              }}
            />
          </Grid>


          <Grid item xs={12} md={6} lg={3} >

            <DatePicker
              labelTitle="Job Start Date"
              variant="standard"
              disabled={false}
              defaultDate={rawRowData.startDate ? rawRowData.startDate : null}
              onChangeDate={(e) => {
                if (e !== null && new Date(e).toDateString() !== 'Invalid Date') {
                  let selectedDate = new Date(e);
                  let date = CommonService.GetDateAsStringWithDash(selectedDate)
                  setUpdateApi({ ...updateApi, startDate: date })
                  setRawRowData({ ...rawRowData, startDate: date })
                  setFormData({ ...formData, checkJobStartValue: false })
                } else {
                  setUpdateApi({ ...updateApi, startDate: undefined })
                  setRawRowData({ ...rawRowData, startDate: undefined })
                  setFormData({ ...formData, checkJobStartValue: undefined })
                }

              }}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={4} xl={3} sx={{ mt: 2 }}>
            <FormControlLabel control={<Checkbox checked={formData.checkJobStartValue} onChange={(e, value) => CommonJobStartDate(e, value)} />} label="Apply Common Job Start Date" />
          </Grid>



          <Grid item xs={12}>
            <Paper elevation={0} className={classes.addBorder}>
              <div className="d-flex table-optons justify-content-between m-3">
                <div>
                  <TextField
                    id="input-with-icon-textfield"
                    className="serchByKeyword mt-1"
                    size="small"
                    value={searchValue}
                    placeholder="Search by Keyword"
                    onKeyUp={handleKeyUp}
                    onChange={(e) => globalSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                    }}
                    variant="standard"
                  />
                  {searchValue ? (
                    <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                      <ClearIcon style={{ fontSize: 10 }} />
                    </IconButton>
                  ) : ("")}
                </div>
              </div>

              <TableContainer component={Paper} elevation={0}
                sx={{ "& > *": { borderBottom: "unset" }, boxShadow: 0, maxHeight: "calc(100vh - 440px)" }}
                className={classes.cruTable}>
                <Table aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader} style={{ minWidth: 300 }}>
                        Company
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ minWidth: 200 }}>
                        Asset
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center" style={{ minWidth: 160 }}>
                        Variable Count
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ minWidth: 300 }}>
                        Commodity
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ minWidth: 180 }}>
                        Assigned To
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center" style={{ minWidth: 130 }}>
                        Job Start Date
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center" style={{ minWidth: 130 }}>
                        TAT
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center" style={{ minWidth: 130 }}>
                        Remaining Days
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center" style={{ minWidth: 130 }}>
                        Priority
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ minWidth: 180 }}>
                        Country
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ minWidth: 180 }}>
                        Region
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left" style={{ minWidth: 180 }}>
                        Sub Region
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center" style={{ minWidth: 110 }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRow?.map((row) => (
                      <EditableTable
                        updateApi={updateApi}
                        isEdited={isEdited}
                        atat={atat}
                        id={props.idforEdit}
                        setATat={setATat}
                        asJobAsset={asJobAsset}
                        setAsJobAsset={setAsJobAsset}
                        row={row}
                        setTableRow={setTableRow}
                        tableRow={tableRow}
                        stArrayData={stArrayData}
                        priorities={priorities}
                        setUpdateApi={setUpdateApi}
                        setDeletedRow={setDeletedRow}
                        assign={assign}
                        deltedRow={deltedRow}
                      ></EditableTable>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="right">
              <Grid item xs={'auto'}>
                <Button variant="contained" className="rounded-border" color="accent3"
                  onClick={() => { props.setOpen(false); }}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={'auto'}>
                <LoadingButton buttonName="Update" clickHandler={submitForEdit} isLoading={loading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Alert Dialog - Confirmation for Delete */}
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openSaveConfirmation}
        onClose={handleADClose}
        description="Job already exists for the selected asset(s).Click here to view the existing job(s) or click ok to proceed"
        action={
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>Cancel</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button color="secondary"
                onClick={() => {
                  axios.post(process.env.React_App_baseUrl + "job/SaveJob", finalData, {
                    headers: {
                      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                    }
                  })
                    .then(function (response) {
                      handleADClose();
                      toasterSuccess("Job saved successfully");
                      props.setOpen(false);
                      props.setPageRefresh(true);
                    })
                    .catch(function (error) {
                      ErroHandling(error, function (res) {
                        toasterError(res)
                      })
                    });
                }}>
                OK
              </Button>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default EditJobRecords;

const EditableTable = ({
  updateApi, setUpdateApi, priorities, row, setTableRow, tableRow,
  stArrayData, setDeletedRow, deltedRow, assign,
}) => {
  let [edit, setEdit] = useState(false);
  let [value, setValue] = useState();
  const [deletedItem, setDeletedItem] = useState({});
  const classes = useStyles();
  const [openConfirmation, setConfirmation] = useState(false);
  const handleADClose = () => { setConfirmation(false); };
  const editData = () => { setEdit(!edit); };
  const openAlertDialog = (item) => { setConfirmation(true); setDeletedItem(item); };
  const CancelData = () => {

    //setJobIds(0);
    setValue(); setEdit(!edit);
  };
  const [reload, setReload] = useState(false);
  const saveData = () => {
    row.assignedTo = value?.assignedTo ? value?.assignedTo : row.assignedTo;
    row.assignedToId = value?.assignedToId
      ? value?.assignedToId
      : row.assignedToId;
    row.priorityId = value?.priorityId ? value?.priorityId : row.priorityId;
    row.tat = value?.tat ? value?.tat : row.tat;
    row.startDate = value?.startDate ? value?.startDate : row.startDate;
    row.priorityName = value?.priorityName
      ? value?.priorityName
      : row.priorityName;
    row.remainingDays = CommonService.getBusinessDatesCount(new Date(row.tat))
    row.isEdited = true;

    setEdit(!edit);
  };
  const priorityChange = (ab) => { setValue({ ...value, priorityName: ab.label, priorityId: ab.id }); };
  const assignChange = (ab) => {
    setValue({ ...value, assignedTo: ab.label, assignedToId: ab.id });
  };
  const totChange = (e) => { setValue({ ...value, tat: e }); setUpdateApi({ ...updateApi, tat: e }); };
  const startDateChange = (e) => {

    //    console.log(row)
    if (row.companyId > 0 && row.assetId > 0) {

      axios.get(
        `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
        headers: {
          CompanyId: row.companyId,
          AssetId: row.assetId,
          IsCRUCompany: row.isCRUCompany,
          IsCRUAsset: row.isCRUAsset,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }).then((res) => {
        if (res.status === 200) {
          let tempStartDate = new Date(e);

          //tempStartDate.setDate(tempStartDate.getDate() + res.data);
          let g = CommonService.addWorkingDays(tempStartDate, res.data)
          let newTatDate = CommonService.GetDateAsStringWithDash(g);
          let remainingDaysTemp = CommonService.getBusinessDatesCount(g);
          row.tat = newTatDate;
          row.startDate = e;
          row.remainingDays = remainingDaysTemp;
          row.isEdited = true;
          setReload(!reload)
          // setEdit(!edit);

        }
      })
    }
  };
  const DeleteData = () => {
    let arr = [...tableRow];
    row.isDeleted = true;
    deltedRow.push(row);
    setDeletedRow(deltedRow);
    let index = arr.indexOf(row);
    arr.splice(index, 1);
    setTableRow(arr);
    handleADClose();
    toasterSuccess("Successfuly Delete");
  };
  const handleChange = (e, key, id) => {
    if (key === "isEdited") { setEdit(!edit); }
    const val = { ...updateApi };
    const role = val.jobAsset.find((r) => r.jobAssetId === id);
    role[key] = e;
    val.jobAsset.push(role);
    const item = [...new Set(val.jobAsset)];
    val.jobAsset = item;
    setUpdateApi({ ...val });
    setConfirmation(false);
  };

  const truncateString = (string, length) => {
    return (
      string.length > length ? string.substring(0, length) + "..." : string
    );
  }


  return (
    <>
      <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row" className={classes.tableCell}>{row.companyName}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{row.assetName}</TableCell>
        <TableCell align="center" className={classes.tableCell}>{row.noOfVariable ? row.noOfVariable : 0}</TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <Tooltip title={row.commodity} placement="top" arrow>
            <div>
              {truncateString(`${row.commodity}`, 90)}
            </div>
          </Tooltip>
        </TableCell>
        {!edit ? (<TableCell align="left" className={classes.tableCell}>{row.assignedTo}</TableCell>) : (
          <TableCell align="left" className={`${classes.tableCell} ${classes.paddingRight}`}>
            <div className={classes.smallAlign}>
              <CustomAutoComplete
                options={assign}
                sx={{ width: 150 }}
                label="Analyst"
                optionLabelKey="name"
                optionValueKey="userId"
                onChange={(value) => { assignChange(value); }}
                defaultValueKey={["name", "userId"]}
                defaultValue={[row.assignedTo, row.assignedToId]}
              />
            </div>
          </TableCell>
        )}

        {!edit ? (<TableCell align="center" className={classes.tableCell}>
          {row.startDate ? row.startDate.split("-").reverse().join("-") : null}
        </TableCell>) : (
          <TableCell align="left" className={`${classes.tableCell} ${classes.paddingRight}`}>
            <div style={{ width: 140 }}>
              <DatePicker
                size="small"
                fullWidth
                labelTitle="Job Start Date"
                variant="standard"
                defaultDate={row.startDate}
                label="disabled"
                onChangeDate={(e) => {
                  if (e !== null && new Date(e).toDateString() !== 'Invalid Date') {
                    let selectedDate = new Date(e);
                    let date =
                      selectedDate.getFullYear() +
                      "-" +
                      ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                      "-" +
                      ("0" + selectedDate.getDate()).slice(-2);
                    startDateChange(date);
                  }

                }}
              />
            </div>
          </TableCell>
        )}
        {!edit ? (<TableCell align="center" className={classes.tableCell}>
          {row.tat.split("-").reverse().join("-")}
        </TableCell>) : (
          <TableCell align="left" className={`${classes.tableCell} ${classes.paddingRight}`}>
            <div style={{ width: 140 }}>
              <DatePicker
                size="small"
                fullWidth
                labelTitle="TAT"
                variant="standard"
                defaultDate={row.tat}
                label="disabled"
                onChangeDate={(e) => {

                  let selectedDate = new Date(e);
                  let date =
                    selectedDate.getFullYear() +
                    "-" +
                    ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + selectedDate.getDate()).slice(-2);
                  totChange(date);
                  row.remainingDays = CommonService.getBusinessDatesCount(new Date(date))
                }}
              />
            </div>
          </TableCell>
        )}
        <TableCell align="center" className={classes.tableCell}>
          {row.remainingDays}
        </TableCell>

        {!edit ? (
          <TableCell align="center" className={classes.tableCell}>
            {row.priorityName === "High" ? (
              <Chip label={row.priorityName} size="small" color="warning" className="statusChip" />
            ) : undefined}{" "}
            {row.priorityName === "Low" ? (
              <Chip label={row.priorityName} size="small" color="success" className="statusChip" />
            ) : undefined}{" "}
            {row.priorityName === "Medium" ? (
              <Chip label={row.priorityName} size="small" color="info" className="statusChip" />
            ) : undefined}
            {row.priorityName === "Critical" ? (
              <Chip label={row.priorityName} size="small" color="error" className="statusChip" />
            ) : undefined}
          </TableCell>) : (
          <TableCell align="left" className={`${classes.tableCell} ${classes.paddingRight}`}>
            <div className={classes.smallAlign}>
              <CustomAutoComplete
                size="small"
                options={priorities}
                sx={{ width: 300 }}
                label="Priority"
                optionLabelKey="label"
                disableClearable={true}
                optionValueKey="id"
                onChange={(value) => { priorityChange(value); }}
                defaultValueKey={["id", "label"]}
                defaultValue={[row.priorityId, row.priorityName]}
              />
            </div>
          </TableCell>
        )}
        <TableCell align="left" className={classes.tableCell}>{row.countryName}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{row.regionName}</TableCell>
        <TableCell align="left" className={classes.tableCell}>{row.subRegionName}</TableCell>

        {!edit ? (
          <TableCell align="center" className={classes.tableCell}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton aria-label="edit" color="primary">
                <EditIcon className={classes.iconSize}
                  onClick={() => handleChange(true, "isEdited", row.jobAssetId)} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton aria-label="Delete" sx={{ color: "red" }}>
                <DeleteIcon className={classes.iconSize} onClick={() => openAlertDialog(row)} />
              </IconButton>
            </Tooltip>
          </TableCell>) : (
          <TableCell align="center" className={classes.tableCell}>
            <Tooltip title="Submit" placement="top" arrow>
              <IconButton aria-label="Submit">
                <AcceptIcon className={classes.iconSize} onClick={saveData} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel" placement="top" arrow>
              <IconButton aria-label="Cancel" color="secondary">
                <CancelIcon className={classes.iconSize} onClick={CancelData} />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>

      {/* Alert Dialog - Confirmation for Delete */}
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openConfirmation}
        onClose={handleADClose}
        description="The asset will be deleted from the job. Are you sure you want to delete?"
        action={
          <Grid container spacing={2} justifyContent="right">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>No</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button onClick={DeleteData} color="secondary">
                Yes
              </Button>
            </Grid>
          </Grid>
        }
      />

    </>
  );
};