// Default React, useState and Material Components
import React, { useState } from "react";
import { Button, Box, Grid, TextField, Typography, Checkbox } from "@mui/material";
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
// Importing Custom ComponentsFV
import * as Validations from '../../../common/validation'
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
import Autocomplete from "../../../UIComponent/Autocomplete";
import MultiChipField from "../../../UIComponent/MultiChipField";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
// Importing useStyles
import useStyles from "../ManageData.styles";
import MultiSelect from "../../../UIComponent/MultiSelect/MultiSelect";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
//import EnableDisableButton from "../../UIComponent/EnableDisableButton/EnableDisableButton";
const AddEditLegalEntityTypes = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);
  const [defaultCountryValue, setDefaultCountryValue] = useState(null);
  const [LegalEntityData, setLegalEntityData] = useState({ id: 0, commonVariations : [] , cruFormat: '', comments: '', countryIds: [] });
  // Declaration of States and Component Variables End Here
  const [values, setValues] = useState({
    countryIds: []
  });
  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    ApiService.getAll(`DropDown/GetCountryDropDownFilter`)
      .then((res) => {
        setCountries(res.data);        
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }, []);

  React.useEffect(() => {
    if (props.crudState === "Edit" || props.crudState === "View") {
      ApiService.get("managedata/GetLegalEntityTypeById?id=", props.formData.id ,
    )
      .then((res) => {
        let commonVariationList = [];
        if (res.status === 200) {
          res.data.map((e) => {
            commonVariationList = e.commonVariation.split("^");
            setLegalEntityData({...LegalEntityData , id: e.id,             
              comments: e.comments,
              commonVariations : commonVariationList,
              countryIds: e.countryIds,
              countryName: e.countryName,
              cruFormat: e.cruFormat});
              setValues(prev => {
                return {
                    ...prev, countryIds: countries.filter(el => e.countryIds.includes(el.id)) || []
                }
            });             
            });
    }
  }
  ).catch((err) => {
  }); }
    else{
      setLegalEntityData({id: 0, commonVariations : [] , cruFormat: '', comments: '', countryIds: []});
    }
  }, [props, countries]);
  // Declaration of React Hooks End Here

  // Function for Validations
  const validation = (formData) => {
    if (formData.commonVariations.length === 0) { toasterError("Please enter Common Variation"); return false; }
    if (formData.cruFormat.trim() === "") { toasterError("Please enter CRU Format"); return false; }
    return true;
  }

// This function updates the LegalEntityData and values with the given key and value
const onChangeHandler = (key, value) => {
  // Spreads the existing LegalEntityData and adds the countryIds to it
  setLegalEntityData({
    ...LegalEntityData,
    countryIds: value.map((item) => {
      // Returns the id of each item in the array
      return item.id;
  })
});
// Spreads the existing values and adds the countryIds to it
setValues({
  ...values,
  countryIds: value.map((item) => {
    // Returns each item in the array
    return item
})
});

};

  // Function for Register Frequency
  const registerLegalEntityTypes = () => {
    // Set loading to true
    setLoading(true);
    // Check if the country name is not Japan
    if (LegalEntityData.countryName !== "Japan") {
      // Replace parentheses with empty strings
      LegalEntityData.cruFormat.replace('(', '').replace(')', '')
    }
    // Validate the legal entity data
    if (validation(LegalEntityData)) {
   // Make an API call to save the legal entity type   
      ApiService.create(
        "managedata/SaveLegalEntityType",
        LegalEntityData
      )
        .then((res) => {
          // If the response status is 200, set loading to false and show success message
          if (res.status === 200) {
            setLoading(false);
            toasterSuccess("Legal entity type details added successfully!");
            props.hideSideDrawer();
          }
        })
        .catch((err) => {
           // Handle errors
          ErroHandling(err, function (res) {
            toasterError(res)
          })
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  // This function updates the legal entity types
  const updateLegalEntityTypes = () => {
    // Set loading to true
    setLoading(true);
    // Check if country name is not Japan
    if (LegalEntityData.countryName !== "Japan") {
     // Replace parentheses with empty strings  
      LegalEntityData.cruFormat.replace('(', '').replace(')', '')
    }
     // Validate the legal entity data
    if (validation(LegalEntityData)) {
      // Make an API call to update the legal entity type
      ApiService.create("managedata/UpdateLegalEntityType", LegalEntityData
      )
        .then((res) => {
          // If response status is 200, show success message and hide side drawer
          if (res.status === 200) {
            toasterSuccess("Legal entity type details edited successfully!");
            setLoading(false);
            props.hideSideDrawer();
          }
        })
        .catch(
          // Handle errors
          (err) => {
            ErroHandling(err, function (res) {
              toasterError(res)
            })
          },
          setLoading(false)
        );
    } else {
      setLoading(false)
    }
  };
  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Legal Entity Type
        </Typography>
      </Box>

      {/* Add & Edit Frequency Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>       
          <MultiChipField
          label="Common Variation Type"
          id="input-keyword"
          // size="medium"
          // margin="normal"
          helperText="Press ENTER after each Common variation Type"
          value={LegalEntityData.commonVariations}
          onChange ={(value) => {if(value.length > 0){if(value[value.length-1].length < 200){setLegalEntityData({ ...LegalEntityData, commonVariations: value })}}else{setLegalEntityData({ ...LegalEntityData, commonVariations: value })}}}
          viewOnly={props.crudState === "View"}
          />

        </Grid>
        <Grid item xs={12} sx={{ mt: -0.75 }}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            required
            fullWidth
            id="standard-basic"
            name="cruFormat"
            size="small"
            label="CRU Format"
            placeholder="CRU Format"
            variant="standard"
            inputProps={{ maxLength: 200 }}
            value={LegalEntityData.cruFormat}
            onChange={(e) => {
              setLegalEntityData({ ...LegalEntityData, cruFormat: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>

        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={values.countryIds}
            getOptionSelected={(option, anotherOption) =>
              option.countryName === anotherOption.countryName
            }
            items={countries}
            label="Country"
            selectAllLabel={{ countryName: "Select all" }}
            getOptionLabel={(option) => option.countryName}
            role="country"
            // renderOption={(props, option, { selected }) => (
            //   <li {...props}>
            //     <Checkbox
            //       defaultChecked
            //       icon={icon}
            //       checkedIcon={checkedIcon}
            //       style={{ marginRight: 8 }}
            //       checked={selected}
            //       {...props.formData.countryIds}
            //     />
            //     {/* {option.countryName} */}
            //   </li>
            // )}
            // renderInput={(params) => (
            //   <TextField
            //     //required={fa}
            //     {...params}
            //     label="Country"
            //     placeholder="Country"
            //     variant="standard"
            //   />
            // )}
           />         
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            fullWidth
            multiline
            maxRows={4}
            inputProps={{ maxLength: 200 }}
            id="standard-basic"
            name="comments"
            size="small"
            label="Comments"
            placeholder="Comments"
            variant="standard"
            value={LegalEntityData.comments}
            onChange={(e) => {
              setLegalEntityData({ ...LegalEntityData, comments: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>

        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={'auto'}>
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Add" ? registerLegalEntityTypes : updateLegalEntityTypes}
                  isLoading={loading}
                  buttonClass="dummy-btn save-color"
                />
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant="contained" color="accent3" className="dummy-btn reset-color" onClick={() => props.setState({ ...props.state, right: false })}>Cancel</Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>

  );
};

export default AddEditLegalEntityTypes;