// Default React, Router and Services Components
import React from "react";

// Importing Material Component 
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Button, Grid, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { ErrorBadgeIcon, CircleAdditionIcon } from "@fluentui/react-icons-mdl2";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";


// Importing style files 
import "../../../Resources/scss/layout.scss";
import "react-toastify/dist/ReactToastify.css";
import "../../../../src/index.scss";

// Importing Custom Component and service
import SourceChange from "./DataCollectionSourceChange";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster/index";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
import ErroHandling from "../../../Services/ErrorHandling";
import axios from "axios"; 


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreateSource = (props) => {
 
  // Declaration of States and Component Variables Start Here
  const [formData, setFormData] = React.useState({
    editCompanyId: 0,
    editAssetId: 0,
    brandNewSource: true,
    isCRUCompany: true,
    companyId: 0,
    companyName: "",
    remarks: "",
    statusId: 1,
    isCruAsset: true,
    assetId: 0,
    assetName: null,
    assetTypeId: 0,
    regionId: 0,
    countryId: 0,
    subRegionId: 0,
    isJiraCreated: false,
    commoditiesID: [],
    referenceLinks: [],
    regionName: "",
    subRegionName: "",
    countryName: "",
    statusName: "Pending",
  });
  const [changeData, setChangeData] = React.useState({});
  const [newSource, setNewSource] = React.useState(true);
  const [newEntry, setNewEntry] = React.useState(false);
  const [isNewAsset, setIsNewAsset] = React.useState(false);
  const [buttonStatus, setButtonStatus] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [assetsDropDown, setAssetsDropdown] = React.useState([]);
  const [subRegionDropDown, setSubRegionDropDown] = React.useState([]);
  const [countryDropDown, setCountryDropDown] = React.useState([]);
  const [compDropDown, setCompDropDown] = React.useState([]);
  const [sourcechangeDD, setSourceChangeDD] = React.useState([]);
  const [sourceChangeComp, setSourceChangeComp] = React.useState([]);
  const [jiraforNewSource, setJiraforNewSource] = React.useState(false);
  const [allCompanies, setAllCompanies] = React.useState([]);
  const [jiraticketState, setJiraTicketState] = React.useState({
    id: 0,
    isCompanyChange: true,
  });
  const [editData, setEditData] = React.useState(true);
  const [editCompId, setEditCompId] = React.useState(null);
  const [editedAssetId, setEditedAssetId] = React.useState(null);
  const [testId, setTestId] = React.useState(null);
  const [assetsId, setAssetId] = React.useState(null);
  const [tempCompanyName, setTempCompanyName] = React.useState("");
  const [initialValue, setInitialValue] = React.useState(false);
  const [initialCompanyName, setInitialCompanyName] = React.useState("");
  const [initialAssetName, setInitialAssetName] = React.useState("");
  const [forCompany, setForCompany] = React.useState(false);
  const [companyIdforAsset, setCompanyIdForAsset] = React.useState(null);
  const [companyNameforAsset, setCompanyNameforAsset] = React.useState("");
  const [triggerAssetDropDown, setTriggerAssetDropDown] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [companyValue, setcompanyValue] = React.useState(null)
  const [isJiraCreated,setIsJiraCreated]=React.useState(true);

  // Declaration of States and Component Variables End Here

  React.useEffect(() => {
    getAllCompanies();
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
        headers: {
          isNewSource: false,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        
        },
      })
      .then(function (response) {
        setSourceChangeComp(response.data);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)}) 
              });
    setFormData(newFormData);
    setChangeData(sourceChangeData);
    setSourceChangeDD(props.companyDropDown);
    setCompDropDown(props.companyDropDown);
    setFormData({
      ...formData,
      referenceLinks: [""],
    });
  }, []);
// This function is used to call the region dropdown
  const callRegionDropDown = (regionID) => {
    // Making an axios request to get the subregion dropdown
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetSubRegionDropDown", {
        headers: {
          regionId: regionID,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        
        },
      })
      .then(function (response) {
       // console.log(response.data);
       // Setting the subregion dropdown
        setSubRegionDropDown(response.data);
      })
      .catch(function (error) {
        // Handling errors
        ErroHandling(error, function (res) {
          // Displaying error message in a toaster
          toasterError(res)}) 
              });
  };

  /////new source submissions

  const fetchFormData = async () => {
    //console.log(finalData, "finalDyaa");
    // Set loading to true
    setLoading(true);
    // Check if all required fields are filled for company
    let newCompanyValidation =
      finalData.companyName &&
      finalData.referencelink.length &&
      finalData.remarks.length > 0;
// If form is for company
    if (forCompany) {
      // Check if all required fields are filled
      if (newCompanyValidation) {
        let validated = false;
        // Function to validate URL
        function validURL(str) {
          var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
              "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
              "(\\#[-a-z\\d_]*)?$",
            "i"
          ); // fragment locator

          return !!pattern.test(str);
        }
        // Map through reference links and check if valid URL
        finalData.referencelink.map((link) => {
          if (!validURL(link)) {
            validated = true;
            setLoading(false);
          }
        });
        // If valid URL
        if (!validated) {
          // Make API call to save source
          axios
            .post(
              process.env.React_App_baseUrl + "source/SaveSource",
              finalData,{
                headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
              }
            )
            .then(function (response) {
              getCOmpaniesForEditing(response.data.id);
            //  console.log(response);
              props.setRefresh(!props.refresh);
              toasterSuccess("New Company Added!");
              setJiraTicketState({
                ...jiraticketState,
                id: response.data.id,
                isCompanyChange: response.data.isCompanyChange,
              });
              setLoading(false);
              setJiraforNewSource(true);
              setEditData(false);
              setIsJiraCreated(false);
            })
            .catch(function (error) {
              setLoading(false);
              setEditData(false);
              ErroHandling(error, function (res) {
                toasterError(res)}) 
            });
        } else {
          toasterError("Please enter a valid link");
          setLoading(false);
        }
      } else {
        toasterError("Please check the required fields");
        setLoading(false);
      }
    } else {
     // Check if all required fields are filled for asset
      if (
        finalData.assetName !== null &&
        finalData.assetTypeId !== 0 &&
        finalData.regionId !== 0 &&
        finalData.countryId !== 0 &&
        finalData.subRegionId !== 0 &&
        finalData.commoditiesId.length > 0 &&
        finalData.referencelink.length > 0 &&
        finalData.remarks.length > 0
      ) {
        let validated = false;
        // Function to validate URL
        function validURL(str) {
          var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
              "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
              "(\\#[-a-z\\d_]*)?$",
            "i"
          ); // fragment locator

          return !!pattern.test(str);
        }
        // Map through reference links and check if valid URL
        finalData.referencelink.map((link) => {
          if (!validURL(link)) {
            validated = true;
           
          }
        });
        // If valid URL    
        if (!validated) {
          // Make API call to save source
          axios
            .post(
              process.env.React_App_baseUrl + "source/SaveSource",
              finalData,{
                headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
              }
            )
            .then(function (response) {
              getCOmpaniesForEditing(response.data.id);
            //  console.log(response);
              props.setRefresh(!props.refresh);
              toasterSuccess("New Asset Added!");
              setJiraTicketState({
                ...jiraticketState,
                id: response.data.id,
                isCompanyChange: response.data.isCompanyChange,
              });
              setJiraforNewSource(true);
              setEditData(false);
              setLoading(false);
              setIsJiraCreated(false);
            })
            .catch(function (error) {
              ErroHandling(error, function (res) {
                toasterError(res)
                setLoading(false);
              }) 
            });
            
        } else {
          toasterError("Please enter a valid link");
          setLoading(false);
        }
      } else {
        toasterError("Please check the required fields");
        setLoading(false);
      }
    }
  };
// This function is used to get companies for editing
  const getCOmpaniesForEditing = (id) => {
    // Check if new entry
    if (newEntry) {
      // Make an API call to get company details
      axios
        .get(
          `${process.env.React_App_baseUrl}source/GetCompaniesDetail?CompanyId=${id}`,{
            headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
          }
        )
        .then((res) => {
    
         // console.log(res.data);
          // finalData.editCompanyId = res.data.editCompanyId;
          // finalData.editAssetId = res.data.editAssetId;
          setEditCompId(res.data.editCompanyId);
          setEditedAssetId(res.data.editAssetId);
          setTestId(res.data.companyId);
          setAssetId(res.data.assetId);
        })
        .catch((err) => ErroHandling(err, function (res) {
          toasterError(res)}) );
    } else {
      // Make an API call to get asset by Id
      axios
        .get(process.env.React_App_baseUrl + "source/GetAssetById", {
          headers: {
            AssetId: id,
           'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        
          },
        })
        .then((res) => {
        //  console.log(res.data);
        // Set company name to null
          finalData.companyName = null;
          setEditCompId(res.data.editCompanyId);
          setEditedAssetId(res.data.editAssetId);
          setTestId(res.data.companyId);
          setAssetId(res.data.assetId);
        })
        .catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res)}) 
            // Set button status
            setButtonStatus(true);
        });
    }
  };

  const editedFormData = () => {
    // Assign values to finalData object
    finalData.editCompanyId = editCompId;
    finalData.editAssetId = editedAssetId;
    finalData.companyId = testId;
    finalData.assetId = assetsId;
    // console.log(finalData)
    // Set loading state to true
    setLoading(true);
    // Make a post request to save source
    axios
      .post(process.env.React_App_baseUrl + "source/SaveSource", finalData,{
        headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then(function (response) {
        // Refresh the page
       // console.log(response);
        props.setRefresh(!props.refresh);
        // Show success message
        toasterSuccess("Edited Source saved!");
        // Set jira ticket state
        setJiraTicketState({
          ...jiraticketState,
          id: response.data.id,
          isCompanyChange: response.data.isCompanyChange,
        });
        // Set jira for new source
        setJiraforNewSource(true);
        // Set success message
        setSuccessMessage("Source Created successfully");
        // Set button status
        setButtonStatus(true);
        // Set loading state to false
        setLoading(false);
      })
      .catch(function (error) {
        // Handle error
        ErroHandling(error, function (res) {
          toasterError(res)})   
          // Set loading state to false
          setLoading(false);
      });
    // console.log("test");
  };

  ////initial Date for new source
// This object contains data for a new form
  const newFormData = {
    editCompanyId: 0,
    editAssetId: 0,
    brandNewSource: true,
    isCRUCompany: false,
    companyId: 0,
    companyName: "",
    remarks: "",
    statusId: 1,
    isCruAsset: true,
    assetId: 0,
    assetName: null,
    assetTypeId: 0,
    regionId: 0,
    countryId: 0,
    subRegionId: 0,
    isJiraCreated: false,
    commoditiesID: [],
    referenceLinks: ["wwww.google.com"],
    regionName: "",
    subRegionName: "",
    countryName: "",
    statusName: "Pending",
  };

  ////initial Data for souce change

  const sourceChangeData = {
    editCompanyId: 0,
    editAssetId: 0,
    brandNewSource: true,
    isCRUCompany: false,
    companyId: 0,
    companyName: "",
    remarks: "",
    statusId: 1,
    isCruAsset: false,
    assetId: 0,
    assetName: "",
    assetTypeId: 0,
    regionId: 0,
    countryId: 0,
    subRegionId: 0,
    isJiraCreated: false,
    commoditiesID: null,
    referenceLinks: [],
  };

  ////final data state new sourceAaaa

  const finalData = {
    editCompanyId: 0,
    editAssetId: 0,
    isNewSource: newFormData.brandNewSource,
    isCRUCompany: formData.isCRUCompany,
    companyId: formData.companyId,
    companyName: formData.companyName,
    remarks: formData.remarks,
    statusId: formData.statusId,
    isCRUAsset: formData.isCruAsset,
    assetId: formData.assetId,
    assetName: formData.assetName,
    assetTypeId: formData.assetTypeId,
    countryId: formData.countryId,
    regionId: formData.regionId,
    subRegionId: formData.subRegionId,
    isJiraCreated: formData.isJiraCreated,
    commoditiesId: formData.commoditiesID,
    referencelink: formData.referenceLinks,
  };

  ///final data stateb for change state

  const changedFinalData = {
    editCompanyId: 0,
    editAssetId: 0,
    isNewSource: changeData.brandNewSource,
    isCRUCompany: changeData.isCRUCompany,
    companyId: changeData.companyId,
    companyName: changeData.companyName,
    remarks: changeData.remarks,
    statusId: changeData.statusId,
    isCRUAsset: changeData.isCruAsset,
    assetId: changeData.assetId,
    assetName: changeData.assetName,
    assetTypeId: changeData.assetTypeId,
    countryId: changeData.countryId,
    regionId: changeData.regionId,
    subRegionId: changeData.subRegionId,
    isJiraCreated: changeData.isJiraCreated,
    commoditiesId: changeData.commoditiesID,
    referencelink: changeData.referenceLinks,
  };
// This function is used to call the CountryDropDown API
  const callCountryDropDown = (regionid, subRegionid) => {
    // Making an axios request to the specified URL
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCountryDropDown", {
        headers: {
          regionId: regionid,
          subRegionId: subRegionid,
         'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        
        },
      })
      .then(function (response) {
       // console.log(response.data);
       // Setting the CountryDropDown with the response data
        setCountryDropDown(response.data);
      })
      .catch(function (error) {
        // Handling errors and displaying a toaster error message
        ErroHandling(error, function (res) {
          toasterError(res)})
               });
  };
  // , isCRUCompany, companyName, iseNewSource
  const assetDropDown = (companyId, companyName) => {
    //console.log(companyId, companyName);
    // Makes a post request to the specified URL with the companyValue as the body of the request
    axios
      .post(process.env.React_App_baseUrl + "DropDown/GetAssetDropDown", companyValue,{
        // Sets the authorization header to the token stored in sessionStorage if it exists
        headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then(
        (response) => {
         // console.log(response.data);
         // Sets the assetsDropdown state to the response data
          setAssetsDropdown(response.data);
        },
        (error) => {
        //  console.log(error);
        }
      );
  };
// Function to create a Jira ticket
  const createJiraTicket = () => {
    // Make an axios post request to the React_App_baseUrl with the jiraticketState.id and isCompanyChange
    axios
      .post(process.env.React_App_baseUrl + "jira/CreateJiraTicket", {
        id: jiraticketState.id,
        isCompanyChange: !isNewAsset,// default true is passed for iscompanychange so that it create jira ticket for company from backend
      },{
        headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then(
        (res) => {
         // console.log(res);
      // Set isJiraCreated to true and set right to false
        setIsJiraCreated(true);
        props.setState({ ...props.state, right: false });
        // Show success message
        toasterSuccess(`Jira ticket created!`);

        },
        (err) => {
         // console.log(err);
         // Set button status to true and set error message
          setButtonStatus(true);
          setErrorMessage(err.response.data);
         // console.log(err.response.data);
         // Show error message
          toasterError(`${err.response.data}`)
        }
      );
  };

  ///////getCOmpanies
// Function to get all companies from the API
  const getAllCompanies = () => {
    // Make a GET request to the API using axios
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
        headers: {
          isNewSource: true,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        
        },
      })
      .then(function (response) {
        // Set the response data to the setAllCompanies function
        setAllCompanies(response.data);
      })
      .catch(function (error) {
        // Call the ErroHandling function and pass in the error and a callback function
        ErroHandling(error, function (res) {
          // Call the toasterError function with the response as an argument
          toasterError(res)})       });
    // Make another GET request to the API using axios
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
        headers: {
          isNewSource: false,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        
        },
      })
      .then(function (response) {
       // console.log(response.data);
        let res = response.data;
        // Concatenate the response data to the allCompanies variable
        allCompanies.concat(res);
      })
      .catch(function (error) {
        // Call the ErroHandling function and pass in the error and a callback function
        ErroHandling(error, function (res) {
          // Call the toasterError function with the response as an argument
          toasterError(res)}) 
              });
  };

  //////get all assets for the listed  compnaies
// This function handles the addition of a link to the formData object
  const handleAddLink = (e) => {
    // Create a copy of the referenceLinks array from the formData object
    const links = [...formData?.referenceLinks];
// Push an empty string to the end of the array
    links.push("");
// Create a new item with the updated referenceLinks array
    let item = { ...formData, referenceLinks: links };
// Update the formData with the new item
    setFormData(item);
  };

// This function handles the removal of a link from the referenceLinks array
  const handleRemoveLink = (e, index) => {
    // Create a copy of the referenceLinks array
    const links = [...formData?.referenceLinks];
    // Remove the item at the specified index
    links.splice(index, 1);
    // Create a new object with the updated referenceLinks array
    let item = { ...formData, referenceLinks: links };
    // Update the formData with the new object
    setFormData(item);
  };

  const handleChange = (e, i) => {
    //console.log("test");
    // Get a copy of the referenceLinks array from formData
    const links = [...formData?.referenceLinks];

   // console.log(links);
    // Update the value of the link at index i
    links[i] = e.target.value;

   // console.log(links);
    // Create a new object with the updated referenceLinks array
    let item = { ...formData, referenceLinks: links };

    //console.log(item);
    // Set the formData to the new object
    setFormData(item);
  };

  // const onChangeHandler = (value) => {
  //   console.log(value);
  //   const regex = new RegExp(
  //     "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  //   );
  //   console.log(regex.test(value));
  // };

  React.useEffect(() => {
    if(companyValue)
    assetDropDown(companyIdforAsset, companyNameforAsset);
  }, [triggerAssetDropDown]);

  return (
    <Box sx={{ pt: 0, pb: 3, pr: 3, pl: 3 }}>
      <Box sx={{ pt: 2 }}>
        <Typography id="modal-modal-title" variant="h5">
          Create Source
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl sx={{ pt: 2 }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                checked={newSource === true}
                value="newsource"
                control={<Radio />}
                label="New Source"
                id="newSource"
                color="accent2"
                onClick={() => {
                  setNewSource(!newSource);
                  setFormData({
                    ...formData,
                    assetName: "",
                  });
                }}
              />
              <FormControlLabel
                value="sourcechange"
                control={<Radio />}
                checked={newSource === false}
                label="Source Change"
                id="editSource"
                color="accent2"
                onClick={() => {
                  setNewSource(false);
                  setChangeData({
                    ...changeData,
                    assetName: "",
                  });
                }}
              />
            </RadioGroup>
          </FormControl>

          {newSource ? (
            ///////////////////new source component
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  //freeSolo
                 // clearOnEscape
                  required={true}
                  id="size-small-standard size-small-standard"
                  size="small"
                  options={allCompanies}
                  getOptionLabel={(option) => option.companyName.toString()}
                  isOptionEqualToValue={(option, value) =>
                    option.companyName === value.companyName
                  }
                  
                  noOptionsText={"No Companies as mentioned in the Input"}
                  onChange={(e, value) => {
                    setInitialCompanyName(value.companyName.length);
                    setcompanyValue(value)
                   // console.log(value, "Data checker");
                    if (value.companyName === "Others") {
                      setForCompany(true);
                      setNewEntry(!newEntry);
                      setTempCompanyName("");
                      setInitialValue(true);
                      setFormData({ ...formData, brandNewSource: true });
                    } else if (value.companyName !== "Others") {
                      setInitialValue(false);
                      setForCompany(false);
                      setNewEntry(false);
                     // console.log(value);
                      setFormData({
                        ...formData,
                        companyName: value.companyName,
                        companyId: value.companyId,
                        isCRUCompany: value.isCRUCompany,
                        isNewSource: value.isNewSource,
                      });
                      setCompanyIdForAsset(value.companyId);
                      setCompanyNameforAsset(value.companyName);
                      setTriggerAssetDropDown(!triggerAssetDropDown);
                      //
                    }
                  }}
                  renderOption={(props, allCompanies) => (
                    <li  {...props} key={allCompanies.companyName}>
                      {allCompanies.companyName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      variant="standard"
                      label="Company Name"
                      placeholder="Company Name"
                    />
                  )}
                />
              </Grid>

              {newEntry && (
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    id="size-small-standard"
                    label="New Company Name"
                    variant="standard"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        companyName: e.target.value,
                        isCRUCompany: false,
                        statusId: 1,
                        assetName: "",
                      });
                    }}
                  />
                </Grid>
              )}

              {!initialValue && (
                <Grid item xs={12}>
                  <Autocomplete
                    //freeSolo
                    //clearOnEscape
                    id="size-small-standard"
                    disabled={newEntry === true}
                    options={assetsDropDown}
                    getOptionLabel={(option) => option.assetName}
                    onChange={(e, value) => {
                      setInitialAssetName(value.assetName);
                     // console.log(value);
                      if (value.assetName === "Others") {
                        setIsNewAsset(true);
                        setFormData({
                          ...formData,
                          isCruAsset: false,
                          brandNewSource: true,
                        });
                      } else {
                        setIsNewAsset(false);
                        setFormData({
                          ...formData,
                          assetName: value.assetName,
                          assetId: value.assetId,
                          isCruAsset: true,
                          brandNewSource: false,
                        });
                      }
                    }}
                    // getOptionDisabled={(option) =>
                    //   option ===   assetsDropDown[assetsDropDown.length-1]
                    // }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        variant="standard"
                        label="Asset Name"
                        placeholder="Asset Name"
                      />
                    )}
                  />
                </Grid>
              )}

              {initialValue && (
                <Grid item xs={12}>
                  <Autocomplete
                   // freeSolo
                   // clearOnEscape
                    id="size-small-standard"
                    options={assetsDropDown}
                    getOptionLabel={(option) => option.assetName}
                    disabled
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        variant="standard"
                        label="Asset Name"
                        placeholder="Asset Name"
                      />
                    )}
                  />
                </Grid>
              )}

              {isNewAsset && (
                <Grid item xs={12}>
                  <TextField
                    disabled={newEntry === true}
                    required
                    fullWidth
                    size="small"
                    id="standard-basic"
                    label="New Asset Name"
                    variant="standard"
                    // value={""}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        assetName: e.target.value,
                        assetId: 0,
                        companyName: null,
                        // isCRUCompany: false,
                      });
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Autocomplete
                 // freeSolo
                 // clearOnEscape
                  disabled={newEntry === true}
                  size="small"
                  id="size-small-standard"
                  options={props.assetTypeDropDown}
                  onChange={(e, value) => {
                    setFormData({ ...formData, assetTypeId: value.id });
                  }}
                  value={formData.assetType}
                  getOptionLabel={(option) => option.assetType}
                  renderInput={(params) => (
                    <TextField
                      required={isNewAsset}
                      {...params}
                      label="Asset Type"
                      placeholder="Asset Type"
                      variant="standard"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  //freeSolo
                  //clearOnEscape
                  disabled={newEntry === true}
                  size="small"
                  id="size-small-standard"
                  options={props.regionDropDown}
                  onChange={(e, value) => {
                    setFormData({
                      ...formData,
                      regionId: value.id,
                      isJiraCreated: false,
                    });
                    callRegionDropDown(value.id);
                  }}
                  getOptionLabel={(option) => option.regionName}
                  renderInput={(params) => (
                    <TextField
                      required={isNewAsset}
                      {...params}
                      label="Region"
                      placeholder="Region"
                      variant="standard"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                 // freeSolo
                 // clearOnEscape
                  disabled={newEntry === true}
                  size="small"
                  id="size-small-standard"
                  options={subRegionDropDown}
                  onChange={(e, value) => {
                   // console.log(value);
                    setFormData({ ...formData, subRegionId: value.id });
                    callCountryDropDown(value.regionId, value.id);
                  }}
                  getOptionLabel={(option) => option.subRegionName}
                  renderInput={(params) => (
                    <TextField
                      required={isNewAsset}
                      {...params}
                      label="Sub Region"
                      placeholder="Sub Region"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  //freeSolo
                  //clearOnEscape
                  disabled={newEntry === true}
                  size="small"
                  id="size-small-standard"
                  options={countryDropDown}
                  onChange={(e, value) =>
                    setFormData({ ...formData, countryId: value.id })
                  }
                  getOptionLabel={(option) => option.countryName}
                  renderInput={(params) => (
                    <TextField
                      required={isNewAsset}
                      {...params}
                      label="Country"
                      placeholder="Country"
                      variant="standard"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  disabled={newEntry === true}
                  // size="small"
                  multiple
                  limitTags={2}
                  id="checkboxes-tags-demo"
                  options={props.commodityDropDown}
                  disableCloseOnSelect
                  onChange={(e, value) => {
                    setFormData({
                      ...formData,
                      commoditiesID: value.map((item) => {
                        return item.id;
                      }),
                    });
                  }}
                  value={formData.commodity}
                  getOptionLabel={(option) => option.commoditySymbol}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                      />
                      {option.commodityName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required={isNewAsset}
                      {...params}
                      label="Commodity"
                      placeholder="Commodity"
                      variant="standard"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                {formData?.referenceLinks &&
                  formData?.referenceLinks.map((linki, i) => {
                    return (
                      <>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <TextField
                              fullWidth
                              required
                              id="standard-basic"
                              label="Link"
                              variant="standard"
                              multiline
                              onChange={(e) => handleChange(e, i)}
                              defaultValue={linki}
                            />
                          </div>
                          <div>
                            {i === 0 && (
                              <IconButton
                                aria-label="Add"
                                style={{ marginTop: 10, marginLeft: 10 }}
                                onClick={(e) => {
                                  handleAddLink(e);
                                }}
                              >
                                <CircleAdditionIcon />
                              </IconButton>
                            )}
                            {i > 0 && (
                              <IconButton
                                aria-label="Add"
                                style={{ marginTop: 10, marginLeft: 10 }}
                                onClick={(e) => {
                                  handleRemoveLink(e, i);
                                }}
                              >
                                <ErrorBadgeIcon />
                              </IconButton>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  // sx={{ background: "#eeeeee" }}
                  id="size-small-standard size-small-standard"
                  label="Status"
                  variant="standard"
                  value={"Pending"}
                  disabled
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  size="small"
                  id="standard-basic"
                  label="Remarks"
                  variant="standard"
                  onChange={(e) =>
                    setFormData({ ...formData, remarks: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Box component="span" fullWidth sx={{ pt: 2, pb: 1 }}>
                  {/* <Button
                    variant="contained"
                    style={{ marginRight: "15px" }}
                    className="rounded-border"
                    color="accent5"
                    onClick={editData ? fetchFormData : editedFormData}
                    disabled={buttonStatus === true}
                    // onClick={fetchFormData}
                  >
                    Save2
                  </Button> */}
                  
                  <LoadingButton
                        buttonName="Save"
                        clickHandler={editData ? fetchFormData : editedFormData}
                        isLoading={loading}
                        disabled={buttonStatus === true} />
                  <Button
                    variant="contained"
                    style={{ marginRight: "15px",marginLeft:"15px" }}
                    className="rounded-border"
                    color="accent3"
                    onClick={() =>
                      props.setState({ ...props.state, right: false })
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isJiraCreated}
                    variant="contained"
                    className="rounded-border"
                    color="secondary"
                    onClick={createJiraTicket}

                  >
                    Create jira Ticket
                  </Button>
                </Box>
              </Grid>
            </Grid>
          ) : (
            /////////////////////Source changes component////////////////////////////

            <SourceChange
              state={props.state}
              setState={props.setState}
              setRefresh={props.setRefresh}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateSource;
