// Imported Files
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import "./i18n/config";
import App from "./App";
import theme from "./theme";
import { store } from "./store";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { FilterProvider } from "./Components/DataCollection/DashboardReports/Context/Context";
import { HashRouter } from "react-router-dom";
import { OwnershipFilterProvider } from "./Components/OwnershipChanges/DashboardReports/Context/Context";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <OwnershipFilterProvider>
        <FilterProvider>
          <HashRouter>
            <CssBaseline />
            <App />
          </HashRouter>
        </FilterProvider>
      </OwnershipFilterProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
