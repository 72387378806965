import { red } from '@mui/material/colors';
const { createTheme } = require("@mui/material/styles");
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    palette: {
        primary: {
            light: '#5B5F67',
            main: '#333842',
            dark: '#23272E',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#FFE797',
            main: '#DB9400',
            dark: '#7D4A00',
            contrastText: '#ffffff',
        },
        accent1: {
            light: '#7D818A',
            main: '#5D626D',
            dark: '#41444C',
            contrastText: '#ffffff',
        },
        accent2: {
            light: '#f4fdff',
            main: '#EBFBFF',
            dark: '#e3f9ff',
            contrastText: '#333842',
        },
        accent3: {
            light: '#EEEFF2',
            main: '#eaecef',
            dark: '#A3A5A7',
            contrastText: '#333842',
        },
        accent4: {
            light: '#E2A933',
            main: '#DB9400',
            dark: '#996700',
            contrastText: '#ffffff',
        },
        accent5: {
            light: '#5B5F67',
            main: '#333842',
            dark: '#23272E',
            contrastText: '#ffffff',
        },
        accent6: {
            light: '#4B9FEA',
            main: '#1E88E5',
            dark: '#155FA0',
            contrastText: '#ffffff',
        },
        accent7: {
            light: '#68B36B',
            main: '#43a047',
            dark: '#2E7031',
            contrastText: '#ffffff',
        },
    },
    typography: {
        h1: {
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            fontWeight: 'bold',
        },
        h2: {
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            fontWeight: 'bold',
        },
        h3: {
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            fontWeight: 'bold',
        },
        h4: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif"',
            fontWeight: 'bold',
        },
        h5: {
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            fontWeight: '600',
        },
        h6: {
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            fontWeight: 'bold',
        },
        subtitle: {
            // fontSize: 1,
            fontWeight: '600',
            lineHeight: 1.5,
        },
        subtitle2: {
            // fontSize: 1,
            fontWeight: '600',
            lineHeight: 1.5,
        },
        subtitle3: {
            // fontSize: 1,
            fontWeight: '600',
            lineHeight: 1.5,
        },
    },
    components: {
        MuiFormLabel:{
            styleOverrides:{
                asterisk:{
                    color: red[600]
                }
            }
        }
    }
})
export default theme