// Default React, Router and Services Components
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import MultiSelect from "../../../UIComponent/MultiSelect/MultiSelect";
import DatePicker from "../../../UIComponent/DatePicker";
import { toasterError } from "../../../UIComponent/Toaster";
import Container from "../SourceManagement/TreeContainer";
import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import {
  clearFilterJobRecord,
  clearFilterJobRecords,
  GetAssetData,
  setFilterJobRecord,
  setFilterJobRecords,
} from "../../../features/filter/filterSlice";

import {
  callBasedOnLocation,
  callFromAssetType,
  callFromComm,
  callFromPriority,
  callSource,
} from "../../../Util/TreeAPI";

// Importing useStyles and Styles
import "../../../../src/index.scss";
import "../../../Resources/scss/layout.scss";
import useStyles from "./StatusReport/StatusReport.styles";
import "react-toastify/dist/ReactToastify.css";
import "react-dropdown-tree-select/dist/styles.css";

const FilterForm = (props) => {
  console.log(props);
  // Declaration of States and Component Variables Start Here
  const { filterJobRecords, assetDropDownlist } = useSelector(
    (store) => store.filter
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [status, setStatus] = React.useState([]);
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [jobComms, setJobComms] = React.useState([]);
  const [prior, setPrior] = React.useState([]);
  const [jobDate, setJobDate] = React.useState("");
  const [assignedTo, setAssignedTo] = React.useState([]);
  const [assignedBy, setAssignedBy] = React.useState([]);
  const [jobSelection, setJobSelection] = React.useState([]);
  const [disableFilters, setDisableFilters] = React.useState(false);
  const [jobNames, setJobNames] = React.useState(null);
  const [filters, setFilters] = React.useState({
    company: [],
    asset: [],
    assetTypeIds: [],
    countryIds: [],
    regionIds: [],
    subRegionIds: [],
    commodityIds: [],
    statusIds: [],
    priorityIds: [],
  });

  const [dependency, setDependency] = React.useState(null);
  const [apiData, setApiData] = React.useState({
    companies: [],
    location: {},
    assetType: [],
    priority: [],
    status:[],
    commodity: [],

  });
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  

// This function is used to handle changes in the filterJobRecords object
const onChangeHandler = (key, value, reason) => {
  // Create a copy of the filterJobRecords object
  const pre = { ...filterJobRecords };
  // Update the key with the new value
  pre[key] = value;
  // Check if the key is "commodity"
  if (key === "commodity") {
    // Set the dependency to "commodity"
    setDependency("commodity");
    // Update the apiData object with the new value
    setApiData({ ...apiData, commodity: value });
  }  else if (key === "assetType") {
    // Set the dependency to "assetType"
    setDependency("assetType");
    // Update the apiData object with the new value
    setApiData({ ...apiData, assetType: value });
  }
  else if (key === "status") {
    // Set the dependency to "status"
    setDependency("status");
    // Update the apiData object with the new value
    setApiData({ ...apiData, status: value });
  }
  // Dispatch the updated filterJobRecords object
  dispatch(setFilterJobRecord({ filterJobRecords: pre }));
};

//Function to get all job assets
const GetAllJobAssets = () => {
  //Making an axios call to the API endpoint
  axios
    .get(process.env.React_App_baseUrl + "statusreport/GetJobAssetDropDownForStatusReportForOwnershipChanges",{
      headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
    .then(function (response) {
      //Setting the job selection
      setJobSelection(response.data);
      //Checking if props.JobName and props.JobName.jobId exist
      if (props.JobName && props.JobName.jobId) {
        //Filtering the response data for the jobId
        let jobAsset = response.data.filter((e) => e.jobId === props.JobName.jobId)
        //Setting the job names
        setJobNames(jobAsset[0]);
      } else { 
        //Setting the job names to null
        setJobNames(null); 
      }
    })
    .catch(function (error) { 
      //Handling errors
      ErroHandling(error, function (res) {
      toasterError(res)}) 
    });
}
  React.useEffect(() => {
    GetAllJobAssets();

  }, []);
  React.useEffect(() => {
    if (dependency === "company") {
      callSource(apiData)
        .then((res) => {
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationFilter) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationFilter) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationFilter) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          setJobComms(res.data.commodity);
          setPrior(res.data.priority);
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "location") {
      callBasedOnLocation(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
          setJobComms(res.data.commodity);
          setPrior(res.data.priority);
        })
        .catch((err) => console.log(err));
    } else if (dependency === "assetType") {
      callFromAssetType(apiData)
        .then((res) => {
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationFilter) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationFilter) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationFilter) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          setJobComms(res.data.commodity);
          setPrior(res.data.priority);
          props.setDataForFilter({
            ...props.dataForFilter,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "priority") {
      callFromPriority(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationFilter) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationFilter) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationFilter) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          props.setCompanyFilter(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
          });
          setJobComms(res.data.commodity);
          setPrior(res.data.priority);
        })
        .catch((err) => console.log(err));
    } else if (dependency === "commodity") {
      callFromComm(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach((el) => {
            for (let item of props.locationFilter) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true;
                }
              }
            }
            el.children.forEach((it) => {
              for (let item of props.locationFilter) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true;
                    }
                  }
                }
              }
              it.children.forEach((kt) => {
                for (let item of props.locationFilter) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true;
                        }
                      }
                    }
                  }
                }
              });
            });
          });
          props.setCompanyFilter(res.data.companies);
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            priority: res.data.priority,
          });
          setJobComms(res.data.commodity);
          setPrior(res.data.priority);
        })
        .catch((err) => console.log(err));
    }
  }, [apiData]);

  const clearFieldHanlder = () => {
    props.resetTreeFilter();
    dispatch(clearFilterJobRecord());
    props.setSelectedJob(null);
    props.setFilterValue("");
    props.setReload(!props.reload);
    localStorage.removeItem("selectedTatDate");
  };

  const submitFilter = () => {
    const formData = {
      JobId: props.JobName?.jobId,
      //assignedById: filterJobRecords.userBy.map((use) => use.userId),
      statusIds: filterJobRecords.status?.map((stat) => stat.id),
      company: props.companyFilter
        .filter((el) => el.checked === true)
        .map((el) => el.value),
      asset:filterJobRecords.assetType?.map((commo) => commo.assetTypeId),
      commodityIds: filterJobRecords?.commodities?.map((commodity) => commodity.commodityId),
      tat: props.tatDate||localStorage.getItem("selectedTatDate"),
      assetType:filterJobRecords.assetType,
      status:filterJobRecords.status,
      commodities:filterJobRecords?.commodities
    };

    // this data will be send to the body api
    dispatch(setFilterJobRecord({ filterJobRecords: formData }));
    props.setFilterValue(formData);

    props.setState({ ...props.state, right: false });
  };

// Function to get all status from API
const getAllStatus = () => {
  // Call ApiService to get all data from "DropDown/GetJobStatusFilterAsync"
  ApiService.getAll("DropDown/GetJobStatusFilterAsync")
    .then((res) => {
      // If response status is 200, set the status
      if (res.status === 200) {
        setStatus(res.data);
      } else {
        // Else call toasterError with response data
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Call ErroHandling with error and a callback function
      ErroHandling(err, function (res) {
        // Call toasterError with response
        toasterError(res);
      });
    });
};
// This function is used to get all companies from the API
const getAllCompanies = () => {
  // Call ApiService to get all companies
  ApiService.getAll("DropDown/GetCompanyDropDownFilter")
    .then((res) => {
      
      // Check if response status is 200
      if (res.status === 200) {
        // Set job companies with response data
        setJobCompanies(res.data);
      } else {
        // Show error message in toaster
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Handle error and show error message in toaster
      ErroHandling(err, function (res) {
        toasterError(res);
      });
    });
};

// This function is used to get all commodities from the API
const getAllCommodities = () => {
  // Call ApiService to get all commodities
  ApiService.getAll("DropDown/GetCommodityDropDownFilter")
    .then((res) => {
      // If response status is 200, set job commodities
      if (res.status === 200) {
        setJobComms(res.data);
      } else {
        // Else call toasterError with response data
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Call ErrorHandling with error and a callback function
      ErroHandling(err, function (res) {
        // Call toasterError with response
        toasterError(res);
      });
  });
};

// Function to get all priority from API
const getAllPriority = () => {
  // Call ApiService to get all data from "DropDown/GetPriorityDropDownFilter"
  ApiService.getAll("DropDown/GetPriorityDropDownFilter")
    .then((res) => {
      // If response status is 200, set the data to 'setPrior'
      if (res.status === 200) {
        setPrior(res.data);
      } else {
        // Else call 'toasterError' with response data
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Call 'ErroHandling' with error and a callback function
      ErroHandling(err, function (res) {
        // Call 'toasterError' with response
        toasterError(res);
      });
  });
};

// This function is used to get all assignedTo values from the API
const getAllassignedTo = () => {
  // Call ApiService to get all assignedTo values
  ApiService.getAll("DropDown/GetAssignedToFilterAsync")
    .then((res) => {
      // If response status is 200, set the assignedTo value
      if (res.status === 200) {
        setAssignedTo(res.data);
      } else {
        // Else call toasterError with response data
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Call ErroHandling with error and a callback function
      ErroHandling(err, function (res) {
        // Call toasterError with response
        toasterError(res);
      });
    });
};

// This function is used to get all assigned by from the API
const getAllassignedBy = () => {
  // Call the ApiService to get all data from "DropDown/GetAssignedByFilterAsync"
  ApiService.getAll("DropDown/GetAssignedByFilterAsync")
    .then((res) => {
      // If the response status is 200, set the AssignedBy with the response data
      if (res.status === 200) {
        setAssignedBy(res.data);
      } else {
        // Else call the toasterError function with the response data
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Call the ErrorHandling function with the error and a callback function
      ErroHandling(err, function (res) {
        // Call the toasterError function with the response
        toasterError(res);
      });
  });
};

  React.useEffect(() => {
    getAllassignedTo();
    getAllassignedBy();
    getAllStatus();
    getAllCompanies();
    dispatch(GetAssetData());
    getAllCommodities();
    getAllPriority();
  }, []);

// This function sets the selected job, job name and disables filters
const selectAJob = (e, value) => {
  // Set the selected job
  props.setSelectedJob(value)
  // Set the job names
  setJobNames(!value ? null : value)
  // Set the job name
  props.setJobName(!value ? null : value);
  // If no value is selected, disable filters
  if (!value) { setDisableFilters(false); }
  // Else enable filters
  else { setDisableFilters(true); }
}

 // This function is used to set the dependency of company and selected companies
const onChangeComp = (currentNode, selectedNodes) => {
  // Set the dependency of company
  setDependency("company");
  // Set the selected companies
  setSelectedCompanies(selectedNodes);
  // Loop through the company filter
  props.companyFilter.forEach((el) => {
    // If the current node is checked
    if (currentNode.checked === true) {
      // Check if the current node value is equal to el value
      if (currentNode.value === el.value) {
        // Set the el checked to true
        el.checked = true;
      } else if (currentNode.parentValue === el.value) {
        // Loop through the item in el children
        el.children.forEach((item) => {
          // Check if the current node value is equal to item value
          if (currentNode.value === item.value) {
            // Set the item checked to true
            item.checked = true;
          }
        });
      }
    } else if (currentNode.checked === false) {
      // Check if the current node value is equal to el value
      if (currentNode.value === el.value) {
        // Set the el checked to false
        el.checked = false;
      } else if (currentNode.parentValue === el.value) {
        // Loop through the item in el children
        el.children.forEach((item) => {
          // Check if the current node value is equal to item value
          if (currentNode.value === item.value) {
            // Set the item checked to false
            item.checked = false;
          }
        });
      }
    }
  });

  // Get the first parent from the company filter
  const firstParent = props.companyFilter
    .map((el) => {
      let data = {
        label: el.label,
        value: el.value,
        // Filter the children with checked value true
        children: el.children.filter((item) => item["checked"] === true),
      };
      return data;
    })
    // Filter the element with children length greater than 0
    .filter((el) => el.children.length > 0);
  // Set the api data
  setApiData({
    ...apiData,
    companies:
      // Check if the length of elements with checked value true is greater than 0
      props.companyFilter.filter((el) => el["checked"] === true).length > 0
        ? props.companyFilter.filter((el) => el["checked"] === true)
        : firstParent,
  });
};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Add & Edit Frequency Form */}
      <Grid container spacing={4}>
      <Grid item xs={12}>
      <Autocomplete
            disablePortal
            value={props.selectedJob}
            id="combo-box-demo"
            options={jobSelection}
            getOptionLabel={(option) => option.jobNoName}
            onChange={selectAJob}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} placeholder="Job ID - Job Name" variant="standard"
              sx={{input: {"&::placeholder": {
                       opacity: 0.75,
                    },
                    "&::after": {
                      borderBottom:'2px solid rgba(0, 0, 0, 0.75) !important',
                      opacity: 0.75,
                    }
                   
                },
              }}
              />
            )}
          />
                 </Grid>
        <Grid item xs={12}>
          <Container
            data={props.companyFilter}
            onChange={onChangeComp}
            showPartiallySelected={true}
            texts={{ placeholder: "Owner" }}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterJobRecords.assetType}
            items={
              props.dataForFilter.assetType ? props.dataForFilter.assetType : []
            }
            label="Asset Type"
            selectAllLabel={{ assetTypeName: "Select all" }}
            getOptionLabel={(option) => option.assetTypeName}
            getOptionSelected={(option, anotherOption) =>
              option.assetTypeName === anotherOption.assetTypeName
            }
            role="assetType"
          />
        </Grid>
         <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterJobRecords.commodities}
            items={props.dataForFilter.commodity ? props.dataForFilter.commodity : []}
            label="commodity"
            selectAllLabel={{ commodityName: "Select all" }}
            getOptionLabel={(option) => option.commodityName}
            getOptionSelected={(option, anotherOption) =>
              option.commodityName === anotherOption.commodityName
            }
            role="commodities"
          />
        </Grid>
        {/* <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterJobRecords.userBy}
            items={assignedBy}
            label="Assigned by"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            role="userBy"
          />
        </Grid> */}
        <Grid item xs={12}>
          <DatePicker
            labelTitle="TAT"
            placeHolder="DD-MM-YYYY"
            variant="standard"
            past={false}
            defaultDate={props.tatDate || localStorage.getItem("selectedTatDate")}
            onChange={onChangeHandler}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              props.setTatDate(
                selectedDate.getFullYear() +
                  "-" +
                  (selectedDate.getMonth() + 1) +
                  "-" +
                  selectedDate.getDate(),
                  localStorage.setItem("selectedTatDate", selectedDate)
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterJobRecords.status}
            getOptionSelected={(option, anotherOption) =>
              option.statusName === anotherOption.statusName
            }
            items={status}
            label="Status"
            selectAllLabel={{ statusName: "Select all" }}
            getOptionLabel={(option) => option.statusName}
            role="status"
          />
        </Grid>

        {/* <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterJobRecords.priority}
            getOptionSelected={(option, anotherOption) =>
              option.priorityName === anotherOption.priorityName
            }
            items={prior}
            label="Priority"
            selectAllLabel={{ priorityName: "Select all" }}
            getOptionLabel={(option) => option.priorityName}
            role="priority"
          />
        </Grid>
 */}
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn"
                sx={{ mr: 2 }}
                color="accent5"
                onClick={submitFilter}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn reset-color"
                color="accent3"
                onClick={clearFieldHanlder}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterForm;
