// Default React, Router and Services Components
import React from "react";
import { Button } from "@mui/material";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";

// Import Fluent UI Icons
import { ExportIcon } from "@fluentui/react-icons-mdl2";

const ExportToExcel = ({ apiData, fileName, cruReviewReport, CRUReviewReportDrill }) => {
  // Declaration of States and Component Variables Start Here
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  // Declaration of States and Component Variables End Here

// This function is used to export data from an API to a CSV file
const exportToCSV = (apiData, fileName) => {
  // Create a worksheet using the JSON data from the API
  const ws = XLSX.utils.json_to_sheet(apiData);
  
  // If the CRU review report is true, add the job priority columns to the worksheet
  if (cruReviewReport) {
    XLSX.utils.sheet_add_aoa(
      ws, [["Job Priority", "Passed with no errors", "Passed with CRU review errors", "CRU Review failed",],],
      { origin: "A1", }
    );
  }
  
  // If the CRU review report drill is true, add the job details columns to the worksheet
  if (CRUReviewReportDrill) {
    XLSX.utils.sheet_add_aoa(
      ws, [[
        "Job ID",
        "Job Name",
        "Company", 
        "Asset", 
        "Variable Count",
        "Commodity",
        "Created Date",
        "Job Start Date",
        "Turn Around Time",
        "Remaining Days" ,
        "Completed Time", 
        "Assigned To", 
        "Merit QC", 
        "Job Priority", 
        "Country", 
        "Region", 
        "Sub Region"
      ],],
      { origin: "A1", }
    );
  }
  
  // Create a workbook and add the worksheet to it
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  
  // Write the workbook to an array buffer
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  
  // Create a blob from the array buffer
  const data = new Blob([excelBuffer], { type: fileType });

  // Save the blob as a CSV file
  FileSaver.saveAs(data, fileName + fileExtension);
};


  return (
    // Export to Excel
    <Button variant="contained" color="secondary" startIcon={<ExportIcon style={{ fontSize: 14 }} />}
      onClick={(e) => exportToCSV(apiData, fileName)}>
      Export To Excel
    </Button>
  );
};

export default ExportToExcel;