// Default React, Router and Services Components
import React, { useState } from "react";
import {
    Button, Breadcrumbs, Box, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TablePagination, TextField, Tooltip, Typography, TableSortLabel, CircularProgress
} from "@mui/material";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import { visuallyHidden } from "@mui/utils";
import axios from 'axios';
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import GetMenuService from "../../../Services/GetMenuService";
// Importing Custom Components
import MultiSelect from "../../../UIComponent/MultiSelectDropdown";
import BasicPopover from "../../../UIComponent/PopOver/index";
import AlertDialog from "../../../UIComponent/AlertDialog";
import SideDrawer from "../../../../src/UIComponent/Drawer/index";
import FilterReportCalendar from "./FilterReportCalendar";
import CreatePublicationDetails from "./CreatePublicationDetails";
import BaseLayout from "../../../UIComponent/BaseLayout";
import { LoaderWrapper } from "../../../UIComponent/Progress";
// import "react-toastify/dist/ReactToastify.css";

// Importing useStyles
import useStyles from "./ReportingCalendar.styles";

// Importing FluentUI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, CommentIcon, DeleteIcon, EditIcon, FilterIcon, SearchIcon } from '@fluentui/react-icons-mdl2';

const DataCollectionReportingCalendar = () => {
    // Declaration of States and Component Variables Start Here
    const [crudState, setCrudState] = React.useState(false);
    const [state, setState] = React.useState({ right: false });
    const classes = useStyles();
    const [searchValue, setSearchValue] = React.useState();
    const [state2, setState2] = React.useState({ right: false });
    const [showFilter, setShowFilter] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sorting, setSorting] = useState({ orderBy: "", order: false });
    const [openConfirmation, setConfirmation] = useState(false);
    const openAlertDialog = () => { setConfirmation(true); }
    const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
    const [sOpen, setOpen] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [companyDeatils, setCompanyDeatis] = React.useState([])
    const [companyHeader, setCompanyHeader] = React.useState([])
    const [Comment, setComment] = useState({ message: '', date: '' })
    const [refresh, setRefresh] = useState(false)
    const [dropDown, setDropDown] = React.useState({ year: [] });
    const [selected, setSelected] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
    const [isLoading, setLoading] = useState(false);
    let [filterValue, setFilterValue] = React.useState({
        "Company": [], "YearId": [], "ReportingPeriodId": [], "Q1": null, "Q2": null, "Q3": null,
        "Q4": null, "Annual": null, "FromDate": "", "ToDate": "", "SearchText": ""
    });
    const [values, setValues] = useState({});
    const [calenderID, setCalenderID] = useState(null);
    const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })

    // Declaration of States and Component Variables End Here

    // Declaration of React Hooks Start Here
    React.useEffect(() => {
        GetMenuService(13, function (err, res) {
            if (res) {
                // console.log(res.data)
                setAccess(res.data)
            } else {
                toasterError(err)
            }
        })
    }, [])
    React.useEffect(() => {
        GetReportingCalendar()
        setRefresh(false)
    }, [sorting, page, rowsPerPage, refresh]);
    React.useEffect(() => { setValues(filterValue); }, [filterValue]);
    React.useEffect(() => {
        axios.get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingYear", {
            headers: {
                'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
            }
        })
            .then(function (response) {
                let data = response.data.map((el) => { return { label: el.year.toString(), value: el.id } })
                setDropDown({ year: data || [] })
            })
            .catch(function (error) {
                ErroHandling(error, function (res) {
                    toasterError(res)
                })
            });
    }, [])
    // Declaration of React Hooks End Here

    // Function for Open Panel
    const toggleDrawer = (open) => {
        setState({ ...state, right: open });
        setCrudState(true);
    };
    const openPopover = (event, mesg, date) => {
        setComment({ message: mesg, date: date })
        setOpen(event.currentTarget);
    };

    // Function for Sorting
    const handleRequestSort = (event, property) => {
        const isAscending = sorting.orderBy === property && sorting.order;
        setSorting({ orderBy: property, order: isAscending ? false : true });
    };

    // This function is used to get the reporting calendar details
const GetReportingCalendar = () => {
    // Set loading to true while fetching data
    setLoading(true)
    var SendFilterValue;
    // Check if all filter values are empty
    if (filterValue.Company.length === 0 && filterValue.YearId.length === 0 && filterValue.ReportingPeriodId.length === 0 && filterValue.Q1 === null && filterValue.Q2 === null && filterValue.Q3 === null && filterValue.Q4 === null && filterValue.Annual === null && filterValue.FromDate === "" && filterValue.ToDate === "" && filterValue.SearchText === "") {
        SendFilterValue = ""
    } else {
        SendFilterValue = filterValue
    }
    // Make an API call to get the reporting calendar details
    axios.get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingCalendar", {
        headers: {
            // Set the sorting column and order
            sortColumn: sorting.orderBy,
            sourceFilter: encodeURIComponent(JSON.stringify(SendFilterValue)),
            isAscending: sorting.order,
            // Set the authorization token
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

        },
        params: { pageNumber: page + 1, pagesize: rowsPerPage, }
    }).then(function (response) {
        // Set the total count of records
        setTotalCount(response.data.totalCount);
        var responseData = response.data.data
        // Check if there is any data in the response
        if (responseData.length) {
            let reqHeader = responseData[0].publicationDetails.map(el => el.publication)
            let bb = responseData.map(el => {
                const publication = el.publicationDetails.map(e => {
                    return { [e.publication]: e }
                });
                return { ...el, ...Object.assign({}, ...publication) };
            });
            // Set the company details
            setCompanyDeatis([...bb]);
            // Set the company header
            setCompanyHeader(reqHeader);
            // Set loading to false after fetching data
            setLoading(false)
        } else {
            // Set company details to empty array
            setCompanyDeatis([])
            // Set loading to false after fetching data
            setLoading(false)
        }
    })
        .catch(function (error) {
            // Set loading to false after fetching data
            setLoading(false)
            // Handle errors
            ErroHandling(error, function (res) {
                toasterError(res)
            })
        });
}

    const handleClose = () => { setOpen(null); };
    const open1 = Boolean(sOpen);
    const idss = open1 ? "simple-popover" : undefined;

    // Handle Change Page
    const handleChangePage = (event, newPage) => { setPage(newPage); };
    const handleADClose = () => {
        setConfirmation(false)
        setOpenSaveConfirmation(false);
    };

    // Handle Change Rows PerPage
    const handleChangeRowsPerPage = (event) => { setRowsPerPage(+event.target.value); setPage(0); };
    const filterSubmit = () => { setRefresh(!refresh) }

    // Filter side drawer
    const toggleDrawer2 = (open) => (event) => {
        setState2({ ...state2, right: open });
        setShowFilter(true);
    };

    // Function for Handle KeyUp
    const handleKeyUp = (event) => {
        if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== 'BUTTON') {
            setPage(0)
            setRefresh(true)
        }
    }

    // Function for Global Search
    const globalSearch = (value) => { setFilterValue({ ...filterValue, SearchText: value }) }

    // Function for Search Clear
    const searchClear = () => {
        setFilterValue({ ...filterValue, SearchText: '' })
        setPage(0)
        setRefresh(true)
    }
    // This function updates the selected year, company header, filter value and refresh state
    const yearChange = (data) => {
        // Set the selected data to the passed in data or an empty array
        setSelected(data || []);
        // Set the company header to an empty array
        setCompanyHeader([]);
        // Map over the data and get the value of each element
        let year = (data || []).map((el) => el.value);
        // Update the filterValue with the new year
        setFilterValue({ ...filterValue, YearId: year });
        // Refresh the state
        setRefresh(!refresh)
    }
    // Function to delete an item from the calender
    const deleteItem = (element) => { 
        // Set the calender ID of the element to be deleted
        setCalenderID(element.id); 
        // Open an alert dialog to confirm the deletion
        openAlertDialog(); 
    }
   // This function sets the calender ID and toggles the drawer
    const editItem = (element) => { 
        // Set the calender ID
        setCalenderID(element.id); 
        // Toggle the drawer
        toggleDrawer(true); 
    }

    // Function to create an item
    const createItem = () => { 
        // Set the calender ID to null
        setCalenderID(null); 
        // Toggle the drawer to true
        toggleDrawer(true); 
    }

   // Function to delete selected calender
        const deleteSelectedCalender = () => {
            // Set loading state of delete button to true
            setDeleteBtnLoadinng(true);
            // Make a post request to the specified url with the given parameters
            axios.post(process.env.React_App_baseUrl + "reportingcalendar/DeletePublication?CalendarId=" + calenderID, null, {
                headers: {
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                }
            })
                .then(function (response) {
                    // Set calender ID to null
                    setCalenderID(null);
                    // If response status is 200, set loading state of delete button to false and call GetReportingCalendar()
                    if (response.status === 200) {
                        setDeleteBtnLoadinng(false);
                        handleADClose();
                        toasterSuccess("Deleted Successfully.");
                        GetReportingCalendar();
                    }
                })
                .catch(function (error) {
                    // Set loading state of delete button to false
                    setDeleteBtnLoadinng(false);
                    // Close the AD window
                    handleADClose();
                    // Set calender ID to null
                    setCalenderID(null);
                    // Call ErroHandling function to handle errors
                    ErroHandling(error, function (res) {
                        toasterError(res)
                    })
                });
        }

    return (
        // Page Layout Start Here
        <Box>
            <Grid container maxWidth justifyContent="flex-start" alignItems="center">

                {/* Breadcrumbs Menu Start Here */}
                <Grid item xs={12} sm={12} md={6} className="page-title">
                    <h2>Reporting Calendar</h2>
                    <Breadcrumbs
                        separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
                        <Typography color="text.primary">Manage Company Data Collections</Typography>
                        <Typography color="text.primary">Reporting Calendar</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* Breadcrumbs Menu End Here */}

                {/* Main Buttons Start Here */}
                {(access.allowEdit) ? <>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                            <Grid item xs={"auto"}>
                                <Button onClick={() => createItem()} variant="contained" color="accent2" className="createPublication"
                                    startIcon={<AddIcon style={{ fontSize: 16 }} color="primary" />} >
                                    Create Publication Details
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid></> : null
                }
                {/* Main Buttons End Here */}

                {/* Baselayout Start Here */}
                <Grid item xs={12}>
                    <BaseLayout>
                        <Box className={classes.addBorder}>
                            {/* Search Option and Total Count Start Here */}
                            <Grid item xs={12} className={classes.tableTopPart}>
                                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                                    <Grid item xs={12} xl={4}>
                                        <TextField
                                            id="input-with-icon-textfield"
                                            className="serchByKeyword mt-1"
                                            value={filterValue.SearchText}
                                            placeholder="Search by Keyword"
                                            onKeyUp={handleKeyUp}
                                            onChange={(e) => globalSearch(e.target.value)}
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start">
                                                    <SearchIcon className={classes.searchIcon} />
                                                </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                        />
                                        {filterValue.SearchText ? (
                                            <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                                                <ClearIcon style={{ fontSize: 10 }} />
                                            </IconButton>
                                        ) : ("")}
                                    </Grid>
                                    <Grid item xs={12} xl={8} textAlign="right">
                                        <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                                            <Grid item xs={"auto"}>
                                                <Grid>
                                                    <div style={{ width: 380 }}>
                                                        <MultiSelect
                                                            onChange={(value) => yearChange(value)}
                                                            items={dropDown.year}
                                                            value={selected}
                                                            selectAllLabel={{ label: "Select all" }}
                                                            getOptionLabel={'label'}
                                                            placholder="Year"
                                                            dropLable={'label'}
                                                            role="year"
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={"auto"}>
                                                {(access.allowView) ? <>
                                                    <Button onClick={toggleDrawer2(true)} variant="contained" color="secondary"
                                                        startIcon={<FilterIcon style={{ fontSize: 16 }} />}>
                                                        Filter
                                                    </Button>
                                                </> : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Search Option and Total Count End Here */}

                            {/* Report Calendar Table /> */}
                            <Grid item xs={12}>
                                <LoaderWrapper loading={isLoading}>
                                    <TableContainer component={Paper} sx={{ maxWidth: 2000, maxHeight: 500, boxShadow: 0 }} elevation={0} >
                                        <Table stickyHeader aria-label="spanning table" className={classes.cruTable}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sortDirection={sorting.orderBy === "company" ? sorting.order ? "asc" : "desc" : false} align="left" rowSpan={2} style={{ minWidth: 300, width: 350 }} className={classes.cruThead}>
                                                        <TableSortLabel active={sorting.orderBy === "Company"} direction={sorting.orderBy === "Company" ? sorting.order ? "asc" : "desc" : "asc"} onClick={(e) => handleRequestSort(e, "Company")} >
                                                            Company
                                                            {sorting.orderBy === "Company" ? (
                                                                <Box component="span" sx={visuallyHidden}>
                                                                    {sorting.order === false
                                                                        ? "sorted descending"
                                                                        : "sorted ascending"}
                                                                </Box>
                                                            ) : null}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell align="center" colSpan={5} className={classes.cruThead && classes.cruTheadDoubleSep}>
                                                        Collection Frequency
                                                    </TableCell>
                                                    {companyHeader.map((el, ind) =>
                                                        <TableCell align="center" rowSpan={2} style={{ minWidth: '130px' }} className={classes.cruThead && classes.cruTdSep} key={ind}>
                                                            {el}
                                                        </TableCell>)}
                                                    <TableCell align="center" rowSpan={2} style={{ minWidth: '150px' }} className={classes.cruThead}>Action</TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    <TableCell align="center" style={{ minWidth: '45px', top: 30 }} className={classes.cruThead && classes.cruTheadLeftSep}>Q1</TableCell>
                                                    <TableCell align="center" style={{ minWidth: '45px', top: 30 }} className={classes.cruThead}>Q2</TableCell>
                                                    <TableCell align="center" style={{ minWidth: '45px', top: 30 }} className={classes.cruThead}>Q3</TableCell>
                                                    <TableCell align="center" style={{ minWidth: '45px', top: 30 }} className={classes.cruThead}>Q4</TableCell>
                                                    <TableCell align="center" style={{ minWidth: '50px', top: 30 }} className={classes.cruThead}>Annual</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {companyDeatils?.map((el, ind) => <TableRow hover key={ind}>
                                                    <TableCell align="left" className={classes.cruTd}>{el.company}</TableCell>
                                                    <TableCell align="center" className={classes.cruTd && classes.cruTdSep}>{el.q1 ? 'Y' : 'N'}</TableCell>
                                                    <TableCell align="center" className={classes.cruTd}>{el.q2 ? 'Y' : 'N'}</TableCell>
                                                    <TableCell align="center" className={classes.cruTd}>{el.q3 ? 'Y' : 'N'}</TableCell>
                                                    <TableCell align="center" className={classes.cruTd}>{el.q4 ? 'Y' : 'N'}</TableCell>
                                                    <TableCell align="center" className={classes.cruTd}>{el.annual ? 'Y' : 'N'}</TableCell>
                                                    {companyHeader.map((head, index) => <TableCell align="right" key={index} className={classes.cruTd && classes.cruTdSep}>
                                                        {el[head]?.displayDate ? el[head]?.displayDate : <span className={classes.overviewIcons}>-</span>}
                                                        {el[head]?.comment ?
                                                            <IconButton aria-describedby={idss} onClick={(event) => openPopover(event, el[head]?.comment, el[head]?.displayDate)}>
                                                                <CommentIcon />
                                                            </IconButton> : <span className={classes.overviewIcons}>-</span>}
                                                    </TableCell>)}
                                                    <TableCell align="center" className={classes.cruTd} >
                                                        {(access.allowEdit) ? <>
                                                            <Tooltip title="Edit" placement="top" arrow>
                                                                <IconButton color="primary">
                                                                    <EditIcon onClick={() => editItem(el)} style={{ fontSize: '20px' }} />
                                                                </IconButton>
                                                            </Tooltip></> : null
                                                        }
                                                        {(access.allowDelete) ? <>
                                                            <Tooltip title="Delete" placement="top" arrow>
                                                                <IconButton color="error">
                                                                    <DeleteIcon onClick={() => deleteItem(el)} style={{ fontSize: '20px' }} />
                                                                </IconButton>
                                                            </Tooltip></> : null
                                                        }
                                                    </TableCell>
                                                </TableRow>)}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 15, 25, 50, 100]}
                                        component="div"
                                        count={totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </LoaderWrapper>
                            </Grid>
                        </Box>
                    </BaseLayout>
                </Grid>


                {/* SideDrawer for Create Publication Details */}
                <SideDrawer
                    drawerWidth="md"
                    formComp={
                        <CreatePublicationDetails
                            state={state}
                            setState={setState}
                            calenderID={calenderID}
                            recallApi={GetReportingCalendar}
                        />
                    }
                    state={state}
                    setState={setState}
                //crudState={showFilter}
                />


                <SideDrawer
                    drawerWidth="md"
                    formComp={
                        <FilterReportCalendar
                            state={state2}
                            setState={setState2}
                            filterValue={filterValue}
                            values={values}
                            setFilterValue={setFilterValue}
                            filterSubmit={filterSubmit}
                        />
                    }

                    state={state2}
                    setState={setState2}
                    crudState={showFilter}
                />

                <AlertDialog
                    title={"Confirmation"}
                    maxWidth={"sm"}
                    id="alert-delete"
                    open={openConfirmation}
                    onClose={handleADClose}
                    description="Are you sure you want to delete?"
                    action={
                        <>
                            <Button onClick={handleADClose} style={{ marginRight: "15px" }}>
                                No
                            </Button>
                            {deleteBtnLoadinng ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    onClick={() => {
                                        deleteSelectedCalender()
                                    }}
                                    color="secondary">
                                    Yes
                                </Button>
                            )}
                        </>
                    }
                />

                <BasicPopover
                    sOpen={sOpen}
                    open={open1}
                    onClose={handleClose}
                    idss={idss}
                    description={Comment.message}
                />
            </Grid>
        </Box>

    )
}

export default DataCollectionReportingCalendar;


