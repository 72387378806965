/* eslint-disable no-undef */
// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Box, Button, Checkbox, Chip, FormControlLabel, Grid, IconButton, InputAdornment, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography
} from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import EmptyState from "../../../UIComponent/EmptyState";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import { upload } from "@testing-library/user-event/dist/upload";
import MultiSelect from "../../../UIComponent/MultiSelect/MultiSelect";
// Importing Custom Components
import DatePicker from "../../../UIComponent/DatePicker";
import AlertDialog from "../../../UIComponent/AlertDialog";
import CustomAutoComplete from "../../../UIComponent/CustomAutoComplete";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
import { LoaderWrapper } from "../../../UIComponent/Progress";
// Importing useStyles and Styles
import "../../../../src/index.scss";
import "../../../Resources/scss/layout.scss";
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./jobManagement.styles";

// Import Fluent UI Icons
import { AcceptIcon, CancelIcon, ClearIcon, DeleteIcon, EditIcon, SearchIcon } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../Services/CommonService";

const EditMultipleJobForAssignJob = (props) => {
  const [atat, setATat] = useState();
  const [userData, setUserData] = useState({ jobId: 0, jobName: "", jobInstructions: "", });
  let [tableRow, setTableRow] = useState();
  const [arrayData, stArrayData] = useState([]);
  let [jobtype, setJobType] = useState([{ id: 1, label: "Company Changes" }]);
  const [searchValue, setSearchValue] = React.useState("");
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [finalData, setFinalData] = useState();
  const [isEdited, setIsEdited] = useState(true);
  const [deletedRow, setDeletedRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoader, setLoader] = useState(false);
  //
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [longitude, setLongitude] = useState(false);
  const [latitude, setLatitude] = useState(false);
  const [owner, setOwner] = useState(false);
  const [operator, setOperator] = useState(false);
  const [isListedCompany, setIsListedCompany] = useState(false);
  const [ownershipDate, setOwnershipDate] = useState(false);
  const [stockExchange, setStockExchange] = useState(false);
  const [ownershipPercentage, setOwnershipPercentage] = useState(false);
  const [seeThroughOwners, setSeeThroughOwners] = useState(false);
  const [jointVentures, setJointVentures] = useState(false);
  const [updates, setUpdates] = useState(false);
  const [selectAllDataPoints, setSelectAllDataPoints] = useState(false);
  // const [remainingDays, setRemainingDays] = React.useState("0");
  const [rawRowData, setRawRowData] = useState({
    jobId: props.editJobNumber, jobName: "", jobInstructions: "", jobAsset: [
      {
        jobAssetId: 0, priorityId: 0, tat: "", isEdited: false, isDeleted: false,
        companyId: 0, isCRUCompany: true, assetId: 0, isCRUAsset: true,
      },
    ],
    priorityName: ""
  });
  let [priorities, setpriotity] = useState();
  const [asJobAsset, setAsJobAsset] = useState({
    jobAssetId: 0, priorityId: 0, tat: "", isEdited: true, isDeleted: true,
    companyId: 0, isCRUCompany: true, assetId: 0, isCRUAsset: true,
  });
  const [formData, setFormData] = useState({
    jobId: props.editJobNumber, jobName: "shhhh", jobInstructions: "", jobAsset: [asJobAsset], checkJobStartValue: false,
    startDate: ""
  });
  const [updateApi, setUpdateApi] = useState({
    jobId: props.idforEdit, jobName: "", jobInstructions: "", jobAsset: [
      {
        jobAssetId: 0, priorityId: 0, tat: "", isEdited: false, isDeleted: false,
        companyId: 0, isCRUCompany: true, assetId: 0, isCRUAsset: true,
      },
    ],
        DataPointsCODto: {
      lattitude: false,
      longitude: false,
      owner: false,
      joinVentures: false,
      ownershipDate: false,
      operator: false,
      ownershipPercentage: false,
      seeThroughOwners: false,
      stockExchange: false,
      updates: false
    },
    JobYearsDto: []
  });

  function createPirorityData(label, id) { return { label, id }; }
  const globalSearch = (value) => { setSearchValue(value); };

  const handleADClose = () => { setOpenSaveConfirmation(false); };

  React.useEffect(() => {
    getYear();
    axios.get(process.env.React_App_baseUrl + "DropDown/GetPriorityDropDownFilter", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        const rows = response.data.map((row) =>
          createPirorityData(row.priorityName, row.id)
        );
        setpriotity(rows);
      })
      .catch(function (error) { ErroHandling(error, function (res) { toasterError(res) }) });
  }, []);

  React.useEffect(() => {
    getDetailsForEdit(props.idforEdit);
    setFormData({ ...formData, jobId: props.editJobNumber });
  }, []);
  const getYear = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetYears",
        {
          headers: {
            Authorization: CommonService.getToken()
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setYears(res.data);
        } else {
          toasterError("Something went wrong");
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };
  const submitForEdit = () => {
    setLoading(true);
    if (updateApi.jobName === '') {
      toasterError("Please check all the required fields");
      setLoading(false);
      return;
    }
    updateApi.jobAsset = tableRow
    if (deletedRow.length) {
      updateApi.jobAsset = [...updateApi.jobAsset, ...deletedRow]
    }
    axios.post(`${process.env.React_App_baseUrl}companyownershipjob/CompanyOwnershipUpdateJob`, updateApi, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then((res) => {
        toasterSuccess("Job details updated successfully");
        setTimeout(function () {
          setLoading(false);
        }, 4000)
        getDetailsForEdit(props.idforEdit)
        props.setReload(!props.reload);


      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 400) {
          toasterError(err.response.data);
        }
      });
  };

   const getDetailsForEdit = (jobid) => {
    setLoader(true);
    axios.get(
      `${process.env.React_App_baseUrl}companyownershipjob/CompanyOwnershipGetJobCompanyAssetDetailForEdit`,
      {
        headers: {
          jobId: jobid,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      }
    )
      .then((res) => {
        setLoader(false);
        setRawRowData(res.data);
        let ob = [];
        res.data.companyAssetDetailsDto.forEach(el => {
          if (!deletedRow?.some(x => x.assetId === el.assetId && x.companyId === el.companyId && x.isCRUAsset === el.isCRUAsset)) {
            ob.push({
              ...el, tat: el.tat.split("-").reverse().join("-"),
              startDate: el.startDate ? el.startDate.split("-").reverse().join("-") : null
            });
          }
        });
        setTableRow(ob);
        if (res?.data?.dataPointsCODto) {
          setLongitude(res?.data?.dataPointsCODto?.longitude)
          setLatitude(res?.data?.dataPointsCODto?.lattitude)
          setOwner(res?.data?.dataPointsCODto?.owner)
          setOperator(res?.data?.dataPointsCODto?.operator)
          setOwnershipDate(res?.data?.dataPointsCODto?.ownershipDate)
          setStockExchange(res?.data?.dataPointsCODto?.stockExchange)
          setOwnershipPercentage(res?.data?.dataPointsCODto?.ownershipPercentage)
          setSeeThroughOwners(res?.data?.dataPointsCODto?.seeThroughOwners)
          setJointVentures(res?.data?.dataPointsCODto?.joinVentures)
          setUpdates(res?.data?.dataPointsCODto?.updates)
          if (res?.data?.dataPointsCODto?.longitude
            && res?.data?.dataPointsCODto?.lattitude
            && res?.data?.dataPointsCODto?.owner
            && res?.data?.dataPointsCODto?.operator
            && res?.data?.dataPointsCODto?.ownershipDate
            && res?.data?.dataPointsCODto?.stockExchange
            && res?.data?.dataPointsCODto?.ownershipPercentage
            && res?.data?.dataPointsCODto?.seeThroughOwners
            && res?.data?.dataPointsCODto?.joinVentures
            && res?.data?.dataPointsCODto?.updates) {
            setSelectAllDataPoints(true);
          }
        }
        if (res?.data?.jobYearsDto) {
          let tempSelectedYears = res.data.jobYearsDto.map(ele => {
            return { id: ele.yearId, year: ele.year }
          });
          setSelectedYear(tempSelectedYears);
        }
        setTableRow(ob);
        const data = res.data;
        const jobAsset = [];
        data.companyAssetDetailsDto.map((item) => {
          if (!(deletedRow?.some(x => x.assetId === item.assetId && x.companyId === item.companyId && x.isCRUAsset === item.isCRUAsset))) {
            jobAsset.push({
              ...updateApi.jobAsset[0],
              ...item,
              priorityId: item.priorityId,
              tat: item.tat.split("-").reverse().join("-"),
              startDate: item.startDate ? item.startDate.split("-").reverse().join("-") : null,
              jobAssetId: item.jobAssetId
            })
          } else {
            jobAsset.push({
              ...updateApi.jobAsset[0],
              ...item,
              priorityId: item.priorityId,
              tat: item.tat.split("-").reverse().join("-"),
              startDate: item.startDate ? item.startDate.split("-").reverse().join("-") : null,
              jobAssetId: item.jobAssetId,
              isDeleted: true
            })
          }
        });
        setUpdateApi({
          ...updateApi,
          jobName: data.jobName,
          jobInstructions: data.jobInstructions,
          jobAsset,
          DataPointsCODto: {
            lattitude: data?.dataPointsCODto?.lattitude,
            longitude: data?.dataPointsCODto?.longitude,
            owner: data?.dataPointsCODto?.owner,
            joinVentures: data?.dataPointsCODto?.joinVentures,
            ownershipDate: data?.dataPointsCODto?.ownershipDate,
            operator: data?.dataPointsCODto?.operator,
            ownershipPercentage: data?.dataPointsCODto?.ownershipPercentage,
            seeThroughOwners: data?.dataPointsCODto?.seeThroughOwners,
            stockExchange: data?.dataPointsCODto?.stockExchange,
            updates: data?.dataPointsCODto?.updates
          },
          JobYearsDto: res?.data?.jobYearsDto
        });
      })
      .catch((err) => { { ErroHandling(err, function (res) { toasterError(res) })
                          setLoader(false);} });
  }; 

  const searchClear = () => { setSearchValue(""); searchUser("") };

  const handleDateChange = (e) => {
    let selectedDate = new Date(e);
    let date = selectedDate.getFullYear() +
      "-" + ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
      "-" + ("0" + selectedDate.getDate()).slice(-2);
    setFormData({ ...formData, tat: date, checkJobStartValue: false });
  };
  const classes = useStyles();
  var valueData = { jobId: "000" };
  const handleKeyUp = (event) => {

    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { searchUser(); }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      searchUser(searchValue);
    }
  };
  const searchUser = (searchValue) => {
    axios.get(
      `${process.env.React_App_baseUrl}companyownershipjob/CompanyOwnershipGetJobCompanyAssetDetailForEdit`,
      {
        headers: {
          searchText: encodeURIComponent(searchValue),
          jobId: props.idforEdit,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      }
    )
      .then((res) => {
        let ob = [];
        res.data.companyAssetDetailsDto.forEach(el => {
          if (!deletedRow?.some(x => x.assetId === el.assetId && x.companyId === el.companyId && x.isCRUAsset === el.isCRUAsset)) {
            ob.push({
              ...el, tat: el.tat.split("-").reverse().join("-"),
              startDate: el.startDate ? el.startDate.split("-").reverse().join("-") : null
            });
          }
        });
        setTableRow(ob);
        const data = res.data;
        const jobAsset = [];
        data.companyAssetDetailsDto.map((item) => {
          if (!deletedRow?.some(x => x.assetId === item.assetId && x.companyId === item.companyId && x.isCRUAsset === item.isCRUAsset)) {
            jobAsset.push({
              ...updateApi.jobAsset[0],
              ...item,
              priorityId: item.priorityId,
              tat: item.tat.split("-").reverse().join("-"),
              startDate: item.startDate ? item.startDate.split("-").reverse().join("-") : null,
              jobAssetId: item.jobAssetId
            })
          } else {
            jobAsset.push({
              ...updateApi.jobAsset[0],
              ...item,
              priorityId: item.priorityId,
              tat: item.tat.split("-").reverse().join("-"),
              startDate: item.startDate ? item.startDate.split("-").reverse().join("-") : null,
              jobAssetId: item.jobAssetId,
              isDeleted: true
            })
          }
        });
        setUpdateApi({
          ...updateApi,
          jobName: data.jobName,
          jobInstructions: data.jobInstructions,
          jobAsset,
        });
      })
      .catch((err) => { { ErroHandling(err, function (res) { toasterError(res) }) } });
  };


  const CommonJobStartDate = (e, value) => {

    if (rawRowData.startDate) {
      setFormData({ ...formData, checkJobStartValue: value })
      if (value) {

        let updatedRow = [];
        let promise = Promise.all(tableRow.map(async (element) => {
          if (element.companyId > 0 && element.assetId > 0) {
            let assetDetails = {
              companyId: element.companyId,
              assetId: element.assetId,
              isCRUCompany: element.isCRUCompany,
              isCRUAsset: element.isCRUAsset
            }
            await AsyncGetNoOfDays(assetDetails).then((res) => {
              let startDate = rawRowData.startDate;
              let tempStartDate = new Date(rawRowData.startDate);
              if (tempStartDate.toString().toLowerCase() === 'Invalid Date'.toLowerCase()) {
                tempStartDate = new Date(CommonService.GetDateInReverse(rawRowData.startDate))
                startDate = CommonService.GetDateInReverse(rawRowData.startDate);
              }
              let g = CommonService.addWorkingDays(tempStartDate, res.data)
              let newTatDate = CommonService.GetDateAsStringWithDash(g)
              let remainingDaysTemp = CommonService.getBusinessDatesCount(g)
              updatedRow.push({ ...element, startDate: startDate, remainingDays: remainingDaysTemp, tat: newTatDate, isEdited: true })
            })
          }
        }))
        promise.then(() => {
          if (updatedRow.length > 0) {
            setTableRow(updatedRow)
            setRawRowData({ ...rawRowData, isEdited: true })
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    }
    else {
      toasterError("Please select Job Start Date");
    }
  }

  const AsyncGetNoOfDays = async (finalAssetDetailObject) => {
    return axios.get(
      `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
      headers: {
        CompanyId: finalAssetDetailObject.companyId,
        AssetId: finalAssetDetailObject.assetId,
        IsCRUCompany: finalAssetDetailObject.isCRUCompany,
        IsCRUAsset: finalAssetDetailObject.isCRUAsset,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })

  }

  return (
    // Create Multiple Jobs
    <div>
      <LoaderWrapper loading={isLoader}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={3} lg={2} xl={1}>
            <TextField
              id="standard-basic"
              label="Job ID"
              variant="standard"
              fullWidth
              readOnly
              value={props.editJobNumber}
              InputProps={{ shrink: true, }}
            />
          </Grid>
          <Grid item xs={12} md={9} lg={7} xl={8}>
            <TextField
              InputProps={{ shrink: true, }}
              InputLabelProps={
                props.crudState === "Edit" ? { shrink: true } : null
              }
              id="standard-basic"
              label="Job Name"
              variant="standard"
              onChange={(e) => {
                setUpdateApi({ ...updateApi, jobName: e.target.value, });
              }}
              value={updateApi.jobName || " "}
              fullWidth
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CustomAutoComplete
              options={jobtype}
              sx={{ width: 300 }}
              disabled={true}
              label="Job Type"
              optionLabelKey="label"
              optionValueKey="id"
              defaultValueKey={["id", "label"]}
              defaultValue={[1, "Company Changes"]}
            />
          </Grid>
          <Grid item xs={12} md={9} lg={6}>
            <TextField
              fullWidth
              id="standard-multiline-static"
              label="Job Instructions"
              multiline
              rows={2}
              value={updateApi.jobInstructions}
              onChange={(e) => {
                setUpdateApi({ ...updateApi, jobInstructions: e.target.value, });
              }}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ mt: 3 }}>
            <TextField
              id="standard-basic"
              label="Priority"
              variant="standard"
              fullWidth
              disabled
              value={rawRowData?.priorityName}
              InputProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ mt: 3 }}>
            <DatePicker
              labelTitle="TAT"
              variant="standard"
              disabled
              defaultDate={rawRowData.tat ? rawRowData.tat : null}
              onChangeDate={(e) => {
                let selectedDate = new Date(e);
                let date = selectedDate.getFullYear() +
                  "-" + (selectedDate.getMonth() + 1) +
                  "-" + selectedDate.getDate();
                setValue({ ...value, tat: date, });
                rawRowData.remainingDays = CommonService.getBusinessDatesCount(new Date(date));
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} >

            <DatePicker
              labelTitle="Job Start Date"
              variant="standard"
              disabled={false}
              defaultDate={rawRowData.startDate ? rawRowData.startDate : null}
              onChangeDate={(e) => {
                if (e !== null && new Date(e).toDateString() !== 'Invalid Date') {
                  let selectedDate = new Date(e);
                  let date = CommonService.GetDateAsStringWithDash(selectedDate)
                  setRawRowData({ ...rawRowData, startDate: date })
                  setFormData({ ...formData, checkJobStartValue: false })
                } else {
                  setRawRowData({ ...rawRowData, startDate: undefined })
                  setFormData({ ...formData, checkJobStartValue: false })
                }

              }}
            />
          </Grid>


          <Grid item xs={12} md={5} lg={4} xl={3} >
            <FormControlLabel style={{ marginTop: "10px" }} control={<Checkbox checked={formData.checkJobStartValue} onChange={(e, value) => CommonJobStartDate(e, value)} />} label="Apply Common Job Start Date" />
          </Grid>
          <Grid item xs={12} md={5} lg={4} xl={3}>
            <MultiSelect
              value={selectedYear}
              selectAllLabel={{ year: "Select all" }}
              items={years ? years : []}
              getOptionLabel={(option) => option.year.toString()}
              label="Year"
              onChange={(key, value, reason) => {
                if (value !== null && value !== undefined) {
                  setSelectedYear(value);
                  let tempYears = value.map(ele => {
                    return { YearId: ele.id, Year: ele.year }
                  })
                  setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, JobYearsDto: tempYears }));

                }
              }}
              role="year"
              key="year"
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid xs={12} sx={{ mr: 1 }}>
                <Typography color="text.primary" sx={{ mt: 1 }}>
                <strong>Select Data Points</strong>
                  <FormControlLabel sx={{ ml: 1 }}
                    control={
                      <Checkbox
                        onChange={(event, value) => {
                          if (value !== null && value !== undefined) {
                            setLongitude(value)
                            setLatitude(value)
                            setOwner(value)
                            setOperator(value)
                            setOwnershipDate(value)
                            setStockExchange(value)
                            setOwnershipPercentage(value)
                            setSeeThroughOwners(value)
                            setJointVentures(value)
                            setUpdates(value)
                            setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, lattitude: value, longitude: value, owner: value, operator: value, ownershipDate: value, ownershipPercentage: value, stockExchange: value, seeThroughOwners: value, joinVentures: value, updates: value } }));
                          }
                          setSelectAllDataPoints(!selectAllDataPoints);

                        }}
                        checked={selectAllDataPoints}
                      />
                    }
                    label="Select All"
                  />
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={1.5}
                  justifyContent="left"
                  alignItems="flex-start"
                >

                  <Grid
                    item
                    xs={12}
                  >
                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                      <Grid item xs={"auto"}>
                        <div className={classes.subHeading} style={{ minWidth: "100px" }}>Asset Info:</div>{" "}
                      </Grid>
                      <Grid item xs={"auto"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event, value) => {
                                if (value !== undefined && value !== null) {
                                  setLongitude(!longitude);
                                  setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, lattitude: value } }));
                                  if (value
                                    && latitude
                                    && owner
                                    && operator
                                    && ownershipDate
                                    && stockExchange
                                    && ownershipPercentage
                                    && seeThroughOwners
                                    && jointVentures
                                    && updates) {
                                    setSelectAllDataPoints(true);
                                  } else {
                                    setSelectAllDataPoints(false);
                                  }
                                }

                              }}
                              checked={longitude}
                            />
                          }
                          label="Longitude"
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event, value) => {

                                if (value !== undefined && value !== null) {
                                  setLatitude(!latitude);
                                  setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, longitude: value } }));
                                  if (longitude
                                    && value
                                    && owner
                                    && operator
                                    && ownershipDate
                                    && stockExchange
                                    && ownershipPercentage
                                    && seeThroughOwners
                                    && jointVentures
                                    && updates) {
                                    setSelectAllDataPoints(true);
                                  } else {
                                    setSelectAllDataPoints(false);
                                  }
                                }
                              }}
                              checked={latitude}
                            />
                          }
                          label="Latitude"
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event, value) => {
                                if (value !== undefined && value !== null) {
                                  setOperator(!operator);
                                  setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, operator: value } }));
                                  if (longitude
                                    && latitude
                                    && owner
                                    && value
                                    && ownershipDate
                                    && stockExchange
                                    && ownershipPercentage
                                    && seeThroughOwners
                                    && jointVentures
                                    && updates) {
                                    setSelectAllDataPoints(true);
                                  } else {
                                    setSelectAllDataPoints(false);
                                  }
                                }
                              }}
                              checked={operator}
                            />
                          }
                          label="Operator"
                        />
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>


              <Grid
                item
                xs={12}
              >
                <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                  <Grid item xs={"auto"}>
                    <div className={classes.subHeading} style={{ minWidth: "100px" }}>Owner Info:</div>{" "}
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {
                            if (value !== undefined && value !== null) {
                              setOwner(!owner);
                              setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, owner: value } }));
                              if (longitude
                                && latitude
                                && value
                                && operator
                                && ownershipDate
                                && stockExchange
                                && ownershipPercentage
                                && seeThroughOwners
                                && jointVentures
                                && updates) {
                                setSelectAllDataPoints(true);
                              } else {
                                setSelectAllDataPoints(false);
                              }
                            }
                          }}
                          checked={owner}
                        />
                      }
                      label="Owner"
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {

                            if (value !== undefined && value !== null) {
                              setOwnershipPercentage(!ownershipPercentage);
                              setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, ownershipPercentage: value } }));
                              if (longitude
                                && latitude
                                && owner
                                && operator
                                && ownershipDate
                                && stockExchange
                                && value
                                && seeThroughOwners
                                && jointVentures
                                && updates) {
                                setSelectAllDataPoints(true);
                              } else {
                                setSelectAllDataPoints(false);
                              }
                            }
                          }}
                          checked={ownershipPercentage}
                        />
                      }
                      label="Ownership %"
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {

                            if (value !== undefined && value !== null) {
                              setOwnershipDate(!ownershipDate);
                              setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, ownershipDate: value } }));
                              if (longitude
                                && latitude
                                && owner
                                && operator
                                && value
                                && stockExchange
                                && ownershipPercentage
                                && seeThroughOwners
                                && jointVentures
                                && updates) {
                                setSelectAllDataPoints(true);
                              } else {
                                setSelectAllDataPoints(false);
                              }
                            }

                          }}
                          checked={ownershipDate}
                        />
                      }
                      label="Ownership Date"
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {
                            if (value !== undefined && value !== null) {
                              setStockExchange(!stockExchange);
                              setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, stockExchange: value } }));
                              if (longitude
                                && latitude
                                && owner
                                && operator
                                && ownershipDate
                                && value
                                && ownershipPercentage
                                && seeThroughOwners
                                && jointVentures
                                && updates) {
                                setSelectAllDataPoints(true);
                              } else {
                                setSelectAllDataPoints(false);
                              }
                            }
                          }}
                          checked={stockExchange}
                        />
                      }
                      label="Stock Exchange"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                  <Grid item xs={"auto"}>
                    <div className={classes.subHeading} style={{ minWidth: "100px" }}>Joint Venture:</div>{" "}
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {
                            if (value !== undefined && value !== null) {
                              setJointVentures(!jointVentures);
                              setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, joinVentures: value } }));
                              if (longitude
                                && latitude
                                && owner
                                && operator
                                && ownershipDate
                                && stockExchange
                                && ownershipPercentage
                                && seeThroughOwners
                                && value
                                && updates) {
                                setSelectAllDataPoints(true);
                              } else {
                                setSelectAllDataPoints(false);
                              }
                            }
                          }}
                          checked={jointVentures}
                        />
                      }
                      label="Joint Ventures"
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {

                            if (value !== undefined && value !== null) {
                              setSeeThroughOwners(!seeThroughOwners);
                              setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, seeThroughOwners: value } }));
                              if (longitude
                                && latitude
                                && owner
                                && operator
                                && ownershipDate
                                && stockExchange
                                && ownershipPercentage
                                && value
                                && jointVentures
                                && updates) {
                                setSelectAllDataPoints(true);
                              } else {
                                setSelectAllDataPoints(false);
                              }
                            }
                          }}
                          checked={seeThroughOwners}
                        />
                      }
                      label="Equity Owners"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                  <Grid item xs={"auto"}>
                    <div className={classes.subHeading} style={{ minWidth: "100px" }}>Updates:</div>{" "}
                  </Grid>
                  <Grid item xs={"auto"}>
                  <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event, value) => {
                              if (value !== undefined && value !== null) {
                                setUpdates(!updates);
                                setUpdateApi(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, updates: value } }));
                                if (longitude
                                  && latitude
                                  && owner
                                  && operator
                                  && ownershipDate
                                  && stockExchange
                                  && ownershipPercentage
                                  && seeThroughOwners
                                  && jointVentures
                                  && value) {
                                  setSelectAllDataPoints(true);
                                } else {
                                  setSelectAllDataPoints(false);
                                }
                              }
                            }}
                            checked={updates}
                          />
                        }
                        label="Updates"
                      />
                  </Grid>                 
                </Grid>
                </Grid>

              </Grid>
            </Grid>
         

          <Grid item xs={12}>
            <Paper elevation={0} className="table-box"
              sx={{ borderRadius: "6px", boxShadow: 0, border: "1px solid #d7d7d7", }}>
              <div className="d-flex table-optons justify-content-between m-3">
                <div>
                  <TextField
                    id="input-with-icon-textfield"
                    className="serchByKeyword mt-1"
                    size="small"
                    value={searchValue}
                    placeholder="Search by Keyword"
                    onKeyUp={handleKeyUp}
                    onChange={(e) => globalSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  {searchValue ? (
                    <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                      <ClearIcon style={{ fontSize: 10 }} />
                    </IconButton>
                  ) : ("")}
                </div>
              </div>

              <TableContainer component={Paper} elevation={0} className={classes.cruTable}
                sx={{ "& > *": { borderBottom: "unset" }, boxShadow: 0,maxHeight: "calc(100vh - 440px)" }}>
                <Table aria-label="simple table" >
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${classes.cruTheadFirst} ${classes.tableHeader}`} style={{ minWidth: 200 }}>
                        Asset
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="left" style={{ minWidth: 200 }}>
                        Owner
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="center" style={{ minWidth: 120 }}>
                        Ownership %
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="left" style={{ minWidth: 200 }}>
                        Operator
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="center" style={{ minWidth: 80 }}>
                        Priority
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="center" style={{ minWidth: 130 }}>
                        TAT
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="center" style={{ minWidth: 90 }}>
                        Remaining Days
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="left" style={{ minWidth: 100 }}>
                        Commodity
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="left" style={{ minWidth: 150 }}>
                        Country
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="left" style={{ minWidth: 150 }}>
                        Region
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="left" style={{ minWidth: 150 }}>
                        Sub Region
                      </TableCell>
                      <TableCell className={`${classes.cruThead} ${classes.tableHeader}`} align="center" style={{ minWidth: 120 }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRow?.map((row) => (
                      <EditableTable
                        updateApi={updateApi}
                        isEdited={isEdited}
                        atat={atat}
                        id={props.idforEdit}
                        setATat={setATat}
                        asJobAsset={asJobAsset}
                        setAsJobAsset={setAsJobAsset}
                        row={row}
                        setTableRow={setTableRow}
                        tableRow={tableRow}
                        stArrayData={stArrayData}
                        priorities={priorities}
                        setUpdateApi={setUpdateApi}
                        setDeletedRow={setDeletedRow}
                        deletedRow={deletedRow}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="right">
              <Grid item xs={'auto'}>
                <Button variant="contained" className="rounded-border" color="accent3"
                  onClick={() => { props.setOpen(false); }}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={'auto'}>
                <LoadingButton
                  buttonName={"Update"}
                  clickHandler={submitForEdit}
                  isLoading={loading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Alert Dialog - Confirmation for Delete */}
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openSaveConfirmation}
        onClose={handleADClose}
        description="Job already exists for the selected asset(s).Click here to view the existing job(s) or click ok to proceed"
        action={
          <Grid container spacing={2} justifyContent="right">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>Cancel</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button color="secondary"
                onClick={() => {
                  axios.post(
                    process.env.React_App_baseUrl + "job/SaveJob",
                    finalData, {
                    headers: {
                      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                    }
                  })
                    .then(function (response) {
                      handleADClose();
                      toasterSuccess("Job saved successfully");
                      props.setOpen(false);
                      props.setPageRefresh(true);
                    })
                    .catch(function (error) { ErroHandling(error, function (res) { toasterError(res) }) });
                }}>
                OK
              </Button>
            </Grid>
          </Grid>
        }
      />
      </LoaderWrapper>
    </div>
  );
};

export default EditMultipleJobForAssignJob;
const EditableTable = ({
  updateApi, setUpdateApi, priorities, row, setTableRow, tableRow, stArrayData, setDeletedRow, deletedRow
}) => {
  // Declaration of States and Component Variables Start Here
  let [edit, setEdit] = useState(false);
  let [value, setValue] = useState();
  //let [jobStartDate, setJobStartDate]=useState()
  const [deletedItem, setDeletedItem] = useState({});
  const classes = useStyles();
  const [openConfirmation, setConfirmation] = useState(false);
  const handleADClose = () => { setConfirmation(false); };
  const editData = () => { setEdit(!edit); };
  const openAlertDialog = (item) => { setConfirmation(true); setDeletedItem(item); };
  const CancelData = () => { setValue(); setEdit(!edit); };
  const [reload, setReload] = useState(false);
  const saveData = () => {

    row.priorityId = value?.priorityId ? value.priorityId : row.priorityId;
    row.tat = value?.tat ? value.tat : row.tat;
    // row.startDate = value?.startDate ? value.startDate : row.startDate;
    row.priorityName = value?.priorityName
      ? value.priorityName
      : row.priorityName;
    row.remainingDays = CommonService.getBusinessDatesCount(new Date(row.tat))
    row.isEdited = true
    setEdit(!edit);
  };

  // const checkRowValidation = (row) => {
  //   if (row.tat === null || row.tat === undefined || row.tat === "") {
  //     toasterError("TAT date can not be empty.")
  //     return false;
  //   }
  //   if (row.startDate === null || row.startDate === undefined || row.startDate === "") {
  //     toasterError("Job start date can not be empty.")
  //     return false;
  //   }
  //   if (new Date(row.tat) < new Date(row.startDate)) {
  //     toasterError("Job start date can not be greater than TAT date.")
  //     return false;
  //   }
  //   if (row.priorityId == 0 || row.priorityId === null || row.priorityId === undefined) {
  //     toasterError("priority can not be empty.")
  //     return false;
  //   }
  //   return true;
  // }


  const priorityChange = (ab) => { setValue({ ...value, priorityName: ab.label, priorityId: ab.id }); };
  const totChange = (e) => { setValue({ ...value, tat: e }); };
  //   const startDatechange = (e) => { 
  //     //setValue({ ...value, startDate: e });
  //       console.log("row",row);
  //       if(row.companyId > 0 && row.assetId > 0)
  //       {

  //         axios.get(
  //           `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
  //           headers: {
  //             CompanyId: row.companyId,
  //             AssetId: row.assetId,
  //             IsCRUCompany: row.isCRUCompany,
  //             IsCRUAsset: row.isCRUAsset,
  //             'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
  //           }
  //         }).then((res) => {
  //           if (res.status === 200) {
  //           let tempStartDate=new Date(e);

  //           tempStartDate.setDate(tempStartDate.getDate() + res.data);
  //           let newTatDate = CommonService.GetDateAsStringWithDash(tempStartDate);
  //           let remainingDaysTemp = CommonService.getDayDifference(tempStartDate);
  //           row.tat=newTatDate;
  //           row.startDate= e;
  //           row.remainingDays=remainingDaysTemp;
  //           setReload(!reload)
  //          // setEdit(!edit);

  //       }
  //     })
  //   }
  // }
  // Declaration of States and Component Variables End Here

  // Function for Delete Data
  const DeleteData = () => {
    let arr = [...tableRow];
    row.isDeleted = true
    let delRows = deletedRow;
    delRows.push(row)
    setDeletedRow(delRows)
    let index = arr.indexOf(row);
    arr.splice(index, 1);
    setTableRow(arr);
    handleADClose();
    toasterSuccess("Deleted successfully");
  };

  // Function for Handle Change
  const handleChange = (e, key, id) => {
    if (key === "isEdited") { setEdit(!edit); }
    const val = { ...updateApi };
    const role = val.jobAsset.find((r) => r.jobAssetId === id);
    role[key] = e;
    val.jobAsset.push(role);
    const item = [...new Set(val.jobAsset)];
    val.jobAsset = item;
    setUpdateApi({ ...val });
    setConfirmation(false);
  };

  const truncateString = (string, length) => {
    return (
      string.length > length ? string.substring(0, length) + "..." : string
    );
  }

  return (
    <>
      <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }} className={classes.cruTr}>
        <TableCell align="left" className={classes.cruTd}>
          {row.assetName}
        </TableCell>
        <TableCell component="th" scope="row" className={classes.cruTdFirst}>
          {row.companyName}
        </TableCell>
        <TableCell align="center" className={classes.cruTd}>
          {row.ownershipPercentage}
        </TableCell>
        <TableCell align="left" className={classes.cruTd}>
          {row.operatorName}
        </TableCell>

        {!edit ? (
          <TableCell align="center" className={classes.cruTd}>
            {row.priorityName === "High" ? (
              <Chip
                label={row.priorityName}
                size="small"
                color="warning"
                className="statusChip"
              />
            ) : undefined}{" "}
            {row.priorityName === "Low" ? (
              <Chip
                label={row.priorityName}
                size="small"
                color="success"
                className="statusChip"
              />
            ) : undefined}{" "}
            {row.priorityName === "Medium" ? (
              <Chip
                label={row.priorityName}
                size="small"
                color="info"
                className="statusChip"
              />
            ) : undefined}
            {row.priorityName === "Critical" ? (
              <Chip
                label={row.priorityName}
                size="small"
                color="error"
                className="statusChip"
              />
            ) : undefined}
          </TableCell>
        ) : (
          <TableCell align="left" className={classes.cruTd}>
            <div className={classes.smallAlign}>
              <CustomAutoComplete
                size="small"
                options={priorities}
                sx={{ width: 300 }}
                label="Priority"
                disableClearable={true}
                optionLabelKey="label"
                optionValueKey="id"
                onChange={(value) => {
                  priorityChange(value);
                }}
                defaultValueKey={["id", "label"]}
                defaultValue={[row.priorityId, row.priorityName]}
              />
            </div>
          </TableCell>
        )}

        {!edit ? (
          <TableCell align="center" className={classes.cruTd}>
            {row.tat.split("-").reverse().join("-")}
          </TableCell>
        ) : (
          <TableCell align="left" className={classes.cruTd}>
            <div style={{ width: 140 }}>
              <DatePicker
                size="small"
                fullWidth
                labelTitle="TAT"
                variant="standard"
                defaultDate={row.tat}
                label="disabled"
                onChangeDate={(e) => {

                  let selectedDate = new Date(e);
                  let date = selectedDate.getFullYear() +
                    "-" + ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                    "-" + ("0" + selectedDate.getDate()).slice(-2);
                  totChange(date);
                  row.remainingDays = CommonService.getBusinessDatesCount(new Date(date))
                }}
              />
            </div>
          </TableCell>
        )}
        <TableCell align="center" className={classes.cruTd}>
          {row.remainingDays}
        </TableCell>
        <TableCell align="left" className={classes.cruTd}>

          <Tooltip title={row.commodity} placement="top" arrow>
            <div>
              {truncateString(`${row.commodity}`, 60)}
            </div>
          </Tooltip>
        </TableCell>

        {/* {!edit ? (
          <TableCell align="center" className={classes.cruTd}>
            {row.ownershipPercentage}
            {row.startDate ? row.startDate.split("-").reverse().join("-") : null}

          </TableCell>
        ) : (
          <TableCell align="left" className={classes.cruTd}>
            <div style={{ width: 140 }}>
              <DatePicker
                size="small"
                fullWidth
                labelTitle="Job Start Date"
                variant="standard"
                defaultDate={row.startDate}
                label="disabled"
                onChangeDate={(e) => {
                  if(e!==null && new Date(e).toDateString()!=='Invalid Date') {
                    let selectedDate = new Date(e);
                    let date = selectedDate.getFullYear() +
                      "-" + ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                      "-" + ("0" + selectedDate.getDate()).slice(-2);
                    startDatechange(date);
  
                  }
                }}
              />
            </div>
          </TableCell>
        )} */}



        <TableCell align="left" className={classes.cruTd}>
          {row.countryName}
        </TableCell>
        <TableCell align="left" className={classes.cruTd}>
          {row.regionName}
        </TableCell>
        <TableCell align="left" className={classes.cruTd}>
          {row.subRegionName}
        </TableCell>

        {!edit ? (
          <TableCell align="center" className={classes.cruTd}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton aria-label="edit" color="primary">
                <EditIcon className={classes.iconSize}
                  onClick={() => handleChange(true, "isEdited", row.jobAssetId)} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton aria-label="Delete" sx={{ color: "red" }}>
                <DeleteIcon className={classes.iconSize}
                  onClick={() => openAlertDialog(row)} />
              </IconButton>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell align="center" className={classes.cruTd}>
            <Tooltip title="Submit" placement="top" arrow>
              <IconButton aria-label="Submit">
                <AcceptIcon className={classes.iconSize} onClick={saveData} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel" placement="top" arrow>
              <IconButton aria-label="Cancel" color="secondary">
                <CancelIcon className={classes.iconSize} onClick={CancelData} />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>

      {/* Alert Dialog - Confirmation for Delete */}
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openConfirmation}
        onClose={handleADClose}
        description="The asset will be deleted from the job. Are you sure you want to delete?"
        action={
          <Grid container spacing={2} justifyContent="right">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>No</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button onClick={DeleteData} color="secondary">Yes</Button>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};