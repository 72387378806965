// Default React, useState and Material Components
import React, { useState } from "react";
import { Box, Breadcrumbs, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import SideDrawer from "../../../UIComponent/Drawer";
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { toasterSuccess, toasterError } from '../../../UIComponent/Toaster/index';

// Importing Add & Edit Components
import AddEditSubRegion from "./AddEditSubRegion";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";
 
const Subregion = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [selected, SetSelected] = useState([]);
  const [formData, setFormData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [allSubRegion, setAllStatus] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [state, setState] = useState({ right: false });
  const [startSearch, setStartSearch] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [subRegionData, setSubRegionData] = useState(null);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [deleteSubRegionData, setDeleteSubRegionData] = useState(null);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [menuPermission, setMenuPermission] = useState({ allowEdit: true, allowDelete: true, });
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
  // Declaration of States and Component Variables End Here

  // Assigning Colums and UI Props
  const columns = [
    { id: "subRegionName", label: "Subregion", minWidth: 200, width: "25%", sorting: true },
    { id: "regionName", label: "Region", minWidth: 300, width: "70%", sorting: true }
  ];

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { getSubRegionData(); }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      setTotalCount(0);
      setPage(0);
      setStartSearch(true)
      searchUser(event.target.value);
      // console.log("Search Triggered from Search")
    }
  };

  // Function to get subregion data
const getSubRegionData = () => {
  // Set loading to true
  setLoading(true);
  // If reload is true, set it to false
  if (reload) { setReload(false); }
  // Create an array of parameters
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
  // Call the ApiService search method
  ApiService.search("managedata/GetSubRegionDetails?", {
    // Set the sort column and isAscending values
    sortColumn: changeSortColumnNameFormat(sorting.sortBy),
    isAscending: sorting.isAscending,
  }, prt
  )
    .then((res) => {
      // If response status is 200
      if (res.status === 200) {
        // Get the result from the response
        let result = Array.isArray(res.data) ? res.data : res.data.data || [];
        // Set all status with the result
        setAllStatus(result);
        // Set loading to false
        setLoading(false);
        // Set total count with the response data
        setTotalCount(res.data.totalCount);

      } else { 
        // If response status is not 200, call toasterError function
        toasterError(`${res.data}`); 
        // Set loading to false
        setLoading(false); 
      }
    })
    .catch((err) => {
      // Call ErroHandling function
      ErroHandling(err, function (res) {
        // Call toasterError function
        toasterError(res)
      })
    });
};

  // Function for Delete Sub Region Data
  const deleteSubRegion = () => {
    // Set loading state of delete button to true
    setDeleteBtnLoadinng(true);
    // Get data for deleting the sub-region
    let data = deleteSubRegionData;
    // Make an axios post request to delete the sub-region
    axios.post(
      process.env.React_App_baseUrl + "managedata/DeleteSubRegion", null, {
      headers: {
        id: data.subRegionId, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then((res) => {
        // If the response status is 200, show success message and get sub-region data again
        if (res.status === 200) {
          toasterSuccess("Sub-Region deleted successfully");
          getSubRegionData();
          setDeleteBtnLoadinng(false);
          setOpenDelete(false);
        } else {
           // Else show error message
          toasterError("Somthing went wrong")
          setOpenDelete(false);
          setDeleteBtnLoadinng(false);
        }
      })
      .catch((err) => {
         // Handle errors
        ErroHandling(err, function (res) {
          toasterError(res)
        });
        setDeleteBtnLoadinng(false);
      });
  };
  React.useEffect(() => {
    GetMenuService(22, function (err, res) {
      if (res) {
        // console.log(res.data)
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])

  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) { searchUser('') }
    else { searchUser(searchValue) }
  }, [sorting]);


  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) { searchUser('') }
    else {
      searchUser(searchValue)
    }
  }, [page, reload, rowsPerPage])

// This function toggles the drawer open or closed
const toggleDrawer = (open, mode) => (event, item) => {
  // Set the subregion data
  setSubRegionData(item)
  
  // If the mode is delete, set the delete subregion data and open the delete dialog
  if (mode === "Delete") { 
      setDeleteSubRegionData(item); 
      setOpenDelete(true); 
  }
  else {
    // Otherwise, set the form data and update the state
    setFormData(item);
    setState({ ...state, right: open, formData: item });
    setCrudState(mode);
  }
};

  // Function for Global Search
  const globalSearch = (value) => { setSearchValue(value); };

// This function changes the format of a field name to match the expected format
const changeSortColumnNameFormat = (fieldName) => {
  // If the fieldName is null or empty, return an empty string
  if (fieldName == null || fieldName == "") { return ""; }
  // If the fieldName is "regionname", change it to "RegionName"
  if (fieldName.toLocaleLowerCase() == "regionname") { fieldName = "RegionName"; }
  // If the fieldName is "subregionname", change it to "SubRegionName"
  if (fieldName.toLocaleLowerCase() == "subregionname") { fieldName = "SubRegionName"; }
  // Return the modified fieldName
  return fieldName;
}
  // This function is used to search for a user based on the filter value provided
const searchUser = (filterValue) => {
  // Set loading to true while the search is in progress
  setLoading(true);
  // Create an array of parameters
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
  // Call the ApiService to search for the user
  ApiService.search("managedata/GetSubRegionDetails?", {
    // Set the sorting column and order
    sortColumn: changeSortColumnNameFormat(sorting.sortBy),
    isAscending: sorting.isAscending,
    // Set the search text
    searchText: filterValue
  }, prt)
    .then((res) => {
      // If the response status is 200, set the search result, loading to false and total count
      if (res.status === 200) {
        let searchResult = Array.isArray(res.data) ? res.data : res.data.data || [];
        setAllStatus(searchResult);
        setLoading(false);
        setTotalCount(res.data.totalCount);
      } else { 
          // Else, display an error message and set loading to false
          toasterError(`${res.data}`); setLoading(false); 
      }
    })
    .catch((err) => {
      // Set loading to false and handle errors
      setLoading(false);
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
};

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Subregion</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">Subregion</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}

        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {(access.allowEdit) ? <>
                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: 16 }} />}>
                  Add Subregion
                </Button>
              </> : null}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className={classes.searchByKeyword}
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="serchByClear"
                        onClick={() => {
                          setSearchValue("");
                          getSubRegionData();
                        }}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : ("")}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">
                      Total Records: <span className={classes.totalRecord}>{totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Sub-Region Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allSubRegion ? allSubRegion : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setReload(true)
                    setSorting(e)
                  }}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Add & Edit Commodity Side Drawer */}
      <SideDrawer
        formComp={
          <AddEditSubRegion
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            // userId={null}
            hideSideDrawer={() => {
              setState({ right: false });
              getSubRegionData();
            }}
            subRegionData={subRegionData}
          />
        }
        state={state}
        setState={setState}
      />

      {/* Alert Dialog - Confirmation for Delete */}
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to delete the Sub-Region "${deleteSubRegionData?.subRegionName}" ?`}
          action={
            <Grid container alignItems="center" justifyContent="right" spacing={2} className={classes.alertDialogButton}>
              <Grid item xs={"auto"}>
                <Button onClick={() => setOpenDelete(false)}>No</Button>
              </Grid>
              <Grid item xs={"auto"}>
                <Button onClick={() => deleteSubRegion()} color="secondary">
                  {deleteBtnLoadinng ? (<CircularProgress size={25} />) : ("Yes")}
                </Button>
              </Grid>
            </Grid>
          }
        />
      ) : null}
    </Box>
  );
};

export default Subregion;