// Default React, Router and Services Components
import React, { useEffect, useState } from "react";
import { Breadcrumbs, Button, Box, CircularProgress, Grid, Link, Typography, IconButton, TextField } from "@mui/material";
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// Importing Custom Components
import FilterForm from "./FilterForm";
import SideDrawer from "../../../UIComponent/Drawer";
import SearchIcon from "@mui/icons-material/Search";
import { ScrollingDialog } from "../../../UIComponent";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import DataTable from "../../../UIComponent/DataTable/index";
import EditMultipleJobForAssignJob from "./EditMultipleJobForAssignJob";
import { LoaderWrapper } from "../../../UIComponent/Progress";
// Importing useStyles
import "./index.scss";
import useStyles from "./jobManagement.styles";

// Import Material Icons
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import InputAdornment from "@mui/material/InputAdornment";

// Import Fluent UI Icons
import { ChevronRightSmallIcon, ClearIcon } from "@fluentui/react-icons-mdl2";
import GetMenuService from '../../../Services/GetMenuService';

// Creating Data Table for Job Lists
function createData(
  id, jobid, jobName, createdOn, createdBy, noOfCompanies, noOfRecords, status, priorityName) {
  return {
    id, jobid, jobName, createdOn, createdBy, noOfCompanies, noOfRecords, status, priorityName,
  };
}

// Assigning Columns and UI Props
const columns = [
  { id: "id", label: "ID", type: "Highlight" },
  { id: "jobid", label: "Job ID", sorting: true, minWidth: 80, width: "5%" },
  { id: "jobName", label: "Job Name", sorting: true, minWidth: 300, width: "35%" },
  { id: "createdOn", label: "Created On", sorting: true, minWidth: 115, width: "10%", },
  { id: "createdBy", label: "Created By", sorting: true, minWidth: 180, width: "10%", },
  { id: "noOfCompanies", label: "No. of Companies", minWidth: 120, width: "10%", sorting: true, align: "center", },
  { id: "noOfRecords", label: "No. of Records", minWidth: 110, width: "10%", sorting: true, align: "center", },
  // { id: "status", label: "Status", minWidth: 150, width: 180, sorting: true, },
  // { id: "tat", label: "TAT", minWidth: 110, width: 110, sorting: true, align: "center", },
  { id: "priorityName", label: "Priority", type: "Chip", minWidth: 110, width: "10%", sorting: true, align: "center" },
];

const DataCollectionJobList = () => {
  // Declaration of States and Component Variables Start Here
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = useState({ right: false });
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [filterState, setFilterState] = React.useState({ right: false });
  const [reload, setReload] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("desc");
  const [selected, SetSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rawRowData, setRawRowData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteUserData, setDeleteUserData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [deleteJobNumber, setDeleteJobNumber] = useState(null);
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  const [idforEdit, setIdForEdit] = useState(null);
  const [editJobNumber, setEditJobNumber] = useState(null);
  const [id, setId] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isAllSelected, setIsSelected] = useState(false);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  let [filterValue, setFilterValue] = React.useState();
  const [searchValue, setSearchValue] = React.useState();
  const [edit, setEdit] = useState({});
  const [crudState, setCrudState] = React.useState(false);
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValues, setFilterValues] = useState();
  const [companyFilter, setCompanyFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [jobCompanies, setJobCompanies] = React.useState([])
  const [jobLocations, setJobLocations] = React.useState([])
  const [assetTypes, setAssetTypes] = React.useState(dataForFilter.assetType ? dataForFilter.assetType : [])
  const [commodityList, setCommodityList] = React.useState(dataForFilter.commodityList ? dataForFilter.commodity : [])
  const [priorityList, setPriorityList] = React.useState(dataForFilter.priority ? dataForFilter.priority : [])
  const classes = useStyles();
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })


  React.useEffect(() => {
    GetMenuService(7, function (err, res) {
      if (res) {
        // console.log(res.data)
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])
  // Declaration of States and Component Variables End Here

  const getJobData = () => {
    setLoading(true);
    if (filterValue) {
      let s = searchValue ? { searchText: searchValue } : "";
      filterValue = { ...filterValue, ...s };
    } else { filterValue = searchValue ? { searchText: searchValue } : ""; }

    // console.log({ searchText: searchValue });
    // setLoading(true);
    if (reload) {
      setReload(false);
    }
    // console.log(filterValue);
    axios
      .get(process.env.React_App_baseUrl + "job/GetManageJobs", {
        headers: {
          // sortColumn: sorting.orderBy,
          sortColumn: changeSortColumnNameFormat(sorting.sortBy),
          sourceFilter: filterValue ? encodeURIComponent(JSON.stringify(filterValue)) : "",
          isAscending: sorting.isAscending,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

        },
        params: {
          pageNumber: page + 1,
          pagesize: rowsPerPage,

        },
      })
      .then(function (response) {
        if (response.data.data) {
          setTotalCount(response.data.totalCount);
          let rowsData = []
          rowsData = response.data.data.map((row) =>
            createData(
              row.id, row.jobNo, row.jobName, row.createdOn, row.createdBy, row.noOfCompanies,
              row.noOfRecords, row.status, row.priorityName
            )
          );
          setRawRowData(rowsData);
          setLoading(false);
        }
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
  };

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      setPage(0);
      getFilterData()
      getJobData();
      setTotalCount(0);
    }
  };



  // Function for Global Search
  const globalSearch = (value) => { setSearchValue(value); };
  const searchClear = () => {
    setSearchValue("");
    setPage(0);
    setReload(!reload);
    getJobData();
  };

  const [tatDate, setTatDate] = React.useState(null)
  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    if (rawRowData.length < 1 || reload) { getJobData(); }
    var location = window.location.pathname;
  }, [filterValue]);


  React.useEffect(() => {
    if (state) {
      getJobData()
    }
  }, [filterValue, reload]);

  React.useEffect(() => {
    getJobData();
  }, [sorting, page, rowsPerPage]);

  const resetTreeFilter = () => {
    // console.log(companyFilter)
    companyFilter.forEach((item, index) => {
      item.checked = false
      if (item.children) {
        item.children.forEach((item, index) => { item.checked = false })
      }
    })
    locationFilter.forEach((item, index) => {
      item.checked = false;
      if (item.children) {
        item.children.forEach((item, index) => { item.checked = false })
        if (item.children) {
          item.children.forEach((item, index) => { item.checked = false })
        }
      }
    });
  }
  const checkboxRequired = (e, item) => { SetSelected(e); setId(e); };

  // Function for Open Delete Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") {
      setDeleteUserData(item.id);
      setDeleteJobNumber(item.jobid);
      setOpenDelete(true);
    } else { }
  };
  const toggleDrawerFilter = (open, mode, e) => (event, item) => {
    setFilterState({ ...filterState, right: open });
  };

  // Function for Open Multi Job
  const openMultiJobPage = (open, mode, e) => (event, item) => {
    setCrudState(mode);
    setIdForEdit(item.id);
    setEditJobNumber(item.jobid);
    setOpen(true);
    setScroll("paper");
  };

  // Function for Delete User
  const deleteUser = () => {
    setDeleteBtnLoadinng(true);
    let data = deleteUserData;
    axios.delete(`${process.env.React_App_baseUrl}job/DeleteJob`, {
      headers: {
        jobId: data,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
    })
      .then((res) => {
        toasterSuccess("Job deleted successfully!");
        getJobData();
        setDeleteBtnLoadinng(false);
        setOpenDelete(false);
        setReload(true);
      })
      .catch((err) => ErroHandling(err, function (res) {
        toasterError(res)
        setDeleteBtnLoadinng(false);
        setOpenDelete(false);
      }));
  };
  const redirectToRecords = (open, mode, e) => (event, item) => {
    history.push({
      pathname: "/app/data-collections/manage-job-records", state: { jobAssetId: item.id }, prevPath: location,
    });
  };

  // Function for Getting Filter Data
  const getFilterData = async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      });
      if (response.data.length === 0) {
        setDataForFilter([]);
      } else {
        setDataForFilter(response.data);
        response.data.companies.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => { item.checked = false; });
          }
        });
        response.data.location.regions.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => { item.checked = false })
            if (item.children) {
              item.children.forEach((item, index) => { item.checked = false })
            }
          }
        });
        setCompanyFilter(response.data.companies);
        setLocationFilter(response.data.location.regions);
      }
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res)
      })
    }
  };

  useEffect(() => {
    getFilterData()
  }, [reload])

  // Function for Sorting
  const changeSortColumnNameFormat = (fieldName) => {

    if (fieldName == null || fieldName == "") { return ""; }
    if (fieldName.toLocaleLowerCase() == "jobid".toLocaleLowerCase()) { fieldName = "JobNo"; }
    if (fieldName.toLocaleLowerCase() == "jobName".toLocaleLowerCase()) { fieldName = "JobName"; }
    if (fieldName.toLocaleLowerCase() == "createdOn".toLocaleLowerCase()) { fieldName = "CreatedOn"; }
    if (fieldName.toLocaleLowerCase() == "createdBy".toLocaleLowerCase()) { fieldName = "CreatedBy"; }
    if (fieldName.toLocaleLowerCase() == "noOfCompanies".toLocaleLowerCase()) { fieldName = "NoOfCompanies"; }
    if (fieldName.toLocaleLowerCase() == "noOfRecords".toLocaleLowerCase()) { fieldName = "NoOfRecords"; }
    if (fieldName.toLocaleLowerCase() == "Status".toLocaleLowerCase()) { fieldName = "Status"; }
    // if (fieldName.toLocaleLowerCase() == "tat".toLocaleLowerCase()) { fieldName = "TAT"; }
    if (fieldName.toLocaleLowerCase() == "priorityName".toLocaleLowerCase()) { fieldName = "PriorityName"; }

    return fieldName;
  }
  const AddRecords = () => {
    if (id === undefined || id.length < 1 ||  id.length > 1) {
      toasterError("please select one job to add record.")
      return;
    }
    history.push({ pathname: "/app/data-collections/create-jobs", state: id[0] });
  }

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Manage Jobs</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/create-jobs" }}>Job Management</Link>
            <Typography color="text.primary">Manage Jobs</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}


        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              <Button onClick={() => { history.push("/app/data-collections/create-jobs"); }}
                variant="contained" color="accent2" startIcon={<ArrowBackIosIcon />}>
                Back
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              {access.allowEdit && <Button onClick={() => { AddRecords() }}
                variant="contained" color="secondary" startIcon={<AddIcon />}>
                Add Records
              </Button>}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      // label="TextField"
                      className="serchByKeyword mt-1"
                      size="small"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"><SearchIcon /></InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : ("")}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Button onClick={toggleDrawerFilter(true)}
                      variant="contained" color="secondary" startIcon={<FilterAltOutlinedIcon />}>
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              <Grid item xs={12}>
                <LoaderWrapper loading={isLoading}>
                  <DataTable
                    className={classes.removeBorder}
                    rows={rawRowData ? rawRowData : []}
                    columns={columns}
                    page={page}
                    setPage={(data) => {
                      setPage(data);
                      setReload(true);
                    }}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={(data) => {
                      setRowsPerPage(data);
                      setReload(true);
                    }}
                    totalCount={totalCount}
                    isAllSelected={isAllSelected}
                    setIsSelected={setIsSelected}
                    selected={selected}
                    setSelected={SetSelected}
                    sorting={sorting}
                    setSorting={setSorting}
                    isActionButton={{
                      ...(access.allowView && {
                        view: redirectToRecords(),
                      }),
                      ...(access.allowEdit && {
                        edit: openMultiJobPage(true, "Edit"),
                      }),
                      ...(access.allowDelete && {
                        delete: toggleDrawer(true, "Delete"),
                      }),
                    }}
                    hasChip={{
                      High: { color: "warning", label: "High" },
                      Critical: { color: "error", label: "Critical" },
                      Low: { color: "success", label: "Low" },
                      Medium: { color: "info", label: "Medium" },
                    }}
                    checkboxSelection={{
                      checkbox: checkboxRequired,
                    }}
                  />
                </LoaderWrapper>
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>

        {/* Open Filter Panel */}
        <SideDrawer
          formComp={
            <FilterForm
              dataForFilter={dataForFilter}
              setDataForFilter={setDataForFilter}
              filterFinalData={filterFinalData}
              companyFilter={companyFilter}
              locationFilter={locationFilter}
              setFilterValues={setFilterValues}
              state={filterState}
              setState={setFilterState}
              setFilterValue={setFilterValue}
              filterValue={filterValue}
              reload={reload}
              setReload={setReload}
              resetTreeFilter={resetTreeFilter}
              setJobCompanies={setJobCompanies}
              setJobLocations={setJobLocations}
              setAssetTypes={setAssetTypes}
              setCommodityList={setCommodityList}
              setPriorityList={setPriorityList}
              getFilterData={getFilterData}
              setCompanyFilter={setCompanyFilter}
              setLocationFilter={setLocationFilter}
              tatDate={tatDate}
              setTatDate={setTatDate}
            />
          }
          state={filterState}
          setState={setFilterState}
          reload={reload}
          setReload={setReload}
          setFilterValue={setFilterValue}
        />

        {/* Open Edit Multiple Job Panel */}
        <ScrollingDialog
          open={open}
          setOpen={setOpen}
          scroll={scroll}
          setScroll={setScroll}
          dialogClass="darkTitle"
          dialogTitle={crudState === "Edit" ? "Edit Jobs" : "View Jobs"}
          dialogContent={
            <EditMultipleJobForAssignJob
              open={open}
              crudState={crudState}
              state={state}
              idforEdit={idforEdit}
              editJobNumber={editJobNumber}
              setState={setState}
              setOpen={setOpen}
              getData={getJobData}
              hideSideDrawer={() => {
                setOpen(false);
                setState({ right: false });
                getJobData();
              }}
            />
          }
        />

        {/* Alert Dialog - Confirmation for Delete */}
        {openDelete ? (
          <AlertDialog
            open={openDelete}
            title={"Confirmation"}
            maxWidth={"sm"}
            description={`Are you sure want to delete the Job Id : ${deleteJobNumber}?`}
            action={
              <Grid container justifyContent="right">
                <Grid item xs={'auto'}>
                  <Button onClick={() => setOpenDelete(false)} style={{ marginRight: "15px" }}>No</Button>
                </Grid>
                <Grid item xs={'auto'}>
                  {deleteBtnLoadinng ? (<CircularProgress />) :
                    (<Button onClick={deleteUser} color="secondary">Yes</Button>)}
                </Grid>
              </Grid>
            }
          />
        ) : null}
      </Grid>
    </Box>
  );
};

export default DataCollectionJobList;