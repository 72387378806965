// Default React, Router and Services Components
import React, { useEffect } from 'react'
import {
    Autocomplete, Box, Button, FormControl, FormControlLabel, FormLabel, Grid, IconButton,
    Paper, Radio, RadioGroup, TextField, Tooltip, Typography
} from '@mui/material'
import axios from 'axios';
import ErroHandling from '../../../Services/ErrorHandling';

// Importing Custom Components
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing useStyles
import useStyles from "./jobManagement.styles";

// Import Fluent UI Icons
import { AddIcon, DeleteIcon, OpenEnrollmentIcon } from '@fluentui/react-icons-mdl2'
import CurrencyFormat from 'react-currency-format';
const EditDataMetrics = (props) => {
    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    let intialReport = { reportName: "", pageNumber: "" }
    
    const {
        reportInfo, editDataMaterics, setEditDataMaterics, reportInfoDetails, openDocument,
        reportsValue, setReportsValue, setEditState, updateFromSave, getHistoryData, setIsReportUpload,
        isReportUpload, dropDown
    } = props

    React.useEffect (()=>{
      console.log(reportInfo,"reportInfo")
      console.log(reportsValue,"reportsValue")
      console.log(reportInfoDetails,"reportInfoDetails")
      if(reportsValue.length===0 &&reportInfo?.length>0){
            let recivedData = []
            recivedData.push({
                reportName : reportInfo[0].reportName,
                reportingId : reportInfo[0].assetReportId
            })                                                
      
        setReportsValue([...recivedData]);
      
      }
    },[])
    const addReport = () => {
        let data = [...reportsValue]
        data.push(intialReport)
        setReportsValue(data)
    }

    const removeReport = (index) => {
        if (reportsValue[index].valueReportMappingId) {
            axios.delete(process.env.React_App_baseUrl + 'updatejobrecord/DeleteVariableValueReportJob', {
                headers: {
                    ReportMappingId: reportsValue[index].valueReportMappingId,
                    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                }
            }).then((res) => {
                toasterSuccess("Deleted Successfully")
                let data = [...reportsValue];
                data.splice(index, 1);
                setReportsValue(data);
                getHistoryData()
            }).catch((err) => {
                {
                    ErroHandling(err, function (res) {
                        toasterError(res)
                    })
                }
            })
        } else {
            let data = [...reportsValue];
            data.splice(index, 1);
            setReportsValue(data);
        }
    }
    React.useState(() => {
        console.log("usestate",editDataMaterics)
        if (props.reportInfoDetails.length) {
            setReportsValue(props.reportInfoDetails)
            setIsReportUpload(true)
        } else {
            setReportsValue([intialReport])
        }
    }, [])
    return (

        // Layout for Profile
        <Box className={classes.popupArea}>
            {/* Title for Side Drawer */}
            <Box className={classes.popupHeading}>
                <Typography id="modal-modal-title" variant="h5">
                    Edit
                </Typography>
            </Box>

            {/* Data Metrics Edit Form */}
            <Grid container spacing={4} justifyContent="flex-start" alignItems="self-end">
                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                        <Grid item xs="auto">
                            <FormLabel id="demo-row-radio-buttons-group-label">Do you have reports to upload
                            </FormLabel>
                        </Grid>
                        <Grid item xs="auto" textAlign="right">
                            <FormControl>
                                <RadioGroup row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group">
                                    <FormControlLabel value={"YES"}
                                        control={<Radio value={"YES"} checked={isReportUpload}
                                            onChange={(e) => { setIsReportUpload(true) }}
                                        />}
                                        label={"YES"}
                                    />
                                    <FormControlLabel value={"NO"}
                                        control={<Radio value={"NO"} checked={!isReportUpload}
                                            onChange={(e) => {
                                                if (reportsValue.length) { toasterError("Please delete reports") }
                                                else { setIsReportUpload(false) }
                                            }}
                                        />}
                                        label={"NO"}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                {isReportUpload ?
                    <Grid item xs={12}>
                        <Box className={classes.addReport2} sx={{ mt: -2 }}>
                            {reportsValue.map((item, index) => {  
                                
                                // if((item.reportName===undefined || item.reportName===null || item.reportName==="" ) && index===0){
                                //     item.reportName=''
                                //     item.reportingId =0                                  
                                // }else{
                                //     if(reportInfo){
                                //         if(reportInfo.length>0){
                                //             if(reportInfo[0].reportName){
                                //                 item.reportName=reportInfo[0].reportName;
                                //             }
                                //             if(reportInfo[0].assetReportId){
                                //                 item.reportingId = reportInfo[0].assetReportId;
                                //             }
                                //         }                                        
                                //     } 
                                    
                                // }                              
                                return (
                                    <>
                                        <Grid container spacing={2} xs={12}>
                                            <Grid item xs={6}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={dropDown.year}
                                                    value={editDataMaterics?.lastUpdateYear}
                                                    fullWidth
                                                    onChange={(e, item) => {
                                                        setEditDataMaterics({
                                                            ...editDataMaterics,
                                                            lastUpdateYearId: item.value,
                                                            lastUpdateYear: item.label
                                                        });

                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Last Update Year"
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={dropDown.reportingPeroid}
                                                    value={editDataMaterics?.lastUpdateQuater}
                                                    fullWidth
                                                    onChange={(e, item) => {
                                                        setEditDataMaterics({
                                                            ...editDataMaterics,
                                                            lastUpdateQuaterId: item.value,
                                                            lastUpdateQuater: item.label
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Last Update Quarter"
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                                            <Grid item xs={12} sx={{ mt: 1, mb: -3 }}>
                                                <Paper elevation={0} className={classes.recordTitleArea}>
                                                    <Box className={classes.recordTitle}>#{index + 1}</Box>
                                                    <Box className={classes.recordLine}></Box>
                                                    <Box className={classes.recordAction}>
                                                        <Tooltip title="Delete" placement="top" arrow>
                                                            <IconButton color="error">
                                                                <DeleteIcon onClick={() => removeReport(index)} style={{ fontSize: "20px" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </Paper>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={reportInfo}
                                                    fullWidth
                                                    value={{  reportName:  item.reportName }}
                                                    getOptionLabel={(option) => option.reportName}
                                                    
                                                    onChange={(e, value) => {
                                                        let recivedData = reportsValue                                                   
                                                            
                                                            recivedData[index].reportName = value ?  value.reportName :''
                                                            recivedData[index].reportingId = value ? value.assetReportId : 0
                                                        
                                                        setReportsValue([...recivedData]);
   
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Report Name" required variant="standard" />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <Grid container spacing={2} justifyContent="flex-start" alignItems="self-end">
                                                        <Grid item xs="auto">
                                                            <Button
                                                                variant="contained"
                                                                color="accent5"
                                                                size="small"
                                                                disabled={item.reportName ?  false : true}
                                                                onClick={() => { openDocument(item.reportName, 1) }}
                                                                startIcon={
                                                                    <OpenEnrollmentIcon style={{ fontSize: 16 }} />
                                                                }
                                                            >
                                                                Open Document
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs>
                                                            <TextField
                                                                id="standard-basic"
                                                                label="Page No"
                                                                required
                                                                className={classes.pageNumberWidth}
                                                                value={item.pageNumber}
                                                                onChange={(e) => {
                                                                    let recivedData = reportsValue
                                                                    recivedData[index].pageNumber = Number(e.target.value)
                                                                    setReportsValue([...recivedData]);
                                                                }}
                                                                variant="standard"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <Grid container spacing={1} justifyContent="flex-start" alignItems="self-end">
                                                    </Grid>
                                                </Box>
                                            </Grid>

                                        </Grid>
                                    </>
                                )
                            })}
                            <Grid item xs={12} textAlign="right">
                                <Button variant="contained" color="accent7" size="small" onClick={addReport}
                                    startIcon={<AddIcon style={{ fontSize: 16 }} />}>
                                    Add Report
                                </Button>
                            </Grid>
                        </Box>
                    </Grid> : ''}

                <Grid item xs={12} sx={{ mt: -2 }}>
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={dropDown.valuation}
                            value={editDataMaterics?.valuation}
                            fullWidth
                            onChange={(e, item) => {
                                setEditDataMaterics({
                                    ...editDataMaterics,
                                    valuation: item.label,
                                    valuationId: item.value,
                                    value: "",
                                    minValue: "",
                                    maxValue: ""
                                });
                            }}
                            renderInput={(params) => (
                                <TextField {...params} required label="Valuation" variant="standard" />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mt: -2 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} justifyContent="flex-start" alignItems="self-end">
                            {editDataMaterics.valuationId === 4 ? <><Grid item xs>
                                <div className='d-flex'>
                                        <Typography sx={{ color: "#898b8c" }}>Min Value&nbsp;</Typography><Typography sx={{ color: "red" }}> *</Typography>
                                    </div>
                                    <CurrencyFormat
                                        style={{ outline: "none", width: "100%", border: "none", borderBottom: "solid 1px #dee0e3" }}
                                        id="standard-basic"
                                        value={editDataMaterics?.minValue || ""}
                                        onChange={(e) => {
                                            setEditDataMaterics({ ...editDataMaterics, minValue: e.target.value, value: "" })
                                        }}
                                        variant="standard"
                                        thousandSeparator={true}
                                    />
                              
                            </Grid>
                                <Grid item xs>
                                <div className='d-flex'>
                                        <Typography sx={{ color: "#898b8c" }}>Max Value&nbsp;</Typography>
                                    </div>
                                    <CurrencyFormat
                                        style={{ outline: "none", width: "100%", border: "none", borderBottom: "solid 1px #dee0e3" }}
                                        id="standard-basic"
                                        value={editDataMaterics?.maxValue || ""}
                                        onChange={(e) => {
                                            setEditDataMaterics({ ...editDataMaterics, maxValue: e.target.value,value: "" })
                                        }}
                                        variant="standard"
                                        thousandSeparator={true}
                                    />
                                </Grid>
                            </> : <>
                                <Grid item xs={12}>
                                    <div className='d-flex'>
                                        <Typography sx={{ color: "#898b8c" }}>Value&nbsp;</Typography><Typography sx={{ color: "red" }}> *</Typography>
                                    </div>
                                    <CurrencyFormat
                                        style={{ outline: "none", width: "100%", border: "none", borderBottom: "solid 1px #dee0e3" }}
                                        id="standard-basic"
                                        value={editDataMaterics?.value || ""}
                                        onChange={(e) => {
                                            setEditDataMaterics({ ...editDataMaterics, value: e.target.value ,minValue:"",maxValue:""})
                                        }}
                                        variant="standard"
                                        thousandSeparator={true}
                                    />
                                </Grid>
                            </>}

                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="standard-multiline-static"
                        label="Comment"
                        multiline
                        maxRows={2}
                        value={editDataMaterics.comments}
                        onChange={(e) => {
                            setEditDataMaterics({ ...editDataMaterics, comments: e.target.value })
                        }}
                        variant="standard"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="flex-start" alignItems="self-end">
                        <Grid item sm="auto">
                            <Button variant="contained" color="primary" onClick={updateFromSave}>
                                Save
                            </Button>
                        </Grid>
                        <Grid item sm="auto">
                            <Button variant="contained" color="accent3" onClick={() => { setEditState(false) }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default EditDataMetrics