import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
// import history from "../../../Routes/History";
import { useHistory } from "react-router-dom";

import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import GetMenuService from "../../../Services/GetMenuService";
// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import { ScrollingDialog } from "../../../UIComponent";
import { LoaderWrapper } from "../../../UIComponent/Progress";
// Importing useStyles
import useStyles from "./QualityManagement.styles";

// Import Fluent UI Icons
import {
  ChevronLeftIcon,
  ChevronRightSmallIcon,
  ClearIcon,
  SearchIcon,
} from "@fluentui/react-icons-mdl2";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Button } from "@mui/material";
import SideDrawer from "../../../UIComponent/Drawer/index";
import FilterJobRecords from "../JobManagement/FilterJobRecords";
import QualityFilter from "./FilterForm";
import { FilterIcon } from "@fluentui/react-icons-mdl2";
import DatePicker from "../../../UIComponent/DatePicker";
import { TRUE } from "sass";
import QualityCheckDataTable from "../../../UIComponent/QualityCheckDataTable";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import QualityQCPassFilter from "./QualityQCPassFilters";
const QualityManagementQcPass = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [selecteddate, setSelectedDate] = useState(
    getInitialDate() //new Date().setDate(new Date().getDate() - 1)
  );
  const [selectedtodate, setSelectedToDate] = useState(
    getInitialDate() //new Date().setDate(new Date().getDate() - 1)
  );
  const [senddate, setSendDate] = useState();
  const [allRows, setAllRows] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totaljobssubmitted, setTotalJobsSubmitted] = useState(0);
  const [sampled, setSampled] = useState(0);
  const [notsampled, setNotSampled] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [selected, SetSelected] = useState([]);
  const [viewDataButtonDisable, setviewDataButtonDisable] = useState(true);
  const [idforEdit, setIdForEdit] = useState(null);
  const [editJobNumber, setEditJobNumber] = useState(null);
  const [isAllSelected, setIsSelected] = useState(false);
  const [AlljobAssetId, setAllJobAsset] = useState([]);
  const [state, setState] = React.useState({ right: false });
  const [menuPermission, setMenuPermission] = useState({
    allowView: true,
    allowEdit: true,
    allowDelete: true,
  });
  const [crudState, setCrudState] = React.useState(false);
  const [formData, setFormData] = useState([]);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [reload, setReload] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [jobSelection, setJobSelection] = useState([]);
  const [jobIds, setJobIds] = useState(
    props.location.state ? props.location.state : 68
  );
  const [jId, setJId] = useState(null);
  const [access, setAccess] = React.useState({ allowView: false });
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValues, setFilterValues] = React.useState(null);
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [jobLocations, setJobLocations] = React.useState([]);
  const [resetFilter, setResetFilter] = React.useState(false);
  const [tatDate, setTatDate] = React.useState(null);
  const [jobCompletionDate, setJobCompletionDate] = React.useState(null);
  // Declaration of States and Component Variables Start Here
  const [checkBoxValue, setCheckBoxValue] = useState([]);
  //console.log(access, "accesssss");
  // Declaration of React Hooks Start Here
  // This code is used to perform an effect when the component mounts or updates
  React.useEffect(() => {
    // Get Quality List based on search value
    GetQualityList(searchValue);
    //GetAllAsset(searchValue);
    // Set Job Id from props location state
    setJId(props.location.state);
    // Make an API call to get job selection data
    axios
      .get(
        process.env.React_App_baseUrl +
          "QualityManagement/GetJobNoNameDropdownforQuality",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then(function (response) {
        // Set job selection data
        setJobSelection(response.data);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });
      // Set reset filter to false
    setResetFilter(false);
  }, [reload, resetFilter]);
// This useEffect hook will run when filterValues changes
  React.useEffect(() => {
    // If filterValues is truthy, call GetQualityList with null argument
    if (filterValues) {
      GetQualityList(null);
    }
  }, [filterValues]);

  // Function for Sorting and Pagination
  React.useEffect(() => {
    GetMenuService(11, function (err, res) {
      if (res) {
        // console.log(res.data, "accesssss");
        setAccess(res.data);
      } else {
        toasterError(err);
      }
    });
  }, []);
  React.useEffect(() => {
    GetQualityList(searchValue);
  }, [sorting, page, rowsPerPage]);

  // This function resets the tree filter
  const resetTreeFilter = () => {
    // Loop through companyFilter and set checked to false
    companyFilter.forEach((item, index) => {
      item.checked = false;
      // If there are children, loop through them and set checked to false
      if (item.children) {
        item.children.forEach((item, index) => {
          item.checked = false;
        });
      }
    });
    // Loop through locationFilter and set checked to false
    locationFilter.forEach((item, index) => {
      item.checked = false;
      // If there are children, loop through them and set checked to false
      if (item.children) {
        item.children.forEach((item, index) => {
          item.checked = false;
        });
        // If there are grandchildren, loop through them and set checked to false
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
        }
      }
    });
    // Set TatDate and JobCompletionDate to null
    setTatDate(null);
    setJobCompletionDate(null);
  };

  React.useEffect(() => {
    getFilterData();
  }, [reload]);
  // This function is used to get filter data from the API
  const getFilterData = async () => {
    // Making a GET request to the API
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      // Checking if the response data length is 0
      if (response.data.length === 0) {
        setDataForFilter([]);
      } else {
        // Setting the data for filter
        setDataForFilter(response.data);
        // Looping through the companies and setting checked to false
        response.data.companies.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
          }
        });
        // Looping through the locations and setting checked to false
        response.data.location.regions.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
            if (item.children) {
              item.children.forEach((item, index) => {
                item.checked = false;
              });
            }
          }
        });
        // Setting the company and location filters
        setCompanyFilter(response.data.companies);
        setLocationFilter(response.data.location.regions);
      }
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res);
      });
    }
  };
  // Assigning Columns and UI Props
  const columns = [
    { id: "jobAssetId", label: "Id", type: "Highlight" },
    {
      id: "jobNoAndName",
      label: "Job No & Name",
      width: 300,
      minWidth: 320,
      //sorting: true,
    },
    /*     {
      id: "jobName",
      label: "Job Name",
      minWidth: 300,
      width: 320,
      sorting: true,
    }, */
    { id: "asset", label: "Asset", minWidth: 200, width: 200, sorting: true },
    { id: "company", label: "Company", minWidth: 250, width: 250, sorting: true },
    {
      id: "tat",
      label: "TAT",
      minWidth: 120,
      width: 120,
      sorting: true,
      align: "center",
    },
    {
      id: "remainingDays",
      label: "Remaining Days",
      minWidth: 120,
      width: 120,
      sorting: true,
      align: "center",
    },
    {
      id: "completionDate",
      label: "Job Completion Date",
      minWidth: 150,
      width: 150,
      sorting: true,
      align: "center",
    },
    {
      id: "completedBy",
      label: "Completed By",
      minWidth: 200,
      width: 200,
      sorting: true,
      align: "left",
    },
  ];

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    // Check if the key pressed is backspace and the target element is not a button
    if (
      (event.keyCode === 8 || event.which === 8) &&
      event.target.nodeName !== "BUTTON"
    ) {
      // If searchValue length is 0, do nothing
      if (searchValue.length === 0) {
      }
    }
    // Check if the key pressed is enter and the target element is not a button
    if (
      (event.keyCode === 13 || event.which === 13) &&
      event.target.nodeName !== "BUTTON"
    ) {
      // Call searchReport function with the value of the target element
      searchReport(event.target.value);
    }
  };

  // Function for Sorting
  const changeSortColumnNameFormat = (fieldName) => {
    console.log("KK", fieldName);
    if (fieldName == null || fieldName == "") {
      return "";
    }
    if (fieldName.toLocaleLowerCase() == "jobIdAndName".toLocaleLowerCase()) {
      fieldName = "JobIdAndName";
    }
    if (fieldName.toLocaleLowerCase() == "company".toLocaleLowerCase()) {
      fieldName = "Company";
    }
    if (fieldName.toLocaleLowerCase() == "asset".toLocaleLowerCase()) {
      fieldName = "Asset";
    }
    if (fieldName.toLocaleLowerCase() == "operator".toLocaleLowerCase()) {
      fieldName = "Operator";
    }
    if (
      fieldName.toLocaleLowerCase() == "ownershipPercentage".toLocaleLowerCase()
    ) {
      fieldName = "OwnershipPercentage";
    }
    if (fieldName.toLocaleLowerCase() == "completionDate".toLocaleLowerCase()) {
      fieldName = "CompletionDate";
    }
    if (fieldName.toLocaleLowerCase() == "tat".toLocaleLowerCase()) {
      fieldName = "TAT";
    }
    if (fieldName.toLocaleLowerCase() == "remainingDays".toLocaleLowerCase()) {
      fieldName = "RemainingDays";
    }
    if (fieldName.toLocaleLowerCase() == "completedBy".toLocaleLowerCase()) {
      fieldName = "CompletedBy";
    }

    return fieldName;
  };

  // Function for Pagination after search
  const searchReport = (filterValue) => {
    setTotalCount(0);
    setPage(0);
    setLoading(true);
    setReload(!reload);
  };

  // Function for Searching the grid
  const globalSearch = (value) => {
    setSearchValue(value);
  };
  function containsOnlyNumbers(str) {
    return /^[0-9,]+$/.test(str);
  }
  const selectAJob = (e, value) => {
    if (value != null && value != "undefined") {
      setJobIds(value.id);
      setJId(value.id);
      let jobName = value.name;
      const jobNameArr = jobName.split(" - ");
      GetQualityList(jobNameArr[0]);
    } else {
      GetQualityList("");
    }
    props.location.state = 1;
  };

  // Function for Getting Quality Data
  const GetAllAsset = (filterValue) => {
    setLoading(true);
    let SubmissionDate = selecteddate;
    let SubmissionToDate = selectedtodate;
    let QualityJobID = location.state ? location.state.JobIdCheck : 0;
    let searchData = { search: filterValue };
    let SubmissionDateSerach = {
      JobsStartDate: SubmissionDate.startDate
        ? SubmissionDate.startDate
        : SubmissionDate,
    };
    let SubmissionDateToSerach = {
      JobsEndDate: SubmissionToDate.endDate
        ? SubmissionToDate.endDate
        : SubmissionToDate,
    };
    let jobId = { jobId: QualityJobID };
    let filterData = {
      ...filterValues,
      ...searchData,
      ...SubmissionDateSerach,
      ...SubmissionDateToSerach,
      ...jobId,
    };

    let prt = [`pageNumber=${page + 1}`, `pageSize=50`]; //, `jobId=${filterValue.jobid ?? 0}`
    ApiService.search(
      `OwnershipChangeQualityManagement/GetCompanyOwnershipQCPassJobs?`,
      {
        // searchText: filterValue,
        sourceFilter: encodeURIComponent(JSON.stringify(filterData)),
        // jobId: jobIDSearch,
        sortColumn: changeSortColumnNameFormat(sorting.sortBy),
        isAscending: sorting.isAscending,
        Authorization: localStorage.getItem("token")
          ? "Bearer " + localStorage.getItem("token")
          : null,
      },
      prt
    ).then((res) => {
      if (res.status === 200) {
        let jobAssetId = res.data.data.map((element) => {
          return element.jobAssetId;
        });
        setAllJobAsset(jobAssetId);
      } else {
        toasterError(`${res.data}`);
      }
    });
  };
  const location = useLocation();

  // This function is used to get the list of quality jobs based on the filter value
  const GetQualityList = (filterValue) => {
    // Set loading to true
    setLoading(true);

    let SubmissionDate = selecteddate;
    let SubmissionToDate = selectedtodate;
    let QualityJobID = location.state ? location.state.JobIdCheck : 0;
    let searchData = { search: filterValue };
    let SubmissionDateSerach = {
      JobsStartDate: SubmissionDate.startDate
        ? SubmissionDate.startDate
        : SubmissionDate,
    };
    let SubmissionDateToSerach = {
      JobsEndDate: SubmissionToDate.endDate
        ? SubmissionToDate.endDate
        : SubmissionToDate,
    };
    let jobId = { jobId: QualityJobID };
    let filterData = {
      ...filterValues,
      ...searchData,
      ...SubmissionDateSerach,
      ...SubmissionDateToSerach,
      ...jobId,
    };
    console.log("ENV", filterData, selectedtodate);
    // Declare page number and page size
    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`]; //, `jobId=${filterValue.jobid ?? 0}`
    // Call ApiService to search for data
    ApiService.search(
      `QualityManagement/GetQCPassJobs?`,
      {
        // searchText: filterValue,
        sourceFilter: encodeURIComponent(JSON.stringify(filterData)),
        // jobId: jobIDSearch,
        sortColumn: changeSortColumnNameFormat(sorting.sortBy),
        isAscending: sorting.isAscending,
        Authorization: localStorage.getItem("token")
          ? "Bearer " + localStorage.getItem("token")
          : null,
      },
      prt
    )
      .then((res) => {
        // Check if response status is 200
        if (res.status === 200) {
          let jobAssetId = res.data.data.map((element) => {
            return element.jobAssetId;
          });
          // Check if all rows are selected
          if (isAllSelected) {
            let tempSelected = selected;
            jobAssetId.forEach((element) => {
              let index = selected.indexOf(element);
              if (index === -1) {
                selected.push(element);
              }
            });
          }
          // Set all rows, total count, total jobs submitted, sampled and not sampled
          console.log(res.data.data, "resssssss");
          setAllRows(res.data.data);
          setTotalCount(res.data.totalCount);
          setTotalJobsSubmitted(res.data.totalCount);
          setSampled(res.data.totalCount);
          setNotSampled(res.data.totalCount);
          setLoading(false);
        } else {
          toasterError(`${res.data}`);
          setLoading(false);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
        setLoading(false);
      });
  };
  console.log("CLEARD", selected);
  // console.log(allRows, "allRows");
  // Function for Search Clear
  const searchClear = () => {
    setSearchValue("");
    setPage(0);
    setReload(!reload);
  };

  const toggleFilter = (open) => (event) => {
    setState({ ...state, right: open });
    setCrudState(true);
  };

  React.useEffect(() => {
    if (selected) {
      AddCheckBoxValue(selected);
    } else {
      RemoveAllCheckboxValue();
    }
  }, [selected]);
  // This function adds the value to the checkboxValue array
  const AddCheckBoxValue = (value) => {
    // Create a temporary array to store the values
    let tempStoredValue = [];
    tempStoredValue = checkBoxValue;
    // Check if both the arrays have values
    if (tempStoredValue && value) {
      // Check if the value is an array
      if (value instanceof Array) {
        // Loop through the array and add the elements to the tempStoredValue array
        value.forEach((element) => {
          let index = tempStoredValue.indexOf(element);
          // Check if the element is not present in the array
          if (index === -1) {
            tempStoredValue.push(element);
          }
        });
      } else if (typeof value === "number") {
        // Check if the value is a number
        let index = tempStoredValue.indexOf(value);
        // Check if the element is not present in the array
        if (index === -1) {
          tempStoredValue.push(value);
        }
      }
    }
    // Set the checkboxValue array with the new values
    setCheckBoxValue(tempStoredValue);
  };
  // This function removes all the values from the checkboxValue array
  const RemoveAllCheckboxValue = () => {
    // Set the checkboxValue array to empty
    setCheckBoxValue([]);
  };
  // Function for Open View and Edit Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "View") {
      history.push({
        pathname: "/app/ownership-changes/manage-quality-details",
        state: item,
        allRow: allRows,
      });
    } else {
      setIdForEdit(item.id);
      setEditJobNumber(item.JobNo);
      setOpen(true);
      setScroll("paper");
    }
  };

  console.log(columns, "allRows");
  /*   const handleMovetoqc = () => {
    if (senddate === undefined || selected.length === 0) {
      toasterError("Please Make a Selection!");
    }
  }; */

  /*   function getInitialDate() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const daysUntilFriday = dayOfWeek === 1 ? 3 : dayOfWeek === 0 ? 2 : 1; // Monday is 1, Sunday is 0
    const initialDate = new Date(today);
    initialDate.setDate(today.getDate() - daysUntilFriday);
    return initialDate;
  } */

  function getInitialDate() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    console.log("CL", dayOfWeek);
    // If today is Monday, set the date to the previous Friday
    if (dayOfWeek === 1) {
      const previousFriday = new Date(today);
      previousFriday.setDate(today.getDate() - 3); // Assuming Monday is the first day of the week (0-indexed).
      return previousFriday;
    }
    // For all other days, set the date to the previous day
    const previousDate = new Date(today);
    previousDate.setDate(today.getDate() - 1);
    return previousDate;
  }

  const handleMovetoqc = () => {
    // Check if senddate is undefined or no selection has been made
    if (senddate === undefined || selected.length === 0) {
      toasterError("Please Make a Selection!");
    }
    // If selection has been made, proceed with the code
    if (selected.length) {
      let SubmissionDate = selecteddate;
      let SubmissionToDate = selectedtodate;
      // Get JobIdCheck from location state
      let QualityJobID = location.state ? location.state.JobIdCheck : 0;
      let searchData = { search: searchValue };
      let SubmissionDateSerach = {
        JobsStartDate: SubmissionDate.startDate
          ? SubmissionDate.startDate
          : SubmissionDate,
      };
      let SubmissionDateToSerach = {
        JobsEndDate: SubmissionToDate.endDate
          ? SubmissionToDate.endDate
          : SubmissionToDate,
      };
      let jobId = { jobId: QualityJobID };
      // Create filterData object by combining filterValues, searchData, SubmissionDateSerach, SubmissionDateToSerach and jobId
      let filterData = {
        ...filterValues,
        ...searchData,
        ...SubmissionDateSerach,
        ...SubmissionDateToSerach,
        ...jobId,
      };
      console.log("JSON", filterData);
      // Post request to assign jobs to Merit QC Passed
      axios
        .post(
          `${process.env.React_App_baseUrl}QualityManagement/AssignToMeritQCPassCD`,
          selected,
          {
            headers: {
              selectAll: isAllSelected === true ? true : false,
              //jobId: location.state.JobIdCheck ? location.state.JobIdCheck : 0,

              sourceFilter:
                isAllSelected === true
                  ? encodeURIComponent(JSON.stringify(filterData))
                  : location.state
                  ? location.state.JobIdCheck
                  : 0,

              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
          }
        )
        .then((res) => {
          setReload(true);
          GetQualityList();
          toasterSuccess(`${selected?.length}job(s) Marked as Merit QC Passed`);
          history.push({
            pathname: "/app/data-collections/quality-management",
            state: {
              totalCount: totalCount,
            },
          });
          setIsSelected(false);
        })
        .catch((err) => {
          {
            ErroHandling(err, function (res) {
              toasterError(res);
            });
          }
          //setOpenDialog(false);
        });
      //setIsOpenAssign(false);
    } else {
      toasterError("");
      //setIsOpenAssign(false);
    }
  };

  // Function to reset the selected date
  const handleResetDate = () => {
    setSelectedDate(null);
    setSelectedToDate(null);
  };
  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={8} className="page-title">
          <h2>Mark QC Pass</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/data-collections/source-management" }}
            >
              Home
            </Link>
            <Typography color="text.primary">
            Manage Company Data Collections
            </Typography>
            <Link
              underline="hover"
              color="inherit"
              to={{ pathname: "/app/data-collections/quality-management" }}
            >
              Quality Management
            </Link>
            <Link
              underline="hover"
              color="inherit"
              to={{
                pathname:
                  "/app/data-collection/quality-management-qualitycheck",
              }}
            >
              Mark QC Pass
            </Link>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={4}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                color="accent2"
                startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
                onClick={() => {
                  history.push("/app/data-collections/quality-management");
                }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.assignDetails2}
              sx={{ mt: 0, pt: 0 }}
            >
              <Grid item xs={12} lg={9}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="self-start"
                    alignItems="self-end"
                  >
                    <Grid item xs="auto">
                      <Grid item xs={"auto"}>
                        <DatePicker
                          labelTitle="Job Submission From Date"
                          variant="standard"
                          past={false}
                          maxDate={true}
                          defaultDate={selecteddate}
                          onChangeDate={(e) => {
                            let selectedDate = new Date(e);
                            let date =
                              selectedDate.getFullYear() +
                              "-" +
                              ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + selectedDate.getDate()).slice(-2);
                            setSelectedDate({
                              ...selecteddate,
                              startDate: date,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs="auto">
                      <DatePicker
                        labelTitle="Job Submission To Date"
                        variant="standard"
                        past={false}
                        defaultDate={selectedtodate}
                        minDates={
                          selecteddate?.startDate
                            ? selecteddate?.startDate
                            : selecteddate
                        }
                        onChangeDate={(e) => {
                          let selectedDate = new Date(e);
                          let date =
                            selectedDate.getFullYear() +
                            "-" +
                            ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + selectedDate.getDate()).slice(-2);
                          setSelectedToDate({
                            ...selectedtodate,
                            endDate: date,
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={9} lg={10} xl={5}>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="left"
                        sx={{ mt: 1 }}
                      >
                        <Grid item xs={"auto"}>
                          <Button
                            variant="contained"
                            className="rounded-border"
                            color="accent5"
                            onClick={(e) => {
                              setSendDate(selecteddate);
                              GetQualityList();
                            }}
                            //disabled={!assignToQc}
                          >
                            Apply
                          </Button>

                          <Button
                            variant="contained"
                            className="rounded-border"
                            color="accent3"
                            sx={{ ml: 2 }}
                            onClick={handleResetDate}
                            // disabled={!assignToQc}
                          >
                            Reset
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {/*  <Grid item xs={12} sm={6} md={2} textAlign="center">
                Total Jobs Submitted
                <div className={classes.assignValues}>{totaljobssubmitted}</div>
              </Grid>

              <Grid item xs={12} sm={6} md={2} textAlign="center">
                Sampled
                <div className={classes.assignValues}>{sampled}</div>
              </Grid>

              <Grid item xs={12} sm={6} md={2} textAlign="center">
                Not Sampled
                <div className={classes.assignValues}>{notsampled}</div>
              </Grid> */}
            </Grid>
            <Box className={classes.addBorder}>
              {/* Search and Filter Options Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      size="small"
                      fullWidth
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="searchByClear"
                        onClick={searchClear}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item xs={12} sm={8} textAlign="right">
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="right"
                    >
                      <Grid item xs="auto">
                        <Typography variant="subtitle2">
                          Total Jobs:&nbsp;
                          <span className={classes.totalRecord}>
                            {totalCount}
                          </span>
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="subtitle2">
                          {" "}
                          Selected Jobs:&nbsp;
                          <span className={classes.totalRecord}>
                            {isAllSelected === true
                              ? totalCount
                              : selected?.length}
                          </span>
                        </Typography>
                      </Grid>
                      {console.log(
                        "Move",
                        isAllSelected === true
                          ? AlljobAssetId?.length
                          : selected?.length
                      )}
                      <Grid item>
                        <Button
                          onClick={handleMovetoqc}
                          variant="contained"
                          color="accent7"
                          startIcon={<TaskAltIcon />}
                          disabled={
                            senddate === undefined || selected.length === 0
                          }
                        >
                          Move to QC Pass
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          onClick={toggleFilter(true)}
                          variant="contained"
                          color="secondary"
                          startIcon={<FilterIcon style={{ fontSize: 16 }} />}
                        >
                          Filter
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <LoaderWrapper loading={isLoading}>
                  <QualityCheckDataTable
                    className={classes.removeBorder}
                    rows={allRows ? allRows : []}
                    columns={columns}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    totalCount={totalCount}
                    isAllSelected={isAllSelected}
                    setIsSelected={setIsSelected}
                    selected={selected}
                    setSelected={SetSelected}
                    sorting={sorting}
                    setSorting={setSorting}
                    setviewDataButtonDisable={setviewDataButtonDisable}
                    selectableRows
                    hasChip={{
                      High: { color: "warning", label: "High" },
                      Critical: { color: "error", label: "Critical" },
                      Low: { color: "success", label: "Low" },
                      Medium: { color: "info", label: "Medium" },
                    }}
                  />
                </LoaderWrapper>
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
      <SideDrawer
        formComp={
          <QualityQCPassFilter
            dataForFilter={dataForFilter}
            filterFinalData={filterFinalData}
            companyFilter={companyFilter}
            locationFilter={locationFilter}
            setFilterValue={setFilterValues}
            state={state}
            setState={setState}
            resetTreeFilter={resetTreeFilter}
            setJobCompanies={setJobCompanies}
            setJobLocations={setJobLocations}
            setLocationFilter={setLocationFilter}
            setCompanyFilter={setCompanyFilter}
            setResetFilter={setResetFilter}
            setDataForFilter={setDataForFilter}
            getFilterData={getFilterData}
            reload={reload}
            setReload={setReload}
            setTatDate={setTatDate}
            tatDate={tatDate}
            setJobCompletionDate={setJobCompletionDate}
            jobCompletionDate={jobCompletionDate}
            JobIdCheck={location?.state ? location.state.JobIdCheck : 0}
          />
        }
        state={state}
        setState={setState}
        crudState={crudState}
      />
    </Box>
  );
};

export default QualityManagementQcPass;
