import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  breadCrumbsIcon: {
    fontSize: 10,
    marginLeft: 3
  },
    addBorder: {
    borderRadius: '6px',
    border: "1px solid rgba(224, 224, 224, 1) !important",
  },
    tableTopPart: {
    padding: theme.spacing(2)
  },
  popupArea: {
    padding: theme.spacing(3),
  },
  popupHeading: {
    padding: theme.spacing(0, 0, 3, 0),
  },
}));

export default useStyles;