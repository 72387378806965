import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const AutocompleteText = (props) => {
  const {
    option,
    dropShow,
    lable,
    isRequired,
    onChangeRole,
    readOnly,
    mode,
    defaultValue,
    nestedData,
    labelName,
    feildName
  } = props;

  // console.log("option: ", option);
  // console.log("nestedData: ", nestedData);
  const [value, setValue] = React.useState(null);
  const [checked, setChecked] = React.useState([]);
  const [opt1Select, setOpt1Select] = React.useState(false);
  const [opt2Select, setOpt2Select] = React.useState(false);
  const [selectedReg, setSelectedReg] = React.useState([]);
  const [selectedSubReg, setSelectedSubReg] = React.useState(null);
  const [selectedCun, setSelectedCun] =React.useState(null)
  const [subRegions, setSubRegions] = React.useState([]);

  const defaultProps = {
    options: option,
    getOptionLabel: (option) => option[dropShow],
  };

  const handleChange = (value) => {
    setValue(value);
    onChangeRole(value);
  };

  const handleCon = (value) => {
    //console.log("value: ", value);
    setSelectedReg(value);
    let regs = [];
    if (value[0]) {
      nestedData[0].forEach((subReg) => {
        if (subReg.regionId === value[0].id) {
          regs.push(subReg);
        }
      });
      setSubRegions(regs);
    }
  };
  const handleChange1 = (event) => {
    setOpt1Select(event.target.checked);
  };

  const handleChange2 = (event, subReg) => {
    //console.log("checked: ", subReg);
    setSelectedSubReg(subReg);
    setOpt2Select(event.target.checked);
  };

  const handleChange3 = (event) => {
  };

  const nestedChild = (opt, subReg) => {
    // console.log('opt: ', opt);
    // console.log('selectedReg: ', selectedReg);
    // console.log('selectedSubReg: ', selectedSubReg);
    return nestedData[1].map((cun) =>
    selectedCun ||( selectedSubReg && cun && opt.id === selectedReg[0].id && cun.regionId ===selectedSubReg.regionId && cun.subRegionId ===selectedSubReg.id)?
        (<FormControlLabel
          label={cun.countryName}
          control={
            <Checkbox onChange={(event) => handleChange3(event, cun)} />
          }
        />
        ):null
    );
  };

  const children = (opt) => {
    return subRegions.map((subReg,i) =>
      (selectedSubReg && selectedSubReg.id) || opt.id === selectedReg[0].id ? (
        <>
        <FormControlLabel
          key={i}
          label={subReg.subRegionName}
          control={
            <Checkbox onChange={(event) => handleChange2(event, subReg)} />
          }
        />
        <Box
        sx={{ display: "flex", flexDirection: "column", ml: 3 }}
      >
        {nestedChild(opt, subReg)}
      </Box>
        </>
      ) : null
    );
  };

  return (
    <Stack spacing={1}>
      {mode === "nestedAuto" ? (
        <>
          <Autocomplete
            multiple
            id="disable-close-on-select"
            variant="standard"
            fullWidth
            options={option}
            disableCloseOnSelect
            onChange={(e, value) => handleCon(value)}
            getOptionLabel={(option) => option[dropShow]}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Box sx={{ display: "flex", flexDirection: "column", ml: 2 }}>
                  <FormControlLabel
                    label={option[dropShow]}
                    control={
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        onChange={handleChange1}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                      />
                    }
                  />
                  {opt1Select ? (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", ml: 3 }}
                    >
                      {children(option)}
              
                    </Box>
                  ) : null}
                </Box>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Checkboxes"
                placeholder="Favorites"
                variant="standard"
              />
            )}
          />
        </>
      ) : (
        <Autocomplete
          value={value ? value : defaultValue}
          disablePortal
          required={isRequired}
          fullWidth
          id="clear-on-escape"
          clearOnEscape
          variant="standard"
          name={lable}
          readOnly={readOnly}
          {...defaultProps}
          onChange={(e, value) => handleChange(value)}
          renderInput={(params) => (
            <TextField
              required={isRequired}
              fullWidth
              {...params}
              name={feildName} 
              value={lable}
              variant="standard"
              label={labelName} 
              InputLabelProps={{ style: { color: "black" } }}
            />
          )}
        />
      )}
    </Stack>
  );
};

export default AutocompleteText;
