// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Box, Breadcrumbs, Button, Chip, Collapse, Grid, IconButton, InputAdornment, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Typography
} from "@mui/material";
import { Link } from 'react-router-dom';
import axios from "axios";
import { visuallyHidden } from "@mui/utils";
// import { truncateString } from "../../UIComponent/shared/utility";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError } from "../../../UIComponent/Toaster";
import GetMenuService from "../../../Services/GetMenuService";
// Importing Custom Components
import EditAsset from "./DataCollectionEditAsset";
import FilterForm from "./DataCollectionFilterForm";
import EditSource from "./DataCollectionEditSource";
import CreateSource from "./DataCollectionCreateSource";
import SideDrawer from "../../../../src/UIComponent/Drawer/index";
import { LoaderWrapper } from "../../../UIComponent/Progress";

// Importing useStyles
// import "./index.scss";
import useStyles from "./DataCollectionSourceManagement.styles";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, EditIcon, FilterIcon, LinkIcon, RemoveIcon, SearchIcon } from "@fluentui/react-icons-mdl2";
import BaseLayout from "../../../UIComponent/BaseLayout";

// Creating Data Table for Manage Users
function createData(company, commodity, noOfAssets, priority, status, companyId, isCRUCompany, isJiraCreated) {
  return { company, commodity, noOfAssets, priority, status, companyId, isCRUCompany, isJiraCreated };
}
function createSubData(
  asset, assetType, country, region, subRegion, commodity, status, isCRUAsset, isJiraTicketCreated,
  assetId, linktoAssetPlatform, linktoCAT, linktoEAT) {
  return {
    asset, assetType, country, region, subRegion, commodity, status, isCRUAsset, isJiraTicketCreated,
    assetId, linktoAssetPlatform, linktoCAT, linktoEAT,
  };
}
function Row(props) {
  const { row, filterValue, searchValue, access } = props;
  const [open, setOpen] = React.useState(false);
  const [subData, setSubData] = React.useState([]);
  const value = "";
  const classes = useStyles();
// Function to edit a row in either page 1 or page 2
  const edit = (row, page) => {
    // If the page is page 1
    if (page === 1) {
      // Set state3 right to true and set crudState3 to true
      props.setState3({ ...props.state3, right: true });
      props.setCrudState3(true);
      // Set companyId to the row's companyId
      props.setCompanyId(row.companyId);
    } else {
      // Set state4 right to true and set crudState4 to true
      props.setState4({ ...props.state4, right: true });
      props.setCrudState4(true);
      // Set assetId to the row's assetId
      props.setAssetId(row.assetId);
    }
  };
// This function takes a string and a length as parameters
// If the length of the string is greater than the given length, it will return a truncated version of the string with "..." at the end
// Otherwise, it will return the original string
  const truncateString = (string, length) => {
    return (
      string.length > length ? string.substring(0, length) + "..." : string
    );
  }

  // console.log(access, "accessprops")

  return (
    // Inner Table
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} hover>
        <TableCell align="center" className={classes.tableCell} style={{ minWidth: 60, width: 60 }}>
          <IconButton aria-label="expand row" size="small"
            onClick={() => {
              var searchData = searchValue
              var filterData = filterValue

              if (filterData) {
                let s = searchData ? { searchText: searchData } : "";
                filterData = { ...filterValue, ...s };
              } else { filterData = searchValue ? { searchText: searchValue } : ""; }
              axios.get(process.env.React_App_baseUrl + "source/GetAssets", {
                headers: {
                  CompanyId: row.companyId, IsCRUCompany: row.isCRUCompany,
                  sourceFilter: filterData ? encodeURIComponent(JSON.stringify(filterData)) : "",
                  'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

                },
                params: { pageNumber: 1, pagesize: 1000, },
              })
                .then(function (response) {
                  const rowsData = response.data.data.map((row) =>
                    createSubData(row.asset, row.assetType, row.country, row.region, row.subRegion,
                      row.commodity, row.status, row.isCRUAsset, row.isJiraTicketCreated, row.assetId,
                      row.linktoAssetPlatform, row.linktoCAT, row.linktoEAT)
                  );
                  setSubData(rowsData);
                })
                .catch(function (error) {
                  ErroHandling(error, function (res) {
                    toasterError(res)
                  })
                });
              setOpen(!open);
            }}
          >
            {open ? (<RemoveIcon style={{ fontSize: 14, color: "#000000" }} />) :
              (<AddIcon style={{ fontSize: 14, color: "#000000" }} />)}
          </IconButton>
        </TableCell>
        <TableCell className={classes.tableCell} component="th" scope="row">{row.company}</TableCell>
        <TableCell className={classes.tableCell} align="left">
          {row.commodity === null ? '-' :
            <Tooltip title={row.commodity} placement="top" arrow>
              <div>
                {truncateString(`${row.commodity}`, 95)}
              </div>
            </Tooltip>
          }
        </TableCell>
        <TableCell className={classes.tableCell} align="center"><div className="pr-4">{row.noOfAssets}</div></TableCell>
        <TableCell className={classes.tableCell} align="center">
          <div className="pr-4">
            {" "}
            {row.priority == "High" ? (
              <Chip label={row.priority} size="small" color="warning" className="statusChip" />
            ) : undefined}{" "}
            {row.priority == "Low" ? (
              <Chip label={row.priority} size="small" color="success" className="statusChip" />
            ) : undefined}{" "}
            {row.priority == "Medium" ? (
              <Chip label={row.priority} size="small" color="info" className="statusChip" />
            ) : undefined}
            {row.priority == "Critical" ? (
              <Chip label={row.priority} size="small" color="error" className="statusChip" />
            ) : undefined}
          </div>
        </TableCell>
        <TableCell className={classes.tableCell} align="center"><div className="pr-4">{row.status}</div></TableCell>
        <TableCell className={classes.tableCell} align="center">
          {access.allowEdit && (row.status === "Pending") ?
            (<Tooltip title="Edit" placement="top" arrow>
              <IconButton aria-label="edit" size="small" color="primary">
                {/* {console.log(row, "historyRow")} */}
                {row.isJiraCreated === false && <EditIcon onClick={() => edit(row, 1)} />}
              </IconButton>
            </Tooltip>) : ("")
          }
        </TableCell>
      </TableRow>
      <TableRow className="plusMinus-table">
        <TableCell style={{ padding: 0, borderBottomColor: "#ebebeb", background: "#f9f9f9" }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '10px 10px 10px 60px' }}>
              <div className={classes.fullWidth}>
                <TableContainer sx={{ maxHeight: 350 }}>
                  <Table size="small" aria-label="purchases" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 150, width: 150 }} align="left">Asset</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 180, width: 220 }} align="left">Asset Type</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 150, width: 150 }} align="left">Country</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 150, width: 150 }} align="left">Region</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 150, width: 150 }} align="left">Sub Region</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 320, width: 350 }} align="left">Commodity</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 120, width: 120 }} align="center">Status</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 110, width: 110 }} align="center">Link</TableCell>
                        <TableCell className={classes.tableHeader} style={{ minWidth: 60, width: 60 }} align="center"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subData.map((historyRow) => (
                        <TableRow key={historyRow.asset} hover>
                          <TableCell className={classes.tableCell} component="th" scope="row">{historyRow.asset}</TableCell>
                          <TableCell className={classes.tableCell} align="left">{historyRow.assetType}</TableCell>
                          <TableCell className={classes.tableCell}>{historyRow.country}</TableCell>
                          <TableCell className={classes.tableCell} align="left">{historyRow.region}</TableCell>
                          <TableCell className={classes.tableCell} align="left">{historyRow.subRegion}</TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            <Tooltip title={historyRow.commodity} placement="top" arrow>
                              <div>
                                {truncateString(`${historyRow.commodity}`, 95)}
                              </div>
                            </Tooltip>
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">{historyRow.status}</TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            {!historyRow.linktoAssetPlatform ? (
                              <Tooltip title="Link Not Available" placement="top" arrow>
                                <span><LinkIcon style={{ fontSize: "20px", color: "#cdcdcd" }} /></span>
                              </Tooltip>
                            ) : (
                              <Tooltip title={historyRow.linktoAssetPlatform} placement="top" arrow>
                                <Link href={`${historyRow.linktoAssetPlatform}`} target="_blank">
                                  <LinkIcon style={{ fontSize: "20px", color: "blue" }} />
                                </Link>
                              </Tooltip>
                            )}

                            {!historyRow.linktoCAT ? (
                              <Tooltip title="Link Not Available" placement="top" arrow>
                                <span><LinkIcon style={{ fontSize: "20px", color: "#cdcdcd", marginLeft: "10px" }} /></span>
                              </Tooltip>
                              // <LinkIcon style={{ fontSize: "20px", color: "orange", marginLeft: "10px", }} />
                            ) : (
                              <Tooltip title={historyRow.linktoCAT} placement="top" arrow>
                                <Link style={{ marginLeft: "10px" }} href={`${historyRow.linktoCAT}`} target="_blank">
                                  <LinkIcon style={{ fontSize: "20px", color: "orange" }} />
                                </Link>
                              </Tooltip>
                            )}
                            {!historyRow.linktoEAT ? (
                              <Tooltip title="Link Not Available" placement="top" arrow>
                                <span><LinkIcon style={{ fontSize: "20px", color: "#cdcdcd", marginLeft: "10px" }} /></span>
                              </Tooltip>
                              // <LinkIcon style={{ fontSize: "20px", color: "grey", marginLeft: "10px", }} />
                            ) : (
                              <Tooltip title={historyRow.linktoEAT} placement="top" arrow>
                                <Link style={{ marginLeft: "10px" }} href={`${historyRow.linktoEAT}`} target="_blank">
                                  <LinkIcon style={{ fontSize: "20px", color: "grey" }} />
                                </Link>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell} align="center">
                            {access.allowEdit && (historyRow.status === "Pending") ? (
                              <Tooltip title="Edit" placement="top" arrow>
                                <IconButton aria-label="edit" size="small" color="primary">
                                  {/* {console.log(historyRow, "historyRow")} */}
                                  {
                                    historyRow.isJiraTicketCreated === false && <EditIcon style={{ color: "#000000" }} onClick={() => edit(historyRow, 2)} />}
                                </IconButton>
                              </Tooltip>) : ("-")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment >
  );
}

const SourceManagement = () => {
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = React.useState({ right: false, });
  const [state2, setState2] = React.useState({ right: false, });
  const [state3, setState3] = React.useState({ right: false, });
  const [state4, setState4] = React.useState({ right: false, });
  const [companyDropDown, setCompanyDropDown] = React.useState([]);
  const [assetTypeDropDown, setAssetTypeDropDown] = React.useState([]);
  const [regionDropDown, setRegionDropDown] = React.useState([]);
  const [subRegionDropDown, setSubRegionDropDown] = React.useState([]);
  const [countryDropDown, setCountryDropDown] = React.useState([]);
  const [commodityDropDown, setCommodityDropDown] = React.useState([]);
  const [statusDropDown, setStatusDropDown] = React.useState([]);
  const [crudState3, setCrudState3] = React.useState(false);
  const [crudState4, setCrudState4] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState();
  const [company, setCompany] = React.useState([]);
  let [filterValue, setFilterValue] = React.useState();
  const [crudState, setCrudState] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [companyId, setCompanyId] = React.useState();
  const [assetId, setAssetId] = React.useState();
  const [refresh, setRefresh] = React.useState(false);
  const [previousSelect, setPreviousSelect] = React.useState(null);
  const [previousSelectSource, setPreviousSelectSource] = React.useState(null);
  const [totalCount, setTotalCount] = React.useState(0);
  const [sorting, setSorting] = useState({ orderBy: "", order: false });
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValues, setFilterValues] = useState();
  const [companyFilter, setCompanyFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [reload, setReload] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleRequestSort = (event, property) => {
    const isAscending = sorting.orderBy === property && sorting.order;
    setSorting({ orderBy: property, order: isAscending ? false : true });
    setPage(0);
  };
  const [jobCompanies, setJobCompanies] = React.useState([])
  const [jobLocations, setJobLocations] = React.useState([])
  const [assetTypes, setAssetTypes] = React.useState(dataForFilter.assetType ? dataForFilter.assetType : [])
  const [commodityList, setCommodityList] = React.useState(dataForFilter.commodityList ? dataForFilter.commodity : [])
  const [priorityList, setPriorityList] = React.useState(dataForFilter.priority ? dataForFilter.priority : [])
  // Declaration of States and Component Variables End Here

  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
  // console.log(access, "access data")
  React.useEffect(() => {
    GetMenuService(5, function (err, res) {
      if (res) {
        // console.log(res.data)
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])


  // Declaration of React Hooks Start Here

  React.useEffect(() => { 
    getCompany();
   }, [refresh, filterValue, sorting, reload, page]);
  React.useEffect(() => { 
    getCompany(); 
  }, [reload]);
  React.useEffect(() => {
    getFilterData();
    getAssetTypeDropDownForFilter();
    getCompany();
    axios.get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDownFilter",
      {
        headers: {
          isNewSource: false, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      }
    )
      .then(function (response) { setCompanyDropDown(response.data); })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });

    ////asset type DropDown API
    axios.get(process.env.React_App_baseUrl + "DropDown/GetAssetTypeDropDownFilter", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) { setAssetTypeDropDown(response.data); })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });


    /// Region Dropdown API calling

    axios.get(process.env.React_App_baseUrl + "DropDown/GetRegionDropDownFilter", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) { setRegionDropDown(response.data); })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });

    /////Commodity Drop DOwn API
    axios.get(process.env.React_App_baseUrl + "DropDown/GetCommodityDropDownFilter", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) { setCommodityDropDown(response.data); })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
    //////////status dropdown API
    axios.get(process.env.React_App_baseUrl + "DropDown/GetStatusDropDownFilter", {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) { setStatusDropDown(response.data); })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
  }, []);
  // Declaration of React Hooks End Here

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      setTotalCount(0);
      setPage(0);
      getFilterData();
      getCompany();
    }

  };

  // Getting Company Details
  const getCompany = () => {
    setLoading(true)
    if (filterValue) {
      let s = searchValue ? { searchText: searchValue } : "";
      filterValue = { ...filterValue, ...s };
    } else { filterValue = searchValue ? { searchText: searchValue } : ""; }

    axios.get(process.env.React_App_baseUrl + "source/GetCompanies", {
      headers: {
        sortColumn: sorting.orderBy,
        sourceFilter: filterValue ? encodeURIComponent(JSON.stringify(filterValue)) : "",
        isAscending: sorting.order,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

      },
      params: { pageNumber: page + 1, pagesize: rowsPerPage, },
    })
      .then(function (response) {
        setTotalCount(response.data.totalCount);
        const rowsData = response.data.data.map((row) =>
          createData(
            row.company,
            row.commodity,
            row.noOfAssets,
            row.priority,
            row.status,
            row.companyId,
            row.isCRUCompany,
            row.isJiraCreated
          )
        );
        setCompany(rowsData);
        setLoading(false)
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)

        })
        setLoading(false)
      });
  };

  // Function for Global Search
  const globalSearch = (value) => { setSearchValue(value); };
  const searchClear = () => {
    setSearchValue("");
    axios.get(process.env.React_App_baseUrl + "source/GetCompanies", {
      headers: {
        sourceFilter: filterValue ? encodeURIComponent(JSON.stringify(filterValue)) : "", 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
      params: { pageNumber: 1, pagesize: rowsPerPage },
    })
      .then(function (response) {
        setTotalCount(response.data.totalCount);
        const rowsData = response.data.data.map((row) =>
          createData(
            row.company,
            row.commodity,
            row.noOfAssets,
            row.priority,
            row.status,
            row.companyId,
            row.isCRUCompany,
            row.isJiraCreated
          )
        );
        setCompany(rowsData);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res)
        })
      });
  };

  // Function for Reset Filter
  const [assetTypeDD, setAssetTypeDD] = useState([]);
  const resetTreeFilter = () => {
    let comFilter = companyFilter
    let loFilter = locationFilter
    comFilter.forEach((item, index) => {
      item.checked = false;
      if (item.children) {
        item.children.forEach((item, index) => { item.checked = false; });
      }
    });
    loFilter.forEach((item, index) => {
      item.checked = false;
      if (item.children) {
        item.children.forEach((item, index) => { item.checked = false; });
        if (item.children) {
          item.children.forEach((item, index) => { item.checked = false; });
        }
      }
    });
    // console.log('comFilter', comFilter)
    // console.log('loFilter', loFilter)
    setCompanyFilter([...comFilter])
    setLocationFilter([...loFilter])
    // console.log(companyFilter, "test CHecker")
  };

  // Function for Getting Filter Data
  const getFilterData = async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer" + localStorage.getItem("token") : null
        }
      }
      );
      if (response.data.length === 0) {
        setDataForFilter([]);
      } else {
        setDataForFilter(response.data);
        console.log(response.data, "response.data")
        response.data.companies.forEach((item, index) => {
          item.checked = false;
          item.isPartialSelected = false
          if (item.children) {
            item.children.forEach((item, index) => { item.checked = false; item.isPartialSelected = false });
          }
        });
        response.data.location.regions.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((items, index) => {
              items.checked = false;
              if (items.children) {
                items.children.forEach(kt => {
                  kt.checked = false
                })
              }
            });
          }
        });
        // console.log(response.data, "Filter Data")
        setCompanyFilter(response.data.companies);
        setLocationFilter(response.data.location.regions);
      }
    } catch (error) { }
  };

  // Function for Getting Asset Type Data
  const getAssetTypeDropDownForFilter = async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetAssetTypeDropDownFilter`, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }
      );
      if (response.data.length === 0) { setAssetTypeDD([]); }
      else { setAssetTypeDD(response.data); }
    } catch (error) { }
  };

  // Function for Open Panel
  const toggleDrawer = (open) => (event) => { setState({ ...state, right: open }); setCrudState(true); };

  // Function for Open Filter Panel
  const toggleDrawer2 = (open) => (event) => {
    setState2({ ...state2, right: open });
    setShowFilter(true);
  };

  const handleChangePage = (event, newPage) => { 
    setPage(newPage);
   };
  const handleChangeRowsPerPage = (event) => { 
    setRowsPerPage(event.target.value);
     setPage(0);
     setReload(!reload);
   };

  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Source Management</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Typography color="text.primary">Source Management</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              <Button onClick={toggleDrawer(true)} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: 14 }} />}>
                Create Source
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    {/* <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      size="small"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton aria-label="Add" className="serchByClear"
                        style={{ position: "absolute", top: "5px", right: "0px" }}
                        onClick={searchClear}>
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>) : ("")} */}

                    <TextField
                      id="input-with-icon-textfield"
                      // label="TextField"
                      className="serchByKeyword mt-1"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {/* {console.log('searchVal', searchValue)} */}
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="serchByClear"
                        // style={{ position: "absolute", top: "5px", right: "0px" }}
                        onClick={() => {
                          searchClear()
                        }}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : ("")}

                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Button onClick={toggleDrawer2(true)} variant="contained" color="secondary"
                      startIcon={<FilterIcon style={{ fontSize: 16 }} />}>
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}


              {/* Producers Table /> */}
              <Grid item xs={12}>
                <LoaderWrapper loading={isLoading}>
                  <TableContainer component={Paper} elevation={0} className={classes.container}>
                    <Table aria-label="collapsible table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableHeader} style={{ minWidth: 60, width: 60 }} align="center" />
                          <TableCell className={classes.tableHeader} style={{ minWidth: "300px", width: "20%" }}
                            sortDirection={sorting.orderBy === "Company" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel
                              active={sorting.orderBy === "Company"}
                              direction={sorting.orderBy === "Company" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "Company")}>
                              Company
                              {sorting.orderBy === "Company" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableHeader} style={{ minWidth: "350px", width: "35%" }} align="left">
                            Commodity
                          </TableCell>
                          <TableCell className={classes.tableHeader} style={{ minWidth: "140px", width: "15%" }} align="center"
                            sortDirection={sorting.orderBy === "NoOfAssets" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel active={sorting.orderBy === "NoOfAssets"}
                              direction={sorting.orderBy === "NoOfAssets" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "NoOfAssets")}>
                              No. of Assets
                              {sorting.orderBy === "NoOfAssets" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableHeader} style={{ minWidth: "140px", width: "15%" }} align="center"
                            sortDirection={sorting.orderBy === "Priority" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel active={sorting.orderBy === "Priority"}
                              direction={sorting.orderBy === "Priority" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "Priority")}>
                              Priority
                              {sorting.orderBy === "Priority" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableHeader} style={{ minWidth: "100px", width: "15%" }} align="center"
                            sortDirection={sorting.orderBy === "Status" ? sorting.order ? "asc" : "desc" : false}>
                            <TableSortLabel active={sorting.orderBy === "Status"}
                              direction={sorting.orderBy === "Status" ? sorting.order ? "asc" : "desc" : "asc"}
                              onClick={(e) => handleRequestSort(e, "Status")}>
                              Status
                              {sorting.orderBy === "Status" ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {sorting.order === false ? "sorted descending" : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                          <TableCell className={classes.tableHeader} style={{ minWidth: "60px", width: "60px" }} align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {company.map((row) => (
                          <Row
                            key={row?.companyId}
                            row={row}
                            setState={setState}
                            setCrudState={setCrudState}
                            setState3={setState3}
                            setCrudState3={setCrudState3}
                            setState4={setState4}
                            setCrudState4={setCrudState4}
                            filterValue={filterValue}
                            searchValue={searchValue}
                            setCompanyId={setCompanyId}
                            setAssetId={setAssetId}
                            access={access}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 25, 50, 100]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </LoaderWrapper>
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
        <SideDrawer
          formComp={
            <CreateSource
              state={state}
              setState={setState}
              companyDropDown={companyDropDown}
              assetTypeDropDown={assetTypeDropDown}
              regionDropDown={regionDropDown}
              commodityDropDown={commodityDropDown}
              statusDropDown={statusDropDown}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          }
          state={state}
          setState={setState}
          crudState={crudState}
        />
        <SideDrawer
          formComp={
            <EditSource
              state={state3}
              setState={setState3}
              companyDropDown={companyDropDown}
              assetTypeDropDown={assetTypeDropDown}
              regionDropDown={regionDropDown}
              commodityDropDown={commodityDropDown}
              statusDropDown={statusDropDown}
              companyId={companyId}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          }
          state={state3}
          setState={setState3}
          crudState={crudState3}
        />

        <SideDrawer
          formComp={
            <EditAsset
              state={state4}
              setState={setState4}
              companyDropDown={companyDropDown}
              assetTypeDropDown={assetTypeDropDown}
              regionDropDown={regionDropDown}
              commodityDropDown={commodityDropDown}
              statusDropDown={statusDropDown}
              assetId={assetId}
              companyId={companyId}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          }
          state={state4}
          setState={setState4}
          crudState={crudState4}
        />

        <SideDrawer
          formComp={
            <FilterForm
              dataForFilter={dataForFilter}
              filterFinalData={filterFinalData}
              companyFilter={companyFilter}
              locationFilter={locationFilter}
              setLocationFilter={setLocationFilter}
              setCompanyFilter={setCompanyFilter}
              setFilterValues={setFilterValues}
              assetTypeDD={assetTypeDD}
              setFilterFinalData={setFilterFinalData}
              setFilterValue={setFilterValue}
              lastSelectedSource={previousSelectSource}
              LastSelection={previousSelect}
              state={state2}
              setState={setState2}
              reload={reload}
              setReload={setReload}
              resetTreeFilter={resetTreeFilter}
              setJobCompanies={setJobCompanies}
              setJobLocations={setJobLocations}
              setAssetTypes={setAssetTypes}
              setCommodityList={setCommodityList}
              setPriorityList={setPriorityList}
              setDataForFilter={setDataForFilter}
              getFilterData={getFilterData}
            />
          }
          state={state2}
          setState={setState2}
          crudState={showFilter}
          reload={reload}
          setReload={setReload}
          setFilterValue={setFilterValue}
        />
      </Grid>
    </Box>
  );
};

export default SourceManagement;