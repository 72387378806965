const menu = [
    // 01 - Manage Users/Roles
    {
        'isEdit': false,
        'isAdd': true,
        'isView': true,
        'isDelete': false,
        'name': 'ManageUsers/Roles',
        'icon': 'users',
        'children': [{
            'isEdit': false,
            'isAdd': true,
            'isView': true,
            'isDelete': false,
            'name': 'Manage Users',
            'route': '/app/manage-users',
            'icon': '',
            'children': []
        }, {
            'isEdit': false,
            'isAdd': true,
            'isView': true,
            'isDelete': false,
            'name': 'Manage Roles',
            'route': '/app/manage-roles',
            'icon': '',
            'children': []
        }]
    },

    // 02 - Manage Company Data Collections

    {
        'isEdit': false,
        'isAdd': true,
        'isView': true,
        'isDelete': false,
        'name': 'Manage Company Data Collections',
        'icon': 'area-chart',
        'children': [
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Source Management',
                'route': '/app/data-collections/source-management',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Job Management',
                'icon': '',
                'children': [
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Create Jobs',
                        'route': '/app/data-collections/create-jobs',
                        'icon': '',
                        'children': []

                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Manage Jobs',
                        'route': '/app/data-collections/manage-jobs',
                        'icon': '',
                        'children': []

                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Jobs Status',
                        'route': '/app/data-collections/assign-jobs',
                        'icon': '',
                        'children': []

                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Manage Job Records',
                        'route': '/app/data-collections/manage-job-records',
                        'icon': '',
                        'children': []

                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Duplicate Jobs',
                        'route': '/app/data-collections/duplicate-jobs',
                        'icon': '',
                        'children': []

                    },

                ]
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Quality Management',
                'route': '/app/data-collections/quality-management',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Reporting Calendar',
                'route': '/app/data-collections/reporting-calendar',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Search & Export',
                'route': '/app/data-collections/search-export',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Dashboard and Reports',
                'icon': '',
                'children': [
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Aging Report',
                        'route': '/app/data-collections/aging-report',
                        'icon': '',
                        'children': []
                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Status Report',
                        'route': '/app/data-collections/status-report',
                        'icon': '',
                        'children': []
                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Jobs in Queue',
                        'route': '/app/data-collections/jobs-in-queue',
                        'icon': '',
                        'children': []
                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Jira Tickets',
                        'route': '/app/data-collections/jira-tickets',
                        'icon': '',
                        'children': []
                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Weekly Status Report',
                        'route': '/app/data-collections/weekly-status-report',
                        'icon': '',
                        'children': []
                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Merit QC Report',
                        'route': '/app/data-collections/merit-qc-report',
                        'icon': '',
                        'children': []
                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'CRU Review Report',
                        'route': '/app/data-collections/cru-review-report',
                        'icon': '',
                        'children': []
                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Merit QC Failed Report',
                        'route': '/app/data-collections/qc-failed-report',
                        'icon': '',
                        'children': []
                    },


                ],
            },
        ]
    },

    // 03 - Manage Company Ownership Collection	
    {
        'isEdit': false,
        'isAdd': true,
        'isView': true,
        'isDelete': false,
        'name': 'Manage Company Ownership Changes',
        'icon': 'ownership',
        'children': [
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Source Management',
                'route': '/app/ownership-changes/source-management',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Job Management',
                // 'route': '/app/manage-users',
                'icon': '',
                'children': [
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Create Jobs',
                        'route': '/app/ownership-changes/create-job',
                        'icon': '',
                        'children': []

                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Manage Jobs',
                        'route': '/app/ownership-changes/manage-jobs',
                        'icon': '',
                        'children': []

                    },
                    // {
                    //     'isEdit': false,
                    //     'isAdd': true,
                    //     'isView': true,
                    //     'isDelete': false,
                    //     'name': 'Jobs Status',
                    //     'route': '/app/ownership-changes/assign-jobs',
                    //     'icon': '',
                    //     'children': []
                    // },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Manage Job Records',
                        'route': '/app/ownership-changes/manage-job-records',
                        'icon': '',
                        'children': []

                    },
                    // {
                    //     'isEdit': false,
                    //     'isAdd': true,
                    //     'isView': true,
                    //     'isDelete': false,
                    //     'name': 'Duplicate Jobs',
                    //     'route': '/app/ownership-changes/duplicate-jobs',
                    //     'icon': '',
                    //     'children': []
                    // },
                ]
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Quality Management',
                'route': '/app/ownership-changes/quality-management',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Search & Export',
                'route': '/app/ownership-changes/search-export',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Dashboard and Reports',
                'icon': '',
                'children': [
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Status Report',
                        'route': '/app/ownership-changes/status-report',
                        'icon': '',
                        'children': []
                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Weekly Status Report',
                        'route': '/app/ownership-changes/weekly-status-report',
                        'icon': '',
                        'children': []
                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Merit QC Report',
                        'route': '/app/ownership-changes/merit-qc-report',
                        'icon': '',
                        'children': []
                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'CRU Review Report',
                        'route': '/app/ownership-changes/cru-review-report',
                        'icon': '',
                        'children': []
                    },
                    {
                        'isEdit': false,
                        'isAdd': true,
                        'isView': true,
                        'isDelete': false,
                        'name': 'Scoring Report',
                        'route': '/app/ownership-changes/scoring-report',
                        'icon': '',
                        'children': []
                    },
                ],
            },
        ]
    },

    // 05 - Manage Data
    {
        'isEdit': false,
        'isAdd': true,
        'isView': true,
        'isDelete': false,
        'name': 'Manage Data',
        'icon': 'data',
        'children': [
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Commodity',
                'route': '/app/commodity',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Country',
                'route': '/app/country',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Currency',
                'route': '/app/currency',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Frequency',
                'route': '/app/frequency',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Region',
                'route': '/app/region',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Subregion',
                'route': '/app/sub-region',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Priority',
                'route': '/app/priority',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Status',
                'route': '/app/status',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Unit (CRU)',
                'route': '/app/unit-cru',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Unit (Report)',
                'route': '/app/unit-report',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Variable (CRU)',
                'route': '/app/variable-cru',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Variable (Report)',
                'route': '/app/variable-report',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Asset Variable (Count)',
                'route': '/app/asset-variable-count',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'TAT Configuration',
                'route': '/app/tat-configuration',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Variable Type',
                'route': '/app/variable-type',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Scoring Parameters',
                'route': '/app/scoring-parameters',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Legal Entity Types',
                'route': '/app/legal-entity-types',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Stock Exchanges',
                'route': '/app/stock-exchanges',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Change Types',
                'route': '/app/change-types',
                'icon': '',
                'children': []
            },
            {
                'isEdit': false,
                'isAdd': true,
                'isView': true,
                'isDelete': false,
                'name': 'Company & Stock Exchange',
                'route': '/app/company-stock-exchange',
                'icon': '',
                'children': []
            },
            // {
            //     'isEdit': false,
            //     'isAdd': true,
            //     'isView': true,
            //     'isDelete': false,
            //     'name': 'Owner & Correct Owner',
            //     'route': '/app/owner-correct-ownerName',
            //     'icon': '',
            //     'children': []
            // },

        ]
    },

]

export default menu;
