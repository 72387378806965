// Default React, Router and Services Components
import React, { useState } from "react";
import { Box, Button, Checkbox, Chip, FormControlLabel, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterSuccess, toasterError } from "../../../UIComponent/Toaster";

// Importing Custom Components
import DatePicker from "../../../UIComponent/DatePicker";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { LoaderWrapper } from "../../../UIComponent/Progress";
import CustomAutoComplete from "../../../UIComponent/CustomAutoComplete";

// Importing useStyles
import useStyles from "./jobManagement.styles";

// Import Fluent UI Icons
import { AcceptIcon, CancelIcon, ClearIcon, DeleteIcon, EditIcon, SearchIcon, } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../Services/CommonService";
import { toast } from "react-toastify";
import { use } from "i18next";

const AddRecords = (props) => {
  // Declaration of States and Component Variables Start Here
  let [formData, setFormData] = useState({ "jobtypeId": 1, "checkValue": false, checkJobStartValue: false })
  const classes = useStyles();
  const { jobid, companyData, setOpenRecords } = props;
  // const { jobid, setJobId } = useState(null);
  // const { companyData, setCompanyDatum } = useState([]);
  // let setOpenRecords = null;

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fetchedDetails, setFetchedDetails] = useState({ jobId: null, jobName: "", jobInstructions: "" });
  const [tableData, setTableData] = useState([]);
  const [priority, setPriority] = useState([]);
  const [finalData, setFinalData] = useState({ jobId: 0, jobName: "string", jobInstructions: "string", jobAsset: [], });
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [searchText, setSearchText] = useState("")
  const [reload, setReload] = useState(false)
  // Declaration of States and Component Variables End Here
  useEffect(() => {
    GetHistory()
  }, [])
  const GetHistory = () => {
    //let temp = CommonService.GetPropsStoredData(props, "addrecords");
    let tempHistoryData = CommonService.GetStoredData(props, "historyData");
    if (tempHistoryData) {
      // if (tempHistoryData.jobid) {
      //   setJobId(tempHistoryData.state);
      // }
    }
    // if(temp){
    //   if (temp.companyData) {
    //     setCompanyDatum(temp.companyData);
    //   }
    //   if (temp.setOpenRecords) {
    //     setOpenRecords = temp.setOpenRecords
    //   }
    // }





  }
  const getPriorityList = async () => {
    await axios
      .get(process.env.React_App_baseUrl + "DropDown/GetPriorityDropDownFilter", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then(function (response) {
        const rows = response.data.map((row) => createPirorityData(row.priorityName, row.id));
        setPriority(rows);
      })
      .catch((err) => {

        if (err.response.status === 400) {
          toasterError(err.response.data);

        }

      });
  };

  const getRecords = async (searchValue) => {

    const getData = data.filter(item => item.isSelected === true).map((el) => {
      let finalData = {
        companyId: el.companyId,
        isCRUCompany: el.isCRUCompany ? el.isCRUCompany : false,
        assetId: el.assetId ? el.assetId : 0,
        isCRUAsset: el.isCRUAsset ? el.isCRUAsset : false,
        jobAssetId: 0,
      };
      return finalData;
    });
    await axios
      .post(`${process.env.React_App_baseUrl}job/GetJobRecords`, getData, {
        headers: {
          jobId: jobid, searchText: encodeURIComponent(searchValue),
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      })
      .then((res) => {
        setFetchedDetails({
          ...fetchedDetails,
          jobId: res.data.jobId,
          jobName: res.data.jobName,
          jobInstructions: res.data.jobInstructions,
          jobNo: res.data.jobNo
        });
        setFinalData({
          ...finalData,
          jobId: res.data.jobId,
          jobName: res.data.jobName,
          jobInstructions: res.data.jobInstructions,
        });
        setTableData(res.data.companyAssetDetailsDto);
      })
      .catch((err) => {

        if (err.res.status === 400) {

          toasterError(err.res.data);

        }

      });
  };

  const addRecords = async () => {
    function One() {

      setFinalData({
        ...finalData,
        jobAsset: tableData.map((el) => {
          let jobAssetId = 0;
          if (el.jobAssetId !== null && el.jobAssetId !== undefined && el.jobAssetId !== '') {
            jobAssetId = el.jobAssetId;
          }
          return {
            jobAssetId: jobAssetId,
            priorityId: el.priorityId,
            tat: el.tat ? el.tat.split("-").reverse().join("-") : null,
            startDate: el.startDate ? el.startDate.split("-").reverse().join("-") : null,
            remainingDays: el.remainingDays,
            assignedTo: el.assignedTo ? el.assignedTo : "NA",
            assignedToId: el.assignedToId,
            isEdited: true,
            isDeleted: false,
            companyId: el.companyId ? el.companyId : 0,
            isCRUCompany: el.isCRUCompany,
            assetId: el.assetId,
            isCRUAsset: el.isCRUAsset,
          };
        }),
      });
      openAlertSaveDialog()
    }
    One()
  };

  function callApi() {

    axios
      .post(`${process.env.React_App_baseUrl}job/SaveJobRecords`, finalData, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then((res) => {
        setOpenSaveConfirmation(false);
        setOpenRecords(false)
        toasterSuccess(`Record(s) added successfully!`)
      })
      .catch((err) => {

        if (err.res.status === 400) {
          toasterError(err.res.data);

        }

      });
  }




  useEffect(() => {
    setData([]);
    if (companyData) {
      companyData.forEach(element => {
        element.children.forEach(item => {
          item.isCRUCompany = element.isCRUCompany
        })
      });
    }


    for (let i in companyData) {
      if (companyData[i].isSelected) {
        data.push(companyData[i]);
      }
      for (let j in companyData[i].children) {
        if (companyData[i].children[j].isSelected) {
          data.push(companyData[i].children[j]);
        }
      }
    }
    getPriorityList();
  }, [reload]);

  useEffect(() => { getRecords(searchText); }, [reload])
  function createPirorityData(label, id) { return { label, id }; }

  const openAlertSaveDialog = () => { setOpenSaveConfirmation(true); }

  const handleADClose = () => { setOpenSaveConfirmation(false); }

  const globalSearch = (e) => {
    if (e.key === "Enter") { setReload(!reload); }
  };


  const handleJobStartDateChange = (e) => {
    if (e !== null && new Date(e).toDateString() !== 'Invalid Date') {
      let selectedDate = new Date(e);
      let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
      setFormData({ ...formData, startDate: date, checkJobStartValue: false })
      // GetNoOfDays(selectedDate);
    } else {
      setFormData({ ...formData, startDate: undefined, checkValue: false, checkJobStartValue: false })
    }

  }



  const CommonJobStartDate = (e, value) => {
    if (formData.startDate) {

      //let remainingDaysTemp=CommonService.getDayDifference(new Date(formData.tat))

      if (value) {
        setFormData({ ...formData, checkJobStartValue: value })
        let updatedRow = [];
        let promise = Promise.all(tableData.map(async (element) => {
          if (element.companyId > 0 && element.assetId > 0) {
            let assetDetails = {
              companyId: element.companyId,
              assetId: element.assetId,
              isCRUCompany: element.isCRUCompany,
              isCRUAsset: element.isCRUAsset
            }
            await AsyncGetNoOfDays(assetDetails).then((res) => {
              let tempStartDate = new Date(formData.startDate);
              //tempStartDate.setDate(tempStartDate.getDate() + res.data)
              let g = CommonService.addWorkingDays(tempStartDate, res.data)
              let newTatDate = CommonService.GetDateAsStringWithDash(g)
              let remainingDays = CommonService.getBusinessDatesCount(g)
              updatedRow.push({ ...element, startDate: formData.startDate.split("-").reverse().join("-"), remainingDays: remainingDays, tat: newTatDate.split("-").reverse().join("-") })
            })
          }
        }))
        promise.then(() => {
          // console.log("test",updatedRow)
          if (updatedRow.length > 0) {

            setTableData(updatedRow)
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    } else {
      toasterError("Please select Job Start Date");
    }
  }

  const AsyncGetNoOfDays = async (finalAssetDetailObject) => {
    return axios.get(
      `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
      headers: {
        CompanyId: finalAssetDetailObject.companyId,
        AssetId: finalAssetDetailObject.assetId,
        IsCRUCompany: finalAssetDetailObject.isCRUCompany,
        IsCRUAsset: finalAssetDetailObject.isCRUAsset,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })

  }



  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} md={3} lg={2} xl={1}>
          <TextField
            id="standard-basic"
            label="Job ID"
            variant="standard"
            fullWidth
            readOnly
            // defaultValue="1005"
            value={Number(fetchedDetails.jobNo)}
            InputProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={9} lg={10} xl={5}>
          <TextField
            id="standard-basic"
            label="Job Name"
            variant="standard"
            value={fetchedDetails.jobName}
            fullWidth
            required={true}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={6}>
          <TextField
            fullWidth
            id="standard-multiline-static"
            label="Job Instructions"
            multiline
            maxRows={2}
            value={fetchedDetails.jobInstructions}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} >
          <DatePicker
            labelTitle="Job Start Date(DD-MM-YYYY)"
            variant="standard"
            past={true}
            defaultDate={null}
            onChangeDate={(e) => { handleJobStartDateChange(e) }} />
        </Grid>
        <Grid item xs={12} md={5} lg={4} xl={3} sx={{ mt: 2 }}>
          <FormControlLabel control={<Checkbox checked={formData.checkJobStartValue} onChange={(e, value) => CommonJobStartDate(e, value)} />} label="Apply Common Job Start Date" />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.addBorder}>
            <Grid item>
              <Grid container alignItems="center" justifyContent="left" spacing={2} sx={{ pt: 1, pb: 2, pr: 2, pl: 2 }} >
                <Grid item xs={12}>
                  <TextField
                    id="input-with-icon-textfield"
                    className="serchByKeyword mt-1"
                    value={searchText}
                    placeholder="Search by Keyword"
                    onKeyUp={globalSearch}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon className={classes.searchIcon} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <ClearIcon
                              style={{ fontSize: 10 }}
                              onClick={() => {
                                setSearchText("")
                                setReload(!reload)
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
            <LoaderWrapper className={classes.minHeightTable} loading={isLoading}>
              <TableContainer component={Paper} elevation={0}
                sx={{ "& > *": { borderBottom: "unset" }, boxShadow: 0 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader} align="left"
                        style={{ minWidth: 200, maxWidth: "20%" }}>
                        Company Name
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left"
                        style={{ minWidth: 150, maxWidth: "13%" }}>
                        Asset
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center"
                        style={{ minWidth: 150, maxWidth: "13%" }}>
                        Variable Count
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left"
                        style={{ minWidth: 150, maxWidth: "22%" }}>
                        Assigned To
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center"
                        style={{ minWidth: 140, maxWidth: "15%" }}>
                        Job Start Date
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center"
                        style={{ minWidth: 140, maxWidth: "15%" }}>
                        TAT
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center"
                        style={{ minWidth: 140, maxWidth: "15%" }}>
                        Remaining Days
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center"
                        style={{ minWidth: 140 }}>
                        Priority
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left"
                        style={{ minWidth: 150, maxWidth: "13%" }}>
                        Country
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left"
                        style={{ minWidth: 150, maxWidth: "13%" }}>
                        Region
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left"
                        style={{ minWidth: 150, maxWidth: "13%" }}>
                        Sub Region
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center"
                        style={{ minWidth: 100 }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData?.map((row) => (
                      <EditableTable
                        row={row}
                        setTableRow={setTableData}
                        tableRow={tableData}
                        priority={priority}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </LoaderWrapper>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="right">
            <Grid item xs={12} sm={"auto"}>
              <Button variant="contained" color="accent3" onClick={() => setOpenRecords(false)}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={"auto"}>
              <Button variant="contained" color="accent5" onClick={addRecords}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openSaveConfirmation}
        onClose={handleADClose}
        description="Would you like to proceed with the changes?"
        action={
          <Grid container spacing={2} justifyContent="right">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>Cancel</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button onClick={callApi} color="secondary">Yes</Button>
            </Grid>
          </Grid>
        }
      />
    </Box >
  );
};

export default AddRecords;
const EditableTable = (props) => {
  // Declaration of States and Component Variables Start Here
  let [edit, setEdit] = useState(false);
  let [value, setValue] = useState();
  const classes = useStyles();
  const [openConfirmation, setConfirmation] = useState(false);
  const handleADClose = () => { setConfirmation(false); };
  const editData = () => { setEdit(!edit); };
  const openAlertDialog = () => { setConfirmation(true); };
  const DeleteData = () => {
    let arr = [...props.tableRow];
    let index = arr.indexOf(props.row);
    arr.splice(index, 1);
    props.setTableRow(arr);
    handleADClose();
  };
  const [reload, setReload] = useState(false);
  const CancelData = () => { setValue(); setEdit(!edit); };
  const saveData = () => {

    props.row.priorityName = value?.priorityName
      ? value?.priorityName
      : props.row.priorityName;
    props.row.priorityId = value?.priorityId
      ? value?.priorityId
      : props.row.priorityId;
    props.row.tat = value?.tat ? value.tat : props.row.tat;
    props.row.startDate = value?.startDate ? value.startDate : props.row.startDate;

    props.row.remainingDays = CommonService.getBusinessDatesCount(new Date(CommonService.GetDateInReverse(props.row.tat)))
    setEdit(!edit);
  };
  const totChange = (e) => { setValue({ ...value, tat: CommonService.GetDateInReverse(e) }); };
  const startDateChange = (e) => {
    //  setValue({ ...value, startDate: e }); 
    if (props.row.companyId > 0 && props.row.assetId > 0) {

      axios.get(
        `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
        headers: {
          CompanyId: props.row.companyId,
          AssetId: props.row.assetId,
          IsCRUCompany: props.row.isCRUCompany,
          IsCRUAsset: props.row.isCRUAsset,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }).then((res) => {
        if (res.status === 200) {
          let tempStartDate = new Date(e);

          // tempStartDate.setDate(tempStartDate.getDate() + res.data);
          // change for setting working days 
          tempStartDate = CommonService.addWorkingDays(tempStartDate, res.data)
          let newTatDate = CommonService.GetDateAsStringWithDash(tempStartDate);
          let remainingDaysTemp = CommonService.getBusinessDatesCount(tempStartDate);
          props.row.tat = CommonService.GetDateInReverse(newTatDate);
          props.row.startDate = CommonService.GetDateInReverse(e);
          props.row.remainingDays = remainingDaysTemp;
          setReload(!reload)
          // setEdit(!edit);

        }
      })
    }
  };
  const priorityChange = (ab) => { setValue({ ...value, priorityName: ab.label, priorityId: ab.id }); };
  // Declaration of States and Component Variables End Here
  //console.log(props.row, "props.row")
  return (
    <>
      <TableRow hover className={classes.cruTr} key={props.row.companyName}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left" className={classes.tableCell}>
          {props.row.companyName}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {props.row.assetName}
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          {props.row.noOfVariable ? props.row.noOfVariable : 0}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {props.row.assignedTo ? props.row.assignedTo : "NA"}
        </TableCell>
        {!edit ? (
          <TableCell align="center" className={classes.tableCell}>
            {/* {CommonService.GetDateInReverse(props.row.startDate)} */}
            {props.row.startDate}
          </TableCell>
        ) : (
          <TableCell align="left" className={classes.tableCell}>
            <div style={{ width: 120 }}>
              <DatePicker
                labelTitle="Job Start Date"
                variant="standard"
                past={true}
                defaultDate={props.row.startDate ? props.row.startDate.split("-").reverse().join("-") : null}
                onChangeDate={(e) => {

                  if (e !== null && new Date(e).toDateString() !== 'Invalid Date') {
                    let selectedDate = new Date(e);
                    let date =
                      selectedDate.getFullYear() +
                      "-" +
                      ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                      "-" +
                      ("0" + selectedDate.getDate()).slice(-2);
                    startDateChange(date);
                  }

                }}
              />
            </div>
          </TableCell>
        )}


        {!edit ? (
          <TableCell align="center" className={classes.tableCell}>
            {/* {CommonService.GetDateInReverse(props.row.tat)} */}
            {props.row.tat}
          </TableCell>
        ) : (
          <TableCell align="left" className={classes.tableCell}>
            <div style={{ width: 120 }}>
              <DatePicker
                labelTitle="TAT"
                variant="standard"
                past={true}
                defaultDate={props.row.tat}
                onChangeDate={(e) => {

                  let selectedDate = new Date(e);
                  let date =
                    selectedDate.getFullYear() +
                    "-" +
                    ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + selectedDate.getDate()).slice(-2);
                  totChange(date);
                  props.row.remainingDays = CommonService.getBusinessDatesCount(new Date(selectedDate))
                }}
              />
            </div>
          </TableCell>
        )}
        <TableCell align="center" className={classes.tableCell}>
          {props.row.remainingDays}
        </TableCell>
        {!edit ? (
          <TableCell align="center" className={classes.tableCell}>
            {props.row.priorityName === "High" ? (
              <Chip
                label={props.row.priorityName}
                size="small"
                color="warning"
                className="statusChip"
              />
            ) : undefined}{" "}
            {props.row.priorityName === "Low" ? (
              <Chip
                style={{ color: "blue" }}
                label={props.row.priorityName}
                size="small"
                color="success"
                className="statusChip"
              />
            ) : undefined}{" "}
            {props.row.priorityName === "Medium" ? (
              <Chip
                label={props.row.priorityName}
                size="small"
                color="info"
                className="statusChip"
              />
            ) : undefined}
            {props.row.priorityName === "Critical" ? (
              <Chip
                label={props.row.priorityName}
                size="small"
                color="error"
                className="statusChip"
              />
            ) : undefined}
          </TableCell>
        ) : (
          <TableCell align="center" className={`${classes.tableCell} ${classes.paddingRight}`}>
            <div className={classes.smallAlign}>
              <CustomAutoComplete
                options={props.priority}
                sx={{ width: 300 }}
                label="Priority"
                disableClearable={true}
                optionLabelKey="label"
                optionValueKey="id"
                onChange={(value) => { priorityChange(value); }}
                defaultValueKey={["id", "label"]}
                defaultValue={[props.row.priorityId, props.row.priorityName]}
              />
            </div>
          </TableCell>
        )}
        <TableCell align="left" className={classes.tableCell}>
          {props.row.countryName}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>

          {props.row.regionName}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {props.row.subRegionName}
        </TableCell>

        {!edit ? (
          <TableCell align="center" className={classes.tableCell}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton aria-label="edit" color="primary">
                <EditIcon className={classes.iconSize} onClick={editData} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton aria-label="Delete" sx={{ color: "red" }}>
                <DeleteIcon className={classes.iconSize} onClick={openAlertDialog} />
              </IconButton>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell align="center" className={classes.tableCell}>
            <Tooltip title="Submit" placement="top" arrow>
              <IconButton aria-label="Submit">
                <AcceptIcon className={classes.iconSize} onClick={saveData} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel" placement="top" arrow>
              <IconButton aria-label="Cancel" color="secondary">
                <CancelIcon className={classes.iconSize} onClick={CancelData} />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>

      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openConfirmation}
        onClose={handleADClose}
        description="The asset will be deleted from the job. Are you sure you want to delete?"
        action={
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>No</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button onClick={DeleteData} color="secondary">
                Yes
              </Button>
            </Grid>
          </Grid>
        }
      />

    </>
  );
};