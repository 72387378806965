// Default React, useState and Material Components
import React, { useState } from "react";
import { Box, Breadcrumbs, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import SideDrawer from "../../../UIComponent/Drawer";
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { toasterSuccess, toasterError } from '../../../UIComponent/Toaster/index';

// Importing Add & Edit Components
import AddEditStatus from "./AddEditStatus";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const Status = (props) => {

  // Default React, useState and Material Components
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState([]);
  const [selected, SetSelected] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [state, setState] = useState({ right: false });
  const [startSearch, setStartSearch] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [deleteProducerData, setDeleteProducerData] = useState(null);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [menuPermission, setMenuPermission] = useState({ allowEdit: true, allowDelete: true, });
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(24, function (err, res) {
      if (res) {
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])
  React.useEffect(() => {
    if (searchValue === "" || searchValue == null || searchValue === undefined) {
      getStatusData()
    } else { searchUser(searchValue) }
  }, [sorting]);
  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) {
      getStatusData()
    } else { searchUser(searchValue) }
  }, [page, reload, rowsPerPage])
  // Declaration of React Hooks End Here

  const columns = [
    { id: "statusName", label: "Status", minWidth: 200, width: "25%", sorting: false },
    { id: "statusDesc", label: "Description", minWidth: 400, width: "70%", sorting: false, truncate: 90, tooltipWithTruncate: true }
  ];

// This function handles the keyup event
const handleKeyUp = (event) => {
  // If backspace is pressed and target is not a button
  if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
    // If search value is empty, get status data
    if (searchValue.length === 0) { getStatusData(); }
  }
  // If enter is pressed and target is not a button
  if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
    // Set total count to 0, page to 0 and start search to true
    setTotalCount(0);
    setPage(0);
    setStartSearch(true)
    // Search user with the value of the target
    searchUser(event.target.value);
  }
};

  // Function for Global Search
  const globalSearch = (value) => { setSearchValue(value); };

  // Function to search for user based on filter value
const searchUser = (filterValue) => {
  // Set loading to true
  setLoading(true);
  // Create an array of parameters
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
  // Call ApiService to search for user
  ApiService.search("managedata/GetStatusDetails?", {
    isAscending: sorting.isAscending,
    searchText: filterValue
  }, prt)
    .then((res) => {
      // If response status is 200, set search result and total count
      if (res.status === 200) {
        let searchResult = Array.isArray(res.data) ? res.data : res.data.data || [];
        setAllStatus(searchResult);
        setLoading(false);
        setTotalCount(res.data.totalCount);
      } else {
        // Else, display error message
        toasterError(`${res.data}`)
        setLoading(false);
      }
    })
    .catch((err) => {
      // Catch any errors and handle them
      setLoading(false);
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
};

// This function is used to get the status data from the API
const getStatusData = () => {
  // Set loading to true
  setLoading(true);
  
  // If reload is true, set it to false
  if (reload) { setReload(false); }
  
  // Create an array of parameters for the API call
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
  
  // Make the API call
  ApiService.search("managedata/GetStatusDetails?", {
  }, prt
  )
    .then((res) => {
      // If the response is successful, set the allStatus and totalCount variables
      if (res.status === 200) {
        let result = Array.isArray(res.data) ? res.data : res.data.data || [];
        setAllStatus(result);
        setLoading(false);
        setTotalCount(res.data.totalCount);
      } else { 
          // If the response is not successful, display an error message
          toasterError(`${res.data}`); setLoading(false); 
      }
    })
    .catch((err) => {
      // Handle any errors that occur
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
};

// Function to delete status
const deleteStatus = () => {
  // Set loading state of delete button to true
  setDeleteBtnLoadinng(true);
  // Create data object with active property set to false
  let data = deleteProducerData;
  data.active = false;
  // Make API call to delete status
  ApiService.create(`managedata/DeleteStatus?statusId=${deleteProducerData.id}`)
    .then((res) => {
      // If API call is successful, show success message and get updated status data
      if (res.status === 200) {
        toasterSuccess("Status deleted successfully");
        getStatusData();
        setDeleteBtnLoadinng(false);
        setOpenDelete(false);
      } else {
        // If API call fails, show error message
        toasterError("Somthing went wrong");
        setOpenDelete(false);
        setDeleteBtnLoadinng(false);
      }
    })
    .catch((err) => {
      // Handle errors
      ErroHandling(err, function (res) {
        toasterError(res)
      });
      setDeleteBtnLoadinng(false);
    });
};

// This function toggles the drawer open or closed based on the mode
const toggleDrawer = (open, mode) => (event, item) => {
  // If the mode is set to delete, set the delete producer data and open the delete drawer
  if (mode === "Delete") {
    setDeleteProducerData(item);
    setOpenDelete(true);
  } else {
    // Otherwise, set the form data and state, and set the crud state
    setFormData(item);
    setState({ ...state, right: open, formData: item });
    setCrudState(mode);
  }
};
  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Status</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">Status</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {(access.allowEdit) ? <>
                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: 16 }} />}>
                  Add Status
                </Button>
              </> : null}
            </Grid>
          </Grid>
        </Grid>

        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className={classes.searchByKeyword}
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className={classes.serchByClear}
                        onClick={() => {
                          setSearchValue("");
                          getStatusData();
                        }}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">
                      Total Records: <span className={classes.totalRecord}>{totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Status Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allStatus ? allStatus : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setReload(true)
                    setSorting(e)
                  }}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                />
                <SideDrawer
                  formComp={
                    <AddEditStatus
                      state={state}
                      setState={setState}
                      crudState={crudState}
                      formData={formData}
                      setFormData={setFormData}
                      hideSideDrawer={() => {
                        setState({ right: false });
                        getStatusData();
                      }}
                    />
                  }
                  state={state}
                  setState={setState}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Alert Dialog - Confirmation for Delete */}
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to delete the status "${deleteProducerData?.statusName}" ?`}
          action={
            <>
              <div className={classes.alertDialogbtn}>
                <Button onClick={() => setOpenDelete(false)}>No</Button>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button onClick={() => deleteStatus()} color="secondary">
                    Yes
                  </Button>
                )}
              </div>
            </>
          }
        />
      ) : null}
    </Box>
  );
};

export default Status;