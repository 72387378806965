// Default React, Router and Services Components
import React, { useEffect, useState } from "react";
import { Breadcrumbs, Button, Grid, Box, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import axios from "axios";
import CommonService from "../../../Services/CommonService";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import EditAsset from "./OwnershipChangesEditAsset";
import EditSource from "./OwnershipChangesEditSource";
import FilterForm from "./OwnershipChangesFilterForm";
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import CreateSource from "./OwnershipChangesCreateSource";
import SideDrawer from "../../../UIComponent/Drawer/index";
import { toasterSuccess, toasterError } from '../../../UIComponent/Toaster/index';

// Import Material & Fluent UI Icons
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { ClearIcon, ChevronRightSmallIcon, EditIcon } from "@fluentui/react-icons-mdl2";

//data collapasable
import "./index.scss";
import useStyles from "./OwnershipChangesManagement.styles";

const SourceManagement = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({ right: false, });
  const [state2, setState2] = React.useState({ right: false, });
  const [state3, setState3] = React.useState({ right: false, });
  const [state4, setState4] = React.useState({ right: false, });

  const [isLoading, setLoading] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [selected, SetSelected] = useState([]);
  const [companyDropDown, setCompanyDropDown] = React.useState([]);
  const [assetTypeDropDown, setAssetTypeDropDown] = React.useState([]);
  const [regionDropDown, setRegionDropDown] = React.useState([]);
  const [subRegionDropDown, setSubRegionDropDown] = React.useState([]);
  const [countryDropDown, setCountryDropDown] = React.useState([]);
  const [commodityDropDown, setCommodityDropDown] = React.useState([]);
  const [statusDropDown, setStatusDropDown] = React.useState([]);
  const [crudState3, setCrudState3] = React.useState(false);
  const [crudState4, setCrudState4] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState()
  const [company, setCompany] = React.useState([]);
  let [filterValue, setFilterValue] = React.useState();
  const [reload, setReload] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [companyId, setCompanyId] = React.useState();
  const [assetId, setAssetId] = React.useState();
  const [refresh, setRefresh] = React.useState(false);
  const [previousSelect, setPreviousSelect] = React.useState(null)
  const [access, setAccess] = React.useState({ allowEdit: true, allowDelete: true, allowView: true })
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false })
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [jobLocations, setJobLocations] = React.useState([])
  const [jobCompanies, setJobCompanies] = React.useState([])
  const [filterValues, setFilterValues] = useState();
  const [locationFilter, setLocationFilter] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [previousSelectSource, setPreviousSelectSource] = React.useState(null);
  const [assetTypes, setAssetTypes] = React.useState(dataForFilter.assetType ? dataForFilter.assetType : [])
  const [commodityList, setCommodityList] = React.useState(dataForFilter.commodityList ? dataForFilter.commodity : [])
  const [priorityList, setPriorityList] = React.useState(dataForFilter.priority ? dataForFilter.priority : [])

  // Assigning Columns and UI Props
  const columns = [
    { id: "asset", label: "Asset", minWidth: 200, width: "5%", sorting: true },
    { id: "commodity", label: "Commodity", minWidth: 250, width: "15%", sorting: true, sorting: false, truncate: 75, tooltipWithTruncate: true },
    { id: "country", label: "Country", minWidth: 180, width: "10%", sorting: true },
    { id: "region", label: "Region", minWidth: 180, width: "10%", sorting: true },
    { id: "subRegion", label: "Sub Region", minWidth: 180, width: "10%", sorting: true },
    { id: "operator", label: "Operator", minWidth: 250, width: "15%", sorting: true },
    { id: "company", label: "Owner", minWidth: 300, width: "15%", sorting: true },
    { id: "ownershipPercentage", label: "Ownership %", minWidth: 80, width: "5%", sorting: true, align: "center" },
    { id: "isListedCompanyGrid", label: "Listed Company", minWidth: 100, width: "5%", sorting: true, align: "center" },
    { id: "", label: "Latitude / Longitude ", minWidth: 200, width: "10%", isLinkColumn: true, linkUrl: "https://www.google.com/maps/?q={latitude},{longitude}&ll={latitude},{longitude}&z=8", linkText: "{latitude}/{longitude}", valuePlaceholders: "latitude,longitude", align: "center" },
    {
      id: "priority",
      label: "Priority",
      minWidth: 110,
      width: 110,
      sorting: true,
      type: "Chip",
      align: "center",
    },
  ];


  // Function for Sorting
  const changeSortColumnNameFormat = (fieldName) => {
    if (fieldName == null || fieldName == "") { return ""; }
    if (fieldName.toLocaleLowerCase() == "asset") { fieldName = "Asset"; }
    if (fieldName.toLocaleLowerCase() == "country") { fieldName = "Country"; }
    if (fieldName.toLocaleLowerCase() == "region") { fieldName = "Region"; }
    if (fieldName.toLocaleLowerCase() == "subregion") { fieldName = "SubRegion"; }
    if (fieldName.toLocaleLowerCase() == "company") { fieldName = "Company"; }
    if (fieldName.toLocaleLowerCase() == "operator") { fieldName = "Operator"; }
    if (fieldName.toLocaleLowerCase() == "ownershippercentage") { fieldName = "OwnershipPercentage"; }
    if (fieldName.toLocaleLowerCase() == "islistedcompanygrid") { fieldName = "IsListedCompanyGrid"; }
    if (fieldName.toLocaleLowerCase() == "priority") {
      fieldName = "Priority";
    }
    return fieldName;
  }

  React.useEffect(() => {
    GetMenuService(50, function (err, res) {
      if (res) {
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])

  React.useEffect(() => {
    console.log("Refresh calling")
    getCompany()
  }, [sorting, page,filterValue, rowsPerPage, refresh, reload])


  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) { getCompany('') }
    else { getCompany(searchValue) }
  }, [sorting]);


  React.useEffect(() => {
    getFilterData();
    getAssetTypeDropDownForFilter();
    axios
      .get(process.env.React_App_baseUrl + "DropDown/GetCompanyDropDownFilter", {
        headers: {
          isNewSource: false,
        },
      })
      .then(function (response) {
        setCompanyDropDown(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    //asset type DropDown API
    axios.get(process.env.React_App_baseUrl + "DropDown/GetAssetTypeDropDownFilter")
      .then(function (response) {
        setAssetTypeDropDown(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    // Region Dropdown API calling
    axios.get(process.env.React_App_baseUrl + "DropDown/GetRegionDropDownFilter")
      .then(function (response) {
        setRegionDropDown(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });


    // Commodity Drop DOwn API
    axios.get(process.env.React_App_baseUrl + "DropDown/GetCommodityDropDownFilter")
      .then(function (response) {
        
        setCommodityDropDown(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    // Status dropdown API
    axios.get(process.env.React_App_baseUrl + "DropDown/GetStatusDropDownFilter")
      .then(function (response) {
        
        setStatusDropDown(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  // Function to get company ownership assets
const getCompany = () => {
  // Set loading to true
  setLoading(true)
  
  // Check if filterValue is present
  if (filterValue) {
    // Create a variable s with searchValue if present
    let s = searchValue ? { searchText: searchValue } : '';
    // Merge filterValue and s
    filterValue = { ...filterValue, ...s };
  } else {
    // Set filterValue to searchValue if present
    filterValue = searchValue ? { searchText: searchValue } : "";
  }
  // Log filterValue
  console.log("VLU",JSON.stringify(filterValue))

  // Make an axios call to get company ownership assets
  axios
    .get(process.env.React_App_baseUrl + "ownershipsource/GetCompanyOwnershipAssets", {
      headers: {
        // Get token from CommonService
        'Authorization': CommonService.getToken(),
        // Change sortColumn name format
        sortColumn: changeSortColumnNameFormat(sorting.sortBy),
        // Encode filterValue
        sourceFilter: filterValue ? encodeURIComponent(JSON.stringify(filterValue)) : "",
        // Set sorting order
        isAscending: sorting.isAscending,

      },
      params: {
        // Set page number and page size
        pageNumber: page + 1,
        pagesize: rowsPerPage,
      },
    })
    .then(function (response) {
      // Set total count
      setTotalCount(response.data.totalCount);
      // Create an empty array for rowsData
      let rowsData = [];
      // Assign response data to rowsData
      rowsData = response.data.data;
      // Set company
      setCompany(rowsData);
      // Set loading to false
      setLoading(false);
    })
    .catch(function (error) {
      // Log error
      console.log(error);
      // Set loading to false
      setLoading(false);
    });
};

// This function handles the keyup event
const handleKeyUp = (event) => {
  // Check if the keycode is 13 or which is 13 and the target node is not a button
  if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== 'BUTTON') {
    // Call getCompany() function
    getCompany();
    // Set total count to 0
    setTotalCount(0);
    // Set page to 0
    setPage(0);
  }
}
// This function sets the search value
const globalSearch = (value) => {
  // Set the search value to the given parameter
  setSearchValue(value)
}

// This function is used to clear the search value and make an API call to get company ownership assets
const searchClear = () => {
  // Set loading to true while making the API call
  setLoading(true);
  // Clear the search value
  setSearchValue('');
  // Make an API call to get company ownership assets
  axios
    .get(process.env.React_App_baseUrl + "ownershipsource/GetCompanyOwnershipAssets", {
      headers: {
        'Authorization': CommonService.getToken(),
        // Encode the filter value if it exists
        sourceFilter: filterValue ? encodeURIComponent(JSON.stringify(filterValue)) : "",
      },
      params: {
        pageNumber: page,
        pagesize: rowsPerPage,
      },
    })
    .then(function (response) {

      // Set the total count of the response data
      setTotalCount(response.data.totalCount);
      let rowsData = [];
      // Set the rows data from the response
      rowsData = response.data.data;
      setCompany(rowsData);
      // Set loading to false after the API call is complete
      setLoading(false);
    })
    .catch(function (error) {
      console.log(error);
      // Set loading to false if there is an error in the API call
      setLoading(false);
    });
}

  // Function for Reset Filter
  const [assetTypeDD, setAssetTypeDD] = useState([]);
 
  // This function resets the tree filter
const resetTreeFilter = () => {
  // Declare company and location filters
  let comFilter = companyFilter
  let loFilter = locationFilter

  // Loop through company filter and set checked to false
  comFilter.forEach((item, index) => {
    item.checked = false;
    // If there are children, loop through them and set checked to false
    if (item.children) {
      item.children.forEach((item, index) => { item.checked = false; });
    }
  });

  // Loop through location filter and set checked to false
  loFilter.forEach((item, index) => {
    item.checked = false;
    // If there are children, loop through them and set checked to false
    if (item.children) {
      item.children.forEach((item, index) => { item.checked = false; });
      // If there are grandchildren, loop through them and set checked to false
      if (item.children) {
        item.children.forEach((item, index) => { item.checked = false; });
      }
    }
  });

  // Set company and location filters with updated values
  setCompanyFilter([...comFilter])
  setLocationFilter([...loFilter])
};


  // This function is used to get filter data from the API
const getFilterData = async () => {
  try {
    // Making a GET request to the API
    const response = await axios.get(
      `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer" + localStorage.getItem("token") : null
      }
    }
    );
    // If no data is returned, set the data for filter to an empty array
    if (response.data.length === 0) {
      setDataForFilter([]);
    } else {
      // Set the data for filter to the response data
      setDataForFilter(response.data);
      console.log(response.data, "response.data")
      // Iterate through the companies and set checked and isPartialSelected to false
      response.data.companies.forEach((item, index) => {
        item.checked = false;
        item.isPartialSelected = false
        if (item.children) {
          item.children.forEach((item, index) => { item.checked = false; item.isPartialSelected = false });
        }
      });
      // Iterate through the regions and set checked to false
      response.data.location.regions.forEach((item, index) => {
        item.checked = false;
        if (item.children) {
          item.children.forEach((items, index) => {
            items.checked = false;
            if (items.children) {
              items.children.forEach(kt => {
                kt.checked = false
              })
            }
          });
        }
      });
      // Set the company filter and location filter to the response data
      setCompanyFilter(response.data.companies);
      setLocationFilter(response.data.location.regions);
    }
  } catch (error) { }
};
 

// This function is used to get the asset type dropdown for filter
const getAssetTypeDropDownForFilter = async () => {
  try {
    // Making a GET request to the specified URL with authorization token
    const response = await axios.get(
      `${process.env.React_App_baseUrl}DropDown/GetAssetTypeDropDownFilter`, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    }
    );
    // If the response data length is 0, set the asset type DD to an empty array
    if (response.data.length === 0) { setAssetTypeDD([]); }
    // Else set the asset type DD to the response data
    else { setAssetTypeDD(response.data); }
  } catch (error) { }
};



  // Create source and source change drawer
  const toggleDrawer = (open) => (event) => {
    setState({ ...state, right: open });
    setCrudState(true);
  };

  // Filter side drawer
  const toggleDrawer2 = (open) => (event) => {
    setState2({ ...state2, right: open });
    setShowFilter(true);
  };

// Function to edit a test
const editTest = (e, item, index, page) => {
  // Set the state4 to true
  setState4({ ...state4, right: true });
  // Set the crudState4 to true
  setCrudState4(true);
  // Set the assetId to the item's assetId
  setAssetId(item.assetId);
};
// This function checks if the data is valid and if it is not a listed company or a Jira ticket created
const isListedCompanyCondition = (data) => {
  // Check if data is truthy
  return data && (!data.isListedCompany && !data.isJiraTicketCreated);
}

  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Source Management</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Ownership Changes</Typography>
            <Typography color="text.primary">Source Management</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              <Button onClick={toggleDrawer(true)} variant="contained" color="accent2" startIcon={<AddIcon />} >
                Create Source
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      // label="TextField"
                      className="serchByKeyword mt-1"
                      size="small"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}><ClearIcon style={{ fontSize: 10 }} /></IconButton> : ''}

                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Button onClick={toggleDrawer2(true)} variant="contained" color="secondary"
                      startIcon={<FilterAltOutlinedIcon />}>Filter
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Source Management Table /> */}
              <Grid item xs={12}>
                <DataTable
                  loading={isLoading}
                  rows={company ? company : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setReload(true)
                    setSorting(e)
                  }}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: editTest,
                    }),

                  }}
                  hasChip={{
                    High: { color: "warning", label: "High" },
                    Critical: { color: "error", label: "Critical" },
                    Low: { color: "success", label: "Low" },
                    Medium: { color: "info", label: "Medium" },
                  }}
                  isEditActionVisibile={isListedCompanyCondition}
                  setCrudState={setCrudState}
                  setState3={setState3}
                  setCrudState3={setCrudState3}
                  setState4={setState4}
                  setCrudState4={setCrudState4}
                  filterValue={filterValue}
                  setCompanyId={setCompanyId}
                  setAssetId={setAssetId}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>

        {/* Calling Create Source Side Drawer */}
        <SideDrawer
          formComp={
            <CreateSource
              state={state}
              setState={setState}
              companyDropDown={companyDropDown}
              assetTypeDropDown={assetTypeDropDown}
              regionDropDown={regionDropDown}
              commodityDropDown={commodityDropDown}
              statusDropDown={statusDropDown}
              setRefresh={setRefresh}
              refresh={refresh}
              reload={reload}
              setReload={setReload}
            />
          }
          state={state}
          setState={setState}
          crudState={crudState}
        />

        {/* Calling Edit Source Side Drawer */}
        <SideDrawer
          formComp={
            <EditSource
              state={state3}
              setState={setState3}
              companyDropDown={companyDropDown}
              assetTypeDropDown={assetTypeDropDown}
              regionDropDown={regionDropDown}
              commodityDropDown={commodityDropDown}
              statusDropDown={statusDropDown}
              companyId={companyId}
              assetId={assetId}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          }
          state={state3}
          setState={setState3}
          crudState={crudState3}
        />

        {/* Calling Edit Asset Side Drawer */}
        <SideDrawer
          formComp={
            <EditAsset
              state={state4}
              setState={setState4}
              companyDropDown={companyDropDown}
              assetTypeDropDown={assetTypeDropDown}
              regionDropDown={regionDropDown}
              commodityDropDown={commodityDropDown}
              statusDropDown={statusDropDown}
              assetId={assetId}
              companyId={companyId}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          }
          state={state4}
          setState={setState4}
          crudState={crudState4}
        />

        {/* Calling Filter Form Side Drawer */}
        <SideDrawer
          formComp={
            <FilterForm
              dataForFilter={dataForFilter}
              filterFinalData={filterFinalData}
              companyFilter={companyFilter}
              locationFilter={locationFilter}
              setLocationFilter={setLocationFilter}
              setCompanyFilter={setCompanyFilter}
              setFilterValues={setFilterValues}
              assetTypeDD={assetTypeDD}
              setFilterFinalData={setFilterFinalData}
              setFilterValue={setFilterValue}
              lastSelectedSource={previousSelectSource}
              LastSelection={previousSelect}
              state={state2}
              setState={setState2}
              reload={reload}
              setReload={setReload}
              resetTreeFilter={resetTreeFilter}
              setJobCompanies={setJobCompanies}
              setJobLocations={setJobLocations}
              setAssetTypes={setAssetTypes}
              setCommodityList={setCommodityList}
              setPriorityList={setPriorityList}
              setDataForFilter={setDataForFilter}
              getFilterData={getFilterData}
            />
          }
          state={state2}
          setState={setState2}
          crudState={showFilter}
          reload={reload}
          setReload={setReload}
          setFilterValue={setFilterValue}
        />

      </Grid>
    </Box>
  );
};

export default SourceManagement;
