// Default React, Router and Services Components
import React from 'react';
import axios from 'axios';
import { useEffect } from "react";
import ViewSDKClient from './ViewSDKClient';
import { useHistory } from "react-router-dom";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterSuccess, toasterError } from "../../../UIComponent/Toaster";

const AdobeViewer = (props) => {

    // Declaration of States and Component Variables Start Here
    const history = useHistory();
    const { jobId, fileName, pageNumber, crudState } = { jobId: props.jobId, fileName: props.fileName, pageNumber: props.pageNumber, crudState: props.crudState }
    // Declaration of States and Component Variables End Here

    // Declaration of React Hooks Start Here
    useEffect(() => {
        var viewerConfig;
        if (crudState === "Edit") {
            viewerConfig = {
                showDownloadPDF: false,
                showPrintPDF: false,
                showAnnotationTools: true
            };
        } else {
            viewerConfig = {
                showDownloadPDF: false,
                showPrintPDF: false,
                showAnnotationTools: false
            };
        }
        
        const adobeKey=process.env.React_App_Adobe_Key;

        if(adobeKey!==undefined && adobeKey!==null && adobeKey!==''){
            const viewSDKClient = new ViewSDKClient(adobeKey);
            viewSDKClient.ready().then(() => {

                axios.get(`${process.env.React_App_baseUrl}ManageOwnership/FetchBlobFile?fileName=${fileName}`, {
                    headers: {
                        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                    }
                }).then((result) => {
                    var uint8Array = _base64ToArrayBuffer(result.data.file);
                    var promise = new Promise((resolve) => {
                        resolve(uint8Array)
                    });
                    let Senddata = { pageNumber: pageNumber,fileName: fileName, viewerConfig: viewerConfig, promise: promise, crudState: crudState }
                    viewSDKClient.previewFile("pdf-div", Senddata);
                    if (props?.isLoading) {
                        props?.isLoading(false);
                    }
                }).catch((err) => {
                    {
                        if (props?.isLoading) {
                            props?.isLoading(false);
                        }
                        ErroHandling(err, function (res) {
                            toasterError(res)
                        })
                    }
    
                })
            })
        }else{
            toasterError("pdf key is missing");
        }
        
    }, []);
    // Declaration of React Hooks End Here

    function _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) { bytes[i] = binary_string.charCodeAt(i); }
        return bytes.buffer;
    }
    return (<div id="pdf-div" className="full-window-div" style={{ height: 'calc(100vh - 150px)' }} />)
}

export default AdobeViewer;