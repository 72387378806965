const getSession = () => {
    // const mshbSession = jsonParse(localStorage.getItem('mshbSession'));
    // const sessionObj = {
    //     token: mshbSession?.token || '',
    //     refreshToken: mshbSession?.refreshToken || '',
    //     roleId: mshbSession?.roleId || '',
    //     userName: mshbSession?.userName || '',
    //     name: mshbSession?.name || '',
    // };
      const sessionObj = {
        token: "Test",
        refreshToken: 'Test',
        roleId: 0,
        userName:'CRU',
        name: 'CRU',
    };
    
    return sessionObj;
};

const jsonParse = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return '{}';
    }
}

const saveSession = (params) => {
    localStorage.setItem('mshbSession', JSON.stringify(params));
};

const clearSession = () => {
    localStorage.clear();
    localStorage.clear();
}

export {
    saveSession,
    clearSession,
    getSession
};