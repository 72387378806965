// Default React, useState and Material Components
import React, { useState } from "react";
import { Button, Box, Grid, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import AutocompleteText from "../../../UIComponent/Autocomplete";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddEditFinancialYearQuarters = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [months, setMonths] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);
  const [defaultCountryValue, setDefaultcountryValue] = useState(null);
  const [defaultQ1ToMonthValue, setDefaultQ1ToMonthValue] = useState(null);
  const [defaultQ2ToMonthValue, setDefaultQ2ToMonthValue] = useState(null);
  const [defaultQ3ToMonthValue, setDefaultQ3ToMonthValue] = useState(null);
  const [defaultQ4ToMonthValue, setDefaultQ4ToMonthValue] = useState(null);
  const [defaultQ1FromMonthValue, setDefaultQ1FromMonthValue] = useState(null);
  const [defaultQ2FromMonthValue, setDefaultQ2FromMonthValue] = useState(null);
  const [defaultQ3FromMonthValue, setDefaultQ3FromMonthValue] = useState(null);
  const [defaultQ4FromMonthValue, setDefaultQ4FromMonthValue] = useState(null);
  const [defaultFinancialYearToValue, setDefaultFinancialYearToValue] = useState(null);
  const [defaultFinancialYearFromValue, setDefaultFinancialYearFromValue] = useState(null);
  const [FinancialYearQuartersData, setFinancialYearQuartersData] = useState({
    id: 0, countryName: '', countryId: 0, financialYearFrom: '', financialYearTo: '',
    q1FromMonth: '', q1ToMonth: '', q2FromMonth: '', q2ToMonth: '', q3FromMonth: '',
    q3ToMonth: '', q4FromMonth: '', q4ToMonth: '', IsActive: true,
  });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    setMonths([
      { "name": "Jan" },
      { "name": "Feb" },
      { "name": "Mar" },
      { "name": "Apr" },
      { "name": "May" },
      { "name": "Jun" },
      { "name": "Jul" },
      { "name": "Aug" },
      { "name": "Sep" },
      { "name": "Oct" },
      { "name": "Nov" },
      { "name": "Dec" },
    ]);
  }, []);
  React.useEffect(() => {
    ApiService.getAll(`DropDown/GetCountryDropDownFilter`)
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }, []);
  React.useEffect(() => {
    if (props.crudState === "Edit" || props.crudState === "View") {
      let data = {
        id: props.formData.id,
        countryName: props.formData.countryName,
        countryId: props.formData.id,
        financialYearFrom: props.formData.financialYearFrom,
        financialYearTo: props.formData.financialYearTo,
        q1FromMonth: props.formData.q1FromMonth,
        q1ToMonth: props.formData.q1ToMonth,
        q2FromMonth: props.formData.q2FromMonth,
        q2ToMonth: props.formData.q2FromMonth,
        q3FromMonth: props.formData.q3FromMonth,
        q3ToMonth: props.formData.q3ToMonth,
        q4FromMonth: props.formData.q4FromMonth,
        q4ToMonth: props.formData.q4ToMonth,
      };
      setFinancialYearQuartersData(data);
      countries.forEach((rData, i) => { if (rData.id === props.formData.countryId) { setDefaultcountryValue(countries[i]); } });
      months.forEach((rData, i) => { if (rData.name === props.formData.financialYearFrom) { setDefaultFinancialYearFromValue(months[i]); } });
      months.forEach((rData, i) => { if (rData.name === props.formData.financialYearTo) { setDefaultFinancialYearToValue(months[i]); } });
      months.forEach((rData, i) => { if (rData.name === props.formData.q1FromMonth) { setDefaultQ1FromMonthValue(months[i]); } });
      months.forEach((rData, i) => { if (rData.name === props.formData.q1ToMonth) { setDefaultQ1ToMonthValue(months[i]); } });
      months.forEach((rData, i) => { if (rData.name === props.formData.q2FromMonth) { setDefaultQ2FromMonthValue(months[i]); } });
      months.forEach((rData, i) => { if (rData.name === props.formData.q2ToMonth) { setDefaultQ2ToMonthValue(months[i]); } });
      months.forEach((rData, i) => { if (rData.name === props.formData.q3FromMonth) { setDefaultQ3FromMonthValue(months[i]); } });
      months.forEach((rData, i) => { if (rData.name === props.formData.q3ToMonth) { setDefaultQ3ToMonthValue(months[i]); } });
      months.forEach((rData, i) => { if (rData.name === props.formData.q4FromMonth) { setDefaultQ4FromMonthValue(months[i]); } });
      months.forEach((rData, i) => { if (rData.name === props.formData.q4ToMonth) { setDefaultQ4ToMonthValue(months[i]); } });
    }
  }, [props, countries, months]);
  // Declaration of React Hooks End Here

  // Function for Register Financial Year Quarters
  const registerFinancialYearQuarters = () => {
    setLoading(true);
    if (validation(FinancialYearQuartersData)) {
      ApiService.create(
        "managedata/SaveFinancialYearAndQuarter",
        FinancialYearQuartersData
      ).then((res) => {
        if (res.status === 200) {
          toasterSuccess("Financial Year and Quarters details added successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
        .catch((err) => {
          setLoading(false)
          ErroHandling(err, function (res) {
            toasterError(res)
          })
        });

    } else {
      setLoading(false);
    }
  };

  // Function for Update Financial Year Quarters
  const updateFinancialYearQuarters = () => {
    setLoading(true);
    if (validation(FinancialYearQuartersData)) {
      axios.patch(process.env.React_App_baseUrl + "managedata/UpdateFinancialYearAndQuarter", FinancialYearQuartersData, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
        .then((res) => {
          if (res.status === 200) {
            toasterSuccess("Financial Year and Quarters details edited successfully!");
            setLoading(false);
            props.hideSideDrawer();
          }
        })
        .catch((err) => {
          setLoading(false)
          ErroHandling(err, function (res) {
            toasterError(res)
          })
        });

    } else {
      setLoading(false);
    }
  };

  // Function for Validation
  const validation = (formData) => {
    if (formData.countryName === null || formData.countryName === 0 || formData.countryName === '') {
      toasterError("Please select country");
      return false;
    }
    if (formData.financialYearFrom === null || formData.financialYearFrom === 0 || formData.financialYearFrom === '') {
      toasterError("Please select financial Year From");
      return false;
    }
    if (formData.financialYearTo === null || formData.financialYearTo === 0 || formData.financialYearTo === '') {
      toasterError("Please select financial Year To");
      return false;
    }
    if (formData.q1FromMonth === null || formData.q1FromMonth === 0 || formData.q1FromMonth === '') {
      toasterError("Please select Q1 from month");
      return false;
    }
    if (formData.q1ToMonth === null || formData.q1ToMonth === 0 || formData.q1ToMonth === '') {
      toasterError("Please select Q1 to month");
      return false;
    }
    if (formData.q2FromMonth === null || formData.q2FromMonth === 0 || formData.q2FromMonth === '') {
      toasterError("Please select Q2 From month");
      return false;
    }
    if (formData.q2ToMonth === null || formData.q2ToMonth === 0 || formData.q2ToMonth === '') {
      toasterError("Please select Q2 to month");
      return false;
    }
    if (formData.q3FromMonth === null || formData.q3FromMonth === 0 || formData.q3FromMonth === '') {
      toasterError("Please select Q3 from month");
      return false;
    }
    if (formData.q3ToMonth === null || formData.q3ToMonth === 0 || formData.q3ToMonth === '') {
      toasterError("Please select Q3 to month");
      return false;
    }
    if (formData.q4FromMonth === null || formData.q4FromMonth === 0 || formData.q4FromMonth === '') {
      toasterError("Please select Q4 from month");
      return false;
    }
    if (formData.q4ToMonth === null || formData.q4ToMonth === 0 || formData.q4ToMonth === '') {
      toasterError("Please select Q4 to month");
      return false;
    }
    return true;
  }

  return (

    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Details
        </Typography>
      </Box>

      {/* Add & Edit Financial Year Quarters Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AutocompleteText
            option={countries}
            dropShow={"countryName"}
            labelName={"Country"}
            isRequired={true}
            feildName={"countryName"}
            defaultValue={defaultCountryValue}
            readOnly={props.crudState === "View" ? true : false}
            onChangeRole={(value) => {
              setDefaultcountryValue({
                ...defaultValue,
                countryName: value && value.countryName ? value.countryName : "",
                countryId: value && value.id ? value.id : null,
              });
              setFinancialYearQuartersData({
                ...FinancialYearQuartersData,
                countryName: value && value.countryName ? value.countryName : "",
                countryId: value && value.id ? value.id : null,
              });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <AutocompleteText
            option={months}
            dropShow={"name"}
            labelName={"Financial Year From"}
            isRequired={true}
            feildName={"financialYearFrom"}
            defaultValue={defaultFinancialYearFromValue}
            readOnly={props.crudState === "View" ? true : false}
            onChangeRole={(value) => {
              setDefaultFinancialYearFromValue({
                ...defaultValue,
                financialYearFrom: value && value.name ? value.name : "",
                name: value && value.name ? value.name : ""
              });
              setFinancialYearQuartersData({
                ...FinancialYearQuartersData,
                financialYearFrom: value && value.name ? value.name : "",
                name: value && value.name ? value.name : ""
              });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <AutocompleteText
            option={months}
            dropShow={"name"}
            labelName={"Financial Year To"}
            isRequired={true}
            feildName={"financialYearTo"}
            defaultValue={defaultFinancialYearToValue}
            readOnly={props.crudState === "View" ? true : false}
            onChangeRole={(value) => {
              setDefaultFinancialYearToValue({
                ...defaultValue,
                financialYearTo: value && value.name ? value.name : "",
                name: value && value.name ? value.name : ""
              });
              setFinancialYearQuartersData({
                ...FinancialYearQuartersData,
                financialYearTo: value && value.name ? value.name : "",
                name: value && value.name ? value.name : ""
              });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.subTitle}>Q1</Typography>
          <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={5}>
              <AutocompleteText
                option={months}
                dropShow={"name"}
                labelName={"Q1 From Month"}
                isRequired={true}
                feildName={"q1FromMonth"}
                defaultValue={defaultQ1FromMonthValue}
                readOnly={props.crudState === "View" ? true : false}
                onChangeRole={(value) => {
                  setDefaultQ1FromMonthValue({
                    ...defaultValue,
                    q1FromMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                  setFinancialYearQuartersData({
                    ...FinancialYearQuartersData,
                    q1FromMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={2} >
              <div className={classes.dashSign}> - </div>
            </Grid>

            <Grid item xs={12} md={5}>
              <AutocompleteText
                option={months}
                dropShow={"name"}
                labelName={"Q1 To Month"}
                isRequired={true}
                feildName={"q1ToMonth"}
                defaultValue={defaultQ1ToMonthValue}
                readOnly={props.crudState === "View" ? true : false}
                onChangeRole={(value) => {
                  setDefaultQ1ToMonthValue({
                    ...defaultValue,
                    q1ToMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                  setFinancialYearQuartersData({
                    ...FinancialYearQuartersData,
                    q1ToMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                }}
              />
            </Grid>

          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.subTitle}>Q2</Typography>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={5}>
              <AutocompleteText
                option={months}
                dropShow={"name"}
                labelName={"Q2 From Month"}
                isRequired={true}
                feildName={"q2FromMonth"}
                defaultValue={defaultQ2FromMonthValue}
                readOnly={props.crudState === "View" ? true : false}
                onChangeRole={(value) => {
                  setDefaultQ2FromMonthValue({
                    ...defaultValue,
                    q2FromMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                  setFinancialYearQuartersData({
                    ...FinancialYearQuartersData,
                    q2FromMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <div className={classes.dashSign}> - </div>
            </Grid>

            <Grid item xs={12} md={5}>
              <AutocompleteText
                option={months}
                dropShow={"name"}
                labelName={"Q2 To Month"}
                isRequired={true}
                feildName={"q2ToMonth"}
                defaultValue={defaultQ2ToMonthValue}
                readOnly={props.crudState === "View" ? true : false}
                onChangeRole={(value) => {
                  setDefaultQ2ToMonthValue({
                    ...defaultValue,
                    q2ToMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                  setFinancialYearQuartersData({
                    ...FinancialYearQuartersData,
                    q2ToMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                }}
              />
            </Grid>

          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.subTitle}>Q3</Typography>
          <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={5}>
              <AutocompleteText
                option={months}
                dropShow={"name"}
                labelName={"Q3 From Month"}
                isRequired={true}
                feildName={"q3FromMonth"}
                defaultValue={defaultQ3FromMonthValue}
                readOnly={props.crudState === "View" ? true : false}
                onChangeRole={(value) => {
                  setDefaultQ3FromMonthValue({
                    ...defaultValue,
                    q3FromMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                  setFinancialYearQuartersData({
                    ...FinancialYearQuartersData,
                    q3FromMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <div className={classes.dashSign}> - </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <AutocompleteText
                option={months}
                dropShow={"name"}
                labelName={"Q3 To Month"}
                isRequired={true}
                feildName={"q3ToMonth"}
                defaultValue={defaultQ3ToMonthValue}
                readOnly={props.crudState === "View" ? true : false}
                onChangeRole={(value) => {
                  setDefaultQ3ToMonthValue({
                    ...defaultValue,
                    q3ToMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                  setFinancialYearQuartersData({
                    ...FinancialYearQuartersData,
                    q3ToMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.subTitle}>Q4</Typography>
          <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={5}>
              <AutocompleteText
                option={months}
                dropShow={"name"}
                labelName={"Q4 From Month"}
                isRequired={true}
                feildName={"q4FromMonth"}
                defaultValue={defaultQ4FromMonthValue}
                readOnly={props.crudState === "View" ? true : false}
                onChangeRole={(value) => {
                  setDefaultQ4FromMonthValue({
                    ...defaultValue,
                    q4FromMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                  setFinancialYearQuartersData({
                    ...FinancialYearQuartersData,
                    q4FromMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <div className={classes.dashSign}> - </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <AutocompleteText
                option={months}
                dropShow={"name"}
                labelName={"Q4 To Month"}
                isRequired={true}
                feildName={"q4ToMonth"}
                defaultValue={defaultQ4ToMonthValue}
                readOnly={props.crudState === "View" ? true : false}
                onChangeRole={(value) => {
                  setDefaultQ4ToMonthValue({
                    ...defaultValue,
                    q4ToMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                  setFinancialYearQuartersData({
                    ...FinancialYearQuartersData,
                    q4ToMonth: value && value.name ? value.name : "",
                    name: value && value.name ? value.name : ""
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={'auto'}>
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Add" ? registerFinancialYearQuarters : updateFinancialYearQuarters}
                  isLoading={loading} />
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant="contained" color="accent3" onClick={() => props.setState({ ...props.state, right: false })}>Cancel</Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddEditFinancialYearQuarters;