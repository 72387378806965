// Default React, Router and Services Components
import React from "react";
import { Box, Button, Card, CardContent, Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material'

// Importing Custom Components
import useStyles from "./Header.styles";

// Importing useStyles
import { CancelIcon } from "@fluentui/react-icons-mdl2";

const Notifications = () => {
    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    // Declaration of States and Component Variables End Here

    return (
        // Layout for Notification
        <Box className={classes.popupArea}>
            {/* Title for Side Drawer */}
            <Box className={classes.popupHeading}>
                <Typography id="modal-modal-title" variant="h5">
                    Notifications <Chip label="10" size="small" color="primary" />
                </Typography>
            </Box>

            {/* Notification List */}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card elevation={0}>
                        <CardContent className={classes.notiArea}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                                    <Grid item xs>
                                        <Typography color="text.primary" fontSize={14} fontWeight="bold">
                                            Heading Comes Here
                                        </Typography>
                                        <Typography color="text.secondary" fontSize={11}>
                                            5 Mins ago  | Shanmugam Jayaraman
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Tooltip title="Close" placement="top" arrow>
                                            <IconButton aria-label="settings">
                                                <CancelIcon style={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Typography variant="body2" className={classes.notiMessage}>
                                This impressive paella is a perfect party dish and a fun meal to cook
                                together with your guests.
                                if you like.sssss
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card elevation={0}>
                        <CardContent className={classes.notiArea}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                                    <Grid item xs>
                                        <Typography color="text.primary" fontSize={14} fontWeight="bold">
                                            Heading Comes Here
                                        </Typography>
                                        <Typography color="text.secondary" fontSize={11}>
                                            5 Mins ago  | Shanmugam Jayaraman
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Tooltip title="Close" placement="top" arrow>
                                            <IconButton aria-label="settings">
                                                <CancelIcon style={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Typography variant="body2" className={classes.notiMessage}>
                                This impressive paella is a perfect party dish and a fun meal to cook
                                together with your guests.
                                if you like.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card elevation={0}>
                        <CardContent className={classes.notiArea}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                                    <Grid item xs>
                                        <Typography color="text.primary" fontSize={14} fontWeight="bold">
                                            Heading Comes Here
                                        </Typography>
                                        <Typography color="text.secondary" fontSize={11}>
                                            5 Mins ago  | Shanmugam Jayaraman
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Tooltip title="Close" placement="top" arrow>
                                            <IconButton aria-label="settings">
                                                <CancelIcon style={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Typography variant="body2" className={classes.notiMessage}>
                                This impressive paella is a perfect party dish and a fun meal to cook
                                together with your guests.
                                if you like.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card elevation={0}>
                        <CardContent className={classes.notiArea}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                                    <Grid item xs>
                                        <Typography color="text.primary" fontSize={14} fontWeight="bold">
                                            Heading Comes Here
                                        </Typography>
                                        <Typography color="text.secondary" fontSize={11}>
                                            5 Mins ago  | Shanmugam Jayaraman
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Tooltip title="Close" placement="top" arrow>
                                            <IconButton aria-label="settings">
                                                <CancelIcon style={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Typography variant="body2" className={classes.notiMessage}>
                                This impressive paella is a perfect party dish and a fun meal to cook
                                together with your guests.
                                if you like.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card elevation={0}>
                        <CardContent className={classes.notiArea}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                                    <Grid item xs>
                                        <Typography color="text.primary" fontSize={14} fontWeight="bold">
                                            Heading Comes Here
                                        </Typography>
                                        <Typography color="text.secondary" fontSize={11}>
                                            5 Mins ago  | Shanmugam Jayaraman
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Tooltip title="Close" placement="top" arrow>
                                            <IconButton aria-label="settings">
                                                <CancelIcon style={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Typography variant="body2" className={classes.notiMessage}>
                                This impressive paella is a perfect party dish and a fun meal to cook
                                together with your guests.
                                if you like.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card elevation={0}>
                        <CardContent className={classes.notiArea}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                                    <Grid item xs>
                                        <Typography color="text.primary" fontSize={14} fontWeight="bold">
                                            Heading Comes Here
                                        </Typography>
                                        <Typography color="text.secondary" fontSize={11}>
                                            5 Mins ago  | Shanmugam Jayaraman
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Tooltip title="Close" placement="top" arrow>
                                            <IconButton aria-label="settings">
                                                <CancelIcon style={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Typography variant="body2" className={classes.notiMessage}>
                                This impressive paella is a perfect party dish and a fun meal to cook
                                together with your guests.
                                if you like.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card elevation={0}>
                        <CardContent className={classes.notiArea}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                                    <Grid item xs>
                                        <Typography color="text.primary" fontSize={14} fontWeight="bold">
                                            Heading Comes Here
                                        </Typography>
                                        <Typography color="text.secondary" fontSize={11}>
                                            5 Mins ago  | Shanmugam Jayaraman
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Tooltip title="Close" placement="top" arrow>
                                            <IconButton aria-label="settings">
                                                <CancelIcon style={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Typography variant="body2" className={classes.notiMessage}>
                                This impressive paella is a perfect party dish and a fun meal to cook
                                together with your guests.
                                if you like.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card elevation={0}>
                        <CardContent className={classes.notiArea}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                                    <Grid item xs>
                                        <Typography color="text.primary" fontSize={14} fontWeight="bold">
                                            Heading Comes Here
                                        </Typography>
                                        <Typography color="text.secondary" fontSize={11}>
                                            5 Mins ago  | Shanmugam Jayaraman
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Tooltip title="Close" placement="top" arrow>
                                            <IconButton aria-label="settings">
                                                <CancelIcon style={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Typography variant="body2" className={classes.notiMessage}>
                                This impressive paella is a perfect party dish and a fun meal to cook
                                together with your guests.
                                if you like.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card elevation={0}>
                        <CardContent className={classes.notiArea}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                                    <Grid item xs>
                                        <Typography color="text.primary" fontSize={14} fontWeight="bold">
                                            Heading Comes Here
                                        </Typography>
                                        <Typography color="text.secondary" fontSize={11}>
                                            5 Mins ago  | Shanmugam Jayaraman
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Tooltip title="Close" placement="top" arrow>
                                            <IconButton aria-label="settings">
                                                <CancelIcon style={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Typography variant="body2" className={classes.notiMessage}>
                                This impressive paella is a perfect party dish and a fun meal to cook
                                together with your guests.
                                if you like.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card elevation={0}>
                        <CardContent className={classes.notiArea}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                                    <Grid item xs>
                                        <Typography color="text.primary" fontSize={14} fontWeight="bold">
                                            Heading Comes Here
                                        </Typography>
                                        <Typography color="text.secondary" fontSize={11}>
                                            5 Mins ago  | Shanmugam Jayaraman
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Tooltip title="Close" placement="top" arrow>
                                            <IconButton aria-label="settings">
                                                <CancelIcon style={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Typography variant="body2" className={classes.notiMessage}>
                                This impressive paella is a perfect party dish and a fun meal to cook
                                together with your guests.
                                if you like.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} textAlign="center">
                    <Button variant="contained" color="accent3" size="small">
                        View All Notifications
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Notifications