// Default React, Router and Services Components
import React, { useState } from "react";
import { Breadcrumbs, Button, Box, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';

// Importing Custom Services
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import SideDrawer from "../../../UIComponent/Drawer";
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { toasterSuccess, toasterError } from '../../../UIComponent/Toaster/index';

// Importing Add & Edit Components
import AddTopProducers from "./AddTopProducers";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import "../../../../src/index.scss";
import useStyles from "../ManageData.styles";

const TopProducers = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [id, setId] = useState([]);
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState([]);
  const [selected, SetSelected] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [showDelete, setDelete] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [state, setState] = useState({ right: false });
  const [startSearch, setStartSearch] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [deleteProducerData, setDeleteProducerData] = useState(null);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [menuPermission, setMenuPermission] = useState({ allowEdit: true, allowDelete: true, });
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(25, function (err, res) {
      if (res) {
        // console.log(res.data)
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])
  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) {
      searchUser('')
    } else { searchUser(searchValue) }
  }, [sorting]);

  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) {
      searchUser('')
    } else { searchUser(searchValue) }
  }, [page, reload, rowsPerPage])
  // Declaration of React Hooks End Here

  // Assigning Columns and UI Props
  const columns = [
    { id: "rank", label: "Rank", minWidth: 100, width: '10%', sorting: true },
    { id: "commodityName", label: "Commodity", minWidth: 300, width: '29%', sorting: true, truncate: 90, tooltipWithTruncate: true },
    { id: "cruCompanyName", label: "Company", minWidth: 250, width: '29%', sorting: false },
    { id: "source", label: "Data Source", minWidth: 250, width: '30%', sorting: false },
  ];

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { getUserData(); }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      setTotalCount(0);
      setPage(0);
      setStartSearch(true)
      searchUser(event.target.value);
    }
  };
  // Function for Global Search
  const globalSearch = (value) => { setSearchValue(value); };

  // Function for Search Clear
  const searchUser = (filterValue) => {
    setLoading(true);
    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
    ApiService.search("topproducercompanies/GetTopProducerCompaniesList?", {
      sortColumn: changeSortColumnNameFormat(sorting.sortBy),
      isAscending: sorting.isAscending,
      searchText: encodeURIComponent(filterValue)
    }, prt)
      .then((res) => {
        if (res.status === 200) {
          let searchResult = Array.isArray(res.data) ? res.data : res.data.data || [];
          setAllUsers(searchResult);
          setLoading(false);
          setTotalCount(res.data.totalCount);
        } else {
          toasterError(`${res.data}`)
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

  // Function for Sorting
  const changeSortColumnNameFormat = (fieldName) => {
    if (!fieldName) { return ""; }
    if (fieldName.toLocaleLowerCase() == "rank") { fieldName = "Rank"; }
    if (fieldName.toLocaleLowerCase() == "commodityname") { fieldName = "CommodityName"; }
    return fieldName;
  }

  // Function for Getting Top Producer List
  const getUserData = () => {
    setLoading(true);
    if (reload) { setReload(false); }
    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
    ApiService.search("topproducercompanies/GetTopProducerCompaniesList?", {
      sortColumn: changeSortColumnNameFormat(sorting.sortBy),
      isAscending: sorting.isAscending,
    }, prt)
      .then((res) => {
        if (res.status === 200) {
          let result = Array.isArray(res.data) ? res.data : res.data.data || [];
          setAllUsers(result);
          setLoading(false);
          setTotalCount(res.data.totalCount);
        } else { toasterError(`${res.data}`); setLoading(false); }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

  // Function for Delete Producer
  const deleteProducer = () => {
    setDeleteBtnLoadinng(true);
    let data = deleteProducerData;
    data.active = false;
    ApiService.create("topproducercompanies/DeleteTopProducerCompany", data)
      .then((res) => {
        if (res.status === 200) {
          toasterSuccess("Producer deleted successfully");
          getUserData();
          setDeleteBtnLoadinng(false);
          setOpenDelete(false);
        } else {
          toasterError("Somthing went wrong");
          setOpenDelete(false);
          setDeleteBtnLoadinng(false);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") {
      setDeleteProducerData(item);
      setOpenDelete(true);
    } else {
      setFormData(item);
      setState({ ...state, right: open, formData: item });
      setCrudState(mode);
    }
  };
  const checkboxRequired = (e, item) => {
    SetSelected(e);
    setId(e);
    if (e.length > 0) { setDelete(true); }
    else { setDelete(false); }
  };


  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Top Producers</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">Top Producers</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {(access.allowEdit) ? <>
                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: "16px" }} />}>
                  Add Top Producers
                </Button></> : null}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      // label="TextField"
                      className="serchByKeyword mt-1"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="serchByClear"
                        onClick={() => {
                          setSearchValue("");
                          getUserData();
                        }}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">
                      Total Records: <span className={classes.totalRecord}>{totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Producers Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allUsers ? allUsers : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => { setPage(data); setReload(true); }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setReload(true)
                    setSorting(e)
                  }}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Add Top Producers Side Drawer */}
      <SideDrawer
        formComp={
          <AddTopProducers
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            // userId={null}
            hideSideDrawer={() => {
              setState({ right: false });
              getUserData();
            }}
          />
        }
        state={state}
        setState={setState}
      />

      {/* Alert Dialog - Confirmation for Delete */}
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Do you want to delete rank ${deleteProducerData?.rank} for ${deleteProducerData?.commodityName} from ${deleteProducerData?.cruCompanyName} ?`}
          action={
            <>
              <div className={classes.alertDialogbtn}>
                <Button onClick={() => setOpenDelete(false)}>No</Button>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button onClick={() => deleteProducer()} color="secondary">
                    Yes
                  </Button>
                )}
              </div>
            </>
          }
        />
      ) : null}
    </Box>

  );
};

export default TopProducers;