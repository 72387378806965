// Default React, useState and Material Components
import React, { useState, useEffect } from "react";
import { Box, Button, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import AutocompleteText from "../../../UIComponent/Autocomplete";
import SideDrawer from "../../../../src/UIComponent/Drawer/index";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing Add & Edit Components
import AddUnit from "../UnitCRU/AddUnitCRU";

// Import Fluent UI Icons
import { CirclePlusIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddUnitReport = (props) => {
  
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const mode = props.crudState;
  const [roles, setRoles] = useState([]);
  const [show, setShow] = useState(-1);
  const [formData, setFormData] = useState([]);
  const [cRUUnits, setCRUUnits] = useState([]);
  const [editView, setEditView] = useState({});
  const [loading, setLoading] = useState(false);
  const [tableName, setTableName] = useState("");
  const [crudMode, setCrudMode] = useState("add");
  const [crudModal, setCrudModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [apiAccessRight, setApiAccessRight] = useState({});
  const [state, setState] = React.useState({ right: false });
  const [defaultCRUUnit, setDefaultCRUUnit] = useState(null);
  const [openConfirmation, setConfirmation] = useState(false);
  const [refreshCRUUnits, setrefreshCRUUnits] = useState(false);
  const [jiraTicketDisable, setJiraTicketDisable] = useState(true);
  const [addUnitReportDrawer, setAddUnitReportDrawer] = useState(false);
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [hideAddCRUUnitButton, setHideAddCRUUnitButton] = useState(false);
  const [mappedCRUUnit, setMappedCRUUnit] = useState(props.mappedCRUVariable);
  const [unitReportData, setUnitReportData] = useState({
    id: 0, name: "", cruUnitId: 0, cruUnitName: "", isActive: true, statusId: 0,
    isDefault: true, isEdited: true, isDeleted: true,
  });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  useEffect(() => { getAllCRUUnits(); }, [refreshCRUUnits]); useEffect(() => {
    if (Object.values(props.formData).length > 0) { setEditView(props.formData); }
  }, [props.formData]);
  React.useEffect(() => {
    setMappedCRUUnit(props.mappedCRUVariable);
    if (props.prevFormValue != null) {
      setUnitReportData({
        id: props.prevFormValue.id, name: props.prevFormValue.name,
        cruUnitId: props.prevFormValue.cruUnitId,
        cRUUnitName: props.prevFormValue.cRUUnitName,
        isActive: props.prevFormValue.isActive,
        statusId: props.prevFormValue.statusId
      });
      cRUUnits.forEach((rData, i) => {
        if (rData.cRUUnitId === props.prevFormValue.cruUnitId) {
          setDefaultCRUUnit(cRUUnits[i]);
        }
      });
      getUnitReportStatus(props.prevFormValue.id);
    }
    if (props.crudState === "Edit") {
      getUnitReportStatus(props.formData.id);
      setUnitReportData({
        id: props.formData.id,
        name: props.formData.name,
        cRUUnitId: props.formData.cruUnitId,
        cRUUnitName: props.formData.cruUnitName,
        isActive: props.formData.isActive,
        statusId: props.formData.statusId
      });
      if (props.formData.cruUnitId != null) {
        cRUUnits.forEach((rData, i) => {
          if (rData.cRUUnitId === props.formData.cruUnitId) {
            setDefaultCRUUnit(cRUUnits[i]);
          }
        });
      }
    }
  }, [cRUUnits]);

// Function to get all CRU Units from the API
const getAllCRUUnits = () => {
  // Call the ApiService to get all CRU Units
  ApiService.getAll(`managedata/FillAllCRUUnits`).then((res) => {
    // Check if the response status is 200
    if (res.status == 200) {
      // Set the CRU Units with the response data
      setCRUUnits(res.data)
    }
  }).catch((err) => { 
    // Handle the error and call the toasterError function
    ErroHandling(err, function (res) {
    toasterError(res)})
  });
};
const cruUnitClick = () => { props.openAddCRUUnitDrawer(unitReportData); };

// This function is used to get the unit report status
const getUnitReportStatus = (id) => {
  // Check if id is greater than 0
  if (id > 0) {
     // Make an axios request with the given url and headers
     axios.get(process.env.React_App_baseUrl + `managedata/GetUnit?unitID=${id}`,{
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
    })
      .then((res) => {
        // Check if response status is 200 and jira is not created and cruUnitId is either 0 or null
        if (res.status == 200 &&  (!res.data.isJiraCreated) && (unitReportData.cruUnitId == 0 || unitReportData.cruUnitId == null)) {
          // Set jira ticket disable to false
          setJiraTicketDisable(false);
        } else {
          // Set jira ticket disable to true
          setJiraTicketDisable(true);
        }
      });
  } else { 
    // Show error message
    toasterError("something wrong has occured!"); 
  }
};

// This function is used to update the unit report data
const onUpdateApi = () => {
  // Set loading to true while updating the unit report data
  setLoading(true);
  // Get the name from the unit report data
  const { name } = unitReportData;
  // Check if the name length is greater than 0
  if (name.length > 0) {
    // Make a patch request to the API with the unit report data and authorization token
    axios.patch(process.env.React_App_baseUrl + "managedata/UpdateUnitReport", {
      ...unitReportData,
    },{
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
    })
      .then((res) => {
        // Set the edit view to the response data
        setEditView(res.data);
        // If the response status is 200, set loading to false and show success message
        if (res.status === 200) {
          setLoading(false);
          toasterSuccess("Unit Report updated successfully!");
          props.hideSideDrawer();
        }
      })
      .catch((err) => { 
        // Set loading to false and handle errors
        setLoading(false); 
        ErroHandling(err, function (res) {
          toasterError(res)})
        });
  } else {
    // Show error message if required fields are not entered
    toasterError("Please enter the required fields");
    setLoading(false);
  }
};

// This function is used to save data to the API
const onSaveApi = (data) => {
  // Check if the name field is not empty and has a length of less than 150 characters
  if (data.name !== '' && data.name.trim() !== "" && (data.name.length <= 150)) {
    setLoading(true);
    // Call the ApiService to create the data
    ApiService.create("manageData/SaveUnitReport", data)
      .then((res) => {
        toasterSuccess("Unit added successfully!");
        // Set the default unit value if props.setDefaultUnitValue is not null
        if (props.setDefaultUnitValue != null) {
          props.setDefaultUnitValue(data.name)
        }
        // If the response status is 200, set the loading to false and update the unit report
        if (res.status === 200) {
          if (unitReportData.cruUnitId == 0 || unitReportData.cruUnitId == null) {
            setLoading(false);
            setJiraTicketDisable(false);
            if (props.updateUnitReport != null) {
              props.updateUnitReport(false);
            }
          } else {
            setJiraTicketDisable(true);
            if (props.updateUnitReport != null) {
              props.updateUnitReport(true);
            }
          }
          // Set the unit report data id to the response data id
          if (unitReportData.id == 0) {
            setUnitReportData({
              ...unitReportData, id: res.data.id
            })
          }
          setJiraTicketDisable();
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        // Call the error handling function
        ErroHandling(err, function (res) {
          toasterError(res)})
      });
  } else {
    // Show an error message if the name field is empty or has more than 150 characters
    if (data.name.length > 150) {
      toasterError("Unit name must be less than 150 characters");
      setLoading(false);
    }
    else {
      toasterError("Please enter the required fields");
    }
  }
};
 
// This function handles the save action for unit report data
const handleSave = () => {
  debugger
  // Create a copy of the unit report data
  const data = { ...unitReportData };
  // Set the name, cruUnitId and cruUnitName from the original unit report data
  data.name = unitReportData.name;
  data.cruUnitId = unitReportData.cruUnitId;
  data.cruUnitName = unitReportData.cruUnitName;
  // Update the unit report data with the new data
  setUnitReportData(data);
  // Call the API to save the data
  onSaveApi(data);
};

  // Function for Open, Edit and Delete Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") { setOpenDelete(true); }
    else {
      setAddUnitReportDrawer(true);
      if (mode === "Edit") {
        setFormData(item);
        setState({ ...state, right: open, formData: item });
        setCrudState(mode);
      }
      if (mode === "Add") {
        setState({ ...state, right: open, formData: item });
        setCrudState(mode);
      }
    }
  };

// Function for Creating Jira Ticket
const createJiraTicket = () => {
  // Check if unitReportData.id is greater than 0
  if (unitReportData.id > 0) {
    // Make a POST request to the specified URL with the given data and headers
    axios.post(process.env.React_App_baseUrl + "jira/CreateCRUJiraTicket", {
      id: unitReportData.id,
      isVariableReportChange: false,
    },{
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
    })
      .then((res) => {
        // If response status is 200, call getUnitReportStatus() and setJiraTicketDisable() functions
        if (res.status === 200) {
          getUnitReportStatus(unitReportData.id);
          toasterSuccess("Jira ticket created successfully!");
          setJiraTicketDisable(true);
        }
      }, (err) => { 
        // If an error occurs, display an error message
        toasterError("Jira Ticket Not Created!"); 
      }
      );
  }
};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Unit (Report)
        </Typography>
      </Box>

      {/* Add & Edit Top Producers Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField id="standard-basic"
            label="Unit"
            fullWidth
            required
            variant="standard"
            inputProps={{ maxLength: 50 }}
            value={props.crudState === "Edit" ? props.formData.name : null}
            onChange={(e) => {
              props.setFormData({ ...props.formData, name: e.target.value });
              setUnitReportData({ ...unitReportData, name: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={!mappedCRUUnit && !hideAddCRUUnitButton ? 10 : 12}>
          <AutocompleteText
            option={cRUUnits}
            dropShow={"cruUnitName"}
            labelName={"CRU Unit"}
            feildName={"id"}
            isRequired={false}
            defaultValue={defaultCRUUnit}
            readOnly={props.crudState === "View" ? true : false}
            onChangeRole={(value) => {
                if (value && value.cRUUnitId) {
                setDefaultCRUUnit({
                  ...cRUUnits,
                  cRUUnitName: value && value.cruUnitName ? value.cruUnitName : "",
                  cRUUnitId: value && value.cRUUnitId ? value.cRUUnitId : 0,

                });
                setUnitReportData({
                  ...unitReportData,
                  cRUUnitName: value && value.cruUnitName ? value.cruUnitName : "",
                  cRUUnitId: value && value.cRUUnitId ? value.cRUUnitId : 0,

                });
                setJiraTicketDisable(true);
                setHideAddCRUUnitButton(true);
                setMappedCRUUnit(true);
              }
              else {
                setDefaultCRUUnit(null);
                setUnitReportData({
                  ...unitReportData,
                  cRUUnitName: "",
                  cRUUnitId: 0,

                });
                setHideAddCRUUnitButton(false);
                setMappedCRUUnit(false);
              }
            }}
          />
        </Grid>
            {!mappedCRUUnit && !hideAddCRUUnitButton ?
            <Grid item xs={2}>
              <Tooltip title="Add Unit" placement="top" arrow>
                <IconButton color="secondary"
                  style={{ marginTop: 10 }}
                >
                  <CirclePlusIcon onClick={toggleDrawer(true, "Add")} />
                </IconButton>
              </Tooltip>
              </Grid>
              : ""}
         
        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={12} sm={'auto'}>
                <LoadingButton
                  buttonName={props.crudState === "Edit" ? "Update" : "Save"}
                  clickHandler={props.crudState === "Edit" ? onUpdateApi : handleSave}
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <Button variant="contained" color="accent3"
                  onClick={() => {
                    if (props.closeVariableReportDrawer != null && props.closeVariableReportDrawer !== undefined) {
                      props.closeVariableReportDrawer();
                    }
                  }}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <Button variant="contained" color="secondary"
                  disabled={jiraTicketDisable}
                  onClick={createJiraTicket}>
                  Create jira Ticket
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Add Unit Side Drawer */}
      <SideDrawer
        formComp={
          // Calling Add Unit
          <AddUnit
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            variableId={null}
            hideSideDrawer={() => {
              setState({ right: false });
              setAddUnitReportDrawer(true);
              setrefreshCRUUnits(!refreshCRUUnits);
            }}
            addUnitReportDrawer={addUnitReportDrawer}
            closeAddUnitReport={(e) => {
              setAddUnitReportDrawer(true);
              setrefreshCRUUnits(!refreshCRUUnits);
            }
            }
          />
        }
        state={state}
        setState={setState}
      />
    </Box>
  );
};

export default AddUnitReport;