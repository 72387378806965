// Default React, useState and Material Components
import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import AutocompleteText from "../../../UIComponent/Autocomplete";
import SideDrawer from "../../../../src/UIComponent/Drawer/index";
import AddEditCruVariable from "../CruVariable/AddEditCruVariable";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Import Fluent UI Icons
import { CirclePlusIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddEditVariableReport = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const mode = props.crudState;
  const [show, setShow] = useState(-1);
  const [roles, setRoles] = useState([]);
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState([]);
  const [editView, setEditView] = useState({});
  const [loading, setLoading] = useState(false);
  const [tableName, setTableName] = useState("");
  const [crudMode, setCrudMode] = useState("add");
  const [isDeleted, setIsDeleted] = useState(false);
  const [crudModal, setCrudModal] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [cRUVariables, setCRUVariables] = useState([]);
  const [jiraloading, setjiraloading] = useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [apiAccessRight, setApiAccessRight] = useState({});
  const [state, setState] = React.useState({ right: false });
  const [mappedVariable, setMappedVariable] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [cruVariableLoad, setCRUVariableLoad] = useState(false);
  const [jiraTicketDisable, setJiraTicketDisable] = useState(true);
  const [defaultCRUVariable, setDefaultCRUVariable] = useState(null);
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [addCRUVariableDrawer, setAddCRUVariableDrawer] = useState(false);
  const [hideAddCRUVariableButton, setHideAddCRUVariableButton] = useState(false);
  const [variableReportData, setVariableReportData] = useState({
    id: 0, name: "", cRUVariableId: 0, cRUVariableName: "", isActive: true,
    statusId: 0, isDefault: true, isEdited: true, isDeleted: true,
  });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  useEffect(() => { getAllCRUVariables(); }, [cruVariableLoad]);
  React.useEffect(() => {
    setMappedVariable(props.mappedCRUVariable);
    if (props.crudState === "Edit") {
      if (mappedVariable) {
        setHideAddCRUVariableButton(true);
      }
      if (props.formData.cruVariableId == 0 || props.formData.cruVariableId != null) {
        setJiraTicketDisable(true);
      } else {
        getVariableReportStatus(props.formData.id);
      }
      setVariableReportData({
        id: props.formData.id,
        name: props.formData.name,
        cRUVariableId: props.formData.cruVariableId,
        cRUVariableName: props.formData.cruVariableName,
        isActive: props.formData.isActive,
        statusId: props.formData.statusId
      });
      if (props.formData.cruVariableId != null) {
        cRUVariables.forEach((rData, i) => {
          if (rData.id === props.formData.cruVariableId) {
            setDefaultCRUVariable(cRUVariables[i]);
          }
        });
      }
    }
  }, [cRUVariables]);

  React.useEffect(() => {
    if (variableReportData.cRUVariableId == 0 || variableReportData.cRUVariableId == null) {
      getVariableReportStatus(variableReportData.id);
    }
  }, [variableReportData.cRUVariableId])
  // Declaration of React Hooks End Here


  // Function for Open Add Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Add") {
      setState({ ...state, right: open, formData: item });
      setCrudState(mode);
    }
  };

// Function to get the status of a variable report
const getVariableReportStatus = (id) => {
  // Check if id is greater than 0
  if (id > 0) {
    // Make an axios request with the given id
    axios.get(process.env.React_App_baseUrl + `variablereport/GetVariableReportById?id=${id}`, {
      headers: {
        // Get the token from session storage
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then((res) => {
        // Check if response status is 200 and jira ticket is not created and cRUVariableId is 0 or null
        if (res.status == 200 && (!res.data.isJiraCreated) && (variableReportData.cRUVariableId == 0 || variableReportData.cRUVariableId == null)) {
          // Set jira ticket disable to false
          setJiraTicketDisable(false);
        } else {
          // Set jira ticket disable to true
          setJiraTicketDisable(true);
        }
      });
  } else { 
    // Do nothing
  }
}



  const handleClickOpen = (item) => {
    setOpenDelete(true);
    setDeletedItem(item);
    // if (item.id > 0) {
    //   isDeletedApi(item.id);
    // } else {
    //   setIsDeleted(true);
    // }
  };

  const handleClose = () => {
    setIsDeleted(false);
  };


 // This function is used to save the data to the API
const onSaveApi = (data) => {
    
  // Set loading to true
  setLoading(true);
  
  // Check if the data is valid and the id is 0
  if (validation(data) && data.id == 0) {
    axios
      .post(process.env.React_App_baseUrl + "variablereport/CreateVariableReport", { ...data }, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then((res) => {
        // If props.setDefaultVariableValue is not null, set the default variable value
        if (props.setDefaultVariableValue != null) {
          props.setDefaultVariableValue(data.name)
          props.setDefaultVaribaleCRUValue(data.cRUVariableName)
        }
        
        // If response status is 200
        if (res.status === 200) {
          
          // Check if cRUVariableId is 0 or null
          if (variableReportData.cRUVariableId == 0 || variableReportData.cRUVariableId == null) {
            // Set jiraTicketDisable to false
            setJiraTicketDisable(false);
            
            // If props.updateMappedCRUVariable is not null, update mapped CRU Variable
            if (props.updateMappedCRUVariable != null) {
              props.updateMappedCRUVariable(false);
            }

          } else {
            // Set jiraTicketDisable to true
            setJiraTicketDisable(true);
            
            // If props.updateMappedCRUVariable is not null, update mapped CRU Variable
            if (props.updateMappedCRUVariable != null) {
              props.updateMappedCRUVariable(true);
            }
          }

          // If variableReportData.id is 0, set it to res.data.id
          if (variableReportData.id == 0) {
            setVariableReportData({
              ...variableReportData, id: res.data.id
            })
          }

          // Get variable report status
          getVariableReportStatus(variableReportData.id);

          // Show success message
          toasterSuccess("Variable report added successfully!");
          setLoading(false);
          
        }
      })
      .catch((err) => {
        // Set loading to false
        setLoading(false);
        
        // Handle error
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  } else {
    // Set loading to false
    setLoading(false);
  }
}
// This function is used to update the variable report data
const onUpdateApi = (data) => {
  // Set loading to true while updating the data
  setLoading(true);
  // Check if the data is valid and id is greater than 0
  if (validation(data) && data.id > 0) {
    // Make a post request to the API with the data and authorization token
    axios
      .post(process.env.React_App_baseUrl + "variablereport/UpdateVariableReport", { ...data }, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then((res) => {
        // If the response status is 200, show success message and update the mapped CRU variable
        if (res.status === 200) {
          
          toasterSuccess("Variable report updated successfully!");
          setLoading(false);
          if (variableReportData.cRUVariableId == 0 || variableReportData.cRUVariableId == null) {
            setJiraTicketDisable(false);
            if (props.updateMappedCRUVariable != null) {
              props.updateMappedCRUVariable(false);
            }

          } else {
            setJiraTicketDisable(true);
            if (props.updateMappedCRUVariable != null) {
              props.updateMappedCRUVariable(true);
            }
          }
          // Update the variable report data with the new id
          if (variableReportData.id == 0) {
            setVariableReportData({
              ...variableReportData, id: res.data.id
            })
          }
          // Get the variable report status
          getVariableReportStatus(variableReportData.id);


        }
      })
      .catch((err) => {

        setLoading(false);
        // Handle errors
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  } else {
    setLoading(false);
  }
}
  const handleChangeIcon = () => {
    setChecked((prev) => !prev);
    setIsActive((current) => !current);
  };

  const handleADClose = () => {
    setOpenSaveConfirmation(false);
  };

  const openAlertDialog = () => {
    setConfirmation(true);
  };


  const validation = (variableReport) => {
    if (variableReport.name.trim() == "") {
      toasterError("Please enter report variable");
      return false;
    }
    if (variableReport.name.length > 150) {
      toasterError("Please enter CRU variable less than 150 characters");
      return false;
    }
    // if (variableReport.cRUVariableId == 0) {
    //   toasterError("Please select CRU variable");
    //   return false;
    // }
    // if (variableReport.usualSource.length > 100) {
    //   toasterError("Please enter usual source less than 100 characters");
    //   return false;
    // }

    return true;
  }
  const handleSave = () => {
    const data = { ...variableReportData };
    //data.accessRightsDto = apiAccessRight.accessRightsDto;
    setVariableReportData(data);
    onSaveApi(data);

  };



  const handUpdate = () => {
    const data = { ...variableReportData };
    //data.accessRightsDto = apiAccessRight.accessRightsDto;
    setVariableReportData(data);
    onUpdateApi(data);
  };

  const onChangeHandler = (e, id) => {
    setTableName(e.target.value);
  };

  const handleDelete = (id, index) => {
    const value = { ...apiAccessRight };
    const arr = value.accessRightsDto.filter((r) => r.id !== id);
    value.accessRightsDto = arr;
    setApiAccessRight(value);
  };


// Function to get all CRU Variables
const getAllCRUVariables = () => {
  // Call ApiService to get all CRU Variables
  ApiService.getAll(`cruvariable/GetAllCRUVariable`).then((res) => {
    // Check if response status is 200
    if (res.status == 200) {
      // Set CRU Variables with response data
      setCRUVariables(res.data)
    }
  }).catch((err) => {
    // Handle error and call toasterError function
    ErroHandling(err, function (res) {
      toasterError(res)
    })
  });
}

 // Function to create a Jira ticket
const createJiraTicket = () => {
  // Set loading state to true
  setjiraloading(true);
  // Check if variableReportData.id is greater than 0
  if (variableReportData.id > 0) {
    // Make an axios post request with the given parameters
    axios.post(process.env.React_App_baseUrl + "jira/CreateCRUJiraTicket", {
      id: variableReportData.id,
      isVariableReportChange: true,
    }, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then((res) => {
        // If response status is 200, call getVariableReportStatus function and display success message
        if (res.status === 200) {
          getVariableReportStatus(variableReportData.id);
          toasterSuccess("Jira ticket created successfully!");
          setjiraloading(false);
          setJiraTicketDisable(true);
        }
      },
        // If error occurs, display error message
        (err) => {
          toasterError("Jira Ticket Not Created");
          setjiraloading(false);
        }
      );
  }
  // If variableReportData.id is not greater than 0, display error message
  else {
    toasterError("Please create  report variable before jira ticket.");
    setjiraloading(false);
  }
};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Variable (Report)
        </Typography>
      </Box>

      {/* Add & Edit Top Producers Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField id="standard-basic"
            label="Report Variable"
            value={props.crudState == "Edit" ? props.formData.name : variableReportData.name}
            name="name"
            fullWidth
            required
            inputProps={{ maxLength: 150 }}
            onChange={(e) => {
              props.setFormData({ ...props.formData, name: e.target.value });
              setVariableReportData({ ...variableReportData, name: e.target.value });
            }}
            variant="standard" />
        </Grid>

        <Grid item xs={12}>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1} justifyContent="self-end" alignItems="self-end">
              <Grid item xs>
                <AutocompleteText
                  option={cRUVariables}
                  dropShow={"cruVariableName"}
                  labelName={"CRU Variable"}
                  feildName={"id"}
                  isRequired={false}
                  defaultValue={defaultCRUVariable}
                  readOnly={props.crudState === "View" ? true : false}
                  onChangeRole={(value) => {
                    if (value && value.id) {
                      setDefaultCRUVariable({
                        ...cRUVariables,
                        cRUVariableName: value && value.cruVariableName ? value.cruVariableName : "",
                        cRUVariableId: value && value.id ? value.id : 0,
                      });
                      setVariableReportData({
                        ...variableReportData,
                        cRUVariableName: value && value.cruVariableName ? value.cruVariableName : "",
                        cRUVariableId: value && value.id ? value.id : 0,
                      });
                      setJiraTicketDisable(true);
                      setHideAddCRUVariableButton(true);
                      setMappedVariable(true);
                    }
                    else {
                      setDefaultCRUVariable(null);
                      setVariableReportData({
                        ...variableReportData,
                        cRUVariableName: "",
                        cRUVariableId: 0,
                      });
                      setHideAddCRUVariableButton(false)
                      setMappedVariable(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs="auto">
                {!hideAddCRUVariableButton ?
                  <Tooltip title="Add (CRU) Variable" placement="top" arrow>
                    <IconButton color="secondary" style={{ marginTop: 10, marginLeft: 10 }}>
                      <CirclePlusIcon onClick={toggleDrawer(true, "Add")} />
                    </IconButton>
                  </Tooltip> : ""}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={12} sm={'auto'}>
                {loading ? (
                  <Button disabled>
                    <CircularProgress size={24} />
                  </Button>
                ) : (
                  <Button variant="contained" color="accent5"
                    onClick={props.crudState === "Edit" ? handUpdate : handleSave}>
                    {props.crudState === "Edit" ? "Update" : "Save"}
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <Button variant="contained" color="accent3"
                  onClick={() => {
                    if (props.closeVariableReportDrawer != null && props.closeVariableReportDrawer !== undefined) {
                      props.closeVariableReportDrawer(mappedVariable);
                    }
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                {jiraloading ? (
                  <Button disabled>
                    <CircularProgress size={24} />
                  </Button>
                ) : (
                  <Button variant="contained" color="secondary"
                    disabled={jiraTicketDisable}
                    onClick={createJiraTicket}
                  >
                    Create jira Ticket
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Add CRU Variable Side Drawer */}
      <SideDrawer
        formComp={
          <AddEditCruVariable
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            variableId={null}
            hideSideDrawer={() => {
              setState({ right: false });
              setAddCRUVariableDrawer(true);
              setCRUVariableLoad(!cruVariableLoad);
            }}
            addCRUVariableDrawer={addCRUVariableDrawer}
            closeAddCRUVariable={(e) => {
              setAddCRUVariableDrawer(true);
              setCRUVariableLoad(!cruVariableLoad);
            }
            }
          />
        }
        state={state}
        setState={setState}
      />
    </Box>
  );
};

export default AddEditVariableReport;