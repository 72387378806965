import { AppInsights } from "./AppInsights";
import CommonService from "./CommonService";

export default function ErroHandling(error, callback) {
  let hasError = false;
  console.log("error", error);
  if (
    AppInsights !== undefined &&
    AppInsights !== null &&
    AppInsights.config?.instrumentationKey !== undefined &&
    AppInsights.config?.instrumentationKey !== ""
  ) {
    AppInsights.trackException({ error: error });
  }

  // if(error.code){
  //   callback(error.code)
  //   hasError=true;
  // }
  // if(error.message){
  //   callback(error.message)
  //   hasError=true;
  // }
  if (error.response) {
    hasError = true;
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      localStorage.clear();
      localStorage.clear();
      window.location.href = '/logout';
      // console.log("token got expired");
      //callback("Error")
      callback = null;
    } else if (
      error.response &&
      error.response.status &&
      error.response.status === 403
    ) {
      //window.location.replace('#app');
      callback("Your access permission are denied");
    } else if (error.response.data) {
      callback(
        typeof error.response.data === "string"
          ? error.response.data
          : "Internal server error"
      );
    } else {
      callback("Something went wrong");
    }
  }
  // if (!hasError) {
  //   callback("Unknown error");
  // }
}
