/* eslint-disable no-undef */
// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Grid, Link, TextField, Typography
} from "@mui/material";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// Importing Custom Components
import { lightBlue } from "@mui/material/colors";
import DatePicker from "../../../UIComponent/DatePicker";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

import {
  getJobID, getJobTypeDropDown, getCompanyDropDown, companiesForBrandnewJob
} from "../../../features/JobManagement/jobSlice";

// import "../../Resources/scss/layout.scss";
// import "react-toastify/dist/ReactToastify.css";
// import "../../../src/index.scss";
// import DuplicateJob from "./DuplicateJob";

// Importing Custom Components
import { ScrollingDialog } from "../../../UIComponent";
import DuplicateJobData from "./DuplicateJobsData";

// Importing useStyles
import useStyles from "./jobManagement.styles";

// Import Material Icons
import ClearIcon from "@mui/icons-material/Clear";
import CommonService from "../../../Services/CommonService";
import { strUndefined } from "@microsoft/applicationinsights-core-js";

const CreateJob = (props) => {
  // Declaration of States and Component Variables Start Here
    const [historyData, setHistoryData] = useState();
  const classes = useStyles();
  const darkBlue = lightBlue[600];
  const { generatedJobId, allCompsFornewJOb } = useSelector((store) => store.jobs);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => { setOpen(true); };
  const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));
  const [testCOmpId, setTestCompId] = React.useState(0);
  const [comp, setComp] = React.useState("");
  const [currentCompany, setCurrentCompany] = React.useState([]);
  const [companyName, setCompanyName] = React.useState("");
  const [currentAsset, setCurrentAsset] = React.useState(null);
  const [currentAssetID, setCurrentAssetID] = React.useState("");
  const [countryName, setCountryName] = React.useState("");
  const [regionName, setRegionName] = React.useState("");
  const [currentassetType, setCurrentAssetType] = React.useState("");
  const [currentSubregion, setSubregion] = React.useState("");
  const [currentCommodity, setCurrentCommodity] = React.useState([]);
  const [currentPriority, setCurrentPriority] = React.useState({
    priorityId: 1, priorityName: "High", priorityDate: "",
  });
  const [tempPriority, setTempPriority] = React.useState(null)
  const [createJobInitial, setCreateJobInitial] = React.useState([]);
  const [finalAssetDetails, setFinalAssetDetils] = React.useState([]);
  const [jobType, setJobType] = React.useState("Company Data");
  const [tempAsset, setTempAsset] = React.useState("");
  const [remainingDays, setRemainingDays] = React.useState(0);
  const [enableStartDate, setEnableStartDate] = React.useState(true);
  const [finalAssetDetailObject, setFinalAssetDetailObject] = React.useState({
    tat: "",
    startDate: "",
    companyId:  0,
    priorityId: 0, isCRUCompany: false, assetId: 0, isCRUAsset: false
   
  });
  // const [finalAssetDetailObject, setFinalAssetDetailObject] = React.useState({
  //   tat: "2022-09-10T05:35:58.638Z",
  //   companyId: props.createJobData.companyId || 0,
  //   priorityId: 1, isCRUCompany: false, assetId: 0, isCRUAsset: false,
  // });
  const [finalData, setFinalData] = React.useState({
    jobName: "", jobTypeId: 1, instructions: "", companyAssetDetails: finalAssetDetails,
  });
  const [tempData, setTempData] = React.useState([]);
  const [priority, setPriority] = React.useState([]);
  const [triggerAssetList, setTriggerAssetList] = React.useState([]);
  const [duplicateJobs, setDuplicateJobs] = useState([]);
  const [totalDuplicateJobs, setTotalDuplicateJobs] = useState(0);
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [companyAssets, setCompanyAssets] = useState([])
  const [createManual, setCreateManual] = React.useState(true);
  const [triggerPriority, setTriggerPriority] = React.useState([]);
  const [loading, setLoading] = useState(false);
  // Declaration of States and Component Variables End Here

    // React.useEffect(() => {
    //     getDataFromHistory()
    // },[])
  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    // console.log(props.companyData, "props.companyData")
      createJobInitial.push(...props.companyData);
    dispatch(getJobID());
    dispatch(companiesForBrandnewJob());
    getCompanyDetails(createJobInitial);
    createJobInitial.length > 0 && setComp(createJobInitial[0].company)
    getAllPriority();

      if (props.isAssetSelected) {
      getCompaniesBasedonAsset(createJobInitial);
      setComp(createJobInitial[0].company)
    }
  }, []);
  React.useEffect(() => {
    if (triggerAssetList.length > 0) {
      getCompanyDetailsUnprompted(triggerAssetList);
    }
  }, [triggerAssetList]);

  React.useEffect(() => {
    if (triggerPriority.length > 0) {
      getCompanyPriority(
        triggerPriority[0].companyId,
        triggerPriority[0].isCRUCompany,
        triggerPriority[0].assetId,
        triggerPriority[0].isCRUAsset
      );
    }
  }, [triggerPriority]);
  // Declaration of React Hooks End Here


    const getDataFromHistory = () => {
        let temp = CommonService.GetStoredData(props, "cratejob");
        //let tempNewJob = CommonService.GetStoredData(props, "cratenewjob");
        if (temp) {
            setHistoryData(temp);
            setComp(temp.createJobData.companyName);
            setFinalAssetDetailObject({ ...finalAssetDetailObject, companyId: temp.createJobData.companyId })
        }
        // if(tempNewJob){
        //   setHistoryData(tempNewJob);
        //   setComp(tempNewJob.createJobData.companyName);
        //   setFinalAssetDetailObject({ ...finalAssetDetailObject, companyId: tempNewJob.createJobData.companyId })
 
        // }

    }


  // Function for Getting All Priority Data
  const getAllPriority = async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetPriorityDropDownFilter`, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      });
      const { data } = response;
      setPriority(data);
    } catch (err) {
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    }
  };

  // Function for Getting Companies Basedon Asset Data
  const getCompaniesBasedonAsset = (createJobInitialData) => {
    axios.get(
      `${process.env.React_App_baseUrl}source/GetAssets?pageNumber=1&pageSize=1000`,
      {
        headers: {
          CompanyId: createJobInitialData[0].companyId,
          isCRUCompany: createJobInitialData[0].isCRUCompany,
          sortColumn: "Asset",
          isAscending: true,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

        },
      }
    )
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].assetId === props.assetValue) {
            setFinalAssetDetailObject({
              ...finalAssetDetailObject,
              assetId: res.data.data[i].assetId,
              isCRUAsset: res.data.data[i].isCRUAsset,
              isCRUCompany: createJobInitialData[0].isCRUCompany,
            });
            setCurrentAsset(res.data.data[i].asset);
            setCurrentAssetID(res.data.data[i].assetId);
            setCurrentAssetType(res.data.data[i].assetType);
            setCountryName(res.data.data[i].country);
            setRegionName(res.data.data[i].region);
            setSubregion(res.data.data[i].subRegion);
            setCurrentCommodity([res.data.data[i].commodity]);
          }
        }
      })
      .catch((err) => {
        if(err.res.status===400){
          toasterError(err.res.data);

        }

         });
  };


  // Function for Getting Company Priority Data
  const getCompanyPriority = (companyId, isCRUCompany, assetId, isCRUAsset) => {
    axios.get(
      `${process.env.React_App_baseUrl}job/GetAssetPriorityDetails?CompanyId=${companyId}&IsCRUCompany=${isCRUCompany}&AssetId=${assetId}&IsCRUAsset=${isCRUAsset}`, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    }
    )
      .then((res) => {
        setCurrentPriority({
          ...currentPriority,
          priorityId: res.data.priorityId,
          priorityName: res.data.priorityName,
          priorityDate: res.data.tat,
          startDate: res.data.startDate
        });


        let defaultPrID
        priority.forEach((pr, i) => {
          if (pr.id === res.data.priorityId) {
            defaultPrID = i
          }
        });
        setTempPriority(priority[defaultPrID])
        let priorityDate = new Date(res.data.tat.split('-').reverse().join('-'));
        let startDateTemp = null;
        if (res.data.startDate !== null && res.data.startDate !== undefined) {
          startDateTemp = new Date(res.data.startDate.split('-').reverse().join('-'))
        }
        setFinalAssetDetailObject({
          ...finalAssetDetailObject,
          priorityId: res.data.priorityId,
          tat: priorityDate,
          startDate: startDateTemp
        });
        setRemainingDays(CommonService.getBusinessDatesCount(priorityDate))
        setEnableStartDate(false)
      })
      .catch((err) => {
        if(err.res.status===400){
          toasterError(err.res.data);

        }

         });
  };
  const checkDateValidation = (finalAssetDetailObject, escapeValidation = true) => {
    let validation = true;
    if (escapeValidation && finalData.jobName.length === 0) {
      toasterError("Please fill the required fields");
      validation = false;
      return validation;
    }
    finalAssetDetailObject.forEach((element, index) => {
      if (element.priorityId === 0) {
        toasterError("Please fill the required fields");
        validation = false;
        return validation;
      }
      // if (element.startDate === null || element.startDate === undefined || element.startDate === "") {
      //   toasterError("Job Start date can not be empty");
      //   validation = false;
      //   return validation;
      // }
      if (element.tat === null || element.tat === undefined || element.tat === "") {
        toasterError("TAT date can not be empty");
        validation = false;
        return validation;
      }
      // if (element.startDate > element.tat) {
      //   toasterError("Job can not start after completion of TAT date");
      //   validation = false;
      //   return validation;
      // }
    })
    return validation;
  }

  const saveJob  = (payload)=>{
    axios.post(`${process.env.React_App_baseUrl}job/SaveJob`, payload, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
    .then((res) => {
      props.setState({ ...props.state, right: false });
      props.setPageRefresh(true);
      toasterSuccess("Job created successfully");
      setLoading(false); 
    }).catch((err) => {
      setLoading(false);
      finalAssetDetails.length = 0;
      if(err.response.status===400){
        toasterError(err.response.data);

      }

       });

  
}


  const submitForm = async () => {
    setLoading(true);

    await finalAssetDetails.push(finalAssetDetailObject);
    if (checkDateValidation(finalAssetDetails)) {
      setCompanyAssets([
        {
          companyId: finalAssetDetailObject.companyId,
          isCRUCompany: finalAssetDetailObject.isCRUCompany,
          assetId: finalAssetDetailObject.assetId,
          isCRUAsset: finalAssetDetailObject.isCRUAsset,
        },
      ]);
      axios.post(`${process.env.React_App_baseUrl}job/CheckJobDuplication?pageNumber=0&pageSize=10`, [
        {
          companyId: finalAssetDetailObject.companyId,
          isCRUCompany: finalAssetDetailObject.isCRUCompany,
          assetId: finalAssetDetailObject.assetId,
          isCRUAsset: finalAssetDetailObject.isCRUAsset,
        },
      ], {
        headers: {
          startDate: '', endDate: '', manualJobCreation: createManual, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },

      })
        .then((res) => {
  
          if (res.data.data.length > 0) {
            setDuplicateJobs(res.data.data);
            setTotalDuplicateJobs(res.data.totalCount);
            setOpen(true);
            setLoading(false);
          } else {
            saveJob(finalData)
            setLoading(false);
          }
        })

    } else {
      setLoading(false);
    }
  };

  const handleClose = (e) => {
    setOpen(false);
    saveJob(finalData)
  };
  const showDuplicate = (e) => {
    e.stopPropagation();
    setOpenDuplicateDialog(true);
  }
  const getCompanyDetailsUnprompted = (createJobInitialData) => {
    axios.get(
      `${process.env.React_App_baseUrl}source/GetAssets?pageNumber=1&pageSize=1000`,
      {
        headers: {
          CompanyId: createJobInitialData[0].companyId,
          isCRUCompany: createJobInitialData[0].isCRUCompany,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

        },
      })
      .then((res) => {
        setCurrentCompany(res.data.data);
        if(res.data.data!==undefined && res.data.data.length > 0){
          setFinalAssetDetailObject({
            ...finalAssetDetailObject,
            companyId: res.data.data[0].companyId,
          });
        
        }
        clearFunction();
      })
      .catch((err) => {
        if(err.res.status===400){
          toasterError(err.res.data);
        }
         });

  };

  // Function for Getting Company Data
  const getCompanyDetails = (createJobInitialData) => {
    // console.log(createJobInitialData, "createJobInitialData")
    createJobInitialData.length > 0 &&
      axios
        .get(`${process.env.React_App_baseUrl}source/GetAssets?pageNumber=1&pageSize=1000`,
          {
            headers: {
              CompanyId: createJobInitialData[0].companyId ? createJobInitialData[0].companyId : 0,
              isCRUCompany: createJobInitialData[0].isCRUCompany ? createJobInitialData[0].isCRUCompany : false,
              'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

            },
          }
        )
        .then((res) => {

          setCurrentCompany(res.data.data);
          if(res.data.data!==undefined && res.data.data.length > 0 )
          {
            setCompanyName(res.data.data[0].company);
                   
              if (props.isAssetSelected) {
            setFinalAssetDetailObject({
              ...finalAssetDetailObject,
              assetId: res.data.data[0].assetId,
            });
          } else {
            setFinalAssetDetailObject({
              ...finalAssetDetailObject,
              companyId: res.data.data[0].companyId,
              isCRUCompany: createJobInitialData[0].isCRUCompany,
            });
          }
        }
        })
        .catch((err) => {
          if(err.res.status===400){
            toasterError(err.res.data);
          }
           });
  };


  // Function for Clear All Data
  const clearFunction = () => {
    setTempAsset("");
    setCurrentAsset("");
    setCurrentAssetType("");
    setCountryName("");
    setRegionName("");
    setSubregion("");
    setCurrentCommodity([]);
   
  };

  const GetNoOfDays = (startDate) => {
    let tempObject = [];
    tempObject.push(finalAssetDetailObject)
    if (checkDateValidation(tempObject, false)) {
      axios.get(
        `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
        headers: {
          CompanyId: finalAssetDetailObject.companyId,
          AssetId: finalAssetDetailObject.assetId,
          IsCRUCompany: finalAssetDetailObject.isCRUCompany,
          IsCRUAsset: finalAssetDetailObject.isCRUAsset,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }).then((res) => {
        if (res.status === 200) {
          let tempStartDate = new Date(startDate);
          //tempStartDate.setDate(tempStartDate.getDate() + res.data)
          let g = CommonService.addWorkingDays(tempStartDate, res.data)
          let newTatDate = CommonService.GetDateAsStringWithDash(g)
          let temstart=CommonService.GetDateAsStringWithDash(startDate)
          console.log(newTatDate)
          setCurrentPriority({
            ...currentPriority,
            priorityDate: newTatDate,
          });
          setFinalAssetDetailObject({
            ...finalAssetDetailObject,
            tat: newTatDate,
            startDate:temstart
          });
          setRemainingDays(CommonService.getBusinessDatesCount(g))
        } else { toasterError(`${res.data}`); setLoading(false); }
      })
      .catch((err) => {
        setLoading(false);
        if(err.res.status===400){
          toasterError(err.res.data);
        }

         });
    }
  }

  return (
    // Layout for Create Job
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Create Jobs
        </Typography>
      </Box>

      {/* Create Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            readOnly={true}
            size="small"
            fullWidth
            label="Job ID"
            placeholder="Job ID"
            value={generatedJobId}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            variant="standard"
            size="small"
            fullWidth
            label="Job Name"
            placeholder="JobName"
            onChange={(e) =>
              setFinalData({ ...finalData, jobName: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            variant="standard"
            readOnly
            size="small"
            fullWidth
            label="Job Type"
            placeholder="Job Type"
            value={jobType}
          />
        </Grid>
        <Grid item xs={12}>
                  {props?.newJob ? (
            <Autocomplete
              clearIcon={<ClearIcon fontSize="small" onClick={clearFunction} />}
              size="small"
              id="size-small-standard size-small-standard"
              options={allCompsFornewJOb}
              onChange={(e, value) => {
                
                if (value) {
                  setFinalAssetDetailObject({
                    ...finalAssetDetailObject,
                    companyId: value.companyId,
                    priorityId: currentPriority.priorityId,
                    isCRUCompany: value.isCRUCompany,
                  });

                  let tempInitialData = [
                    {
                      companyId: value.companyId,
                      isCRUCompany: value.isCRUCompany,
                    },
                  ];
                  props.setDisableAsset(false);
                  setTriggerAssetList(tempInitialData);
                }
                
              }}
              getOptionLabel={(option) => option.companyName}
                          renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                    {option.companyName}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Company"
                  placeholder="Company Name"
                  required={true}

                />
              )}
            />
          ) : (
            <TextField
            required={true}
              variant="standard"
              readOnly={true}
              size="small"
              fullWidth
              label="Company Name"
              placeholder="Company Name"
              value={comp}
            />
          )}
        </Grid>
        <Grid item xs={12}>
                  {props?.isAssetSelected ? (
            <TextField
              variant="standard"
              readOnly={true}
              size="small"
              fullWidth
              label="Asset"
              placeholder="Asset"
              value={currentAsset}
            />
          ) : (
            <Autocomplete
              size="small"
              id="size-small-standard size-small-standard"
              clearIcon={<ClearIcon fontSize="small" onClick={clearFunction} />}
              options={currentCompany}
              inputValue={tempAsset}
              getOptionLabel={(option) => option.asset}
              onChange={(e, value) => {
                
                if (value) {
                  setFinalAssetDetailObject({
                    ...finalAssetDetailObject,
                    assetId: value.assetId,
                    isCRUAsset: value.isCRUAsset,
                    companyId: value.companyId,
                  });
                  let tempInitialData = [
                    {
                      companyId: finalAssetDetailObject.companyId,
                      isCRUCompany: finalAssetDetailObject.isCRUCompany,
                      assetId: value.assetId,
                      isCRUAsset: value.isCRUAsset,
                    },
                  ];
                  setTriggerPriority(tempInitialData);
                  setTempAsset(value.asset);
                  setCurrentAsset(value.asset);
                  setCurrentAssetID(value.assetId);
                  setCurrentAssetType(value.assetType);
                  setCountryName(value.country);
                  setRegionName(value.region);
                  setSubregion(value.subRegion);
                  setCurrentCommodity([value.commodity]);
                }
              }}
                              renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                    {option.asset}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={tempAsset}
                  variant="standard"
                  label="Asset"
                  placeholder="Asset"
                  required={true}

                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            required={true}
            variant="standard"
            readOnly={true}
            size="small"
            fullWidth
            label="Asset Type"
            placeholder="Asset Type"
            value={currentassetType}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            readOnly={true}
            size="small"
            fullWidth
            label="Country"
            placeholder="Country"
            value={countryName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            readOnly={true}
            size="small"
            fullWidth
            label="Region"
            placeholder="Region"
            value={regionName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            readOnly={true}
            size="small"
            fullWidth
            label="Sub Region"
            placeholder="Sub Region"
            value={currentSubregion}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            readOnly
            multiple
            freeSolo
            fullWidth
            size="small"
            id="size-small-standard size-small-standard"
            variant="standard"
            options={currentCommodity}
            getOptionLabel={(option) => option}
            value={currentCommodity}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="standard"
                  id="size-small-standard size-small-standard"
                  label={option}
                  size="Commoditites"
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                id="size-small-standard size-small-standard"
                label="Commodities"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            labelTitle="Job Start Date"
            variant="standard"
            past={true}
             disabled={enableStartDate}
            // defaultDate={currentPriority.startDate ? currentPriority.startDate : finalAssetDetailObject.startDate}
            defaultDate={currentPriority.startDate ? currentPriority.startDate : null}
            onChangeDate={(e) => {
              
               let selectedDate = new Date(e);
               GetNoOfDays(selectedDate);
              let tempStartDate=CommonService.GetDateAsStringWithDash(selectedDate)
              setFinalAssetDetailObject({
                  ...finalAssetDetailObject,
                  startDate: tempStartDate
                });

            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            labelTitle="TAT"
            variant="standard"
            past={true}
            required={true}
            defaultDate={currentPriority.priorityDate ? currentPriority.priorityDate : null}
            onChangeDate={(e) => {

              let selectedDate = new Date(e);
              // console.log("selectedDate", selectedDate)
              //     console.log("currentPriority.priorityDate", currentPriority.priorityDate);
              setFinalAssetDetailObject({
                ...finalAssetDetailObject,
                tat: selectedDate,
              });

              setRemainingDays(CommonService.getBusinessDatesCount(selectedDate))
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="standard"
            readOnly={true}
            size="small"
            fullWidth
            disabled={true}
            label="Remaining Days"
            placeholder="Remaining Days"
            value={remainingDays}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            size="small"
            id="size-small-standard size-small-standard"
            options={priority}
            value={tempPriority}
            getOptionLabel={(option) => option.priorityName}
            onChange={(e, value) => {
              setFinalAssetDetailObject({
                ...finalAssetDetailObject,
                priorityId: value.id,
              });
              setTempPriority(value)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Priority"
                placeholder="Priority"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            size="small"
            fullWidth
            multiline
            maxRows={4}
            label="Job Instructions"
            placeholder="Job Instructions"
            onChange={(e) =>
              setFinalData({ ...finalData, instructions: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              {/* <Button variant="contained" className="rounded-border"
                color="accent5" onClick={submitForm}>
                Save
              </Button> */}
              <LoadingButton
                buttonName="Save"
                clickHandler={submitForm}
                isLoading={loading} />
            </Grid>
            <Grid item xs={'auto'}>
              <Button variant="contained" className="rounded-border" color="accent3"
                onClick={() => {
                  props.setPageRefresh(true);
                  setCurrentCompany([]);
                  props.setState({ ...props.state, right: false });
                  setCurrentAsset("");
                  setCurrentAssetType("");
                  setCountryName("");
                  setRegionName("");
                  setSubregion("");
                  setCurrentCommodity([]);
                }}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Dialog for Confirmation  */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Job already exists for the selected asset(s) or company(s). click <Link underline="hover" onClick={showDuplicate} color={darkBlue}>
              here</Link> to view the duplicate jobs.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justifyContent="right">
            <Grid item xs={'auto'}>
              <Button value={false} onClick={() => {
                setOpen(false);
                props.setState({ ...props.state, right: false });
                props.setPageRefresh(true);
              }}>No
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button value={true} onClick={handleClose} autoFocus>
                Yes
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>


      <ScrollingDialog
        open={openDuplicateDialog}
        setOpen={setOpenDuplicateDialog}
        scroll={scroll}
        setScroll={setScroll}
        dialogClass="darkTitle"
        dialogTitle="Duplicate Jobs"
        dialogContent={
          <Box className={classes.addBorder}>
            <DuplicateJobData
              duplicateJobs={duplicateJobs}
              companyAssets={companyAssets}
              totalDuplicateJobs={totalDuplicateJobs}
              createManual={createManual}
              selectedCompnies={companyAssets}
              startDate={undefined}
              endDate={undefined}
            />
          </Box>
        }
      />
    </Box>
  );
};

export default CreateJob;