// Default React, Router and Services Components
import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Button, Grid,  Typography } from "@mui/material";
import XLSX from "sheetjs-style";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
import { Link } from 'react-router-dom';
// import history from "../../../Routes/History";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
// Importing Custom Components
import DataTable from "../../../../UIComponent/DataTable";
import ApiService from "../../../../Services/ApiService";
import BaseLayout from "../../../../UIComponent/BaseLayout";

// Importing useStyles
import useStyles from "./JiraTicketStatusReport.styles";

// Import Fluent UI Icons
import { ChevronRightSmallIcon, ChevronLeftIcon, ExportIcon } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../../Services/CommonService";
import { useHistory } from "react-router-dom";

const DataCollectionJiraTicketStatusReportDrill = (props) => {
  const history = useHistory()
  // Declaration of States and Component Variables Start Here
  const [historyData, setHistoryData] = useState();
  const [allJiraTicketStatus, setAllJiraTicketStatus] = useState([]);
  const [allJobsForExcelExport, setAllJobsForExcelExport] = useState([]);
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [selected, SetSelected] = useState([]);
  const [isAllSelected, setIsSelected] = useState(false);
  const [searchData, setSerachData] = useState();
  const [state, setState] = useState({ right: false });
  const [reload, setReload] = useState(false);
  const [ticktType, setTicketType] = useState();
  const [ticketStatus, setTicketStatus] = useState();
  const [tableColumns, setTableColumns] = useState([]);
  const [excelColumns, setExcelColumns] = useState([]);
  const fileName = `Jira Tickets Drill Down ${new Date().toLocaleDateString().split("/").join("-")}`
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => { getDataFromHistory(); }, []);
  React.useEffect(() => {
    if (ticktType !== undefined && ticktType !== null && ticketStatus !== undefined && ticketStatus !== null) { searchJiraTicketStatusDetails(searchValue); }
  }, [ticktType, ticketStatus]);
  React.useEffect(() => {
    if (ticktType !== undefined && ticktType !== null && ticketStatus !== undefined && ticketStatus !== null) { searchJiraTicketStatusDetails(searchValue); }
  }, [page, rowsPerPage, reload])

  useEffect(() => {
    if (ticktType !== undefined && ticktType !== null && ticketStatus !== undefined && ticketStatus !== null) {
      if (ticktType.toLowerCase() == "New Company".toLowerCase()) {
        setTableColumns(newCompanyColumns)
        setExcelColumns([
          "Company Name", "No. of Assets", "Priority Name", "Jira Ticket Id", "Status Name",
          "Jira Ticket Created Date", "Action Taken Date"
        ])
      } else if (ticktType.toLowerCase() == "Company Name Changes".toLowerCase()) {
        setTableColumns(companyNameChangesColumns)
        setExcelColumns([
          "Old Company Name", "New Company Name", "No. of Assets", "Priority Name",
          "Jira Ticket Id", "Status Name", "Jira Ticket Created Date", "Action Taken Date"
        ])

      } else if (ticktType.toLowerCase() == "New Asset".toLowerCase()) {
        setTableColumns(newAssetColumns)
        setExcelColumns(["Company Name", "Asset Name", "Asset Type", "Jira Ticket ID", "Region Name", "SubRegion Name", "Country Name",
          "Status Name", "Jira Ticket Created Date", "Action Taken Date"])

      } else if (ticktType.toLowerCase() == "Asset Name Changes".toLowerCase()) {
        setTableColumns(assetChangeNameColumns)
        setExcelColumns(["Company Name", "Old Asset Name", "New Asset Name", "Asset Type", "Jira Ticket ID", "Region Name", "SubRegion Name", "Country Name",
          "Status Name", "Jira Ticket Created Date", "Action Taken Date"])

      } else if (ticktType.toLowerCase() == "Missing CRU Variable".toLowerCase()) {

        setTableColumns(missingCruVarColumns)
        setExcelColumns([
          "Variable Name", "Jira Ticket Id", "Status Name", "Jira Ticket Created Date", "Action Taken Date"
        ])
      } else {
        setTableColumns(missingCruUnitsColumns)
        setExcelColumns([
          "Unit Name", "Jira Ticket Id", "Status Name", "Jira Ticket Created Date", "Action Taken Date"
        ])
      }

      searchJiraTicketStatusDetails(searchValue);
    }
  }, [ticktType, ticketStatus])
  // Declaration of React Hooks End Here

  // Assigning Columns and UI Props
  const newCompanyColumns = [
    { id: "companyName", label: "Company Name", minWidth: 250, width: "20%", sorting: false, },
    { id: "noOfAssets", label: "No. of Assets", minWidth: 130, width: "13%", sorting: false, align: "center" },
    { id: "priorityName", label: "Priority Name", minWidth: 150, width: "10%", sorting: false, align: "center" },
    { id: "jiraTicketId", label: "Jira Ticket ID", minWidth: 120, width: "10%", sorting: false, align: "center" },
    { id: "statusName", label: "Status Name", minWidth: 120, width: "10%", sorting: false, align: "center" },
    { id: "jiraTicketCreatedDate", label: "Jira Ticket Created Date", minWidth: 130, width: "15%", sorting: false, align: "center" },
    { id: "actionTakenDate", label: "Action Taken Date", minWidth: 110, width: "15%", sorting: false, align: "center" }
  ]
  const newAssetColumns = [{ id: "companyName", label: "Company Name", minWidth: 250, width: "15%", sorting: false, },
  { id: "assetName", label: "Asset Name", minWidth: 110, width: "15%", sorting: false, align: "center" },
  { id: "assetType", label: "Asset Type", minWidth: 110, width: "15%", sorting: false, align: "center" },
  { id: "jiraTicketId", label: "Jira Ticket ID", minWidth: 120, width: "10%", sorting: false, align: "center" },
  { id: "regionName", label: "Region Name", minWidth: 150, width: "10%", sorting: false, align: "center" },
  { id: "subRegionName", label: "SubRegion Name", minWidth: 120, width: "12%", sorting: false, align: "center" },
  { id: "countryName", label: "Country Name", minWidth: 250, width: "15%", sorting: false, align: "center" },
  { id: "statusName", label: "Status Name", minWidth: 120, width: "12%", sorting: false, align: "center" },
  { id: "jiraTicketCreatedDate", label: "Jira Ticket Created Date", minWidth: 130, width: "15%", sorting: false, align: "center" },
  { id: "actionTakenDate", label: "Action Taken Date", minWidth: 110, width: "15%", sorting: false, align: "center" },]
  const companyNameChangesColumns = [
    { id: "oldCompanyName", label: "Old Company Name", minWidth: 250, width: "15%", sorting: false, },
    { id: "newCompanyName", label: "New Company Name", minWidth: 250, width: "15%", sorting: false, },
    { id: "noOfAssets", label: "No. of Assets", minWidth: 130, width: "10%", sorting: false, align: "center" },
    { id: "priorityName", label: "Priority Name", minWidth: 150, width: "10%", sorting: false, align: "center" },
    { id: "jiraTicketId", label: "Jira Ticket ID", minWidth: 120, width: "10%", sorting: false, align: "center" },
    { id: "statusName", label: "Status Name", minWidth: 120, width: "12%", sorting: false, align: "center" },
    { id: "jiraTicketCreatedDate", label: "Jira Ticket Created Date", minWidth: 130, width: "15%", sorting: false, align: "center" },
    { id: "actionTakenDate", label: "Action Taken Date", minWidth: 110, width: "15%", sorting: false, align: "center" },
  ]
  const assetChangeNameColumns = [
    { id: "companyName", label: "Company Name", minWidth: 250, width: "15%", sorting: false, },
    { id: "oldAssetName", label: "Old Asset Name", minWidth: 250, width: "15%", sorting: false, },
    { id: "newAssetName", label: "New Asset Name", minWidth: 130, width: "10%", sorting: false, },
    { id: "assetType", label: "Asset Type", minWidth: 110, width: "15%", sorting: false, },
    { id: "jiraTicketId", label: "Jira Ticket ID", minWidth: 120, width: "10%", sorting: false, align: "center" },
    { id: "regionName", label: "Region Name", minWidth: 150, width: "10%", sorting: false, },
    { id: "subRegionName", label: "SubRegion Name", minWidth: 120, width: "12%", sorting: false, },
    { id: "countryName", label: "Country Name", minWidth: 250, width: "15%", sorting: false, },
    { id: "statusName", label: "Status Name", minWidth: 120, width: "12%", sorting: false, align: "center" },
    { id: "jiraTicketCreatedDate", label: "Jira Ticket Created Date", minWidth: 130, width: "15%", sorting: false, align: "center" },
    { id: "actionTakenDate", label: "Action Taken Date", minWidth: 110, width: "15%", sorting: false, align: "center" },
  ]
  const missingCruVarColumns = [
    { id: "variableName", label: "Variable Name", minWidth: 250, width: "20%", sorting: false, },
    { id: "jiraTicketId", label: "Jira Ticket ID", sorting: false, minWidth: 150, width: "20%", align: "center" },
    { id: "statusName", label: "Status Name", sorting: false, minWidth: 150, width: "20%", align: "center" },
    { id: "jiraTicketCreatedDate", label: "Jira Ticket Created Date", sorting: false, minWidth: 130, width: "20%", align: "center" },
    { id: "actionTakenDate", label: "Action Taken Date", sorting: false, minWidth: 110, width: "20%", align: "center" },
  ]
  const missingCruUnitsColumns = [
    { id: "unitName", label: "Unit Name", minWidth: 250, width: "20%", sorting: false, },
    { id: "jiraTicketId", label: "Jira Ticket ID", sorting: false, minWidth: 150, width: "20%", align: "center" },
    { id: "statusName", label: "Status Name", sorting: false, minWidth: 150, width: "20%", align: "center" },
    { id: "jiraTicketCreatedDate", label: "Jira Ticket Created Date", sorting: false, minWidth: 130, width: "20%", align: "center" },
    { id: "actionTakenDate", label: "Action Taken Date", sorting: false, minWidth: 110, width: "20%", align: "center" }
  ]

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if (
      (event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
    }
    if (
      (event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      setTotalCount(0);
      setPage(0);
      searchJiraTicketStatusDetails(event.target.value);
    }
  };

// Function to get data from history
const getDataFromHistory = () => {
    
  // Get stored data from CommonService
  let temp = CommonService.GetStoredData(props, "historyData");
  if (temp) {
    setHistoryData(temp);
    // Check if searchData is present in the stored data
    if (temp.state.searchData !== undefined) {
      setSerachData(temp.state.searchData);
    }
    // Check if TicketType is present in the stored data
    if (temp.state.TicketType !== undefined) {
      setTicketType(temp.state.TicketType);
    }
    // Check if TicketStatus is present in the stored data
    if (temp.state.TicketStatus !== undefined) {
      setTicketStatus(temp.state.TicketStatus);
    }
  }

}

  // Function for Global Search
  const globalSearch = (value) => {
    setSearchValue(value);
  };

 // This function is used to search Jira ticket status details
  const searchJiraTicketStatusDetails = (filterValue, exportExcel = false) => {
     // Check if ticktType and ticketStatus are not empty
    if (ticktType != "" && ticketStatus != "") {
     // Check if searchData is not null  
      if (searchData != null) {
        filterValue = `{FromDate:${searchData.FromDate},ToDate:${searchData.ToDate}}}`
      }
     // Set loading to true  
      setLoading(true);
    // Initialize pageNumber and pageSize  
      let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
     // Initialize url  
      let url = "reports/GetJiraTicketNewCompany?";
    // Check for different types of tickets  
      if (ticktType.toLowerCase() == "New Company".toLowerCase()) {
        url = "reports/GetJiraTicketNewCompany?";
      } else if (ticktType.toLowerCase() == "Company Name Changes".toLowerCase()) {
        url = "reports/GetJiraTicketChangeCompany?";

      } else if (ticktType.toLowerCase() == "New Asset".toLowerCase()) {
        url = "reports/GetJiraTicketNewAsset?";

      } else if (ticktType.toLowerCase() == "Asset Name Changes".toLowerCase()) {
        url = "reports/GetJiraTicketChangesAsset?";

      } else if (ticktType.toLowerCase() == "Missing CRU Variable".toLowerCase()) {
        url = "reports/GetJiraTicketMissingVariable?";
      } else {
        url = "reports/GetJiraTicketMissingUnit?";
      }
     // Check if exportExcel is true 
      if (exportExcel) {
      // Set totalCount to 10  
        setTotalCount(10)
      // Update pageNumber and pageSize  
        prt = [`pageNumber=1`, `pageSize=${totalCount}`];
      }
     // Initialize searchvalue 
      let searchvalue = '{"ticktType":"' + ticktType + '","Status":"' + ticketStatus + '"}';
      // Check if FromDate and ToDate are not null
      if (searchData.FromDate != null && searchData.ToDate != null) {
        searchvalue = '{"ticktType":"' + ticktType + '","Status":"' + ticketStatus + '","FromDate":"' + searchData.FromDate + '","ToDate":"' + searchData.ToDate + '"}';
      }
      // Call ApiService to search
      ApiService.search(url, { JiraFilter: searchvalue, isAscending: sorting.isAscending, }, prt)
        .then((res) => {
      // Check if response status is 200    
          if (res.status === 200) {
        // Check if exportExcel is true
            if (exportExcel) {
            // Initialize apiData array   
              let apiData = [];
            // Check for different types of tickets  
              if (ticktType.toLowerCase() == "New Company".toLowerCase()) {
                res.data.data.forEach(element => {
                  apiData.push({
                    "Company Name": element.companyName,
                    "No of Assets": element.noOfAssets,
                    "Priority Name": element.priorityName,
                    "Jira Ticket Id": element.jiraTicketId,
                    "Status Name": element.statusName,
                    "Jira Ticket Created Date": element.jiraTicketCreatedDate,
                    "Action Taken Date": element.actionTakenDate,
                  });
                });
              } else if (ticktType.toLowerCase() == "Company Name Changes".toLowerCase()) {
                res.data.data.forEach(element => {
                  apiData.push({
                    "Old Company Name": element.oldCompanyName,
                    "New Company Name": element.newCompanyName,
                    "No of Assets": element.noOfAssets,
                    "Priority Name": element.priorityName,
                    "Jira Ticket Id": element.jiraTicketId,
                    "Status Name": element.statusName,
                    "Jira Ticket Created Date": element.jiraTicketCreatedDate,
                    "Action Taken Date": element.actionTakenDate,
                  });
                });
              } else if (ticktType.toLowerCase() == "New Asset".toLowerCase()) {
                res.data.data.forEach(element => {
                  apiData.push({
                    "Company Name": element.companyName,
                    "Asset Name": element.assetName,
                    "Asset Type": element.assetType,
                    "Jira Ticket ID": element.jiraTicketId,
                    "Region Name": element.regionName,
                    "SubRegion Name": element.subRegionName,
                    "Country Name": element.countryName,
                    "Status Name": element.statusName,
                    "Jira Ticket Created Date": element.jiraTicketCreatedDate,
                    "Action Taken Date": element.actionTakenDate
                  });
                });
              } else if (ticktType.toLowerCase() == "Asset Name Changes".toLowerCase()) {
                res.data.data.forEach(element => {
                  apiData.push({
                    "Company Name": element.companyName,
                    "Old Asset Name": element.oldAssetName,
                    "New Asset Name": element.newAssetName,
                    "Asset Type": element.assetType,
                    "Jira Ticket ID": element.jiraTicketId,
                    "Region Name": element.regionName,
                    "SubRegion Name": element.subRegionName,
                    "Country Name": element.countryName,
                    "Status Name": element.statusName,
                    "Jira Ticket Created Date": element.jiraTicketCreatedDate,
                    "Action Taken Date": element.actionTakenDate
                  });
                });
              } else if (ticktType.toLowerCase() == "Missing CRU Variable".toLowerCase()) {
                res.data.data.forEach(element => {
                  apiData.push({
                    "Variable Name": element.variableName,
                    "Jira Ticket Id": element.jiraTicketId,
                    "Status Name": element.statusName,
                    "Jira Ticket Created Date": element.jiraTicketCreatedDate,
                    "Action Taken Date": element.actionTakenDate,
                  });
                });
              } else {
                res.data.data.forEach(element => {
                  apiData.push({
                    "Unit Name": element.unitName,
                    "Jira Ticket Id": element.jiraTicketId,
                    "Status Name": element.statusName,
                    "Jira Ticket Created Date": element.jiraTicketCreatedDate,
                    "Action Taken Date": element.actionTakenDate,
                  });
                });
              }
              // Call exportToCSV function
              exportToCSV(apiData, fileName, excelColumns);
              // Set allJiraTicketStatus
              setAllJiraTicketStatus(res.data.data);
               // Set loading to false
              setLoading(false);
            } else {
             // Set allJiraTicketStatus to empty array 
              setAllJiraTicketStatus([]);
              // Set allJiraTicketStatus
              setAllJiraTicketStatus(res.data.data);
              // Set totalCount
              setTotalCount(res.data.totalCount);
            }
            // Set loading to false
            setLoading(false);
          } else {
            // Call toasterError function
            toasterError(`${res.data.data}`);
            // Set loading to false
            setLoading(false);
          }
        })
        .catch((err) => {
          // Set loading to false
          setLoading(false);
          // Check if response status is 400
          if(err.res.status===400){
            toasterError(err.res.data);
          }
           });
    }
  };

  // Function for Search Clear
  const searchClear = () => {
    setSearchValue("");
    setPage(0);
    setReload(!reload);
  };

// This function handles the export of data to an Excel file
const handleExportToExcel = () => { 
  // Call the searchJiraTicketStatusDetails function with the searchValue and true as parameters
  searchJiraTicketStatusDetails(searchValue, true); 
}
// This function is used to export data from an API to a CSV file
const exportToCSV = (apiData, fileName, excelHeaderColumns) => {
  // Set the file type and extension
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  
  // Convert the API data to a worksheet
  const ws = XLSX.utils.json_to_sheet(apiData);
  
  // Add the header columns to the worksheet
  XLSX.utils.sheet_add_aoa(ws, [excelHeaderColumns], { origin: "A1", });
  
  // Create a workbook with the worksheet
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  
  // Write the workbook to an array buffer
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  
  // Create a blob from the array buffer
  const data = new Blob([excelBuffer], { type: fileType });
  
  // Save the blob as a file
  FileSaver.saveAs(data, fileName + fileExtension);
};

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Jira Tickets Drill Down</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/aging-report" }}>Dashboard and Reports</Link>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/jira-tickets" }}>Jira Tickets</Link>
            <Typography color="text.primary">Jira Tickets Drill Down</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              <Button variant="contained" color="accent2" startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
                onClick={() => { history.push("/app/data-collections/jira-tickets"); }}>
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search and Filter Options Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="right" spacing={2}>
                  <Grid item textAlign="right">
                    <Button variant="contained" color="secondary" startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                      onClick={handleExportToExcel}
                    >
                      Export To Excel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search and Filter Options End Here */}

              {/* Jira Ticket Status Table */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allJiraTicketStatus ? allJiraTicketStatus : []}
                  columns={tableColumns}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={setSorting}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataCollectionJiraTicketStatusReportDrill;