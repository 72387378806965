// Default React, Router and Services Components
import React, { useState } from "react";
import { Box, Breadcrumbs, Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import XLSX from "sheetjs-style";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
 import { Link } from 'react-router-dom';
// import history from '../../../../Routes/History';
import { useHistory } from "react-router-dom";

import ApiService from "../../../../Services/ApiService";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
// Importing Custom Components
import DataTable from "../../../../UIComponent/DataTable";
import BaseLayout from "../../../../UIComponent/BaseLayout";

// Importing useStyles
import useStyles from "./MeritQCStatusReport.styles";

// Import Fluent UI Icons
import { ChevronLeftIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon, ExportIcon } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../../Services/CommonService";

const DataCollectionMeritQCDrill = (props) => {
  const history = useHistory();
  // Declaration of States and Component Variables Start Here

  const [historyData, setHistoryData] = useState();
  const classes = useStyles();
  const [allRows, setAllRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [selected, SetSelected] = useState([]);
  const [idforEdit, setIdForEdit] = useState(null);
  const [editJobNumber, setEditJobNumber] = useState(null);
  const [isAllSelected, setIsSelected] = useState(false);
  const [state, setState] = React.useState({ right: false });
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  const [crudState, setCrudState] = React.useState(false);
  const [formData, setFormData] = useState([]);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [reload, setReload] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const date = new Date().toLocaleDateString();
  const date1 = date.split("/").join("-");
  const [tableData, setTableData] = useState([]);
  const [priorityId, setPriorityId] = useState(0);
  const [reportType, setReportType] = useState("");
  const [filterData, setFilterData] = useState("");
  const [exportToExcel, setExportToExcel] = useState(false);
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => { getDataFromHistory(); }, [history, priorityId, reportType]);
  React.useEffect(() => {
    if (priorityId > 0 && reportType != "") { GetMeritQCDrillReport(searchValue, priorityId, reportType); }
  }, [priorityId, reportType, reload]);
  React.useEffect(() => {

    GetMeritQCDrillReport(searchValue, priorityId, reportType);
  }, [sorting, page, rowsPerPage, priorityId, reportType]);
  // Declaration of React Hooks End Here

  // Assigning Columns and UI Props
  const gridcolumns = [
    { id: "jobAssetId", label: "Id", type: "Highlight" },
    { id: "jobNo", label: "Job ID", minWidth: 90, width: 100, sorting: true },
    { id: "jobName", label: "Job Name", minWidth: 300, width: 300, sorting: true },
    { id: "company", label: "Company", minWidth: 250, width: 250, sorting: true },
    { id: "asset", label: "Asset", minWidth: 250, width: 250, sorting: true },
    { id: "noOfVariable", label: "Variable Count", minWidth: 110, width: 110, sorting: true, align: "center" },
    { id: "commodities", label: "Commodity", minWidth: 350, width: 350, sorting: true, truncate: 80, tooltipWithTruncate: true },
    { id: "createdOn", label: "Created Date", minWidth: 150, width: 150, sorting: true, align: "center" },
    { id: "startDate", label: "Job Start Date", minWidth: 110, width: 110, sorting: true, align: "center" },
    { id: "tat", label: "TAT", minWidth: 150, width: 150, sorting: true, align: "center" },
    { id: "remainingDays", label: "Remaining Days", minWidth: 110, width: 110, sorting: true, align: "center" },
    { id: "completedOn", label: "Completed Time", minWidth: 130, width: 150, sorting: true, align: "center" },
    { id: "assignedTo", label: "Assigned To", minWidth: 250, width: 250, sorting: true },
    { id: "meritQC", label: "Merit QC", minWidth: 250, width: 250, sorting: true },
    { id: "priority", label: "Job Priority", minWidth: 110, width: 110, sorting: true, type: "Chip", align: "center" },
    { id: "country", label: "Country", minWidth: 180, width: 180, sorting: true },
    { id: "region", label: "Region", minWidth: 180, width: 180, sorting: true },
    { id: "subRegion", label: "Subregion", minWidth: 180, width: 180, sorting: true },
  ];

  // Function for Sorting Column
  const changeSortColumnNameFormat = (fieldName) => {
    if (fieldName == null || fieldName == "") { return ""; }
    if (fieldName.toLocaleLowerCase() == "jobNo".toLocaleLowerCase()) { fieldName = "JobNo"; }
    if (fieldName.toLocaleLowerCase() == "jobName".toLocaleLowerCase()) { fieldName = "JobName"; }
    if (fieldName.toLocaleLowerCase() == "company".toLocaleLowerCase()) { fieldName = "Company"; }
    if (fieldName.toLocaleLowerCase() == "asset".toLocaleLowerCase()) { fieldName = "Asset"; }
    if (fieldName.toLocaleLowerCase() == "noOfVariable".toLocaleLowerCase()) { fieldName = "NoOfVariable"; }
    if (fieldName.toLocaleLowerCase() == "commodities".toLocaleLowerCase()) { fieldName = "Commodities"; }
    if (fieldName.toLocaleLowerCase() == "country".toLocaleLowerCase()) { fieldName = "Country"; }
    if (fieldName.toLocaleLowerCase() == "region".toLocaleLowerCase()) { fieldName = "Region"; }
    if (fieldName.toLocaleLowerCase() == "subRegion".toLocaleLowerCase()) { fieldName = "SubRegion"; }
    if (fieldName.toLocaleLowerCase() == "createdOn".toLocaleLowerCase()) { fieldName = "CreatedOn"; }
    if (fieldName.toLocaleLowerCase() == "TAT".toLocaleLowerCase()) { fieldName = "TAT"; }
    if (fieldName.toLocaleLowerCase() == "startDate".toLocaleLowerCase()) { fieldName = "StartDate"; }
    if (fieldName.toLocaleLowerCase() == "remainingDays".toLocaleLowerCase()) { fieldName = "RemainingDays"; }
    if (fieldName.toLocaleLowerCase() == "commodities".toLocaleLowerCase()) { fieldName = "Commodities"; }
    if (fieldName.toLocaleLowerCase() == "completedOn".toLocaleLowerCase()) { fieldName = "CompletedOn"; }
    if (fieldName.toLocaleLowerCase() == "assignedTo".toLocaleLowerCase()) { fieldName = "AssignedTo"; }
    if (fieldName.toLocaleLowerCase() == "meritQC".toLocaleLowerCase()) { fieldName = "MeritQC"; }
    if (fieldName.toLocaleLowerCase() == "priority".toLocaleLowerCase()) { fieldName = "Priority"; }
    return fieldName;
  }

  // Function for Search Enter Press
  const handleKeyUp = (event) => {
    if (
      (event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      searchReport(event.target.value);
    }
  };

  // For pagination after search
  const searchReport = (filterValue) => {
    setTotalCount(0);
    setPage(0);
    setLoading(true);
    setReload(!reload);
  };

  // Function for Global Search
  const globalSearch = (value) => {
    setSearchValue(value);
  };
  const searchClear = () => {
    setSearchValue("");
    setPage(0);
  };


// This function retrieves data from the history object
const getDataFromHistory = () => {
  // Get stored data from CommonService
  let temp = CommonService.GetStoredData(history, "historyData");
  // If there is stored data, set the historyData, priorityId and reportType
  if (temp) {
    setHistoryData(temp);
    setPriorityId(temp.state.priorityId);
    setReportType(temp.state.type);
  }
}



  // This function is used to get Merit QC Drill Report
const GetMeritQCDrillReport = (filterValue, priorityId, reportType, filterData, exportExcel = false) => {

  // Set loading to true
  setLoading(true);

  let searchvalue;
  // Check if priorityId and reportType are defined and not empty
  if (priorityId != undefined && priorityId > 0 && reportType != undefined && reportType != "") {
    // Check if priorityId is equal to 5
    if (priorityId === 5) {
      // Check if filterData is defined and not empty
      if (filterData != undefined && filterData != "") {
        // Set searchvalue with filterData
        searchvalue = '{"Company":[' + filterData.company + '],"Asset":[' + filterData.jobAssetId + '],"Commodities":[' + filterData.commodities + '],"Country":[' + filterData.country + '],"Region":[' + filterData.region + '],"subRegion":[' + filterData.subRegion + '],"FromDate":"","ToDate":"","SearchText":"' + filterValue + '"}';
      }
      else {
        // Set searchvalue without filterData
        searchvalue = '{"Company":[],"Asset":[],"FromDate":"","ToDate":"" ,"SearchText":"' + filterValue + '"}';
      }

    }
    else {
      // Check if filterData is defined and not empty
      if (filterData != undefined && filterData != "") {
        // Set searchvalue with filterData
        searchvalue = '{"Company":[' + filterData.company + '],"Asset":[' + filterData.jobAssetId + '],"Commodities":[' + filterData.commodities + '],"Country":[' + filterData.country + '],"Region":[' + filterData.region + '],"subRegion":[' + filterData.subRegion + '],"FromDate":"","ToDate":"","PriorityId":"' + priorityId + '" ,"SearchText":"' + filterValue + '"}';
      }
      else {
        // Set searchvalue without filterData
        searchvalue = '{"Company":[],"Asset":[],"FromDate":"","ToDate":"","PriorityId":"' + priorityId + '" ,"SearchText":"' + filterValue + '"}';
      }
    }

    // Set pageNumber and pageSize
    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
    // Check if exportExcel is true
    if (exportExcel) { 
      // Set pageNumber and pageSize for exportExcel
      prt = [`pageNumber=1`, `pageSize=${totalCount}`]; 
    }
    let url = "";
    // Check reportType and set url accordingly
    if (reportType == "qcPassedWithNoErrors") {
      url = `reports/GetMeritQCPassedWithNoErrorsDetails?`;
    }
    else if (reportType == "qcPassedWithMeritQCErrors") {
      url = `reports/GetMeritQCPassedWithErrorsDetails?`;
    }
    else if (reportType == "meritQCFailed") {
      url = `reports/GetMeritQCFailedDetails?`;
    }
    else if (reportType === "total") {
      url = `reports/GetMeritQCPriorityDetails?`;
    }

    // Call ApiService to search
    ApiService.search(
      url,
      {
        filter: encodeURIComponent(JSON.stringify(
          { ...historyData.state.filterData, 
            "searchText": searchValue, 
            PriorityId: priorityId === 5 ? null : priorityId, })),
        sortColumn: changeSortColumnNameFormat(sorting.sortBy),
        isAscending: sorting.isAscending,
      }, prt
    )
      .then((res) => {
        // Check if response status is 200
        if (res.status === 200) {
          // Check if exportExcel is true
          if (exportExcel) {
            // Create apiData array
            let apiData = [];
            // Push data into apiData array
            res.data.data.forEach(element => {
              apiData.push({
                "Job Id": element.jobNo, "Job Name": element.jobName, "Company": element.company,
                "Asset": element.asset, "Variable Count": element.noOfVariable, "Commodity": element.commodities,
                "Created Date": element.createdOn,
                "Job Start Date": element.startDate,
                "TAT": element.tat,
                "Remaining Days": element.remainingDays,
                "Completed Time": element.completedOn, "Assigned To": element.assignedTo,
                "Merit QC": element.meritQC, "Job Priority": element.priority, "Country": element.country,
                "Region": element.region,
                "Sub Region": element.subRegion,
              });
            });
            // Set fileName
            let fileName = `MeritQCStatusDrillDownReport ${date1}`;
            // Call exportToCSV function
            exportToCSV(apiData, fileName);
            // Set loading to false
            setLoading(false);
          }
          else {
            // Set allRows and totalCount
            setAllRows(res.data.data);
            setTotalCount(res.data.totalCount);
            // Set loading to false
            setLoading(false);
          }
        } else {
          // Call toasterError function
          toasterError(`${res.data}`);
          // Set loading to false
          setLoading(false);
        }
      })
      .catch((err) => {
        // Check if response status is 400
        if(err.res.status===400){
          // Call toasterError function
          toasterError(err.res.data);
        }

         });
    
  }

};

  // This function handles the export of a report to an Excel file
  const handleExportToExcel = () => {
    // Call GetMeritQCDrillReport with the given parameters and set the last parameter to true to indicate that the report should be exported to Excel
    GetMeritQCDrillReport(searchValue, priorityId, reportType, filterData, true);
  }

// This function is used to export data from an API to a CSV file
const exportToCSV = (apiData, fileName) => {
    // Set the file type and extension
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    
    // Convert the API data to a worksheet
    const ws = XLSX.utils.json_to_sheet(apiData);
    
    // Add the column headers to the worksheet
    XLSX.utils.sheet_add_aoa(
      ws,
      [["Job ID", "Job Name", "Company", "Asset", "Variable count", "Commodity",
        "Created Date", "Job Start Date", "Turn Around Time", "Remaining Days", "Completed Time", "Assigned To", "Merit QC", "Job Priority", "Country", "Region", "Sub Region",],],
      { origin: "A1", }
    );
    
    // Create the workbook object
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    
    // Write the workbook to a buffer
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    
    // Create a blob from the buffer
    const data = new Blob([excelBuffer], { type: fileType });
    
    // Save the blob as a file
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Merit QC Report Drill Down</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/aging-report" }}>Dashboard and Reports</Link>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/merit-qc-report" }}>Merit QC Report</Link>
            <Typography color="text.primary">Merit QC Report Drill Down</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              <Button variant="contained" color="accent2" startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
                onClick={() => { history.push("/app/data-collections/merit-qc-report"); }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      size="small"
                      fullWidth
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {searchValue ? (
                      <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                      <Grid item xs={"auto"}>
                        <Typography variant="subtitle2">
                          Total Units: <span className={classes.totalRecord}> {totalCount}</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={"auto"}>
                        <Button variant="contained" color="secondary" startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                          onClick={handleExportToExcel}>
                          Export To Excel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Merit QC Report Drill Down Table */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allRows ? allRows : []}
                  columns={gridcolumns}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={setSorting}
                  hasChip={{
                    High: { color: "warning", label: "High" },
                    Critical: { color: "error", label: "Critical" },
                    Low: { color: "success", label: "Low" },
                    Medium: { color: "info", label: "Medium" },
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
    </Box >
  );
};

export default DataCollectionMeritQCDrill;