  // Default React, Router and Services Components
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

// Importing Custom Components
import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Typography } from "@mui/material";
import MultiSelect from "../../../../UIComponent/MultiSelect/MultiSelect";
import ApiService from "../../../../Services/ApiService";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
import Container from "../../SourceManagement/TreeContainer";
import {
  clearFilterJobRecords,
  clearFilterQC,
  GetAssetData,
  setFilterJobRecords,
  setFilterQC,
} from "../../../../features/filter/filterSlice";
import axios from "axios";
import {
  callBasedOnLocation,
  callFromAssetType,
  callFromComm,
  callFromPriority,
  callSource,
} from "../../../../Util/TreeAPI";

// Importing useStyles
import useStyles from "./ScoringReport.styles";
import "react-dropdown-tree-select/dist/styles.css";
import "../../../../../src/index.scss";
import "../../../../Resources/scss/layout.scss";
import {
  clearMeritQCFilter,
  setMeritQCFilter,
} from "../../../../features/filter/StateSlice";
import { FilterContext } from "../../../DataCollection/DashboardReports/Context/Context";
import DatePicker from "../../../../UIComponent/DatePicker";

const FilterForm = (props) => {
  const classes = useStyles();
  const { filterQC, assetDropDownlist } = useSelector((store) => store.filter);
  const {
    companyList,
    setCompanyList,
    locationQC,
    setLocationQC,
    commodsQC,
    setCommodsQC,
  } = useContext(FilterContext);
  const dispatch = useDispatch();

  // Declaration of States and Component Variables Start Here
  const [allState, setAllState] = React.useState(props);
  const [status, setStatus] = React.useState([]);
  const [jobComms, setJobComms] = React.useState([]);
  const [prior, setPrior] = React.useState([]);
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);
  const [fromDate, setFromDate] = React.useState();
  const [toDate, setToDate] = React.useState();
  const [keyToDate, setKeyToDate] = React.useState(false);
  const [keyFromoDate, setKeyFromDate] = React.useState(false);
  const[keyJobDate,setKeyJobDate] = React.useState(false);
  const[keyTatDate,setKeyTatDate] = React.useState(false);
  const [qcNames, setQCNames] = React.useState([]);
  const [disableFilters, setDisableFilters] = React.useState(false);
  const [analystName, setAnalystName] = React.useState([]);
  const [analystSelection, setAnalystSelection] = React.useState([]);
  const [qcSelection, setQCSelection] = React.useState([]);
  const[jobCreatdDate,setJobDate] = React.useState();
  const [tatDate,setTatDate]= React.useState();
  const [ analyst,setAnalyst]= React.useState([]);
  const[qc,setMeritQC]= React.useState([]);
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [filters, setFilters] = React.useState({
    company: [],
    asset: [],
    assetTypeIds: [],
    countryIds: [],
    regionIds: [],
    subRegionIds: [],
    commodityIds: [],
    statusIds: [],
    priorityIds: [],
    
  });
  const [apiData, setApiData] = React.useState({
    companies: [],
    location: {},
    assetType: [],
    priority: [],
    commodity: [],
   analystName:[],
    qcNames:[],
  });

  const [dependency, setDependency] = React.useState(null);

  // Declaration of States and Component Variables End Here

 // This function handles the onChange event for the filterQC state
const onChangeHandler = (key, value, reason) => {
  // Create a copy of the filterQC state
  const pre = { ...filterQC };
  pre[key] = value;

  // If the key is assetType, set the dependency and update the apiData state
  if (key === "assetType") {
    setDependency("assetType");

    setApiData({
      ...apiData,
      assetType: value,
    });
  } 
  // If the key is analystName, set the dependency and update the apiData state
  if (key === "analystName") {
    setDependency("analystName");
    setApiData({
      ...apiData,
      analystName: value,
    });
  } 
  // If the key is meritQc, set the dependency and update the apiData state
  if (key === "qcNames") {
    setDependency("qcNames");

    setApiData({
      ...apiData,
      qcNames: value,
    });
  } 
  // Dispatch the updated filterQC state
  dispatch(setFilterQC({ filterQC: pre }));
};

  // This function clears all the filters and sets them to empty arrays
const clearFieldHanlder = () => {
  // Set filters to empty arrays
  setFilters({
    filters,
    company: [],
    asset: [],
    assetTypeIds: [],
    countryIds: [],
    regionIds: [],
    subRegionIds: [],
    commodityIds: [],
    statusIds: [],
    priorityIds: [],
  });
  // Set api data to null
  setApiData(null);
  // Dispatch clear filter QC action
  dispatch(clearFilterQC());
  // Dispatch clear merit QC filter action
  dispatch(clearMeritQCFilter());
  // Remove selected from date, to date, job date and tat date from session storage
  localStorage.removeItem("selectedFromDate");
  localStorage.removeItem("selectedToDate");
  localStorage.removeItem("selectedJobDate");
  localStorage.removeItem("selectedTatDate");
  // Set filter value to empty string
  props.setFilterValue("");
  // Set reload to opposite of current value
  props.setReload(!props.reload);
  // Get filter data
  props.getFilterData();
  // Set key from date to opposite of current value
  setKeyFromDate((k) => !k);
  // Set key to date to opposite of current value
  setKeyToDate((t) => !t);
  // Set key job date to opposite of current value
  setKeyJobDate((t)=>!t);
  // Set key tat date to opposite of current value
  setKeyTatDate((t)=>!t);
  // Set company list to empty array
  setCompanyList([]);
  // Set location QC to empty array
  setLocationQC([]);
  // Set dependency to null
  setDependency(null);

 // Invokes the function setTatDate('')
// This function may be used to set a "turnaround time" (TAT) date, specifying an expected completion date for a task or process.
setTatDate(null);

// Invokes the function setFromDate('')
// This function may be setting a "from date" for a certain range or operation, but as it receives an empty string as a parameter, it might reset or clear the previously set date.
setFromDate(null);

// Invokes the function setToDate('')
// Similar to setFromDate(''), this function might be setting a "to date", defining the end of a range or operation. As it uses an empty string as a parameter, it could also serve to reset or clear the end date.
setToDate(null);
setJobDate(null);
};
 

  // const selectAnalyst = (e, value) => {
  //   props.setSelectedAnalyst(value)
  //   setAnalystName(!value ? null : value)
  //   props.setAnalystName(!value ? null : value);
  //   if (!value) { setDisableFilters(false); }
  //   else { setDisableFilters(true); }
  // };
  // const selectMeritQC = (e, value) => {
  //   props.setSelectedMeritQC(value)
  //   seQCNames(!value ? null : value)
  //   props.seQCNames(!value ? null : value);
  //   if (!value) { setDisableFilters(false); }
  //   else { setDisableFilters(true); }
  // };

  // Declaration of React Hooks Start Here

  React.useEffect(() => {
    dispatch(GetMeritQc());
    dispatch(GetAnalyst());
    getAllCompanies();

  }, []);

// This code creates an async thunk called GetMeritQc
const GetMeritQc = createAsyncThunk(
    "filter/analyst",
    async (arg, { rejectWithValue }) => {

    // This code makes an API call to get all the data from CompanyOwnershipReport/GetMeritQCDropdown
    ApiService.getAll("CompanyOwnershipReport/GetMeritQCDropdown")
      .then((res) => {
        // If the response status is 200, set the MeritQC with the response data
        if (res.status === 200) {
          setMeritQC(res.data);
        } else {
          // If the response status is not 200, call the toasterError function with the response
          toasterError(res);
        }
      })
      .catch((err) => {
        // If there is an error, call the ErroHandling function with the error and a callback function
        ErroHandling(err, function (res) {
          // Call the toasterError function with the response
          toasterError(res);
        });
      });
    }
    );

// createAsyncThunk is used to create an asynchronous thunk action
const GetAnalyst = createAsyncThunk(
    "filter/analyst",
    // async function that takes two arguments, arg and rejectWithValue
    async (arg, { rejectWithValue }) => {

      // ApiService.getAll is used to make a GET request to the specified endpoint
      ApiService.getAll("CompanyOwnershipReport/GetAssignedToDropdown")
        .then((res) => {
          // if the response status is 200, set the analyst data
          if (res.status === 200) {
            setAnalyst(res.data);
          } else {
            // call toasterError with the response if the status is not 200
            toasterError(res);
          }
        })
        .catch((err) => {
          // call ErroHandling with the error and a callback function
          ErroHandling(err, function (res) {
            // call toasterError with the response from the callback
            toasterError(res);
          });
        });
   
  }
  );

  // Declaration of React Hooks End Here

 // This function is used to submit the filter values
const submitFilter = () => {
  // Create an object to store the filter values
  const formData = {
    analystName : filterQC.analystName,
    qcNames: filterQC.qcNames,
    analyst: filterQC.analystName?.map((el) => el.userId),
    meritQC: filterQC.qcNames?.map((el) => el.userId),
    fromDate: fromDate|| localStorage.getItem("selectedFromDate"),
    toDate: toDate||localStorage.getItem("selectedToDate"),
    tat: tatDate ||localStorage.getItem("selectedTatDate"),
    jobCompletionDate: jobCreatdDate||localStorage.getItem("selectedJobDate"),
    owner: props.companyFilter
      .filter((el) => el.checked === true)
      .map((el) => el.value),
    asset: props.companyFilter
      .map((el) =>
        el.children.filter((it) => it.checked === true).map((tk) => tk.value)
      )
      .filter((item) => item.length > 0)
      .flat(1),
  };

  // Set the filter values
  props.setFilterValue(formData);
  dispatch(setFilterQC({filterQC:formData}));
  props.setSubmitValues(!props.submitValues);
  props.setState({ ...props.state, right: false });
};
 

  // This function is used to get all companies from the API
const getAllCompanies = () => {
  // Call ApiService to get all companies
  ApiService.getAll("DropDown/GetCompanyDropDownFilter")
    .then((res) => {
      
      // Check if response status is 200
      if (res.status === 200) {
        // Set job companies with response data
        setJobCompanies(res.data);
      } else {
        // Show error message in toaster
        toasterError(res.data);
      }
    })
    .catch((err) => {
      // Handle errors and show error message in toaster
      ErroHandling(err, function (res) {
        toasterError(res);
      });
    });
};

 // This function is used to set the dependency of company and selected companies
const onChangeComp = (currentNode, selectedNodes) => {
  // Set the dependency of company
  setDependency("company");
  // Set the selected companies
  setSelectedCompanies(selectedNodes);
  // Loop through the company filter
  props.companyFilter.forEach((el) => {
    // If the current node is checked
    if (currentNode.checked === true) {
      // Check if the current node value is equal to el value
      if (currentNode.value === el.value) {
        // Set the el.checked to true
        el.checked = true;
      } else if (currentNode.parentValue === el.value) {
        // Loop through the item in el.children
        el.children.forEach((item) => {
          // Check if the current node value is equal to item value
          if (currentNode.value === item.value) {
            // Set the item.checked to true
            item.checked = true;
          }
        });
      }
    } else if (currentNode.checked === false) {
      // Check if the current node value is equal to el value
      if (currentNode.value === el.value) {
        // Set the el.checked to false
        el.checked = false;
      } else if (currentNode.parentValue === el.value) {
        // Loop through the item in el.children
        el.children.forEach((item) => {
          // Check if the current node value is equal to item value
          if (currentNode.value === item.value) {
            // Set the item.checked to false
            item.checked = false;
          }
        });
      }
    }
  });

  // Get the first parent from the company filter
  const firstParent = props.companyFilter
    .map((el) => {
      let data = {
        label: el.label,
        value: el.value,
        children: el.children.filter((item) => item["checked"] === true),
      };
      return data;
    })
    .filter((el) => el.children.length > 0);
  // Set the api data
  setApiData({
    ...apiData,
    companies:
      props.companyFilter.filter((el) => el["checked"] === true).length > 0
        ? props.companyFilter.filter((el) => el["checked"] === true)
        : firstParent,
  });
};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Add & Edit Currency Form */}
      <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
          <DatePicker
            labelTitle="From Date"
            variant="standard"
            defaultDate={fromDate|| localStorage.getItem("selectedFromDate")}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date =
                selectedDate.getFullYear() +
                "-" +
                ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + selectedDate.getDate()).slice(-2);
              setFromDate(date);
              localStorage.setItem("selectedFromDate", date);
            }}
            past={false}
            key={keyFromoDate}
            clearable={true}
            required={true}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <DatePicker
            labelTitle="To Date"
            variant="standard"
            past={false}
            defaultDate={toDate || localStorage.getItem("selectedToDate")}
            minDates={toDate || localStorage.getItem("selectedFromDate")}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date =
                selectedDate.getFullYear() +
                "-" +
                ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + selectedDate.getDate()).slice(-2);
              setToDate(date);
              localStorage.setItem("selectedToDate", date);
            }}
            key={keyToDate}
            clearable={true}
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <MultiSelect
            onChange={onChangeHandler}
            value={filterQC.assetType}
            items={
              props.dataForFilter.assetType ? props.dataForFilter.assetType : []
            }
            label="Asset"
            selectAllLabel={{ assetTypeName: "Select all" }}
            getOptionLabel={(option) => option.assetTypeName}
            getOptionSelected={(option, anotherOption) =>
              option.assetTypeName === anotherOption.assetTypeName
            }
            role="assetType"
          /> */}
          <Container
            data={props.companyFilter}
            onChange={onChangeComp}
            showPartiallySelected={true}
            texts={{ placeholder: "Owner" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            labelTitle="Job Completion Date"
            variant="standard"
            past={false}
            defaultDate={jobCreatdDate || localStorage.getItem("selectedJobDate")}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date =
                selectedDate.getFullYear() +
                "-" +
                ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + selectedDate.getDate()).slice(-2);
              setJobDate(date);
              localStorage.setItem("selectedJobDate",date);
            }}
            key={keyJobDate}
            clearable={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            labelTitle="TAT"
            variant="standard"
            past={false}
            minDates={tatDate || localStorage.getItem("selectedTatDate")}
            defaultDate={tatDate || localStorage.getItem("selectedTatDate")}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date =
                selectedDate.getFullYear() +
                "-" +
                ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + selectedDate.getDate()).slice(-2);
              setTatDate(date);
              localStorage.setItem("selectedTatDate", date);
            }}
            key={keyTatDate}
            clearable={true}
          />
        </Grid>

        <Grid item xs={12}>
             <MultiSelect
            onChange={onChangeHandler}
            value= {filterQC.analystName}
            items={
              analyst
            }
            label="Analyst"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            role="analystName"
          />
        </Grid>
        <Grid item xs={12}>
           <MultiSelect
           onChange={onChangeHandler}
           value={filterQC.qcNames}
            items={
              qc
            }
            label="Merit QC"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            role="qcNames"
          />
        </Grid>


        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn"
                color="accent5"
                onClick={submitFilter}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                className="dummy-btn reset-color"
                color="accent3"
                onClick={clearFieldHanlder}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterForm;
