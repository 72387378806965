// Default React, useState and Material Components
import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterSuccess, toasterError } from "../../../UIComponent/Toaster";

// Importing Custom Components
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddVariableType = ({

  // Declaration of States and Component Variables Start Here
  setState, state, crudState, selectedItem, reload, setReload, }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(
    selectedItem ? selectedItem.variabletype : ""
  );
  const [description, setDescription] = useState(selectedItem ? selectedItem.description : "");
  // Declaration of States and Component Variables End Here

// This function is used to save data using axios post request
const saveData = async () => {
  // Check if status is true
  if (status) {
    setLoading(true);
    try {
      // Make a post request to the specified url with headers and null body
      await axios
        .post(`${process.env.React_App_baseUrl}managedata/SaveVariableType`, null, {
          headers: {
            variableTypeName: encodeURIComponent(status), variableTypeDescription: (description), 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          },
        })
        .then((res) => {
          // Set state and reload flag to true and loading to false
          setState({ ...state, right: false });
          setReload(true);
          setLoading(false);
          // Show success message
          toasterSuccess("Status added successfully!");
        })
        .catch((err) => {
          // If error status is 400, show error message
          if(err.res.status===400){
            toasterError(err.res.data);
          }
           });
    } catch (error) {
      // Call ErroHandling function to handle errors
      ErroHandling(error, function (res) {
        toasterError(res)
      })
    }
  } else { 
    // Show error message if required fields are not entered
    toasterError("Please enter the required fields!"); 
  }
  setLoading(false);
};

// This function is used to update the data
const updateData = async () => {
  // Check if status is true
  if (status) {
    setLoading(true);
    try {
      // Make a post request to the given URL with the required parameters
      await axios
        .post(`${process.env.React_App_baseUrl}managedata/UpdateVariableType`, {
          id: selectedItem.id,
          name: status,
          description: description,
        }, {
          headers: {
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          }
        })
        .then((res) => {
          // Set the state and loading to false and show success message
          setState({ ...state, right: false });
          setLoading(false);
          toasterSuccess("Status edited successfully!");
          setReload(true);
        })
        .catch((err) => {
          // Handle errors
          ErroHandling(err, function (res) {
            toasterError(res)
          })
        });
    } catch (error) {
      // Handle errors
      ErroHandling(error, function (res) {
        toasterError(res)
      })
    }
  } else { 
    // Show error message if required fields are not entered
    toasterError("Please enter the required fields"); 
  }
  setLoading(false);
};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {crudState} VariableType
        </Typography>
      </Box>

      {/* Add & Edit Commodity Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            id="standard-basic"
            label="VariableType"
            fullWidth
            required
            variant="standard"
            inputProps={{ maxLength: 50 }}
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="standard-basic"
            label="Description"
            fullWidth
            variant="standard"
            multiline
            maxRows={4}
            value={description}
            inputProps={{ maxLength: 250 }}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sx={{ mt: -1 }}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              {/* <Button variant="contained" color="accent5" onClick={crudState === "Add" ? saveData : updateData}>
                {crudState === "Edit" ? "Update" : "Save"}
              </Button> */}
              <LoadingButton
                buttonName={crudState === "Add" ? "Save" : "Update"}
                clickHandler={crudState === "Add" ? saveData : updateData}
                isLoading={loading}
              />
            </Grid>
            <Grid item xs={'auto'}>
              <Button variant="contained" color="accent3" onClick={() => setState({ ...state, right: false })}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddVariableType;