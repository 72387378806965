// Default React, Router and Services Components
import React, { useState, useEffect } from "react";
import { Autocomplete, Box, Breadcrumbs, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Link, ListItemSecondaryAction, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// import history from "../../../Routes/History";
import { useHistory } from "react-router-dom";
import { grey, lightBlue } from "@mui/material/colors";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";
// Importing Custom Components
import AddReport from "./addReport";
import EditSideDeawer from "./EditSideDeawer";
import SideDrawer from "../../../UIComponent/Drawer";

import { ScrollingDialog } from "../../../UIComponent";
import AdobeViewer from "../JobManagement/AdobeViewer";
import BaseLayout from "../../../UIComponent/BaseLayout";
import HistoricData from "../JobManagement/HistoricData";
import AlertDialog from "../../../UIComponent/AlertDialog";
import DataTable from "../../../UIComponent/DataTable/index";
import CustomDataTable from "../../../UIComponent/CustomDataTable/CustomDataTable"
import MultiSelect from "../../../UIComponent/MultiSelectDropdown";
import PlaceIcon from "@mui/icons-material/Place";
import TooltipIcon from "../../../UIComponent/Tooltip/index";
import FormDialog from "../../../UIComponent/FormDialog";


import {
  DeleteIcon,
  EditIcon,
  FileOffIcon,
  ViewIcon,
  CommentIcon,
  AttachIcon,
  AddIcon,
  LinkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronRightSmallIcon,
  TimeEntryIcon,
} from "@fluentui/react-icons-mdl2";
import CommonService from "../../../Services/CommonService";


// Importing useStyles and Styles
import useStyles from "./QualityManagement.styles";

// Import Fluent UI Icons
import EmptyState from "../../../UIComponent/EmptyState";

// Assigning the Grey and #00519c Color Code
const darkGrey = grey[900];
const darkBlue = lightBlue[900];

function createData(
  lastUpdateYear, lastUpdateQuater, variableReport, unitReport, variableCru, unitCru, currency, q1, q2, q3, q4, cy, qcFeedback, qcStatus
) {
  return {
    lastUpdateYear, lastUpdateQuater, variableReport, unitReport, variableCru, unitCru, currency, q1, q2, q3, q4, cy, qcFeedback, qcStatus,
  };
}

const OwnershipChangesManageQualityDetails = (props) => {
  const history = useHistory()
  // Declaration of States and Component Variables Start Here
  const [adobeViewerData, setAdobeViewerData] = useState({ jobId: 0, fileName: "", pageNumber: 0, crudState: "", });
  const [allTableRecord, setallTableRecord] = useState(props.location.allRow);
  const [assetType, setAssetType] = useState("all");
  const [currentTableDataIndex, setcurrentTableDataIndex] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [currentYeatData, setcurrentYeatData] = useState([])
  const [datum, setDatum] = useState([])
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [excelColumns, setExcelColumns] = useState([]);
  const [excelExportRows, setExcelExportRows] = useState([]);
  const [excelExportRowsWithComments, setexcelExportRowsWithComments] = useState([])
  const [allComment, setAllComent] = useState([])
  const [excelColorCode, setexcelColorCode] = useState([])
  const [filterState, setFilterState] = React.useState({ right: false });
  const [historicData, setHistoricData] = useState(false);
  const [isAssetSegment, setIsAssetSegment] = useState(false)
  const [HistoricDataList, setHistoricDataList] = useState([]);
  const [id, setId] = useState([]);
  const [isAllSelected, setIsSelected] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [jobAssetId, setJobAssetId] = useState(JSON.parse(localStorage.asset)?.jobAssetId ? JSON.parse(localStorage.asset)?.jobAssetId : JSON.parse(localStorage.assets));
  const [ManageQualityData, setManageQualityData] = useState("");
  const [open, setOpen] = useState(false);
  const [openAddReport, setopenAddReport] = useState(false);
  const [openAdobeViewer, setOpenAdobeViewer] = useState(false);
  const [openDeleteAlart, setOpenDeleteAlart] = useState(false);
  const [page, setPage] = React.useState(0);
  const [qcFormValue, setqcFormValue] = useState({ variableStatusId: null, variableFeedback: null, });
  const [qcStatus, setqcStatus] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [reload, setReload] = useState(false);
  const [reloadScore, setReloadScore] = useState(true);
  const [Rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scroll, setScroll] = React.useState("paper");
  const [searchValue, setSearchValue] = useState();
  const [selected, SetSelected] = React.useState([]);
  const [SelectedYear, setSelectedYear] = useState([]);
  const [state, setState] = useState({ right: false });
  const [statusAndFeedBackLoading, setstatusAndFeedBackLoading] = useState(false);
  const [tempId, setTempId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [VariableDeleteItem, setVariableDeleteItem] = useState(null);
  const [YearOption, setYearOption] = useState([]);
  const [scoringParameters, setScoringParameters] = useState();
  const classes = useStyles();
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
  const [addOpenScore, setOpenScore] = useState(false);
  const [openJoinVenture, setOpenJointventure] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [editState, setEditState] = useState()
  const [qualityDrildropDownData, setQualityDrildropDownData] = useState([])
  const [jointVentureData, setJointVenrtureData] = useState([])
  const [updateData, setUpdateData] = useState([])
  const [seeThroughOwners, setSeeThroughOwners] = useState()
  const [seeThroughOwnersForJv, setSeeThroughOwnersForJv] = useState()
  const [drillDropAllChecked, setDrillDropsAllChecked] = React.useState(false)
  const [drilldropCheckedValue, setDrillDropCheckedValue] = React.useState([])
  const [jointVentureAllChecked, setJointVentureAllChecked] = React.useState(false)
  const [jointVentureCheckedValue, setJointVentureCheckedValue] = React.useState([])
  const [updateAllChecked, setUpdateAllChecked] = React.useState(false)
  const [updateCheckedValue, setUpdateCheckedValue] = React.useState([])
  const [FinalValues, setFinalValues] = React.useState([])
  const [confirmation, setConfirmation] = React.useState(false)
  const [confirmation1, setConfirmation1] = React.useState(false)
  const [confirmation2, setConfirmation2] = React.useState(false)
  const [confirmation3, setConfirmation3] = React.useState(false)
  const [defaultYearValue, setDefaultYearValue] = useState(null);
  const [selectedYearId, setSelectedYearId] = useState(null);
  const [years, setYears] = useState([]);
  const [deleteUpdateId, setDeleteUpdateId] = React.useState()
  const [deleteJvId, setDeleteJvId] = React.useState()
  const [isSelectedLineItem, setIsSelectedLineItem] = React.useState(false);
  const [selectedJobId, setSelectedJobId] = useState();
  const [data, setData] = useState({});
  const [AnyValueTrue, setAnyValueTrue] = useState(false);
  const [scoringOpened, setScoringOPened] = useState(false);
  const [liveUpdateId, setLiveUpdateId] = useState()
  const [liveJvId, setLiveJvId] = useState()
  const [deleteQCId, setDeleteQcId] = useState();
  const [disableSave, setDisableSave] = useState(true);




  const [mockScore, setmockScore] = useState([
    {
      jobAssetId: 0,
      total: 0,
      totalAchieved: 0,
      achievedPercentage: 0,
      parameterList: [
        {
          parameterId: 1,
          parameterName: "Boliden",
          parameterTypeId: 3,
          parameterType: "Boliden",
          isApplicable: true,
          total: 5,
          totalAchieved: 5
        },

        {
          parameterId: 2,
          parameterName: "Boliden2",
          parameterTypeId: 4,
          parameterType: "Boliden2",
          isApplicable: true,
          total: 10,
          totalAchieved: 10,

        },]
    }
  ]);
  console.log("mock", mockScore);
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(52, function (err, res) {
      if (res) {
        //console.log(res.data)
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
    getRoleBasedStatus();

  }, [])
  // React.useEffect(() => {
  //   getYear();

  // }, [selectedYearId])

  useEffect(() => {
    getQuailityDrilDropDown()
  }, [])
  // useEffect(() => {
  //   // if ManageQualityData is empty then call the api
  //   setTempId(props.location.state.jobAssetId);
  //   if (YearOption.length < 1) { GetReportingYear(); }
  //   if (qcStatus.length < 1) {
  //     getRoleBasedStatus();
  //     if (!ManageQualityData) { getAssetVariableDetails(props.location.state.jobAssetId); }
  //   }
  //   if (!currentTableDataIndex) {
  //     if (allTableRecord && allTableRecord.length > 0) {
  //       allTableRecord.forEach((al, i) => {
  //         if (al.jobAssetId === props.location.state.jobAssetId) {
  //           setcurrentTableDataIndex(i);
  //         }
  //       });
  //     }
  //   }
  // }, []);
  // useEffect(() => {
  //   if (reload) {
  //     setReload(false);
  //     if (sorting && sorting.sortBy) {
  //       if (SelectedYear.length < 1 && (sorting.sortBy !== "" || sorting.sortBy !== null)) {
  //         getAssetVariableDetails(jobAssetId ? jobAssetId : props.location.state.jobAssetId);
  //       } else {
  //         getVariableHistoryDetails(SelectedYear, historicData);
  //       }
  //     }
  //     if (SelectedYear.length < 1 && (sorting.sortBy === "" || sorting.sortBy === null)) {
  //       getAssetVariableDetails(jobAssetId ? jobAssetId : props.location.state.jobAssetId);
  //     } else if (sorting.sortBy === "" || sorting.sortBy === null) {
  //       getVariableHistoryDetails(SelectedYear, historicData);
  //     }
  //   }
  // }, [reload, SelectedYear]);
  const checkStatus = (statusName) => {

    let status = false;
    if (qcStatus && qcStatus.length > 0) {
      if (qcStatus[0].statusName === 'CRU Review Passed') {
        if (statusName === 'CRU Review Passed' || statusName === 'CRU Review Passed with Errors') {
          status = true;
        }
      } else {
        if (statusName === 'CRU Review Passed' || statusName === 'CRU Review Passed with Errors' || statusName === 'Merit QC Passed' || statusName === 'Merit QC Passed with Errors') {
          status = true;
        }
      }

    }
    return status;
  }
  useEffect(() => {
    let yeOption = YearOption
    if (currentYear && YearOption.length > 0) {
      yeOption.forEach((ye, i) => {
        if (ye.year == currentYear) {
          yeOption.splice(i, 1)
          setcurrentYeatData(ye);
        }
      })
    }
  }, [currentYear, YearOption])

  // Declaration of React Hooks End Here
  useEffect(() => {
    getJobInfo();
    if (reloadScore) {
      setReload(false);
      // if(props.location.state){
      //   getScoringDetails();
      // }

    }

  }, [reloadScore])

  useEffect(() => {
    for (const key in data.dataPoints) {
      if (
        data.dataPoints.hasOwnProperty(key) &&
        data.dataPoints[key] === true
      ) {
        setAnyValueTrue(true);
        break;
      }
    }
  }, [data]);

  const getJobInfo = async () => {
    console.log(localStorage.getItem('asset'));
    var obj = JSON.parse(localStorage.asset);
    var singleAsset = obj?.jobAssetId;
    var multi = JSON.parse(localStorage.assets);



    await axios
      .post(
        `${process.env.React_App_baseUrl}ManageOwnership/GetJobDetails`,
        null,
        {
          headers: {
            jobAssetId: singleAsset ? singleAsset : multi[0],
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setData(res.data);


        //setDataPoints(res.data.map((i, e) => i))



      })
      .catch((err) => {
        {
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        }
        // setOpenDialog(false);
      });
    // setIsOpenAssign(false);
  };

  const handleChangeCheckBoxAll = (event, checked) => {
    //let status = false;
    const allCheckboxValue =
      drilldropCheckedValue.length === qualityDrildropDownData.length
        ? []
        : qualityDrildropDownData
    // qualityDrildropDownData.forEach(element => {
    //   if (checkStatus(element.statusName)) {
    //     toasterError('Quality control passed items can not be selected');
    //     status = true;
    //   }
    // })
    // if (status) {
    //   return;
    // }
    setDrillDropCheckedValue(allCheckboxValue);
    setDrillDropsAllChecked(!drillDropAllChecked)
    setDisableSave(!checked)





  };

  //Method for single check functionality 
  const handleChangeCheckBoxSingle = (event, option, checked) => {
    // if (checkStatus(option.statusName)) {
    //   toasterError('Quality control passed items can not be selected');
    //   return;
    // }
    let selectedOld = [...drilldropCheckedValue];
    if (selectedOld.length === qualityDrildropDownData.length) {
      setDrillDropsAllChecked(false);
    }
    if (selectedOld.length === 0 && qualityDrildropDownData.length > 1) {
      selectedOld.push(option);
    } else if (selectedOld.includes(option)) {
      selectedOld.splice(selectedOld.indexOf(option), 1);
    } else {
      selectedOld.push(option);
      if (selectedOld.length === qualityDrildropDownData.length) {
        setDrillDropsAllChecked(true);
      }
    }
    setDrillDropCheckedValue(selectedOld);
    if (qualityDrildropDownData.length > 1) {

      if (selectedOld.length === 1) {
        setIsSelectedLineItem(true);
        setSelectedJobId(selectedOld[0].jobAssetId);
      }
      else {
        setIsSelectedLineItem(false);
      }
    }
    setDisableSave(!checked)
  };

  const handleChangeJVCheckBoxAll = (event) => {
    const allCheckboxValue =
      jointVentureCheckedValue.length === jointVentureData.length
        ? []
        : jointVentureData
    setJointVentureCheckedValue(allCheckboxValue);
    setJointVentureAllChecked(!jointVentureAllChecked)
  };

  //Method for single check functionality 
  const handleChangeJVCheckBoxSingle = (event, option) => {
    let selectedOld = [...jointVentureCheckedValue];
    if (selectedOld.length === jointVentureData.length) {
      setJointVentureAllChecked(false);
    }
    if (selectedOld.length === 0 && jointVentureData.length > 1) {
      selectedOld.push(option);
    } else if (selectedOld.includes(option)) {
      selectedOld.splice(selectedOld.indexOf(option), 1);
    } else {
      selectedOld.push(option);
      if (selectedOld.length === jointVentureData.length) {
        setJointVentureAllChecked(true);
      }
    }
    setJointVentureCheckedValue(selectedOld);


  };

  const handleChangeUpdateCheckBoxAll = (event) => {
    const allCheckboxValue =
      updateCheckedValue.length === updateData.length
        ? []
        : updateData
    setUpdateCheckedValue(allCheckboxValue);
    setUpdateAllChecked(!updateAllChecked)
  };

  //Method for single check functionality 
  const handleChangeUpdateCheckBoxSingle = (event, option) => {
    let selectedOld = [...updateCheckedValue];
    if (selectedOld.length === updateData.length) {
      setUpdateAllChecked(false);
    }
    if (selectedOld.length === 0 && updateData.length > 1) {
      selectedOld.push(option);
    } else if (selectedOld.includes(option)) {
      selectedOld.splice(selectedOld.indexOf(option), 1);
    } else {
      selectedOld.push(option);
      if (selectedOld.length === updateData.length) {
        setUpdateAllChecked(true);
      }
    }
    setUpdateCheckedValue(selectedOld);
  };
  const deleteQc = () => {
    axios.delete(process.env.React_App_baseUrl + "OwnershipChangeQualityManagement/DeleteJobAssetQCDrilldown", {
      headers: {
        Authorization: localStorage.getItem("token")
          ? "Bearer " + localStorage.getItem("token")
          : null,
        jobAssetId: deleteQCId
      },
    }).then(() => {
      setConfirmation(false)
      getQuailityDrilDropDown()
      toasterSuccess("Deleted Successfully")


    }).catch(() => {
      toasterError("Something went wrong")
    })
  }
  const getQuailityDrilDropDown = () => {
    var arr = [];

    console.log(localStorage.getItem('asset'));
    var obj = JSON.parse(localStorage.asset);
    var singleAsset = obj?.jobAssetId;
    var multi = JSON.parse(localStorage.assets);

    if (obj) {

      arr.push(obj?.jobAssetId)
    }

    var a = 0
    axios.get(process.env.React_App_baseUrl + "OwnershipChangeQualityManagement/GetQCDrildownJobAssetList",
      {
        headers: {
          jobAssetId: obj ? arr : multi,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      }
    )
      .then((res) => {
        console.log(res.data)
        res.data?.map((item) => {
          if (item.getSeeThroughOwnerDetails.length > a) {
            a = item.getSeeThroughOwnerDetails.length
          }
        })
        setSeeThroughOwners(a)
        setQualityDrildropDownData(res.data)
      }).catch((res) => {

      })
  }

  const GetJointVentureDetails = (id) => {


    axios.get(process.env.React_App_baseUrl + "OwnershipChangeQualityManagement/GetQCDrildownJointVentureList",
      {
        headers: {
          jobAssetId: id,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      }
    )
      .then((res) => {
        var a = 0



        res.data?.map((item) => {
          if (item.getSeeThroughOwnerDetails.length > a) {
            a = item.getSeeThroughOwnerDetails.length
          }
        })
        setSeeThroughOwnersForJv(a)
        setJointVenrtureData(res.data)
      }).catch((res) => {

      })
  }

  const handleADClose = () => { setConfirmation(false); }
  const handleADClose1 = () => { setConfirmation1(false); }
  const handleADClose2 = () => { setConfirmation2(false); }
  const handleADClose3 = () => { setConfirmation3(false); }



  const GetUpdateDetails = (id) => {
    axios.get(process.env.React_App_baseUrl + "OwnershipChangeQualityManagement/GetQCDrilldownUpdateList",
      {
        headers: {
          jobAssetId: id,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      }
    )
      .then((res) => {
        if (res.data) {
          res.data.forEach(el => {
            if (el.status === 'CRU Review Passed' || el.status === 'CRU Review Passed with Errors' || el.status === 'Merit QC Passed' || el.status === 'Merit QC Passed with Errors') {

            }
          });
        }
        setUpdateData(res.data)
      }).catch((res) => {

      })
  }

  const deleteJointVenture = () => {
    axios.delete(process.env.React_App_baseUrl + "ManageOwnership/DeleteJointVentureInfo?jointVentureInfoId=" + deleteJvId, {
      headers: {
        Authorization: localStorage.getItem("token")
          ? "Bearer " + localStorage.getItem("token")
          : null,
      },
    }).then(() => {
      setConfirmation2(false)
      setOpenJointventure(false)
      toasterSuccess("Deleted Successfully")
    }).catch(() => {
      toasterError("Something went wrong")
    })

  }

  const deleteUpdateVenture = () => {
    axios
      .delete(`${process.env.React_App_baseUrl}ManageOwnership/DeleteUpdate`, {
        headers: {
          updateId: deleteUpdateId,
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      })
      .then((res) => {
        setOpenUpdate(false)
        setConfirmation1(false)
        toasterSuccess("Update Info Deleted Successfully!");
      })
      .catch((err) =>
        ErroHandling(err, function (res) {
          toasterError(res);
        })
      );
  }

  const bulckEditQuality = () => {
    console.log("drilldropCheckedValue", drilldropCheckedValue)
    if (drilldropCheckedValue.length) {
      var a = []
      let status=false;
      drilldropCheckedValue?.map((item) => {
        if (!checkStatus(item.statusName)) {
        a.push(item?.jobAssetId)
        }else{
          status=true;
        }
      })
      if(status){
        toasterError("Some of the selected job(s) are already in 'QC passed' status.. Please deselect them and try again.")
        return;
      }
      if (drilldropCheckedValue.length === a.length) {
        setConfirmation3(false)
        FinalSave(a, 'Save')
      }
    } else {
      toasterError("Please select atleats any one asset")
    }
  }
  const bulckEditJv = () => {
    console.log("jointVentureCheckedValue", jointVentureCheckedValue)
    if (jointVentureCheckedValue.length) {
      var a = []
      jointVentureCheckedValue?.map((item) => {
        a.push(item.id)
      })
      if (jointVentureCheckedValue.length === a.length) {

        setFinalValues(a)
        setIsOpenForm(true)
        setEditState("Joint Venture")
      }
    } else {
      toasterError("Please select atleats any one asset")
    }
  }

  const bulckEditUpdate = () => {
    console.log("updateCheckedValue", updateCheckedValue)
    if (updateCheckedValue.length) {
      var a = []
      updateCheckedValue?.map((item) => {
        a.push(item.updateId)
      })
      if (updateCheckedValue.length === a.length) {
        setFinalValues(a)
        setIsOpenForm(true)
        setEditState("Update")
      }
    } else {
      toasterError("Please select atleats any one company")
    }
  }
  const FinalSave = (finalId, status) => {

    if (qcFormValue.variableStatusId === 90 && !scoringOpened) {
      toasterError("Please provide Scoring")

    }
    else
      if (qcFormValue.variableStatusId && qcFormValue.variableFeedback) {
        axios.post(process.env.React_App_baseUrl + "OwnershipChangeQualityManagement/UpdateQCStatus",
          {
            "jobAssetIds": finalId,
            "statusId": qcFormValue.variableStatusId,
            "qcFeedback": qcFormValue.variableFeedback
          }, {
          headers: {
            "saveAll": status === "Save",
            "owner": status === "Quality Management Drilldown"||status==="Owner Info - QC Status Update",
            "update": status === "Update"||status==="Update Info - QC Status",
            "jv": status === "Joint Venture"||status==="Joint Venture Info - QC Status Update",
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          },
          params: {
            jobAssetId: ManageQualityData?.jobAssetId
          }

        }
        )
          .then((res) => {
            toasterSuccess("Feedback Saved Successfully")
            if (status === "Quality Management Drilldown"||status==="Owner Info - QC Status Update" || status === "Save") {
              setIsOpenForm(false)
              getQuailityDrilDropDown()
            } else if (status === "Update"||status==="Update Info - QC Status") {
              setIsOpenForm(false)
              GetUpdateDetails(liveUpdateId)
            } else {
              setIsOpenForm(false)
              GetJointVentureDetails(liveJvId)
            }
          }).catch((err) => {
            toasterError("Something went wrong")
          })
      } else {
        toasterError("Please fill all input fields")
      }
  }
  // Function for Next and Previous Button
  // const handleNextAndPrevious = (mode) => {
  //   setSelectedYear([])
  //   setCurrentYear(null)
  //   setcurrentYeatData([]);
  //   GetReportingYear();
  //   if (mode === "next") {
  //    // getAssetVariableDetails(allTableRecord[currentTableDataIndex + 1].jobAssetId);
  //     setcurrentTableDataIndex(currentTableDataIndex + 1);
  //     setJobAssetId(allTableRecord[currentTableDataIndex + 1].jobAssetId);
  //   } else {
  //     getAssetVariableDetails(allTableRecord[currentTableDataIndex - 1].jobAssetId);
  //     setcurrentTableDataIndex(currentTableDataIndex - 1);
  //     setJobAssetId(allTableRecord[currentTableDataIndex - 1].jobAssetId);
  //   }
  // };

  const handleCloseLegal = () => {
    setOpenScore(false);
    setOpenJointventure(false)
    setOpenUpdate(false)
  };
  const handleSubmit = () => {
    setstatusAndFeedBackLoading(true);
    axios.post(process.env.React_App_baseUrl + "OwnershipChangeQualityManagement/SaveQCDrildownJobAssetScoring", scoringParameters, {
      headers: { 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null }
    })
      .then((res) => {
        setstatusAndFeedBackLoading(false);
        if (res.status === 200) {
          setReloadScore(true)
          toasterSuccess("Score Added Successfully");
          setOpenScore(!addOpenScore);

        }
        else { toasterError("Something went wrong"); }
      })
      .catch((err) => {
        setstatusAndFeedBackLoading(false);
        ErroHandling(err, function (res) { toasterError(res) })
      });

  };

  // Function for Sorting Column
  const changeSortColumnNameFormat = (fieldName) => {
    if (fieldName == null || fieldName == "") { return ""; }
    if (fieldName.toLocaleLowerCase() == "VariableReport".toLocaleLowerCase()) {
      fieldName = "VariableReport";
    }
    if (fieldName.toLocaleLowerCase() == "UnitReport".toLocaleLowerCase()) {
      fieldName = "UnitReport";
    }
    if (fieldName.toLocaleLowerCase() == "VariableCru".toLocaleLowerCase()) {
      fieldName = "VariableCru";
    }
    if (fieldName.toLocaleLowerCase() == "UnitCru".toLocaleLowerCase()) {
      fieldName = "UnitCru";
    }
    return fieldName;
  };

  // Function for Delete Variable Report 
  const deleteVariableReport = () => {
    setDeleteBtnLoadinng(true);
    axios.delete(process.env.React_App_baseUrl + "QualityManagement/DeleteVariable",
      {
        headers: {
          VariableId: VariableDeleteItem.variableId, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      }
    )
      .then((res) => {
        setOpenDeleteAlart(false);
        if (res.status === 200) {
          setReload(true);
          setDeleteBtnLoadinng(false);
          toasterSuccess("Variable deleted successfully");
        } else { toasterError("Something went wrong"); }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
        setOpenDeleteAlart(false);
        setDeleteBtnLoadinng(false);

      });
  };

  // Function for Getting Asset Variable Report 
  const getAssetVariableDetails = (id) => {
    console.log("id", id)
    setLoading(true);
    axios.post(process.env.React_App_baseUrl + "QualityManagement/GetAssetVariableDetails", null,
      {
        headers: {
          jobAssetId: id,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      }
    )
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setManageQualityData({ ...res.data });
          setIsAssetSegment(res.data.isSegment);
          sortHistoryTableData([...res.data.variablesDetails]);
          let excelData = [];
          let excelDataWithOutComment = []
          let allComentvalue = []
          let colorcodes = []
          if (res.data != null) {
            if (res.data.variablesDetails != null) {
              res.data.variablesDetails.forEach((element, index) => {
                let variableDetails = {
                  LastUpdateYear: element.lastUpdateYear,
                  LastUpdateQuater: element.lastUpdateQuater,
                  VariableReport: element.valuationType === "Guidance" ? element.valuationType + " - " + element.variableReport : element.variableReport,
                  UnitReport: element.unitReport,
                  VariableCRU: element.variableCru,
                  UnitCRU: element.unitCru,
                  Currency: element.currency,
                };
                let variableDetailsWithOutComments = {
                  LastUpdateYear: element.lastUpdateYear,
                  LastUpdateQuater: element.lastUpdateQuater,
                  Company: res.data.companyName,
                  Asset: res.data.asset,
                  Commodity: res.data.commodity,
                  Country: res.data.country,
                  Region: res.data.region,
                  "Sub Region": res.data.subRegion,
                  VariableReport: element.valuationType === "Guidance" ? element.valuationType + " - " + element.variableReport : element.variableReport,
                  UnitReport: element.unitReport,
                  VariableCRU: element.variableCru,
                  UnitCRU: element.unitCru,
                  Currency: element.currency,
                  VariableType: element.variableTypeName
                };
                if (element.yearId) {
                  setSelectedYearId(element.yearId);
                }
                if (element.reportingYear != null) {
                  element.reportingYear.forEach((ele, index) => {
                    variableDetails[ele.publication] = ele.value ? ele.value : (ele.minValue ? ele.minValue : "") + (ele.minValue && ele.maxValue ? " - " : '') + (ele.maxValue ? ele.maxValue : '');
                    variableDetails[ele.publication + "_comments"] =
                      ele.comments;
                    variableDetails[ele.publication + "_FileName"] =
                      ele.valueReportInfo?.map((el) => `${el.reportName}.pdf`)
                        .join(",");


                    variableDetailsWithOutComments[ele.publication] = ele.value ? ele.value : (ele.minValue ? ele.minValue : "") + (ele.minValue && ele.maxValue ? " - " : '') + (ele.maxValue ? ele.maxValue : '');
                    variableDetailsWithOutComments[ele.publication + "_FileName"] =
                      ele.valueReportInfo?.map((el) => `${el.reportName}.pdf`)
                        .join(",");
                    if (ele.value || ele.maxValue || ele.minValue) {
                      allComentvalue.push(ele.comments)
                      colorcodes.push(ele.isValueChanged
                        ? 'FF0800' : element.valuationType === "Guidance"
                          ? '0C2AE9' :
                          element.valuationType === 'Calculated'
                            ? '8908EE' : '0F011A')
                    }
                  });
                }
                variableDetails["QC Feedback"] = element.variableFeedback;
                variableDetails["QC Status"] = element.variableStatusName;
                variableDetailsWithOutComments["QC Feedback"] = element.variableFeedback;
                variableDetailsWithOutComments["QC Status"] = element.variableStatusName;
                excelData.push(variableDetails);
                excelDataWithOutComment.push(variableDetailsWithOutComments)
              });
              setExcelExportRows(excelDataWithOutComment);
              setexcelExportRowsWithComments(excelData)
              setexcelColorCode(colorcodes)
              setAllComent(allComentvalue)
            }
          }
        } else {
          toasterError("Something went wrong");
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
        setLoading(false);
      });
  };

  // Function for Getting Reporting Year
  // const GetReportingYear = () => {
  //   // api for get year data for year dropdown
  //   axios
  //     .get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingYear", {
  //       headers: {
  //         'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
  //       }
  //     })
  //     .then((res) => { setYearOption(res.data); })
  //     .catch((err) => ErroHandling(err, function (res) {
  //       toasterError(res)
  //     }));
  // };

  //Function for Getting Scoring Json
  const getScoringDetails = () => {
    // api for get year data for year dropdown
    axios
      .get(process.env.React_App_baseUrl + "OwnershipChangeQualityManagement/GetQCDrildownScoringParameters", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null,
          jobAssetId: JSON.parse(localStorage.asset)?.jobAssetId,
        },
      })
      .then((res) => {
        setScoringParameters(res.data);
        setOpenScore(true)
      })
      .catch((err) => ErroHandling(err, function (res) {
        toasterError(res)
      }));
  };
  const getSingleScoringDetails = () => {
    axios
      .get(process.env.React_App_baseUrl + "OwnershipChangeQualityManagement/GetQCDrildownScoringParameters", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null,
          jobAssetId: isSelectedLineItem && selectedJobId
        },
      })
      .then((res) => {
        setScoringParameters(res.data);
        setOpenScore(true)
      })
      .catch((err) => ErroHandling(err, function (res) {
        toasterError(res)
      }));

  }

  // Function for Getting Variable History Details
  // const getVariableHistoryDetails = (data, hismode, cols) => {
  //   // currentYeatData
  //   if (data != null) {
  //     setLoading(true);
  //     let allId = [];
  //     data.forEach((yid) => {
  //       if (yid.year == currentYeatData.year) {
  //         allId.push(currentYeatData.id);
  //       } else { allId.push(yid.id); }
  //     });
  //     allId = allId.toString();
  //     axios.get(process.env.React_App_baseUrl + "QualityManagement/GetVariableHistoryDetails",
  //       {
  //         headers: {
  //           jobAssetId: jobAssetId,
  //           yearId: allId,
  //           IsHistoricView: hismode ? hismode : historicData,
  //           sortColumn: changeSortColumnNameFormat(sorting.sortBy),
  //           isAscending: sorting.isAscending,
  //           'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null

  //         },
  //       }
  //     )
  //       .then((res) => {
  //         setLoading(false);
  //         if (res.status === 200) {
  //           setHistoricDataList(res.data);
  //           sortHistoryTableData(res.data);
  //           let excelData = [];
  //           if (res.data != null) {
  //             if (res.data.variablesDetails != null) {
  //               res.data.variablesDetails.forEach((element, index) => {
  //                 let variableDetails = {
  //                   LastUpdateYear: element.lastUpdateYear,
  //                   LastUpdateQuater: element.lastUpdateQuater,
  //                   VariableReport: element.variableReport,
  //                   UnitReport: element.unitReport,
  //                   VariableCRU: element.variableCru,
  //                   UnitCRU: element.unitCru,
  //                   Currency: element.currency,
  //                   VariableType: element.variableTypeName
  //                 };
  //                 if (element.reportingYear != null) {
  //                   element.reportingYear.forEach((ele, index) => {
  //                     switch (element.valuationType.toLowerCase()) {
  //                       case "guidance": {
  //                         if (ele.minValue != null && ele.maxValue != null)
  //                           variableDetails[ele.publication] = `${ele.minValue}-${ele.maxValue}`;
  //                         else if (ele.minValue != null)
  //                           variableDetails[ele.publication] = `${ele.minValue}`;
  //                         else if (ele.maxValue != null)
  //                           variableDetails[ele.publication] = `${ele.maxValue}`;
  //                         break;
  //                       }
  //                       case "actual": {
  //                         if (ele.value != null)
  //                           variableDetails[ele.publication] = ele.value;
  //                         else
  //                           variableDetails[ele.publication] = "";
  //                         break;
  //                       }
  //                       default: {
  //                         if (ele.value != null)
  //                           variableDetails[ele.publication] = ele.value;
  //                         else
  //                           variableDetails[ele.publication] = "";
  //                         break;
  //                       }
  //                     }
  //                     variableDetails[ele.publication + "_comments"] = ele.comments;
  //                     variableDetails[ele.publication + "_FileName"] = ele.valueReportInfo.map(el => `${el.reportName}.pdf`).join(',');
  //                   })
  //                 }
  //                 variableDetails["QC Feedback"] = element.variableFeedback;
  //                 variableDetails["QC Status"] = element.variableStatusName;
  //                 excelData.push(variableDetails);
  //               });
  //               setExcelExportRows(excelData);
  //             }
  //           }
  //         } else {
  //           toasterError("Something went wrong2 ");
  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         ErroHandling(err, function (res) {
  //           toasterError(res)
  //         })
  //       });
  //   }
  // };

  // Function for Open Document For View
  const openDocumentForView = (file) => {
    let sendData = {
      jobId: data.jobId,
      fileName: file.reportName + ".pdf",
      pageNumber: file.pageNo,
      crudState: "View",
    };
    CommonService.openFilePreview(sendData);
  };
  const OpenViewer = (data) => {
    setOpenAdobeViewer(true);
    setAdobeViewerData({
      jobId: data.jobId, fileName: data.fileName, pageNumber: data.pageNumber, crudState: data.crudState,
    });
  };

  // Function for Export To Excel
  const handleExportToExcel = () => {
    exportToCSV(
      excelExportRows,
      `QualityManagement_${new Date()
        .toLocaleDateString()
        .split("/")
        .join("-")}`,
      excelExportRowsWithComments
    );
  };

  const createAlphybet = (v, ws, dataWithOutComments) => {
    let commentData = {}
    let commentCount = 0
    var ordA = 'A'.charCodeAt(0);
    var ordZ = 'Z'.charCodeAt(0);
    var len = ordZ - ordA + 1;
    var s = "";
    //let n = Object.keys(v[0])?.length
    for (let index = 0; index < v.length; index++) {
      (Object.values(v[index]).map((value, ind) => {
        let commentIndex = (Object.keys(v[index]))[ind]
        if (ind > 13 && ind < (Object.keys(v[0]).length - 2) && value && value !== "" && !commentIndex.includes('_FileName')) {
          let n = ind
          while (n >= 0) {
            s = String.fromCharCode(n % len + ordA);
            n = Math.floor(n / len) - 1;
          }
          commentData[`${s + (index + 2)}`] = allComment[commentCount]
          ws[s + (index + 2)].s = {
            font: {
              color: { rgb: excelColorCode[commentCount] }
            },
          }
          commentCount = commentCount + 1
        }
      }))
    }
    Object.keys(ws).forEach(ww => {
      Object.keys(commentData).forEach((cmData) => {
        if (ww === cmData && commentData[cmData]) {
          ws[ww].c = [];
          ws[ww].c.hidden = true;
          ws[ww].c.push({ a: ws[ww].v, t: commentData[cmData], T: true });
        }
      })
    });
    return ws;
  }

  // Function for Export to CSV
  const exportToCSV = (apiData, fileName, dataWithOutComments) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    let wsData = createAlphybet(apiData, ws, dataWithOutComments)
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    excelColumns.forEach((exCol, i) => { if (exCol.includes('_Comments')) { excelColumns.splice(i, 1) } });
    XLSX.utils.sheet_add_aoa(wsData, [excelColumns], { origin: "A1" });
    const wb = { Sheets: { data: wsData }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleUpdateComments = (id, e) => {
    const mockTemp = { ...scoringParameters };
    mockTemp.parameterList.map((items, index) => {
      if (items.parameterId === id) {
        return items.comments = e;
      }
    });
    setScoringParameters(mockTemp);
  }

  const handleUpdateAchieved = (id, e) => {
    const mockTemp = { ...scoringParameters };
    if (e.target.checked) {
      mockTemp.parameterList.map((items, index) => {

        if (items.parameterId === id) {


          items.isAchieved = true;
          items.totalAchieved = items.total;
        }
      }
      )
    }
    else {
      mockTemp.parameterList.map((items, index) => {

        if (items.parameterId === id) {

          items.isAchieved = false;
          items.totalAchieved = 0;
        }


      })

    }
    var total = 0
    mockTemp.parameterList.map((items, index) => {
      total += items.totalAchieved;


    });
    mockTemp.totalAchieved = total
    var totalPercentage = (total / mockTemp.total) * 100;
    mockTemp.achievedPercentage = totalPercentage.toFixed(2);


    setScoringParameters(mockTemp);

  }

  // Function for Get Role Based Status
  const getRoleBasedStatus = (ed) => {
    var head;
    if (ed) {
      head = {
        isEdited: ed,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    } else {
      head = {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    }
    axios.get(process.env.React_App_baseUrl + "OwnershipChangeQualityManagement/GetRoleBasedStatusQCCO",
      { headers: head })
      .then((res) => {
        if (res.status === 200) { setqcStatus(res.data); }
        else { toasterError("Something went wrong"); }
      })
      .catch((err) => ErroHandling(err, function (res) {
        toasterError(res)
      }));
  };

  // Function for Save Job Asset Feedback
  // const saveJobAssetFeedback = () => {
  //   var a = [ManageQualityData.jobAssetId]
  //   FinalSave(a, "Save")
  // };

  // Function for Update Table Title
  // const updateTableTitle = (year, mode, defaultData) => {
  //   if (currentYear) {
  //     let yearPresent = year.filter((ye) => ye.year == currentYear)
  //     if (yearPresent.length < 1) { year.unshift({ year: currentYear }); }
  //   }
  //   let col;
  //   let Excelcol;
  //   if (year.length > 0) {
  //     col = [
  //       { id: "id", label: "ID", type: "Highlight", border: true, },
  //       { id: "lastUpdateYear", label: "Last Updated Year", minWidth: 110, sorting: false, border: true, },
  //       { id: "lastUpdateQuater", label: "Last Updated Quarter", minWidth: 110, sorting: false, wrap: true, border: true, },
  //       { id: "variableReport", label: "Variable (Report)", minWidth: 250, sorting: true, border: true, },
  //       { id: "unitReport", label: "Unit (Report)", sorting: true, minWidth: 140, align: "left", border: true, },
  //       { id: "variableCru", label: "Variable (CRU)", minWidth: 250, sorting: true, align: "left", border: true, },
  //       { id: "unitCru", label: "Unit (CRU)", minWidth: 140, sorting: true, align: "left", border: true, },
  //       { id: "currency", label: "Currency", minWidth: 130, sorting: false, align: "left", border: true, },
  //       { id: "variableTypeName", label: "Variable Type", minWidth: 130, sorting: false, align: "left", border: true, },
  //       { id: "variableFeedback", label: "QC Feedback", minWidth: 200, sorting: false, align: "left", border: true, },
  //       { id: "variableStatusName", label: "QC Status", minWidth: 200, sorting: false, align: "left", border: true, },
  //     ];
  //     year.forEach((ye, i) => {
  //       col.splice(col.length - 2, 0, {
  //         id: ye.year + " - " + "Q1", label: ye.year + "-" + "Q1", minWidth: 120, sorting: false,
  //         align: "center", edit: true, comment: true, colorCode: "#00519c", attatch: true,
  //         qValue: true, border: true,
  //       },
  //         {
  //           id: ye.year + " - " + "Q2", label: ye.year + "-" + "Q2", minWidth: 120, sorting: false,
  //           align: "center", edit: true, comment: true, colorCode: "#00519c", attatch: true,
  //           qValue: true, border: true,
  //         },
  //         {
  //           id: ye.year + " - " + "Q3", label: ye.year + "-" + "Q3", minWidth: 120, sorting: false,
  //           align: "center", edit: true, comment: true, colorCode: "#00519c", attatch: true,
  //           qValue: true, border: true,
  //         },
  //         {
  //           id: ye.year + " - " + "Q4", label: ye.year + "-" + "Q4", minWidth: 120, sorting: false,
  //           align: "center", edit: true, comment: true, colorCode: "#00519c", attatch: true,
  //           qValue: true, border: true,
  //         },
  //         {
  //           id: ye.year + " - " + "Annual", label: ye.year + "-" + "Annual", minWidth: 120, sorting: false,
  //           align: "center", qValue: true, border: true,
  //         }
  //       );
  //     });

  //     Excelcol = [
  //       "Last Updated Year", "Last Updated Quarter", 'Company', 'Asset', 'Commodity', 'Country',
  //       'Region', "Sub Region", "Variable (Report)", "Unit (Report)", "Variable (CRU)",
  //       "Unit (CRU)", "Currency"
  //       , "Variable Type"
  //       , "QC Feedback", "QC Status",
  //     ];
  //     year.forEach((ye, i) => {
  //       Excelcol.splice(
  //         Excelcol.length - 2, 0,
  //         ye.year + "-" + "Q1", ye.year + "-" + "Q1_Comments", ye.year + "-" + "Q1_FileName",
  //         ye.year + "-" + "Q2", ye.year + "-" + "Q2_Comments", ye.year + "-" + "Q2_FileName",
  //         ye.year + "-" + "Q3", ye.year + "-" + "Q3_Comments", ye.year + "-" + "Q3_FileName",
  //         ye.year + "-" + "Q4", ye.year + "-" + "Q4_Comments", ye.year + "-" + "Q4_FileName",
  //         ye.year + "-" + "Annual", ye.year + "-" + "Annual_Comments", ye.year + "-" + "Annual_FileName"
  //       );
  //     });
  //   } else {
  //     setDefaultCol();
  //   }
  //   setcolumns([...col]);
  //   setExcelColumns([...Excelcol]);
  //   if (mode === "default") { sortHistoryTableData(defaultData.variablesDetails); }
  //   else { }
  // };



  // Assigning Columns and UI Props
  // const setDefaultCol = () => {
  //   let col = [
  //     { id: "id", label: "ID", type: "Highlight" },
  //     { id: "lastUpdateYear", label: "Last Updated Year", minWidth: 110, sorting: false, },
  //     { id: "lastUpdateQuater", label: "Last Updated Quarter", minWidth: 110, sorting: false, },
  //     { id: "variableReport", label: "Variable (Report)", minWidth: 250, sorting: false, },
  //     { id: "unitReport", label: "Unit (Report)", sorting: false, minWidth: 120, align: "left", },
  //     { id: "variableCru", label: "Variable (CRU)", minWidth: 250, sorting: false, align: "left", },
  //     { id: "unitCru", label: "Unit (CRU)", minWidth: 140, sorting: false, align: "left", },
  //     { id: "currency", label: "Currency", minWidth: 130, sorting: false, align: "left", },
  //     { id: "variableTypeName", label: "Variable Type", minWidth: 130, sorting: false, align: "left", },
  //     { id: "q1", label: "Q1", minWidth: 200, sorting: false, align: "center", colorCode: "#00519c", attatch: true, },
  //     { id: "q2", label: "Q2", minWidth: 200, sorting: false, align: "center", colorCode: "#00519c", attatch: true, },
  //     { id: "q3", label: "Q3", minWidth: 200, sorting: false, align: "center", colorCode: "#00519c", attatch: true, },
  //     { id: "q4", label: "Q4", minWidth: 200, sorting: false, align: "center", colorCode: "#00519c", attatch: true, },
  //     { id: "cy", label: "Annual", minWidth: 200, sorting: false, align: "center", colorCode: "#00519c", attatch: true, },
  //     { id: "variableFeedback", label: "QC Feedback", minWidth: 200, maxWidth: 150, sorting: false, align: "left", },
  //     { id: "variableStatusName", label: "QC Status", minWidth: 200, maxWidth: 150, sorting: false, align: "left", },
  //   ];
  // };

  // Function for Sorting History Data
  const sortHistoryTableData = (res) => {
    if (res.length > 0) {
      let reqHeader = res[0].reportingYear.map((el) => el.publication);
      let bb = res.map((el) => {
        const publication = el.reportingYear.map((e) => {
          return {
            [e.publication]:
              el['valuationType'] === "Guidance" && e.maxValue || e.minValue
                ? (e.minValue ? e.minValue : "") + (e.minValue && e.maxValue ? " - " : '') + (e.maxValue ? e.maxValue : '')
                : e.value,
          };
        });
        return {
          ...el, ...Object.assign({}, ...publication),
        };
      });
      bb.forEach((b) => {


        b.reportingYear.forEach((rp) => {
          reqHeader.forEach((req) => {
            if (b[req] !== null && rp.publication === req) {
              b["selectedReportingYear"] = rp;
              b["variablevalueid"] = rp.variableValueId;
              b["variableComment"] = rp.comments;
              b["isEdit"] = rp.isEdit;
              b["isValueChanged"] = rp.isValueChanged;
              b["id"] = b.variablevalueid;
              b['variableValueStatusName'] = rp.variableValueStatusName;
              b.variableReport = b.valuationType === 'Guidance' ? 'Guidance - ' + b.variableReport : b.variableReport
            }
          });
        });
      });
      setRows([...bb]);
    } else { setRows([]); }
  };
  const [menuPermission, setMenuPermission] = useState({ allowDelete: true, allowEdit: true, });

  // Function for Open Edit and Delete Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") {
      setVariableDeleteItem(item); setOpenDeleteAlart(true);
    } else {
      if (mode === "Edit") { }
      setFilterState({ ...state, right: true, formData: item, mode: "action" });
    }
  };
  const openVariableEdit = (item) => { setFilterState({ ...state, right: true, formData: item, mode: "variable" }); };
  const checkboxRequired = (e, item, row) => {
    let selectedRecord = [];
    e.forEach((el) => {
      Rows.forEach((row) => { if (row.id === el) { selectedRecord.push(row); } });
    });
    SetSelected(e);
    if (e.length === Rows.length) { setIsSelected(true) }
    else { setIsSelected(false) }
    setId(selectedRecord.map(el => el.reportingYear.filter(item => item.variableValueId > 0).map(k => {
      return { id: k.variableValueId, yearId: k.reportingYearId, periodId: k.reportingPeriodId }
    })))
  };
  // const getYear = () => {
  //   axios
  //     .get(
  //       process.env.React_App_baseUrl + "DropDown/GetYears",
  //       {
  //         headers: {
  //           Authorization: CommonService.getToken()
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setYears(res.data);
  //         res.data.forEach(element => {
  //           if (selectedYearId) {
  //             if (element.id === selectedYearId) {
  //               setDefaultYearValue(element);
  //             }
  //           }
  //         });
  //         setDefaultYearValue(res.data[0]);
  //       } else {
  //         toasterError("Something went wrong");
  //       }
  //     })
  //     .catch((err) => {
  //       ErroHandling(err, function (res) {
  //         toasterError(res);
  //       });
  //     });
  // };
  return (

    <Box>
      <Grid container maxWidth justifyContent="left" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={9} className="page-title">
          <h2>Quality Control Assessment</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>
              Home
            </Link>
            <Typography>
              Manage Company Ownership Changes
            </Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/quality-management" }}>
              Quality Management
            </Link>
            <Typography color="text.primary">Quality Control Assessment</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={3}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              <Button variant="contained" color="accent2"
                startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}
                onClick={() => { history.push("/app/ownership-changes/quality-management"); }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        <Grid item xs={12}>
          <BaseLayout>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.jobShortDetails}
            >
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                    <div className={classes.shortDetails}>
                      Job ID:{" "}
                      <span className={classes.boldText}>
                        {data.jobNo}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <div className={classes.shortDetails}>
                      Job Type:{" "}
                      <span className={classes.boldText}>{data.jobType}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
                    <div className={classes.shortDetails}>
                      Job Name:{" "}
                      <span className={classes.boldText}>{data.jobName}</span>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                    <div className={classes.shortDetails}>
                      Priority:&nbsp;
                      {data.priority === "High" ? (
                        <Typography className={classes.twoValues} color="error">
                          {" "}<span className={classes.boldText}>{data.priority}</span>
                        </Typography>) : undefined}{" "}
                      {data.priority === "Low" ? (
                        <Typography className={classes.twoValues} color="orange">
                          {" "}<span className={classes.boldText}>{data.priority}</span>
                        </Typography>) : undefined}{" "}
                      {data.priority === "Medium" ? (
                        <Typography className={classes.twoValues} color="blue">
                          {" "}<span className={classes.boldText}>{data.priority}</span>
                        </Typography>) : undefined}
                      {data.priority === "Critical" ? (
                        <Typography className={classes.twoValues} color="red">
                          {" "}
                          <span className={classes.boldText}>{data.priority}</span>
                        </Typography>) : undefined}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <div className={classes.shortDetails}>
                      TAT: <span className={classes.boldText}>{data.tat}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className={classes.shortDetails}>
                      Year:{" "}
                      <>
                        {data.jobYearsDto && data.jobYearsDto.map((element, index) => {
                          return (
                            <>
                              <strong>
                                <Chip
                                  label={element.year}
                                  size="small"
                                  key={index}
                                />{" "}</strong>
                            </>
                          )
                        })}
                      </>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.shortDetails}>
                      Job Instruction:{" "}
                      <span className={classes.boldText}>{data?.instructions}</span>
                    </div>
                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ mt: 2, mb: 0 }}>
                <Grid container spacing={0}>
                  <Grid xs={12}>
                    <Typography color="text.primary" sx={{ mt: 1 }}>
                      <strong>Selected Data Points</strong>
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Grid
                      container
                      rowSpacing={0}
                      columnSpacing={1.5}
                      justifyContent="left"
                      alignItems="flex-start"
                    >
                      {AnyValueTrue && (
                        <>
                          {Object.entries(data.dataPoints).map(([key, value]) => {


                            if (value === true) {
                              const capitalizedKey = key
                                .split(/(?=[A-Z])/) // Split at capital letters
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ");
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={4}
                                  xl={3}
                                  className={classes.removeCheckMargin}
                                >
                                  <FormControlLabel sx={{ mb: 0 }}
                                    key={key}
                                    control={<Checkbox checked />}
                                    label={capitalizedKey}
                                  />
                                </Grid>
                              );
                            }
                            return null;
                          })}
                        </>
                      )}
                    </Grid>
                  </Grid> */}

                  {/* Grouping Data Points */}
                  <Grid item xs={12}>
                    <Grid
                      container
                      rowSpacing={0}
                      columnSpacing={1.5}
                      justifyContent="left"
                      alignItems="flex-start"
                    >

                      <Grid
                        item
                        xs={12}
                        className={classes.removeCheckMargin}
                      >
                        <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                          <Grid item xs={"auto"}>
                            <div className={classes.boldText} style={{ minWidth: "100px" }}>Asset Info:</div>{" "}
                          </Grid>
                          <Grid item xs={"auto"}>
                            <FormControlLabel
                              sx={{ mb: 0 }}
                              control={
                                <Checkbox checked={data.dataPoints?.lattitude === true} />
                              }
                              label="Lattitude"
                            />
                          </Grid>
                          <Grid item xs={"auto"}><FormControlLabel
                            sx={{ mb: 0 }}
                            //key={key}
                            control={
                              <Checkbox checked={data.dataPoints?.longitude === true} />
                            }
                            label="Longitude
                    "
                          /></Grid>
                          <Grid item xs={"auto"}><FormControlLabel
                            sx={{ mb: 0 }}
                            //key={key}
                            control={
                              <Checkbox checked={data.dataPoints?.operator === true} />
                            }
                            label="Operator"
                          /></Grid>

                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        className={classes.removeCheckMargin}
                      >
                        <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                          <Grid item xs={"auto"}>
                            <div className={classes.boldText} style={{ minWidth: "100px" }}>Owner Info:</div>{" "}
                          </Grid>
                          <Grid item xs={"auto"}>
                            <FormControlLabel
                              sx={{ mb: 0 }}
                              //key={key}
                              control={
                                <Checkbox checked={data.dataPoints?.owner === true} />
                              }
                              label="Owner"
                            />
                          </Grid>
                          <Grid item xs={"auto"}>
                            <FormControlLabel
                              sx={{ mb: 0 }}
                              //key={key}
                              control={
                                <Checkbox
                                  checked={data.dataPoints?.ownershipPercentage === true}
                                />
                              }
                              label="Ownership %
                    "
                            />
                          </Grid>
                          <Grid item xs={"auto"}>
                            <FormControlLabel
                              sx={{ mb: 0 }}
                              //key={key}
                              control={
                                <Checkbox
                                  checked={data.dataPoints?.ownershipDate === true}
                                />
                              }
                              label="Ownership Date"
                            />
                          </Grid>
                          <Grid item xs={"auto"}>
                            <FormControlLabel
                              sx={{ mb: 0 }}
                              //key={key}
                              control={
                                <Checkbox
                                  checked={data.dataPoints?.stockExchange === true}
                                />
                              }
                              label="Stock Exchange
                    "
                            />
                          </Grid>
                        </Grid>

                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.removeCheckMargin}
                      >
                        <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                          <Grid item xs={"auto"}>
                            <div className={classes.boldText} style={{ minWidth: "100px" }}>Joint Venture:</div>{" "}
                          </Grid>
                          <Grid item xs={"auto"}>
                            <FormControlLabel
                              sx={{ mb: 0 }}
                              //key={key}
                              control={
                                <Checkbox
                                  checked={data.dataPoints?.joinVentures === true}
                                />
                              }
                              label="Joint Ventures"
                            />
                          </Grid>
                          <Grid item xs={"auto"}>
                            <FormControlLabel
                              sx={{ mb: 0 }}
                              //key={key}
                              control={
                                <Checkbox
                                  checked={data.dataPoints?.seeThroughOwners === true}
                                />
                              }
                              label="Equity Owner"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.removeCheckMargin}
                      >
                        <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                          <Grid item xs={"auto"}>
                            <div className={classes.boldText} style={{ minWidth: "100px" }}>Updates:</div>{" "}
                          </Grid>
                          <Grid item xs={"auto"}>
                            <FormControlLabel
                              sx={{ mb: 0 }}
                              //key={key}
                              control={
                                <Checkbox checked={data.dataPoints?.updates === true} />
                              }
                              label="Updates
                    "
                            />
                          </Grid>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent="left" alignItems="self-end" sx={{ pt: 1, pb: 3 }}>
              {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>Company</Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.companyName ? ManageQualityData.companyName : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>Attributable %</Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.attibutablePercentage ? ManageQualityData.attibutablePercentage : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>Asset</Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.asset ? ManageQualityData.asset : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <div style={{ marginLeft: -10 }}>                
                  <Typography variant="body1" color={darkGrey}>
                    <Checkbox disabled checked={isAssetSegment} />
                    {" "}
                    Asset is a segment
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>
                  Commodity
                </Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.commodity ? 
                   <Tooltip title={ManageQualityData.commodity} placement="top" arrow>
                  <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "99%", display: "inline-block" }}>{ManageQualityData.commodity}</span>
                  </Tooltip> : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>
                  Country
                </Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.country ? ManageQualityData.country : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>
                  Region
                </Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.region ? ManageQualityData.region : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Typography variant="caption" display="block" gutterBottom>
                  Sub-Region
                </Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.subRegion
                    ? ManageQualityData.subRegion
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  Segment Comments
                </Typography>
                <Typography variant="body1" color={darkGrey}>
                  {ManageQualityData.segmentComments ? ManageQualityData.segmentComments : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  Link to CAT
                </Typography>
                <Typography variant="body1" color={darkGrey} noWrap>
                  <LinkIcon style={{ fontSize: 22, marginRight: "5px" }} />{" "}
                  <Link color={darkBlue} onClick={() => {
                    if (ManageQualityData.linktoCAT) {
                      window.open(ManageQualityData.linktoCAT, "_blank");
                    }
                  }}>
                    {ManageQualityData.linktoCAT ? ManageQualityData.linktoCAT : "-"}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  Link to Asset Platform
                </Typography>
                <Typography variant="body1" color={darkGrey} noWrap>
                  <LinkIcon style={{ fontSize: 22, marginRight: "5px" }} />{" "}
                  <Link color={darkBlue} onClick={() => {
                    if (ManageQualityData.linktoAssetPlatform) {
                      window.open(ManageQualityData.linktoAssetPlatform, "_blank");
                    }
                  }}>
                    {ManageQualityData.linktoAssetPlatform ? ManageQualityData.linktoAssetPlatform : "-"}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  Link to EAT
                </Typography>
                <Typography variant="body1" color={darkGrey} noWrap>
                  <LinkIcon style={{ fontSize: 22, marginRight: "5px" }} />{" "}
                  <Link color={darkBlue}
                    onClick={() => {
                      if (ManageQualityData.linktoEAT) {
                        window.open(ManageQualityData.linktoEAT, "_blank");
                      }
                    }}>
                    {ManageQualityData.linktoEAT ? ManageQualityData.linktoEAT : "-"}
                  </Link>
                </Typography>
              </Grid> */}
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={qcStatus}
                  getOptionLabel={(option) => option.statusName}
                  onChange={(event, value) => {
                    setqcFormValue({ ...qcFormValue, variableStatusId: value.id, });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="QC Status" defaultValue="Hello World" variant="standard" required={true} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={5} xl={5}>
                <TextField fullWidth variant="standard" id="outlined-required" label="QC Feedback"
                  inputProps={{ maxLength: 2000 }}
                  onChange={(event, value) => {
                    setqcFormValue({ ...qcFormValue, variableFeedback: event.target.value, });
                  }}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                <Grid container spacing={{ xs: 1, sm: 2 }} alignItems="center" justifyContent="flex-start">
                  <Grid item>
                    {/* {(access.allowEdit) ? <> */}
                    <Button variant="contained" color="accent5"
                     
                      onClick={() => { setConfirmation3(true) }}>
                      {statusAndFeedBackLoading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : ("save")}
                    </Button>
                    {/* </> : null} */}
                  </Grid>
                  <Grid item>
                    {/* {(access.allowEdit) ? <> */}
                    <Button variant="contained" color="accent3"
                      onClick={() => { history.push("/app/ownership-changes/quality-management"); }}>
                      Cancel
                    </Button>
                    {/* </> : null} */}
                  </Grid>
                  {(JSON.parse(localStorage.asset) !== null) ?
                    <Grid item>
                      <Button variant="contained" color="secondary"
                    
                        onClick={() => { getScoringDetails(); setScoringOPened(true); }}
                      >
                        Score
                      </Button>

                    </Grid>
                    : " "}
                  {(JSON.parse(localStorage.assets)?.length > 0 && isSelectedLineItem) ?
                    <Grid item>
                      <Button variant="contained"
                        disabled={disableSave}
                        color="secondary"
                        onClick={() => { getSingleScoringDetails(); setScoringOPened(true); }}
                      >
                        Score
                      </Button>

                    </Grid>
                    : " "

                  }
                </Grid>
              </Grid>

              {/* <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <MultiSelect
                  onChange={(value) => {
                    if (value.length > 0) {
                      updateTableTitle(value);
                      setSelectedYear(value);
                    } else {
                      setSelectedYear(value);
                    }
                    setReload(true)
                  }}
                  items={YearOption}
                  values={SelectedYear}
                  selectAllLabel={{ label: "Select all" }}
                  getOptionLabel={"year"}
                  placholder="Year"
                  dropLable={"year"}
                  role="year"
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Button
                  className={classes.largeButtonAuto}
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => setOpen(!open)}
                  startIcon={<TimeEntryIcon style={{ fontSize: 16 }} />}>
                  Historic Data
                </Button>
              </Grid> */}

              {/* <Grid item xs={12} sm={12} md={12} lg={2} xl={4} textAlign="right">
                <Grid container spacing={2} justifyContent="flex-end" alignItems="flex-end" >
                  <Grid item xs={12} sm={"auto"}>
                    <Tooltip title=" Replace Report's" placement="top" arrow
                      disabled={selected.length < 1 ? true : false}>
                      <IconButton color="secondary">
                        <TimeEntryIcon onClick={() => setopenAddReport(!openAddReport)} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={"auto"}>
                    <Tooltip title="Export To Excel" placement="top" arrow>
                      <IconButton color="secondary">
                        <ExportIcon onClick={handleExportToExcel} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>

            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
              sx={{ mb: 1 }}
            >
              <Grid item xs={"6"}>
                <Typography variant="h6" sx={{ m: 0 }} gutterBottom>
                  Ownership Details
                </Typography>
              </Grid>
              {/* <Grid item xs={"6"} textAlign="right">
                <Button variant="contained" onClick={bulckEditQuality} color="accent7" size="small"
                  startIcon={<EditIcon style={{ fontSize: 15 }} />}
                >
                  Edit
                </Button>
              </Grid> */}
            </Grid>

            <Grid container alignItems="center" justifyContent="center" className={classes.tableBorder}>
              {/* Assign Jobs Start Here */}
              <Grid item xs={12}>

                {/* <DataTable
                  isAllowEdit={true}
                  className={classes.removeBorder}
                  rows={Rows ? Rows : []}
                  columns={columns ? columns : []}
                  openDocumentForView={(fileName) => {
                    openDocumentForView(fileName);
                  }}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setSorting(e)
                    setReload(true)
                  }}
                  isActionButton={{
                    ...(menuPermission.allowView && {
                      view: toggleDrawer(true, "View"),
                    }),
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                  checkboxSelection={{
                    checkbox: checkboxRequired,
                  }}
                /> */}
                {/* <CustomDataTable
                  rows={Rows ? Rows : []}
                  columns={columns ? columns : []}
                  sortBy = {false}
                  openDocumentForView={openDocumentForView}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                /> */}

                <TableContainer sx={{ maxHeight: "calc(100vh - 203px)" }}>
                  <Table
                    stickyHeader
                    className={classes.historyTable}
                  >
                    <TableHead>
                      <TableRow hover>
                        <TableCell
                          key={"checkboxId"}
                          padding="checkbox"
                          className={classes.tableHeader}
                        >
                          <Checkbox
                            color="primary"
                            checked={drillDropAllChecked}
                            onChange={(event, checked) => {
                              handleChangeCheckBoxAll(event, checked)
                            }}

                          />
                        </TableCell>
                        <TableCell className={classes.tableHeader}
                          style={{ minWidth: "150px", whiteSpace: "nowrap", }} >
                          Asset
                        </TableCell>
                        <TableCell className={classes.tableHeader && classes.cruTheadLeftSep}
                          style={{ minWidth: "200px", width: "25%", whiteSpace: "nowrap", }} >
                          Commodity
                        </TableCell>
                        <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                          style={{ minWidth: "200px", width: "25%", whiteSpace: "nowrap", }} >
                          Lattitude
                        </TableCell>
                        <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                          style={{ minWidth: "200px", width: "25%", whiteSpace: "nowrap", }} >
                          Longitude
                        </TableCell>
                        <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="left"
                          style={{ minWidth: "200px", width: "13%", whiteSpace: "nowrap", }} >
                          Owner
                        </TableCell>
                        <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="left"
                          style={{ minWidth: "200px", width: "13%", whiteSpace: "nowrap", }} >
                          Operator
                        </TableCell>
                        <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                          style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                          Ownership %
                        </TableCell>
                        <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                          style={{ minWidth: "120px", width: "13%", whiteSpace: "nowrap", }} >
                          Ownership Date
                        </TableCell>
                        <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="left"
                          style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                          Stock Exchange
                        </TableCell>
                        {(() => {
                          const options = [];
                          for (let i = 1; i <= seeThroughOwners; i++) {
                            options.push(<><TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="left"
                              style={{ minWidth: "180px", width: "13%", whiteSpace: "nowrap", }} >
                              Equity Owner {i}
                            </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                                style={{ minWidth: "120px", width: "13%" }} >
                                Equity Ownership {i}%
                              </TableCell></>);
                          }

                          return options;
                        })()}
                        <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="left"
                          style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                          Status
                        </TableCell>
                        <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="left"
                          style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                          Feedback
                        </TableCell>
                        <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                          style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                          JV
                        </TableCell>
                        <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                          style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                          Update
                        </TableCell>
                        <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                          style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {qualityDrildropDownData?.map((rowItemData) => {
                        console.log(rowItemData)
                        return (
                          <TableRow hover>
                            <TableCell key={"checkboxId"}
                              padding="checkbox" className={classes.tableCell}>
                              <Checkbox
                                color="primary"
                                checked={drilldropCheckedValue.indexOf(rowItemData) > -1}
                                onChange={(e, checked) => {
                                  handleChangeCheckBoxSingle(e, rowItemData, checked)
                                }}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                              {rowItemData.assetName}
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell && classes.tableCellLeftSep}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }}  >
                              {rowItemData.commodities}
                            </TableCell>
                            <TableCell align="center" className={classes.tableCell && classes.tableCellLeftSep}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                              {rowItemData.lattitude ? rowItemData.lattitude : null}
                              {(rowItemData.lattitude > 0 && rowItemData.longitude > 0) ?
                                <Tooltip title="Open Location" placement="top" arrow>
                                  <IconButton sx={{ ml: 1 }}
                                    // size="large"
                                    color="accent6"
                                    onClick={() => {
                                      window.open(
                                        `https://www.google.com/maps/?q=${rowItemData.lattitude},${rowItemData.longitude}&z=5`,
                                        "_blank"
                                      )
                                    }
                                    }
                                  >
                                    <PlaceIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip> : <></>}
                            </TableCell>
                            <TableCell align="center" className={classes.tableCell && classes.tableCellLeftSep}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                              {rowItemData.longitude ? rowItemData.longitude : null}
                              {(rowItemData.lattitude > 0 && rowItemData.longitude) ?
                                <Tooltip title="Open Location" placement="top" arrow>
                                  <IconButton sx={{ ml: 1 }}
                                    // size="large"
                                    color="accent6"
                                    onClick={() => {
                                      window.open(
                                        `https://www.google.com/maps/?q=${rowItemData.lattitude},${rowItemData.longitude}&z=5`,
                                        "_blank"
                                      )
                                    }
                                    }
                                  >
                                    <PlaceIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip> : <></>}
                            </TableCell>

                            <TableCell align="left"
                              className={classes.tableCell && classes.tableCellLeftSep}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                              {rowItemData.ownerName}
                              {rowItemData.ownerComment ? <>
                                <Tooltip title={rowItemData.ownerComment}>
                                  <IconButton sx={{ ml: 1 }}>
                                    <CommentIcon />
                                  </IconButton>
                                </Tooltip>
                              </> : <></>}
                              {rowItemData.ownerReportDetails?.map((repoItem, index) => {
                                return (
                                  <>
                                    {repoItem.link ? <>
                                      <Tooltip
                                        title={"Link"}
                                        placement="top"
                                        arrow
                                        key={`${index}-link`}
                                      >
                                        <IconButton color="accent4">
                                          <LinkIcon
                                            color="accent4"
                                            onClick={(event) => {
                                              window.open(`${repoItem.link}`, "_blank");
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </> : <></>}
                                    {repoItem.reportName ? <>
                                      <Tooltip title="File Preview" placement="top" arrow>
                                        <IconButton color="secondary">
                                          <AttachIcon
                                            onClick={(event) => {
                                              openDocumentForView(repoItem)
                                            }
                                            }
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </> : <></>}
                                  </>
                                )
                              })
                              }
                            </TableCell>
                            <TableCell align="left"
                              className={classes.tableCell && classes.tableCellLeftSep}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                              {rowItemData.operator}
                              {rowItemData.operatorComment ? <>
                                <Tooltip title={rowItemData.operatorComment}>
                                  <IconButton sx={{ ml: 1 }}>
                                    <CommentIcon />
                                  </IconButton>
                                </Tooltip>
                              </> : <></>}
                              {rowItemData.operatorReportDetails?.map((repoItem, index) => {
                                return (
                                  <>
                                    {repoItem.link ? <>
                                      <Tooltip
                                        title={"Link"}
                                        placement="top"
                                        arrow
                                        key={`${index}-link`}
                                      >
                                        <IconButton color="accent4">
                                          <LinkIcon
                                            color="accent4"
                                            onClick={(event) => {
                                              window.open(`${repoItem.link}`, "_blank");
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </> : <></>}
                                    {repoItem.reportName ? <>
                                      <Tooltip title="File Preview" placement="top" arrow>
                                        <IconButton color="secondary">
                                          <AttachIcon
                                            onClick={(event) => {
                                              openDocumentForView(repoItem)
                                            }
                                            }
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </> : <></>}
                                  </>
                                )
                              })
                              }
                            </TableCell> <TableCell align="center"
                              className={classes.tableCell && classes.tableCellLeftSep}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                              {rowItemData.ownershipPercentage}
                              {rowItemData.ownershipComment ? <>
                                <Tooltip title={rowItemData.ownershipComment}>
                                  <IconButton sx={{ ml: 1 }}>
                                    <CommentIcon />
                                  </IconButton>
                                </Tooltip>
                              </> : <></>}
                              {rowItemData.ownershipReportDetails?.map((repoItem, index) => {
                                return (
                                  <>
                                    {repoItem.link ? <>
                                      <Tooltip
                                        title={"Link"}
                                        placement="top"
                                        arrow
                                        key={`${index}-link`}
                                      >
                                        <IconButton color="accent4">
                                          <LinkIcon
                                            color="accent4"
                                            onClick={(event) => {
                                              window.open(`${repoItem.link}`, "_blank");
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </> : <></>}
                                    {repoItem.reportName ? <>
                                      <Tooltip title="File Preview" placement="top" arrow>
                                        <IconButton color="secondary">
                                          <AttachIcon
                                            onClick={(event) => {
                                              openDocumentForView(repoItem)
                                            }
                                            }
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </> : <></>}
                                  </>
                                )
                              })
                              }

                            </TableCell> <TableCell align="center"
                              className={classes.tableCell && classes.tableCellLeftSep}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                              {rowItemData.year}
                            </TableCell>
                            <TableCell align="left"
                              className={classes.tableCell && classes.tableCellLeftSep}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                              {rowItemData.stockExchange}
                              {rowItemData.stockExchangeComment ? <>
                                <Tooltip title={rowItemData.stockExchangeComment}>
                                  <IconButton sx={{ ml: 1 }}>
                                    <CommentIcon />
                                  </IconButton>
                                </Tooltip>
                              </> : <></>}
                              {rowItemData.stockExchangeReportDetails?.map((repoItem, index) => {
                                return (
                                  <>
                                    {repoItem.link ? <>
                                      <Tooltip
                                        title={"Link"}
                                        placement="top"
                                        arrow
                                        key={`${index}-link`}
                                      >
                                        <IconButton color="accent4">
                                          <LinkIcon
                                            color="accent4"
                                            onClick={(event) => {
                                              window.open(`${repoItem.link}`, "_blank");
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </> : <></>}
                                    {repoItem.reportName ? <>
                                      <Tooltip title="File Preview" placement="top" arrow>
                                        <IconButton color="secondary">
                                          <AttachIcon
                                            onClick={(event) => {
                                              openDocumentForView(repoItem)
                                            }
                                            }
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </> : <></>}
                                  </>
                                )
                              })
                              }
                            </TableCell>

                            {(() => {
                              const options = [];

                              for (let i = 1; i <= seeThroughOwners; i++) {
                                options.push(<>  <TableCell align="left"
                                  className={classes.tableCell && classes.tableCellLeftSep}
                                  style={{ whiteSpace: "nowrap", }} >
                                  {rowItemData.getSeeThroughOwnerDetails[i - 1]?.ownerName ? <>
                                    {rowItemData.getSeeThroughOwnerDetails[i - 1]?.ownerName}</> : <>-</>}
                                  {rowItemData.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnerComments ?
                                    <Tooltip title={rowItemData.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnerComments}>
                                      <IconButton>
                                        <CommentIcon />
                                      </IconButton>
                                    </Tooltip> : <></>}
                                  {rowItemData.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnerReportDetails?.map((seeThroughOwnerItem, index) => {
                                    return (
                                      <>
                                        {seeThroughOwnerItem.link ? <>
                                          <Tooltip
                                            title={"Link"}
                                            placement="top"
                                            arrow
                                            key={`${index}-link`}
                                          >
                                            <IconButton color="accent4">
                                              <LinkIcon
                                                color="accent4"
                                                onClick={(event) => {
                                                  window.open(`${seeThroughOwnerItem.link}`, "_blank");
                                                }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </> : <></>}
                                        {seeThroughOwnerItem.reportName ? <>
                                          <Tooltip title="File Preview" placement="top" arrow>
                                            <IconButton color="secondary">
                                              <AttachIcon
                                                onClick={(event) => {
                                                  openDocumentForView(seeThroughOwnerItem)
                                                }
                                                }
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </> : <></>}

                                      </>
                                    )
                                  })}

                                </TableCell>
                                  <TableCell align="center"
                                    className={classes.tableCell && classes.tableCellLeftSep}
                                    style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                                    {rowItemData.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnerPercentage ? <>
                                      {rowItemData.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnerPercentage}
                                    </> : <>-</>}
                                    {rowItemData.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnershipComments ? <>
                                      <Tooltip title={rowItemData.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnershipComments}>
                                        <IconButton>
                                          <CommentIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </> : <></>}
                                    {rowItemData.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnershipPercentageReportDetails?.map((seeThroughOwnershipItem, index) => {
                                      return (
                                        <>
                                          {seeThroughOwnershipItem.link ? <>
                                            <Tooltip
                                              title={"Link"}
                                              placement="top"
                                              arrow
                                              key={`${index}-link`}
                                            >
                                              <IconButton color="accent4">
                                                <LinkIcon
                                                  color="accent4"
                                                  onClick={(event) => {
                                                    window.open(`${seeThroughOwnershipItem.link}`, "_blank");
                                                  }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </> : <></>}
                                          {seeThroughOwnershipItem.reportName ? <>
                                            <Tooltip title="File Preview" placement="top" arrow>
                                              <IconButton color="secondary">
                                                <AttachIcon
                                                  onClick={(event) => {
                                                    openDocumentForView(seeThroughOwnershipItem)
                                                  }
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </> : <></>}
                                        </>
                                      )
                                    })}
                                  </TableCell></>);
                              }

                              return options;
                            })()}
                            <TableCell align="center"
                              className={classes.tableCell && classes.tableCellLeftSep}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                              {rowItemData.status}
                            </TableCell>
                            <TableCell align="left"
                              className={classes.tableCell && classes.tableCellLeftSep}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                              {rowItemData.feedback}
                            </TableCell>
                            <TableCell align="center"
                              className={classes.tableCell && classes.tableCellLeftSep}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                              {rowItemData.jointVentureView && !checkStatus(rowItemData.statusName) ?
                                <TooltipIcon
                                  title="View"
                                  placement="top"
                                  color="primary"
                                  onClick={(e) => {
                                    GetJointVentureDetails(rowItemData.jobAssetId)
                                    setLiveJvId(rowItemData.jobAssetId)
                                    setOpenJointventure(true)
                                  }}
                                >
                                  <ViewIcon style={{ fontSize: "20px", color: "#DB9400" }} />
                                </TooltipIcon> : <></>}
                            </TableCell>
                            <TableCell align="center"
                              className={classes.tableCell && classes.tableCellLeftSep}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                              {rowItemData.updateView && !checkStatus(rowItemData.statusName) ?
                                <TooltipIcon
                                  title="View"
                                  placement="top"
                                  color="primary"
                                  onClick={(e) => {
                                    GetUpdateDetails(rowItemData.jobAssetId)
                                    setLiveUpdateId(rowItemData.jobAssetId)
                                    setOpenUpdate(true)
                                  }}
                                >
                                  <ViewIcon style={{ fontSize: "20px", color: "#DB9400" }} />
                                </TooltipIcon> : <></>}
                            </TableCell>
                            <TableCell align="center"
                              className={classes.tableCell && classes.tableCellLeftSep}
                              style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                              {rowItemData.isEditable && !checkStatus(rowItemData.statusName) ?
                                <TooltipIcon
                                  title="Edit"
                                  placement="top"
                                  onClick={(e) => {
                                    setFinalValues([rowItemData.id])
                                    setIsOpenForm(true)
                                    setEditState("Owner Info - QC Status Update")

                                  }}
                                >
                                  <EditIcon style={{ fontSize: "20px", color: "#333842" }} />
                                </TooltipIcon> : <></>}
                              {!checkStatus(rowItemData.statusName) ?
                                <><TooltipIcon title="View" placement="top" color="primary">
                                  <ViewIcon
                                    style={{ fontSize: "20px", color: "#DB9400" }}
                                    onClick={() => {
                                      history.push({
                                        pathname: "/app/ownership-changes/update-record",
                                        state: {
                                          jobId: rowItemData.jobId,
                                          jobAssetId: rowItemData.jobAssetId,
                                          jobNo: rowItemData.jobNo,
                                          statusName: rowItemData.statusName,
                                          isQualityPage:true
                                        },
                                      });

                                      //console.log(history.state);
                                    }}
                                  />
                                </TooltipIcon>
                                  <Tooltip title="Delete"
                                    placement="top" arrow >
                                    <IconButton aria-label="Delete" sx={{ color: "red" }}>
                                      <DeleteIcon className={classes.iconSize} onClick={() => {
                                        setConfirmation(true)
                                        setDeleteQcId(rowItemData.jobAssetId)
                                      }} />
                                    </IconButton>
                                  </Tooltip></> : <></>}
                            </TableCell>
                          </TableRow>
                        )
                      })}

                    </TableBody>


                  </Table>
                </TableContainer>
              </Grid>



              {/* <Grid container spacing={{ xs: 1, sm: 2 }} sx={{ p: 2 }} alignItems="center" justifyContent="flex-end">
                <Grid item>
                  <Button variant="contained"
                    style={{ "background-color": currentTableDataIndex === 0 ? "#EBFBFF" : "#DB9400", }}
                    size="small"
                    disabled={currentTableDataIndex === 0 ? true : false}
                    onClick={(e) => {
                      //handleNextAndPrevious("pre");
                    }}
                    startIcon={<ChevronLeftIcon style={{ fontSize: 15 }} />}>
                    Prev
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" style={{
                    "background-color":
                      allTableRecord && currentTableDataIndex === allTableRecord.length - 1
                        ? "#EBFBFF" : "#DB9400",
                  }}
                    size="small"
                    disabled={
                      allTableRecord && currentTableDataIndex === allTableRecord.length - 1
                        ? true : false
                    }
                    onClick={(e) => {
                      // handleNextAndPrevious("next");
                    }}
                    endIcon={<ChevronRightIcon style={{ fontSize: 15 }} />}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid> */}

              {/* Filter Assign Jobs Start Here */}
              <SideDrawer
                formComp={
                  <EditSideDeawer
                    state={filterState}
                    setState={setFilterState}
                    jobId={ManageQualityData.jobId}
                    propsData={jobAssetId}
                    reload={() => {
                      setReload(true);
                    }}
                  />
                }
                setReload={() => { }}
                state={filterState}
                setState={setFilterState}
              />

              {/* Filter Assign Jobs End Here */}
            </Grid>
            <ScrollingDialog
              open={open}
              setOpen={setOpen}
              scroll={scroll}
              setScroll={setScroll}
              dialogClass="darkTitle"
              dialogTitle="Historic Data"
              dialogContent={
                <HistoricData
                  historyData={null}
                  historyColumns={null}
                  reportData={ManageQualityData}
                  variableReportId={
                    ManageQualityData
                      ? ManageQualityData.variablesDetails.variableReportId
                      : null
                  }
                  isOverallInfo={false}
                  jobAssetId={JSON.parse(localStorage.asset)?.jobAssetId ? JSON.parse(localStorage.asset).jobAssetId : JSON.parse(localStorage.assets)}
                  jobID={ManageQualityData ? ManageQualityData.jobId : null}
                />
              }
            />
            <AlertDialog
              open={openDeleteAlart}
              title={"Confirmation"}
              maxWidth={"sm"}
              description={`Are you sure want to delete Report ${VariableDeleteItem?.variableReport} ?`}
              action={
                <>
                  <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                    <Button
                      onClick={() => {
                        setOpenDeleteAlart(false);
                        setVariableDeleteItem(null);
                      }}
                    >
                      No
                    </Button>
                    {deleteBtnLoadinng ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Button color="secondary" onClick={deleteVariableReport}>
                        Yes
                      </Button>
                    )}
                  </div>
                </>
              }
            />
            {openAddReport ? (
              <AddReport
                open={openAddReport}
                jobAssetId={jobAssetId}
                jobId={ManageQualityData.jobId}
                selectedRow={id}
                setopenAddReport={() => setopenAddReport(!openAddReport)}
                reload={() => {
                  setReload(true)
                }}
              />
            ) : null}
          </BaseLayout>

          <ScrollingDialog
            open={openAdobeViewer}
            setOpen={setOpenAdobeViewer}
            // scroll={scroll}
            // setScroll={setScroll}
            dialogClass="darkTitle"
            dialogTitle="File Preview"
            dialogContent={
              <AdobeViewer
                jobId={adobeViewerData.jobId}
                fileName={adobeViewerData.fileName}
                pageNumber={adobeViewerData.pageNumber}
                crudState={adobeViewerData.crudState}
              ></AdobeViewer>
            }
          />
        </Grid>
        <Grid item xs={12}>
          {isOpenForm && (
            <FormDialog
              isOpenAssign={isOpenForm}
              onClose={() => { setIsOpenForm(false) }}
              setIsOpenAssign={setIsOpenForm}
              title={editState}
              content={
                <>
                  <Box sx={{ height: 140 }}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={qcStatus}
                          getOptionLabel={(option) => option.statusName}
                          onChange={(event, value) => {
                            setqcFormValue({ ...qcFormValue, variableStatusId: value.id, });
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="QC Status" variant="standard" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField fullWidth variant="standard" id="outlined-required" label="QC Feedback"
                          inputProps={{ maxLength: 2000 }}
                          onChange={(event, value) => {
                            setqcFormValue({ ...qcFormValue, variableFeedback: event.target.value, });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </>
              }
              action={
                <Grid container spacing={2} justifyContent="right" style={{ marginTop: "15px" }}>
                  <Grid item xs={'auto'}>
                    <Button onClick={() => {

                      setIsOpenForm(false)
                    }}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={'auto'}>
                    <Button onClick={() => {
                      FinalSave(FinalValues, editState)
                    }} color="secondary">Update</Button>
                  </Grid>
                </Grid>
              }
            />
          )}
        </Grid>
      </Grid >
      {
        addOpenScore ? (
          <Dialog
            open={true}
            fullWidth
            // onClose={handleCloseLegal}
            maxWidth={"lg"}
          >
            <DialogTitle>

              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={"4"}>
                  Score
                </Grid>

              </Grid>
            </DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
              <Grid item xs={12}>
                <Box className={classes.setBorder}>
                  <TableContainer sx={{ maxHeight: "calc(100vh - 203px)" }}>
                    <Table
                      stickyHeader
                      // className={classes.table}
                      className={classes.historyTable}
                    >
                      <TableHead>
                        <TableRow hover>
                          <TableCell className={classes.tableHeader}
                            style={{ minWidth: "300px", whiteSpace: "nowrap", }} >
                            Parameter Name
                          </TableCell>
                          <TableCell className={classes.tableHeader && classes.cruTheadLeftSep}
                            style={{ minWidth: "200px", width: "25%", whiteSpace: "nowrap", }} >
                            Parameter Type
                          </TableCell>
                          <TableCell className={classes.tableHeader && classes.cruTheadLeftSep}
                            style={{ minWidth: "200px", width: "25%", whiteSpace: "nowrap", }} >
                            Comments
                          </TableCell>
                          <TableCell className={classes.tableHeader && classes.cruTheadLeftSep}
                            style={{ minWidth: "150px", width: "15%", whiteSpace: "nowrap", }} >
                            Achieved(No/Yes)
                          </TableCell>
                          <TableCell className={classes.tableHeader && classes.cruTheadLeftSep}
                            style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                            Total Score
                          </TableCell>
                          <TableCell className={classes.tableHeader && classes.cruTheadLeftSep}
                            style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                            Achieved
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {

                          scoringParameters.parameterList.length > 0 ?
                            scoringParameters.parameterList?.map((item, index) => (

                              <TableRow key={index} hover>
                                <TableCell className={classes.tableCell}>{item.parameterName}</TableCell>
                                <TableCell className={classes.tableCell && classes.tableCellLeftSep}>{item.parameterType}</TableCell>
                                {/* </Link> */}
                                <TableCell align="center" className={classes.tableCell && classes.tableCellLeftSep}>
                                  <FormControlLabel control={<TextField
                                    variant="standard"
                                    size="small"
                                    fullWidth
                                    label="Scoring Comments"
                                    placeholder="Comments"
                                    value={item.comments}
                                    onChange={(e) =>
                                      handleUpdateComments(item.parameterId, e.target.value)
                                    }
                                  />} label="" sx={{ m: 0, p: 0 }} />
                                </TableCell>


                                <TableCell align="center" className={classes.tableCell && classes.tableCellLeftSep}>
                                  <FormControlLabel control={<Switch color="secondary" checked={item.isAchieved} onChange={(e) => { handleUpdateAchieved(item.parameterId, e) }} />} label="" sx={{ m: 0, p: 0 }} />
                                </TableCell>

                                <TableCell className={classes.tableCell && classes.tableCellLeftSep}>{item.total}</TableCell>
                                <TableCell className={classes.tableCell && classes.tableCellLeftSep}>{item.totalAchieved}</TableCell>
                              </TableRow>
                            )) :
                            (<TableRow>
                              <TableCell className={classes.tableCell} align="center" colSpan={99}>
                                <EmptyState
                                  icon={
                                    <FileOffIcon style={{ color: "#db9400", fontSize: "40px" }} />
                                  }
                                  title="No Data Found"
                                />
                              </TableCell>
                            </TableRow>)}
                      </TableBody>

                      {/* <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={99}>
                          <EmptyState
                            icon={
                              <FileOffIcon
                                style={{ color: "#db9400", fontSize: "40px" }}
                              />
                            }
                            title="No Data Found"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody> */}
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </DialogContent>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              mt={2}
              sx={{ pr: 5 }}
            >
              <Grid item xs={"auto"}>
                <Typography variant="subtitle2">
                  Total Score:{" "}
                  <span className={classes.totalRecord}>
                    {" "}
                    <strong>{scoringParameters.total}</strong>
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={"auto"}>
                <Typography variant="subtitle2">
                  Total Achieved:{" "}
                  <span className={classes.totalRecord}>
                    {" "}
                    <strong>{scoringParameters.totalAchieved}</strong>
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={"auto"}>
                <Typography variant="subtitle2">
                  Achieved Quality (%):{" "}
                  <span className={classes.totalRecord}>
                    {" "}
                    <strong>{scoringParameters.achievedPercentage}{"%"}</strong>
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <DialogActions sx={{ mt: 1, mr: 2 }}>
              <Button onClick={handleSubmit}>Submit</Button>
              <Button onClick={handleCloseLegal}>Cancel</Button>
            </DialogActions>
          </Dialog>
        ) : null}

      {
        openJoinVenture ? (
          <>
            <ScrollingDialog
              open={openJoinVenture}
              setOpen={setOpenJointventure}
              scroll={scroll}
              setScroll={setScroll}
              dialogClass="darkTitle"
              dialogTitle="View Joint Venture"
              dialogContent={
                <>
                  <Grid item xs={12}>
                    <Box className={classes.setBorder}>
                      <TableContainer sx={{ maxHeight: "calc(100vh - 203px)" }}>
                        <Table
                          stickyHeader
                          // className={classes.table}
                          className={classes.historyTable}
                        >
                          <TableHead>
                            <TableRow hover>
                              <TableCell
                                key={"checkboxId"}
                                padding="checkbox"
                                className={classes.tableHeader}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={jointVentureAllChecked}
                                  onChange={(event) => {
                                    handleChangeJVCheckBoxAll(event)
                                  }}

                                />
                              </TableCell>
                              <TableCell className={classes.tableHeader}
                                style={{ minWidth: "100px", whiteSpace: "nowrap", }} >
                                From Year
                              </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep}
                                style={{ minWidth: "150px", width: "25%", whiteSpace: "nowrap", }} >
                                Asset Name
                              </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                                style={{ minWidth: "250px", width: "35%", whiteSpace: "nowrap", }} >
                                Company to included (Format name)
                              </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                                style={{ minWidth: "200px", width: "13%", whiteSpace: "nowrap", }} >
                                Ownership %
                              </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                                style={{ minWidth: "200px", width: "13%", whiteSpace: "nowrap", }} >
                                Stock Exchange
                              </TableCell>
                              {(() => {
                                const options = [];
                                for (let i = 1; i <= seeThroughOwnersForJv; i++) {
                                  options.push(<><TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                                    style={{ minWidth: "200px", width: "13%", whiteSpace: "nowrap", }} >
                                    Equity Owner {i}
                                  </TableCell>
                                    <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                                      style={{ minWidth: "150px", width: "13%", whiteSpace: "nowrap", }} >
                                      Equity Ownership {i}%
                                    </TableCell></>);
                                }

                                return options;
                              })()}
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="left"
                                style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                                Status
                              </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="left"
                                style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                                Feedback
                              </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                                style={{ minWidth: "100px", width: "100px", whiteSpace: "nowrap", }} >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {jointVentureData?.map((jvItem) => {
                              return (
                                <TableRow hover>
                                  <TableCell key={"checkboxId"}
                                    padding="checkbox" className={classes.tableCell}>
                                    <Checkbox
                                      color="primary"
                                      checked={jointVentureCheckedValue.indexOf(jvItem) > -1}
                                      onChange={(e) => {
                                        handleChangeJVCheckBoxSingle(e, jvItem)
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="left" className={classes.tableCell}>
                                    {jvItem.year}
                                  </TableCell>
                                  <TableCell align="left" className={classes.tableCell && classes.tableCellLeftSep} >
                                    {jvItem.assetName}
                                  </TableCell>

                                  <TableCell align="right" className={classes.tableCell && classes.tableCellLeftSep}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {jvItem.ownerName}
                                    {jvItem.ownerComment ? <>
                                      <Tooltip title={jvItem.ownerComment}>
                                        <IconButton sx={{ ml: 1 }}>
                                          <CommentIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </> : <span className={classes.overviewIcons}>-</span>}
                                    {jvItem.ownerReportDetails?.map((repoItem, index) => {
                                      return (
                                        <>
                                          {repoItem.link ? <>
                                            <Tooltip
                                              title={"Link"}
                                              placement="top"
                                              arrow
                                              key={`${index}-link`}
                                            >
                                              <IconButton color="accent4">
                                                <LinkIcon
                                                  color="accent4"
                                                  onClick={(event) => {
                                                    window.open(`${repoItem.link}`, "_blank");
                                                  }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </> : <span className={classes.overviewIcons}>-</span>}
                                          {repoItem.reportName ? <>
                                            <Tooltip title="File Preview" placement="top" arrow>
                                              <IconButton color="secondary">
                                                <AttachIcon
                                                  onClick={(event) => {
                                                    openDocumentForView(repoItem)
                                                  }
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </> : <></>}
                                        </>
                                      )
                                    })
                                    }
                                  </TableCell>
                                  <TableCell align="right" className={classes.tableCell && classes.tableCellLeftSep}>
                                    {jvItem.ownershipPercentage}
                                    {jvItem.ownershipComment ? <>
                                      <Tooltip title={jvItem.ownershipComment}>
                                        <IconButton sx={{ ml: 1 }}>
                                          <CommentIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </> : <span className={classes.overviewIcons}>-</span>}
                                    {jvItem.ownershipReportDetails?.map((repoItem, index) => {
                                      return (
                                        <>
                                          {repoItem.link ? <>
                                            <Tooltip
                                              title={"Link"}
                                              placement="top"
                                              arrow
                                              key={`${index}-link`}
                                            >
                                              <IconButton color="accent4">
                                                <LinkIcon
                                                  color="accent4"
                                                  onClick={(event) => {
                                                    window.open(`${repoItem.link}`, "_blank");
                                                  }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </> : <span className={classes.overviewIcons}>-</span>}
                                          {repoItem.reportName ? <>
                                            <Tooltip title="File Preview" placement="top" arrow>
                                              <IconButton color="secondary">
                                                <AttachIcon
                                                  onClick={(event) => {
                                                    openDocumentForView(repoItem)
                                                  }
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </> : <span className={classes.overviewIcons}>-</span>}
                                        </>
                                      )
                                    })
                                    }
                                  </TableCell>
                                  <TableCell align="right" className={classes.tableCell && classes.tableCellLeftSep}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {jvItem.stockExchange}
                                    {jvItem.stockExchangeComment ? <>
                                      <Tooltip title={jvItem.stockExchangeComment}>
                                        <IconButton sx={{ ml: 1 }}>
                                          <CommentIcon />
                                        </IconButton>
                                      </Tooltip></> : <span className={classes.overviewIcons}>-</span>}
                                    {jvItem.stockExchangeReportDetails?.map((repoItem, index) => {
                                      return (
                                        <>
                                          {repoItem.link ? <>
                                            <Tooltip
                                              title={"Link"}
                                              placement="top"
                                              arrow
                                              key={`${index}-link`}
                                            >
                                              <IconButton color="accent4">
                                                <LinkIcon
                                                  color="accent4"
                                                  onClick={(event) => {
                                                    window.open(`${repoItem.link}`, "_blank");
                                                  }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </> : <span className={classes.overviewIcons}>-</span>}
                                          {repoItem.reportName ? <>
                                            <Tooltip title="File Preview" placement="top" arrow>
                                              <IconButton color="secondary">
                                                <AttachIcon
                                                  onClick={(event) => {
                                                    openDocumentForView(repoItem)
                                                  }
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </> : <span className={classes.overviewIcons}>-</span>}
                                        </>
                                      )
                                    })
                                    }

                                  </TableCell>
                                  {(() => {
                                    const options = [];

                                    for (let i = 1; i <= seeThroughOwnersForJv; i++) {
                                      options.push(<>  <TableCell align="right"
                                        className={classes.tableCell && classes.tableCellLeftSep}
                                        style={{ whiteSpace: "nowrap" }}>
                                        {jvItem.getSeeThroughOwnerDetails[i - 1]?.ownerName ? <>
                                          {jvItem.getSeeThroughOwnerDetails[i - 1]?.ownerName}</> : <span className={classes.overviewIcons}>-</span>}
                                        {jvItem.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnerComments ?
                                          <Tooltip title={jvItem.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnerComments}>
                                            <IconButton sx={{ ml: 1 }}>
                                              <CommentIcon />
                                            </IconButton>
                                          </Tooltip> : <span className={classes.overviewIcons}>-</span>}
                                        {jvItem.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnerReportDetails?.map((seeThroughOwnerItem, index) => {
                                          return (
                                            <>
                                              {seeThroughOwnerItem.link ? <>
                                                <Tooltip
                                                  title={"Link"}
                                                  placement="top"
                                                  arrow
                                                  key={`${index}-link`}
                                                >
                                                  <IconButton color="accent4">
                                                    <LinkIcon
                                                      color="accent4"
                                                      onClick={(event) => {
                                                        window.open(`${seeThroughOwnerItem.link}`, "_blank");
                                                      }}
                                                    />
                                                  </IconButton>
                                                </Tooltip>
                                              </> : <span className={classes.overviewIcons}>-</span>}
                                              {seeThroughOwnerItem.reportName ? <>
                                                <Tooltip title="File Preview" placement="top" arrow>
                                                  <IconButton color="secondary">
                                                    <AttachIcon
                                                      onClick={(event) => {
                                                        openDocumentForView(seeThroughOwnerItem)
                                                      }
                                                      }
                                                    />
                                                  </IconButton>
                                                </Tooltip>
                                              </> : <span className={classes.overviewIcons}>-</span>}

                                            </>
                                          )
                                        })}

                                      </TableCell>
                                        <TableCell align="right"
                                          className={classes.tableCell && classes.tableCellLeftSep}
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          {jvItem.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnerPercentage ? <>
                                            {jvItem.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnerPercentage}
                                          </> : <span className={classes.overviewIcons}>-</span>}
                                          {jvItem.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnershipComments ? <>
                                            <Tooltip title={jvItem.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnershipComments}>
                                              <IconButton sx={{ ml: 1 }}>
                                                <CommentIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </> : <span className={classes.overviewIcons}>-</span>}
                                          {jvItem.getSeeThroughOwnerDetails[i - 1]?.seeThroughOwnershipPercentageReportDetails?.map((seeThroughOwnershipItem, index) => {
                                            return (
                                              <>
                                                {seeThroughOwnershipItem.link ? <>
                                                  <Tooltip
                                                    title={"Link"}
                                                    placement="top"
                                                    arrow
                                                    key={`${index}-link`}
                                                  >
                                                    <IconButton color="accent4">
                                                      <LinkIcon
                                                        color="accent4"
                                                        onClick={(event) => {
                                                          window.open(`${seeThroughOwnershipItem.link}`, "_blank");
                                                        }}
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                </> : <span className={classes.overviewIcons}>-</span>}
                                                {seeThroughOwnershipItem.reportName ? <>
                                                  <Tooltip title="File Preview" placement="top" arrow>
                                                    <IconButton color="secondary">
                                                      <AttachIcon
                                                        onClick={(event) => {
                                                          openDocumentForView(seeThroughOwnershipItem)
                                                        }
                                                        }
                                                      />
                                                    </IconButton>
                                                  </Tooltip>
                                                </> : <span className={classes.overviewIcons}>-</span>}
                                              </>
                                            )
                                          })}
                                        </TableCell></>);
                                    }

                                    return options;
                                  })()}
                                  <TableCell align="left" className={classes.tableCell && classes.tableCellLeftSep}
                                    style={{ whiteSpace: "nowrap" }}>
                                    {jvItem.status}
                                  </TableCell>
                                  <TableCell align="left" className={classes.tableCell && classes.tableCellLeftSep}
                                    style={{ whiteSpace: "nowrap" }}>
                                    {jvItem.feedback}
                                  </TableCell>
                                  <TableCell align="center"
                                    className={classes.tableCell && classes.tableCellLeftSep}>
                                    <TooltipIcon
                                      title="Edit"
                                      placement="top"
                                      onClick={(e) => {
                                        setFinalValues([jvItem.id])
                                        setIsOpenForm(true)
                                        setEditState("Joint Venture Info - QC Status Update")

                                      }}
                                    >
                                      <EditIcon style={{ fontSize: "20px", color: "#333842" }} />
                                    </TooltipIcon>
                                    <Tooltip title="Delete"
                                      placement="top" arrow >
                                      <IconButton aria-label="Delete" sx={{ color: "red" }}>
                                        <DeleteIcon className={classes.iconSize} onClick={() => { setConfirmation2(true); setDeleteJvId(jvItem.id) }} />
                                      </IconButton >
                                    </Tooltip >
                                  </TableCell >
                                </TableRow >
                              )
                            })}

                          </TableBody >
                          {/* <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={99}>
                          <EmptyState
                            icon={
                              <FileOffIcon
                                style={{ color: "#db9400", fontSize: "40px" }}
                              />
                            }
                            title="No Data Found"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody> */}
                        </Table >
                      </TableContainer >
                    </Box >
                  </Grid >
                  <AlertDialog
                    id="alert-delete"
                    open={confirmation2}
                    onClose={handleADClose2}
                    title={"Confirmation"}
                    maxWidth={"sm"}
                    description="Are you sure you want to delete?"
                    action={
                      <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                        <Grid container spacing={2} justifyContent="left">
                          <Grid item xs={'auto'}>
                            <Button onClick={handleADClose2}>No</Button>
                          </Grid>
                          <Grid item xs={'auto'}>
                            <Button color="secondary" onClick={() => { deleteJointVenture() }}>Yes</Button>
                          </Grid>
                        </Grid>
                      </div>
                    }
                  />
                </>
              }
            />
          </>
        ) : null
      }

      {
        openUpdate ? (

          <>
            <ScrollingDialog
              open={openUpdate}
              setOpen={setOpenUpdate}
              scroll={scroll}
              setScroll={setScroll}
              dialogClass="darkTitle"
              dialogTitle="View Update"
              dialogContent={
                <>
                  <Grid item xs={12}>
                    <Box className={classes.setBorder}>
                      <TableContainer sx={{ maxHeight: "calc(100vh - 203px)" }}>
                        <Table
                          stickyHeader
                          // className={classes.table}
                          className={classes.historyTable}
                        >
                          <TableHead>
                            <TableRow hover>
                              <TableCell
                                key={"checkboxId"}
                                padding="checkbox"
                                className={classes.tableHeader}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={updateAllChecked}
                                  onChange={(event) => {
                                    handleChangeUpdateCheckBoxAll(event)
                                  }}

                                />
                              </TableCell>
                              <TableCell className={classes.tableHeader}
                                style={{ minWidth: "200px", whiteSpace: "nowrap", }} >
                                Company
                              </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep}
                                style={{ minWidth: "200px", width: "25%", whiteSpace: "nowrap", }} >
                                Asset Name
                              </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="left"
                                style={{ minWidth: "150px", width: "15%", whiteSpace: "nowrap", }} >
                                Commodity
                              </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                                style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                                Year
                              </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="left"
                                style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                                Type of change
                              </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="left"
                                style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                                Status
                              </TableCell>  <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="left"
                                style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                                Feedback
                              </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                                style={{ minWidth: "100px", width: "13%", whiteSpace: "nowrap", }} >
                                Comment & URL
                              </TableCell>
                              <TableCell className={classes.tableHeader && classes.cruTheadLeftSep} align="center"
                                style={{ minWidth: "100px", width: "100px", whiteSpace: "nowrap", }} >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {updateData?.map((updateItem) => {
                              return (
                                <TableRow hover>
                                  <TableCell key={"checkboxId"}
                                    padding="checkbox" className={classes.tableCell}>
                                    <Checkbox
                                      color="primary"
                                      checked={updateCheckedValue.indexOf(updateItem) > -1}
                                      onChange={(e) => {
                                        handleChangeUpdateCheckBoxSingle(e, updateItem)
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="left" className={classes.tableCell}
                                    style={{ minWidth: "150px", whiteSpace: "nowrap", }}>
                                    {updateItem.ownerName}
                                  </TableCell>
                                  <TableCell align="left" className={classes.tableCell && classes.tableCellLeftSep}
                                    style={{ minWidth: "150px", whiteSpace: "nowrap", }}>
                                    {updateItem.assetName}
                                  </TableCell>

                                  <TableCell align="left" className={classes.tableCell && classes.tableCellLeftSep}
                                    style={{ minWidth: "150px", whiteSpace: "nowrap", }}>
                                    {updateItem.commodities}
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableCell && classes.tableCellLeftSep}
                                    style={{ minWidth: "150px", whiteSpace: "nowrap", }}>
                                    {updateItem.year}
                                  </TableCell>
                                  <TableCell align="left" className={classes.tableCell && classes.tableCellLeftSep}
                                    style={{ minWidth: "150px", whiteSpace: "nowrap", }}>
                                    {updateItem.changeType}
                                  </TableCell>
                                  <TableCell align="left" className={classes.tableCell && classes.tableCellLeftSep}
                                    style={{ minWidth: "150px", whiteSpace: "nowrap", }}>
                                    {updateItem.status}
                                  </TableCell>   <TableCell align="left" className={classes.tableCell && classes.tableCellLeftSep}
                                    style={{ minWidth: "150px", whiteSpace: "nowrap", }}>
                                    {updateItem.feedback}
                                  </TableCell>
                                  <TableCell align="right" className={classes.tableCell && classes.tableCellLeftSep}
                                    style={{ minWidth: "150px", whiteSpace: "nowrap", }}>
                                    {updateItem.comment ?
                                      <Tooltip title={updateItem.comment}>
                                        <IconButton>
                                          <CommentIcon />
                                        </IconButton>
                                      </Tooltip> : <span className={classes.overviewIcons}>-</span>}
                                    {updateItem.reportDetails?.map((repoItem, index) => {
                                      return (
                                        <>
                                          {repoItem.link ? <>
                                            <Tooltip
                                              title={"Link"}
                                              placement="top"
                                              arrow
                                              key={`${index}-link`}
                                            >
                                              <IconButton color="accent4">
                                                <LinkIcon
                                                  color="accent4"
                                                  onClick={(event) => {
                                                    window.open(`${repoItem.link}`, "_blank");
                                                  }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </> : <span className={classes.overviewIcons}>-</span>}
                                          {repoItem.reportName ? <>
                                            <Tooltip title="File Preview" placement="top" arrow>
                                              <IconButton color="secondary">
                                                <AttachIcon
                                                  onClick={(event) => {
                                                    openDocumentForView(repoItem)
                                                  }
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </> : <span className={classes.overviewIcons}>-</span>}
                                        </>
                                      )
                                    })
                                    }
                                  </TableCell>
                                  <TableCell align="center"
                                    className={classes.tableCell && classes.tableCellLeftSep}>
                                    <TooltipIcon
                                      title="Edit"
                                      placement="top"
                                      onClick={(e) => {
                                        setFinalValues([updateItem.updateId])
                                        setIsOpenForm(true)
                                        setEditState("Update Info - QC Status")
                                      }}
                                    >
                                      <EditIcon style={{ fontSize: "20px", color: "#333842" }} />
                                    </TooltipIcon>
                                    <Tooltip title="Delete"
                                      placement="top" arrow >
                                      <IconButton aria-label="Delete" sx={{ color: "red" }}>
                                        <DeleteIcon className={classes.iconSize} onClick={() => { setConfirmation1(true); setDeleteUpdateId(updateItem.updateId) }} />
                                      </IconButton >
                                    </Tooltip >
                                  </TableCell >
                                </TableRow >
                              )
                            })}
                          </TableBody >
                        </Table >
                      </TableContainer >
                    </Box >
                  </Grid >
                  <AlertDialog
                    id="alert-delete"
                    open={confirmation1}
                    onClose={handleADClose1}
                    title={"Confirmation"}
                    maxWidth={"sm"}
                    description="Are you sure you want to delete?"
                    action={
                      <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                        <Grid container spacing={2} justifyContent="left">
                          <Grid item xs={'auto'}>
                            <Button onClick={handleADClose1}>No</Button>
                          </Grid>
                          <Grid item xs={'auto'}>
                            <Button color="secondary" onClick={() => { deleteUpdateVenture() }}>Yes</Button>
                          </Grid>
                        </Grid>
                      </div>
                    }
                  />
                </>
              }
            />
          </>
        ) : null
      }
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={confirmation}
        onClose={handleADClose}
        description="Are you sure you want to delete?"
        action={
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>No</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button color="secondary" onClick={deleteQc}>Yes</Button>
            </Grid>
          </Grid>
        }
      />
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={confirmation3}
        onClose={handleADClose3}
        description="The update of the QC status will overwrite the QC status for all the assets in this job. Are you sure you want to proceed ?"
        action={
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose3}>No</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button color="secondary" onClick={bulckEditQuality}>Yes</Button>
            </Grid>
          </Grid>
        }
      />
      {/* <AlertDialog
        id="alert-delete"
        open={confirmation2}
        onClose={handleADClose2}
        description="Are you sure you want to delete?"
        action={
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose2}>No</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button  color="secondary" onClick = {deleteJointVenture()}>Yes</Button>
            </Grid>
          </Grid>
        }
      />  */}
    </Box >



  );
};

export default OwnershipChangesManageQualityDetails;
