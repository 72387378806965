// Default React, useState and Material Components
import React, { useState } from "react";
import { Button, Breadcrumbs, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography, Box, } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import SideDrawer from "../../../UIComponent/Drawer";
import DataTable from "../../../UIComponent/DataTable";
import AlertDialog from "../../../UIComponent/AlertDialog";
import BaseLayout from "../../../UIComponent/BaseLayout";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing Add & Edit Components
import AddEditCompanyAndStock from "./AddEditCompanyAndStock";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const CompanyStockExchange = (props) => {

    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [reload, setReload] = React.useState(false);
    const [formData, setFormData] = React.useState([]);
    const [selected, SetSelected] = React.useState([]);
    const [state, setState] = useState({ right: false });
    const [totalCount, setTotalCount] = React.useState(0);
    const [isLoading, setLoading] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState();
    const [crudState, setCrudState] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [isAllSelected, setIsSelected] = React.useState(false);
    const [allStockExchanges, setStockExchanges] = React.useState([]);
    const [deleteBtnLoadinng, setDeleteBtnLoadinng] = React.useState(false);
    const [deleteStockExchanges, setDeleteStockExchanges] = React.useState(null);
    const [sorting, setSorting] = React.useState({ sortBy: "", isAscending: false });
    const [addStockExchangeDrawer, setAddStockExchangeDrawer] = React.useState(true);
    const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false });
    const [menuPermission, setMenuPermission] = React.useState({ allowView: true, allowEdit: true, allowDelete: true, });

    // Declaration of React Hooks Start Here
    React.useEffect(() => {
        GetMenuService(49, function (err, res) {
            if (res) {
                setAccess(res.data)
            } else {
                toasterError(err)
            }
        })
    }, [])
    React.useEffect(() => {
        if (searchValue == "" || searchValue == null || searchValue == undefined) {
            getAllStockExchanges();
        }
        if (searchValue && reload) { searchStockExchange(searchValue); }
    }, [page, reload, rowsPerPage])
    React.useEffect(() => {
        if (searchValue == "" || searchValue == null || searchValue == undefined) {
            getAllStockExchanges();
        }
        if (searchValue) { searchStockExchange(searchValue); }
    }, [sorting])
    // Declaration of React Hooks End Here

    // Assigning Columns and UI Props
    const columns = [
        { id: "Id", label: "Id", type: "Highlight" },
        { id: "company", label: "Company", minWidth: 350, width: '15%', sorting: true },
        { id: "stockExchange", label: "Stock Exchange", minWidth: 350, width: 'auto', sorting: true },
        { id: "comments", label: "Comments", minWidth: 400, width: 'auto', sorting: true, truncate: 90, tooltipWithTruncate: true },
    ];

// This function handles the keyup event
const handleKeyUp = (event) => {
    // If backspace is pressed and target is not a button
        if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
            if (searchValue.length === 0) { getAllStockExchanges(); }
        }
        if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
            setTotalCount(0);
            setPage(0);
            searchStockExchange(event.target.value);
        }
    };

// This function takes a column name as an argument and returns the same column name with the first letter capitalized
const changeSortColumnNameFormat = (columnName) => {
     // Initialize newColumnName variable
        let newColumnName = "";
      // Check if columnName is not empty or null   
        if (columnName !== "" && columnName !== null) {
      // Capitalize the first letter of the columnName and add it to the newColumnName variable      
            newColumnName = columnName[0].toUpperCase() + columnName.slice(1, columnName.length)
        }
        return newColumnName;
    }

// This function toggles the drawer open or closed
const toggleDrawer = (open, mode) => (event, item) => {
    // If mode is set to "Delete"
    if (mode === "Delete") {
        // Set deleteStockExchanges to the item
        setDeleteStockExchanges(item);
        // Set openDelete to true
        setOpenDelete(true);
    } else {
        // Set formData to the item
        setFormData(item);
        // Set state to open and formData to the item
        setState({ ...state, right: open, formData: item });
        // Set crudState to mode
        setCrudState(mode);
    }
};

    // Function to get all stock exchanges
const getAllStockExchanges = () => {
    // Set loading to true
    setLoading(true);
    // Create an array of parameters
    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
    // Call the ApiService search method
    ApiService.search("stock/GetCompanyStockExchangeDetails?",
        {
            // Set the sorting column and order
            sortColumn: changeSortColumnNameFormat(sorting.sortBy),
            isAscending: sorting.isAscending
        }, prt
    ).then((res) => {
        // If the response status is 200, set the stock exchanges and total count
        if (res.status === 200) {
            setStockExchanges(res.data.data);
            setTotalCount(res.data.totalCount);
            setLoading(false);
        } else {
            // Else, display an error message
            toasterError(`${res.data}`);
            setLoading(false);
        }
    })
        .catch((err) => {
            // Catch any errors and handle them
            setLoading(false);
            ErroHandling(err, function (res) {
                toasterError(res)
            })
        });
}
   

    // Function for Global Search
    const globalSearch = (value) => { setSearchValue(value); }
// This function is used to search stock exchange details
const searchStockExchange = (filterValue) => {
    // Set loading to true while the API call is in progress
    setLoading(true);
    // Create an array of parameters for the API call
    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
    // Make an API call to get company stock exchange details
    ApiService.search(
        "stock/GetCompanyStockExchangeDetails?",
        {
            // Encode the search text
            searchText: encodeURIComponent(filterValue),
            // Change the format of the sort column name
            sortColumn: changeSortColumnNameFormat(sorting.sortBy),
            // Set the sorting order
            isAscending: sorting.isAscending
        }, prt)
        .then((res) => {
            if (res.status === 200) {
                // Set the stock exchanges data
                setStockExchanges(res.data.data);
                // Set loading to false after the API call is completed
                setLoading(false);
                // Set the total count of the stock exchanges
                setTotalCount(res.data.totalCount);
            } else {
                // Show error message if the API call fails
                toasterError(`${res.data}`);
                setLoading(false);
            }
        })
        .catch((err) => {
            // Set loading to false if there is an error
            setLoading(false);
            // Handle the error
            ErroHandling(err, function (res) {
                toasterError(res)
            })
        });
}

 // Function to delete a stock exchange
const deleteStockExchange = () => {
    // Set loading state of delete button to true
    setDeleteBtnLoadinng(true);
    // Create data object with deleteStockExchanges
    let data = deleteStockExchanges;
    // Set active property of data to false
    data.active = false;
    // Make API call to delete company stock exchange
    ApiService.create(`stock/DeleteCompanyStockExchange?companyStockExchangeId=${data.id}`)
        .then((res) => {
            // If API call is successful, show success message and update stock exchanges
            if (res.status === 200) {
                toasterSuccess("Company and its Stock Exchange deleted successfully!");
                getAllStockExchanges();
                // Set loading state of delete button to false
                setDeleteBtnLoadinng(false);
                // Close delete modal
                setOpenDelete(false);
            } else {
                // If API call fails, show error message
                toasterError("Something went wrong");
                // Close delete modal
                setOpenDelete(false);
                // Set loading state of delete button to false
                setDeleteBtnLoadinng(false);
            }
        })
        .catch((err) => {
            // Close delete modal
            setOpenDelete(false);
            // Set loading state of delete button to false
            setDeleteBtnLoadinng(false);
            // Handle errors
            ErroHandling(err, function (res) {
                toasterError(res)
            })
        });
}
  
    return (

        // Page Layout Start Here
        <Box>
            <Grid container maxWidth justifyContent="right" alignItems="center">

                {/* Breadcrumbs Menu Start Here */}
                <Grid item xs={12} sm={12} md={6} className="page-title">
                    <h2>Company & Stock Exchanges</h2>
                    <Breadcrumbs
                        separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/commodity" }}>Manage Data</Link>
                        <Typography>Company & Stock Exchanges</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* Breadcrumbs Menu End Here */}

                {/* Baselayout Start Here */}
                <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                        <Grid item xs={"auto"}>
                            {(access.allowEdit) ? <>
                                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: "16px" }} />}>
                                    Add Company & Stock Exchanges
                                </Button></> : null}
                        </Grid>
                    </Grid>
                </Grid>
                {/* Main Buttons End Here */}

                {/* Baselayout Start Here */}
                <Grid item xs={12}>
                    <BaseLayout>
                        <Box className={classes.addBorder}>
                            {/* Search Option and Total Count Start Here */}
                            <Grid item xs={12} className={classes.tableTopPart}>
                                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="input-with-icon-textfield"
                                            className={classes.searchByKeyword}
                                            value={searchValue}
                                            placeholder="Search by Keyword"
                                            onKeyUp={handleKeyUp}
                                            onChange={(e) => globalSearch(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon className={classes.searchIcon} />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {searchValue ? (<IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => {
                                                                setSearchValue("");
                                                                // setReload(true);
                                                                getAllStockExchanges();
                                                            }}
                                                        >
                                                            <ClearIcon style={{ fontSize: 10 }} />
                                                        </IconButton>) : ("")}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} textAlign="right">
                                        <Typography variant="subtitle2">
                                            Total Records: <span className={classes.totalRecord}>{totalCount}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Search Option and Total Count End Here */}

                            {/* Stock Exchange Table /> */}

                            <Grid item xs={12}>
                                <DataTable
                                    className={classes.removeBorder}
                                    loading={isLoading}
                                    rows={allStockExchanges ? allStockExchanges : []}
                                    columns={columns}
                                    page={page}
                                    setPage={(data) => { setPage(data); setReload(true); }}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={(data) => {
                                        setRowsPerPage(data);
                                        setReload(true);
                                    }}
                                    totalCount={totalCount}
                                    isAllSelected={isAllSelected}
                                    setIsSelected={setIsSelected}
                                    selected={selected}
                                    setSelected={SetSelected}
                                    sorting={sorting}
                                    setSorting={(e) => {
                                        setSorting(e);
                                    }}
                                    isActionButton={{
                                        ...(access.allowView && {
                                            view: toggleDrawer(true, "View"),
                                        }),
                                        ...(access.allowEdit && { edit: toggleDrawer(true, "Edit"), }),
                                        ...(access.allowDelete && { delete: toggleDrawer(true, "Delete"), }),
                                    }}
                                />
                            </Grid>
                        </Box>
                    </BaseLayout>
                </Grid>
            </Grid>

            <SideDrawer
                formComp={
                    <AddEditCompanyAndStock
                        state={state}
                        setState={setState}
                        crudState={crudState}
                        formData={formData}
                        setFormData={setFormData}
                        variableId={null}
                        hideSideDrawer={() => {
                            setState({ right: false });
                            setSearchValue("");
                            setReload(!reload);
                        }}
                        addStockExchangeDrawer={addStockExchangeDrawer}
                    />
                }
                state={state}
                setState={setState}
            />
            {openDelete ? (
                <AlertDialog
                    open={openDelete}
                    title={"Confirmation"}
                    maxWidth={"sm"}
                    description={`Are you sure you want to delete ?`}
                    action={
                        <>
                            <div className={classes.alertDialogbtn}>
                                <Button onClick={() => setOpenDelete(false)} style={{ marginRight: "15px" }}>No</Button>
                                {deleteBtnLoadinng ? (
                                    <CircularProgress />
                                ) : (
                                    <Button onClick={() => deleteStockExchange()} color="secondary">
                                        Yes
                                    </Button>
                                )}
                            </div>
                        </>
                    }
                />
            ) : null}
        </Box>
    );
};

export default CompanyStockExchange;