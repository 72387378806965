
// Default React, Router and Services Components
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid, TextField } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { logo } from "../../../Resources/index";
import { toast } from "react-toastify";

import * as Yup from 'yup';
import { da } from "date-fns/locale";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { FloatLabel } from "../../../UIComponent/index";
import { LoginService, saveSession } from "../../../Services/AppService";

import axios from "axios";
import QueryString from 'query-string'
import { useLocation } from 'react-router-dom';
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// Importing useStyles
import "./index.scss";

const changePass = { password: "", confirmPassword: "" }

const ChangePassword = () => {
    // Declaration of States and Component Variables Start Here
    const history = useHistory();
    let location = useLocation();
    const [data, setData] = useState(changePass);
    const token = localStorage.getItem('token')
    // Declaration of States and Component Variables End Here

    // Declaration of React Hooks Start Here
    useEffect(() => {
        // Getting authentication token from query string and adding to local storage
        // console.log("###########################################################")
        const value = QueryString.parse(location.search);
        const bearerToken = value.Bearer;
       // console.log(bearerToken)
        if (bearerToken) { localStorage.setItem("token", bearerToken) }
    }, []);
    // Declaration of React Hooks End Here

    // Function for Validation

    const handleSubmit = () => {
        const changePassword = { password: data.password, }
        var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (re.test(data.password)) {
            if (data.password === data.confirmPassword) {
                {
                    axios.post(process.env.React_App_baseUrl + "account/ChangePassword", changePassword, {
                        headers: {
                            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
                        }
                    })
                        .then(function (response) {
                            toasterSuccess("Password Changed Successfully")
                            localStorage.removeItem("token");
                            history.push('login')  
                        })
                        .catch(function (error) {
                            ErroHandling(error, function (res) {
                                toasterError(res)
                            })
                        });
                }
            }
            else { toasterError("Please make sure password and conform password must be same"); }
        } else {
            toasterError("Please choose a stronger password");
        }
    };

    const handleReset = () => { setData(changePass) }

    return <Formik
        initialValues={{ email: '', password: '', showPassword: false }}>
        {({ values, isValid, dirty }) => (
            <Form noValidate autoComplete="new-password">
                <div className="loginPage">
                    <div className="d-flex align-items-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="loginArea col-12 col-sm-9 col-md-7 col-lg-5">
                                    <div className="loginContainer py-4 px-4">
                                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                                            {/* Login Start Here */}
                                            <Grid item xs={12}>
                                                <div className="logoContainer d-flex justify-content-center">
                                                    <img className="image" src={logo} width="65" height="auto" alt="Welcome to CRU - Primary Data Collection Platform" title="Welcome to CRU - Primary Data Collection Platform" />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="welcomeMessage">
                                                    <div className="title text-center">Welcome to CRU - Primary Data Collection Platform </div>
                                                </div>
                                            </Grid>

                                            {/* Change Password Start Here */}
                                            <Grid item xs={12}>
                                                <div variant="h6" className="subheading">
                                                  Change Password Requirements:
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ul className="passwordChecklist">
                                                    <li>
                                                    <ErrorOutlineIcon /> Must contain at least one uppercase letter &#38; one lowercase letter
                                                    </li>
                                                    <li>
                                                    <ErrorOutlineIcon /> Must contain at least one special character &#38; one number
                                                    </li>
                                                    <li>
                                                    <ErrorOutlineIcon /> Must contain at least 8 to 12 characters
                                                    </li>
                                                    
                                                </ul>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    size="small"
                                                    label="New Password"
                                                    fullWidth
                                                    name="newPassword"
                                                    placeholder="Please enter your New Password"
                                                    variant="standard"
                                                    value={data.password}
                                                    onChange={(e) => setData({ ...data, password: e.target.value })}
                                                    type={values.showPassword ? "text" : "password"}
                                                    required
                                                />

                                            </Grid>
                                            
                                            <Grid item xs={12}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    label="Confirm Password"
                                                    placeholder="Please enter your Confirm Password"
                                                    name="confirmPassword"
                                                    variant="standard"
                                                    value={data.confirmPassword}
                                                    onChange={(e) => setData({ ...data, confirmPassword: e.target.value })}
                                                    type={values.showPassword ? "text" : "password"}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="form-check show-password">
                                                    <Field name="showPassword" className="form-check-input" type="checkbox" id="showPassword" />
                                                    <label class="form-check-label" htmlFor="showPassword" for="showPassword">Show Password</label>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="loginSubmit">
                                                    {/* <Button size="large" disabled={!(isValid && dirty)} onClick={handleSubmit} className="button">LOGIN</Button> */}
                                                    <Button variant="contained" onClick={handleSubmit} style={{ marginRight: "15px", minWidth: '100px' }} className="rounded-border">Submit</Button>
                                                    <Button variant="contained" color="accent3" style={{ minWidth: '100px' }} onClick={handleReset}>Reset</Button>
                                                </div>
                                            </Grid>
                                            {/* Reset Password End Here */}

                                            <Grid item xs={12}>
                                                <div className="copyright-text text-center mt-3">
                                                    © 2023 <a href="https://www.crugroup.com/" target={"_blank"}>CRU International Ltd</a>. All rights reserved.
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        )}
    </Formik>

};

export default ChangePassword;