import React, { useEffect, useState } from "react";
import ErroHandling from "../../../Services/ErrorHandling";
// Default React, Router and Services Components
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AccordionDetails,
} from "@mui/material";
import {
  AddIcon,
  AttachIcon,
  CommentIcon,
  DownloadDocumentIcon,
} from "@fluentui/react-icons-mdl2";
import CircularProgress from "@mui/material/CircularProgress";
import AlertDialog from "../../../UIComponent/AlertDialog";
// Importing Custom Components
import DatePicker from "../../../UIComponent/DatePicker";
import Dropzone from "../../../UIComponent/DragDropField";
import {
  CancelIcon,
  DeleteIcon,
  TextDocumentIcon,
  LinkIcon,
} from "@fluentui/react-icons-mdl2";
import ApiService from "../../../Services/ApiService";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// import history from "../../../Routes/History";
import { useHistory } from "react-router-dom";

import SideDrawer from "../../../UIComponent/Drawer";
import { ScrollingDialog } from "../../../UIComponent";
import AdobeViewer from "./AdobeViewer";
import dayjs from "dayjs";

// Import Fluent UI Icons

// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";
import "./index.scss";
import axios from "axios";
//ADD ON
// Accordion Components
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

// Import Material & Fluent UI Icons
import PlaceIcon from "@mui/icons-material/Place";
import RemoveIcon from "@mui/icons-material/Remove";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import { styled } from "@mui/material/styles";
import DataTable from "../../../UIComponent/DataTable";
import GetMenuService from "../../../Services/GetMenuService";
import AddUpdateInfo from "./AddUpdateInfo";
import CustomDataTable from "../../../UIComponent/CustomDataTable/CustomDataTable";
import CommonService from "../../../Services/CommonService";

const OwnershipChangesUpdates = (props) => {
  console.log("PROPPP", props);
  const history = useHistory();
  const classes = useStyles();

  /***********ADD ON************ */
  const [access, setAccess] = React.useState({
    allowEdit: false,
    allowDelete: false,
    allowView: false,
  });
  const [expanded, setExpanded] = React.useState(true);
  const [state, setState] = useState({ right: false });
  const [crudState, setCrudState] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [showAddUpdateInfo, setShowAddUpdateInfo] = React.useState(false);
  //USESTATE FOR GRID DATA
  const [updateData, setUpdatesData] = useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setLoading] = useState(false);
  const [preselectedAsset, setPreselectedAsset] = React.useState(null);
  const [prefillData, setPreFillData] = useState([]);
  const [assetdropdown, setAssetDropdown] = useState([]);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [refresh, setRefresh] = React.useState(false);
  console.log("", props.prefillData);
  React.useEffect(() => {
    GetMenuService(54, function (err, res) {
      if (res) {
        setAccess(res.data);
      } else {
        toasterError(err);
      }
    });
  }, []);
  /*   React.useEffect(() => {
    if (props.jobAssetId !== undefined) {
      setPreFillData(props.prefillData);
    }
  }, [props]); */
  const deleteRecord = () => {
    setDeleteBtnLoadinng(true);
    let data = deleteData;
    axios
      .delete(`${process.env.React_App_baseUrl}ManageOwnership/DeleteUpdate`, {
        headers: {
          updateId: deleteData,
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      })
      .then((res) => {
        setLoading(true);
        toasterSuccess("Update Info Deleted Successfully!");
        getGridData();
        setDeleteBtnLoadinng(false);
        setOpenDelete(false);
      })
      .catch((err) =>
        ErroHandling(err, function (res) {
          toasterError(res);
        })
      );
  };
  // Assigning Columns and UI Props
  const rows = [
    {
      slNo: "234",
      company: "newcompany",
      year: "1996",
      reportType: "xxx",
      reportName: "12 Angry Men",
      //linksAndAttachments: "https://chat.openai.com/",
      linksAndAttachments: [
        { comment: "hello" },
        { link: "https://chat.openai.com/" },
        { attach: "https://chat.openai.com/" },
      ],
    },
  ];
  const rowss = [
    {
      id: 0,
      ownerGuid: null,
      ownerName: "International",
      assetGuid: null,
      assetName: "aaa asset",
      yearId: 0,
      year: 2005,
      ownerUpdateCommodityList: [
        {
          commodityId: 0,
          commodityName: "Zinc",
        },
      ],
      changeTypeId: 0,
      changeType: "suspended",
      pageNo: null,
      comment: "comment here1",
      ownerUpdateReportInfoList: [
        {
          jobAssetReportInfoId: 0,
          reportName: "https://www.office.com/",
          link: "https://www.office.com/",
        },
      ],
    },
    {
      id: 1,
      ownerGuid: null,
      ownerName: "International",
      assetGuid: null,
      assetName: "aaa asset",
      yearId: 0,
      year: 2005,
      ownerUpdateCommodityList: [
        {
          commodityId: 0,
          commodityName: "Zinc",
        },
      ],
      changeTypeId: 0,
      changeType: "suspended",
      pageNo: null,
      comment: "comment here2",
      ownerUpdateReportInfoList: [
        {
          jobAssetReportInfoId: 0,
          reportName: "https://www.zoho.com/",
          link: "https://www.zoho.com/",
        },
      ],
    },
  ];

  // Add Report Info Drawer
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") {
      setDeleteData(item.id);
      //setDeleteJobNumber(item.jobid);
      setOpenDelete(true);
    } else {
      console.log("PRBLM", item, mode);
      //getAssetInfo();
      //setPreFillData(item);
      setState({ ...state, right: open, prefillData: item });
      setCrudState(mode);
    }
  };
  /**********Open a file on cliking the icon********* */
  const openDocumentForView = (rowData,pageNumber=1) => {
    console.log("PREVIEW", rowData);
    //var jobdetails = tempData?.state;
    let sendData = {
      jobId: props.jobId,
      fileName: rowData + ".pdf",
      pageNumber: pageNumber,
      crudState: "View",
    };
    CommonService.openFilePreview(sendData);
  };
  // Remove the RenderIcons component and define it within the main component

  /**************API TO GET THE GRID DATA***************** */
  const getGridData = () => {
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0
    ) {
      return;
    }
    setLoading(true);
    axios
      .get(
        process.env.React_App_baseUrl + "ManageOwnership/GetUpdatesList",

        {
          headers: {
            jobAssetId: props.jobAssetId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setTotalCount(res.data.totalCount);
        setUpdatesData(res.data);
        console.log("updates:", res.data);
      })

      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
        setLoading(false);
      });
  };

  /*   const UpdatesDatacomm = updateData.map((item) => {
    const commodityNames = item.ownerUpdateCommodityList.map(
      (commodity) => commodity.commodityName
    );
    const formattedCommodityNames = commodityNames.join(", ");

    return {
      ...item,
      commodityNames: formattedCommodityNames,
    };
  }); */

  const columns = [
    {
      id: "id",
      label: "id",
      type: "Highlight",
    },
    {
      id: "ownerName",
      label: "Owner",
      minWidth: 250,
      width: "20%",
      align: "left",
    },
    {
      id: "assetName",
      label: "Asset",
      minWidth: 200,
      width: "10%",
      align: "left",
    },
    {
      id: "commodities",
      label: "Commodity",
      minWidth: 140,
      width: "15%",
    },
    { id: "year", label: "Year", minWidth: 100, width: "8%" },
    { id: "changeType", label: "Type of Change", minWidth: 150, width: "15%" },
    {
      id: "ownerUpdateReportInfoList",
      label: "Remarks",
      //isIcons: true,
      colorCode: "blue",
      qValue: true,
      minWidth: 200,
      width: "35%",
    },
    /*  {
      id: "actions",
      label: "Action",
      minWidth: 110,
      width: "10%",
      align: "center",
    }, */
  ];
  //check
  const getAssetInfo = async () => {
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0
    ) {
      return;
    }
    await axios
      .post(
        `${process.env.React_App_baseUrl}ManageOwnership/GetJobAssetInfo`,
        null,
        {
          headers: {
            jobAssetId: props.jobAssetId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        // Find the preselected asset object based on matching asset IDs
        const preselected = assetdropdown.find(
          (e) => e.assetGUID === res.data.assetGUID
        );
        // Set the preselectedAsset state
        setPreselectedAsset(preselected, {ownerGuid : res.data.ownerGUID, owner : res.data.owner, commodityIds : res.data.commodityIds});
        console.log(preselected);
      })
      .catch((err) => {
        {
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        }
        // setOpenDialog(false);
      });
    // setIsOpenAssign(false);
  };
  React.useEffect(() => {
    getAssetInfo();
    getGridData();
  }, [sorting, props]);
   React.useEffect(() => {
    if (
      props.jobId === undefined ||
      props.jobId === null ||
      props.jobId === 0
    ) {
      return;
    }
    axios
      .post(
        process.env.React_App_baseUrl + "ManageOwnership/GetJobAssetIds",
        null,
        {
          headers: {
            jobId: props.jobId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setAssetDropdown(res.data);
        console.log("hell", res.data);
      });
  }, [props.jobId]); 

  return (
    <>
      <AccordionDetails>
        {/* <Grid item xs={12} sm={12} md={6}>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            sx={{ mt: 1, mb: 0 }}
            justifyContent="flex-end"
            textAlign="right"
          >
            <Grid item xs={"auto"}>
              <Button
                variant="contained"
                color="accent7"
                size="small"
                startIcon={<AddIcon style={{ fontSize: 16 }} />}
                onClick={toggleDrawer(true, "Add")}
              >
                Add Update
              </Button>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={12}>
          <Box className={classes.tableBorder}>
            <CustomDataTable
              // className={classes.removeBorder}
              //loading={isLoading}
              sorting={sorting}
              setSorting={setSorting}
              rows={updateData}
              columns={columns}
              totalCount={totalCount}
              // Pass the actual values for ownerUpdateReportInfoList, comment, link, and reportName
              // IconList={updateData.map((row) => row.ownerUpdateReportInfoList)}
              // MessageBox={updateData.map((row) => row.comment)}
              openDocumentForView={(fileName) => {
                console.log("PREVIEW", fileName);
                openDocumentForView(fileName?.reportName,fileName?.pageNo);
              }}
              isActionButton={{
                ...(props.access && {
                  edit: props?.toggleDrawer5(true, "Edit"),
                }),
                ...(props.access && {
                  delete: toggleDrawer(true, "Delete"),
                }),
              }}
            />
          </Box>
        </Grid>
      </AccordionDetails>
      {/* Add User Side Drawer */}

      {/*  <SideDrawer
        formComp={
          // Calling Add User
          <AddUpdateInfo
            state={props.State5}
            setState={props.setState5}
            crudState={props.crudState}
            prefillData={prefillData ? prefillData : ""}
            setPreFillData={setPreFillData}
            preselectedAsset={props.preselectedAsset}
            setPreselectedAsset={props.setPreselectedAsset}
            jobAssetId={props.jobAssetId}
            setRefresh={setRefresh}
            jobId={props.jobId}
       
            hideSideDrawer={() => {
              props.setState5({ right: false });
              getGridData();
            }}
          />
        }
        state={props.State5}
        setState={props.setState5}
      
      /> */}

      {/* Alert Dialog - Confirmation for Delete */}
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          // ${deleteJobNumber}
          description={`Are you sure you want to delete  ?`} /* Id : ${deleteData} */
          action={
            <Grid container justifyContent="right">
              <Grid item xs={"auto"}>
                <Button
                  onClick={() => setOpenDelete(false)}
                  style={{ marginRight: "15px" }}
                >
                  No
                </Button>
              </Grid>
              <Grid item xs={"auto"}>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button onClick={deleteRecord} color="secondary">
                    Yes
                  </Button>
                )}
              </Grid>
            </Grid>
          }
        />
      ) : null}
    </>
  );
};

export default OwnershipChangesUpdates;
