// Default React, useState and Material Components
import React, { useState, useEffect } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const CommoditySideBar = (props) => {
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [editView, setEditView] = useState({});
  const [loading, setLoading] = useState(false);
  const handleSave = () => { const data = { ...userData }; setUserData(data); onSaveApi(data); };
  const [userData, setUserData] = useState({ roleId: 0, commoditySymbol: "", commodityName: "", });

  // Declaration of React Hooks Start Here
  useEffect(() => { if (Object.values(props.formData).length > 0) { setEditView(props.formData); } }, [props.formData]);
  // Declaration of React Hooks End Here

// This function is used to save the commodity data
const onSaveApi = (data) => {
  // Set loading to true
  setLoading(true);
  // Check if both commoditySymbol and commodityName are present
  if (data.commoditySymbol.length > 0 && data.commodityName.length > 0) {
    // Make a post request to the managedata/SaveCommodity endpoint
    axios.post(process.env.React_App_baseUrl + "managedata/SaveCommodity", null, {
      headers: {
        // Encode the commodityName and commoditySymbol
        commodityName: encodeURIComponent(data.commodityName), commoditySymbol: encodeURIComponent(data.commoditySymbol),
        // Get the token from sessionStorage
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      },
    })
      .then((res) => {
        // If the response status is 200, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("Commodity details added successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // Set loading to false and call ErroHandling function
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  } else {
    // Show error message if required fields are not entered
    toasterError("Please enter the required fields");
    setLoading(false);
  }
};


// This function is used to update the commodity details
const onUpdateApi = () => {
  // Check if the editView.commoditySymbol and editView.name are not empty
  if (editView.commoditySymbol.length > 0 && editView.name.length > 0) {
    // Make a post request to the managedata/UpdateCommodity endpoint
    axios.post(process.env.React_App_baseUrl + "managedata/UpdateCommodity", {
      ...editView,
    }, {
      headers: {
        // Set the Authorization header with the token stored in sessionStorage
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then((res) => {
        setEditView(res.data);
        // If the response status is 200, show success message and hide the side drawer
        if (res.status === 200) {
          toasterSuccess("Commodity details updated successfully!");
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // Call the ErroHandling function to handle errors
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  } else {
    // Show error message if required fields are not entered
    toasterError("Please enter the required fields");
  }
};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Commodity
        </Typography>
      </Box>

      {/* Add & Edit Commodity Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            required
            variant="standard"
            defaultValue={props.crudState === "Edit" ? editView.commoditySymbol : null}
            fullWidth
            label="Symbol"
            InputProps={{ shrink: true, }}
            value={props.crudState === "Add" ? null : editView.commoditySymbol}
            onChange={(e) => {
              if (props.crudState === "Edit") {
                setEditView({ ...editView, commoditySymbol: e.target.value, });
                return;
              }
              setUserData({ ...userData, commoditySymbol: e.target.value });
            }}
            placeholder="Symbol"
            InputLabelProps={
              props.crudState === "Edit" ? { shrink: true } : null
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            required
            fullWidth
            label="Name"
            placeholder="Name"
            value={props.crudState === "Add" ? null : editView.name}
            onChange={(e) => {
              if (props.crudState === "Edit") {
                setEditView({ ...editView, name: e.target.value, });
                return;
              }
              setUserData({ ...userData, commodityName: e.target.value });
            }}
            InputLabelProps={props.crudState === "Edit" ? { shrink: true } : null}
          />
        </Grid>
        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={'auto'}>
                <Button variant="contained" color="accent5" onClick={props.crudState === "Edit" ? onUpdateApi : handleSave}>
                  {props.crudState === "Edit" ? "Update" : "Save"}
                </Button>
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant="contained" color="accent3" onClick={() => props.setState({ ...props.state, right: false })}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CommoditySideBar;