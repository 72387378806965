// Default React, Router and Services Components
import React from "react";
import { Box, Breadcrumbs, Grid, Link, Typography } from "@mui/material";

// Importing utility Components
import EmptyState from "../../UIComponent/EmptyState";

// Importing Custom Components
import BaseLayout from "../../UIComponent/BaseLayout";

// Importing useStyles
import useStyles from "./UserManagement/UserManagement.styles";

// Import Fluent UI Icons
import { ChevronRightSmallIcon, UserWarningIcon } from "@fluentui/react-icons-mdl2";

const BlockUserAccess = (props) => {
  const classes = useStyles();

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} className="page-title">
          <h2>&nbsp;</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" href="/">Home</Link>
            <Typography color="text.primary">BlockUserAccess</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* BaseLayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box sx={{ pt: 11.5 }}>
              <center >
                <EmptyState 
                  icon={
                    <UserWarningIcon
                      style={{ color: "#db9400", fontSize: "70px", marginBottom: "10px" }}
                    />
                  }
                  title=" You don’t have permission to access this page"
                />
              </center>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
    </Box> 
  );
};

export default BlockUserAccess;


