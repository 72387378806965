import React, { useEffect, useState } from "react";
import ErroHandling from "../../../Services/ErrorHandling";
import { useHistory } from "react-router-dom";

// Default React, Router and Services Components
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { AddIcon, AttachIcon, DownloadDocumentIcon } from "@fluentui/react-icons-mdl2";
import CircularProgress from "@mui/material/CircularProgress";
import AlertDialog from "../../../UIComponent/AlertDialog";
// Importing Custom Components
import DatePicker from "../../../UIComponent/DatePicker";
import Dropzone from "../../../UIComponent/DragDropField";
import {
  CancelIcon,
  DeleteIcon,
  TextDocumentIcon,
  LinkIcon,
} from "@fluentui/react-icons-mdl2";
import ApiService from "../../../Services/ApiService";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// import history from "../../../Routes/History";

import SideDrawer from "../../../UIComponent/Drawer";
import { ScrollingDialog } from "../../../UIComponent";
import AdobeViewer from "./AdobeViewer";
import dayjs from "dayjs";

// Import Fluent UI Icons

// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";
import "./index.scss";
import axios from "axios";
import CommonService from "../../../Services/CommonService";
const ReportInfo = ({
  reportInfoData,
  setReportInfo,
  reportInfoError,
  setReportInfoError,
  uploadFiles,
  companyData,
  fileuploadLoading,
  setRefreshReportInfo,
  setReportInfoDataValue,
  reportInfoDataValueIntial,
  updateCall,
  reportDeleteRefreshvalue,
  setreportDeleteRefresh,
  editAccess,
  GetVariableDeatils
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [formData, setFromData] = useState([]);
  const [reportError, setReportError] = useState(reportInfoError);
  const [addCustomeReportType, setAddCustomeReportType] = useState(false);
  const [customeReportType, setCustomeReportType] = useState({
    name: "",
    description: "",
  });
  const [yearOption, setYearOption] = useState([]);
  const [reportingCalOption, setReportingCalOption] = useState([]);
  const [reportTypeOption, setReportTypeOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDeleteAlart, setOpenDeleteAlart] = useState(false)
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false)
  const [deleteReportInfoData, setDeleteReportInfoData] = useState(null)
  const [fileupLoading, setFileupLoading] = useState([])
  const [openAdobeViewer, setOpenAdobeViewer] = useState()
  const [isFileCleared, setIsFileCleared] = useState(false)
  const [adobeViewerData, setAdobeViewerData] = useState({ jobId: 0, fileName: '', pageNumber: 0, crudState: "" })
  const [openConfirmDeleteAlart, setopenConfirmDeleteAlart] = useState(false)
  const [openConfirmfileUploadAlart, setopenConfirmfileUploadAlart] = useState(false)
  const [openConfirmDeleteAlartMessage, setopenConfirmDeleteAlartMessage] = useState(null)
  const [replaceFile, setreplaceFile] = useState(null)
  const [openConfirmfileDeleteAlart, setopenConfirmfileDeleteAlart] = useState(false)
  const [deleteFileIndex, setdeleteFileIndex] = useState(null)
  const [deleteFileloading, setdeleteFileloading] = useState(false)
  const onFilesAdded = (e, fileUpload, i) => {
    // console.log("onFilesAdded")
    if (formData[i].assetReportId && formData[i].reportName) {
      setopenConfirmfileUploadAlart(true)
      setreplaceFile({ fileUpload, i })
    } else {
      checkfileupload(e, fileUpload, i)
    }
  };

/*   React.useEffect(() => {
    if (reportDeleteRefreshvalue) {
      deleteReportInfo(true)
    }
  }, [reportDeleteRefreshvalue]); */

  const checkfileupload = (e, fileUpload, i) => {

   
    if (formData[i].reportingYear && formData[i].reportingPeriod && formData[i].publicationDate && formData[i].reportingType && formData[i].link) {

      let reportData = formData;
      let reportName;
      reportName = reportData[i]?.reportingYear?.year + '-' + companyData?.companyName + '-'+reportData[i]?.reportingPeriod?.name +'-'+reportData[i]?.reportingType?.reportingType+'-'+ "Report"+ (i > 0 ? '-' + i : '');
      fileUpload[0]["DisplayName"] = reportName + ".pdf";
      fileUpload[0]['reportName'] = reportName
      reportData[i].reportName = reportName
      if (fileUpload[0].type !== "application/pdf") {
        reportData[i].originalReportName = fileUpload[0].name
      }
      reportData[i].file = fileUpload[0];
      setFromData([...reportData]);
      setReportInfo(reportData);
      uploadFiles(fileUpload[0])
      setopenConfirmfileUploadAlart(false)
      setFileupLoading(i)
    } else {
      toasterError("Please fill all the required fields in report info");
    }
  }

  const handleFileClear = (objeceIndex, isConfirm) => {
    setdeleteFileloading(isConfirm)
    if (!deleteFileIndex) {
      setdeleteFileIndex(objeceIndex)
    }
    setIsFileCleared(!isFileCleared)
    let frData = formData;
    // console.log('companyData: ', companyData);
    setFileupLoading(objeceIndex)
    axios.delete(process.env.React_App_baseUrl + `job/DeleteBlobFile?fileName=${frData[objeceIndex].file.DisplayName}&reportInfoId=` + formData[objeceIndex].assetReportId + `&isConfirm=${isConfirm ? isConfirm : false}`,{
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
    }).then((res) => {
      if (res.status === 200) {
        setopenConfirmfileDeleteAlart(false)
        setdeleteFileloading(false)
        frData[objeceIndex].file = null;
        setFromData([...frData]);
        setFileupLoading([])
        toasterSuccess('File deleted successfully')
        setdeleteFileIndex(null)
      } else {
        setdeleteFileloading(false)
        setopenConfirmfileDeleteAlart(false)
        toasterError("Somthing want wrong in delete file");
        setFileupLoading([])
      }
    }).catch((err) => {
       ErroHandling(err, function (res) {
        toasterError(res)})
      setdeleteFileloading(false)
      setopenConfirmfileDeleteAlart(false)
      if (err.response.status === 400 && err.response.data === "Report Info has used in variable so can't be deleted.") {
        setopenConfirmfileDeleteAlart(true)
      } else {
        toasterError("Somthing went wrong");
      }
      setFileupLoading([])
    });
  };



  React.useEffect(() => {
    GetReportingYear();
    GetReportingPeriod();
    GetReportingTypeDropdown();
  }, []);

  React.useEffect(() => {
    if (!fileuploadLoading) {
      setFileupLoading([])
      setreplaceFile(null)
    }
  }, [fileuploadLoading]);

  React.useEffect(() => {
    
    // console.log('reportInfoData: ', reportInfoData);
    if (reportInfoData.length > 0) {
      reportInfoData.forEach(rd => {
        if (rd.assetReportId) {
          yearOption.forEach((yo, i) => {
            if (yo.year === rd.reportingYear) {
              rd.reportingYear = yearOption[i]
            }
          });
          reportingCalOption.forEach((rc, i) => {
            if (rc.name === rd.reportingPeriod) {
              rd.reportingPeriod = reportingCalOption[i]
            }
          });
          reportTypeOption.forEach((rto, i) => {
            if (rd.reportingTypeId === rto.id) {
              rd.reportingType = reportTypeOption[i]
            }
          });
          rd.file = { DisplayName: rd.reportName ? rd.reportName + '.pdf' : '' }

        }
      });
      setFromData(reportInfoData)
    } else {
      setFromData([
        {
          reportingYear: null,
          reportingPeriod: null,
          publicationDate: null,
          reportingType: null,
          reportName: "",
          link: "",
          FilePath: null,
          file: [],
          companyName: companyData?.companyName,
        },
      ])
    }
  }, [reportInfoData])

  const GetReportingYear = () => {
    ApiService.getAll("reportingcalendar/GetReportingYear")
      .then((res) => {
        setYearOption(res.data);
      })
      .catch((err) => {
         ErroHandling(err, function (res) {
          toasterError(res)})
      });
  };

  const GetReportingPeriod = () => {
    ApiService.getAll("reportingcalendar/GetReportingPeriod")
      .then((res) => {
        setReportingCalOption(res.data);
      })
      .catch((err) => {
         ErroHandling(err, function (res) {
          toasterError(res)})    });
  };

  const GetReportingTypeDropdown = () => {
    ApiService.getAll("DropDown/GetReportingTypeDropDown")
      .then((res) => {
        setReportTypeOption(res.data);
      })
      .catch((err) => {
         ErroHandling(err, function (res) {
          toasterError(res)})});
  };

  // const changeFileName=(data, index)=>{
  //   console.log('fromData', formData);
  //   let frData = formData;
  //   setFileupLoading(index)
  //   axios.post(process.env.React_App_baseUrl + `job/RenameBlobFile?jobId=${companyData.jobIds}&originalFileName=${data.file.DisplayName}&renameFilename=${data.reportName+'.pdf'}`, null).then((res)=>{
  //     if (res.status === 200) {
  //       setFileupLoading([])
  //       console.log('res: ', res);
  //         frData[index].file.DisplayName = data.reportName+ ".pdf"
  //         setFromData([...frData])
  //     }else {
  //       setFileupLoading([])
  //     }
  //   }).catch((err)=>{
  //     toasterError(err.response.data.detail)
  //     setFileupLoading([])
  //   })
  // }

  const onChangeHandle = (value, event, i, valuePlace) => {
   // console.log(value, event)
    let reportData = formData;
    // adding Report name
    if (value) {
      if (event === "reportingType" && value.reportingType === "Others") {
        setAddCustomeReportType(!addCustomeReportType);
      } else {
        reportData[i][event] =
          event === "link" ? value.target.value : value;
      }

    } else {
      reportData[i][event] = value;
    }
    
    // if (
    //   companyData.companyName &&
    //   reportData[i].reportingYear &&
    //   reportData[i].reportingPeriod
    // ) {
    //   reportData[i].reportName =
    //     (event === 'reportingYear' ? value.year : reportData[i].reportingYear.year)
    //     + "-" +
    //     companyData.companyName +
    //     "-" +
    //     (event === 'reportingPeriod' ? value.name : reportData[i].reportingPeriod.name) +
    //     "-" +
    //     (event === 'reportingType' ? value.reportingType : (reportData[i]?.reportingType === null) ? '' : reportData[i]?.reportingType?.reportingType)
    //     + "-" +
    //     "Report" +
    //     (i > 0 ? "-" + i : '');
    // }
    // console.log('reportData: ', reportData[i]);
    // if(reportData[i].file.DisplayName && (event === "reportingYear" ||event === "reportingPeriod") ) {
    //   changeFileName(reportData[i], i)
    // }

    // console.log(reportData)
    setFromData([...reportData]);
    setReportInfo(reportData);
  };

  const onDateChange = (event, i) => {
    let selectedDate = new Date(event);
    let reportData = reportInfoData;
    let date = dayjs(selectedDate).format('YYYY/MM/DD')
    reportData[i].publicationDate = date;
    setFromData(reportData);
    setReportInfo(reportData);
  };

  const handleAddNewFrom = () => {
    
    let reportData = [
      ...formData,
      {
        reportingYear: null,
        reportingPeriod: null,
        publicationDate: null,
        reportingType: null,
        reportName: null,
        link: null,
        FilePath: null,
        file: [],
        companyName: companyData.companyName,
        originalReportName: null
      },
    ];
    setFromData(reportData);
    setReportInfo(reportData);
    let error = [
      ...reportInfoError,
      {
        reportingYear: false,
        reportingPeriod: false,
        publicationDate: false,
        reportingType: false,
        reportName: false,
        link: false,
        FilePath: false,
        file: false,
      },
    ];
    setReportError(error);
    setReportInfoError(error);
  };

  const handleDeleteReportInfo = (i) => {
    let frData = formData;
    if (frData[i].assetReportId) {
      setOpenDeleteAlart(true)
      let delData = frData[i]
      delData['index'] = i + 1
      setDeleteReportInfoData(delData)
    } else {
      frData.splice(i, 1);
      setFromData([...frData]);
      setReportInfo([...frData])
    }
  };


  const deleteReportInfo = (isConfirm) => {
    if(deleteReportInfoData){
      setDeleteBtnLoadinng(true)
      axios.delete(process.env.React_App_baseUrl + `updatejobrecord/DeleteReportInfo?reportInfoId=${deleteReportInfoData.assetReportId}&isConfirm=${isConfirm}`,{
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
      }).then((res) => {
        if (res.status === 200) {
          setreportDeleteRefresh(false)
          setopenConfirmDeleteAlart(false)
          // console.log('res: ', res);
          setReportInfoDataValue(reportInfoDataValueIntial)
          let frData = formData
          let removeReportDataIndexId
          formData.forEach((fd, i) => {
            if (fd.assetReportId === deleteReportInfoData.assetReportId) {
              removeReportDataIndexId = i
            }
          });
          frData.splice(removeReportDataIndexId, 1);
          setFromData([...frData]);
          setReportInfo([...frData])
          setDeleteBtnLoadinng(false)
          setOpenDeleteAlart(false)
          toasterSuccess(res.data)
          setRefreshReportInfo(true)
          GetVariableDeatils()
        } else {
          toasterError('Something went wrong')
        }
      }).catch((err) => {
        setDeleteBtnLoadinng(false)
        // console.log('err.response: ', err.response);
         ErroHandling(err, function (res) {
          if(res === "Report Info has used in variable value so can't be deleted. Still you want to delete?"){
            setopenConfirmDeleteAlart(true)
            setopenConfirmDeleteAlartMessage(res)
          }else{
            toasterError(res)
          }})
      })
    }
  }

  const handleClose = () => {
    setAddCustomeReportType(!addCustomeReportType);
  };

  const handleAddCustomeReportType = () => {
    if (customeReportType.name.length > 0 && customeReportType.description.length > 0) {
      setLoading(true);
      ApiService.create("updatejobrecord/AddReportingType", customeReportType)
        .then((res) => {
          setLoading(false);
          GetReportingTypeDropdown();
          if (res.status === 200) {
            toasterSuccess("Report type added successfully ");
          } else {
            toasterError("Somthing went wrong");
          }
          setAddCustomeReportType(!addCustomeReportType);
        })
        .catch((err) => {
          setLoading(false);
           ErroHandling(err, function (res) {
            toasterError(res)})
        });
    } else {
      toasterError('Please fill all the required fields')
    }
  };


  const openDocumentForView = (file) => {
    let senData = {
      jobId: companyData.jobIds,
      fileName: file.file.DisplayName,
      pageNumber: 1,
      crudState: "View"
    }
    // history.push({ pathname: '/adobeView', state: senData })
    // OpenViewer(senData);
    localStorage.setItem("filePreview", JSON.stringify(senData));
    //CommonService.AddTokenFromSessionStorageToLocalStorage();
     window.open("#app/data-collections/file-preview", '_blank', 'noopener,noreferrer');
    
  }


  const OpenViewer = (data) => {
    setOpenAdobeViewer(true);
    setAdobeViewerData({ jobId: data.jobId, fileName: data.fileName, pageNumber: data.pageNumber, crudState: data.crudState });
  }

  const handleFileDownload = (objeceIndex) => {
    // console.log('formData: ', formData);
    var afterDot = formData[objeceIndex].originalReportName.substr(formData[objeceIndex].originalReportName.indexOf('.'));
    let mediaType
    switch (afterDot) {
      case ".xlsx":
        mediaType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case ".xls":
        mediaType = "application/vnd.ms-excel";
        break;
      case ".doc":
        mediaType = "application/msword";
        break;
      case ".docx":
        mediaType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case ".ppt":
        mediaType = "application/vnd.ms-powerpoint";
        break;
      case ".pptx":
        mediaType = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        break;
    }


    setLoading(true);
    // console.log("Filedata", {
    //   jobId: companyData.jobIds,
    //   fileName: formData[objeceIndex].file.DisplayName,
    // });
    axios.get(process.env.React_App_baseUrl + "job/DownloadFile", {
      headers: {
        fileName: formData[objeceIndex].reportName + afterDot,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      

      },
      responseType: "blob",
    }
    ).then((res) => {
      if (res.status == 200) {
        //console.log(res);
        


        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let fileName = formData[objeceIndex].originalReportName;
        var json = JSON.stringify(fileName),
          blob = new Blob([res.data], { type: mediaType}),
          url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        // const link = document.createElement('a');
        // link.href = "data:application/octet-stream;base64," + res.data;
        // link.download = 'file.pdf';
        // link.click();

      } else {
        //toasterError('Cannot delete Currency, as job(s) available with the currency that you are trying to delete.')

      }
    }
    )
    // .catch(err => {
    //   toasterError(`${err.response.data}`)
    // });
  }

  return (
    <>
      <Grid item xs={12} sx={{ mt: 1, mb: -2 }}>
        <h4 className={classes.headingText}>Report Info</h4>
      </Grid>

      <Grid item xs={12} sx={{ mb: 0 }}>
        <Paper
          elevation={0}
          className={classes.posRelative}
          sx={{ p: 2, pb: 3 }}
        >
          <Grid container spacing={2} justifyContent="left" alignItems="center">
            {formData.map((fr, i) => (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ mt: 0, mb: -2 }}
                >
                  <Paper elevation={0} className={classes.recordTitleArea}>
                    <Box className={classes.recordTitle}>Report {i + 1}</Box>
                    <Box className={classes.recordLine}></Box>
                    <Box className={classes.recordAction}>
                    {editAccess?<Tooltip title="Delete" placement="top" arrow>
                        <IconButton color="error">
                          <DeleteIcon
                            onClick={() => handleDeleteReportInfo(i)}
                            style={{ fontSize: "20px" }}
                          />
                        </IconButton>
                      </Tooltip>:null}
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={2}>
                  <Autocomplete
                    disablePortal
                    key="year"
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.year}
                    options={yearOption}
                    value={formData[i].reportingYear}
                    // defaultValue={{
                    //   year: fr.reportingYear ? fr.reportingYear : "",
                    // }}
                    onChange={(e, value) => {
                      onChangeHandle(value, "reportingYear", i, "year");
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        key="year"
                        label="Year"
                        variant="standard"
                        required
                        error={
                          reportError ? reportError[i]?.reportingYear : false
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={2}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.name}
                    value={formData[i].reportingPeriod}
                    defaultValue={{
                      name: fr.reportingPeriod ? fr.reportingPeriod : "",
                    }}
                    onChange={(e, value) =>
                      onChangeHandle(value, "reportingPeriod", i, "name")
                    }
                    options={reportingCalOption}
                    // value={["Q1"]}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        setFromData
                        label="Reporting Period"
                        variant="standard"
                        required
                        error={
                          reportError ? reportError[i]?.reportingPeriod : false
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={2}>
                  <DatePicker
                    labelTitle="Publication Date"
                    variant="standard"
                    defaultDate={fr.publicationDate ? fr.publicationDate : null}
                    onChangeDate={(e) => onDateChange(e, i)}
                    required="true"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={2}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={(e, value) => {
                     // console.log("value", value)
                      onChangeHandle(value, "reportingType", i, "reportingType")
                    }
                    }
                    getOptionLabel={(option) => option.reportingType}
                    value={formData[i].reportingType}
                    options={reportTypeOption}
                    // value={["Invester Report"]}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Report Type"
                        variant="standard"
                        required
                        error={
                          reportError ? reportError[i]?.reportingType : false
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={12} lg={12} xl={4}>
                  <TextField
                    disabled
                    id="standard-basic"
                    label="Report Name"
                    value={fr.reportName ? fr.reportName : ""}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    variant="standard"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={1}
                      justifyContent="center"
                      alignItems="self-end"
                    >
                      <Grid item xs>
                        <TextField
                          id="standard-basic"
                          label="Link"
                          defaultValue={fr.link}
                          value={fr.link}
                          onChange={(e, value) => onChangeHandle(e, "link", i)}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          variant="standard"
                          required
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <Tooltip title="Open Link" placement="top" arrow>
                          <IconButton color="secondary">
                            <LinkIcon
                              onClick={() => window.open(fr.link, "_blank")}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>

                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                  <Grid
                    container
                    spacing={3}
                    justifyContent="left"
                    alignItems="center"
                  >                   
                    <Grid item style={{ fontSize: 16 }} xs={12}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        style={{ color: "#00000099" }}
                      >
                        Uploaded File(s):
                      </Typography>
                      {fr.file && fr.file.DisplayName ? (
                        <Grid item style={{ fontSize: 16 }} xs={12}>
                          <TextDocumentIcon
                            // style={{ fontSize: 22, marginRight: "5px", color: '#DB9400' }}
                            style={{ fontSize: 22, marginRight: "5px" }}
                          />{" "}
                          {fr.file.DisplayName}
                          <Tooltip title="File Preview" placement="top" arrow>
                            <IconButton color="secondary">
                              <AttachIcon
                                onClick={(event) =>
                                  openDocumentForView(fr)
                                }
                              />
                            </IconButton>
                          </Tooltip>
                          {editAccess?
                          <Tooltip title="Remove Attachment" placement="top" arrow>
                            <IconButton
                              color="error"
                              onClick={(e) => handleFileClear(i)}
                            >
                              {fileuploadLoading && i === fileupLoading ?
                                <CircularProgress size={20} />
                                :
                                <CancelIcon style={{ fontSize: 18 }} />
                              }
                            </IconButton>
                          </Tooltip>:null}
                          {/* <Grid item xs="auto">
                          <Tooltip title="Preview" placement="top" arrow>
                            <IconButton>
                          <LinkIcon
                            // onClick={() => window.open(linkToCat, "_blank")}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid> */}
                        </Grid>
                      ) : null}
                    </Grid>
                    {fr.originalReportName ? <>
                      <Grid item style={{ fontSize: 16 }} xs={12}>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          style={{ color: "#00000099" }}
                        >
                          Orginal File(s):
                        </Typography>
                        {fr.file && fr.file.DisplayName ? (
                          <Grid item style={{ fontSize: 16 }} xs={12}>
                            <TextDocumentIcon
                              // style={{ fontSize: 22, marginRight: "5px", color: '#DB9400' }}
                              style={{ fontSize: 22, marginRight: "5px" }}
                            />{" "}
                            {fr.originalReportName}
                            <Tooltip title="File Download" placement="top" arrow>
                              <IconButton color="secondary">
                                <DownloadDocumentIcon onClick={() => { handleFileDownload(i) }} />

                              </IconButton>
                            </Tooltip>
                            {editAccess?
                            <Tooltip title="Remove Attachment" placement="top" arrow>
                              <IconButton
                                color="error"
                                onClick={(e) => handleFileClear(i)}
                              >

                                {fileuploadLoading || i === fileupLoading ?
                                  <CircularProgress size={20} />
                                  :
                                  <CancelIcon style={{ fontSize: 18 }} />
                                }
                              </IconButton>
                            </Tooltip>:null}
                            {/* <Grid item xs="auto">
                          <Tooltip title="Preview" placement="top" arrow>
                            <IconButton>
                          <LinkIcon
                            // onClick={() => window.open(linkToCat, "_blank")}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid> */}
                          </Grid>
                        ) : null}
                      </Grid></> : ""}
                  </Grid>

                  {/* <Box mt={2}>
                  <Chip variant="outlined" label="2022-Glencore PLC-Q1 Report.xls"
                    onDelete={handleDelete}
                  />
                </Box> */}
                </Grid>
                 {editAccess?
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                  <Dropzone
                    margin="normal"
                    content={"CSV File"}
                    helperText="Max. of 100 rows allowed"
                    showSelectedFile={false}
                    isFileCleared={isFileCleared}
                    onFilesAdded={(e, fileUpload) => {                      
                     // console.log("Dropzone")
                      onFilesAdded(e, fileUpload, i)

                    }
                    }
                  />
                </Grid>:null}

                {i === formData.length - 1 && editAccess ? (
                  <Grid item xs={12} xl={6}>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="flex-end"
                      alignItems="center"
                      textAlign="right"
                    >
                      {" "}
                      <Grid
                        item
                        xs={12}
                        container
                        spacing={0}
                        sx={{ mt: 1, mb: 0 }}
                        justifyContent="flex-end" textAlign="right">
                        <Button
                          variant="contained"
                          color="accent7"
                          size="small"
                          onClick={handleAddNewFrom}
                          startIcon={<AddIcon style={{ fontSize: 16 }} />}
                        >
                          Add report info
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </>
            ))}
          </Grid>
        </Paper>
        <AlertDialog
          open={openDeleteAlart}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure want to delete Report ${deleteReportInfoData?.index}`}
          action={
            <>
              <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                <Button onClick={() => setOpenDeleteAlart(false)}>No</Button>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button color="secondary"
                    onClick={() => deleteReportInfo(true)}
                  >
                    Yes
                  </Button>
                )}
              </div>
            </>
          }
        />
        {addCustomeReportType ? (
          <Dialog open={true} fullWidth onClose={handleClose}>
            <DialogTitle>Add Report Type</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="email"
                fullWidth
                required
                variant="standard"
                onChange={(event) => {
                  let data = customeReportType;
                  data[event.target.name] = event.target.value;
                  setCustomeReportType(data);
                }}
              />
              <TextField
                margin="dense"
                id="name"
                name="description"
                label="Description"
                type="email"
                fullWidth
                required
                variant="standard"
                onChange={(event) => {
                  let data = customeReportType;
                  data[event.target.name] = event.target.value;
                  setCustomeReportType(data);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <Button onClick={handleAddCustomeReportType}>Add</Button>
              )}
            </DialogActions>
          </Dialog>
        ) : null}
        <AlertDialog
          open={openConfirmDeleteAlart}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={openConfirmDeleteAlartMessage}
          action={
            <>
              <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                <Button onClick={() => setopenConfirmDeleteAlart(false)}>No</Button>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button color="secondary"
                    onClick={() => deleteReportInfo(true)}
                  >
                    Yes
                  </Button>
                )}
              </div>
            </>
          }
        />
        <AlertDialog
          open={openConfirmfileUploadAlart}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={'Are you sure you want to replace the report ?'}
          action={
            <>
              <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                <Button onClick={() => setopenConfirmfileUploadAlart(false)}>No</Button>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button color="secondary"
                    onClick={() => {
                     // console.log('replaceFile: ', replaceFile.i);
                      checkfileupload('', replaceFile.fileUpload, replaceFile.i)
                    }}
                  >
                    Yes
                  </Button>
                )}
              </div>
            </>
          }
        />
        <AlertDialog
          open={openConfirmfileDeleteAlart}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={'Report info has used in variable are you sure you want Delete the file ?'}
          action={
            <>
              <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                <Button onClick={() => setopenConfirmfileDeleteAlart(false)}>No</Button>
                {deleteFileloading ? (
                  <CircularProgress />
                ) : (
                  <Button color="secondary"
                    onClick={() => {
                      handleFileClear(deleteFileIndex, true)
                    }}
                  >
                    Yes
                  </Button>
                )}
              </div>
            </>
          }
        />
      </Grid>
      <ScrollingDialog
        open={openAdobeViewer}
        setOpen={setOpenAdobeViewer}
        // scroll={scroll}
        // setScroll={setScroll}
        dialogClass="darkTitle"
        dialogTitle="File Preview"
        dialogContent={
          <AdobeViewer jobId={adobeViewerData.jobId}
            fileName={adobeViewerData.fileName}
            pageNumber={adobeViewerData.pageNumber}
            crudState={adobeViewerData.crudState}>

          </AdobeViewer>
        }
      />
    </>
  );
};

export default ReportInfo;