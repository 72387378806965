// Default React, useState and Material Components
import React, { useState, useEffect } from "react";
import { Button, Breadcrumbs, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography, Box, } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import SideDrawer from "../../../UIComponent/Drawer";
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing Add & Edit Components
import AddEditStockExchange from "./AddEditStockExchange";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles
import useStyles from "../ManageData.styles";

const StockExchanges = (props) => {
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState([]);
  const [selected, SetSelected] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [state, setState] = useState({ right: false });
  const [isAllSelected, setIsSelected] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [allStockExchanges, setStockExchanges] = useState([]);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [deleteStockExchanges, setDeleteStockExchanges] = useState(null);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [addStockExchangeDrawer, setAddStockExchangeDrawer] = useState(true);
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(48, function (err, res) {
      if (res) {
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])
  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) {
      getAllStockExchanges();
    }
    if (searchValue && reload) { searchStockExchange(searchValue); }
  }, [page, reload, rowsPerPage])
  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) {
      getAllStockExchanges();
    }
    if (searchValue) { searchStockExchange(searchValue); }
  }, [sorting])
  // Declaration of React Hooks End Here

  // Assigning Columns and UI Props
  const columns = [
    { id: "Id", label: "Id", type: "Highlight" },
    { id: "symbol", label: "Stock Exchange Short Form", minWidth: 300, width: '15%', sorting: true },
    { id: "name", label: "Stock Exchange Name", minWidth: 400, sorting: true, truncate: 90, tooltipWithTruncate: true },
  ];

  // This function handles the keyup event
  const handleKeyUp = (event) => {
     // If backspace is pressed and target element is not a button
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      // If search value is empty, get all stock exchanges
      if (searchValue.length === 0) { getAllStockExchanges(); }
    }
    // If enter is pressed and target element is not a button
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
     // Set total count to 0 and page to 0 
      setTotalCount(0);
      setPage(0);
     // Search stock exchange with the value of the target element 
      searchStockExchange(event.target.value);
    }
  };

// This function changes the format of a field name for sorting
const changeSortColumnNameFormat = (fieldName) => {
  // If the fieldName is null or empty, return an empty string
  if (fieldName == null || fieldName == "") { return ""; }
  // If the fieldName is "symbol", set it to "Symbol"
  if (fieldName.toLocaleLowerCase() == "symbol") { fieldName = "Symbol"; }
  // If the fieldName is "name", set it to "Name"
  if (fieldName.toLocaleLowerCase() == "name") { fieldName = "Name"; }
  // Return the formatted fieldName
  return fieldName;
}

  // Function for Open Delete Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") {
      setDeleteStockExchanges(item);
      setOpenDelete(true);
    } else {
      setFormData(item);
      setState({ ...state, right: open, formData: item });
      setCrudState(mode);
    }
  };

 // This function is used to get all stock exchanges
const getAllStockExchanges = () => {
  // Set loading to true while the data is being fetched
  setLoading(true);
  // Create an array of parameters for the API call
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
  // Make an API call to search for stock exchange details
  ApiService.search("stock/GetStockExchangeDetails?",
    {
      // Sort the results based on the sort column and sorting order
      sortColumn: changeSortColumnNameFormat(sorting.sortBy),
      isAscending: sorting.isAscending
    }, prt
  ).then((res) => {
    // If the response status is 200, set the stock exchanges and total count
    if (res.status === 200) {
      setStockExchanges(res.data.data);
      setTotalCount(res.data.totalCount);
      setLoading(false);
    } else {
      // If the response status is not 200, display an error message
      toasterError(`${res.data}`);
      setLoading(false);
    }
  })
    .catch((err) => {
      // Handle any errors that occur during the API call
      setLoading(false);
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
}

  // Function for Global Search
  const globalSearch = (value) => { setSearchValue(value); }
 // This function is used to search stock exchange details
const searchStockExchange = (filterValue) => {
  // Set loading to true while the API call is in progress
  setLoading(true);
  // Create an array of parameters for the API call
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
  // Make an API call to get stock exchange details
  ApiService.search(
    "stock/GetStockExchangeDetails?",
    {
      // Pass filter value, sorting column and sorting order as parameters
      searchText: filterValue,
      sortColumn: changeSortColumnNameFormat(sorting.sortBy),
      isAscending: sorting.isAscending
    }, prt)
    .then((res) => {
      // If the API call is successful, set the stock exchanges, loading and total count
      if (res.status === 200) {
        setStockExchanges(res.data.data);
        setLoading(false);
        setTotalCount(res.data.totalCount);
      } else {
        // If the API call fails, display an error message
        toasterError(`${res.data}`);
        setLoading(false);
      }
    })
    .catch((err) => {
      // If there is an error, set loading to false and handle the error
      setLoading(false);
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
}

// Function to delete a stock exchange
const deleteStockExchange = () => {
  // Set loading state of delete button to true
  setDeleteBtnLoadinng(true);
  // Create data object with active property set to false
  let data = deleteStockExchanges;
  data.active = false;
  // Make API call to delete stock exchange
  ApiService.create(`stock/DeleteStockExchange?stockExchangeId=${data.id}`)
    .then((res) => {
      // If API call is successful, show success message and update stock exchanges list
      if (res.status === 200) {
        toasterSuccess("Stock Exchange deleted successfully!");
        getAllStockExchanges();
        setDeleteBtnLoadinng(false);
        setOpenDelete(false);
      } else {
        // If API call fails, show error message
        toasterError("Something went wrong");
        setOpenDelete(false);
        setDeleteBtnLoadinng(false);
      }
    })
    .catch((err) => {
      // If an error occurs, set loading state of delete button to false and handle the error
      setOpenDelete(false);
      setDeleteBtnLoadinng(false);
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
}

  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Stock Exchange</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">Stock Exchange</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {(access.allowEdit) ? <>
                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: "16px" }} />}>
                  Add Stock Exchange
                </Button></> : null}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className={classes.searchByKeyword}
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {searchValue ? (<IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setSearchValue("");
                                getAllStockExchanges();
                              }}
                            >
                              <ClearIcon style={{ fontSize: 10 }} />
                            </IconButton>) : ("")}
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">
                      Total Records: <span className={classes.totalRecord}>{totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Stock Exchange Table /> */}

              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allStockExchanges ? allStockExchanges : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => { setPage(data); setReload(true); }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setSorting(e);
                  }}
                  isActionButton={{
                    ...(access.allowView && {
                      view: toggleDrawer(true, "View"),
                    }),
                    ...(access.allowEdit && { edit: toggleDrawer(true, "Edit"), }),
                    ...(access.allowDelete && { delete: toggleDrawer(true, "Delete"), }),
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      <SideDrawer
        formComp={
          <AddEditStockExchange
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            variableId={null}
            hideSideDrawer={() => {
              setState({ right: false });
              setSearchValue("");
              setReload(!reload);
            }}
            addStockExchangeDrawer={addStockExchangeDrawer}
          />
        }
        state={state}
        setState={setState}
      />

      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to delete Stock Exchange ${deleteStockExchanges?.name} ?`}
          action={
            <>
              <div className={classes.alertDialogbtn}>
                <Button onClick={() => setOpenDelete(false)} style={{ marginRight: "15px" }}>No</Button>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button onClick={() => deleteStockExchange()} color="secondary">
                    Yes
                  </Button>
                )}
              </div>
            </>
          }
        />
      ) : null}
    </Box>
  )
}


export default StockExchanges;
