import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCompanyData = createAsyncThunk(
  "filter/companyData",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl + "DropDown/GetCompanyDropDownFilter"
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const GetAssetTypeData = createAsyncThunk(
  "filter/assetTypeData",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl + "DropDown/GetAssetTypeDropDownFilter",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const GetAssetData = createAsyncThunk(
  "filter/assetData",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        process.env.React_App_baseUrl + "DropDown/GetAssetDropDownFilter",
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const GetCountryDropDownFilter = createAsyncThunk(
  "filter/countryData",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl + "DropDown/GetCountryDropDownFilter",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const GetRegionDropDownFilter = createAsyncThunk(
  "filter/regionData",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl + "DropDown/GetRegionDropDownFilter",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const GetSubRegionDropDown = createAsyncThunk(
  "filter/subregionData",
  async (arg, { rejectWithValue }) => {
    // const Data = 1;
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl + "DropDown/GetSubRegionDropDownFilter",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
        // {
        //   headers: {
        //     regionId: 2,
        //   },
        // }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const GetCommodityDropDownFilter = createAsyncThunk(
  "filter/commodityDropDown",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl + "DropDown/GetCommodityDropDownFilter",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const GetStatusDropDownFilter = createAsyncThunk(
  "filter/statusDropDown",
  async (arg, { rejectWithValue }) => {
    // const Data = ["Boliden"];
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl + "DropDown/GetStatusDropDownFilter",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const GetPriorityDropDownFilter = createAsyncThunk(
  "filter/priorityDropDown",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl + "DropDown/GetPriorityDropDownFilter",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const getAllUserForFilter = createAsyncThunk(
  "filter/allUsersFilter",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl + "account/GetAllUser",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      // console.log(data, "all Users");
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

/**********DROPDOWNS FOR QUALITY CHECK*********** */
export const GetJobidsDropDownFilter = createAsyncThunk(
  "filter/jobidsDropDown",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl +
          "QualityManagement/GetJobNoNameDropdownforQuality",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const GetCompletedByDropDownFilter = createAsyncThunk(
  "filter/completedbyusersDropDown",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        process.env.React_App_baseUrl + "DropDown/GetAssignedByFilterAsync",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  filters: {
    company: [],
    asset: [],
    assetType: [],
    country: [],
    region: [],
    subRegion: [],
    commodity: [],
    status: [],
    priority: [],
  },
  filtersJob: {
    jobId: [],
    jobName: [],
    jobType: [],
    company: [],
    asset: [],
    assetType: [],
    country: [],
    region: [],
    subRegion: [],
    commodity: [],
    tat: "",
    status: [],
    priority: [],
  },
  filterQuality: {
    assetType: [],
    user: [],
    userBy: [],
    status: [],
    company: [],
    asset: [],
    commodity: [],
    tat: "",
    priority: [],
  },
  filterQualityCheck: {
    JobIds: [],
    asset: [],
    tat: "",
    jobCompletionDate: "",
    completedBy: [],
  },
  filtersRecords: {
    assetType: [],
    user: [],
    userBy: [],
    status: [],
    company: [],
    asset: [],
    commodity: [],
    tat: "",
    priority: [],
  },
  filtersJobPage: {
    assetType: [],
    user: [],
    userBy: [],
    status: [],
    company: [],
    asset: [],
    commodity: [],
    tat: "",
    priority: [],
  },
  filterJobRecords: {
    assetType: [],
    user: [],
    userBy: [],
    status: [],
    company: [],
    asset: [],
    commodity: [],
    tat: "",
    priority: [],
  },
  filterQC: {
    assetType: [],
    user: [],
    userBy: [],
    status: [],
    company: [],
    asset: [],
    commodity: [],
    tat: "",
    priority: [],
  },

  filterReport: {
    companyName: [],
    assetName: [],
    commodities: [],
    reportingFrequency: [],
    tat: "",
  },
  filterMeritQCReports: {
    commodities: [],
    assignedto: [],
    meritqcuser: [],
    meritqccompletion: "",
    formdata: "",
    todate: "",
  },
  filterCRUReviewReports: {
    commodities: [],
    assignedto: [],
    cruqcuser: [],
    cruqccompletion: "",
    formdata: "",
    todate: "",
  },

  filterStatusFrequency: {
    reportingFrequency: [],
  },
  yearsForHistoryData: {
    years: [
      {
        year: 2022,
        id: 1,
        isActive: true,
        createdById: 0,
        createdOn: "2022-11-23T12:37:02.9385283",
      },
    ],
  },
  companyDropDownlist: [],
  assetDropDownlist: [],
  assetTypeDropDownlist: [],
  countryDropDownList: [],
  regionDropDownList: [],
  subRegionDropDownList: [],
  commodityDropDownList: [],
  statusDropDownList: [],
  priorityDropDownList: [],
  jobIdsDropdownList: [],
  completedByUserDropdownList: [],
  userList: [],
  asyncStatus: "initial",
  error: false,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    clearFilterData: (state) => {
      return {
        ...state,
        filters: initialState.filters,
        error: false,
      };
    },
    setFilterAction: (state, { payload }) => {
      state.filters = payload.filters;
    },
    setFilterActionJob: (state, { payload }) => {
      state.filtersJob = payload.filtersJob;
    },
    clearFilterDataJob: (state) => {
      return {
        ...state,
        filtersJob: initialState.filtersJob,
        error: false,
      };
    },
    setFilterJobRecords: (state, { payload }) => {
      // console.log(payload, "payload");
      state.filtersRecords = payload.filtersRecords;
    },
    setYearsForHistoryData: (state, { payload }) => {
      // console.log(payload, "payload");
      // console.log(state.yearsForHistoryData.years, "payload");
      state.yearsForHistoryData = payload.yearsForHistoryData;
    },
    clearFilterJobRecords: (state) => {
      return {
        ...state,
        filtersRecords: initialState.filtersRecords,
        error: false,
      };
    },
    setFilterReports: (state, { payload }) => {
      // console.log(payload, "payload");
      state.filterReport = payload.filterReport;
    },
    clearFilterReports: (state) => {
      return {
        ...state,
        filterReport: initialState.filterReport,
        error: false,
      };
    },
    setFilterMeritQCReports: (state, { payload }) => {
      // console.log(payload, "payload");
      state.filterMeritQCReports = payload.filterMeritQCReports;
    },
    clearFilterMeritQCReport: (state) => {
      return {
        ...state,
        filterMeritQCReports: initialState.filterMeritQCReports,
        error: false,
      };
    },
    setFilterCRUQCReports: (state, { payload }) => {
      // console.log(payload, "payload");
      state.filterCRUReviewReports = payload.filterCRUReviewReports;
    },
    clearFilterCRUQCReport: (state) => {
      return {
        ...state,
        filterCRUReviewReports: initialState.filterCRUReviewReports,
        error: false,
      };
    },

    setFilterJobRecord: (state, { payload }) => {
      // console.log(payload, "payload");
      state.filterJobRecords = payload.filterJobRecords;
    },
    clearFilterJobRecord: (state) => {
      return {
        ...state,
        filterJobRecords: initialState.filterJobRecords,
        error: false,
      };
    },
    setFilterJobpage: (state, { payload }) => {
      // console.log(payload, "payload");
      state.filtersJobPage = payload.filtersJobPage;
    },
    clearFilterJobPage: (state) => {
      return {
        ...state,
        filtersJobPage: initialState.filtersJobPage,
        error: false,
      };
    },
    setFilterQC: (state, { payload }) => {
      
      
      // console.log(payload, "payload");
      state.filterQC = payload.filterQC;
    },
    clearFilterQC: (state) => {
      return {
        ...state,
        filterQC: initialState.filterQC,
        error: false,
      };
    },
    setQualtyFilter: (state, { payload }) => {
      // console.log(payload, "payload");
      state.filterQuality = payload.filterQuality;
    },
    clearQualityFilter: (state) => {
      return {
        ...state,
        filterQuality: initialState.filterQuality,
        error: false,
      };
    },
    setQualtyCheckFilter: (state, { payload }) => {
      // console.log(payload, "payload");
      state.filterQualityCheck = payload.filterQualityCheck;
    },
    clearQualityCheckFilter: (state) => {
      return {
        ...state,
        filterQualityCheck: initialState.filterQualityCheck,
        error: false,
      };
    },
    setStausFilter: (state, { payload }) => {
      // console.log(payload, "payload");
      state.filterStatusFrequency = payload;
    },
    clearStatusFilter: (state) => {
      state.filterStatusFrequency = [];
    },
  },
  extraReducers: {
    [getCompanyData.pending]: (state, { payload }) => {
      state.asyncStatus = "loading";
    },
    [getCompanyData.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.companyDropDownlist = payload;
    },
    [getCompanyData.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [GetAssetTypeData.pending]: (state) => {
      state.asyncStatus = "loading";
    },
    [GetAssetTypeData.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.assetTypeDropDownlist = payload;
    },
    [GetAssetTypeData.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [GetAssetData.pending]: (state) => {
      state.asyncStatus = "loading";
    },
    [GetAssetData.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.assetDropDownlist = payload;
    },
    [GetAssetData.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [GetCountryDropDownFilter.pending]: (state) => {
      state.asyncStatus = "loading";
    },
    [GetCountryDropDownFilter.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.countryDropDownList = payload;
    },
    [GetCountryDropDownFilter.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [GetRegionDropDownFilter.pending]: (state) => {
      state.asyncStatus = "loading";
    },
    [GetRegionDropDownFilter.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.regionDropDownList = payload;
    },
    [GetRegionDropDownFilter.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [GetSubRegionDropDown.pending]: (state) => {
      state.asyncStatus = "loading";
    },
    [GetSubRegionDropDown.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.subRegionDropDownList = payload;
    },
    [GetSubRegionDropDown.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [GetCommodityDropDownFilter.pending]: (state) => {
      state.asyncStatus = "loading";
    },
    [GetCommodityDropDownFilter.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.commodityDropDownList = payload;
    },
    [GetCommodityDropDownFilter.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [GetStatusDropDownFilter.pending]: (state) => {
      state.asyncStatus = "loading";
    },
    [GetStatusDropDownFilter.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.statusDropDownList = payload;
    },
    [GetStatusDropDownFilter.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [GetPriorityDropDownFilter.pending]: (state) => {
      state.asyncStatus = "loading";
    },
    [GetPriorityDropDownFilter.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.priorityDropDownList = payload;
    },
    [GetPriorityDropDownFilter.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    [getAllUserForFilter.pending]: (state) => {
      state.asyncStatus = "loading";
    },
    [getAllUserForFilter.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.userList = payload;
    },
    [getAllUserForFilter.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
    /*****QUALITY CHECK******* */
    [GetJobidsDropDownFilter.pending]: (state) => {
      state.asyncStatus = "loading";
    },
    [GetJobidsDropDownFilter.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.jobIdsDropdownList = payload;
    },
    [GetJobidsDropDownFilter.rejected]: (state) => {
      state.asyncStatus = "failed";
    },

    [GetCompletedByDropDownFilter.pending]: (state) => {
      state.asyncStatus = "loading";
    },
    [GetCompletedByDropDownFilter.fulfilled]: (state, { payload }) => {
      state.asyncStatus = "success";
      state.completedByUserDropdownList = payload;
    },
    [GetCompletedByDropDownFilter.rejected]: (state) => {
      state.asyncStatus = "failed";
    },
  },
});

// console.log(filterSlice);

export const {
  setFilterQC,
  clearFilterQC,
  setFilterJobRecord,
  clearFilterJobRecord,
  setFilterJobpage,
  clearFilterJobPage,
  setQualtyFilter,
  clearQualityFilter,
  setQualtyCheckFilter,
  clearQualityCheckFilter,
  clearFilterData,
  setFilterAction,
  setFilterActionJob,
  clearFilterDataJob,
  setFilterJobRecords,
  clearFilterJobRecords,
  setYearsForHistoryData,
  setFilterReports,
  clearFilterReports,
  setFilterMeritQCReports,
  clearFilterMeritQCReport,
  setFilterCRUQCReports,
  clearFilterCRUQCReport,
} = filterSlice.actions;

export default filterSlice.reducer;
