// Default React, Router and Services Components
import React from "react";
import { Button, Box, Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import MultiSelect from "../../../UIComponent/MultiSelect/MultiSelect";
import DatePicker from "../../../UIComponent/DatePicker";
import { toasterError } from "../../../UIComponent/Toaster";
import Container from '../SourceManagement/TreeContainer';
import { clearFilterJobRecord, clearFilterJobRecords, GetAssetData, setFilterJobRecord, setFilterJobRecords } from "../../../features/filter/filterSlice";
import { callBasedOnLocation, callFromAssetType, callFromComm, callFromPriority, callSource } from "../../../Util/TreeAPI";

// Importing useStyles and Styles
import "../../../../src/index.scss";
import "../../../Resources/scss/layout.scss";
import useStyles from "./jobManagement.styles";
import "react-toastify/dist/ReactToastify.css";
import "react-dropdown-tree-select/dist/styles.css";

const FilterJobRecords = (props) => {
  // Declaration of States and Component Variables Start Here
  const { filterJobRecords, assetDropDownlist } = useSelector(
    (store) => store.filter
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [status, setStatus] = React.useState([]);
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [jobComms, setJobComms] = React.useState([]);
  const [prior, setPrior] = React.useState([]);
  const [jobDate, setJobDate] = React.useState("");
  const [assignedTo, setAssignedTo] = React.useState([]);
  const [assignedBy, setAssignedBy] = React.useState([]);
  const [filters, setFilters] = React.useState({
    company: [], asset: [], assetTypeIds: [], countryIds: [], regionIds: [],
    subRegionIds: [], commodityIds: [], statusIds: [], priorityIds: [],
  });

  const [dependency, setDependency] = React.useState(null);
  const [apiData, setApiData] = React.useState({
    companies: [], location: {}, assetType: [], priority: [], commodity: [],
  });
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);

  const onChangeHandler = (key, value, reason) => {
    const pre = { ...filterJobRecords };
    pre[key] = value;
    if (key === "commodity") {
      setDependency("commodity");
      setApiData({ ...apiData, commodity: value, });
    } else if (key === "priority") {
      setDependency("priority");
      setApiData({ ...apiData, priority: value, });
    } else if (key === "assetType") {
      setDependency("assetType");
      setApiData({ ...apiData, assetType: value, });
    }
    dispatch(setFilterJobRecord({ filterJobRecords: pre }));
  };

  React.useEffect(() => {
    if (dependency === "company") {
      callSource(apiData)
        .then((res) => {
          res.data.location.regions.forEach(el => {
            for (let item of props.locationFilter) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true
                }
              }
            }
            el.children.forEach(it => {
              for (let item of props.locationFilter) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true
                    }
                  }
                }
              }
              it.children.forEach(kt => {
                for (let item of props.locationFilter) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true
                        }
                      }
                    }
                  }
                }
              })
            })
          })
          setJobComms(res.data.commodity)
          setPrior(res.data.priority)
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });

        })
        .catch((err) => console.log(err));
    } else if (dependency === "location") {
      callBasedOnLocation(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
          setJobComms(res.data.commodity)
          setPrior(res.data.priority)
        })
        .catch((err) => console.log(err));
    } else if (dependency === "assetType") {
      callFromAssetType(apiData)
        .then((res) => {
          res.data.location.regions.forEach(el => {
            for (let item of props.locationFilter) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true
                }
              }
            }
            el.children.forEach(it => {
              for (let item of props.locationFilter) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true
                    }
                  }
                }
              }
              it.children.forEach(kt => {
                for (let item of props.locationFilter) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true
                        }
                      }
                    }
                  }
                }
              })
            })
          })
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyFilter(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          setJobComms(res.data.commodity)
          setPrior(res.data.priority)
          props.setDataForFilter({
            ...props.dataForFilter,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "priority") {
      callFromPriority(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach(el => {
            for (let item of props.locationFilter) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true
                }
              }
            }
            el.children.forEach(it => {
              for (let item of props.locationFilter) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true
                    }
                  }
                }
              }
              it.children.forEach(kt => {
                for (let item of props.locationFilter) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true
                        }
                      }
                    }
                  }
                }
              })
            })
          })
          props.setCompanyFilter(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
          });
          setJobComms(res.data.commodity)
          setPrior(res.data.priority)
        })
        .catch((err) => console.log(err));
    } else if (dependency === "commodity") {
      callFromComm(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyFilter.length; i++) {
              if (props.companyFilter[i].value === el.value) {
                if (props.companyFilter[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyFilter[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyFilter[i].children[j].value === item.value
                    ) {
                      if (props.companyFilter[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach(el => {
            for (let item of props.locationFilter) {
              if (item.value === el.value) {
                if (item.checked === true) {
                  el.checked = true
                }
              }
            }
            el.children.forEach(it => {
              for (let item of props.locationFilter) {
                for (let items of item.children) {
                  if (items.value === it.value) {
                    if (items.checked === true) {
                      it.checked = true
                    }
                  }
                }
              }
              it.children.forEach(kt => {
                for (let item of props.locationFilter) {
                  for (let items of item.children) {
                    for (let ite of items.children) {
                      if (ite.value === kt.value) {
                        if (ite.checked === true) {
                          kt.checked = true
                        }
                      }
                    }
                  }
                }
              })
            })
          })
          props.setCompanyFilter(res.data.companies);
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            priority: res.data.priority,
          });
          setJobComms(res.data.commodity)
          setPrior(res.data.priority)
        })
        .catch((err) => console.log(err));
    }
  }, [apiData]);

  const clearFieldHanlder = () => {
    props.resetTreeFilter()
    dispatch(clearFilterJobRecord());
    props.setFilterValue("");
    props.setReload(!props.reload);
  };
 //Function for country selection
 const countryFilter = ()=>{
  let data = []
  for(let item of props.locationFilter){
    for(let items of item.children){
      for(let ites of items.children){
        if(ites.checked === true){
          data.push(ites.value)
        }
      }
    }
  }

  return data
}
  const submitFilter = () => {
    console.log(filterJobRecords.commodity, "filterJobRecords.commodity")
    const formData = {
      assignedToId: filterJobRecords.user.map((use) => use.userId),
      assignedById: filterJobRecords.userBy.map((use) => use.userId),
      statusIds: filterJobRecords.status.map((stat) => stat.id), 
      company: props.companyFilter
        .filter((el) => el.checked === true)
        .map((el) => el.value),
      asset: props.companyFilter
        .map((el) =>
          el.children.filter((it) => it.checked === true).map((tk) => tk.value)
        )
        .filter((item) => item.length > 0)
        .flat(1),
        countryIds: countryFilter(),
      regionIds: props.locationFilter
        .filter((el) => el.checked === true)
        .map((el) => el.value),
      subRegionIds: props.locationFilter
        .map((el) =>
          el.children.filter((it) => it.checked === true).map((tk) => tk.value)
        )
        .filter((item) => item.length > 0)
        .flat(1),
      commodityIds: filterJobRecords.commodity.map((commo) => commo.commodityId?commo.commodityId:commo.id),
       tat: props.tatDate, 
      priorityIds: filterJobRecords.priority.map((prio) => prio.id),
    };
    // this data will be send to the body api
    console.log( formData,"FORMmm")
    props.setFilterValue(formData);
    props.setState({ ...props.state, right: false });
  };

  const getAllStatus = () => {
    ApiService.getAll("DropDown/GetJobStatusFilterAsync")
      .then((res) => {
        if (res.status === 200) {
          setStatus(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

  const getAllCompanies = () => {
    ApiService.getAll("DropDown/GetCompanyDropDownFilter")
      .then((res) => {
        
        if (res.status === 200) {
          setJobCompanies(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

  const getAllCommodities = () => {
    ApiService.getAll("DropDown/GetCommodityDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setJobComms(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

  const getAllPriority = () => {
    ApiService.getAll("DropDown/GetPriorityDropDownFilter")
      .then((res) => {
        if (res.status === 200) {
          setPrior(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

  const getAllassignedTo = () => {
    ApiService.getAll("DropDown/GetAssignedToFilterAsync")
      .then((res) => {
        if (res.status === 200) {
          setAssignedTo(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

  const getAllassignedBy = () => {
    ApiService.getAll("DropDown/GetAssignedByFilterAsync")
      .then((res) => {
        if (res.status === 200) {
          setAssignedBy(res.data);
        } else {
          toasterError(res.data);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  };

  React.useEffect(() => {
    getAllassignedTo();
    getAllassignedBy();
    getAllStatus();
    getAllCompanies();
    dispatch(GetAssetData());
    getAllCommodities();
    getAllPriority();
  }, []);

  const onChangeComp = (currentNode, selectedNodes) => {
    setDependency("company");
    setSelectedCompanies(selectedNodes);
    props.companyFilter.forEach((el) => {
      if (currentNode.checked === true) {
        if (currentNode.value === el.value) {
          el.checked = true;
        } else if (currentNode.parentValue === el.value) {
          el.children.forEach((item) => {
            if (currentNode.value === item.value) {
              item.checked = true;
            }
          });
        }
      } else if (currentNode.checked === false) {
        if (currentNode.value === el.value) {
          el.checked = false;
        } else if (currentNode.parentValue === el.value) {
          el.children.forEach((item) => {
            if (currentNode.value === item.value) {
              item.checked = false;
            }
          });
        }
      }
    });

    const firstParent = props.companyFilter
      .map((el) => {
        let data = {
          label: el.label,
          value: el.value,
          children: el.children.filter((item) => item["checked"] === true),
        };
        return data;
      })
      .filter((el) => el.children.length > 0);
    setApiData({
      ...apiData,
      companies:
        props.companyFilter.filter((el) => el["checked"] === true).length > 0
          ? props.companyFilter.filter((el) => el["checked"] === true)
          : firstParent,
    });
  };
  //Function for location selection
  const onChangeLoc = (currentNode, selectedNodes) => {
    setDependency("location");
  if (selectedNodes.length === 0) {
    props.locationFilter.forEach((el) => {
      el["checked"] = false;
      el.children.forEach((it) => {
        it["checked"] = false;
        it.children.forEach((kt) => {
          kt["checked"] = false;
        });
      });
    });
  }

  if (currentNode.checked === true) {
    if (currentNode.grantParentValue) {
      props.locationFilter.forEach((el) => {
        el.children.forEach((first) => {
          first.children.forEach((second) => {
            if (currentNode.value === second.value) {
              second.checked = true;
              filters.countryIds.push(currentNode.value)
            }
          });
        });
      });
    } else if (currentNode.parentValue) {
      props.locationFilter.forEach((el) => {
        el.children.forEach((first) => {
          if (currentNode.value === first.value) {
            first.checked = true;
          }
          
        });
      });
    } else if (currentNode.value) {
      props.locationFilter.forEach((el) => {
        if (currentNode.value === el.value) {
          el.checked = true;
        }
      });
    }
    
  } else if (currentNode.checked === false) {
    if (
      currentNode.value &&
      !currentNode.parentValue &&
      !currentNode.grantParentValue
    ) {
      props.locationFilter.forEach((el) => {
        if (el.value === currentNode.value) {
          el.checked = false;
        }
      });
    }
    if (
      currentNode.value &&
      currentNode.parentValue &&
      !currentNode.grantParentValue
    ) {
      props.locationFilter.forEach((el) => {
        el.children.forEach((first) => {
          if (currentNode.value === first.value) {
            first.checked = false;
          }
        });
      });
    } else {
      props.locationFilter.forEach((el) => {
        if (
          el.children.map((it) => it.parentValue)[0] === currentNode.value
        ) {
          el.children.forEach((item) => {
            if (item.parentValue === currentNode.value) {
              item.checked = false;
            }
          });
        }
      });
    }

    if (
      currentNode.value &&
      currentNode.parentValue &&
      currentNode.grantParentValue
    ) {
      props.locationFilter.forEach((el) => {
        el.children.forEach((first) => {
          first.children.forEach((second) => {
            if (currentNode.value === second.value) {
              second.checked = false;
            }
          });
        });
      });
    }
  }

  const firstParent = props.locationFilter
    .map((el) => {
      let data = {
        label: el.label,
        value: el.value,
        children: el.children.filter((item) => item["checked"] === true),
      };
      return data;
    })
    .filter((el) => el.children.length > 0);

  const grandParent = props.locationFilter
    .map((el) => {
      let data = {
        label: el.label,
        value: el.value,
        children: el.children.map((item) => {
          let items = {
            label: item.label,
            value: item.value,
            children: item.children.filter(
              (item) => item["checked"] === true
            ),
          };

          return items;
        })
      };

      return data;
    })
  
  const finalChecker = () => {
    let data = [];
    for (let i = 0; i < grandParent.length; i++) {
      for (let j = 0; j < grandParent[i].children.length; j++) {
        if (grandParent[i].children[j].children.length > 0) {
          data.push(grandParent[i]);
        }
      }
    }
    return data;
  };
  setApiData({
    ...apiData,
    location: {
      regions:
        props.locationFilter.filter((el) => el["checked"] === true).length > 0
          ? props.locationFilter.filter((el) => el["checked"] === true)
          : firstParent.length > 0
          ? firstParent
          : finalChecker(),
    },
  });


};


  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Add & Edit Frequency Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Container
            data={props.companyFilter}
            onChange={onChangeComp}
            showPartiallySelected={true}
            texts={{ placeholder: "Owner" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Container
            data={props.locationFilter}
            onChange={onChangeLoc}
            showPartiallySelected={true}
            texts={{ placeholder: "Location" }}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterJobRecords.commodity}
            items={jobComms}
            label="Commodities"
            selectAllLabel={{ commodityName: "Select all" }}
            getOptionLabel={(option) => option.commodityName}
            getOptionSelected={(option, anotherOption) =>
              option.commodityName === anotherOption.commodityName
            }
            role="commodity"
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterJobRecords.user}
            items={assignedTo}
            label="Assigned to"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            role="user"
          />
        </Grid> 
       {/*  <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterJobRecords.userBy}
            items={assignedBy}
            label="Assigned by"
            selectAllLabel={{ name: "Select all" }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, anotherOption) =>
              option.name === anotherOption.name
            }
            role="userBy"
          />
        </Grid>  */}
        <Grid item xs={12}>
          <DatePicker
            labelTitle="TAT"
            placeHolder="DD-MM-YYYY"
            variant="standard"
            past={false}
            defaultDate={props.tatDate}
            onChange={onChangeHandler}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              props.setTatDate(
                selectedDate.getFullYear() +
                "-" +
                (selectedDate.getMonth() + 1) +
                "-" +
                selectedDate.getDate()
              );
            }}
          />
        </Grid> 
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterJobRecords.status}
            getOptionSelected={(option, anotherOption) =>
              option.statusName === anotherOption.statusName
            }
            items={status}
            label="Status"
            selectAllLabel={{ statusName: "Select all" }}
            getOptionLabel={(option) => option.statusName}
            role="status"
          />
        </Grid> 

      <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterJobRecords.priority}
            getOptionSelected={(option, anotherOption) =>
              option.priorityName === anotherOption.priorityName
            }
            items={prior}
            label="Priority"
            selectAllLabel={{ priorityName: "Select all" }}
            getOptionLabel={(option) => option.priorityName}
            role="priority"
          />
        </Grid> 

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn" sx={{ mr: 2 }}
                color="accent5" onClick={submitFilter}>
                Filter
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn reset-color"
                color="accent3" onClick={clearFieldHanlder}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );
};

export default FilterJobRecords;