// Default React, Router and Services Components
import React from 'react'

// Importing useStyles
import "./styles.scss";

const Footer = () => {
    return (
        // Footer
        <footer>
            <div className="copyright-text text-center">
                &copy; {(new Date().getFullYear())} <a href="https://www.crugroup.com/" target={"_blank"}>CRU International Ltd</a>. All rights reserved.
            </div>
        </footer>
    )
}
export default Footer;