// Default React, Router and Services Components
import React, { useState, useEffect } from "react";

// Importing Material Component and service 
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { AddIcon, DeleteIcon, OpenEnrollmentIcon } from "@fluentui/react-icons-mdl2";
import axios from "axios";

// Importing Custom Component 
import AdobeViewer from "../JobManagement/AdobeViewer";
import { ScrollingDialog } from "../../../UIComponent";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing useStyles
import useStyles from "./QualityManagement.styles";
import CommonService from "../../../Services/CommonService";


export default function AddReport(props) {
  const classes = useStyles();
  // Declaration of States and Component Variables Start Here
  const [open, setOpen] = React.useState(props.open);
  const [reportOption, setReportOption] = useState([]);
  const [adobeViewerData, setAdobeViewerData] = useState({
    jobId: 0, fileName: "", pageNumber: 0, crudState: "",
  });
  const [openAdobeViewer, setOpenAdobeViewer] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectedReportingPeriod, setselectedReportingPeriod] = useState(null);
  const [yearDropdown, setYearDropDown] = useState([]);
  const [selectedReportingYear, setSelectedReportingYear] = useState(null);
  const [reportingPrtionDropdown, setreportingPrtionDropdown] = useState([]);
  const [addloading, setaddloading] = useState(false);
  const [replaceVariableForm, setreplaceVariableForm] = useState({
    variableValueId: [],
    reportingPeriodId: null,
    values: [{ reportingId: { reportName: "" }, pageNumber: null, },],
  });

  // Declaration of States and Component Variables Ebd Here

  // Declaration of React Hooks Start Here
  useEffect(() => {
    if (reportOption.length < 1) { getreortInfo(); }
    if (replaceVariableForm.variableValueId.length < 1) {
      setreplaceVariableForm({ ...replaceVariableForm, variableValueId: props.selectedRow, });
    }
    if (reportingPrtionDropdown.length < 1) { getReportingPeriod(); }
    getReportingYear();
  }, []);
// Declaration of React Hooks End Here

//Method for get reporting year
  const getReportingYear = async () => {
    try {
      const response = await axios.get(`${process.env.React_App_baseUrl}reportingcalendar/GetReportingYear`,{headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }});
      const { data } = response;
      setYearDropDown(data);
    } catch (error) { ErroHandling(error, function (res) {
      toasterError(res)}) }
  };

  //Method for get reporting Period
  const getReportingPeriod = () => {
    axios.get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingPeriod",{
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
    })
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter(function (item) {
            return item.id !== 7;
          });

          setreportingPrtionDropdown(data);
        } else {
          toasterError("Something went wrong");
        }
      })
      .catch((err) => { ErroHandling(err, function (res) {
        toasterError(res)}) 
      });
  };

  

  const OpenViewer = (data) => {
    // setOpenAdobeViewer(true);
    // setAdobeViewerData({
    //   jobId: props.jobId,
    //   fileName: data.reportingId.reportName + ".pdf",
    //   pageNumber: data.pageNumber,
    //   crudState: "view",
    // });

    let reportName = `${data.reportingId.reportName}.pdf`;
    
    let senData =  {
        jobId: props.jobId,
        fileName: data.reportingId.reportName + ".pdf",
        pageNumber: data?.pageNumber ? data.pageNumber:1,
        crudState: "Edit",
       };
    localStorage.setItem("filePreview", JSON.stringify(senData));
   // CommonService.AddTokenFromSessionStorageToLocalStorage();
    window.open("#app/data-collections/file-preview", '_blank', 'noopener,noreferrer');
  };


  //Method for get report info
  const getreortInfo = () => {
    axios.post(process.env.React_App_baseUrl + "updatejobrecord/GetReportInfo",
      null, { headers: { jobAssetId: props.jobAssetId,'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
     }, }
    )
      .then((res) => {
        if (res.status === 200) { setReportOption(res.data); }
      });
  };

  const handleClose = () => { setOpen(false); props.setopenAddReport(); };


  //Method for all validation feilds
  const checkValidation = (data) => {
    let result;
    data.forEach((el) => {
      if (!el.pageNumber && el.reportingId) {
        toasterError("Please fill all required fields");
        result = false;
      }
      if (!el.pageNumber) {
        toasterError("Please fill all required fields");
        result = false;
      }
      if (!el.reportingId) {
        toasterError("Please fill all required fields");
        result = false;
      }
      if (el.pageNumber && el.reportingId) {
        result = true;
      }
    });
    return result;
  };

  //Method for add report
  const addReport = () => {
    // console.log(replaceVariableForm);
    let finalVariableIds = [];
    for (let k of props.selectedRow) {
      for (let i of k) {
        if (i.periodId === selectedReportingPeriod.id) {
          if (i.yearId === selectedReportingYear.id) {
            finalVariableIds.push(i);
          }
        }
      }
    }
    const Checkdata = {
      variableValueId: finalVariableIds.map((el) => el.id),
      files: replaceVariableForm.values.map((el) => {
        return {
          reportingId: el.reportingId.assetReportId,
          pageNumber: el.pageNumber,
        };
      }),
    };
    setloading(true);
    if (selectedReportingPeriod) {
      let replaceData = replaceVariableForm;
      replaceData.reportingPeriodId = selectedReportingPeriod.id;
      if (checkValidation(replaceVariableForm.values)) {
        let replaceData = replaceVariableForm;
        let data = replaceVariableForm.values;
        data.forEach((da) => {
          da.reportingId = da.reportingId.assetReportId;
        });
        replaceData.values = data;
        axios.post(process.env.React_App_baseUrl + "QualityManagement/AddVariableValueReport", Checkdata,{
          headers: {
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
        })
          .then((res) => {
            setloading(false);
            if (res.status === 200) {
              toasterSuccess("Report added successfully");
              props.reload();
              handleClose();
            } else {
              toasterError("Something went wrong");
            }
          })
          .catch((er) => {
            setloading(false);
            ErroHandling(er, function (res) {
              toasterError(res)}) 
                      });
      } else {
        setloading(false);
      }
    } else {
      setloading(true);
      toasterError("Please select a reporting period ");
    }
  };

  const replaceData = () => {
    let finalVariableIds = [];
    for (let k of props.selectedRow) {
      for (let i of k) {
        if (i.periodId === selectedReportingPeriod.id) {
          if (i.yearId === selectedReportingYear.id) {
            finalVariableIds.push(i);
          }
        }
      }
    }

    const Checkdata = {
      variableValueId: finalVariableIds.map((el) => el.id),
      files: replaceVariableForm.values.map((el) => {
        return {
          reportingId: el.reportingId.assetReportId,
          pageNumber: el.pageNumber,
        };
      }),
    };
    setloading(true);
    if (selectedReportingPeriod) {
      let replaceData = replaceVariableForm;
      replaceData.reportingPeriodId = selectedReportingPeriod.id;
      if (checkValidation(replaceVariableForm.values)) {
        let replaceData = replaceVariableForm;
        let data = replaceVariableForm.values;
        data.forEach((da) => {
          da.reportingId = da.reportingId.assetReportId;
        });
        replaceData.values = data;
        axios.post(process.env.React_App_baseUrl + "QualityManagement/ReplaceVariableValueReport", Checkdata,{
          headers: {
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
        })
          .then((res) => {
            setloading(false);
            if (res.status === 200) {
              toasterSuccess("Report replaced successfully");
              props.reload();
              handleClose();
            } else {
              toasterError("Something went wrong");
            }
          })
          .catch((er) => {
            setloading(false);
            ErroHandling(er, function (res) {
              toasterError(res)})           });
      } else {
        setloading(false);
      }
    } else {
      setloading(true);
      toasterError("Please select a reporting period ");
    }
  };

  //Method for add new report
  const addAnotherReport = () => {
    let data = replaceVariableForm.values;
    data.push({
      reportingId: { reportName: "" },
      pageNumber: "",
    });
    setreplaceVariableForm({ ...replaceVariableForm, values: data });
  };

  //Method for delete report
  const handleDeleteReportInfo = (i) => {
    let val = replaceVariableForm.values;
    val.splice(i, 1);
    setreplaceVariableForm({ ...replaceVariableForm, values: val });
  };

  return (

    <Box>
      <Dialog fullWidth={true} maxWidth={"md"} open={props.open}
        keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            {/* Title */}
            <Grid item xs={12}>
              <Typography id="modal-modal-title" variant="h5">
                Replace Report
              </Typography>
            </Grid>

            <Box sx={{ mt: 1, mb: 2, pr: 2 }}>
              <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    key="Reporting Period"
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.name}
                    options={reportingPrtionDropdown}
                    onChange={(e, value) => {
                      // console.log(value);
                      setselectedReportingPeriod(value);
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        key="Reporting Period"
                        label="Reporting Period"
                        variant="standard"
                        required
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    key="Year"
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.year}
                    options={yearDropdown}
                    onChange={(e, value) => {
                    //  console.log(value);
                      setSelectedReportingYear(value);
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        key="Year"
                        label="Year"
                        variant="standard"
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            {replaceVariableForm &&
              replaceVariableForm.values.map((val, i) => (
                <Box className={classes.jobShortDetails}>
                  <Grid container spacing={3} alignItems="center" justifyContent="flex-end">
                    <Grid item xs={12}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={1} justifyContent="center" alignItems="self-end">
                          <Grid item xs>
                            <Autocomplete
                              disablePortal
                              key="Select Report"
                              id="combo-box-demo"
                              getOptionLabel={(option) => option.reportName}
                              options={reportOption}
                              value={val.reportingId}
                              onChange={(e, value) => {
                                console.log(value);
                                let oldData = replaceVariableForm.values;
                                if (value) {
                                  let isAlreadyUse = oldData.filter(
                                    (d) =>
                                      d.reportingId.assetReportId ===
                                      value.assetReportId
                                  );
                                  if (isAlreadyUse.length > 0) {
                                    toasterError(
                                      "Please select a different Report"
                                    );
                                  } else {
                                    oldData[i].reportingId = value;
                                  }
                                } else {
                                  oldData[i].reportingId = { reportName: "" };
                                }
                                setreplaceVariableForm({
                                  ...replaceVariableForm,
                                  values: oldData,
                                });
                              }}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  key="Select Report"
                                  label="Select Report"
                                  variant="standard"
                                  required
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs="auto">
                            <Button variant="contained" color="accent5" size="small"
                              onClick={() => { OpenViewer(val, 1); }}
                              startIcon={<OpenEnrollmentIcon style={{ fontSize: 16 }} />}>
                              Open Document
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={1} justifyContent="center" alignItems="self-end">
                          <Grid item xs>
                            <TextField
                              id="standard-basic"
                              label="Page No"
                              onChange={(e) => {
                                let oldD = replaceVariableForm.values;
                                oldD[i].pageNumber = e.target.value;
                                setreplaceVariableForm({
                                  ...replaceVariableForm,
                                  values: oldD,
                                });
                              }}
                              fullWidth
                              required
                              variant="standard"
                            />
                          </Grid>
                          <Grid item xs="auto">
                            {replaceVariableForm.values.length > 1 ? (
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton color="error">
                                  <DeleteIcon onClick={() => handleDeleteReportInfo(i)} style={{ fontSize: "20px" }} />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid item xs={8} textAlign="right">
                      {replaceVariableForm.values.length - 1 === i ? (
                        <Grid item xs="auto">
                          <Button variant="contained" color="accent7" size="small" onClick={addAnotherReport}
                            startIcon={<AddIcon style={{ fontSize: 16 }} />}>
                            Add Report
                          </Button>
                        </Grid>
                      ) : null}
                    </Grid>

                  </Grid>
                </Box>
              ))}
          </Box>
        </DialogContent>

        <DialogActions>
          <Box sx={{ mt: 1, mb: 2, pr: 2 }}>
            <Grid container spacing={2} justifyContent="flex-start" alignItems="self-end">
              <Grid item xs="auto">
                <Button disabled={loading} variant="contained" color="primary" onClick={addReport}>
                  {addloading ? (<CircularProgress size={20} color="inherit" />) : ("Add")}
                </Button>
              </Grid>

              <Grid item xs="auto">
                <Button disabled={addloading} variant="contained" color="accent1" onClick={replaceData}>
                  {loading ? (<CircularProgress size={20} color="inherit" />) : ("Replace")}
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button variant="contained" color="accent3" onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* <ScrollingDialog
            open={openAdobeViewer}
            setOpen={setOpenAdobeViewer}
            dialogClass="darkTitle"
            dialogTitle="File Preview"
            dialogContent={
              <AdobeViewer
                jobId={adobeViewerData.jobId}
                fileName={adobeViewerData.fileName}
                pageNumber={adobeViewerData.pageNumber}
                crudState={adobeViewerData.crudState}
              ></AdobeViewer>
            }
          /> */}

        </DialogActions>
      </Dialog>
    </Box>
  );
}