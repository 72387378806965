// Default React, useState and Material Components
import React from "react";
import {
  Button,
  Box,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Tooltip,
  IconButton,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

// Importing Custom Components
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
// Import Material & Fluent UI Icons
import AddIcon from "@mui/icons-material/Add";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  CirclePlusIcon,
  DeleteIcon,
  ErrorBadgeIcon,
  TextDocumentIcon,
} from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";
import MultiSelect from "../../../UIComponent/MultiSelect/MultiSelect";
import ApiService from "../../../Services/ApiService";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
import axios from "axios";
import AddReportInfo from "./AddReportInfo";
import SideDrawer from "../../../UIComponent/Drawer";
import CommonService from "../../../Services/CommonService";
import AlertDialog from "../../../UIComponent/AlertDialog";
const AddUpdateInfo = (props) => {
  console.log("PROPPP", props);
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [formData, setFormData] = React.useState({
    id: 0,
    isCRUCompany: false,
    ownerId: props.preselectedOwner.ownerGuid,
    companyName: props.preselectedOwner.owner,
    jobAssetId: props.jobAssetId,
    assetId: props.preselectedAsset?.assetGUID,
    assetName: props.preselectedAsset?.assetName,
    commoditiesID: props.preselectedOwner.commodityIds,
    changeTypeId: 0,
    yearId: 0,
    sourceFile: [],
    comment: "",
  });
  const [comment, setComment] = React.useState("");
  const [selectedsourceFile, setSelectedsourceFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [addCustomeOwner, setAddCustomeOwner] = React.useState(false);
  const [customeOwner, setCustomeOwner] = React.useState({
    name: "",
  });
  const [state, setState] = React.useState({ right: false });
  const [showReportInfo, setShowReportInfo] = React.useState(false);
  //USESTATE FOR DROPDOWN VALUES
  const [ownerdropdown, setOwnerDropdown] = React.useState([]);
  const [assetdropdown, setAssetDropdown] = React.useState([]);
  const [commoditydropdown, setCommodityDropdown] = React.useState([]);
  const [selectedCommodities, setSelectedCommodities] = React.useState([]);
  const [typeofchangedropdown, setTypeofChangeDropdown] = React.useState([]);
  const [yeardropdown, setYearDropdown] = React.useState([]);
  const [sourcefiledropdown, setSourceFileDropdown] = React.useState([]);
  //USESTATE FOR NEW ENTRY
  const [newEntry, setNewEntry] = React.useState(false);
  const [forCompany, setForCompany] = React.useState(false);
  const [tempCompanyName, setTempCompanyName] = React.useState("");
  const [initialValue, setInitialValue] = React.useState(false);
  //USESTATE TO SAVE AND EDIT THE DATA
  const [editData, setEditData] = React.useState(true);
  const [buttonStatus, setButtonStatus] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [defaultOwner, setDefaultOwner] = React.useState(null);
  const [defaultCommodities, setDefaultCommodities] = React.useState(null);
  const [defaultChangetype, setDefaultChangeType] = React.useState(null);
  const [defaultYear, setDefaultYear] = React.useState(null);
  const [defaultSourcefile, setDefaultSourceFile] = React.useState([
    {
      reportInfoId: 0,
      reportName: "",
      sourceFileId: 0,
      pageNo: 0,
      isDeleted: false,
      isEdited: false,
    },
  ]);
  const [removeIndex, setRemoveIndex] = React.useState(null);
  const [order, setOrder] = React.useState(null);
  const [reportInfoId, setReportInfoIdValue] = React.useState(null);
  const [confirmPopUp, setConfirmPopUp] = React.useState(false);
  const [preselectedAsset, setPreselectedAsset] = React.useState(null);
  const [pgno, setPgNo] = React.useState(null);
  const [crudState, setCrudState] = React.useState("");
  const [jobstate, setJobState] = React.useState(0);
  // Top 100 films

  React.useEffect(() => {
    if (defaultSourcefile.length === 0) {
      setDefaultSourceFile([
        {
          reportInfoId: 0,
          reportName: "",
          sourceFileId: 0,
          pageNo: 0,
          isDeleted: false,
          isEdited: false,
        },
      ]);
    }
  }, [defaultSourcefile.length]);

  const top100Films = [
    { id: 1, label: "The Shawshank Redemption", year: 1994 },
    { id: 2, label: "The Godfather", year: 1972 },
    { id: 3, label: "The Godfather: Part II", year: 1974 },
    { id: 4, label: "The Dark Knight", year: 2008 },
    { id: 5, label: "12 Angry Men", year: 1957 },
    { id: 6, label: "Schindler's List", year: 1993 },
    { id: 7, label: "Pulp Fiction", year: 1994 },
  ];

  const toggleDrawer = (open, mode) => (event, item) => {
    console.log("MODE", mode);
    setState({ ...state, right: open });
    setShowReportInfo(true);
    setCrudState(mode);
  };

  React.useEffect(() => {
    if (props.preselectedOwner?.commodityIds && props.preselectedOwner?.ownerGuid) {
      if (props.crudState === "Add") {
        ownerdropdown.forEach((rData, i) => {
          if (rData.guid === props.preselectedOwner.ownerGuid.toUpperCase()) {
            setDefaultOwner(ownerdropdown[i]);
          }
        });
        const preselectedCommodities = [];
        commoditydropdown.forEach((rData, i) => {
          props.preselectedOwner?.commodityIds.forEach((item) => {
            if (rData.id === item) {
              preselectedCommodities.push(commoditydropdown[i]);
            }
          });
        });
        setDefaultCommodities(preselectedCommodities);
      }
    }
  }, [
    ownerdropdown,
    props,
    props.preselectedOwner?.ownerGuid,
    props.preselectedOwner?.commodityIds,
  ]);


  React.useEffect(() => {
    if (props.crudState === "Edit") {
      let data = {
        id: props.prefillData.id,
        jobAssetId: formData.jobAssetId,
        isCRUCompany: formData.isCRUCompany,
        ownerId: props.prefillData.ownerGuid,
        companyName: props.prefillData.ownerName,
        assetId: props.prefillData.assetGuid,
        assetName: props.prefillData.assetName,
        commoditiesID: props.prefillData.ownerUpdateCommodityList?.map(
          (e) => e.commodityId
        ),
        changeTypeId: props.prefillData.changeTypeId,
        yearId: props.prefillData.yearId,
        sourceFile: props.prefillData.ownerUpdateReportInfoList
          ? props.prefillData.ownerUpdateReportInfoList.map((e) => ({
              reportInfoId: e.reportInfoMappingId,
              sourceFileId: e.jobAssetReportInfoId,
              reportName: e.reportName,
              pageNo: e.pageNo,
              isDeleted: false,
              isEdited: false,
            }))
          : [
              {
                isDeleted: false,
              },
            ],

        comment: props.prefillData.comment,
      };
      setFormData(data);
      console.log(data);
      ownerdropdown.forEach((rData, i) => {
        if (rData.guid === props.prefillData.ownerGuid) {
          setDefaultOwner(ownerdropdown[i]);
        }
      });
      const preselectedCommodities = [];
      commoditydropdown.forEach((rData, i) => {
        props.prefillData.ownerUpdateCommodityList.forEach((item) => {
          if (rData.id === item.commodityId) {
            preselectedCommodities.push(commoditydropdown[i]);
          }
        });
      });
      setDefaultCommodities(preselectedCommodities);

      typeofchangedropdown.forEach((rData, i) => {
        if (rData.id === props.prefillData.changeTypeId) {
          setDefaultChangeType(typeofchangedropdown[i]);
        }
      });
      console.log(defaultCommodities);
      yeardropdown.forEach((rData, i) => {
        if (rData.id === props.prefillData.yearId) {
          setDefaultYear(yeardropdown[i]);
        }
      });
      const preselectedSourceFiles = [];
      /* sourcefiledropdown.forEach((rData, i) => {
        props.prefillData.ownerUpdateReportInfoList?.forEach((item) => {
          console.log(item);
          if (rData.id === item.jobAssetReportInfoId) {
            preselectedSourceFiles.push(item);
          }
        });
      }); */
      props.prefillData.ownerUpdateReportInfoList?.forEach((item) => {
        console.log(item);
        preselectedSourceFiles.push(item);
      });
      if (preselectedSourceFiles.length > 0) {
        setDefaultSourceFile(preselectedSourceFiles);
      }
      console.log("hell", preselectedSourceFiles);
      const preselectedPageNos =
        props.prefillData.ownerUpdateReportInfoList.map((item) => item.pageNo);
      setPgNo(preselectedPageNos);
    }
  }, [
    ownerdropdown,
    commoditydropdown,
    typeofchangedropdown,
    yeardropdown,
    sourcefiledropdown,
    props,
    props.prefillData,
  ]);

//     if (props.crudState === "Add") {
//       console.log(formData.ownerId, 'ownerr');
//       console.log(formData.commoditiesID, 'commoditiess');
//   ownerdropdown.forEach((rData, i) => {
//     if (rData.guid === formData.ownerId) {
//       setDefaultOwner(ownerdropdown[i]);
//       console.log(ownerdropdown[i], 'ownerr');
//     }
//   });
//   const preselectedCommodities = [];
//   commoditydropdown.forEach((rData, i) => {
//     formData.commoditiesID.forEach((item) => {
//       if (rData.id === item) {
//         preselectedCommodities.push(commoditydropdown[i]);
//       }
//     });
//   });
//   setDefaultCommodities(preselectedCommodities);
//   console.log(preselectedCommodities, 'commoditiess');
// };
  const finalData = {
    //editCompanyId: 0,
    //editAssetId: 0,
    id: formData.id,
    isCRUOwner: formData.isCRUCompany,
    // isCRUCompany: formData.isCRUCompany,
    jobAssetId: formData.jobAssetId,
    ownerGuid: formData.ownerId,
    ownerName: formData.companyName,
    assetGuid: formData.assetId,
    assetName: formData.assetName,
    commodities: formData.commoditiesID ? formData.commoditiesID : [],
    changeTypeId: formData.changeTypeId,
    yearId: formData.yearId,
    sourceFile: formData.sourceFile ? formData.sourceFile : [],
    //pageNo: formData.pageNo,
    comments: formData.comment ? formData.comment : "",
    //isCRUAsset: formData.isCruAsset,
  };

  console.log(defaultOwner);
  React.useEffect(() => {
    setSelectedCommodities(defaultCommodities);
  }, [defaultCommodities]);
  /*********API CALLS TO POPULATE DROPDOWN VALUES*********** */
  const GetOwnerDropdown = () => {
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0
    ) {
      return;
    }
    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetCorrectOwnerDropdown",
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setOwnerDropdown(res.data);
        console.log(res.data);
      });
  };

  const GetCommodityDropdown = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetCommodityDropDownFilter",
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setCommodityDropdown(res.data);
        console.log(res.data);
      });
  };
  const GetTypeofChangesDropdown = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetChangeTypeDropdown",
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setTypeofChangeDropdown(res.data);
        console.log(res.data);
      });
  };
  const GetYearDropdown = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "ManageOwnership/GetReportingYears",
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setYearDropdown(res.data);
        console.log(res.data);
      });
  };
  const GetSourceFileDropdown = () => {
    axios
      .post(
        process.env.React_App_baseUrl + "ManageOwnership/GetSourceFileDropdown",
        null,
        {
          headers: {
            jobAssetId: props.jobAssetId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setSourceFileDropdown(res.data);
        console.log(res.data);
      });
  };
  // Declaration of States and Component Variables End Here
  React.useEffect(() => {
    setFormData({
      ...formData,
      sourceFile: [{ deleted: true }],
    });
    GetOwnerDropdown();
    //GetAssetDropdown();
    GetCommodityDropdown();
    GetTypeofChangesDropdown();
    GetYearDropdown();
    GetSourceFileDropdown();
  }, []);
  console.log(formData);
  /*******SAVE UPDATE****** */
  console.log(finalData);
  const addFormData = async () => {
    setLoading(true);

    // Perform validation checks
    console.log("finalData:", finalData);
    if (
      finalData.ownerGuid || finalData.ownerName &&
      finalData.assetName &&
      finalData.changeTypeId !== 0 &&
      finalData.yearId !== 0
    ) {
      /* if (
        finalData.sourceFile &&
        finalData.sourceFile[0] &&
        finalData.sourceFile[0].pageNo === 0
      ) */
      // Validate the page number if source files are selected
      /*     if (finalData.sourceFile && finalData.sourceFile.length > 0) {
        for (let i = 0; i < finalData.sourceFile.length; i++) {
          const file = finalData.sourceFile[i];
          if (file.pageNo === 0) {
            toasterError(
              `Please enter a valid page number for source file ${i + 1}`
            );
            setLoading(false);
            return;
          }
        }
      } else if (finalData.sourceFile && finalData.sourceFile[0]) {
        // Check if source file is selected and page number is provided (if selected)
        if (!finalData.sourceFile[0].pageNo) {
          toasterError(
            "Please enter the page number for the selected source file"
          );
          setLoading(false);
          return;
        }
      } */

      // Make the HTTP POST request
      axios
        .post(
          process.env.React_App_baseUrl +
            "ManageOwnership/CompanyOwnershipSaveUpdates",
          finalData,
          {
            headers: {
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
          }
        )
        .then(function (response) {
          // Update state and display success message
          props.setRefresh(!props.refresh);
          toasterSuccess("Update Info Added successfully");
          setSuccessMessage("Update Info Added successfully");
          setLoading(false);
          props.hideSideDrawer();
          setEditData(false);
        })
        .catch(function (error) {
          console.log(error);
          ErroHandling(error, function (res) {
            console.log(res);
            toasterError(res);
          });
          setLoading(false);
        });
    } else {
      // Display error message for missing fields
      toasterError("Please check the required fields");
      setLoading(false);
    }
  };

  /*******EDIT UPDATE****** */

  const editedFormData = () => {
    /*    finalData.editCompanyId = editCompId;
    finalData.editchangeTypeId = editedchangeTypeId;
    finalData.commodityId = testId;
    finalData.assetId = assetsId;
    (finalData.sourceFileId = sourceFileId),
      (finalData.pageNo = pageNo),
      (finalData.yearId = yearId),
      (finalData.comments = comments), */

    // Log the updated source files

    console.log(finalData);
    setLoading(true);
    if (
      finalData.ownerName &&
      finalData.assetName &&
      finalData.changeTypeId !== 0 &&
      finalData.changeTypeId !== null &&
      finalData.yearId !== 0 &&
      finalData.yearId !== null
    ) {
      axios
        .post(
          process.env.React_App_baseUrl +
            "ManageOwnership/CompanyOwnershipEditUpdates",
          finalData,
          {
            headers: {
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
          }
        )
        .then(function (response) {
          // console.log(response);
          props.setRefresh(!props.refresh);
          toasterSuccess("Update Info Edited Successfully!");
          props.hideSideDrawer();
          setButtonStatus(true);
          setLoading(false);
        })
        .catch(function (error) {
          ErroHandling(error, function (res) {
            toasterError(res);
          });
          setLoading(false);
        });
    } else {
      toasterError("Please check the required fields");
      setLoading(false);
    }
  };
  const EditData = (id) => {
    setLoading(true);

    // Perform validation checks
    console.log("finalData:", finalData);
    if (
      finalData.ownerName &&
      //finalData.assetName &&
      finalData.changeTypeId !== 0 &&
      finalData.yearId !== 0
    ) {
      /* if (
        finalData.sourceFile &&
        finalData.sourceFile[0] &&
        finalData.sourceFile[0].pageNo === 0
      ) */
      // Validate the page number if source files are selected
      if (finalData.sourceFile && finalData.sourceFile.length > 0) {
        for (let i = 0; i < finalData.sourceFile.length; i++) {
          const file = finalData.sourceFile[i];
          if (file.pageNo === 0) {
            toasterError(
              `Please enter a valid page number for source file ${i + 1}`
            );
            setLoading(false);
            return;
          }
        }
      } else if (finalData.sourceFile && finalData.sourceFile[0]) {
        // Check if source file is selected and page number is provided (if selected)
        if (!finalData.sourceFile[0].pageNo) {
          toasterError(
            "Please enter the page number for the selected source file"
          );
          setLoading(false);
          return;
        }
      }

      // Make the HTTP POST request
      axios
        .post(
          process.env.React_App_baseUrl +
            "ManageOwnership/CompanyOwnershipEditUpdates",
          finalData,
          {
            headers: {
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
          }
        )
        .then(function (response) {
          // Update state and display success message
          props.setRefresh(!props.refresh);
          toasterSuccess("New Owner Added!");
          setSuccessMessage("Update Info Edited successfully");
          setLoading(false);
          props.hideSideDrawer();
          setEditData(false);
        })
        .catch(function (error) {
          console.log(error);
          ErroHandling(error, function (res) {
            console.log(res);
            toasterError(res);
          });
          setLoading(false);
        });
    } else {
      // Display error message for missing fields
      toasterError("Please check the required fields");
      setLoading(false);
    }
  };
  //Handle add source file and page no
  const handleAddLink = (e) => {
    const links = [...formData?.sourceFile];

    links.push("");

    let item = { ...formData, sourceFile: links };

    setFormData(item);
  };
  const RemoveData = (e, reportInfoMappingId, index) => {
    setRemoveIndex(index);
    //setOrder(reportType);
    setReportInfoIdValue(reportInfoMappingId);
    setConfirmPopUp(true);
  };
  React.useEffect(() => {}, [removeIndex, order, reportInfoId]);
  const RemoveDataAdd = (e, index) => {
    setRemoveIndex(index);
    //setOrder(reportType);
    //setReportInfoIdValue(reportInfoMappingId);
    setConfirmPopUp(true);
  };
  React.useEffect(() => {}, [removeIndex, order, reportInfoId]);
  const handleRemoveLink = (e, index) => {
    const links = [...formData?.sourceFile];

    links.splice(index, 1);

    let item = { ...formData, sourceFile: links };

    setFormData(item);
  };

  const handleChange = (e, value, i, field) => {
    console.log(field);
    const links = [...formData?.sourceFile];
    if (field === "sourceFile") {
      links[i] = {
        sourceFileId: value ? value.id : null,
        pageNo: links[i]?.pageNo || 0, // Preserve the existing page number value
        deleted: false,
      };
      setSelectedsourceFile(value); // Update the selected source file
      if (!value === null || !value === undefined) {
        setDefaultSourceFile(value);
      }
      console.log(value);
    } else if (field === "pageNo") {
      links[i] = {
        sourceFileId: links[i]?.sourceFileId || null, // Preserve the existing source file index value
        pageNo: e.target.value !== "" ? parseInt(e.target.value) : 0,
        deleted: false,
      };
    }
    const updatedFormData = { ...formData, sourceFile: links };
    setFormData(updatedFormData);
    const preedit = {
      //  ...defaultSourcefile,
      sourceFile: links,
    };
    //setDefaultSourceFile(preedit);
    console.log(updatedFormData);
  };

  const handleRemoveLinkEdit = () => {
    console.log("Before deletion - defaultSourceFile:", defaultSourcefile);
    console.log("Before deletion - formData:", formData);

    setDefaultSourceFile((prevSourceFile) => {
      const updatedSourceFile = [...prevSourceFile];
      updatedSourceFile.splice(removeIndex, 1); // Replace the item in the array
      return updatedSourceFile;
    });
    setPgNo((prevSourceFile) => {
      const updatedSourceFile = [...prevSourceFile];
      updatedSourceFile.splice(removeIndex, 1); // Replace the item in the array
      return updatedSourceFile;
    });

    setFormData((prevFormData) => {
      const updatedSourceFileData = prevFormData.sourceFile.map((file) => {
        console.log(file.reportInfoId);
        if (file.reportInfoId === reportInfoId) {
          console.log("hh", file);
          return {
            ...file,
            isDeleted: true,
          };
        }
        return file;
      });

      return {
        ...prevFormData,
        sourceFile: updatedSourceFileData,
      };
    });

    console.log("After deletion - defaultSourceFile:", defaultSourcefile);
    console.log("After deletion - formData:", formData);
  };

  //Handle edit ource file page no
  console.log(defaultSourcefile);
  const handleAddLinkEdit = (e) => {
    const updatedSourceFiles = [...defaultSourcefile];
    updatedSourceFiles.push(null); // Add an empty source file to the array

    const updatedPgno = [...pgno];
    updatedPgno.push(""); // Add an empty page number to the array

    setDefaultSourceFile(updatedSourceFiles);
    setPgNo(updatedPgno);
  };

  /*******POPUP TO ADD A NEW OWNER NAME*********/
  const onChangeHandleOwner = (value, event, i, valuePlace) => {
    console.log(event, value);
    if (value) {
      if (event === "name" && value.name === "Boliden" /* "Others" */) {
        setAddCustomeOwner(!addCustomeOwner);
      }
    }
  };
  console.log(addCustomeOwner);
  const handleClose = () => {
    setAddCustomeOwner(!addCustomeOwner);
  };
  const handleAddCustomeOwner = () => {
    if (customeOwner.name.length > 0) {
      setLoading(true);
      ApiService.create("updatejobrecord/AddReportingType", customeOwner)
        .then((res) => {
          setLoading(false);
          GetOwnerDropdown();
          if (res.status === 200) {
            toasterSuccess("Owner added successfully ");
          } else {
            toasterError("Something went wrong");
          }
          setAddCustomeOwner(!addCustomeOwner);
        })
        .catch((err) => {
          setLoading(false);
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        });
    } else {
      toasterError("Please fill all the required fields");
    }
  };

  /*   const handleChangeSource = (e, value, index, field) => {
    const updatedDefaultSourcefile = [...defaultSourcefile];
    if (field === "sourceFile") {
      updatedDefaultSourcefile[index] = value;
      console.log(updatedDefaultSourcefile);
    } else if (field === "pageNo") {
      updatedDefaultSourcefile[index].pageNo = e.target.value;
      console.log(updatedDefaultSourcefile);
    }
    setDefaultSourceFile(updatedDefaultSourcefile);
  }; */
  const handleChangeSource = (e, value, index, field, reportInfoMappingId) => {
    console.log("val", value);
    const updatedDefaultSourcefile = [...defaultSourcefile];
    console.log(...defaultSourcefile);
    if (field === "sourceFile") {
      updatedDefaultSourcefile[index] = value;
      updatedDefaultSourcefile[index].reportInfoMappingId = reportInfoMappingId;
    } else if (field === "pageNo") {
      updatedDefaultSourcefile[index].pageNo = e.target.value;
    }
    setDefaultSourceFile(updatedDefaultSourcefile);

    // Update pgno with the modified page number
    const updatedPgno = [...pgno];
    updatedPgno[index] = e.target.value;
    setPgNo(updatedPgno);

    // Update formData with the modified source files
    console.log(updatedDefaultSourcefile);
    const updatedSourceFiles = updatedDefaultSourcefile.map(
      (sourceFile, i) => ({
        reportInfoId: sourceFile ? sourceFile?.reportInfoMappingId || 0 : 0,
        sourceFileId: sourceFile
          ? sourceFile.id || sourceFile.jobAssetReportInfoId
          : null,
        pageNo: updatedPgno[i] || 0,
        isDeleted: false,
        isEdited: true,
      })
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      sourceFile: updatedSourceFiles,
    }));
  };

  const [fileuploadLoading, setFileuploadLoading] = React.useState(false);
  const uploadFile = (data) => {
    setFileuploadLoading(true);
    let fileName;
    if (
      data.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      fileName = data.reportName + ".xlsx";
    }
    if (data.type === "application/vnd.ms-powerpoint") {
      fileName = data.reportName + ".ppt";
    }
    if (data.type === "application/pdf") {
      fileName = data.DisplayName;
    }
    if (data.type === "application/msword") {
      fileName = data.reportName + ".doc";
    }
    if (
      data.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      fileName = data.reportName + ".docx";
    }
    const myNewFile = new File([data], `${fileName}`, {
      type: data.type,
    });
    var formdata = new FormData();

    formdata.append("file", myNewFile);
    axios
      .post(
        process.env.React_App_baseUrl +
          `ManageOwnership/UploadFile?jobId=${
            props.jobId
          }&checkDuplicate=${true}`,
        formdata,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
            checkDuplicate: true,
            jobId: props.jobId,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          toasterSuccess("File uploaded  successfully");
        }
        // setReportInfoDataValue(reportInfoDataValueIntial)
        setFileuploadLoading(false);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
        setFileuploadLoading(false);
      });
  };
/*   const openDocument = (rowData,pageNumber=1) => {
    //var jobdetails = tempData?.state;
    let sendData = {
      jobId: props.jobId,
      fileName: rowData + ".pdf",
      pageNumber: pageNumber,
      crudState: "Edit",
    };
    CommonService.openFilePreview(sendData);
  }; */
  const openDocument = (file, pageNumber = 1) => {
    if (pageNumber === "") {
      pageNumber = 1;
    }
    //var jobdetails = tempData?.state;
    var reportName = file + ".pdf";
    var senData = {
      jobId: props.jobId,
      fileName: reportName,
      pageNumber: Number(pageNumber),
      crudState: "Edit",
    };
    localStorage.setItem("filePreview", JSON.stringify(senData));
    //CommonService.AddTokenFromSessionStorageToLocalStorage();
    window.open(
      "#app/ownership-changes/file-preview",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      {/* Layout for Add and Edit */}
      <Box className={classes.popupArea}>
        {/* Title for Side Drawer */}
        <Box className={classes.popupHeading}>
          <Typography id="modal-modal-title" variant="h5">
            {props.crudState} Update
          </Typography>
        </Box>

        {/* Add & Edit Commodity Form */}
        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ mt: -1 }}>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              spacing={0}
            >
              <Grid item xs={12} textAlign="right">
                <Button
                  variant="contained"
                  className="add-color"
                  color="accent7"
                  size="small"
                  onClick={toggleDrawer(true, "Add")}
                  startIcon={<AddIcon style={{ fontSize: 16 }} />}
                >
                  Add Source File
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: -2 }}>
            {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={ownerdropdown}
              getOptionLabel={(option) => option.name}
              //value={ownerdropdown}

              onChange={(e, value) => {
                onChangeHandleOwner(value, "name", {
                  name: value && value.name ? value.name : "",
                  id: value && value.id ? value.id : null,
                });

                
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Owner"
                  required
                  variant="standard"
                />
              )}
            /> */}

            <Autocomplete
              freeSolo
              clearOnEscape
              required={true}
              id="size-small-standard size-small-standard"
              size="small"
              options={ownerdropdown}
              getOptionLabel={(option) => option.companyName}
              isOptionEqualToValue={(option, value) =>
                option.companyName === value.companyName
              }
              noOptionsText={"No Owner has mentioned in the Input"}
              value={defaultOwner}
              onChange={(e, value) => {
                //setInitialCompanyName(value.companyName.length);
                //setcompanyValue(value)
                console.log(value, "Data checker");
                if (value.companyName === "Others") {
                  //setForCompany(true);
                  setNewEntry(!newEntry);
                  //setTempCompanyName("");
                  //setInitialValue(true);
                  setFormData({ ...formData,companyName: value.companyName, isNewOwner: true });
                } else if (value.companyName !== "Others") {
                  //setInitialValue(false);
                  //setForCompany(false);
                  setNewEntry(false);
                  console.log(value.companyName);
                  setFormData({
                    ...formData,
                    companyName: value.companyName,
                    ownerId: value.guid,
                    isCRUOwner: value.isCRUCompany,
                  });
                  setDefaultOwner({
                    ...defaultOwner,
                    companyName: value.companyName,
                    ownerId: value.guid,
                    isCRUOwner: value.isCRUCompany,
                  });
                }
              }}
              renderOption={(props, allCompanies) => (
                <li {...props} key={allCompanies.companyName}>
                  {allCompanies.companyName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Owner"
                  placeholder="Owner"
                />
              )}
            />
          </Grid>
          {newEntry && (
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                size="small"
                id="size-small-standard"
                label="New Owner"
                variant="standard"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    ownerName: e.target.value,
                    isCRUOwner: false,
                  });
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              disabled
              id="combo-box-demo"
              options={top100Films}
              value={props?.preselectedAsset?.assetName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Asset"
                  //required
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelect
              onChange={(e, value) => {
                if (value.find((item) => item.commodityName === "Select all")) {
                  // If "Select all" is checked, select all commodities
                  setSelectedCommodities(commoditydropdown);
                  console.log(selectedCommodities);
                } else {
                  setSelectedCommodities(value);
                  console.log(value);
                }

                setFormData({
                  ...formData,

                  //commodityName: value.map((item) => item.commodityName),
                  commoditiesID: value.map((item) => item.id),
                  //commodityName: value.map((item) => item.commodityName),
                });
                setDefaultCommodities(value);
                /*    setDefaultCommodities({
                  ...defaultCommodities,
                  ...value,
                  //commodityName: value.map((item) => item.commodityName),
                  commoditiesID: value.map((item) => item.id),
                  //commodityName: value.map((item) => item.commodityName),
                });*/
              }}
              value={selectedCommodities}
              items={commoditydropdown || []}
              label="Commodities"
              selectAllLabel={{ commodityName: "Select all" }}
              option={commoditydropdown}
              getOptionLabel={(option) => option.commodityName}
              getOptionSelected={(option, anotherOption) =>
                option.commodityName === anotherOption.commodityName
              }
              role="commodity"
            />
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={typeofchangedropdown}
              value={defaultChangetype}
              getOptionLabel={(option) => option.changeType}
              onChange={(value, e) => {
                console.log(e);
                setFormData({
                  ...formData,
                  changeType: e && e.changeType ? e.changeType : "",
                  changeTypeId: e && e.id ? e.id : null,
                });
                setDefaultChangeType({
                  ...defaultChangetype,
                  changeType: e && e.changeType ? e.changeType : "",
                  changeTypeId: e && e.id ? e.id : null,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type of Change"
                  required
                  variant="standard"
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={yeardropdown}
              value={defaultYear}
              getOptionLabel={(option) => option.year}
              onChange={(value, e) => {
                setFormData({
                  ...formData,
                  year: e && e.year ? e.year : "",
                  yearId: e && e.id ? e.id : null,
                });
                setDefaultYear({
                  ...defaultYear,
                  year: e && e.year ? e.year : "",
                  yearId: e && e.id ? e.id : null,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Year"
                  required
                  variant="standard"
                />
              )}
            />
          </Grid>

          {/*   <Grid item xs={12}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="self-end"
            >
              <Grid item xs>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Source File"
                      variant="standard"
                    />
                  )}
                />
              </Grid>

              <Grid item xs="auto">
                <Tooltip title="Open Document" placement="top" arrow>
                  <IconButton color="accent6" aria-label="add to shopping cart">
                    <TextDocumentIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Grid> */}

          {/* <Grid item xs={12}>
          <Box>
            <Grid
              container
              spacing={1}
              justifyContent="self-start"
              alignItems="self-end"
            >
              <Grid item xs="auto">
                <TextField
                  id="standard-basic"
                  label="Page No"
                  fullWidth
                  required
                  variant="standard"
                  sx={{ width: 100 }}
                />
              </Grid>

              <Grid item xs="auto">
                <Tooltip title="Delete" placement="top" arrow>
                  <IconButton color="error">
                    <DeleteIcon style={{ fontSize: "20px" }} />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs="auto">
                <Tooltip title="Add" placement="top" arrow>
                  <IconButton color="secondary" style={{ fontSize: "20px" }}>
                    <CirclePlusIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Grid> */}

          {props.crudState === "Add" ? (
            <>
              {formData?.sourceFile &&
                formData?.sourceFile.map((linki, i) => (
                  <>
                    <Grid item xs={12} key={i}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          alignItems="self-end"
                        >
                          <Grid item xs>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={sourcefiledropdown}
                              getOptionLabel={(option) => option.reportName}
                              onChange={(e, value) => {
                                //console.log("value", e, value, i);
                                handleChange(e, value, i, "sourceFile");
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Source File"
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs="auto">
                            <Tooltip
                              title="Open Document"
                              placement="top"
                              arrow
                            >
                              <IconButton
                                color="accent6"
                                aria-label="add to shopping cart"
                                disabled={!selectedsourceFile} // Disable the icon when no source file is selected
                                onClick={() => {
                                  openDocument(
                                    selectedsourceFile?.reportName,
                                    linki?.pageNo
                                  );
                                }}
                              >
                                <TextDocumentIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} key={i}>
                      <Box sx={{ ml: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="self-start"
                          alignItems="self-end"
                        >
                          <Grid item xs="auto">
                            <TextField
                              id="standard-basic"
                              label="Page No"
                              type="number"
                              // fullWidth
                              /* required={
                                formData?.sourceFile &&
                                formData.sourceFile.length > 0
                              } */
                              variant="standard"
                              onChange={(e) =>
                                handleChange(e, null, i, "pageNo")
                              }
                              defaultValue={linki.pageNo}
                              //value={defaultSourcefile[i]?.pageNo || 0}
                              sx={{ width: 100 }}
                            />
                          </Grid>
                          <Grid item>
                            {i === 0 && (
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton color="error" disabled>
                                  <DeleteIcon
                                    style={{ fontSize: "20px" }}
                                    onClick={(e) => {
                                      //handleRemoveLink(e);
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {i === 0 && (
                              <Tooltip title="Add" placement="top" arrow>
                                <IconButton
                                  color="secondary"
                                  style={{ fontSize: "20px" }}
                                  onClick={(e) => {
                                    handleAddLink(e);
                                  }}
                                >
                                  <CirclePlusIcon />
                                </IconButton>
                              </Tooltip>
                            )}

                            {i > 0 && (
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton color="error">
                                  <DeleteIcon
                                    style={{ fontSize: "20px" }}
                                    onClick={(e) => {
                                      //handleRemoveLink(e);
                                      RemoveDataAdd(e, i);
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </>
                ))}
            </>
          ) : (
            <>
              {console.log(defaultSourcefile)}

              {defaultSourcefile &&
                defaultSourcefile?.map((option, i) => {
                  console.log(defaultSourcefile, i);
                  return (
                    <React.Fragment key={i}>
                      <Grid item xs={12}>
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent="center"
                            alignItems="self-end"
                          >
                            <Grid item xs>
                              <Autocomplete
                                disablePortal
                                id={`combo-box-demo-sourcefile-${i}`}
                                options={sourcefiledropdown}
                                value={defaultSourcefile[i] || null}
                                getOptionLabel={(option) => option.reportName}
                                onChange={(e, value) =>
                                  handleChangeSource(
                                    e,
                                    value,
                                    i,
                                    "sourceFile",
                                    defaultSourcefile[i]
                                      ? defaultSourcefile[i].reportInfoMappingId
                                      : 0
                                  )
                                }
                                /*  onChange={(e, value) => 
                                          handleSourceFileChange(e, value, i);
                                        } */
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Source File"
                                    variant="standard"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs="auto">
                              <Tooltip
                                title="Open Document"
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  color="accent6"
                                  aria-label="add to shopping cart"
                                  disabled={!defaultSourcefile[i]} // Disable the icon when no source file is selected
                                  onClick={() => {
                                    openDocument(
                                      option?.reportName,
                                      option?.pageNo
                                    );
                                  }}
                                >
                                  <TextDocumentIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ ml: 1 }}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent="self-start"
                            alignItems="self-end"
                          >
                            <TextField
                              id={`standard-basic-pageno-${i}`}
                              label="Page No"
                              type="number"
                              fullWidth
                              /* required={sourcefiledropdown.length > 0} */
                              variant="standard"
                              onChange={(e, value) =>
                                handleChangeSource(e, value, i, "pageNo")
                              }
                              //onChange={(e) => handlePageNoChange(e, i)}
                              value={pgno ? (pgno[i] ? pgno[i] : "") : ""}
                              sx={{ width: 100 }}
                            />
                            {i === 0 && (
                              <Grid item xs="auto">
                                <Tooltip title="Delete" placement="top" arrow>
                                  <IconButton
                                    color="error"
                                    onClick={(e) => {
                                      RemoveData(
                                        e,
                                        defaultSourcefile[i]
                                          ? defaultSourcefile[i]
                                              .reportInfoMappingId
                                          : 0,
                                        i
                                      );
                                    }}
                                  >
                                    <DeleteIcon style={{ fontSize: "20px" }} />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                            {i === 0 && (
                              <Grid item xs="auto">
                                <Tooltip title="Add" placement="top" arrow>
                                  <IconButton
                                    color="secondary"
                                    style={{ fontSize: "20px" }}
                                    onClick={(e) => {
                                      handleAddLinkEdit(e);
                                    }}
                                  >
                                    <CirclePlusIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                            {i > 0 && (
                              <Grid item xs="auto">
                                <Tooltip title="Delete" placement="top" arrow>
                                  <IconButton
                                    color="error"
                                    onClick={(e) => {
                                      console.log(
                                        e,
                                        defaultSourcefile[i]
                                          ? defaultSourcefile[i]
                                              .reportInfoMappingId
                                          : 0,
                                        i
                                      );
                                      RemoveData(
                                        e,
                                        defaultSourcefile[i]
                                          ? defaultSourcefile[i]
                                              .reportInfoMappingId
                                          : 0,
                                        i
                                      );
                                    }}
                                  >
                                    <DeleteIcon style={{ fontSize: "20px" }} />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </>
          )}
          {/*     {props.prefillData.ownerUpdateReportInfoList?.length === 0 ? (
            <>
              <Grid item xs={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    alignItems="self-end"
                  >
                    <Grid item xs>
                      <Autocomplete
                        disablePortal
                        id={`combo-box-demo-sourcefile-0`}
                        options={sourcefiledropdown}
                        value={null}
                        getOptionLabel={(option) => option.reportName}
                        onChange={(e, value) => {
                          handleChangeSource(e, value, 1, "sourceFile", 0);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Source File"
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Open Document" placement="top" arrow>
                        <IconButton
                          color="accent6"
                          aria-label="add to shopping cart"
                          disabled
                        >
                          <TextDocumentIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ ml: 1 }}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="self-start"
                    alignItems="self-end"
                  >
                    <TextField
                      id={`standard-basic-pageno-0`}
                      label="Page No"
                      fullWidth
                      required={sourcefiledropdown.length > 0}
                      variant="standard"
                      onChange={(e, value) =>
                        handleChangeSource(e, value, 0, "pageNo", 0)
                      }
                      value=""
                      sx={{ width: 100 }}
                    />
                    <Grid item xs="auto">
                      <Tooltip title="Delete" placement="top" arrow>
                        <IconButton
                          color="error"
                          onClick={(e) => {
                            handleRemoveLinkEdit(e);
                          }}
                        >
                          <DeleteIcon style={{ fontSize: "20px" }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Add" placement="top" arrow>
                        <IconButton
                          color="secondary"
                          style={{ fontSize: "20px" }}
                          onClick={(e) => {
                            handleAddLinkEdit(e);
                          }}
                        >
                          <CirclePlusIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </>
          ) : null} */}
          <Grid item xs={12}>
            <TextField
              InputProps={{ readOnly: props.crudState === "View" && true }}
              fullWidth
              id="standard-basic"
              label="Comments "
              variant="standard"
              value={
                props.crudState === "Add" ? null : props?.prefillData?.comment
              }
              onChange={(e) => {
                props.setPreFillData({
                  ...props.prefillData,
                  comment: e.target.value,
                });
                setFormData({ ...formData, comment: e.target.value });
              }}
              multiline
              minRows={3}
              maxRows={3}
            />
          </Grid>

          <Grid item xs={12}>
            {props.crudState === "View" ? null : (
              <Grid
                container
                alignItems="center"
                justifyContent="left"
                spacing={2}
                className={classes.alertDialogbtn}
              >
                <Grid item xs={"auto"}>
                  <LoadingButton
                    buttonName={props.crudState === "Add" ? "Save" : "Update"}
                    buttonClass="save-color2"
                    clickHandler={
                      props.crudState === "Add" ? addFormData : editedFormData
                    }
                    //isLoading={loading}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <Button
                    variant="contained"
                    className="cancel-color"
                    color="accent3"
                    onClick={() =>
                     { props.setState({ ...props.state, right: false })
                      props.hideSideDrawer()}
                    }
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
      {/* POPUP TO ADD NEW OPERATOR  */}
      {addCustomeOwner ? (
        <Dialog open={true} fullWidth onClose={handleClose}>
          <DialogTitle>Add New Owner</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              name="name"
              label="Owner"
              type="email"
              fullWidth
              required
              variant="standard"
              onChange={(event) => {
                let data = customeOwner;
                data[event.target.name] = event.target.value;
                setCustomeOwner(data);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Button onClick={handleAddCustomeOwner}>Add</Button>
            )}
          </DialogActions>
        </Dialog>
      ) : null}

      {confirmPopUp ? (
        <AlertDialog
          open={confirmPopUp}
          title={"Confirmation"}
          maxWidth={"sm"}
          // ${deleteJobNumber}
          description={`Are you sure you want to delete  ?`} /* Id : ${deleteData} */
          action={
            <Grid container justifyContent="right">
              <Grid item xs={"auto"}>
                <Button
                  onClick={() => setConfirmPopUp(false)}
                  style={{ marginRight: "15px" }}
                >
                  No
                </Button>
              </Grid>
              <Grid item xs={"auto"}>
                <Button
                  onClick={() => {
                    setConfirmPopUp(false);
                    props?.crudState === "Edit"
                      ? handleRemoveLinkEdit()
                      : handleRemoveLink();
                  }}
                  color="secondary"
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          }
        />
      ) : null}
      <SideDrawer
        formComp={
          <AddReportInfo
            state={state}
            setState={setState}
            jobId={props.jobId}
            jobAssetId={props.jobAssetId}
            crudState={crudState}
             getSourceFile={() => {
              GetSourceFileDropdown();
            }}             
            hideSideDrawer={() => {              
              setState({ right: false });
              //getGridData();
              GetSourceFileDropdown();
            }}
          />
        }
        state={state}
        setState={setState}
      />
    </>
  );
};

export default AddUpdateInfo;
