import * as React from "react";
import {
  styled,
  Box,
  Popper,
  Checkbox,
  TextField,
  Autocomplete,
  Divider,
  ClickAwayListener,
  AutocompleteCloseReason,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { makeStyles } from "@mui/styles";

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `0px solid ${theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
    }`
}));

const useStyles = makeStyles((theme) => ({
  selectAllOptions: {
    backgroundColor: "white",
    height: "54px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    fontSize: 16,
    borderBottomLeftRadius: 1,
    borderBottomRightRadius: 1,
    padding: "6px 16px",
    lineHeight: "20px",
    "&:hover":{
      backgroundColor: "#f5f5f5",
      cursor: "pointer",
    },

  },
  customMultiSelect: {
    '& .MuiPaper-root': {
      borderRadius: 0,
      boxShadow: 0,
    },
    '& .MuiAutocomplete-listbox': {
      paddingTop: 0,
    },

  },

})
)

export default function MultiSelect({
  items,
  label,
  selectAllLabel,
  noOptionsText,
  onChange,
  getOptionLabel,
  getOptionSelected,
  values,
  defaultChecked,
  role,
  dropLable,
  placholder,
  required
}) {
  
  const [value, setValue] = React.useState([]);
  const [checkAll, setCheckAll] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const checkAllChange = (event) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      setValue(items);
    } else {
      setValue([]);
    }
    onChange(items)
  };

  React.useEffect(()=>{
    if(values){
      setValue(values)
    }
  },[values])

  const handleClickAway = (e) => {
    setOpen(false);
  };
  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Autocomplete
          multiple
          fullWidth
          disableCloseOnSelect
          limitTags={3}
          id="checkboxes-tags-demo"
          options={items}
          defaultChecked = {defaultChecked}
          value={value}
          open={open}
          onChange={(event, newValue, reason) => {
            if (reason === "selectOption") {
              setValue(newValue);
            } else if (reason === "removeOption") {
              setCheckAll(false);
              setValue(newValue);
            } else if (reason === "clear") {
              setValue([]);
              setCheckAll(false);
            }
            onChange(newValue)
          }}
          onClose={(e, reason) => {
           // console.log("On Close: ", reason);
            if (reason === "escape") {
              setOpen(false);
            }
          }}
          onOpen={() => {
            setOpen(true);
          }}
          PopperComponent={(param) => (
            <PopperStyledComponent {...param} sx={{ boxShadow: 1, borderRadius: 1, }} >
              <Box className={classes.selectAllOptions}>
                <Checkbox
                  checked={checkAll}
                  onChange={checkAllChange}
                  id="check-all"
                  sx={{ marginRight: "8px" }}
                  onMouseDown={(e) => e.preventDefault()}
                />
                <span style={{ paddingTop: '3px', display: 'inline-flex' }}>Select All</span>
              </Box>
              <Box {...param} className={classes.customMultiSelect} />

            </PopperStyledComponent>
          )}
          getOptionLabel={(option) => option[dropLable]}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected || checkAll}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
              />
              {option[dropLable]}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label={label} variant="standard" placeholder={placholder} required={required}/>
          )}
        />
      </Box>
    </ClickAwayListener>
  );
}



