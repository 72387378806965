import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: 600 // Table Max Height
  },
  colHighlight: {
    padding: theme.spacing(0, 0, 0, 1),
  },
  tableHeader: {
    padding: theme.spacing(0.5, 0.75) + '!important',
    whiteSpace: "normal",
    borderBottom: "1px solid #d7d7d7 !important",
    height: "46px",
    fontWeight: "bold !important"
  },
  tableCell: {
    /* whiteSpace: "pre-wrap", */
    wordBreak: "normal",
    wordWrap: "normal",
    borderBottom: "1px solid #d7d7d7 !important",
    padding: theme.spacing(0.5, 0.75) + '!important',
    height: "46px"
  },
  tableActionCell: {
    padding: "0px 10px !important",
    borderBottom: "1px solid #d7d7d7 !important",
    height: "46px",
    padding: theme.spacing(0.5, 0.75) + '!important',
  },
  cellNoWrap: {
    whiteSpace: "nowrap"
  },
  cellYesWrap: {
    whiteSpace: "normal"
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  helperText: {
    display: 'block',
    whiteSpace: "nowrap",
    fontSize: 12,
    //color:theme.palette.text.secondary
  },
  tableLink: {
    cursor: 'pointer',
    color: '#01579b',
    padding: theme.spacing(1.5, 1.5),
    "&:hover": {
    },
  },
  overviewIcons: {
    width: 40,
    display: "inline-block",
    textAlign: "center",
  },
  overviewValues: {
    minWidth: 40,
    maxWidth: 130,
    display: "inline-block",
    textAlign: 'right',
    margin: theme.spacing(0, 0, 0, 0.75),
  },
  cruTheadLeftSep: {
    padding: theme.spacing(0, 0.75) + '!important',
    whiteSpace: "normal",
    fontWeight: 'bold !important',
    height: "46px",
    borderBottom: "1px solid #d7d7d7",
    borderLeft: "1px solid #d7d7d7 !important",
  },
  cruTheadRightSep: {
    padding: theme.spacing(0, 0.75) + '!important',
    whiteSpace: "normal",
    fontWeight: 'bold !important',
    height: "46px",
    borderBottom: "1px solid #d7d7d7",
    borderRight: "1px solid #d7d7d7 !important",
  },
  tableCellRightSep: {
    padding: theme.spacing(0, 0.75) + '!important',
    height: "46px",
    borderBottom: "1px solid #d7d7d7",
    borderRight: "1px solid #d7d7d7 !important",
  },
  tableCellLeftSep: {
    padding: theme.spacing(0, 0.75) + '!important',
    height: "46px",
    borderBottom: "1px solid #d7d7d7",
    borderLeft: "1px solid #d7d7d7 !important",
  },
  linkText: {
    fontSize: "14px !important",
    textAlign: "center",
    cursor: "pointer",
    color: "#01579b"
  },
  tableCellSorting: {
    padding: theme.spacing(0, 3.25, 0, 0.75) + '!important',
    height: "46px",
  },
}));

export default useStyles;