// Default React, useState and Material Components
import React, { useState } from "react";
import { Button, Box, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import AutocompleteText from "../../../UIComponent/Autocomplete";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddEditSubRegion = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [defaultValue, setDefaultValue] = useState(null);
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultRegionValue, setDefaultRegionValue] = useState(null);
  const [SubRegionData, setSubRegionData] = useState({ regionId: props.subRegionData ? props.subRegionData.regionId : 0, subRegionId: props.subRegionData ? props.subRegionData.subRegionId : 0, subRegionName: '', regionName: '', IsActive: true });
  // Declaration of React Hooks Start Here

  React.useEffect(() => {
    ApiService.getAll(`DropDown/GetRegionDropDownFilter`)
      .then((res) => {
        setRegions(res.data);
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }, []);
  React.useEffect(() => {
    if (props.crudState === "Edit" || props.crudState === "View") {
      let data = {
        regionId: props.formData.regionId,
        subRegionName: props.formData.subRegionName,
        regionName: props.formData.regionName,
        subRegionId: props.formData.subRegionId
      };
      setSubRegionData(data);
      regions.forEach((rData, i) => {
        if (rData.id === props.formData.regionId) {
          setDefaultRegionValue(regions[i]);
        }
      });
    }
  }, [props, regions]);
  // Declaration of React Hooks End Here

  // Function for Validations
  const validation = (formData) => {
    if ((formData.subRegionName.trim() === "")) {
      toasterError("Please enter Sub-Region"); return false;
    }
    if (formData.regionName === null || formData.regionName === 0 || formData.regionName === '') {
      toasterError("Please select region name"); return false;
    }
    return true;
  }

 // This function is used to register a sub-region
const registerSubRegion = () => {
  // Set loading to true while the request is being processed
  setLoading(true);
  // Validate the SubRegionData
  if (validation(SubRegionData)) {
    // Make an axios post request to the specified URL with the SubRegionData and Authorization token
    axios.post(process.env.React_App_baseUrl + "managedata/SaveSubRegion", null, {
      headers: { ...SubRegionData, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null }

    }).then((res) => {
      // If the response status is 200, display success message and hide the side drawer
      if (res.status === 200) {
        toasterSuccess("Sub-Region details added successfully!");
        setLoading(false);
        props.hideSideDrawer();
      }
    }).catch((err) => {
      // Set loading to false and handle errors
      setLoading(false);
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    });
  }
}; 
  

  // Function for Update Sub-Region
const updateSubRegion = () => {
  // Set loading to true
  setLoading(true);
  
  // Validate the SubRegionData
  if (validation(SubRegionData)) {
    // Make a post request to the API endpoint
    axios.post(process.env.React_App_baseUrl + "managedata/UpdateSubRegion", null, {
      headers: { ...SubRegionData, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null },
    })
      .then((res) => {
        // If response status is 200, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("Sub-Region details edited successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      }).catch((err) => {
        // Set loading to false and handle errors
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }
};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Subregion
        </Typography>
      </Box>

      {/* Add & Edit Commodity Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            required
            fullWidth
            id="standard-basic"
            name="Subregion"
            size="small"
            label="Subregion"
            placeholder="Status"
            variant="standard"
            inputProps={{ maxLength: 100 }}
            value={props.crudState === "Add" ? null : props.formData.subRegionName}
            onChange={(e) => {
              props.setFormData({
                ...props.formData, subRegionName: e.target.value,
              });
              setSubRegionData({
                ...SubRegionData, subRegionName: e.target.value,
              });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteText
            option={regions}
            dropShow={"regionName"}
            labelName={"Region"}
            isRequired={true}
            feildName={"regionName"}
            defaultValue={defaultRegionValue}
            readOnly={props.crudState === "View" ? true : false}
            onChangeRole={(value) => {
              setDefaultRegionValue({
                ...defaultValue,
                regionName:
                  value && value.regionName ? value.regionName : "",
                regionId: value && value.id ? value.id : null,
              });
              setSubRegionData({
                ...SubRegionData,
                regionName:
                  value && value.regionName ? value.regionName : "",
                regionId: value && value.id ? value.id : null,
              });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid item xs={12} sx={{ mt: -1 }}>
              <Grid container spacing={2} justifyContent="left">
                <Grid item xs={'auto'}>
                  <LoadingButton
                    buttonName={props.crudState === "Add" ? "Save" : "Update"}
                    clickHandler={props.crudState === "Add" ? registerSubRegion : updateSubRegion}
                    isLoading={loading}
                  />
                </Grid>
                <Grid item xs={'auto'}>
                  <Button variant="contained" color="accent3" onClick={() => props.setState({ ...props.state, right: false })}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddEditSubRegion;