import React, { useEffect, useState } from "react";
import ErroHandling from "../../../Services/ErrorHandling";
// Default React, Router and Services Components
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@mui/material";
import {
  AddIcon,
  AttachIcon,
  DownloadDocumentIcon,
} from "@fluentui/react-icons-mdl2";
import CircularProgress from "@mui/material/CircularProgress";
import AlertDialog from "../../../UIComponent/AlertDialog";
// Importing Custom Components
import DatePicker from "../../../UIComponent/DatePicker";
import Dropzone from "../../../UIComponent/DragDropField";
import {
  CancelIcon,
  DeleteIcon,
  TextDocumentIcon,
  LinkIcon,
} from "@fluentui/react-icons-mdl2";
import ApiService from "../../../Services/ApiService";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// import history from "../../../Routes/History";
import { useHistory } from "react-router-dom";

import SideDrawer from "../../../UIComponent/Drawer";
import { ScrollingDialog } from "../../../UIComponent";
import AdobeViewer from "../../DataCollection/JobManagement/AdobeViewer";
import dayjs from "dayjs";

// Import Fluent UI Icons

// Importing useStyles and Styles
import useStyles from "../../DataCollection/JobManagement/jobManagement.styles";
import "./index.scss";
import axios from "axios";

import CommonService from "../../../Services/CommonService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const OwnershipChangesJobInfo = (props) => {
  const history = useHistory();
  console.log(props);
  const classes = useStyles();

  /***********ADD ON************ */
  const [tempData, setTempData] = useState();
  console.log(tempData);

  const [expanded, setExpanded] = React.useState(true);
  const [state, setState] = useState({ right: false });
  const [showReportInfo, setShowReportInfo] = React.useState(false);
  const [data, setData] = useState({});
  // const [years, setYears] = useState([]);
  // const [selectedYear, setSelectedYear] = useState(null);
  // const [defaultYearValue, setDefaultYearValue] = useState(null);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  React.useEffect(() => {
    getJobInfo();
  }, [props]);
  // React.useEffect(() => {
  //   getYear();
  // }, [data])
  const getJobInfo = async () => {
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0
    ) {
      return;
    }
    await axios
      .post(
        `${process.env.React_App_baseUrl}ManageOwnership/GetJobDetails`,
        null,
        {
          headers: {
            jobAssetId: props.jobAssetId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setData(res.data);
        //console.log(res.data);

        //setDataPoints(res.data.map((i, e) => i))

      })
      .catch((err) => {
        {
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        }
        // setOpenDialog(false);
      });
    // setIsOpenAssign(false);
  };
  const [AnyValueTrue, setAnyValueTrue] = useState(false);

  useEffect(() => {
    for (const key in data.dataPoints) {
      if (
        data.dataPoints.hasOwnProperty(key) &&
        data.dataPoints[key] === true
      ) {
        setAnyValueTrue(true);
        break;
      }
    }
  }, [data]);
  console.log(AnyValueTrue);
  // Add Report Info Drawer
  const toggleDrawer = (open) => (event) => {
    setState({ ...state, right: open });
    setShowReportInfo(true);
  };
  React.useEffect(() => {
    GetDataFromTempStorage();
    console.log(props);
  }, [props.location]);

  const GetDataFromTempStorage = () => {
    let temp = CommonService.GetStoredData(props, "ownershipjobManagement");
    console.log(temp);
    setTempData(temp);
  };
  // const getYear = () => {
  //   axios
  //     .get(
  //       process.env.React_App_baseUrl + "DropDown/GetYears",
  //       {
  //         headers: {
  //           Authorization: CommonService.getToken()
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.status === 200) {

  //         setYears(res.data);
  //         res.data.forEach(element => {
  //           if (data?.dataPoints?.yearId) {
  //             if (element.id === data?.dataPoints?.yearId) {
  //               setDefaultYearValue(element);
  //             }
  //           }
  //         });

  //         console.log("test",defaultYearValue);


  //       } else {
  //         toasterError("Something went wrong");
  //       }
  //     })
  //     .catch((err) => {
  //       ErroHandling(err, function (res) {
  //         toasterError(res);
  //       });
  //     });
  // };
  /****************ADD ON END*************** */

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.jobShortDetails}
      >
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
              <div className={classes.shortDetails}>
                Job ID:{" "}
                <span className={classes.boldText}>
                  {props.tempData?.state?.jobNo}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className={classes.shortDetails}>
                Job Type:{" "}
                <span className={classes.boldText}>{data.jobType}</span>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
              <div className={classes.shortDetails}>
                Job Name:{" "}
                <span className={classes.boldText}>{data.jobName}</span>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
              <div className={classes.shortDetails}>
                Priority:&nbsp;
                {data.priority === "High" ? (
                  <Typography className={classes.twoValues} color="error">
                    {" "}<span className={classes.boldText}>{data.priority}</span>
                  </Typography>) : undefined}{" "}
                {data.priority === "Low" ? (
                  <Typography className={classes.twoValues} color="orange">
                    {" "}<span className={classes.boldText}>{data.priority}</span>
                  </Typography>) : undefined}{" "}
                {data.priority === "Medium" ? (
                  <Typography className={classes.twoValues} color="blue">
                    {" "}<span className={classes.boldText}>{data.priority}</span>
                  </Typography>) : undefined}
                {data.priority === "Critical" ? (
                  <Typography className={classes.twoValues} color="red">
                    {" "}
                    <span className={classes.boldText}>{data.priority}</span>
                  </Typography>) : undefined}
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className={classes.shortDetails}>
                TAT: <span className={classes.boldText}>{data.tat}</span>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className={classes.shortDetails}>
                Year:{" "}
                <>
                  {data.jobYearsDto && data.jobYearsDto.map((element, index) => {
                    return (
                      <>
                      <strong>
                        <Chip
                          label={element.year}
                          size="small"
                          key={index}
                        />{" "}
                        </strong>
                      </>
                    )
                  })}
                </>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.shortDetails}>
                Job Instruction:{" "}
                <span className={classes.boldText}>{data?.instructions}</span>
              </div>

            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2, mb: 0 }}>
          <Grid container spacing={0}>
            <Grid xs={12}>
              <Typography color="text.primary" sx={{ mt: 1 }}>
                <strong>Selected Data Points</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                rowSpacing={0}
                columnSpacing={1.5}
                justifyContent="left"
                alignItems="flex-start"
              >
                {/* {AnyValueTrue && (
                  <>
                    {Object.entries(data.dataPoints).map(([key, value]) => {
                      console.log("kk", key, value);
                      if (value === true) {
                        const capitalizedKey = key
                          .split(/(?=[A-Z])/) // Split at capital letters
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ");
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={4}
                            xl={3}
                            className={classes.removeCheckMargin}
                          >
                            <FormControlLabel
                              sx={{ mb: 0 }}
                              key={key}
                              control={<Checkbox checked />}
                              label={capitalizedKey}
                            />
                          </Grid>
                        );
                      }
                      return null;
                    })}
                  </>
                )} */}

                <Grid
                  item
                  xs={12}
                  className={classes.removeCheckMargin}
                >
                  <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                    <Grid item xs={"auto"}>
                      <div className={classes.boldText} style={{ minWidth: "100px" }}>Asset Info:</div>{" "}
                    </Grid>
                    <Grid item xs={"auto"}>
                      <FormControlLabel
                        sx={{ mb: 0 }}
                        control={
                          <Checkbox checked={data.dataPoints?.lattitude === true} />
                        }
                        label="Lattitude"
                      />
                    </Grid>
                    <Grid item xs={"auto"}><FormControlLabel
                      sx={{ mb: 0 }}
                      //key={key}
                      control={
                        <Checkbox checked={data.dataPoints?.longitude === true} />
                      }
                      label="Longitude
                    "
                    /></Grid>
                    <Grid item xs={"auto"}><FormControlLabel
                      sx={{ mb: 0 }}
                      //key={key}
                      control={
                        <Checkbox checked={data.dataPoints?.operator === true} />
                      }
                      label="Operator"
                    /></Grid>

                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={classes.removeCheckMargin}
                >
                  <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                    <Grid item xs={"auto"}>
                      <div className={classes.boldText} style={{ minWidth: "100px" }}>Owner Info:</div>{" "}
                    </Grid>
                    <Grid item xs={"auto"}>
                      <FormControlLabel
                        sx={{ mb: 0 }}
                        //key={key}
                        control={
                          <Checkbox checked={data.dataPoints?.owner === true} />
                        }
                        label="Owner"
                      />
                    </Grid>
                    <Grid item xs={"auto"}>
                      <FormControlLabel
                        sx={{ mb: 0 }}
                        //key={key}
                        control={
                          <Checkbox
                            checked={data.dataPoints?.ownershipPercentage === true}
                          />
                        }
                        label="Ownership %
                    "
                      />
                    </Grid>
                    <Grid item xs={"auto"}>
                      <FormControlLabel
                        sx={{ mb: 0 }}
                        //key={key}
                        control={
                          <Checkbox
                            checked={data.dataPoints?.ownershipDate === true}
                          />
                        }
                        label="Ownership Date"
                      />
                    </Grid>
                    <Grid item xs={"auto"}>
                      <FormControlLabel
                        sx={{ mb: 0 }}
                        //key={key}
                        control={
                          <Checkbox
                            checked={data.dataPoints?.stockExchange === true}
                          />
                        }
                        label="Stock Exchange
                    "
                      />
                    </Grid>
                  </Grid>

                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.removeCheckMargin}
                >
                  <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                    <Grid item xs={"auto"}>
                      <div className={classes.boldText} style={{ minWidth: "100px" }}>Joint Venture:</div>{" "}
                    </Grid>
                    <Grid item xs={"auto"}>
                      <FormControlLabel
                        sx={{ mb: 0 }}
                        //key={key}
                        control={
                          <Checkbox
                            checked={data.dataPoints?.joinVentures === true}
                          />
                        }
                        label="Joint Ventures"
                      />
                    </Grid>
                    <Grid item xs={"auto"}>
                      <FormControlLabel
                        sx={{ mb: 0 }}
                        //key={key}
                        control={
                          <Checkbox
                            checked={data.dataPoints?.seeThroughOwners === true}
                          />
                        }
                        label="Equity Owner"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.removeCheckMargin}
                >
                  <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                    <Grid item xs={"auto"}>
                      <div className={classes.boldText} style={{ minWidth: "100px" }}>Updates:</div>{" "}
                    </Grid>
                    <Grid item xs={"auto"}>
                      <FormControlLabel
                        sx={{ mb: 0 }}
                        //key={key}
                        control={
                          <Checkbox checked={data.dataPoints?.updates === true} />
                        }
                        label="Updates
                    "
                      />
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default OwnershipChangesJobInfo;
