// Default React, Router and Services Components
import React from 'react'
import { Alert, Box, Button, Collapse, Grid, IconButton, Link, TextField, Tooltip, Typography } from '@mui/material'
import { grey, lightBlue } from "@mui/material/colors";

// Importing useStyles and Styles
import useStyles from "./Header.styles";

// Import Fluent UI Icons
import { CancelIcon, CheckMarkIcon, CircleAdditionIcon, LaptopSecureIcon } from '@fluentui/react-icons-mdl2';

const MyProfile = () => {
    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const darkGrey = grey[900];
    const lightGrey = grey[500];
    const darkBlue = lightBlue[900];
    // Declaration of States and Component Variables End Here

    return (
        // Layout for Profile
        <Box className={classes.popupArea}>
            {/* Title for Side Drawer */}
            <Box className={classes.popupHeading}>
                <Typography id="modal-modal-title" variant="h5">
                    My Profile
                </Typography>
            </Box>

            {/* Add & Edit Currency Form */}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="caption" display="block" gutterBottom color={lightGrey}>
                        First Name
                    </Typography>
                    <Typography variant="body1" color={darkGrey}>
                        Venkatesh
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption" display="block" gutterBottom color={lightGrey}>
                        Last Name
                    </Typography>
                    <Typography variant="body1" color={darkGrey}>
                        Lakshminarayanan
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption" display="block" gutterBottom color={lightGrey}>
                        Email ID
                    </Typography>
                    <Typography variant="body1" color={darkGrey}>
                        venkatesh.lakshminarayanan@meritgroup.co.uk
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption" display="block" gutterBottom color={lightGrey}>
                        Role
                    </Typography>
                    <Typography variant="body1" color={darkGrey}>
                        CRU Admin
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default MyProfile