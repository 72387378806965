import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AddReportInfo from "./AddReportInfo";
import DataTableWithAttachmentCol from "../../../UIComponent/DataTableWithAttachmentCol/DataTableWithAttachmentCol";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
import AlertDialog from "../../../UIComponent/AlertDialog";
import CommonService from "../../../Services/CommonService";
// Importing useStyles and Styles
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  CircularProgress,
} from "@mui/material";
import SideDrawer from "../../../UIComponent/Drawer";
import { styled } from "@mui/material/styles";
import useStyles from "./jobManagement.styles";

// Import Material & Fluent UI Icons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// Accordion Components
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<AddIcon sx={{ fontSize: "0rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === 'dark'
  //     ? 'rgba(255, 255, 255, .05)'
  //     : 'rgba(0, 0, 0, .03)',
  padding: theme.spacing(0),
  flexDirection: "row-reverse",
  "& .MuiButtonBase-root-MuiAccordionSummary-root": {
    padding: theme.spacing(0),
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(45deg)",
  },

  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1, 0, 1, 0),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const OwnershipDetailsReportInfo = (props) => {
  console.log("report", props);
  const classes = useStyles();
  // Assigning Columns and UI Props
  const [state, setState] = useState({ right: false });
  // const [page, setPage] = React.useState(1);
  // const [totalCount, setTotalCount] = useState(null);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [edit, setEdit] = useState({});
  const [reload, setReload] = useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [crudState, setCrudState] = React.useState(false);
  const [showReportInfo, setShowReportInfo] = React.useState(false);
  const [reportData, setReportData] = useState([]);
  const [fileuploadLoading, setFileuploadLoading] = useState(false);
  const [formData, setFormData] = useState([]);
  const [deleteReportData, setDeleteReportData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [access, setAccess] = React.useState({
    allowEdit: true,
    allowDelete: true,
    allowView: false,
  });
  const [expanded, setExpanded] = React.useState(true);
  const [isLoading, setLoading] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const columns = [
    { id: "id", label: "Status", type: "Highlight" },
    { id: "sNo", label: "Sl.No", minWidth: 90, width: 90, align: "center" },
    { id: "reportingYear", label: "Year", minWidth: 100, width: 100 },
    {
      id: "reportingType",
      label: "Source File Type",
      minWidth: 200,
      width: "15%",
    },
    { id: "reportName", label: "Source File Name", minWidth: 300 },
    {
      id: "showReportAttatchment",
      label: "Links and Attachments",
      minWidth: 200,
      width: "20%",
      colorCode: "blue",
      isReportAttachmant: true,
    },
  ];

  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") {
      setDeleteReportData(item);
      setOpenDelete(true);
    } else {
      if (mode === "Edit") {
        setFormData(item);
      }
      setState({ ...state, right: open, formData: item });
      setCrudState(mode);
    }
  };

  // Function for Report Data row
  const deleteReport = () => {   
    setDeleteBtnLoadinng(true);
    let data = {
      reportInfoId: deleteReportData.assetReportId,
      isConfirm: true,
    };
    axios
      .delete(
        process.env.React_App_baseUrl +
          `ManageOwnership/DeleteReportInfo?reportInfoId=${deleteReportData.assetReportId}&isConfirm=true`,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
          data,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toasterSuccess("Source file deleted Successfully");
          if (props.getFileInfo) {
            props.getFileInfo()
          }
          GetReportData();
          setDeleteBtnLoadinng(false);
          setOpenDelete(false);
        } else {
          toasterError("Something went wrong");
          setDeleteBtnLoadinng(false);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
        setDeleteBtnLoadinng(false);
        setOpenDelete(false);
      });
  };

  // Function for Get Report Data
  const GetReportData = () => {
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0
    ) {
      return;
    }
    setLoading(true);
    // let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
    axios
      .get(process.env.React_App_baseUrl + "ManageOwnership/GetReportInfo", {
        headers: {
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
          jobAssetId: props.jobAssetId,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setReportData(res.data);
          // setTotalCount(res.data.totalCount)
          setLoading(false);
        } else {
          toasterError(`${res.data}`);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

  useEffect(() => {
    if (!reportData || reportData.length < 1) {
      GetReportData();
    }
    if (reload) {
      GetReportData();
    }
  }, [reload]);

  React.useEffect(() => {
    GetReportData();
  }, [refresh, reload]);

  useEffect(() => {
    GetReportData();
  }, [props]);

  const openReportInfoView = (rowData) => {
    let sendData = {
      jobId: props.jobId,
      fileName: rowData.reportName + ".pdf",
      pageNumber: 1,
      crudState: "View",
    };

    CommonService.openFilePreview(sendData);
  };

  return (
    <>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} sm={12} md={6}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <h4 className={classes.accHeadingText}>
                {expanded === "panel2" ? (
                  <RemoveIcon
                    sx={{
                      fontSize: "1.75rem",
                      color: "#606060",
                      marginRight: 0.75,
                      marginTop: -0.5,
                    }}
                  />
                ) : (
                  <AddIcon
                    sx={{
                      fontSize: "1.75rem",
                      color: "#606060",
                      marginRight: 0.75,
                      marginTop: -0.5,
                    }}
                  />
                )}
                Source File Info
              </h4>
            </AccordionSummary>
          </Grid>
          {props.access?<>
          <Grid item xs={12} sm={12} md={6}>
            <Grid
              item
              xs={12}
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              textAlign="right"
            >
              <Grid item xs={"auto"}>
                <Button
                  variant="contained"
                  color="accent7"
                  size="small"
                  startIcon={<AddIcon style={{ fontSize: 16 }} />}
                  onClick={toggleDrawer(true, "Add")}
                >
                  Add Source File Info
                </Button>
              </Grid>
            </Grid>
          </Grid></>:<></>}
        </Grid>

        <AccordionDetails>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={3}
            sx={{ mb: 0 }}
          >
            <Grid item xs={12}>
              <Box className={classes.tableBorder}>
                <DataTableWithAttachmentCol
                  // className={classes.removeBorder}
                  loading={isLoading}
                  columns={columns}
                  // rows={rows}
                  rows={reportData ? reportData : []}
                  // crudState={crudState}
                  // page={page}
                  // setPage={(data) => {
                  //   setPage(data);
                  //   setReload(true);
                  // }}
                  // rowsPerPage={rowsPerPage}
                  // setRowsPerPage={(data) => {
                  //   setRowsPerPage(data);
                  //   setReload(true);
                  // }}
                  // totalCount={totalCount}
                  openReportInfoView={(rowData) => {
                    openReportInfoView(rowData);
                  }}
                  isActionButton={{
                    ...(props.access && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(props.access && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                />
              </Box>
            </Grid>
            {/* Alert Dialog - Confirmation for Delete */}
            {openDelete ? (
              <AlertDialog
                open={openDelete}
                title={"Confirmation"}
                maxWidth={"sm"}
                description={`Are you sure you want to Delete Source File "${deleteReportData?.reportName}" ?`}
                action={
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="right"
                    spacing={2}
                    className={classes.alertDialogbtn}
                  >
                    <Grid item xs={"auto"}>
                      <Button onClick={() => setOpenDelete(false)}>No</Button>
                    </Grid>
                    <Grid item xs={"auto"}>
                      <Button onClick={deleteReport} color="secondary">
                        {deleteBtnLoadinng ? (
                          <CircularProgress size={25} />
                        ) : (
                          "Yes"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                }
              />
            ) : null}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <SideDrawer
        formComp={
          <AddReportInfo
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            // setFormData={setEdit}
            refreshGrid={() => {
              GetReportData();
            }}
            tempData={props.tempData}
            jobId={props.jobId}
            jobAssetId={props.jobAssetId}
            // uploadFiles={(e) => {
            //   uploadFile(e);
            // }}
            //fileuploadLoading={fileuploadLoading}
            reload={reload}
            setReload={setReload}
            setRefresh={setRefresh}
            reportData={reportData}
            getFileInfo={() => {
              props.getFileInfo();
            }}
            refresh={refresh}
            hideSideDrawer={() => {
              setState({ right: false });
              GetReportData();
            }}
          />
        }
        state={state}
        setState={setState}
        //  crudState={showReportInfo}
      />
    </>
  );
};

export default OwnershipDetailsReportInfo;
