import React from "react";
import { Link } from "react-router-dom";
import {Tooltip,IconButton} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { alpha } from '@mui/material/styles';
import clsx from 'clsx';

const TooltipIcon = (props) => {
    const classes = useStyles();
    const renderLink = React.forwardRef((itemProps, ref) => (
        <Link ref={ref} to={props.to} {...itemProps} />
    ));
    if (!props.disabled && props.title !== '') {
        return (
            <Tooltip title={props.title} placement={props.placement ? props.placement : 'top'} arrow>
                <IconButton
                    size={props.size}
                    edge={props.edge}
                    disabled={props.disabled}
                    className={clsx(classes.root, {
                        [classes.active]: props.active,
                        [classes.light]: props.light,
                        [classes.primary]: props.color === 'primary',
                        [classes.secondary]: props.color === 'secondary',
                        [classes.info]: props.color === 'info',
                        [classes.error]: props.color === 'error',
                        [classes.warning]: props.color === 'warning',
                        [classes.success]: props.color === 'success',
                        [classes.outlined]: props.variant === 'outlined'
                    })}
                    onClick={props.onClick}
                    {...props.to ? { component: renderLink } : {}}
                >
                    {props.children}
                </IconButton>
            </Tooltip>
        )
    } else {
        return (
            <IconButton
                size={props.size}
                edge={props.edge}
                disabled={props.disabled}
                className={clsx(classes.root, {
                    [classes.active]: props.active,
                    [classes.light]: props.light,
                    [classes.primary]: props.color === 'primary',
                    [classes.secondary]: props.color === 'secondary',
                    [classes.info]: props.color === 'info',
                    [classes.error]: props.color === 'error',
                    [classes.warning]: props.color === 'warning',
                    [classes.success]: props.color === 'success',
                    [classes.outlined]: props.variant === 'outlined'
                })}
                onClick={props.onClick}
                {...props.to ? { component: renderLink } : {}}
            >
                {props.children}
            </IconButton>
        )
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.secondary,
        '& .MuiSvgIcon-root': {
            fontSize: "inherit"
        },
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    light: {
        color: theme.palette.common.white,
        '&:hover': {
            color: theme.palette.primary.light,
            background: alpha(theme.palette.common.black, .15)
        }
    },
    active: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    outlined: {
        padding: theme.spacing(1) - 1,
        color: theme.palette.text.primary,
        border: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
        '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.action.hover,
        },
        '.MuiFormControl-root &': {
            padding: theme.spacing(1) + 1,
        },
        '&.MuiIconButton-sizeSmall': {
            padding: theme.spacing(1) - 2
        },
        '&$error': {
            color: theme.palette.error.main,
            borderColor: alpha(theme.palette.error.main, .5),
            '&:hover': {
                borderColor: theme.palette.error.main,
            }
        },
        '&$success': {
            color: theme.palette.success.main,
            borderColor: alpha(theme.palette.success.main, .5),
            '&:hover': {
                borderColor: theme.palette.success.main,
            }
        }
    },
    primary: {
        color:"#db9400",
        '&:hover': {
            color:"#db9400",
            // color: theme.palette.primary.main,
        }
    },
    secondary: {
        color:"#666666",
        '&:hover': {
            color: theme.palette.secondary.main,
        }
    },
    error: {
        color: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.main,
        }
    },
    warning: {
        '&:hover': {
            color: theme.palette.warning.main,
        }
    },
    info: {
        '&:hover': {
            color: theme.palette.info.main,
        }
    },
    success: {
        '&:hover': {
            color: theme.palette.success.main,
        }
    }
}));

export default TooltipIcon;