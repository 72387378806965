import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    pageContent: {
        borderRadius: 4,
        minHeight: 430,
        marginTop: theme.spacing(3),
        padding: theme.spacing(2, 2),

        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(3, 3),
        },
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(3, 3),
        },
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(3, 3),
        },
    },

}));

export default useStyles;