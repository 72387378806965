// Default React, useState and Material Components
import React, { useEffect, useState } from "react";
import { Button, Box, Breadcrumbs, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography, } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from './../../../Services/GetMenuService';

// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { toasterError } from "../../../UIComponent/Toaster";
import SideDrawer from "../../../../src/UIComponent/Drawer/index";

// Importing Add & Edit Components
import AddAndEditTat from './AddEditTatConfiguration';

// Importing useStyles
import useStyles from "../ManageData.styles";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

const Tatconfiguration = () => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [selected, SetSelected] = useState([]);
  const [formData, setFormData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allTatValue, setallTatValue] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [DeleteTatData, setDeleteTatData] = useState(null);
  const [state, setState] = React.useState({ right: false });
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [menuPermission, setMenuPermission] = useState({ allowEdit: true, });
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(42, function (err, res) {
      if (res) {
        //console.log(res.data)
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])
  // Declaration of React Hooks Start Here
  useEffect(() => {
    if (!allTatValue || allTatValue.length < 1 || reload) {
      if (searchValue) { getAllTatConfig(searchValue) }
      else { getAllTatConfig() }
    }
  }, [allTatValue, reload])
  useEffect(() => {

    if (searchValue) { getAllTatConfig(searchValue) }
    else { getAllTatConfig() }
  }, [searchValue, sorting, page, rowsPerPage])
  // Declaration of React Hooks End Here

  const columns = [
    { id: "id", label: "Status", type: "Highlight" },
    { id: "priority", label: "Priority", width: '15%', minWidth: 150, sorting: true, },
    { id: "description", label: "Description", width: '25%', minWidth: 250, sorting: false, },
    { id: "minVariableCount", label: "Variable Count (Min)", width: '22%', minWidth: 140, sorting: true, align: "center" },
    { id: "maxVariableCount", label: "Variable Count (Max)", width: '22%', minWidth: 140, sorting: true, align: "center" },
    { id: "tatDays", label: "TAT(Days)", width: '16%', minWidth: 100, sorting: true, align: "center" },
  ];

  // This function is used to get all Tat Configuration
  const getAllTatConfig = (searcText = "", sort = "") => {
    // Set loading to true
    setLoading(true)
    // If reload is true, set it to false
    if (reload) { setReload(false) }
    // Set sort order to true
    let sortOrder = true;
   // Check if sorting is defined and set the sort value accordingly
    if (sorting.sortBy !== "" && sorting.sortBy !== undefined && sorting.sortBy !== null) {
      sort = sorting.sortBy[0].toUpperCase() + sorting.sortBy.slice(1)
      sortOrder = sorting.isAscending
    } else {
      sort = ""
    }
  // Make an API call to get all Tat Configuration
    axios.get(process.env.React_App_baseUrl + `managedata/GetAllTatConfiguration?pageNumber=${page}&pageSize=${rowsPerPage}`, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null,
        searchText: encodeURIComponent(searcText),
        sortColumn: sort,
        isAscending: sortOrder,
      }
    }).then((res) => {
      setLoading(false)
      // If response status is 200, set allTatValue and totalCount
      if (res.status === 200) {
        setallTatValue(res.data.data)
        setTotalCount(res.data.totalCount)
      } else {
      // Else show error message  
        toasterError("Something went wrong")
      }
    }).catch((err) => {
      // Set loading to false
      setLoading(false)
      ErroHandling(err, function (res) {
        toasterError(res)
      })
    })
  }

// This function handles the keyup event
  const handleKeyUp = (event) => {
    // If backspace is pressed and target element is not a button
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
       // If search value is empty, get all TatConfig
      if (searchValue.length === 0) { getAllTatConfig(); }
    }
    // If enter is pressed and target element is not a button
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      // Set total count to 0 and page to 0
      setTotalCount(0);
      setPage(0);
      getAllTatConfig(event.target.value);
    }
  };
  const globalSearch = (event) => { setSearchValue(event) }

  const deleteTat = () => {
    // setDeleteBtnLoadinng(true);
  }

  // Function for Edit and Delete Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") {
      setDeleteTatData(item);
      setOpenDelete(true);
    } else {
      if (mode === "Edit") {
        setFormData(item);
      }
      setState({ ...state, right: open, formData: item, mode });
    }
  };
  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>TAT Configuration</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">TAT Configuration</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {access.allowEdit && <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: "16px" }} />}>
                Add TAT
              </Button>}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              <Grid container alignItems="center" justifyContent="left">
                {/* Search and Filter Options Start Here */}
                <Grid item xs={12} className={classes.tableTopPart}>
                  <Grid container alignItems="center" justifyContent="left" spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="input-with-icon-textfield"
                        className={classes.searchByKeyword}
                        value={searchValue}
                        placeholder="Search by Keyword"
                        onKeyUp={handleKeyUp}
                        onChange={(e) => globalSearch(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon className={classes.searchIcon} />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                      />
                      {searchValue ? (
                        <IconButton
                          aria-label="Add"
                          className={classes.serchByClear}
                          onClick={() => {
                            setSearchValue("");
                            getAllTatConfig();
                          }}
                        >
                          <ClearIcon style={{ fontSize: 10 }} />
                        </IconButton>
                      ) : ("")}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid container alignItems="center" justifyContent="right" spacing={2}>
                        <Grid item textAlign="right">
                          <Typography variant="subtitle2">
                            Total Records: <span className={classes.totalRecord}>{totalCount}</span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Variable Report */}
                <Grid item xs={12}>
                  <DataTable
                    className={classes.removeBorder}
                    loading={isLoading}
                    rows={allTatValue ? allTatValue : []}
                    columns={columns}
                    page={page}
                    setPage={(data) => {
                      if (page === 0 && data === 1) {
                        setPage(2)
                      }
                      else if (page === 2 && data === 1) {
                        setPage(0)
                      }
                      else {
                        setPage(data);
                      }
                      setReload(true);
                    }}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={(data) => {
                      setRowsPerPage(data);
                      setReload(true);
                    }}
                    totalCount={totalCount}
                    isAllSelected={isAllSelected}
                    setIsSelected={setIsSelected}
                    selected={selected}
                    setSelected={SetSelected}
                    sorting={sorting}
                    setSorting={setSorting}
                    isActionButton={{
                      ...(access.allowEdit && {
                        edit: toggleDrawer(true, "Edit"),
                      })
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
        <SideDrawer
          // drawerWidth="md"
          formComp={
            <AddAndEditTat
              state={state}
              setState={setState}
              hideSideDrawer={() => {
                setState({ right: false });
                getAllTatConfig();
              }}
            />
          }
          state={state}
          setState={setState}
        />
        {
          openDelete ? (
            <AlertDialog
              open={openDelete}
              title={"Confirmation"}
              maxWidth={"sm"}
              description={`Are you sure you want to delete tat ?`}
              action={
                <Grid container alignItems="center" justifyContent="right" spacing={2} className={classes.alertDialogbtn}>
                  <Grid item xs={"auto"}>
                    <Button onClick={() => setOpenDelete(false)}>No</Button>
                  </Grid>
                  <Grid item xs={"auto"}>
                    <Button onClick={deleteTat} color="secondary">
                      {deleteBtnLoadinng ? (<CircularProgress size={25} />) : ("Yes")}
                    </Button>
                  </Grid>
                </Grid>
              }
            />
          ) : null
        }
      </Grid >
    </Box >
  )

}

export default Tatconfiguration