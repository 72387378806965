import React from "react";
import './Loader.scss';

export function Loader() {
    return <div className="loader">
        <div className="loader-text">
            Loading
        </div>
        <div>
            <div className="spinner-grow text-success" role="status">
                <span className="sr-only"></span>
            </div>
            <div className="spinner-grow text-danger" role="status">
                <span className="sr-only"></span>
            </div>
            <div className="spinner-grow text-warning" role="status">
                <span className="sr-only"></span>
            </div>
        </div>
    </div>
}