// Default React, useState and Material Components
import React, { useState } from "react";
import { Box, Breadcrumbs, Button, CircularProgress, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import SideDrawer from "../../../UIComponent/Drawer";
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { toasterSuccess, toasterError } from '../../../UIComponent/Toaster/index';

// Importing Add & Edit Components
import AddEditLegalEntityTypes from "./AddEditLegalEntityTypes";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const LegalEntityTypes = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [selected, SetSelected] = useState([]);
  const [formData, setFormData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [state, setState] = useState({ right: false });
  const [startSearch, setStartSearch] = useState(false);
  const [isAllSelected, setIsSelected] = useState(false);
  const [crudState, setCrudState] = React.useState(false);
  const [legalEntityTypes, setLegalEntityTypes] = useState([]);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [deleteFrequencyData, setDeleteFrequencyData] = useState(null);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [access, setAccess] = React.useState({ allowEdit: true, allowDelete: true, allowView: true })
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetMenuService(46, function (err, res) {
      if (res) {
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])
  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) { searchUser('') }
    else { searchUser(searchValue) }
  }, [sorting]);

  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) { searchUser('') }
    else { searchUser(searchValue) }
  }, [page, reload, rowsPerPage])

  const toggleDrawer = (open, mode) => (event, item) => {
    if (mode === "Delete") { setDeleteFrequencyData(item); setOpenDelete(true); }
    else {
      setFormData(item); setState({ ...state, right: open, formData: item }); setCrudState(mode);
    }
  };
  // Declaration of React Hooks End Here

  // Assigning Colums and UI Props
  const columns = [
    { id: "commonVariation", label: "Common Variations", minWidth: 180, width: "20%", sorting: true },
    { id: "cruFormat", label: "CRU Format", minWidth: 300, width: "20%", sorting: true, truncate: 70, tooltipWithTruncate: true  },
    { id: "countryName", label: "Country", minWidth: 200, width: "30%", sorting: true, truncate: 80, tooltipWithTruncate: true },
    { id: "comments", label: "Description", minWidth: 300, width: "30%", sorting: false, truncate: 100, tooltipWithTruncate: true }
  ];

// This function handles the keyup event
const handleKeyUp = (event) => {
  // If backspace is pressed and target is not a button
  if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
    // If search value is empty, search user with empty string
    if (searchValue.length === 0) { searchUser(""); }
  }
  // If enter is pressed and target is not a button
  if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
    // Reset total count, page and start search
    setTotalCount(0);
    setPage(0);
    setStartSearch(true)
    // Search user with the value of the target
    searchUser(event.target.value);
  }
};

// This function is used to delete a legal entity type
const deleteLegalEntityTypes = () => {
  // Set loading state of delete button to true
  setDeleteBtnLoadinng(true);
  // Store delete frequency data in a variable
  let data = deleteFrequencyData;
  // Set active status to false
  data.active = false;

  // Make an axios delete request to the API
  axios.delete(process.env.React_App_baseUrl + `managedata/DeleteLegalEntityType?id=${deleteFrequencyData.id}`, {
  headers: {
    'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
}})
    .then((res) => {
      // If response status is 200, show success message and search user with empty string
      if (res.status === 200) {
        toasterSuccess("Legal entity type deleted successfully");
        searchUser("")
        setDeleteBtnLoadinng(false);
        setOpenDelete(false);
      } else {
        // Else show error message
        toasterError("Somthing went wrong");
        setOpenDelete(false);
        setDeleteBtnLoadinng(false);
      }
    })
    .catch((err) => {
      // Handle errors
      ErroHandling(err, function (res) {
        toasterError(res)
      })
      setDeleteBtnLoadinng(false);
    });
};


  // Function for Global Search
  const globalSearch = (value) => { setSearchValue(value); };

// This function takes a column name as an argument and returns the same column name with the first letter capitalized
// If the column name is "cruformat", it will return "CRUFormat"
const ChangeColumnCase = (columnName) => {
  // Initialize newColumnName variable to store the modified column name
  let newColumnName = "";
  
  // Check if the column name is "cruformat"
  if (columnName.toLocaleLowerCase() == "cruformat"){
    // If so, set newColumnName to "CRUFormat"
    newColumnName = "CRUFormat"
  }
  // Check if the column name is not empty, null, or "cruformat"
  if (columnName !== "" && columnName !== null && columnName.toLocaleLowerCase() != "cruformat") {
    // If so, capitalize the first letter of the column name and add it to the rest of the string
    newColumnName = columnName[0].toUpperCase() + columnName.slice(1, columnName.length)
  }
  // Return the modified column name
  return newColumnName;
}

// This function is used to search for a user based on the filter value provided
const searchUser = (filterValue) => {
  // Set loading to true while the search is in progress
  setLoading(true);
  // Create an array of parameters to be passed to the API call
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
  // Make an API call to search for the user
  ApiService.search("managedata/GetAllLegalEntityTypeDetails?",
    {
      // Pass the filter value as a parameter
      searchText: filterValue ? encodeURIComponent(filterValue) : "",
      // Pass the sorting column and order as parameters
      sortColumn: ChangeColumnCase(sorting.sortBy),
      isAscending: sorting.isAscending,
    }, prt
  )
    .then((res) => {
      // If the response status is 200, set the search result, loading and total count
      if (res.status === 200) {
        let searchResult = Array.isArray(res.data) ? res.data : res.data.data || [];
        setLegalEntityTypes(searchResult);
        setLoading(false);
        setTotalCount(res.data.totalCount);
      } else { 
          // Else, display an error message
          toasterError(`${res.data}`); setLoading(false); 
      }
    })
    .catch((err) => {
      // Set loading to false and handle any errors
      setLoading(false); {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      }
    });
};


  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Legal Entity Types</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">Legal Entity Types</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {(access.allowEdit) ? <>
                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: 16 }} />}>
                  Add Legal Entity Type
                </Button>
              </> : null}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className={classes.searchByKeyword}
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className={classes.serchByClear}
                        onClick={() => {
                          setSearchValue("");
                          searchUser("")
                        }}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">Total Records: <span className={classes.totalRecord}>{totalCount}</span></Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Legal Entity Types Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={legalEntityTypes ? legalEntityTypes : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setReload(true)
                    setSorting(e)
                  }}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Add & Edit Legal Entity Type Year Quarters Side Drawer */}
      <SideDrawer
        formComp={
          // Calling the AddEditFrequency
          <AddEditLegalEntityTypes
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            hideSideDrawer={() => {
              setState({ right: false });
              searchUser("")
            }}
          />
        }
        state={state}
        setState={setState}
      />

      {/* Alert Dialog - Confirmation for Delete */}
      {openDelete ? (
        <AlertDialog
          open={openDelete}
          title={"Confirmation"}
          maxWidth={"sm"}
          description={`Are you sure you want to delete the Legal Entity Type "${deleteFrequencyData?.commonVariation}" ?`}
          action={
            <Grid container alignItems="center" justifyContent="right" spacing={2} className={classes.alertDialogButton}>
              <Grid item xs={"auto"}>
                <Button onClick={() => setOpenDelete(false)}>No</Button>
              </Grid>
              <Grid item xs={"auto"}>
                <Button onClick={() => deleteLegalEntityTypes()} color="secondary">
                  {deleteBtnLoadinng ? (<CircularProgress size={25} />) : ("Yes")}
                </Button>
              </Grid>
            </Grid>
          }
        />
      ) : null}
    </Box>
  );
};

export default LegalEntityTypes;
