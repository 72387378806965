import React, { useEffect, useState } from "react";
import ErroHandling from "../../../Services/ErrorHandling";
// Default React, Router and Services Components
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  AddIcon,
  AttachIcon,
  CirclePlusIcon,
  DownloadDocumentIcon,
} from "@fluentui/react-icons-mdl2";
import CircularProgress from "@mui/material/CircularProgress";
import AlertDialog from "../../../UIComponent/AlertDialog";
// Importing Custom Components
import DatePicker from "../../../UIComponent/DatePicker";
import Dropzone from "../../../UIComponent/DragDropField";
import {
  CancelIcon,
  DeleteIcon,
  TextDocumentIcon,
  LinkIcon,
} from "@fluentui/react-icons-mdl2";
import ApiService from "../../../Services/ApiService";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// import history from "../../../Routes/History";
import { useHistory } from "react-router-dom";

import SideDrawer from "../../../UIComponent/Drawer";
import { ScrollingDialog } from "../../../UIComponent";
import AdobeViewer from "./AdobeViewer";
import dayjs from "dayjs";

// Import Fluent UI Icons

// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";
import "./index.scss";
import axios from "axios";
//ADD ON
// Accordion Components
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

// Import Material & Fluent UI Icons
import PlaceIcon from "@mui/icons-material/Place";
import RemoveIcon from "@mui/icons-material/Remove";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import { styled } from "@mui/material/styles";
import DataTable from "../../../UIComponent/DataTable";
import { setDate } from "date-fns";
import CommonService from "../../../Services/CommonService";
import AddReportInfo from "./AddReportInfo";

/******************ADD ON START*********************** */

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1, 0, 1, 0),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
/************ADD ON END************* */
const OwnershipChangesAssetInfo = (props) => {

  const history = useHistory();
  console.log(history);
  const classes = useStyles();
  const [assetdropdown, setAssetDropdown] = useState([]);
  const [operatordropdown, setOperatorDropdown] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [data, setData] = useState({});
  const [preselectedAsset, setPreselectedAsset] = useState(null);
  const [preselectedoperator, setPreselectedOperator] = useState(null);
  const [previousOperator, setPreviousOperator] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addCustomeOperator, setAddCustomeOperator] = useState(false);
  const [customeOperator, setCustomeOperator] = useState({
    name: "",
  });
  //Comments and Source File States 
  const [comments, setComments] = useState("");
  const [sourcefiledropdown, setSourceFileDropdown] = React.useState([]);
  var intialReportValue = {
    reportInfoId: 0,
    sourceFileId: 0,
    pageNo: 0,
    isDeleted: false,
    isEdited: false,
    reportType: 0,
  };
  const [reportInfoDataValue, setReportInfoDataValue] = useState([
    intialReportValue,
  ]);
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [removeIndex, setRemoveIndex] = useState(null);
  const [order, setOrder] = useState(null);
  const [reportInfoId, setReportInfoIdValue] = useState(null);
  const [stateReport, setStateReport] = React.useState({ right: false });
  const [state, setState] = useState({ right: false });
  const [showReportInfo, setShowReportInfo] = React.useState(false);
  const [crudStateReport, setCrudStateReport] = React.useState("");
  const getAssetInfo = async () => {
    console.log("testsam1", props.jobAssetId);
    var a;
    var b;
    var c;
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0
    ) {
      return;
    }
    await axios
      .get(
        process.env.React_App_baseUrl + "ManageOwnership/GetOperators",
        /* "ManageOwnership/SaveOperator" */
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res1) => {
        setOperatorDropdown(res1.data);
        c = res1;
        console.log(res1.data);
      });
    await axios
      .post(
        `${process.env.React_App_baseUrl}ManageOwnership/GetJobAssetInfo`,
        null,
        {
          headers: {
            jobAssetId: props.jobAssetId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res2) => {
        setData(res2.data);
        console.log("preselected+", res2.data, assetdropdown, operatordropdown);
        // Find the preselected asset object based on matching asset IDs
        a = res2;

         /* setLatitude(res2.data.latitude ? res2.data.latitude : null);
        setLongitude(res2.data.longtitude ? res2.data.longtitude : null);   */
        //setComments(res2.data.comments?res2.data.comments:"");

        // props.setFinalData({
        //   ...props.finalData,
        //   latitude: res2.data.latitude ? Number(res2.data.latitude) : 0,
        //   longitude: res2.data.longtitude ? Number(res2.data.longtitude) : 0,
        // });
       
    
     
      })
      .catch((err) => {
        {
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        }
        // setOpenDialog(false);
      });

    if (
      props.jobId === undefined ||
      props.jobId === null ||
      props.jobId === 0
    ) {
      return;
    }
    await axios
      .post(
        process.env.React_App_baseUrl + "ManageOwnership/GetJobAssetIds",
        null,
        {
          headers: {
            jobId: props.jobId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res3) => {
        b = res3;
        setAssetDropdown(res3.data);
        console.log("hell", res3.data);
      });

/*       //SOURCE FILE DROPDOWN
      if (
        props.jobAssetId === undefined ||
        props.jobAssetId === null ||
        props.jobAssetId === 0
      ) {
        return;
      }
      axios
        .post(
          process.env.React_App_baseUrl +
            "ManageOwnership/GetSourceFileOwnerInfoDropdown",
          null,
          {
            headers: {
              jobAssetId: props.jobAssetId,
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
          }
        )
        .then((res) => {
          setSourceFileDropdown(res.data);
          console.log(res.data);
        }); */

    console.log("preselected33", /* b.data, c.data, */ a?.data);
    const preselected = b.data.find((e) => e.assetGUID === a.data.assetGUID);
    const preselectedvalue = c.data.find((e) => e.id === a.data.operatorId);
    console.log("preselected", preselected);
    console.log("preselectedvalue", preselectedvalue);
    let ownerData = [];
         if (a?.data.operatorInfoReportDetailsList !== null) {
          a.data.operatorInfoReportDetailsList.forEach((e) => {
            const report = {
              reportInfoId: e.reportInfoMappingId,
              sourceFileId: e.jobAssetReportInfoId,
              operatorInfoReportId:0,
              reportName: e.reportName,
              pageNo: e.pageNumber,
              isDeleted: false,
              isEdited: false,
              reportType: 0,
              link:e.link,
            };
              ownerData.push(report);
          });
        } 
    props.setFinalData({
      ...props.finalData,
      assetId: preselected?.assetId,
      operatorId: preselectedvalue?.id,
      latitude:Number(a.data.latitude),
      longitude:Number(a.data.longtitude),
      operatorComment:a.data?.operatorComment,
      editOwnerReportDetails: ownerData,
    });
    setPreselectedAsset(preselected);
    props?.setPreselectedAsset(preselected)
    console.log("PRPPPPP",props)
    if (customeOperator.name !== "") {
      setPreselectedOperator(customeOperator);
    } else {
      if (preselectedvalue) {
        setPreselectedOperator(preselectedvalue);
      }
    }
    setComments(a.data?.operatorComment)
    setLatitude(a.data?.latitude);
    setLongitude(a.data?.longtitude);
    const preselectedSourceFilesOwner = ownerData.map((item) => ({
      sourceFile: item,
      pageNo: item.pageNo || "",
    }));
    setReportInfoDataValue(preselectedSourceFilesOwner);

    // setIsOpenAssign(false);
  };
const GetSourceFileDropdown=(()=>{
      //SOURCE FILE DROPDOWN
      if (
        props.jobAssetId === undefined ||
        props.jobAssetId === null ||
        props.jobAssetId === 0
      ) {
        return;
      }
      axios
        .post(
          process.env.React_App_baseUrl +
            "ManageOwnership/GetSourceFileOwnerInfoDropdown",
          null,
          {
            headers: {
              jobAssetId: props.jobAssetId,
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
          }
        )
        .then((res) => {
          setSourceFileDropdown(res.data);
          console.log(res.data);
        });
})
  React.useEffect(() => {
    getAssetInfo();
  }, [props.jobAssetId, props.jobId]);
React.useEffect(()=>{
  GetSourceFileDropdown()
},[props.jobAssetId,props.reload])

  //Set Latitude and Longitude
  const handleLatitudeChange = (event) => {
    console.log(event);
    setLatitude(event.target.value);
    props.setFinalData({
      ...props.finalData,
      latitude: Number(event.target.value),
    });

  };

  const handleLongitudeChange = (event) => {
    console.log(event);
    setLongitude(event.target.value);
    props.setFinalData({
      ...props.finalData,
      longitude: Number(event.target.value),
    });
  };
  const onChangeHandleOperator = (value, event, i, valuePlace) => {
    console.log(event, value);
    if (value) {
      if (event === "name" && value.name === "Others") {
        setAddCustomeOperator(!addCustomeOperator);
      }
    }
  };

  console.log(addCustomeOperator);

  const handleClose = () => {
    setAddCustomeOperator(!addCustomeOperator);
    setPreselectedOperator(previousOperator);
  };
  const handleAddCustomeOperator = () => {
    if (customeOperator.name.length > 0) {
      setLoading(true);
      ApiService.create("ManageOwnership/SaveOperator", customeOperator)
        .then((res) => {
          setLoading(false);
          getAssetInfo();
          if (res.status === 200) {
            toasterSuccess("Operator added successfully ");
          } else {
            toasterError("Somthing went wrong");
          }
          setAddCustomeOperator(!addCustomeOperator);
        })
        .catch((err) => {
          setLoading(false);
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        });
    } else {
      toasterError("Please Enter the Operator Name");
    }
  };
//Comments and Source File Functions
  const RemoveData = (index, reportType, mappingId) => {
    setRemoveIndex(index);
    setOrder(reportType);
    setReportInfoIdValue(mappingId);
    setConfirmPopUp(true);
  };
  const addReport = (reportType) => {

      setReportInfoDataValue((prevFiles) => {
        const updatedFileso = [...prevFiles, { sourceFile: null, pageNo: "" }];
        const updatedFormDataOwner = {
          ...props.finalData,
          editOwnerReportDetails: updatedFileso.map((file) => ({
            reportInfoId: 0,
            sourceFileId: file.sourceFile
              ? file.sourceFile.id || file.sourceFile?.sourceFileId
              : 0,
            reportName: file.sourceFile?.reportName,
            pageNo: file.pageNo,
            reportType: 1,
            isEdited: false,
            isDeleted: false,
          })),
        };
        props.setFinalData((prevFormData) => ({
          ...prevFormData,
          editOwnerReportDetails: updatedFormDataOwner.editOwnerReportDetails,
        }));

        return updatedFileso;
      });
    
  };
  const removeReport = (index, reportType, mappingId) => {

      setReportInfoDataValue((prevFiles) => {
        const updatedFiles = prevFiles.map((file) => {
          if (
            file.sourceFile &&
            file.sourceFile.reportInfoId === reportInfoId
          ) {
            return {
              reportInfoId: file.sourceFile.reportInfoId,
              sourceFileId: file.sourceFile.sourceFileId,
              reportName: file.sourceFile.reportName,
              pageNo: file.sourceFile.pageNo,
              reportType: 1,
              isEdited: false,
              isDeleted: true,
            };
          }
          return {
            reportInfoId: file.sourceFile?.reportInfoId,
            sourceFileId: file.sourceFile?.sourceFileId,
            reportName: file.sourceFile?.reportName,
            pageNo: file.sourceFile?.pageNo,
            reportType: 1,
            isEdited: false,
            isDeleted: false,
          };
        });

        const updatedFormDataOwner = {
          ...props.finalData,
          editOwnerReportDetails: updatedFiles,
        };

        props.setFinalData(updatedFormDataOwner);

        const updatedSourceFile = [...reportInfoDataValue];
        updatedSourceFile.splice(removeIndex, 1); // Remove the file from the UI
        setReportInfoDataValue(updatedSourceFile);

        return updatedFiles;
      });
 
  };
  useEffect(() => {}, [removeIndex, order, reportInfoId]);
  React.useEffect(() => {
    if (reportInfoDataValue.length === 0) {
      setReportInfoDataValue([
        {
          reportInfoId: 0,
          sourceFileId: 0,
          pageNo: 0,
          isDeleted: false,
          isEdited: false,
          reportType: 0,
        },
      ]);
    }

  }, [
    reportInfoDataValue.length
  ]);
  const toggleDrawerReport = (open, mode) => (event, item) => {
    setStateReport({ ...stateReport, right: open });
    setShowReportInfo(true);
    setCrudStateReport(mode);
  };
  const openDocument = (file, pageNumber = 1) => {
    if (pageNumber === "") {
      pageNumber = 1;
    }
    //var jobdetails = tempData?.state;
    var reportName = file + ".pdf";
    var senData = {
      jobId: props.jobId,
      fileName: reportName,
      pageNumber: Number(pageNumber),
      crudState: "Edit",
    };
    localStorage.setItem("filePreview", JSON.stringify(senData));
   // CommonService.AddTokenFromSessionStorageToLocalStorage();
    window.open(
      "#app/ownership-changes/file-preview",
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <>
      <AccordionDetails>
        <Box className={classes.borderPadding}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={3}
            sx={{ mb: 0 }}
          >
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={assetdropdown}
                getOptionLabel={(option) => option.assetName}
                value={preselectedAsset}
                onChange={(event, value) => {
                  //  ...userData,
                  setPreselectedAsset({
                    assetName: value && value.assetName ? value.assetName : "",
                    assetId: value && value.assetId ? value.assetId : null,
                  });
                  props.setFinalData({
                    ...props.finalData,
                    assetId: value.assetId ? value.assetId : null,
                  });                                  
                  props.setjobAssetId(value.jobAssetId)
                 
                  //setPreselectedAsset(newValue);
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.assetId}>
                    {option.assetName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Asset" variant="standard" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Typography variant="caption" display="block" gutterBottom>
                Country
              </Typography>
              <Typography variant="body1">{data.country}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Typography variant="caption" display="block" gutterBottom>
                Region
              </Typography>
              <Typography variant="body1">{data.region}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Typography variant="caption" display="block" gutterBottom>
                Sub Region
              </Typography>
              <Typography variant="body1">{data.subRegion}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Typography variant="caption" display="block" gutterBottom>
                Commodity Type
              </Typography>
              <Typography variant="body1">{data.commodity}</Typography>
            </Grid>
            
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <TextField
                type="number"
                id="standard-basic"
                label="Latitude"
                fullWidth
                disabled={!(props?.dataPoints?.lattitude)}
                variant="standard"
                value={latitude}
                onChange={handleLatitudeChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="self-end"
                >
                  <Grid item xs>
                    <TextField
                      type="number"
                      id="standard-basic"
                      disabled={!(props?.dataPoints?.longitude)}
                      label="Longitude"
                      fullWidth
                      variant="standard"
                      value={longitude}
                      onChange={handleLongitudeChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs="auto">
                    <Tooltip title="Open Location" placement="top" arrow>
                      <IconButton
                        size="large"
                        color="accent6"
                        disabled={latitude === 0 && longitude === 0}
                        onClick={() =>
                          window.open(
                            `https://www.google.com/maps/?q=${latitude},${longitude}&z=5`,
                            "_blank"
                          )
                        }
                      >
                        <PlaceIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={operatordropdown}
                getOptionLabel={(option) => option.name}
                value={preselectedoperator}
                disabled={!(props?.dataPoints?.operator)}
                onChange={(e, value) => {
                  setPreviousOperator(preselectedoperator);
                  onChangeHandleOperator(value, "name", {
                    name: value && value.name ? value.name : "",
                    id: value && value.id ? value.id : null,
                  });
                  setPreselectedOperator(value);
                  props.setFinalData({
                    ...props.finalData,
                    operatorId: value.id ? value.id : null,
                  });
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.id}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Operator" variant="standard" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="standard-basic"
                        label="Comments"
                        variant="standard"
                        value={comments}
                         onChange={(e) => {
                           props.setFinalData({
                             ...props.finalData,
                             operatorComment: e.target.value,
                           });
                           setComments(e.target.value);
                        
                         }}
                        multiline
                        minRows={1}
                        maxRows={1}
                      />
            </Grid>

            </Grid>
            {reportInfoDataValue.map((el, index) => {
                      return (
                        <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={3}
                        sx={{ mt: 0 }}
                      >
                          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="self-end"
                              >
                                <Grid item xs>
                                  <Autocomplete
                                    disablePortal
                                    disableClearable
                                    id="combo-box-demo"
                                    options={sourcefiledropdown}
                                    fullWidth
                                    value={el.sourceFile || null}
                                    getOptionLabel={(option) =>
                                      option.reportName
                                    }
                                    onChange={(event, newValue) => {
                                      console.log("ev",event,newValue)
                                      if (newValue?.reportName === "Others") {
                                        toggleDrawerReport(true, "Add")(
                                          event,
                                          newValue
                                        );
                                      } else {
                                        const updatedFiles = [
                                          ...reportInfoDataValue,
                                        ];
                                        updatedFiles[index] = {
                                          ...el,
                                          sourceFile: newValue? {
                                            ...el.sourceFile,
                                            ...newValue,
                                          }:null,
                                        };
                                        console.log("GTTT", updatedFiles, el);
                                        setReportInfoDataValue(updatedFiles);
                                        const updatedFormData = {
                                          ...props.finalData,
                                          editOwnerReportDetails:
                                            updatedFiles.map((file) => ({
                                              reportInfoId: file.sourceFile
                                                .reportInfoId
                                                ? file.sourceFile?.reportInfoId
                                                : 0,
                                              sourceFileId: file.sourceFile
                                                ? file.sourceFile.id ||
                                                  file.sourceFile?.sourceFileId
                                                : 0,
                                              reportName:
                                                file.sourceFile?.reportName,
                                              pageNo: file?.pageNo,
                                              reportType: 0,
                                              isEdited: true,
                                              isDeleted: false,
                                            })),
                                        };
                                        props.setFinalData(updatedFormData);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Source File"
                                        //required
                                        variant="standard"
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item xs="auto">
                                  <Tooltip
                                    title="Open Document"
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      color="accent6"
                                      aria-label="add to shopping cart"
                                      disabled={!el.sourceFile}
                                      onClick={() => {
                                        openDocument(
                                          el?.sourceFile?.reportName,
                                          el?.pageNo
                                        );
                                      }}
                                    >
                                      <TextDocumentIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid item xs="auto">
                                  <Tooltip
                                    title={el.sourceFile ? el.sourceFile.link : ""}
                                    placement="top"
                                    arrow
                                  >

                                    <IconButton color="accent4" disabled={!el.sourceFile}>
                                      <LinkIcon
                                        color="accent4"
                                        
                                        onClick={(event) => {
                                          window.open(`${el.sourceFile ? el.sourceFile.link : ""}`, "_blank");
                                        }}
                                      />
                                    </IconButton>

                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
                            <Box>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="self-start"
                                alignItems="self-end"
                              >
                                <Grid item xs="auto">
                                  <TextField
                                    id="standard-basic"
                                    type="number"
                                    label="Page No"
                                    value={el.pageNo || ""}
                                    minValue={1}
                                    onChange={(event) => {
                                      const updatedFiles = [
                                        ...reportInfoDataValue,
                                      ];
                                      updatedFiles[index] = {
                                        ...updatedFiles[index],
                                        pageNo: event.target.value,
                                      };
                                      setReportInfoDataValue(updatedFiles);
                                      const updatedFormData = {
                                        ...props.finalData,
                                        editOwnerReportDetails:
                                          updatedFiles.map((file) => ({
                                            reportInfoId: file?.sourceFile
                                              ?.reportInfoId
                                              ? file.sourceFile?.reportInfoId
                                              : 0,
                                            sourceFileId: file.sourceFile
                                              ? file.sourceFile.id ||
                                                file.sourceFile?.sourceFileId
                                              : 0,
                                            reportName:
                                              file.sourceFile?.reportName,
                                            pageNo: file?.pageNo,
                                            reportType: 0,
                                            isEdited: true,

                                            isDeleted: false,
                                          })),
                                      };
                                      props.setFinalData(updatedFormData);
                                    }}
                                    sx={{ width: 100 }}
                                    fullWidth
                                    //required
                                    variant="standard"
                                  />
                                </Grid>

                                {props.access?<>
                                {reportInfoDataValue.length > 1 ? (
                                  <Grid item xs="auto">
                                    <Tooltip
                                      title="Delete "
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton color="error">
                                        <DeleteIcon
                                          style={{ fontSize: "20px" }}
                                          onClick={() => {
                                            // removeReport(
                                            //   index,
                                            //   1,
                                            //   el.sourceFile?.reportInfoId
                                            // );
                                            RemoveData(
                                              index,
                                              1,
                                              el.sourceFile?.reportInfoId
                                            );
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                ) : (
                                  <Grid item xs="auto">
                                    <Tooltip
                                      title="Delete"
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton color="error">
                                        <DeleteIcon
                                          style={{ fontSize: "20px" }}
                                          onClick={() => {
                                            RemoveData(
                                              index,
                                              1,
                                              el.sourceFile?.reportInfoId
                                            );
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                                {index === 0 && (
                                  <Grid item xs="auto">
                                    <Tooltip title="Add" placement="top" arrow>
                                      <IconButton
                                        color="secondary"
                                        style={{ fontSize: "20px" }}
                                        onClick={() => {
                                          addReport(1);
                                        }}
                                      >
                                        <CirclePlusIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}</>
                                :<></>}
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      );
                    })}
            {/* <Grid item xs={12}>
                          <div className={classes.borderBottom}></div>
                        </Grid> */}
          
        </Box>
      </AccordionDetails>

      {/* POPUP TO ADD NEW OPERATOR  */}
      {addCustomeOperator ? (
        <Dialog open={true} fullWidth onClose={handleClose}>
          <DialogTitle>Add New Operator</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              name="name"
              label="Operator"
              type="email"
              fullWidth
              required
              variant="standard"
              inputProps={{ maxLength: 200 }}
              onChange={(event) => {
                let data = customeOperator;
                data[event.target.name] = event.target.value;
                setCustomeOperator(data);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Button onClick={handleAddCustomeOperator}>Add</Button>
            )}
          </DialogActions>
        </Dialog>
      ) : null}
      {/* PoPUP FOR SOURCE FILE CONFIRMATION ON Remove */}
      {confirmPopUp ? (
        <AlertDialog
          open={confirmPopUp}
          title={"Confirmation"}
          maxWidth={"sm"}
          // ${deleteJobNumber}
          description={`Are you sure you want to delete  ?`} /* Id : ${deleteData} */
          action={
            <Grid container justifyContent="right">
              <Grid item xs={"auto"}>
                <Button
                  onClick={() => setConfirmPopUp(false)}
                  style={{ marginRight: "15px" }}
                >
                  No
                </Button>
              </Grid>
              <Grid item xs={"auto"}>
                {deleteBtnLoadinng ? (
                  <CircularProgress />
                ) : (
                  <Button
                    onClick={() => {
                      setConfirmPopUp(false);
                      removeReport();
                    }}
                    color="secondary"
                  >
                    Yes
                  </Button>
                )}
              </Grid>
            </Grid>
          }
        />
      ) : null}

<SideDrawer
        formComp={
          <AddReportInfo
            state={stateReport}
            setState={setStateReport}
            getSourceFile={() => {
             // getAssetInfo();
             GetSourceFileDropdown()
            }}
            jobId={props.jobId}
            jobAssetId={props.jobAssetId}
            crudState={crudStateReport}
            /* getFileName={(value) => {
              setPrefillReportName({
                id: value.id,
                reportName: value.reportName,
              });
            }} */
             
            hideSideDrawer={() => {
             
              setStateReport({ right: false });
              //getAssetInfo();
              GetSourceFileDropdown()
              if(props.setReload){
                props.setReload(!props.reload)
            }
            }}
          />
        }
        state={stateReport}
        setState={setStateReport}
      />
    </>
  );
};

export default OwnershipChangesAssetInfo;
