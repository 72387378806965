import axios from "axios";
import ErroHandling from "./ErrorHandling";

function GetMenuService(id, callback) {

    axios.get(`${process.env.React_App_baseUrl}account/GetMenuPermissions`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
        params: {
            "menuId": id,
        }
    })
        .then((res) => {
            callback(null, res);
        })
        .catch((err) => {
            callback(err)
        })


    axios.get(`${process.env.React_App_baseUrl}account/GetMenuList`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null,
        }
    }).then((res) => {
       // console.log(res.data, "++++++++")
    })
}


export default GetMenuService;
