// Default React, useState and Material Components
import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling"

// Importing Custom Components
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";  
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddEditCurrency = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [CurrencyData, setCurrencyData] = useState({ name: '', description: '', id: 0, });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    if (props.crudState === "Edit" || props.crudState === "View") {
      let data = { id: props.formData.id, name: props.formData.name, description: props.formData.description };
      setCurrencyData(data);
    }
  }, [props]);
  // Declaration of React Hooks End Here

 // This function is used to register a new currency
const registerCurrency = () => {
  // Set loading to true while the request is being processed
  setLoading(true);
  // Validate the CurrencyData
  if (validation(CurrencyData)) {
    // Make an axios post request to save the currency data
    axios.post(process.env.React_App_baseUrl + "managedata/SaveCurrency", null, {
      headers: { 
          // Encode the currency name and description
          currencyName: encodeURIComponent(CurrencyData.name), 
          currencyDescription: encodeURIComponent(CurrencyData.description),
          // Get the token from sessionStorage
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
    })
      .then((res) => {
        // If the response status is 200, show success message and hide the side drawer
        if (res.status === 200) {
          toasterSuccess("Currency details added successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // Handle errors
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      },
      // Set loading to false after the request is completed
      setLoading(false)
      );
  }else{
      // Set loading to false if validation fails
      setLoading(false)
  }
};
  // Function for Validation
  const validation = (formData) => {
    if (formData.name.trim() === "") { toasterError("Please enter currency"); return false; }
    if (formData.description.trim() === "") { toasterError("Please enter description"); return false; }
    return true;
  }

  // Function for Update Region
  const updateRegion = () => {
    //Set loading to true
    setLoading(true);
     //Check if validation is successful
    if (validation(CurrencyData)) {
      //Make a post request to the server
      axios.post(process.env.React_App_baseUrl + "managedata/UpdateCurrency", CurrencyData,{
        headers: {
          //Set authorization header
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
        .then((res) => {
          //If response status is 200, show success message and hide side drawer
          if (res.status === 200) {
            toasterSuccess("Currency details edited successfully!");
            setLoading(false);
            props.hideSideDrawer();
          }
        })
        .catch((err) => {
          //Error handling
          ErroHandling(err, function (res) {
          toasterError(res)
        }) },
        //Set loading to false
        setLoading(false)
        );
    }else{
      //Set loading to false
      setLoading(false)
    }
  };

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Currency
        </Typography>
      </Box>

      {/* Add & Edit Currency Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            InputProps={{
              readOnly: props.crudState === "View" && true
            }}
            required
            fullWidth
            id="standard-basic"
            name="Currency"
            size="small"
            label="Currency"
            placeholder="Currency"
            variant="standard"
            inputProps={{ maxLength: 50 }}
            value={props.crudState === "Add" ? null : props.formData.name}
            onChange={(e) => {
              props.setFormData({ ...props.formData, name: e.target.value });
              setCurrencyData({ ...CurrencyData, name: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            InputProps={{
              readOnly: props.crudState === "View" && true,
            }}
            required
            fullWidth
            multiline
            maxRows={4}
            id="standard-basic"
            name="description"
            size="small"
            label="Description"
            placeholder="Description"
            variant="standard"
            value={props.crudState === "Add" ? null : props.formData.description}
            onChange={(e) => {
              props.setFormData({ ...props.formData, description: e.target.value });
              setCurrencyData({ ...CurrencyData, description: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>

        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={'auto'}>
                 <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Add" ? registerCurrency : updateRegion}
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant="contained" color="accent3" onClick={() => props.setState({ ...props.state, right: false })}>Cancel</Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddEditCurrency;