// Default React, Router and Services Components
import React, { useContext, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Components
import BaseLayout from "../../../../UIComponent/BaseLayout";

// Importing useStyles
import useStyles from "./ScoringReport.styles";
// Import Fluent UI Icons
import {
  ChevronRightSmallIcon,
  ClearIcon,
  CodeEditIcon,
  SearchIcon,
  ExportIcon,
} from "@fluentui/react-icons-mdl2";
import DatePicker from "../../../../UIComponent/DatePicker";
import { useSelector } from "react-redux";
import { FilterContext } from "../../../DataCollection/DashboardReports/Context/Context";
import axios from "axios";
// import history from "../../../../Routes/History";
import { useHistory } from "react-router-dom";

import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
import ExportToExcel from "../Excelexport";
import DataTable from "../../../../UIComponent/DataTable";
import SideDrawer from "../../../../UIComponent/Drawer";
import QCSamplingFilter from "./ScoringReportFilter";
import ExcelExport from "../../../Shared/ExcelExport";

import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
const OwnershipChangesQCSamplingReport = (props) => {
  const history = useHistory();
  const filterState = useSelector((state) => state.stateSlice);
  const { filterQC, assetDropDownlist } = useSelector((store) => store.filter);
  const { companyList, setCompanyList, locationQC, setLocationQC } =
    useContext(FilterContext);

  const [allRows, setAllRows] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [crudState, setCrudState] = React.useState(false);
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [editJobNumber, setEditJobNumber] = useState(null);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValue, setFilterValue] = useState(null);
  const [filterValues, setFilterValues] = useState();
  const [formData, setFormData] = useState([]);
  const [idforEdit, setIdForEdit] = useState(null);
  const [isAllSelected, setIsSelected] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [locationFilter, setLocationFilter] = useState([]);
  const [analystName, setAnalystName] = useState('');
  const [selectedAnalyst, setSelectedAnalyst] = useState([]);
const [excelData,setExcelData]=useState([]);
  const [qcNames, seQCNames] = useState('');
  const [selectedMeritQC, setSelectedMeritQC] = useState([]);
  const [excelHeader, setExcelHeader] = useState(["Job No & Name",
    "Asset",
    "Operator",
    "Owner",
    "Job Creation Date",
    "Created By",
    "TAT",
    "Job Completion Date",
    "Assinged To",
    "Merit QC",
    "Merit QC Completion Date",
    "Status",
    "Scoring %",
    "Failed Parameter"]);
  const [searchData, setSearchData] = useState({
    FromDate: null,
    ToDate: null,
  });
  const [menuPermission, setMenuPermission] = useState({
    allowView: true,
    allowEdit: true,
    allowDelete: true,
  });
  const [open, setOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scroll, setScroll] = React.useState("paper");
  const [searchValue, setSearchValue] = useState("");
  const [selected, SetSelected] = useState([]);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [state, setState] = React.useState({ right: false });
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalaverage, setTotalAverage] = useState(0);
  const [submitValues, setSubmitValues] = useState(false);
  const [value, setValue] = useState({ startDate: "", endDate: "" });
  const classes = useStyles();
  const date = new Date().toLocaleDateString();
  const date1 = date.split("/").join("-");

  const gridcolumns = [
    // { id: "jobId", label: "Job Id", minWidth: 140, width: "20%" },
    {
      id: "jobNoAndName",
      //type: "clickable",
      label: "Job No & Name",
      minWidth: 350,
      width: "20%",
      sorting: true,
      //align: "center",
    },
    {
      id: "asset",
      //type: "clickable",
      label: "Asset",
      minWidth: 250,
      minWidth: 250,
      width: "20%",
      sorting: true,
      //align: "center",
    },
    {
      id: "operator",
      //type: "clickable",
      label: "Operator",
      minWidth: 180,
      minWidth: 180,
      width: "20%",
      sorting: true,
      //align: "center",
    },
    {
      id: "company",
      //type: "clickable",
      label: "Owner",
      minWidth: 200,
      minWidth: 200,
      width: "20%",
      sorting: true,
      //align: "center",
    },
    {
      id: "jobCreationDate",
      //type: "clickable",
      label: "Job Creation Date",
      minWidth: 130,
      width: "20%",
      sorting: true,
      align: "center",
    },
    {
      id: "createdBy",
      //type: "clickable",
      label: "Created By",
      minWidth: 180,
      minWidth: 180,
      width: "20%",
      sorting: true,
      //align: "center",
    },
    {
      id: "tat",
      //type: "clickable",
      label: "TAT",
      minWidth: 110,
      width: "20%",
      sorting: true,
      align: "center",
    },
    {
      id: "jobCompletionDate",
      //type: "clickable",
      label: "Job Completion Date",
      minWidth: 150,
      width: "20%",
      sorting: true,
      align: "center",
    },
    {
      id: "assignedTo",
      //type: "clickable",
      label: "Assinged To",
      minWidth: 180,
      minWidth: 180,
      width: "20%",
      sorting: true,
      //align: "center",
    },
    {
      id: "meritQc",
      //type: "clickable",
      label: "Merit QC",
      minWidth: 180,
      label: "Merit QC",
      minWidth: 180,
      width: "20%",
      sorting: true,
      //align: "center",
    },
    {
      id: "meritQCCompletionDate",
      //type: "clickable",
      label: "Merit QC Completion Date",
      minWidth: 160,
      width: "20%",
      sorting: true,
      align: "center",
    },
    {
      id: "statusName",
      //type: "clickable",
      label: "Status",
      minWidth: 170,
      width: "20%",
      sorting: true,
      //align: "center",
    },
    {
      id: "scoringPercentage",
      //type: "clickable",
      label: "Scoring %",
      minWidth: 110,
      width: "20%",
      sorting: true,
      align: "center",
    },
    {
      id: "failedParameter",
      label: "Failed Parameter",
      minWidth: 250,
      width: "20%",
      sorting: true,
      truncate: 55,
      tooltipWithTruncate: true
    },
  ];
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];

 // This function changes the format of a given column name
const changeSortColumnNameFormat = (columnName) => {
  // Initialize newColumnName variable
  let newColumnName = "";
  // Check if columnName is not empty or null
  if (columnName !== "" && columnName !== null) {
    // Capitalize first letter of columnName and add it to newColumnName
    newColumnName = columnName[0].toUpperCase() + columnName.slice(1, columnName.length)
  }
  // Check if columnName is equal to "Company"
  if (columnName.toLocaleLowerCase() === "company".toLocaleLowerCase()) {
    // Set newColumnName to "Company"
    newColumnName = "Company";
  }
  // Check if columnName is equal to "TAT"
  if (columnName.toLocaleLowerCase() === "tat".toLocaleLowerCase()) {
    // Set newColumnName to "TAT"
    newColumnName = "TAT";
  }
  // Return new column name 
   return newColumnName;
}
  // search on enter press
  const handleKeyUp = (event) => {
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      GetScoringReport();
    }
  };


  // To Fill the grid
  React.useEffect(() => {
    GetScoringReport();
  }, [filterValue, page, sorting, rowsPerPage, submitValues, reload]);

  React.useEffect(() => {
    getFilterData();
  }, [reload]);

  // For searching the grid
  const globalSearch = (value) => {
    setTotalCount(0);
    setPage(0);
    setSearchValue(value);
  };

  
// GetScoringReport is an async function that sets loading to true, 
// creates a promise with searchData and axios get request, 
// sets allRows, calculates average, sets tableData, totalCount and loading to false.
const GetScoringReport = async () => {
  setLoading(true);
  let response = await new Promise((resolve, reject) => {
    let searchvalue;

    let searchData = { ...filterQC, searchText: searchValue };
    resolve(
      axios.get(`${process.env.React_App_baseUrl}CompanyOwnershipReport/GetScoringReportForCompanyOwnership?pageNumber=${page + 1}&pageSize=${rowsPerPage}`, {
        headers: {
          filter: encodeURIComponent(JSON.stringify(searchData)),
          sortColumn: changeSortColumnNameFormat(sorting.sortBy),
          isAscending: sorting.isAscending,
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      })
    );
  });
  setAllRows(response.data.data);
  
  /* if(response?.data?.data){
      let excelData=[];
      response?.data?.data.forEach((element,index)=>{
        excelData.push({"Job No & Name":element.jobNoAndName,
        "Asset":element.asset,
        "Operator":element.operator,
        "Owner":element.company,
        "Job Creation Date":element.jobCreationDate,
        "Created By":element.createdBy,
        "TAT":element.tat,
        "Job Completion Date":element.jobCompletionDate,
        "Assinged To":element.assignedTo,
        "Merit QC":element.meritQc,
        "Merit QC Completion Date":element.meritQCCompletionDate,
        "Status":element.statusName,
        "Scoring %":element.scoringPercentage,
        "Failed Parameter":element.failedParameter })
        setExcelData(excelData);
      });
    } */

  // Calculate the average of scoringPercentage
  if (response.data.data) {
     let average = 0;
     response.data.data.forEach((a) => {
       average += a.scoringPercentage;
     });
     if (average !== 0) {
       const recordsProcessed =
         page + 1 === 1
           ? Math.min(response.data.totalCount, rowsPerPage)
           : Math.min(rowsPerPage, response.data.totalCount - rowsPerPage);

       average = average / recordsProcessed;
     }
     setTotalAverage(average);
   }

  // Set tableData, totalCount and loading to false
  setTableData(response.data.data);
  setTotalCount(response.data.totalCount);
  setLoading(false);
  return response;
};


// This function handles the export of a scoring report to an Excel file
const handleExportToExcel = () => {
    // Call the ExportScoringReport() function to export the report
    ExportScoringReport();
  };

 // This function is used to export data from an API to a CSV file
const exportToCSV = (apiData, fileName) => {
  // Set the file type and extension
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  
  // Convert the API data to a worksheet
  const ws = XLSX.utils.json_to_sheet(apiData);
  
  // Add the excel header to the worksheet
  XLSX.utils.sheet_add_aoa(ws, [excelHeader], { origin: "A1", });
  
  // Create a workbook with the worksheet
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  
  // Write the workbook to an array buffer
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  
  // Create a blob from the array buffer
  const data = new Blob([excelBuffer], { type: fileType });
  
  // Save the blob as a file
  FileSaver.saveAs(data, fileName + fileExtension);
};

// Create a file name with the format MeritQCSamplingReport followed by the current date in the format of YYYY-MM-DD
const fileName = `MeritQCSamplingReport ${new Date()
  .toLocaleDateString() // Get the current date in the format of MM/DD/YYYY
  .split("/") // Split the date into an array of strings using "/" as the separator
  .join("-")}`; // Join the array of strings using "-" as the separ

  // Function to export scoring report in CSV format
const ExportScoringReport = async () => {
  // Set loading to true
  setLoading(true);
  // Create a promise to get response from API
  let response = await new Promise((resolve, reject) => {
    // Declare searchValue variable
    let searchvalue;

    // Create searchData object with filterQC and searchText
    let searchData = { ...filterQC, searchText: searchValue };
    // Resolve the promise with axios get request
    resolve(
      axios.get(`${process.env.React_App_baseUrl}CompanyOwnershipReport/GetScoringReportForCompanyOwnership?pageNumber=${page + 1}&pageSize=${totalCount}`, {
        headers: {
          filter: encodeURIComponent(JSON.stringify(searchData)),
          sortColumn: changeSortColumnNameFormat(sorting.sortBy),
          isAscending: sorting.isAscending,
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
      })
    );
  });
  
  // Check if response data exists
  if(response?.data?.data){
    // Declare excelData array
    let excelData=[];
    // Loop through response data
    response?.data?.data.forEach((element,index)=>{
      // Push data into excelData array
      excelData.push({"Job No & Name":element.jobNoAndName,
      "Asset":element.asset,
      "Operator":element.operator,
      "Owner":element.company,
      "Job Creation Date":element.jobCreationDate,
      "Created By":element.createdBy,
      "TAT":element.tat,
      "Job Completion Date":element.jobCompletionDate,
      "Assinged To":element.assignedTo,
      "Merit QC":element.meritQc,
      "Merit QC Completion Date":element.meritQCCompletionDate,
      "Status":element.statusName,
      "Scoring %":element.scoringPercentage,
      "Failed Parameter":element.failedParameter })
      // Set excelData
      setExcelData(excelData);
    });
    // Call exportToCSV function with excelData and fileName as parameters
    exportToCSV(excelData, fileName);
  }
  // Set loading to false
  setLoading(false);
  // Return response
  return response;
}

  // Function for Search Clear
  const searchClear = () => {
    setSearchValue("");
    setPage(0);
    setReload(!reload);
  };


  // Function for Validation
  const validation = (value) => {
    if (value.startDate >= value.endDate) {
      // toasterError("To Date Must Be Greater");
      return false;
    }
    return true;
  };

  // Function for Getting Source Filter Data
  const getFilterData = async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      if (response.data.length === 0) {
        setDataForFilter([]);
      } else {
        setDataForFilter(response.data);
        response.data.companies.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
          }
        });
        response.data.location.regions.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
            if (item.children) {
              item.children.forEach((item, index) => {
                item.checked = false;
              });
            }
          }
        });
        if (companyList.length === 0) {
          setCompanyList(response.data.companies);
        }
        if (locationQC.length === 0) {
          setLocationQC(response.data.location.regions);
        }

        setCompanyFilter(response.data.companies);
        setLocationFilter(response.data.location.regions);
      }
    } catch (err) {
      ErroHandling(err, function (res) {
        toasterError(res);
      });
    }
  };

  // Function for Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    setState({ ...state, right: open });
  };
  const [assetTypeDD, setAssetTypeDD] = useState([]);

  const resetTreeFilter = () => {
    let comFilter = companyFilter;
    let loFilter = locationFilter;
    comFilter.forEach((item, index) => {
      item.checked = false;
      if (item.children) {
        item.children.forEach((item, index) => {
          item.checked = false;
        });
      }
    });
    loFilter.forEach((item, index) => {
      item.checked = false;
      if (item.children) {
        item.children.forEach((item, index) => {
          item.checked = false;
        });
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
        }
      }
    });
    setCompanyFilter([...comFilter]);
    setLocationFilter([...loFilter]);
  };

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} className="page-title">
          <h2>Scoring Report</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>
              Home
            </Link>
            <Typography>
              Manage Company Ownership Changes
            </Typography>
            <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/qc-sampling-report" }}>
              Dashboard and Reports
            </Link>
            <Typography color="text.primary">
              Scoring Report
            </Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* BaseLayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            {/*  <Box sx={{ pt: 11 }}>
              <center>
                <EmptyState
                  icon={
                    <CodeEditIcon
                      style={{ color: "#db9400", fontSize: "70px", marginBottom: "10px" }}
                    />
                  }
                  title="Under Construction!"
                />
              </center>
            </Box> */}
            <Box className={classes.addBorder}>

              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  alignItems="flex-end"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      size="small"
                      fullWidth
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="serchByClear"
                        onClick={searchClear}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Grid
                      container
                      spacing={2}
                      justifyContent="flex-end"
                      alignItems="flex-end"
                    >
                      {/* <Grid item xs={"auto"}>
                        <Typography variant="subtitle2">
                          <ExcelExport
                            fileName={`MeritQCSamplingReport ${date1}`}
                            headers={excelHeader}
                            dataRow={excelData}

                          />
                        </Typography>
                      </Grid> */}
                      <Grid item textAlign="right">
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                          onClick={handleExportToExcel}
                        >
                          Export To Excel
                        </Button>
                      </Grid>
                      <Grid item xs={"auto"}>
                        <Button
                          onClick={toggleDrawer(true)}
                          variant="contained"
                          color="secondary"
                        >
                          Filter
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Merit QC Report Table */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allRows ? allRows : []}
                  columns={gridcolumns}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageVisible={false}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={setSorting}
                />
              </Grid>
              <Grid
                  container
                  spacing={2}
                >
                  <Grid item xs={12} sm={12} md={12} textAlign="right" justifyContent="flex-end"
                  alignItems="flex-end">
                 <div style={{paddingBottom:"20px", paddingRight:"20px"}}>
                <span style={{fontWeight:'bold',fontSize: 15}}>Average:  </span>
                  <b>{totalaverage}</b>
               </div>
                  </Grid>     
                  </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
      <SideDrawer
        formComp={
          <QCSamplingFilter
            dataForFilter={dataForFilter}
            filterFinalData={filterFinalData}
            companyFilter={companyFilter}
            locationFilter={locationFilter}
            setFilterValues={setFilterValues}
            setFilterValue={setFilterValue}
            state={state}
            setState={setState}
            crudState={crudState}
            formData={formData}
            setFormData={setFormData}
            analystName={analystName}
            setAnalysName={setAnalystName}
            selectedAnalyst={selectedAnalyst}
            setSelectedAnalyst={setSelectedAnalyst}
            qcNames={qcNames}
            seQCNames={seQCNames}
            selectedMeritQC={selectedMeritQC}
            setSelectedMeritQC={setSelectedMeritQC}
            userId={null}
            setLocationFilter={setLocationFilter}
            hideSideDrawer={() => {
              setState({ right: false });
            }}
            reload={reload}
            setReload={setReload}
            setCompanyFilter={setCompanyFilter}
            assetTypeDD={assetTypeDD}
            setFilterFinalData={setFilterFinalData}
            resetTreeFilter={resetTreeFilter}
            setDataForFilter={setDataForFilter}
            getFilterData={getFilterData}
            setSubmitValues={setSubmitValues}
            submitValues={submitValues}
            companyList={companyList}
            setCompanyList={setCompanyList}
            locationQC={locationQC}
            setLocationQC={setLocationQC}
          />
        }
        state={state}
        setState={setState}
        reload={reload}
        setReload={setReload}
        setFilterValue={setFilterValue}
      />
    </Box>
  );
};
export default OwnershipChangesQCSamplingReport;
