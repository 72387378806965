// Default React, Router and Services Components
import React, { useContext } from "react";
import { Button, Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError } from "../../../UIComponent/Toaster";
// Importing Custom Components
import MultiSelect from "../../../UIComponent/MultiSelect/MultiSelect";
import { clearFilterReports, GetAssetData, GetAssetTypeData, setFilterReports, } from "../../../features/filter/filterSlice";
import DropdownTreeSelect from "react-dropdown-tree-select";


// Importing useStyles
import useStyles from "./StatusReport/StatusReport.styles";
import { clearFilterValue, setFiterValue } from "../../../features/filter/StateSlice";
import { callBasedOnLocation, callFromAssetType, callFromComm, callFromPriority, callSource } from "../../../Util/TreeAPI";
import Container from "../SourceManagement/TreeContainer";
import { FilterContext } from "./Context/Context";

// import "../../Resources/scss/layout.scss";
// import "react-toastify/dist/ReactToastify.css";
// import "../../../src/index.scss";
// import "react-dropdown-tree-select/dist/styles.css";

const FilterForm = (props) => {
  const {companyList, setCompanyList, commods, setCommods, frequencey, setFrequency} = useContext(FilterContext)

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const { filterReport, assetDropDownlist } = useSelector((store) => store.filter);
  const dispatch = useDispatch();
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [jobComms, setJobComms] = React.useState([]);
  const [dropDown, setDropDown] = React.useState({ reportingperoid: [], });
  const [values, setValues] = React.useState({ reporting: [], });
  const [priorities, setPriorities] = React.useState([]);
  const [filters, setFilters] = React.useState({
    company: [], asset: [], assetTypeIds: [], countryIds: [], regionIds: [],
    subRegionIds: [], commodityIds: [], statusIds: [], priorityIds: [],
  });
  const [apiData, setApiData] = React.useState({
    companies: [],
    location: {},
    assetType: [],
    priority: [],
    commodity: [],
  });
  const [dependency, setDependency] = React.useState(null);
  const [selectedCompanies, setSelectedCompanies] = React.useState([]);

// onChangeHandler is a function that takes three parameters: key, value and reason
  const onChangeHandler = (key, value, reason) => {
    // create a copy of the filterReport object
    const pre = { ...filterReport };
    // set the value of the key in the copied object
    pre[key] = value;
    // if the key is "commodities"
    if (key === "commodities") {
      setDependency("commodity");
    
    setApiData({
      ...apiData,
      commodity: value,
    });
  } else if (key === "priority") {
    // set dependency for priority
      setDependency("priority");
    setApiData({
      ...apiData,
      priority: value,
    });
  } else if (key==="assetType") {
    // set dependency for assetType
      setDependency("assetType");
    setApiData({
      ...apiData,
      assetType: value,
    });
  }
  // dispatch the updated filterReport object
    dispatch(setFilterReports({ filterReport: pre }));
  };
  // This function clears all the fields in the filter
  const clearFieldHanlder = () => {
    setCompanyList([])
    setCommods([])
    setFrequency([])
    props.setFilterValue(null);
    props.resetTreeFilter()
    props.setReload(!props.reload);
    dispatch(clearFilterReports());
    dispatch(clearFilterValue(null))
    setValues({ reporting: [], });
  };
  // This function is used to submit the filter report
  const submitFilter = () => {
    console.log(filterReport, "filterReport")
    // console.log(filterReport, "filterReprotCommm")
    // Creating an object with the filtered values
    const formData = {
      company: props.companyList
        .filter((el) => el.checked === true)
        .map((el) => el.value),
      asset: props.companyList
        .map((el) =>
          el.children.filter((it) => it.checked === true).map((tk) => tk.value)
        )
        .filter((item) => item.length > 0)
        .flat(1),
      CommodityIds: filterReport.commodities.map((commo) => commo.commodityId),
      reportingFrequency: filterReport.reportingFrequency.map((priority) => priority.value),
    };
    // Dispatching the setFilterValue action
    dispatch(setFiterValue(formData))
    // this data will be send to the body api
    props.setState({ ...props.state, right: false });
    props.setFilterValue(formData);
    setCompanyList(props.companyList)
    setCommods(props.filterValue.commodities)
    setFrequency(props.filterValue.reportingFrequency)
  };
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    getAllCompanies();
    dispatch(GetAssetData());
    dispatch(GetAssetTypeData());
    getAllCommodities();
    reportingFrequency();
    getPriorityDropDown();
  }, []);
  // Declaration of React Hooks End Here
  React.useEffect(() => {
    if (dependency === "company") {
      callSource(apiData)
        .then((res) => {
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationFilter){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationFilter){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationFilter){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "location") {
      callBasedOnLocation(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyList.length; i++) {
              if (props.companyList[i].value === el.value) {
                if (props.companyList[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyList[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyList[i].children[j].value === item.value
                    ) {
                      if (props.companyList[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyList(res.data.companies);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "assetType") {
      callFromAssetType(apiData)
        .then((res) => {
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationFilter){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationFilter){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationFilter){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyList.length; i++) {
              if (props.companyList[i].value === el.value) {
                if (props.companyList[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyList[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyList[i].children[j].value === item.value
                    ) {
                      if (props.companyList[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });

          props.setCompanyList(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            commodity: res.data.commodity,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "priority") {
      callFromPriority(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyList.length; i++) {
              if (props.companyList[i].value === el.value) {
                if (props.companyList[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyList[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyList[i].children[j].value === item.value
                    ) {
                      if (props.companyList[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationFilter){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationFilter){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationFilter){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          props.setCompanyList(res.data.companies);

          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            commodity: res.data.commodity,
          });
        })
        .catch((err) => console.log(err));
    } else if (dependency === "commodity") {
      callFromComm(apiData)
        .then((res) => {
          res.data.companies.forEach((el) => {
            for (let i = 0; i < props.companyList.length; i++) {
              if (props.companyList[i].value === el.value) {
                if (props.companyList[i].checked === true) {
                  el.checked = true;
                }
                el.children.forEach((item) => {
                  for (
                    let j = 0;
                    j < props.companyList[i].children.length;
                    j++
                  ) {
                    if (
                      props.companyList[i].children[j].value === item.value
                    ) {
                      if (props.companyList[i].children[j].checked === true) {
                        item.checked = true;
                      }
                    }
                  }
                });
              }
            }
          });
          res.data.location.regions.forEach(el=>{
            for( let item of props.locationFilter){
              if(item.value === el.value){
                if(item.checked === true){
                  el.checked = true
                }
              }
            }
            el.children.forEach(it=>{
              for( let item of props.locationFilter){
                for(let items of item.children){
                   if(items.value === it.value){
                    if(items.checked === true){
                      it.checked = true
                    }
                   }
                }
              }
              it.children.forEach(kt=>{
                for( let item of props.locationFilter){
                  for(let items of item.children){
                     for(let ite of items.children){
                      if(ite.value === kt.value){
                        if(ite.checked === true){
                          kt.checked = true
                        }
                      }
                     }
                  }
                }
              })
            })
          })
          props.setCompanyList(res.data.companies);
          props.setLocationFilter(res.data.location.regions);
          props.setDataForFilter({
            ...props.dataForFilter,
            assetType: res.data.assetType,
            priority: res.data.priority,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [apiData]);

  // This function is used to handle the change in company selection
  const onChangeComp = (currentNode, selectedNodes) => {
    // Set the dependency for company
      setDependency("company");
    // Set the selected companies
    setSelectedCompanies(selectedNodes);
    // Iterate through the company list and check/uncheck the nodes based on the current node
    props.companyList.forEach((el) => {
      if (currentNode.checked === true) {
        if (currentNode.value === el.value) {
          el.checked = true;
        } else if (currentNode.parentValue === el.value) {
          el.children.forEach((item) => {
            if (currentNode.value === item.value) {
              item.checked = true;
            }
          });
        }
      } else if (currentNode.checked === false) {
        if (currentNode.value === el.value) {
          el.checked = false;
        } else if (currentNode.parentValue === el.value) {
          el.children.forEach((item) => {
            if (currentNode.value === item.value) {
              item.checked = false;
            }
          });
        }
      }
    });
    // Get the first parent of the company list
    const firstParent = props.companyList
      .map((el) => {
        let data = {
          label: el.label,
          value: el.value,
          children: el.children.filter((item) => item["checked"] === true),
        };
        return data;
      })
      .filter((el) => el.children.length > 0);
      // Set the api data with the filtered company list
    setApiData({
      ...apiData,
      companies:
        props.companyList.filter((el) => el["checked"] === true).length > 0
          ? props.companyList.filter((el) => el["checked"] === true)
          : firstParent,
    });
  };
  // Function for Get Company DropDown Filter
  // Function to get all companies from the API
  const getAllCompanies = () => {
    // Call ApiService to get all companies
    ApiService.getAll("DropDown/GetCompanyDropDownFilter")
      .then((res) => {
        // If response status is 200, set job companies
        if (res.status === 200) { setJobCompanies(res.data); }
        // Else show error message
        else { toasterError(`${res.data}`); }
      })
      .catch((err) => {
        // If response status is 400, show error message
        if(err.res.status===400){
          toasterError(err.res.data);
        }
         });
  };

  // Function for Get Reporting Period
  // This function is used to get the reporting frequency from the server
  const reportingFrequency = () => {
    // Making a GET request to the server using axios
    axios.get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingPeriod",{
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })
      .then(function (response) {
        // Mapping the response data to an array of objects
        let data = response.data.map((el) => {
          return { label: el.name, value: el.id };
        });
        // Setting the dropdown state with the response data
        setDropDown((prev) => {
          return { ...prev, reportingperoid: data || [] };
        });
        // Setting the values state with the response data
        setValues((prev) => {
          return {
            ...prev, reporting:
              data.filter((el) => prev.reporting.includes(el.value)) || [],
          };
        });
      })
      .catch((err) => {
        // Handling errors if the status code is 400
        if(err.res.status===400){
          toasterError(err.res.data);
        }
         });
  };

  // Function for Get Commodity DropDown Filter
  // Function to get all commodities from the API
  const getAllCommodities = () => {
    ApiService.getAll("DropDown/GetCommodityDropDownFilter")
      .then((res) => {
        // If response status is 200, set job commodities
        if (res.status === 200) { setJobComms(res.data); }
        // Else show error message
        else { toasterError(`${res.data}`); }
      })
      .catch((err) => {
        // If response status is 400, show error message
        if(err.res.status===400){
          toasterError(err.res.data);
        }
         });
  };

  // Function for Get Priority DropDown Filter
  // Function to get the Priority DropDown from the API
  const getPriorityDropDown = async () => {
    try {
      // Call ApiService to get all data from the endpoint
      await ApiService.getAll("DropDown/GetPriorityDropDownFilter").then(
        (res) => {
          // Destructure the response data
          const { data } = res;
          // Set the priorities with the response data
          setPriorities(data);
        }
      );
    } catch (err) { ErroHandling(err, function (res) {
      // Display an error message in a toaster
      toasterError(res)}) }
  };
 

  return (

    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Add & Edit Currency Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Container
            data={props.companyList}
            onChange={onChangeComp}
            showPartiallySelected={true}
            texts={{ placeholder: 'Company' }}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={commods}
            items={props.dataForFilter.commodity ? props.dataForFilter.commodity : []}
            label="Commodities"
            selectAllLabel={{ commodityName: "Select all" }}
            getOptionLabel={(option) => option.commodityName}
            getOptionSelected={(option, anotherOption) =>
              option.commodityName === anotherOption.commodityName
            }
            role="commodities"
            key="commodities"
          />
        </Grid>
        {/* <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterReport.reportingFrequency}
            items={dropDown.reportingperoid}
            label="Reporting Frequency"
            selectAllLabel={{ label: "Select all" }}
            getOptionLabel={(option) => option.label}
            role="reportingFrequency"
          />
        </Grid> */}

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn" color="accent5" onClick={submitFilter}>
                Filter
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn reset-color" color="accent3" style={{ margin: "0 !important" }} onClick={clearFieldHanlder}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterForm;