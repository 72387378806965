import * as React from "react";
import { useState } from "react";
import {
    Box,
    Button,
    Grid,
    IconButton,
    Paper,
    Tooltip
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { DeleteIcon } from "@fluentui/react-icons-mdl2";
import CustomDataTable from "../../../UIComponent/CustomDataTable/CustomDataTable";
import axios from "axios";
import AddSeeThroughOwnerInfo from "./AddSeeThroughOwnerInfo";
import SideDrawer from "../../../UIComponent/Drawer";
import EditSeeThroughOwnerInfo from "./EditSeeThroughtOwnerInfo";
import AddJointVentureInfo from "./AddJoinVenture";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import CommonService from "../../../Services/CommonService";
import EditJointVentureInfo from "../JobManagement/EditJoinVenture"
import AlertDialog from "../../../UIComponent/AlertDialog";
import dayjs from "dayjs";














const OwnershipJoinVenture = (props) => {
    console.log(props)
    const [gridData, setGridData] = useState([{ row1: [], row2: [] }])
    const [state, setState] = useState({ right: false });
    const [crudState, setCrudState] = React.useState();
    const [refresh, setRefresh] = React.useState(false);
    const [stateedit, setStateEdit] = useState({ right: false });
    const [stateedit1, setStateEdit1] = useState({ right: false });
    const [crudStateEdit, setCrudStateEdit] = React.useState(false);
    const [crudStateEdit1, setCrudStateEdit1] = React.useState(false);
    const [prefillData, setPreFillData] = useState([]);
    const [prefillData1, setPreFillData1] = useState([]);
    const [jobAsst, setJobAsst] = useState(0)
    const [openConfirmfileDeleteAlart, setopenConfirmfileDeleteAlart] = useState(false)
    const [openConfirmfileDeleteAlart1, setopenConfirmfileDeleteAlart1] = useState(false)


    React.useEffect(() => {
        if (props.jobAssetId) {
            setJobAsst(props.jobAssetId)
        }
    }, [props.jobAssetId])



    React.useEffect(() => {
        if (jobAsst) {
            GetJointVenture()
        }
    }, [jobAsst])

    const toggleDraweredit = (open, mode) => (event, item) => {
        console.log("EE", item);
        var a = { id: item.SeethroughOwnerInfo.id, legalEntityTypeId: item.SeethroughOwnerInfo.legalEntityTypeId, seeThroughOwnerGuid: item.SeethroughOwnerInfo.seeThroughOwnerGuid, seeThroughOwnerComment: item.SeethroughOwnerInfo.seeThroughOwnerComment, yearId: item.SeethroughOwnerInfo.yearId
            //ownershipDate: item.SeethroughOwnerInfo.ownershipDate
            ,seeThroughOwnershipPercentage: item.SeethroughOwnerInfo.seeThroughOwnershipPercentage, seeThroughOwnershipComment: item.SeethroughOwnerInfo.seeThroughOwnershipComment, stockExchangeId: item.SeethroughOwnerInfo.stockExchangeId, stockExchangeComment: item.SeethroughOwnerInfo.stockExchangeComment, seeThroughOwnerSourceFileList: item.SeeThroughOwnerFileList, seeThroughOwnershipSourceFileList: item.SeeThroughOwnerPerFileList, stockExchangeSourceFileList: item.SeeThroughOwnerExchangeFileList,unsure:item.unsure }
        setPreFillData(a);
        setStateEdit({ ...stateedit, right: open });
        setCrudStateEdit(mode);
    };

    const toggleDrawereditJointventure = (open, mode) => (event, item) => {
        console.log("EE", item);
        var a = { id: item.ownerinfo.jobAssetJointVentureId, year: item.ownerinfo.year ? item.ownerinfo.year : null, yearId: item.ownerinfo.yearId ? item.ownerinfo.yearId : null, legalEntityTypeId: item.ownerinfo.legalEntityTypeId, ownershipYear: item.ownerinfo.ownershipYear, seeThroughOwnerGuid: item.ownerinfo.ownerGUID, seeThroughOwnerComment: item.ownerinfo.ownerComment, seeThroughOwnershipPercentage: item.ownerinfo.ownershipPercentage, seeThroughOwnershipComment: item.ownerinfo.ownershipComment, stockExchangeId: item.ownerinfo.stockExchangeId, stockExchangeComment: item.ownerinfo.stockExchangeComment, seeThroughOwnerSourceFileList: item.joinVentureCompnayToBeIncludedFileList, seeThroughOwnershipSourceFileList: item.ownershipFileList, stockExchangeSourceFileList: item.stockExchangeFileList,unsure:item.unsure,ownershipYearId:item.ownerinfo.ownershipYearId }
        setPreFillData1(a);
        setStateEdit1({ ...stateedit1, right: open });
        setCrudStateEdit1(mode);
    };

    const GetJointVenture = () => {
        console.log("JointVenture")
        if (jobAsst > 0) {
            axios
                .post(
                    process.env.React_App_baseUrl + "ManageOwnership/GetJointVentureDetails",
                    null,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token")
                                ? "Bearer " + localStorage.getItem("token")
                                : null,
                            jobAssetId: jobAsst
                        },
                    }
                )
                .then((res) => {
                    var ownerpercent = 0
                    var resData = res.data.map((item) => {
                        var resJoinVenture = []
                        var resSeeThrough = []
                        var joinVentureCompnayToBeIncludedFileListKey = []
                        var ownershipFileListKey = []
                        var stockExchangeFileListKey = []

                        item?.jointVentureReportDetailsList.map((subitem) => {
                            if (subitem.reportType === 1) {
                                joinVentureCompnayToBeIncludedFileListKey.push({
                                    link: subitem.link,
                                    reportName: subitem.reportName,
                                    pageNo: subitem.pageNumber,
                                    jobAssetReportInfoId:subitem.jobAssetReportInfoId,
                                    reportInfoMappingId:subitem.jointVentureReportMappingId                                })
                            } else if (subitem.reportType === 2) {
                                ownershipFileListKey.push({
                                    link: subitem.link,
                                    reportName: subitem.reportName,
                                    pageNo: subitem.pageNumber,
                                    jobAssetReportInfoId:subitem.jobAssetReportInfoId,
                                    reportInfoMappingId:subitem.jointVentureReportMappingId
                                })
                            } else if (subitem.reportType === 3) {
                                stockExchangeFileListKey.push({
                                    link: subitem.link,
                                    reportName: subitem.reportName,
                                    pageNo: subitem.pageNumber,
                                    jobAssetReportInfoId:subitem.jobAssetReportInfoId,
                                    reportInfoMappingId:subitem.jointVentureReportMappingId
                                })
                            }
                            return true;
                        })

                        item?.seeThroughOwnerInfoList.map((subitem, index) => {
                            var seethroughOwner = []
                            var seethroughPercent = []
                            var seethroughExchange = []
                            subitem?.seeThroughOwnerReportDetailList.map((subItemofSub) => {
                                if (subItemofSub.reportType === 1) {
                                    seethroughOwner.push({
                                        link: subItemofSub.link,
                                        reportName: subItemofSub.reportName,
                                        pageNo: subItemofSub.pageNumber,
                                        jobAssetReportInfoId: subItemofSub.jobAssetReportInfoId,
                                        reportInfoMappingId: subItemofSub.seeThroughOwnerReportMappingId
                                    })
                                } else if (subItemofSub.reportType === 2) {
                                    seethroughPercent.push({
                                        link: subItemofSub.link,
                                        reportName: subItemofSub.reportName,
                                        pageNo: subItemofSub.pageNumber,
                                        jobAssetReportInfoId: subItemofSub.jobAssetReportInfoId,
                                        reportInfoMappingId: subItemofSub.seeThroughOwnerReportMappingId

                                    })
                                } else if (subItemofSub.reportType === 3) {
                                    seethroughExchange.push({
                                        link: subItemofSub.link,
                                        reportName: subItemofSub.reportName,
                                        pageNo: subItemofSub.pageNumber,
                                        jobAssetReportInfoId: subItemofSub.jobAssetReportInfoId,
                                        reportInfoMappingId: subItemofSub.seeThroughOwnerReportMappingId

                                    })
                                }
                            })
                            resSeeThrough.push({
                                slNo: index + 1,
                                seeThroughOwner: subitem.seeThroughOwnerName ? subitem.seeThroughOwnerName : 'Others',
                                seeThroughOwnerComment: subitem.seeThroughOwnerComment,
                                SeeThroughOwnerPer: subitem.seeThroughOwnershipPercentage,
                                SeeThroughOwnerPerComment: subitem.seeThroughOwnershipComment,
                                SeeThroughOwnerPerFileList: seethroughPercent,
                                SeeThroughOwnerFileList: seethroughOwner,
                                SeeThroughOwnerExchangeFileList: seethroughExchange,
                                year:subitem.year,
                                unsure:subitem.unsure,
                                stockExchange:subitem.stockExchange,
                                SeethroughOwnerInfo: subitem
                            })
                        })
                        ownerpercent = ownerpercent + Number(item.ownershipPercentage)                       
                        console.log("YEAROO",item)
                        resJoinVenture.push({
                            fromYear: item.year ? item.year : '',
                            companyToBeIncluded: item.ownerName ? item.ownerName : 'Others',
                            unsure:item.unsure,
                            companyToBeIncludedComment: item.ownerComment,
                            ownerShipperPer: item.ownershipPercentage,
                            ownerShipperPerComment: item.ownershipComment,
                            //ownershipDate: dayjs(item.ownershipDate).format('DD-MM-YYYY'),
                            ownershipYear:item.ownershipYear,
                            stockExchange: item.stockExchangeName,
                            stockExchangeComment: item.stockExchangeComment,
                            joinVentureCompnayToBeIncludedFileList: joinVentureCompnayToBeIncludedFileListKey,
                            ownershipFileList: ownershipFileListKey,
                            stockExchangeFileList: stockExchangeFileListKey,
                            ownerinfo: item
                        })

                        var returnData = { row1: resJoinVenture, row2: resSeeThrough, jobAssetJointVentureId: item.jobAssetJointVentureId, ownerGUID: item.ownerGUID }
                        return returnData
                    }
                    )
                    console.log(resData)
                    if (resData.length) {
                        setGridData(resData)
                        props.setOwnerPercent(ownerpercent)
                    } else {
                        setGridData([{ row1: [], row2: [] }])

                    }
                });
        }
    };

    const columns3 = [
        { id: "id", label: "Status", type: "Highlight" },
        {
            id: "fromYear",
            label: "From Year",
            minWidth: 100,
            width: 100,
            align: "center",
        },
        {
            id: "companyToBeIncluded",
            label: "Company to be included (Format Name)",
            minWidth: 300,
            width: 300,
            qValue: true
        },
        {
            id: "ownershipYear",
            label: "Ownership Date",
            minWidth: 120,
            width: "16%",
        },
        {
            id: "ownershipFileList",
            label: "Ownership (%)",
            minWidth: 120,
            width: "16%",
            qValue: true
        },
        {
            id: "stockExchange",
            label: "Stock Exchange",
            minWidth: 250,
            // width: "20%",
            qValue: true
        },
    ];

    const columns2 = [
        { id: "id", label: "Status", type: "Highlight" },
        { id: "slNo", label: "Sl.No", minWidth: 100, width: 100, align: "center" },
        {
            id: "seeThroughOwner",
            label: "Equity Owner",
            minWidth: 300,
            width: 300,
        },
        {
            id: "SeeThroughOwnerPer",
            label: "Equity owner %",
            minWidth: 120,
            width: "16%",
            qValue: true
        },
        {
            id: "year",
            label: "Year",
            minWidth: 120,
            width: "16%",
        },
        {
            id: "stockExchange",
            label: "Stock Exchange",
            minWidth: 250,
            // width: 150,
        },
    ];
    // const rows3 = [
    //     {
    //         row1: [
    //             {
    //                 fromYear: 2022,
    //                 joinVentureCompnayToBeIncludedFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",

    //                     }
    //                 ],
    //                 companyToBeIncluded: "Test",
    //                 companyToBeIncludedComment: "Test Comment",
    //                 ownershipFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 ownerShipperPer: "10.1",
    //                 ownerShipperPerComment: "Percent Comment",
    //                 stockExchangeFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 stockExchange: "Test34",
    //                 stockExchangeComment: "Stockcomment",

    //             },
    //             {
    //                 fromYear: 2023,
    //                 joinVentureCompnayToBeIncludedFileList: [
    //                     {
    //                         link: "https://www.speedtests.net/",
    //                         reportName: "Speet Tests",

    //                     }
    //                 ],
    //                 companyToBeIncluded: "Test1",
    //                 companyToBeIncludedComment: "Test Comment1",
    //                 ownershipFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 ownerShipperPer: "10.1",
    //                 ownerShipperPerComment: "Percent Comment",
    //                 stockExchangeFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 stockExchange: "Test34",
    //                 stockExchangeComment: "Stockcomment",
    //             }
    //         ],
    //         row2: [
    //             {
    //                 slNo: 1,
    //                 seeThroughOwner: "Prithivi",
    //                 SeeThroughOwnerPer: 100,
    //             },
    //             {
    //                 slNo: 2,
    //                 seeThroughOwner: "Raj",
    //                 SeeThroughOwnerPer: 89,
    //                 SeeThroughOwnerPerFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 SeeThroughOwnerFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 SeeThroughOwnerPerComment: "SeeThroughOwnerPerComment",
    //                 SeeThroughOwnerComment:"SeeThroughOwnerComment"
    //             }
    //         ]
    //     },
    //     {
    //         row1: [
    //             {
    //                 fromYear: 2022,
    //                 joinVentureCompnayToBeIncludedFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",

    //                     }
    //                 ],
    //                 companyToBeIncluded: "Test",
    //                 companyToBeIncludedComment: "Test Comment",
    //                 ownershipFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 ownerShipperPer: "10.1",
    //                 ownerShipperPerComment: "Percent Comment",
    //                 stockExchangeFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 stockExchange: "Test34",
    //                 stockExchangeComment: "Stockcomment",

    //             },
    //             {
    //                 fromYear: 2023,
    //                 joinVentureCompnayToBeIncludedFileList: [
    //                     {
    //                         link: "https://www.speedtests.net/",
    //                         reportName: "Speet Tests",

    //                     }
    //                 ],
    //                 companyToBeIncluded: "Test1",
    //                 companyToBeIncludedComment: "Test Comment1",
    //                 ownershipFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 ownerShipperPer: "10.1",
    //                 ownerShipperPerComment: "Percent Comment",
    //                 stockExchangeFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 stockExchange: "Test34",
    //                 stockExchangeComment: "Stockcomment",
    //             }
    //         ],
    //         row2: [
    //             {
    //                 slNo: 1,
    //                 seeThroughOwner: "Prithivi",
    //                 SeeThroughOwnerPer: 100,
    //             },
    //             {
    //                 slNo: 2,
    //                 seeThroughOwner: "Raj",
    //                 SeeThroughOwnerPer: 89,
    //                 SeeThroughOwnerPerFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 SeeThroughOwnerPerComment: "SeeThroughOwnerPerComment"
    //             }
    //         ]
    //     },
    //     {
    //         row1: [
    //             {
    //                 fromYear: 2022,
    //                 joinVentureCompnayToBeIncludedFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",

    //                     }
    //                 ],
    //                 companyToBeIncluded: "Test",
    //                 companyToBeIncludedComment: "Test Comment",
    //                 ownershipFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 ownerShipperPer: "10.1",
    //                 ownerShipperPerComment: "Percent Comment",
    //                 stockExchangeFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 stockExchange: "Test34",
    //                 stockExchangeComment: "Stockcomment",

    //             },
    //             {
    //                 fromYear: 2023,
    //                 joinVentureCompnayToBeIncludedFileList: [
    //                     {
    //                         link: "https://www.speedtests.net/",
    //                         reportName: "Speet Tests",

    //                     }
    //                 ],
    //                 companyToBeIncluded: "Test1",
    //                 companyToBeIncludedComment: "Test Comment1",
    //                 ownershipFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 ownerShipperPer: "10.1",
    //                 ownerShipperPerComment: "Percent Comment",
    //                 stockExchangeFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 stockExchange: "Test34",
    //                 stockExchangeComment: "Stockcomment",
    //             }
    //         ],
    //         row2: [
    //             {
    //                 slNo: 1,
    //                 seeThroughOwner: "Prithivi",
    //                 SeeThroughOwnerPer: 100,
    //             },
    //             {
    //                 slNo: 2,
    //                 seeThroughOwner: "Raj",
    //                 SeeThroughOwnerPer: 89,
    //                 SeeThroughOwnerPerFileList: [
    //                     {
    //                         link: "https://www.speedtest.net/",
    //                         reportName: "Speet Test",
    //                     }
    //                 ],
    //                 SeeThroughOwnerPerComment: "SeeThroughOwnerPerComment"
    //             }
    //         ]
    //     }
    // ]




    const [access, setAccess] = React.useState({
        allowEdit: true,
        allowDelete: true,
        allowView: true,
    });

    const [joinVenture, setJoinVenture] = React.useState()
    const [ownerGuid, setOwnerGuid] = React.useState()
    const [jointventureId, setJointventureId] = React.useState()
    const [seethroughId, setSeethroughId] = React.useState()

    const JointVentureDelete = () => {
        axios.delete(process.env.React_App_baseUrl + "ManageOwnership/DeleteJointVentureInfo?jointVentureInfoId=" + jointventureId, {
            headers: {
                Authorization: localStorage.getItem("token")
                    ? "Bearer " + localStorage.getItem("token")
                    : null,
            },
        }).then(() => {
            toasterSuccess("Deleted Successfully")
            GetJointVenture()
        }).catch(() => {
            toasterError("Something went wrong")
        })
    }

    const seeThroughOwnerDelte = () => {
        axios.delete(process.env.React_App_baseUrl + "ManageOwnership/DeleteSeeThroughOwnerInfo?seeThroughOwnerInfoId=" + seethroughId + "&&isJointVentureSeeThroughOwnerInfo=" + true, {
            headers: {
                Authorization: localStorage.getItem("token")
                    ? "Bearer " + localStorage.getItem("token")
                    : null,
            },
        }).then(() => {
            toasterSuccess("Deleted Successfully")
            GetJointVenture()
        }).catch(() => {
            toasterError("Something went wrong")
        })
    }

    const toggleDrawer = (open, mode) => (event, item) => {
        if (mode === "Delete") {
            setJointventureId(item?.ownerinfo?.jobAssetJointVentureId)
            setopenConfirmfileDeleteAlart(true)

        }
    };

    const toggleDrawer1 = (open, mode) => (event, item) => {
        if (mode === "Delete") {
            setSeethroughId(item?.SeethroughOwnerInfo?.id)
            setopenConfirmfileDeleteAlart1(true)
        }
    };



    const toggleDrawer2 = (open, mode, item) => (event) => {
        setJoinVenture(item.jobAssetJointVentureId)
        setOwnerGuid(item.ownerGUID)
        setState({ ...state, right: open });
        setCrudState('Add')
    };

    const openDocumentForView = (item) => {
        console.log("item", item, props.jobId)
        let sendData = {
            jobId: props.jobId,
            fileName: item.reportName + ".pdf",
            pageNumber: item.pageNo,
            crudState: "View",
        };
        CommonService.openFilePreview(sendData)
    }


    return (
        <Grid
            container
            alignItems="flex-end"
            justifyContent="flex-start"
            spacing={3}
            sx={{ mt: -3 }}
        >
            {gridData.map((item, i) => {
                return (
                    <Grid item xs={12}>
                        <Box className={props.classes.jointVentureDetails}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <Grid item xs={12} sx={{ mt: 0, mb: -1 }}>
                                        <Paper
                                            elevation={0}
                                            className={props.classes.recordTitleArea}
                                        >
                                            <Box className={props.classes.recordTitle}>
                                                Joint Venture {i + 1}
                                            </Box>
                                            <Box className={props.classes.recordLine}></Box>
                                            {/* <Box className={props.classes.recordAction}>
                                                <Tooltip
                                                    title="Delete"
                                                    placement="top"
                                                    arrow
                                                >
                                                    <IconButton color="error">
                                                        <DeleteIcon
                                                            style={{ fontSize: "20px" }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box> */}
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box className={props.classes.tableBorder}>
                                        <CustomDataTable
                                            rows={item.row1}
                                            columns={columns3}
                                            openDocumentForView={openDocumentForView}
                                            isActionButton={{
                                                ...(props.access && {
                                                    edit: toggleDrawereditJointventure(true, "Edit"),
                                                }),
                                                ...(props.access && {
                                                    delete: toggleDrawer(true, "Delete"),
                                                }),
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                {/* Joint Venture See Through Owner Info Start Here */}
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        spacing={3}
                                        alignItems="center"
                                        sx={{ mb: 2 }}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            sx={{ mb: -2 }}
                                        >
                                            <h4 className={props.classes.subHeadingText2}>
                                                Equity Owner Info
                                            </h4>
                                        </Grid>
                                        {props.access && props.dataPoints?.seeThroughOwners?
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Grid
                                                item
                                                xs={12}
                                                container
                                                spacing={0}
                                                sx={{ mt: 1, mb: 0 }}
                                                justifyContent="flex-end"
                                                textAlign="right"
                                            >
                                                <Grid item xs={"auto"}>
                                                    <Button
                                                        variant="contained"
                                                        color="accent7"
                                                        size="small"
                                                        startIcon={
                                                            <AddIcon style={{ fontSize: 16 }} />
                                                        }
                                                        onClick={toggleDrawer2(true, "Add", item)}
                                                    >
                                                        Add Equity owner
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>:<></>}
                                      </Grid>
                                    <Grid item xs={12}>
                                        <Box className={props.classes.tableBorder}>
                                            <CustomDataTable
                                                rows={item.row2}
                                                columns={columns2}
                                                openDocumentForView={openDocumentForView}
                                                isActionButton={{
                                                    ...(props.access && props.dataPoints?.seeThroughOwners && {
                                                        edit: toggleDraweredit(true, "Edit"),
                                                    }),
                                                    ...(props.access && props.dataPoints?.seeThroughOwners && {
                                                        delete: toggleDrawer1(true, "Delete"),
                                                    }),
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                {/* Joint Venture See Through Owner Info End Here */}
                            </Grid>
                        </Box>
                    </Grid>
                )
            })}
            <SideDrawer
                formComp={
                    <AddSeeThroughOwnerInfo
                        state={state}
                        setState={setState}
                        jobAssetId={jobAsst}
                        jobId={props.jobId}
                        jointVentureInfoId={joinVenture}
                        crudState={crudState}
                        setRefresh={setRefresh}
                        refresh={refresh}
                        companyGuid={ownerGuid}
                        hideSideDrawer={() => {
                            console.log("histttty",props)
                            setState({ right: false });
                            GetJointVenture();
                            if(props.setReload){
                                props.setReload(!props.reload)
                            }
                            
                        }}
                    />
                }
                state={state}
                setState={setState}
                crudState={crudState}
            />


            <SideDrawer
                formComp={
                    <EditSeeThroughOwnerInfo
                        state={stateedit}
                        setState={setStateEdit}
                        jobAssetId={jobAsst}
                        jobId={props.jobId}
                        crudState={crudStateEdit}
                        prefillData={prefillData ? prefillData : ""}
                        setPreFillData={setPreFillData}
                        companyGuid={ownerGuid}
                        setRefresh={setRefresh}
                        hideSideDrawer={() => {
                            setStateEdit({ right: false });
                            GetJointVenture();
                            if(props.setReload){
                                props.setReload(!props.reload)
                            }
                        }}
                    />
                }
                state={stateedit}
                setState={setStateEdit}
                crudState={crudStateEdit}
            />

            <SideDrawer
                formComp={
                    <EditJointVentureInfo
                        state={stateedit1}
                        setState={setStateEdit1}
                        jobAssetId={jobAsst}
                        jobId={props.jobId}
                        crudState={crudStateEdit1}
                        prefillData={prefillData1 ? prefillData1 : ""}
                        setPreFillData={setPreFillData1}
                        companyGuid={ownerGuid}
                        setRefresh={setRefresh}
                        hideSideDrawer={() => {
                            setStateEdit1({ right: false });
                            GetJointVenture();
                            if(props.setReload){
                                props.setReload(!props.reload)
                            }
                        }}
                    />
                }
                state={stateedit1}
                setState={setStateEdit1}
                crudState={crudStateEdit1}
            />

            <SideDrawer
                formComp={
                    <AddJointVentureInfo
                        state={props.State3}
                        setState={props.setState3}
                        jobAssetId={jobAsst}
                        jobId={props.jobId}
                        crudState={props.crudState}
                        setRefresh={setRefresh}
                        refresh={refresh}
                        companyGuid={"GERDFTEWW"}
                        hideSideDrawer={() => {
                            props.setState3({ right: false });
                            props.setShowSeeThroughOwnerInfo(false)
                            GetJointVenture()                     
                            if(props.setReload){
                                props.setReload(!props.reload)
                            }
                        }}
                    />
                }
                state={props.State3}
                setState={props.setState3}
                crudState={props.showSeeThroughOwnerInfo}
            />
            <AlertDialog
                open={openConfirmfileDeleteAlart}
                title={"Confirmation"}
                maxWidth={"sm"}
                description={'Are you sure you want to delete ?'}
                action={
                    <>
                        <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                            <Button onClick={() => setopenConfirmfileDeleteAlart(false)}>No</Button>
                            <Button color="secondary"
                                onClick={(e) => {
                                    JointVentureDelete();
                                    setopenConfirmfileDeleteAlart(false)
                                }}
                            >
                                Yes
                            </Button>
                        </div>
                    </>
                }
            />
            <AlertDialog
                open={openConfirmfileDeleteAlart1}
                title={"Confirmation"}
                maxWidth={"sm"}
                description={'Are you sure you want to delete ?'}
                action={
                    <>
                        <div style={{ marginBottom: "10px", marginRight: "10px" }}>
                            <Button onClick={() => setopenConfirmfileDeleteAlart1(false)}>No</Button>
                            <Button color="secondary"
                                onClick={(e) => {
                                    seeThroughOwnerDelte();
                                    setopenConfirmfileDeleteAlart1(false)
                                }}
                            >
                                Yes
                            </Button>
                        </div>
                    </>
                }
            />

        </Grid>

    )
}
export default OwnershipJoinVenture;
