// Default React, Router and Services Components
import React from "react";
import { Button, Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../../../Services/ApiService";
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
import {
  clearFilterDataJob, clearFilterReports, GetAssetData, GetAssetTypeData,
  setFilterActionJob, setFilterReports,
} from "../../../../features/filter/filterSlice";

// Importing Custom Components
import DatePicker from "../../../../UIComponent/DatePicker";
import MultiSelect from "../../../../UIComponent/MultiSelect/MultiSelect";
import DropdownTreeSelect from "react-dropdown-tree-select";

// Importing useStyles
import "../../../../Resources/scss/layout.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-dropdown-tree-select/dist/styles.css";
import useStyles from "./JiraTicketStatusReport.styles";

const FilterForm = (props) => {
  // Declaration of States and Component Variables Start Here
  const { filterReport, assetDropDownlist } = useSelector((store) => store.filter);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [countryDropdownList, setCountryDropdownList] = React.useState([]);
  const [regionDropdownList, setRegionDropdownList] = React.useState([]);
  const [subRegionDropdownList, setSubRegionDropdownList] = React.useState([]);
  const [jobComms, setJobComms] = React.useState([]);
  const [dropDown, setDropDown] = React.useState({ reportingperoid: [], });
  const [values, setValues] = React.useState({ reporting: [], });
  const [fromDate, setFromDate] = React.useState(props.fromDate);
  const [toDate, setToDate] = React.useState(props.toDate);
  const [keyToDate, setKeyToDate] = React.useState(false);
  const [keyFromoDate, setKeyFromDate] = React.useState(false);
  const [filters, setFilters] = React.useState({
    company: [], asset: [], assetTypeIds: [], countryIds: [], regionIds: [], subRegionIds: [],
    commodityIds: [], statusIds: [], priorityIds: [],
  });
  // Declaration of States and Component Variables End Here

  const onChangeHandler = (key, value, reason) => {
    const pre = { ...filterReport };
    pre[key] = value;
    dispatch(setFilterReports({ filterReport: pre }));
  };

  // Function for Handle KeyUp
  const clearFieldHanlder = () => {
    props.setReload(!props.reload);
    setKeyFromDate(k => !k);
    setKeyToDate(t => !t);
    dispatch(clearFilterReports());
    setValues({
      reporting: [],
    });
  };

  // Function for Search Filter
  const searchFilter = () => {
    // Create an object with all the filter values
    const formData = {
      company: [...new Set(filters.company)],
      asset: [...new Set(filters.asset)],
      countryIds: [...new Set(filters.countryIds)],
      regionIds: [...new Set(filters.regionIds)],
      subRegionIds: [...new Set(filters.subRegionIds)],
      CommodityIds: (filterReport.commodities || []).map((commo) => commo.id),
      FromDate: fromDate,
      ToDate: toDate,
      Status: null
    };
     // Set the state of right to false and set the filter value
    props.setState({ ...props.state, right: false });
    props.setFilterValue(formData);
  };

  // This function is used to get all companies from the API
  const getAllCompanies = () => {
    // Call the ApiService to get all companies
    ApiService.getAll("DropDown/GetCompanyDropDownFilter")
      .then((res) => {
        // If the response status is 200, set the job companies
        if (res.status === 200) {
          setJobCompanies(res.data);
        } else {
          // Otherwise, display an error message
          toasterError(`${res.data}`);
        }
      })
      .catch((err) => {
        // If the response status is 400, display an error message
        if(err.res.status===400){
          toasterError(err.res.data);
        }

        });
  };

// This function is used to get all commodities from the API
const getAllCommodities = () => {
  // Call ApiService to get all commodities
  ApiService.getAll("DropDown/GetCommodityDropDownFilter")
    .then((res) => {
      if (res.status === 200) {
        // Set job commodities with response data
        setJobComms(res.data);
        //console.log(res.data);
      } else {
        // Show error message in toaster
        toasterError(`${res.data}`);
      }
    })
    .catch((err) => {
      // If status code is 400, show error message in toaster
      if(err.res.status===400){
        toasterError(err.res.data);
      }

       });
};

// Function to get all countries from the API
const getAllCountries = () => {
  // Call the ApiService to get all countries
  ApiService.getAll("DropDown/GetCountryDropDownFilter")
    .then((res) => {
      // If the response status is 200, set the country dropdown list
      if (res.status === 200) { setCountryDropdownList(res.data); }
      else {
        // Else, display an error message
        toasterError(`${res.data}`);
      }
    })
    .catch((err) => {
      // If the response status is 400, display an error message
      if(err.res.status===400){
        toasterError(err.res.data);
      }
       });
};

 // Function to get all regions from API
const getAllRegion = () => {
  // Call ApiService to get all regions
  ApiService.getAll("DropDown/GetRegionDropDownFilter")
    .then((res) => {
      // If response status is 200, set region dropdown list
      if (res.status === 200) { setRegionDropdownList(res.data); }
      else {
        // Else show error message
        toasterError(`${res.data}`);
      }
    })
    .catch((err) => {
      // If response status is 400, show error message
      if(err.res.status===400){
        toasterError(err.res.data);
      }

       });
};

  // Function to get all subregions from API
  const getAllSubRegion = () => {
    // Call ApiService to get all subregions
    ApiService.getAll("DropDown/GetSubRegionDropDownFilter")
      .then((res) => {
        // If response status is 200, set the subregion dropdown list
        if (res.status === 200) { setSubRegionDropdownList(res.data); }
        else {
          // Else, display error message
          toasterError(`${res.data}`);
        }
      })
      .catch((err) => {
        // If response status is 400, display error message
        if(err.res.status===400){
          toasterError(err.res.data);
        }

        });
  };

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    getAllCompanies();
    dispatch(GetAssetData());
    dispatch(GetAssetTypeData());
    getAllCommodities();
    getAllCountries();
    getAllSubRegion();
    getAllRegion();
  }, []);
  // Declaration of React Hooks End Here

  // Function for Change Company Asset
  const onChangeCompandAsset = (currentNode, selectedNodes) => {
     // Initialize empty arrays for company and asset filters
    filters.company = []
    filters.asset = []
     // Iterate through selected nodes
    for (let i in selectedNodes) {
      // Check if both parent and child values are present
      let all = selectedNodes[i].parentValue && selectedNodes[i].value;
      // Check if only parent value is present
      let onlyParent = selectedNodes[i].value;
      // If both parent and child values are present
      if (all) {
      // Push parent value to company filter array  
        filters.company.push(selectedNodes[i].parentValue);
     // Push child value to asset filter array   
        filters.asset.push(selectedNodes[i].value);
       // Iterate through props companyFilter array 
        for (let j in props.companyFilter) {
       // Check if parent value matches with props companyFilter array   
          if (selectedNodes[i].parentValue === props.companyFilter[j].value) {
        // Iterate through props companyFilter children array     
            for (let k in props.companyFilter[j].children) {
              if (
         // Check if child value matches with props companyFilter children array       
                selectedNodes[i].value ===
                props.companyFilter[j].children[k].value
              ) {
          // Set checked property of props companyFilter children array to true      
                props.companyFilter[j].children[k].checked = true;
              }
            }
          }
        }
      } 
      // If only parent value is present
      else if (onlyParent) {
      // Push parent value to company filter array  
        filters.company.push(selectedNodes[i].value);
     // Iterate through props companyFilter array   
        for (let k in props.companyFilter) {
     // Check if parent value matches with props companyFilter array     
          if (selectedNodes[i].value === props.companyFilter[k].value) {
      // Set checked property of props companyFilter array to true      
            props.companyFilter[k].checked = true;
          }
        }
      }
    }
  };

  // This function is used to update the filters based on the selected nodes
const onChangeRegions = (currentNode, selectedNodes) => {
  // Initialize the filter variables
  filters.regionIds = []
  filters.subRegionIds = []
  filters.countryIds = []
  // Iterate through the selected nodes
  for (let i in selectedNodes) {
    // Check if all three values are present
    let all =
      selectedNodes[i].grantParentValue &&
      selectedNodes[i].parentValue &&
      selectedNodes[i].value;
    // Check if parent and child values are present
    let parentAndChild =
      selectedNodes[i].parentValue && selectedNodes[i].value;
    // Check if only parent value is present
    let onlyParent = selectedNodes[i].value;
    // If all three values are present
    if (all) {
      // Push the grantParentValue, subRegionIds and countryIds to the respective filters
      filters.regionIds.push(selectedNodes[i].grantParentValue);
      filters.subRegionIds.push(
        selectedNodes[i]
          .grantParentValuegrantParentValuegrantParentValuegrantParentValue
      );
      filters.countryIds.push(selectedNodes[i].value);
      // Iterate through the props locationFilter
      for (let j in props.locationFilter) {
        // Check if the grantParentValue matches with the value of the props locationFilter
        if (
          selectedNodes[i].grantParentValue === props.locationFilter[j].value
        ) {
          // Iterate through the children of the props locationFilter
          for (let k in props.locationFilter[j].children) {
            // Check if the parentValue matches with the value of the children of the props locationFilter
            if (
              selectedNodes[i].parentValue ===
              props.locationFilter[j].children[k].value
            ) {
              // Set the checked property of the children of the props locationFilter to true
              props.locationFilter[j].children[k].checked = true;
              // Iterate through the grandchildren of the props locationFilter
              for (let l in props.locationFilter[j].children[k].children) {
                // Check if the value matches with the value of the grandchildren of the props locationFilter
                if (
                  selectedNodes[i].value ===
                  props.locationFilter[j].children[k].children[l].value
                ) {
                  // Set the checked property of the grandchildren of the props locationFilter to true
                  props.locationFilter[j].children[k].children[
                    l
                  ].checked = true;
                }
              }
            }
          }
        }
      }
    } 
    // If parent and child values are present
    else if (parentAndChild) {
      // Push the parentValue and childValue to the respective filters
      filters.regionIds.push(selectedNodes[i].parentValue);
      filters.subRegionIds.push(selectedNodes[i].value);
      // Iterate through the props locationFilter
      for (let j in props.locationFilter) {
        // Check if the parentValue matches with the value of the props locationFilter
        if (selectedNodes[i].parentValue === props.locationFilter[j].value) {
          // Iterate through the children of the props locationFilter
          for (let k in props.locationFilter[j].children) {
            // Check if the value matches with the value of the children of the props locationFilter
            if (
              selectedNodes[i].value ===
              props.locationFilter[j].children[k].value
            ) {
              // Set the checked property of the children of the props locationFilter to true
              props.locationFilter[j].children[k].checked = true;
            }
          }
        }
      }
    } 
    // If only parent value is present
    else if (onlyParent) {
      // Push the parentValue to the respective filters
      filters.regionIds.push(selectedNodes[i].value);
      // Iterate through the props locationFilter
      for (let k in props.locationFilter) {
        // Check if the value matches with the value of the props locationFilter
        if (selectedNodes[i].value === props.locationFilter[k].value) {
          // Set the checked property of the props locationFilter to true
          props.locationFilter[k].checked = true;
        }
      }
    }
  }
};
  

  return (
    // Layout for Filter
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Filter
        </Typography>
      </Box>

      {/* Filter Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DropdownTreeSelect
            data={props.companyFilter}
            onChange={onChangeCompandAsset}
            showPartiallySelected={true}
            texts={{ placeholder: 'Company' }}
          />
        </Grid>
        <Grid item xs={12}>
          <DropdownTreeSelect
            data={props.locationFilter}
            onChange={onChangeRegions}
            showPartiallySelected={true}
            texts={{ placeholder: 'Location' }}
          />
        </Grid>

        <Grid item xs={12}>
          <MultiSelect
            onChange={onChangeHandler}
            value={filterReport.commodities}
            items={jobComms}
            label="Commodities"
            selectAllLabel={{ commodityName: "Select all" }}
            getOptionLabel={(option) => option.commodityName}
            getOptionSelected={(option, anotherOption) =>
              option.commodityName === anotherOption.commodityName
            }
            // onChange={(newValue) => onChangeHandler("company", newValue)}
            role="commodities"
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            labelTitle="From Date"
            variant="standard"
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
              setFromDate(date)
            }}
            past={false}
            key={keyFromoDate}
            clearable={true}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            labelTitle="To Date"
            variant="standard"
            past={false}
            minDates={fromDate}
            onChangeDate={(e) => {
              let selectedDate = new Date(e);
              let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
              setToDate(date)

            }}
            key={keyToDate}
            clearable={true}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn" color="accent5"
                onClick={searchFilter}>
                Filter
              </Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button variant="contained" className="dummy-btn reset-color" color="accent3"
                onClick={clearFieldHanlder}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterForm;