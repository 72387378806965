import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 640, // Table Max Height
  },
  colHighlight: {
    padding: theme.spacing(0, 0, 0, 1),
  },
  tableHeader: {
    padding: theme.spacing(0.5, 0.75),
    whiteSpace: "normal"
  },
  tableCell: {
    wordBreak: "break-all",
    wordWrap: "break-word",
    borderBottom: "1px solid #d7d7d7 !important",
    padding: theme.spacing(0.5, 0.75),
    height: "46px"
  },
  tableActionCell: {
    padding: theme.spacing(0.5, 0.75),
  },
  cellNoWrap: {
    whiteSpace: "nowrap",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  helperText: {
    display: "block",
    whiteSpace: "nowrap",
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  addBorder: {
    borderRadius: "6px",
    border: "1px solid rgba(224, 224, 224, 1) !important",
  },
  totalRecord: {
    color: "blue",
  },
  alertDialogbtn: {
    margin: "0% 5% 2% 0%",
  },
  rightDrowerbtn: {
    margin: "0% 5% 2% 0%",
    display: 'inline-flex'
  },
  maxWidthDate: {
    width: 170,
    marginRight: 15,
  },
  marginTopSource: {
    marginTop: 14,
  },
  addBorder2: {
    border: '1px solid #d7d7d7',
    borderRadius: 6,
    overflow: 'hidden',
    padding: theme.spacing(0),
    margin: theme.spacing(0, 0, 0, 0),
  },
}));

export default useStyles;