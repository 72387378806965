// Default React, useState and Material Components
import React, { useState } from "react";
import { Button, Box, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import * as Validations from '../../../common/validation';
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddEditFrequency = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [ProducerData, setProducerData] = useState({ id: 0, name: '', description: '', IsActive: true });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    if (props.crudState === "Edit" || props.crudState === "View") {
      let data = { id: props.formData.id, name: props.formData.name, description: props.formData.description };
      setProducerData(data);
    }
  }, [props]);
  // Declaration of React Hooks End Here

  // Function for Validations
  const validation = (formData) => {
    if (formData.name.trim() === "") { toasterError("Please enter frequency"); return false; }
    if (!Validations.onlyTextAndChar(formData.name)) { toasterError("Please enter valid frequency name"); return false; }
    if (formData.description.trim() === "") { toasterError("Please enter description"); return false; }
    return true;
  }

// Function to register frequency
const registerFrequency = () => {
  // Set loading to true
  setLoading(true);
  // Validate ProducerData
  if (validation(ProducerData)) {
    // Call ApiService to create managedata/SaveFrequency
    ApiService.create(
      "managedata/SaveFrequency",
      ProducerData
    )
      .then((res) => {
        // If response status is 200, set loading to false and show success message
        if (res.status === 200) {
          setLoading(false);
          toasterSuccess("Frequency details added successfully!");
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // Handle error and show error message
        ErroHandling(err, function (res) {
          toasterError(res)
        })
        setLoading(false);
      });
  } else {
    // Set loading to false
    setLoading(false);
  }
};

// This function updates the frequency of a producer
const updateFrequency = () => {

  // Set loading to true while the request is being processed
  setLoading(true);
  
  // Validate the ProducerData
  if (validation(ProducerData)) {
    // Make a patch request to the server with the ProducerData and Authorization token
    axios.patch(process.env.React_App_baseUrl + "managedata/UpdateFrequency", ProducerData, {
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    }
    )
      .then((res) => {
        // If the response status is 200, show success message and hide the side drawer
        if (res.status === 200) {
          toasterSuccess("Frequency details edited successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // Handle errors
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      }, setLoading(false))
  } else {
    // Set loading to false if validation fails
    setLoading(false)
  }
};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Frequency
        </Typography>
      </Box>

      {/* Add & Edit Frequency Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            required
            fullWidth
            id="standard-basic"
            name="status"
            size="small"
            label="Frequency"
            placeholder="Frequency"
            variant="standard"
            inputProps={{ maxLength: 100 }}
            value={props.crudState === "Add" ? null : props.formData.name}
            onChange={(e) => {
              props.setFormData({ ...props.formData, name: e.target.value });
              setProducerData({ ...ProducerData, name: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            required
            fullWidth
            multiline
            maxRows={4}
            id="standard-basic"
            name="description"
            size="small"
            label="Description"
            placeholder="Description"
            variant="standard"
            inputProps={{ maxLength: 250 }}
            value={props.crudState === "Add" ? null : props.formData.description}
            onChange={(e) => {
              props.setFormData({ ...props.formData, description: e.target.value });
              setProducerData({ ...ProducerData, description: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>

        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={'auto'}>
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Add" ? registerFrequency : updateFrequency}
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant="contained" color="accent3" onClick={() => props.setState({ ...props.state, right: false })}>Cancel</Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>

  );
};

export default AddEditFrequency;