// Default React, useState and Material Components
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

// Importing Custom Components
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
import CommonService from "../../../Services/CommonService";
import ApiService from "../../../Services/ApiService";
import * as Validations from "../../../common/validation";
// Import Material & Fluent UI Icons
import {
  AttachIcon,
  CancelIcon,
  LinkIcon,
  TextDocumentIcon,
  DownloadDocumentIcon,
} from "@fluentui/react-icons-mdl2";
import CircularProgress from "@mui/material/CircularProgress";
// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";
import Dropzone from "../../../UIComponent/DragDropField";

const AddReportInfo = (props) => {
  console.log("AddReport", props);
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();

  const [state, setState] = useState({ right: false });

  const [addReportInfoData, setAddReportInfoData] = useState({
    reportingYearId: null,
    reportingTypeId: null,
    link: null,
    reportName: null,
    reportingYear: null,
    reportingType: null,
    originalReportName: null,
    jobAssetId: null,
    ownerName: null,
  });
  const [allYear, setAllYear] = useState([]);
  const [allReportType, setAllReportType] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);
  const [defaultYearValue, setDefaultYearValue] = useState(null);
  const [defaultReportType, setDefaultReportType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
  const [fileupLoading, setFileupLoading] = useState(false);
  const [openAdobeViewer, setOpenAdobeViewer] = useState();
  const [isFileCleared, setIsFileCleared] = useState(false);
  const [editView, setEditView] = useState({});
  const [reportRowData, setReportRowData] = useState({});
  const [openConfirmfileUploadAlart, setopenConfirmfileUploadAlart] =
    useState(false);
  const [replaceFile, setreplaceFile] = useState(null);
  const [openConfirmfileDeleteAlart, setopenConfirmfileDeleteAlart] =
    useState(false);
  const [deleteFileIndex, setdeleteFileIndex] = useState(null);
  const [deleteFileloading, setdeleteFileloading] = useState(false);
  const [fileuploadLoading, setFileuploadLoading] = useState(false);
  const [addCustomeReportType, setAddCustomeReportType] = useState(false);
  const [addDuplicateFileAlert, setAddDuplicateFileAlert] = useState(false);
  //const [addDuplicateFile, setAddDuplicateFile] = useState(false);
  const [ownerName, setOwnerName] = useState();
  const [reportNameExist, setReportNameExist] = useState(false);
  const [reportNameExitFile, setReportNameExitFile] = useState("");
  const [triggerReportNameExist, setTriggerReportNameExist] = useState(false);
  const [newFileUploaded, setNewFileUploaded] = useState(false);
  const [updateFileNameChangeAlert, setUpdateFileNameChangeAlert] = useState(false);
  const [isRetained, setIsRetained] = useState(false);
  const [resetBooleanVal, setResetBooleanVal] = useState(false);
  // Declaration of React Hooks Start Here
  const [assetReportId, setAssetReportId] = React.useState(
    props?.formData?.assetReportId ? props?.formData?.assetReportId : 0
  );
  const [customeReportType, setCustomeReportType] = useState({
    name: "",
    description: "",
  });

  const EditReportData = (jobAssetId, ReportId) => {
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0 ||
      ReportId === undefined ||
      ReportId === null ||
      ReportId === 0
    ) {
      return;
    }
    setLoading(true);
    axios
      .get(
        process.env.React_App_baseUrl + "ManageOwnership/GetReportInfoforEdit",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
            jobAssetId: jobAssetId,
            ReportId: ReportId,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setAddReportInfoData(res.data);
          setReportRowData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

  const getOwnerNameInfo = () => {
    axios
      .post(
        process.env.React_App_baseUrl + "ManageOwnership/GetAssetOwnerName",
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
            jobAssetId: props.jobAssetId,
          },
        }
      )
      .then((res) => {
        setOwnerName(res.data.ownerName);
        console.log(res.data);
      });
  };


  useEffect(() => {
    getOwnerNameInfo();
  }, []);

  useEffect(() => {
    if (props.crudState === "Edit") {
      if (Object.values(props.formData).length > 0) {
        //setEditView({...props.formData, link:props.formData.links && props.formData.links.length ? props.formData.links[0].link : ''});
        EditReportData(props.formData.jobAssetId, assetReportId);
        //EditReportData(1346, 14)
      }
    }

    console.log("FormData", props.formData);
  }, [props.formData]);

  React.useEffect(() => {
    if (props.crudState === "Edit") {
      setDefaultYearValue({ ...null });
      allYear.forEach((rData, i) => {
        if (rData.id === reportRowData.reportingYearId) {
          setDefaultYearValue(allYear[i]);
          setAddReportInfoData({
            ...addReportInfoData,
            reportingYearId: rData && rData.id ? rData.id : null,
          });
        }
      });
    }
  }, [allYear, reportRowData]);

  React.useEffect(() => {
    if (props.crudState === "Edit" && !customeReportType.name) {
      setDefaultReportType({ ...null });
      allReportType.forEach((rData, i) => {
        if (rData.id === reportRowData?.reportingTypeId) {
          setDefaultReportType(rData);
          setAddReportInfoData({
            ...addReportInfoData,
            reportingTypeId: rData && rData.id ? rData.id : null,
          });
        }
      });
    }
  }, [allReportType, reportRowData]);

  React.useEffect(() => {
    getAddReportYear();
    getReportType();
  }, []);

  // React.useEffect(() => {
  //   if (!props.fileuploadLoading) {
  //     setFileupLoading(false)
  //     setreplaceFile(null)
  //   }
  // }, [props.fileuploadLoading]);
  // Function for getting Year dropdown
  const getAddReportYear = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "ManageOwnership/GetReportingYears",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setAllYear(res.data);
        } else {
          toasterError("Something went wrong");
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

  // Function for getting Report Type dropdown
  const getReportType = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "ManageOwnership/GetReportingTypes",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setAllReportType(res.data);
          if (customeReportType) {

            let newReportType = res.data.find((r) => {
              return r.reportingType == customeReportType.name;
            });
            if (newReportType) {
              setDefaultReportType(newReportType);
              setAddReportInfoData({
                ...addReportInfoData,
                reportingTypeId: newReportType.id,
                reportingType: newReportType.reportingType,
              });
            }
          }
        } else {
          toasterError("Something went wrong");
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };

  // Declaration of States and Component Variables End Here
  const onFilesAdded = (e, fileUpload, i) => {
    setreplaceFile({ fileUpload, i });
  };

  useEffect(() => {
    if (replaceFile) {
      if (addReportInfoData.jobAssetId && addReportInfoData.reportName) {
        setopenConfirmfileUploadAlart(true);
      } else {
        checkDuplicateRecord();
      }
    }
  }, [replaceFile]);

  const checkFileNameChange = () => {
    if (
      triggerReportNameExist &&
      addReportInfoData.reportingTypeId &&
      addReportInfoData.reportingYearId
    ) {
      checkSourceFileExit();
      
      if (props.crudState === "Edit" && reportNameExist == true) {
        setUpdateFileNameChangeAlert(true);
      }
    }
    setTriggerReportNameExist(false);
  }

  useEffect(() => {
    checkFileNameChange();
    // if (
    //   triggerReportNameExist &&
    //   addReportInfoData.reportingTypeId &&
    //   addReportInfoData.reportingYearId
    // ) {
    //   checkSourceFileExit();
      
    //   if (props.crudState === "Edit" && reportNameExist == true) {
    //     setUpdateFileNameChangeAlert(true);
    //   }
    // }
    // setTriggerReportNameExist(false);
  }, [triggerReportNameExist]);

  const checkfileupload = (e, fileUpload, i, allowDuplicate) => {
    if (
      addReportInfoData.reportingYearId &&
      addReportInfoData.reportingTypeId &&
      addReportInfoData.link
    ) {
      let yearData = allYear.find((y) => {
        return y.id == addReportInfoData.reportingYearId;
      });
      let reportingTypeData = allReportType.find((r) => {
        return r.id == addReportInfoData.reportingTypeId;
      });

      let reportData = { ...addReportInfoData };
      let dynamicReportName =
        yearData?.year +
        "-" +
        ownerName +
        "-" +
        reportingTypeData?.reportingType +
        "-" +
        "Report" +
        (i > 0 ? "-" + i : "");
      let reportName = reportNameExist
        ? reportNameExitFile
        : addReportInfoData.reportName || dynamicReportName;
      // let flUploadData= {...fileUpload[0], DisplayName:reportName + ".pdf",reportName:reportName,jobAssetId: reportData.jobAssetId}
      fileUpload[0]["DisplayName"] = reportName + ".pdf";
      fileUpload[0]["reportName"] = reportName;
      fileUpload[0].jobAssetId = reportData.jobAssetId;
      reportData.reportName = reportName;

      //if (fileUpload.type !== "application/pdf") {
      reportData.originalReportName = fileUpload[0].name;
      //}
      reportData.file = fileUpload[0];
      if (props.crudState === "Edit") {
        setNewFileUploaded(true);
      }

      setAddReportInfoData({ ...reportData, reportName: reportName });
      setReportRowData({ ...reportData, reportName: reportName });
      uploadFile(fileUpload[0], allowDuplicate);
    } else {
      toasterError("Please fill all the required fields in report info");
    }
    setopenConfirmfileUploadAlart(false);
  };

  const openDocumentForView = (file) => {
    let senData = {
      jobId: props.jobId,
      fileName: addReportInfoData.reportName + ".pdf",
      pageNumber: 1,
      crudState: "View",
    };
    CommonService.openFilePreview(senData);
    // history.push({ pathname: '/adobeView', state: senData })
    // OpenViewer(senData);
    // localStorage.setItem("OwnershipFilePreview", JSON.stringify(senData));
    // CommonService.AddTokenFromSessionStorageToLocalStorage();
    // window.open("#app/OwnershipFilePreview", "_blank", "noopener,noreferrer");
  };
  // Function for Validations  
  const validateForm = (data) => {
    if (!data.reportingYearId && !data.reportingTypeId && !data.link) {
      toasterError("Please fill all the required fields");
      return false;
    } else if (!data.reportingYearId) {
      toasterError("Please Select Reporting Year");
      return false;
    } else if (!data.reportingTypeId) {
      toasterError("Please Select Source File Type ");
      return false;
    } else if (!data.link) {
      toasterError("Please fill Link ");
      return false;
    } else if (!Validations.linkValidation(data.link)) {
      toasterError("Please enter a valid link ");
      return false;
    } else if (!Validations.linkValidationWithHttps(data.link)) {
      toasterError("Please enter a valid link ");
      return false;
    } else if (!addReportInfoData.reportName) {
      toasterError("Please upload source file");
    } else {
      return true;
    }
  };

  const checkDuplicateRecord = () => {
    // let isValidFile = !props.reportData.some((f) => {
    //   return(f.reportingYearId === addReportInfoData.reportingYearId && f.reportingTypeId === addReportInfoData.reportingTypeId);
    // });
    // if(!isValidFile){
    //   setAddDuplicateFileAlert(true);
    //   return false;
    // }else{
    //   checkfileupload("", replaceFile.fileUpload, replaceFile.i);
    // }
    if (reportNameExist == true) {
      setAddDuplicateFileAlert(true);
      return false;
    } else {
      checkfileupload("", replaceFile.fileUpload, replaceFile.i);
    }
  };

  const registerReport = () => {
    let isValidHttps = /^https:\/\/.*/.test(addReportInfoData.link);
    let isValidHttp = /^http:\/\/.*/.test(addReportInfoData.link);
    if (!isValidHttp && !isValidHttps) {
      const newLink = `https://${addReportInfoData.link}`;
      addReportInfoData.link = newLink; }

    let registerData = JSON.stringify({
      assetReportId: 0,
      reportingYearId: addReportInfoData.reportingYearId,
      reportingTypeId: addReportInfoData.reportingTypeId,
      reportName: addReportInfoData.reportName,
      link: addReportInfoData.link,
      originalReportName: addReportInfoData.originalReportName,
      jobAssetId: props.jobAssetId,
      newFileUploaded: newFileUploaded || false,
    });
    setLoading(true);
    if (validateForm(addReportInfoData)) {
      axios
        .post(
          process.env.React_App_baseUrl + "ManageOwnership/SaveReportInfo",
          registerData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            toasterSuccess("Source file Info added successfully");
            if (props.hideSideDrawer) {
              props.hideSideDrawer();
            }
            setLoading(false);
            
            if (props.setReload) {
              props.setReload(!props.reload);
            }
            if (props.refresh) {
              props.setRefresh(!props.refresh);
            }
            
            if (props.getSourceFile) {
              props.getSourceFile();
            }
            if (props.getFileName) {
              props.getFileName({
                id: res?.data,
                reportName: addReportInfoData?.reportName,
              });
            }
            if (props.getFileInfo) {
              props.getFileInfo();
            }
          } else {
            toasterError("Something went wrong");
            setLoading(false);
          }
          if (props.refreshGrid) {
            props.refreshGrid();
          }
        })
        .catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res);
          });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const updateReport = () => {
    setLoading(true);
    //if http(s) not present add https
    let isValidHttps = /^https:\/\/.*/.test(addReportInfoData.link);
    let isValidHttp = /^http:\/\/.*/.test(addReportInfoData.link);
    if (!isValidHttp && !isValidHttps) {
      const newLink = `https://${addReportInfoData.link}`;
      addReportInfoData.link = newLink; }

     
    let registerData = JSON.stringify({
      assetReportId: assetReportId,
      reportingYearId: addReportInfoData.reportingYearId,
      reportingTypeId: addReportInfoData.reportingTypeId,
      reportName: addReportInfoData.reportName,
      link: addReportInfoData.link,
      originalReportName: addReportInfoData.originalReportName,
      jobAssetId: props.jobAssetId,
      newFileUploaded: newFileUploaded || false,
      isRetained:isRetained
      
    });
    if (validateForm(addReportInfoData)) {
      //checkfileupload(null, [addReportInfoData.file], 0);

      axios
        .post(
          process.env.React_App_baseUrl + "ManageOwnership/SaveReportInfo",
          registerData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            toasterSuccess("Source file Info updated successfully");
            props.hideSideDrawer();
            setLoading(false);
          } else {
            toasterError("Something went wrong");
            setLoading(false);
          }
          if (props.refreshGrid) {
            props.refreshGrid();
          }
          setIsRetained(false);
        })
        .catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res);
          });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleFileClear = (isConfirm) => {
    setdeleteFileloading(isConfirm);
    // if (!deleteFileIndex) {
    //   setdeleteFileIndex()
    // }
    setIsFileCleared(!isFileCleared);
    let frData = addReportInfoData;
    // console.log('companyData: ', companyData);
    setFileupLoading();
    axios
      .delete(
        process.env.React_App_baseUrl +
          `ManageOwnership/DeleteBlobFile?jobId=${props.jobId}&fileName=${
            addReportInfoData.reportName + ".pdf"
          }&reportInfoId=` +
          assetReportId +
          `&isConfirm=true`,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {

        if (res.status === 200) {
          
          if (res.data === 200) {
            setopenConfirmfileDeleteAlart(false);
            setdeleteFileloading(false);
            frData.file = null;
            frData.reportName = "";
            frData.originalReportName = null;
            setAddReportInfoData({ ...frData });
            toasterSuccess("File deleted successfully");
            setFileupLoading(false);
            if(props.crudState === "Edit"){
              props.refreshGrid();
              props.setState({ ...props.state, right: false });
            }
          }else{
            toasterError(res.data);
            setFileupLoading(false);
            setopenConfirmfileDeleteAlart(false);
          }
          
        } else {
          setdeleteFileloading(false);
          setopenConfirmfileDeleteAlart(false);
          toasterError("Somthing want wrong in delete file");
          setFileupLoading(false);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
        setdeleteFileloading(false);
        setopenConfirmfileDeleteAlart(false);
        if (
          err.response.status === 400 &&
          err.response.data ===
            "Source file has used in variable so can't be deleted."
        ) {
          setopenConfirmfileDeleteAlart(true);
        } else {
          toasterError("Somthing went wrong");
        }
        setFileupLoading(false);
      });
  };

  //funciton for file download

  const handleFileDownload = () => {
    var afterDot = addReportInfoData.originalReportName.substr(
      addReportInfoData.originalReportName.indexOf(".")
    );
    let mediaType;
    switch (afterDot) {
      case ".xlsx":
        mediaType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case ".xls":
        mediaType = "application/vnd.ms-excel";
        break;
      case ".doc":
        mediaType = "application/msword";
        break;
      case ".docx":
        mediaType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case ".ppt":
        mediaType = "application/vnd.ms-powerpoint";
        break;
      case ".pptx":
        mediaType =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        break;
    }

    setLoading(true);
    axios
      .get(process.env.React_App_baseUrl + "ManageOwnership/DownloadFile", {
        headers: {
          jobId: props.jobId,
          fileName: addReportInfoData.reportName + afterDot,
          Authorization: localStorage.getItem("token")
            ? "Bearer " + localStorage.getItem("token")
            : null,
        },
        responseType: "blob",
      })
      .then((res) => {
        if (res.status == 200) {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let fileName = addReportInfoData.originalReportName;
          var json = JSON.stringify(fileName),
            blob = new Blob([res.data], { type: mediaType }),
            url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          // const link = document.createElement('a');
          // link.href = "data:application/octet-stream;base64," + res.data;
          // link.download = 'file.pdf';
          // link.click();
          setLoading(false);
        } else {
          //toasterError('Cannot delete Currency, as job(s) available with the currency that you are trying to delete.')
          setLoading(false);
        }
      }).catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
          setLoading(false);
        });
        
      });
  };

  const uploadFile = (data, allowDuplicate) => {
    setFileuploadLoading(true);
    let fileExtn;
    switch (data.type) {
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        fileExtn = ".xlsx";
        break;
      case "application/vnd.ms-powerpoint":
        fileExtn = ".ppt";
        break;
      case "application/msword":
        fileExtn = ".doc";
        break;
      case "text/plain":
        fileExtn = ".txt";
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        fileExtn = ".docx";
        break;
      case "image/png":
        fileExtn = ".png";
        break;
      case "image/jpeg":
        fileExtn = ".jpeg";
        break;
      case "application/pdf":
        fileExtn = ".pdf";
        break;
    }

    let fileName = (reportNameExitFile || data.reportName) + fileExtn;

    const myNewFile = new File([data], `${fileName}`, {
      type: data.type,
    });
    var formdata = new FormData();

    formdata.append("file", myNewFile);
    axios
      .post(
        process.env.React_App_baseUrl +
          `ManageOwnership/UploadFile?jobId=${
            props.jobId
          }&checkDuplicate=${true}`,
        formdata,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
            checkDuplicate: true,
            //allowDuplicateFile:allowDuplicate || false,
            jobId: props.jobId,
            // jobAssetId: props.jobAssetId,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          if (allowDuplicate || false) {
            setAddReportInfoData({
              ...addReportInfoData,
              reportName: response.data,
            });
          }
          toasterSuccess("File uploaded successfully");
        }

        // setReportInfoDataValue(reportInfoDataValueIntial)
        setFileuploadLoading(false);
        // setAddDuplicateFile(false);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
          setAddReportInfoData({
            ...addReportInfoData,
            reportName: null,
          });
        });
        setFileuploadLoading(false);
      });
  };

  const handleAddCustomeReportType = () => {
    if (
      customeReportType.name.length > 0 &&
      customeReportType.description.length > 0
    ) {
      setLoading(true);
      ApiService.create("ManageOwnership/AddReportingType", customeReportType)
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            getReportType();
            toasterSuccess("Source File Type added successfully ");
          } else {
            toasterError("Somthing went wrong");
          }
          setAddCustomeReportType(!addCustomeReportType);
        })
        .catch((err) => {
          setLoading(false);
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        });
    } else {
      toasterError("Please fill all the required fields");
    }
  };

  const onChangeReportingTypeHandle = (value, valuePlace) => {
    // let reportData = formData;
    // adding Report name
    if (value) {
      if (value.reportingType === "Others") {
        setAddCustomeReportType(!addCustomeReportType);
      }
    }
    // setFromData([...reportData]);
    // setReportInfo(reportData);
  };

  const addDuplicateFileClick = () => {
    checkfileupload("", replaceFile.fileUpload, replaceFile.i, true);
    setAddDuplicateFileAlert(!addDuplicateFileAlert);
  };

  const closeUpdateFileNameChangeAlert = () => {
    setUpdateFileNameChangeAlert(!updateFileNameChangeAlert);
    props.setState({ ...props.state, right: false });
  }

  const updateFileNameChange  = () => {
    
    let reportFileName = reportNameExitFile;

    var splitReportFileName = reportFileName.split("_");

    var prevNum = parseInt(splitReportFileName.pop() - 1);
      
    var reportNameOldYesClickFile = isNaN(prevNum) ? reportFileName : (prevNum > 0 ? (splitReportFileName.splice(0, splitReportFileName.length).join("_") + "_" + (prevNum)) : splitReportFileName.splice(0, splitReportFileName.length).join("_"));
  
    setAddReportInfoData({
      ...addReportInfoData,
      reportName: reportNameOldYesClickFile,
    });

    setUpdateFileNameChangeAlert(!updateFileNameChangeAlert);
    setIsRetained(true);
  }
  const handleClose = () => {
    setAddReportInfoData({
      ...addReportInfoData,
      reportingTypeId: null,
      reportingType: '',
    });
    setDefaultReportType({});
    setAddCustomeReportType(!addCustomeReportType);
  };

  const closeAddDuplicateFileAlert = () => {

    let reportFileName = reportNameExitFile;

    var splitReportFileName = reportFileName.split("_");

    var prevNum = parseInt(splitReportFileName.pop() - 1);
    
    
    var reportNameOldFile = isNaN(prevNum) ? reportFileName : (prevNum > 0 ? (splitReportFileName.splice(0, splitReportFileName.length).join("_") + "_" + (prevNum)) : splitReportFileName.splice(0, splitReportFileName.length).join("_"));
    
    let isValidHttps = /^https:\/\/.*/.test(addReportInfoData.link);
    let isValidHttp = /^http:\/\/.*/.test(addReportInfoData.link);
    if (!isValidHttp && !isValidHttps) {
      const newLink = `https://${addReportInfoData.link}`;
      addReportInfoData.link = newLink;
      }
      
    //checkfileupload("", replaceFile.fileUpload, replaceFile.i, true);

    let registerExistingData = JSON.stringify({
      assetReportId: 0,
      reportingYearId: addReportInfoData.reportingYearId,
      reportingTypeId: addReportInfoData.reportingTypeId,
      reportName: reportNameOldFile,
      link: addReportInfoData.link,
      originalReportName: addReportInfoData.originalReportName,
      jobAssetId: props.jobAssetId,
      newFileUploaded: newFileUploaded || false, });
      
    setLoading(true);
    
      axios
        .post(
          process.env.React_App_baseUrl + "ManageOwnership/SaveExistingReportInfo",
          registerExistingData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
           // toasterSuccess("Source file Info added successfully");
           props.setState({ ...props.state, right: false });
           setAddDuplicateFileAlert(!addDuplicateFileAlert);
            if (props.hideSideDrawer) {
              props.hideSideDrawer();
            }
            setLoading(false);
            
            if (props.setReload) {
              props.setReload(!props.reload);
            }
            if (props.refresh) {
              props.setRefresh(!props.refresh);
            }
            
            if (props.getSourceFile) {
              props.getSourceFile();
            }
            if (props.getFileName) {
              props.getFileName({
                id: res?.data,
                reportName: addReportInfoData?.reportName,
              });
            }
            if (props.getFileInfo) {
              props.getFileInfo();
            }
           

          } else {
            toasterError("Something went wrong");
            setLoading(false);
            props.setState({ ...props.state, right: false });
           setAddDuplicateFileAlert(!addDuplicateFileAlert);
          }
          if (props.refreshGrid) {
            props.refreshGrid();
          }
        })
        .catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res);
          });
          setLoading(false);
          props.setState({ ...props.state, right: false });
          setAddDuplicateFileAlert(!addDuplicateFileAlert);
        });


  };

  const checkSourceFileExit = () => {
    let reportingYearObj = allYear.find((y) => {
      return y.id == addReportInfoData.reportingYearId;
    });
    let reportingTypeObj = allReportType.find((r) => {
      return r.id == addReportInfoData.reportingTypeId;
    });
    if (reportingYearObj && reportingTypeObj) {
      let fileName =
        reportingYearObj.year +
        "-" +
        ownerName +
        "-" +
        reportingTypeObj.reportingType +
        "-" +
        "Report";
      axios
        .get(
          process.env.React_App_baseUrl +
            `ManageOwnership/CheckSourceInfoFileExists?fileName=${fileName}`,
          {
            headers: {
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : null,
            },
            params: {
              fileName: fileName,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            setResetBooleanVal(!resetBooleanVal);
            setReportNameExist(res.data.reportNameExist);
            if(res.data.reportNameExist && props.crudState === "Edit" ){
              setUpdateFileNameChangeAlert(true);
            }
            
            setReportNameExitFile(res.data.reportName);
            if (props.crudState === "Edit" && res.data.reportName) {
              setAddReportInfoData({
                ...addReportInfoData,
                reportName: res.data.reportName,
              });
            }
          } else {
            //toasterError('Cannot delete Currency, as job(s) available with the currency that you are trying to delete.')
            setLoading(false);
          }
        });
    }
  };

  return (
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          Source File Info - {props.crudState}
        </Typography>
      </Box>

      {/* Add & Edit Commodity Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            value={defaultYearValue}
            id="combo-box-demo"
            options={allYear}
            getOptionLabel={(option) =>
              option.year != undefined ? option.year : ""
            }
            onChange={(e, value) => {
              if (value != null) {
                allYear.forEach((rData, i) => {
                  if (rData.id === value.id) {
                    setDefaultYearValue(allYear[i]);
                  }
                });
                setAddReportInfoData({
                  ...addReportInfoData,
                  reportingYearId: value && value.id ? value.id : null,
                  reportingYear: value && value.year ? value.year : null,
                });
                setTriggerReportNameExist(true);
              } else {
                setAddReportInfoData({
                  ...addReportInfoData,
                  reportingYearId: null,
                  reportingYear: null,
                });
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Year" required variant="standard" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            value={defaultReportType}
            id="combo-box-demo"
            options={allReportType}
            getOptionLabel={(option) =>
              option.reportingType != undefined ? option.reportingType : ""
            }
            onChange={(e, value) => {
              onChangeReportingTypeHandle(value, "reportingPeriod", "name");
              if (value != null) {
                allReportType.forEach((rData, i) => {
                  if (rData.id === value.id) {
                    setDefaultReportType(allReportType[i]);
                  }
                });
                setAddReportInfoData({
                  ...addReportInfoData,
                  reportingTypeId: value && value.id ? value.id : null,
                  reportingType:
                    value && value.reportingType ? value.reportingType : null,
                });
                setTriggerReportNameExist(true);
              } else {
                setAddReportInfoData({
                  ...addReportInfoData,
                  reportingTypeId: null,
                  reportingType: null,
                });
              }
              
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Source File Type"
                required
                variant="standard"
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="self-end"
            >
              <Grid item xs>
                <TextField
                  id="standard-basic"
                  label="Link"
                  fullWidth
                  variant="standard"
                  // defaultValue={props.crudState === "Edit" ? reportRowData.link : null}
                  value={
                    props.crudState === "Add" ? null : addReportInfoData.link
                  }
                  onChange={(e) => {
                    if (e.target.value) {
                      if (props.crudState === "Edit") {
                        setReportRowData({
                          ...reportRowData,
                          link: e.target.value,
                        });
                      }

                      setAddReportInfoData({
                        ...addReportInfoData,
                        link: e.target.value,
                      });
                    }
                  }}
                  placeholder="Link"
                  className={classes.placeholderStyle}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs="auto">
                <Tooltip title="Open Link" placement="top" arrow>
                  <IconButton
                    color="secondary"
                    aria-label="add to shopping cart"
                    disabled={
                      !Validations.linkValidation(addReportInfoData.link)
                    }>
                    <LinkIcon
                      onClick={() => {
                        window.open(addReportInfoData.link, "_blank");
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-basic"
            label="Source File Name"
            fullWidth
            variant="standard"
            defaultValue={
              props.crudState === "Edit" ? reportRowData.reportName : null
            }
            value={addReportInfoData.reportName}
            // onChange={(e) => {
            //   if (props.crudState === "Edit") {
            //     setReportRowData({ ...reportRowData, reportName: e.target.value });
            //   }

            //   setAddReportInfoData({ ...addReportInfoData, reportName: e.target.value });
            // }}
            placeholder="Source File Name"
            className={classes.placeholderStyle}
            disabled={true}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent="left" alignItems="center">
            <Grid item style={{ fontSize: 16 }} xs={12}>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                style={{ color: "#00000099" }}
              >
                Uploaded File(s):
              </Typography>
              {addReportInfoData.reportName ? (
                <Grid item style={{ fontSize: 16 }} xs={12}>
                  <TextDocumentIcon
                    style={{ fontSize: 22, marginRight: "5px" }}
                  />
                  {addReportInfoData.reportName + ".pdf"}

                  <Tooltip title="File Preview" placement="top" arrow>
                    <IconButton color="secondary">
                      <AttachIcon
                        onClick={(event) => openDocumentForView(event)}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Remove Attachment"
                    placement="top"
                    onClick={(e) => setopenConfirmfileDeleteAlart(true)}
                    arrow
                  >
                    {fileuploadLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <CancelIcon color="error" style={{ fontSize: 18 }} />
                    )}
                  </Tooltip>
                </Grid>
              ) : (
                "-"
              )}
            </Grid>
            {addReportInfoData.originalReportName &&
            !addReportInfoData.originalReportName.endsWith(".pdf") ? (
              <Grid item style={{ fontSize: 16 }} xs={12}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ color: "#00000099" }}
                >
                  Orginal File(s):
                </Typography>
                <Grid item style={{ fontSize: 16 }} xs={12}>
                  <TextDocumentIcon
                    // style={{ fontSize: 22, marginRight: "5px", color: '#DB9400' }}
                    style={{ fontSize: 22, marginRight: "5px" }}
                  />
                  {addReportInfoData.originalReportName}

                  <Tooltip title="File Download" placement="top" arrow>
                    <IconButton color="secondary">
                      <DownloadDocumentIcon
                        onClick={() => {
                          handleFileDownload();
                        }}
                      />
                      {/* <DownloadDocumentIcon onClick={() => { handleFileDownload() }} /> */}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Remove Attachment" placement="top" arrow>
                    <IconButton
                      color="error"
                      onClick={(e) => setopenConfirmfileDeleteAlart(true)}
                    >
                      {fileuploadLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <CancelIcon style={{ fontSize: 18 }} />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Dropzone
            margin="normal"
            content={"CSV File"}
            helperText="Max. of 100 rows allowed"
            showSelectedFile={false}
            isFileCleared={isFileCleared}
            onFilesAdded={(e, fileUpload) => {
              onFilesAdded(e, fileUpload);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid item xs={12} sx={{ mt: -1 }}>
              <Grid container spacing={2} justifyContent="left">
                <Grid item xs={"auto"}>
                  <LoadingButton
                    buttonName={props.crudState === "Add" ? "Save" : "Update"}
                    clickHandler={
                      props.crudState === "Add" ? registerReport : updateReport
                    }
                    isLoading={loading}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <Button
                    variant="contained"
                    color="accent3"
                    onClick={() =>
                      props.setState({ ...props.state, right: false })
                    }
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <AlertDialog
        open={openConfirmfileDeleteAlart}
        title={"Confirmation"}
        maxWidth={"sm"}
        description={
          "Source file is already used. Are you sure you want to delete the file ?"
        }
        action={
          <>
            <div style={{ marginBottom: "10px", marginRight: "10px" }}>
              <Button onClick={() => setopenConfirmfileDeleteAlart(false)}>
                No
              </Button>
              {deleteFileloading ? (
                <CircularProgress />
              ) : (
                <Button
                  color="secondary"
                  onClick={(e) => {
                    handleFileClear(e);
                  }}
                >
                  Yes
                </Button>
              )}
            </div>
          </>
        }
      />

      <AlertDialog
        open={openConfirmfileUploadAlart}
        title={"Confirmation"}
        maxWidth={"sm"}
        description={"Changing this source file will replace everywhere if source file is associated with other jobs.  Are you sure you want to replace the source file ?"}
        action={
          <>
            <div style={{ marginBottom: "10px", marginRight: "10px" }}>
              <Button onClick={() => setopenConfirmfileUploadAlart(false)}>
                No
              </Button>
              {deleteBtnLoadinng ? (
                <CircularProgress />
              ) : (
                <Button
                  color="secondary"
                  // onClick={() => {
                  //   addDuplicateFileClick
                  //   console.log("replaceFile: ", replaceFile.i);
                  //   checkfileupload("", replaceFile.fileUpload, replaceFile.i);
                  // }}
                  onClick={() => {
                    checkDuplicateRecord();
                  }}
                >
                  Yes
                </Button>
              )}
            </div>
          </>
        }
      />

      {addCustomeReportType ? (
        <Dialog open={true} fullWidth onClose={handleClose}>
          <DialogTitle>Add Source File Type</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              name="name"
              label="Name"
              type="email"
              fullWidth
              required
              variant="standard"
              onChange={(event) => {
                let data = customeReportType;
                data[event.target.name] = event.target.value;
                setCustomeReportType(data);
              }}
            />
            <TextField
              margin="dense"
              id="name"
              name="description"
              label="Description"
              type="email"
              fullWidth
              required
              variant="standard"
              onChange={(event) => {
                let data = customeReportType;
                data[event.target.name] = event.target.value;
                setCustomeReportType(data);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Button onClick={handleAddCustomeReportType}>Add</Button>
            )}
          </DialogActions>
        </Dialog>
      ) : null}

      {addDuplicateFileAlert ? (
        <Dialog open={true} fullWidth onClose={closeAddDuplicateFileAlert}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <h1 className={classes.alertMessage}>
              {" "}
              Source file with same name already exists. Do you want to add a source file with same name (with an increment File name_1) ?
            </h1>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAddDuplicateFileAlert}>No</Button>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Button onClick={addDuplicateFileClick}>Yes</Button>
            )}
          </DialogActions>
        </Dialog>
      ) : null}


{updateFileNameChangeAlert ? (
        <Dialog open={true} fullWidth onClose={closeUpdateFileNameChangeAlert}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <h1 className={classes.alertMessage}>
              {" "}
              Source file already exists. Do you want to retain the existing file?
            </h1>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeUpdateFileNameChangeAlert}>No</Button>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Button onClick={updateFileNameChange}>Yes</Button>
            )}
          </DialogActions>
        </Dialog>
      ) : null}

    </Box>
  );
};

export default AddReportInfo;
