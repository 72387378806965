// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import ApiService from "../../../Services/ApiService";
import * as Validations from "../../../common/validation";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing Custom Components
import AutocompleteText from "../../../UIComponent/Autocomplete";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// Importing useStyles
import useStyles from "./UserManagement.styles";

const AddUser = (props) => {
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [roles, setRoles] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    id: 0,
    password: "",
    confirmPassword: "",
    active: true,
    roleId: null,
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    isFirstTimeLogin: true,
    createdOn: "2022-09-05T09:18:29.865Z",
  });
  // Declaration of States and Component Variables End Here

// This useEffect hook is used to set the user data and default value when the crudState is either Edit or View
  React.useEffect(() => {
    // Check if the crudState is either Edit or View
    if (props.crudState === "Edit" || props.crudState === "View") {
      // Set the user data
      let data = {
        id: props.formData.id,
        password: props.formData.password,
        confirmPassword: props.formData.confirmPassword,
        active: props.formData.active,
        roleId: props.formData.roleId,
        firstName: props.formData.firstName,
        lastName: props.formData.lastName,
        email: props.formData.email,
        role: props.formData.roleName,
        isFirstTimeLogin: props.formData.isFirstTimeLogin,
        createdOn: props.formData.createdOn,
      };
      setUserData(data);
      // Loop through the roles array to find the default value
      roles.forEach((rData, i) => {
        if (rData.id === props.formData.roleId) {
          setDefaultValue(roles[i]);
        }
      });
    }
  }, [roles, props]);

  React.useEffect(() => {
    ApiService.getAll("account/GetAllRoles")
      .then((res) => {
        setRoles(res.data);
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  }, []);
  // Declaration of React Hooks End Here

// Function to send form error message
const sendFormErrorMessage = () => {
  // Check if first name is valid
  if (!Validations.onlyChar(userData.firstName)) {
    toasterError("Please enter a valid first name");
  } 
  // Check if last name is valid
  else if (!Validations.onlyChar(userData.lastName)) {
    toasterError("Please enter a valid last name");
  } 
  // Check if email is valid
  else if (!Validations.emailValid(userData.email)) {
    toasterError("Please enter a valid email address");
  } 
  // Check if role is valid
  else if (userData.role.length < 1) {
    toasterError("please enter a valid role");
  } 
  // If all the above conditions are false, then display this error message
  else {
    toasterError("Please enter the required fields");
  }
};

// This function is used to register a user
const registerUser = () => {
  // Set loading to true while the user is being registered
  setLoading(true);
  // Check if all the validations are met
  if (
    Validations.onlyChar(userData.firstName) &&
    Validations.onlyChar(userData.lastName) &&
    Validations.emailValid(userData.email) &&
    userData.role.length > 1
  ) {
    // Make an API call to register the user
    ApiService.create("account/RegisterUser", userData)
      .then((res) => {
        // If the response status is 200, show success message and hide the side drawer
        if (res.status === 200) {
          toasterSuccess("User details added successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // Handle errors
        ErroHandling(err, function (res) {
          toasterError(res);
        });
        setLoading(false);
      });
  } else {
    // If validations are not met, set loading to false and send form error message
    setLoading(false);
    sendFormErrorMessage();
  }
};

// This function updates the user details
const updateUser = () => {
  // Set loading to true while updating user data
  setLoading(true);
  // Create a validData object with userData and formData id
  let validData = userData;
  validData.id = props.formData.id;
  // Check if all the validations are true
  if (
    Validations.onlyChar(userData.firstName) &&
    Validations.onlyChar(userData.lastName) &&
    Validations.emailValid(userData.email) &&
    userData.role.length > 1
  ) {
    // Call ApiService to update user data
    ApiService.create("account/UpdateUser", validData)
      .then((res) => {
        // If response is 200, show success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("User details edited successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // Handle error and show error message
        ErroHandling(err, function (res) {
          toasterError(res);
        });
        setLoading(false);
      });
  } else {
    // If validations fail, set loading to false and send form error message
    setLoading(false);
    sendFormErrorMessage();
  }
};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} User
        </Typography>
      </Box>

      {/* Add & Edit Currency Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true }}
            required
            fullWidth
            id="standard-basic"
            name="firstName"
            size="small"
            label="First Name"
            placeholder="First Name"
            variant="standard"
            inputProps={{ maxLength: 100 }}
            value={props.crudState === "Add" ? null : props.formData.firstName}
            onChange={(e) => {
              props.setFormData({
                ...props.formData,
                firstName: e.target.value,
              });
              setUserData({ ...userData, firstName: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true }}
            required
            fullWidth
            id="standard-basic"
            name="lastName"
            size="small"
            label="Last Name"
            placeholder="Last Name"
            variant="standard"
            inputProps={{ maxLength: 100 }}
            onChange={(e) => {
              props.setFormData({
                ...props.formData,
                lastName: e.target.value,
              });
              setUserData({ ...userData, lastName: e.target.value });
            }}
            value={props.crudState === "Add" ? null : props.formData.lastName}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true }}
            required
            fullWidth
            id="standard-basic"
            name="email"
            size="small"
            label="Email Address"
            placeholder="Email Address"
            variant="standard"
            inputProps={{ maxLength: 100 }}
            onChange={(e) => {
              props.setFormData({ ...props.formData, email: e.target.value });
              setUserData({ ...userData, email: e.target.value });
            }}
            value={props.crudState === "Add" ? null : props.formData.email}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>

        <Grid item xs={12}>
          <AutocompleteText
            option={roles}
            dropShow={"roleName"}
            feildName={"role"}
            labelName={"Role"}
            isRequired={true}
            defaultValue={defaultValue}
            readOnly={props.crudState === "View" ? true : false}
            onChangeRole={(value) => {
              setUserData({
                ...userData,
                role: value && value.roleName ? value.roleName : "",
                roleId: value && value.id ? value.id : null,
              });
              setDefaultValue({
                ...defaultValue,
                roleName: value && value.roleName ? value.roleName : "",
                roleId: value && value.id ? value.id : null,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid
              container
              alignItems="center"
              justifyContent="left"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                {/* {loading ? (<Button disabled sx={{ mr: 2 }}>
                  <CircularProgress size={24} />
                </Button>) : (
                  <Button type="submit" variant="contained" className="rounded-border" color="accent5"
                    onClick={props.crudState === "Add" ? updateUser: registerUser}>
                    {props.crudState === "Edit" ? "Update" : "Save"}
                  </Button>
                )} */}
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={
                    props.crudState === "Add" ? registerUser : updateUser
                  }
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <Button
                  variant="contained"
                  className="rounded-border"
                  color="accent3"
                  onClick={() =>
                    props.setState({ ...props.state, right: false })
                  }
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddUser;
