// Default React, useState and Material Components
import React, { useState, useEffect } from "react";
import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddAndEditTat = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [editView, setEditView] = useState({});
  const [loading, setLoading] = useState(false);
  const [allRegion, setAllRegion] = useState([]);
  const [allPriority, setallPriority] = useState([]);
  const [allsubRegion, setAllsubRegion] = useState([]);
  const [selectedPriority, setselectedPriority] = useState(null);
  const [tatForm, settatForm] = useState({
    priorityId: '', priority: '', description: '', minVariableCount: '',
    maxVariableCount: '', tatDays: ''
  })
  const [countryData, setCountryData] = useState({ countryName: null, regionId: null, subRegionId: null, });
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  useEffect(() => {
    if (allPriority.length < 1) { getPriority() }
  }, [allRegion])

  useEffect(() => {
    if (allPriority.length > 0 && props.state.formData) {
      settatForm({
        priorityId: props.state.formData.priorityId,
        priority: props.state.formData.priority,
        description: props.state.formData.description,
        minVariableCount: props.state.formData.minVariableCount,
        maxVariableCount: props.state.formData.maxVariableCount,
        tatDays: props.state.formData.tatDays
      })
      setselectedPriority(allPriority.filter((f) => f.id === props.state.formData.priorityId)[0])
    }
  }, [allPriority])
  // Declaration of React Hooks End Here

// Function to get the priority dropdown filter from the API
const getPriority = () => {
  // Making a GET request to the API using axios
  axios.get(process.env.React_App_baseUrl + 'DropDown/GetPriorityDropDownFilter',{
    headers: {
      // Setting the authorization header with the token stored in sessionStorage
      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
  }
  }).then((res) => {
    // If the response status is 200, set the allPriority state with the response data
    if (res.status === 200) {
      setallPriority(res.data)
    } else { 
      // If the response status is not 200, show an error message
      toasterError('Something went wrong') 
    }
  }).catch((err) => { 
    // Error handling for any errors that occur during the request
    ErroHandling(err, function (res) {
    toasterError(res)})
   })
}

  // Function for Validation
  const validateForm = (data) => {
    if (!data.priorityId && !data.minVariableCount && !data.maxVariableCount && !data.tatDays) {
      toasterError('Please fill all the fields'); return false
    } else if (!data.priorityId) {
      toasterError('Please select priority'); return false
    } else if (!data.minVariableCount) {
      toasterError('Please fill the min variableCount '); return false
    } else if (!data.maxVariableCount) {
      toasterError('Please fill the max variableCount '); return false
    } else if (!data.tatDays) {
      toasterError('Please fill the tat days '); return false
    }
    else { return true }
  }

// This function is used to update the TAT configuration
const onUpdateApi = () => {
  // Declare a variable to store the formTat
  let formTat = tatForm
  // Set the id of the formData to the formTat
  formTat['id'] = props.state.formData.id
  // Set loading to true
  setLoading(true)
  // Check if the maxVariableCount is valid and validate the form
  if (checkMaxValue(tatForm.maxVariableCount) && validateForm(tatForm)) {
    // Make an API call to update the TAT configuration
    axios.patch(process.env.React_App_baseUrl + 'managedata/UpdateTATConfiguration', formTat,{
      headers: {
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
    }
    }).then((res) => {
      // If the response status is 200, show success message and hide the side drawer
      if (res.status === 200) {
        toasterSuccess('Tat updated successfully')
        props.hideSideDrawer()
        setLoading(false)
      } else {
        // Else show error message
        toasterError('Something went wrong')
        setLoading(false)
      }
    }).catch((err) => {
      // Set loading to false
      setLoading(false)
      // Call the ErrorHandling function
      ErroHandling(err, function (res) {
      toasterError(res)})
    })
  } else {
    // Set loading to false
    setLoading(false)
  }
}

// This function handles the save action for Tat configuration
const handleSave = () => {
  // Set loading to true
  setLoading(true)
  // Check if max value is valid and form is validated
  if (checkMaxValue(tatForm.maxVariableCount) && validateForm(tatForm)) {
    // Make a post request to save Tat configuration
    axios.post(process.env.React_App_baseUrl + 'managedata/SaveTATConfiguration', tatForm,{headers: {
      'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
  }}
    ).then((res) => {
      // If response status is 200, show success message and hide side drawer
      if (res.status === 200) {
        toasterSuccess('Tat configuration created successfully')
        props.hideSideDrawer()
        setLoading(false)
      } else {
        // Else show error message
        toasterError('Something went wrong')
        setLoading(false)
      }
    }).catch((err) => {
      // Set loading to false and call ErrorHandling function
      setLoading(false)
      ErroHandling(err, function (res) {
      toasterError(res)})
    })
  } else {
    // Set loading to false
    setLoading(false)
  }
};

// This function handles the change in priority selection
const handlePriorityChange = (event) => {
  // Update the tatForm state with the new priorityId and priorityName
  settatForm({
    ...tatForm, priorityId: event ? event.id : '',
    priority: event ? event.priorityName : ''
  })
  // Set the selected priority to the new event
  setselectedPriority(event)
}

// This function handles the change of input values in the form
const handleInputChange = (event) => {
  // Update the tatForm state with the new value of the input field 
  settatForm({ ...tatForm, [event.target.name]: event.target.value, })
}
// This function checks if the value of event is greater than the minVariableCount
const checkMaxValue = (event) => {
  let status
  // If the value of event is less than minVariableCount, set status to false and display an error message
  if (parseInt(event) < parseInt(tatForm.minVariableCount)) {
    status = false
    toasterError('Max VariableCount should be greater than Min VariableCount1 ')
  } else {
    // Otherwise, set status to true
    status = true
  }
  // Return the status
  return status
}

  return (

    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.state.mode} TAT
        </Typography>
      </Box>

      {/* Add & Edit Top Producers Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            key="Priority"
            id="combo-box-demo"
            getOptionLabel={(option) => option.priorityName}
            options={allPriority}
            value={selectedPriority}
            onChange={(e, value) => {
              handlePriorityChange(value)
            }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                key="Priority"
                label="Priority"
                variant="standard"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            defaultValue={props.crudState === "Edit" ? editView.countryName : null}
            fullWidth
            name='description'
            label="Description"
            InputProps={{
              shrink: true,
            }}
            value={tatForm.description}
            onChange={(e) => {
              handleInputChange(e)
            }}
            placeholder="Description"
            InputLabelProps={
              props.crudState === "Edit" ? { shrink: true } : null
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            name='minVariableCount'
            type='number'
            variant="standard"
            fullWidth
            label="Min Variable Count"
            InputProps={{
              shrink: true,
            }}
            value={tatForm.minVariableCount}
            onChange={(e) => {
              handleInputChange(e)
            }}
            placeholder="Min Variable Count"
            InputLabelProps={
              props.crudState === "Edit" ? { shrink: true } : null
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            name='maxVariableCount'
            type='number'
            variant="standard"
            // defaultValue={props.crudState === "Edit" ? editView.countryName : null}
            fullWidth
            label="Max Variable Count"
            InputProps={{
              shrink: true,
            }}
            value={tatForm.maxVariableCount}
            onChange={(e) => {
              handleInputChange(e)
            }}
            placeholder="Max Variable Count"
            InputLabelProps={
              props.crudState === "Edit" ? { shrink: true } : null
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            name='tatDays'
            type='number'
            variant="standard"
            defaultValue={props.crudState === "Edit" ? editView.countryName : null}
            fullWidth
            label="TAT (Days)"
            InputProps={{
              shrink: true,
            }}
            value={tatForm.tatDays}
            onChange={(e) => {
              handleInputChange(e)
            }}
            placeholder="TAT (Days)"
            InputLabelProps={
              props.crudState === "Edit" ? { shrink: true } : null
            }
          />
        </Grid>
        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={12} sm={'auto'}>
                {/* <Button variant="contained" className="rounded-border" color="accent5"
                  onClick={props.state.mode === "Edit" ? onUpdateApi : handleSave}>
                  {loading ? <CircularProgress size={20} color="inherit" /> : props.state.mode === "Edit" ? "Update" : "Save"}
                </Button> */}
                
                <LoadingButton
                buttonName={props.state.mode === "Edit" ? "Update" : "Save"}
                clickHandler={props.state.mode === "Edit" ? onUpdateApi  : handleSave }
                isLoading ={loading} />
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <Button variant="contained" className="rounded-border" color="accent3"
                  onClick={() => props.setState({ ...props.state, right: false })} >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddAndEditTat;