// Default React, Router and Services Components
import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  InputAdornment,
    TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
//import history from "../../Routes/History";
import { useHistory } from "react-router-dom";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError } from "../../../UIComponent/Toaster";
import GetMenuService from "../../../Services/GetMenuService";
// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import { ScrollingDialog } from "../../../UIComponent";
import { LoaderWrapper } from "../../../UIComponent/Progress";
// Importing useStyles
import useStyles from "./QualityManagement.styles";

// Import Fluent UI Icons
import {
  ChevronRightSmallIcon,
  ClearIcon,
  SearchIcon,
} from "@fluentui/react-icons-mdl2";
import { Button } from "@mui/material";
import SideDrawer from "../../../UIComponent/Drawer/index";
import FilterJobRecords from "../JobManagement/FilterJobRecords";
import QualityFilter from "./FilterForm";
import { FilterIcon } from "@fluentui/react-icons-mdl2";

const DataCollectionQualityList = (props) => {
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [allRows, setAllRows] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [selected, SetSelected] = useState([]);
  const [idforEdit, setIdForEdit] = useState(null);
  const [editJobNumber, setEditJobNumber] = useState(null);
  const [isAllSelected, setIsSelected] = useState(false);
  const [state, setState] = React.useState({ right: false });
  const [menuPermission, setMenuPermission] = useState({
    allowView: true,
    allowEdit: true,
    allowDelete: true,
  });
  const [crudState, setCrudState] = React.useState(false);
  const [formData, setFormData] = useState([]);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [reload, setReload] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [jobSelection, setJobSelection] = useState([]);
  const [jobIds, setJobIds] = useState(
    props.location.state ? props.location.state : 68
  );
  const [JobIdCheck, setJobIdCheck] = useState(null);
  const [jId, setJId] = useState(null);
  const [access, setAccess] = React.useState({ allowView: false });
  const [dataForFilter, setDataForFilter] = React.useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [filterFinalData, setFilterFinalData] = useState();
  const [filterValues, setFilterValues] = React.useState(null);
  const [jobCompanies, setJobCompanies] = React.useState([]);
  const [jobLocations, setJobLocations] = React.useState([]);
  const [resetFilter, setResetFilter] = React.useState(false);
  const [tatDate, setTatDate] = React.useState(null)
  const history = useHistory();
  // Declaration of States and Component Variables Start Here

  //console.log(access, "accesssss");
  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    GetQualityList(searchValue);
    setJId(props.location.state);
    axios
      .get(
        process.env.React_App_baseUrl +
        "QualityManagement/GetJobNoNameDropdownforQuality",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then(function (response) {
        setJobSelection(response.data);
      })
      .catch(function (error) {
        ErroHandling(error, function (res) {
          toasterError(res);
        });
      });
    setResetFilter(false);
  }, [reload, resetFilter]);

  React.useEffect(() => {
    if (filterValues) {
      GetQualityList(null);
    }
  }, [filterValues]);

  // Function for Sorting and Pagination
  React.useEffect(() => {
    GetMenuService(11, function (err, res) {
      if (res) {
        // console.log(res.data, "accesssss");
        setAccess(res.data);
      } else {
        toasterError(err);
      }
    });
  }, []);
  React.useEffect(() => {
    GetQualityList(searchValue);
  }, [sorting, page, rowsPerPage]);

  const resetTreeFilter = () => {
    companyFilter.forEach((item, index) => {
      item.checked = false;
      if (item.children) {
        item.children.forEach((item, index) => {
          item.checked = false;
        });
      }
    });
    locationFilter.forEach((item, index) => {
      item.checked = false;
      if (item.children) {
        item.children.forEach((item, index) => {
          item.checked = false;
        });
        if (item.children) {
          item.children.forEach((item, index) => {
            item.checked = false;
          });
        }
      }
    });
    setTatDate(null)
  };

  React.useEffect(() => {
    getFilterData();
  }, [reload]);
  const getFilterData = async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}DropDown/GetSourceFilterDropdownListAsyncWithId`,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      );
      if (response.data.length === 0) {
        setDataForFilter([]);
      } else {
        setDataForFilter(response.data);
        response.data.companies.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
          }
        });
        response.data.location.regions.forEach((item, index) => {
          item.checked = false;
          if (item.children) {
            item.children.forEach((item, index) => {
              item.checked = false;
            });
            if (item.children) {
              item.children.forEach((item, index) => {
                item.checked = false;
              });
            }
          }
        });
        setCompanyFilter(response.data.companies);
        setLocationFilter(response.data.location.regions);
      }
    } catch (error) {
      ErroHandling(error, function (res) {
        toasterError(res);
      });
    }
  };


  // Assigning Columns and UI Props
  const columns = [
    { id: "jobAssetId", label: "Id", type: "Highlight" },
    { id: "jobNo", label: "Job ID", width: 90, minWidth: 90, sorting: true },
    {
      id: "jobName",
      label: "Job Name",
      minWidth: 300,
      width: 320,
      sorting: true,
    },
    {
      id: "company",
      label: "Company",
      minWidth: 250,
      width: 280,
      sorting: true,
    },
    { id: "asset", label: "Asset", minWidth: 200, width: 250, sorting: true },
    {
      id: "noOfVariable",
      label: "Variable Count",
      minWidth: 130,
      width: 130,
      sorting: true,
      align: "center"
    },


    { id: "startDate", label: "Job Start Date", minWidth: 120, width: 120, sorting: true, align: "center" },
    { id: "tat", label: "TAT", minWidth: 120, width: 120, sorting: true, align: "center" },
    { id: "remainingDays", label: "Remaining Days", minWidth: 120, width: 120, sorting: true, align: "center" },
    { id: "status", label: "Status", minWidth: 180, width: 200, sorting: true },
    {
      id: "priority",
      type: "Chip",
      label: "Job Priority",
      minWidth: 100,
      width: 120,
      sorting: true,
      align: "center"
    },
    { id: "assignedTo", label: "Merit Analyst Name", minWidth: 180, width: 200, sorting: true },
    { id: "assignedBy", label: "AssignedBy", minWidth: 180, width: 200, sorting: true },
    {
      id: "completedTime",
      label: "Asset Completion Date",
      minWidth: 110,
      width: 110,
      sorting: true,
      align: "center"
    },
    {
      id: "qcCompletionDate",
      label: "Merit QC Completion Date",
      minWidth: 120,
      width: 120,
      sorting: true,
      align: "center",
    },
    {
      id: "cruCompletionDate",
      label: "CRU QC completion Date",
      minWidth: 120,
      width: 120,
      sorting: true,
      align: "center",
    },

    {
      id: "commodity",
      label: "Commodity",
      minWidth: 300,
      width: 300,
      sorting: true,
      truncate: 60,
      tooltipWithTruncate: true
    },
    {
      id: "country",
      label: "Country",
      minWidth: 150,
      width: 180,
      sorting: true,
    },
    { id: "region", label: "Region", minWidth: 150, width: 180, sorting: true },

  ];

  // Function for Handle KeyUp
  const handleKeyUp = (event) => {
    if (
      (event.keyCode === 8 || event.which === 8) &&
      event.target.nodeName !== "BUTTON"
    ) {
      if (searchValue.length === 0) {
      }
    }
    if (
      (event.keyCode === 13 || event.which === 13) &&
      event.target.nodeName !== "BUTTON"
    ) {
      searchReport(event.target.value);
    }
  };

  // Function for Sorting
  const changeSortColumnNameFormat = (fieldName) => {
    if (fieldName == null || fieldName == "") {
      return "";
    }
    if (fieldName.toLocaleLowerCase() == "jobNo".toLocaleLowerCase()) {
      fieldName = "JobNo";
    }
    if (fieldName.toLocaleLowerCase() == "jobname".toLocaleLowerCase()) {
      fieldName = "JobName";
    }
    if (fieldName.toLocaleLowerCase() == "company".toLocaleLowerCase()) {
      fieldName = "Company";
    }
    if (fieldName.toLocaleLowerCase() == "asset".toLocaleLowerCase()) {
      fieldName = "Asset";
    }
    if (fieldName.toLocaleLowerCase() == "commodity".toLocaleLowerCase()) {
      fieldName = "Commodity";
    }
    if (fieldName.toLocaleLowerCase() == "country".toLocaleLowerCase()) {
      fieldName = "Country";
    }
    if (fieldName.toLocaleLowerCase() == "region".toLocaleLowerCase()) {
      fieldName = "Region";
    }
    if (fieldName.toLocaleLowerCase() == "completedTime".toLocaleLowerCase()) {
      fieldName = "CompletedTime";
    }
    if (fieldName.toLocaleLowerCase() == "tat".toLocaleLowerCase()) {
      fieldName = "TAT";
    }
    if (fieldName.toLocaleLowerCase() == "status".toLocaleLowerCase()) {
      fieldName = "Status";
    }
    if (fieldName.toLocaleLowerCase() == "priority".toLocaleLowerCase()) {
      fieldName = "Priority";
    }
    if (fieldName.toLocaleLowerCase() == "qcCompletionDate".toLocaleLowerCase()) {
      fieldName = "QCCompletionDate";
    }
    if (fieldName.toLocaleLowerCase() == "cruCompletionDate".toLocaleLowerCase()) {
      fieldName = "CRUCompletionDate";
    }
    if (fieldName.toLocaleLowerCase() == "remainingDays".toLocaleLowerCase()) {
      fieldName = "RemainingDays";
    }
    if (fieldName.toLocaleLowerCase() == "startDate".toLocaleLowerCase()) {
      fieldName = "StartDate";
    }
    if (fieldName.toLocaleLowerCase() == "noOfVariable".toLocaleLowerCase()) {
      fieldName = "NoOfVariable";
    }
    if (fieldName.toLocaleLowerCase() == "assignedTo".toLocaleLowerCase()) {
      fieldName = "AssignedTo";
    }
    if (fieldName.toLocaleLowerCase() == "assignedBy".toLocaleLowerCase()) {
      fieldName = "AssignedBy";
    }
    return fieldName;
  };

  // Function for Pagination after search
  const searchReport = (filterValue) => {
    setTotalCount(0);
    setPage(0);
    setLoading(true);
    setReload(!reload);
  };

  // Function for Searching the grid
  const globalSearch = (value) => {
    setSearchValue(value);
  };
  function containsOnlyNumbers(str) {
    return /^[0-9,]+$/.test(str);
  }
  const selectAJob = (e, value) => {
    if (value != null && value != "undefined") {
      setJobIdCheck(value.id);
      setJobIds(value.id);
      setJId(value.id);
      let jobName = value.name;
      const jobNameArr = jobName.split(" - ");
      GetQualityList(jobNameArr[0]);
    } else {
      GetQualityList("");
    }
    props.location.state = 1;
  };
  const goToQualitycheck = (mode) => (e, state) => {
    //console.log("item checker", item);
    if (mode === "View") {
      history.push({
        pathname: "/app/data-collection/quality-management-qualitycheck",
        state: {
          JobIdCheck: JobIdCheck, 
        },
      });
    }
  };

  const goToQcSampling = (mode) => (e, state) => {
    //console.log("item checker", item);
    if (mode === "View") {
      history.push({
        pathname: "/app/data-collections/quality-management-drill",
        state: {
          totalCount: totalCount,
        },
      });
    }
  };
  // Function for Getting Quality Data
  const GetQualityList = (filterValue) => {
    setLoading(true);
    let jobIDSearch = null;
    let searchData = { searchText: filterValue };
    let jobNameSearch = { jobId: jobIDSearch };
    let filterData = { ...filterValues, ...searchData, ...jobNameSearch };

    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`]; //, `jobId=${filterValue.jobid ?? 0}`
    ApiService.search(
      `qualitymanagement/GetManageQualityJobs?`,
      {
        // searchText: filterValue,
        sourceFilter: encodeURIComponent(JSON.stringify(filterData)),
        // jobId: jobIDSearch,
        sortColumn: changeSortColumnNameFormat(sorting.sortBy),
        isAscending: sorting.isAscending,
        Authorization: localStorage.getItem("token")
          ? "Bearer " + localStorage.getItem("token")
          : null,
      },
      prt
    )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.data, "resssssss")
          setAllRows(res.data.data);
          setTotalCount(res.data.totalCount);
          setLoading(false);
        } else {
          toasterError(`${res.data}`);
          setLoading(false);
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
        setLoading(false);
      });

  };

  // console.log(allRows, "allRows");
  // Function for Search Clear
  const searchClear = () => {
    setSearchValue("");
    setPage(0);
    setReload(!reload);
  };

  const toggleFilter = (open) => (event) => {
    setState({ ...state, right: open });
    setCrudState(true);
  };

  // Function for Open View and Edit Panel
  const toggleDrawer = (open, mode) => (event, item) => {
    sessionStorage.setItem('assetsPhase1', null);
    sessionStorage.setItem('assetPhase1', JSON.stringify(item));
    console.log(item)
    if (mode === "View") {
      history.push({
        pathname: "/app/data-collections/manage-quality-details",
        state: item,
        allRow: allRows,
      });
    } else {
      setIdForEdit(item.id);
      setEditJobNumber(item.JobNo);
      setOpen(true);
      setScroll("paper");
   }
  };

  console.log(columns, "allRows")

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">
        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={8} className="page-title">
          <h2>Quality Management</h2>
          <Breadcrumbs
            separator={
              <ChevronRightSmallIcon className={classes.breadCrumbsIcon} />
            }
            aria-label="breadcrumb"
            className="custom-breadcrumb"
          >
           <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
            <Typography color="text.primary">Manage Company Data Collections</Typography>
            <Typography color="text.primary">Quality Management</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}
        <Grid item xs={12} sm={12} md={4}>
          {localStorage.getItem("role").toLowerCase() ===
            "Super Admin".toLowerCase() ||
          localStorage.getItem("role").toLowerCase() ===
            "Merit Admin".toLowerCase() ||
          localStorage.getItem("role").toLowerCase() ===
            "Merit QC".toLowerCase() ? (
            /* localStorage.getItem("role").toLowerCase() ===
            "CRU DG Analyst".toLowerCase() ||
          localStorage.getItem("role").toLowerCase() ===
            "CRU DG Admin".toLowerCase() ? ( */
            <>
              {" "}
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={"auto"}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={goToQualitycheck("View")}
                  >
                    Mark QC Pass
                  </Button>
                </Grid>
                <Grid item xs={"auto"}>
                  {localStorage.getItem("role") === "Merit QC" ||
                  localStorage.getItem("role") === "Merit Admin" ||
                  localStorage.getItem("role") === "Super Admin" ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={goToQcSampling("View")}
                    >
                      QC Sampling
                    </Button>
                  ) : (
                    <></>
                  )}
                </Grid>
               
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search and Filter Options Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="left"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6} lg={4}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      size="small"
                      fullWidth
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton
                        aria-label="Add"
                        className="serchByClear"
                        onClick={searchClear}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={jobSelection}
                      getOptionLabel={(option) => option.name}
                      onChange={selectAJob}
                      onKeyUp={handleKeyUp}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Job ID - Job Name"
                          variant="standard"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={"auto"}>
                        <Typography variant="subtitle2">
                          Total Units:{" "}
                          <span className={classes.totalRecord}>
                            {" "}
                            {totalCount}
                          </span>
                        </Typography>
                      </Grid>

                      <Grid item xs={"auto"}>
                        <Button
                          onClick={toggleFilter(true)}
                          variant="contained"
                          color="secondary"
                          startIcon={<FilterIcon style={{ fontSize: 16 }} />}
                        >
                          Filter
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <LoaderWrapper loading={isLoading}>
                  <DataTable
                    className={classes.removeBorder}
                    rows={allRows ? allRows : []}
                    columns={columns}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    totalCount={totalCount}
                    isAllSelected={isAllSelected}
                    setIsSelected={setIsSelected}
                    selected={selected}
                    setSelected={SetSelected}
                    sorting={sorting}
                    setSorting={setSorting}
                    hasChip={{
                      High: { color: "warning", label: "High" },
                      Critical: { color: "error", label: "Critical" },
                      Low: { color: "success", label: "Low" },
                      Medium: { color: "info", label: "Medium" },
                    }}
                    isActionButton={{
                      ...(access.allowView && {
                        view: toggleDrawer(true, "View"),
                      }),
                    }}
                  />
                </LoaderWrapper>
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>
      <SideDrawer
        formComp={
          <QualityFilter
            dataForFilter={dataForFilter}
            filterFinalData={filterFinalData}
            companyFilter={companyFilter}
            locationFilter={locationFilter}
            setFilterValue={setFilterValues}
            state={state}
            setState={setState}
            resetTreeFilter={resetTreeFilter}
            setJobCompanies={setJobCompanies}
            setJobLocations={setJobLocations}
            setLocationFilter={setLocationFilter}
            setCompanyFilter={setCompanyFilter}
            setResetFilter={setResetFilter}
            setDataForFilter={setDataForFilter}
            getFilterData={getFilterData}
            reload={reload}
            setReload={setReload}
            setTatDate={setTatDate}
            tatDate={tatDate}
          />
        }
        state={state}
        setState={setState}
        crudState={crudState}
      />
    </Box>
  );
};

export default DataCollectionQualityList;