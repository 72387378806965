// Default React, useState and Material Components
import React, { useState } from "react";
import { Button, Breadcrumbs, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography, Box, } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import SideDrawer from "../../../UIComponent/Drawer";
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing Add & Edit Components
import AddEditCruVariable from "./AddEditCruVariable";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const ManageCruVariables = (props) => {

    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [reload, setReload] = useState(false);
    const [formData, setFormData] = useState([]);
    const [selected, SetSelected] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openDelete, setOpenDelete] = useState(false);
    const [state, setState] = useState({ right: false });
    const [isAllSelected, setIsSelected] = useState(false);
    const [allCRUVariables, setCRUVariables] = useState([]);
    const [crudState, setCrudState] = React.useState(false);
    const [deleteBtnLoadinng, setDeleteBtnLoadinng] = useState(false);
    const [deleteVariableData, setDeleteVariableData] = useState(null);
    const [addCRUVariableDrawer, setAddCRUVariableDrawer] = useState(true);
    const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
    const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
    const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false })
    // Declaration of States and Component Variables End Here

    // Declaration of React Hooks Start Here
    React.useEffect(() => {
        GetMenuService(29, function (err, res) {
            if (res) {
                // console.log(res.data)
                setAccess(res.data)
            } else {
                toasterError(err)
            }
        })
    }, [])
    React.useEffect(() => {
        if (searchValue == "" || searchValue == null || searchValue == undefined) {
            getAllCRUVariables();
        }
        if (searchValue && reload) { searchCruVariable(searchValue); }
    }, [page, reload, rowsPerPage])
    React.useEffect(() => {
        if (searchValue == "" || searchValue == null || searchValue == undefined) {
            getAllCRUVariables();
        }
        if (searchValue) { searchCruVariable(searchValue); }
    }, [sorting])
    // Declaration of React Hooks End Here

    // Assigning Columns and UI Props
    const columns = [
        { id: "Id", label: "Id", type: "Highlight" },
        { id: "name", label: "Variable", minWidth: 200, sorting: true },
        { id: "variableType", label: "Variable Type", minWidth: 200, width: '15%', sorting: true },
        { id: "usualSource", label: "Usual Source", minWidth: 250, width: '20%', sorting: true },
        { id: "description", label: "Description", minWidth: 300, width: '30%', sorting: true, truncate: 80, tooltipWithTruncate: true },
    ];

// This function handles the keyup event
const handleKeyUp = (event) => {
    // If backspace is pressed and target is not a button
    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
        // If search value is empty, get all CRU variables
        if (searchValue.length === 0) { getAllCRUVariables(); }
    }
    // If enter is pressed and target is not a button
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
        // Set total count to 0 and page to 0
        setTotalCount(0);
        setPage(0);
        // Search for CRU variable with given value
        searchCruVariable(event.target.value);
    }
};

    // Function for Sorting changes the format of a given field name to match the expected format
    const changeSortColumnNameFormat = (fieldName) => {
        // If the fieldName is null or empty, return an empty string
        if (fieldName == null || fieldName == "") { return ""; }
        // Change the fieldName to 'Name' if it matches 'name' in lowercase
        if (fieldName.toLocaleLowerCase() == "name") { fieldName = "Name"; }
        // Change the fieldName to 'VariableType' if it matches 'variabletype' in lowercase
        if (fieldName.toLocaleLowerCase() == "variabletype") { fieldName = "VariableType"; }
        // Change the fieldName to 'UsualSource' if it matches 'usualsource' in lowercase
        if (fieldName.toLocaleLowerCase() == "usualsource") { fieldName = "UsualSource"; }
        // Change the fieldName to 'Description' if it matches 'description' in lowercase
        if (fieldName.toLocaleLowerCase() == "description") { fieldName = "Description"; }
        // Return the modified fieldName
        return fieldName;
    }

    // Function for Open Delete Panel
    const toggleDrawer = (open, mode) => (event, item) => {
        if (mode === "Delete") {
            setDeleteVariableData(item);
            setOpenDelete(true);
        } else {
            setFormData(item);
            setState({ ...state, right: open, formData: item });
            setCrudState(mode);
        }
    };

// This function is used to get all CRU Variables
const getAllCRUVariables = () => {
    // Set loading to true
    setLoading(true);
    // Create an array of parameters
    let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
    // Call the ApiService search method
    ApiService.search("cruvariable/GetAllCRUVariablesDetails?",
        {
            // Set the sort column name format
            sortColumn: changeSortColumnNameFormat(sorting.sortBy),
            // Set the sorting order
            isAscending: sorting.isAscending
        }, prt
    ).then((res) => {
        // Check if response status is 200
        if (res.status === 200) {
            // Set CRU Variables
            setCRUVariables(res.data.data);
            // Set total count
            setTotalCount(res.data.totalCount);
            // Set loading to false
            setLoading(false);
        } else {
            // Show error message
            toasterError(`${res.data}`);
            // Set loading to false
            setLoading(false);
        }
    })
        .catch((err) => {
            // Set loading to false
            setLoading(false);
            // Handle errors
            ErroHandling(err, function (res) {
                // Show error message
                toasterError(res)
            })
        });
}

    // Function for Global Search
    const globalSearch = (value) => { setSearchValue(value); }

    // This function is used to search for CRUVariables
    const searchCruVariable = (filterValue) => {
    // Set loading to true while the data is being fetched    
        setLoading(true);
        let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
     // Create an array of parameters   
        ApiService.search(
            "cruvariable/GetAllCRUVariablesDetails?",
            {
                // Encode the searchText parameter
                searchText: encodeURIComponent(filterValue),
                 // Change the format of the sortColumn parameter
                sortColumn: changeSortColumnNameFormat(sorting.sortBy),
                 // Set the sorting order
                isAscending: sorting.isAscending
            }, prt)
            .then((res) => {
             // If the response status is 200, set the CRUVariables and totalCount   
                if (res.status === 200) {
                    setCRUVariables(res.data.data);
                    setLoading(false);
                    setTotalCount(res.data.totalCount);
                } else {
                 // Else, display an error message   
                    toasterError(`${res.data}`);
                    setLoading(false);
                }
            })
            .catch((err) => {
                // Catch any errors and handle them
                setLoading(false);
                ErroHandling(err, function (res) {
                    toasterError(res)
                })
            });
    }

  // Function to delete CRU Variable
const deleteCruVariable = () => {
    // Set loading state of delete button to true
    setDeleteBtnLoadinng(true);
    // Store data in a variable
    let data = deleteVariableData;
    // Set active status to false
    data.active = false;
    // Make API call to delete CRU Variable
    ApiService.create(`cruvariable/DeleteCRUVariable?Id=${data.id}`)
        .then((res) => {
            // If API call is successful
            if (res.status === 200) {
                // Show success message
                toasterSuccess("CRU variable deleted successfully!");
                // Get all CRU Variables
                getAllCRUVariables();
                // Set loading state of delete button to false
                setDeleteBtnLoadinng(false);
                // Close delete modal
                setOpenDelete(false);
            } else {
                // Show error message
                toasterError("Somthing went wrong");
                // Close delete modal
                setOpenDelete(false);
                // Set loading state of delete button to false
                setDeleteBtnLoadinng(false);
            }
        })
        .catch((err) => {
            // Close delete modal
            setOpenDelete(false);
            // Set loading state of delete button to false
            setDeleteBtnLoadinng(false);
            // Handle errors
            ErroHandling(err, function (res) {
                // Show error message
                toasterError(res)
            })
        });
}

    return (

        // Page Layout Start Here
        <Box>
            <Grid container maxWidth justifyContent="right" alignItems="center">

                {/* Breadcrumbs Menu Start Here */}
                <Grid item xs={12} sm={12} md={6} className="page-title">
                    <h2>Variable (CRU)</h2>
                    <Breadcrumbs
                        separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/commodity" }}>Manage Data</Link>
                        <Typography>Variable (CRU)</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* Breadcrumbs Menu End Here */}

                {/* Main Buttons Start Here */}
                <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                        <Grid item xs={"auto"}>
                            {(access.allowEdit) ? <>
                                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: "16px" }} />}>
                                    Add Variable
                                </Button></> : null}
                        </Grid>
                    </Grid>
                </Grid>
                {/* Main Buttons End Here */}

                {/* Baselayout Start Here */}
                <Grid item xs={12}>
                    <BaseLayout>
                        <Box className={classes.addBorder}>
                            {/* Search Option and Total Count Start Here */}
                            <Grid item xs={12} className={classes.tableTopPart}>
                                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="input-with-icon-textfield"
                                            className={classes.searchByKeyword}
                                            value={searchValue}
                                            placeholder="Search by Keyword"
                                            onKeyUp={handleKeyUp}
                                            onChange={(e) => globalSearch(e.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon className={classes.searchIcon} />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {searchValue ? (<IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => {
                                                                setSearchValue("");
                                                                getAllCRUVariables();
                                                            }}
                                                        >
                                                            <ClearIcon style={{ fontSize: 10, position: "absolute", left: "25px" }} />
                                                        </IconButton>) : ("")}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} textAlign="right">
                                        <Typography variant="subtitle2">
                                            Total Records: <span className={classes.totalRecord}>{totalCount}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Search Option and Total Count End Here */}

                            {/* Unit CRU Table /> */}

                            <Grid item xs={12}>
                                <DataTable
                                    className={classes.removeBorder}
                                    loading={isLoading}
                                    rows={allCRUVariables ? allCRUVariables : []}
                                    columns={columns}
                                    page={page}
                                    setPage={(data) => { setPage(data); setReload(true); }}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={(data) => {
                                        setRowsPerPage(data);
                                        setReload(true);
                                    }}
                                    totalCount={totalCount}
                                    isAllSelected={isAllSelected}
                                    setIsSelected={setIsSelected}
                                    selected={selected}
                                    setSelected={SetSelected}
                                    sorting={sorting}
                                    setSorting={(e) => {
                                        setSorting(e);
                                    }}
                                    isActionButton={{
                                        ...(access.allowEdit && { edit: toggleDrawer(true, "Edit"), }),
                                        ...(access.allowDelete && { delete: toggleDrawer(true, "Delete"), }),
                                    }}
                                />
                            </Grid>
                        </Box>
                    </BaseLayout>
                </Grid>
            </Grid>
            <SideDrawer
                formComp={
                    <AddEditCruVariable
                        state={state}
                        setState={setState}
                        crudState={crudState}
                        formData={formData}
                        setFormData={setFormData}
                        variableId={null}
                        hideSideDrawer={() => {
                            setState({ right: false });
                            setSearchValue("");
                            setReload(!reload);
                        }}
                        addCRUVariableDrawer={addCRUVariableDrawer}
                    />
                }
                state={state}
                setState={setState}
            />
            {openDelete ? (
                <AlertDialog
                    open={openDelete}
                    title={"Confirmation"}
                    maxWidth={"sm"}
                    description={`Are you sure you want to delete CRU variable ${deleteVariableData?.name} ?`}
                    action={
                        <>
                            <div className={classes.alertDialogbtn}>
                                <Button onClick={() => setOpenDelete(false)} style={{ marginRight: "15px" }}>No</Button>
                                {deleteBtnLoadinng ? (
                                    <CircularProgress />
                                ) : (
                                    <Button onClick={() => deleteCruVariable()} color="secondary">
                                        Yes
                                    </Button>
                                )}
                            </div>
                        </>
                    }
                />
            ) : null}
        </Box>
    )
}

export default ManageCruVariables;