// Default React, Router and Services Components
import React from "react";
import { Box, Chip, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import EmptyState from "../../../UIComponent/EmptyState";
import { toasterError } from "../../../UIComponent/Toaster";

// Import Fluent UI Icons
import { FileOffIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";

// Creating Data Table for Data Metrics
function createData(variableName, publication, valuation, value, maxValue, minValue, currency, modifiedDate, modifiedBy, comments) {
  return {
    variableName, publication, valuation, value, maxValue, minValue,
    currency, modifiedDate, modifiedBy, comments,
  };
}

const DataMetrics = (props) => {
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [rowData, setRowData] = React.useState([]);
  const [isGuidance, setisGuidance] = React.useState(false)

  // Assigning Columns and UI Props
  const columnOne = [
    { id: "variableName", label: "Variable(Reports)", sorting: false, align: "center", },
    { id: "publication", label: "Publication", sorting: false, align: "center", },
    { id: "valuation", label: "Valuation", sorting: false, align: "center", },
    { id: "value", label: "Value", sorting: false, align: "center", },
    { id: "currency", label: "Currency (If Applicable)", sorting: false, align: "center", },
    { id: "modifiedDate", label: "Modified Date", sorting: false, },
    { id: "modifiedBy", label: "Modified By", sorting: false, },
    { id: "comments", label: "Comments", sorting: false, align: "center", },
  ];
  // Declaration of States and Component Variables End Here

  // Function for Getting Data
  const getData = async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_baseUrl}updatejobrecord/VariableValuesAuditLog`,
        {
          headers: {
            variableId: [... new Set(props.auditVariableId)],
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          }
        }
      )
      let finalData = await response.data?.map((el, key) =>
        createData(
          el.variableName, el.publication, el.valuationType, el.value, el.minValue, el.maxValue,
          el.currency, el.modifiedDate, el.modifiedBy, el.comments
        )
      );
      setRowData(finalData);
    } catch (error) {
      ErroHandling(error, function (res) { toasterError(res) })
      return null;
    }
  };

  // Declaration of React Hooks Start Here
  React.useEffect(() => { getData(); }, []);
  // Declaration of React Hooks End Here

  return (
    <Box>
      <Grid container spacing={3} justifyContent="left" alignItems="center" sx={{ p: 3 }}>
        <Grid item xs={12}>
          <Box className={classes.setBorder}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {columnOne.map((el) => {
                    return (
                      <TableCell className={classes.tableHeader}
                        style={{ minWidth: "100px", whiteSpace: "nowrap" }}>
                        {el.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              {rowData.length > 0 ? (
                <TableBody>
                  {rowData.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="left" className={classes.tableCell}
                        style={{ minWidth: 200, maxWidth: 250 }}>
                        {row.variableName}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell} style={{ minWidth: 100 }}>
                        {row.publication}
                      </TableCell>
                      <TableCell component="th" scope="row" className={classes.tableCell} style={{ minWidth: 100 }}>
                        {row.valuation === "Actual" ? (
                          <Chip
                            label={row.valuation}
                            size="small"
                            color="warning"
                            className="statusChip"
                          />
                        ) : undefined}{" "}
                        {row.valuation === "Calculated" ? (
                          <Chip
                            label={row.valuation}
                            size="small"
                            color="success"
                            className="statusChip"
                          />
                        ) : undefined}{" "}
                        {row.valuation === "Guidance" ? (
                          <Chip
                            label={row.valuation}
                            size="small"
                            color="success"
                            className="statusChip"
                          />
                        ) : undefined}{" "}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell} style={{ minWidth: 140 }}>
                        {row.valuation === "Guidance" ? `${row.minValue ? row.minValue?.toLocaleString() : ""} ${row.minValue === null || row.maxValue === null ? "" : "~"}  ${row.maxValue ? row.maxValue?.toLocaleString()  : ""}` : row.value?.toLocaleString() }
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell} style={{ minWidth: 120 }}>
                        {row.currency}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell} style={{ minWidth: 120 }}>
                        {row.modifiedDate}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell} style={{ minWidth: 120 }}>
                        {row.modifiedBy ?? "NA"}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell} style={{ minWidth: 250, maxWidth: '30%' }}>
                        {row.comments ? row.comments : "NA"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={99}>
                      <EmptyState
                        icon={<FileOffIcon style={{ color: "#db9400", fontSize: "40px" }} />}
                        title="No Data Found"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataMetrics;