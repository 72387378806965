// Default React, Router and Services Components
import React, { useState } from "react";
import { Breadcrumbs, Box, Grid, Paper, Typography, Button } from "@mui/material";
import ApiService from "../../../../Services/ApiService";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import ErroHandling from "../../../../Services/ErrorHandling";
import { toasterError } from "../../../../UIComponent/Toaster";
// Importing Custom Components
import BaseLayout from "../../../../UIComponent/BaseLayout";
import DataTable from "../../../../UIComponent/DataTable";
import DatePicker from "../../../../UIComponent/DatePicker";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";

// Importing useStyles
import useStyles from "./WeeklyStatusReport.styles";

// Import Fluent UI Icons
import { ChevronRightSmallIcon, ExportIcon } from "@fluentui/react-icons-mdl2";

const DataCollectionWeeklyStatusReport = (props) => {
    // Declaration of States and Component Variables Start Here
    const [allWeeklyReports, setWeeklyReports] = useState([]);
    const classes = useStyles();
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
    const [selected, SetSelected] = useState([]);
    const [isAllSelected, setIsSelected] = useState(false);
    const [reload, setReload] = useState(false);
    const [value, setValue] = useState({ startDate: '', endDate: '', });
    // Declaration of States and Component Variables End Here

    // Declaration of React Hooks Start Here
    React.useEffect(() => {
        if (value.startDate != '' && value.endDate != '') getAllWeeklyStatusReports();
    }, [page, reload, rowsPerPage, sorting])

    React.useEffect(() => {
        if (value.startDate != '' && value.endDate != '') getAllWeeklyStatusReports();
    }, [value.startDate, value.endDate])
    // Declaration of React Hooks End Here

    // Assigning Columns and UI Props
    const columns = [
        { id: "Id", label: "Id", type: "Highlight" },
        { id: "jobName", label: "Job Name", minWidth: 350, width: 350, sorting: true },
        { id: "totalNoOfAssets", label: "Total Assets", minWidth: 100, width: 'auto', sorting: true, align: "center" },
        { id: "totalNoOfAssetsCompleted", label: "Assets Completed ", minWidth: 120, width: 'auto', sorting: true, align: "center" },
        { id: "totalNoOfAssetsPending", label: "Assets Pending", minWidth: 100, width: 'auto', sorting: true, align: "center" },
        { id: "nameOfProductionAnalyst", label: "Analysts Deployed (Production)", minWidth: 170, width: 'auto', sorting: true },
        { id: "nameOfQCAnalyst", label: "Analysts Deployed (QC)", minWidth: 150, width: 'auto', sorting: true, },
        { id: "productionHours", label: "Production Hours", minWidth: 110, width: 'auto', sorting: true, align: "center" },
        { id: "qcHours", label: "QC Hours", minWidth: 100, width: 'auto', sorting: true, align: "center" },
        { id: "averageTimeTakenPerAssets", label: "Avg Time Taken per asset", minWidth: 150, width: 'auto', sorting: true, align: "center" },
        { id: "ragStatus", label: "RAG Status", minWidth: 90, width: 'auto', sorting: true, type: "Chip", align: "center" },
        { id: "jobStartDate", label: "Job Start Date", minWidth: 110, width: 'auto', sorting: true, align: "center" },
        { id: "jobEndDate", label: "Job End Date", minWidth: 110, width: 'auto', sorting: true, align: "center" },
    ];

    // Function for Validation
    const date = "2022-11-25T21:11:54";
    const validation = (value) => {
        if (value.startDate >= value.endDate) {
            toasterError("To Date Must Be Greater");
            return false;
        }
        return true;
    }
    const getAllWeeklyStatusReports = () => {
        if (validation(value)) {
            setLoading(true);
            let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
            ApiService.search("reports/GetWeeklyStatusReport?",
                {
                    isAscending: sorting.isAscending,
                    sortColumn: changeSortColumnNameFormat(sorting.sortBy),
                    startDate: value.startDate.split('-').reverse().join('-'),
                    endDate: value.endDate.split('-').reverse().join('-')
                }, prt
            ).then((res) => {
                if (res.status === 200) {
                    if (res.data.data != null) {
                        let newData = res.data.data.map((e) => {
                            if (e.productionHours !== null && e.productionHours !== undefined && e.productionHours !== '') {
                                let tempProductionHours = e.productionHours;
                                let splitedValue = tempProductionHours.split(':');
                                let tempNewsplitedValue = splitedValue.map((el) => { return formatNumber(el) });
                                tempProductionHours = tempNewsplitedValue.join(':');
                                e.productionHours = tempProductionHours;
                            }
                            if (e.qcHours !== null && e.qcHours !== undefined && e.qcHours !== '') {
                                let tempQcHours = e.qcHours;
                                let splitedValue = tempQcHours.split(':');
                                let tempNewsplitedValue = splitedValue.map((el) => { return formatNumber(el) });
                                tempQcHours = tempNewsplitedValue.join(':');
                                e.qcHours = tempQcHours;
                            }
                            if (e.averageTimeTakenPerAssets !== null && e.averageTimeTakenPerAssets !== undefined && e.averageTimeTakenPerAssets !== '') {
                                let tempAverageTimeTakenPerAssets = e.averageTimeTakenPerAssets;
                                let splitedValue = tempAverageTimeTakenPerAssets.split(':');
                                let tempNewsplitedValue = splitedValue.map((el) => { return formatNumber(el) });
                                tempAverageTimeTakenPerAssets = tempNewsplitedValue.join(':');
                                e.averageTimeTakenPerAssets = tempAverageTimeTakenPerAssets;
                            }
                            return e;
                        })
                        setWeeklyReports(newData);
                    } else {
                        setWeeklyReports(res.data.data);
                    }

                    setTotalCount(res.data.totalCount);
                    setLoading(false);
                } else {
                    toasterError(`${res.data}`);
                    setLoading(false);
                }
            })
            .catch((err) => {

                setLoading(false);
        
                if(err.res.status===400){
        
                  toasterError(err.res.data);
        
                }
        
                 });
        }
        else {
            setLoading(false)
        }
    }

    // Function for Change Sort Column
    const changeSortColumnNameFormat = (fieldName) => {
        if (fieldName == null || fieldName == "") { return ""; }
        if (fieldName.toLocaleLowerCase() === "jobName".toLocaleLowerCase()) { fieldName = "JobName"; }
        if (fieldName.toLocaleLowerCase() === "totalNoOfAssets".toLocaleLowerCase()) { fieldName = "TotalNoOfAssets"; }
        if (fieldName.toLocaleLowerCase() === "totalNoOfAssetsCompleted".toLocaleLowerCase()) { fieldName = "TotalNoOfAssetsCompleted"; }
        if (fieldName.toLocaleLowerCase() === "totalNoOfAssetsPending".toLocaleLowerCase()) { fieldName = "TotalNoOfAssetsPending"; }
        if (fieldName.toLocaleLowerCase() === "nameOfProductionAnalyst".toLocaleLowerCase()) { fieldName = "NameOfProductionAnalyst"; }
        if (fieldName.toLocaleLowerCase() === "nameOfQCAnalyst".toLocaleLowerCase()) { fieldName = "NameOfQCAnalyst"; }
        if (fieldName.toLocaleLowerCase() === "productionHours".toLocaleLowerCase()) { fieldName = "ProductionHours"; }
        if (fieldName.toLocaleLowerCase() === "qcHours".toLocaleLowerCase()) { fieldName = "QCHours"; }
        if (fieldName.toLocaleLowerCase() === "averageTimeTakenPerAssets".toLocaleLowerCase()) { fieldName = "AverageTimeTakenPerAssets"; }
        if (fieldName.toLocaleLowerCase() === "ragStatus".toLocaleLowerCase()) { fieldName = "RAGStatus"; }
        if (fieldName.toLocaleLowerCase() === "jobStartDate".toLocaleLowerCase()) { fieldName = "JobStartDate"; }
        if (fieldName.toLocaleLowerCase() === "jobEndDate".toLocaleLowerCase()) { fieldName = "JobEndDate"; }
        return fieldName;
    };
    const formatNumber = (number) => {
        let formattedNumber = String(number).padStart(2, '0')
        return formattedNumber;
    }
    const handleExportToExcel = async () => {
        try {
            if (validation(value)) {
                let prt = [`pageNumber=1`, `pageSize=${totalCount}`];
                ApiService.search("reports/GetWeeklyStatusReport?",
                    {
                        isAscending: sorting.isAscending,
                        sortColumn: changeSortColumnNameFormat(sorting.sortBy),
                        startDate: value.startDate.split('-').reverse().join('-'),
                        endDate: value.endDate.split('-').reverse().join('-')
                    }, prt
                ).then((res) => {
                    let excelData = [];
                    res.data.data.forEach((obj, index) => {
                        let tempData = {
                            "JobName": obj.jobName,
                            "Total Asset": obj.totalNoOfAssets,
                            "Assets Compeleted": obj.totalNoOfAssetsCompleted,
                            "Assets Pending": obj.totalNoOfAssetsPending,
                            "Analysts Deployed (Production)": obj.nameOfProductionAnalyst,
                            "Analysts Deployed (QC)": obj.nameOfQCAnalyst,
                            "Production Hours": obj.productionHours,
                            "QC Hours": obj.qcHours,
                            "Avg Time Taken per asset": obj.averageTimeTakenPerAssets,
                            "RAG Status": obj.ragStatus,
                            "Job Start Date": obj.jobStartDate,
                            "Job End Date": obj.jobEndDate
                        }
                        excelData.push(tempData);
                    });
                    const fileName = `Weekly Status Report ${new Date()
                        .toLocaleDateString()
                        .split("/")
                        .join("-")}`;
                    exportToCSV(excelData, fileName)

                });

            }
        } catch (error) {
            ErroHandling(error, function (res) {
                toasterError(res)
            })
        }
    }
    // Function for Export to Excel
    const exportToCSV = (apiData, fileName) => {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(apiData);
        XLSX.utils.sheet_add_aoa(
            ws,
            [[
                "JobName",
                "Total Asset",
                "Assets Compeleted",
                "Assets Pending",
                "Analysts Deployed (Production)",
                "Analysts Deployed (QC)",
                "Production Hours",
                "QC Hours",
                "Avg Time Taken per asset",
                "RAG Status",
                "Job Start Date",
                "Job End Date"

            ],],
            { origin: "A1", }
        );
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        // Page Layout Start Here
        <Box>
            <Grid container maxWidth justifyContent="right" alignItems="center">

                {/* Breadcrumbs Menu Start Here */}
                <Grid item xs={12} sm={12} md={6} className="page-title">
                    <h2>Weekly Status Report</h2>
                    <Breadcrumbs
                        separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/source-management" }}>Home</Link>
                        <Typography color="text.primary">Manage Company Data Collections</Typography>
                        <Link underline="hover" color="inherit" to={{ pathname: "/app/data-collections/aging-report" }}>Dashboard and Reports</Link>
                        <Typography color="text.primary">Weekly Status Report</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* Breadcrumbs Menu End Here */}

                {/* Main Buttons Start Here */}
                <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                        <Grid item xs={"auto"}>

                        </Grid>
                    </Grid>
                </Grid>
                {/* Main Buttons End Here */}

                {/* Baselayout Start Here */}
                <Grid item xs={12}>
                    <BaseLayout>
                        <Box className={classes.addBorder}>

                            {/* Search and Filter Options Start Here */}
                            <Grid item xs={12} className={classes.tableTopPart}>
                                <Grid container alignItems="flex-end" justifyContent="left" spacing={2}>
                                    <Grid item xs={12} lg={9}>
                                        <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-end">
                                            <Grid item xs={"auto"}>
                                                <DatePicker
                                                    labelTitle="From Date"
                                                    variant="standard"
                                                    past={false}
                                                    onChangeDate={(e) => {
                                                        let selectedDate = new Date(e);
                                                        let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
                                                        setValue({ ...value, startDate: date })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={"auto"}>
                                                <DatePicker
                                                    labelTitle="To Date"
                                                    variant="standard"
                                                    past={false}
                                                    minDates={value.startDate}
                                                    onChangeDate={(e) => {
                                                        let selectedDate = new Date(e);
                                                        let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
                                                        setValue({ ...value, endDate: date })
                                                    }}
                                                />
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={3} textAlign="right">
                                        <Grid container spacing={3} justifyContent="flex-end" alignItems="flex-end">
                                            <Grid item xs={"auto"}>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    startIcon={<ExportIcon style={{ fontSize: 14 }} />}
                                                    onClick={handleExportToExcel}

                                                >
                                                    Export To Excel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Search and Filter Options End Here */}



                            {/* Weekly Status Report Table */}
                            <Grid item xs={12}>
                                <DataTable
                                    className={classes.removeBorder}
                                    loading={isLoading}
                                    rows={allWeeklyReports ? allWeeklyReports : []}
                                    columns={columns}
                                    page={page}
                                    setPage={(data) => {
                                        setPage(data);
                                        setReload(true);
                                    }}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={(data) => {
                                        setRowsPerPage(data);
                                        setReload(true);
                                    }}
                                    totalCount={totalCount}
                                    isAllSelected={isAllSelected}
                                    setIsSelected={setIsSelected}
                                    selected={selected}
                                    setSelected={SetSelected}
                                    sorting={sorting}
                                    setSorting={setSorting}
                                    hasChip={{
                                        Red: { color: "error", label: "Red" },
                                        Green: { color: "success", label: "Green" },
                                        Amber: { color: "warning", label: "Amber" },
                                    }}
                                />
                            </Grid>
                        </Box>
                    </BaseLayout>
                </Grid>
            </Grid>
        </Box>
    )
}


export default DataCollectionWeeklyStatusReport;