// Default React, useState and Material Components
import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";

// Importing Custom Components
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Import Material & Fluent UI Icons
import AddIcon from "@mui/icons-material/Add";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  CirclePlusIcon,
  DeleteIcon,
  FileOffIcon,
  TextDocumentIcon,
} from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";
import axios from "axios";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
import ApiService from "../../../Services/ApiService";
import EmptyState from "../../../UIComponent/EmptyState";
import CommonService from "../../../Services/CommonService";
import { el } from "date-fns/locale";
import AddReportInfo from "./AddReportInfo";
import SideDrawer from "../../../UIComponent/Drawer";
import DatePicker from "../../../UIComponent/DatePicker";
import dayjs from "dayjs";
import AlertDialog from "../../../UIComponent/AlertDialog";

const EditJointVentureInfo = (props) => {
  console.log("CRU", props);
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [state, setState] = React.useState({ right: false });
  const [showReportInfo, setShowReportInfo] = React.useState(false);
  const [crudState, setCrudState] = React.useState(false);

  const [sourcefiledropdown, setSourceFileDropdown] = React.useState([]);
  const [stockdropdown, setStockExchangeDropdown] = React.useState([]);
  const [ownerdropdown, setOwnerDropdown] = React.useState([]);
  const [newEntry, setNewEntry] = React.useState(false);
  const [selectedsourceFile, setSelectedsourceFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [Owner, setOwner] = React.useState(null);
  const [Stock, setStock] = React.useState(null);
  const [addLegalEntity, setAddLegalEntity] = React.useState(false);
  const [addCustomeStock, setAddCustomeStock] = React.useState(false);
  const [allYear, setAllYear] = React.useState([]);
  const [allYearOwnership, setAllYearOwnership] = React.useState([]);
  const [preselectedYear, setPreselectedYear] = React.useState(null);
  const [defaultYearValue, setDefaultYearValue] = React.useState(null);
  const [openLegal, setOpenLegal] = React.useState(false);
  const [confirmPopUp, setConfirmPopUp] = React.useState(false);
  const [BtnLoadinng, setBtnLoadinng] = React.useState(false);
  const [legalid, setLegalId] = React.useState();

  const [customeStock, setCustomeStock] = React.useState({
    id: 0,
    symbol: "",
    name: "",
  });
  const [addCustomeOwner, setAddCustomeOwner] = React.useState(false);
  const [customeOwner, setCustomeOwner] = React.useState({
    ownerGuid: "",
    correctOwnerName: "",
    legalEntityTypeId: 0,
  });
  const [popupDropdown, setPopupDropdown] = React.useState([]);
  const [customeLegal, setCustomLegal] = React.useState({
    ownerGuid: "",
    correctOwnerName: "",
    legalEntityTypeId: 0,
  });

  const [legalentitydropdown, setLegalEntityDropdown] = React.useState([]);
  const [previousPopdata, setPreviousPopdata] = React.useState(null);
  const [popdata, setPopdata] = React.useState(null);
  const [sourceFileOwner, setSourceFileOwner] = React.useState([
    {
      jointVentureReportMappingId: 0,
      jobAssetReportInfoId: 0,
      reportName: "",
      pageNumber: 0,
      reportType: 0,
      isEdited: false,
      isDeleted: false,
    },
  ]);
  const [sourceFileOwnership, setSourceFileOwnership] = React.useState([
    {
      jointVentureReportMappingId: 0,
      jobAssetReportInfoId: 0,
      reportName: "",
      pageNumber: 0,
      reportType: 0,
      isEdited: false,
      isDeleted: false,
    },
  ]);
  const [sourceFileStock, setSourceFileStock] = React.useState([
    {
      jointVentureReportMappingId: 0,
      jobAssetReportInfoId: 0,
      reportName: "",
      pageNumber: 0,
      reportType: 0,
      isEdited: false,
      isDeleted: false,
    },
  ]);
  const [editformData, setEditFormData] = React.useState({});

  const toggleDrawer = (open, mode) => (event, item) => {
    setState({ ...state, right: open });
    setShowReportInfo(true);
    setCrudState(mode);
  };
  React.useEffect(() => {
    console.log("props.prefillData1.unsure",props.prefillData.unsure)
    console.log("Data1")

    if (props.crudState === "Edit") {
      let Data = {
        jobAssetId: props.jobAssetId,
        seeThroughOwnerInfoId: props.prefillData.id,
        unsure: props.prefillData.unsure,
        legalEntityTypeId: props.prefillData.legalEntityTypeId,
        seeThroughOwnerGuid: props.prefillData.seeThroughOwnerGuid,
        seeThroughOwnerComment: props.prefillData.seeThroughOwnerComment,
        seeThroughOwnershipPercentage:
          props.prefillData.seeThroughOwnershipPercentage,
          ownershipYearId: props.prefillData.ownershipYearId,
          yearId:props.prefillData.yearId,
        //ownershipDate: props.prefillData.ownershipDate,
        seeThroughOwnershipComment:
          props.prefillData.seeThroughOwnershipComment,
        //ownershipDate: props.prefillData.ownershipDate,
        stockExchangeId: props.prefillData.stockExchangeId,
        stockExchangeComment: props.prefillData.stockExchangeComment,
        editSeeThroughOwnerReportDetails: [
          ...(props?.prefillData?.seeThroughOwnerSourceFileList || []).map(
            (e) => ({
              jointVentureReportMappingId: e.reportInfoMappingId,
              jobAssetReportInfoId: e.jobAssetReportInfoId,
              reportName: e.reportName,
              pageNumber: e.pageNo,
              reportType: 1,
              isEdited: false,
              isDeleted: false,
            })
          ),
        ],
        editSeeThroughOwnershipReportDetails: [
          ...(props?.prefillData?.seeThroughOwnershipSourceFileList || []).map(
            (e) => ({
              jointVentureReportMappingId: e.reportInfoMappingId,
              jobAssetReportInfoId: e.jobAssetReportInfoId,
              reportName: e.reportName,
              pageNumber: e.pageNo,
              reportType: 2,
              isEdited: false,
              isDeleted: false,
            })
          ),
        ],
        editSeeThroughStockReportDetails: [
          ...(props?.prefillData?.stockExchangeSourceFileList || []).map(
            (e) => ({
              jointVentureReportMappingId: e.reportInfoMappingId,
              jobAssetReportInfoId: e.jobAssetReportInfoId,
              reportName: e.reportName,
              pageNumber: e.pageNo,
              reportType: 3,
              isEdited: false,
              isDeleted: false,
            })
          ),
        ],
      };
      console.log("Data",Data)
      setEditFormData(Data);

      setDefaultYearValue({
        createdById: null,
        createdOn: null,
        id: props.prefillData.yearId,
        isActive: true,
        modifiedById: null,
        modifiedOn: null,
        year: props.prefillData.year,
        
      });
      setPreselectedYear({
          id: props.prefillData.ownershipYearId,
          isActive: true,
          createdById: null,
          createdOn: null,
          modifiedById: null,
          year: props.prefillData.ownershipYear,

      })
      ownerdropdown.forEach((rData, i) => {
        if (rData.guid === props.prefillData.seeThroughOwnerGuid) {
          setOwner(ownerdropdown[i]);
        }
      });
      stockdropdown.forEach((rData, i) => {
        if (rData.id === props.prefillData.stockExchangeId) {
          setStock(stockdropdown[i]);
        }
        
      });

      const preselectedSourceFiles = (
        props.prefillData.seeThroughOwnerSourceFileList || []
      ).map((item) => ({
        sourceFile: item, // Modify this line to match the structure of your source file data
        pageNo: item.pageNo || "", // Set the prefill value of the page number or an empty string
      }));
      setSourceFileOwner(preselectedSourceFiles);

      const preselectedSourceFilesOwnership = (
        props.prefillData.seeThroughOwnershipSourceFileList || []
      ).map((item) => ({
        sourceFile: item, // Modify this line to match the structure of your source file data
        pageNo: item.pageNo || "", // Set the prefill value of the page number or an empty string
      }));
      setSourceFileOwnership(preselectedSourceFilesOwnership);

      const preselectedSourceFilesStock = (
        props.prefillData.stockExchangeSourceFileList || []
      ).map((item) => ({
        sourceFile: item,
        pageNo: item.pageNo || "",
      }));
      setSourceFileStock(preselectedSourceFilesStock);
      console.log("PIP", preselectedSourceFilesStock);
    }
  }, [ownerdropdown, props, props.prefillData]);

  const getAddReportYear = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "ManageOwnership/GetReportingYears",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setAllYear(res.data);
          //setAllYearOwnership(res.data)
        } else {
          toasterError("Something went wrong");
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };
  const finalData = {
    jobAssetId: editformData.jobAssetId,
    seeThroughOwnerInfoId: editformData.seeThroughOwnerInfoId,
    unsure: editformData.unsure,
    legalEntityTypeId: editformData.legalEntityTypeId,
    seeThroughOwnerGuid: editformData.seeThroughOwnerGuid,
    seeThroughOwnerComment: editformData.seeThroughOwnerComment,
    seeThroughOwnershipPercentage: editformData.seeThroughOwnershipPercentage,
    yearId: editformData.yearId,
    /* ownershipDate: editformData.ownershipDate
      ? editformData.ownershipDate
      : null, */
      ownershipYearId:editformData.ownershipYearId?editformData.ownershipYearId:0,
    seeThroughOwnershipComment: editformData.seeThroughOwnershipComment,
    stockExchangeId: editformData.stockExchangeId,
    stockExchangeComment: editformData.stockExchangeComment,
    editSeeThroughOwnerReportDetails: [
      ...(editformData.editSeeThroughOwnerReportDetails?.map((link) => ({
        ...link,
      })) || []),
      ...(editformData.editSeeThroughOwnershipReportDetails?.map((link) => ({
        ...link,
      })) || []),
      ...(editformData.editSeeThroughStockReportDetails?.map((link) => ({
        ...link,
      })) || []),
    ],

    /* 
    seeThroughReportDetails: [
      ...editformData?.ownerLinks?.map((link) => ({ ...link })),
      ...editformData?.ownershipPercentageLinks?.map((link) => ({
        ...link,
      })),
      ...editformData?.ownershipStockLinks?.map((link) => ({
        ...link,
      })),
    ], */
  };
  console.log("UOX",editformData,finalData)
  // Top 100 films
  const top100Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: "Pulp Fiction", year: 1994 },
  ];
  // Declaration of States and Component Variables End Here
  /*********API CALLS TO POPULATE DROPDOWN VALUES*********** */
  const GetOwnerDropdown = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetCorrectOwnerDropdown",
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setOwnerDropdown(res.data);
        console.log(res.data);
      });
  };
  const GetPopupCompanyDropdown = () => {
    axios
      .get(
        process.env.React_App_baseUrl +
          "DropDown/GetCompanyDropDownForCompanyStockExchange",
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setPopupDropdown(res.data);
        console.log(res.data);
      });
  };
  const GetLegalEntityGrid = () => {
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0
    ) {
      return;
    }
    axios
      .get(
        process.env.React_App_baseUrl +
          `ManageOwnership/GetLegalEntityTypes?jobAssetId=${props.jobAssetId}`,
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setLegalEntityDropdown(res.data);
        console.log(res.data);
      });
  };
  const GetSourceFileDropdown = () => {
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0
    ) {
      return;
    }
    axios
      .post(
        process.env.React_App_baseUrl + "ManageOwnership/GetSourceFileDropdown",
        null,
        {
          headers: {
            jobAssetId: props.jobAssetId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setSourceFileDropdown(res.data);
        console.log(res.data);
      });
  };
  const GetStockExchangeDropdown = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "stock/GetStockExchangeDropDown",

        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setStockExchangeDropdown(res.data);
        console.log(res.data);
      });
  };
  const getAddReportOwnershipYear = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "ManageOwnership/GetReportingYears",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )

      .then((res) => {
        if (res.status === 200) {
          //setAllYear(res.data);
          setAllYearOwnership(res.data)
          console.log("res.data",res.data)
        } else {
          toasterError("Something went wrong");
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };
  React.useEffect(() => {
    GetStockExchangeDropdown();
    GetSourceFileDropdown();
    GetOwnerDropdown();
    GetLegalEntityGrid();
    GetPopupCompanyDropdown();
    getAddReportYear();
    getAddReportOwnershipYear();
  }, [props]);
  React.useEffect(() => {
    if (ownerdropdown !== null && ownerdropdown !== undefined) {
      if (customeLegal) {
        if (customeLegal.ownerGuid !== "") {
          const preselectedlegal = ownerdropdown.find(
            (e) => e.guid === customeLegal.ownerGuid
          );

          console.log("CMN", preselectedlegal);
          setOwner(preselectedlegal);

          setEditFormData({
            ...editformData,
            companyName: preselectedlegal.companyName,
            seeThroughOwnerGuid: preselectedlegal.guid,
            // isCRUOwner: value.isCRUCompany,
          });
        }
      }
    }
  }, [ownerdropdown]);
  const columnsLegalEntity = [
    {
      id: "commonVariation",
      label: "Common Variation",
      minWidth: 250,
      width: "20%",
      align: "left",
    },
    {
      id: "cruFormat",
      label: "CRU Format",
      minWidth: 250,
      width: "20%",
      align: "left",
    },
    {
      id: "country",
      label: "Country",
      minWidth: 250,
      width: "20%",
      align: "left",
    },
    {
      id: "actions",
      label: "Action",
      minWidth: 90,
      width: "10%",
      align: "center",
    },
  ];
  /*   const handleAddSourceFile = (reportType) => {
    if (reportType === 6) {
      setSourceFileOwner((prevFiles) => {
        const updatedFiles = [...prevFiles, { sourceFile: null, pageNo: "" }];
        // Update editformData
        const updatedFormData = {
          ...editformData,
          editSeeThroughOwnerReportDetails: updatedFiles.map((file) => ({
            jointVentureReportMappingId: 0,
            jobAssetReportInfoId:
              file.sourceFile?.jobAssetReportInfoId || file.sourceFile?.id,
            reportName: file.sourceFile
              ? file.sourceFile.reportName
              : file.reportName,
            pageNumber: file.pageNo,
            reportType: reportType,
            isEdited: true,
            isDeleted: true,
          })),
        };
        setEditFormData(updatedFormData);
        console.log("UPD1", updatedFormData);
        return updatedFiles;
      });
    } else if (reportType === 7) {
      setSourceFileOwnership((prevFiles) => {
        const updatedFiles = [...prevFiles, { sourceFile: null, pageNo: "" }];

        // Update editformData
        const updatedFormData = {
          ...editformData,
          editSeeThroughOwnerReportDetails: updatedFiles.map((file) => ({
            jointVentureReportMappingId: 0,
            jobAssetReportInfoId:
              file.sourceFile?.jobAssetReportInfoId || file.sourceFile?.id,
            reportName: file.sourceFile
              ? file.sourceFile.reportName
              : file.reportName,
            pageNumber: file.pageNo,
            reportType: reportType,
            isEdited: true,
            isDeleted: true,
          })),
        };
        setEditFormData(updatedFormData);
        console.log("UPD2", updatedFormData);
        return updatedFiles;
      });
    } else {
      setSourceFileStock((prevFiles) => {
        const updatedFiles = [...prevFiles, { sourceFile: null, pageNo: "" }];

        // Update editformData
        const updatedFormData = {
          ...editformData,
          editSeeThroughOwnerReportDetails: updatedFiles.map((file) => ({
            jointVentureReportMappingId: 0,
            jobAssetReportInfoId:
              file.sourceFile?.jobAssetReportInfoId || file.sourceFile?.id,
            reportName: file.sourceFile
              ? file.sourceFile.reportName
              : file.reportName,
            pageNumber: file.pageNo,
            reportType: reportType,
            isEdited: true,
            isDeleted: true,
          })),
        };
        setEditFormData(updatedFormData);
        console.log("UPD3", updatedFormData);
        return updatedFiles;
      });
    }
    console.log("LOG", sourceFileOwner, sourceFileOwnership, sourceFileStock);
  }; */

  const handleAddSourceFile = (reportType) => {
    if (reportType === 1) {
      setSourceFileOwner((prevFiles) => {
        const updatedFileso = [...prevFiles, { sourceFile: null, pageNo: "" }];
        const updatedFormDataOwner = {
          ...editformData,
          editSeeThroughOwnerReportDetails: updatedFileso.map((file) => ({
            jointVentureReportMappingId: 0,
            jobAssetReportInfoId: file.sourceFile
              ? file.sourceFile.id || file.sourceFile.jobAssetReportInfoId
              : null,
            reportName: file.sourceFile?.reportName,
            pageNumber: file.pageNo,
            reportType: 1,
            isEdited: false,
            isDeleted: false,
          })),
        };
        setEditFormData((prevFormData) => ({
          ...prevFormData,
          editSeeThroughOwnerReportDetails:
            updatedFormDataOwner.editSeeThroughOwnerReportDetails,
          editSeeThroughOwnershipReportDetails:
            prevFormData.editSeeThroughOwnershipReportDetails.filter(
              (file) => file.reportType === 2
            ),
          editSeeThroughStockReportDetails:
            prevFormData.editSeeThroughStockReportDetails.filter(
              (file) => file.reportType === 3
            ),
        }));
        console.log("UPD1", updatedFormDataOwner);
        return updatedFileso;
      });
    } else if (reportType === 2) {
      setSourceFileOwnership((prevFiles) => {
        const updatedFilesp = [...prevFiles, { sourceFile: null, pageNo: "" }];
        const updatedFormDataOwnership = {
          ...editformData,
          editSeeThroughOwnershipReportDetails: updatedFilesp.map((file) => ({
            jointVentureReportMappingId: 0,
            jobAssetReportInfoId: file.sourceFile
              ? file.sourceFile.id || file.sourceFile.jobAssetReportInfoId
              : null,
            reportName: file.sourceFile
              ? file.sourceFile.reportName
              : file.reportName,
            pageNumber: file.pageNo,
            reportType: 2,
            isEdited: false,
            isDeleted: false,
          })),
        };

        setEditFormData((prevFormData) => ({
          ...prevFormData,
          editSeeThroughOwnershipReportDetails:
            updatedFormDataOwnership.editSeeThroughOwnershipReportDetails,
          editSeeThroughOwnerReportDetails:
            prevFormData.editSeeThroughOwnerReportDetails.filter(
              (file) => file.reportType !== 2 // Remove files with report type 7 from editSeeThroughOwnerReportDetails
            ),
          editSeeThroughStockReportDetails:
            prevFormData.editSeeThroughStockReportDetails.filter(
              (file) => file.reportType !== 2 // Remove files with report type 7 from editSeeThroughStockReportDetails
            ),
        }));

        console.log("UPD2", updatedFormDataOwnership);
        return updatedFilesp;
      });
    } else {
      setSourceFileStock((prevFiles) => {
        const updatedFiles = [...prevFiles, { sourceFile: null, pageNo: "" }];
        const updatedFormDataStock = {
          ...editformData,
          editSeeThroughStockReportDetails: updatedFiles.map((file) => ({
            jointVentureReportMappingId: 0,
            jobAssetReportInfoId: file.sourceFile
              ? file.sourceFile.id || file.sourceFile.jobAssetReportInfoId
              : null,
            reportName: file.sourceFile
              ? file.sourceFile.reportName
              : file.reportName,
            pageNumber: file.pageNo,
            reportType: reportType,
            isEdited: false,
            isDeleted: false,
          })),
        };
        setEditFormData((prevFormData) => ({
          ...prevFormData,
          editSeeThroughStockReportDetails:
            updatedFormDataStock.editSeeThroughStockReportDetails,
          editSeeThroughOwnerReportDetails:
            prevFormData.editSeeThroughOwnerReportDetails.filter(
              (file) => file.reportType === 1
            ),
          editSeeThroughOwnershipReportDetails:
            prevFormData.editSeeThroughOwnershipReportDetails.filter(
              (file) => file.reportType === 2
            ),
        }));
        console.log("UPD", updatedFormDataStock);
        return updatedFiles;
      });
    }
  };

  const handleDeleteSourceFile = (index, reportType, mappingId) => {
    if (reportType === 1) {
      setSourceFileOwner((prevFiles) => {
        const updatedFiles = prevFiles.map((file) => {
          if (
            file.sourceFile &&
            file.sourceFile.reportInfoMappingId === mappingId
          ) {
            return {
              jointVentureReportMappingId: file.sourceFile.reportInfoMappingId,
              jobAssetReportInfoId: file.sourceFile.jobAssetReportInfoId,
              reportName: file.sourceFile.reportName,
              pageNumber: Number(file.sourceFile.pageNo),
              reportType: 1,
              isEdited: false,
              isDeleted: true,
            };
          }
          return {
            jointVentureReportMappingId: file.sourceFile?.reportInfoMappingId,
            jobAssetReportInfoId: file.sourceFile?.jobAssetReportInfoId,
            reportName: file.sourceFile?.reportName,
            pageNumber: Number(file.sourceFile?.pageNo),
            reportType: 1,
            isEdited: false,
            isDeleted: false,
          };
        });

        const updatedFormDataOwner = {
          ...editformData,
          editSeeThroughOwnerReportDetails: updatedFiles,
        };

        setEditFormData(updatedFormDataOwner);
        console.log("DELETE", updatedFormDataOwner);

        const updatedSourceFile = [...sourceFileOwner];
        updatedSourceFile.splice(index, 1); // Remove the file from the UI
        setSourceFileOwner(updatedSourceFile);

        return updatedFiles;
      });
    } else if (reportType === 2) {
      setSourceFileOwnership((prevFiles) => {
        const updatedFiles = prevFiles.map((file) => {
          if (
            file.sourceFile &&
            file.sourceFile.reportInfoMappingId === mappingId
          ) {
            return {
              jointVentureReportMappingId: file.sourceFile.reportInfoMappingId,
              jobAssetReportInfoId: file.sourceFile.jobAssetReportInfoId,
              reportName: file.sourceFile.reportName,
              pageNumber: Number(file.sourceFile.pageNo),
              reportType: 2,
              isEdited: false,
              isDeleted: true,
            };
          }
          return {
            jointVentureReportMappingId: file.sourceFile?.reportInfoMappingId,
            jobAssetReportInfoId: file.sourceFile?.jobAssetReportInfoId,
            reportName: file.sourceFile?.reportName,
            pageNumber: Number(file.sourceFile?.pageNo),
            reportType: 2,
            isEdited: false,
            isDeleted: false,
          };
        });

        const updatedFormDataOwnership = {
          ...editformData,
          editSeeThroughOwnershipReportDetails: updatedFiles,
        };

        setEditFormData(updatedFormDataOwnership);
        console.log("DELETE", updatedFormDataOwnership);

        const updatedSourceFile = [...sourceFileOwnership];
        updatedSourceFile.splice(index, 1); // Remove the file from the UI
        setSourceFileOwnership(updatedSourceFile);

        return updatedFiles;
      });
    } else {
      setSourceFileStock((prevFiles) => {
        const updatedFiles = prevFiles.map((file) => {
          if (
            file.sourceFile &&
            file.sourceFile.reportInfoMappingId === mappingId
          ) {
            return {
              jointVentureReportMappingId: file.sourceFile.reportInfoMappingId,
              jobAssetReportInfoId: file.sourceFile.jobAssetReportInfoId,
              reportName: file.sourceFile.reportName,
              pageNumber: Number(file.sourceFile.pageNo),
              reportType: 3,
              isEdited: false,
              isDeleted: true,
            };
          }
          return {
            jointVentureReportMappingId: file.sourceFile?.reportInfoMappingId,
            jobAssetReportInfoId: file.sourceFile?.jobAssetReportInfoId,
            reportName: file.sourceFile?.reportName,
            pageNumber: Number(file.sourceFile?.pageNo),
            reportType: 3,
            isEdited: false,
            isDeleted: false,
          };
        });

        const updatedFormDatastock = {
          ...editformData,
          editSeeThroughStockReportDetails: updatedFiles,
        };

        setEditFormData(updatedFormDatastock);
        console.log("DELETE", updatedFormDatastock);

        const updatedSourceFile = [...sourceFileStock];
        updatedSourceFile.splice(index, 1); // Remove the file from the UI
        setSourceFileStock(updatedSourceFile);

        return updatedFiles;
      });
    }
  };

  React.useEffect(() => {
    console.log("LOG", editformData);
    console.log("Final", finalData);
  }, [editformData]);
  /*   const handleDeleteSourceFile = (index) => {
    setSourceFileOwner((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);

      // Update editformData
      const updatedFormData = {
        ...editformData,
        editSeeThroughOwnerReportDetails: updatedFiles.map((file) => ({
          jointVentureReportMappingId: file.reportInfoMappingId,
          jobAssetReportInfoId: file.jobAssetReportInfoId,
          reportName: file.reportName,
          pageNumber: file.pageNo,
          reportType: file.reportType,
          isEdited: true,
          isDeleted: true,
        })),
      };
      setEditFormData(updatedFormData);

      return updatedFiles;
    });
  }; */

  const EditSeethroughownerData = async () => {
    setLoading(true);
    // Perform validation checks if `finalData.unsure` is not true
    if (finalData.unsure === false) {
      console.log("finalData:", finalData);
      if (
        finalData.seeThroughOwnerGuid &&
        finalData.stockExchangeId !== 0 &&
        finalData.seeThroughOwnershipPercentage !== 0 &&
        finalData.ownershipYearId !== 0
      ) {
        // Validate the page number if source files are selected
        if (
          finalData.seeThroughReportDetails &&
          finalData.seeThroughReportDetails.length > 0
        ) {
          for (let i = 0; i < finalData.seeThroughReportDetails.length; i++) {
            const file = finalData.seeThroughReportDetails[i];
            if (file.pageNo === 0) {
              toasterError(
                `Please enter a valid page number for source file ${i + 1}`
              );
              setLoading(false);
              return;
            }
          }
        } else if (finalData.sourceFile && finalData.sourceFile[0]) {
          // Check if source file is selected and page number is provided (if selected)
          if (!finalData.sourceFile[0].pageNo) {
            toasterError(
              "Please enter the page number for the selected source file"
            );
            setLoading(false);
            return;
          }
        }
      } else {
        // Display error message for missing fields
        toasterError("Please fill all the required fields");
        setLoading(false);
        return;
      }
    }

    var tempdata = {
      jobAssetId: finalData.jobAssetId,
      jobAssetJointVentureId: finalData.seeThroughOwnerInfoId,
      unsure: finalData.unsure,
      legalEntityTypeId: finalData.legalEntityTypeId
        ? finalData.legalEntityTypeId
        : 0,
      ownerGUID: finalData.seeThroughOwnerGuid,
      ownerComment: finalData.seeThroughOwnerComment,
      yearId: finalData.yearId,
      ownershipPercentage: finalData.seeThroughOwnershipPercentage
        ? Number(finalData.seeThroughOwnershipPercentage)
        : 0,
      //ownershipDate: finalData.ownershipDate,
      ownershipYearId:finalData.ownershipYearId?finalData.ownershipYearId:0,
      ownershipComment: finalData.seeThroughOwnershipComment,
      stockExchangeId: finalData.stockExchangeId,
      stockExchangeComment: finalData.stockExchangeComment,
      editJointVentureReportDetails: finalData.editSeeThroughOwnerReportDetails,
    };
    axios
      .post(
        process.env.React_App_baseUrl +
          "ManageOwnership/EditJointVentureDetails",
        tempdata,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then(function (response) {
        // Update state and display success message
        props.setRefresh(!props.refresh);
        toasterSuccess("Join venture updated successfully");
        setSuccessMessage("Join venture updated successfully");
        setLoading(false);
        props.hideSideDrawer();
        //setEditData(false);
      })
      .catch(function (error) {
        console.log(error);
        ErroHandling(error, function (res) {
          console.log(res);
          toasterError(res);
        });
        setLoading(false);
      });
  };
  const handleAddCustomeOwner = () => {
    console.log(customeOwner);
    if (customeOwner.correctOwnerName.length > 0) {
      setLoading(true);

      ApiService.create("ManageOwnership/SaveCorrectOwnerName", customeOwner)
        .then((res) => {
          setLoading(false);
          GetOwnerDropdown();
          if (res.status === 200) {
            toasterSuccess("Owner added successfully ");
          } else {
            toasterError("Something went wrong");
          }
          setAddCustomeOwner(!addCustomeOwner);
        })
        .catch((err) => {
          setLoading(false);
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        });
    } else {
      toasterError("Please fill all the required fields");
    }
  };
  const handleCloseOwner = () => {
    setPopdata(previousPopdata);
    setAddCustomeOwner(!addCustomeOwner);
  };
  const handleCloseLegal = () => {
    setAddLegalEntity(!addLegalEntity);
  };
  const handleAddCustomeLegalCheck = (id) => {
    setLoading(true);
    customeLegal.legalEntityTypeId = id;
    console.log("LEGAL", customeLegal);

    ApiService.create("ManageOwnership/CheckCommonVariation", customeLegal)
      .then((res) => {
        setLoading(false);
        GetOwnerDropdown();
        if (res.status === 200) {
          if (res.data === true) {
            handleAddCustomeLegal(id);
          } else {
            toggleDrawerLegal(true, "Legal")(id);
          }
        } else {
          toasterError("Something went wrong");
        }
        setAddLegalEntity(!addLegalEntity);
      })
      .catch((err) => {
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };
  const toggleDrawerLegal = (open, mode) => (event, item, id) => {
    if (mode === "Legal") {
      console.log("MOD", id);
      setOpenLegal(true);
    }
  };
  const handleAddCustomeLegal = (id) => {
    setLoading(true);
    customeLegal.legalEntityTypeId = id;
    console.log("LEGAL", customeLegal);
    ApiService.create("ManageOwnership/SaveCorrectOwnerName", customeLegal)
      .then((res) => {
        setLoading(false);
        GetOwnerDropdown();
        if (res.status === 200) {
          toasterSuccess("Owner added successfully ");
        } else {
          toasterError("Something went wrong");
        }
        setAddLegalEntity(!addLegalEntity);
      })
      .catch((err) => {
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };
  const handleClose = () => {
    setAddCustomeStock(!addCustomeStock);
  };
  const handleaddCustomeStock = () => {
    if (customeStock.name.length > 0) {
      setLoading(true);
      ApiService.create("stock/SaveStockExchange", customeStock)
        .then((res) => {
          setLoading(false);
          GetStockExchangeDropdown();
          if (res.status === 200) {
            toasterSuccess("Owner added successfully ");
          } else {
            toasterError("Something went wrong");
          }
          setAddCustomeStock(!addCustomeStock);
        })
        .catch((err) => {
          setLoading(false);
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        });
    } else {
      toasterError("Please enter the stock exchange name and symbol");
    }
  };
  React.useEffect(() => {
    const customcompany = popupDropdown.find(
      (e) => e.guid === props.companyGuid
    );
    setPopdata(customcompany);

    console.log("CC", customcompany);
  }, [popupDropdown, props.companyGuid]);

  React.useEffect(() => {
    if (sourceFileOwner.length === 0) {
      setSourceFileOwner([
        {
          jointVentureReportMappingId: 0,
          jobAssetReportInfoId: 0,
          reportName: "",
          pageNumber: 0,
          reportType: 0,
          isEdited: false,
          isDeleted: false,
        },
      ]);
    }
    if (sourceFileOwnership.length === 0) {
      setSourceFileOwnership([
        {
          jointVentureReportMappingId: 0,
          jobAssetReportInfoId: 0,
          reportName: "",
          pageNumber: 0,
          reportType: 0,
          isEdited: false,
          isDeleted: false,
        },
      ]);
    }
    if (sourceFileStock.length === 0) {
      setSourceFileStock([
        {
          jointVentureReportMappingId: 0,
          jobAssetReportInfoId: 0,
          reportName: "",
          pageNumber: 0,
          reportType: 0,
          isEdited: false,
          isDeleted: false,
        },
      ]);
    }
  }, [
    sourceFileOwner.length,
    sourceFileOwnership.length,
    sourceFileStock.length,
  ]);
  const openDocument = (file, pageNumber) => {
    if (!pageNumber) {
      pageNumber = 1;
    }
    //var jobdetails = tempData?.state;
    var reportName = file + ".pdf";
    var senData = {
      jobId: props.jobId,
      fileName: reportName,
      pageNumber: Number(pageNumber),
      crudState: "Edit",
    };
    localStorage.setItem("filePreview", JSON.stringify(senData));
   // CommonService.AddTokenFromSessionStorageToLocalStorage();
    window.open(
      "#app/ownership-changes/file-preview",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Joint Venture
        </Typography>
      </Box>

      {/* Add & Edit Commodity Form */}
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ mt: -1 }}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={0}
          >
            <Grid item xs={6} className={classes.removeCheckMargin}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editformData.unsure !== undefined ? editformData.unsure : false}
                    onChange={(e) =>
                      setEditFormData({
                        ...editformData,
                        unsure: e.target.checked,
                      })
                    }
                  />
                }
                label="Unsure"
              />
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Button
                variant="contained"
                color="accent7"
                size="small"
                startIcon={<AddIcon style={{ fontSize: 16 }} />}
                onClick={toggleDrawer(true, "Add")}
              >
                Add report info
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: -2 }}>
          <Box className={classes.jobShortDetails}>
            <Grid
              container
              alignItems="flex-end"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  value={defaultYearValue}
                  id="combo-box-demo"
                  options={allYear}
                  getOptionLabel={(option) =>
                    option.year != undefined ? option.year : ""
                  }
                  onChange={(e, value) => {
                    if (value != null) {
                      allYear.forEach((rData, i) => {
                        if (rData.id === value.id) {
                          setDefaultYearValue(allYear[i]);
                          console.log(allYear[i]);
                          setEditFormData({
                            ...editformData,
                            yearId: allYear[i].id,
                          });
                        }
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Year"
                      required={finalData.unsure === false}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: -1 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    alignItems="self-end"
                  >
                    <Grid item xs>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={ownerdropdown}
                        value={Owner}
                        getOptionLabel={(option) => option.companyName}
                        onChange={(e, value) => {
                          console.log(value, "Data checker");
                          if (value.companyName === "Others") {
                            //setPreviousPopdata(popdata);
                            setCustomeOwner({
                              ownerGuid: props.companyGuid,
                              correctOwnerName: "",
                              legalEntityTypeId: 0,
                            });
                            // setAddCustomeOwner(!addCustomeOwner);
                            setEditFormData({
                              ...editformData,
                              isNewOwner: true,
                            });
                          } else if (value.companyName !== "Others") {
                            console.log(value.companyName);
                            setEditFormData({
                              ...editformData,
                              companyName: value.companyName,
                              seeThroughOwnerGuid: value.guid,
                              // isCRUOwner: value.isCRUCompany,
                            });
                            setOwner({
                              ...Owner,
                              companyName: value.companyName,
                              seeThroughOwnerGuid: value.guid,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required={finalData.unsure === false}
                            label="Correct Owner Name"
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                    {newEntry && (
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          size="small"
                          id="size-small-standard"
                          label="New Owner"
                          variant="standard"
                          onChange={(e) => {
                            setEditFormData({
                              ...editformData,
                              ownerName: e.target.value,
                              isCRUOwner: false,
                            });
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs="auto">
                      <Tooltip title="Legal Entity" placement="top" arrow>
                        <IconButton
                          color="accent7"
                          onClick={(e, value) => {
                            if (Owner && Owner.guid) {
                              setCustomLegal({
                                ownerGuid: Owner.guid,
                                correctOwnerName: "",
                                legalEntityTypeId:
                                  customeLegal.legalEntityTypeId,
                              });

                              setAddLegalEntity(true);
                            }
                          }}
                        >
                          <TaskAltIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Comments "
                  variant="standard"
                  value={editformData.seeThroughOwnerComment}
                  onChange={(e) => {
                    // props.setPreFillData({
                    //   ...props.prefillData,
                    //   seeThroughOwnerComment: e.target.value,
                    // });
                    setEditFormData({
                      ...editformData,
                      seeThroughOwnerComment: e.target.value,
                    });
                  }}
                  multiline
                  minRows={3}
                  maxRows={3}
                />
              </Grid>

              {sourceFileOwner.map((file, index) => (
                <>
                  <Grid item xs={12}>
                    <Box sx={{ flexGrow: 1 }} key={index}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        alignItems="self-end"
                      >
                        <Grid item xs>
                          <Autocomplete
                            disablePortal
                            id={`source-file-${index}`}
                            options={sourcefiledropdown}
                            getOptionLabel={(option) => option.reportName}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Source File"
                                variant="standard"
                              />
                            )}
                            value={file.sourceFile || null}
                            onChange={(event, newValue) => {
                              const updatedFiles = [...sourceFileOwner];
                              updatedFiles[index] = {
                                ...file,
                                sourceFile: {
                                  ...file.sourceFile,
                                  ...newValue,
                                },
                              };
                              console.log("Updatedvalues", [
                                ...sourceFileOwner,
                              ]);
                              setSourceFileOwner(updatedFiles);
                              const updatedFormData = {
                                ...editformData,
                                editSeeThroughOwnerReportDetails:
                                  updatedFiles.map((file) => ({
                                    jointVentureReportMappingId: file.sourceFile
                                      ?.reportInfoMappingId
                                      ? file.sourceFile?.reportInfoMappingId
                                      : 0 || file.jointVentureReportMappingId,
                                    jobAssetReportInfoId: file.sourceFile
                                      ? file.sourceFile?.id ||
                                        file.sourceFile?.jobAssetReportInfoId
                                      : null,
                                    reportName: file.sourceFile?.reportName,
                                    pageNumber: Number(file?.pageNo),
                                    reportType: 1,
                                    isEdited:
                                      file.sourceFile !== null &&
                                      file.sourceFile.reportInfoMappingId !== 0,
                                    isDeleted: false,
                                  })),
                              };
                              setEditFormData(updatedFormData);
                              console.log(
                                "File",
                                updatedFormData,
                                editformData
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs="auto">
                          <Tooltip title="Open Document" placement="top" arrow>
                            <IconButton
                              color="accent6"
                              aria-label="add to shopping cart"
                              disabled={!file.sourceFile} // Disable the icon when no source file is selected
                              onClick={() => {
                                openDocument(
                                  file.sourceFile.reportName,
                                  file.sourceFile.pageNo
                                );
                              }}
                            >
                              <TextDocumentIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ ml: 1 }}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="self-start"
                        alignItems="self-end"
                      >
                        <TextField
                          id={`page-no-${index}`}
                          type="number"
                          label="Page No"
                          fullWidth
                          //required={sourceFileOwner.length > 0}
                          variant="standard"
                          onChange={(event) => {
                            const updatedFiles = [...sourceFileOwner];
                            updatedFiles[index] = {
                              ...updatedFiles[index],
                              pageNo: event.target.value,
                            };
                            setSourceFileOwner(updatedFiles);
                            const updatedFormData = {
                              ...editformData,
                              editSeeThroughOwnerReportDetails:
                                updatedFiles.map((file) => ({
                                  jointVentureReportMappingId: file.sourceFile
                                    ?.reportInfoMappingId
                                    ? file.sourceFile?.reportInfoMappingId
                                    : 0,
                                  jobAssetReportInfoId: file.sourceFile
                                    ? file.sourceFile.id ||
                                      file.sourceFile?.jobAssetReportInfoId
                                    : null,
                                  reportName: file.sourceFile?.reportName,
                                  pageNumber: Number(file.pageNo),
                                  reportType: 1,
                                  isEdited:
                                    file.sourceFile !== null &&
                                    file.sourceFile.reportInfoMappingId !== 0,
                                  isDeleted: false,
                                })),
                            };
                            setEditFormData(updatedFormData);
                            console.log("ON", updatedFormData);
                          }}
                          value={file.pageNo || ""}
                          sx={{ width: 100 }}
                        />
                        {index === 0 && (
                          <Grid item xs="auto">
                            <Tooltip title="Delete" placement="top" arrow>
                              <IconButton
                                color="error"
                                onClick={(e) => {
                                  console.log(
                                    "MAP",
                                    file.sourceFile?.reportInfoMappingId
                                  );
                                  handleDeleteSourceFile(
                                    index,
                                    1,
                                    file.sourceFile?.reportInfoMappingId
                                  );
                                }}
                              >
                                <DeleteIcon style={{ fontSize: "20px" }} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                        {index === 0 && (
                          <Grid item xs="auto">
                            <Tooltip title="Add" placement="top" arrow>
                              <IconButton
                                color="secondary"
                                style={{ fontSize: "20px" }}
                                onClick={(e) => {
                                  handleAddSourceFile(1);
                                }}
                              >
                                <CirclePlusIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                        {index > 0 && (
                          <Grid item xs="auto">
                            <Tooltip title="Delete" placement="top" arrow>
                              <IconButton
                                color="error"
                                onClick={(e) => {
                                  console.log(
                                    "MAP",
                                    file.sourceFile?.jointVentureReportMappingId
                                  );
                                  handleDeleteSourceFile(
                                    index,
                                    1,
                                    file.sourceFile?.reportInfoMappingId
                                  );
                                }}
                              >
                                <DeleteIcon style={{ fontSize: "20px" }} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mt: -4 }}>
          <Box className={classes.jobShortDetails}>
            <Grid
              container
              alignItems="flex-end"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  id="standard-basic"
                  label="Ownership Percentage(%)"
                  variant="standard"
                  required={finalData.unsure === false}
                  value={editformData.seeThroughOwnershipPercentage}
                  onChange={(e) => {
                    // props.setPreFillData({
                    //   ...props.prefillData,
                    //   seeThroughOwnershipPercentage: e.target.value,
                    // });
                    setEditFormData({
                      ...editformData,
                      seeThroughOwnershipPercentage: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/*  <TextField
                  fullWidth
                  id="standard-basic"
                  label="Ownership Date"
                  value={props?.prefillData?.ownershipDate}
                  onChange={(e) => {
                    props.setPreFillData({
                      ...props.prefillData,
                      ownershipDate: e.target.value,
                    });
                    setEditFormData({
                      ...editformData,
                      ownershipDate: e.target.value,
                    });
                  }}
                  variant="standard"
                /> */}

               {/*  <DatePicker
                  labelTitle="Ownership Date"
                  variant="standard"
                  defaultDate={props?.prefillData?.ownershipDate}
                  required={finalData.unsure === false}
                  onChangeDate={(e) => {
                    let selectedDate = new Date(e);
                    let date =
                      selectedDate.getFullYear() +
                      "-" +
                      ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                      "-" +
                      ("0" + selectedDate.getDate()).slice(-2);
                    props.setPreFillData({
                      ...props.prefillData,
                      ownershipDate: dayjs(date, "YYYY-MM-DD").format(
                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                      ),
                    });
                    setEditFormData({
                      ...editformData,
                      ownershipDate: dayjs(date, "YYYY-MM-DD").format(
                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                      ),
                    });
                    console.log("DATE", {
                      ...editformData,
                      seeThroughOwnershipDate: dayjs(date, "YYYY-MM-DD").format(
                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                      ),
                    });
                  }}
                  past={false}
                  //key={keyFromoDate}
                  clearable={true}
                /> */}
                 <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={allYearOwnership}
                              getOptionLabel={(option) => option.year}
                              value={preselectedYear}
                              onChange={(event, value) => {  
                                console.log("valb",value)
                               /*  if (value) {
                                  setEditFormData({
                                    ...editformData,
                                    ownershipYearId:
                                      value && value.id ? value.id : null,
                                  });
                                  setPreselectedYear(value);                                
                                } */
                                if (value != null) {
                                  allYearOwnership.forEach((rData, i) => {
                                    if (rData.id === value.id) {
                                      setPreselectedYear(allYearOwnership[i]);
                                      console.log(allYearOwnership[i]);
                                      setEditFormData({
                                        ...editformData,
                                        ownershipYearId: allYearOwnership[i].id,
                                      });
                                    }
                                })}
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Ownership Date"
                                  variant="standard"
                                  required={finalData.unsure === false}
                                />
                              )}
                            />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Comments"
                  variant="standard"
                  value={editformData.seeThroughOwnershipComment}
                  onChange={(e) => {
                    // props.setPreFillData({
                    //   ...props.prefillData,
                    //   seeThroughOwnershipComment: e.target.value,
                    // });

                    setEditFormData({
                      ...editformData,
                      seeThroughOwnershipComment: e.target.value,
                    });
                  }}
                  multiline
                  minRows={3}
                  maxRows={3}
                />
              </Grid>
              {sourceFileOwnership.map((file, index) => (
                <>
                  <Grid item xs={12}>
                    <Box sx={{ flexGrow: 1 }} key={index}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        alignItems="self-end"
                      >
                        <Grid item xs>
                          <Autocomplete
                            disablePortal
                            id={`source-file-${index}`}
                            options={sourcefiledropdown}
                            getOptionLabel={(option) => option.reportName}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Source File"
                                variant="standard"
                              />
                            )}
                            value={file.sourceFile || null}
                            onChange={(event, newValue) => {
                              const updatedFiles = [...sourceFileOwnership];
                              updatedFiles[index] = {
                                ...file,
                                sourceFile: {
                                  ...file.sourceFile,
                                  ...newValue,
                                },
                              };
                              setSourceFileOwnership(updatedFiles);
                              const updatedFormData = {
                                ...editformData,
                                editSeeThroughOwnershipReportDetails:
                                  updatedFiles.map((file) => ({
                                    jointVentureReportMappingId: file.sourceFile
                                      ?.reportInfoMappingId
                                      ? file.sourceFile?.reportInfoMappingId
                                      : 0,
                                    jobAssetReportInfoId: file.sourceFile
                                      ? file.sourceFile?.id ||
                                        file.sourceFile?.jobAssetReportInfoId
                                      : null,
                                    reportName: file.sourceFile?.reportName,
                                    pageNumber: Number(file?.pageNo),
                                    reportType: 2,
                                    isEdited:
                                      file.sourceFile !== null &&
                                      file.sourceFile.reportInfoMappingId !== 0,
                                    isDeleted: false,
                                  })),
                              };
                              setEditFormData(updatedFormData);
                            }}
                          />
                        </Grid>
                        <Grid item xs="auto">
                          <Tooltip title="Open Document" placement="top" arrow>
                            <IconButton
                              color="accent6"
                              aria-label="add to shopping cart"
                              disabled={!file.sourceFile} // Disable the icon when no source file is selected
                              onClick={() => {
                                openDocument(
                                  file.sourceFile.reportName,
                                  file.sourceFile.pageNo
                                );
                              }}
                            >
                              <TextDocumentIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ ml: 1 }}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="self-start"
                        alignItems="self-end"
                      >
                        <TextField
                          id={`page-no-${index}`}
                          type="number"
                          label="Page No"
                          fullWidth
                          //required={sourceFileOwnership.length > 0}
                          variant="standard"
                          onChange={(event) => {
                            const updatedFiles = [...sourceFileOwnership];
                            updatedFiles[index] = {
                              ...updatedFiles[index],
                              pageNo: event.target.value,
                            };
                            setSourceFileOwnership(updatedFiles);
                            const updatedFormData = {
                              ...editformData,
                              editSeeThroughOwnershipReportDetails:
                                updatedFiles.map((file) => ({
                                  jointVentureReportMappingId: file.sourceFile
                                    ?.reportInfoMappingId
                                    ? file.sourceFile?.reportInfoMappingId
                                    : 0,
                                  jobAssetReportInfoId: file.sourceFile
                                    ? file.sourceFile?.id ||
                                      file.sourceFile?.jobAssetReportInfoId
                                    : null,
                                  reportName: file.sourceFile?.reportName,
                                  pageNumber: Number(file?.pageNo),
                                  reportType: 2,
                                  isEdited:
                                    file.sourceFile !== null &&
                                    file.sourceFile.reportInfoMappingId !== 0,
                                  isDeleted: false,
                                })),
                            };
                            setEditFormData(updatedFormData);
                            console.log("ON", updatedFormData);
                          }}
                          value={file.pageNo || ""}
                          sx={{ width: 100 }}
                        />
                        {index === 0 && (
                          <Grid item xs="auto">
                            <Tooltip title="Delete" placement="top" arrow>
                              <IconButton
                                color="error"
                                onClick={(e) => {
                                  handleDeleteSourceFile(
                                    index,
                                    2,
                                    file.sourceFile?.reportInfoMappingId
                                  );
                                }}
                              >
                                <DeleteIcon style={{ fontSize: "20px" }} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                        {index === 0 && (
                          <Grid item xs="auto">
                            <Tooltip title="Add" placement="top" arrow>
                              <IconButton
                                color="secondary"
                                style={{ fontSize: "20px" }}
                                onClick={(e) => {
                                  handleAddSourceFile(2);
                                }}
                              >
                                <CirclePlusIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                        {index > 0 && (
                          <Grid item xs="auto">
                            <Tooltip title="Delete" placement="top" arrow>
                              <IconButton
                                color="error"
                                onClick={(e) => {
                                  handleDeleteSourceFile(
                                    index,
                                    2,
                                    file.sourceFile?.reportInfoMappingId
                                  );
                                }}
                              >
                                <DeleteIcon style={{ fontSize: "20px" }} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mt: -4 }}>
          <Box className={classes.jobShortDetails}>
            <Grid
              container
              alignItems="flex-end"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item xs={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    alignItems="self-end"
                  >
                    <Grid item xs>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={stockdropdown}
                        getOptionLabel={(option) => option.name}
                        value={Stock}
                        onChange={(e, value) => {
                          setEditFormData({
                            ...editformData,
                            stockExchangeId: value.id,
                          });
                          setStock({
                            ...Stock,
                            name: value.name,
                            stockExchangeId: value.guid,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required={finalData.unsure === false}
                            label="Stock Exchange with Largest market cap"
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Add Stock Exchange" placement="top" arrow>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            setAddCustomeStock(true);
                          }}
                        >
                          <CirclePlusIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Comments "
                  variant="standard"
                  value={editformData.stockExchangeComment}
                  onChange={(e) => {
                    // props.setPreFillData({
                    //   ...props.prefillData,
                    //   stockExchangeComment: e.target.value,
                    // });
                    setEditFormData({
                      ...editformData,
                      stockExchangeComment: e.target.value,
                    });
                  }}
                  multiline
                  minRows={3}
                  maxRows={3}
                />
              </Grid>
              {/*    [...sourceFileStock, { sourceFile: null, pageNo: "" }] */}
              {sourceFileStock.map((file, index) => (
                <>
                  <Grid item xs={12}>
                    <Box sx={{ flexGrow: 1 }} key={index}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        alignItems="self-end"
                      >
                        <Grid item xs>
                          <Autocomplete
                            disablePortal
                            id={`source-file-${index}`}
                            options={sourcefiledropdown}
                            getOptionLabel={(option) => option.reportName}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Source File"
                                variant="standard"
                              />
                            )}
                            value={file.sourceFile || null}
                            onChange={(event, newValue) => {
                              const updatedFiles = [...sourceFileStock];
                              updatedFiles[index] = {
                                ...file,
                                sourceFile: {
                                  ...file.sourceFile,
                                  ...newValue,
                                },
                              };
                              setSourceFileStock(updatedFiles);
                              const updatedFormData = {
                                ...editformData,
                                editSeeThroughStockReportDetails:
                                  updatedFiles.map((file) => ({
                                    jointVentureReportMappingId: file.sourceFile
                                      ?.reportInfoMappingId
                                      ? file.sourceFile?.reportInfoMappingId
                                      : 0,
                                    jobAssetReportInfoId: file.sourceFile
                                      ? file.sourceFile.id ||
                                        file.sourceFile?.jobAssetReportInfoId
                                      : null,
                                    reportName: file.sourceFile?.reportName,
                                    pageNumber: Number(file?.pageNo),
                                    reportType: 3,
                                    isEdited:
                                      file.sourceFile !== null &&
                                      file.sourceFile.reportInfoMappingId !== 0,
                                    isDeleted: false,
                                  })),
                              };
                              setEditFormData(updatedFormData);
                            }}
                          />
                        </Grid>
                        <Grid item xs="auto">
                          <Tooltip title="Open Document" placement="top" arrow>
                            <IconButton
                              color="accent6"
                              aria-label="add to shopping cart"
                              disabled={!file.sourceFile} // Disable the icon when no source file is selected
                              onClick={() => {
                                openDocument(
                                  file.sourceFile.reportName,
                                  file.sourceFile.pageNo
                                );
                              }}
                            >
                              <TextDocumentIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ ml: 1 }}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="self-start"
                        alignItems="self-end"
                      >
                        <TextField
                          id={`page-no-${index}`}
                          type="number"
                          label="Page No"
                          fullWidth
                          //required={sourceFileStock.length > 0}
                          variant="standard"
                          onChange={(event) => {
                            const updatedFiles = [...sourceFileStock];
                            updatedFiles[index] = {
                              ...updatedFiles[index],
                              pageNo: event.target.value,
                            };
                            setSourceFileStock(updatedFiles);
                            const updatedFormData = {
                              ...editformData,
                              editSeeThroughStockReportDetails:
                                updatedFiles.map((file) => ({
                                  jointVentureReportMappingId: file.sourceFile
                                    ?.reportInfoMappingId
                                    ? file.sourceFile?.reportInfoMappingId
                                    : 0,
                                  jobAssetReportInfoId: file.sourceFile
                                    ? file.sourceFile?.id ||
                                      file.sourceFile?.jobAssetReportInfoId
                                    : null,
                                  reportName: file.sourceFile?.reportName,
                                  pageNumber: Number(file?.pageNo),
                                  reportType: 3,
                                  isEdited:
                                    file.sourceFile !== null &&
                                    file.sourceFile.reportInfoMappingId !== 0,
                                  isDeleted: false,
                                })),
                            };
                            setEditFormData(updatedFormData);
                            console.log("ON", updatedFormData);
                          }}
                          value={file.pageNo || ""}
                          sx={{ width: 100 }}
                        />
                        {index === 0 && (
                          <Grid item xs="auto">
                            <Tooltip title="Delete" placement="top" arrow>
                              <IconButton
                                color="error"
                                onClick={(e) => {
                                  handleDeleteSourceFile(
                                    index,
                                    3,
                                    file.sourceFile?.reportInfoMappingId
                                  );
                                }}
                              >
                                <DeleteIcon style={{ fontSize: "20px" }} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                        {index === 0 && (
                          <Grid item xs="auto">
                            <Tooltip title="Add" placement="top" arrow>
                              <IconButton
                                color="secondary"
                                style={{ fontSize: "20px" }}
                                onClick={(e) => {
                                  handleAddSourceFile(3);
                                }}
                              >
                                <CirclePlusIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                        {index > 0 && (
                          <Grid item xs="auto">
                            <Tooltip title="Delete" placement="top" arrow>
                              <IconButton
                                color="error"
                                onClick={(e) => {
                                  handleDeleteSourceFile(
                                    index,
                                    3,
                                    file.sourceFile?.reportInfoMappingId
                                  );
                                }}
                              >
                                <DeleteIcon style={{ fontSize: "20px" }} />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mt: -3 }}>
          {props.crudState === "View" ? null : (
            <Grid item xs={12} sx={{ mt: -1 }}>
              <Grid container spacing={2} justifyContent="left">
                <Grid item xs={"auto"}>
                  <LoadingButton
                    buttonName={props.crudState === "Add" ? "Save" : "Update"}
                    clickHandler={
                      props.crudState === "Add" ? "" : EditSeethroughownerData
                    } //addeditformData
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <Button
                    variant="contained"
                    color="accent3"
                    onClick={() =>
                      props.setState({ ...props.state, right: false })
                    }
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* POPUP TO ADD NEW OWNER */}
          {addCustomeOwner ? (
            <Dialog open={true} fullWidth onClose={handleCloseOwner}>
              <DialogTitle>Add New Owner</DialogTitle>
              <DialogContent>
                <Autocomplete
                  disablePortal
                  options={popupDropdown}
                  value={popdata}
                  getOptionLabel={(option) => option.companyName}
                  onChange={(e, value) => {
                    setPopdata(value);
                    if (value) {
                      let data = {
                        ownerGuid: value.guid,
                        correctOwnerName: customeOwner.correctOwnerName,
                        legalEntityTypeId: 0,
                      };

                      setCustomeOwner(data);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Owner Name"
                      variant="standard"
                    />
                  )}
                />
                <TextField
                  margin="dense"
                  id="correctOwnerName"
                  name="correctOwnerName"
                  label="Owner"
                  type="email"
                  fullWidth
                  required
                  variant="standard"
                  onChange={(event) => {
                    console.log(customeOwner);
                    let data = { ...customeOwner };
                    data[event.target.name] = event.target.value;
                    setCustomeOwner(data);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseOwner}>Cancel</Button>
                {/*  {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Button onClick={handleAddCustomeOwner}>Add</Button>
            )} */}
                <Button onClick={handleAddCustomeOwner}>Add</Button>
              </DialogActions>
            </Dialog>
          ) : null}
          {addLegalEntity ? (
            <Dialog
              open={true}
              fullWidth
              onClose={handleCloseLegal}
              maxWidth={"lg"}
            >
              <DialogTitle>Legal Entity Type</DialogTitle>
              <DialogContent sx={{ pb: 0 }}>
                <Grid item xs={12}>
                  <Box className={classes.setBorder}>
                    <TableContainer sx={{ maxHeight: "calc(100vh - 203px)" }}>
                      <Table
                        stickyHeader
                        // className={classes.table}
                        className={classes.historyTable}
                      >
                        <TableHead>
                          <TableRow hover>
                            {columnsLegalEntity.map((el) => {
                              console.log("Table", el);
                              return (
                                <TableCell
                                  className={
                                    classes.tableHeader &&
                                    classes.cruTheadLeftSep
                                  }
                                  style={{
                                    minWidth: "30px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {el.label}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        {legalentitydropdown.length > 0 ? (
                          <TableBody>
                            {legalentitydropdown.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={
                                    classes.tableCell &&
                                    classes.tableCellLeftSep
                                  }
                                  style={{ minWidth: "200px" }}
                                >
                                  {row.commonVariation}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={
                                    classes.tableCell &&
                                    classes.tableCellLeftSep
                                  }
                                  style={{ minWidth: "150px" }}
                                >
                                  {row.cruFormat}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={
                                    classes.tableCell &&
                                    classes.tableCellLeftSep
                                  }
                                  style={{ minWidth: "180px" }}
                                >
                                  {row.country}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={
                                    classes.tableCell &&
                                    classes.tableCellLeftSep
                                  }
                                  style={{ minWidth: "90px" }}
                                >
                                  <Tooltip title="Select" placement="top" arrow>
                                    <IconButton
                                      aria-label="edit"
                                      color="accent7"
                                    >
                                      <TaskAltIcon
                                        className={classes.iconSize}
                                        onClick={() => {
                                          setLegalId(row.id);
                                          handleAddCustomeLegalCheck(row.id);
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  {/* <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={() =>
                                      handleAddCustomeLegal(row.id)
                                    }
                                  >
                                    Select
                                  </Button> */}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={99}>
                                <EmptyState
                                  icon={
                                    <FileOffIcon
                                      style={{
                                        color: "#db9400",
                                        fontSize: "40px",
                                      }}
                                    />
                                  }
                                  title="No Data Found"
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ mt: 1, mr: 2 }}>
                <Button onClick={handleCloseLegal}>Cancel</Button>
              </DialogActions>
            </Dialog>
          ) : null}
          {openLegal ? (
            <AlertDialog
              open={openLegal}
              title={"Confirmation"}
              maxWidth={"sm"}
              // ${deleteJobNumber}
              description={`Common variation is not matched do you want to proceed? `} /* Id : ${deleteData} */
              action={
                <Grid container justifyContent="right">
                  <Grid item xs={"auto"}>
                    <Button
                      onClick={() => setOpenLegal(false)}
                      style={{ marginRight: "15px" }}
                    >
                      No
                    </Button>
                  </Grid>
                  <Grid item xs={"auto"}>
                    {BtnLoadinng ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        onClick={() => handleAddCustomeLegal(legalid)}
                        color="secondary"
                      >
                        Yes
                      </Button>
                    )}
                  </Grid>
                </Grid>
              }
            />
          ) : null}
          {addCustomeStock ? (
            <Dialog open={true} fullWidth onClose={handleClose}>
              <DialogTitle>Add New Stock Exchange</DialogTitle>
              <DialogContent>
                <TextField
                  margin="dense"
                  id="name"
                  name="name"
                  label="Name"
                  type="email"
                  fullWidth
                  required
                  variant="standard"
                  onChange={(event) => {
                    let data = customeStock;
                    data[event.target.name] = event.target.value;
                    setCustomeStock(data);
                  }}
                />
                <TextField
                  margin="dense"
                  id="symbol"
                  name="symbol"
                  label="Symbol"
                  type="email"
                  fullWidth
                  required
                  variant="standard"
                  onChange={(event) => {
                    let data = customeStock;

                    data[event.target.name] = event.target.value;
                    setCustomeStock(data);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>

                <Button onClick={handleaddCustomeStock}>Add</Button>
              </DialogActions>
            </Dialog>
          ) : null}

          <SideDrawer
            formComp={
              <AddReportInfo
                state={state}
                setState={setState}
                jobId={props.jobId}
                jobAssetId={props.jobAssetId}
              />
            }
            state={state}
            setState={setState}
            crudState={showReportInfo}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditJointVentureInfo;
