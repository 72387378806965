import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // Manage Roles Page Styles
  breadCrumbsIcon: {
    fontSize: 10,
    marginLeft: 3
  },
  tableTopPart: {
    padding: theme.spacing(2)
  },
  totalRecord: {
    color: "#00519c",
  },
  container: {
    maxHeight: 640, // Table Max Height
  },
  colHighlight: {
    padding: theme.spacing(0, 0, 0, 1),
  },
  tableHeader: {
    padding: theme.spacing(0.5, 1.5) + "!important",
    whiteSpace: "normal",
    height: "46px",
  },
  tableCell: {
    wordBreak: "normal",
    wordWrap: "normal",
    borderBottom: "1px solid #d7d7d7 !important",
    padding: theme.spacing(0.5, 1.5) + "!important",
    height: "46px",
  },
  tableActionCell: {
    padding: theme.spacing(0.5, 1.5) + "!important",
    height: "46px"
  },
  cellNoWrap: {
    whiteSpace: "nowrap",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  helperText: {
    display: "block",
    whiteSpace: "nowrap",
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  addBorder: {
    borderRadius: "6px",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  alertDialogbtn: {
    padding: theme.spacing(0, 2, 1.5, 0),
  },
  rightDrowerbtn: {
    margin: "0% 5% 2% 0%",
    display: 'inline-flex',
  },

  // Add Currency Popup Styles
  popupArea: {
    padding: theme.spacing(3),
  },
  popupHeading: {
    padding: theme.spacing(0, 0, 3, 0),
  },
  addAccessRights: {
    border: "1px solid #d7d7d7",
    padding: theme.spacing(2, 2, 1, 2),
    margin: theme.spacing(1, 0, 2, 0),
  },
  switchControls: {
    margin: theme.spacing(0, 3, 0, 0),
    fontSize: "0.875rem !important",
  },
  subTitle: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    fontSize: '18px',
    fontWeight: "bold !important",
    margin: theme.spacing(0.75, 0, 0, 0) + "!important",
  },
  closeStyle: {
    transform: "rotate(45deg)",
  },
}));

export default useStyles;
