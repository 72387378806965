// Default React, useState and Material Components
import React, { useState } from "react";
import { Button, Box, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import AutocompleteText from "../../../UIComponent/Autocomplete";
import ApiService from "../../../Services/ApiService";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddEditCompanyAndStock = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [allCompany, setAllCompany] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);
  const [allStockExchange, setAllStockExchange] = useState([]);
  const [defaultCompanyValue, setDefaultCompanyValue] = useState(null);
  const [stockCompanyExchange, setStockExchange] = useState({
    id: 0, companyGuid: null, stockExchangeId: null, stockExchange: '',
    company: '', comments: "", isActive: true,
  });
  const [defaultStockExchangeValue, setDefaultStockExchangeValue] = useState(null);
  const [addStockExchangeDrawer, setAddStockExchangeDrawer] = useState(props.addStockExchangeDrawer);
  // Declaration of States and Component Variables End Here

  React.useEffect(() => {
    if (props.crudState === "Edit" || props.crudState === "View") {
      let data = {
        id: props.formData.id,
        company: props.formData.company,
        stockExchange: props.formData.stockExchange,
        companyGuid: props.formData.companyGuid,
        stockExchangeId: props.formData.stockExchangeId,
        comments: props.formData.comments,
        isActive: props.formData.isActive,
        createdOn: props.formData.createdOn,
      };
      setStockExchange(data);
      console.log(props.formData, 'props');
      allCompany.forEach((rData, i) => {
        if (rData.guid === props.formData.companyGuid) {
          setDefaultCompanyValue(allCompany[i]);
        }
      });
      allStockExchange.forEach((rData, i) => {
        if (rData.id === props.formData.stockExchangeId) {
          setDefaultStockExchangeValue(allStockExchange[i]);
        }
      });
    }
  }, [props, allCompany, allStockExchange]);

  // Function for getting Regions 
  React.useEffect(() => {
    ApiService.getAll(`DropDown/GetCompanyDropDownForCompanyStockExchange`)
      .then((res) => {
        setAllCompany(res.data);
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }, []);

  React.useEffect(() => {
    ApiService.getAll(`stock/GetStockExchangeDropDown`)
      .then((res) => {
        setAllStockExchange(res.data);
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }, []);


  // Function for Validation
  const validation = (stockCompanyExchange) => {
    console.log(stockCompanyExchange)
    if (stockCompanyExchange.companyGuid === null || stockCompanyExchange.companyGuid === 0 || stockCompanyExchange.companyGuid === '') {
      toasterError("Please select Company"); return false;
    }
    if (stockCompanyExchange.stockExchangeId === null || stockCompanyExchange.stockExchangeId === 0 || stockCompanyExchange.stockExchangeId === '') {
      toasterError("Please select Stock Exchange"); return false;
    }
    return true;
  }

// Function to save stock exchange
const saveStockExchange = () => {
  // Set loading to true
    setLoading(true);
  // Validate the stock company exchange  
    if (validation(stockCompanyExchange)) {
    // Call API service to create stock/SaveCompanyStockExchange   
      ApiService.create("stock/SaveCompanyStockExchange", stockCompanyExchange)
        .then((res) => {
   // If response status is 200, show success message and hide side drawer       
          if (res.status === 200) {
            toasterSuccess("Company and its Stock Exchange added successfully!");
            if (addStockExchangeDrawer) {
              setLoading(false);
              if (props.hideSideDrawer != null) {
                props.hideSideDrawer();
              }
            } else {
              setLoading(false);
              if (props.closeStockExchange != null) {
                props.closeStockExchange();
              }
              if (props.hideSideDrawer != null) {
                props.hideSideDrawer();
              }
            }
          }
        })
       // Catch any errors and handle them 
        .catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res)
          }
          )
          setLoading(false);
          if (addStockExchangeDrawer) {
            if (props.hideSideDrawer != null) {
              props.hideSideDrawer();
            }
          } else {
            setLoading(false);
            if (props.closeStockExchange != null) {
              props.closeStockExchange();
            }
          }
        });
    } else {
    // Set loading to false if validation fails  
      setLoading(false);
    }
  }

// This function updates the stock exchange details of a company
const updateStockExchange = () => {
  // Set loading to true while data is being fetched
  setLoading(true);
  // Create a validData variable and assign it the value of stockCompanyExchange
  let validData = stockCompanyExchange;
  // Assign the id from props.formData to validData
  validData.id = props.formData.id;
  // Check if validation passes for stockCompanyExchange
  if (validation(stockCompanyExchange)) {
    // Make an API call to updatepatch with the given parameters
    ApiService.updatepatch("stock/UpdateCompanyStockExchange","", validData)
      .then((res) => {
        // If the response status is 200, display success message and hide side drawer
        if (res.status === 200) {
          toasterSuccess("Company Stock and its Exchange details updated successfully!");
          setLoading(false);
          props.hideSideDrawer();
        }
      })
      .catch((err) => {
        // Handle errors and display error message
        ErroHandling(err, function (res) {
          toasterError(res)
        })
        setLoading(false);
      });
  } else { 
    // Set loading to false if validation fails
    setLoading(false); 
  }
}


  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Company & Stock Exchanges
        </Typography>
      </Box>

      {/* Add & Edit Top Producers Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AutocompleteText
            option={allCompany}
            dropShow={"companyName"}
            labelName={"Company"}
            isRequired={true}
            feildName={"companyName"}
            defaultValue={defaultCompanyValue}
            readOnly={props.crudState === "View" ? true : false}
            onChangeRole={(value) => {
              if (value) {
                setDefaultCompanyValue({
                  ...defaultValue,
                  company:
                    value && value.companyName ? value.companyName : "",
                  companyGuid: value && value.guid ? value.guid : null,
                  company: value && value.companyName ? value.companyName : "",
                });
                setStockExchange({
                  ...stockCompanyExchange,
                  company:
                    value && value.companyName ? value.companyName : "",
                  companyGuid: value && value.guid ? value.guid : null,
                  companyName: value && value.companyName ? value.companyName : "",
                });
              }
              else {
                setDefaultCompanyValue({
                  ...defaultValue,
                  company: "",
                  companyGuid: null,
                  companyName: "",
                });
                setStockExchange({
                  ...stockCompanyExchange,
                  company: "",
                  companyGuid: null,
                  companyName: "",
                });
              }
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteText
            option={allStockExchange}
            dropShow={"name"}
            labelName={"Stock Exchange"}
            isRequired={true}
            feildName={"name"}
            defaultValue={defaultStockExchangeValue}
            readOnly={props.crudState === "View" ? true : false}
            onChangeRole={(value) => {
              if (value) {
                setDefaultStockExchangeValue({
                  ...defaultValue,
                  name:
                    value && value.name ? value.name : "",
                  stockExchangeId: value && value.id ? value.id : null,
                  stockExchange:
                    value && value.name ? value.name : "",
                });
                setStockExchange({
                  ...stockCompanyExchange,
                  name:
                    value && value.name ? value.name : "",
                  stockExchangeId: value && value.id ? value.id : null,
                  stockExchange:
                    value && value.name ? value.name : "",
                });
              }
              else {
                setDefaultStockExchangeValue({
                  ...defaultValue,
                  name: "",
                  stockExchangeId: null,
                  stockExchange: "",
                });
                setStockExchange({
                  ...stockCompanyExchange,
                  name: "",
                  stockExchangeId: null,
                  stockExchange: "",
                });
              }

            }}
            InputLabelProps={{ style: { color: "black" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{ readOnly: props.crudState === "View" && true, }}
            fullWidth
            multiline
            maxRows={4}
            id="standard-basic"
            name="Description"
            size="small"
            label="Comments"
            placeholder="Comments"
            variant="standard"
            inputProps={{ maxLength: 500 }}
            value={props.crudState === "Add" ? null : props.formData.comments}
            onChange={(e) => {
              props.setFormData({ ...props.formData, comments: e.target.value });
              setStockExchange({ ...stockCompanyExchange, comments: e.target.value });
            }}
            InputLabelProps={{ style: { color: "black" } }}
          />

        </Grid>
        <Grid item xs={12}>
          {props.crudState === "View" ? null : (
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={12} sm={'auto'}>
                <LoadingButton
                  buttonName={props.crudState === "Add" ? "Save" : "Update"}
                  clickHandler={props.crudState === "Add" ? saveStockExchange : updateStockExchange}
                  isLoading={loading}
                />
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <Button
                  variant="contained"
                  className="rounded-border"
                  color="accent3"
                  onClick={() => {
                    if (addStockExchangeDrawer) {
                      props.setState({ ...props.state, right: false })
                    } else {
                      setAddStockExchangeDrawer(true);
                      if (props.closeStockExchange != null) { props.closeStockExchange(); }
                    }
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddEditCompanyAndStock;