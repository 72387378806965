// Default React, Router and Services Components
import React, { useState } from "react";
import { Breadcrumbs, Button, Box, CircularProgress, Grid, IconButton, InputAdornment, Link, Paper, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import ApiService from "../../../Services/ApiService";

// Importing Custom Components
import AddUser from "./AddUser";
import SideDrawer from "../../../UIComponent/Drawer";
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import AlertDialog from "../../../UIComponent/AlertDialog";
import ErroHandling from "../../../Services/ErrorHandling";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
// Importing useStyles
import useStyles from "./UserManagement.styles";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";
import GetMenuService from './../../../Services/GetMenuService';

const ManageUsers = (props) => {
  // Declaration of States and Component Variables Start Here
  const [allUsers, setAllUsers] = useState([]);
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState();
  const [showDelete, setDelete] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: false });
  const [id, setId] = useState([]);
  const [selected, SetSelected] = useState([]);
  const [isAllSelected, setIsSelected] = useState(false);
  const [state, setState] = useState({ right: false });
  const [deleteUserData, setDeleteUserData] = useState(null);
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  const [crudState, setCrudState] = React.useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState([]);
  // Declaration of States and Component Variables End Here

  // Creating Data Table for User Management
  function createData(id, firstName, lastName, email, roleName) {
    return { id, firstName, lastName, email, roleName, };
  }

  // Assigning Columns and UI Props
  const columns = [
    { id: "status", label: "Status", type: "Highlight" },
    { id: "firstName", label: "First Name", minWidth: 200, width: '25%', sorting: false },
    { id: "lastName", label: "Last Name", minWidth: 200, width: '25%', sorting: false, },
    { id: "email", label: "Email Address", sorting: false, minWidth: 330, width: '35%', },
    { id: "roleName", label: "Role", sorting: false, minWidth: 130, width: '15%', },
  ];

  const [access,setAccess]= React.useState({allowEdit:false,allowDelete:false,allowView:false})


  React.useEffect(()=>{
      GetMenuService(1,function(err,res){
        if(res){
         // console.log(res.data)
          setAccess(res.data)
        }else{
          toasterError(err)
        }
      })
    },[])

  // Declaration of React Hooks Start Here
  React.useEffect(() => {
    if (searchValue == "" || searchValue == null || searchValue == undefined) {
      searchUser('')
    }
    else { searchUser(searchValue) }
  }, [page, reload, rowsPerPage])

  React.useEffect(() => {
    if ((allUsers.length < 1 && !searchValue)) {
      getUserData();
    }
    // if (searchValue && reload) {
    //   searchUser(searchValue);
    // }
  }, [allUsers, page, reload, rowsPerPage]);

// This function toggles the drawer open or closed based on the mode
const toggleDrawer = (open, mode) => (event, item) => {
  // If the mode is set to delete, set the delete user data and open the delete drawer 
  if (mode === "Delete") {
    setDeleteUserData(item);
    setOpenDelete(true);
  } else {
    // Otherwise, set the form data and update the state with the right drawer open and the form data
    setFormData(item);
    setState({ ...state, right: open, formData: item });
    setCrudState(mode);
  }
};
  // Declaration of React Hooks End Here

  // Function for Handle KeyUp
// This function handles the keyup event
const handleKeyUp = (event) => {
  // If backspace is pressed and target element is not a button
  if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
    // If search value is empty, get user data
    if (searchValue.length === 0) { getUserData(); }
  }
  // If enter is pressed and target element is not a button
  if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
    // Reset total count and page number
    setTotalCount(0);
    setPage(0);
    // Search for user with given value
    searchUser(event.target.value);
  }
};

 // console.log(searchValue, "searchValue")

  // Function for Global Search
  const globalSearch = (value) => { setSearchValue(value); };

  // Function for Search Clear
// Function to search for a user based on filter value
const searchUser = (filterValue) => {
  // Set loading state to true
  setIsLoading(true);
  // Create an array of parameters
  let prt = [`pageNumber=${page + 1}`, `pageSize=${rowsPerPage}`];
  // Call the API service to search for user details
  ApiService.search("account/GetUserDetails?",
   { searchText: filterValue,
     'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null}, prt)
    .then((res) => {
      // If response is successful, set all users and total count
      if (res.status === 200) {
        setAllUsers(res.data.data);
        setIsLoading(false);
        setTotalCount(res.data.totalCount);
      } else {
        // Else, display error message
        toasterError(`${res.data}`);
        setIsLoading(false);
      }
    })
    .catch((err) => {
      // Set loading state to false and handle errors
      setIsLoading(false);
      ErroHandling(err, function (res) {
        toasterError(res)})       });
};

// This function is used to get user data from the API
const getUserData = () => {
  // Set loading state to true
  setIsLoading(true);
  // If reload is true, set it to false
  if (reload) { setReload(false); }
  // Make an API call to get user details
  ApiService.getAll(`account/GetUserDetails?pageNumber=${page + 1}&pageSize=${rowsPerPage}`,{'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null})
    .then((res) => {
      // If response status is 200, set all users and total count
      if (res.status === 200) {
        setAllUsers(res.data.data);
        setTotalCount(res.data.totalCount);
        setIsLoading(false);
      } else {
        // Else show error message
        toasterError(`${res.data}`);
        setIsLoading(false);
      }
    })
    .catch((err) => { 
      // Handle errors
      ErroHandling(err, function (res) {
      toasterError(res)}) 
       });
};

// Function to delete a user
const deleteUser = () => {
  // Set loading to true
  setLoading(true);
  // Get the data for deleting the user
  let data = deleteUserData;
  // Set active status to false
  data.active = false;
  // Call the API service to delete the user
  ApiService.create("account/DeleteUser", data)
    .then((res) => {
      // If the response is successful
      if (res.status === 200) {
        // Show success message
        toasterSuccess("User deleted successfully!");
        // Get updated user data
        getUserData();
        // Set loading to false
        setLoading(false);
        // Close the delete modal
        setOpenDelete(false);
      } else {
        // Show error message
        toasterError("Somthing went wrong");
        // Close the delete modal
        setOpenDelete(false);
        // Set loading to false
        setLoading(false);
      }
    })
    .catch((err) => { 
      // Handle errors
      ErroHandling(err, function (res) {
      // Show error message
      toasterError(res)}) 
    });
};
  const checkboxRequired = (e, item) => {
    SetSelected(e);
    setId(e);
    if (e.length > 0) { setDelete(true); }
    else { setDelete(false); }
  };

  return (

    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Manage Users</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />}
            aria-label="breadcrumb" className="custom-breadcrumb">
            <Typography color="text.primary">Home</Typography>
            <Typography color="text.primary">Manage Users/Roles</Typography>
            <Typography color="text.primary">Manage Users</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {access.allowEdit && <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2"
                startIcon={<AddIcon style={{ fontSize: "16px" }} />}>
                Add User
              </Button>}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>

              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className="serchByKeyword mt-1"
                      value={searchValue}
                      placeholder="Search by Keyword"
                      onKeyUp={handleKeyUp}
                      onChange={(e) => globalSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {searchValue ? (
                      <IconButton aria-label="Add" className="searchByClear"
                        onClick={() => { setSearchValue(""); getUserData(); }}>
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : ("")}
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">
                      Total Users:{" "}<span className={classes.totalRecord}> {totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* User Management Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={allUsers ? allUsers : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => { setPage(data); setReload(true); }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={setSorting}
                  isActionButton={{
                    ...(access.allowView && {
                      view: toggleDrawer(true, "View"),
                    }),
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: toggleDrawer(true, "Delete"),
                    }),
                  }}
                />

                {/* Add User Side Drawer */}
                <SideDrawer
                  formComp={
                    // Calling Add User
                    <AddUser
                      state={state}
                      setState={setState}
                      crudState={crudState}
                      formData={formData}
                      setFormData={setFormData}
                      userId={null}
                      hideSideDrawer={() => {
                        setState({ right: false });
                        getUserData();
                      }}
                    />
                  }
                  state={state}
                  setState={setState}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>

        {/* Alert Dialog - Confirmation for Delete */}
        {openDelete ? (
          <AlertDialog
            open={openDelete}
            title={"Confirmation"}
            maxWidth={"sm"}
            description={`Are you sure you want to delete user ${deleteUserData?.firstName} ${deleteUserData?.lastName} ?`}
            action={
              <Grid container alignItems="center" justifyContent="right" spacing={2} className={classes.alertDialogbtn}>
                <Grid item xs={"auto"}>
                  <Button onClick={() => setOpenDelete(false)}>No</Button>
                </Grid>
                <Grid item xs={"auto"}>
                <Button onClick={deleteUser} >
                    {loading ? (<CircularProgress size={25} />) : ("Yes")}
                  </Button>
                </Grid>
              </Grid>
            }
          />
        ) : null}
      </Grid>
    </Box>
  );
};

export default ManageUsers;
