// Default React, Router and Services Components
import React, { useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import axios from "axios";
import { filter } from "rxjs";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterError } from "../../../UIComponent/Toaster";
// Importing Custom Components
import MultiSelect from "../../../UIComponent/MultiSelect/MultiSelect";
// import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "../../../UIComponent/DatePicker";

// Importing useStyles
import useStyles from "./ReportingCalendar.styles";

const FilterReportCalendar = (props) => {
    // Declaration of States and Component Variables Start Here
    const classes = useStyles();
    const [dropDown, setDropDown] = useState({ company: [], year: [], reportingperoid: [] })
    const [selected, setSelected] = React.useState([]);
    const [values, setValues] = useState({
        company: [], year: [], reporting: [], ToDate: '', FromDate: '', "Q1": false,
        "Q2": false, "Q3": false, "Q4": false, "Annual": false
    });
    // Declaration of States and Component Variables End Here

    // Declaration of React Hooks Start Here
    React.useEffect(() => {
        setValues(prev => {
            return {
                ...prev,
                company: props.values?.Company || [],
                reporting: props.values?.ReportingPeriodId || [],
                year: props.values?.YearId || [],
                ToDate: props.values?.ToDate || '',
                FromDate: props.values?.FromDate || '',
                "Q1": props.values?.Q1 || false,
                "Q2": props.values?.Q2 || false,
                "Q3": props.values?.Q3 || false,
                "Q4": props.values?.Q4 || false,
                "Annual": props.values?.Annual || false
            }
        });
        axios.get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingPeriod",{
            headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
        }).then(function (response) {
            let data = response.data.map((el) => { return { label: el.name, value: el.id } })
            setDropDown(prev => { return { ...prev, reportingperoid: data || [] } });
            setValues(prev => {
                return {
                    ...prev, reporting: data.filter(el => prev.reporting.includes(el.value)) || []
                }
            });
        })
            .catch(function (error) { ErroHandling(error, function (res) {
                toasterError(res)}) });

        axios.get(
            process.env.React_App_baseUrl + "DropDown/GetCompanyDropDown", {
            headers: { isNewSource: false,'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
         },
        }
        ).then(function (response) {
            let data = response.data.map((el) => { return { label: el.companyName, value: el.companyId } })
            setDropDown(prev => { return { ...prev, company: data || [] } });
            setTimeout(() => {
                setValues(prev => {
                    return {
                        ...prev, company: data.filter(el => prev.company.includes(el.label)) || []
                    }
                });
            }, 100);
        })
            .catch(function (error) { ErroHandling(error, function (res) {
                toasterError(res)}) });
        axios.get(process.env.React_App_baseUrl + "reportingcalendar/GetReportingYear",{
            headers: {'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
        })
            .then(function (response) {
                let data = response.data.map((el) => { return { label: el.year.toString(), value: el.id } })
                setDropDown(prev => { return { ...prev, year: data || [] } });
                setValues(prev => {
                    return {
                        ...prev,
                        year: data.filter(el => prev.year.includes(el.value)) || []
                    }
                });
            })
            .catch(function (error) {ErroHandling(error, function (res) {
                toasterError(res)})  });
    }, [])
    // Declaration of React Hooks End Here

// This function is used to submit the filter and set the right state to false
const filter = () => { 
    props.filterSubmit(); // Submit the filter
    props.setState({ right: false }) // Set the right state to false
}

// This function resets the values of the filter and submits the filter
const reset = () => {
        // Set the values of the filter to their default values
        setValues({
            company: [], year: [], reporting: [], ToDate: '', FromDate: '', "Q1": false,
            "Q2": false, "Q3": false, "Q4": false, "Annual": false
        });
        // Set the values of the filter in props to their default values
        props.setFilterValue({
            "Company": [], "YearId": [], "ReportingPeriodId": [], "Q1": null, "Q2": null, "Q3": null,
            "Q4": null, "Annual": null, "FromDate": "", "ToDate": "", "SearchText": ""
        });
        // Submit the filter
        props.filterSubmit()
    }

// This function is used to handle changes in the company field
const onChangeHandlerCompany = (key, value, reason) => {
    // Set the values of the company field
    setValues({ ...values, company: value });
    // Map the value of the company field and store it in data variable
    let data = value.map((el) => el.label);
    // Set the filter value of the company field
    props.setFilterValue({ ...props.filterValue, Company: data });
}

// This function is used to handle changes in the year field
const onChangeHandlerYear = (key, value, reason) => {
    // Set the values of the year field
    setValues({ ...values, year: value });
    // Create an array of years from the value
    let year = value.map((el) => el.value);
    // Set the filter value for the year field
    props.setFilterValue({ ...props.filterValue, YearId: year });
}

// This function is used to handle the change event of the reporting period
const onChangeHandlerReporting = (key, value, reason) => {
    // Set the values in the state
    setValues({ ...values, reporting: value });
    // Create an array of reporting period values
    let reporting = value.map((el) => el.value);
    // Set the filter value with the reporting period values
    props.setFilterValue({ ...props.filterValue, ReportingPeriodId: reporting });
}

    return (
        // Layout for Add and Edit
        <Box className={classes.popupArea}>

            {/* Title for Side Drawer */}
            <Box className={classes.popupHeading}>
                <Typography id="modal-modal-title" variant="h5">
                    Filter
                </Typography>
            </Box>

            {/* Filter Form */}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <MultiSelect
                        onChange={(key, value, reason) => onChangeHandlerCompany(key, value, reason)}
                        value={values.company}
                        items={dropDown.company}
                        label="Company"
                        selectAllLabel={{ label: "Select all" }}
                        getOptionLabel={(option) => option.label}
                        role="company"
                    />
                </Grid>
                <Grid item xs={12}>
                    <MultiSelect
                        onChange={(key, value, reason) => onChangeHandlerYear(key, value, reason)}
                        value={values.year}
                        items={dropDown.year}
                        label="Year"
                        selectAllLabel={{ label: "Select all" }}
                        getOptionLabel={(option) => option.label}
                        role="year"
                    />
                </Grid>
                <Grid item xs={12}>
                    <MultiSelect
                        onChange={(key, value, reason) => onChangeHandlerReporting(key, value, reason)}
                        value={values.reporting}
                        items={dropDown.reportingperoid}
                        label="Reporting Period"
                        selectAllLabel={{ label: "Select all" }}
                        getOptionLabel={(option) => option.label}
                        role="reportingperoid"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="left">
                        <Grid item xs={6}>
                            <DatePicker
                                labelTitle="From Date"
                                variant="standard"
                                past={false}
                                defaultDate={values.FromDate}
                                onChangeDate={(e) => {
                                    let selectedDate = new Date(e);
                                    let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
                                    props.setFilterValue({ ...props.filterValue, FromDate: date });
                                    setValues({ ...values, FromDate: date })
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                labelTitle="To Date"
                                variant="standard"
                                past={false}
                                minDates={values.FromDate}
                                defaultDate={values.ToDate}
                                onChangeDate={(e) => {
                                    let selectedDate = new Date(e);
                                    let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
                                    props.setFilterValue({ ...props.filterValue, ToDate: date });
                                    setValues({ ...values, ToDate: date })
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.subTitle} style={{ marginTop: 0, marginBottom: "15px" }}>
                        Collection Frequency
                    </Typography>
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', mb: -1 }}>
                        <Grid item textAlign="center">
                            <FormControlLabel
                                value="top"
                                control={<Checkbox checked={values.Q1} onChange={(e, value) => {
                                    props.setFilterValue({ ...props.filterValue, Q1: value ? value : null });
                                    setValues({ ...values, Q1: value ? value : null });
                                }}
                                />}
                                label="Q1"
                                labelPlacement="top"
                            />
                        </Grid>
                        <Grid item textAlign="center">
                            <FormControlLabel
                                value="top"
                                control={<Checkbox checked={values.Q2} onChange={(e, value) => {
                                    props.setFilterValue({ ...props.filterValue, Q2: value ? value : null });
                                    setValues({ ...values, Q2: value ? value : null });
                                }} />}
                                label="Q2"
                                labelPlacement="top"
                            />
                        </Grid>
                        <Grid item textAlign="center">
                            <FormControlLabel
                                value="top"
                                control={<Checkbox checked={values.Q3} onChange={(e, value) => {
                                    props.setFilterValue({ ...props.filterValue, Q3: value ? value : null });
                                    setValues({ ...values, Q3: value ? value : null });
                                }} />}
                                label="Q3"
                                labelPlacement="top"
                            />
                        </Grid>
                        <Grid item textAlign="center">
                            <FormControlLabel
                                value="top"
                                control={<Checkbox checked={values.Q4} onChange={(e, value) => {
                                    props.setFilterValue({ ...props.filterValue, Q4: value ? value : null });
                                    setValues({ ...values, Q4: value ? value : null });
                                }} />}
                                label="Q4"
                                labelPlacement="top"
                            />

                        </Grid>
                        <Grid item textAlign="center">
                            <FormControlLabel
                                value="top"
                                control={<Checkbox checked={values.Annual} onChange={(e, value) => {
                                    props.setFilterValue({ ...props.filterValue, Annual: value ? value : null });
                                    setValues({ ...values, Annual: value ? value : null });
                                }} />}
                                label="Annual"
                                labelPlacement="top"
                            />
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="left">
                        <Grid item xs={'auto'}>
                            <Button variant="contained"
                                sx={{ mr: 2 }}
                                className="dummy-btn"
                                color="accent3"
                                onClick={filter}
                            >Filter</Button>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Button variant="contained"
                                className="dummy-btn reset-color"
                                color="accent3"
                                onClick={reset}
                            >Reset</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FilterReportCalendar;
