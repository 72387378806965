import React from "react";
import useStyles from "./chipfield.style";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip, TextField } from "@mui/material";
import clsx from 'clsx';

import FormControlStatic from '../FormControlStatic';

const MultiChipField = (props) => {
    const classes = useStyles();

    if (!props.viewOnly) {
        const setMultipleChipValue = (event, value) => {
            value = value.filter(function(entry) { return entry.trim() != ''; });
         if (props.onChange) props.onChange(value);
        }

        return (
            <Autocomplete
                size="small"
                margin="normal"
                fullWidth
                multiple
                id={props.id}
                options={[]}
                freeSolo
                classes={{
                    inputRoot: clsx({
                        [classes.sizeMd]: props.size === 'medium',
                        [classes.sizeLg]: props.size === 'large'
                    })
                }}
                renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                        <Chip
                            //variant="outlined"
                            label={option}
                            //size="small"
                            {...getTagProps({ index })}
                        />
                    ))
                }
                }
                renderInput={(params) => {
                    if (props.ariaLabel) params.inputProps['aria-label'] = props.ariaLabel;
                    return (
                        <TextField {...params} label={props.label} placeholder={props.placeholder} margin={props.margin} helperText={props.helperText} variant="standard" required maxRows={5} />
                    )
                }}
                value={props.value}
                onChange={(event, value) => setMultipleChipValue(event, value)}
            />


        )
    } else {
        return (
            <FormControlStatic label={props.label} value={props.value} />
        )
    }
}

export default MultiChipField;