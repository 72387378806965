// Default React, useState and Material Components
import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Chip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
// Importing Custom Components
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Import Material & Fluent UI Icons
import AddIcon from "@mui/icons-material/Add";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  CirclePlusIcon,
  DeleteIcon,
  FileOffIcon,
  TextDocumentIcon,
} from "@fluentui/react-icons-mdl2";
import CommonService from "../../../Services/CommonService";
// Importing useStyles and Styles
import useStyles from "./jobManagement.styles";
import axios from "axios";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
import ApiService from "../../../Services/ApiService";
import EmptyState from "../../../UIComponent/EmptyState";
import AddReportInfo from "./AddReportInfo";
import SideDrawer from "../../../UIComponent/Drawer";
import DatePicker from "../../../UIComponent/DatePicker";
import dayjs from "dayjs";
import AlertDialog from "../../../UIComponent/AlertDialog";

const AddSeeThroughOwnerInfo = (props) => {
  console.log("PROPS", props);
  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [openLegal, setOpenLegal] = React.useState(false);
  const [confirmPopUp, setConfirmPopUp] = React.useState(false);
  const [BtnLoadinng, setBtnLoadinng] = React.useState(false);

  const [preselectedOwner, setPreselectedOwner] = React.useState(null);
  const [sourcefiledropdown, setSourceFileDropdown] = React.useState([]);
  const [stockdropdown, setStockExchangeDropdown] = React.useState([]);
  const [ownerdropdown, setOwnerDropdown] = React.useState([]);
  const [newEntry, setNewEntry] = React.useState(false);
  const [selectedsourceFile, setSelectedsourceFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [addLegalEntity, setAddLegalEntity] = React.useState(false);
  const [addCustomeStock, setAddCustomeStock] = React.useState(false);
  const [customeStock, setCustomeStock] = React.useState({
    id: 0,
    symbol: "",
    name: "",
  });
  const [allYear, setAllYear] = React.useState([]);
  const [preselectedYear, setPreselectedYear] = React.useState(null);
  const [preselectedStock, setPreselectedStock] = React.useState(null);
  const [popdata, setPopdata] = React.useState(null);
  const [previousPopdata, setPreviousPopdata] = React.useState(null);
  const [addCustomeOwner, setAddCustomeOwner] = React.useState(false);
  const [customeOwner, setCustomeOwner] = React.useState({
    ownerGuid: "",
    correctOwnerName: "",
    legalEntityTypeId: 0,
  });
  const [popupDropdown, setPopupDropdown] = React.useState([]);
  const [customeLegal, setCustomLegal] = React.useState({
    ownerGuid: "",
    correctOwnerName: "",
    legalEntityTypeId: 0,
  });
  const [legalid, setLegalId] = React.useState(null);
  const [ownershippercentage, setOwnershipPercentage] = React.useState("");

  const [state, setState] = React.useState({ right: false });
  const [showReportInfo, setShowReportInfo] = React.useState(false);
  const [crudState, setCrudState] = React.useState("");
  const [legalentitydropdown, setLegalEntityDropdown] = React.useState([]);
  console.log("PROPE", props.jobAssetOwnerInfoId);
  const [removeIndex, setRemoveIndex] = React.useState(null);
  const [order, setOrder] = React.useState(null);
  const [reportInfoId, setReportInfoIdValue] = React.useState(null);

  const [formData, setFormData] = React.useState({
    jobAssetOwnerInfoId: props.jobAssetOwnerInfoId
      ? props.jobAssetOwnerInfoId
      : 0,
    jointVentureInfoId: props.jointVentureInfoId ? props.jointVentureInfoId : 0,
    unsure: false,
    legalEntityTypeId: null,
    seeThroughOwnerGuid: "",
    seeThroughOwnerComment: "",
    seeThroughOwnershipPercentage: 0,
    //ownershipDate: null,
    yearId:0,
    seeThroughOwnershipComment: "",
    stockExchangeId: 0,
    stockExchangeComment: "",
    seeThroughReportDetails: [],
    ownerLinks: [],
    ownershipPercentageLinks: [],
    ownershipStockLinks: [],
  });
  const finalData = {
    jobAssetOwnerInfoId: formData.jobAssetOwnerInfoId
      ? formData.jobAssetOwnerInfoId
      : props.jobAssetOwnerInfoId,
    jointVentureInfoId: formData.jointVentureInfoId,
    unsure: formData.unsure,
    legalEntityTypeId: formData.legalEntityTypeId,
    seeThroughOwnerGuid: formData.seeThroughOwnerGuid,
    seeThroughOwnerComment: formData.seeThroughOwnerComment,
    seeThroughOwnershipPercentage: formData.seeThroughOwnershipPercentage,
    // ownershipDate: formData.ownershipDate ? formData.ownershipDate : null,
    yearId:formData.yearId?formData.yearId:0,
    seeThroughOwnershipComment: formData.seeThroughOwnershipComment,
    stockExchangeId: formData.stockExchangeId,
    stockExchangeComment: formData.stockExchangeComment,
    seeThroughReportDetails: [
      ...(formData?.ownerLinks || []).map((link) => ({ ...link })),
      ...(formData?.ownershipPercentageLinks || []).map((link) => ({
        ...link,
      })),
      ...(formData?.ownershipStockLinks || []).map((link) => ({ ...link })),
    ].filter(
      (link) =>
        link.jobAssetReportInfoId !== 0 ||
        link.pageNumber !== 0 ||
        link.reportType !== 0
    ),
  };
  const toggleDrawerLegal = (open, mode) => (event, item, id) => {
    if (mode === "Legal") {
      console.log("MOD", id);
      setOpenLegal(true);
    }
  };
  const toggleDrawer = (open, mode) => (event, item) => {
    console.log("Mode", mode);
    setState({ ...state, right: open });
    setShowReportInfo(true);
    setCrudState(mode);
  };
  const columnsLegalEntity = [
    {
      id: "commonVariation",
      label: "Common Variation",
      minWidth: 250,
      width: "20%",
      align: "left",
    },
    {
      id: "cruFormat",
      label: "CRU Format",
      minWidth: 250,
      width: "20%",
      align: "left",
    },
    {
      id: "country",
      label: "Country",
      minWidth: 250,
      width: "20%",
      align: "left",
    },
    {
      id: "actions",
      label: "Action",
      minWidth: 90,
      width: "10%",
      align: "center",
    },
  ];
  // Top 100 films

  // Declaration of States and Component Variables End Here
  /*********API CALLS TO POPULATE DROPDOWN VALUES*********** */
  const GetOwnerDropdown = () => {
    setOwnerDropdown([]);
    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetCorrectOwnerDropdown",
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setOwnerDropdown(res.data);
        console.log(res.data);
        if (customeOwner) {
          if (customeOwner.ownerGuid !== "") {
            setPreselectedOwner({
              guid: customeOwner.ownerGuid,
              companyName: customeOwner.correctOwnerName,
            });
            console.log("Chcek", customeOwner);
          }
        }
      });
  };
  const GetPopupCompanyDropdown = () => {
    axios
      .get(
        process.env.React_App_baseUrl +
          "DropDown/GetCompanyDropDownForCompanyStockExchange",
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setPopupDropdown(res.data);
        console.log(res.data);
      });
  };
  const GetLegalEntityGrid = () => {
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0
    ) {
      return;
    }
    axios
      .get(
        process.env.React_App_baseUrl +
          `ManageOwnership/GetLegalEntityTypes?jobAssetId=${props.jobAssetId}`,
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setLegalEntityDropdown(res.data);
        console.log(res.data);
      });
  };
  const GetSourceFileDropdown = () => {
    if (
      props.jobAssetId === undefined ||
      props.jobAssetId === null ||
      props.jobAssetId === 0
    ) {
      return;
    }
    axios
      .post(
        process.env.React_App_baseUrl + "ManageOwnership/GetSourceFileDropdown",
        null,
        {
          headers: {
            jobAssetId: props.jobAssetId,
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setSourceFileDropdown(res.data);
        console.log(res.data);
      });
  };
  const GetStockExchangeDropdown = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "stock/GetStockExchangeDropDown",

        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then((res) => {
        setStockExchangeDropdown(res.data);
        console.log(res.data);
      });
  };
  const getAddReportYear = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "ManageOwnership/GetReportingYears",
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setAllYear(res.data);
        } else {
          toasterError("Something went wrong");
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };
  React.useEffect(() => {
    GetStockExchangeDropdown();
    GetSourceFileDropdown();
    GetOwnerDropdown();
    GetPopupCompanyDropdown();
    GetLegalEntityGrid();
    getAddReportYear()
  }, [props]);
  React.useEffect(() => {
    if (ownerdropdown !== null && ownerdropdown !== undefined) {
      if (customeLegal) {
        if (customeLegal.ownerGuid !== "") {
          const preselectedlegal = ownerdropdown.find(
            (e) => e.guid === customeLegal.ownerGuid
          );

          console.log("CMN", preselectedlegal);
          setPreselectedOwner(preselectedlegal);
        }
      }
    }
  }, [ownerdropdown]);

  const handleChange = (e, value, i, field, reportType, section) => {
    const updatedFormData = { ...formData };

    let links;
    switch (section) {
      case "owner":
        links = [...updatedFormData.ownerLinks];
        break;
      case "ownershipPercentage":
        links = [...updatedFormData.ownershipPercentageLinks];
        break;
      case "ownershipStock":
        links = [...updatedFormData.ownershipStockLinks];
        break;
      default:
        break;
    }

    if (links) {
      if (field === "sourceFile") {
        links[i] = {
          jobAssetReportInfoId: value ? value.id : null,
          pageNumber: links[i]?.pageNumber || 0, // Preserve the existing page number value
          reportType: reportType,
        };

        setSelectedsourceFile(value); // Update the selected source file
        console.log(value);
      } else if (field === "pageNo") {
        links[i] = {
          jobAssetReportInfoId: links[i]?.jobAssetReportInfoId || null, // Preserve the existing source file index value
          pageNumber: e.target.value !== "" ? parseInt(e.target.value) : 0,
          reportType: reportType,
        };
      }

      switch (section) {
        case "owner":
          updatedFormData.ownerLinks = links;
          break;
        case "ownershipPercentage":
          updatedFormData.ownershipPercentageLinks = links;
          break;
        case "ownershipStock":
          updatedFormData.ownershipStockLinks = links;
          break;
        default:
          break;
      }

      setFormData(updatedFormData);
    }
  };

  const handleAddLink = (section, reportType) => {
    const newLink = {
      jobAssetReportInfoId: 0,
      pageNumber: 0,
      reportType: reportType,
    };

    const updatedFormData = { ...formData };

    switch (section) {
      case "owner":
        updatedFormData.ownerLinks = [
          ...updatedFormData.ownerLinks,
          { ...newLink, section: "owner" },
        ];
        break;
      case "ownershipPercentage":
        updatedFormData.ownershipPercentageLinks = [
          ...updatedFormData.ownershipPercentageLinks,
          { ...newLink, section: "ownershipPercentage" },
        ];
        break;
      case "ownershipStock":
        updatedFormData.ownershipStockLinks = [
          ...updatedFormData.ownershipStockLinks,
          { ...newLink, section: "ownershipStock" },
        ];
        break;
      default:
        break;
    }

    setFormData(updatedFormData);
  };

  /*   const handleAddLink = (section, reportType) => {
    const links = [...formData?.seeThroughReportDetails];

    const newLink = {
      jobAssetReportInfoId: 0,
      pageNumber: 0,
      reportType: reportType,
    };

    switch (section) {
      case "owner":
        if (reportType === 1) {
          links.push(newLink);
        }
        break;
      case "ownershipPercentage":
        if (reportType === 2) {
          links.push(newLink);
        }
        break;
      case "stock":
        if (reportType === 3) {
          links.push(newLink);
        }
        break;
      default:
        break;
    }

    const updatedFormData = { ...formData, seeThroughReportDetails: links };
    setFormData(updatedFormData);
  }; */

  /*   const handleRemoveLink = (e, index) => {
    const links = [...formData?.seeThroughReportDetails];

    links.splice(index, 1);

    let item = { ...formData, seeThroughReportDetails: links };

    setFormData(item);
  }; */
  const RemoveData = (section, index, reportType) => {
    setRemoveIndex(index);
    setOrder(section);
    setReportInfoIdValue(reportType);
    setConfirmPopUp(true);
  };
  React.useEffect(() => {}, [removeIndex, order, reportInfoId]);
  const handleRemoveLink = () => {
    const updatedFormData = { ...formData };

    let links;
    switch (order) {
      case "owner":
        links = [...updatedFormData.ownerLinks];
        break;
      case "ownershipPercentage":
        links = [...updatedFormData.ownershipPercentageLinks];
        break;
      case "ownershipStock":
        links = [...updatedFormData.ownershipStockLinks];
        break;
      default:
        break;
    }

    if (links) {
      const filteredLinks = links.filter(
        (link, i) => i !== removeIndex || link.reportType !== reportInfoId
      );

      switch (order) {
        case "owner":
          updatedFormData.ownerLinks = filteredLinks;
          break;
        case "ownershipPercentage":
          updatedFormData.ownershipPercentageLinks = filteredLinks;
          break;
        case "ownershipStock":
          updatedFormData.ownershipStockLinks = filteredLinks;
          break;
        default:
          break;
      }

      setFormData(updatedFormData);
    }
  };

  React.useEffect(() => {
    setFormData({
      ...formData,
      ownerLinks: [
        {
          jobAssetReportInfoId: 0,
          pageNumber: 0,
          reportType: 0,
        },
      ],
      ownershipPercentageLinks: [
        {
          jobAssetReportInfoId: 0,
          pageNumber: 0,
          reportType: 0,
        },
      ],
      ownershipStockLinks: [
        {
          jobAssetReportInfoId: 0,
          pageNumber: 0,
          reportType: 0,
        },
      ],
    });
  }, []);

  const addFormData = async () => {
    setLoading(true);

    // Perform validation checks if `finalData.unsure` is not true
    if (finalData.unsure === false) {
      console.log("finalData:", finalData);
      if (
        finalData.seeThroughOwnerGuid &&
        finalData.stockExchangeId !== 0 &&
        finalData.seeThroughOwnershipPercentage !== 0 &&
        finalData.yearId !== 0
      ) {
        // Validate the page number if source files are selected
        if (
          finalData.seeThroughReportDetails &&
          finalData.seeThroughReportDetails.length > 0
        ) {
          for (let i = 0; i < finalData.seeThroughReportDetails.length; i++) {
            const file = finalData.seeThroughReportDetails[i];
            if (file.pageNo === 0) {
              toasterError(
                `Please enter a valid page number for source file ${i + 1}`
              );
              setLoading(false);
              return;
            }
          }
        } else if (finalData.sourceFile && finalData.sourceFile[0]) {
          // Check if source file is selected and page number is provided (if selected)
          if (!finalData.sourceFile[0].pageNo) {
            toasterError(
              "Please enter the page number for the selected source file"
            );
            setLoading(false);
            return;
          }
        }
      } else {
        // Display error message for missing fields
        toasterError("Please fill all the required fields");
        setLoading(false);
        return;
      }
    }

    // Make the HTTP POST request
    axios
      .post(
        process.env.React_App_baseUrl +
          "ManageOwnership/SaveSeeThroughOwnerInfo",
        finalData,
        {
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : null,
          },
        }
      )
      .then(function (response) {
        // Update state and display success message
        props.setRefresh(!props.refresh);
        toasterSuccess("Equity owner saved successfully");
        setSuccessMessage("Equity owner saved successfully");
        setLoading(false);
        props.hideSideDrawer();
        //setEditData(false);
      })
      .catch(function (error) {
        console.log(error);
        ErroHandling(error, function (res) {
          console.log(res);
          toasterError(res);
        });
        setLoading(false);
      });
  };
  const handleCloseOwner = () => {
    setPopdata(previousPopdata);
    setAddCustomeOwner(!addCustomeOwner);
  };
  const handleCloseLegal = () => {
    setAddLegalEntity(!addLegalEntity);
  };
  const handleClose = () => {
    setAddCustomeStock(!addCustomeStock);
  };
  const handleAddCustomeOwner = () => {
    console.log(customeOwner);
    if (customeOwner.correctOwnerName.length > 0) {
      setLoading(true);

      ApiService.create("ManageOwnership/SaveCorrectOwnerName", customeOwner)
        .then((res) => {
          setLoading(false);
          GetOwnerDropdown();
          if (res.status === 200) {
            toasterSuccess("Owner added successfully ");
            if (popdata) {
              if (popdata.ownerGuid !== "") {
                setPreselectedOwner(popdata);
              }
            }
            console.log("Chcek", customeOwner);
          } else {
            toasterError("Something went wrong");
          }
          setAddCustomeOwner(!addCustomeOwner);
        })
        .catch((err) => {
          setLoading(false);
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        });
    } else {
      toasterError("Please fill all the required fields");
    }
  };
  const handleAddCustomeLegalCheck = (id) => {
    setLoading(true);
    setBtnLoadinng(true);
    customeLegal.legalEntityTypeId = id;
    console.log("LEGAL", customeLegal);

    ApiService.create("ManageOwnership/CheckCommonVariation", customeLegal)
      .then((res) => {
        setLoading(false);
        GetOwnerDropdown();
        if (res.status === 200) {
          if (res.data === true) {
            handleAddCustomeLegal(id);
          } else {
            toggleDrawerLegal(true, "Legal")(id);
          }
        } else {
          toasterError("Something went wrong");
        }
        setAddLegalEntity(!addLegalEntity);
      })
      .catch((err) => {
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };
  const handleAddCustomeLegal = (id) => {
    setLoading(true);
    setBtnLoadinng(true);
    customeLegal.legalEntityTypeId = id;
    console.log("LEGAL", customeLegal);
    ApiService.create("ManageOwnership/SaveCorrectOwnerName", customeLegal)
      .then((res) => {
        setLoading(false);
        GetOwnerDropdown();
        if (res.status === 200) {
          toasterSuccess("Legal Entity type added Successfully");
        } else {
          toasterError("Something went wrong");
        }
        setAddLegalEntity(!addLegalEntity);
      })
      .catch((err) => {
        setLoading(false);
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };
  const handleaddCustomeStock = () => {
    if (customeStock.name.length > 0) {
      setLoading(true);
      ApiService.create("stock/SaveStockExchange", customeStock)
        .then((res) => {
          setLoading(false);
          GetStockExchangeDropdown();
          if (res.status === 200) {
            toasterSuccess("Stock exchange added successfully ");
            setPreselectedStock(customeStock);
          } else {
            toasterError("Something went wrong");
          }
          setAddCustomeStock(!addCustomeStock);
        })
        .catch((err) => {
          setLoading(false);
          ErroHandling(err, function (res) {
            toasterError(res);
          });
        });
    } else {
      toasterError("Please enter stock name and symbol");
    }
  };
  React.useEffect(() => {
    const customcompany = popupDropdown.find(
      (e) => e.guid === props.companyGuid
    );
    setPopdata(customcompany);

    console.log("CC", customcompany);
  }, [popupDropdown, props.companyGuid]);

  const openDocument = (file, pageNumber) => {
    if (pageNumber === "") {
      pageNumber = 1;
    }
    //var jobdetails = tempData?.state;
    var reportName = file + ".pdf";
    var senData = {
      jobId: props.jobId,
      fileName: reportName,
      pageNumber: Number(pageNumber),
      crudState: "Edit",
    };
    localStorage.setItem("filePreview", JSON.stringify(senData));
   // CommonService.AddTokenFromSessionStorageToLocalStorage();
    window.open(
      "#app/ownership-changes/file-preview",
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>
      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {props.crudState} Equity Ownership Info
        </Typography>
      </Box>

      {/* Add & Edit Commodity Form */}
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ mt: -1 }}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={0}
          >
            <Grid item xs={6} className={classes.removeCheckMargin}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.unsure}
                    onChange={(e) =>
                      setFormData({ ...formData, unsure: e.target.checked })
                    }
                  />
                }
                label="Unsure"
              />
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Button
                variant="contained"
                color="accent7"
                size="small"
                startIcon={<AddIcon style={{ fontSize: 16 }} />}
                onClick={toggleDrawer(true, "Add")}
              >
                Add Source File
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: -2 }}>
          <Box className={classes.jobShortDetails}>
            <Grid
              container
              alignItems="flex-end"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item xs={12} sx={{ mt: -1 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    alignItems="self-end"
                  >
                    <Grid item xs>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={ownerdropdown}
                        value={preselectedOwner}
                        getOptionLabel={(option) => option.companyName}
                        onChange={(e, value) => {
                          console.log(value, "Data checker");
                          if (value.companyName === "Others") {
                            //setPreviousPopdata(popdata);
                            setCustomeOwner({
                              ownerGuid: props.companyGuid,
                              correctOwnerName: "",
                              legalEntityTypeId: 0,
                            });
                            //setAddCustomeOwner(!addCustomeOwner);
                            setFormData({ ...formData, isNewOwner: true });
                          } else if (value.companyName !== "Others") {
                            console.log(value.companyName);
                            setFormData({
                              ...formData,
                              seeThroughOwnerGuid: value.guid,
                            });
                          }
                          setPreselectedOwner(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required={finalData.unsure === false}
                            label="Equity Owner Name"
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                    {newEntry && (
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          size="small"
                          id="size-small-standard"
                          label="New Owner"
                          variant="standard"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              ownerName: e.target.value,
                              isCRUOwner: false,
                            });
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs="auto">
                      <Tooltip title="Apply Legal Entity" placement="top" arrow>
                        <IconButton
                          color="accent7"
                          onClick={(e, value) => {
                            setCustomLegal({
                              ownerGuid: formData?.seeThroughOwnerGuid,
                              correctOwnerName: "",
                              legalEntityTypeId: customeLegal.legalEntityTypeId,
                            });
                            setAddLegalEntity(true);
                          }}
                        >
                          <TaskAltIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Comments  "
                  variant="standard"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      seeThroughOwnerComment: e.target.value,
                    });
                  }}
                  multiline
                  minRows={3}
                  maxRows={3}
                />
              </Grid>

              {formData?.ownerLinks &&
                formData?.ownerLinks?.map((linki, i) => (
                  <>
                    <Grid item xs={12} key={i}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          alignItems="self-end"
                        >
                          <Grid item xs>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={sourcefiledropdown}
                              getOptionLabel={(option) => option.reportName}
                              onChange={(e, value) => {
                                handleChange(
                                  e,
                                  value,
                                  i,
                                  "sourceFile",
                                  1,
                                  "owner"
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Source File"
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs="auto">
                            <Tooltip
                              title="Open Document"
                              placement="top"
                              arrow
                            >
                              <IconButton
                                color="accent6"
                                aria-label="add to shopping cart"
                                disabled={!selectedsourceFile} // Disable the icon when no source file is selected
                                onClick={() => {
                                  openDocument(
                                    selectedsourceFile?.reportName,
                                    linki?.pageNumber
                                  );
                                }}
                              >
                                <TextDocumentIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} key={i}>
                      <Box sx={{ ml: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="self-start"
                          alignItems="self-end"
                        >
                          <TextField
                            id="standard-basic"
                            type="number"
                            label="Page No"
                            fullWidth
                            required={
                              formData?.sourceFile &&
                              formData.sourceFile.length > 0
                            }
                            variant="standard"
                            onChange={(e) =>
                              handleChange(e, null, i, "pageNo", 1, "owner")
                            }
                            defaultValue={linki.pageNo}
                            //value={defaultSourcefile[i]?.pageNo || 0}
                            sx={{ width: 100 }}
                          />

                          {i === 0 && (
                            <Grid item xs="auto">
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton color="error"disabled>
                                  <DeleteIcon
                                    style={{ fontSize: "20px" }}
                                    onClick={(e) => {
                                      //RemoveData("owner", i, 1);
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                          {i === 0 && (
                            <Grid item xs="auto">
                              <Tooltip title="Add" placement="top" arrow>
                                <IconButton
                                  color="secondary"
                                  style={{ fontSize: "20px" }}
                                  onClick={(e) => {
                                    handleAddLink("owner", 1);
                                  }}
                                >
                                  <CirclePlusIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}

                          {i > 0 && (
                            <Grid item xs="auto">
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton color="error">
                                  <DeleteIcon
                                    style={{ fontSize: "20px" }}
                                    onClick={(e) => {
                                      RemoveData("owner", i, 1);
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </>
                ))}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mt: -4 }}>
          <Box className={classes.jobShortDetails}>
            <Grid
              container
              alignItems="flex-end"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  id="standard-basic"
                  label="Ownership Percentage(%)"
                  variant="standard"
                  value={ownershippercentage}
                  required={finalData.unsure === false}
                  onChange={(e) => {
                    if (e.target.value <= 100) {
                      setOwnershipPercentage(e.target.value);

                      setFormData({
                        ...formData,
                        seeThroughOwnershipPercentage: e.target.value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/*  <TextField
                  fullWidth
                  id="standard-basic"
                  label="Ownership Date"
                  onChange={(e) => {
                     setFormData({
                      ...formData,
                      seeThroughOwnershipPercentage: e.target.value,
                    }); 
                  }}
                  variant="standard"
                /> */}
                {/* <DatePicker
                  labelTitle="Ownership Date"
                  variant="standard"
                  required={finalData.unsure === false}
                  onChangeDate={(e) => {
                    let selectedDate = new Date(e);
                    let date =
                      selectedDate.getFullYear() +
                      "-" +
                      ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                      "-" +
                      ("0" + selectedDate.getDate()).slice(-2);
                    setFormData({
                      ...formData,
                      ownershipDate: dayjs(date, "YYYY-MM-DD").format(
                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                      ),
                    });
                    console.log("DATE", {
                      ...formData,
                      ownershipDate: dayjs(date, "YYYY-MM-DD").format(
                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                      ),
                    });
                  }}
                  past={false}
                  //key={keyFromoDate}
                  clearable={true}
                /> */}
                <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={allYear}
                              
                              getOptionLabel={(option) => option.year}
                              value={preselectedYear}
                              onChange={(event, value) => {
                                if (value) {
                                  setFormData({
                                    ...formData,
                                    yearId:
                                      value && value.id ? value.id : null,
                                  });
                                  setPreselectedYear(value);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Ownership Date"
                                  variant="standard"
                                  required={finalData.unsure === false}
                                />
                              )}
                            />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Comments "
                  variant="standard"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      seeThroughOwnershipComment: e.target.value,
                    });
                  }}
                  multiline
                  minRows={3}
                  maxRows={3}
                />
              </Grid>
              {formData?.ownershipPercentageLinks &&
                formData?.ownershipPercentageLinks?.map((linki, i) => (
                  <>
                    <Grid item xs={12} key={i}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          alignItems="self-end"
                        >
                          <Grid item xs>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={sourcefiledropdown}
                              getOptionLabel={(option) => option.reportName}
                              onChange={(e, value) => {
                                handleChange(
                                  e,
                                  value,
                                  i,
                                  "sourceFile",
                                  2,
                                  "ownershipPercentage"
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Source File"
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs="auto">
                            <Tooltip
                              title="Open Document"
                              placement="top"
                              arrow
                            >
                              <IconButton
                                color="accent6"
                                aria-label="add to shopping cart"
                                disabled={!selectedsourceFile} // Disable the icon when no source file is selected
                                onClick={() => {
                                  openDocument(
                                    selectedsourceFile?.reportName,
                                    linki?.pageNumber
                                  );
                                }}
                              >
                                <TextDocumentIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} key={i}>
                      <Box sx={{ ml: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="self-start"
                          alignItems="self-end"
                        >
                          <TextField
                            id="standard-basic"
                            type="number"
                            label="Page No"
                            fullWidth
                            required={
                              formData?.sourceFile &&
                              formData.sourceFile.length > 0
                            }
                            variant="standard"
                            onChange={(e) =>
                              handleChange(
                                e,
                                null,
                                i,
                                "pageNo",
                                2,
                                "ownershipPercentage"
                              )
                            }
                            defaultValue={linki.pageNo}
                            //value={defaultSourcefile[i]?.pageNo || 0}
                            sx={{ width: 100 }}
                          />

                          {i === 0 && (
                            <Grid item xs="auto">
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton color="error"disabled>
                                  <DeleteIcon
                                    style={{ fontSize: "20px" }}
                                    onClick={(e) => {
                                      RemoveData("ownershipPercentage", i, 2);
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                          {i === 0 && (
                            <Grid item xs="auto">
                              <Tooltip title="Add" placement="top" arrow>
                                <IconButton
                                  color="secondary"
                                  style={{ fontSize: "20px" }}
                                  onClick={(e) => {
                                    handleAddLink("ownershipPercentage", 2);
                                  }}
                                >
                                  <CirclePlusIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}

                          {i > 0 && (
                            <Grid item xs="auto">
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton color="error">
                                  <DeleteIcon
                                    style={{ fontSize: "20px" }}
                                    onClick={(e) => {
                                      RemoveData("ownershipPercentage", i, 2);
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </>
                ))}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mt: -4 }}>
          <Box className={classes.jobShortDetails}>
            <Grid
              container
              alignItems="flex-end"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item xs={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    alignItems="self-end"
                  >
                    <Grid item xs>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={stockdropdown}
                        value={preselectedStock}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => {
                          setPreselectedStock(value);
                          setFormData({
                            ...formData,

                            stockExchangeId: value.id,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required={finalData.unsure === false}
                            label="Stock Exchange with Largest market cap"
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Add Stock Exchange" placement="top" arrow>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            setCustomeStock({
                              id: 0,
                              symbol: "",
                              name: "",
                            });
                            setAddCustomeStock(true);
                          }}
                        >
                          <CirclePlusIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Comments "
                  variant="standard"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      stockExchangeComment: e.target.value,
                    });
                  }}
                  multiline
                  minRows={3}
                  maxRows={3}
                />
              </Grid>
              {formData?.ownershipStockLinks &&
                formData?.ownershipStockLinks?.map((linki, i) => (
                  <>
                    <Grid item xs={12} key={i}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          alignItems="self-end"
                        >
                          <Grid item xs>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={sourcefiledropdown}
                              getOptionLabel={(option) => option.reportName}
                              onChange={(e, value) => {
                                handleChange(
                                  e,
                                  value,
                                  i,
                                  "sourceFile",
                                  3,
                                  "ownershipStock"
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Source File"
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs="auto">
                            <Tooltip
                              title="Open Document"
                              placement="top"
                              arrow
                            >
                              <IconButton
                                color="accent6"
                                aria-label="add to shopping cart"
                                disabled={!selectedsourceFile} // Disable the icon when no source file is selected
                                onClick={() => {
                                  openDocument(
                                    selectedsourceFile?.reportName,
                                    linki?.pageNumber
                                  );
                                }}
                              >
                                <TextDocumentIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} key={i}>
                      <Box sx={{ ml: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          justifyContent="self-start"
                          alignItems="self-end"
                        >
                          <TextField
                            id="standard-basic"
                            type="number"
                            label="Page No"
                            fullWidth
                            required={
                              formData?.sourceFile &&
                              formData.sourceFile.length > 0
                            }
                            variant="standard"
                            onChange={(e) =>
                              handleChange(
                                e,
                                null,
                                i,
                                "pageNo",
                                3,
                                "ownershipStock"
                              )
                            }
                            defaultValue={linki.pageNo}
                            //value={defaultSourcefile[i]?.pageNo || 0}
                            sx={{ width: 100 }}
                          />

                          {i === 0 && (
                            <Grid item xs="auto">
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton color="error" disabled>
                                  <DeleteIcon
                                    style={{ fontSize: "20px" }}
                                    onClick={(e) => {
                                      RemoveData("ownershipStock", i, 3);
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                          {i === 0 && (
                            <Grid item xs="auto">
                              <Tooltip title="Add" placement="top" arrow>
                                <IconButton
                                  color="secondary"
                                  style={{ fontSize: "20px" }}
                                  onClick={(e) => {
                                    handleAddLink("ownershipStock", 3);
                                  }}
                                >
                                  <CirclePlusIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}

                          {i > 0 && (
                            <Grid item xs="auto">
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton color="error">
                                  <DeleteIcon
                                    style={{ fontSize: "20px" }}
                                    onClick={(e) => {
                                      RemoveData("ownershipStock", i, 3);
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </>
                ))}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mt: -3 }}>
          {props.crudState === "View" ? null : (
            <Grid item xs={12} sx={{ mt: -1 }}>
              <Grid container spacing={2} justifyContent="left">
                <Grid item xs={"auto"}>
                  <LoadingButton
                    buttonName={props.crudState === "Add" ? "Save" : "Update"}
                    clickHandler={props.crudState === "Add" ? addFormData : ""} //addFormData
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <Button
                    variant="contained"
                    color="accent3"
                    onClick={() =>
                     { props.setState({ ...props.state, right: false })
                    props.hideSideDrawer()}
                    }
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* POPUP TO ADD NEW STOCK */}
          {addCustomeStock ? (
            <Dialog open={true} fullWidth onClose={handleClose}>
              <DialogTitle>Add New Stock Exchange</DialogTitle>
              <DialogContent>
                <TextField
                  margin="dense"
                  id="name"
                  name="name"
                  label="Name"
                  type="email"
                  fullWidth
                  required
                  variant="standard"
                  onChange={(event) => {
                    let data = customeStock;
                    data[event.target.name] = event.target.value;
                    setCustomeStock(data);
                  }}
                />
                <TextField
                  margin="dense"
                  id="symbol"
                  name="symbol"
                  label="Symbol"
                  type="email"
                  fullWidth
                  required
                  variant="standard"
                  onChange={(event) => {
                    let data = customeStock;

                    data[event.target.name] = event.target.value;
                    setCustomeStock(data);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>

                <Button onClick={handleaddCustomeStock}>Add</Button>
              </DialogActions>
            </Dialog>
          ) : null}
          {/* POPUP TO ADD NEW OWNER */}
          {addCustomeOwner ? (
            <Dialog open={true} fullWidth onClose={handleCloseOwner}>
              <DialogTitle>Add New Owner</DialogTitle>
              <DialogContent>
                <Autocomplete
                  disablePortal
                  options={popupDropdown}
                  value={popdata}
                  getOptionLabel={(option) => option.companyName}
                  onChange={(e, value) => {
                    setPopdata(value);
                    if (value) {
                      let data = {
                        ownerGuid: value.guid,
                        correctOwnerName: customeOwner.correctOwnerName,
                        legalEntityTypeId: 0,
                      };

                      setCustomeOwner(data);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Owner Name"
                      variant="standard"
                    />
                  )}
                />
                <TextField
                  margin="dense"
                  id="correctOwnerName"
                  name="correctOwnerName"
                  label="Correct Owner Name"
                  type="email"
                  fullWidth
                  required
                  variant="standard"
                  onChange={(event) => {
                    console.log(customeOwner);
                    let data = { ...customeOwner };
                    data[event.target.name] = event.target.value;
                    setCustomeOwner(data);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseOwner}>Cancel</Button>
                {/*  {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Button onClick={handleAddCustomeOwner}>Add</Button>
            )} */}
                <Button onClick={handleAddCustomeOwner}>Add</Button>
              </DialogActions>
            </Dialog>
          ) : null}
          {/* POPUP TO ADD LEGAL ENTITY*/}
          {addLegalEntity ? (
            <Dialog
              open={true}
              fullWidth
              onClose={handleCloseLegal}
              maxWidth={"lg"}
            >
              <DialogTitle>Legal Entity Type</DialogTitle>
              <DialogContent sx={{ pb: 0 }}>
                <Grid item xs={12}>
                  <Box className={classes.setBorder}>
                    <TableContainer sx={{ maxHeight: "calc(100vh - 203px)" }}>
                      <Table
                        stickyHeader
                        // className={classes.table}
                        className={classes.historyTable}
                      >
                        <TableHead>
                          <TableRow hover>
                            {columnsLegalEntity.map((el) => {
                              console.log("Table", el);
                              return (
                                <TableCell
                                  className={
                                    classes.tableHeader &&
                                    classes.cruTheadLeftSep
                                  }
                                  style={{
                                    minWidth: "30px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {el.label}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        {legalentitydropdown.length > 0 ? (
                          <TableBody>
                            {legalentitydropdown.map((row) => (
                              <TableRow key={row.id} hover>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={
                                    classes.tableCell &&
                                    classes.tableCellLeftSep
                                  }
                                  style={{ minWidth: "200px" }}
                                >
                                  {row?.commonVariation?.includes("^") ? (
                                    <>
                                      {row?.commonVariation
                                        ?.split("^")
                                        ?.map((part, index) => {
                                          if (index % 2 === 0) {
                                            return (
                                              <Chip
                                                label={part}
                                                size="small"
                                                key={index}
                                              />
                                            );
                                          } else {
                                            return (
                                              <Chip
                                                label={`${part}`}
                                                size="small"
                                                key={index}
                                                style={{ marginLeft: 2 }}
                                              />
                                            );
                                          }
                                        })}
                                    </>
                                  ) : (
                                    <Chip
                                      label={row.commonVariation}
                                      size="small"
                                    />
                                  )}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={
                                    classes.tableCell &&
                                    classes.tableCellLeftSep
                                  }
                                  style={{ minWidth: "150px" }}
                                >
                                  {row.cruFormat}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={
                                    classes.tableCell &&
                                    classes.tableCellLeftSep
                                  }
                                  style={{ minWidth: "180px" }}
                                >
                                  {row.country}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={
                                    classes.tableCell &&
                                    classes.tableCellLeftSep
                                  }
                                  style={{ minWidth: "30px" }}
                                >
                                  <Tooltip title="Select" placement="top" arrow>
                                    <IconButton
                                      aria-label="edit"
                                      color="accent7"
                                    >
                                      <TaskAltIcon
                                        className={classes.iconSize}
                                        onClick={() => {
                                          setLegalId(row.id);
                                          handleAddCustomeLegalCheck(row.id);
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={99}>
                                <EmptyState
                                  icon={
                                    <FileOffIcon
                                      style={{
                                        color: "#db9400",
                                        fontSize: "40px",
                                      }}
                                    />
                                  }
                                  title="No Data Found"
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ mt: 1, mr: 2 }}>
                <Button onClick={handleCloseLegal}>Cancel</Button>
              </DialogActions>
            </Dialog>
          ) : null}
          {confirmPopUp ? (
            <AlertDialog
              open={confirmPopUp}
              title={"Confirmation"}
              maxWidth={"sm"}
              // ${deleteJobNumber}
              description={`Are you sure you want to delete  ?`} /* Id : ${deleteData} */
              action={
                <Grid container justifyContent="right">
                  <Grid item xs={"auto"}>
                    <Button
                      onClick={() => setConfirmPopUp(false)}
                      style={{ marginRight: "15px" }}
                    >
                      No
                    </Button>
                  </Grid>
                  <Grid item xs={"auto"}>
                    {BtnLoadinng ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        onClick={() => {
                          setConfirmPopUp(false);
                          handleRemoveLink();
                        }}
                        color="secondary"
                      >
                        Yes
                      </Button>
                    )}
                  </Grid>
                </Grid>
              }
            />
          ) : null}

          {openLegal ? (
            <AlertDialog
              open={openLegal}
              title={"Confirmation"}
              maxWidth={"sm"}
              // ${deleteJobNumber}
              description={`Selected Common variation is not matched, Are you sure you want to proceed? `} /* Id : ${deleteData} */
              action={
                <Grid container justifyContent="right">
                  <Grid item xs={"auto"}>
                    <Button
                      onClick={() => setOpenLegal(false)}
                      style={{ marginRight: "15px" }}
                    >
                      No
                    </Button>
                  </Grid>
                  <Grid item xs={"auto"}>
                    {BtnLoadinng ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        onClick={() => handleAddCustomeLegal(legalid)}
                        color="secondary"
                      >
                        Yes
                      </Button>
                    )}
                  </Grid>
                </Grid>
              }
            />
          ) : null}

          <SideDrawer
            formComp={
              <AddReportInfo
                state={state}
                setState={setState}
                jobId={props.jobId}
                jobAssetId={props.jobAssetId}
                crudState={crudState}
                getSourceFile={() => {
                  GetSourceFileDropdown();
                }}
                hideSideDrawer={() => {              
                  setState({ right: false });
                  //getGridData();
                  GetSourceFileDropdown();
                }}
              />
            }
            state={state}
            setState={setState}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddSeeThroughOwnerInfo;
