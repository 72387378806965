// Default React, useState and Material Components
import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

// Importing Custom Services
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";

// Importing Custom Components
import { toasterSuccess, toasterError } from "../../../UIComponent/Toaster";
import LoadingButton from "../../../UIComponent/LoadingButton/LoadingButton";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

const AddPriority = ({
  
  // Declaration of States and Component Variables Start Here
  setState, state, crudState, selectedItem, reload, setReload, }) => {
  const [status, setStatus] = useState(
    selectedItem ? selectedItem.priority : ""
  );
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(selectedItem ? selectedItem.description : "");
  // Declaration of States and Component Variables End Here

// saveData is an async function that saves data to the server
const saveData = async () => {
  // check if status is true
  if (status) {
    // set loading to true
    setLoading(true);
    try {
      // make a post request to the server with the required headers
      await axios
        .post(`${process.env.React_App_baseUrl}managedata/SavePriority`, null, {
          headers: {
            prioriyName: encodeURIComponent(status), prioriyDesc: encodeURIComponent(description), 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          },
        })
        // if successful, set state, reload and show success message
        .then((res) => {
          setState({ ...state, right: false });
          setReload(true);
          setLoading(false);
          toasterSuccess("Status added successfully!");
        })
        // if unsuccessful, call error handling function and show error message
        .catch((err) => {
          ErroHandling(err, function (res) {
            toasterError(res)
          })
        });
    } catch (error) {
      // if unsuccessful, call error handling function and show error message
      ErroHandling(error, function (res) {
        toasterError(res)
      })
    }
  } else { 
    // if status is not true, show error message
    toasterError("Please enter the required fields!"); 
  }
  // set loading to false
  setLoading(false);
};
  
 // This function is used to update data
const updateData = async () => {
  // Check if status is true
  if (status) {
    setLoading(true);
    try {
      // Make a post request to the given URL with the given data and headers
      await axios
        .post(`${process.env.React_App_baseUrl}managedata/UpdatePriority`, {
          id: selectedItem.id,
          name: status,
          description: description,
          createdOn: "2022-11-11T07:54:25.442Z",
        }, {
          headers: {
            'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          }
        })
        .then((res) => {
          // Set state, loading and reload
          setState({ ...state, right: false });
          setLoading(false);
          toasterSuccess("Status edited successfully!");
          setReload(true);
        })
        .catch((err) => {
          // Handle errors
          ErroHandling(err, function (res) {
            toasterError(res)
          })
        });
    } catch (error) {
      // Handle errors
      ErroHandling(error, function (res) {
        toasterError(res)
      })
    }
  } else { 
    // Show error message if required fields are not entered
    toasterError("Please enter the required fields"); 
  }
  setLoading(false);
};

  return (
    // Layout for Add and Edit
    <Box className={classes.popupArea}>

      {/* Title for Side Drawer */}
      <Box className={classes.popupHeading}>
        <Typography id="modal-modal-title" variant="h5">
          {crudState} Priority
        </Typography>
      </Box>

      {/* Add & Edit Commodity Form */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            id="standard-basic"
            label="Priority"
            fullWidth
            required
            variant="standard"
            inputProps={{ maxLength: 50 }}
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="standard-basic"
            label="Description"
            fullWidth
            variant="standard"
            multiline
            maxRows={4}
            value={description}
            inputProps={{ maxLength: 250 }}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <LoadingButton
                buttonName={crudState === "Add" ? "Save" : "Update"}
                clickHandler={crudState === "Add" ? saveData : updateData}
                isLoading={loading}
              />
            </Grid>
            <Grid item xs={'auto'}>
              <Button variant="contained" color="accent3" onClick={() => setState({ ...state, right: false })}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddPriority;