// Default React, Router and Services Components
import React, { useEffect, useState, useMemo } from "react";

// Importing useStyles
import { makeStyles } from "@mui/styles";

// Importing Material Component 
import TextField from "@mui/material/TextField";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  dropdown: {
    width: "400px",
  },
}));

const MultiSelect = ({
  items,
  label,
  selectAllLabel,
  noOptionsText,
  onChange,
  getOptionLabel,
  getOptionSelected,
  value,
  role,
  disabled=false,
  required
}) => {
  const classes = useStyles();

// Declaration of States and Component Variables Start Here

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [statee, setStatee] = useState(true);

// Declaration of States and Component Variables End Here


  const allSelected = items.length === selectedOptions.length;

  // Declaration of React Hooks Start Here
  const memoizedValue = useMemo(() => selectAllLabel, [selectAllLabel]);

  useEffect(() => {
    if (value && value.length > 0) {
      setSelectedOptions(value);
    } else {
      setSelectedOptions([]);
    }
  }, [value]);
  // Declaration of React Hooks End Here

  //Method for toogle option list 
  const handleToggleOption = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    onChange(role, selectedOptions);
  };

  //Method for clear input feild 
  const handleClearOptions = () => {
    setSelectedOptions([]);
    onChange(role, []);
  };

  //Method for change option in dropdown 
/*   const handleChange = (event, selectedOptions, reason) => {

    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find((option) => option === memoizedValue)) {
        let result = [];
        if (statee) {
          result = items.filter((el) => el !== memoizedValue);
          return onChange(role, result);
        } else {
          return onChange(role, []);
        }
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return onChange(role, selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  }; */

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedOptions.find((option) => option === memoizedValue)) {
        if (allSelected) {
          setSelectedOptions([]);
          return onChange(role, []);
        } else {
          setSelectedOptions(items);
          return onChange(role, items);
        }
      } else {
        setSelectedOptions(selectedOptions);
        return onChange(role, selectedOptions);
      }
    } else if (reason === 'clear') {
      setSelectedOptions([]);
      return onChange(role, []);
    }
  };

   //Method for render option in list  
  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option === memoizedValue // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <li {...props}>
        <Checkbox
          defaultChecked
          // color="success"
          style={{ marginRight: 8 }}
          icon={<CheckBoxOutlineBlankIcon />}
          checkedIcon={<CheckBoxIcon />}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </li>
    );
  };
  const inputRenderer = (params) => <TextField {...params} variant="standard" label={label}  required={required} />;
  
  const filter = createFilterOptions();
  return (
    <div >
      <Autocomplete
        multiple
        fullWidth
        limitTags={3}
        isOptionEqualToValue={getOptionSelected}
        options={items}
        value={selectedOptions}
        disabled={disabled}
        disableCloseOnSelect
        getOptionLabel={getOptionLabel}
        noOptionsText={noOptionsText}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return [memoizedValue, ...filtered];
        }}
        onChange={handleChange}
        renderOption={optionRenderer}
        renderInput={inputRenderer}
       
      />
    </div>
  );
};
export default MultiSelect;