//importing Services Components
import axios from "axios";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";
import ErroHandling from "../../../Services/ErrorHandling";
import CommonService from "../../../Services/CommonService";

class ViewSDKClient {
    _adobeKey;
    constructor(adobeKey) {
        this._adobeKey=adobeKey;
        this.readyPromise = new Promise((resolve) => {
            if (window.AdobeDC) {
                resolve();
            } else {
                document.addEventListener("adobe_dc_view_sdk.ready", () => {
                   // console.log("resolve")
                    resolve();
                });
            }
        });
        this.adobeDCView = undefined;
    }
    ready() {
        return this.readyPromise;
    }

    previewFile(divId, receiveData) {
        const config = {
            /* Pass your registered client id */
            //clientId:"8c0cd670273d451cbc9b351b11d22318",
            // Local
            //clientId: "1b0a6cb19eed4595badd40775894e4a4",
            //Develop
           // clientId:"06f11bd89ba046d686b70558cd45bae9"
            // QC
            //clientId:"cc479b0e16f74b4eb3d795a853ad9778"
            clientId:this._adobeKey
        };
        if (divId) { /* Optional only for Light Box embed mode */
            /* Pass the div id in which PDF should be rendered */
            config.divId = divId;
        }
        /* Initialize the AdobeDC View object */
        this.adobeDCView = new window.AdobeDC.View(config);
        /* Invoke the file preview API on Adobe DC View object */
        const previewFilePromise = this.adobeDCView.previewFile({
            /* Pass information on how to access the file */
            content: {
                promise: receiveData.promise
            },
            /* Pass meta data of file */
            metaData: {
                /* file name */
                fileName: receiveData.fileName,
            }
        }, receiveData.viewerConfig);

        //console.log("config", config)

        previewFilePromise.then((adobeViewer) => {
            adobeViewer.getAPIs().then((apis) => {
                apis.gotoLocation(receiveData.pageNumber)
                    .then(() => console.log("Page navigated", receiveData.pageNumber))
                    .catch((error) => ErroHandling(error, function (res) {
                        toasterError(res)}));
            });
        });
        this.adobeDCView.registerCallback(
            window.AdobeDC.View.Enum.CallbackType.SAVE_API,
            (metaData, content, options) => {
              //  console.log(content);
                var uint8Array = new Uint8Array(content);
                var blob = new Blob([uint8Array], { type: 'application/pdf' });
              //  console.log(metaData.fileName);
                //Below properties to convert blob to file object
                blob.lastModifiedDate = new Date();
                blob.name = metaData.fileName;
                var fdata = new FormData();
                fdata.append('file', blob, metaData.fileName);
                //Upload file api
                
                console.log(CommonService.getToken());
                axios.post(process.env.React_App_baseUrl+"job/UploadFile", fdata,{
                    headers: {
                        'Authorization': CommonService.getToken()
                    }
                }).then(() => {
                    toasterSuccess("File Updated Successfully")
                }).catch((err) => {
                    ErroHandling(err, function (res) {
                        toasterError(res)})

                })
                return new Promise(resolve => {
                    setTimeout(() => {
                        const response = {
                            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
                            data: {
                                metaData: Object.assign(metaData, { updatedAt: new Date().getTime() })
                            },
                        };
                        resolve(response);
                    }, 2000);
                });
            },
            {
                autoSaveFrequency: 0,
                enableFocusPolling: false,
                showSaveButton: receiveData.crudState === "Edit" ? true : false
            }
        );
    }
}

export default ViewSDKClient;