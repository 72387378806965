// Default React, Router and Services Components
import React, { useState } from "react";
import { Autocomplete, Box, Button, Checkbox, Chip, FormControlLabel, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import ErroHandling from "../../../Services/ErrorHandling";
import { toasterSuccess, toasterError } from "../../../UIComponent/Toaster";

// Importing Custom Components
import DatePicker from "../../../UIComponent/DatePicker";
import AlertDialog from "../../../UIComponent/AlertDialog";
import { LoaderWrapper } from "../../../UIComponent/Progress";
import CustomAutoComplete from "../../../UIComponent/CustomAutoComplete";
import { useHistory } from "react-router-dom";
// Importing useStyles
import useStyles from "./jobManagement.styles";

// Import Fluent UI Icons
import { AcceptIcon, CancelIcon, ClearIcon, DeleteIcon, EditIcon, SearchIcon, } from "@fluentui/react-icons-mdl2";
import CommonService from "../../../Services/CommonService";
import { toast } from "react-toastify";
import { use } from "i18next";
import MultiSelect from "../../../UIComponent/MultiSelect/MultiSelect";

const AddRecords = (props) => {
  // Declaration of States and Component Variables Start Here
  let [formData, setFormData] = useState({ "jobtypeId": 1, "checkValue": false, checkJobStartValue: false })
  const classes = useStyles();
  const { jobid, companyData, setOpenRecords } = props;
  // const { jobid, setJobId } = useState(null);
  // const { companyData, setCompanyDatum } = useState([]);
  // let setOpenRecords = null;

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fetchedDetails, setFetchedDetails] = useState({ jobId: null, jobName: "", jobInstructions: "" });
  const [tableData, setTableData] = useState([]);
  const [priority, setPriority] = useState([]);
  const [deletedRow, setDeletedRow] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [finalData, setFinalData] = useState({
    jobId: 0, jobName: "string", jobInstructions: "string", jobAsset: []
    ,
    DataPointsCODto: {
      lattitude: false,
      longitude: false,
      owner: false,
      joinVentures: false,
      ownershipDate: false,
      operator: false,
      ownershipPercentage: false,
      seeThroughOwners: false,
      stockExchange: false,
      updates: false,
      yearId: null
    },
    JobYearsDto: []
  });
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [searchText, setSearchText] = useState("")
  const [reload, setReload] = useState(false)
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [longitude, setLongitude] = useState(false);
  const [latitude, setLatitude] = useState(false);
  const [owner, setOwner] = useState(false);
  const [operator, setOperator] = useState(false);
  const [isListedCompany, setIsListedCompany] = useState(false);
  const [ownershipDate, setOwnershipDate] = useState(false);
  const [stockExchange, setStockExchange] = useState(false);
  const [ownershipPercentage, setOwnershipPercentage] = useState(false);
  const [seeThroughOwners, setSeeThroughOwners] = useState(false);
  const [jointVentures, setJointVentures] = useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [updates, setUpdates] = useState(false);
  const [selectAllDataPoints, setSelectAllDataPoints] = useState(false);
  // Declaration of States and Component Variables End Here
  useEffect(() => {
    getYear();
  }, [props])

  const handleKeyUp = (event) => {

    if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
      if (searchValue.length === 0) { getRecords(); }
    }
    if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
      getRecords(searchValue);
    }
  };
  const globalSearch = (value) => {
    setSearchValue(value);
  };
  const GetHistory = () => {
    //let temp = CommonService.GetPropsStoredData(props, "addrecords");
    let tempHistoryData = CommonService.GetStoredData(props, "historyData");
    if (tempHistoryData) {
      // if (tempHistoryData.jobid) {
      //   setJobId(tempHistoryData.state);
      // }
    }
    // if(temp){
    //   if (temp.companyData) {
    //     setCompanyDatum(temp.companyData);
    //   }
    //   if (temp.setOpenRecords) {
    //     setOpenRecords = temp.setOpenRecords
    //   }
    // }





  }
  const getPriorityList = async () => {
    await axios
      .get(process.env.React_App_baseUrl + "DropDown/GetPriorityDropDownFilter", {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then(function (response) {
        const rows = response.data.map((row) => createPirorityData(row.priorityName, row.id));
        setPriority(rows);
      })
      .catch(function (err) { ErroHandling(err, function (res) { toasterError(res) }) });
  };

  const getRecords = async (searchValue) => {

    // const getData = data.filter(item => item.isSelected === true).map((el) => {
    //   
    //   let finalData = {
    //     companyId: el.companyId,
    //     isCRUCompany: el.isCRUCompany ? el.isCRUCompany : false,
    //     assetId: el.assetId ? el.assetId : 0,
    //     isCRUAsset: el.isCRUAsset ? el.isCRUAsset : false,
    //     jobAssetId: 0,
    //   };
    //   return finalData;
    // });

    setLoader(true);
    const getData = [];
    companyData.forEach((element) => {
      if (element.isSelected) {
        getData.push({
          "isCRUAsset": element.isCRUAsset,
          "assetId": element.assetId,
          "asset": element.asset,
          "companyId": element.companyId,
          "company": element.company,
        })
      }
    })

    setData(getData);
    var filterValues;
    if (props.filterValue) {
      let s = props.searchValue ? { searchText: props.searchValue } : "";
      filterValues = { ...props.filterValue, ...s };
    } else {
      filterValues = props.searchValue ? { searchText: props.searchValue } : "";
    }
    await axios
      .post(`${process.env.React_App_baseUrl}companyownershipjob/CompanyOwnershipGetJobRecords`, getData, {
        headers: {
          jobId: jobid,
          sourceFilter: filterValues
          ? encodeURIComponent(JSON.stringify(filterValues))
          : "",
        selectAll:props.isAllSelected,
          searchText: encodeURIComponent(searchValue),
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        },
      })
      .then((res) => {
        setLoader(false);
        setFetchedDetails({
          ...fetchedDetails,
          jobId: res.data.jobId,
          jobName: res.data.jobName,
          jobInstructions: res.data.jobInstructions,
          jobNo: res.data.jobNo
        });
        if (res?.data?.dataPointsCODto) {
          setLongitude(res?.data?.dataPointsCODto?.longitude)
          setLatitude(res?.data?.dataPointsCODto?.lattitude)
          setOwner(res?.data?.dataPointsCODto?.owner)
          setOperator(res?.data?.dataPointsCODto?.operator)
          setOwnershipDate(res?.data?.dataPointsCODto?.ownershipDate)
          setStockExchange(res?.data?.dataPointsCODto?.stockExchange)
          setOwnershipPercentage(res?.data?.dataPointsCODto?.ownershipPercentage)
          setSeeThroughOwners(res?.data?.dataPointsCODto?.seeThroughOwners)
          setJointVentures(res?.data?.dataPointsCODto?.joinVentures)
          setUpdates(res?.data?.dataPointsCODto?.updates)
          if (res?.data?.dataPointsCODto?.longitude
            && res?.data?.dataPointsCODto?.lattitude
            && res?.data?.dataPointsCODto?.owner
            && res?.data?.dataPointsCODto?.operator
            && res?.data?.dataPointsCODto?.ownershipDate
            && res?.data?.dataPointsCODto?.stockExchange
            && res?.data?.dataPointsCODto?.ownershipPercentage
            && res?.data?.dataPointsCODto?.seeThroughOwners
            && res?.data?.dataPointsCODto?.joinVentures
            && res?.data?.dataPointsCODto?.updates) {
            setSelectAllDataPoints(true);
          }
        }



        if (res.data.jobYearsDto) {
          let tempSelectedYears = res.data.jobYearsDto.map(ele => {
            return { id: ele.yearId, year: ele.year }
          });
          setSelectedYear(tempSelectedYears);
        }
        setFinalData({
          ...finalData,
          jobId: res.data.jobId,
          jobName: res.data.jobName,
          jobInstructions: res.data.jobInstructions,
          DataPointsCODto: {
            lattitude: res.data?.dataPointsCODto?.lattitude,
            longitude: res.data?.dataPointsCODto?.longitude,
            owner: res.data?.dataPointsCODto?.owner,
            joinVentures: res.data?.dataPointsCODto?.joinVentures,
            ownershipDate: res.data?.dataPointsCODto?.ownershipDate,
            operator: res.data?.dataPointsCODto?.operator,
            ownershipPercentage: res.data?.dataPointsCODto?.ownershipPercentage,
            seeThroughOwners: res.data?.dataPointsCODto?.seeThroughOwners,
            stockExchange: res.data?.dataPointsCODto?.stockExchange,
            updates: res.data?.dataPointsCODto?.updates
          },
          JobYearsDto: res?.data?.jobYearsDto
        });

        let tableData = res.data;
        let tableRows = [];
        tableData?.companyAssetDetailsDto?.forEach(el => {
          if (!deletedRow?.some(x => x.assetId === el.assetId && x.companyId === el.companyId && x.isCRUAsset === el.isCRUAsset)) {

            tableRows.push({
              ...el, tat: (el.tat || "").split("-").reverse().join("-")
            });
          }
        });

        setTableData(tableRows);

      })
      .catch((err) => {
        {
          ErroHandling(err, function (res) {
            if (err.response.status === 400) {
              toasterError(err.response.data);
            }
          })
          setLoader(false);
        }
      });
  };

  const addRecords = async () => {

    function One() {

      setFinalData({
        ...finalData,
        jobAsset: tableData.map((el) => {
          let jobAssetId = 0;
          if (el.jobAssetId !== null && el.jobAssetId !== undefined && el.jobAssetId !== '') {
            jobAssetId = el.jobAssetId;
          }
          return {
            jobAssetId: jobAssetId,
            priorityId: el.priorityId,
            tat: el.tat ? new Date(el.tat) : null,
            startDate: el.startDate ? el.startDate.split("-").reverse().join("-") : null,
            remainingDays: el.remainingDays,
            assignedTo: el.assignedTo ? el.assignedTo : "NA",
            assignedToId: el.assignedToId,
            isEdited: true,
            isDeleted: el.isDeleted,
            companyId: el.companyId ? el.companyId : 0,
            isCRUCompany: el.isCRUCompany,
            assetId: el.assetId,
            isCRUAsset: el.isCRUAsset,
          };
        }),
      });
      if (props?.deltedRow?.length) {
        finalData.jobAsset = [...finalData.jobAsset, ...props?.deltedRow]
      }
      openAlertSaveDialog()
    }
    One()
  };

  function callApi() {

    axios
      .post(`${process.env.React_App_baseUrl}companyownershipjob/CompanyOwnershipSaveJobRecords`, finalData, {
        headers: {
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      })
      .then((res) => {
        setOpenSaveConfirmation(false);
        setOpenRecords(false)
        toasterSuccess(`Record(s) added successfully!`)
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  }


  useEffect(() => {
    getPriorityList();
  }, [reload]);

  useEffect(() => { getRecords(searchText); }, [reload])
  function createPirorityData(label, id) { return { label, id }; }

  const openAlertSaveDialog = () => { setOpenSaveConfirmation(true); }

  const handleADClose = () => { setOpenSaveConfirmation(false); }

  // const globalSearch = (e) => {
  //   if (e.key === "Enter") { setReload(!reload); }
  // };


  const handleJobStartDateChange = (e) => {
    if (e !== null && new Date(e).toDateString() !== 'Invalid Date') {
      let selectedDate = new Date(e);
      let date = selectedDate.getFullYear() + '-' + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (selectedDate.getDate())).slice(-2)
      setFormData({ ...formData, startDate: date, checkJobStartValue: false })
      // GetNoOfDays(selectedDate);
    } else {
      setFormData({ ...formData, startDate: undefined, checkValue: false, checkJobStartValue: false })
    }

  }



  const CommonJobStartDate = (e, value) => {
    if (formData.startDate) {

      //let remainingDaysTemp=CommonService.getDayDifference(new Date(formData.tat))

      if (value) {
        setFormData({ ...formData, checkJobStartValue: value })
        let updatedRow = [];
        let promise = Promise.all(tableData.map(async (element) => {
          if (element.companyId > 0 && element.assetId > 0) {
            let assetDetails = {
              companyId: element.companyId,
              assetId: element.assetId,
              isCRUCompany: element.isCRUCompany,
              isCRUAsset: element.isCRUAsset
            }
            await AsyncGetNoOfDays(assetDetails).then((res) => {
              let tempStartDate = new Date(formData.startDate);
              let g = CommonService.addWorkingDays(tempStartDate, res.data)
              let newTatDate = CommonService.GetDateAsStringWithDash(g)
              let remainingDaysTemp = CommonService.getBusinessDatesCount(g)
              updatedRow.push({ ...element, startDate: formData.startDate.split("-").reverse().join("-"), remainingDays: remainingDaysTemp, tat: newTatDate.split("-").reverse().join("-") })
            })
          }
        }))
        promise.then(() => {
          // console.log("test",updatedRow)
          if (updatedRow.length > 0) {

            setTableData(updatedRow)
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    } else {
      toasterError("Please select Job Start Date");
    }
  }

  const AsyncGetNoOfDays = async (finalAssetDetailObject) => {
    return axios.get(
      `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
      headers: {
        CompanyId: finalAssetDetailObject.companyId,
        AssetId: finalAssetDetailObject.assetId,
        IsCRUCompany: finalAssetDetailObject.isCRUCompany,
        IsCRUAsset: finalAssetDetailObject.isCRUAsset,
        'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
      }
    })

  }
  const searchClear = () => { setSearchValue(""); getRecords(""); };
  const getYear = () => {
    axios
      .get(
        process.env.React_App_baseUrl + "DropDown/GetYears",
        {
          headers: {
            Authorization: CommonService.getToken()
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setYears(res.data);
        } else {
          toasterError("Something went wrong");
        }
      })
      .catch((err) => {
        ErroHandling(err, function (res) {
          toasterError(res);
        });
      });
  };


  return (
    <LoaderWrapper loading={isLoader}>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} md={3} lg={2} xl={1}>
          <TextField
            id="standard-basic"
            label="Job ID"
            variant="standard"
            fullWidth
            readOnly
            // defaultValue="1005"
            value={Number(fetchedDetails.jobNo)}
            InputProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={9} lg={10} xl={5}>
          <TextField
            id="standard-basic"
            label="Job Name"
            variant="standard"
            value={fetchedDetails.jobName}
            fullWidth
            required={true}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={6}>
          <TextField
            fullWidth
            id="standard-multiline-static"
            label="Job Instructions"
            multiline
            maxRows={2}
            value={fetchedDetails.jobInstructions}
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} >
          <DatePicker
            labelTitle="Job Start Date(DD-MM-YYYY)"
            variant="standard"
            past={true}
            defaultDate={null}
            onChangeDate={(e) => { handleJobStartDateChange(e) }} />
        </Grid>
        <Grid item xs={12} md={5} lg={4} xl={3} sx={{ mt: 2 }}>
          <FormControlLabel control={<Checkbox checked={formData.checkJobStartValue} onChange={(e, value) => CommonJobStartDate(e, value)} />} label="Apply Common Job Start Date" />
        </Grid>
        <Grid item xs={12} md={5} lg={4} xl={3}>
          <MultiSelect
            value={selectedYear}
            selectAllLabel={{ year: "Select all" }}
            items={years ? years : []}
            getOptionLabel={(option) => option.year.toString()}
            label={"Year"}
            onChange={(key, value, reason) => {
              if (value !== null && value !== undefined) {
                setSelectedYear(value);
                let tempYears = value.map(ele => {
                  return { YearId: ele.id, Year: ele.year }
                })
                setFinalData(prevUpdateApi => ({ ...prevUpdateApi, JobYearsDto: tempYears }));

              }
            }}
            role="year"
            key="year"
            required={true}
          />
        </Grid>


        <Grid item xs={12}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={1.5}
            justifyContent="left"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Grid xs="auto" sx={{ mr: 1 }}>
                <Typography color="text.primary" sx={{ mt: 1 }}>
                  <strong>Select Data Points</strong>
                  <FormControlLabel sx={{ ml: 1 }}
                    control={
                      <Checkbox
                        onChange={(event, value) => {
                          if (value !== null && value !== undefined) {
                            setLongitude(value)
                            setLatitude(value)
                            setOwner(value)
                            setOperator(value)
                            setOwnershipDate(value)
                            setStockExchange(value)
                            setOwnershipPercentage(value)
                            setSeeThroughOwners(value)
                            setJointVentures(value)
                            setUpdates(value)
                            setFinalData(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, lattitude: value, longitude: value, owner: value, operator: value, ownershipDate: value, ownershipPercentage: value, stockExchange: value, seeThroughOwners: value, joinVentures: value, updates: value } }));
                          }
                          setSelectAllDataPoints(!selectAllDataPoints);

                        }}
                        checked={selectAllDataPoints}
                      />
                    }
                    label="Select All"
                  />
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.removeCheckMargin}>
              <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item xs={"auto"}>
                  <div className={classes.subHeading}>Asset Info:</div>{" "}
                </Grid>
                <Grid item xs={"auto"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event, value) => {

                          if (value !== undefined && value !== null) {
                            setLongitude(!longitude);
                            setFinalData(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, lattitude: value } }));
                            if (value
                              && latitude
                              && owner
                              && operator
                              && ownershipDate
                              && stockExchange
                              && ownershipPercentage
                              && seeThroughOwners
                              && jointVentures
                              && updates) {
                              setSelectAllDataPoints(true);
                            } else {
                              setSelectAllDataPoints(false);
                            }
                          }

                        }}
                        checked={longitude}
                      />
                    }
                    label="Longitude"
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event, value) => {

                          if (value !== undefined && value !== null) {
                            setLatitude(!latitude);
                            setFinalData(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, longitude: value } }));
                            if (longitude
                              && value
                              && owner
                              && operator
                              && ownershipDate
                              && stockExchange
                              && ownershipPercentage
                              && seeThroughOwners
                              && jointVentures
                              && updates) {
                              setSelectAllDataPoints(true);
                            } else {
                              setSelectAllDataPoints(false);
                            }
                          }
                        }}
                        checked={latitude}
                      />
                    }
                    label="Latitude"
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event, value) => {
                          if (value !== undefined && value !== null) {
                            setOperator(!operator);
                            setFinalData(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, operator: value } }));
                            if (longitude
                              && latitude
                              && owner
                              && value
                              && ownershipDate
                              && stockExchange
                              && ownershipPercentage
                              && seeThroughOwners
                              && jointVentures
                              && updates) {
                              setSelectAllDataPoints(true);
                            } else {
                              setSelectAllDataPoints(false);
                            }
                          }
                        }}
                        checked={operator}
                      />
                    }
                    label="Operator"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.removeCheckMargin}>
              <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item xs={"auto"}>
                  <div className={classes.subHeading}>Owner Info:</div>{" "}
                </Grid>
                <Grid item xs={"auto"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event, value) => {
                          if (value !== undefined && value !== null) {
                            setOwner(!owner);
                            setFinalData(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, owner: value } }));
                            if (longitude
                              && latitude
                              && value
                              && operator
                              && ownershipDate
                              && stockExchange
                              && ownershipPercentage
                              && seeThroughOwners
                              && jointVentures
                              && updates) {
                              setSelectAllDataPoints(true);
                            } else {
                              setSelectAllDataPoints(false);
                            }
                          }
                        }}
                        checked={owner}
                      />
                    }
                    label="Owner"
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event, value) => {

                          if (value !== undefined && value !== null) {
                            setOwnershipPercentage(!ownershipPercentage);
                            setFinalData(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, ownershipPercentage: value } }));
                            if (longitude
                              && latitude
                              && owner
                              && operator
                              && ownershipDate
                              && stockExchange
                              && value
                              && seeThroughOwners
                              && jointVentures
                              && updates) {
                              setSelectAllDataPoints(true);
                            } else {
                              setSelectAllDataPoints(false);
                            }
                          }
                        }}
                        checked={ownershipPercentage}
                      />
                    }
                    label="Ownership %"
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event, value) => {

                          if (value !== undefined && value !== null) {
                            setOwnershipDate(!ownershipDate);
                            setFinalData(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, ownershipDate: value } }));
                            if (longitude
                              && latitude
                              && owner
                              && operator
                              && value
                              && stockExchange
                              && ownershipPercentage
                              && seeThroughOwners
                              && jointVentures
                              && updates) {
                              setSelectAllDataPoints(true);
                            } else {
                              setSelectAllDataPoints(false);
                            }
                          }

                        }}
                        checked={ownershipDate}
                      />
                    }
                    label="Ownership Date"
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event, value) => {
                          if (value !== undefined && value !== null) {
                            setStockExchange(!stockExchange);
                            setFinalData(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, stockExchange: value } }));
                            if (longitude
                              && latitude
                              && owner
                              && operator
                              && ownershipDate
                              && value
                              && ownershipPercentage
                              && seeThroughOwners
                              && jointVentures
                              && updates) {
                              setSelectAllDataPoints(true);
                            } else {
                              setSelectAllDataPoints(false);
                            }
                          }
                        }}
                        checked={stockExchange}
                      />
                    }
                    label="Stock Exchange"
                  />
                </Grid>
              </Grid>

            </Grid>

            <Grid item xs={12} className={classes.removeCheckMargin}>
              <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item xs={"auto"}>
                  <div className={classes.subHeading}>Joint Venture:</div>{" "}
                </Grid>
                <Grid item xs={"auto"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event, value) => {
                          if (value !== undefined && value !== null) {
                            setJointVentures(!jointVentures);
                            setFinalData(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, joinVentures: value } }));
                            if (longitude
                              && latitude
                              && owner
                              && operator
                              && ownershipDate
                              && stockExchange
                              && ownershipPercentage
                              && seeThroughOwners
                              && value
                              && updates) {
                              setSelectAllDataPoints(true);
                            } else {
                              setSelectAllDataPoints(false);
                            }
                          }
                        }}
                        checked={jointVentures}
                      />
                    }
                    label="Joint Ventures"
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event, value) => {

                          if (value !== undefined && value !== null) {
                            setSeeThroughOwners(!seeThroughOwners);
                            setFinalData(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, seeThroughOwners: value } }));
                            if (longitude
                              && latitude
                              && owner
                              && operator
                              && ownershipDate
                              && stockExchange
                              && ownershipPercentage
                              && value
                              && jointVentures
                              && updates) {
                              setSelectAllDataPoints(true);
                            } else {
                              setSelectAllDataPoints(false);
                            }
                          }
                        }}
                        checked={seeThroughOwners}
                      />
                    }
                    label="Equity Owners"
                  />
                </Grid>
              </Grid>

            </Grid>

            <Grid item xs={12} className={classes.removeCheckMargin}>
              <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item xs={"auto"}>
                  <div className={classes.subHeading}>Updates:</div>{" "}
                </Grid>
                <Grid item xs={"auto"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event, value) => {
                          if (value !== undefined && value !== null) {
                            setUpdates(!updates);
                            setFinalData(prevUpdateApi => ({ ...prevUpdateApi, DataPointsCODto: { ...prevUpdateApi.DataPointsCODto, updates: value } }));
                            if (longitude
                              && latitude
                              && owner
                              && operator
                              && ownershipDate
                              && stockExchange
                              && ownershipPercentage
                              && seeThroughOwners
                              && jointVentures
                              && value) {
                              setSelectAllDataPoints(true);
                            } else {
                              setSelectAllDataPoints(false);
                            }
                          }
                        }}
                        checked={updates}
                      />
                    }
                    label="Updates"
                  />
                </Grid>
              </Grid>


            </Grid>
          </Grid>
        </Grid>


        <Grid item xs={12}>
          <Paper elevation={0} className={classes.addBorder}>
            <Grid item>
              <Grid container alignItems="center" justifyContent="left" spacing={2} sx={{ pt: 1, pb: 2, pr: 2, pl: 2 }} >
                <Grid item xs={12}>
                  <TextField
                    id="input-with-icon-textfield"
                    className="serchByKeyword mt-1"
                    value={searchValue}
                    placeholder="Search by Keyword"
                    onKeyUp={handleKeyUp}
                    onChange={(e) => globalSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon className={classes.searchIcon} />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  {searchValue ? (
                    <IconButton aria-label="Add" className="serchByClear" onClick={searchClear}>
                      <ClearIcon style={{ fontSize: 10 }} />
                    </IconButton>
                  ) : ("")}
                </Grid>
              </Grid>
            </Grid>
            <LoaderWrapper className={classes.minHeightTable} loading={isLoading}>
              <TableContainer component={Paper} elevation={0}
                sx={{ "& > *": { borderBottom: "unset" }, boxShadow: 0 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader} align="left"
                        style={{ minWidth: 200, maxWidth: "20%" }}>
                        Asset
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left"
                        style={{ minWidth: 150, maxWidth: "13%" }}>
                        Owner
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center"
                        style={{ minWidth: 150, maxWidth: "13%" }}>
                        Ownership %
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left"
                        style={{ minWidth: 150, maxWidth: "22%" }}>
                        Operator
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center"
                        style={{ minWidth: 140 }}>
                        Priority
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center"
                        style={{ minWidth: 140, maxWidth: "15%" }}>
                        TAT
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center"
                        style={{ minWidth: 140, maxWidth: "15%" }}>
                        Remaining Days
                      </TableCell>

                      <TableCell className={classes.tableHeader} align="left"
                        style={{ minWidth: 150, maxWidth: "13%" }}>
                        Country
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left"
                        style={{ minWidth: 150, maxWidth: "13%" }}>
                        Region
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="left"
                        style={{ minWidth: 150, maxWidth: "13%" }}>
                        Sub Region
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="center"
                        style={{ minWidth: 100 }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {tableData?.map((row) => (
                      <EditableTable
                        row={row}
                        setTableRow={setTableData}
                        tableRow={tableData}
                        priority={priority}
                        deletedRow={deletedRow}
                        setDeletedRow={setDeletedRow}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </LoaderWrapper>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="right">
            <Grid item xs={12} sm={"auto"}>
              <Button variant="contained" color="accent5" onClick={addRecords}>
                Save
              </Button>
            </Grid>
            <Grid item xs={12} sm={"auto"}>
              <Button variant="contained" color="accent3" onClick={() => setOpenRecords(false)}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openSaveConfirmation}
        onClose={handleADClose}
        description="Would you like to proceed with the changes?"
        action={
          <Grid container spacing={2} justifyContent="right">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>Cancel</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button onClick={callApi} color="secondary">Yes</Button>
            </Grid>
          </Grid>
        }
      />
    </Box>
    </LoaderWrapper>
  );
};

export default AddRecords;
const EditableTable = (props) => {
  // Declaration of States and Component Variables Start Here
  let [edit, setEdit] = useState(false);
  let [value, setValue] = useState();
  const classes = useStyles();
  const [openConfirmation, setConfirmation] = useState(false);
  const handleADClose = () => { setConfirmation(false); };
  const editData = () => { setEdit(!edit); };
  const openAlertDialog = () => { setConfirmation(true); };
  const DeleteData = () => {
    let arr = [...props.tableRow];
    let deleteRecord = props?.deletedRow;
    props.row.isDeleted = true
    deleteRecord.push(props.row);
    props?.setDeletedRow(deleteRecord);
    let index = arr.indexOf(props.row);
    arr.splice(index, 1);
    props.setTableRow(arr);
    handleADClose();
  };
  const [reload, setReload] = useState(false);
  const CancelData = () => { setValue(); setEdit(!edit); };
  const saveData = () => {

    props.row.priorityName = value?.priorityName
      ? value?.priorityName
      : props.row.priorityName;
    props.row.priorityId = value?.priorityId
      ? value?.priorityId
      : props.row.priorityId;
    props.row.tat = value?.tat ? value.tat : props.row.tat;
    props.row.startDate = value?.startDate ? value.startDate : props.row.startDate;
    props.row.remainingDays = CommonService.getBusinessDatesCount(new Date(props.row.tat))
    setEdit(!edit);
  };
  const totChange = (e) => { setValue({ ...value, tat: e }); };
  const startDateChange = (e) => {
    //  setValue({ ...value, startDate: e }); 
    if (props.row.companyId > 0 && props.row.assetId > 0) {

      axios.get(
        `${process.env.React_App_baseUrl}job/GetNoOfDays`, {
        headers: {
          CompanyId: props.row.companyId,
          AssetId: props.row.assetId,
          IsCRUCompany: props.row.isCRUCompany,
          IsCRUAsset: props.row.isCRUAsset,
          'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
        }
      }).then((res) => {
        if (res.status === 200) {
          let tempStartDate = new Date(e);

          let g = CommonService.addWorkingDays(tempStartDate, res.data)
          let newTatDate = CommonService.GetDateAsStringWithDash(g)
          let remainingDaysTemp = CommonService.getBusinessDatesCount(g)
          props.row.tat = newTatDate;
          props.row.startDate = e;
          props.row.remainingDays = remainingDaysTemp;
          setReload(!reload)
          // setEdit(!edit);

        }
      })
    }
  };
  const priorityChange = (ab) => { setValue({ ...value, priorityName: ab.label, priorityId: ab.id }); };
  // Declaration of States and Component Variables End Here
  //console.log(props.row, "props.row")
  return (
    <>
      <TableRow hover className={classes.cruTr} key={props.row.companyName}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align="left" className={classes.tableCell}>
          {props.row.assetName}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {props.row.companyName}
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          {props.row.ownershipPercentage}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {/* {props.row.assignedTo ? props.row.assignedTo : "NA"} */}
          {props.row.operatorName}
        </TableCell>

        {!edit ? (
          <TableCell align="center" className={classes.tableCell}>
            {props.row.priorityName === "High" ? (
              <Chip
                label={props.row.priorityName}
                size="small"
                color="warning"
                className="statusChip"
              />
            ) : undefined}{" "}
            {props.row.priorityName === "Low" ? (
              <Chip
                style={{ color: "blue" }}
                label={props.row.priorityName}
                size="small"
                color="success"
                className="statusChip"
              />
            ) : undefined}{" "}
            {props.row.priorityName === "Medium" ? (
              <Chip
                label={props.row.priorityName}
                size="small"
                color="info"
                className="statusChip"
              />
            ) : undefined}
            {props.row.priorityName === "Critical" ? (
              <Chip
                label={props.row.priorityName}
                size="small"
                color="error"
                className="statusChip"
              />
            ) : undefined}
          </TableCell>
        ) : (
          <TableCell align="center" className={`${classes.tableCell} ${classes.paddingRight}`}>
            <div className={classes.smallAlign}>
              <CustomAutoComplete
                options={props.priority}
                sx={{ width: 300 }}
                label="Priority"
                disableClearable={true}
                optionLabelKey="label"
                optionValueKey="id"
                onChange={(value) => { priorityChange(value); }}
                defaultValueKey={["id", "label"]}
                defaultValue={[props.row.priorityId, props.row.priorityName]}
              />
            </div>
          </TableCell>
        )}


        {!edit ? (
          <TableCell align="center" className={classes.tableCell}>
            {CommonService.GetDateInReverse(props.row.tat)}
            {/* {props.row.tat} */}
          </TableCell>
        ) : (
          <TableCell align="left" className={classes.tableCell}>
            <div style={{ width: 120 }}>
              <DatePicker
                labelTitle="TAT"
                variant="standard"
                past={true}
                defaultDate={props.row.tat}
                onChangeDate={(e) => {

                  let selectedDate = new Date(e);
                  let date =
                    selectedDate.getFullYear() +
                    "-" +
                    ("0" + (selectedDate.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + selectedDate.getDate()).slice(-2);
                  totChange(date);
                  props.row.remainingDays = CommonService.getBusinessDatesCount(new Date(selectedDate))
                }}
              />
            </div>
          </TableCell>
        )}
        <TableCell align="center" className={classes.tableCell}>
          {props.row.remainingDays}
        </TableCell>

        <TableCell align="left" className={classes.tableCell}>
          {props.row.countryName}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>

          {props.row.regionName}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          {props.row.subRegionName}
        </TableCell>

        {!edit ? (
          <TableCell align="center" className={classes.tableCell}>
            <Tooltip title="Edit" placement="top" arrow>
              <IconButton aria-label="edit" color="primary">
                <EditIcon className={classes.iconSize} onClick={editData} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="top" arrow>
              <IconButton aria-label="Delete" sx={{ color: "red" }}>
                <DeleteIcon className={classes.iconSize} onClick={openAlertDialog} />
              </IconButton>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell align="center" className={classes.tableCell}>
            <Tooltip title="Submit" placement="top" arrow>
              <IconButton aria-label="Submit">
                <AcceptIcon className={classes.iconSize} onClick={saveData} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel" placement="top" arrow>
              <IconButton aria-label="Cancel" color="secondary">
                <CancelIcon className={classes.iconSize} onClick={CancelData} />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>

      <AlertDialog
        title={"Confirmation"}
        maxWidth={"sm"}
        id="alert-delete"
        open={openConfirmation}
        onClose={handleADClose}
        description="The asset will be deleted from the job. Are you sure you want to delete?"
        action={
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={'auto'}>
              <Button onClick={handleADClose}>No</Button>
            </Grid>
            <Grid item xs={'auto'}>
              <Button onClick={DeleteData} color="secondary">
                Yes
              </Button>
            </Grid>
          </Grid>
        }
      />

    </>
  );
};