import * as redux from 'redux';
import reduxThunk from 'redux-thunk';
import {
    loaderReducer
} from './Reducers/index';

const combined = redux.combineReducers({
    loaderReducer
});

const store = redux.createStore(combined, redux.applyMiddleware(reduxThunk));
export default store;