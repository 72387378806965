import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  breadCrumbsIcon: {
    fontSize: 10,
    marginLeft: 3
  },
  container: {
    maxHeight: 640,
  },
  tableVerticalScroll: {
    maxHeight: "539px",
    overflowY: 'auto'
  },
  tableBorder: {
    border: '1px solid #d7d7d7',
    borderRadius: 6,
    overflow: 'hidden',
  },
  tableTopPart: {
    padding: theme.spacing(2)
  },
  colHighlight: {
    padding: theme.spacing(0, 0, 0, 1) + '!important',
  },
  tableHeader: {
    padding: theme.spacing(0, 0.75) + '!important',
    whiteSpace: "normal",
    fontWeight: 'bold !important',
    height: "46px !important",
    lineHeight: 1.25 + '!important',
  },
  tableCell: {
    wordBreak: "normal",
    wordWrap: "normal",
    borderBottom: "1px solid #d7d7d7 !important",
    padding: theme.spacing(0, 0.75) + '!important',
    height: "46px !important",
    lineHeight: 1.25 + '!important',
  },
  tableActionCell: {
    padding: theme.spacing(0, 0.75) + '!important',
  },
  cellNoWrap: {
    whiteSpace: "nowrap"
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  helperText: {
    display: 'block',
    whiteSpace: "nowrap",
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  addBorder: {
    borderRadius: '6px',
    border: "1px solid rgba(224, 224, 224, 1) !important",
  },
  totalRecord: {
    color: '#00519c'
  },
  shortDetails: {
    fontSize: '14px !important',
    fontFamily: "Roboto, sans-serif",
  },
  boldText: {
    fontWeight: 700,
  },
  twoValues: {
    marginRight: "40px",
    fontSize: '14px !important',
    fontFamily: "Roboto, sans-serif",
    display: "inline-block",
  },
  largeButton: {
    fontWeight: 'bold',
    minWidth: '230px !important',

    [theme.breakpoints.up("sm")]: {
      minWidth: '210px !important',
      width: '210px !important',
    },
    [theme.breakpoints.up("md")]: {
      minWidth: '190px !important',
      width: '190px !important',
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: '180px !important',
      width: '180px !important',
    },
  },
  iconSize: {
    fontSize: "20px",
    width: "1em",
    height: "1em",
  },
  smallAlign: {
    margin: "0 !important",
    "& .MuiAutocomplete-root": {
      margin: "0 !important",
    },
  },
  jobShortDetails: {
    border: '1px solid #d7d7d7',
    borderRadius: 6,
    overflow: 'hidden',
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0, 2, 0),
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
  },
  largeButtonAuto: {
    minWidth: 230,

    [theme.breakpoints.up("sm")]: {
      minWidth: 'auto',
      width: 'auto',
    },
  },
  posRelative: {
    borderRadius: '6px',
    border: "1px solid rgba(224, 224, 224, 1)",
    position: 'relative',
  },
  recordArea: {
    position: 'absolute',
    display: 'inline-block',
    top: '-15px',
    left: '10px',
    background: "#ffffff",
    padding: theme.spacing(0, 1),
    color: '#00519c',
  },
  iconArea: {
    position: 'absolute',
    top: '-19px',
    right: '10px',
    background: "#ffffff",
    padding: theme.spacing(0, 1),
  },
  recordTitleArea: {
    position: 'relative',
    zIndex: '0',
    background: '#ffffff',
    height: '30px',
  },
  recordLine: {
    height: '2px',
    width: '100%',
    background: '#00519c',
    position: 'absolute',
    top: '10px',
    left: '0px',
    zIndex: '90'
  },
  recordTitle: {
    position: 'absolute',
    top: '-9px',
    left: '0px',
    background: "#ffffff",
    padding: theme.spacing(1, 1, 1, 0),
    zIndex: '99',
    fontWeight: 'bold',
    color: '#00519c',
    fontFamily: "Roboto, sans-serif",
  },
  recordAction: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    background: "#ffffff",
    padding: theme.spacing(0),
    zIndex: '99'
  },
  headingText: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '22px',
    color: '#00519c',
    fontWeight: '600',
  },
  accHeadingText: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '22px',
    color: '#00519c',
    fontWeight: '600',
    margin: 0,
  },
  subHeadingText: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '19px',
    color: '#00519c',
    fontWeight: 'bold !important',
  },
  subHeadingText2: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '17px',
    color: '#00519c',
    fontWeight: 'bold !important',
  },
  setBorder: {
    borderRadius: "6px",
    border: "1px solid rgba(224, 224, 224, 1)",
    overflow: "auto",
  },
  setBorder2: {
    borderRadius: "6px",
    border: "1px solid rgba(224, 224, 224, 1)",
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0, 2, 0),
  },
  color1: {
    color: '#00519c',
    padding: theme.spacing(0, 1, 0, 0),
  },
  color2: {
    color: '#FF0000',
    padding: theme.spacing(0, 1, 0, 0),
  },
  color3: {
    color: '#A020F0',
    padding: theme.spacing(0, 1, 0, 0),
  },
  color4: {
    color: '#0000FF',
    padding: theme.spacing(0, 1, 0, 0),
  },
  addReport: {
    borderRadius: '6px',
    border: "1px solid rgba(224, 224, 224, 1) !important",
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0, -2, 0),
  },
  addReport2: {
    borderRadius: '6px',
    border: "1px solid rgba(224, 224, 224, 1) !important",
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0, 2, 0),
  },
  pageNumber: {
    width: 100,
  },
  pageNumberWidth: {
    width: 100
  },
  cruTheadLeftSep: {
    padding: theme.spacing(0, 0.75) + '!important',
    whiteSpace: "normal",
    fontWeight: 'bold !important',
    height: "46px",
    borderBottom: "1px solid #d7d7d7",
    borderLeft: "1px solid #d7d7d7 !important",
  },
  cruTheadRightSep: {
    padding: theme.spacing(0, 0.75) + '!important',
    whiteSpace: "normal",
    fontWeight: 'bold !important',
    height: "46px",
    borderBottom: "1px solid #d7d7d7",
    borderRight: "1px solid #d7d7d7 !important",
  },
  tableCellRightSep: {
    padding: theme.spacing(0, 0.75) + '!important',
    height: "46px",
    borderBottom: "1px solid #d7d7d7",
    borderRight: "1px solid #d7d7d7 !important",
  },
  tableCellLeftSep: {
    padding: theme.spacing(0, 0.75) + '!important',
    height: "46px",
    borderBottom: "1px solid #d7d7d7",
    borderLeft: "1px solid #d7d7d7 !important",
  },
  overviewIcons: {
    minWidth: 40,
    display: "inline-block",
    textAlign: "center",
  },
  fullWidth: {
    width: 'calc(100vw - 255px) !important',
  },
  fullWidth2: {
    width: 'calc(100vw - 315px) !important',
  },
  // Add Jobs Popup Styles
  popupArea: {
    padding: theme.spacing(3),
  },
  popupHeading: {
    padding: theme.spacing(0, 0, 3, 0),
  },
  setColor: {
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  bulkTemplate: {
    background: "#E5F6FD",
    border: "1px solid #03A9F4",
    borderRadius: '6px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '14px',
    padding: theme.spacing(1),
    color: "#014361 !important",
    fontWeight: 'normal !important',
  },
  removeCheckMargin: {
    marginBottom: ' 0 !important',
    "& .MuiFormControlLabel-root": {
      marginBottom: ' 0 !important',
    }
  },
  borderBottom: {
    borderBottom: "1px solid #d7d7d7",
    width: "100%",
  },
  jointVentureDetails: {
    border: '1px solid #d7d7d7',
    borderRadius: 6,
    overflow: 'hidden',
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0, 1, 0),
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
  },
  borderPadding: {
    border: '1px solid #d7d7d7',
    borderRadius: 6,
    overflow: 'hidden',
    padding: theme.spacing(2),
    margin: theme.spacing(0),
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
  },
  alertMessage:{
    fontFamily: "Roboto, sans-serif",
    marginTop:"20px",
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  subHeading: {
    fontWeight: 700,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "14px",
    minWidth: "100px",
  },
  // historyTable: {
  //   "& thead": {
  //     "& th:nth-child(-n + 6)": {
  //       borderLeft: '0 !important'
  //     }
  //   },
  // }
}));

export default useStyles;
