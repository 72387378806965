import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toasterError = (errorMessage)=>{
    toast.error(`${errorMessage}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'error',
      });
}

export const toasterSuccess = (successMessage)=>{
  toast.success(`${successMessage}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'success',
      });
}