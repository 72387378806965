
import { Button, CircularProgress } from '@mui/material';
import * as React from 'react';

    function LoadingButton({ buttonName, buttonIcon, clickHandler, buttonColor, isLoading = false, buttonClass, searchValue }) {
    return (
        <>
            <Button
                type="submit"
                disabled={isLoading|| !!searchValue}
                variant="contained"
                className={buttonClass == '' ? 'rounded-border' : buttonClass}
                color={buttonColor == 'undefined' ? '' : buttonColor}
                onClick={clickHandler}
                style={{
                    color: isLoading ? "transparent" : "",
                    position: "relative", height: "100%", width: "auto",
                    padding: "10px 15px !important",
                }}
                startIcon={buttonIcon}
            >
                {buttonName}
                {isLoading ? (
                    <div style={{ position: "absolute", top: "calc(50% - 11px)", right: "38%" }} >
                        <CircularProgress size={20} />
                    </div>
                ) : null}
            </Button>

        </>
    );
}

export default LoadingButton;


// {isLoading ? (
//     <div style={{ paddingTop: '2px', verticalAlign: 'middle', lineHeight: '15px',display:'inline-block' }} >
//         <CircularProgress size={20} />
//     </div>
// ) : (buttonName)}