// Default React, useState and Material Components
import React, { useEffect, useState } from "react";
import { Button, Box, Breadcrumbs, Grid, IconButton, InputAdornment, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, } from "@mui/material";
import { Link } from 'react-router-dom';
// Importing Custom Services
import axios from "axios";
import ErroHandling from "../../../Services/ErrorHandling";
import GetMenuService from "../../../Services/GetMenuService";

// Importing Custom Components
import DataTable from "../../../UIComponent/DataTable";
import BaseLayout from "../../../UIComponent/BaseLayout";
import SideDrawer from "../../../UIComponent/Drawer/index";
import { toasterError, toasterSuccess } from "../../../UIComponent/Toaster";

// Importing Add & Edit Components
import AddPriority from "./AddPriority";

// Import Fluent UI Icons
import { AddIcon, ChevronRightSmallIcon, ClearIcon, SearchIcon } from "@fluentui/react-icons-mdl2";

// Importing useStyles and Styles
import useStyles from "../ManageData.styles";

// Creating Data Table for Top Priority
function createData(id, priority, description) {
  return { id, priority, description };
}

// Assigning Columns and UI Props
const columns = [
  { id: "id", label: "ID", type: "Highlight" },
  { id: "priority", label: "Priority", minWidth: 200, width: "25%", sorting: true, },
  { id: "description", label: "Description", minWidth: 400, width: "70%", sorting: true, truncate: 90, tooltipWithTruncate: true },
];

const TopPriority = (props) => {

  // Declaration of States and Component Variables Start Here
  const classes = useStyles();
  const [id, setId] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [crudState, setCrudState] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, SetSelected] = React.useState([]);
  const closeDeleteDialog = () => { setOpen(false); };
  const [state, setState] = useState({ right: false });
  const [isAllSelected, setIsSelected] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filterValue, setFilterValue] = React.useState("");
  const [sorting, setSorting] = useState({ sortBy: "", isAscending: true });
  const openDeleteDialog = (open, mode) => (event, item) => { setOpen(open); setSelectedItem(item.id); };
  const globalSearch = (e) => { if (e.key === "Enter") { setReload(true); } };
  const [access, setAccess] = React.useState({ allowEdit: false, allowDelete: false, allowView: false });
  const [menuPermission, setMenuPermission] = useState({ allowView: true, allowEdit: true, allowDelete: true, });
  const toggleDrawer = (open, mode) => (event, item) => {
    setState({ ...state, right: open }); setCrudState(mode); setSelectedItem(item);
  };
  // Declaration of States and Component Variables End Here

  // Declaration of React Hooks Start Here
  useEffect(() => { getData(); setReload(false); }, [reload]);
  React.useEffect(() => {
    GetMenuService(23, function (err, res) {
      if (res) {
        // console.log(res.data)
        setAccess(res.data)
      } else {
        toasterError(err)
      }
    })
  }, [])
  // Declaration of React Hooks End Here

  React.useEffect(() => {
    if (filterValue == "" || filterValue == null || filterValue == undefined) { getData('') }
    else { getData(filterValue) }
  }, [sorting]);

  React.useEffect(() => {
    if (filterValue == "" || filterValue == null || filterValue == undefined) { getData('') }
    else { getData(filterValue) }
  }, [page, reload, rowsPerPage])

// This function handles the keyup event
const handleKeyUp = (event) => {
  // If backspace is pressed and target element is not a button
  if ((event.keyCode === 8 || event.which === 8) && event.target.nodeName !== "BUTTON") {
    // If filter value is empty, get priority data
    if (filterValue.length === 0) { getPriorityData(); }
  }
  // If enter is pressed and target element is not a button
  if ((event.keyCode === 13 || event.which === 13) && event.target.nodeName !== "BUTTON") {
    // Set total count to 0 and page to 0
    setTotalCount(0);
    setPage(0);
    // Get data with the value of the target element
    getData(event.target.value);
  }
};

// This function is used to get priority data from the server
  const getPriorityData = async () => {
     // Create a new promise to handle the response
    const response = await new Promise((resolve, reject) => {
       // Resolve the promise with an axios GET request
      resolve(axios.get(
        `${process.env.React_App_baseUrl}managedata/GetPriorityDetails`,
        {
          // Set headers for the request
          headers: {
            searchText: filterValue ? encodeURIComponent(filterValue) : "", isAscending: false, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          },
          // Set parameters for the request
          params: { pageNumber: page + 1, pagesize: rowsPerPage, },
        }
      ));
    });
    return response;
  };

// Function to get data from an API
const getData = async () => {
  // Set loading state to true
  setLoading(true);
  try {
    // Get priority data from API
    const { data } = await getPriorityData();
    // Set loading state to false
    setLoading(false);
    // Set total count of data
    setTotalCount(data.totalCount);
    // Map over the data and create new data
    const finalData = await data.data.map((el) =>
      createData(el.id, el.name, el.description)
    );
    // Set rows with the new data
    setRows(finalData);
  } catch (error) {
    // Set rows to empty array if error occurs
    setRows([]); return null;
  }
};

// This function handles the deletion of a priority item
const handleDelete = async () => {
  try {
    // Make an axios post request to the React_App_baseUrl endpoint with the selectedItem and token from sessionStorage
    await axios
      .post(`${process.env.React_App_baseUrl}managedata/DeletePriority`, null,
        {
          headers: {
            id: selectedItem, 'Authorization': localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : null
          },
        }
      )
      // If successful, close the modal and display a success message
      .then((res) => {
        setOpen(false);
        toasterSuccess("Status deleted successfully!");
        setReload(true);
      })
      // If unsuccessful, close the modal and display an error message
      .catch((err) => {
        setOpen(false); ErroHandling(err, function (res) {
          toasterError(res)
        })
      });
  } catch (error) {
    // If an error occurs, display an error message
    ErroHandling(error, function (res) {
      toasterError(res)
    })
  }
};

  return (
    // Page Layout Start Here
    <Box>
      <Grid container maxWidth justifyContent="right" alignItems="center">

        {/* Breadcrumbs Menu Start Here */}
        <Grid item xs={12} sm={12} md={6} className="page-title">
          <h2>Priority</h2>
          <Breadcrumbs
            separator={<ChevronRightSmallIcon className={classes.breadCrumbsIcon} />} aria-label="breadcrumb" className="custom-breadcrumb">
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/ownership-changes/source-management" }}>Home</Link>
            <Link underline="hover" color="inherit" to= {{ pathname: "/app/commodity" }}>Manage Data</Link>
            <Typography color="text.primary">Priority</Typography>
          </Breadcrumbs>
        </Grid>
        {/* Breadcrumbs Menu End Here */}

        {/* Main Buttons Start Here */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={"auto"}>
              {(access.allowEdit) ? <>
                <Button onClick={toggleDrawer(true, "Add")} variant="contained" color="accent2" startIcon={<AddIcon style={{ fontSize: 16 }} />}>
                  Add Priority
                </Button>
              </> : null}
            </Grid>
          </Grid>
        </Grid>
        {/* Main Buttons End Here */}

        {/* Baselayout Start Here */}
        <Grid item xs={12}>
          <BaseLayout>
            <Box className={classes.addBorder}>
              {/* Search Option and Total Count Start Here */}
              <Grid item xs={12} className={classes.tableTopPart}>
                <Grid container alignItems="center" justifyContent="left" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="input-with-icon-textfield"
                      className={classes.searchByKeyword}
                      value={filterValue}
                      onKeyUp={handleKeyUp}
                      //onChange={(e) => globalSearch(e.target.value)}
                      placeholder="Search by Keyword"
                      onChange={(e) => setFilterValue(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    {filterValue ? (
                      <IconButton
                        aria-label="Add"
                        className={classes.serchByClear}
                        onClick={() => {
                          setFilterValue("");
                          setReload(true);
                          //getSubRegionData();
                        }}
                      >
                        <ClearIcon style={{ fontSize: 10 }} />
                      </IconButton>
                    ) : ("")}

                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="right">
                    <Typography variant="subtitle2">
                      Total Records: <span className={classes.totalRecord}>{totalCount}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Search Option and Total Count End Here */}

              {/* Priority Table /> */}
              <Grid item xs={12}>
                <DataTable
                  className={classes.removeBorder}
                  loading={isLoading}
                  rows={rows ? rows : []}
                  columns={columns}
                  page={page}
                  setPage={(data) => {
                    setPage(data);
                    setReload(true);
                  }}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={(data) => {
                    setRowsPerPage(data);
                    setReload(true);
                  }}
                  totalCount={totalCount}
                  isAllSelected={isAllSelected}
                  setIsSelected={setIsSelected}
                  selected={selected}
                  setSelected={SetSelected}
                  sorting={sorting}
                  setSorting={(e) => {
                    setReload(true);
                    setSorting(e);
                  }}
                  isActionButton={{
                    ...(access.allowEdit && {
                      edit: toggleDrawer(true, "Edit"),
                    }),
                    ...(access.allowDelete && {
                      delete: openDeleteDialog(true),
                    }),
                  }}
                />
              </Grid>
            </Box>
          </BaseLayout>
        </Grid>
      </Grid>

      {/* Add & Edit Frequency Year Quarters Side Drawer */}
      <SideDrawer
        state={state}
        setState={setState}
        formComp={
          // Calling the AddPriority
          <AddPriority
            state={state}
            setState={setState}
            crudState={crudState}
            selectedItem={selectedItem}
            reload={reload}
            setReload={setReload}
          />
        }
      />

      {/* Alert Dialog - Confirmation for Delete */}
      <Dialog
        open={open}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"> Would you like to delete the selected item?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} autoFocus style={{ marginRight: "15px" }}>No</Button>
          <Button onClick={handleDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TopPriority;