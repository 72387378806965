import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
  filterValue: null,
  searchText: null,
  filterData: null,
  companyFilter: null,
  locationFilter: null,
  treeLocation: [],
  treeCompany: [],
  treeComm: [],
  meritQCFilter : null,
  filterDatas : null,
};

export const stateSlice = createSlice({
  name: "stateSlice",
  initialState,
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // },
    setFiterValue: (state, { payload }) => {
      state.filterValue = payload;
    },
    setSearchValues: (state, { payload }) => {
      state.searchValue = payload;
    },
    setCompanyValues: (state, { payload }) => {
      state.companyFilter = payload;
    },
    setLocationValues: (state, { payload }) => {
      state.locationFilter = payload;
    },
    setFilterData: (state, { payload }) => {
      state.filterData = payload;
    },
    setTreeLocation: (state, { payload }) => {
      state.treeLocation = payload;
    },
    setTreeCompany: (state, { payload }) => {
      state.treeCompany = payload;
    },
    setTreeComm: (state, { payload }) => {
      state.treeComm = payload;
    },
    clearFilterValue: (state, { payload }) => {
      state.filterValue = payload;
    },
    setMeritQCFilter: (state, { payload }) => {
      state.meritQCFilter = payload
    },
    clearMeritQCFilter: (state) => {
      state.meritQCFilter = "";
    },
    setFilterDatas: (state, { payload }) => {
      state.meritQCFilter = payload
    },
    clearFilterDatas: (state) => {
      state.meritQCFilter = "";
    },
    
  },
});

// Action creators are generated for each case reducer function
export const {
  setFiterValue,
  setSearchValues,
  setCompanyValues,
  setLocationValues,
  setFilterData,
  setTreeLocation,
  setTreeCompany,
  setTreeComm,
  clearFilterValue,
  setMeritQCFilter,
  clearMeritQCFilter
} = stateSlice.actions;

export default stateSlice.reducer;
